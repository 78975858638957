import React from 'react'
import Select from 'react-select'

const TimezoneSelector = ({ timezone, onChange }) => {
  const timezoneList = Intl.supportedValuesOf('timeZone').map(tz => ({
    value: tz,
    label: tz,
  }))

  const timezoneOption = timezoneList.find(option =>
    option.value === timezone
  )

  const handleTimezoneChange = (option) => {
    onChange(option.value)
  }

  return (
    <div className="timezone-wrapper">
      <label>
        Timezone:
      </label>
      <Select
        className="timezone-selector"
        options={timezoneList}
        placeholder="Choose timezone"
        value={timezoneOption}
        onChange={handleTimezoneChange}
      />
    </div>
  )
}

export default TimezoneSelector
