import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import VideoLink from '../../../CommonComponents/VideoLink'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'

import AdgroupSelector from '../../AdgroupSelector'
import KeywordTable from './keywords-table'
import SalesTable from './sales-table'
import AcosTable from './nonprofitable-table'
import CtrTable from './low-ctr-table'

const videoList = [
  { title: 'Keyword Optimization Video', url: 'https://www.loom.com/embed/b0c3af42a05741ea99b905c66a564ba9' },
]

const KeywordCleanerTab = ({ campaignType }) => {
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const [currentAdgroup, setCurrentAdgroup] = useState('')

  useEffect(() => {
    if (!currentAdGroups || !currentAdGroups.length) {
      return
    }
    setCurrentAdgroup(currentAdGroups[0])
  }, [currentAdGroups])

  if (campaignType !== 'sp') {
    return null
  }

  return (
    <div className="campaign-detail-keyword-cleaner campaign-detail-tab">
      <div className="tab-info">
        <div className="tab-title">
          Keyword Cleaner
          <CustomTooltip placement="right">
            <p>Keyword Cleaner allows you to research problematic search terms
            on a keyword level - click a keyword below to reveal the search terms
            they’ve connected with.</p>
            <p>You’ll then be able to optimize out any search terms that have clicks without sales,
            high ACoS, or CTR issues.</p>
            <p>This is a great way to reduce the ACoS of a keyword without
            lowering the bid.</p>
            <p>Keyword Cleaner does not work with Exact Match Keywords.</p>
          </CustomTooltip>
        </div>
        <div className="tab-description">
          Click on a Keyword to See Unprofitable Search Terms Below.
        </div>
        <VideoLink
          videoList={videoList}
          modalTitle='Keyword Cleaner Optimization'
        />
      </div>
      <AdgroupSelector
        campaignType={campaignType}
        currentAdgroup={currentAdgroup}
        showAllAdgroup
        onAdgroupChange={setCurrentAdgroup}
      />
      <KeywordTable
        currentAdGroupId={currentAdgroup?.adgroupid}
        campaignType={campaignType}
      />
      <div className="results-container">
        <h5>Keyword Cleaner Results</h5>
        <div className="results-tables">
          <SalesTable
            currentAdGroupId={currentAdgroup?.adgroupid}
            campaignType={campaignType}
          />
          <AcosTable
            currentAdGroupId={currentAdgroup?.adgroupid}
            campaignType={campaignType}
          />
          <CtrTable
            currentAdGroupId={currentAdgroup?.adgroupid}
            campaignType={campaignType}
          />
        </div>
      </div>
    </div>
  )
}

export default KeywordCleanerTab
