import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'
import TargetingSelector from '../../CommonComponents/TargetingSelector'

import AdgroupInfo from '../../CampaignCreator/Shared/AdgroupInfo'
import ProductSection from '../../CampaignCreator/Shared/ProductSection'
import NegativeKeywordSection from '../../CampaignCreator/Shared/NegativeKeywordSection'
import NegativeTargetingSection from '../../CampaignCreator/Shared/NegativeTargetingSection'
import SDLocationSection from '../../CampaignCreator/Shared/SDLocationSection'
import SDAdSection from '../../CampaignCreator/Shared/SDAdSection'
import SDCreativeSection from '../../CampaignCreator/Shared/SDCreativeSection'
import TargetingTypeSelector from '../../CampaignCreator/Shared/TargetingTypeSelector'
import SBLandingPageSection from '../../CampaignCreator/Shared/SBLandingPageSection'
import SBAdSection from '../../CampaignCreator/Shared/SBAdSection'
import SBTargetInputSection from '../../CampaignCreator/Shared/SBTargetInputSection'
import SBCreativeSection from '../../CampaignCreator/Shared/SBCreativeSection'

import SPKeywordSection from '../../CampaignCreator/SPKeywordSection'
import SPTargetingSection from '../../CampaignCreator/SPTargetingSection'
import SDTargetingSection from '../../CampaignCreator/SDTargetingSection'
import AudienceSection from '../../CampaignCreator/AudienceSection'

import {
  getSpSuggestions,
  getSpBidSuggestions,
  getSdSuggestions,
  getSbLandingPageAsins,
  getSbStoreAsins,
  getSbSuggestions,
  getSbBidSuggestions,
} from '../../../redux/actions/campaignCreator'

import { searchProduct } from '../../../redux/actions/targeting'
import { createAdgroup } from '../../../redux/actions/bulkEngine'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import {
  compileSbAd,
  compileSdCreative,
  getBidLimits,
  isAsin,
  parseNtExp,
  parseTargeting,
} from '../../../services/helper'
import {
  validateAdgroupName,
  validateKeywordLength,
  validateNKLength,
  validateTargets,
} from '../../../services/validator'
import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
  adFormatList,
  LANDING_PAGE_TYPE_NEW_LANDING_PAGE,
  LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE,
  LANDING_PAGE_TYPE_STORE,
  GOAL_BRAND_IMPRESSION_SHARE,
  PLACEMENT_PRODUCT_PAGE_V3,
  PLACEMENT_TOP_V3,
  PLACEMENT_PRODUCT_PAGE,
  PLACEMENT_TOP,
  campaignTypeMap,
} from '../../../utils/defaultValues'


const CampaignOption = (props) => {
  const { innerRef, innerProps, getStyles, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
      className="campaign-option"
    >
      <div>
        { data.campaign }
      </div>
      <div className="campaign-detail">
        {
          data.campaignType === 'sp' && (
            <span>
              { data.targeting_type === 'auto' ? 'Auto' : 'Manual' }
            </span>
          )
        }
        <span>
          { campaignTypeMap[data.campaignType] }
        </span>
      </div>
    </div>
  )
}

const bidOpOptions = [
  { value: 'clicks', label: 'Optimize for page visits' },
  { value: 'conversions', label: 'Optimize for conversion' },
  { value: 'reach', label: 'Optimize for viewable impressions' },
]

const ModalAddAdgroup = ({ campaignDetail = null, campaigns = [],
  onCreate = () => {}, onCancel }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)

  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [isCreating, setIsCreating] = useState(false)

  const currentDetail = useMemo(() => {
    if (campaignDetail) {
      return campaignDetail
    }
    if (selectedCampaign) {
      return selectedCampaign
    }
    return null
  }, [campaignDetail, selectedCampaign])

  const [name, setName] = useState('')
  const [defaultBid, setDefaultBid] = useState(0.02)
  const [selectedBidOp, setSelectedBidOp] = useState(bidOpOptions[0])
  const [products, setProducts] = useState([])

  const [manualTarget, setManualTarget] = useState('keyword')

  const [locations, setLocations] = useState([])
  const [keywords, setKeywords] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [targetings, setTargetings] = useState([])
  const [negativeTargetings, setNegativeTargetings] = useState([])

  const [basicInfo, setBasicInfo] = useState({
    brandEntityId: currentDetail?.brand_entity_id,
    hasLogo: false,
    logoAsset: null,
    hasHeadline: false,
    headline: '',
    hasCustomImage: false,
    customImageAsset: null,
    brandName: '',
  })

  const [adInfo, setAdInfo] = useState({
    name: '',
    landingPage: '',
  })

  const [logoCrop, setLogoCrop] = useState(null)
  const [customCrop, setCustomCrop] = useState(null)
  const [customSquareCrop, setCustomSquareCrop] = useState(null)

  const [adFormat, setAdFormat] = useState(AD_FORMAT_COLLECTION)
  const [landingPageType, setLandingPageType] = useState(LANDING_PAGE_TYPE_NEW_LANDING_PAGE)
  const [storePageUrl, setStorePageUrl] = useState('')

  const [creativeProducts, setCreativeProducts] = useState([])
  const [creativeSubPages, setCreativeSubPages] = useState([])

  const [adName, setAdName] = useState('')

  const [isStorePageAsinsLoading, setIsStorePageAsinsLoading] = useState(false)
  const [subPages, setSubPages] = useState([])
  const [isLandingPageAsinsLoading, setIsLandingPageAsinsLoading] = useState(false)

  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [isSuggestedBidsLoading, setIsSuggestedBidsLoading] = useState(false)
  const [sbKeywordSuggestions, setSbKeywordSuggestions] = useState([])
  const [sbCategorySuggestions, setSbCategorySuggestions] = useState([])
  const [sbProductSuggestions, setSbProductSuggestions] = useState([])

  useEffect(() => {
    if (!location.state) {
      return
    }
    if (location.state.targets && location.state.targets.length) {
      setKeywords(location.state.targets.map((target, index) => ({
        id: index,
        keywordText: target,
        matchType: 'broad',
        keywordBid: 0,
      })))

      const asinTargets = location.state.targets.filter(isAsin)

      if (asinTargets.length) {
        (async () => {
          const accessToken = await getAccessTokenSilently()
          const response = await dispatch(searchProduct(accessToken, {
            asins: asinTargets.join(),
          }))
          setTargetings(response.map(product => ({
            ...product,
            isTargeted: true,
            type: 'product',
            bid: 0,
          })))
        })()
      }
    }

    if (location.state.categories && location.state.categories.length) {
      setTargetings(location.state.categories.map(category => ({
        ...category,
        type: 'category',
        bid: 0,
      })))
    }

    if (location.state.productTargeting) {
      setManualTarget('product')
    }
  }, [location.state]) // eslint-disable-line

  const getProductTargets = () => {
    const targets = []
    targetings.forEach((targeting) => {
      if (currentDetail.campaignType === 'sp') {
        const payload = parseTargeting(targeting, true)
        if (!payload) {
          return
        }

        payload.expressionType = 'manual'
        payload.state = 'enabled'

        targets.push(payload)
      } else if (currentDetail.campaignType === 'sd') {
        if (
          (
            currentDetail.tactic === 'T00020'
            && ['category', 'refine', 'product'].indexOf(targeting.type) === -1
          )
          ||
          (
            currentDetail.tactic === 'T00030'
            && ['audience_category', 'audience_refine', 'audience_product', 'audience'].indexOf(targeting.type) === -1
          )
        ) {
          return
        }

        const payload = parseTargeting(targeting)
        if (!payload) {
          return
        }

        targets.push(payload)
      } else {
        const payload = parseTargeting(targeting)
        if (!payload) {
          return
        }

        payload.expressions = payload.expression
        delete payload.expression

        targets.push(payload)
      }
    })
    return targets
  }

  const getNegativeProductTargets = () => {
    if (currentDetail.campaignType !== 'sb') {
      return negativeTargetings.map(product => ({
        expressionType: 'manual',
        expression: parseNtExp(product),
        state: 'enabled',
      }))
    }
    return negativeTargetings.map(product => ({
      expressions: parseNtExp(product),
    }))
  }

  const handleCreate = async () => {
    if (!currentDetail) {
      toast.show({
        title: 'Warning',
        description: 'Please choose a campaign.',
      })
      return
    }

    const error = validateAdgroupName(name)
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    let bidLimits
    let bidOp
    let productAds
    let creativeProps = {}

    if (currentDetail.campaignType !== 'sb') {
      bidLimits = getBidLimits(
        currentDetail.campaignType,
        currentAccount?.country_id,
        currentDetail.cost_type,
      )

      // Validate default bid.
      if (!defaultBid
        || Number.isNaN(parseFloat(defaultBid))) {
        toast.show({
          title: 'Warning',
          description: `Please enter a bid value for use when no bid is specified `
            + 'for keywords/targets.',
        })
        return
      }

      if (currentDetail.campaignType === 'sd'
        && parseFloat(defaultBid) >= parseFloat(currentDetail.daily_budget) / 2) {
        toast.show({
          title: 'Warning',
          description: 'Bid must be less than half the value of your budget.',
        })
        return
      }

      if (parseFloat(defaultBid) < bidLimits[0]
        || parseFloat(defaultBid) > bidLimits[1]) {
        toast.show({
          title: 'Warning',
          description: `Default bid is out of range (must be in [${bidLimits[0]}, ${bidLimits[1]}])`,
        })
        return
      }

      if (!isNonEndemicAccount) {
        if (!products.length) {
          toast.show({
            title: 'Warning',
            description: 'Please add products that you want to promote in this ad group.',
          })
          return
        }

        bidOp = selectedBidOp.value

        productAds = products.map(product => ({
          asin: product.asin,
          sku: product.sku,
        }))
      } else {
        // For non-endemics
        if (adInfo.name === '' || adInfo.landingPage === '') {
          toast.show({
            title: 'Warning',
            description: 'Please enter an ad info.',
          })
          return
        }
        if (!(basicInfo.hasLogo && basicInfo.logoAsset)
          || !(basicInfo.hasHeadline && basicInfo.headline !== '')
          || !(basicInfo.hasCustomImage && basicInfo.customImageAsset)) {
          toast.show({
            title: 'Warning',
            description: 'Please add a logo, headline, and custom image for creative.',
          })
          return
        }

        if (currentDetail.cost_type === 'cpc') {
          bidOp = 'clicks'
        } else {
          bidOp = 'reach'
        }

        productAds = [
          {
            adName: adInfo.name,
            landingPageURL: adInfo.landingPage,
          },
        ]

        creativeProps = compileSdCreative(
          basicInfo,
          logoCrop,
          customCrop,
          customSquareCrop,
        )
      }
    } else {
      // For SB ad groups.
      if (!adName) {
        toast.show({
          title: 'Warning',
          description: 'Please enter a ad name.',
        })
        return
      }

      if (adFormat === AD_FORMAT_COLLECTION) {
        if (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
          if (products.length < 3) {
            toast.show({
              title: 'Warning',
              description: 'Please select at least 3 products.',
            })
            return
          }
        } else {
          if (!storePageUrl) {
            toast.show({
              title: 'Warning',
              description: 'Please select a store URL.',
            })
            return
          }

          if (currentDetail.goal === GOAL_BRAND_IMPRESSION_SHARE
            && !basicInfo.customImageAsset) {
            toast.show({
              title: 'Warning',
              description: 'Please select a custom image.',
            })
            return
          }
        }
      } else if (adFormat === AD_FORMAT_SPOTLIGHT) {
        if (!storePageUrl) {
          toast.show({
            title: 'Warning',
            description: 'Please select a store URL.',
          })
          return
        }
      } else {
        if (landingPageType === LANDING_PAGE_TYPE_STORE) {
          if (!storePageUrl) {
            toast.show({
              title: 'Warning',
              description: 'Please select a store URL.',
            })
            return
          }
        }

        if (products.length < 1) {
          toast.show({
            title: 'Warning',
            description: 'Please select at least 1 products.',
          })
          return
        }

        if (!basicInfo.videoAsset
          || !basicInfo.videoAsset.assetId) {
          toast.show({
            title: 'Warning',
            description: 'Please upload a video.',
          })
          return
        }
      }

      if (adFormat !== AD_FORMAT_VIDEO
        || landingPageType === LANDING_PAGE_TYPE_STORE) {
        if (!basicInfo.brandName) {
          toast.show({
            title: 'Warning',
            description: 'Please enter a creative brand name.',
          })
          return
        }

        if (!basicInfo.logoAsset) {
          toast.show({
            title: 'Warning',
            description: 'Please select a creative brand logo.',
          })
          return
        }

        if (!basicInfo.headline) {
          toast.show({
            title: 'Warning',
            description: 'Please enter a creative headline.',
          })
          return
        }

        let headlineLimit = 50
        if (currentAccount?.country_id === 'jp') {
          headlineLimit = 35
        }
        if (basicInfo.headline.length > headlineLimit) {
          toast.show({
            title: 'Warning',
            description: `A creative headline cannot be more than ${headlineLimit} characters.`,
          })
          return
        }
      }

      if (adFormat === AD_FORMAT_SPOTLIGHT) {
        const invalidSubPage = creativeSubPages.find(subPage => (
          subPage.name.length >= 50
        ))
        if (invalidSubPage) {
          toast.show({
            title: 'Warning',
            description: 'Display name must be less than 50 characters.',
          })
          return
        }
      }

      let adType = 'sb'
      if (adFormat === AD_FORMAT_VIDEO
        && landingPageType === LANDING_PAGE_TYPE_STORE) {
        adType = 'sbv'
      }
      bidLimits = getBidLimits(
        adType,
        currentAccount?.country_id,
        currentDetail.cost_type,
      )
    }

    let targetsPayload = []
    let ntsPayload = []
    let keywordsPayload = []
    let nksPayload = []
    let invalidError = null
    if (currentDetail.campaignType !== 'sd') {
      if (manualTarget === 'keyword' && keywords.length < 1) {
        toast.show({
          title: 'Warning',
          description: 'Please enter at least 1 keyword.',
        })
        return
      }

      if (manualTarget === 'product' && targetings.length < 1) {
        toast.show({
          title: 'Warning',
          description: 'Please enter at least 1 product target.',
        })
        return
      }

      if (manualTarget === 'product') {
        targetsPayload = getProductTargets()
        ntsPayload = getNegativeProductTargets()
        invalidError = validateTargets(
          targetsPayload,
          currentDetail.budget || currentDetail.daily_budget,
          bidLimits,
        )
      } else {
        keywordsPayload = keywords.map(kw => ({
          keywordText: kw.keywordText !== '(_targeting_auto_)'
            && kw.keywordText !== '' ? kw.keywordText : kw.search,
          matchType: kw.matchType.toLowerCase(),
          bid: parseFloat(kw.keywordBid),
        }))
        nksPayload = negativeKeywords.map(kw => ({
          keywordText: kw.keywordText !== '(_targeting_auto_)'
            && kw.keywordText !== '' ? kw.keywordText : kw.search,
          matchType: kw.matchType,
        }))
        invalidError = validateTargets(
          keywordsPayload,
          currentDetail.budget || currentDetail.daily_budget,
          bidLimits,
        )
        if (!invalidError) {
          invalidError = validateKeywordLength(keywordsPayload)
        }
        if (!invalidError) {
          invalidError = validateNKLength(nksPayload)
        }
      }
    } else {
      targetsPayload = getProductTargets()
      invalidError = validateTargets(
        targetsPayload,
        currentDetail.budget || currentDetail.daily_budget,
        bidLimits,
      )
    }

    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    setIsCreating(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(createAdgroup(
      accessToken,
      currentDetail.campaign_id,
      currentDetail.campaignType,
      name,
      parseFloat(defaultBid),
      bidOp,
      currentDetail.tactic,
      productAds,
      keywordsPayload,
      nksPayload,
      targetsPayload,
      ntsPayload,
      locations.map(location => location.locationId),
      Object.keys(creativeProps).length
        ? [{
            creativeType: 'IMAGE',
            properties: creativeProps,
          }]
        : [],
      isNonEndemicAccount,
      adFormat,
      currentDetail.campaignType === 'sb'
      ? compileSbAd(
          adFormat,
          landingPageType,
          adName,
          basicInfo,
          currentDetail.goal,
          products,
          creativeProducts,
          creativeSubPages,
          storePageUrl,
          logoCrop,
          customCrop,
        )
      : [],
    ))
    setIsCreating(false)

    if (response && response.adgroup_id) {
      onCreate(response.adgroup_id.toString())
      onCancel()
    }
  }

  const handleSpSuggestionLoad = forTarget => async () => {
    if (products.length) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getSpSuggestions(
        accessToken,
        products.map(product => product.asin),
        forTarget,
      ))
      setIsSuggestionsLoading(false)
    }
  }

  const handleSdSuggestionLoad = forTarget => async () => {
    if (products.length) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getSdSuggestions(
        accessToken,
        currentDetail.tactic,
        products.map(product => product.asin),
        forTarget,
      ))
      setIsSuggestionsLoading(false)
    }
  }

  const handleSbSuggestionLoad = forTarget => async () => {
    let productsToGet
    if (landingPageType === LANDING_PAGE_TYPE_STORE) {
      productsToGet = creativeProducts
    } else {
      productsToGet = products
    }

    if (productsToGet.length) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSbSuggestions(
        accessToken,
        productsToGet.map(product => product.asin),
        forTarget,
      ))
      if (forTarget) {
        setSbCategorySuggestions(response.categories)
        setSbProductSuggestions(response.products)
      } else {
        setSbKeywordSuggestions(response)
      }
      setIsSuggestionsLoading(false)
    }
  }

  const loadSpKeywordBidSuggestions = async (keywordsToGetBid) => {
    const bidding = {
      adjustments: [],
    }
    if (currentDetail.bidding.strategy === 'legacyForSales') {
      bidding.strategy = 'LEGACY_FOR_SALES';
    } else if (currentDetail.bidding.strategy === 'autoForSales') {
      bidding.strategy = 'AUTO_FOR_SALES';
    } else if (currentDetail.bidding.strategy === 'manual') {
      bidding.strategy = 'MANUAL';
    }

    (currentDetail.bidding.adjustments || []).forEach((adjustment) => {
      let predicate = ''
      if (adjustment.predicate === PLACEMENT_TOP) {
        predicate = PLACEMENT_TOP_V3
      } else if (adjustment.predicate === PLACEMENT_PRODUCT_PAGE) {
        predicate = PLACEMENT_PRODUCT_PAGE_V3
      } else {
        return
      }

      bidding.adjustments.push({
        predicate,
        percentage: adjustment.percentage,
      })
    })

    setIsSuggestedBidsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSpBidSuggestions(
      accessToken,
      keywordsToGetBid,
      products.map(product => product.asin),
      bidding,
    ))
    setIsSuggestedBidsLoading(false)

    setKeywords(prev => prev.map((keyword) => {
      const suggestedBid = response.filter(bid => (
        bid.keywordText === keyword.keywordText
        && bid.matchType === keyword.matchType
      ))
      if (suggestedBid.length) {
        return {
          ...keyword,
          suggestedBid: suggestedBid[0].suggestedBid,
        }
      }
      return keyword
    }))
  }

  const loadSbKeywordBidSuggestions = async (keywordsToGetBid) => {
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbBidSuggestions(
      accessToken,
      keywordsToGetBid,
      adFormat,
    ))

    setKeywords(prev => prev.map((keyword) => {
      const suggestedBid = response.filter(bid => (
        bid.keyword.keywordText === keyword.keywordText
        && bid.keyword.matchType === keyword.matchType
      ))
      if (suggestedBid.length) {
        return {
          ...keyword,
          suggestedBid: suggestedBid[0].recommendedBid,
        }
      }
      return keyword
    }))
  }

  const handleKeywordsSelect = async (keywords, reload = false, newKeywords = []) => {
    setKeywords(keywords)

    if (reload && newKeywords.length && products.length) {
      if (currentDetail.campaignType === 'sp' && products.length) {
        await loadSpKeywordBidSuggestions(newKeywords)
      } else if (currentDetail.campaignType === 'sb') {
        await loadSbKeywordBidSuggestions(newKeywords)
      }
    }
  }

  const handleBasicInfoChange = (name, value) => {
    setBasicInfo(Object.assign({}, basicInfo, {
      [name]: value,
    }))
  }

  const handleAdInfoChange = (name, value) => {
    setAdInfo(Object.assign({}, adInfo, {
      [name]: value,
    }))
  }

  const handleImageCrop = (cropType, cropCoordinates = null, squareCoordinates = null) => {
    if (cropType === 'logo') {
      setLogoCrop(cropCoordinates)
    } else {
      setCustomCrop(cropCoordinates)
      setCustomSquareCrop(squareCoordinates)
    }
  }

  const handleAdFormatChange = (value) => {
    setAdFormat(value)
    setProducts([])

    if (value === AD_FORMAT_SPOTLIGHT
      && (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        || landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE)) {
      setLandingPageType(LANDING_PAGE_TYPE_STORE)
    } else if (value === AD_FORMAT_VIDEO
      && landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
      setLandingPageType(LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE)
    } else if (value === AD_FORMAT_COLLECTION
      && landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
      setLandingPageType(LANDING_PAGE_TYPE_NEW_LANDING_PAGE)
    }
  }

  const handleLandingPageTypeChange = (value) => {
    if (
      currentDetail.goal === GOAL_BRAND_IMPRESSION_SHARE
      && (
        value === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        || value === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE
      )
    ) {
      toast.show({
        title: 'Warning',
        description: 'Not available with Grow brand impression share goal.',
      })
      return
    }
    setLandingPageType(value)
  }

  const handleStorePageChange = async (value) => {
    setStorePageUrl(value)

    setIsLandingPageAsinsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbLandingPageAsins(
      accessToken,
      value,
    ))
    setIsLandingPageAsinsLoading(false)
    if (response.length < 3) {
      toast.show({
        title: 'Warning',
        description: 'This page has less than 3 products.',
      })
    } else {
      handleSbProductsSelect(response)
    }
  }

  const handleStoreChange = async (storePageInfo) => {
    setIsStorePageAsinsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSbStoreAsins(
      accessToken,
      storePageInfo,
    ))
    setIsStorePageAsinsLoading(false)
    if (response.length < 3) {
      // The response omits HOME page, so we check
      // if the count is 3, not 4.
      toast.show({
        title: 'Warning',
        description: 'The store must have 4 or more pages, each with 1 or more unique products.',
      })
    } else {
      const _creativeSubPages = []
      const _subPages = response.map((page, index) => {
        if (index < 3) {
          _creativeSubPages.push({
            id: page.pageInfo.storePageId,
            name: page.pageInfo.storePageName,
            url: page.pageInfo.storePageUrl,
            product: page.products[0],
          })
        }

        return {
          id: page.pageInfo.storePageId,
          name: page.pageInfo.storePageName,
          url: page.pageInfo.storePageUrl,
          products: page.products,
        }
      })

      setSubPages(_subPages)
      setCreativeSubPages(_creativeSubPages)

      const homeData = storePageInfo.find(page =>
        page.storePageName === 'Home'
      )
      setStorePageUrl(homeData.storePageUrl)
    }
  }

  const handleSbProductsSelect = (value) => {
    if (adFormat === AD_FORMAT_COLLECTION) {
      setProducts(value)
      setCreativeProducts(value.slice(0, 3))
    } else if (adFormat === AD_FORMAT_VIDEO) {
      if (landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
        setProducts(value.slice(0, 1))
        setCreativeProducts(value.slice(0, 1))
      } else {
        setProducts(value.slice(0, 3))
        setCreativeProducts(value.slice(0, 3))
      }
    }
  }

  const handleSubPageChange = (_subPages, _creativeSubPages) => {
    setSubPages(_subPages)
    setCreativeSubPages(_creativeSubPages)
  }

  const handleBrandLogoSelect = (selectedLogo) => {
    handleBasicInfoChange('logoAsset', selectedLogo)
  }

  const renderDefaultBid = () => {
    if (currentDetail.campaignType === 'sb') {
      return (
        <div className="field-wrapper"></div>
      )
    }

    return (
      <div className="field-wrapper">
        <div className="field-name">
          Default Bid
        </div>
        <input
          type="number"
          placeholder="Default bid price"
          value={defaultBid}
          onChange={(event) => { setDefaultBid(event.target.value) }}
        />
      </div>
    )
  }

  const renderProducts = () => {
    if (currentDetail.campaignType === 'sb'
      || isNonEndemicAccount) {
      return null
    }

    return (
      <ProductSection
        products={products}
        onChange={setProducts}
      />
    )
  }

  const renderSpSections = () => {
    if (currentDetail.targeting_type !== 'manual') {
      return null
    }

    return (
      <>
        <TargetingSelector
          manualTarget={manualTarget}
          noAudience
          onChange={setManualTarget}
        />
        {
          manualTarget === 'keyword' && (
            <>
              <SPKeywordSection
                keywords={keywords}
                isSuggestionsLoading={isSuggestionsLoading}
                onFind={handleSpSuggestionLoad(false)}
                onChange={handleKeywordsSelect}
              />
              <NegativeKeywordSection
                negativeKeywords={negativeKeywords}
                bidInfo={{ defaultBid }}
                onChange={setNegativeKeywords}
              />
            </>
          )
        }
        {
          manualTarget === 'product' && (
            <>
              <SPTargetingSection
                targetings={targetings}
                isSuggestionsLoading={isSuggestionsLoading}
                dailyBudget={currentDetail.daily_budget}
                onFind={handleSpSuggestionLoad(true)}
                onChange={setTargetings}
              />
              <NegativeTargetingSection
                negativeTargetings={negativeTargetings}
                onChange={setNegativeTargetings}
              />
            </>
          )
        }
      </>
    )
  }

  const renderSdSections = () => {
    return (
      <>
        {
          isNonEndemicAccount && (
            <SDLocationSection
              locations={locations}
              onSelect={setLocations}
            />
          )
        }
        {
          currentDetail.tactic === 'T00020' ? (
            <SDTargetingSection
              targetings={targetings}
              isSuggestionsLoading={isSuggestionsLoading}
              dailyBudget={currentDetail.daily_budget}
              bidInfo={{ bidOp: selectedBidOp.value }}
              products={products}
              onFind={handleSdSuggestionLoad(true)}
              onChange={setTargetings}
            />
          ) : (
            <AudienceSection
              targetings={targetings}
              isSuggestionsLoading={isSuggestionsLoading}
              dailyBudget={currentDetail.daily_budget}
              bidInfo={{ bidOp: selectedBidOp.value }}
              products={products}
              onFind={handleSdSuggestionLoad(false)}
              onChange={setTargetings}
            />
          )
        }
        {
          isNonEndemicAccount && (
            <>
              <SDAdSection
                adInfo={adInfo}
                onChange={handleAdInfoChange}
              />
              <SDCreativeSection
                adFormat="image"
                products={[]}
                basicInfo={basicInfo}
                onCrop={handleImageCrop}
                onChange={handleBasicInfoChange}
              />
            </>
          )
        }
      </>
    )
  }

  const renderSbContents = () => {
    if (
      (
        adFormat === AD_FORMAT_COLLECTION
        &&
        (
          (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE && products.length < 3)
          ||
          (landingPageType=== LANDING_PAGE_TYPE_STORE && !products.length)
        )
      )
      ||
      (
        adFormat === AD_FORMAT_SPOTLIGHT
        && subPages.length < 3
      )
      ||
      (
        adFormat === AD_FORMAT_VIDEO
        && !products.length
      )
    ) {
      return null
    }

    return (
      <>
        <TargetingSelector
          manualTarget={manualTarget}
          noAudience
          onChange={setManualTarget}
        />
        <SBTargetInputSection
          manualTarget={manualTarget}
          keywords={keywords}
          negativeKeywords={negativeKeywords}
          targetings={targetings}
          negativeTargetings={negativeTargetings}
          isSuggestionsLoading={isSuggestionsLoading}
          keywordSuggestions={sbKeywordSuggestions}
          categorySuggestions={sbCategorySuggestions}
          productSuggestions={sbProductSuggestions}
          onFind={handleSbSuggestionLoad}
          onKeywordsSelect={handleKeywordsSelect}
          onNksSelect={setNegativeKeywords}
          onTargetsSelect={setTargetings}
          onNtsSelect={setNegativeTargetings}
        />
        <SBCreativeSection
          adFormat={adFormat}
          landingPageType={landingPageType}
          goal={currentDetail.goal}
          basicInfo={basicInfo}
          products={products}
          creativeProducts={creativeProducts}
          subPages={subPages}
          creativeSubPages={creativeSubPages}
          onChange={handleBasicInfoChange}
          onProductOrderChange={setCreativeProducts}
          onSubPageChange={handleSubPageChange}
          onBrandLogoSelect={handleBrandLogoSelect}
          onCrop={handleImageCrop}
        />
      </>
    )
  }

  const renderSbSections = () => {
    return (
      <>
        <TargetingTypeSelector
          adType="sb"
          targetList={adFormatList}
          target={adFormat}
          onChange={handleAdFormatChange}
        />
        <SBLandingPageSection
          adFormat={adFormat}
          landingPageType={landingPageType}
          goal={currentDetail.goal}
          products={products}
          fromAdGroupCreator
          onLandingPageTypeChange={handleLandingPageTypeChange}
          onStorePageChange={handleStorePageChange}
          onStoreChange={handleStoreChange}
          onProductChange={handleSbProductsSelect}
        />
        <SBAdSection
          name={adName}
          onChange={setAdName}
        />
        { renderSbContents() }
      </>
    )
  }

  const renderContents = () => {
    if (currentDetail.campaignType === 'sp') {
      return renderSpSections()
    }

    if (currentDetail.campaignType === 'sd') {
      return renderSdSections()
    }

    return renderSbSections()
  }

  const renderCampaignSelector = () => {
    if (!campaigns.length) {
      return null
    }

    return (
      <div className="section-container">
        <div className="field-row">
          <div className="field-wrapper">
            <div className="field-name">
              Campaign
            </div>
            <Select
              components={{ Option: CampaignOption }}
              value={selectedCampaign}
              options={campaigns}
              getOptionLabel={record => record.campaign}
              getOptionValue={record => record.campaign_id}
              placeholder="Choose campaign"
              onChange={setSelectedCampaign}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderAdgroupContents = () => {
    if (!currentDetail) {
      return null
    }

    return (
      <>
        <div className="section-container">
          <div className="field-row">
            <AdgroupInfo
              name={name}
              onChange={setName}
            />
            { renderDefaultBid() }
          </div>
        </div>
        {
          currentDetail.campaignType === 'sd'
          && currentDetail.cost_type === 'cpc'
          && !isNonEndemicAccount
          && (
            <div className="section-container">
              <div className="field-row">
                <div className="field-wrapper">
                <div className="field-name">
                  Bid Optimization
                </div>
                <Select
                  value={selectedBidOp}
                  options={bidOpOptions}
                  placeholder="Choose bid optimization"
                  onChange={setSelectedBidOp}
                />
              </div>
              </div>
            </div>
          )
        }
        { renderProducts() }
        { renderContents() }
      </>
    )
  }

  const isLoading = isSuggestedBidsLoading
    || isCreating
    || isStorePageAsinsLoading
    || isLandingPageAsinsLoading

  return (
    <Modal
      className={`adgroup-creator-modal${isLoading ? ' loading' : ''}`}
      backdrop="static"
      size="lg"
      show
    >
      <Modal.Header onHide={() => { onCancel() }}>
        <Modal.Title>
          Create New Ad Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="creator-section">
        { isLoading && <LoaderComponent /> }
        { renderCampaignSelector() }
        { renderAdgroupContents() }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isCreating}
          onClick={handleCreate}
        >
          Create
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onCancel()}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddAdgroup
