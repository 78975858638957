import React from 'react'
import { 
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer 
} from 'recharts'

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 20;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  
  if (value === 0) {
    return null
  }

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name} ${value.toFixed(2)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const PieChartWidget = ({title, data}) => {
  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          <span>
            {title}
          </span>
        </div>
      </div>
      <div className="report-chart-widget-content">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart
            width={500}
            height={300}>
              <Pie
                activeIndex={data.map((_, index) => index)}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="47%"
                innerRadius={50}
                outerRadius={75}
                dataKey="value"
              >
                {(data || []).map(d => (
                  <Cell key={d.name} fill={d.color}/>
                ))}
              </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default PieChartWidget
