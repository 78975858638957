import { callGet, callPost } from '../../services/axios'

import {
  GET_SP_SUGGESTIONS_SUCCEED,
  GET_SD_SUGGESTIONS_SUCCEED,
} from '../actionTypes/campaignCreator'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

export const getSpSuggestions = (accessToken, asins, forTarget) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSpSuggestions', accessToken, {
    userId: currentUserId,
    asins: asins.join(','),
    forTarget,
  }).then((response) => {
    dispatch({
      type: GET_SP_SUGGESTIONS_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve SP suggestions.',
    })
  })
}

export const getSpBidSuggestions = (accessToken, keywords, asins, bidding) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/getSpBidSuggestions', {
    userId: currentUserId,
    keywords,
    asins,
    bidding,
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve suggested keyword bids.'
    })
    return []
  })
}

export const createSpCampaign = (accessToken, data) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/createSpCampaign', Object.assign({}, data, {
    userId: currentUserId,
  }), accessToken).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to create a campaign.')
  })
}

export const getSdSuggestions = (accessToken, tactic, asins, forTarget) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSdSuggestions', accessToken, {
    userId: currentUserId,
    tactic,
    asins: asins.join(','),
    forTarget,
  }).then((response) => {
    dispatch({
      type: GET_SD_SUGGESTIONS_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve suggestions.',
    })
  })
}

export const getAudiences = (accessToken, path) => async (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return await callGet('/campaignCreator/getAudiences', accessToken, {
    userId: currentUserId,
    path,
  }).then(response => response.data)
  .catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve SD audiences.',
    })
    return Promise.reject(error)
  })
}

export const createSdCampaign = (accessToken, data) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callPost('/campaignCreator/createSdCampaign', Object.assign({}, data, {
    userId: currentUserId,
  }), accessToken).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to create a campaign.')
  })
}

export const getSbBrands = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbBrands', accessToken, {
    userId: currentUserId
  }).then((response) => {
    return response.data.brandData
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get UserId.',
    })
  })
}

export const getSbBrandLogos = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbBrandLogos', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve logos.',
      })
    }
    return []
  })
}

export const getSbSuggestions = (accessToken, asins, forTarget) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbSuggestions', accessToken, {
    userId: currentUserId,
    asins: asins.join(','),
    forTarget,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get suggested keywords.',
    })
    return []
  })
}

export const getSbBidSuggestions = (accessToken, keywords, target) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbBidSuggestions', accessToken, {
    userId: currentUserId,
    keywords,
    adFormat: target
  }).then((response) => {
    return response.data.suggestedSBBids
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get suggested bids.',
    })
    return []
  })
}

export const getSdBidSuggestions = (accessToken, bidParams) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/getSdBidSuggestions', Object.assign({}, {
    userId: currentUserId,
    bidParams,
  }), accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve suggested bids.',
    })
  })
}

export const createSbCampaign = (accessToken, campaign, adFormat) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/createSbCampaign', {
    userId: currentUserId,
    campaign,
    adFormat,
  }, accessToken).then(() => {
    //
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to create a campaign.')
  })
}

export const getSbStores = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbStores', accessToken, {
    userId: currentUserId,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get storeInfo.',
    })
    return []
  })
}

export const getSbLandingPageAsins = (accessToken, pageUrl) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSbLandingPageAsins', accessToken, {
    userId: currentUserId,
    pageUrl,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get store information.',
    })
    return []
  })
}

export const getSbStoreAsins = (accessToken, pages) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/getSbStoreAsins', {
    userId: currentUserId,
    pages,
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get store pages.',
    })
    return []
  })
}

export const createAsset = (accessToken, creative) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/createAsset', {
    userId: currentUserId,
    creative,
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to upload an image.')
  })
}

export const createProductsInBulk = (accessToken, payloads, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignCreator/createProductsInBulk', {
    userId: currentUserId,
    payloads,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      toast.show({
        title: 'Success',
        description: `Added ${response.data.count} products successfully.`,
      })
      return true
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to add products.',
    })
    return false
  })
}

export const searchLocations = (accessToken, keyword) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/searchLocations', accessToken, {
    userId: currentUserId,
    keyword,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to search locations.',
    })
  })
}
