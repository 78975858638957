import React from 'react'
import { Modal } from 'rsuite'

const VideoModal = ({ title = '', showModal = false, onClose, videoList }) => (
  <Modal
    backdrop={true}
    className="video-popup-modal"
    show={showModal}
    size="lg"
    onClick={onClose}
    onHide={onClose}
    overflow={false}
  >
    <Modal.Header>
      <Modal.Title>
        { title }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="flow-video-list">
        {
          videoList.map(video => (
            <div className="flow-video-item" key={video.title}>
              <iframe title={video.title} src={video.url} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
            </div>
          ))
        }
      </div>
    </Modal.Body>
  </Modal>
)
export default VideoModal