import React from 'react'

import OpKeywordAdvancedSetting from './OpKeywordAdvancedSetting'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'

// Low CTR Targets
const OpKeywordCtr = ({ settings, onChange, ...props }) => (
  <>
    <OpKeywordAdvancedSetting
      isOriginal
      settings={settings}
      onChange={onChange}
      {...props}
    >
      <FieldRow>
        <FieldNumber
          label="Minimum impressions to trigger"
          field="op_adv_low_ctr_impressions"
          settings={settings}
          onChange={onChange}
        />
        <FieldNumber
          label="CTR targets (%)"
          suffix="or less"
          field="op_adv_low_ctr_ctr"
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
    </OpKeywordAdvancedSetting>
    {
      settings[`copy_${props.prefix}active`] && (
        <OpKeywordAdvancedSetting
          settings={settings}
          onChange={onChange}
          {...props}
        >
          <FieldRow>
            <FieldNumber
              label="Minimum impressions to trigger"
              field="copy_op_adv_low_ctr_impressions"
              settings={settings}
              onChange={onChange}
            />
            <FieldNumber
              label="CTR targets (%)"
              suffix="or less"
              field="copy_op_adv_low_ctr_ctr"
              settings={settings}
              onChange={onChange}
            />
          </FieldRow>
        </OpKeywordAdvancedSetting>
      )
    }
  </>
)

export default OpKeywordCtr
