import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import SortableTableComponent from '../../components/CommonComponents/SortableTableComponent'
import GroupTable from '../../components/CommonComponents/GroupTableComponent'
import { changeViewWidgetState } from '../../redux/actions/pageGlobal'

const ReportGroupbTableWidget = ({
  widget,
  columns,
  sortBy,
  sorter,
  sorterChild,
  records,
  idField,
  idFieldChild,
  searchFields,
  customizing,
  isLoading,
  section,
  onRemove,
  renderParent,
  renderTotalGroup,
  renderChild,
}) => {
  const dispatch = useDispatch()

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          { widget.title }
        </div>
        <div className="widget-action">
          {customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={widget.view ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )}
        </div>
      </div>
      <SortableTableComponent
        tableComponent={GroupTable}
        isLoading={isLoading}
        columns={columns}
        defaultSort={sortBy}
        sorter={sorter}
        className="group-table-report"
        records={records}
        idField={idField}
        searchFields={searchFields}
        noCheckBox
        renderRecord={renderParent}
        renderTotal={renderTotalGroup}
        sorterChild={sorterChild}
        idFieldChild={idFieldChild}
        renderChild={renderChild}
      />
    </div>
  )
}

export default ReportGroupbTableWidget
