import React from 'react'

const AdgroupInfo = ({ name, onChange }) => {
  let error = null
  if (!name) {
    error = 'Please enter an ad group name.'
  } else if (name.length > 255) {
    error = 'Ad group name should not be longer than 255 characters.'
  }

  return (
    <div className="field-wrapper">
      <div className="field-name">
        Ad Group Name
      </div>
      <input
        type="text"
        value={name}
        onChange={(event) => { onChange(event.target.value) }}
      />
      {
        error !== null && (
          <div className="warning-message">{ error }</div>
        )
      }
    </div>
  )
}

export default AdgroupInfo
