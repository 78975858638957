import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { toast } from '../ToastComponent/toast'

import { applyFilter } from '../../../redux/actions/pageGlobal'
import filterDef from '../../../utils/filterDef'

const TableFilterShower = ({ filterName, noTitle = false, onValidate }) => {
  const dispatch = useDispatch()
  const filterValues = useSelector(state => state.pageGlobal.filterValues)

  const handleRemove = (key, optionValue = null) => {
    const newValues = { ...(filterValues || {})[filterName] || {} }
    if (optionValue) {
      // If filter type is multi-select, this option value will be selected one.
      newValues[key] = newValues[key].filter(v => v.value !== optionValue)
    } else {
      delete newValues[key]
    }

    if (onValidate) {
      const error = onValidate(newValues)
      if (error) {
        toast.show({
          title: 'Warning',
          description: error,
        })
        return
      }
    }

    dispatch(applyFilter(filterName, newValues))
  }

  const values = (filterValues || {})[filterName] || {}
  const filterTags = []
  filterDef[filterName].forEach((filter) => {
    if (filter.type === 'select') {
      if ((values[filter.key] || '') !== '') {
        filterTags.push((
          <span key={filter.key}>
            { `${filter.label} = ${values[filter.key].label}` }
            <span className="close-icon" onClick={() => { handleRemove(filter.key) }}>
              &times;
            </span>
          </span>
        ))
      }
    } else if (filter.type === 'target_acos') {
      if (values[filter.key] === true) {
        filterTags.push((
          <span key={filter.key}>
            { filter.label }
            <span className="close-icon" onClick={() => { handleRemove(filter.key) }}>
              &times;
            </span>
          </span>
        ))
      }
    } else if (filter.type === 'multi-select') {
      if ((values[filter.key] || []).length) {
        values[filter.key].forEach(v => {
          filterTags.push((
            <span key={`${filter.key}-${v.value}`}>
              {`${filter.isNegative ? 'Not: ' : ''}${v.label}`}
              <span className="close-icon" onClick={() => { handleRemove(filter.key, v.value) }}>
                &times;
              </span>
            </span>
          ))
        })
      }
    } else {
      if ((values[`${filter.key}Min`] || '') !== '') {
        filterTags.push((
          <span key={`${filter.key}Min`}>
            { `${filter.label} >= ${values[`${filter.key}Min`]}` }
            <span className="close-icon" onClick={() => { handleRemove(`${filter.key}Min`) }}>
              &times;
            </span>
          </span>
        ))
      }

      if ((values[`${filter.key}Max`] || '') !== '') {
        filterTags.push((
          <span key={`${filter.key}Max`}>
            { `${filter.label} <= ${values[`${filter.key}Max`]}` }
            <span className="close-icon" onClick={() => { handleRemove(`${filter.key}Max`) }}>
              &times;
            </span>
          </span>
        ))
      }
    }
  })

  return (
    filterTags.length > 0 && (
      <div className="table-filter-shower">
        {
          !noTitle && (
            <span className="filter-title">
              Filters
            </span>
          )
        }
        <span className="filter-contents">
          { filterTags }
        </span>
      </div>
    )
  )
}

export default TableFilterShower
