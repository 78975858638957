import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { callGet } from '../../services/axios'
import { getISODate } from '../../services/helper'
import {
  GET_ALL_ACCOUNT_START,
  GET_ALL_ACCOUNT_SUCCEED,
  SET_SELECTED_USER_INFO,
  SET_CURRENCY_INFO,
  SET_DATE_RANGE,
} from '../actionTypes/header'

export const getAccounts = (accessToken) => (dispatch, getState) => {
  const {
    header: {
      currentStartDate,
      currentEndDate
    }
  } = getState()
  dispatch({
    type: GET_ALL_ACCOUNT_START,
  })
  callGet('/account/getAccounts', accessToken, {
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    dispatch({
      type: GET_ALL_ACCOUNT_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get all accounts.'
    })
  })
}

export const setSelectedUserInfo = (accessToken, data, noRefetch = false) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_USER_INFO,
    data,
  })

  if (!noRefetch) {
    dispatch(getAccounts(accessToken))
  }
}

export const setCurrencyInfo = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENCY_INFO,
    data,
  })
}

export const setDateRange = ({ startDate, endDate }) => (dispatch) => {
  dispatch({
    type: SET_DATE_RANGE,
    data: { startDate, endDate }
  })
}
