import React from 'react'
import {
  Grid,
  Row,
  Col,
  Icon,
  IconButton
} from 'rsuite'

const SectionHeader = ({
  title,
  showSection,
  customizingSection,
  onToggle,
  onCustomize,
  onSave = undefined,
}) => {
  return (
    <Grid fluid>
      <Row className="section-header">
        <Col xs={12} className="section-header-title">
          <div className="section-title">
            { title }
          </div>
          <div className="section-show-btn">
            <IconButton
              icon={showSection ? (
                <Icon icon="eye" />
              ) : (
                <Icon icon="eye-slash" />
              )}
              appearance="subtle"
              onClick={onToggle}
            />
          </div>
        </Col>
        <Col className={`section-actions${showSection ? '' : ' hide'}`} xs={12}>
          <button
            type="button"
            className={`btn ${customizingSection ? 'btn-green' : 'btn-blue'}`}
            onClick={() => onCustomize(p => !p)}
          >
            {
              customizingSection ? (
                <>
                  <Icon icon="check"/>
                  Done
                </>
              ) : 'Customize Section'
            }
          </button>
          {
            typeof onSave !== 'undefined' && (
              <button
                type="button"
                className="btn btn-blue"
                onClick={() => onSave()}
              >
                Save
              </button>
            )
          }
        </Col>
      </Row>
    </Grid>
  )
}

export default SectionHeader
