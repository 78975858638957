import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import CheckboxComponent from '../../components/CommonComponents/CheckboxComponent'
import Section from './Section'

import { saveNotification } from '../../redux/actions/auth'
import { selectCurrentAccount } from '../../redux/reducers/header'

const alertList = [
  { label: 'Monthly', value: 'monthly_alert' },
  { label: 'Weekly', value: 'weekly_alert' },
]

const SectionNotification = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)

  const [alertSettings, setAlertSettings] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  // Load current settings.
  useEffect(() => {
    setAlertSettings({
      monthly_alert: currentAccount?.monthly_alert || 0,
      weekly_alert: currentAccount?.weekly_alert || 0,
    })
  }, [currentAccount])

  const handleChange = value => (checked) => {
    const newSettings = { ...alertSettings }
    newSettings[value] = checked ? 1 : 0
    setAlertSettings(newSettings)
  }

  const handleSave = async () => {
    const { monthly_alert, weekly_alert } = alertSettings
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveNotification(accessToken, {
      monthlyAlert: monthly_alert || 0,
      weeklyAlert: weekly_alert || 0,
    }))
    setIsSaving(false)
  }

  return (
    <Section
      title="Notifications"
      note="Mark notifications that you would like to receive"
      isSaving={isSaving}
      bodyClass="section-notification"
    >
      {
        alertList.map(({ label, value }) => (
          <CheckboxComponent
            key={value}
            label={label}
            checked={alertSettings[value] || 0}
            onChange={handleChange(value)}
          />
        ))
      }
      <button
        type="button"
        className="btn btn-red"
        onClick={handleSave}
      >
        Save
      </button>
    </Section>
  )
}

export default SectionNotification
