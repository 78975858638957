import {
  GET_ALL_TAGS_START,
  GET_ALL_TAGS_SUCCEED,
  GET_ALL_TAGS_FAIL,
  GET_ALL_TAGS_FOR_CAMPAIGN_START,
  GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED,
  GET_ALL_TAGS_FOR_CAMPAIGN_FAIL,
  REMOVE_TAGS_FROM_CAMPAIGN_START,
  REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED,
  REMOVE_TAGS_FROM_CAMPAIGN_FAIL,
  GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED,
  CREATE_TAGS_FOR_CAMPAIGNS,
  DELETE_TAG_SUCCEED,
  SELECT_TAGS,
} from '../actionTypes/tag'

export const initialState = {
  isLoading: false,
  tags: [],
  isLoadingTagsForCampaign: false,
  tagsForCampaign: [],
  isRemovingTag: false,
  bulkTagsForCampaigns: null,
  selectedTags: [],
}

const tag = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TAGS_START:
      return {
        ...state,
        isLoading: true,
        tags: [],
      }
    case GET_ALL_TAGS_SUCCEED:

      return {
        ...state,
        isLoading: false,
        tags: action.data
      }
    case GET_ALL_TAGS_FAIL:
      return {
        ...state,
        isLoading: false,
        tags: [],
      }
    case GET_ALL_TAGS_FOR_CAMPAIGN_START:
      return {
        ...state,
        isLoadingTagsForCampaign: true,
        tagsForCampaign: []
      }
    case GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED:
      return {
        ...state,
        isLoadingTagsForCampaign: false,
        tagsForCampaign: action.data
      }
    case GET_ALL_TAGS_FOR_CAMPAIGN_FAIL:
      return {
        ...state,
        isLoadingTagsForCampaign: false,
        tagsForCampaign: [],
      }
    case REMOVE_TAGS_FROM_CAMPAIGN_START:
      return {
        ...state,
        isRemovingTag: true,
      }
    case REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED:
      return {
        ...state,
        isRemovingTag: false,
      }
    case REMOVE_TAGS_FROM_CAMPAIGN_FAIL:
      return {
        ...state,
        isRemovingTag: false,
      }
    case GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED:
      return {
        ...state,
        bulkTagsForCampaigns: action.data
      }
    case CREATE_TAGS_FOR_CAMPAIGNS:
      return {
        ...state,
        tags: [
          ...state.tags,
          {
            id: action.data.tagId,
            tag_name: action.data.tagName,
          }
        ],
      }
    case DELETE_TAG_SUCCEED:
      let b = {...state.bulkTagsForCampaigns}
      Object.keys(state.bulkTagsForCampaigns).forEach(campaign => {
        b[campaign] = b[campaign].filter(t => t !== action.data)
      })
      return {
        ...state,
        tags: state.tags.filter(t => t.id !== action.data),
        tagsForCampaign: state.tagsForCampaign.filter(t => t !== action.data),
        bulkTagsForCampaigns: b,
      }
    case SELECT_TAGS:
      return {
        ...state,
        selectedTags: action.data,
      }
    default:
      return state
  }
}

export default tag
