import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import FieldRow from './FieldRow'

const lookbackList = [
  { value: 14, label: 14 },
  { value: 30, label: 30 },
  { value: 60, label: 60 },
  { value: 90, label: 90 },
]

const Lookback = ({ disabled = false, field, settings, onChange }) => {
  const [useCustom, setUseCustom] = useState(false)

  useEffect(() => {
    const days = lookbackList.map(option => option.value)
    // If a custom day is saved in DB, enable an input box
    // to enter a custom day.
    if (days.indexOf(settings[field]) === -1
      && !useCustom) {
      setUseCustom(true)
    }
  }, [settings[field]]) // eslint-disable-line

  const lookback = lookbackList.find(option => (
    option.value === settings[field]
  ))

  const handleLookbackChange = (item) => {
    onChange(field, item.value)
  }

  const handleCustomCheck = (checked) => {
    if (!checked) {
      const days = lookbackList.map(option => option.value)
      if (days.indexOf(settings[field]) === -1) {
        // If a custom day entered doesn't match any of predefined values
        // and an user decides not to use a custom day, select
        // the first of predefined values by default.
        onChange(field, days[0])
      }
    }
    setUseCustom(checked)
  }

  const validateCustom = () => {
    if (settings[field] === ''
      || isNaN(settings[field])
      || parseFloat(settings[field]) < 1) {
      onChange(field, 1)
    }
  }

  return (
    <div className="lookback-wrapper">
      <FieldRow disabled={disabled}>
        <div className="field-wrapper">
          <div className="field-name">
            Lookback Period (days)
            <CustomTooltip>
              <p>The amount of historical days we'll use to make optimizations.</p>
              <p>By default, we add 3 days to this number for better data accuracy.</p>
              <p>For example: If you choose 30 days, we use data from 33 days ago up until 3 days ago.</p>
            </CustomTooltip>
          </div>
          <Select
            classNamePrefix="lookback-selector"
            options={lookbackList}
            placeholder="Choose period"
            isDisabled={useCustom}
            value={lookback}
            onChange={handleLookbackChange}
          />
        </div>
        <div className="field-wrapper">
          <div className="field-name">
            Custom Lookback Period (days)
            {
              useCustom && parseInt(settings[field] || 0, 10) < 14 && (
                <CustomTooltip>
                  <p>Basing automation changes on less than 14 days of data is
                  only recommended if you have an aggressive budget
                  with high ad spend.</p>
                  <p>Automation with too little data may cause unintended effects.</p>
                </CustomTooltip>
              )
            }
          </div>
          <div className="check-input-container">
            <CheckboxComponent
              checked={useCustom}
              onChange={handleCustomCheck}
            />
            <input
              type="number"
              min="0"
              value={useCustom ? settings[field] : ''}
              disabled={!useCustom}
              onChange={(event) => { onChange(field, event.target.value) }}
              onBlur={validateCustom}
            />
          </div>
        </div>
      </FieldRow>
    </div>
  )
}

export default Lookback
