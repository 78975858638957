import React from 'react'

const Footer = ({ isLoading, onApply, onClose }) => {
  return (
    <div className="pane-footer">
      <button
        type="button"
        className="btn btn-blue"
        disabled={isLoading}
        onClick={onApply}
      >
        Save Rules
      </button>
      <button
        type="button"
        className="btn btn-white"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  )
}

export default Footer
