// Smart pilot template editor pane footer.
import React, { useState } from 'react'
import { Modal } from 'rsuite'

const Footer = ({ isSaving, isSavable, isEditable,
  isDeletable, deleteForAll,
  onSave, onSaveAsAnother, onDelete, onRulesDelete, onClose }) => {
  const [type, setType] = useState()
  const [showConfirm, setShowConfirm] = useState(false)

  const handleAction = (actionType) => {
    if (!isEditable) {
      // For creating new templates.
      onSave()
      return
    }

    setType(actionType)
    setShowConfirm(true)
  }

  const handleSave = () => {
    if (type === 'save') {
      onSave()
    } else {
      onDelete()
    }
    setShowConfirm(false)
  }

  return (
    <div className="pane-footer">
      {
        isDeletable && (
          <button
            type="button"
            className="btn btn-yellow clear-link"
            onClick={onRulesDelete}
          >
            { deleteForAll ? 'Remove All Existing Rules' : 'Remove Selected Rules' }
          </button>
        )
      }
      {
        isEditable && (
          <>
            <button
              type="button"
              className="btn btn-red"
              disabled={isSaving}
              onClick={() => { handleAction('delete') }}
            >
              Delete Template
            </button>
            <button
              type="button"
              className="btn btn-green"
              disabled={isSaving || !isSavable}
              onClick={onSaveAsAnother}
            >
              Save as New
            </button>
          </>
        )
      }
      <button
        type="button"
        className="btn btn-blue"
        disabled={isSaving}
        onClick={() => { handleAction('save') }}
      >
        Save Rules
      </button>
      <button
        type="button"
        className="btn btn-white"
        disabled={isSaving}
        onClick={onClose}
      >
        Close
      </button>
      <Modal backdrop="static" show={showConfirm} size="xs">
        <Modal.Body>
          Are you sure you want to { type === 'save' ? 'update' : 'delete' } the template?
          It will affect campaigns that the template is applied to.
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="rs-btn rs-btn-primary" onClick={handleSave}>
            Yes
          </button>
          <button type="button" className="rs-btn rs-btn-subtle" onClick={() => { setShowConfirm(false) }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Footer
