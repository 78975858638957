import React, { useState } from 'react'
import { Dropdown, Modal } from 'rsuite'

import LoaderComponent from '../CommonComponents/LoaderComponent'

const Footer = ({ isLoading, isSavable, isDeletable, deleteForAll, hasTemplates,
  onApply, onApplyToMultiple, onSaveTemplate, onTemplateChange, onDelete, onClose }) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [needApply, setNeedApply] = useState(false)

  const handleTemplateSaveOpen = apply => () => {
    setTemplateName('')
    setNeedApply(apply)
    setShowTemplateModal(true)
  }

  const handleTemplateSave = () => {
    onSaveTemplate(templateName, needApply).then(() => {
      setShowTemplateModal(false)
    })
  }

  return (
    <div className="pane-footer">
      <div className="clear-link">
        {
          isDeletable && (
            <button
              type="button"
              className="btn btn-light-green"
              onClick={onDelete}
            >
              { deleteForAll ? 'Remove All Existing Rules' : 'Remove Selected Rules' }
            </button>
          )
        }
        {
          hasTemplates && (
            <button
              type="button"
              className="btn btn-yellow"
              onClick={() => { onTemplateChange([]) }}
            >
              Remove Template
            </button>
          )
        }
      </div>
      <Dropdown
        title="More actions"
        placement="topStart"
        disabled={isLoading || !isSavable}
      >
        <Dropdown.Item onSelect={onApplyToMultiple}>
          Apply to multiple campaigns
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleTemplateSaveOpen(false)}>
          Save as template
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleTemplateSaveOpen(true)}>
          Save as template and apply
        </Dropdown.Item>
      </Dropdown>
      <button
        type="button"
        className="btn btn-blue"
        disabled={isLoading}
        onClick={onApply}
      >
        Save Rules
      </button>
      <button
        type="button"
        className="btn btn-white"
        onClick={onClose}
      >
        Close
      </button>
      <Modal backdrop="static" show={showTemplateModal} size="xs">
        <Modal.Body>
          { isLoading && <LoaderComponent /> }
          <input
            type="text"
            className="input-template-name"
            value={templateName}
            placeholder="Enter a template name"
            onChange={(event) => { setTemplateName(event.target.value) }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="rs-btn rs-btn-primary"
            disabled={templateName === '' || isLoading}
            onClick={handleTemplateSave}
          >
            Save
          </button>
          <button
            type="button"
            className="rs-btn rs-btn-subtle"
            disabled={isLoading}
            onClick={() => { setShowTemplateModal(false) }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Footer
