import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import LoomVideoCollection from './LoomVideoCollection'

const topAttachments = [
  { name: 'Entourage 2.0 Playbook.pdf', url: 'https://go.ppcentourage.com/hubfs/Amazon%20Ads%20Playbook%20Series%20V2%202021.pdf?hsLang=en' },
]

const attachments = [
  { name: 'Customer Buying Cycle Cheat Sheet.csv', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=631974858' },
  { name: 'Keyword Cheat Sheet.csv', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=81687217' },
  { name: 'ASIN Finder Cheat Sheet.csv', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=389486051' },
  { name: 'Audience Finder Cheat Sheet.csv', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=1641638220' },
  { name: 'Headline Creator.csv', url: 'https://ppcentourage.com/spotlight-headlines/' },
]

const videoList = [
  { name: 'How to Use This Playbook', videoId: '1203615d7c3a4c8e82a4a027c16f2607' },
  {
    name: 'Where will my ads show up?',
    videoId: '4f620e408243488db0b02ca0b644033c',
    thumbnail: 'https://cdn.loom.com/sessions/thumbnails/public/7b11a7a144f644fe8f4a85e42c5a94af-1594550430064-with-play.gif',
  },
  { name: 'Ad Type Overview', videoId: '9a40227ecf454d229c35128f69c2c4c0' },
  { name: 'Customer Buying Cycle', videoId: '1412ba5582b541e68e37941581eccf76' },
]

const EntourPlaybookSeries = ({ ...props }) => {
  return (
    <FlowContainer
      name="Entourage 2.0 Playbook Series"
      {...props}
    >
      <AttachmentList attachments={topAttachments} />
      <AttachmentList attachments={attachments} />
      <LoomVideoCollection videoList={videoList} />
    </FlowContainer>
  )
}

export default EntourPlaybookSeries
