/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { InputNumber, DatePicker } from 'rsuite'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  RULE_TYPE_EXCLUDE_DATA,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
  RULE_MODIFIER_ACTION_SET,
} from '../../utils/defaultValues'
import { getISODate, parseDate } from '../../services/helper'

export const ruleTypeList = [
  { value: RULE_TYPE_BID, label: 'Bid' },
  { value: RULE_TYPE_CAMPAIGN, label: 'Campaign budget/status' },
  { value: RULE_TYPE_TOS_MODIFIER, label: 'Top of Search modifier' },
  { value: RULE_TYPE_PRODUCT_MODIFIER, label: 'Product Pages modifier' },
  { value: RULE_TYPE_REST_MODIFIER, label: 'Rest of the Search modifier' },
  // { value: RULE_TYPE_EXCLUDE_DATA, label: 'Exclude Data' },
]

const RuleEntry = ({ rule, hasSpCampaign, isReadOnly, onChange, onRemove }) => {
  const [selectedType, setSelectedType] = useState(null)
  const [selectedAction, setSelectedAction] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [isRampUp, setIsRampUp] = useState(false)
  const [isRampDown, setIsRampDown] = useState(false)
  const [rampUpDate, setRampUpDate] = useState(null)
  const [rampDownDate, setRampDownDate] = useState(null)

  const filteredTypeList = useMemo(() => {
    if (hasSpCampaign) {
      return ruleTypeList
    }
    return ruleTypeList.filter(type =>
      ![
        RULE_TYPE_TOS_MODIFIER,
        RULE_TYPE_PRODUCT_MODIFIER,
        RULE_TYPE_REST_MODIFIER,
      ].includes(type.value)
    )
  }, [hasSpCampaign])

  useEffect(() => {
    if (!rule.t) {
      return
    }

    setSelectedType(ruleTypeList.find(record => (
      record.value === rule.t
    )))

    let action = null
    if (rule.t === RULE_TYPE_BID) {
      action = ruleBidActionList.find(record => (
        record.value === rule.a
      ))
    } else if (rule.t === RULE_TYPE_CAMPAIGN) {
      action = ruleCampaignActionList.find(record => (
        record.value === rule.a
      ))
    } else {
      action = ruleModifierActionList.find(record => (
        record.value === rule.a
      ))
    }
    setSelectedAction(action)

    setSelectedValue(rule.v || null)

    if (rule.rampUp) {
      setIsRampUp(true)
      setRampUpDate(parseDate(rule.rampUp))
    }

    if (rule.rampDown) {
      setIsRampDown(true)
      setRampDownDate(parseDate(rule.rampDown))
    }
  }, [rule])

  useEffect(() => {
    if (!selectedType
      || (selectedType.value !== RULE_TYPE_EXCLUDE_DATA && !selectedAction)) {
      return
    }

    if (!selectedValue) {
      if (
        (
          selectedType.value === RULE_TYPE_BID
          && selectedAction.value !== RULE_BID_ACTION_PAUSE
        )
        ||
        (
          selectedType.value === RULE_TYPE_CAMPAIGN
          && selectedAction.value !== RULE_CAMPAIGN_ACTION_PAUSE
        )
        || selectedType.value === RULE_TYPE_TOS_MODIFIER
        || selectedType.value === RULE_TYPE_PRODUCT_MODIFIER
        || selectedType.value === RULE_TYPE_REST_MODIFIER
      ) {
        return
      }
    }

    if ((isRampUp && !rampUpDate)
      || (isRampDown && !rampDownDate)) {
      return
    }

    const values = {
      t: selectedType.value,
    }

    if (selectedType.value !== RULE_TYPE_EXCLUDE_DATA) {
      values.a = selectedAction.value
    }

    if (
      (
        selectedType.value === RULE_TYPE_BID
        && selectedAction.value !== RULE_BID_ACTION_PAUSE
      )
      ||
      (
        selectedType.value === RULE_TYPE_CAMPAIGN
        && selectedAction.value !== RULE_CAMPAIGN_ACTION_PAUSE
      )
      || selectedType.value === RULE_TYPE_TOS_MODIFIER
      || selectedType.value === RULE_TYPE_PRODUCT_MODIFIER
      || selectedType.value === RULE_TYPE_REST_MODIFIER
    ) {
      values.v = parseFloat(selectedValue)
    }

    if (isRampUp) {
      values.rampUp = getISODate(rampUpDate)
    }

    if (isRampDown) {
      values.rampDown = getISODate(rampDownDate)
    }

    if (rule.t) {
      const ruleWithoutId = Object.assign({}, rule)
      delete ruleWithoutId.id
      if (JSON.stringify(values) === JSON.stringify(ruleWithoutId)) {
        return
      }
    }

    onChange(values)
  }, [rule, selectedType, selectedAction, selectedValue,
    isRampUp, rampUpDate, isRampDown, rampDownDate, onChange])

  const handleTypeChange = (value) => {
    setSelectedType(value)
    setSelectedAction(null)
    setSelectedValue(null)
  }

  const handleRemove = (event) => {
    event.preventDefault()
    onRemove()
  }

  const renderAction = () => {
    if (!selectedType || selectedType.value === RULE_TYPE_EXCLUDE_DATA) {
      return null
    }

    let options
    if (selectedType.value === RULE_TYPE_BID) {
      options = ruleBidActionList
    } else if (selectedType.value === RULE_TYPE_CAMPAIGN) {
      options = ruleCampaignActionList
    } else {
      options = ruleModifierActionList
    }

    return (
      <Select
        className="rule-selector"
        classNamePrefix="rule-select"
        options={options}
        placeholder="Select action"
        isDisabled={isReadOnly}
        value={selectedAction}
        onChange={setSelectedAction}
      />
    )
  }

  const renderValue = () => {
    if (!selectedType || !selectedAction) {
      return null
    }

    if (
      (
        selectedType.value === RULE_TYPE_BID
        && selectedAction.value === RULE_BID_ACTION_PAUSE
      )
      || (
        selectedType.value === RULE_TYPE_CAMPAIGN
        && selectedAction.value === RULE_CAMPAIGN_ACTION_PAUSE
      )
    ) {
      return null
    }

    return (
      <InputNumber
        className="value-input"
        min={0}
        placeholder="Set value"
        postfix="%"
        disabled={isReadOnly}
        value={selectedValue}
        onChange={setSelectedValue}
      />
    )
  }

  const renderRamp = () => {
    if (
      !selectedType
      || !selectedAction
      || selectedType.value === RULE_TYPE_EXCLUDE_DATA
      || (
        selectedType.value === RULE_TYPE_BID
        && selectedAction.value === RULE_BID_ACTION_PAUSE
      )
      || (
        selectedType.value === RULE_TYPE_CAMPAIGN
        && selectedAction.value === RULE_CAMPAIGN_ACTION_PAUSE
      )
      || (
        [
          RULE_TYPE_TOS_MODIFIER,
          RULE_TYPE_PRODUCT_MODIFIER,
          RULE_TYPE_REST_MODIFIER,
        ].includes(selectedType.value)
        && selectedAction.value === RULE_MODIFIER_ACTION_SET
      )
    ) {
      return null
    }

    return (
      <>
        <div className="rule-entry-ramp">
          <CheckboxComponent
            label="Ramp Up"
            disabled={isReadOnly}
            checked={isRampUp}
            onChange={setIsRampUp}
          />
          <CustomTooltip placement="right">
            <p>
              Choose a date before the start of the time frame above
              and we’ll slowly ramp changes until the start of the rule date.
            </p>
          </CustomTooltip>
          {
            isRampUp && (
              <DatePicker
                format="MMM D, YYYY"
                oneTap
                disabled={isReadOnly}
                value={rampUpDate}
                onChange={setRampUpDate}
              />
            )
          }
        </div>
        <div className="rule-entry-ramp">
          <CheckboxComponent
            label="Ramp Down"
            disabled={isReadOnly}
            checked={isRampDown}
            onChange={setIsRampDown}
          />
          <CustomTooltip placement="right">
            <p>
              Choose a date after the end of the date range above
              and we’ll gradually ramp down.
            </p>
            <p>If this box is left unchecked, we’ll end exactly on the date set above.</p>
          </CustomTooltip>
          {
            isRampDown && (
              <DatePicker
                format="MMM D, YYYY"
                oneTap
                disabled={isReadOnly}
                value={rampDownDate}
                onChange={setRampDownDate}
              />
            )
          }
        </div>
      </>
    )
  }

  return (
    <div className="rule-entry-wrapper">
      <div className="rule-entry-custom">
        <div className="rule-entry-settings">
          <Select
            className="rule-selector"
            classNamePrefix="rule-select"
            options={filteredTypeList}
            placeholder="Select rule"
            isDisabled={isReadOnly}
            value={selectedType}
            onChange={handleTypeChange}
          />
          {
            selectedType !== null && selectedType.value === RULE_TYPE_EXCLUDE_DATA && (
              <CustomTooltip placement="right">
                <p>Exclude data from selected date ranges.</p>
                <p>Smart Pilot will not include data from the selected time frame.</p>
              </CustomTooltip>
            )
          }
          { renderAction() }
          { renderValue() }
        </div>
        {
          !isReadOnly && (
            <a
              href="#"
              className="remove-rule-link"
              onClick={handleRemove}
            >
              Remove rule
            </a>
          )
        }
      </div>
      { renderRamp() }
    </div>
  )
}

export default RuleEntry
