import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { addDays, differenceInDays, format, startOfDay } from 'date-fns'

import { formatValue, parseDate } from '../../../services/helper'

const COLOR_RED = { stroke: '#EA305C', fill: '#colorUr' }
const COLOR_GREEN = { stroke: '#93DF8D', fill: '#colorUg' }

const parseChartData = (data, startDate, endDate) => {
  const newChartData = []

  const start = startOfDay(parseDate(startDate))
  const period = differenceInDays(startOfDay(parseDate(endDate)), start)

  let minValue = 0
  let maxValue = 0
  let startPoint
  let endPoint
  for (let index = 0; index <= period; index += 1) {
    const date = format(addDays(start, index), 'yyyy/MM/dd')
    const itemIndex = data.findIndex(item =>
      format(parseDate(item.date), 'yyyy/MM/dd') === date
    )

    let value = 0
    if (itemIndex !== -1) {
      value = parseFloat(data[itemIndex].value)
      if (typeof startPoint === 'undefined') {
        startPoint = value
      }
      endPoint = value
    }

    if (minValue > value) {
      minValue = value
    }
    if (maxValue < value) {
      maxValue = value
    }

    newChartData.push({
      value,
      date,
    })
  }

  return {
    newChartData,
    minValue,
    maxValue,
    startPoint,
    endPoint,
  }
}

const TrendingChart = ({ metricKey, chartData, showXAxis, showYAxis, showToolTip, startDate, endDate }) => {
  const {
    newChartData,
    minValue,
    maxValue,
    startPoint,
    endPoint,
  } = parseChartData(chartData, startDate, endDate)

  const formatter = (value) => {
    if (['orders', 'impressions', 'clicks', 'ntb_orders', 'viewable_impressions'].includes(metricKey)) {
      return formatValue(value, 'removeZeroDecimal')
    } else if (['acos', 'ctr', 'conversion', 'ntb_orders_percent', 'ntb_sales_percent'].includes(metricKey)) {
      return formatValue(value, 'percent')
    } else if (['revenue', 'cost', 'cpc', 'ntb_sales', 'daily_budget'].includes(metricKey)) {
      return formatValue(value, 'number')
    } else if (metricKey === 'roas') {
      return formatValue(value, 'number')
    } else if (metricKey === 'st_impr_rank') {
      if (parseFloat(value)) {
        return formatValue(Math.ceil(parseFloat(value)), 'removeZeroDecimal')
      }
    } else if (metricKey === 'st_impr_share') {
      if (parseFloat(value)) {
        return formatValue(value, 'percent')
      }
    }
    return value
  }

  let color
  const diff = endPoint - (startPoint || 0)
  if (metricKey === 'cpc' || metricKey === 'acos') {
    color = diff > 0 ? COLOR_RED : COLOR_GREEN
  } else {
    color = diff > 0 ? COLOR_GREEN : COLOR_RED
  }

  return (
    <ResponsiveContainer minWidth="100px" minHeight="54px">
      <AreaChart data={newChartData}>
        <defs>
          <linearGradient id="colorUr" x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#EA305C' stopOpacity={0.8} />
            <stop offset='95%' stopColor='#EA305C' stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorUg" x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#93DF8D' stopOpacity={0.8} />
            <stop offset='95%' stopColor='#93DF8D' stopOpacity={0} />
          </linearGradient>
        </defs>
        {
          showXAxis && <XAxis dataKey="date" />
        }
        {
          showYAxis && <YAxis dataKey="value" domain={[minValue, maxValue]} />
        }
        {
          showToolTip && <Tooltip formatter={formatter} />
        }
        <Area
          type='monotone'
          dataKey='value'
          stroke={color['stroke']}
          fillOpacity={1}
          fill={`url(${color['fill']})`}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default TrendingChart
