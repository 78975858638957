import { callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  FIND_TARGETS_SUCCEED,
  ADJUST_KEYWORD_BIDS_SUCCEED,
  ADJUST_TARGET_BIDS_SUCCEED,
  FIND_DUPS_SUCCEED,
  UPDATE_KEYWORD_STATES_SUCCEED,
  UPDATE_TARGET_STATES_SUCCEED,
  GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED,
  GET_SKU_OP_SUCCEED,
  UPDATE_PA_STATES_SUCCEED,
  GET_TARGET_OP_SUCCEED,
  GET_ST_OP_SUCCEED,
  ADD_NEGATIVES_OP_SUCCEED,
  GET_NEGATIVE_FINDER_SUCCEED,
  GET_PLACEMENT_OP_SUCCEED,
  GET_ADVANCED_NEGATIVE_SUCCEED,
  ARCHIVE_NKS_SUCCEED,
  ARCHIVE_NTS_SUCCEED,
  CREATE_AD_GROUP_SUCCEED,
  GET_ASIN_IMAGES_SUCCEED,
} from '../actionTypes/bulkEngine'

// For target search tool
export const findTargets = (accessToken, keyword, startDate, endDate, includeZeroSpend) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getTargetSearch', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    keyword,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    includeZeroSpend,
  }, accessToken)
}

export const saveFoundTargets = (data) => (dispatch) => {
  dispatch({
    type: FIND_TARGETS_SUCCEED,
    data,
  })
}

export const getTargetCharts = (accessToken, keyword, startDate, endDate, type) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getTargetChart', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    keyword,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
    type,
  }, accessToken).then((response) => {
    return response.data
  })
}

export const updateKeywordBids = (accessToken, keywords, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/updateKeywordBids', {
    userId: currentUserId,
    keywords,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processKeywordBidsUpdate(response.data, keywords))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update keyword bids.',
    })

    return []
  })
}

export const processKeywordBidsUpdate = (response, keywords) => (dispatch) => {
  if (!response.errors.length) {
    toast.show({
      title: 'Success',
      description: 'Updated keyword bids successfully.',
    })
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to update bids for following keywords:<br /><ul>'
        + response.errors.map(error => `<li>${error}</li>`).join('')
        + '</ul>',
    })
  }

  dispatch({
    type: ADJUST_KEYWORD_BIDS_SUCCEED,
    data: response.ids,
    payload: keywords,
  })

  return response.ids
}

export const updateTargetBids = (accessToken, targets, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/updateTargetBids', {
    userId: currentUserId,
    targets,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processTargetBidsUpdate(response.data, targets))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update target bids.',
    })

    return []
  })
}

export const processTargetBidsUpdate = (response, targets) => (dispatch) => {
  if (!response.errors.length) {
    toast.show({
      title: 'Success',
      description: 'Updated target bids successfully.',
    })
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to update bids for following targets:<br /><ul>'
        + response.errors.map(error => `<li>${error}</li>`).join('')
        + '</ul>',
    })
  }

  dispatch({
    type: ADJUST_TARGET_BIDS_SUCCEED,
    data: response.ids,
    payload: targets,
  })

  return response.ids
}

export const findDups = (accessToken, startDate, endDate) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getDuplicate', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
  }, accessToken)
}

export const saveFoundDuplicates = (data) => (dispatch) => {
  dispatch({
    type: FIND_DUPS_SUCCEED,
    data,
  })
}

export const updateKeywordStates = (accessToken, keywords, state, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/updateKeywordStates', {
    userId: currentUserId,
    keywords,
    state,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processKeywordStatesUpdate(response.data, state))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update keyword states.',
    })

    return []
  })
}

export const processKeywordStatesUpdate = (response, state) => (dispatch) => {
  if (response.keywordIds.length) {
    toast.show({
      title: 'Success',
      description: 'Updated keyword states successfully.',
    })

    dispatch({
      type: UPDATE_KEYWORD_STATES_SUCCEED,
      data: response.keywordIds,
      state,
    })

    return response.keywordIds
  }

  let errorText = 'Failed to update keyword states.'
  if (response.errors.length) {
    errorText = response.errors.join('<br />')
  }

  toast.show({
    title: 'Danger',
    description: errorText,
  })

  return []
}

export const updateTargetStates = (accessToken, targets, state, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/updateTargetStates', {
    userId: currentUserId,
    targets,
    state,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processTargetStatesUpdate(response.data, state))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update target states.',
    })
  })
}

export const processTargetStatesUpdate = (response, state) => (dispatch, getState) => {
  if (response.length) {
    toast.show({
      title: 'Success',
      description: 'Updated target states successfully.',
    })

    dispatch({
      type: UPDATE_TARGET_STATES_SUCCEED,
      data: response,
      state,
    })

    return response
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to update target states.',
    })
  }
}

export const findNewTargets = (accessToken, targets) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getTargetExData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    targets,
  }, accessToken)
}

export const getAdgroupsToAddTargets = (accessToken, payload, targetingOnly) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/getAdgroupsToAddTargets', {
    userId: currentUserId,
    payload,
    targetingOnly,
  }, accessToken).then((response) => {
    dispatch({
      type: GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED,
      data: response.data,
    })
  }).catch(() => {
    //
  })
}

export const addTargets = (accessToken, payload, forCategory = false,
  audience = [], lookback = 0, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/createTargets', {
    userId: currentUserId,
    payload,
    forCategory,
    audience,
    lookback,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return processTargetsAdd(response.data)
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to add targets.',
    })

    return false
  })
}

export const processTargetsAdd = (response) => {
  if (!response.errors.length) {
    toast.show({
      title: 'Success',
      description: `Added ${response.count} keyword/target${response.count > 1 ? 's' : ''} successfully.`,
    })

    return true
  }

  toast.show({
    title: 'Danger',
    description: 'Failed to add targets:<br /><ul>'
      + response.errors.map(error => `<li>${error}</li>`).join('')
      + '</ul>',
  })
  return false
}

export const findSts = (accessToken, startDate, endDate, acosStart, acosEnd, stOnly = true) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getStExData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    acosStart,
    acosEnd,
    stOnly,
  }, accessToken)
}

export const findPts = (accessToken, startDate, endDate, acosStart, acosEnd, typeTarget, newAsinOnly) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getPtExData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    acosStart,
    acosEnd,
    typeTarget,
    newAsinOnly,
  }, accessToken)
}

export const getAdgroupsForCampaigns = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/getAdgroupsForCampaigns', {
    userId: currentUserId,
    campaignIds,
  }, accessToken).then((response) => {
    return response.data
  })
}

// For SKU Op
export const getSkuOpData = (accessToken, startDate, endDate) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getSkuOpData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
  }, accessToken)
}

export const saveSkuOpData = (data) => (dispatch) => {
  dispatch({
    type: GET_SKU_OP_SUCCEED,
    data,
  })
}

export const updatePaStates = (accessToken, pas, state, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/updatePaStates', {
    userId: currentUserId,
    pas,
    state,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processPaStatesUpdate(response.data, state))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update product ad states.',
    })

    return []
  })
}

export const processPaStatesUpdate = (response, state) => (dispatch) => {
  if (response.length) {
    toast.show({
      title: 'Success',
      description: 'Updated successfully.',
    })

    dispatch({
      type: UPDATE_PA_STATES_SUCCEED,
      data: response,
      state,
    })

    return response
  }

  toast.show({
    title: 'Danger',
    description: 'Failed to update product ad states.',
  })

  return []
}

// For target Op
export const getTargetOpData = (accessToken, startDate, endDate, action = '') => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getBidOpData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    action,
  }, accessToken)
}

export const saveBidOpData = (data) => (dispatch) => {
  dispatch({
    type: GET_TARGET_OP_SUCCEED,
    data,
  })
}

// For ST Op
export const getStOpData = (accessToken, startDate, endDate, newTermOnly = false) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getStOpData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    newTermOnly,
  }, accessToken)
}

export const saveStOpData = (data) => (dispatch) => {
  dispatch({
    type: GET_ST_OP_SUCCEED,
    data,
  })
}

export const createNegatives = (accessToken, negativeKeywords,
  negativeTargets, targetLevel, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/createNegatives', {
    userId: currentUserId,
    negativeKeywords,
    negativeTargets,
    targetLevel,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processNegativesCreate(response.data))
      return true
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to add negatives.',
    })

    return false
  })
}

export const processNegativesCreate = (response) => (dispatch) => {
  dispatch({
    type: ADD_NEGATIVES_OP_SUCCEED,
    data: response,
  })

  toast.show({
    title: 'Success',
    description: `Added ${response.count} negative${response.count > 1 ? 's' : ''} successfully.`,
  })

  return true
}

// For negative finder
export const getNegativeFinderData = (accessToken, startDate, endDate, targetAcos, hideKeywords) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getNegativeData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
    targetAcos,
    stOnly: hideKeywords,
  }, accessToken)
}

export const saveNegativeFinderData = (data) => (dispatch) => {
  dispatch({
    type: GET_NEGATIVE_FINDER_SUCCEED,
    data,
  })
}

// For placement op
export const getPlacementOpData = (accessToken, startDate, endDate) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getPlacementOpData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
    startDate: startDate ? getISODate(startDate) : null,
    endDate: endDate ? getISODate(endDate) : null,
  }, accessToken)
}

export const savePlacementOpData = (data) => (dispatch) => {
  dispatch({
    type: GET_PLACEMENT_OP_SUCCEED,
    data,
  })
}

// For advanced op
export const getAdvancedNegativeData = (accessToken) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    campaign: { campaignIdsSelected },
  } = getState()

  return callPost('/bulkEngine/getAdvancedNegativeData', {
    userId: currentUserId,
    campaignIds: campaignIdsSelected,
  }, accessToken)
}

export const saveAdvancedNegativeData = (data) => (dispatch) => {
  dispatch({
    type: GET_ADVANCED_NEGATIVE_SUCCEED,
    data,
  })
}

export const archiveNks = (accessToken, negatives) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/archiveNegativeKeywords', {
    userId: currentUserId,
    negatives,
  }, accessToken).then((response) => {
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to remove negative keywords.',
    })
  })
}

export const processNksArchive = (response) => (dispatch) => {
  if (response.length) {
    toast.show({
      title: 'Success',
      description: 'Removed negative keywords successfully.',
    })

    dispatch({
      type: ARCHIVE_NKS_SUCCEED,
      data: response,
    })
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to remove negative keywords.',
    })
  }
}

export const archiveNegativeTargets = (accessToken, negatives) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/archiveNegativeTargets', {
    userId: currentUserId,
    negatives,
  }, accessToken).then((response) => {
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to remove negative targets.',
    })
  })
}

export const processNtsArchive = (response) => (dispatch) => {
  if (response.length) {
    toast.show({
      title: 'Success',
      description: 'Remove negative targets successfully.',
    })

    dispatch({
      type: ARCHIVE_NTS_SUCCEED,
      data: response,
    })
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to remove negative targets.',
    })
  }
}

export const createAdgroup = (accessToken, campaignId, campaignType, name,
  defaultBid, bidOptimization, tactic, products,
  keywords = [], nks = [], targets = [], nts = [],
  locations = [], creative = [], forNonEndemic = false, adFormat, sbAd) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/createAdgroup', {
    userId: currentUserId,
    campaignId,
    campaignType,
    name,
    defaultBid,
    bidOptimization,
    tactic,
    products,
    keywords,
    nks,
    targets,
    nts,
    locations,
    creative,
    forNonEndemic,
    adFormat,
    sbAd,
  }, accessToken).then((response) => {
    if (response.data.adgroup_id) {
      toast.show({
        title: 'Success',
        description: 'Created successfully.',
      })

      dispatch({
        type: CREATE_AD_GROUP_SUCCEED,
        data: response.data,
      })
    } else {
      toast.show({
        title: 'Danger',
        description: response.data.text || 'Failed to create ad group.',
      })
    }
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to create ad group.',
    })
  })
}

export const getAsinImages = (accessToken, asins, module) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/bulkEngine/getAsinImages', {
    userId: currentUserId,
    asins,
  }, accessToken).then((response) => {
    dispatch({
      type: GET_ASIN_IMAGES_SUCCEED,
      data: {
        imageMap: response.data,
        module,
      },
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve ASIN images.',
    })
  })
}
