import React, { useState } from 'react'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import SBProductsOrderModal from './SBProductsOrderModal'

const SBCreativeProducts = ({ products, creativeProducts, onChange }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleChange = (orderedProducts) => {
    setOpenModal(false)
    onChange(orderedProducts)
  }

  return (
    <div className="customizer-section">
      <div className="field-section-name">
        Products
        <CustomTooltip placement="right">
          <p>Change or reorder products.</p>
        </CustomTooltip>
      </div>
      <div className="field-section-contents">
        <div className="product-thumbnail-list">
          {
            creativeProducts.map(product => (
              <div key={product.id} className="product-thumbnail-wrapper">
                <img src={product.image} alt={product.name} />
              </div>
            ))
          }
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { setOpenModal(true) }}
          >
            Change Order
          </button>
        </div>
      </div>
      {
        openModal && (
          <SBProductsOrderModal
            products={products}
            creativeProducts={creativeProducts}
            onChange={handleChange}
            onClose={() => { setOpenModal(false) }}
          />
        )
      }
    </div>
  )
}

export default SBCreativeProducts
