import React, { useMemo, useState } from 'react'
import Select, { components }  from 'react-select'

const getCampaignName = (campaign) => {
  return `${campaign.name} (${campaign.type.toUpperCase()})`
}

const Option = (props) => {
  const { innerRef, innerProps, getStyles, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
    >
      { getCampaignName(data) }
    </div>
  )
}

const LoadingIndicator = (props) => {
  return (
    <components.ValueContainer {...props}>
      Campaigns loading. Please wait...
    </components.ValueContainer>
  )
}

// https://github.com/JedWatson/react-select/issues/4170#issuecomment-682465724
const ValueContainer = (props) => {
  const { options, children, getValue } = props
  const selectCount = getValue().length
  let contents = children
  if (selectCount > 0) {
    if (selectCount === options.length) {
      contents = (
        <>
          All campaigns selected
          { children[1] }
        </>
      )
    } else if (selectCount >= 10) {
      contents = (
        <>
          { selectCount } campaigns selected
          { children[1] }
        </>
      )
    }
  }
  return (
    <components.ValueContainer {...props}>
      { contents }
    </components.ValueContainer>
  )
}

const CampaignSelector = ({ campaigns, selectedCampaignIds,
  isLoaded = true, isLoading, isMulti = true, onChange }) => {
  const [keyword, setKeyword] = useState('')

  const selection = useMemo(() => {
    if (isMulti) {
      return campaigns.filter(campaign =>
        selectedCampaignIds.includes(campaign.id.toString())
      )
    }
    return campaigns.find(campaign =>
      selectedCampaignIds.includes(campaign.id.toString())
    )
  }, [campaigns, selectedCampaignIds, isMulti])

  const filterCampaigns = (option, input) => {
    if (!input.length) {
      return true
    }
    const lowercased = input.toLowerCase()
    const { data: { name } } = option
    return name.toLowerCase().indexOf(lowercased) !== -1
  }

  const handleInputChange = (inputValue, { action }) => {
    if (!isMulti || (action !== 'input-blur' && action !== 'menu-close' && action !== 'set-value')) {
      setKeyword(inputValue)
    }
  }

  const handleChange = (selected) => {
    if (isMulti) {
      onChange(selected.map(campaign => campaign.id))
    } else {
      onChange([selected.id])
    }
  }

  const handleSelectAll = () => {
    if (selectedCampaignIds.length === campaigns.length) {
      // If all is selected, un-select all.
      onChange([])
    } else {
      // Select all.
      if (keyword === '') {
        onChange(campaigns.map(campaign => campaign.id))
      } else {
        const filteredCampaigns = campaigns.filter(campaign =>
          filterCampaigns({ data: campaign }, keyword)
        )
        onChange(filteredCampaigns.map(campaign => campaign.id))
        setKeyword('')
      }
    }
  }

  return (
    <div className={`smart-select-wrapper ${!isLoaded ? 'disabled' : ''}`}>
      <Select
        classNamePrefix="campaign-selector"
        options={campaigns}
        getOptionLabel={getCampaignName}
        getOptionValue={campaign => campaign.id}
        value={selection}
        components={{ Option, ValueContainer, LoadingIndicator }}
        isMulti={isMulti}
        isLoading={isLoading}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        placeholder="Select campaigns..."
        inputValue={keyword}
        filterOption={filterCampaigns}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
      {
        isMulti && (
          <button
            type="button"
            className="btn btn-white"
            onClick={handleSelectAll}
          >
            {
              (selectedCampaignIds.length === 0 || selectedCampaignIds.length !== campaigns.length)
              ? 'Select All'
              : 'Unselect All'
            }
          </button>
        )
      }
    </div>
  )
}

export default CampaignSelector
