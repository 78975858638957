import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { formatInTimeZone } from 'date-fns-tz'

import LoaderComponent from '../../../../CommonComponents/LoaderComponent'

import { formatCurrency, formatValue, getLogDescription } from '../../../../../services/helper'

const metricList = [
  { name: 'PPC Revenue', key: 'revenue', color: '#fbab34' },
  { name: 'Ad Spend', key: 'cost', color: '#cfd2f5' },
  { name: 'Orders', key: 'orders', color: '#3dade8' },
  { name: 'Clicks', key: 'clicks', color: '#25c835' },
  { name: 'Impressions', key: 'impressions', color: '#93df8d' },
  { name: 'ACoS %', key: 'acos', color: '#ffd156' },
]

const CampaignChartComponent = ({ isLoading, isLogView = false, isNonEndemicAccount,
  chart, logData = [] }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const [chartData, setChartData] = useState([])

  const metricsToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return metricList
    }
    return metricList.filter(metric => (
      ![
        'revenue',
        'acos',
        'orders',
      ].includes(metric.key)
    ))
  }, [isNonEndemicAccount])

  const visibility = {}
  metricsToRender.forEach((metric) => {
    // By default, turn off line chart for impressions.
    visibility[metric.key] = metric.key !== 'impressions'
  })

  const [metricVisibility, setMetricVisibility] = useState(visibility)

  useEffect(()=> {
    if (!chart || !chart.length) {
      return
    }
    setChartData(chart.map(data => ({
      ...data,
      revenue: parseFloat(data.revenue),
      cost: parseFloat(data.cost),
      acos: parseFloat(data.revenue) !== 0 ? data.cost / data.revenue * 100 : 0,
      date: data.date ? new Date(data.date).valueOf() : new Date().valueOf(),
    })))
  }, [chart, currencyRate])

  const handleMetricToggle = key => () => {
    const visibility = Object.assign({}, metricVisibility, {
      [key]: !metricVisibility[key]
    })
    setMetricVisibility(visibility)
  }

  const formatter = (value, name) => {
    if (name === 'PPC Revenue' || name === 'Ad Spend') {
      return formatCurrency(value, currencySign, currencyRate)
    }
    if (name === 'ACoS %') {
      return formatValue(value, 'percent', 1)
    }
    return formatValue(value, 'number', 0)
  }

  const tickFormatter = (ts) => {
    if (!ts || isNaN(ts)) {
      return ''
    }
    return formatInTimeZone(parseInt(ts, 10), 'UTC', 'MM/dd')
  }

  const renderLegend = ({ payload }) => (
    <ul className="legend-list">
      {
        payload.map((entry) => {
          const style = {
            borderColor: entry.payload.stroke,
          }

          if (metricVisibility[entry.dataKey]) {
            style.backgroundColor = entry.payload.stroke
          }

          return (
            <li key={entry.dataKey} onClick={handleMetricToggle(entry.dataKey)}>
              <span className="bullet" style={style} />
              { entry.value }
            </li>
          )
        })
      }
    </ul>
  )

  const renderLabelFormatter = (ts) => {
    if (!ts || isNaN(ts)) {
      return ''
    }

    const d = formatInTimeZone(parseInt(ts, 10), 'UTC', 'yyyy/MM/dd')

    if (!isLogView || (logData || []).length === 0) {
      return d
    }

    const logs = logData.filter(l =>
      formatInTimeZone(l.created_at, 'UTC', 'yyyy/MM/dd') === d
    )
    if (!logs.length) {
      return d
    }

    const logElements = logs.map(l => (
      <p key={l.id} className="log-item">
        { getLogDescription(l.log_type, l.description) }
      </p>
    ))
    return (
      <>
        <strong>
          { d }
        </strong>
        { logElements }
      </>
    )
  }

  const renderDot = (props, metric) => {
    const { cx, cy, payload } = props
    const dt = formatInTimeZone(parseInt(payload.date, 10), 'UTC', 'yyyy/MM/dd')

    let hasLog = false
    if ((logData || []).length > 0) {
      hasLog = logData.findIndex(l =>
        formatInTimeZone(l.created_at, 'UTC', 'yyyy/MM/dd') === dt
      ) !== -1
    }

    return (
      <circle
        key={`${metric.key}-${dt}`}
        cx={cx}
        cy={cy}
        r={(isLogView && hasLog) ? 6 : 3}
        fill={metric.color}
      />
    )
  }

  return (
    <div className={`campaign-chart-component${isLoading ? ' loading' : ''}`}>
      { isLoading && <LoaderComponent /> }
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={300}
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: -25,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="date"
            tickFormatter={tickFormatter}
          />
          <YAxis />
          <Tooltip
            labelFormatter={renderLabelFormatter}
            formatter={formatter}
          />
          {
            metricsToRender.map(metric => (
              <Line
                key={metric.key}
                type="monotone"
                name={metric.name}
                dataKey={metric.key}
                stroke={metric.color}
                strokeWidth={2}
                dot={(props) => renderDot(props, metric)}
                activeDot={true}
                hide={!metricVisibility[metric.key]}
              />
            ))
          }
          <Legend
            content={renderLegend}
            wrapperStyle={{
              bottom: -5,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default CampaignChartComponent
