import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import CustomTooltip from '../../CommonComponents/CustomTooltip'

import ProductSection from './ProductSection'
import SBStoreSelection from '../SBStoreSelection'

import { selectCurrentAccount } from '../../../redux/reducers/header'
import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
  LANDING_PAGE_TYPE_STORE,
  LANDING_PAGE_TYPE_NEW_LANDING_PAGE,
  LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE,
  GOAL_BRAND_IMPRESSION_SHARE,
} from '../../../utils/defaultValues'

const collectionTypes =[
  { value: LANDING_PAGE_TYPE_STORE, label: 'Store on Amazon' },
  { value: LANDING_PAGE_TYPE_NEW_LANDING_PAGE, label: 'New landing page' },
]

const videoTypes =[
  { value: LANDING_PAGE_TYPE_STORE, label: 'Store on Amazon' },
  { value: LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE, label: 'Product detail page' },
]

const SBLandingPageSection = ({ adFormat, landingPageType, goal, products,
  basicInfo = {}, fromAdGroupCreator = false,
  onLandingPageTypeChange, onStorePageChange, onStoreChange, onProductChange }) => {
  const currentAccount = useSelector(selectCurrentAccount)

  const renderTypeSelector = () => {
    if (adFormat === AD_FORMAT_SPOTLIGHT) {
      return null
    }

    let types = []
    if (adFormat === AD_FORMAT_COLLECTION) {
      types = collectionTypes
    } else if (adFormat === AD_FORMAT_VIDEO) {
      types = videoTypes
    }

    return (
      <Select
        options={types}
        value={types.find(data => data.value === landingPageType)}
        onChange={(option) => { onLandingPageTypeChange(option.value) }}
      />
    )
  }

  const renderStoreSelector = () => {
    if (
      (
        (adFormat === AD_FORMAT_COLLECTION || adFormat === AD_FORMAT_VIDEO)
        &&
        landingPageType === LANDING_PAGE_TYPE_STORE
      )
      ||
      adFormat === AD_FORMAT_SPOTLIGHT
    ) {
      return (
        <SBStoreSelection
          isSpotlight={adFormat === AD_FORMAT_SPOTLIGHT}
          onChange={onStorePageChange}
          onStoreChange={onStoreChange}
        />
      )
    }

    return null
  }

  const renderProductSection = () => {
    if (adFormat === AD_FORMAT_SPOTLIGHT) {
      return null
    }

    let type
    if (adFormat === AD_FORMAT_COLLECTION
      && landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
      type = 'sb'
    } else if (adFormat === AD_FORMAT_VIDEO
      && landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
      type = 'sbv'
    }

    if (!type) {
      return null
    }

    if (!fromAdGroupCreator
      && currentAccount?.seller_type === 'seller'
      && !basicInfo.brandEntityId) {
      return (
        <div className="section-container">
          <div className="section-note">
            Please select a brand name to add products.
          </div>
        </div>
      )
    }

    return (
      <ProductSection
        type={type}
        products={products}
        onChange={onProductChange}
      />
    )
  }

  return (
    <>
      <div className="section-container">
        <div className="field-row">
          <div className="field-wrapper">
            <div className="field-name">
              Landing page
              <CustomTooltip placement="right">
                <p>The landing page is where shoppers are directed after they interact with your ad.</p>
              </CustomTooltip>
            </div>
            {
              adFormat === AD_FORMAT_COLLECTION
              && landingPageType === LANDING_PAGE_TYPE_STORE
              && goal === GOAL_BRAND_IMPRESSION_SHARE && (
                <div className="warning-message">
                  Custom image is required for this ad format
                  when using the Grow brand impression share goal.
                </div>
              )
            }
            { renderTypeSelector() }
          </div>
          <div className="field-wrapper"></div>
        </div>
        { renderStoreSelector() }
      </div>
      { renderProductSection() }
    </>
  )
}

export default SBLandingPageSection
