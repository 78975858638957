import {
  GET_CAMPAIGNS_WITH_HISTORY_SUCCESS,
  GET_CAMPAIGNS_DETAIL,
  GET_CAMPAIGNS_HISTORY,
  UPDATE_CAMPAIGN_STATE_SUCCEED,
  UPDATE_CAMPAIGN_NAMES_SUCCEED,
  UPDATE_BIDDINGS_SUCCEED,
  UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED,
  UPDATE_CAMPAIGNS_TAGS_SUCCEED,
  GET_CAMPAIGNS_WITH_TAG_SUCCESS,
  UPDATE_BULK_DAILY_BUDGET_SUCCEED,
  ADD_BULK_NOTES_SUCCEED,
  DELETE_CAMPAIGN_NOTE_SUCCEED,
  SELECT_CAMPAIGNS,
  SELECT_AD_TYPE_FILTER,
} from '../actionTypes/campaign'

import {
  SAVE_AP_SUCCESS,
  SAVE_AP_TEMPLATE_SUCCESS,
  TURN_AP_BULK_SUCCESS,
  TURN_AP_RULE_SUCCESS,
  APPLY_TEMPLATE_BULK_SUCCESS,
} from '../actionTypes/ap'

import {
  SET_SELECTED_USER_INFO,
} from '../actionTypes/header'

import {
  CREATE_TAGS_FOR_CAMPAIGNS
} from '../actionTypes/tag'
import {TURN_RULES_SUCCESS} from "../actionTypes/ruleManager";
import {RULE_TYPE_DP} from "../../utils/defaultValues";

const initialState = {
  campaignsWithHistory: [],
  campaignsWithTag: [],
  campaignIdsSelected: [],
  adTypeSelected: [],
}

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_USER_INFO:
      return Object.assign(initialState, {
        campaignsWithHistory: state.campaignsWithHistory || initialState.campaignsWithHistory,
        campaignIdsSelected: [],
        adTypeSelected: [],
      })
    case SELECT_CAMPAIGNS:
      return {
        ...state,
        campaignIdsSelected: action.data,
      }
    case SELECT_AD_TYPE_FILTER:
      return {
        ...state,
        adTypeSelected: action.data,
      }
    case GET_CAMPAIGNS_WITH_HISTORY_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: [
          ...new Map(action.data.map(campaign => [campaign.campaign_id, campaign])).values(),
        ].map(record => Object.assign({}, record, {
          clicks: parseInt(record.clicks || 0, 10),
          impressions: parseInt(record.impressions || 0, 10),
          orders: parseInt(record.orders || 0, 10),
          ntb_orders: parseInt(record.ntb_orders || 0, 10),
          conversion: parseFloat(record.conversion || 0),
          cost: parseFloat(record.cost || 0),
          ntb_orders_percent: parseFloat(record.ntb_orders_percent || 0),
          ntb_sales: parseFloat(record.ntb_sales || 0),
          ntb_sales_percent: parseFloat(record.ntb_sales_percent || 0),
          daily_budget: parseFloat(record.daily_budget || 0),
          bidding: record.bidding
            ? (typeof record.bidding === 'object'
              ? record.bidding
              : (typeof JSON.parse(record.bidding) === 'object'
                ? JSON.parse(record.bidding)
                : JSON.parse(JSON.parse(record.bidding))))
            : {},
        })),
      }
    case GET_CAMPAIGNS_HISTORY:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          campaign.chartData = action.data[campaign.campaign_id] || []
          return campaign
        }),
      }
    case GET_CAMPAIGNS_DETAIL:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (action.data[campaign.campaign_id]) {
            return Object.assign({}, campaign, action.data[campaign.campaign_id])
          }
          return campaign
        }),
      }
    case SAVE_AP_TEMPLATE_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (!action.data.needApply
            || action.data.campaignId.toString() !== campaign.campaign_id.toString()) {
            return campaign
          }
          campaign.is_ap_active = true
          return campaign
        }),
      }
    case TURN_AP_BULK_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (!action.data.campaignIds.includes(campaign.campaign_id.toString())
            || campaign.is_ap_active === null) {
            return campaign
          }
          campaign.is_ap_active = !campaign.is_ap_active
          return campaign
        }),
      }
    case TURN_AP_RULE_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (action.data.campaignId !== campaign.campaign_id.toString()
            || action.data.adgroupId) {
            return campaign
          }
          campaign.is_ap_active = action.data.status
          return campaign
        }),
      }
      case TURN_RULES_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (action.data.type !== RULE_TYPE_DP
            || !action.data.campaignIds) {
            return campaign
          }


          if (action.data.campaignIds.includes(campaign.campaign_id.toString())){
            if (action.data.value === null){
              campaign.is_dp_active = !campaign.is_dp_active
            }
            else if (action.data.value === true){
              campaign.is_dp_active = true
            }
            else {
              campaign.is_dp_active = false
            }

          }

          return campaign
        }),
      }
    case SAVE_AP_SUCCESS:
    case APPLY_TEMPLATE_BULK_SUCCESS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (!action.data.includes(campaign.campaign_id.toString())) {
            return campaign
          }
          campaign.is_ap_active = true
          return campaign
        }),
      }
    case UPDATE_CAMPAIGN_STATE_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (action.data.idsUpdated.includes(campaign.campaign_id.toString())) {
            campaign.state = action.data.state
          }
          return campaign
        }),
      }
    case UPDATE_CAMPAIGN_NAMES_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (action.data.updated.includes(campaign.campaign_id.toString())) {
            const found = action.data.payload.find(record =>
              record.campaignId.toString() === campaign.campaign_id.toString()
            )
            return {
              ...campaign,
              campaign: found.name,
            }
          }
          return campaign
        })
      }
    case UPDATE_BIDDINGS_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          action.data.forEach((updated) => {
            if (updated.campaignId.toString() === campaign.campaign_id.toString()) {
              campaign.bidding.adjustments = updated.bidding.adjustments
              campaign.bidding.strategy = updated.bidding.strategy
            }
          })
          return campaign
        })
      }
    case UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          let index = action.data.findIndex(updatedCampaign =>
            updatedCampaign.campaignId.toString() === campaign.campaign_id.toString()
          )
          if (index === -1) {
            return campaign
          }
          return {
            ...campaign,
            target_acos: action.data[index].acos,
          }
        })
      }
    case UPDATE_CAMPAIGNS_TAGS_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (!action.payload[campaign.campaign_id]) {
            return campaign
          }
          return { ...campaign, tags: action.payload[campaign.campaign_id] }
        })
      }
    case GET_CAMPAIGNS_WITH_TAG_SUCCESS:
      return {
        ...state,
        campaignsWithTag: [...new Map(action.data.map(campaign => [campaign.campaign_id, campaign])).values()].map(record => Object.assign({}, record, {
          clicks: parseInt(record.clicks || 0, 10),
          impressions: parseInt(record.impressions || 0, 10),
          orders: parseInt(record.orders || 0, 10),
          ntb_orders: parseInt(record.ntb_orders || 0, 10),
          conversion: parseFloat(record.conversion || 0),
          cost: parseFloat(record.cost || 0),
          ntb_orders_percent: parseFloat(record.ntb_orders_percent || 0),
          ntb_sales: parseFloat(record.ntb_sales || 0),
          ntb_sales_percent: parseFloat(record.ntb_sales_percent || 0),
          daily_budget: parseFloat(record.daily_budget || 0),
        })),
      }
    case UPDATE_BULK_DAILY_BUDGET_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          if (!action.data.updated.includes(campaign.campaign_id.toString())) {
            return campaign
          }

          const found = action.data.payload.find(updatedCampaign =>
            updatedCampaign.campaignId.toString() === campaign.campaign_id.toString()
          )
          return {
            ...campaign,
            daily_budget: found.dailyBudget,
          }
        })
      }
    case ADD_BULK_NOTES_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) => {
          const index = action.data.findIndex(note =>
            note.campaign_id.toString() === campaign.campaign_id.toString()
          )
          if (index === -1) {
            return campaign
          }
          return {
            ...campaign,
            notes: [action.data[index]].concat(campaign.notes),
          }
        })
      }
    case DELETE_CAMPAIGN_NOTE_SUCCEED:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) =>
          action.data.campaign_id.toString() === campaign.campaign_id.toString()
          ? {
            ...campaign,
            notes: campaign.notes.filter(n => n.id !== action.data.id)
          }
          : campaign
        )
      }
    case CREATE_TAGS_FOR_CAMPAIGNS:
      return {
        ...state,
        campaignsWithHistory: state.campaignsWithHistory.map((campaign) =>
          action.data.campaignIds.includes(campaign.campaign_id.toString())
          ? {
            ...campaign,
            tags: [
              ...campaign.tags,
              action.data.tagId,
            ]
          }
          : campaign
        )
      }
    default:
      return state
  }
}

export default campaign
