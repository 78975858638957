import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentAccount } from '../../redux/reducers/header'

const DataFetchComponent = () => {
  const currentAccount = useSelector(selectCurrentAccount)

  if (!currentAccount || currentAccount.fetchingData !== true) {
    return null
  }

  return (
    <div className="notification-box">
      <div className="call-text">
        <div className="call-name">
          Your data is still being fetched.
        </div>
        <div className="call-description">
          Please check back in a few more hours.
        </div>
      </div>
    </div>
  )
}

export default DataFetchComponent
