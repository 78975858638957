import { APPLY_CAMPAIGN_COLUMNS } from "../actionTypes/dsp"

import { dspCampaignColumnSetting } from '../../utils/dspDefaultValues'

import { getCampaignColumns } from "../../services/dspHelper"

export const initialState = {
  campaignColumns: getCampaignColumns(dspCampaignColumnSetting[0].setting),
}

const dsp = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_CAMPAIGN_COLUMNS:
      return {
        ...state,
        campaignColumns: action.data,
      }
    default:
      return state
  }
}

export default dsp