import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import VideoLink from '../../components/CommonComponents/VideoLink'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'
import Section from './Section'

import { saveUniversalSettings } from '../../redux/actions/auth'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

const videoList = [
  { title: 'Search Term Optimization Video', url: 'https://www.loom.com/embed/22375f03637344ad8c8a3ed984aed1a4' },
]

const SectionUniversal = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [profitMargin, setProfitMargin] = useState(0)
  const [acos, setAcos] = useState(0)
  const [timezone, setTimezone] = useState('America/Los_Angeles')
  const [isSaving, setIsSaving] = useState(false)

  // Load current settings.
  useEffect(() => {
    setProfitMargin(parseFloat(currentAccount?.average_profit || 0))
    setAcos(parseFloat(currentAccount?.average_acos || 0))
    setTimezone(currentAccount?.timezone || 'America/Los_Angeles')
  }, [currentAccount])

  const timezoneList = Intl.supportedValuesOf('timeZone').map(tz => ({
    value: tz,
    label: tz,
  }))

  const timezoneOption = timezoneList.find(option =>
    option.value === timezone
  )

  const handleSave = async () => {
    if (profitMargin === '' || isNaN(profitMargin)) {
      toast.show({
        title: 'Warning',
        description: 'Please enter the Universal Profit Margin.',
      })
      return
    }

    if (acos === '' || isNaN(acos)) {
      toast.show({
        title: 'Warning',
        description: 'Please enter the Universal Target ACoS.',
      })
      return
    }

    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveUniversalSettings(accessToken, {
      profitMargin: parseFloat(profitMargin),
      acos: parseFloat(acos),
      timezone,
    }))
    setIsSaving(false)
  }

  if (isNonEndemicAccount) {
    return null
  }

  return (
    <Section
      title="Universal Values"
      isSaving={isSaving}
      bodyClass="section-universal"
      topRight={(
        <VideoLink
          videoList={videoList}
          modalTitle="Universal"
        />
      )}
    >
      {/* <div className="field-wrapper">
        <label>
          Universal Profit Margin %
          <CustomTooltip placement="right">
            <p>Used to determine profit margins ONLY for those SKU’s
            where a profit margin has not been set.</p>
            <p>To set the profit margin on an individual SKU,
            go to Command Center and update the COG.</p>
          </CustomTooltip>
        </label>
        <input
          type="number"
          value={profitMargin}
          onChange={(event) => { setProfitMargin(event.target.value) }}
        />
      </div> */}
      <div className="field-wrapper">
        <label>
          Universal Target ACoS %
          <CustomTooltip placement="right">
            <p>We’ll use this as a target ACoS for campaigns that you have not set a target ACoS for.</p>
            <p>To set a target ACoS for a campaign, head to the command center.</p>
          </CustomTooltip>
        </label>
        <input
          type="number"
          value={acos}
          onChange={(event) => { setAcos(event.target.value) }}
        />
      </div>
      <div className="field-wrapper">
        <label>
          Timezone
        </label>
        <Select
          className="timezone-selector"
          options={timezoneList}
          placeholder="Choose timezone"
          value={timezoneOption}
          onChange={(option) => { setTimezone(option.value) }}
        />
      </div>
      <button
        type="button"
        className="btn btn-red"
        onClick={handleSave}
      >
        Save
      </button>
    </Section>
  )
}

export default SectionUniversal
