// Actions for smart pilot manager.
export const GET_AP = 'GET_AP'
export const GET_AP_SUCCESS = 'GET_AP_SUCCESS'
export const GET_AP_FAIL = 'GET_AP_FAIL'

// Load AP settings for a selected ad group.
export const GET_AP_AD_GROUP = 'GET_AP_AD_GROUP'
export const GET_AP_AD_GROUP_SUCCESS = 'GET_AP_AD_GROUP_SUCCESS'
export const GET_AP_AD_GROUP_FAIL = 'GET_AP_AD_GROUP_FAIL'

export const SAVE_AP_SUCCESS = 'SAVE_AP_SUCCESS'

export const SAVE_AP_TEMPLATE_SUCCESS = 'SAVE_AP_TEMPLATE_SUCCESS'

export const GET_AP_TEMPLATES = 'GET_AP_TEMPLATES'
export const GET_AP_TEMPLATES_SUCCESS = 'GET_AP_TEMPLATES_SUCCESS'
export const GET_AP_TEMPLATES_FAIL = 'GET_AP_TEMPLATES_FAIL'

export const TURN_AP_BULK = 'TURN_AP_BULK'
export const TURN_AP_BULK_SUCCESS = 'TURN_AP_BULK_SUCCESS'
export const TURN_AP_BULK_FAIL = 'TURN_AP_BULK_FAIL'

export const TURN_AP_RULE_SUCCESS = 'TURN_AP_RULE_SUCCESS'

export const APPLY_TEMPLATE_BULK_SUCCESS = 'APPLY_TEMPLATE_BULK_SUCCESS'

export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS'

export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS'
