// Rank rule manager.
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import SkuSelector from '../CommonComponents/SkuSelector'
import Header from './Header'
import Footer from './Footer'
import RuleSection from './RuleSection'

import {
  hideRuleManager,
} from '../../redux/actions/ruleManager'
import {
  loadRrRules,
  saveRrRules,
  turnRrRules,
} from '../../redux/actions/rank'
import { ignoreOutsideClick, isRuleComplete } from '../../services/helper'

const RankManager = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const products = useSelector(state => state.ruleManager.products)

  // Indicate if a rule is saved to database.
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRules, setIsLoadingRules] = useState(true)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
  const [skus, setSkus] = useState([])

  useEffect(() => {
    setSkus(products.map(product => ({
      id: product.id,
      sku: product.sku,
      asin: product.asin,
      name: product.name,
      image: product.image,
    })))
  }, [products])

  const [rules, setRules] = useState([])
  const [originalRuleIds, setOriginalRuleIds] = useState([])

  // Load existing rules.
  useEffect(() => {
    if (!skus.length) {
      setIsLoadingRules(false)
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoadingRules(true)
      const response = await dispatch(loadRrRules(
        accessToken,
        skus.map(sku => sku.sku)
      ))

      const originalIds = []
      setRules(response.map((rule) => {
        originalIds.push(rule.id)
        return Object.assign(rule, {
          slots: rule.slots.map((slot, index) => (
            Object.assign(slot, {
              id: `${Math.floor(Math.random() * 1000).toString()}-${index}`,
            })
          ))
        })
      }))
      setOriginalRuleIds(originalIds)
      setIsSaved(response.length !== 0)

      setIsLoadingRules(false)
    })()
  }, [dispatch, skus]) // eslint-disable-line

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  // When clicking outside of the manager panel.
  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  const handleStatusChange = async (campaignId) => {
    setIsUpdatingStatus(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(turnRrRules(
      accessToken,
      [campaignId.toString()],
    ))
    setIsUpdatingStatus(false)
  }

  const handleApply = async () => {
    const payload = []
    const ruleIdsToSave = []

    rules.forEach((rule) => {
      if (typeof rule.status === 'undefined'
        || !rule.campaign_id
        || !rule.keywords
        || !rule.keywords.length
        || !rule.rank_limit
        || !rule.rank_days
        || !rule.rule_days) {
        return
      }

      const completeActionRules = rule.slots.filter(isRuleComplete)
      if (!completeActionRules.length) {
        return
      }

      const ruleToSave = Object.assign({}, rule)

      delete ruleToSave.campaign_source
      if (!ruleToSave.id.toString().includes('new-')) {
        ruleIdsToSave.push(ruleToSave.id)
      }

      ruleToSave.slots = completeActionRules

      payload.push(ruleToSave)
    })

    const deletedRuleIds = originalRuleIds.filter(id =>
      !ruleIdsToSave.includes(id)
    )

    if (!payload.length && !deletedRuleIds.length) {
      toast.show({
        title: 'Warning',
        description: 'There is nothing to save.',
      })
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(saveRrRules(
      accessToken,
      payload,
      deletedRuleIds,
    ))
    setIsSaved(true)
    setIsLoading(false)

    // Set a real ID saved in database.
    if (Object.keys(response).length) {
      setRules(rules.map((rule) => {
        if (response[rule.id]) {
          rule.id = response[rule.id]
        }
        return rule
      }))
    }
  }

  const selectedSkus = useMemo(() => {
    return skus.map(sku => sku.sku)
  }, [skus])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="rank-manager-component">
        { (isLoading || isLoadingRules || isUpdatingStatus) && <LoaderComponent className="rule-loader" /> }
        <Header
          isSaved={isSaved}
          onClose={handleClose}
        />
        <div className="pane-body">
          <SkuSelector
            selectedSkus={skus}
            onChange={setSkus}
          />
          <RuleSection
            skus={selectedSkus}
            rules={rules}
            isUpdatingStatus={isUpdatingStatus}
            onChange={setRules}
            onStatusChange={handleStatusChange}
          />
        </div>
        <Footer
          isLoading={isLoading || isLoadingRules}
          onApply={handleApply}
          onClose={handleClose}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default RankManager
