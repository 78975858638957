import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

export const getPortfolios = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/portfolio/getPortfolios', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve portfolios.',
      })
    }
    return []
  })
}

export const getPortfoliosWithCampaigns = (
  accessToken,
  startDate,
  endDate,
  signal = undefined,
) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/portfolio/getPortfoliosWithCampaigns', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve portfolios.',
      })
    }
    return []
  })
}

export const updateBudgets = (
  accessToken,
  portfolioIds,
  payload,
) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callPost('/portfolio/updateBudgets', {
    userId: currentUserId,
    portfolioIds,
    payload,
  }, accessToken).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}
