export const SAVE_NOTIFICATION_SUCCEED = 'SAVE_NOTIFICATION_SUCCEED'
export const SAVE_UNIVERSAL_SUCCEED = 'SAVE_UNIVERSAL_SUCCEED'

export const CHECK_AUTH_SUCCEED = 'CHECK_AUTH_SUCCEED'

export const SIGNUP_BASIC_START = 'SIGNUP_BASIC_START'
export const SIGNUP_BASIC_SUCCEED = 'SIGNUP_BASIC_SUCCEED'
export const SIGNUP_BASIC_FAILED = 'SIGNUP_BASIC_FAILED'

export const SIGNUP_NAME_INPUT = 'SIGNUP_NAME_INPUT'

export const AD_CODE_START = 'AD_CODE_START'
export const AD_CODE_SUCCEED = 'AD_CODE_SUCCEED'
export const AD_CODE_FAILED = 'AD_CODE_FAILED'

export const SIGNUP_SUCCEED = 'SIGNUP_SUCCEED'

export const ADD_ACCOUNT_OPEN = 'ADD_ACCOUNT_OPEN'

export const SAVE_BRAND_NAME_SUCCEED = 'SAVE_BRAND_NAME_SUCCEED'

export const REAUTH_SP_START = 'REAUTH_SP_START'
export const REAUTH_SP_SUCCEED = 'REAUTH_SP_SUCCEED'
export const REAUTH_ADS_SUCCEED = 'REAUTH_ADS_SUCCEED'
export const REAUTH_SP_FAILED = 'REAUTH_SP_FAILED'

export const REACTIVATE_SUCCEED = 'REACTIVATE_SUCCEED'
