import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportTableWidget from './ReportTableWidget'
import ReportNoteWidget from './ReportNoteWidget'

import { getCampaignsWithHistory } from '../../redux/actions/campaign'
import {
  formatCurrency,
  formatValue,
  getISODate,
} from '../../services/helper'

const columns = [
  { key: 'campaign_name', name: 'Campaign'},
  { key: 'cost', name: 'Spend'},
  { key: 'sales', name: 'Sales'},
  { key: 'acos', name: 'ACoS'},
  { key: 'cpc', name: 'CPC'},
  { key: 'ctr', name: 'CTR'},
  { key: 'ntb_order', name: 'NTB orders'},
  { key: 'ntb_sales', name: 'NTB sales'},
  { key: 'ntb_percent', name: 'NTB %'},
  { key: 'roas', name: 'ROAS'},
]

const CampaignSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')
  const [highestRevenueList, setHighestRevenueList] = useState([])
  const [expensiveList, setExpensiveList] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      if (showSection && !isLoaded) {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getCampaignsWithHistory(
          accessToken,
          getISODate(startDate),
          getISODate(endDate),
          abortCtrl.signal,
        ))
        setIsLoading(false)
        setIsLoaded(true)
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, showSection, startDate, endDate]) // eslint-disable-line

  useEffect(() => {
    if (isLoading || !campaignsWithHistory.length) {
      return
    }

    setHighestRevenueList(campaignsWithHistory.sort((a, b) => b.revenue - a.revenue).slice(0, 10).map(c => ({
      campaign_name: c.campaign,
      cost: c.cost,
      sales: c.revenue,
      acos: c.revenue !== 0 ? c.cost / c.revenue * 100 : 0.00,
      cpc: c.clicks !== 0 ? c.cost / c.clicks : 0.00,
      ctr: c.impressions !== 0 ? c.clicks / c.impressions * 100 : 0.00,
      ntb_order: c.ntb_orders,
      ntb_sales: c.ntb_sales,
      ntb_percent: c.ntb_sales_percent,
      roas: c.roas,
      campaign_id: c.campaign_id
    })))
    setExpensiveList(campaignsWithHistory.sort((a, b) => b.cost - a.cost).slice(0, 10).map(c => ({
      campaign_name: c.campaign,
      cost: c.cost,
      sales: c.revenue,
      acos: c.revenue !== 0 ? c.cost / c.revenue * 100 : 0.00,
      cpc: c.clicks !== 0 ? c.cost / c.clicks : 0.00,
      ctr: c.impressions !== 0 ? c.clicks / c.impressions * 100 : 0.00,
      ntb_order: c.ntb_orders,
      ntb_sales: c.ntb_sales,
      ntb_percent: c.ntb_sales_percent,
      roas: c.roas,
      campaign_id: c.campaign_id
    })))
  }, [isLoading, campaignsWithHistory])

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_campaigns || '')
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const campaigns = reportWidgetSetting ? reportWidgetSetting.campaigns : []
    setViewWidgets(
      campaigns.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_campaign',
      sectionValue: note,
    })
  }

  const renderRecord = (record) => (
    <>
      <div className="table-col">
        <div className="campaign-name">
          { record.campaign_name }
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.sales, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { record.acos !== 0 ? formatValue(record.acos, 'percent', 2) : '--' }
      </div>
      <div className="table-col">
        { record.cpc !== 0 ? formatCurrency(record.cpc, currencySign, currencyRate) : '--' }
      </div>
      <div className="table-col">
        { record.ctr !== 0 ? formatValue(record.ctr, 'percent', 2) : '--' }
      </div>
      <div className="table-col">
        { record.ntb_order !== 0 ? formatValue(record.ntb_order, 'number', 0) : '--' }
      </div>
      <div className="table-col">
        { record.ntb_sales !== 0 ? formatCurrency(record.ntb_sales, currencySign, currencyRate) : '--' }
      </div>
      <div className="table-col">
        { record.ntb_percent !== 0 ? formatValue(record.ntb_percent, 'percent', 2) : '--' }
      </div>
      <div className="table-col">
        { record.roas !== 0 ? formatValue(record.roas, 'number', 2) : '--' }
      </div>
    </>
  )

  return (
    <>
      <SectionHeader
        title="Your Campaigns"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            {(customizingSection || noteWidgetView) && (
              <ReportNoteWidget
                noteWidgetView={noteWidgetView}
                customizing={customizingSection}
                onRemove={setNoteWidgetView}
                note={note}
                onChange={setNote}
                section="campaigns"
              />
            )}
            {viewWidgets.length > 0 && (viewWidgets[0].view || customizingSection) && (
              <ReportTableWidget
                widget={viewWidgets[0]}
                columns={columns}
                records={highestRevenueList || []}
                onRemove={handleOnRemoveWidget}
                customizing={customizingSection}
                idField="campaign_id"
                renderRecord={renderRecord}
                sortBy="sales"
                sortOrder="desc"
                isLoading={isLoading}
                section="campaigns"
              />
            )}
            {viewWidgets.length > 0 && (viewWidgets[1].view || customizingSection) && (
              <ReportTableWidget
                widget={viewWidgets[1]}
                columns={columns}
                records={expensiveList || []}
                onRemove={handleOnRemoveWidget}
                customizing={customizingSection}
                idField="campaign_id"
                renderRecord={renderRecord}
                sortBy="cost"
                sortOrder="desc"
                isLoading={isLoading}
                section="campaigns"
              />
            )}
          </>
        )
      }
    </>
  )
}

export default withReportSection(CampaignSection, {
  sectionId: 'campaigns',
  reportSectionKey: 'campaigns',
})
