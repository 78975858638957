import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Rate } from 'rsuite'

import NonEndemicPreview from './NonEndemicPreview'

import {
  prettyString,
  formatValue
} from '../../../services/helper'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'

const RATE_NUMBER = 980

const previewTypeOption = [
  {value: 'AMAZON_PRODUCT_PAGE_RIGHT', label: 'Amazon product page right'},
  {value: 'AMAZON_PRODUCT_PAGE_MIDDLE', label: 'Amazon product page middle'},
  {value: 'AMAZON_MOBILE_RECTANGLE', label: 'Amazon mobile rectangle'},
  {value: 'MOBILE_LEADERBOARD', label: 'Mobile leaderboard'},
  {value: 'WIDE_SKYSCRAPER', label: 'Wide Skyscraper'},
  {value: 'MEDIUM_RECTANGLE', label: 'Medium rectangle'},
  {value: 'LEADERBOARD', label: 'Leaderboard'},
  {value: 'BILLBOARD', label: 'Billboard'},
  {value: 'LARGE_RECTANGLE', label: 'Large rectangle'},
  {value: 'AMAZON_TOP_STRIPE', label: 'Amazon top stripe'},
]

const SDCreativePreview = ({ campaignId, products }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [previewType, setPreviewType] = useState(previewTypeOption[0])

  const renderContent = () => {
    if (products.length === 0) {
      return <div className="campaign-creative-panel" />
    }

    if (previewType.value === 'AMAZON_PRODUCT_PAGE_RIGHT') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content amazon-product-page-right">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-title">
                { prettyString(products[0].name, 20)}
              </div>
              <div className="product-rate">
                <div className="rate-star">
                  <Rate
                    defaultValue={4.5}
                    size="xs"
                    allowHalf
                    readOnly
                  />
                </div>
                <div className="rate-number">
                  { formatValue(RATE_NUMBER, 'number', 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'AMAZON_PRODUCT_PAGE_MIDDLE') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content amazon-product-page-middle">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { products[0].name}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'AMAZON_MOBILE_RECTANGLE') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content amazon-mobile-rectangle">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { products[0].name}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'MOBILE_LEADERBOARD') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content mobile-leaderboard">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { products[0].name}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'WIDE_SKYSCRAPER') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content wide-skyscraper">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { prettyString(products[0].name)}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'MEDIUM_RECTANGLE') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content medium-rectangle">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-title">
                { prettyString(products[0].name, 40)}
              </div>
              <div className="product-rate">
                <div className="rate-star">
                  <Rate
                    defaultValue={4.5}
                    size="xs"
                    allowHalf
                    readOnly
                  />
                </div>
                <div className="rate-number">
                  { formatValue(RATE_NUMBER, 'number', 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'LEADERBOARD') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content leaderboard">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { prettyString(products[0].name, 60)}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'BILLBOARD') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content billboard">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { products[0].name}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'LARGE_RECTANGLE') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content large-rectangle">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { prettyString(products[0].name, 60)}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'AMAZON_TOP_STRIPE') {
      return (
        <div className="campaign-creative-panel desktop">
          <div className="ad-creative-content amazon-top-stripe">
            <div className="creative">
              <div className="product-image">
                <img
                  src={products[0].image}
                  alt={products[0].name}
                />
              </div>
              <div className="product-detail">
                <div className="product-title">
                  { prettyString(products[0].name, 75)}
                </div>
                <div className="product-rate">
                  <div className="rate-star">
                    <Rate
                      defaultValue={4.5}
                      size="xs"
                      allowHalf
                      readOnly
                    />
                  </div>
                  <div className="rate-number">
                    { formatValue(RATE_NUMBER, 'number', 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  if (isNonEndemicAccount) {
    return <NonEndemicPreview campaignId={campaignId} />
  }

  return (
    <div className="campaign-creative-section">
      <div className="creative-action-bar">
        <Select
          options={previewTypeOption}
          value={previewType}
          isDisabled={products.length === 0}
          onChange={setPreviewType}
        />
      </div>
      { renderContent() }
    </div>
  )
}

export default SDCreativePreview
