import React, { useState } from 'react'
import { Modal } from 'rsuite'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

const SaveTemplateModal = ({ show, onSave, onCancel }) => {
  const [name, setName] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = () => {
    setIsSaving(true)
    onSave(name).then(() => {
      setIsSaving(false)
      onCancel()
    })
  }

  return (
    <Modal className="save-template-modal" backdrop="static" show={show} size="xs">
      <Modal.Body>
        { isSaving && <LoaderComponent /> }
        <input
          type="text"
          placeholder="Enter a template name"
          value={name}
          onChange={(event) => { setName(event.target.value) }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={name === '' || isSaving}
          onClick={() => { handleSave() }}
        >
          Save
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onCancel() }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SaveTemplateModal
