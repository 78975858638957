import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import DspNavigation from './DspNavigation'
import DspAdvertiserTable from './DspAdvertiserTable'
import DspOrderTable from './DspOrderTable'
import DspLineItemTable from './DspLineItemTable'

const NAV_ADVERTISER = 'advertiser'
const NAV_ORDER = 'order'
const NAV_LINE_ITEM = 'lineItem'

const DspComponent = () => {
  const accountList = useSelector(state => state.header.accountList)

  const [selectedDspAccount, setSelectedDspAccount] = useState(null)
  const [currentAdvertiser, setCurrentAdvertiser] = useState(null)
  const [activeNav, setActiveNav] = useState(NAV_ADVERTISER)

  useEffect(() => {
    if (selectedDspAccount) {
      return
    }

    // By default, choose the first DSP account.
    const dspAccount = accountList.find(account =>
      account.seller_type === 'agency'
      && account.sub_type === 'dsp'
    )

    if (dspAccount) {
      setSelectedDspAccount(dspAccount)
    }
  }, [accountList, selectedDspAccount])

  useEffect(() => {
    if (currentAdvertiser) {
      setActiveNav(NAV_ORDER)
    } else {
      setActiveNav(NAV_ADVERTISER)
    }
  }, [currentAdvertiser])

  const renderContent = () => {
    if (activeNav === NAV_ADVERTISER) {
      return <DspAdvertiserTable
        selectedDspAccount={selectedDspAccount}
        onAdvertiserChange={setCurrentAdvertiser}
      />
    }
    if (activeNav === NAV_ORDER) {
      return <DspOrderTable
        selectedDspAccount={selectedDspAccount}
        currentAdvertiser={currentAdvertiser}
      />
    }
    if (activeNav === NAV_LINE_ITEM) {
      return <DspLineItemTable
        selectedDspAccount={selectedDspAccount}
        currentAdvertiser={currentAdvertiser}
      />
    }
    return null
  }

  return (
    <div className="dsp-component">
      <DspNavigation
        selectedDspAccount={selectedDspAccount}
        activeNav={activeNav}
        currentAdvertiser={currentAdvertiser}
        onAccountSelect={setSelectedDspAccount}
        onNavSelect={setActiveNav}
        onAdvertiserReset={() => { setCurrentAdvertiser(null) }}
      />
      { renderContent() }
    </div>
  )
}

export default DspComponent
