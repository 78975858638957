/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import VideoLink from '../CommonComponents/VideoLink'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import { showTemplateEditor } from '../../redux/actions/ruleManager'
import { RULE_TYPE_SP } from '../../utils/defaultValues'

const SubSection = ({ id, name, templateId = null, tooltip = null,
  videoList = [], children, activeSubSection, noReadOnly = false, onToggle }) => {
  const dispatch = useDispatch()

  const ref = useRef(null)

  const handleTemplateEdit = async (event) => {
    event.preventDefault()
    dispatch(showTemplateEditor(RULE_TYPE_SP, templateId))
  }

  return (
    <div className="subsection-container" ref={ref}>
      <div className="subsection-header">
        <span onClick={() => { onToggle(id, ref) }}>{ name }</span>
        {
          templateId !== null && templateId !== 0 && (
            <a href="#" onClick={handleTemplateEdit}>
              (from template)
            </a>
          )
        }
        {
          tooltip !== null && (
            <CustomTooltip>
              { tooltip }
            </CustomTooltip>
          )
        }
        {
          activeSubSection === id
          ? <BsCaretUpFill size={12} color='#979797' onClick={() => { onToggle(id, ref) }} />
          : <BsCaretDownFill size={12} color='#979797' onClick={() => { onToggle(id, ref) }} />
        }
        {
          videoList.length > 0 && (
            <VideoLink
              videoList={videoList}
            />
          )
        }
      </div>
      {
        activeSubSection === id && (
          <div className={`subsection-body${templateId && !noReadOnly ? ' read-only' : ''}`}>
            { children }
          </div>
        )
      }
    </div>
  )
}

export default SubSection
