import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Radio, RadioGroup } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../../CommonComponents/CustomTableComponent'
import LoaderComponent from '../../CommonComponents/LoaderComponent'
import CheckboxComponent from '../../CommonComponents/CheckboxComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'

import { createNegatives } from '../../../redux/actions/bulkEngine'

import { validateNKLength } from '../../../services/validator'
import {
  NEGATIVE_MATCH_TYPE_EXACT,
  NEGATIVE_MATCH_TYPE_PHRASE,
} from '../../../utils/defaultValues'
import { isAsin } from '../../../services/helper'

const ModalAddNegatives = ({
  campaignType,
  showModal,
  currentAdGroupId,
  modalType,
  terms = [],
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const [targetLevel, setTargetLevel] = useState('adgroup')
  const [matchType, setMatchType] = useState(
    modalType === 'negative-finder'
    ? NEGATIVE_MATCH_TYPE_PHRASE
    : NEGATIVE_MATCH_TYPE_EXACT
  )
  const [selectedNegatives, setSelectedNegatives] = useState([])
  const [selectedAdgroupIds, setSelectedAdgroupIds] = useState([])
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    setSelectedNegatives(terms.map(term => term.search))
  }, [terms])

  const adgroupList = useMemo(() => {
    let adgroups = (currentAdGroups || []).filter(adgroup =>
      !adgroup.state || (adgroup.state.toLowerCase() === 'enabled')
    )

    if (
      currentDetail
      && currentDetail.targeting_type === 'manual'
      && modalType === 'keyword-cleaner'
    ) {
      adgroups = adgroups.filter(adgroup => adgroup.targetType === 'keywords')
    }

    if (currentAdGroupId) {
      adgroups = adgroups.filter(adgroup =>
        adgroup.adgroupid.toString() === currentAdGroupId.toString()
      )
    }

    return adgroups
  }, [currentAdGroups, currentDetail, modalType, currentAdGroupId])

  const handleAdd = async () => {
    const adgroupsById = {}
    currentAdGroups.forEach((adgroup) => {
      adgroupsById[adgroup.adgroupid] = adgroup
    })

    const negativeKeywords = []
    selectedNegatives.forEach((negative) => {
      const keywordText = negative.trim()

      if (targetLevel === 'adgroup') {
        selectedAdgroupIds.forEach((adgroupId) => {
          if (currentDetail.targeting_type !== 'auto'
            && adgroupsById[adgroupId].targetType === 'products') {
            return
          }
          negativeKeywords.push({
            campaignId: currentDetail.campaign_id,
            adgroupId,
            campaignType,
            search: keywordText,
            matchType,
            // Below information are used for logging in backend.
            adgroupName: adgroupsById[adgroupId].name,
          })
        })
      } else {
        if (!currentAdGroups.length) {
          return
        }
        const adgroupId = currentAdGroups[0].adgroupid
        negativeKeywords.push({
          campaignId: currentDetail.campaign_id,
          adgroupId,
          campaignType,
          search: keywordText,
          matchType,
          // Below information are used for logging in backend.
          adgroupName: adgroupsById[adgroupId].name,
        })
      }
    })

    const invalidError = validateNKLength(negativeKeywords, 'search')
    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    const negativeTargets = []
    const asins = selectedNegatives.filter(isAsin)
    asins.forEach((negative) => {
      if (targetLevel === 'adgroup') {
        selectedAdgroupIds.forEach((adgroupId) => {
          if (adgroupsById[adgroupId].targetType !== 'products') {
            return
          }
          negativeTargets.push({
            campaignId: currentDetail.campaign_id,
            adgroupId,
            campaignType,
            search: negative.trim(),
            // Below information are used for logging in backend.
            adgroupName: adgroupsById[adgroupId].name,
          })
        })
      } else {
        if (!currentAdGroups.length) {
          return
        }

        const adgroupId = currentAdGroups[0].adgroupid

        negativeTargets.push({
          campaignId: currentDetail.campaign_id,
          adgroupId,
          campaignType,
          search: negative.trim(),
          // Below information are used for logging in backend.
          adgroupName: adgroupsById[adgroupId].name,
        })
      }
    })

    if (!negativeKeywords.length && !negativeTargets.length) {
      toast.show({
        title: 'Warning',
        description: 'Nothing to add.',
      })
      return
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const success = await dispatch(createNegatives(
      accessToken,
      negativeKeywords,
      negativeTargets,
      targetLevel,
    ))
    setIsAdding(false)
    if (success) {
      onClose()
    }
  }

  const renderNegative = negative => (
    <>
      <div className="table-col">
        { negative.search }
      </div>
    </>
  )

  const renderAdgroup = adgroup => (
    <>
      <div className="table-col">
        { adgroup.name }
      </div>
    </>
  )

  const renderNegatives = () => {
    return (
      <div className="section-container">
        <div className="section-title">
          3) Confirm Negatives
        </div>
        <CustomTable
          className="table-negatives"
          records={terms}
          idField="search"
          noSearch
          selectedRecords={selectedNegatives}
          renderRecord={renderNegative}
          onChange={setSelectedNegatives}
        >
          <div className="table-col">Name</div>
        </CustomTable>
      </div>
    )
  }

  const renderAdgroups = () => {
    if (targetLevel !== 'adgroup') {
      return null
    }

    return (
      <div className="section-container">
        <div className="section-title">
          4) Select Ad Group
        </div>
        <CustomTable
          className="table-adgroups"
          records={adgroupList}
          idField="adgroupid"
          noSearch
          selectedRecords={selectedAdgroupIds}
          renderRecord={renderAdgroup}
          onChange={setSelectedAdgroupIds}
        >
          <div className="table-col">Name</div>
        </CustomTable>
      </div>
    )
  }

  const hasAsin = useMemo(() => (
    terms.find(term => isAsin(term.search))
  ), [terms])

  return (
    <Modal backdrop="static" className="modal-add-negatives" show={showModal} size="lg">
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Add Negatives to Campaign
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isAdding && <LoaderComponent /> }
        <div className="top-section">
          <div className="section-container">
            <div className="section-title">
              1) Choose Ad Group or Campaign Level
            </div>
            <RadioGroup
              value={targetLevel}
              onChange={setTargetLevel}
            >
              <Radio value="adgroup" disabled={campaignType === 'sb'}>Ad Group Level</Radio>
              <Radio value="campaign" disabled={campaignType === 'sb'}>Campaign Level</Radio>
            </RadioGroup>
          </div>
          <div className="section-container">
            <div className="section-title">
              2) Confirm Negative Match Type and Add Negatives
            </div>
            {
              hasAsin && (
                <CheckboxComponent
                  label="Add ASINs as Negative Product Targeting"
                  checked
                  disabled
                />
              )
            }
            <RadioGroup
              value={matchType}
              onChange={setMatchType}
            >
              <Radio value={NEGATIVE_MATCH_TYPE_EXACT}>
                Add All Negatives As Negative Exact
              </Radio>
              <Radio value={NEGATIVE_MATCH_TYPE_PHRASE}>
                Add All Negatives As Negative Phrase
              </Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="bottom-section">
          { renderNegatives() }
          { renderAdgroups() }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={!selectedNegatives.length || (targetLevel === 'adgroup' && !selectedAdgroupIds.length) || isAdding}
          onClick={() => { handleAdd() }}
        >
          Add Negatives
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => { onClose() }}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddNegatives
