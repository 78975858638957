import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'

import TemplateEditorHeader from '../../RuleManagerComponents/TemplateEditorHeader'
import RuleSection from '../RuleSection'
import TemplateEditorFooter from '../../RuleManagerComponents/TemplateEditorFooter'

import { isSlotComplete } from '..'

import {
  hideRuleManager,
  getTemplate,
  deleteTemplate,
} from '../../../redux/actions/ruleManager'
import {
  saveIrTemplate,
  updateIrTemplate,
} from '../../../redux/actions/inventory'
import { RULE_TYPE_IR } from '../../../utils/defaultValues'
import { ignoreOutsideClick, isRuleComplete } from '../../../services/helper'

const TemplateEditor = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const templateToEdit = useSelector(state => state.ruleManager.templateToEdit)

  const [name, setName] = useState('')
  const [slots, setSlots] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!templateToEdit) {
      setName('')
      setSlots([])

      return
    }

    // It means the template ID is passed, not the whole object,
    // so we need to retrieve template details.
    if (typeof templateToEdit !== 'object') {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsSaving(true)
        await dispatch(getTemplate(accessToken, RULE_TYPE_IR, templateToEdit))
        setIsSaving(false)
      })()
      return
    }

    setName(templateToEdit.name)
    setSlots(templateToEdit.slots.map((slot, index) => (
      Object.assign({}, slot, {
        id: `${Math.floor(Math.random() * 1000).toString()}-${index}`,
      })
    )))
  }, [templateToEdit]) // eslint-disable-line

  const isSavable = useMemo(() => {
    return slots.filter(isSlotComplete).length > 0
  }, [slots])

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  const stripIds = (slotsToSave) => {
    return slotsToSave.filter(isSlotComplete).map((slot) => {
      const slotWithoutId = Object.assign({}, slot)
      delete slotWithoutId.id

      slotWithoutId.r = slotWithoutId.r.filter(isRuleComplete)

      return slotWithoutId
    })
  }

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently()
    if (templateToEdit) {
      setIsSaving(true)
      await dispatch(updateIrTemplate(
        accessToken,
        templateToEdit.id,
        name,
        stripIds(slots),
      ))
      setIsSaving(false)
    } else {
      if (name === '') {
        toast.show({
          title: 'Warning',
          description: 'Please enter a template name.',
        })
        return
      }

      setIsSaving(true)
      await dispatch(saveIrTemplate(
        accessToken,
        name,
        stripIds(slots),
      ))
      setIsSaving(false)
    }
  }

  const handleSaveAsAnother = async () => {
    if (templateToEdit && templateToEdit.name === name) {
      toast.show({
        title: 'Warning',
        description: 'Please enter a name different than the original one.',
      })
      return
    }

    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveIrTemplate(
      accessToken,
      name,
      stripIds(slots),
    ))
    setIsSaving(false)
  }

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently()
    setIsSaving(true)
    await dispatch(deleteTemplate(accessToken, RULE_TYPE_IR, templateToEdit.id))
    setIsSaving(false)
    handleClose()
  }

  const renderBody = () => {
    // We pass an empty array by storing it first
    // as a variable. If we pass [] directly to props,
    // a new object ([]) will be created on every render,
    // causing a maximum depth reached exception.
    const currentTemplates = []

    return (
      <div className="pane-body">
        <RuleSection
          hasSpCampaign
          slots={slots}
          currentTemplates={currentTemplates}
          onChange={setSlots}
        />
      </div>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="inventory-template-editor-component">
        { isSaving && <LoaderComponent /> }
        <TemplateEditorHeader
          templateType="Inventory"
          name={name}
          noTimezone
          onNameChange={setName}
          onClose={handleClose}
        />
        { renderBody() }
        <TemplateEditorFooter
          isSaving={isSaving}
          isSavable={isSavable}
          isEditable={templateToEdit !== null}
          onSave={handleSave}
          onSaveAsAnother={handleSaveAsAnother}
          onDelete={handleDelete}
          onClose={handleClose}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default TemplateEditor
