import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../../../CommonComponents/ToastComponent/toast'
import CustomTable from '../../../../CommonComponents/CustomTableComponent'

import {
  getNegativeTargets,
  archiveNegativeTargets,
} from '../../../../../redux/actions/campaignDetail'

import { copyToClipboard, capitalizeFirstLetter } from '../../../../../services/helper'

const NegativeTargetSection = ({ campaignId, campaignType, onNegativesAdd }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [negativeTargets, setNegativeTargets] = useState([])
  const [selectedNegatives, setSelectedNegatives] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isArchiving, setIsArchiving] = useState(false)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      const data = await dispatch(getNegativeTargets(
        accessToken,
        campaignId,
        campaignType,
      ))
      setNegativeTargets(data)
      setIsLoading(false)
    })()
  }, [campaignId]) // eslint-disable-line

  const handleCopy = () => {
    const negatives = negativeTargets.filter(negative => (
      selectedNegatives.indexOf(negative.target_id) !== -1
    )).map((negative) => {
      let expression = negative.resolved_expression
      try {
        const parsed = JSON.parse(negative.resolved_expression)
        if (parsed.length) {
          expression = parsed[0].value
        }
      } catch (e) {
        //
      }
      return expression
    })

    copyToClipboard(negatives.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedNegatives.length} negative target${selectedNegatives.length > 1 ? 's' : ''}.`
    })
  }

  const handleRemove = async () => {
    const negatives = negativeTargets.filter(negative => (
      selectedNegatives.indexOf(negative.target_id) !== -1
    ))
    const accessToken = await getAccessTokenSilently()
    setIsArchiving(true)
    const archivedIds = await dispatch(archiveNegativeTargets(
      accessToken,
      campaignType,
      negatives,
    ))
    setNegativeTargets(negativeTargets.filter(negative => (
      !archivedIds.includes(negative.target_id.toString())
    )))
    setIsArchiving(false)
  }

  const renderAction = () => {
    if (!selectedNegatives.length) {
      return null
    }

    return (
      <>
        <button type="button" className="btn btn-red" onClick={() => { handleRemove() }}>
          Remove from Negatives
        </button>
        <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
          Copy Targets
        </button>
      </>
    )
  }

  const renderNegative = (negative) => {
    let expression = negative.resolved_expression
    try {
      const parsed = JSON.parse(negative.resolved_expression)
      if (parsed.length) {
        expression = parsed[0].value
      }
    } catch (e) {
      //
    }
    return (
      <>
        <div className="table-col col-target">
          { expression }
        </div>
        {
          campaignType === 'sp' && (
            <div className="table-col col-level">
              { typeof negative.campaign_level !== 'undefined' ? 'Campaign Level' : 'Ad Group Level' }
            </div>
          )
        }
        <div className="table-col col-type">
          { capitalizeFirstLetter(negative.targeting_type || 'Manual') }
        </div>
      </>
    )
  }

  return (
    <div className="section">
      <div className="section-header">
        <h4>Negative Targets</h4>
        <button
          type="button"
          className="btn btn-blue"
          onClick={onNegativesAdd}
        >
          Add Negative Targets
        </button>
      </div>
      <CustomTable
        isLoading={isLoading || isArchiving}
        className="table-negatives"
        records={negativeTargets || []}
        idField="target_id"
        searchFields={['resolved_expression']}
        selectedRecords={selectedNegatives}
        renderRecord={renderNegative}
        renderTopRight={renderAction}
        onChange={setSelectedNegatives}
      >
        <div className="table-col col-target">Target</div>
        {
          campaignType === 'sp' && (
            <div className="table-col col-level">Campaign/Ad Group Level</div>
          )
        }
        <div className="table-col col-type">Type</div>
      </CustomTable>
    </div>
  )
}

export default NegativeTargetSection
