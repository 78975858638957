import React from 'react'
import Select from 'react-select'

import Frequency from './Frequency'
import Lookback from './Lookback'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'
import CheckInput from './CheckInput'

const unitList = [
  { value: '%', label: '%' },
  { value: '$', label: '$' },
]

const OpKeywordEx = ({ settings, isNonEndemicAccount, onChange }) => {
  const validate = field => () => {
    if (settings[field] === ''
      || isNaN(settings[field])
      || parseFloat(settings[field]) < 0) {
      onChange(field, 0)
    }
  }

  const unitImpression = unitList.find(unit => unit.value === settings.ex_impressions_unit)

  const disabled = !settings.ex_impressions_active
    && !settings.ex_clicks_active
    && !settings.ex_cost_active
    && !settings.ex_revenue_active

  return (
    <div>
      <span className="container-label">
        Target Bid Expansion
      </span>
      <FieldRow>
        <CheckInput
          label="If target has impressions less than"
          field="ex_impressions_active"
          inputField="ex_impressions_impressions"
          settings={settings}
          tooltip={'If targets have zero or low impressions over the set lookback period, Entourage will increase bid prices to help boost visibility for those targets. This feature will run daily.'}
          onChange={onChange}
        />
        <CheckInput
          label="If target has clicks less than"
          field="ex_clicks_active"
          inputField="ex_clicks"
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow>
        <CheckInput
          label="If target has ad spend less than"
          field="ex_cost_active"
          inputField="ex_cost"
          settings={settings}
          onChange={onChange}
        />
        {
          !isNonEndemicAccount && (
            <CheckInput
              label="If target has sales less than"
              field="ex_revenue_active"
              inputField="ex_revenue"
              settings={settings}
              onChange={onChange}
            />
          )
        }
      </FieldRow>
      <FieldRow disabled={disabled}>
        <div className="field-wrapper unit-selector">
          <div className="field-name">
            Increase bid by
          </div>
          <div className="unit-selector-inner">
            <Select
              classNamePrefix="unit-selector"
              value={unitImpression}
              options={unitList}
              placeholder="Unit"
              onChange={(selected) => { onChange('ex_impressions_unit', selected.value) }}
            />
            <input
              type="number"
              min="0"
              value={settings.ex_impressions_amount}
              onChange={(event) => { onChange('ex_impressions_amount', event.target.value) }}
              onBlur={validate('ex_impressions_amount')}
            />
          </div>
        </div>
        <FieldNumber
          label="Max bid value"
          field="ex_impressions_max_bid_price"
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <Frequency
        disabled={disabled}
        prefix="ex_"
        settings={settings}
        onChange={onChange}
      />
      <Lookback
        field="ex_lookback"
        settings={settings}
        onChange={onChange}
      />
    </div>
  )
}

export default OpKeywordEx
