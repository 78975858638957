import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import CustomTooltip from '../../components/CommonComponents/CustomTooltip'

import { removeCustomMetric } from '../../redux/actions/pageGlobal'
import { formatValue } from '../../services/helper'

const CustomMetricWidget = ({widget, value, customizing = false, diff = null, isBetter = true}) => {
  const dispatch = useDispatch()
  const [widgetValueClass, setWidgetValueClass] = useState('positive-trending')

  useEffect(() => {
    setWidgetValueClass(isBetter ? 'positive-trending' : 'negative-trending')
  }, [isBetter])

  const handleClickRemoveWidget = () => {
    dispatch(removeCustomMetric(widget))
  }

  const diffValueSign = diff?.value > 0 ? '+' : ''

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          <span>
            {`${widget.title}`}
          </span>
          {
            widget.tooltip && (
              <CustomTooltip placement="top">
                <p>{ widget.tooltip }</p>
              </CustomTooltip>
            )
          }
        </div>
        <div className="widget-action">
          {customizing && (
            <IconButton
              icon={<Icon icon="trash" />}
              color="violet"
              circle
              onClick={handleClickRemoveWidget}
            />
          )}
        </div>
      </div>
      <div className="report-widget-content">
        <div className="widget-value">
          { formatValue(value, widget.type, widget.decimal) }
        </div>
        {diff && (
          <div className="widget-prev-value">
            <span className={widgetValueClass}>
              {`${diffValueSign}${formatValue(diff.value, 'number', widget.decimal)} (${formatValue(diff.percent, 'percent', 2)})`}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomMetricWidget
