import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Row, Col, Divider, Button } from 'rsuite'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'
import MetricDifference from './MetricDifference'
import TrendingChart from './TrendingChart'

import {
  formatValue,
  formatCurrency,
  calcDerivedMetrics,
  calculateMetricDiff,
  getCurrencyForCountry,
} from '../../services/helper'
import { defaultMetricValue } from '../../utils/defaultValues'

const AggregatedOverview = ({ diffDisplayType, accountList, isLoading, adType, totalCost, exchangeRates }) => {
  const currencySign = useSelector(state => state.header.currencySign)

  const [currentTotalKPI, setCurrentTotalKPI] = useState(null)
  const [pastTotalKPI, setPastTotalKPI] = useState(null)
  const [difference, setDifference] = useState(null)
  const [trendingData, setTrendingData] = useState([])
  const [showTrending, setShowTrending] = useState(false)
  const [brandedRevenue, setBrandedRevenue] = useState(0)
  const [nonBrandedRevenue, setNonBrandedRevenue] = useState(0)

  useEffect(() => {
    if (!accountList.length) {
      return
    }

    const currentKPI = { ...defaultMetricValue }
    const pastKPI = { ...defaultMetricValue }
    let trendingMetrics = []
    let brandedSale = 0
    let nonBrandedSale = 0

    accountList.forEach((account) => {
      const code = getCurrencyForCountry(account.country).currencyCode

      const derivedMetrics = account.accountKPI ? calcDerivedMetrics(account.accountKPI) : {...defaultMetricValue}
      const pastDerivedMetrics = account.pastAccountKPI ? calcDerivedMetrics(account.pastAccountKPI) : {...defaultMetricValue}

      currentKPI.revenue += parseFloat(derivedMetrics.revenue || 0) / (exchangeRates[code] || 1)
      currentKPI.cost += parseFloat(derivedMetrics.cost || 0) / (exchangeRates[code] || 1)
      currentKPI.impressions += parseInt(derivedMetrics.impressions || 0, 10)
      currentKPI.clicks += parseInt(derivedMetrics.clicks || 0, 10)
      currentKPI.orders += parseInt(derivedMetrics.orders || 0, 10)
      currentKPI.cpc += parseFloat(derivedMetrics.cpc || 0) / (exchangeRates[code] || 1)
      currentKPI.ctr += parseFloat(derivedMetrics.ctr || 0)
      currentKPI.conversion += parseFloat(derivedMetrics.conversion || 0)
      currentKPI.acos += parseFloat(derivedMetrics.acos || 0)

      pastKPI.revenue += parseFloat(pastDerivedMetrics.revenue || 0) / (exchangeRates[code] || 1)
      pastKPI.cost += parseFloat(pastDerivedMetrics.cost || 0) / (exchangeRates[code] || 1)
      pastKPI.impressions += parseInt(pastDerivedMetrics.impressions || 0, 10)
      pastKPI.clicks += parseInt(pastDerivedMetrics.clicks || 0, 10)
      pastKPI.orders += parseInt(pastDerivedMetrics.orders || 0, 10)
      pastKPI.cpc += parseFloat(pastDerivedMetrics.cpc || 0) / (exchangeRates[code] || 1)
      pastKPI.ctr += parseFloat(pastDerivedMetrics.ctr || 0)
      pastKPI.conversion += parseFloat(pastDerivedMetrics.conversion || 0)
      pastKPI.acos += parseFloat(pastDerivedMetrics.acos || 0)

      if (account.accountKPI) {
        brandedSale += parseFloat(account.accountKPI.brandedSales || 0) / (exchangeRates[code] || 1)
        nonBrandedSale += parseFloat(account.accountKPI.nonBrandedSales || 0) / (exchangeRates[code] || 1)
      }

      account.accountCharts.forEach((record) => {
        const originIndex = trendingMetrics.findIndex(t => t.date === record.date)
        if (originIndex === -1) {
          trendingMetrics.push({
            date: record.date,
            impressions: record.impressions ? record.impressions : 0,
            clicks: record.clicks ? record.clicks : 0,
            orders: record.orders ? record.orders : 0,
            cost: record.cost ? record.cost : 0,
            revenue: record.revenue ? record.revenue : 0,
            brandedSale: record.branded_sale ? record.branded_sale : 0,
            nonBrandedSale: record.non_branded_sale ? record.non_branded_sale : 0,
          })
        } else {
          trendingMetrics[originIndex].revenue += (record.revenue || 0) / (exchangeRates[code] || 1)
          trendingMetrics[originIndex].cost += (record.cost || 0) / (exchangeRates[code] || 1)
          trendingMetrics[originIndex].impressions += record.impressions ? record.impressions : 0
          trendingMetrics[originIndex].clicks += record.clicks ? record.clicks : 0
          trendingMetrics[originIndex].orders += record.orders ? record.orders : 0
          trendingMetrics[originIndex].nonBrandedSale += (record.non_branded_sale || 0) / (exchangeRates[code] || 1)
        }
      })
    })

    setCurrentTotalKPI(currentKPI)
    setPastTotalKPI(pastKPI)
    setDifference(calculateMetricDiff(currentKPI, pastKPI))
    setTrendingData(trendingMetrics.map(t => calcDerivedMetrics(t)))
    setBrandedRevenue(brandedSale)
    setNonBrandedRevenue(nonBrandedSale)
  }, [accountList, exchangeRates])

  const renderBrandedData = () => {
    if (adType === 'sd') {
      return null
    }

    return (
      <Row gutter={20}>
        <Col xs={24}>
          <Divider/>
        </Col>
        <Col xsOffset={4} xs={8} className="metric-item">
          <div className="metric-name">
            Branded Sale
          </div>
          <div className="metric-item-content">
            <div className="metric-values">
              <span className="metric-main-value">
                { formatCurrency(brandedRevenue, currencySign, 1) }
              </span>
            </div>
            { showTrending && <TrendingChart data={trendingData} metric='brandedSale' trending="positive"/>}
          </div>
        </Col>
        <Col xs={8} className="metric-item">
          <div className="metric-name">
            Non Branded Sale
          </div>
          <div className="metric-item-content">
            <div className="metric-values">
              <span className="metric-main-value">
                { formatCurrency(nonBrandedRevenue, currencySign, 1) }
              </span>
            </div>
            { showTrending && <TrendingChart data={trendingData} metric='nonBrandedSale' trending="positive"/>}
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <div className={`report-widget${isLoading ? ' loading' : ''}`}>
      { isLoading && <LoaderComponent/> }
      <div className="report-widget-header">
        <div className="widget-title">
          <span>Overview</span>
          <CustomTooltip placement="top">
            <p>These statistics are for all of your clients, combined.</p>
          </CustomTooltip>
        </div>
        <div className="widget-action">
          <Button
            appearance={showTrending ? 'primary' : 'ghost'}
            onClick={() => setShowTrending(p => !p)}
          >
            Trending View
          </Button>
        </div>
      </div>
      <div className="report-widget-content">
        <Grid fluid>
          <Row gutter={20}>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Impressions
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.impressions, 'number', 0) }
                  </span>
                  { (currentTotalKPI?.impressions > 0 && pastTotalKPI?.impressions > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.impressions}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='impressions' trending={difference?.impressions.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Clicks
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.clicks, 'number', 0) }
                  </span>
                  { (currentTotalKPI?.clicks > 0 && pastTotalKPI?.clicks > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.clicks}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='clicks' trending={difference?.clicks.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Orders
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.orders, 'number', 0) }
                  </span>
                  { (currentTotalKPI?.orders > 0 && pastTotalKPI?.orders > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.orders}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='orders' trending={difference?.orders.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={24}>
              <Divider/>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Ad Spend
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatCurrency(currentTotalKPI?.cost, currencySign, 1) }
                    { adType === '' ? '' : (totalCost > 0 ? ' / ' + formatValue(currentTotalKPI?.cost / totalCost * 100, 'percent', 2) : ' / 0.00%') }
                  </span>
                  { (currentTotalKPI?.cost > 0 && pastTotalKPI?.cost > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.cost} decimals={[2, 2]} noTrending/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='cost' trending={'neutral'}/>}
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Revenue
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatCurrency(currentTotalKPI?.revenue, currencySign, 1) }
                  </span>
                  { (currentTotalKPI?.revenue > 0 && pastTotalKPI?.revenue > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.revenue} decimals={[2, 2]}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='revenue' trending={difference?.revenue.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                CPC
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatCurrency(currentTotalKPI?.cpc, currencySign, 1) }
                  </span>
                  { (currentTotalKPI?.cpc > 0 && pastTotalKPI?.cpc > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.cpc} decimals={[2, 2]}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='cpc' trending={difference?.cpc.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={24}>
              <Divider/>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                CTR
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.ctr, 'percent', 3) }
                  </span>
                  { (currentTotalKPI?.ctr > 0 && pastTotalKPI?.ctr > 0) && (
                    <MetricDifference className="metric-diff-value" displayType={diffDisplayType} difference={difference?.ctr} decimals={[3, 3]}/>
                  )}
                </div>
                { showTrending && <TrendingChart data={trendingData} metric='ctr' trending={difference?.ctr.status ? 'positive' : 'negative'}/>}
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                Conversion
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.conversion, 'percent', 2) }
                  </span>
                  {
                    (currentTotalKPI?.conversion > 0 && pastTotalKPI?.conversion > 0) && (
                      <MetricDifference
                        className="metric-diff-value"
                        displayType={diffDisplayType}
                        difference={difference?.conversion}
                        decimals={[2, 2]}
                      />
                    )
                  }
                </div>
                {
                  showTrending &&
                  <TrendingChart
                    data={trendingData}
                    metric='conversion'
                    trending={difference?.conversion.status ? 'positive' : 'negative'}
                  />
                }
              </div>
            </Col>
            <Col xs={8} className="metric-item">
              <div className="metric-name">
                ACoS
              </div>
              <div className="metric-item-content">
                <div className="metric-values">
                  <span className="metric-main-value">
                    { formatValue(currentTotalKPI?.acos, 'percent', 2) }
                  </span>
                  {
                    (currentTotalKPI?.acos > 0 && pastTotalKPI?.acos > 0) && (
                      <MetricDifference
                        className="metric-diff-value"
                        displayType={diffDisplayType}
                        difference={difference?.acos}
                        decimals={[2, 2]}
                      />
                    )
                  }
                </div>
                {
                  showTrending &&
                  <TrendingChart
                    data={trendingData}
                    metric='acos'
                    trending={difference?.acos.status ? 'positive' : 'negative'}
                  />
                }
              </div>
            </Col>
          </Row>
          { renderBrandedData() }
        </Grid>
      </div>
    </div>
  )
}

export default AggregatedOverview