/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { DateRangePicker } from 'rsuite'
import {
  startOfDay,
  subDays,
  endOfDay,
  startOfMonth,
  endOfMonth,
  subMonths,
  format,
} from 'date-fns'

import CustomTooltip from '../CustomTooltip'
import { parseDate } from '../../../services/helper'

const DateRangeComponent = ({ disabled, placeholder = 'Please select date range',
  placement = 'bottomStart', hasLifetimeRange = false, value, onChange }) => {
  const rangeContainerRef = useRef(null)
  const [opened, setOpened] = useState(false)

  const today = new Date()
  const startOfToday = startOfDay(today)
  const endOfToday = endOfDay(today)
  const monthAgo = subMonths(today, 1)

  const ranges = [
    {
      label: 'Last 30 Days',
      value: [subDays(startOfToday, 29), endOfToday],
    },
    {
      label: 'This Month',
      value: [startOfMonth(today), endOfMonth(today)],
    },
    {
      label: 'Last Month',
      value: [startOfMonth(monthAgo), endOfMonth(monthAgo)],
    },
    {
      label: 'Last 60 Days',
      value: [subDays(startOfToday, 59), endOfToday],
    },
    {
      label: 'Last 90 Days',
      value: [subDays(startOfToday, 89), endOfToday],
    },
    {
      label: 'Last 120 Days',
      value: [subDays(startOfToday, 119), endOfToday],
    },
  ]

  if (hasLifetimeRange) {
    ranges.push({
      label: 'Lifetime',
      value: [null, null],
      tooltip: 'Lifetime data represents all of the data accumulated inside of Entourage '
        + 'which is typically 60 days prior to starting your account.',
    })
  }

  const handleOutsideClick = () => {
    if (opened) {
      setOpened(false)
    }
  }

  const handleToggle = (event) => {
    event.preventDefault()

    if (!disabled) {
      setOpened(!opened)
    }
  }

  const handleChange = (range) => {
    onChange(range)
    setOpened(false)
  }

  // When clicking on OK without changing current selection,
  // it won't fire `change` event, so we need to listen for
  // the `click` event of OK button to close the picker.
  const handleOkClick = () => {
    setOpened(false)
  }

  const handleRangeClick = rangeOption => (event) => {
    event.preventDefault()
    handleChange(rangeOption.value)
  }

  const renderToggle = () => {
    let label = ''
    if (!value || typeof value[0] === 'undefined' || typeof value[1] === 'undefined') {
      label = placeholder
    } else if (value[0] === null && value[1] === null) {
      label = 'Lifetime'
    } else {
      label = `${format(parseDate(value[0]), 'MMM d, yyyy')} ~ ${format(parseDate(value[1]), 'MMM d, yyyy')}`
    }

    return (
      <a
        href="#"
        className={`toggle-element${opened ? ' active' : ''}${disabled ? ' disabled' : ''}`}
        tabIndex={-1}
        role="button"
        onClick={handleToggle}
      >
        { label }
        <span className="toggle-caret" />
      </a>
    )
  }

  const renderRanges = () => {
    return (
      <div className="range-container">
        {
          ranges.map(range => (
            <a
              key={range.label}
              href="#"
              tabIndex={-1}
              role="button"
              onClick={handleRangeClick(range)}
            >
              { range.label }
              {
                typeof range.tooltip !== 'undefined' && (
                  <CustomTooltip>
                    <p>{ range.tooltip }</p>
                  </CustomTooltip>
                )
              }
            </a>
          ))
        }
      </div>
    )
  }

  const renderPicker = () => {
    if (!opened) {
      return null
    }

    let rangeValue = [undefined, undefined]
    if (value && value[0] && value[1]) {
      rangeValue = [startOfDay(parseDate(value[0])), endOfDay(parseDate(value[1]))]

      if (rangeValue[0].toString() === 'Invalid Date'
        || rangeValue[1].toString() === 'Invalid Date') {
        rangeValue = [undefined, undefined]
      }
    }

    return (
      <>
        { renderRanges() }
        <DateRangePicker
          container={rangeContainerRef.current}
          open
          ranges={[]}
          format="MMM D, YYYY"
          value={rangeValue}
          onChange={handleChange}
          onOk={handleOkClick}
        />
      </>
    )
  }

  let popupClass = 'picker-popup'
  if (opened) {
    popupClass = `${popupClass} active`
  }
  if (placement === 'bottomEnd') {
    popupClass = `${popupClass} bottom-end`
  }

  return (
    <div className="date-range-component">
      <OutsideClickHandler
        display="inline-block"
        onOutsideClick={handleOutsideClick}
      >
        { renderToggle() }
        <div
          className={popupClass}
          ref={rangeContainerRef}
        >
          { renderPicker() }
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default DateRangeComponent
