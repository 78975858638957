import React, { useMemo } from 'react'
import { getTimezoneOffset } from 'date-fns-tz'

import RuleDow from './RuleDow'

export const dowList = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday',
  'Thursday', 'Friday', 'Saturday',
]

// Convert dow by adding a time zone difference.
const convertDow = (dow, diff) => {
  if (!diff) {
    return dow
  }

  let newDow = dow += diff
  if (newDow >= 7) {
    newDow %= 7
  } else  if (newDow < 0) {
    newDow += 7
  }
  return newDow
}

const RuleSection = ({ hasSpCampaign, slots, currentTemplates,
  timezone, onChange, onAggregateRuleClear }) => {
  const rulesByDow = useMemo(() => {
    const rules = {}
    slots.forEach((slot) => {
      rules[slot.d] = slot
    })

    const currentOffset = getTimezoneOffset(timezone)
    currentTemplates.forEach((template) => {
      const templateOffset = getTimezoneOffset(template.timezone)
      const diff = Math.round((currentOffset - templateOffset) / 1000 / 60 / 60 / 24)

      template.slots.forEach((slot) => {
        const dow = convertDow(slot.d, diff)
        if (!rules[dow]) {
          rules[dow] = Object.assign({}, slot, {
            d: dow,
            template,
          })
        }
      })
    })

    return rules
  }, [slots, currentTemplates, timezone])

  return (
    <div className="rule-section">
      <div className="section-header">
        Add/Edit Rules
      </div>
      {
        dowList.map((dowName, dowNumber) => (
          <RuleDow
            key={dowNumber}
            dowName={dowName}
            dowNumber={dowNumber}
            hasSpCampaign={hasSpCampaign}
            ruleToShow={rulesByDow[dowNumber]}
            onChange={onChange}
            onAggregateRuleClear={onAggregateRuleClear}
          />
        ))
      }
    </div>
  )
}

export default RuleSection
