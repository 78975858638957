import {
  GET_DETAILS_START,
  GET_DETAILS_SUCCEED,
  GET_DETAILS_FAIL,
  UPDATE_PORTFOLIO_START,
  UPDATE_PORTFOLIO_SUCCEED,
  UPDATE_PORTFOLIO_FAIL,
  GET_ST_DATA_START,
  GET_ST_DATA_SUCCEED,
  GET_ST_DATA_FAIL,
  GET_NEGATIVE_KW_DATA_START,
  GET_NEGATIVE_KW_DATA_SUCCEED,
  GET_NEGATIVE_KW_DATA_FAIL,
  GET_BID_DATA_SUCCEED,
  GET_BID_TARGET_DATA_SUCCEED,
  CHANGE_TARGET_SUCCEED,
  GET_NEGATIVE_WORD_DATA_START,
  GET_NEGATIVE_WORD_DATA_SUCCEED,
  GET_NEGATIVE_WORD_DATA_FAIL,
  GET_NEGATIVE_TARGET_DATA_START,
  GET_NEGATIVE_TARGET_DATA_SUCCEED,
  GET_NEGATIVE_TARGET_DATA_FAIL,
  APPLY_PLACEMENT_BIDDING_SUCCEED,
  GET_KEYWORD_DATA_START,
  GET_KEYWORD_RALATED_START,
  GET_KEYWORD_RALATED_SUCCEED,
  GET_KEYWORD_RALATED_FAIL,
  GET_NEGATIVE_TARGET_START,
  GET_NEGATIVE_TARGET_SUCCEED,
  GET_NEGATIVE_TARGET_FAIL,
  GET_LOGS_SUCCEED,
  GET_ADGROUPS_SUCCEED,
} from '../actionTypes/campaignDetail'

import {
  UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED,
  UPDATE_CAMPAIGN_NAMES_SUCCEED,
  DELETE_CAMPAIGN_NOTE_SUCCEED,
  UPDATE_CAMPAIGN_STATE_SUCCEED,
  ADD_BULK_NOTES_SUCCEED,
  UPDATE_BIDDINGS_SUCCEED,
} from '../actionTypes/campaign'

import {
  ADJUST_KEYWORD_BIDS_SUCCEED,
  UPDATE_KEYWORD_STATES_SUCCEED,
} from '../actionTypes/bulkEngine'

import { REVERT_LOG_SUCCESS } from '../actionTypes/campaignLog'

import {
  SAVE_AP_SUCCESS,
  SAVE_AP_TEMPLATE_SUCCESS,
  TURN_AP_RULE_SUCCESS,
  APPLY_TEMPLATE_BULK_SUCCESS,
} from '../actionTypes/ap'

export const initialState = {
  // Dashboard
  currentAcos: 40,
  currentDetail: null,
  currentAdGroups: [],
  currentLogs: [],
  isLoading: false,
  isUpdatingPortfolio: false,
  // ST OP
  stData: [],
  isSTDataLoading: false,
  // Keyword cleaner
  negativeKWData: [],
  isNegativeKWLoading: false,
  // Bid OP
  bidData: [],
  bidTargetData: [],
  // Negative word finder
  isNegativeWordDataLoading: false,
  negativeWordData: [],
  negativeTargetData: [],
  existingNegativeData: [],
  // Keyword cleaner
  isKeywordRelatedDataLoading: false,
  keywordRelatedData: {},
}

const campaignDetail = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAILS_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DETAILS_SUCCEED:
      return {
        ...state,
        isLoading: false,
        currentAcos: action.data.acos,
        currentDetail: action.data,
      }
    case GET_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        currentAcos: null,
        currentDetail: null,
      }
    case GET_ADGROUPS_SUCCEED:
      return {
        ...state,
        currentAdGroups: action.data,
      }
    case GET_LOGS_SUCCEED:
      return {
        ...state,
        currentLogs: action.data,
      }
    case UPDATE_CAMPAIGN_NAMES_SUCCEED:
      let currentDetail = state.currentDetail
      if (state.currentDetail) {
        if (action.data.updated.includes(state.currentDetail.campaign_id.toString())) {
          const found = action.data.payload.find(record =>
            record.campaignId.toString() === state.currentDetail.campaign_id.toString()
          )
          currentDetail = {
            ...currentDetail,
            name: found.name,
          }
        }
      }
      return {
        ...state,
        currentDetail,
      }
    case UPDATE_BIDDINGS_SUCCEED:
      let newCurrentDetailAfterBid = state.currentDetail
      if (state.currentDetail) {
        const updated = action.data.find(record => (
          record.campaignId.toString() === state.currentDetail.campaign_id.toString()
        ))
        if (updated) {
          newCurrentDetailAfterBid = {
            ...newCurrentDetailAfterBid,
            bidding: updated.bidding,
          }
        }
      }
      return {
        ...state,
        currentDetail: newCurrentDetailAfterBid,
      }
    case UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED:
      let newAcos = state.currentAcos
      if (state.currentDetail) {
        const found = action.data.find(updatedCampaign =>
          updatedCampaign.campaignId.toString() === state.currentDetail.campaign_id.toString()
        )
        if (found) {
          newAcos = found.acos
        }
      }
      return {
        ...state,
        currentAcos: newAcos,
      }
    case UPDATE_PORTFOLIO_START:
      return {
        ...state,
        isUpdatingPortfolio: true,
      }
    case UPDATE_PORTFOLIO_SUCCEED:
      let detail = state.currentDetail
      if (detail) {
        if (action.data.campaignIds.includes(detail.campaign_id.toString())) {
          detail = {
            ...state.currentDetail,
            portfolio_id: action.data.portfolioId,
            portfolio_name: action.data.portfolioName,
          }
        }
      }
      return {
        ...state,
        isUpdatingPortfolio: false,
        currentDetail: detail,
      }
    case UPDATE_PORTFOLIO_FAIL:
      return {
        ...state,
        isUpdatingPortfolio: false,
      }
    case GET_ST_DATA_START:
      return {
        ...state,
        isSTDataLoading: true,
        stData: [],
      }
    case GET_ST_DATA_SUCCEED:
      return {
        ...state,
        isSTDataLoading: false,
        stData: action.data,
      }
    case GET_ST_DATA_FAIL:
      return {
        ...state,
        isSTDataLoading: false,
      }
    case GET_NEGATIVE_KW_DATA_START:
      return {
        ...state,
        isNegativeKWLoading: true,
        negativeKWData: [],
      }
    case GET_NEGATIVE_KW_DATA_SUCCEED:
      return {
        ...state,
        isNegativeKWLoading: false,
        negativeKWData: action.data,
      }
    case GET_NEGATIVE_KW_DATA_FAIL:
      return {
        ...state,
        isNegativeKWLoading: false,
        negativeKWData: [],
      }
    case GET_BID_DATA_SUCCEED:
      return {
        ...state,
        bidData: action.data,
      }
    case ADJUST_KEYWORD_BIDS_SUCCEED:
      return {
        ...state,
        bidData: (state.bidData || []).map((keyword) => {
          if (action.data.includes(keyword.keyword_id.toString())) {
            const payload = action.payload.find(record => (
              record.keywordId.toString() === keyword.keyword_id.toString())
            )
            if (payload) {
              return {
                ...keyword,
                bid: payload.bid,
              }
            }
          }
          return keyword
        }),
      }
    case UPDATE_KEYWORD_STATES_SUCCEED:
      return {
        ...state,
        bidData: (state.bidData || []).map(keyword =>
          action.data.includes(keyword.keyword_id.toString())
          ? { ...keyword, state: action.state }
          : keyword
        ),
      }
    case GET_BID_TARGET_DATA_SUCCEED:
      return {
        ...state,
        bidTargetData: action.data,
      }
    case CHANGE_TARGET_SUCCEED:
      let newBidTargetData = [...state.bidTargetData]
      if (action.data.targets && action.data.targets.length) {
        newBidTargetData.forEach((target) => {
          const changedTarget = action.data.targets.find(item => (
            item.targetId.toString() === target.targetId.toString()
          ))
          if (changedTarget) {
            if (changedTarget.state) {
              target.state = changedTarget.state
            }
            if (changedTarget.bid) {
              target.bid = changedTarget.bid
            }
          }
        })
      }
      return {
        ...state,
        bidTargetData: newBidTargetData,
      }
    case GET_NEGATIVE_WORD_DATA_START:
      return {
        ...state,
        isNegativeWordDataLoading: true,
        negativeWordData: [],
        existingNegativeData: [],
      }
    case GET_NEGATIVE_WORD_DATA_SUCCEED:
      return {
        ...state,
        isNegativeWordDataLoading: false,
        negativeWordData: action.data.searchTerms,
        existingNegativeData: action.data.negatives,
      }
    case GET_NEGATIVE_WORD_DATA_FAIL:
      return {
        ...state,
        isNegativeWordDataLoading: false,
      }
    case GET_NEGATIVE_TARGET_DATA_START:
      return {
        ...state,
        isNegativeWordDataLoading: true,
        negativeTargetData: [],
      }
    case GET_NEGATIVE_TARGET_DATA_SUCCEED:
      return {
        ...state,
        isNegativeWordDataLoading: false,
        negativeTargetData: action.data,
      }
    case GET_NEGATIVE_TARGET_DATA_FAIL:
      return {
        ...state,
        isNegativeWordDataLoading: false,
      }
    case APPLY_PLACEMENT_BIDDING_SUCCEED:
      return {
        ...state,
        currentDetail: {
          ...(state.currentDetail || {}),
          bidding: action.data.bidding,
        }
      }
    case SAVE_AP_TEMPLATE_SUCCESS:
      let detailAfterTemplateSave = state.currentDetail
      if (action.data.needApply && detailAfterTemplateSave) {
        if (action.data.campaignId.toString() === detailAfterTemplateSave.campaign_id.toString()) {
          detailAfterTemplateSave.is_ap_active = true
        }
      }
      return {
        ...state,
        currentDetail: detailAfterTemplateSave,
      }
    case TURN_AP_RULE_SUCCESS:
      let detailAfterTurnRule = state.currentDetail
      if (detailAfterTurnRule) {
        if (action.data.campaignId === detailAfterTurnRule.campaign_id.toString()
          && detailAfterTurnRule.is_ap_active !== null
          && !action.data.adgroupId) {
          detailAfterTurnRule.is_ap_active = action.data.status
        }
      }
      return {
        ...state,
        currentDetail: detailAfterTurnRule,
      }
    case SAVE_AP_SUCCESS:
    case APPLY_TEMPLATE_BULK_SUCCESS:
      let detailAfterApSave = state.currentDetail
      if (detailAfterApSave) {
        if (action.data.includes(detailAfterApSave.campaign_id.toString())) {
          detailAfterApSave.is_ap_active = true
        }
      }
      return {
        ...state,
        currentDetail: detailAfterApSave,
      }
    case GET_KEYWORD_DATA_START:
      return {
        ...state,
        negativeKWData: null,
        keywordRelatedData: null,
      }
    case GET_KEYWORD_RALATED_START:
      return {
        ...state,
        isKeywordRelatedDataLoading: true,
      }
    case GET_KEYWORD_RALATED_SUCCEED:
      return {
        ...state,
        isKeywordRelatedDataLoading: false,
        keywordRelatedData: action.data,
      }
    case GET_KEYWORD_RALATED_FAIL:
      return {
        ...state,
        isKeywordRelatedDataLoading: false,
        keywordRelatedData: null,
      }
    case REVERT_LOG_SUCCESS:
      let newCurrentDetail = state.currentDetail
      if ([
        'campaign_placement_update',
        'ap_placement',
        'campaign_placement_apply',
      ].indexOf(action.data.response.type) !== -1) {
        if (((state.currentDetail || {}).campaign_id || '').toString() === action.data.campaignId) {
          newCurrentDetail = Object.assign({}, state.currentDetail || {}, {
            bidding: action.data.response.bidding,
          })
        }
      }

      return {
        ...state,
        currentLogs: (state.currentLogs || []).map((log) => {
          if (parseInt(log.id, 10) !== parseInt(action.data.logId, 10)) {
            return log
          }
          return Object.assign({}, log, {
            reverted: true,
          })
        }),
        currentDetail: newCurrentDetail,
      }
    case GET_NEGATIVE_TARGET_START:
      return {
        ...state,
        isNegativeWordDataLoading: true,
        negativeTargetData: [],
      }
    case GET_NEGATIVE_TARGET_SUCCEED:
      return {
        ...state,
        isNegativeWordDataLoading: false,
        negativeTargetData: action.data,
      }
    case GET_NEGATIVE_TARGET_FAIL:
      return {
        ...state,
        isNegativeWordDataLoading: false,
      }
    case DELETE_CAMPAIGN_NOTE_SUCCEED:
      return {
        ...state,
        currentDetail: action.data.campaign_id.toString() === (state.currentDetail?.campaign_id || '').toString()
          ? {
            ...state.currentDetail,
            notes: state.currentDetail.notes.filter(n => n.id !== action.data.id)
          }
          : state.currentDetail,
      }
    case UPDATE_CAMPAIGN_STATE_SUCCEED:
      if (state.currentDetail
        && action.data.idsUpdated.includes(state.currentDetail.campaign_id.toString())) {
        return {
          ...state,
          currentDetail: {
            ...state.currentDetail,
            state: action.data.state,
          }
        }
      }
      return state
    case ADD_BULK_NOTES_SUCCEED:
      if (!state.currentDetail) {
        return state
      }

      const found = action.data.find(note =>
        note.campaign_id.toString() === state.currentDetail.campaign_id.toString()
      )
      if (!found) {
        return state
      }
      return {
        ...state,
        currentDetail: {
          ...state.currentDetail,
          notes: [found].concat(state.currentDetail.notes),
        }
      }
    default:
      return state
  }
}

export default campaignDetail
