import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import CheckboxComponent from '../../../CommonComponents/CheckboxComponent'
import LoaderComponent from '../../../CommonComponents/LoaderComponent'
import VideoLink from '../../../CommonComponents/VideoLink'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'

import AdgroupSelector from '../../AdgroupSelector'
import STTab from './subTabs/STTab'
import TimeSavingFilterTab from './subTabs/TimeSavingFilterTab'

import { matchTypes } from '../../../../utils/filterDef'

const TAB_BELOW = 'below'
const TAB_ABOVE = 'above'
const TAB_TIME = 'time'

const tabs = [
  {
    value: TAB_BELOW,
    label: 'Below Target ACOS',
  },
  {
    value: TAB_ABOVE,
    label: 'Above Target ACOS',
  },
  {
    value: TAB_TIME,
    label: 'Time Saving Filters',
  },
]

const videoList = [
  { title: 'Search Term Optimization Video', url: 'https://www.loom.com/embed/3af123e23f35452b8e725a8016d2b938' },
]

const STOPTab = ({ campaignType }) => {
  const isSTDataLoading = useSelector(state => state.campaignDetail.isSTDataLoading)
  const isNegativeKWLoading = useSelector(state => state.campaignDetail.isNegativeKWLoading)

  const [currentTab, setCurrentTab] = useState(TAB_ABOVE)
  const [currentAdgroup, setCurrentAdgroup] = useState(null)
  const [hideKeywords, setHideKeywords] = useState(true)
  const [hideAsins, setHideAsins] = useState(false)
  const [hideNegatedTerms, setHideNegatedTerms] = useState(true)
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])

  const renderContents = () => {
    if (currentTab === TAB_BELOW) {
      return (
        <STTab
          isProfitable
          campaignType={campaignType}
          currentAdGroupId={currentAdgroup?.adgroupid}
          hideKeywords={hideKeywords}
          hideNegatedTerms={hideNegatedTerms}
          hideAsins={hideAsins}
          selectedMatchType={selectedMatchType}
        />
      )
    }

    if (currentTab === TAB_ABOVE) {
      return (
        <STTab
          campaignType={campaignType}
          currentAdGroupId={currentAdgroup?.adgroupid}
          hideKeywords={hideKeywords}
          hideNegatedTerms={hideNegatedTerms}
          hideAsins={hideAsins}
          selectedMatchType={selectedMatchType}
        />
      )
    }

    if (currentTab === TAB_TIME) {
      return (
        <TimeSavingFilterTab
          campaignType={campaignType}
          currentAdGroupId={currentAdgroup?.adgroupid}
          hideKeywords={hideKeywords}
          hideNegatedTerms={hideNegatedTerms}
        />
      )
    }
  }

  if (campaignType === 'sd') {
    return null
  }

  const isLoading = isNegativeKWLoading || isSTDataLoading

  return (
    <div className="campaign-detail-st-op campaign-detail-tab">
      <div className="tab-info">
        <h4 className="tab-title">
          Search Term Optimization
          <CustomTooltip placement="right">
            <p>Research and optimize your campaigns at the search term level
            without ever touching a spreadsheet.</p>
            <p>Use the table on Non-Profitable Search Terms to do a deeper dive
            into search terms causing wasteful ad spend.</p>
            <p>By default, ASINs and search terms already added as negatives
            to your campaign will be hidden.</p>
            <p>Uncheck the Remove ASIN and/or New Terms Only boxes respectively to reveal them.</p>
          </CustomTooltip>
        </h4>
        <VideoLink
          videoList={videoList}
          modalTitle='Search Term Optimization'
        />
      </div>
      <div className="tab-list">
        {
          tabs.map((tab) => (
            <button
              key={tab.value}
              type="button"
              className={currentTab === tab.value ? "tab selected" : "tab"}
              onClick={() => { setCurrentTab(tab.value)}}
            >
              { tab.label }
            </button>
          ))
        }
      </div>
      <AdgroupSelector
        campaignType={campaignType}
        currentAdgroup={currentAdgroup}
        showAllAdgroup
        onAdgroupChange={setCurrentAdgroup}
      />
      <div className="filter-container">
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Keywords"
            checked={hideKeywords}
            onChange={setHideKeywords}
          />
          <CustomTooltip placement="right">
            <p>Sometimes a keyword and search term are the same
            (ex: Exact match types), many times they are not.</p>
            <p>Checking this box means ONLY actual customer search
            terms will be revealed, not keywords.</p>
            <p>Using this will help you find customer search terms that are
            resulting in wasted ad spend.</p>
            <p>This way you can consider using them as NEGATIVE EXACT for your campaigns.</p>
          </CustomTooltip>
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove already negated terms from results below"
            onChange={setHideNegatedTerms}
            checked={hideNegatedTerms}
          />
          <CustomTooltip placement="right">
            <p>Remove words already added as negative to this campaign and ad group.</p>
          </CustomTooltip>
        </div>
        {
          currentTab !== TAB_TIME && (
            <div className="checkbox-wrapper">
              <CheckboxComponent
                label="Remove ASINS"
                checked={hideAsins}
                onChange={setHideAsins}
              />
            </div>
          )
        }
        {
          currentTab !== TAB_TIME && (
            <div className="select-wrapper">
              <span>Match Type</span>
              <Select
                classNamePrefix="match-type-selector"
                options={matchTypes}
                value={selectedMatchType}
                onChange={setSelectedMatchType}
              />
            </div>
          )
        }
      </div>
      <div className={`tab-content${isLoading ? ' loading' : ''}`}>
        { isLoading && <LoaderComponent /> }
        { renderContents() }
      </div>
    </div>
  )
}

export default STOPTab
