import React, { useState } from 'react'
import Select from 'react-select'

import { negativeMatchTypeOptions } from '../../../utils/defaultValues'

const NegativeKeywordSection = ({
  adType,
  negativeKeywords,
  bidInfo,
  onChange,
}) => {
  const [matchType, setMatchType] = useState(negativeMatchTypeOptions[0])
  const [keywordList, setKeywordList] = useState('')

  const handleSelect = () => {
    let newKeywords = [...negativeKeywords]
    const enteredKeywords = keywordList.split('\n').map(keyword => keyword.trim().toLowerCase())
    enteredKeywords.forEach((keywordText) => {
      if (/^\s*$/.test(keywordText)) {
        return ''
      }

      const duplicate = negativeKeywords.find(kw => (
        keywordText === kw.keywordText
        && matchType.value === kw.matchType.toLowerCase()
      ))

      if (duplicate) {
        return
      }

      if (adType === 'sb') {
        newKeywords.push({
          matchType: matchType.value,
          keywordText,
        })
      } else {
        newKeywords.push({
          matchType: matchType.value,
          keywordText,
          keywordBid: bidInfo.defaultBid,
          state: 'enabled',
        })
      }
    })

    onChange(newKeywords)
  }

  const handleRemove = (index) => {
    let newKeywords = [...negativeKeywords]
    newKeywords.splice(index, 1)
    onChange(newKeywords)
  }

  const renderKeywords = () => {
    if (!negativeKeywords.length) {
      return (
        <div className="no-keyword-desc">
          No negative keyword added.
        </div>
      )
    }

    return (
      <div className="keyword-container">
        {
          negativeKeywords.map((keyword, index) => (
            <div key={`${keyword.keywordText}-${keyword.matchType}`} className="keyword-box">
              <div className="box-content">
                <div>
                  Keyword: {keyword.keywordText}
                </div>
                <div>
                  Match Type: {keyword.matchType}
                </div>
                <span className="close-icon" title="Remove" onClick={() => { handleRemove(index) }}>
                  &times;
                </span>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-title">
        Optional: Negative Keywords
      </div>
      <div className="section-note">
        Negative keywords prevent your ads from displaying
        when a shopper's search terms match your negative keywords.
        You can exclude irrelevant searches, reducing your advertising cost.
      </div>
      <div className="keyword-input-section">
        <div className="match-type-container">
          <label>Match Type:</label>
          <Select
            classNamePrefix="match-select"
            value={matchType}
            options={negativeMatchTypeOptions}
            onChange={(option) => { setMatchType(option) }}
          />
        </div>
        <textarea
          placeholder="Enter negative keywords separated by a new line."
          rows={5}
          value={keywordList}
          onChange={(event) => { setKeywordList(event.target.value) }}
        />
      </div>
      <button type="button" className="btn btn-blue btn-add-keyword" onClick={handleSelect}>
        Add These Negative Keywords
      </button>
      { renderKeywords() }
    </div>
  )
}

export default NegativeKeywordSection
