// Used to select Amazon accounts to sign up additional Entourage accounts.
import React, { useMemo, useState } from 'react'
import Select from 'react-select'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'

import { accountTypeWithDspOptions } from '../../utils/defaultValues'
import { amazonCountryList } from '../../utils/country'

const AccountSelector = ({ profileList, accountList, selectedProfileIds, onSelect }) => {
  const [currentType, setCurrentType] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')

  const extendedProfiles = useMemo(() => {
    const existingProfileIds = accountList.map(profile => parseInt(profile.profile_id, 10))
    const profiles = []
    profileList.forEach((profile) => {
      if (profile.accountInfo.type === 'agency'
        && profile.accountInfo.subType === 'AMAZON_ATTRIBUTION') {
        return
      }

      if (currentType && currentType.value !== '') {
        if (currentType.value === 'dsp') {
          if (!(profile.accountInfo.type === 'agency'
            && !profile.accountInfo.subType)) {
            return
          }
        } else if (currentType.value !== profile.accountInfo.type.toLowerCase()) {
          return
        }
      }

      if (currentCountry
        && currentCountry.value !== ''
        && currentCountry.value !== profile.countryCode.toUpperCase()) {
        return
      }

      profiles.push(Object.assign({}, profile, {
        id: profile.accountInfo.id,
        name: profile.accountInfo.name,
        brandName: profile.accountInfo.brandName,
        className: existingProfileIds.indexOf(profile.profileId) !== -1 ? 'disabled' : '',
      }))
    })
    return profiles.sort((a, b) => (
      a.countryCode.localeCompare(b.countryCode)
    ))
  }, [profileList, accountList, currentType, currentCountry])

  const handleSelect = (profileIds) => {
    const existingProfileIds = accountList.map(profile => parseInt(profile.profile_id, 10))
    const filtered = profileIds.filter(profileId => (
      existingProfileIds.indexOf(profileId) === -1
    ))
    onSelect(filtered)
  }

  const renderFilter = () => (
    <>
      <Select
        placeholder="Type"
        options={accountTypeWithDspOptions}
        value={currentType}
        onChange={setCurrentType}
      />
      <Select
        placeholder="Country"
        options={amazonCountryList}
        value={currentCountry}
        onChange={setCurrentCountry}
      />
    </>
  )

  const renderProfile = profile => (
    <>
      <div className="table-col col-country">
        { profile.countryCode.toUpperCase() }
      </div>
      <div className="table-col col-type">
        {
          (profile.accountInfo.type === 'agency' && !profile.accountInfo.subType)
            ? 'DSP'
            : profile.accountInfo.type
        }
      </div>
      <div className="table-col col-name" title={profile.accountInfo.name || profile.accountInfo.brandName}>
        { profile.accountInfo.name || profile.accountInfo.brandName }
      </div>
      <div className="table-col col-seller-id">
      { profile.accountInfo.id }
      </div>
    </>
  )

  return (
    <CustomTable
      className="table-accounts"
      records={extendedProfiles}
      idField="profileId"
      searchFields={['id', 'name', 'brandName']}
      selectedRecords={selectedProfileIds}
      renderRecord={renderProfile}
      renderTopRight={renderFilter}
      onChange={handleSelect}
    >
      <div className="table-col col-country">Country</div>
      <div className="table-col col-type">Type</div>
      <div className="table-col col-name">Name</div>
      <div className="table-col col-seller-id">Seller/Entity ID</div>
    </CustomTable>
  )
}

export default AccountSelector
