import React, { useState } from 'react'

import BulkResultContainer from '../BulkResultContainer'
import TargetOpKeywordTable from './TargetOpKeywordTable'
import TargetOpTargetTable from './TargetOpTargetTable'

import { keywordStatuses } from '../../utils/filterDef'
import {
  BULK_ACTION_EXPENSIVE,
  BULK_ACTION_LOW_CONVERSION,
  BULK_ACTION_NO_SALES,
} from '../../utils/defaultValues'

const TAB_KEYWORD = 'keyword'
const TAB_TARGET = 'target'

const tabList = [
  {
    value: TAB_KEYWORD,
    label: 'Keywords',
  },
  {
    value: TAB_TARGET,
    label: 'Targets',
  },
]

export const FILTER_NAME_TARGET_OP = 'bulkTargetOp'
export const FILTER_NAME_ADVANCED_OP = 'bulkAdvancedOp'

const TargetOpResult = ({ activeModule = '', campaignsById, adgroupNamesById,
  isNonEndemicAccount, onChangeDate }) => {
  const [currentTab, setCurrentTab] = useState(!isNonEndemicAccount ? TAB_KEYWORD : TAB_TARGET)
  const [selectedStatus, setSelectedStatus] = useState(keywordStatuses[0])

  let sortBy = ['cost', 'desc']
  let keyMetric = ''
  if (activeModule === BULK_ACTION_EXPENSIVE) {
    sortBy = ['cpc', 'desc']
  } else if (activeModule === BULK_ACTION_LOW_CONVERSION) {
    sortBy = ['conversion', 'asc']
    keyMetric = 'conversion'
  } else if (activeModule === BULK_ACTION_NO_SALES) {
    keyMetric = 'cost'
  }

  const renderTabs = () => {
    if (isNonEndemicAccount) {
      return null
    }

    return (
      <div className="tab-list">
        {
          tabList.map((tab) => (
            <button
              key={tab.value}
              type="button"
              className={currentTab === tab.value ? "tab selected" : "tab"}
              onClick={() => { setCurrentTab(tab.value)}}
            >
              { tab.label }
            </button>
          ))
        }
      </div>
    )
  }

  return (
    <BulkResultContainer>
      <div className="target-op-result-container">
        { renderTabs() }
        {
          (activeModule === BULK_ACTION_LOW_CONVERSION || activeModule === BULK_ACTION_NO_SALES) && (
            <div className="result-description">
              A minimum of 1 click is required to see results.
            </div>
          )
        }
        {
          currentTab === TAB_KEYWORD && (
            <TargetOpKeywordTable
              sortBy={sortBy}
              keyMetric={keyMetric}
              activeModule={activeModule}
              selectedStatus={selectedStatus}
              campaignsById={campaignsById}
              adgroupNamesById={adgroupNamesById}
              onChangeDate={onChangeDate}
              onSelectStatus={setSelectedStatus}
            />
          )
        }
        {
          currentTab === TAB_TARGET && (
            <TargetOpTargetTable
              sortBy={sortBy}
              keyMetric={keyMetric}
              activeModule={activeModule}
              selectedStatus={selectedStatus}
              campaignsById={campaignsById}
              adgroupNamesById={adgroupNamesById}
              isNonEndemicAccount={isNonEndemicAccount}
              onChangeDate={onChangeDate}
              onSelectStatus={setSelectedStatus}
            />
          )
        }
      </div>
    </BulkResultContainer>
  )
}

export default TargetOpResult
