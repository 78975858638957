import React, { useMemo, useState } from 'react'
import Select from 'react-select'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'

import { accountTypeOptions } from '../../utils/defaultValues'
import { amazonCountryList } from '../../utils/country'

const AccountSelector = ({ profileList, selectedProfileIds, isSingleRowSelect = false, onSelect }) => {
  const [currentType, setCurrentType] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')

  const extendedProfiles = useMemo(() => {
    const profiles = []
    profileList.forEach((profile) => {
      // We don't allow DSP account selection during signup.
      if (profile.accountInfo.type === 'agency') {
        return
      }

      if (currentType
        && currentType.value !== ''
        && currentType.value !== profile.accountInfo.type.toLowerCase()) {
        return
      }

      if (currentCountry
        && currentCountry.value !== ''
        && currentCountry.value !== profile.countryCode.toUpperCase()) {
        return
      }

      profiles.push(Object.assign({}, profile, {
        id: profile.accountInfo.id,
        name: profile.accountInfo.name,
        brandName: profile.accountInfo.brandName,
      }))
    })
    return profiles.sort((a, b) => (
      a.countryCode.localeCompare(b.countryCode)
    ))
  }, [profileList, currentType, currentCountry])

  const renderFilter = () => (
    <>
      <Select
        placeholder="Type"
        options={accountTypeOptions}
        value={currentType}
        onChange={setCurrentType}
      />
      <Select
        placeholder="Country"
        options={amazonCountryList}
        value={currentCountry}
        onChange={setCurrentCountry}
      />
    </>
  )

  const renderProfile = profile => (
    <>
      <div className="table-col col-country">
        { profile.countryCode.toUpperCase() }
      </div>
      <div className="table-col col-type">
        { profile.accountInfo.type }
      </div>
      <div className="table-col col-name" title={profile.accountInfo.name || profile.accountInfo.brandName}>
        { profile.accountInfo.name || profile.accountInfo.brandName }
      </div>
      <div className="table-col col-seller-id">
      { profile.accountInfo.id }
      </div>
    </>
  )

  return (
    <CustomTable
      className="table-accounts"
      records={extendedProfiles}
      idField="profileId"
      searchFields={['id', 'name', 'brandName']}
      selectedRecords={selectedProfileIds}
      isSingleRowSelect={isSingleRowSelect}
      renderTopRight={renderFilter}
      renderRecord={renderProfile}
      onChange={onSelect}
    >
      <div className="table-col col-country">Country</div>
      <div className="table-col col-type">Type</div>
      <div className="table-col col-name">Name</div>
      <div className="table-col col-seller-id">Seller/Entity ID</div>
    </CustomTable>
  )
}

export default AccountSelector
