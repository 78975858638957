// Dayparting manager.
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import Header from './Header'
import SlotSelector from './SlotSelector'
import RuleSection from './RuleSection'
import Footer from './Footer'
import CampaignSelector from '../RuleManagerComponents/CampaignSelector'

import {
  turnRules,
  hideRuleManager,
  updateCampaignSelection,
} from '../../redux/actions/ruleManager'
import {
  getDpStats,
  loadDpRules,
  saveDpRules,
  saveDpTemplate,
} from '../../redux/actions/dayparting'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'
import { RULE_TYPE_DP } from '../../utils/defaultValues'
import { ignoreOutsideClick } from '../../services/helper'

/**
 * @param {Array} slots The current rule state
 * @param {Array} slotSelection The selected slot numbers
 * @param {Array} rules The new rules to apply, or an empty array to clear.
 * @returns
 */
export const compileRules = (slots, slotSelection, rules) => {
  const existingSlots = []
  let selection = [...slotSelection]
  slots.forEach((slot) => {
    if (slot.campaign) {
      // When multiple campaigns are selected,
      // do not touch existing rules.
      existingSlots.push(slot)

      selection = selection.filter(slotNumber => (
        !slot.s.includes(slotNumber)
      ))
      return
    }

    // Find existing rules that are applied
    // to any of selected slots.
    const isIncluding = selection.find(slotNumber => (
      slot.s.includes(slotNumber)
    ))
    if (typeof isIncluding === 'undefined') {
      // If a rule has nothing to do with selected slots,
      // keep it as it is.
      existingSlots.push(slot)
    } else {
      // If a rule is applied to some of selected slots,
      // update the rule not to be applied to selected slots,
      // because below, we will add a new rule for selected slots.
      const newSlots = slot.s.filter(slotNumber =>
        !selection.includes(slotNumber)
      )
      if (newSlots.length) {
        existingSlots.push(Object.assign({}, slot, {
          s: newSlots,
        }))
      }
    }
  })

  if (rules.length && selection.length) {
    // Add a new rule for selected slots.
    return [
      ...existingSlots,
      {
        s: selection,
        r: rules,
      },
    ]
  }
  // Clear rules
  return [...existingSlots]
}

const DaypartingManager = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)
  const campaigns = useSelector(state => state.ruleManager.campaigns)

  // Indicate if a rule is saved to database.
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRules, setIsLoadingRules] = useState(true)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

  const [isOn, setIsOn] = useState(false)
  const [timezone, setTimezone] = useState(
    currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [lookback, setLookback] = useState(30)
  const [stats, setStats] = useState([])
  const [currentTemplates, setCurrentTemplates] = useState([])
  const [activeTab, setActiveTab] = useState(!isNonEndemicAccount ? 'acos' : 'impressions')
  const [slots, setSlots] = useState([])
  const [slotSelection, setSlotSelection] = useState([])
  const [ruleToShow, setRuleToShow] = useState(null)

  const [isCampaignSelectorVisible, setCampaignSelectorVisible] = useState(false)

  // Load existing rules.
  useEffect(() => {
    if (!campaigns.length) {
      setIsLoadingRules(false)
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(loadDpRules(
        accessToken,
        campaigns.map(campaign => campaign.campaign_id)
      ))
      let newIsOn = true
      let newTimezone
        = currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      let newLookback = 30
      let newSlots = []
      let newCurrentTemplates = []

      if (response.length){
        response.forEach((record) => {
          let campaign = null
          if (campaigns.length > 1) {
            campaign = campaigns.find(c =>
                c.campaign_id.toString() === record.campaign_id
            )
          }

          newIsOn = record.status
          if (record.timezone) {
            newTimezone = record.timezone
          }
          if (record.lookback) {
            newLookback = record.lookback
          }
          if (record.slots) {
            newSlots = newSlots.concat(record.slots.map(slot =>
                Object.assign({}, slot, {
                  campaign,
                })
            ))
          }
          if (record.templates) {
            newCurrentTemplates = newCurrentTemplates.concat(
                record.templates.map(template =>
                    Object.assign({}, template, {
                      campaign,
                    })
                )
            )
          }
        })
      }
      else {
        newIsOn = false
      }


      const templateMap = new Map(newCurrentTemplates.map(template =>
        [template.id, template]
      ))

      setIsSaved(response.length !== 0)

      setIsOn(newIsOn)
      setTimezone(newTimezone)
      setLookback(newLookback)
      setSlots(newSlots)
      // Remove duplicate templates.
      setCurrentTemplates([...templateMap.values()])

      setIsLoadingRules(false)
    })()
  }, [dispatch, campaigns]) // eslint-disable-line

  // Load stats by day of week and hour.
  useEffect(() => {
    const spCampaignIds = []
    const sdCampaignIds = []
    const sbCampaignIds = []
    campaigns.forEach((campaign) => {
      if (campaign.campaignType === 'sp') {
        spCampaignIds.push(campaign.campaign_id)
      } else if (campaign.campaignType === 'sd') {
        sdCampaignIds.push(campaign.campaign_id)
      } else {
        sbCampaignIds.push(campaign.campaign_id)
      }
    })

    let abortCtrl
    if (campaigns.length && !isLoadingRules) {
      abortCtrl = new AbortController();

      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        dispatch(getDpStats(
          accessToken,
          spCampaignIds,
          sdCampaignIds,
          sbCampaignIds,
          lookback,
          timezone,
          abortCtrl.signal,
        )).then((response) => {
          setIsLoading(false)
          setStats(response.data)
        }).catch((error) => {
          if (error.code !== 'ERR_CANCELED') {
            setIsLoading(false)
          }
        })
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [dispatch, campaigns, lookback, timezone, isLoadingRules]) // eslint-disable-line

  const hasSpCampaign = useMemo(() => {
    return typeof campaigns.find(campaign =>
      campaign.campaignType === 'sp'
    ) !== 'undefined'
  }, [campaigns])

  const isSavable = useMemo(() => {
    return slots.length > 0 || currentTemplates.length > 0
  }, [slots, currentTemplates])

  const isDeletable = useMemo(() => {
    let deletableSlots = []
    let nonDeletableSlots = []
    if (campaigns.length > 1) {
      // When multiple campaigns are selected,
      // only new rules can be deleted.
      slots.forEach((slot) => {
        if (!slot.campaign) {
          deletableSlots.push(slot)
        } else {
          nonDeletableSlots.push(slot)
        }
      })
    } else {
      deletableSlots = slots
    }

    if (slotSelection.length) {
      const hasDeletable = typeof deletableSlots.find((slot) => (
        // Find existing rules that are applied to any of selected slots.
        typeof slotSelection.find(slotNumber => (
          slot.s.includes(slotNumber)
        )) !== 'undefined'
      )) !== 'undefined'

      const hasNonDeletable = typeof nonDeletableSlots.find((slot) => (
        typeof slotSelection.find(slotNumber => (
          slot.s.includes(slotNumber)
        )) !== 'undefined'
      )) !== 'undefined'

      return hasDeletable && !hasNonDeletable
    }
    return deletableSlots.length > 0
      && !nonDeletableSlots.length
  }, [campaigns, slotSelection, slots])

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  // When clicking outside of the manager panel.
  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  const handleIsOnChange = async (value) => {
    setIsUpdatingStatus(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(turnRules(
      accessToken,
      RULE_TYPE_DP,
      campaigns.map(campaign => campaign.campaign_id)
    ))
    if (response) {
      setIsOn(value)
    }
    setIsUpdatingStatus(false)
  }

  // When removing a campaign from header.
  const handleCampaignRemove = (campaignId) => {
    dispatch(updateCampaignSelection(
      campaigns.filter(campaign => campaign.campaign_id !== campaignId)
    ))
  }

  // When slots are selected in the slot selector.
  const handleSlotSelect = (selectedSlots, selectedRule) => {
    setSlotSelection(selectedSlots)
    setRuleToShow(selectedRule)
  }

  // When changing rules in the rule section.
  const handleRulesChange = (rules) => {
    setSlots(prev => compileRules(prev, slotSelection, rules))
  }

  // Delete applied rules.
  const handleRulesDelete = () => {
    if (slotSelection.length) {
      setSlots(prev => compileRules(prev, slotSelection, []))
    } else {
      setSlots([])

      toast.show({
        title: 'Info',
        description: 'You need to click on `Save Rules` button '
          + 'to save your rules.',
        duration: 5000,
      })
    }
  }

  const handleApply = async () => {
    if (!campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    let filteredSlots = slots
    if (campaigns.length > 1) {
      // When multiple campaigns are selected, only
      // new rules are passed to backend.
      filteredSlots = filteredSlots.filter(slot => !slot.campaign)
    }

    filteredSlots = filteredSlots.map((slot) => {
      const slotWithoutCampaign = Object.assign({}, slot)
      if (typeof slotWithoutCampaign.campaign !== 'undefined') {
        delete slotWithoutCampaign.campaign
      }
      return slotWithoutCampaign
    })

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveDpRules(
      accessToken,
      campaigns.map(campaign => campaign.campaign_id),
      isOn,
      timezone,
      lookback,
      filteredSlots,
      currentTemplates.map(template => template.id),
    ))
    setIsSaved(true)
    setIsLoading(false)
  }

  const handleTemplateSave = async (name, needApply) => {
    if (needApply && !campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    const accessToken = await getAccessTokenSilently()
    setIsLoading(true)
    const templateSaved = await dispatch(saveDpTemplate(
      accessToken,
      name,
      timezone,
      slots,
      needApply,
      campaigns.map(campaign => campaign.campaign_id),
    ))

    setIsLoading(false)
    if (needApply && templateSaved) {
      setCurrentTemplates(prev => ([
        ...prev,
        templateSaved,
      ]))
    }
  }

  // When templates are selected from the template selector.
  const handleTemplateChange = (templates) => {
    setCurrentTemplates(templates)

    toast.show({
      title: 'Info',
      description: 'You need to click on `Save Rules` button '
        + 'to save your selection and apply the template.',
      duration: 5000,
    })
  }

  const handleApplyMultiple = () => {
    setCampaignSelectorVisible(true)
  }

  const handleApplyToMultiple = async (campaignIds) => {
    const accessToken = await getAccessTokenSilently()
    setIsLoading(true)
    dispatch(saveDpRules(
      accessToken,
      campaignIds,
      isOn,
      timezone,
      lookback,
      slots,
      currentTemplates.map(template => template.id),
    )).then(() => {
      setIsLoading(false)
    })
  }

  const renderBody = () => {
    if (!isCampaignSelectorVisible) {
      return (
        <>
          <div className="pane-body">
            <SlotSelector
              activeTab={activeTab}
              stats={stats}
              slots={slots}
              currentTemplates={currentTemplates}
              timezone={timezone}
              selectedSlots={slotSelection}
              onSlotSelect={handleSlotSelect}
            />
            {
              slotSelection.length > 0 && (
                <RuleSection
                  hasSpCampaign={hasSpCampaign}
                  ruleToShow={ruleToShow}
                  onChange={handleRulesChange}
                />
              )
            }
          </div>
          <Footer
            isLoading={isLoading || isLoadingRules}
            isSavable={isSavable}
            isDeletable={isDeletable}
            deleteForAll={slotSelection.length === 0}
            hasTemplates={currentTemplates.length > 0}
            onApply={handleApply}
            onApplyToMultiple={handleApplyMultiple}
            onSaveTemplate={handleTemplateSave}
            onTemplateChange={handleTemplateChange}
            onDelete={handleRulesDelete}
            onClose={handleClose}
          />
        </>
      )
    }

    return (
      <CampaignSelector
        isSaving={isLoading}
        onApply={handleApplyToMultiple}
        onCancel={() => { setCampaignSelectorVisible(false) }}
      />
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="dayparting-manager-component">
        { (isLoading || isLoadingRules || isUpdatingStatus) && <LoaderComponent className="rule-loader" /> }
        <Header
          campaigns={campaigns}
          isSaved={isSaved}
          isOn={isOn}
          timezone={timezone}
          lookback={lookback}
          currentTemplates={currentTemplates}
          activeTab={activeTab}
          isCampaignSelectorVisible={isCampaignSelectorVisible}
          isUpdatingStatus={isUpdatingStatus}
          isLoadingRules={isLoadingRules}
          isMultiCampaignSelection={campaigns.length > 1}
          onIsOnChange={handleIsOnChange}
          onTimezoneChange={setTimezone}
          onLookbackChange={setLookback}
          onTabChange={setActiveTab}
          onCampaignRemove={handleCampaignRemove}
          onTemplateChange={handleTemplateChange}
          onClose={handleClose}
        />
        { renderBody() }
      </div>
    </OutsideClickHandler>
  )
}

export default DaypartingManager
