import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'

import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import { formatCurrency, formatValue } from '../../../services/helper'

const metricList = [
  { name: 'PPC Revenue', key: 'revenue', color: '#fbab34' },
  { name: 'Ad Spend', key: 'cost', color: '#cfd2f5' },
  { name: 'Orders', key: 'orders', color: '#3dade8' },
  { name: 'Clicks', key: 'clicks', color: '#25c835' },
  { name: 'Impressions', key: 'impressions', color: '#93df8d' },
  { name: 'ACoS %', key: 'acos', color: '#ffd156' },
  { name: 'Ave CPC', key: 'cpc', color: '#072257' },
  { name: 'Conv %', key: 'conversion', color: '#ff7b56' },
]

const StreamChart = ({ stats }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const visibility = {}
  metricList.forEach((metric) => {
    // By default, turn off line chart for impressions.
    visibility[metric.key] = metric.key !== 'impressions'
  })

  const [metricVisibility, setMetricVisibility] = useState(visibility)

  const metricsToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return metricList
    }
    return metricList.filter(metric => (
      ![
        'revenue',
        'acos',
        'orders',
        'conversion',
      ].includes(metric.key)
    ))
  }, [isNonEndemicAccount])

  const handleMetricToggle = key => () => {
    const visibility = Object.assign({}, metricVisibility, {
      [key]: !metricVisibility[key]
    })
    setMetricVisibility(visibility)
  }

  const formatter = (value, name) => {
    if (name === 'PPC Revenue' || name === 'Ad Spend' || name === 'Ave CPC') {
      return formatCurrency(value, currencySign, currencyRate)
    }
    if (name === 'ACoS %' || name === 'Conv %') {
      return formatValue(value, 'percent', 1)
    }
    return formatValue(value, 'number', 0)
  }

  const renderLegend = ({ payload }) => (
    <ul className="legend-list">
      {
        payload.map((entry) => {
          const style = {
            borderColor: entry.payload.stroke,
          }

          if (metricVisibility[entry.dataKey]) {
            style.backgroundColor = entry.payload.stroke
          }

          return (
            <li key={entry.dataKey} onClick={handleMetricToggle(entry.dataKey)}>
              <span className="bullet" style={style} />
              { entry.value }
            </li>
          )
        })
      }
    </ul>
  )

  return (
    <div className="campaign-chart-component">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={300}
          data={stats}
          margin={{
            top: 10,
            right: 30,
            left: -25,
            bottom: 0,
          }}
        >
          <XAxis dataKey="label" />
          <YAxis />
          <Tooltip formatter={formatter} />
          {
            metricsToRender.map(metric => (
              <Line
                key={metric.key}
                type="monotone"
                name={metric.name}
                dataKey={metric.key}
                stroke={metric.color}
                strokeWidth={2}
                dot={{ r: 2, fill: metric.color }}
                activeDot={true}
                hide={!metricVisibility[metric.key]}
              />
            ))
          }
          <Legend
            content={renderLegend}
            wrapperStyle={{
              bottom: -5,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StreamChart
