import React, { useState } from 'react'

import ProductList from './ProductList'
import ProductModal from './ProductModal'

const ProductSection = ({ type, products, onChange }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleRemove = (id) => {
    onChange(products.filter(product =>
      product.id.toString() !== id.toString()
    ))
  }

  const handleSelect = (products) => {
    setOpenModal(false)
    onChange(products)
  }

  const renderDescription = () => {
    if (type === 'sb' && !products.length < 3) {
      return (
        <div className="no-product-desc">
          Add 3 or more products to your landing page.<br />
          You can select which products to advertise in the Creative step.
          At least 1 product on your landing page must remain in stock,
          or your campaign will be paused.
        </div>
      )
    }

    if (type === 'sbv' && products.length) {
      return (
        <div className="no-product-desc">
          You have added the maximum number of products.
        </div>
      )
    }

    return null
  }

  return (
    <div className="section-container">
      <div className="section-title">
        Products
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setOpenModal(true) }}
        >
          Add Products
        </button>
      </div>
      <div className="section-note">
        Add products that you want to promote in this campaign.
      </div>
      <ProductList
        products={products}
        onRemove={handleRemove}
      />
      { renderDescription() }
      <ProductModal
        show={openModal}
        productsSelected={products}
        onSelect={handleSelect}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default ProductSection
