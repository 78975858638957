import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Row,
  Col,
  Uploader,
  Icon,
  Divider,
  Button,
  Dropdown,
  DateRangePicker,
  IconButton,
  RadioGroup,
  Radio,
  TagPicker,
} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { differenceInDays, format, subDays } from 'date-fns'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'
import GeneratedReportsModal from '../../components/GeneratedReportModal'

import SaveTemplateModal from './SaveTemplateModal'
import TemplatePicker from './TemplatePicker'

import {
  setReportDateRange,
  generateReport,
  saveTemplate,
  getTemplates,
  deleteTemplate,
} from '../../redux/actions/accountHealth'

import { checkStatus } from '../../redux/actions/job'
import { loadReportTemplate } from '../../redux/actions/pageGlobal'
import { selectCurrentAccount } from '../../redux/reducers/header'

import { getTemporaryDownloadLink, parseDate } from '../../services/helper'

const { afterToday } = DateRangePicker

const DATE_RANGE_CUSTOM = 'custom'
const DATE_RANGE_WEEKLY = 'week'
const DATE_RANGE_MONTHLY = 'month'

const ReportHeader = ({
  settings,
  onLoadStats,
  onSaveLogo,
  onDeleteLogo,
  onUpdateBrandTerms,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const reportSectionSetting = useSelector(state => state.pageGlobal.reportSectionSetting)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const statusCheckerInterval = useRef(null)

  const [isUpdatingBrandTerms, setIsUpdatingBrandTerms] = useState(false)
  const [uploadFile, setUploadFile] = useState([])
  const [currentStartDate, setCurrentStartDate] = useState(startDate)
  const [currentEndDate, setCurrentEndDate] = useState(endDate)
  const [logoBase64String, setLogoBase64String] = useState('')
  const [dateRangeType, setDateRangeType] = useState('month')
  const [editBrandName, setEditBrandName] = useState(false)
  const [brandTerms, setBrandTerms] = useState([])
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [showGeneratedReportsModal, setShowGeneratedReportsModal] = useState(false)
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false)
  const [showTemplatePickerModal, setShowTemplatePickerModal] = useState(false)

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      if (statusCheckerInterval.current) {
        clearInterval(statusCheckerInterval.current) // eslint-disable-line
      }
    }
  }, [])

  useEffect(() => {
    if (settings && settings.brand_terms) {
      setBrandTerms(settings.brand_terms.split(','))
    }
  }, [settings])

  const handleChangeUpload = (files) => {
    setUploadFile(files)
    const reader = new FileReader();
    reader.onload = function () {
      setLogoBase64String(reader.result)
    }
    reader.readAsDataURL(files[0].blobFile)
  }

  const handleChangeDateRange = (dates) => {
    const newStart = parseDate(dates[0])
    const newEnd = parseDate(dates[1])

    if (newStart.toString() === 'Invalid Date'
      || newEnd.toString() === 'Invalid Date') {
      return
    }

    dispatch(setReportDateRange(
      dates[0],
      dates[1],
    ))
    setCurrentStartDate(dates[0])
    setCurrentEndDate(dates[1])
  }

  const handleClickUploadLogo = async () => {
    const extension = uploadFile[0].name.split('.').pop()
    await onSaveLogo(logoBase64String, extension)
    setUploadFile([])
  }

  const handleGenerate = async (exportType) => {
    setIsGeneratingReport(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(generateReport(accessToken, startDate, endDate, exportType)).then((response) => {
      statusCheckerInterval.current = setInterval(async () => {
        // @todo: Prevent another status check API call
        // while a previous one is in flight.
      const accessToken = await getAccessTokenSilently()
      dispatch(checkStatus(accessToken, response.data.jobId)).then(({ url }) => {
          if (url) {
            toast.show({
              title: 'Success',
              description: 'The report has been generated. '
                + `You can <a href="${getTemporaryDownloadLink(url)}" target="_blank">download it here</a>, `
                + 'or browse it from the "Generated Reports" modal.',
              duration: 10000,
            })

            setIsGeneratingReport(false)
            clearInterval(statusCheckerInterval.current)
            statusCheckerInterval.current = null
          }
        }).catch((error) => {
          toast.show({
            title: 'Danger',
            description: error,
          })

          setIsGeneratingReport(false)
          clearInterval(statusCheckerInterval.current)
          statusCheckerInterval.current = null
        })
      }, 5000)
    }).catch((error) => {
      setIsGeneratingReport(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve generated reports.',
      })
    })
  }

  const handleClickRemoveLogo = async () => {
    await onDeleteLogo()
    setUploadFile([])
  }

  const handleSaveNewBrandName = async () => {
    try {
      setIsUpdatingBrandTerms(true)
      await onUpdateBrandTerms(brandTerms)
      setIsUpdatingBrandTerms(false)
      setEditBrandName(false)

      onLoadStats('all')
    } catch {
      setEditBrandName(false)
    }
  }

  const handleCancelEditingBrandName = () => {
    setEditBrandName(false)
    if (settings && settings.brand_terms) {
      setBrandTerms(settings.brand_terms.split(','))
    } else {
      setBrandTerms([])
    }
  }

  const handleTemplateSave = async (name) => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(saveTemplate(accessToken, name, {
      reportWidgetSetting: reportWidgetSetting || {},
      reportSectionSetting: reportSectionSetting || {},
    }))
  }

  const handleTemplatesLoad = async () => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(getTemplates(accessToken))
  }

  const handleTemplateSelect = (contents) => {
    dispatch(loadReportTemplate(contents))
    setShowTemplatePickerModal(false)
  }

  const handleTemplateDelete = async (id) => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(deleteTemplate(accessToken, id))
  }

  const [
    currentDurationText,
    prevDurationText,
  ] = useMemo(() => {
    const startDt = parseDate(startDate)
    const endDt = parseDate(endDate)
    const duration = differenceInDays(endDt, startDt)
    return [
      `${format(startDt, 'MM/dd/yyyy')} and ${format(endDt, 'MM/dd/yyyy')}`,
      `${format(subDays(startDt, 1 + duration), 'MM/dd/yyyy')} and `
        + `${format(subDays(startDt, 1), 'MM/dd/yyyy')}`
    ]
  }, [startDate, endDate])

  const renderLogo = () => {
    if (uploadFile.length) {
      return null;
    }

    if (settings && settings.logo_url) {
      return (
        <div className="saved-report-brand-logo">
          <img src={getTemporaryDownloadLink(settings.logo_url)} alt="Brand Logo" />
        </div>
      )
    }

    return (
      <div className="upload-helper-text">
        Upload Brand Logo Image
      </div>
    )
  }

  const renderUploader = () => (
    <>
      <Uploader
        className="brand-logo-upload"
        disabled={uploadFile.length > 0 || (settings && !!settings.logo_url)}
        multiple={false}
        accept="image/*"
        listType="picture"
        autoUpload={false}
        fileList={uploadFile}
        onChange={handleChangeUpload}
      >
        <button>
          <Icon icon="camera" title="Upload" />
        </button>
      </Uploader>
      {
        (uploadFile.length > 0 && (!settings || !settings.logo_url)) && (
          <div className="upload-save-btn">
            <Button color="green" size="xs" onClick={handleClickUploadLogo}>
              Upload Logo
            </Button>
          </div>
        )
      }
      {
        (settings && settings.logo_url) && (
          <IconButton
            className="remove-brand-logo-btn"
            icon={<Icon icon="trash" title="Remove" />}
            appearance="subtle"
            onClick={handleClickRemoveLogo}
          />
        )
      }
    </>
  )

  const renderDownloadDropdown = () => (
    <Dropdown
      title="Download"
      placement="bottomEnd"
      disabled={isGeneratingReport}
      renderTitle={children => (
        <Button appearance="primary">
          { isGeneratingReport ? 'Generating report...' : children }
        </Button>
      )}
    >
      <Dropdown.Item
        icon={<Icon icon="file-pdf-o" />}
        onSelect={() => { handleGenerate('pdf') }}
      >
        Generate PDF
      </Dropdown.Item>
      <Dropdown.Item
        icon={<Icon icon="file-excel-o" />}
        onSelect={() => { handleGenerate('csv') }}
      >
        Generate CSV
      </Dropdown.Item>
      <Dropdown.Item divider />
      <Dropdown.Item
        icon={<Icon icon="list" />}
        onSelect={() => setShowGeneratedReportsModal(true)}
      >
        Generated Reports
      </Dropdown.Item>
    </Dropdown>
  )

  const renderTemplateDropdown = () => (
    <Dropdown
      title="Templates"
      placement="bottomEnd"
    >
      <Dropdown.Item
        onSelect={() => { setShowSaveTemplateModal(true) }}
      >
        Save as New Template
      </Dropdown.Item>
      <Dropdown.Item
        onSelect={() => { setShowTemplatePickerModal(true) }}
      >
        Load a Template
      </Dropdown.Item>
    </Dropdown>
  )

  return (
    <div className="report-header">
      <Grid fluid>
        <Row>
          <Col lg={6} className="report-logo-section">
            { renderLogo() }
            { renderUploader() }
          </Col>
          <Col lg={9} lgPush={9} className="report-date">
            <div className="account-report-date">
              <RadioGroup
                name="date-range-type-list"
                className="flex"
                value={dateRangeType}
                onChange={setDateRangeType}
              >
                <Radio value={DATE_RANGE_CUSTOM}>Custom</Radio>
                <Radio value={DATE_RANGE_WEEKLY}>Weekly</Radio>
                <Radio value={DATE_RANGE_MONTHLY}>Monthly</Radio>
              </RadioGroup>
              <div className="header-row date-range-container">
                <DateRangePicker
                  value={[currentStartDate, currentEndDate]}
                  ranges={[]}
                  limitEndYear={0}
                  disabledDate={afterToday()}
                  oneTap={dateRangeType !== DATE_RANGE_CUSTOM}
                  hoverRange={dateRangeType}
                  showOneCalendar={dateRangeType !== DATE_RANGE_CUSTOM}
                  onChange={handleChangeDateRange}
                />
              </div>
              { renderDownloadDropdown() }
              { renderTemplateDropdown() }
            </div>
            <span>
              vs the period between { prevDurationText }
            </span>
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col xs={16}>
            <div className="account-meta-info">
              {editBrandName ? (
                <div className="account-brand-name">
                  <span>BRAND TERMS:</span>
                  <TagPicker
                    data={brandTerms.map(t => ({label: t, value: t}))}
                    creatable
                    style={{ width: 350, marginRight: 5, marginLeft: 10 }}
                    size="xs"
                    value={brandTerms}
                    onChange={(value) => { setBrandTerms(value || []) }}
                  />
                  <IconButton
                    className="new-brand-name-save-btn"
                    icon={<Icon icon="check" />}
                    appearance="primary"
                    size="xs"
                    onClick={handleSaveNewBrandName}
                    loading={isUpdatingBrandTerms}
                  />
                  <IconButton
                    className="new-brand-name-discard-btn"
                    icon={<Icon icon="close" />}
                    appearance="subtle"
                    size="xs"
                    onClick={handleCancelEditingBrandName}
                  />
                  <CustomTooltip placement="top">
                    <p>
                      Update brand terms to see branded vs non branded sales below.
                      You can add as many terms as you like.
                      If they keyword or search term contains that word,
                      we will include it in branded sales.
                    </p>
                  </CustomTooltip>
                </div>
              ) : (
                <div className="account-brand-name">
                  <span>
                    BRAND TERMS: {brandTerms.join(', ')}
                  </span>
                  <IconButton
                    icon={<Icon icon="pencil" />}
                    appearance="subtle"
                    size="xs"
                    onClick={() => setEditBrandName(true)}
                  />
                  <CustomTooltip placement="top">
                    <p>
                      Update brand terms to see branded vs non branded sales below.
                      You can add as many terms as you like.
                      If they keyword or search term contains that word,
                      we will include it in branded sales.
                    </p>
                  </CustomTooltip>
                </div>
              )}
              <div>
                MARKETPLACE: {(currentAccount?.country_id || '').toUpperCase()}
              </div>
            </div>
          </Col>
          <Col xs={8}>
            <div className="report-helper-text">
              <span>
                All data below is for the period between { currentDurationText }
              </span>
            </div>
          </Col>
        </Row>
      </Grid>
      {
        showGeneratedReportsModal && (
          <GeneratedReportsModal
            reportType="account_report"
            onClose={() => { setShowGeneratedReportsModal(false) }}
          />
        )
      }
      <SaveTemplateModal
        show={showSaveTemplateModal}
        onSave={handleTemplateSave}
        onCancel={() => { setShowSaveTemplateModal(false) }}
      />
      <TemplatePicker
        show={showTemplatePickerModal}
        onLoad={handleTemplatesLoad}
        onSelect={handleTemplateSelect}
        onDelete={handleTemplateDelete}
        onCancel={() => { setShowTemplatePickerModal(false) }}
      />
    </div>
  )
}

export default ReportHeader
