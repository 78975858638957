import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportPerformanceWidget from './ReportPerformanceWidget'

import { parsePerformanceSummary } from '../../services/helper'

const PerformanceByBidType = ({ showSection, onToggle }) => {
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const placementSummary = useSelector(state => state.accountHealth.performanceData.placementSummary)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])

  useEffect(() => {
    const performanceByBidType = reportWidgetSetting ? reportWidgetSetting.performanceByBidType : []
    setViewWidgets(
      performanceByBidType.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const { bidTotal } = useMemo(() => {
    return parsePerformanceSummary(placementSummary)
  }, [placementSummary])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const renderWidget = (widget) => {
    let suffix
    switch (widget.key) {
      case 'legacy':
        suffix = 'Legacy'
        break
      case 'auto':
        suffix = 'Auto'
        break
      case 'manual':
        suffix = 'Manual'
        break
      default:
        return null
    }

    return (
      <ReportPerformanceWidget
        key={widget.key}
        widget={widget}
        data={{
          ctr: bidTotal[`ctr${suffix}`],
          clicks: bidTotal[`clicks${suffix}`],
          impressions: bidTotal[`impressions${suffix}`],
          conversion: bidTotal[`conv${suffix}`],
          orders: bidTotal[`orders${suffix}`],
          acos: bidTotal[`acos${suffix}`],
          revenue: bidTotal[`revenue${suffix}`],
          cpc: bidTotal[`cpc${suffix}`]
        }}
        customizing={customizingSection}
        onRemove={handleOnRemoveWidget}
        section="performanceByBidType"
      />
    )
  }

  return (
    <div>
      <SectionHeader
        title="Performance By Bid Type"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
      />
      {
        showSection && (
          <>
            {
              viewWidgets.map(w => (w.view || customizingSection) ? (
              renderWidget(w)) : null)
            }
          </>
        )
      }
    </div>
  )
}

export default withReportSection(PerformanceByBidType, {
  sectionId: 'performanceByBidType',
  reportSectionKey: 'performanceByBidType',
})
