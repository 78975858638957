import React, { useState, useEffect } from 'react'
import { Input, List, Icon, Modal } from 'rsuite'
import Select from 'react-select'

import { getCampaignColumns } from '../../services/dspHelper'

import {
  dspCampaignColumnList,
  dspCampaignColumnSetting,
} from '../../utils/dspDefaultValues'

const DspColumnSettingModal = ({ columns, onApply, onClose }) => {
  const [preSetting, setPreSetting] = useState(null)
  const [columnSearchKey, setColumnSearchKey] = useState('')
  const [columnList, setColumnList] = useState([])

  useEffect(() => {
    if (columns.length) {
      setColumnList(columns)
    }
  }, [columns])

  useEffect(() => {
    if (preSetting) {
      setColumnList(getCampaignColumns(preSetting.setting))
    }
  }, [preSetting])

  const handleSortColumns = ({ oldIndex, newIndex }) => {
    const moveColumn = columnList.splice(oldIndex, 1)
    const newColumns = [...columnList]
    newColumns.splice(newIndex, 0, moveColumn[0])
    setColumnList([...newColumns])
  }

  const handleAddColumn = (column) => {
    setColumnList(prev => ([...prev, column]))
  }

  const handleRemoveColumn = (column) => {
    setColumnList(p => p.filter(s => s.key !== column.key))
  }


  return (
    <Modal className="dsp-column-setting-modal" backdrop="static" show={true} size="md" overflow={false}>
      <Modal.Header onHide={onClose}>
        <Modal.Title>
          DSP Column Setting
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="setting-content">
        <div className="setting-option">
          <div className="setting-column-list">
            <span className="box-label">Columns</span>
            <Input
              className="column-search-input"
              placeholder="Type to Search"
              value={columnSearchKey}
              onChange={setColumnSearchKey}
            />
            <List className="campaign-columns">
              {
                (dspCampaignColumnList || []).filter(c1 =>
                  (columnSearchKey === '' || c1.label.toLowerCase().includes(columnSearchKey.toLowerCase()))
                  && !columnList.find(c2 => c2.key === c1.key)
                ).map((column, index) => (
                  <List.Item
                    key={column.key}
                    className="column-item"
                    index={index}
                    collection={column.fixed ? 0 : 1}
                  >
                    <span className="column-label">{column.label}</span>
                    <Icon
                      className="add-column"
                      icon="plus"
                      onClick={() => { handleAddColumn(column) }}
                    />
                  </List.Item>
                ))
              }
            </List>
          </div>
          <div className="setting-preset">
            <span className="box-label">Preset Settings</span>
            <Select
              className="preset-setting-select"
              options={dspCampaignColumnSetting}
              value={preSetting}
              placeholder="Select Pre-Defined Setting"
              onChange={setPreSetting}
            />
          </div>
        </div>
        <div className="setting-selected">
          <span className="box-label">
            {`${columnList.length} Selected`}
          </span>
          <List
            className="selected-column-list"
            sortable
            pressDelay={300}
            onSort={handleSortColumns}
          >
            {
              (columnList || []).map((column, index) => (
                <List.Item
                  key={column.key}
                  className="column-item"
                  index={index}
                  collection={column.fixed ? 0 : 1}
                >
                  <Icon className="handle-column" icon="ellipsis-v" size="lg" />
                  <span className="column-label">{column.label}</span>
                  <Icon
                    className="remove-column"
                    icon="close"
                    onClick={() => { handleRemoveColumn(column) }}
                  />
                </List.Item>
              ))
            }
          </List>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          onClick={() => { onApply(columnList) }}
        >
          Apply
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={onClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DspColumnSettingModal
