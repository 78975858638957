import { callGet } from '../../services/axios'
import { getISODate } from '../../services/helper'

export const getBudgetRecommendations = (accessToken, signal) => (_, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/budgetRecommendation/getBudgetRecommendations', accessToken, {
    userId: currentUserId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then(res => res.data)
  .catch((error) => {
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}
