import React from 'react'
import { useSelector } from 'react-redux'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'
import GeniusBidCell from '../GeniusBidCell'

import {
  formatCurrency,
  tableSorter,
  capitalizeFirstLetter,
  getExportValueForColumn,
} from '../../services/helper'

const metricColList = [
  { key: 'cpc', name: 'Ave CPC' },
  { key: 'orders', name: 'Orders' },
  { key: 'acos', name: 'ACoS %' },
  { key: 'revenue', name: 'Sales' },
  { key: 'cost', name: 'Spend' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'conversion', name: 'Conv %' },
]

const columns = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'bid', name: 'Current Bid' },
  { key: 'maxCpc', name: 'Genius Bid', className: 'col-genius-bid' },
  ...metricColList,
]

const KeywordTable = ({ keywords }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const isGettingKeywords = useSelector(state => state.productDetail.isGettingKeywords)

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.match_type } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <GeniusBidCell
        record={record}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
      {
        metricColList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <SortableTable
      columns={columns}
      defaultSort={['cost', 'desc']}
      sorter={tableSorter(['keyword'])}
      className="table-keywords"
      records={keywords}
      idField="keyword_id"
      searchFields={['keyword']}
      noCheckBox
      hasSticky
      isLoading={isGettingKeywords}
      getExportData={getExportData}
      exportFileName="Product Detail Keyword"
      renderRecord={renderKeyword}
    />
  );
}

export default KeywordTable
