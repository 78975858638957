import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

import {
  bulkUpdateCampaignTypes,
  bulkUpdateMatchTypes,
  bulkUpdateSponsoredProducts,
  bulkUpdateSponsoredDisplay,
  bulkUpdateSponsoredBrand,
} from '../../utils/filterDef'

const UpdateCampaignNameModal = ({ campaigns, onUpdate, onClose }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)

  const [initialCampaigns, setInitialCampaigns] = useState([])
  const [campaignsChangeHistory, setCampaignChangeHistory] = useState([]);
  const [addedText, setAddedText] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setInitialCampaigns(JSON.parse(JSON.stringify(campaigns)))
    setCampaignChangeHistory([]);
  }, [campaigns]) // eslint-disable-line

  const modifyCampaigns = (campaigns) => {
    setCampaignChangeHistory([
      ...(JSON.parse(JSON.stringify(campaignsChangeHistory))),
      JSON.parse(JSON.stringify(initialCampaigns)),
    ]);
    setInitialCampaigns(campaigns);
  }

  const handleCampaignName = (record, campaignName) => {
    modifyCampaigns(initialCampaigns.map((campaign) => {
      if (campaign.campaign_id.toString() !== record.campaign_id.toString()) {
        return campaign
      }
      return {
        ...campaign,
        campaign: campaignName,
      }
    }))
  }

  const addAcosToCampaignName = () => {
    const newSelectedCampaigns = JSON.parse(JSON.stringify(initialCampaigns))
    newSelectedCampaigns.forEach((campaign) => {
      if(campaign.target_acos !== null) {
        campaign.campaign = campaign.campaign + ' | ' + Math.round(campaign.target_acos * 1) + ' ACOS'
      } else {
        campaign.campaign = campaign.campaign + ' | ' + Math.round(currentAccount?.average_acos || 30) + ' ACOS'
      }
    })
    modifyCampaigns(newSelectedCampaigns)
  }

  const addTextAtStart = () => {
    modifyCampaigns(initialCampaigns.map(campaign => ({
      ...campaign,
      campaign: `${addedText} | ${campaign.campaign}`,
    })))
  }

  const addTextAtEnd = () => {
    modifyCampaigns(initialCampaigns.map(campaign => ({
      ...campaign,
      campaign: `${campaign.campaign} | ${addedText}`,
    })))
  }

  const addAdTypeToCampaignName = () => {
    const newSelectedCampaigns = JSON.parse(JSON.stringify(initialCampaigns))
    newSelectedCampaigns.forEach((campaign) => {
      campaign.campaign = `${campaign.campaignType.toUpperCase()} | ${campaign.campaign}`
    })
    modifyCampaigns(newSelectedCampaigns)
  }

  const appendBulkOptions = (option) => {
    modifyCampaigns(initialCampaigns.map(campaign => ({
      ...campaign,
      campaign: `${campaign.campaign} | ${option.value}`,
    })))
  }

  const handleUndo = () => {
    setInitialCampaigns(campaignsChangeHistory[campaignsChangeHistory.length - 1])
    setCampaignChangeHistory(campaignsChangeHistory.length === 1
      ? []
      : campaignsChangeHistory.slice(0, campaignsChangeHistory.length - 1)
    )
  }

  const handleSave = async () => {
    setIsSaving(true)
    await onUpdate(initialCampaigns)
    setIsSaving(false)
  }

  const renderActions = () => (
    <>
      <div className="name-suffix-section">
        <input
          type="text"
          placeholder="Type text to add to the campaigns."
          value={addedText}
          onChange={(event) => { setAddedText(event.target.value) }}
        />
        <button
          type="button"
          className="btn btn-green"
          disabled={addedText === ''}
          onClick={addTextAtStart}
        >
          Apply At Start
        </button>
        <button
          type="button"
          className="btn btn-green"
          disabled={addedText === ''}
          onClick={addTextAtEnd}
        >
          Apply At End
        </button>
      </div>
      {
        !isNonEndemicAccount && (
          <div className="type-suffix-section">
            <Select
              options={bulkUpdateCampaignTypes}
              value=""
              placeholder="Campaign Type"
              onChange={appendBulkOptions}
            />
            <Select
              options={bulkUpdateMatchTypes}
              value=""
              placeholder="Match Type"
              onChange={appendBulkOptions}
            />
          </div>
        )
      }
      <div className="type-suffix-section">
        {
          !isNonEndemicAccount && (
            <Select
              options={bulkUpdateSponsoredProducts}
              value=""
              placeholder="Sponsored Product"
              onChange={appendBulkOptions}
            />
          )
        }
        <Select
          options={bulkUpdateSponsoredDisplay}
          value=""
          placeholder="Sponsored Display"
          onChange={appendBulkOptions}
        />
        {
          !isNonEndemicAccount && (
            <Select
              options={bulkUpdateSponsoredBrand}
              value=""
              placeholder="Sponsored Brand"
              onChange={appendBulkOptions}
            />
          )
        }
      </div>
      <div className="undo-section">
        {
          !isNonEndemicAccount && (
            <>
              <button type="button" className="btn btn-green" onClick={addAdTypeToCampaignName}>
                Ad Type (Start)
              </button>
              <button type="button" className="btn btn-green" onClick={addAcosToCampaignName}>
                Target ACoS (End)
              </button>
            </>
          )
        }
        <button
          type="button"
          className="btn btn-red"
          disabled={campaignsChangeHistory.length === 0}
          onClick={handleUndo}
        >
          Undo Change
        </button>
      </div>
    </>
  )

  const renderCampaign = record => (
    <>
      <div className="table-col col-campaign">
        <input
          type="text"
          value={initialCampaigns.find(c => c.campaign_id.toString() === record.campaign_id.toString())?.campaign || ''}
          onChange={(event) => handleCampaignName(record, event.target.value) }
        />
      </div>
    </>
  )

  const renderFooter = () => (
    <Modal.Footer>
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isSaving}
        onClick={handleSave}
      >
        Save
      </button>
      <button
        type="button"
        className="rs-btn rs-btn-subtle"
        disabled={isSaving}
        onClick={() => onClose()}
      >
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className="update-names-modal" backdrop="static" show size="md" overflow={false}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Update Campaign Names
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          isLoading={isSaving}
          records={campaigns}
          idField="campaign_id"
          noCheckBox
          noSearch
          renderRecord={renderCampaign}
          renderTopRight={renderActions}
        >
          <div className="table-col">Campaign</div>
        </CustomTable>
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default UpdateCampaignNameModal
