import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import MainLayout from '../../layout/MainLayout'
import TableCollection from '../../components/TableCollection'
import DashboardChartComponent from '../../components/DashboardChartComponent'
import KpiList from './KpiList'
import ComparisonView from '../../components/ComparisonView'

import {
  COMPARISON_VIEW_TYPE_WEEKLY,
  COMPARISON_VIEW_TYPE_MONTHLY,
  COMPARISON_VIEW_TYPE_LABELS,
} from '../../utils/defaultValues'

import {
  getSalesStats,
  getStats,
  getSummary,
  getPortfolioStats,
} from '../../redux/actions/dashboard'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

const adTypes = [
  { label: 'All Sponsored Dashboard', value: '' },
  { label: 'Sponsored Product Dashboard', value: 'sp' },
  { label: 'Sponsored Brand Dashboard', value: 'sb' },
  { label: 'Sponsored Display Dashboard', value: 'sd' },
  { label: 'Select a Portfolio', value: 'portfolio' },
]

const VIEW_TYPE_CHART = 'chart'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const maxTable = useSelector(state => state.dashboard.maxTable)

  const currentUserId = useSelector(state => state.header.currentUserId)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const [isLoadingSalesStats, setIsLoadingSalesStats] = useState(false)
  const [salesStats, setSalesStats] = useState(null)
  const [isLoadingStats, setIsLoadingStats] = useState(false)
  const [stats, setStats] = useState(null)
  const [isLoadingSummary, setIsLoadingSummary] = useState(false)
  const [summary, setSummary] = useState([])
  const [currentAdType, setCurrentAdType] = useState(isNonEndemicAccount ? adTypes[4] : adTypes[0])
  const [currentViewType, setCurrentViewType] = useState(VIEW_TYPE_CHART)
  const [currentPortfolios, setCurrentPortfolios] = useState([])
  const [portfolioStats, setPortfolioStats] = useState(null)

  useEffect(() => {
    let abortCtrl
    if (currentUserId && !isNonEndemicAccount) {
      abortCtrl = new AbortController();

      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsLoadingSalesStats(true)
        try {
          const response = await dispatch(getSalesStats(accessToken, abortCtrl.signal))
          setSalesStats(response)
          setIsLoadingSalesStats(false)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoadingSalesStats(false)
          }
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentStartDate, currentEndDate, currentUserId]) // eslint-disable-line

  useEffect(() => {
    let abortCtrl
    if (currentUserId) {
      abortCtrl = new AbortController();

      (async () => {
        const accessToken = await getAccessTokenSilently()

        try {
          if (currentAdType.value !== 'portfolio') {
            setIsLoadingStats(true)
            const data = await dispatch(getStats(
              accessToken,
              currentAdType.value,
              abortCtrl.signal,
            ))
            setStats(data)
            setIsLoadingStats(false)
          } else if (currentPortfolios.length) {
            setIsLoadingStats(true)
            const data = await dispatch(getPortfolioStats(
              accessToken,
              currentPortfolios.map(p => p.value),
              abortCtrl.signal,
            ))
            setPortfolioStats(data)
            setIsLoadingStats(false)
          }
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoadingStats(false)
          }
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentStartDate, currentEndDate, currentUserId, currentAdType, currentPortfolios]) // eslint-disable-line

  useEffect(() => {
    let abortCtrl

    if (currentViewType !== VIEW_TYPE_CHART) {
      abortCtrl = new AbortController();

      (async () => {
        const accessToken = await getAccessTokenSilently()

        try {
          if (currentAdType.value !== 'portfolio') {
            setIsLoadingSummary(true)
            const data = await dispatch(getSummary(
              accessToken,
              currentViewType,
              [],
              abortCtrl.signal,
            ))
            setSummary(data)
            setIsLoadingSummary(false)
          } else if (currentPortfolios.length) {
            setIsLoadingSummary(true)
            const data = await dispatch(getSummary(
              accessToken,
              currentViewType,
              currentPortfolios.map(p => p.value),
              abortCtrl.signal,
            ))
            setSummary(data)
            setIsLoadingSummary(false)
          }
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoadingSummary(false)
          }
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentUserId, currentViewType, currentAdType, currentPortfolios]) // eslint-disable-line

  const renderTypeSelector = () => {
    return (
      <div className="type-selector-container">
        {
          [VIEW_TYPE_CHART, COMPARISON_VIEW_TYPE_WEEKLY, COMPARISON_VIEW_TYPE_MONTHLY].map(type => (
            <button
              key={type}
              type="button"
              className={`btn ${currentViewType === type ? 'btn-red' : 'btn-white'}`}
              onClick={() => { setCurrentViewType(type) }}
            >
              { COMPARISON_VIEW_TYPE_LABELS[type] || 'Chart View' }
            </button>
          ))
        }
      </div>
    )
  }

  const renderChartComponent = (isLoading) => {
    const selectedStats = currentAdType.value === 'portfolio' ? portfolioStats : stats
    return (
      <DashboardChartComponent
        isLoading={isLoading}
        isOverview
        salesStats={salesStats}
        stats={selectedStats}
      />
    )
  }

  const renderTopSection = () => {
    if (maxTable) {
      return null
    }

    const isLoading = isLoadingStats || isLoadingSalesStats
    const statsData = currentAdType.value === 'portfolio' ? portfolioStats : stats

    return (
      <>
        <KpiList
          salesStats={salesStats}
          stats={statsData}
          adTypes={adTypes}
          currentAdType={currentAdType}
          hasSpCode={currentAccount?.hasSpCode}
          onChangeAdType={setCurrentAdType}
          isLoading={isLoading}
          currencySign={currencySign}
          currencyRate={currencyRate}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          onChangePortfolios={setCurrentPortfolios}
        />
        { renderTypeSelector() }
        {
          currentViewType === VIEW_TYPE_CHART ?
            renderChartComponent(isLoading)
            : (
              <ComparisonView
                isLoading={isLoadingSummary}
                summary={summary}
              />
            )
        }
      </>
    )
  }

  return (
    <MainLayout>
      <div className="dashboard-page">
        { renderTopSection() }
        <TableCollection />
      </div>
    </MainLayout>
  )
}

export default DashboardPage
