// Add Campaign To Existing Portfolio.
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import VideoLink from '../CommonComponents/VideoLink'
import { toast } from '../CommonComponents/ToastComponent/toast'

import { hideAEPAction } from '../../redux/actions/pageGlobal'
import { updatePortfolios } from '../../redux/actions/campaignDetail'
import { monitorJob } from '../../redux/actions/job'
import { getPortfolios } from '../../redux/actions/portfolio'
import { JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS } from '../../utils/defaultValues'

const videoList = [
  { title: 'Portfolios', url: 'https://www.loom.com/embed/e5ce9e44830142e9b08a792f6bf7a4ca' },
]

const AddToExistingPortfolio = ({ campaigns }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isUpdatingPortfolio = useSelector(state => state.campaignDetail.isUpdatingPortfolio)

  const [step, setStep] = useState(1)
  const [selectedCampaigns, setSelectedCampaigns] = useState(campaigns)
  const [selectedPortfolio, setSelectedPortfolo] = useState()
  const [isLoadingPortfolios, setIsLoadingPortfolios] = useState(false)
  const [portfolios, setPortfolios] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    let abortCtrl
    if (step === 2) {
      abortCtrl = new AbortController();

      (async () => {
        setIsLoadingPortfolios(true)
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getPortfolios(
          accessToken,
          abortCtrl.signal,
        ))
        setPortfolios(response)
        setIsLoadingPortfolios(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [step]) // eslint-disable-line

  // Hide Add to new portfolio model popup.
  const onClose = () => {
    dispatch(hideAEPAction())
  }

  const handleNextStep = () => {
    const inValidCampaigns = selectedCampaigns.filter(item =>
      (item.campaignType === 'sd') && (item.cost_type === 'vcpm')
    )
    if (!inValidCampaigns.length) {
      setStep(2)
    } else {
      toast.show({
        title: 'Warning',
        description: inValidCampaigns.length === 1 ? 'There is a invalid campaign.' : 'There are invalid campaigns.',
      })
    }
  }

  const handlePreviousStep = () => {
    setStep(1)
  }

  const handleCampaignRemove = (campaign_id) => {
    setSelectedCampaigns(selectedCampaigns.filter(item =>
      item.campaign_id.toString() !== campaign_id.toString()
    ))
  }

  const onChange = (value, portfolio) => {
    if (value) {
      setSelectedPortfolo(portfolio)
    } else {
      setSelectedPortfolo()
    }
  }

  const handleConfirm = async () => {
    setIsUpdating(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updatePortfolios(
      accessToken,
      selectedCampaigns.map(item => ({
        campaignId: item.campaign_id,
        campaignType: item.campaignType,
      })),
      selectedPortfolio.portfolio_id,
      selectedPortfolio.name,
      null,
      false,
    ))
    setIsUpdating(false)
    dispatch(monitorJob(
      response.data.jobId,
      JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS,
      selectedPortfolio.name,
    ))
    onClose()
  }

  const renderFirstStep = () => (
    <>
      <div className="section-header">
        Step 1: Confirm Campaigns Add to Exisintg Portfolio
        <em>{ selectedCampaigns.length } campaigns selected</em>
      </div>
      <div className="section-body">
        Campaigns
        <div className="list-container">
          {
            selectedCampaigns.map(data => (
              <div key={data.campaign_id} className="list-item">
                <span>
                  { data.campaign }
                </span>
                <span
                  className="close-icon"
                  title="Remove campaign"
                  onClick={() => handleCampaignRemove(data.campaign_id)}
                >
                  &times;
                </span>
              </div>
            ))
          }
        </div>
      </div>
      <div className="section-footer">
        <button
          type="button"
          className="btn btn-red"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-blue"
          disabled={!selectedCampaigns.length}
          onClick={handleNextStep}
        >
          Confirm
        </button>
      </div>
    </>
  )

  const renderSecondStep = () => (
    <>
      <div className="section-header">
        Step 2: Choose Portfolio to Add Campaign
        <em>{ portfolios.length } portfolio available</em>
      </div>
      <div className="section-body">
        Portfolio
        <div className="list-container">
          {
            portfolios.map((portfolio) => (
              <div key={portfolio.portfolio_id} className="list-item">
                <span>
                  { portfolio.name }
                </span>
                <CheckboxComponent
                  checked={portfolio === selectedPortfolio}
                  onChange={(e) => { onChange(e, portfolio) }}
                />
              </div>
            ))
          }
        </div>
      </div>
      <div className="section-footer">
        <button
          type="button"
          className="btn btn-gray"
          onClick={handlePreviousStep}
        >
          Back to Previous Step
        </button>
        <button
          type="button"
          className="btn btn-red"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-blue"
          disabled={!selectedPortfolio || isUpdating}
          onClick={handleConfirm}
        >
          Confirm
        </button>
      </div>
    </>
  )

  const renderContents = () => {
    if (step === 1) {
      return renderFirstStep()
    }
    if (step === 2) {
      return renderSecondStep()
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => { onClose() }}>
      <div className="add-to-portfolio-pane">
        <div className="top-container">
          <div className="pane-header">
            <div className="left-column">
              <span className="pane-title">
                Add Campaigns to Existing Portfolio
              </span>
              <VideoLink
                videoList={videoList}
                modalTitle='Portfolios'
                linkName=''
              />
            </div>
            <div className="right-column">
              <span className="close-icon" onClick={onClose}>
                &times;
              </span>
            </div>
          </div>
        </div>
        <div className={`pane-body${(isUpdatingPortfolio || isLoadingPortfolios) ? ' loading' : ''}`}>
          { (isUpdatingPortfolio || isLoadingPortfolios) && <LoaderComponent /> }
          { renderContents() }
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default AddToExistingPortfolio
