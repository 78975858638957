import React from 'react'
import { FiFile } from 'react-icons/fi'

const AttachmentList = ({ attachments }) => {
  return (
    <div className="attachment-list">
      {
        attachments.map(attachment => (
          <span key={attachment.name} className="attachment-item">
            <FiFile color="#D9D9D9" />
            <a href={attachment.url} target="_blank" rel="noopener noreferrer">
              { attachment.name }
            </a>
          </span>
        ))
      }
    </div>
  )
}

export default AttachmentList
