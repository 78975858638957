import React from 'react'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'

const TableHeader = ({
  campaigns,
  selectedCampaigns,
  selectedColumns,
  onSelect,
  onSort,
  refHeader,
  tableHeight,
  resizing,
  campaignColRef,
  hideDailyBudget,
  isNonEndemicAccount,
  onCollapseCampaignCol,
  onExpandCampaignCol,
  onStartResizing,
}) => (
  <div className="table-row content-header" ref={refHeader}>
    <div className="table-col">
      <CheckboxComponent
        checked={selectedCampaigns.length > 0 && selectedCampaigns.length === campaigns.length}
        onChange={(checked) => { onSelect(checked ? campaigns : []) }}
      />
    </div>
    <div
      className="table-col campaign-status"
      onClick={() => { onSort('campaign') }}
      ref={campaignColRef}
    >
      Campaign
      <FiMinimize2 className="min-svg" size={16} color="#979797" onClick={onCollapseCampaignCol} />
      <FiMaximize2 className="max-svg" size={16} color="#979797" onClick={onExpandCampaignCol} />
      <div
        style={{ height: tableHeight }}
        onMouseDown={onStartResizing}
        className={`resize-handle ${resizing ? 'active' : 'idle'}`}
      />
    </div>
    {
      selectedColumns.map((column) => {
        if (
          column.fixed
          ||
          (hideDailyBudget && column.key === 'daily_budget')
          ||
          (
            isNonEndemicAccount
            && [
              'target_acos',
              'revenue',
              'acos',
              'orders',
              'conversion',
              'roas',
              'ntb_orders',
              'ntb_orders_percent',
              'ntb_sales',
              'ntb_sales_percent',
              'video_views_25',
              'video_views_50',
              'video_views_75',
              'video_views_100',
              'video_5s_views',
              'view_5_seconds_rate',
            ].includes(column.key)
          )
        ) {
          return null
        }

        return (
          <div key={column.key} className="table-col" onClick={() => { onSort(column.key) }}>
            { column.name || column.label }
          </div>
        )
      })
    }
    <div className="table-col"></div>
  </div>
)

export default TableHeader
