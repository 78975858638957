import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import * as Icon from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../CommonComponents/ToastComponent/toast'
import BidAdjustComponent from '../../../CommonComponents/BidAdjustComponent'
import TableCell from '../../../CommonComponents/TableCell'
import GeniusBidCell from '../../../GeniusBidCell'

import { changeTarget } from '../../../../redux/actions/campaignDetail'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../../../redux/reducers/header'

import {
  formatCurrency,
  copyToClipboard,
  tableSorter,
  parseTargetExp,
  calcDerivedMetrics,
  capitalizeFirstLetter,
  getAmazonCategoryLink,
  getAmazonAsinLink,
  calcMaxCpc,
  calcNewBid,
  getExportValueForColumn,
} from '../../../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
} from '../../../../utils/defaultValues'

import { keywordStatuses } from '../../../../utils/filterDef'

const columns = [
  { key: 'target_text', name: 'Automatic Targeting Defaults', className: 'col-target' },
  { key: 'bid', name: 'Current Bid' },
  { key: 'maxCpc', name: 'Genius Bid', className: 'col-genius-bid' },
  ...bulkBidColumnList,
]

const TargetTable = ({ isLoading, campaignType, campaignId, adgroupId }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const bidTargetData = useSelector(state => state.campaignDetail.bidTargetData)

  const [targets, setTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(keywordStatuses[0])
  const [isChangingTarget, setIsChangingTarget] = useState(false)

  useEffect(() => {
    const extendedTargets = [];
    (bidTargetData || []).forEach((record) => {
      if (selectedStatus.value !== '') {
        if ((record.state || '').toLowerCase() !== selectedStatus.value) {
          return
        }
      }

      const derived = calcDerivedMetrics(record)
      derived.target_acos = currentAcos

      let adType
      if (campaignType === 'sb') {
        adType = currentDetail.video_adgroup_ids.includes(
          record.adgroup_id.toString()
        ) ? 'sbv' : campaignType
      } else {
        adType = campaignType
      }

      extendedTargets.push({
        ...derived,
        adType,
        maxCpc: calcMaxCpc(
          derived,
          adType,
          currentAccount?.country_id,
          currentDetail.cost_type,
        ),
      })
    })

    setTargets(extendedTargets)
  }, [currentAcos, bidTargetData, selectedStatus, campaignType, currentAccount?.country_id]) // eslint-disable-line

  const [tableColumns, columnsToRender] = useMemo(() => {
    if (!isNonEndemicAccount) {
      return [columns, bulkBidColumnList]
    }

    const columnsToExclude = [
      'revenue',
      'acos',
      'orders',
      'conversion',
      'ntb_orders',
      'ntb_orders_percent',
      'ntb_sales',
      'ntb_sales_percent',
    ]

    return [
      columns.filter(column => (
        !columnsToExclude.includes(column.key)
      )),
      bulkBidColumnList.filter(column => (
        !columnsToExclude.includes(column.key)
      )),
    ]
  }, [isNonEndemicAccount])

  const callChangeTarget = async (targets) => {
    const payload = {}
    if (campaignType === 'sb') {
      payload.campaignId = Number(campaignId)
      payload.adGroupId = Number(adgroupId)
    }

    setIsChangingTarget(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(changeTarget(accessToken, targets.map(target => ({
      ...target,
      ...payload,
    })), campaignType))
    setIsChangingTarget(false)
  }

  const handleCopy = () => {
    const targetTexts = targets.filter(target => (
      selectedTargets.indexOf(target.target_id) !== -1
    )).map(keyword => keyword.target_text.trim())

    copyToClipboard([...new Set(targetTexts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedTargets.length}`
        + ` target${selectedTargets.length > 1 ? 's' : ''}.`
    })
  }

  const handleChangeState = (state) => {
    const targetsToChange = targets.filter(target => selectedTargets.indexOf(target.target_id) !== -1)
    callChangeTarget(targetsToChange.map(target => ({
      targetId: parseInt(target.target_id, 10),
      state,
    })))
  }

  const handleAdjustBid = () => {
    let targetsToChange = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.adType,
        currentAccount?.country_id,
        currentDetail.cost_type
      )

      targetsToChange.push({
        targetId: parseInt(record.target_id, 10),
        bid: parseFloat(newBid.toFixed(2)),
      })
    })

    if (!targetsToChange.length){
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your targets',
      })
      return
    }

    callChangeTarget(targetsToChange)
  }

  const handleChangeToMaxBid = () => {
    const targetsToChange = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      if (record.maxCpc) {
        targetsToChange.push({
          targetId: parseInt(record.target_id, 10),
          bid: parseFloat(parseFloat(record.maxCpc).toFixed(2)),
        })
      }
    })

    if (!targetsToChange.length) {
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your targets.',
      })
      return
    }

    callChangeTarget(targetsToChange, campaignType)
  }

  const renderActionItems = () => {
    if (!selectedTargets.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            disabled={isChangingTarget || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isChangingTarget || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button type="button" className="btn btn-light-blue" onClick={() => { setIsShowAdjustBid(true) }}>
            Adjust Bid
          </button>
          {
            !isNonEndemicAccount && (
              <button
                type="button"
                className="btn btn-blue"
                onClick={handleChangeToMaxBid}
              >
                Change to Genius Bid
              </button>
            )
          }
          <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
            Copy
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isChangingTarget}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderAction = () => {
    return (
      <>
        { renderActionItems() }
        <div className="select-wrapper">
          <span>Status</span>
          <Select
            classNamePrefix="match-type-selector"
            options={keywordStatuses}
            value={selectedStatus}
            onChange={setSelectedStatus}
          />
        </div>
      </>
    )
  }

  const renderTargetCell = (record) => {
    const target = parseTargetExp(record.target_text)
    let link
    if (target.indexOf('asin=') === 0) {
      try {
        const parsed = JSON.parse(record.target_text)
        link = (
          <a
            href={getAmazonAsinLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon.FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        //
      }
    } else if (target.indexOf('category=') === 0) {
      try {
        const parsed = JSON.parse(record.target_exp)
        link = (
          <a
            href={getAmazonCategoryLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon.FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        //
      }
    }

    return (
      <div className="table-col col-target" title={target}>
        <div className="target-text-wrapper">
          <strong>
            { target }
          </strong>
          { link }
        </div>
        <div className="meta-data">
          { capitalizeFirstLetter(record.state) }
        </div>
      </div>
    )
  }

  const renderTarget = record => (
    <>
      { renderTargetCell(record) }
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <GeniusBidCell
        record={Object.assign({}, currentDetail || {}, record)}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
      {
        columnsToRender.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <SortableTable
      columns={tableColumns}
      defaultSort={['cost', 'desc']}
      sorter={tableSorter(['state', 'target_text'])}
      className="table-targets"
      records={targets || []}
      idField="target_id"
      searchFields={['target_text']}
      selectedRecords={selectedTargets}
      hasSticky
      hasDateRange
      filterName="campaignDetailBidTarget"
      isLoading={isLoading || isChangingTarget}
      exportFileName="CampaignDetailBid"
      getExportData={getExportData}
      renderRecord={renderTarget}
      renderTopRight={renderAction}
      onChange={setSelectedTargets}
    />
  )
}

export default TargetTable
