/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import HeaderCampaign from '../RuleManagerComponents/HeaderCampaign'
import TemplateSelector from './TemplateSelector'

const CampaignInfo = ({ campaigns, currentTemplates, onTemplateChange, onRemove }) => {
  const [selectorVisible, setSelectorVisible] = useState(false)

  const handleTemplateSelect = (event) => {
    event.preventDefault()
    setSelectorVisible(true)
  }

  const handleTemplateChange = (templates) => {
    onTemplateChange(templates)
    setSelectorVisible(false)
  }

  return (
    <div className="campaign-info">
      <HeaderCampaign
        campaigns={campaigns}
        currentTemplates={currentTemplates}
        onTemplateSelect={handleTemplateSelect}
        onTemplateChange={onTemplateChange}
        onRemove={onRemove}
      />
      <TemplateSelector
        show={selectorVisible}
        currentTemplates={currentTemplates}
        onChange={handleTemplateChange}
        onClose={() => { setSelectorVisible(false) }}
      />
    </div>
  )
}

export default CampaignInfo
