import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CategoryTable from './CategoryTable'
import CategoryTree from './CategoryTree'
import ProductTable from './ProductTable'

import { getCategories, searchProduct } from '../../../redux/actions/targeting'

const TAB_CATEGORY = 'category'
const TAB_PRODUCT = 'product'

const tabList = [
  { value: TAB_CATEGORY, name: 'Category' },
  { value: TAB_PRODUCT, name: 'Product' },
]

const TargetingModal = ({ show, defaultBid, targetings,
  isLoading, suggestedCategories, suggestedProducts, onChange, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [currentTab, setCurrentTab] = useState(TAB_CATEGORY)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchedProducts, setSearchedProducts] = useState([])
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    let abortCtrl

    if (show && !isLoaded) {
      (async () => {
        setIsCategoriesLoading(true)
        abortCtrl = new AbortController()
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getCategories(
          accessToken,
          abortCtrl.signal,
        ))
        setCategories(response)
        setIsLoaded(true)
        setIsCategoriesLoading(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [show]) // eslint-disable-line

  const handleProductSearch = async (keyword) => {
    if (keyword === '') {
      return
    }
    setIsSearching(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(searchProduct(accessToken, {
      search: keyword,
    }))
    setSearchedProducts(response)
    setIsSearching(false)
  }

  const handleAsinsSearch = async asins => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(searchProduct(accessToken, {
      asins,
    }))
  }

  const handleCategoryTarget = (category) => {
    const duplicate = targetings.find(existingCategory => (
      existingCategory.id === category.id
      && (
        existingCategory.type === 'category'
        || existingCategory.type === 'refine'
      )
    ))

    if (duplicate) {
      return
    }

    onChange([
      {
        ...category,
        name: category.name || category.na,
        type: 'category',
        bid: defaultBid,
      },
      ...targetings,
    ])
  }

  const handleCategoryTargetAll = () => {
    const existingIds = targetings
      .filter(category => (
        category.type === 'category' || category.type === 'refine'
      ))
      .map(category => category.id)

    const newTargetings = [...targetings]
    suggestedCategories.forEach((category) => {
      if (!existingIds.includes(category.id)) {
        newTargetings.push({
          ...category,
          type: 'category',
          bid: defaultBid,
        })
      }
    })

    onChange(newTargetings)
  }

  // FIXME: When refining already targeted category, overwrite it.
  const handleCategoryRefine = (category, payload) => {
    const duplicate = targetings.find(existingCategory => (
      existingCategory.id === category.id
      && (
        existingCategory.type === 'category'
        || existingCategory.type === 'refine'
      )
      && (
        (!existingCategory.brandId && !payload.brandId)
        || (
          existingCategory.brandId.toString() === payload.brandId.toString()
        )
      )
    ))

    if (duplicate) {
      return
    }

    onChange(prevTargetings => ([
      {
        ...category,
        type: 'refine',
        bid: defaultBid,
        name: category.name || category.na,
        ...payload,
      },
      ...prevTargetings,
    ]))
  }

  const handleCategoryTargetTree = (category, parentCategory = null, expandedNodes) => {
    const duplicate = targetings.find(existingCategory => (
      existingCategory.id === category.id
      && (
        existingCategory.type === 'category'
        || existingCategory.type === 'refine'
      )
    ))

    if (duplicate) {
      return
    }

    const newCategory = {
      ...category,
      name: category.na,
      type: 'category',
      bid: defaultBid,
      parentId: parentCategory ? parentCategory.id : null,
    }

    if (parentCategory) {
      newCategory.path = expandedNodes[parentCategory.id]
        && expandedNodes[parentCategory.id].path
        ? expandedNodes[parentCategory.id].path
        : category.na
    }

    onChange([ newCategory, ...targetings ])
  }

  const renderCategoryTab = () => {
    if (currentTab !== TAB_CATEGORY) {
      return null
    }

    return (
      <div className="category-tab-container">
        <CategoryTable
          isLoading={isLoading}
          categories={suggestedCategories}
          targetings={targetings}
          onTarget={handleCategoryTarget}
          onTargetAll={handleCategoryTargetAll}
          onRefine={handleCategoryRefine}
        />
        <CategoryTree
          isLoading={isCategoriesLoading}
          categories={categories}
          targetings={targetings}
          onTarget={handleCategoryTargetTree}
          onTargetSearch={handleCategoryTarget}
          onRefine={handleCategoryRefine}
        />
      </div>
    )
  }

  const renderProductTab = () => {
    if (currentTab !== TAB_PRODUCT) {
      return null
    }

    return (
      <ProductTable
        isLoading={isSearching || isLoading}
        products={searchedProducts}
        targetings={targetings}
        defaultBid={defaultBid}
        suggestedProducts={suggestedProducts}
        onSearch={handleProductSearch}
        onSearchAsins={handleAsinsSearch}
        onTarget={onChange}
      />
    )
  }

  return (
    <Modal className="product-targeting-modal" backdrop="static" show={show} size="lg">
      <Modal.Body>
        <div className="tab-list">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? 'selected' : ''}
                onClick={() => { setCurrentTab(tab.value) }}
              >
                { tab.name }
              </button>
            ))
          }
        </div>
        { renderCategoryTab() }
        { renderProductTab() }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TargetingModal
