import React, { useState } from 'react'
import Select from 'react-select'
import { InputNumber } from 'rsuite'

import {
  RULE_MODIFIER_ACTION_DEC,
  RULE_MODIFIER_ACTION_SET,
  ruleModifierActionList,
} from '../../utils/defaultValues'

const ModifierRuleSection = ({ name, isReadOnly, action, value,
  onActionChange, onValueChange, onClear }) => {
  const [error, setError] = useState('')

  const validate = (newAction, newValue) => {
    setError('')
    if (!newAction) {
      return
    }

    if (newAction.value === RULE_MODIFIER_ACTION_DEC) {
      if (!(!isNaN(parseFloat(newValue))
        && parseFloat(newValue) > 0
        && parseFloat(newValue) < 100)) {
        setError('The change must be greater than 0 and less than 100.')
      }
    } else if (newAction.value === RULE_MODIFIER_ACTION_SET) {
      if (!(!isNaN(parseFloat(newValue))
        && parseFloat(newValue) >= 0
        && parseFloat(newValue) <= 900)) {
        setError('The modifier value must be between 0 and 900.')
      }
    } else {
      if (!(!isNaN(parseFloat(newValue))
        && parseFloat(newValue) > 0)) {
        setError('The change must be greater than 0.')
      }
    }
  }

  const handleActionChange = (newAction) => {
    validate(newAction, value)
    onActionChange(newAction)
  }

  const handleValueChange = (newValue) => {
    validate(action, newValue)
    onValueChange(newValue)
  }

  return (
    <div className="rule-sub-section">
      <div className="sub-section-name">
        { name }
      </div>
      <div className="sub-section-body">
        <div className="rule-settings-container">
          <Select
            classNamePrefix="rule-select"
            options={ruleModifierActionList}
            placeholder="Select action"
            isDisabled={isReadOnly}
            value={action}
            onChange={handleActionChange}
          />
          {
            action !== null && (
              <InputNumber
                className="value-input"
                min={0}
                placeholder="Set value"
                postfix="%"
                disabled={isReadOnly}
                value={value}
                onChange={handleValueChange}
              />
            )
          }
        </div>
        {
          action !== null && !isReadOnly && (
            <span
              className="clear-link"
              onClick={onClear}
            >
              Clear
            </span>
          )
        }
      </div>
      {
        error !== '' && (
          <div className="sub-section-error">
            { error }
          </div>
        )
      }
    </div>
  )
}

export default ModifierRuleSection
