import React, { useState } from 'react'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import SubSection from './SubSection'
import Frequency from './Frequency'
import Lookback from './Lookback'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'
import ExSkus from './ExSkus'
import ExCampaigns from './ExCampaigns'
import ExProductAdgroups from './ExProductAdgroups'

const ExProduct = ({ campaign, settings, onChange, ...props }) => {
  const [skusSelected, setSkusSelected] = useState([])
  const [isCampaignsLoaded, setIsCampaignsLoaded] = useState(false)

  return (
    <SubSection name="Product Targeting Expansion" {...props}>
      <div className="checkbox-info-wrapper">
        <CheckboxComponent
          label="Automatically pull winning ASINs from other campaigns into this campaign"
          checked={settings.ex_pt_active}
          onChange={(checked) => { onChange('ex_pt_active', checked) }}
        />
      </div>
      <Frequency
        disabled={!settings.ex_pt_active}
        prefix="ex_pt_"
        settings={settings}
        onChange={onChange}
      />
      <Lookback
        disabled={!settings.ex_pt_active}
        field="ex_pt_lookback"
        settings={settings}
        onChange={onChange}
      />
      <FieldRow disabled={!settings.ex_pt_active}>
        <FieldNumber
          label="<strong>Step 1)</strong> Find ASINs with ACoS between 1 and"
          htmlLabel
          field="ex_pt_acos"
          inline
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <ExSkus
        selectedSkus={skusSelected}
        disabled={!settings.ex_pt_active}
        onChange={setSkusSelected}
      />
      <ExCampaigns
        field="ex_pt_campaigns"
        skus={skusSelected}
        forASINs
        settings={settings}
        disabled={!settings.ex_pt_active}
        isLoaded={isCampaignsLoaded}
        onLoad={setIsCampaignsLoaded}
        onChange={onChange}
      />
      <div className={`st-criteria-label ${!settings.ex_pt_active || !isCampaignsLoaded ? 'disabled' : ''}`}>
        <strong>Step 4)</strong> Pull ASINs with the following criteria.
      </div>
      <FieldRow disabled={!settings.ex_pt_active || !isCampaignsLoaded}>
        <FieldNumber
          label="Min orders"
          settings={settings}
          field="ex_pt_orders"
          tooltip="We'll pull ASINs from campaigns selected that have at least this many orders over the lookback period."
          onChange={onChange}
        />
        <div className="field-wrapper" />
      </FieldRow>
      <FieldRow disabled={!settings.ex_pt_active || !isCampaignsLoaded}>
        <div className="field-wrapper">
          <div className="checkbox-info-wrapper">
            <CheckboxComponent
              label="New ASINs only"
              checked={settings.ex_pt_new_only}
              onChange={(checked) => { onChange('ex_pt_new_only', checked) }}
            />
            <CustomTooltip>
              <p>
                You may wish to only pull ASINS that are not yet being targeted.
                For example, let’s say we find an ASIN with the criteria above
                from an automatic campaign. If you are already targeting that ASIN
                in another campaign (selected above) and check this box,
                we will not pull it into this campaign. In that example,
                we will only pull the new/untargeted ASINS found from the auto campaign.
              </p>
              <p>
                If you are looking to pull ASINS from automatic campaigns
                and other product targeting campaigns to enhance coverage
                across ad types, then we recommend leaving this box unchecked.
              </p>
              <p>
                If this is confusing, watch the video that Mike has prepared.
              </p>
            </CustomTooltip>
          </div>
        </div>
        <div className="field-wrapper">
          <div className="checkbox-info-wrapper">
            <CheckboxComponent
              label="Add as negative target in parent campaign"
              checked={settings.ex_pt_negate_parent}
              onChange={(checked) => { onChange('ex_pt_negate_parent', checked) }}
            />
            <CustomTooltip>
              While adding winning search terms or ASINS into a new campaign,
              you may wish to negate them from the previous campaign. If you check this box,
              we'll use a negative exact at the ad group level on the search term/ASIN
              from the campaign which it was found.
            </CustomTooltip>
          </div>
        </div>
      </FieldRow>
      {
        campaign && (
          <ExProductAdgroups
            campaign={campaign}
            settings={settings}
            disabled={!settings.ex_pt_active || !isCampaignsLoaded}
            onChange={onChange}
          />
        )
      }
    </SubSection>
  )
}

export default ExProduct
