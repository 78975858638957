import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import PortfolioTableComponent from '../../components/PortfolioTableComponent'
import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportNoteWidget from './ReportNoteWidget'

import { changeViewReportPortfolioTableState } from '../../redux/actions/pageGlobal'
import { getISODate } from '../../services/helper'

const PortfolioSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()

  const showReportPortfolioTable = useSelector(state => state.pageGlobal.showReportPortfolioTable)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [note, setNote] = useState('')
  const [tableView, setTableView] = useState(showReportPortfolioTable)

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_portfolio || '')
    }
  }, [settings, isLoadingSettings])

  const handleHideTable = () => {
    dispatch(changeViewReportPortfolioTableState())
    setTableView(p => !p)
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_portfolio',
      sectionValue: note,
    })
  }

  return (
    <>
      <SectionHeader
        title="Your Portfolios"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            {(customizingSection || tableView) && (
              <div className="report-widget">
                <div className="report-widget-header">
                  <div className="widget-title">
                    Portfolios
                  </div>
                  <div className="widget-action">
                    {customizingSection && (
                      <IconButton
                        icon={<Icon icon="check" />}
                        color={tableView ? 'green' : ''}
                        circle
                        onClick={handleHideTable}
                      />
                    )}
                  </div>
                </div>
                <div className="report-widget-content">
                  <PortfolioTableComponent
                    startDate={getISODate(startDate)}
                    endDate={getISODate(endDate)}
                  />
                </div>
              </div>
            )}
            {(customizingSection || noteWidgetView) && (
              <ReportNoteWidget
                noteWidgetView={noteWidgetView}
                customizing={customizingSection}
                onRemove={setNoteWidgetView}
                note={note}
                onChange={setNote}
                section="portfolios"
              />
            )}
          </>
        )
      }
    </>
  )
}

export default withReportSection(PortfolioSection, {
  sectionId: 'portfolios',
  reportSectionKey: 'portfolios',
})
