/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FiDownload, FiFilter, FiColumns } from 'react-icons/fi'

import PaginationComponent from '../PaginationComponent'
import CheckboxComponent from '../CheckboxComponent'
import LoaderComponent from '../LoaderComponent'
import DateRangeComponent from '../DateRangeComponent'
import TableFilter from '../TableFilter'
import TableFilterShower from '../TableFilterShower'
import ColumnEditor from '../ColumnEditor'
import SearchBox from '../SearchBox'

import { setDateRange } from '../../../redux/actions/header'
import {
  showFilter,
  showColumnEditorAction,
  selectColumns,
} from '../../../redux/actions/pageGlobal'
import {  matchFilter, calcDerivedMetrics } from '../../../services/helper'
import filterDef from '../../../utils/filterDef'

const DEFAULT_PAGE_SIZE = 10

// Customized table component.
const CustomTable = (props) => {
  const {
    /**
     * Additional CSS class to add.
     * @type {string}
     */
    className = '',
    /**
     * An array of records to render.
     * @type {Array}
     */
    records,
    /**
     * An array of records that are selected.
     * @type {Array}
     */
    selectedRecords = [],
    /**
     * A field name of records to identify them.
     * @type {string}
     */
    idField,
    /**
     * A list of fields to search records by.
     * @type {Array<string>}
     */
    searchFields = [],
    /**
     * `true` to hide a paginator, `false` to show it.
     * @type {boolean}
     * @default false
     */
    noPagination = false,
    /**
     * The number of pagination buttons to render.
     * @type {number}
     * @default 2
     */
    paginationNeighbours = 2,
    /**
     * `true` to hide check boxes to select records, `false` to render them.
     * @type {boolean}
     * @default false
     */
    noCheckBox = false,
    /**
     * `true` to hide a check box in a table header to select all records,
     * `false` to render it.
     * @type {boolean}
     * @default false
     */
    noHeaderCheckBox = false,
    /**
     * `true` to allow only one record to be selected at a time.
     * @type {boolean}
     * @default false
     */
    isSingleRowSelect = false,
    /**
     * `true` to hide a search box.
     * @type {boolean}
     * @default false
     */
    noSearch = false,
    /**
     * `true` to render a sticky table header.
     * @type {boolean}
     * @default false
     */
    hasSticky = false,
    /**
     * `true` to render a date range picker.
     * @type {boolean}
     * @default false
     */
    hasDateRange = false,
    /**
     * `true` to have an option inside date range picker for lifetime data.
     * @type {boolean}
     * @default false
     */
    hasLifetimeRange = false,
    /**
     * A placeholder text to show when there is no record.
     * @type {string}
     * @default `No records found.`
     */
    noRecordText = 'No records found.',
    /**
     * An associated filter's name.
     * @type {string}
     */
    filterName,
    /**
     * `true` to use a standalone filter modal, instead of filter pane.
     * @type {boolean}
     * @default false
     */
    useFilterModal = false,
    /**
     * An unique identifier to differentiate tables so that they can
     * share a single column editor.
     * @type {string|null}
     * @default null
     */
    columnEditorId = null,
    /**
     * `true` to hide `Reset` button in the column editor.
     * @type {boolean}
     * @default true
     */
    columnEditorNoReset = true,
    /**
     * An array of column definitions.
     * @type {Array<object>}
     */
    columns,
    /**
     * An array of column definitions for the column editor.
     * @type {Array<object>}
     */
    columnList,
    /**
     * An array of columns selected and visible.
     * @type {Array<string>}
     */
    columnSelection,
    /**
     * An indicator to show a loading status.
     * @type {boolean}
     * @default false
     */
    isLoading = false,
    /**
     * A name to be used as an exported file name.
     * @type {string}
     */
    exportFileName = '',
    /**
     * Callback to get exportable data for a record.
     * @type {function}
     */
    getExportData = null,
    /**
     * `true` to match records with an exact match as a keyword when searching,
     * `false` to match records that contain a keyword.
     * @type {boolean}
     * @default false
     */
    exactSearch = false,
    /**
     * `true` to show exclusive/inclusive search option,
     * `false` to hide exclusive/inclusive search option.
     * @type {boolean}
     * @default true
     */
    exclusable = true,
    /**
     * Placeholder text for a search box.
     * @type {string}
     * @default `Type to search`
     */
    searchPlaceholder = 'Type to search',
    /**
     * A render function for individual records.
     * @type {function}
     */
    renderRecord,
    /**
     * A render function for an aggregation row.
     * `null` not to add an aggregation row.
     * @type {function|null}
     * @default null
     */
    renderTotal = null,
    /**
     * A render function for action buttons above a table.
     * @type {function}
     */
    renderTopRight,
    /**
     * A render function for a secondary set of action buttons above a table.
     * @type {function}
     */
    renderTopRightSecondary,
    /**
     * Callback that fired when selection changes.
     * @type {function}
     */
    onChange = () => {},
    /**
     * Callback that fired when clicking on records.
     * @type {function}
     */
    onClickRecord = () => {},
    /**
     * Callback for a custom search logic.
     * @type {function|null}
     */
    onSearch = null,
    /**
     * Callback fired when a date range is changed.
     * @type {function|null}
     */
    onChangeDate = null,
    /**
     * A custom validator function for filter values.
     * @type {function|null}
     */
    onFilterValidate = null,
    /**
     * Child components.
     */
    children,
  } = props

  const dispatch = useDispatch()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const visibleFilterName = useSelector(state => state.pageGlobal.visibleFilterName)
  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleColumnEditorId = useSelector(state => state.pageGlobal.visibleColumnEditorId)

  // A search keyword.
  const [keyword, setKeyword] = useState('')
  // Flag for exclusive or inclusive search
  const [searchInclusive, setSearchInclusive] = useState(true)
  // Records that satisfy filters.
  const [filteredRecords, setFilteredRecords] = useState(records)
  // Pagination settings.
  const [pageStart, setPageStart] = useState(0)
  const [pageEnd, setPageEnd] = useState(DEFAULT_PAGE_SIZE)
  // An offset value to render a sticky header.
  const [stickyOffset, setStickyOffset] = useState(null)
  // Whether a `lifetime` range option is selected.
  const [lifetimeSelected, setLifetimeSelected] = useState(false)

  // Ref values to render a sticky header.
  const refBody = useRef(null)
  const refHeader = useRef(null)
  const refFooter = useRef(null)
  const refAction = useRef(null)

  useEffect(() => {
    doFilter()
  }, [records]) // eslint-disable-line

  useEffect(() => {
    if (filterName) {
      doFilter()
    }
  }, [filterValues]) // eslint-disable-line

  // Attach a scroll event listener.
  useEffect(() => {
    let mainContent
    if (hasSticky) {
      mainContent = document.querySelector('.main-content')
      mainContent.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (mainContent) {
        mainContent.removeEventListener('scroll', handleScroll)
      }
    }
  }, []) // eslint-disable-line

  // Adjust positions of sticky header/footer.
  useEffect(() => {
    if (stickyOffset === null) {
      return
    }
    let offset = 0
    if (hasAction()) {
      refAction.current.style.top = `${stickyOffset}px`
      offset = refAction.current.clientHeight
    }
    if (refHeader.current) {
      refHeader.current.style.top = `${stickyOffset + offset}px`
    }
    if (renderTotal) {
      if (refHeader.current) {
        refFooter.current.style.top = `${refHeader.current.clientHeight + stickyOffset + offset}px`
      } else {
        refFooter.current.style.top = `${stickyOffset + offset}px`
      }
    }
  }, [stickyOffset, renderTotal]) // eslint-disable-line

  // Listen for scroll event on main contents area.
  const handleScroll = () => {
    if (refBody.current) {
      const { top } = refBody.current.getBoundingClientRect()
      if (top < 0) {
        setStickyOffset(-top)
        return
      }
    }
    setStickyOffset(null)
  }

  // When a record is selected/deselected.
  const handleCheck = record => (checked) => {
    if (!isSingleRowSelect) {
      const newList = [...selectedRecords]
      if (checked) {
        newList.push(record[idField])
      } else {
        newList.splice(newList.indexOf(record[idField]), 1)
      }
      onChange(newList)
    } else {
      if (checked) {
        onChange([record[idField]])
      } else {
        onChange([])
      }
    }
  }

  // When a select-all-on-page checkbox is clicked.
  const handleCheckAllOnPage = (checked) => {
    if (checked) {
      if (noPagination) {
        onChange(filteredRecords.map(record => record[idField]))
      } else {
        onChange(filteredRecords.slice(pageStart, pageEnd).map(record => record[idField]))
      }
    } else {
      const filteredIds = filteredRecords.map(record => record[idField])
      onChange(selectedRecords.filter(record => !filteredIds.includes(record)))
    }
  }

  // When a select-all checkbox is clicked.
  const handleCheckAll = (event) => {
    event.preventDefault()
    onChange(filteredRecords.map(record => record[idField]))
  }

  // When a unselect-all checkbox is clicked.
  const handleUncheckAll = (event) => {
    event.preventDefault()
    onChange([])
  }

  // Filter records.
  const doFilter = (noCheckChange = false) => {
    let recordsToHandle
    if (keyword === '') {
      recordsToHandle = records
    } else {
      const lowerCased = keyword.toLowerCase()
      recordsToHandle = records.filter(record => (
        searchFields.find((field) => {
          if (searchInclusive) {
            if (exactSearch) {
              return (record[field] || '').toLowerCase() === lowerCased
            }
            return (record[field] || '').toLowerCase().indexOf(lowerCased) !== -1
          } else {
            return !(record[field] || '').toLowerCase().includes(lowerCased)
          }
        })
      ))
    }

    if (filterName) {
      recordsToHandle = recordsToHandle.filter(record => (
        matchFilter(record, filterDef[filterName], (filterValues || {})[filterName] || {})
      ))
    }

    setFilteredRecords(recordsToHandle)

    if (!noCheckChange) {
      const filteredIds = recordsToHandle.map(record => record[idField])
      onChange(selectedRecords.filter(record => filteredIds.includes(record)))
    }
  }

  // When a search keyword is changed.
  const handleKeywordPress = (event) => {
    if (event.key !== 'Enter') {
      return
    }
    if (onSearch) {
      onSearch(keyword)
      return
    }

    doFilter(true)

    // Un-check when searching.
    onChange([])
  }

  // When a date range is changed.
  const handleRangeChange = ([startDate, endDate]) => {
    if (startDate !== null && endDate !== null) {
      dispatch(setDateRange({
        startDate,
        endDate,
      }))
      setLifetimeSelected(false)
    } else {
      setLifetimeSelected(true)
    }

    if (onChangeDate) {
      onChangeDate(startDate, endDate)
    }
  }

  // Show a filter pane.
  const handleShowFilter = () => {
    dispatch(showFilter(filterName))
  }

  // Show a column editor.
  const handleShowColumnEditor = () => {
    dispatch(showColumnEditorAction(columnEditorId))
  }

  // Callback for a column editor.
  const handleColumnChange = (selectedColumns) => {
    return selectColumns(selectedColumns, columnEditorId)
  }

  // Callback for a paginator to load records.
  const loadData = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStart((pageNum - 1) * pageRows)
      setPageEnd(pageNum * pageRows)
    } else {
      setPageStart(0)
      setPageEnd(filteredRecords.length)
    }
  }

  // Check if all records on page are selected.
  const checkIfAllSelected = () => {
    if (!selectedRecords.length) {
      return false
    }

    let recordsOnPage
    if (noPagination) {
      recordsOnPage = filteredRecords
    } else {
      recordsOnPage = filteredRecords.slice(pageStart, pageEnd)
    }

    return typeof recordsOnPage.find(record => (
      selectedRecords.indexOf(record[idField]) === -1
    )) === 'undefined'
  }

  // Check if an action bar needs to be rendered.
  const hasAction = () => (
    !(noSearch && !renderTopRight && !renderTopRightSecondary
      && !hasDateRange && (typeof filterName === 'undefined' || useFilterModal)
      && (typeof columnList === 'undefined' || columnEditorId === null)
      && !getExportData)
  )

  // Show a selection state. Mock an Amazon functionality.
  const renderSelectionState = () => {
    if (
      noCheckBox
      || noHeaderCheckBox
      || noPagination
      || !checkIfAllSelected()
      || !filteredRecords.length
    ) {
      return null
    }

    if (selectedRecords.length === filteredRecords.length) {
      // When there is only one page, nothing to show.
      if (pageStart === 0 && pageEnd >= filteredRecords.length) {
        return null
      }

      return (
        <div className="selection-state">
          All <strong>{ filteredRecords.length }</strong> results are selected.
          <a href="#" onClick={handleUncheckAll}>
            Clear selection
          </a>
        </div>
      )
    }

    return (
      <div className="selection-state">
        All <strong>{ selectedRecords.length }</strong> results on this page are selected.
        <a href="#" onClick={handleCheckAll}>
          Select all { filteredRecords.length } results found.
        </a>
      </div>
    )
  }

  // Render an action bar.
  const renderAction = () => {
    if (!hasAction()) {
      return null
    }

    let tableActionClass = 'table-header'
    if (stickyOffset !== null) {
      tableActionClass = `${tableActionClass} sticky`
    }

    return (
      <div className={tableActionClass} ref={refAction}>
        {
          !noSearch && (
            <div className="table-header-left">
              <SearchBox
                placeholder={searchPlaceholder}
                keyword={keyword}
                exclusable={exclusable}
                searchInclusive={searchInclusive}
                onChange={setKeyword}
                onKeyPress={handleKeywordPress}
                onChangeSearchInclusive={setSearchInclusive}
              />
            </div>
          )
        }
        {
          (renderTopRight || renderTopRightSecondary
          || hasDateRange || (typeof filterName !== 'undefined' && !useFilterModal)
          || (typeof columnList !== 'undefined' && columnEditorId)
          || getExportData !== null) && (
            <div className="table-header-right">
              { renderTopRight ? renderTopRight() : null }
              {
                hasDateRange && (
                  <DateRangeComponent
                    placement="bottomEnd"
                    value={!lifetimeSelected ? [currentStartDate, currentEndDate] : [null, null]}
                    hasLifetimeRange={hasLifetimeRange}
                    onChange={handleRangeChange}
                  />
                )
              }
              {
                getExportData !== null && (
                  <CSVLink
                    data={csvToExport}
                    filename={`${exportFileName}.csv`}
                    className="export-link"
                    title="Export to CSV"
                  >
                    <FiDownload size={16} />
                  </CSVLink>
                )
              }
              { renderTopRightSecondary ? renderTopRightSecondary() : null }
              {
                (typeof filterName !== 'undefined' && !useFilterModal) && (
                  <FiFilter
                    title="Filter"
                    color="#979797"
                    onClick={handleShowFilter}
                  />
                )
              }
              {
                (typeof columnList !== 'undefined' && columnEditorId) && (
                  <FiColumns
                    title="Column Customizer"
                    color="#979797"
                    onClick={handleShowColumnEditor}
                  />
                )
              }
            </div>
          )
        }
      </div>
    )
  }

  // Render a select check box in header.
  const renderHeaderCheckbox = () => {
    if (noCheckBox) {
      return null
    }

    if (noHeaderCheckBox || isSingleRowSelect) {
      return (
        <div className="table-col col-check"></div>
      )
    }

    return (
      <div className="table-col col-check">
        <CheckboxComponent
          checked={checkIfAllSelected()}
          onChange={handleCheckAllOnPage}
        />
      </div>
    )
  }

  // Render records.
  const renderRecords = () => {
    if (!filteredRecords.length) {
      return (
        <div className="table-row">
          <div className="table-col">
            { noRecordText }
          </div>
        </div>
      )
    }

    let recordsOnPage
    if (noPagination) {
      recordsOnPage = filteredRecords
    } else {
      recordsOnPage = filteredRecords.slice(pageStart, pageEnd)
    }

    return recordsOnPage.map((record, index) => {
      const checked = selectedRecords.indexOf(record[idField]) !== -1
      return (
        <div
          key={record[idField]}
          className={`table-row${record.className ? ` ${record.className}` : ''}`}
          onClick={() => { onClickRecord(record) }}
        >
          {
            !noCheckBox && (
              <div className="table-col col-check">
                <CheckboxComponent
                  checked={checked}
                  onChange={handleCheck(record)}
                />
              </div>
            )
          }
          { renderRecord(record, checked, pageStart + index) }
        </div>
      )
    })
  }

  // Render aggregation row.
  const renderAggregation = () => {
    if (!renderTotal) {
      return null
    }

    const summary = {
      revenue: 0,
      cost: 0,
      orders: 0,
      impressions: 0,
      clicks: 0,
      yearlyCost: 0,
      ntb_orders: 0,
      ntb_sales: 0,
      st_impr_rank: 0,
      st_impr_share: 0,
      viewable_impressions: 0,
      daily_budget: 0,
    }

    filteredRecords.forEach((record) => {
      summary.revenue += parseFloat(record.revenue || 0)
      summary.cost += parseFloat(record.cost || 0)
      summary.orders += parseInt(record.orders || 0, 10)
      summary.impressions += parseInt(record.impressions || 0, 10)
      summary.clicks += parseInt(record.clicks || 0, 10)
      summary.yearlyCost += parseFloat(record.yearlyCost || 0)
      summary.ntb_orders += parseInt(record.ntb_orders || 0, 10)
      summary.ntb_sales += parseFloat(record.ntb_sales || 0)
      summary.st_impr_rank += parseFloat(record.st_impr_rank || 0)
      summary.st_impr_share += parseFloat(record.st_impr_share || 0)
      summary.viewable_impressions += parseInt(record.viewable_impressions || 0, 10)
      summary.daily_budget += parseFloat(record.daily_budget || 0)
    })

    if (filteredRecords.length) {
      summary.st_impr_rank /= filteredRecords.length
      summary.st_impr_share /= filteredRecords.length
    }

    const derived = calcDerivedMetrics(summary)

    return (
      <div className="table-row content-footer" ref={refFooter}>
        {
          !noCheckBox && (
            <div className="table-col col-check"></div>
          )
        }
        { renderTotal(derived) }
      </div>
    )
  }

  // Prepare data to export.
  const csvToExport = useMemo(() => {
    if (!getExportData) {
      return ''
    }

    let dataToExport = []

    // Add row for headings.
    const exportableColumns = []
    columns.forEach((column) => {
      if (column.exportable === false) {
        return
      }

      if (typeof columnList !== 'undefined') {
        const found = columnList.find(c => c.key === column.key)
        if (found && !columnSelection.find(c => c.key === column.key)) {
          return
        }
      }

      exportableColumns.push(column)
    })
    dataToExport.push(exportableColumns.map(column =>
      column.plainName || column.name || column.label
    ))

    dataToExport = dataToExport.concat(
      filteredRecords.map(record =>
        getExportData(exportableColumns, record)
      )
    )

    return dataToExport
  }, [columns, columnList, columnSelection, filteredRecords, getExportData])

  // Prepare CSS classes for table body.
  let tableBodyClass = 'table-body'
  if (renderTotal) {
    tableBodyClass = `${tableBodyClass} has-footer`
  }
  if (hasAction()) {
    tableBodyClass = `${tableBodyClass} has-action`
  }
  if (stickyOffset !== null) {
    tableBodyClass = `${tableBodyClass} sticky`
  }

  return (
    <div className={`custom-table-component ${className} ${isLoading ? 'loading' : ''}`}>
      { isLoading && <LoaderComponent /> }
      {
        typeof filterName !== 'undefined' && (
          <TableFilterShower
            filterName={filterName}
            onValidate={onFilterValidate}
          />
        )
      }
      { renderSelectionState() }
      { renderAction() }
      <div className={tableBodyClass} ref={refBody}>
        {
          typeof children !== 'undefined' && (
            <div className="table-row content-header" ref={refHeader}>
              { renderHeaderCheckbox() }
              { children }
            </div>
          )
        }
        { renderAggregation() }
        { renderRecords() }
      </div>
      {
        !noPagination && (
          <PaginationComponent
            pageNeighbours={paginationNeighbours}
            total={filteredRecords.length}
            loadData={loadData}
          />
        )
      }
      {
        (typeof filterName !== 'undefined')
        && !useFilterModal
        && (visibleFilterName === filterName)
        && <TableFilter filterName={filterName} />
      }
      {
        typeof columnList !== 'undefined' && showColumnEditor && visibleColumnEditorId === columnEditorId &&
        <ColumnEditor
          columnList={columnList}
          currentSelection={columnSelection}
          noReset={columnEditorNoReset}
          onApply={handleColumnChange}
        />
      }
    </div>
  )
}

export default CustomTable
