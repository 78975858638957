import { dspCampaignColumnList } from "../utils/dspDefaultValues"

export const calcDerivedMetrics = (record) => {
  let measurable_rate = 0
  let viewability_rate = 0
  let e_cpm = 0
  let ctr = 0
  let e_cpc = 0
  let off_amazon_roas = 0
  let off_amazon_erpm = 0
  let combined_purchases = 0
  let combined_purchases_views = 0
  let combined_purchases_clicks = 0
  let combined_purchase_rate = 0
  let combined_ecpp = 0
  let combined_roas = 0
  let combined_erpm = 0
  let dpvr = 0
  let e_cpdpv = 0
  let p_rpvr = 0
  let e_cpprpv = 0
  let atlr = 0
  let e_cp_atl = 0
  let atcr = 0
  let e_cp_atc = 0
  let purchase_rate = 0
  let e_cpp = 0
  let ntb_purchase_rate = 0
  let new_to_brand_ecpp = 0
  let roas = 0
  let e_rpm = 0
  let ntb_roas = 0
  let ntb_erpm = 0
  let purchase_click_rate = 0
  let dpv_click_rate = 0
  let ecp_dpv_clicks = 0
  let ecpp_clicks = 0
  let total_purchase_click_rate = 0
  let total_dpv_click_rate = 0
  let total_ecpdpv_clicks = 0
  let total_ecpp_clicks = 0
  let total_roas = 0
  let total_erpm = 0
  let total_dpvr = 0
  let total_ecpdpv = 0
  let total_prpvr = 0
  let total_ecpprpv = 0
  let total_atlr = 0
  let total_ecpatl = 0
  let total_atcr = 0
  let total_ecpatc = 0
  let total_purchase_rate = 0
  let total_ecpp = 0
  let total_ntb_purchase_rate = 0
  let total_ntb_ecpp = 0
  let total_ntb_roas = 0
  let total_ntb_erpm = 0

  if (parseInt(record.impressions, 10)) {
    measurable_rate = parseInt(record.measurable_impressions, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.measurable_impressions, 10)) {
    viewability_rate = parseInt(record.viewable_impressions, 10) / parseInt(record.measurable_impressions, 10) * 100
  }
  if (parseInt(record.impressions, 10)) {
    e_cpm = parseFloat(record.total_cost) / parseInt(record.impressions, 10) * 1000
  }
  if (parseInt(record.impressions, 10)) {
    ctr = parseInt(record.click_throughs, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.click_throughs, 10)) {
    e_cpc = parseFloat(record.total_cost) / parseInt(record.click_throughs, 10)
  }
  if (parseFloat(record.total_cost)) {
    off_amazon_roas = parseFloat(record.off_amazon_product_sales_14d) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    off_amazon_erpm = parseFloat(record.off_amazon_product_sales_14d) / parseInt(record.impressions, 10) * 1000
  }

  combined_purchases = parseFloat(record.off_amazon_purchases_14d) + parseFloat(record.total_purchases_14d)
  combined_purchases_views = parseInt(record.off_amazon_purchases_views_14d, 10)
    + parseInt(record.total_purchases_views_14d, 10)
  combined_purchases_clicks = parseInt(record.off_amazon_purchases_clicks_14d, 10)
    + parseInt(record.total_purchases_clicks_14d, 10)
  
  if (parseInt(record.impressions, 10)) {
    combined_purchase_rate = combined_purchases / parseInt(record.impressions, 10) * 100
  }
  if (combined_purchases) {
    combined_ecpp = parseFloat(record.total_cost) / combined_purchases
  }
  if (parseFloat(record.total_cost)) {
    combined_roas = parseFloat(record.combined_product_sales) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    combined_erpm = parseFloat(record.combined_product_sales) / parseInt(record.impressions, 10) * 1000
  }
  if (parseInt(record.impressions, 10)) {
    dpvr = parseInt(record.dpv_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.dpv_14d, 10)) {
    e_cpdpv = parseFloat(record.total_cost) / parseInt(record.dpv_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    p_rpvr = parseInt(record.p_rpv_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.p_rpv_14d, 10)) {
    e_cpprpv = parseFloat(record.total_cost) / parseInt(record.p_rpv_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    atlr = parseInt(record.atl_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.atl_14d, 10)) {
    e_cp_atl = parseFloat(record.total_cost) / parseInt(record.atl_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    atcr = parseInt(record.atc_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.atc_14d, 10)) {
    e_cp_atc = parseFloat(record.total_cost) / parseInt(record.atc_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    purchase_rate = parseFloat(record.purchases_14d) / parseInt(record.impressions, 10) * 100
  }
  if (parseFloat(record.purchases_14d)) {
    e_cpp = parseFloat(record.total_cost) / parseFloat(record.purchases_14d)
  }
  if (parseInt(record.impressions, 10)) {
    ntb_purchase_rate = parseFloat(record.new_to_brand_purchases_14d) / parseInt(record.impressions, 10) * 100
  }
  if (parseFloat(record.new_to_brand_purchases_14d)) {
    new_to_brand_ecpp = parseFloat(record.total_cost) / parseFloat(record.new_to_brand_purchases_14d)
  }
  if (parseFloat(record.total_cost)) {
    roas = parseFloat(record.sales_14d) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    e_rpm = parseFloat(record.sales_14d) / parseInt(record.impressions, 10) * 1000
  }
  if (parseFloat(record.total_cost)) {
    ntb_roas = parseFloat(record.new_to_brand_product_sales_14d) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    ntb_erpm = parseFloat(record.new_to_brand_product_sales_14d) / parseInt(record.impressions, 10) * 1000
  }
  if (parseInt(record.impressions, 10)) {
    purchase_click_rate = parseInt(record.purchases_clicks_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.impressions, 10)) {
    dpv_click_rate = parseInt(record.dpv_clicks_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.dpv_clicks_14d, 10)) {
    ecp_dpv_clicks = parseFloat(record.total_cost) / parseInt(record.dpv_clicks_14d, 10)
  }
  if (parseInt(record.purchases_clicks_14d, 10)) {
    ecpp_clicks = parseFloat(record.total_cost) / parseInt(record.purchases_clicks_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_purchase_click_rate = parseInt(record.total_purchases_clicks_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.impressions, 10)) {
    total_dpv_click_rate = parseInt(record.total_detail_page_clicks_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_detail_page_clicks_14d, 10)) {
    total_ecpdpv_clicks = parseFloat(record.total_cost) / parseInt(record.total_detail_page_clicks_14d, 10)
  }
  if (parseInt(record.total_purchases_clicks_14d, 10)) {
    total_ecpp_clicks = parseFloat(record.total_cost) / parseInt(record.total_purchases_clicks_14d, 10)
  }
  if (parseFloat(record.total_cost)) {
    total_roas = parseFloat(record.total_sales_14d) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    total_erpm = parseFloat(record.total_sales_14d) / parseInt(record.impressions, 10) * 1000
  }
  if (parseInt(record.impressions, 10)) {
    total_dpvr = parseInt(record.total_detail_page_views_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_detail_page_views_14d, 10)) {
    total_ecpdpv = parseFloat(record.total_cost) / parseInt(record.total_detail_page_views_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_prpvr = parseInt(record.total_add_to_cart_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_prpv_14d, 10)) {
    total_ecpprpv = parseFloat(record.total_cost) / parseInt(record.total_prpv_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_atlr = parseInt(record.total_add_to_list_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_add_to_list_14d, 10)) {
    total_ecpatl = parseFloat(record.total_cost) / parseInt(record.total_add_to_list_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_atcr = parseInt(record.total_add_to_cart_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_add_to_cart_14d, 10)) {
    total_ecpatc = parseFloat(record.total_cost) / parseInt(record.total_add_to_cart_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_purchase_rate = parseInt(record.total_purchases_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_purchases_14d, 10)) {
    total_ecpp = parseFloat(record.total_cost) / parseInt(record.total_purchases_14d, 10)
  }
  if (parseInt(record.impressions, 10)) {
    total_ntb_purchase_rate = parseInt(record.total_new_to_brand_purchases_14d, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.total_new_to_brand_purchases_14d, 10)) {
    total_ntb_ecpp = parseFloat(record.total_cost) / parseInt(record.total_new_to_brand_purchases_14d, 10)
  }
  if (parseFloat(record.total_cost)) {
    total_ntb_roas = parseFloat(record.total_new_to_brand_product_sales_14d) / parseFloat(record.total_cost)
  }
  if (parseInt(record.impressions, 10)) {
    total_ntb_erpm = parseFloat(record.total_new_to_brand_product_sales_14d) / parseInt(record.impressions, 10) * 1000
  }

  return {
    ...record,
    measurable_rate,
    viewability_rate,
    e_cpm,
    ctr,
    e_cpc,
    off_amazon_roas,
    off_amazon_erpm,
    combined_purchases,
    combined_purchases_views,
    combined_purchases_clicks,
    combined_purchase_rate,
    combined_ecpp,
    combined_roas,
    combined_erpm,
    dpvr,
    e_cpdpv,
    p_rpvr,
    e_cpprpv,
    atlr,
    e_cp_atl,
    atcr,
    e_cp_atc,
    purchase_rate,
    e_cpp,
    ntb_purchase_rate,
    new_to_brand_ecpp,
    roas,
    e_rpm,
    ntb_roas,
    ntb_erpm,
    purchase_click_rate,
    dpv_click_rate,
    ecp_dpv_clicks,
    ecpp_clicks,
    total_purchase_click_rate,
    total_dpv_click_rate,
    total_ecpdpv_clicks,
    total_ecpp_clicks,
    total_roas,
    total_erpm,
    total_dpvr,
    total_ecpdpv,
    total_prpvr,
    total_ecpprpv,
    total_atlr,
    total_ecpatl,
    total_atcr,
    total_ecpatc,
    total_purchase_rate,
    total_ecpp,
    total_ntb_purchase_rate,
    total_ntb_ecpp,
    total_ntb_roas,
    total_ntb_erpm,
  }
}

export const getCampaignColumns = (columnKeys) => columnKeys.map(
  c1 => dspCampaignColumnList.find(c2 => c2.key === c1)
)
