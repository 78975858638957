import React, { useMemo } from 'react'

import TableCell from '../CommonComponents/TableCell'

import { calcDerivedMetrics } from '../../services/helper'

const TableFooter = ({ campaigns, selectedColumns, currencySign, currencyRate,
  refTotal, hideDailyBudget, isNonEndemicAccount }) => {
  let total = useMemo(() => {
    const summary = {
      revenue: 0,
      cost: 0,
      impressions: 0,
      clicks: 0,
      orders: 0,
      ntb_orders: 0,
      ntb_sales: 0,
      viewable_impressions: 0,
      daily_budget: 0,
      video_views_25: 0,
      video_views_50: 0,
      video_views_75: 0,
      video_views_100: 0,
      video_5s_views: 0,
    }

    campaigns.forEach((record) => {
      summary.revenue += record.revenue
      summary.cost += record.cost
      summary.impressions += record.impressions
      summary.clicks += record.clicks
      summary.orders += record.orders
      summary.ntb_orders += record.ntb_orders
      summary.ntb_sales += record.ntb_sales
      summary.viewable_impressions += (record.viewable_impressions || 0)
      summary.daily_budget += (record.daily_budget || 0)
      summary.video_views_25 += (record.video_views_25 || 0)
      summary.video_views_50 += (record.video_views_50 || 0)
      summary.video_views_75 += (record.video_views_75 || 0)
      summary.video_views_100 += (record.video_views_100 || 0)
      summary.video_5s_views += (record.video_5s_views || 0)
    })

    return summary
  }, [campaigns])

  total = calcDerivedMetrics(total)

  return (
    <div className="table-row content-footer" ref={refTotal}>
      <div className="table-col"></div>
      <div className="table-col">Totals:</div>
      {
        selectedColumns.map((column) => {
          if (
            column.fixed
            ||
            (hideDailyBudget && column.key === 'daily_budget')
            ||
            (
              isNonEndemicAccount
              && [
                'target_acos',
                'revenue',
                'acos',
                'orders',
                'conversion',
                'roas',
                'ntb_orders',
                'ntb_orders_percent',
                'ntb_sales',
                'ntb_sales_percent',
                'video_views_25',
                'video_views_50',
                'video_views_75',
                'video_views_100',
                'video_5s_views',
                'view_5_seconds_rate',
              ].includes(column.key)
            )
          ) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={total}
              columnKey={column.key}
              columnSelection={selectedColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
      <div className="table-col"></div>
    </div>
  )
}

export default TableFooter
