import React, { useState } from 'react'
import { IconButton, Icon } from 'rsuite'

import YouTubeVideoModal from '../../components/CommonComponents/YouTubeVideoModal'

const YoutubeVideoCollection = ({ videoList, onWatch }) => {
  const [selectedVideoName, setSelectedVideoName] = useState('')
  const [selectedVideo, setSelectedVideo] = useState(null)

  const handleSelectVideo = (e, video) => {
    e.preventDefault()
    setSelectedVideoName(video.name)
    setSelectedVideo(p => ({...video}))
  }

  return (
    <>
      <div className="video-list">
        {
          videoList.map(video => (
            <div
              key={video.name}
              className="video-item"
              onClick={(e) => handleSelectVideo(e, video)}
            >
              <div className="video-name">
                { video.name }
              </div>
              <img
                src={
                  `http://img.youtube.com/vi/${video.videoId}/mqdefault.jpg`
                }
                alt={video.name}
              />
              <div className="youtube-play-btn">
                <IconButton 
                  icon={<Icon icon="play" />} 
                  appearance="primary" 
                  size="lg"
                  color="red"
                />
              </div>
            </div>
          ))
        }
      </div>
      <YouTubeVideoModal
        showModal={selectedVideoName !== ''}
        video={selectedVideo}
        onClose={() => { setSelectedVideoName(''); setSelectedVideo(null) }}
        title={selectedVideoName}
        onWatch={onWatch}
      />
    </>
  )
}

export default YoutubeVideoCollection
