import {
  FIND_TARGETS_SUCCEED,
  ADJUST_KEYWORD_BIDS_SUCCEED,
  ADJUST_TARGET_BIDS_SUCCEED,
  FIND_DUPS_SUCCEED,
  UPDATE_KEYWORD_STATES_SUCCEED,
  UPDATE_TARGET_STATES_SUCCEED,
  GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED,
  GET_SKU_OP_SUCCEED,
  UPDATE_PA_STATES_SUCCEED,
  GET_TARGET_OP_SUCCEED,
  GET_ST_OP_SUCCEED,
  ADD_NEGATIVES_OP_SUCCEED,
  GET_NEGATIVE_FINDER_SUCCEED,
  GET_PLACEMENT_OP_SUCCEED,
  GET_ADVANCED_NEGATIVE_SUCCEED,
  ARCHIVE_NKS_SUCCEED,
  ARCHIVE_NTS_SUCCEED,
  CREATE_AD_GROUP_SUCCEED,
  GET_ASIN_IMAGES_SUCCEED,
} from '../actionTypes/bulkEngine'
import { UPDATE_BIDDINGS_SUCCEED } from '../actionTypes/campaign'

import { parseTargetExp } from '../../services/helper'

export const initialState = {
  // Target search
  findTargetsData: {},
  findDupsData: {},
  // Bulk ex
  adgroupsToAddTargetsData: [],
  // SKU op
  skuOpData: [],
  // Target op
  targetOpData: {},
  // ST op
  stOpData: [],
  // Negative finder
  negativeFinderData: [],
  // Placement op
  placementOpData: [],
  // Advanced op
  advancedNegativeData: {},
}

const bulkEngine = (state = initialState, action) => {
  switch (action.type) {
    case FIND_TARGETS_SUCCEED:
      return {
        ...state,
        findTargetsData: action.data,
        findDupsData: {},
        skuOpData: [],
        targetOpData: {},
        stOpData: [],
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: {},
      }
    case ADJUST_KEYWORD_BIDS_SUCCEED:
      const updatedKeywordsForBid = {}

      Object.keys(state.findDupsData.keywords || {}).forEach((keyword) => {
        updatedKeywordsForBid[keyword] = state.findDupsData.keywords[keyword].map((record) => {
          if (action.data.includes(record.keyword_id.toString())) {
            const payload = action.payload.find(p => (
              p.keywordId.toString() === record.keyword_id.toString())
            )
            if (payload) {
              return {
                ...record,
                bid: payload.bid,
              }
            }
          }
          return record
        })
      })

      return {
        ...state,
        findTargetsData: Object.assign({}, state.findTargetsData, {
          keywords: (state.findTargetsData.keywords || []).map((keyword) => {
            if (action.data.includes(keyword.keyword_id.toString())) {
              const payload = action.payload.find(record => (
                record.keywordId.toString() === keyword.keyword_id.toString())
              )
              if (payload) {
                return {
                  ...keyword,
                  bid: payload.bid,
                }
              }
            }
            return keyword
          }),
        }),
        targetOpData: Object.assign({}, state.targetOpData, {
          keywords: ((state.targetOpData || {}).keywords || []).map((keyword) => {
            if (action.data.includes(keyword.keyword_id.toString())) {
              const payload = action.payload.find(record => (
                record.keywordId.toString() === keyword.keyword_id.toString())
              )
              if (payload) {
                return {
                  ...keyword,
                  bid: payload.bid,
                }
              }
            }
            return keyword
          }),
        }),
        findDupsData: Object.assign({}, state.findDupsData, {
          keywords: updatedKeywordsForBid,
        })
      }
    case ADJUST_TARGET_BIDS_SUCCEED:
      const updatedTargetsForBid = {}

      Object.keys(state.findDupsData.targets || {}).forEach((target) => {
        updatedTargetsForBid[target] = state.findDupsData.targets[target].map((record) => {
          if (action.data.includes(record.target_id.toString())) {
            const payload = action.payload.find(p => (
              p.targetId.toString() === record.target_id.toString())
            )
            if (payload) {
              return {
                ...record,
                bid: payload.bid,
              }
            }
          }
          return record
        })
      })

      return {
        ...state,
        findTargetsData: Object.assign({}, state.findTargetsData, {
          targets: (state.findTargetsData.targets || []).map((record) => {
            if (action.data.includes(record.target_id.toString())) {
              const payload = action.payload.find(p => (
                p.targetId.toString() === record.target_id.toString())
              )
              if (payload) {
                return {
                  ...record,
                  bid: payload.bid,
                }
              }
            }
            return record
          }),
        }),
        targetOpData: Object.assign({}, state.targetOpData, {
          targets: ((state.targetOpData || {}).targets || []).map((target) => {
            if (action.data.includes(target.target_id.toString())) {
              const payload = action.payload.find(record => (
                record.targetId.toString() === target.target_id.toString())
              )
              if (payload) {
                return {
                  ...target,
                  bid: payload.bid,
                }
              }
            }
            return target
          }),
        }),
        findDupsData: Object.assign({}, state.findDupsData, {
          targets: updatedTargetsForBid,
        })
      }
    case FIND_DUPS_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: action.data,
        skuOpData: [],
        targetOpData: {},
        stOpData: [],
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: {},
      }
    case UPDATE_KEYWORD_STATES_SUCCEED:
      const updatedKeywords = {}

      Object.keys(state.findDupsData.keywords || {}).forEach((keyword) => {
        updatedKeywords[keyword] = state.findDupsData.keywords[keyword].map((record) => {
          if (action.data.includes(record.keyword_id.toString())) {
            return {
              ...record,
              state: action.state,
            }
          }
          return record
        })
      })

      return {
        ...state,
        findTargetsData: Object.assign({}, state.findTargetsData, {
          keywords: (state.findTargetsData.keywords || []).map((record) => {
            if (action.data.includes(record.keyword_id.toString())) {
              return {
                ...record,
                state: action.state,
              }
            }
            return record
          }),
        }),
        findDupsData: Object.assign({}, state.findDupsData, {
          keywords: updatedKeywords,
        }),
        targetOpData: Object.assign({}, state.targetOpData, {
          keywords: ((state.targetOpData || {}).keywords || []).map((record) => {
            if (action.data.includes(record.keyword_id.toString())) {
              return {
                ...record,
                state: action.state,
              }
            }
            return record
          }),
        }),
      }
    case UPDATE_TARGET_STATES_SUCCEED:
      const updatedTargets = {}

      Object.keys(state.findDupsData.targets || {}).forEach((target) => {
        updatedTargets[target] = state.findDupsData.targets[target].map((record) => {
          if (action.data.includes(record.target_id.toString())) {
            return {
              ...record,
              state: action.state,
            }
          }
          return record
        })
      })

      return {
        ...state,
        findTargetsData: Object.assign({}, state.findTargetsData, {
          targets: (state.findTargetsData.targets || []).map((record) => {
            if (action.data.includes(record.target_id.toString())) {
              return {
                ...record,
                state: action.state,
              }
            }
            return record
          }),
        }),
        findDupsData: Object.assign({}, state.findDupsData, {
          targets: updatedTargets,
        }),
        targetOpData: Object.assign({}, state.targetOpData, {
          targets: ((state.targetOpData || {}).targets || []).map((record) => {
            if (action.data.includes(record.target_id.toString())) {
              return {
                ...record,
                state: action.state,
              }
            }
            return record
          }),
        }),
      }
    case GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED:
      return {
        ...state,
        adgroupsToAddTargetsData: action.data,
      }
    case GET_SKU_OP_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: action.data,
        targetOpData: {},
        stOpData: [],
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: {},
      }
    case UPDATE_PA_STATES_SUCCEED:
      return {
        ...state,
        skuOpData: (state.skuOpData || []).map((record) => {
          if (action.data.includes(record.ad_id.toString())) {
            return {
              ...record,
              state: action.state,
            }
          }
          return record
        }),
      }
    case GET_TARGET_OP_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: [],
        targetOpData: action.data,
        stOpData: [],
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: {},
      }
    case GET_ST_OP_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: [],
        targetOpData: {},
        stOpData: action.data,
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: {},
      }
    case ADD_NEGATIVES_OP_SUCCEED:
      return {
        ...state,
        stOpData: (state.stOpData || []).filter((record) => {
          const found = action.data.createdList.find(created => (
            created.campaignId === record.campaign_id.toString()
            && created.adgroupId === record.adgroup_id.toString()
            && created.search === record.search
          ))
          return typeof found === 'undefined'
        }),
        negativeFinderData: (state.negativeFinderData || []).filter((record) => {
          const found = action.data.createdList.find(created => (
            created.campaignId === record.campaign_id.toString()
            && created.adgroupId === record.adgroup_id.toString()
            && created.search === record.search
          ))
          return typeof found === 'undefined'
        }),
        findTargetsData: Object.assign({}, state.findTargetsData, {
          searchTerms: (state.findTargetsData.searchTerms || []).filter((record) => {
            const found = action.data.createdList.find(created => (
              created.campaignId === record.campaign_id.toString()
              && created.adgroupId === record.adgroup_id.toString()
              && created.search === record.search
            ))
            return typeof found === 'undefined'
          }),
        }),
      }
    case GET_NEGATIVE_FINDER_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: [],
        targetOpData: {},
        stOpData: [],
        negativeFinderData: action.data,
        placementOpData: [],
        advancedNegativeData: {},
      }
    case GET_PLACEMENT_OP_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: [],
        targetOpData: {},
        stOpData: [],
        negativeFinderData: [],
        placementOpData: action.data,
        advancedNegativeData: {},
      }
    case UPDATE_BIDDINGS_SUCCEED:
      return {
        ...state,
        placementOpData: state.placementOpData.map((placement) => {
          const found = action.data.find(updated =>
            updated.campaignId.toString() === placement.campaign_id.toString()
          )
          if (found) {
            return {
              ...placement,
              bidding: found.bidding,
            }
          }
          return placement
        })
      }
    case GET_ADVANCED_NEGATIVE_SUCCEED:
      return {
        ...state,
        findTargetsData: {},
        findDupsData: {},
        skuOpData: [],
        targetOpData: {},
        stOpData: [],
        negativeFinderData: [],
        placementOpData: [],
        advancedNegativeData: action.data,
      }
    case ARCHIVE_NKS_SUCCEED:
      return {
        ...state,
        advancedNegativeData: Object.assign({}, state.advancedNegativeData, {
          negativeKeywords: ((state.advancedNegativeData || {}).negativeKeywords || []).map((record) => {
            if (action.data.includes(record.keyword_id.toString())) {
              return {
                ...record,
                state: 'archived',
              }
            }
            return record
          }),
        }),
      }
    case ARCHIVE_NTS_SUCCEED:
      return {
        ...state,
        advancedNegativeData: Object.assign({}, state.advancedNegativeData, {
          negativeTargets: ((state.advancedNegativeData || {}).negativeTargets || []).map((record) => {
            if (action.data.includes(record.target_id.toString())) {
              return {
                ...record,
                state: 'archived',
              }
            }
            return record
          }),
        }),
      }
    case CREATE_AD_GROUP_SUCCEED:
      return {
        ...state,
        adgroupsToAddTargetsData: [
          ...state.adgroupsToAddTargetsData,
          Object.assign({}, action.data, {
            keywords: 0,
            targets: 0,
          }),
        ],
      }
    case GET_ASIN_IMAGES_SUCCEED:
      const newState = {
        ...state,
      }

      if (action.data.module === 'targetOp') {
        newState.targetOpData = Object.assign({}, state.targetOpData, {
          targets: ((state.targetOpData || {}).targets || []).map((record) => {
            const target = parseTargetExp(record.target_text)
            if (target.indexOf('asin=') !== 0) {
              return record
            }

            try {
              const parsed = JSON.parse(record.target_text)
              if (action.data.imageMap[parsed[0].value.toUpperCase()]) {
                return {
                  ...record,
                  image: action.data.imageMap[parsed[0].value.toUpperCase()],
                }
              }
            } catch (e) {
              //
            }
            return record
          }),
        })
      } else if (action.data.module === 'advancedNegative') {
        newState.advancedNegativeData = Object.assign({}, state.advancedNegativeData, {
          negativeTargets: ((state.advancedNegativeData || {}).negativeTargets || []).map((record) => {
            const target = parseTargetExp(record.expression)
            let asin = ''
            if (target.indexOf('asin=') === 0) {
              try {
                const parsed = JSON.parse(record.expression)
                asin = parsed[0].value
              } catch (e) {
                //
              }
            }

            if (asin !== '' && action.data.imageMap[asin.toUpperCase()]) {
              return {
                ...record,
                image: action.data.imageMap[asin.toUpperCase()],
              }
            }
            return record
          }),
        })
      } else if (action.data.module === 'stOp') {
        newState.stOpData = (state.stOpData || []).map((record) => {
          if (action.data.imageMap[record.search.toUpperCase()]) {
            return {
              ...record,
              image: action.data.imageMap[record.search.toUpperCase()],
            }
          }
          return record
        })
      }

      return newState
    default:
      return state
  }
}

export default bulkEngine
