import { callPost } from '../../services/axios.js'

import { COIN_EARNED } from '../actionTypes/coin'

import { COIN_TYPE_SCHEDULE_CALL } from '../../utils/coin'

export const watch = (accessToken, flow) => (dispatch) => {
  return callPost('/tutorial/watch', {
    flow,
  }, accessToken).then((response) => {
    if (response.data.amount) {
      dispatch({
        type: COIN_EARNED,
        history: {
          type: flow,
          amount: response.data.amount,
          newlyEarned: true,
        },
      })
    }
  }).catch(() => {
    // Keep silence.
  })
}

export const scheduleCall = (accessToken) => (dispatch) => {
  return callPost('/tutorial/scheduleCall', {}, accessToken).then((response) => {
    if (response.data.amount) {
      dispatch({
        type: COIN_EARNED,
        history: {
          type: COIN_TYPE_SCHEDULE_CALL,
          amount: response.data.amount,
          newlyEarned: true,
        },
      })
    }
  }).catch(() => {
    // Keep silence.
  })
}
