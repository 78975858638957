import React, { useState, useMemo } from 'react'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import CustomTable from '../CustomTableComponent'

const ORDER_ASC = 'asc'
const ORDER_DESC = 'desc'

const SortableTableComponent = ({ sorter, records, tableComponent, showParentColumnsOnly, ...props }) => {
  const { defaultSort, columns, columnList, columnSelection } = props

  const [sortOption, setSortOption] = useState(defaultSort)

  const handleSort = (column) => {
    if (column.sortable === false) {
      return
    }
    setSortOption([column.key, sortBy === column.key && sortOrder === ORDER_ASC ? ORDER_DESC : ORDER_ASC])
  }

  // eslint-disable-next-line
  const sortedRecords = useMemo(() => sorter(records, sortOption), [records, sortOption])

  const [sortBy, sortOrder] = sortOption

  let TableComponent
  if (tableComponent) {
    TableComponent = tableComponent
  } else {
    TableComponent = CustomTable
  }

  let columnsToRender = columns
  if (showParentColumnsOnly) {
    columnsToRender = columns.filter(column => column.parentOnly === true)
  }

  return (
    <TableComponent
      {...props}
      records={sortedRecords}
    >
      {
        columnsToRender.map((column) => {
          if (typeof columnList !== 'undefined') {
            const found = columnList.find(c => c.key === column.key)
            if (found && !columnSelection.find(c => c.key === column.key)) {
              return null
            }
          }

          return (
            <div
              key={column.key}
              className={`table-col${column.className ? ` ${column.className}` : ''}`}
              onClick={() => { handleSort(column) }}
            >
              { column.name || column.label }
              {
                sortBy === column.key && (
                  sortOrder === ORDER_ASC ? <BsCaretUpFill /> : <BsCaretDownFill />
                )
              }
            </div>
          )
        })
      }
    </TableComponent>
  )
}

export default SortableTableComponent
