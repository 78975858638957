import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import {
  GET_ALL_SPONSORED_DATA_SUCCEED,
  SET_REPORT_DATE_RANGE,
  GET_HEALTH_SALES_DATA_SUCCEED,
  GET_SPONSORED_PRODUCTS_DATA_SUCCEED,
  GET_SPONSORED_BRANDS_DATA_SUCCEED,
  GET_SPONSORED_DISPLAY_DATA_SUCCEED,
  GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED,
  GET_PLACEMENT_PERFORMANCE_SP_DATA,
  GET_PLACEMENT_PERFORMANCE_SB_DATA,
} from '../actionTypes/accountHealth'

export const setReportDateRange = (startDate, endDate) => {
  return {
    type: SET_REPORT_DATE_RANGE,
    data: {
      startDate,
      endDate
    },
  }
}

export const getReportStats = (accessToken, adType, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/dashboard/getStats', accessToken, {
    userId: currentUserId,
    adType,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
    forBranded: true,
  }, signal).then((response) => {
    let type
    if (adType === '') {
      type = GET_ALL_SPONSORED_DATA_SUCCEED
    } else if (adType === 'sp') {
      type = GET_SPONSORED_PRODUCTS_DATA_SUCCEED
    } else if (adType === 'sb') {
      type = GET_SPONSORED_BRANDS_DATA_SUCCEED
    } else if (adType === 'sd') {
      type = GET_SPONSORED_DISPLAY_DATA_SUCCEED
    }

    dispatch({
      type: type,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get stats.',
      })
    }
  })
}

export const getReportSalesStats = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
    },
    accountHealth: {
      startDate,
      endDate
    }
  } = getState()

  return callGet('/dashboard/getSalesStats', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    dispatch({
      type: GET_HEALTH_SALES_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get sales stats.',
      })
    }
  })
}

export const getKeywordsReport = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: {
      startDate,
      endDate,
    },
  } = getState()

  return callGet('/report/getKeywordsReport', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get keywrods report.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getTargetsReport = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/report/getTargetsReport', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get targets report.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getPerformanceData = (accessToken, adType, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/account/getHealth', accessToken, {
    userId: currentUserId,
    adType,
    productId: '',
    portfolioId: '',
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    let type
    if (adType === '') {
      type = GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED
    } else if (adType === 'sp') {
      type = GET_PLACEMENT_PERFORMANCE_SP_DATA
    } else if (adType === 'sb') {
      type = GET_PLACEMENT_PERFORMANCE_SB_DATA
    }
    dispatch({
      type,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get performance data.',
      })
    }
  })
}

export const getSettings = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/report/getSettings', accessToken, {
    userId: currentUserId,
  }).then((response) => {
    return response.data
  }, signal).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve report settings.',
      })
    }
    return {}
  })
}

export const saveSettings = (accessToken, { name, sectionKey, sectionValue }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  const payload = {
    userId: currentUserId,
    sectionKey,
    sectionValue,
  }

  if (typeof name !== 'undefined') {
    payload.name = name
  }

  return callPost('/report/saveSettings', payload, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Saved successfully.',
    })

    const updated = {
      [sectionKey]: sectionValue || '',
    }
    if (typeof name !== 'undefined') {
      updated.name = name
    }

    return updated
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save settings.',
    })
    return {}
  })
}

export const saveBrandLogo = (accessToken, base64Image, extension) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/report/saveBrandLogo', {
    userId: currentUserId,
    logo: base64Image,
    extension,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'The brand logo is uploaded successfully.',
    })
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to upload the brand logo.',
    })
    return {}
  })
}

export const deleteBrandLogo = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/report/deleteBrandLogo', {
    userId: currentUserId
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'The report brand logo has been deleted.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to delete the report brand logo.',
    })
  })
}

export const getAggregatedData = (accessToken, adType, signal) => (dispatch, getState) => {
  const { accountHealth: { startDate, endDate } } = getState()

  return callGet('/report/getAccountsWithMetrics', accessToken, {
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
    adType,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get agency data.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getSalesByAdType = (accessToken, signal) => (dispatch, getState) => {
  const { accountHealth: { startDate, endDate } } = getState()

  return callGet('/report/getSalesByAdType', accessToken, {
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get sales by ad type.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const generateReport = (accessToken, startDate, endDate, exportType) => (dispatch, getState) => {
  const {header: { currentUserId }, pageGlobal: { reportWidgetSetting } } = getState()

  toast.show({
    title: 'Info',
    description: 'Generating report might take a few minutes.',
  })

  // Get only widgets that are visible.
  const visibleSettings = {}
  Object.keys(reportWidgetSetting).forEach((sectionKey) => {
    visibleSettings[sectionKey] = reportWidgetSetting[sectionKey].filter(widget => (
      widget.default
    )).map((widget) => {
      if (typeof widget.highlighted !== 'undefined') {
        delete widget.highlighted
      }
      if (typeof widget.tooltip !== 'undefined') {
        delete widget.tooltip
      }

      return widget
    })
  })

  return callPost('/report/generateReport', {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
    settings: visibleSettings,
    exportType,
  }, accessToken)
}

export const generateAuditReport = (accessToken, startDate, endDate) => (dispatch, getState) => {
  const {header: { currentUserId } } = getState()

  toast.show({
    title: 'Info',
    description: 'Generating report might take a few minutes.',
    duration: 5000,
  })

  return callPost('/report/generateAuditReport', {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, accessToken)
}

export const updateBrandTerms = (accessToken, brandTerms) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/report/updateBrandTerms', {
    userId: currentUserId,
    brandTerms: brandTerms.join(','),
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'The brand terms have been updated.',
    })
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update brand terms.',
    })
    return []
  })
}

export const getStreamData = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/report/getStreamData', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get stream data.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getSpendByTargetingType = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/report/getSpendByTargetingType', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get ad spend metrics from targets.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getSpendByKeywordsLength = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/report/getSpendByKeywordsLength', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get ad spend metrics by keywords length.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const saveTemplate = (accessToken, name, contents) => () => {
  return callPost('/report/saveTemplate', {
    name,
    contents,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'The template has been saved.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save a template.',
    })
  })
}

export const getTemplates = (accessToken) => () => {
  return callGet('/report/getTemplates', accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to load templates.',
    })
    return []
  })
}

export const deleteTemplate = (accessToken, id) => () => {
  return callPost(`/report/deleteTemplate/${id}`, {}, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'The template has been deleted.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to delete a template.',
    })
  })
}

export const getPerformanceSegment = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    accountHealth: { startDate, endDate },
  } = getState()

  return callGet('/report/getPerformanceSegment', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get performance segment.',
      })
    }
    return null
  })
}
