import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import LoomVideoCollection from './LoomVideoCollection'

const topAttachments = [
  { name: 'Entourage 2.0 Playbook.pdf', url: 'https://go.ppcentourage.com/hubfs/Amazon%20Ads%20Playbook%20Series%20V2%202021.pdf?hsLang=en' },
]

const attachments = [
  { name: 'Launch Keyword Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=81687217' },
  { name: 'Audience Finder Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=1641638220' },
]

const videoList = [
  { name: 'SD1 - Category Discovery', videoId: '6166d2666f4c4c0b9f71e491f3b3d697' },
  { name: 'SD2 - ASIN Discovery', videoId: '2f0aae87db5e4d2f86d63322c594be36' },
  { name: 'SD3 - Interests/Lifestyle/Events', videoId: 'fa4957cd60ad46d08b07f00cc4acfcd6' },
  { name: 'SD4 - Searches Audience', videoId: '87d419d60e0748eca16426cf676c0ccc' },
  { name: 'SD5 - In Market', videoId: '3094ae5ba55845079ce5b5f6a7b92c7b' },
  { name: 'SD6 - Audience Remarketing', videoId: '29c1fd6fb7424463a9be3be88e79990b' },
  { name: 'SD7- Category Remarketing', videoId: '75d8093fe67e4678ad6014569b2ddf98' },
  { name: 'SD8 - ASIN Scraping', videoId: 'a140a17d8b0d46959c0c7762869cd96b' },
  { name: 'SD9 - Purchases Audience', videoId: 'faf4aa8c4b0a4f01ac1f08c065c95fe1' },
  { name: 'SD10 - ASIN Defence/Cross Sell', videoId: 'bfd2773670814b4a9cea8b7816f65eb0' },
]

const SDAds = ({ ...props }) => {
  return (
    <FlowContainer
      name="Sponsored Display Ads"
      {...props}
    >
      <AttachmentList attachments={topAttachments} />
      <AttachmentList attachments={attachments} />
      <LoomVideoCollection videoList={videoList} />
    </FlowContainer>
  )
}

export default SDAds
