import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../components/CommonComponents/SortableTableComponent'
import GroupTable from '../../components/CommonComponents/GroupTableComponent'
import TableCell from '../../components/CommonComponents/TableCell'
import TableCampaignCell from '../../components/CommonComponents/TableCampaignCell'
import ConfirmModal from '../../components/CommonComponents/ConfirmModal'

import {
  getAllTags,
  removeTagsfromCampaign
} from '../../redux/actions/tag'
import { getCampaignsWithTag } from '../../redux/actions/campaign'
import { tagColumnList } from '../../utils/defaultValues'

import {
  calcDerivedMetrics,
  tableSorter,
} from '../../services/helper'

const initialColumns = [
  { key: 'tag_name', name: 'Tag', className: 'col-tag', parentOnly: true },
  { key: 'action', name: '', className: '' },
  { key: 'campaign', name: 'Campaigns', className: 'col-campaign' },
]

const TagTableComponent = ({startDate, endDate}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const tagTableColumns = useSelector(state => state.pageGlobal.tagTableColumns)

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentUserId = useSelector(state => state.header.currentUserId)

  const campaignsWithTag = useSelector(state => state.campaign.campaignsWithTag)

  const listTags = useSelector(state => state.tag.tags)
  const isTagLoading = useSelector(state => state.tag.isLoading)
  const isRemovingTag = useSelector(state => state.tag.isRemovingTag)

  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [removeCampaign, setRemoveCampaign] = useState(null)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAllTags(accessToken))
    })()
  }, [currentUserId]) // eslint-disable-line

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getCampaignsWithTag(
        accessToken,
        startDate,
        endDate,
      ))
      setIsLoading(false)
    })()
  }, [startDate, endDate, currentUserId]) // eslint-disable-line

  const handleRemoveCampaign = (campaign) => {
    setRemoveCampaign(p => ({...campaign}))
    setConfirmText(`Are you sure want to remove this Campaign (${campaign.campaign}) from the Tag: "${campaign.name}"?`)
    setShowConfirmModal(true)
  }

  const handleModalConfirm = async (isConfirmed) => {
    if (isConfirmed) {
      const accessToken = await getAccessTokenSilently()
      await dispatch(removeTagsfromCampaign(
        accessToken,
        [removeCampaign.tagId],
        removeCampaign.campaign_id
      ))
      setIsLoading(true)
      await dispatch(getCampaignsWithTag(accessToken, startDate, endDate))
      setIsLoading(false)
    }
    setShowConfirmModal(false)
  }

  const tagList = useMemo(() => {
    return listTags.map((tag) => {
      const campaigns = (campaignsWithTag || []).filter(c =>
        c.tags.includes(tag.id)
      )

      let daily_budget = 0
      let cost = 0
      let revenue = 0
      let impressions = 0
      let clicks = 0
      let orders = 0
      let ntb_orders = 0
      let ntb_sales = 0

      campaigns.forEach((campaign) => {
        daily_budget += parseFloat(campaign.daily_budget || 0)
        cost += parseFloat(campaign.cost || 0)
        revenue += parseFloat(campaign.revenue || 0)
        impressions += parseInt(campaign.impressions || 0, 10)
        clicks += parseInt(campaign.clicks || 0, 10)
        orders += parseInt(campaign.orders || 0, 10)
        ntb_orders += parseInt(campaign.ntb_orders || 0, 10)
        ntb_sales += parseFloat(campaign.ntb_sales || 0)
      })

      return calcDerivedMetrics({
        ...tag,
        daily_budget,
        cost,
        revenue,
        impressions,
        clicks,
        orders,
        ntb_orders,
        ntb_sales,
        children: campaigns.map(c => ({
          ...c,
          name: tag.tag_name, // For search
          tagId: tag.id
        })),
      })
    })
  }, [listTags, campaignsWithTag])

  const [columns, columnSelection] = useMemo(() => {
    const availableColumns = tagTableColumns.filter(c => c.key !== 'tag')
    return [
      [...initialColumns, ...availableColumns],
      availableColumns,
    ]
  }, [tagTableColumns])

  const renderTag = record => (
    <>
      <div className="table-col col-tag">
        { record.tag_name }
      </div>
      <div className="table-col"/>
      <div className="table-col col-campaign">
        { record.children.length }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderCampaign = record => (
    <>
      <div className="table-col">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => handleRemoveCampaign(record)}
        >
          Remove
        </button>
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotal = record => (
    <>
      <div className="table-col col-tag">
        Totals:
      </div>
      <div className="table-col" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <>
      <SortableTable
        tableComponent={GroupTable}
        isLoading={isLoading || isTagLoading || isRemovingTag}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['name'])}
        className="table-tag"
        records={tagList}
        idField="id"
        searchFields={['name']}
        noCheckBox
        hasSticky={false}
        noSearch
        hasDateRange={false}
        filterName="tagTable"
        useFilterModal
        columnEditorId="tagTable"
        columnSelection={columnSelection}
        columnEditorNoReset={false}
        columnList={tagColumnList}
        renderRecord={renderTag}
        renderTotal={renderTotal}
        sorterChild={tableSorter(['campaign'])}
        idFieldChild="campaign_id"
        renderChild={renderCampaign}
        extend
      />
      <ConfirmModal
        show={showConfirmModal}
        onConfirm={handleModalConfirm}
        text={confirmText}
      />
    </>
  )
}

export default TagTableComponent
