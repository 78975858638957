export const COIN_TYPE_SIGNUP = 'signup'
export const COIN_TYPE_FLOW_QUICK_RESULTS = 'flow_quick_results'
export const COIN_TYPE_FLOW_SMART_PILOT = 'flow_smart_pilot'
export const COIN_TYPE_FLOW_OP_BASIC = 'flow_op_basic'
export const COIN_TYPE_FLOW_MORE_MARGINS = 'flow_more_margins'
export const COIN_TYPE_SCHEDULE_CALL = 'schedule_call'
export const COIN_TYPE_PURCHASE_COACHING = 'purchase_coaching'
export const COIN_TYPE_AP_SETUP = 'ap_setup'

export const COIN_TYPE_NAMES = {
  [COIN_TYPE_SIGNUP]: 'Sign up',
  [COIN_TYPE_FLOW_QUICK_RESULTS]: 'Complete the Quick Results Flow',
  [COIN_TYPE_FLOW_SMART_PILOT]: 'Complete the Smart Pilot Flow',
  [COIN_TYPE_FLOW_OP_BASIC]: 'Complete the Optimization Basics + Campaign Dashboard Flow',
  [COIN_TYPE_FLOW_MORE_MARGINS]: 'Complete the More Margins Flow',
  [COIN_TYPE_SCHEDULE_CALL]: 'Schedule the Introductory Call with Entourage Expert',
  [COIN_TYPE_PURCHASE_COACHING]: 'Purchase Entourage Coaching',
  [COIN_TYPE_AP_SETUP]: 'Setup Smart Pilot',
}
