import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../../../CommonComponents/LoaderComponent'
import DateRangeComponent from '../../../../CommonComponents/DateRangeComponent'

import CampaignChartComponent from './CampaignChartComponent'
import ComparisonView from '../../../../ComparisonView'

import {
  formatValue,
  formatCurrency,
} from '../../../../../services/helper'

import {
  COMPARISON_VIEW_TYPE_WEEKLY,
  COMPARISON_VIEW_TYPE_MONTHLY,
  COMPARISON_VIEW_TYPE_LABELS,
} from '../../../../../utils/defaultValues'

import { setDateRange } from '../../../../../redux/actions/header'
import { getSummary } from '../../../../../redux/actions/campaignDetail'
import { Toggle } from 'rsuite'

const VIEW_TYPE_CHART = 'chart'

const topMetrics = [
  { key: 'revenue', label: 'Gross Revenue' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'cost', label: 'Spend' },
  { key: 'impressions', label: 'Impressions' },
]

const bottomMetrics = [
  { key: 'orders', label: 'Orders' },
  { key: 'ctr', label: 'CTR' },
  { key: 'conversion', label: 'Conversion Rate' },
  { key: 'acos', label: 'ACoS' },
]

const nonEndemicMetrics = [
  { key: 'clicks', label: 'Clicks' },
  { key: 'cost', label: 'Spend' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR' },
]

const CampaignKpi = ({ kpi, chart, isLoading, isNonEndemicAccount, sign, rate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const currentLogs = useSelector(state => state.campaignDetail.currentLogs)

  const { id: campaignId } = useParams()

  const [currentViewType, setCurrentViewType] = useState(VIEW_TYPE_CHART)
  const [isLogView, setIsLogView] = useState(false)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [summary, setSummary] = useState([])

  useEffect(() => {
    if (currentViewType && currentViewType !== VIEW_TYPE_CHART) {
      (async () => {
        setIsSummaryLoading(true)
        const accessToken = await getAccessTokenSilently()
        const data = await dispatch(getSummary(accessToken, currentViewType, campaignId))
        setSummary(data)
        setIsSummaryLoading(false)
      })()
    }
  }, [campaignId, currentViewType]) // eslint-disable-line

  const handleChangeDateRange = ([startDate, endDate]) => {
    dispatch(setDateRange({
      startDate,
      endDate,
    }))
  }

  const renderValue = (metric) => {
    if (!kpi) {
      return '-'
    }
    switch (metric) {
      case 'revenue':
      case 'cost':
        return formatCurrency(kpi[metric], sign, rate)
      case 'clicks':
      case 'impressions':
      case 'orders':
        return formatValue(kpi[metric], 'number', 0)
      case 'ctr':
        const ctr = kpi.impressions ? kpi.clicks / kpi.impressions * 100 : 0
        return formatValue(ctr, 'percent')
      case 'conversion':
        const conversion = kpi.clicks ? kpi.orders / kpi.clicks * 100 : 0
        return formatValue(conversion, 'percent')
      case 'acos':
        const acos = kpi.revenue ? kpi.cost / kpi.revenue * 100 : 0
        return formatValue(acos, 'percent')
      default:
        return ''
    }
  }

  const renderTypeSelector = () => {
    return (
      <div className="type-selector-container">
        <div>
          {
            [VIEW_TYPE_CHART, COMPARISON_VIEW_TYPE_WEEKLY, COMPARISON_VIEW_TYPE_MONTHLY].map(type => (
              <button
                key={type}
                type="button"
                className={`btn ${currentViewType === type ? 'btn-red' : 'btn-white'}`}
                onClick={() => { setCurrentViewType(type) }}
              >
                { COMPARISON_VIEW_TYPE_LABELS[type] || 'Chart View' }
              </button>
            ))
          }
          {
            currentViewType === VIEW_TYPE_CHART && (
              <Toggle
                size="lg"
                checked={isLogView}
                checkedChildren="Show Logs"
                unCheckedChildren="Hide Logs"
                onChange={setIsLogView}
              />
            )
          }
        </div>
        <DateRangeComponent
          placement="bottomEnd"
          value={[currentStartDate, currentEndDate]}
          onChange={handleChangeDateRange}
        />
      </div>
    )
  }

  const renderMetric = (metric) => {
    return (
      <div key={metric.key} className="statistics-card">
        <div className="statistics-card-name">{ metric.label }</div>
        <div className="statistics-card-value">{ renderValue(metric.key) }</div>
      </div>
    )
  }

  return (
    <div className="section">
      <div className={`statistics-cards${isLoading ? ' loading' : ''}`}>
        { isLoading && <LoaderComponent /> }
        {
          !isNonEndemicAccount ? (
            <>
              <div className="statistics-cards-row">
                { topMetrics.map(renderMetric) }
              </div>
              <div className="statistics-cards-row">
                { bottomMetrics.map(renderMetric) }
              </div>
            </>
          ) : (
            <div className="statistics-cards-row">
              { nonEndemicMetrics.map(renderMetric) }
            </div>
          )
        }
      </div>
      { renderTypeSelector() }
      {
        currentViewType === VIEW_TYPE_CHART ? (
          <CampaignChartComponent
            isLoading={isLoading}
            isLogView={isLogView}
            isNonEndemicAccount={isNonEndemicAccount}
            chart={chart}
            logData={currentLogs}
          />
        ) : (
          <ComparisonView
            isLoading={isSummaryLoading}
            summary={summary}
            noSales
          />
        )
      }
    </div>
  )
}

export default CampaignKpi
