import React from 'react'
import { Modal } from 'rsuite'

const ConfirmModal = ({
  show,
  text,
  yesLabel = 'Yes',
  onConfirm,
}) => {
  return (
    <Modal backdrop="static" show={show} size="xs">
      <Modal.Body>
        { text }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-primary" onClick={() => { onConfirm(true) }}>
          { yesLabel }
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => { onConfirm(false) }}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
