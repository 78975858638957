// Weekparting manager.
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import StreamChart from '../CommonComponents/StreamChart'
import Header from './Header'
import RuleSection, { dowList } from './RuleSection'
import Footer from '../RuleManagerComponents/Footer'
import CampaignSelector from '../RuleManagerComponents/CampaignSelector'

import {
  turnRules,
  hideRuleManager,
  updateCampaignSelection,
} from '../../redux/actions/ruleManager'
import {
  getWpStats,
  loadWpRules,
  saveWpRules,
  saveWpTemplate,
} from '../../redux/actions/weekparting'
import { selectCurrentAccount } from '../../redux/reducers/header'
import { calcDerivedMetrics, ignoreOutsideClick } from '../../services/helper'
import { RULE_TYPE_WP } from '../../utils/defaultValues'

const WeekpartingManager = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const campaigns = useSelector(state => state.ruleManager.campaigns)

  // Indicate if a rule is saved to database.
  const [isSaved, setIsSaved] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRules, setIsLoadingRules] = useState(true)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

  const [isOn, setIsOn] = useState(false)
  const [timezone, setTimezone] = useState(
    currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [lookback, setLookback] = useState(30)
  const [slots, setSlots] = useState([])
  const [clearDows, setClearDows] = useState([])
  const [currentTemplates, setCurrentTemplates] = useState([])
  const [stats, setStats] = useState([])

  const [isCampaignSelectorVisible, setCampaignSelectorVisible] = useState(false)

  // Load existing rules.
  useEffect(() => {
    if (!campaigns.length) {
      setIsLoadingRules(false)
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(loadWpRules(
        accessToken,
        campaigns.map(campaign => campaign.campaign_id)
      ))

      let newIsOn = true
      let newTimezone
        = currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      let newLookback = 30
      let newSlots = []
      let newCurrentTemplates = []

      if (response.length) {
        response.forEach((record) => {
          let campaign = null
          if (campaigns.length > 1) {
            campaign = campaigns.find(c =>
                c.campaign_id.toString() === record.campaign_id
            )
          }

          newIsOn = record.status
          if (record.timezone) {
            newTimezone = record.timezone
          }
          if (record.lookback) {
            newLookback = record.lookback
          }
          if (record.slots) {
            newSlots = newSlots.concat(record.slots.map(slot =>
                Object.assign({}, slot, {
                  campaign,
                })
            ))
          }
          if (record.templates) {
            newCurrentTemplates = newCurrentTemplates.concat(
                record.templates.map(template =>
                    Object.assign({}, template, {
                      campaign,
                    })
                )
            )
          }
        })
      }
      else {
        newIsOn = false
      }

      const templateMap = new Map(newCurrentTemplates.map(template =>
        [template.id, template]
      ))

      setIsSaved(response.length !== 0)

      setIsOn(newIsOn)
      setTimezone(newTimezone)
      setLookback(newLookback)
      setSlots(newSlots)
      // Remove duplicate templates.
      setCurrentTemplates([...templateMap.values()])

      setIsLoadingRules(false)
    })()
  }, [dispatch, campaigns]) // eslint-disable-line

  // Load stats by day of week and hour.
  useEffect(() => {
    if (!campaigns.length) {
      return
    }

    const spCampaignIds = []
    const sdCampaignIds = []
    const sbCampaignIds = []
    campaigns.forEach((campaign) => {
      if (campaign.campaignType === 'sp') {
        spCampaignIds.push(campaign.campaign_id)
      } else if (campaign.campaignType === 'sd') {
        sdCampaignIds.push(campaign.campaign_id)
      } else {
        sbCampaignIds.push(campaign.campaign_id)
      }
    })

    if (isLoadingRules) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      dispatch(getWpStats(
        accessToken,
        spCampaignIds,
        sdCampaignIds,
        sbCampaignIds,
        lookback,
        timezone,
      )).then((response) => {
        setIsLoading(false)
        setStats(response.data.map((record, dow) => ({
          key: dow,
          label: dowList[dow],
          ...calcDerivedMetrics(record),
        })))
      }).catch(() => {
        setIsLoading(false)
      })
    })()
  }, [dispatch, campaigns, lookback, timezone, isLoadingRules]) // eslint-disable-line

  const hasSpCampaign = useMemo(() => {
    return typeof campaigns.find(campaign =>
      campaign.campaignType === 'sp'
    ) !== 'undefined'
  }, [campaigns])

  const isSavable = useMemo(() => {
    return slots.length > 0 || currentTemplates.length > 0
  }, [slots, currentTemplates])

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  // When clicking outside of the manager panel.
  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  const handleIsOnChange = async (value) => {
    setIsUpdatingStatus(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(turnRules(
      accessToken,
      RULE_TYPE_WP,
      campaigns.map(campaign => campaign.campaign_id)
    ))
    if (response) {
      setIsOn(value)
    }
    setIsUpdatingStatus(false)
  }

  // When removing a campaign from header.
  const handleCampaignRemove = (campaignId) => {
    dispatch(updateCampaignSelection(
      campaigns.filter(campaign => campaign.campaign_id !== campaignId)
    ))
  }

  const handleRuleChange = (dowNumber, rules) => {
    setSlots((prev) => {
      const newSlots = prev.filter(slot => (
        slot.d !== dowNumber
      ))

      if (rules.length) {
        newSlots.push({
          d: dowNumber,
          r: rules,
        })
      }

      return newSlots
    })
  }

  const handleAggregateRuleClear = (dowNumber) => {
    setSlots((prev) => {
      return prev.filter(slot => (
        slot.d !== dowNumber
      ))
    })

    setClearDows(prev => ([...prev, dowNumber]))
  }

  const handleApply = async () => {
    if (!campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    let filteredSlots = slots
    if (campaigns.length > 1) {
      // When multiple campaigns are selected, only
      // new rules are passed to backend.
      filteredSlots = filteredSlots.filter(slot => !slot.campaign)
    }

    filteredSlots = filteredSlots.map((slot) => {
      const slotWithoutCampaign = Object.assign({}, slot)
      if (typeof slotWithoutCampaign.campaign !== 'undefined') {
        delete slotWithoutCampaign.campaign
      }
      return slotWithoutCampaign
    })

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveWpRules(
      accessToken,
      campaigns.map(campaign => campaign.campaign_id),
      isOn,
      timezone,
      lookback,
      filteredSlots,
      currentTemplates.map(template => template.id),
      clearDows,
    ))
    setClearDows([])
    setIsSaved(true)
    setIsLoading(false)
  }

  const handleTemplateSave = async (name, needApply) => {
    if (needApply && !campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const templateSaved = await dispatch(saveWpTemplate(
      accessToken,
      name,
      timezone,
      slots,
      needApply,
      campaigns.map(campaign => campaign.campaign_id),
    ))

    setIsLoading(false)
    if (needApply && templateSaved) {
      setCurrentTemplates(prev => ([
        ...prev,
        templateSaved,
      ]))
    }
  }

  // When templates are selected from the template selector.
  const handleTemplateChange = (templates) => {
    setCurrentTemplates(templates)

    toast.show({
      title: 'Info',
      description: 'You need to click on `Save Rules` button '
        + 'to save your selection and apply the template.',
      duration: 5000,
    })
  }

  const handleApplyMultiple = () => {
    setCampaignSelectorVisible(true)
  }

  const handleApplyToMultiple = async (campaignIds) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveWpRules(
      accessToken,
      campaignIds,
      isOn,
      timezone,
      lookback,
      slots,
      currentTemplates.map(template => template.id),
    ))
    setIsLoading(false)
  }

  const renderBody = () => {
    if (!isCampaignSelectorVisible) {
      return (
        <>
          <div className="pane-body">
            <StreamChart stats={stats} />
            <RuleSection
              hasSpCampaign={hasSpCampaign}
              slots={slots}
              currentTemplates={currentTemplates}
              timezone={timezone}
              onChange={handleRuleChange}
              onAggregateRuleClear={handleAggregateRuleClear}
            />
          </div>
          <Footer
            isLoading={isLoading || isLoadingRules}
            isSavable={isSavable}
            onApply={handleApply}
            onApplyToMultiple={handleApplyMultiple}
            onSaveTemplate={handleTemplateSave}
            onClose={handleClose}
          />
        </>
      )
    }

    return (
      <CampaignSelector
        isSaving={isLoading}
        onApply={handleApplyToMultiple}
        onCancel={() => { setCampaignSelectorVisible(false) }}
      />
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="weekparting-manager-component">
        { (isLoading || isLoadingRules || isUpdatingStatus) && <LoaderComponent className="rule-loader" /> }
        <Header
          campaigns={campaigns}
          isSaved={isSaved}
          isOn={isOn}
          timezone={timezone}
          lookback={lookback}
          currentTemplates={currentTemplates}
          isCampaignSelectorVisible={isCampaignSelectorVisible}
          isUpdatingStatus={isUpdatingStatus}
          isLoadingRules={isLoadingRules}
          isMultiCampaignSelection={campaigns.length > 1}
          onIsOnChange={handleIsOnChange}
          onTimezoneChange={setTimezone}
          onLookbackChange={setLookback}
          onCampaignRemove={handleCampaignRemove}
          onTemplateChange={handleTemplateChange}
          onClose={handleClose}
        />
        { renderBody() }
      </div>
    </OutsideClickHandler>
  )
}

export default WeekpartingManager
