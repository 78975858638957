import React from 'react'

import VideoLink from '../../components/CommonComponents/VideoLink'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'

const videoList = [
  { title: 'Activity Log Video', url: 'https://www.loom.com/embed/55e16bd78c454d159dacb34367354b35' }
]

const PageHeader = () => (
  <div className="page-header">
    <div className="page-title">
      Activity Log
      <CustomTooltip placement="right">
        <p>This section will show you all changes made to your campaigns on an account-wide level.</p>
      </CustomTooltip>
    </div>
    <VideoLink
      videoList={videoList}
      modalTitle='Activity Log'
    />
  </div>
)

export default PageHeader
