import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Carousel } from 'rsuite'
import { FiMinus, FiPlus, FiExternalLink, FiEye, FiEyeOff } from 'react-icons/fi'

import { ReactComponent as TaskSvg } from '../../assets/svg/task.svg'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import TableCell from '../CommonComponents/TableCell'
import CreativeModal from './CreativeModal'
import TargetsModal from './TargetsModal'
import NegativeKeywordsModal from './NegativeKeywordsModal'
import NegativeTargetsModal from './NegativeTargetsModal'

import {showSpManager} from '../../redux/actions/ruleManager'

import {
  campaignTypeMap,
  PLACEMENT_TOP,
  PLACEMENT_PRODUCT_PAGE,
  PLACEMENT_REST_OF_SEARCH,
  stateLabels
} from '../../utils/defaultValues'

import { formatValue } from '../../services/helper'
import CustomTooltip from "../CommonComponents/CustomTooltip";

const matchTypeOptions = {
  exact: 'Exact',
  phrase: 'Phrase',
  broad: 'Broad',
  pat: 'PAT'
}

const biddingStrategyOptions = {
  legacyForSales : 'Dynamic bids - down only',
  autoForSales: 'Dynamic bids - up and down',
  manual: 'Fixed bids'
}

const TableRow = ({ isLoading, isDetailLoading, campaign, selectedCampaigns, selectedColumns,
  acosCampaignId, acosRef, dailyBudgetCampaignId, dailyBudgetRef,
  currencySign, currencyRate, startDate, endDate, accountAcos,
  hideDailyBudget, isNonEndemicAccount, expandedRow = false, showHistory = false,
  onSelect, onOpenAcosPopup, onSaveAcos, onCancelAcos,
  onOpenDailyBudgetPopup, onSaveDailyBudget, onCancelDailyBudget, onClickHistory,
  onExpandRow, onToggleHistoryView, onShowNotePane }) => {
  const dispatch = useDispatch()
  const tags = useSelector(state => state.tag.tags)

  const [showCreativeModal, setShowCreativeModal] = useState(false)
  const [showTargetsModal, setShowTargetsModal] = useState(false)
  const [showNegativeKeywordsModal, setShowNegativeKeywordsModal] = useState(false)
  const [showNegativeTargetsModal, setShowNegativeTargetsModal] = useState(false)

  const isSelected = useMemo(() => (
    typeof selectedCampaigns.find(selected => (
      selected.campaign_id.toString() === campaign.campaign_id.toString()
    )) !== 'undefined'
  ), [selectedCampaigns, campaign])

  const targetingType = useMemo(() => {
    if (campaign.campaignType === 'sp') {
      if (campaign.targeting_type === 'auto') {
        return 'Auto'
      }
      return 'Manual'
    }
    return ''
  }, [campaign])

  const handleSmartPilotManagerShow = () => {
    if (isLoading) {
      return
    }
    dispatch(showSpManager(campaign.campaign_id))
  }

  const renderAsins = () => {
    return (
      <div className="view-col">
        {
          (campaign.products || []).length > 0 && (
            <div className="asin-images-record">
              <Carousel shape="bar" placement="bottom" autoplay>
                { campaign.products.map(p => (
                  <img src={p.image} alt="ASIN" key={p.asin} />
                ))}
              </Carousel>
              <div
                className="number-of-asins"
                title={
                  `This campaign has ${campaign.products.length} product${campaign.products.length > 1 ? 's' : ''}.`
                }
              >
                {campaign.products.length}
              </div>
            </div>
          )
        }
      </div>
    )
  }

  const renderTargets = () => {
    const totalTargets = parseInt(campaign.targetCnt || 0, 10) + parseInt(campaign.keywordCnt || 0, 10)

    if (!totalTargets) {
      return (
        <div className="view-col">
          0
        </div>
      )
    }

    return (
      <div
        className="view-col action-col"
        title="Click to View Keywords"
        onClick={() => { setShowTargetsModal(true) }}
      >
        { formatValue(totalTargets, 'number', 0)}
        <FiPlus className="magnify-icon" size={14}/>
      </div>
    )
  }

  const renderNks = () => {
    if (!campaign.negativeKeywordCnt) {
      return (
        <div className="view-col">
          0
        </div>
      )
    }

    return (
      <div
        className="view-col action-col"
        title="Click to View Negative Keywords"
        onClick={() => { setShowNegativeKeywordsModal(true) }}
      >
        { formatValue(campaign.negativeKeywordCnt, 'number', 0) }
        <FiPlus className="magnify-icon" size={14}/>
      </div>
    )
  }

  const renderNts = () => {
    if (!campaign.negativeTargetCnt) {
      return (
        <div className="view-col">
          0
        </div>
      )
    }

    return (
      <div
        className="view-col action-col"
        title="Click to View Negative Targets"
        onClick={() => { setShowNegativeTargetsModal(true) }}
      >
        { formatValue(campaign.negativeTargetCnt, 'number', 0) }
        <FiPlus className="magnify-icon" size={14}/>
      </div>
    )
  }

  const renderDetail = () => {
    if (!expandedRow) {
      return null
    }

    if (isDetailLoading) {
      return (
        <div className="campaign-view-detail">
          <div className="loading-text">
            Loading
          </div>
        </div>
      )
    }

    let topOfSearch = 0
    let restOfSearch = 0
    let productPage = 0
    if (campaign.placement) {
      if (campaign.placement[PLACEMENT_TOP]
        && parseFloat(campaign.placement[PLACEMENT_TOP].revenue)) {
        topOfSearch = campaign.placement[PLACEMENT_TOP].cost
          / campaign.placement[PLACEMENT_TOP].revenue
          * 100
      }
      if (campaign.placement[PLACEMENT_REST_OF_SEARCH]
        && parseFloat(campaign.placement[PLACEMENT_REST_OF_SEARCH].revenue)) {
        restOfSearch = campaign.placement[PLACEMENT_REST_OF_SEARCH].cost
          / campaign.placement[PLACEMENT_REST_OF_SEARCH].revenue
          * 100
      }
      if (campaign.placement[PLACEMENT_PRODUCT_PAGE]
        && parseFloat(campaign.placement[PLACEMENT_PRODUCT_PAGE].revenue)) {
        productPage = campaign.placement[PLACEMENT_PRODUCT_PAGE].cost
          / campaign.placement[PLACEMENT_PRODUCT_PAGE].revenue
          * 100
      }
    }

    let tagNames = []
    if ((campaign.tags || []).length > 0) {
      campaign.tags.forEach(ct => {
        const tag = tags.find(lt => lt.id === ct)
        if (typeof tag !== 'undefined') {
          tagNames.push(tag.tag_name)
        }
      })
    }

    return (
      <div className="campaign-view-detail">
        <div className="view-row view-header">
          {
            !isNonEndemicAccount && <div className="view-col">ASINs</div>
          }
          <div className="view-col">Ad Groups</div>
          <div className="view-col">Targets</div>
          {
            !isNonEndemicAccount && (
              <>
                <div className="view-col">Negative Keywords</div>
                <div className="view-col">Negative Targets</div>
              </>
            )
          }
          <div className="view-col">Match Types</div>
          <div className="view-col">Tags</div>
          {
            campaign.campaignType === 'sp' && (
              <div className="view-col">Bidding Strategy</div>
            )
          }
          {
            campaign.campaignType !== 'sp' ? (
              <div className="view-col">Creative</div>
            ) : (
              <>
                <div className="view-col">Top of Search %</div>
                <div className="view-col">Rest of Search %</div>
                <div className="view-col">Product Page %</div>
              </>
            )
          }
        </div>
        <div className="view-row">
          { !isNonEndemicAccount && renderAsins() }
          <div className="view-col">
            { formatValue((campaign.adgroups || []).length, 'number', 0) }
          </div>
          { renderTargets() }
          {
            !isNonEndemicAccount && (
              <>
                { renderNks() }
                { renderNts() }
              </>
            )
          }
          {
            targetingType === 'Auto' ? (
              <div className="view-col">{ targetingType }</div>
            ) : (
              <div className="view-col">
                {(campaign.matchType || []).map(m => matchTypeOptions[m.toLowerCase()]).join(', ')}
              </div>
            )
          }
          <div className="view-col">{ tagNames.join(', ')}</div>
          {
            campaign.campaignType === 'sp' && (
              <div className="view-col">
                {
                  (campaign.bidding.strategy && biddingStrategyOptions[campaign.bidding.strategy])
                  ? biddingStrategyOptions[campaign.bidding.strategy]
                  : ''
                }
              </div>
            )
          }
          {
            campaign.campaignType !== 'sp' ? (
              <div className="view-col">
                <span className="view-show-creative" onClick={() => setShowCreativeModal(true)}>
                  Show Creative
                </span>
                { showCreativeModal && (
                  <CreativeModal
                    campaignId={campaign.campaign_id}
                    campaignType={campaign.campaignType}
                    products={campaign.products || []}
                    onClose={() => setShowCreativeModal(false)}
                  />
                )}
              </div>
            ) : (
              <>
                <div className="view-col">{formatValue(topOfSearch, 'percent')}</div>
                <div className="view-col">{formatValue(restOfSearch, 'percent')}</div>
                <div className="view-col">{formatValue(productPage, 'percent')}</div>
              </>
            )
          }
        </div>
        { showTargetsModal && (
          <TargetsModal
            campaignId={campaign.campaign_id}
            campaignType={campaign.campaignType}
            adgroups={campaign.adgroups || []}
            onClose={() => {setShowTargetsModal(false)} }
          />
        )}
        { showNegativeKeywordsModal && (
          <NegativeKeywordsModal
            campaignId={campaign.campaign_id}
            campaignType={campaign.campaignType}
            onClose={() => {setShowNegativeKeywordsModal(false)} }
          />
        )}
        { showNegativeTargetsModal && (
          <NegativeTargetsModal
            campaignId={campaign.campaign_id}
            campaignType={campaign.campaignType}
            onClose={() => {setShowNegativeTargetsModal(false)} }
          />
        )}
      </div>
    )
  }

  const renderCampaign = () => (
    <div key="campaign" className="table-col">
      <div className="campaign-status">
        <div className={`status ${campaign.state.toLowerCase() === 'enabled' ? 'on' : 'off'}`}>
          <div className="bullet"></div>
          <span>{stateLabels[campaign.state.toLowerCase()]}</span>
        </div>
        <div className={`status ${campaign.is_dp_active ? 'on' : 'off'}`}>
          <CustomTooltip
              trigger={"hover"}
              placement={"top"}
              wrappedChildren={<div>
                <div className="bullet"></div>
                <span>DP {campaign.is_dp_active ? 'On' : 'Off'}</span>
              </div>}
          >
            Dayparting is currently {campaign.is_dp_active ? 'On' : 'Off'}
          </CustomTooltip>


        </div>
        <div className={`status ${campaign.is_ap_active ? 'on' : 'off'}`}>
          <CustomTooltip
              trigger={"hover"}
              placement={"top"}
              wrappedChildren={<div>
                <div className="bullet"></div>
                <span>SP {campaign.is_ap_active ? 'On' : 'Off'}</span>
              </div>}
          >
            Smart Pilot is currently {campaign.is_ap_active ? 'On' : 'Off'}
          </CustomTooltip>
        </div>
        <FiExternalLink
            title="Open Smart Pilot"
            onClick={handleSmartPilotManagerShow}
        />
        {
            campaign.notes?.length > 0 && (
                <TaskSvg
                    title="Open Notes"
                    onClick={() => {
                      onShowNotePane(campaign)
                    }}
                />
            )
        }
      </div>
      <Link
          to={`/campaign/${campaign.campaign_id}/${campaign.campaignType}`}
          className={`campaign-name${isLoading ? ' disabled' : ''}`}
          target="_blank"
          rel="noopener noreferrer"
        title={campaign.campaign}
      >
        { campaign.campaign }
      </Link>
      <div className="campaign-detail">
        {
          targetingType && <span>{ targetingType }</span>
        }
        <span>
          { campaignTypeMap[campaign.campaignType] }
        </span>
      </div>
    </div>
  )

  const renderTargetAcos = () => (
    <div key={`target-acos-${campaign.campaign_id}`} className="table-col">
      {
        acosCampaignId && acosCampaignId.toString() === campaign.campaign_id.toString()
        ? (
          <>
            <input type="number" ref={acosRef} className="edit-input"/>
            <div className="action-button-container">
              <button type="button" className="btn btn-blue" onClick={() => { onSaveAcos(campaign) }}>
                Save
              </button>
              <button type="button" className="btn btn-white" onClick={() => { onCancelAcos() }}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <input
            type="number"
            className="edit-input"
            value={parseFloat(campaign.target_acos || accountAcos)}
            onChange={() => { onOpenAcosPopup(campaign.campaign_id) } }
          />
        )
      }
    </div>
  )

  const renderDailyBudget = () => (
    <div key={`daily-budget-${campaign.campaign_id}`} className="table-col">
      {
        dailyBudgetCampaignId && dailyBudgetCampaignId.toString() === campaign.campaign_id.toString()
        ? (
          <>
            <input type="number" ref={dailyBudgetRef} className="edit-input"/>
            {
              campaign.budgetrule_status === 'on' && (
                <div className="campaign-status">
                  <div className="status on">
                    <div className="bullet"></div>
                    <span>Rule On</span>
                  </div>
                </div>
              )
            }
            <div className="action-button-container">
              <button type="button" className="btn btn-blue" onClick={() => { onSaveDailyBudget(campaign) }}>
                Save
              </button>
              <button type="button" className="btn btn-white" onClick={() => { onCancelDailyBudget() }}>
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <input
              type="number"
              className="edit-input"
              value={campaign.daily_budget}
              onChange={() => { onOpenDailyBudgetPopup(campaign.campaign_id) }}
            />
            {
              campaign.budgetrule_status === 'on' && (
                <div className="campaign-status">
                  <div className="status on">
                    <div className="bullet"></div>
                    <span>Rule On</span>
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </div>
  )

  return (
    <>
      <div className="table-row">
        <div className="table-col">
          <CheckboxComponent
            checked={isSelected}
            onChange={(checked) => { onSelect(checked, campaign) } }
          />
        </div>
        {
          (selectedColumns || []).map((column) => {
            if (
              isNonEndemicAccount
              && [
                'target_acos',
                'revenue',
                'acos',
                'orders',
                'conversion',
                'roas',
                'ntb_orders',
                'ntb_orders_percent',
                'ntb_sales',
                'ntb_sales_percent',
                'video_views_25',
                'video_views_50',
                'video_views_75',
                'video_views_100',
                'video_5s_views',
                'view_5_seconds_rate',
              ].includes(column.key)
            ) {
              return null
            }

            if (column.key === 'campaign') {
              return renderCampaign()
            }
            if (column.key === 'target_acos') {
              return renderTargetAcos()
            }
            if (column.key === 'daily_budget') {
              return hideDailyBudget ? null : renderDailyBudget()
            }
            if (column.key
              && (column.key !== 'campaign'
              || column.key !== 'target_acos'
              || column.key !== 'daily_budget')) {
              return (
                <TableCell
                  key={`${campaign.campaign_id}-${column.key}`}
                  record={campaign}
                  columnKey={column.key}
                  columnSelection={selectedColumns}
                  currencySign={currencySign}
                  currencyRate={currencyRate}
                  showHistory={showHistory}
                  historyData={campaign.chartData || []}
                  startDate={startDate}
                  endDate={endDate}
                  onClick={() => onClickHistory(campaign.chartData || [], column.key, campaign, column.label)}
                />
              )
            }
            return null
          })
        }
        <div className="table-col action-column">
          {
            showHistory ? (
              <FiEye
                onClick={() => onToggleHistoryView(false, campaign.campaign_id)}
                title="Hide Metrics over Time"
              />
            ) : (
              <FiEyeOff
                onClick={() => onToggleHistoryView(true, campaign.campaign_id)}
                title="Show Metrics over Time"
              />
            )
          }
          { expandedRow ? (
            <FiMinus
              className="expand-row"
              onClick={() => onExpandRow(false, [campaign.campaign_id])}
              title="Less Creatives and Details"
            />
            ) : (
            <FiPlus
              className="expand-row"
              onClick={() => onExpandRow(true, [campaign.campaign_id])}
              title="More Details and Creative"
            />
          )}
        </div>
      </div>
      { renderDetail() }
    </>
  )
}

export default TableRow
