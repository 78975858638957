import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'

import TemplateEditorHeader from '../../RuleManagerComponents/TemplateEditorHeader'
import SlotSelector from '../SlotSelector'
import RuleSection from '../RuleSection'
import Footer from './Footer'

import { compileRules } from '..'

import {
  hideRuleManager,
  getTemplate,
  deleteTemplate,
} from '../../../redux/actions/ruleManager'
import {
  saveDpTemplate,
  updateDpTemplate,
} from '../../../redux/actions/dayparting'
import { selectCurrentAccount } from '../../../redux/reducers/header'
import { RULE_TYPE_DP } from '../../../utils/defaultValues'
import { ignoreOutsideClick } from '../../../services/helper'

const TemplateEditor = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const templateToEdit = useSelector(state => state.ruleManager.templateToEdit)

  const [name, setName] = useState('')
  const [timezone, setTimezone] = useState(
    currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [slots, setSlots] = useState([])
  const [slotSelection, setSlotSelection] = useState([])
  const [ruleToShow, setRuleToShow] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!templateToEdit) {
      setName('')
      setTimezone(
        currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      setSlots([])
      setSlotSelection([])
      setRuleToShow(null)

      return
    }

    // It means the template ID is passed, not the whole object,
    // so we need to retrieve template details.
    if (typeof templateToEdit !== 'object') {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsSaving(true)
        await dispatch(getTemplate(accessToken, RULE_TYPE_DP, templateToEdit))
        setIsSaving(false)
      })()
      return
    }

    setName(templateToEdit.name)
    setTimezone(templateToEdit.timezone)
    setSlots(templateToEdit.slots)
  }, [templateToEdit]) // eslint-disable-line

  const isDeletable = useMemo(() => {
    if (slotSelection.length) {
      return typeof slots.find((slot) => (
        // Find existing rules that are applied to any of selected slots.
        typeof slotSelection.find(slotNumber => (
          slot.s.includes(slotNumber)
        )) !== 'undefined'
      )) !== 'undefined'
    }
    return slots.length > 0
  }, [slotSelection, slots])

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  // When slots are selected in the slot selector.
  const handleSlotSelect = (selectedSlots, selectedRule) => {
    setSlotSelection(selectedSlots)
    setRuleToShow(selectedRule)
  }

  const handleRulesChange = (rules) => {
    setSlots(compileRules(slots, slotSelection, rules))
  }

  // Delete applied rules.
  const handleRulesDelete = () => {
    if (slotSelection.length) {
      setSlots(compileRules(slots, slotSelection, []))
    } else {
      setSlots([])

      toast.show({
        title: 'Info',
        description: 'You need to click on `Save Rules` button '
          + 'to save your rules.',
        duration: 5000,
      })
    }
  }

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently()

    if (templateToEdit) {
      setIsSaving(true)
      dispatch(updateDpTemplate(
        accessToken,
        templateToEdit.id,
        name,
        timezone,
        slots,
      )).then(() => {
        setIsSaving(false)
      })
    } else {
      if (name === '') {
        toast.show({
          title: 'Warning',
          description: 'Please enter a template name.',
        })
        return
      }

      setIsSaving(true)
      dispatch(saveDpTemplate(
        accessToken,
        name,
        timezone,
        slots,
      )).then(() => {
        setIsSaving(false)
      })
    }
  }

  const handleSaveAsAnother = async () => {
    if (templateToEdit && templateToEdit.name === name) {
      toast.show({
        title: 'Warning',
        description: 'Please enter a name different than the original one.',
      })
      return
    }

    const accessToken = await getAccessTokenSilently()
    setIsSaving(true)
    dispatch(saveDpTemplate(
      accessToken,
      name,
      timezone,
      slots,
    )).then(() => {
      setIsSaving(false)
    })
  }

  const handleDelete = async () => {
    const accessToken = await getAccessTokenSilently()
    setIsSaving(true)
    await dispatch(deleteTemplate(accessToken, RULE_TYPE_DP, templateToEdit.id))
    setIsSaving(false)
    handleClose()
  }

  const renderBody = () => {
    // We pass an empty array by storing it first
    // as a variable. If we pass [] directly to props,
    // a new object ([]) will be created on every render,
    // causing a maximum depth reached exception.
    const stats = []
    const currentTemplates = []

    return (
      <div className="pane-body">
        <SlotSelector
          fromEditor
          stats={stats}
          slots={slots}
          currentTemplates={currentTemplates}
          timezone={timezone}
          selectedSlots={slotSelection}
          onSlotSelect={handleSlotSelect}
        />
        {
          slotSelection.length > 0 && (
            <RuleSection
              hasSpCampaign
              ruleToShow={ruleToShow}
              onChange={handleRulesChange}
            />
          )
        }
      </div>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="dayparting-template-editor-component">
        { isSaving && <LoaderComponent /> }
        <TemplateEditorHeader
          templateType="Dayparting"
          name={name}
          timezone={timezone}
          onNameChange={setName}
          onTimezoneChange={setTimezone}
          onClose={handleClose}
        />
        { renderBody() }
        <Footer
          isSaving={isSaving}
          isSavable={slots.length > 0}
          isEditable={templateToEdit !== null}
          isDeletable={isDeletable}
          deleteForAll={slotSelection.length === 0}
          onSave={handleSave}
          onSaveAsAnother={handleSaveAsAnother}
          onDelete={handleDelete}
          onRulesDelete={handleRulesDelete}
          onClose={handleClose}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default TemplateEditor
