import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'

import BasicInfo from './Shared/BasicInfo'
import AdgroupInfo from './Shared/AdgroupInfo'
import TargetingTypeSelector from './Shared/TargetingTypeSelector'
import ProductSection from './Shared/ProductSection'

import SDBidSection from './SDBidSection'
import SDTargetingSection from './SDTargetingSection'
import SDLocationSection from './Shared/SDLocationSection'
import AudienceSection from './AudienceSection'
import SDAdSection from './Shared/SDAdSection'
import SDCreativeSection from './Shared/SDCreativeSection'

import { createSdCampaign, getSdSuggestions } from '../../redux/actions/campaignCreator'
import { searchProduct  } from '../../redux/actions/targeting'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'
import {
  compileSdCreative,
  getBidLimits,
  isAsin,
  parseDate,
  parseTargeting,
} from '../../services/helper'
import { validateAdgroupName, validateBudget, validateCampaignName, validateTargets } from '../../services/validator'

const adFormatList = [
  {
    value: 'image',
    name: 'Image',
    description: 'Promote your product with an optional image',
  },
  {
    value: 'video',
    name: 'Video',
    description: 'Feature a single product with an autoplaying video',
  },
]

const targetList = [
  {
    value: 'T00020',
    name: 'Contextual targeting',
    description: 'Choose specific products or categories to target your ads.',
  },
  {
    value: 'T00030',
    name: 'Audiences targeting',
    description: 'Choose which audiences you want to see your ads.',
  },
]

const SDCampaignCreator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const { getAccessTokenSilently } = useAuth0()

  const [basicInfo, setBasicInfo] = useState({
    name: '',
    dailyBudget: 10,
    acos: 25,
    startDate: new Date(),
    endDate: null,
    hasLogo: false,
    logoAsset: null,
    hasHeadline: false,
    headline: '',
    hasCustomImage: false,
    customImageAsset: null,
    videoAsset: null,
    portfolio: ''
  })

  const [bidInfo, setBidInfo] = useState({
    bidOp: 'clicks',
    defaultBid: 0.45,
  })

  const [adgroupName, setAdgroupName] = useState('Ad group 1')
  const [adFormat, setAdFormat] = useState('image')
  const [target, setTarget] = useState(isNonEndemicAccount ? 'T00030' : 'T00020')

  const [products, setProducts] = useState([])
  const [locations, setLocations] = useState([])
  const [targetings, setTargetings] = useState([])
  const [logoCrop, setLogoCrop] = useState(null)
  const [customCrop, setCustomCrop] = useState(null)
  const [customSquareCrop, setCustomSquareCrop] = useState(null)

  const [adInfo, setAdInfo] = useState({
    name: '',
    landingPage: '',
  })

  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    if (!location.state) {
      return
    }
    if (location.state.targets && location.state.targets.length) {
      const asinTargets = location.state.targets.filter(isAsin)

      if (asinTargets.length) {
        (async () => {
          const accessToken = await getAccessTokenSilently()
          const response = await dispatch(searchProduct(accessToken, {
            asins: asinTargets.join(),
          }))
          setTargetings(response.map(product => ({
            ...product,
            isTargeted: true,
            type: 'product',
            suggestedBid: null,
            bid: 0,
          })))
        })()
      }
    }

    if (location.state.categories && location.state.categories.length) {
      setTargetings(location.state.categories.map(category => ({
        ...category,
        type: 'category',
        bid: 0,
      })))
    }
  }, [location.state]) // eslint-disable-line

  const parseTargetings = () => {
    const targets = []
    targetings.forEach((targeting) => {
      if (
        (
          target === 'T00020'
          && ['category', 'refine', 'product'].indexOf(targeting.type) === -1
        )
        ||
        (
          target === 'T00030'
          && ['audience_category', 'audience_refine', 'audience_product', 'audience'].indexOf(targeting.type) === -1
        )
      ) {
        return
      }

      const payload = parseTargeting(targeting)
      if (!payload) {
        return
      }
      targets.push(payload)
    })
    return targets
  }

  const handleBasicInfoChange = (name, value) => {
    setBasicInfo(Object.assign({}, basicInfo, {
      [name]: value,
    }))
  }

  const handleAdInfoChange = (name, value) => {
    setAdInfo(Object.assign({}, adInfo, {
      [name]: value,
    }))
  }

  const handleBidChange = (name, value) => {
    setBidInfo(Object.assign({}, bidInfo, {
      [name]: value,
    }))
  }

  const handleSuggestionLoad = forTarget => async () => {
    if (products.length && !isSuggestionsLoading) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      dispatch(getSdSuggestions(
        accessToken,
        target,
        products.map(product => product.asin),
        forTarget,
      ))
      setIsSuggestionsLoading(false)
    }
  }

  const handleImageCrop = (
    cropType,
    cropCoordinates = null,
    squareCoordinates = null
  ) => {
    if (cropType === 'logo') {
      setLogoCrop(cropCoordinates)
    } else {
      setCustomCrop(cropCoordinates)
      setCustomSquareCrop(squareCoordinates)
    }
  }

  const handleSave = async () => {
    const forSeller = currentAccount?.seller_type === 'seller'

    let error = validateCampaignName(
      basicInfo.name,
      forSeller,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    error = validateBudget(
      basicInfo.dailyBudget,
      'sd',
      currentAccount?.country_id,
      forSeller,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    error = validateAdgroupName(adgroupName)
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    if (!isNonEndemicAccount) {
      if (!products.length) {
        toast.show({
          title: 'Warning',
          description: 'Please choose at least 1 product.',
        })
        return
      }
    } else {
      if (adInfo.name === '' || adInfo.landingPage === '') {
        toast.show({
          title: 'Warning',
          description: 'Please enter an ad info.',
        })
        return
      }
      if (!(basicInfo.hasLogo && basicInfo.logoAsset)
        || !(basicInfo.hasHeadline && basicInfo.headline !== '')
        || !(basicInfo.hasCustomImage && basicInfo.customImageAsset)) {
        toast.show({
          title: 'Warning',
          description: 'Please add a logo, headline, and custom image for creative.',
        })
        return
      }
    }
    if (!targetings.length) {
      toast.show({
        title: 'Warning',
        description: 'Please choose at least 1 target.',
      })
      return
    }

    // Compile creatives.
    if (adFormat === 'video') {
      if (!basicInfo.videoAsset
        || !basicInfo.videoAsset.assetId
        || !basicInfo.videoAsset.versionId) {
        toast.show({
          title: 'Warning',
          description: 'Please upload a video.',
        })
        return
      }
    }

    const creativeProps = compileSdCreative(
      basicInfo,
      logoCrop,
      customCrop,
      customSquareCrop,
      adFormat,
    )

    // Check if adgroup and target bids fall into limits.
    const costType = bidInfo.bidOp === 'reach' ? 'vcpm' : 'cpc'
    const bidLimits = getBidLimits(
      'sd',
      currentAccount?.country_id,
      costType,
    )

    if (parseFloat(bidInfo.defaultBid) < bidLimits[0]
      || parseFloat(bidInfo.defaultBid) > bidLimits[1]) {
      toast.show({
        title: 'Warning',
        description: `Default bid is out of range (must be in [${bidLimits[0]}, ${bidLimits[1]}])`,
      })
      return
    }

    const newCampaign = {
      name: basicInfo.name,
      budget: parseFloat(basicInfo.dailyBudget),
      startDate: format(parseDate(basicInfo.startDate), 'yyyyMMdd'),
      tactic: target,
      costType,
    }

    if (basicInfo.endDate !== '' && basicInfo.startDate < basicInfo.endDate)  {
      newCampaign.endDate = format(parseDate(basicInfo.endDate), 'yyyyMMdd')
    }

    if (Object.keys(basicInfo.portfolio)) {
      newCampaign.portfolioId = basicInfo.portfolio.portfolio_id
    }

    const targets = parseTargetings()
    const invalidError = validateTargets(
      targets,
      newCampaign.budget,
      bidLimits,
    )

    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    let productAds
    if (!isNonEndemicAccount) {
      productAds = products.map(product => ({
        sku: product.sku,
        asin: product.asin,
      }))
    } else {
      productAds = [
        {
          adName: adInfo.name,
          landingPageURL: adInfo.landingPage,
        },
      ]
    }

    setIsCreating(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(createSdCampaign(accessToken, {
      campaign: newCampaign,
      acos: basicInfo.acos,
      adgroup: {
        name: adgroupName,
        defaultBid: bidInfo.defaultBid,
        bidOptimization: bidInfo.bidOp,
        creativeType: adFormat === 'image' ? 'IMAGE' : 'VIDEO',
      },
      productAds,
      targets,
      locations: locations.map(location => location.locationId),
      creative: Object.keys(creativeProps).length
        ? [{
            creativeType: adFormat === 'image' ? 'IMAGE' : 'VIDEO',
            properties: creativeProps,
          }]
        : [],
      forNonEndemic: isNonEndemicAccount,
    })).then(() => {
      toast.show({
        title: 'Success',
        description: 'The campaign has been successfully created! '
          + 'Your campaign will appear in Entourage as soon as we start collecting data.',
      })

      history.push('/dashboard')
    }).catch((description) => {
      toast.show({
        title: 'Danger',
        description,
      })
    }).finally(() => {
      setIsCreating(false)
    })
  }

  const renderPageHeader = () => {
    return (
      <div className="page-header">
        <div className="page-title">Create Sponsored Display Campaign</div>
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    )
  }

  return (
    <div className={`sd-campaign-creator creator-section${isCreating ? ' loading' : ''}`}>
      { isCreating && <LoaderComponent /> }
      { renderPageHeader() }
      <div className="page-content">
        <BasicInfo
          type="sd"
          isNonEndemicAccount={isNonEndemicAccount}
          info={basicInfo}
          countryCode={currentAccount?.country_id}
          isSeller={currentAccount?.seller_type === 'seller'}
          onChange={handleBasicInfoChange}
        />
        <div className="section-container">
          <div className="field-row">
            <AdgroupInfo
              name={adgroupName}
              onChange={setAdgroupName}
            />
            <SDBidSection
              isNonEndemicAccount={isNonEndemicAccount}
              info={bidInfo}
              dailyBudget={basicInfo.dailyBudget}
              onChange={handleBidChange}
            />
          </div>
        </div>
        {
          !isNonEndemicAccount ? (
            <>
              <ProductSection
                products={products}
                onChange={setProducts}
              />
              <TargetingTypeSelector
                targetList={targetList}
                target={target}
                onChange={setTarget}
              />
            </>
          ) : (
            <SDLocationSection
              locations={locations}
              onSelect={setLocations}
            />
          )
        }
        {
          target === 'T00020' && (
            <SDTargetingSection
              targetings={targetings}
              isSuggestionsLoading={isSuggestionsLoading}
              dailyBudget={basicInfo.dailyBudget}
              bidInfo={bidInfo}
              products={products}
              onFind={handleSuggestionLoad(true)}
              onChange={setTargetings}
            />
          )
        }
        {
          target === 'T00030' && (
            <AudienceSection
              isNonEndemicAccount={isNonEndemicAccount}
              targetings={targetings}
              isSuggestionsLoading={isSuggestionsLoading}
              dailyBudget={basicInfo.dailyBudget}
              bidInfo={bidInfo}
              products={products}
              onFind={handleSuggestionLoad(false)}
              onChange={setTargetings}
            />
          )
        }
        {
          !isNonEndemicAccount ? (
            <TargetingTypeSelector
              adType="sdAdFormat"
              targetList={adFormatList}
              target={adFormat}
              onChange={setAdFormat}
            />
          ) : (
            <SDAdSection
              adInfo={adInfo}
              onChange={handleAdInfoChange}
            />
          )
        }
        {
          (isNonEndemicAccount || products.length > 0) && (
            <SDCreativeSection
              adFormat={adFormat}
              products={products}
              basicInfo={basicInfo}
              onCrop={handleImageCrop}
              onChange={handleBasicInfoChange}
            />
          )
        }
      </div>
      <div className="page-footer">
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    </div>
  )
}

export default SDCampaignCreator
