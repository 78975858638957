import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { Icon, IconButton } from 'rsuite'

import { getPortfolios } from '../../../redux/actions/portfolio'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'

const PortfolioSelector = ({
  classNamePrefix = '',
  exclusable = false,
  inclusive = true,
  onChange,
  onChangeInclusive,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [options, setOptions] = useState([])
  const [value, setValue] = useState([])

  const handleFocus = async () => {
    if (isLoaded || isLoading) {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getPortfolios(accessToken))
    setOptions(
      response.map(portfolio => ({
        label: portfolio.name,
        value: portfolio.portfolio_id,
      }))
      .sort((opt1, opt2) => opt1.label.localeCompare(opt2.label))
    )
    setIsLoading(false)
    setIsLoaded(true)
  }

  const handleChange = (newValue) => {
    setValue(newValue)
    onChange(newValue)
  }

  if (isNonEndemicAccount) {
    return null
  }

  return (
    <div className="portfolio-select-wrapper">
      <Select
        className="portfolio-select"
        classNamePrefix={classNamePrefix}
        isMulti
        isLoading={isLoading}
        placeholder="Portfolios"
        options={options}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {
        exclusable && (
          <IconButton
            title={inclusive ? 'Inclusive' : 'Exclusive'}
            className="select-inclusive-btn"
            icon={
              <Icon icon={inclusive ? 'dot-circle-o' : 'circle-o'} />
            }
            onClick={() => { onChangeInclusive(!inclusive) }}
          />
        )
      }
    </div>
  )
}

export default PortfolioSelector
