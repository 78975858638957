import React, { useState } from 'react'

import TargetingModal from './TargetingModal'
import TargetingList from './TargetingList'
import DefaultBidSection from './DefaultBidSection'

const SBTargetingSection = ({ targetings, isSuggestionsLoading,
  categorySuggestions, productSuggestions, onFind, onChange }) => {
  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const handleFind = () => {
    setOpenModal(true)
    onFind()
  }

  return (
    <div className="section-container">
      <div className="section-title">
        <span>Products Targeting</span>
        <div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFind}
          >
            Find Categories/ASINs
          </button>
          {
            targetings.length > 0 && (
              <button
                type="button"
                className="btn btn-red"
                onClick={() => { onChange([]) }}
              >
                Remove All
              </button>
            )
          }
        </div>
      </div>
      <div className="section-note">
        Help shoppers find your product by choosing categories, products,
        brands, or features related to your product.
      </div>
      <DefaultBidSection
        targetings={targetings}
        defaultBid={defaultBid}
        onChange={onChange}
        onDefaultBidChange={setDefaultBid}
      />
      <TargetingList
        adType="sb"
        targetings={targetings}
        onChange={onChange}
      />
      <TargetingModal
        show={openModal}
        defaultBid={defaultBid}
        targetings={targetings}
        isLoading={isSuggestionsLoading}
        suggestedCategories={categorySuggestions}
        suggestedProducts={productSuggestions}
        onChange={onChange}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default SBTargetingSection
