import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Grid,
  Row,
  Col,
  Divider
} from 'rsuite'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportTileWidget from './ReportTileWidget'
import ReportChartWidget from './ReportChartWidget'
import ReportNoteWidget from './ReportNoteWidget'
import ReportPieChartWidget from './ReportPieChartWidget'

import {
  calculateMetricsDifference,
  calculateChartMetric
} from '../../services/helper'

const sectionList = [
  { metricType: 'impressions', name: 'Impressions' },
  { metricType: 'cost_per_1000_impressions', name: 'Spend / 1,000 Impressions' },
  { metricType: 'ntb_orders', name: 'NTB Orders' },
  { metricType: 'ntb_sales', name: 'NTB Sales' },
  { metricType: 'ntb_units', name: 'NTB Units' },
  { metricType: 'ntb_sales_percent', name: '% of NTB Sales' },
]

const BrandGrowthSection = ({
  settings,
  isLoadingSettings,
  onLoadStats,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)
  const salesData = useSelector(state => state.accountHealth.salesData)
  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const spPastMetrics = useSelector(state => state.accountHealth.spPastMetrics)
  const spChart = useSelector(state => state.accountHealth.spChart)
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sbPastMetrics = useSelector(state => state.accountHealth.sbPastMetrics)
  const sbChart = useSelector(state => state.accountHealth.sbChart)
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)
  const sdPastMetrics = useSelector(state => state.accountHealth.sdPastMetrics)

  const currencySign = useSelector(state => state.header.currencySign)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')

  useEffect(() => {
    const abortCtrl = new AbortController();

    if (showSection) {
      onLoadStats('allSponsoredAds', abortCtrl.signal)
    }

    return () => {
      abortCtrl.abort()
    }
  }, [showSection, startDate, endDate, currentUserId]) // eslint-disable-line

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_brand_growth || '')
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const reportSettings = reportWidgetSetting ? reportWidgetSetting.brandGrowth : []
    setViewWidgets(
      reportSettings.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])


  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_brand_growth',
      sectionValue: note,
    })
  }

  const renderTileWidget = (widget) => {
    let isBetter = true,
      difference = 0,
      diffPercent = 0,
      value = 0

    switch (widget.key) {
      case 'sp_impressions':
      case 'sp_cost_per_1000_impressions':
      case 'sp_percent_ntb_sales':
        ({
          isBetter,
          difference,
          diffPercent,
          value
        } = calculateMetricsDifference(spMetrics, spPastMetrics, salesData, widget.metricType))
        break
      case 'sb_impressions':
      case 'sb_cost_per_1000_impressions':
      case 'sb_ntb_orders':
      case 'sb_ntb_sales':
      case 'sb_ntb_units':
      case 'sb_percent_ntb_sales':
        ({
          isBetter,
          difference,
          diffPercent,
          value
        } = calculateMetricsDifference(sbMetrics, sbPastMetrics, salesData, widget.metricType))
        break
      case 'sd_impressions':
      case 'sd_cost_per_1000_impressions':
      case 'sd_ntb_orders':
      case 'sd_ntb_sales':
      case 'sd_ntb_units':
      case 'sd_percent_ntb_sales':
        ({
          isBetter,
          difference,
          diffPercent,
          value
        } = calculateMetricsDifference(sdMetrics, sdPastMetrics, salesData, widget.metricType))
        break
      default:
        return null
    }

    return (
      <ReportTileWidget
        widget={widget}
        value={value}
        diff={{value: difference, percent: diffPercent}}
        isBetter={isBetter}
        onRemove={handleOnRemoveWidget}
        customizing={customizingSection}
        section="brandGrowth"
        highlighting={false}
      />
    )
  }

  const renderChartWidget = (widget) => {
    let chartData = [],
      chartMetrics = [],
      data = [],
      brandedSale = 0,
      nonBrandedSale = 0

    switch(widget.key) {
      case 'sp_brand_vs_non_brand':
        ({
          chartData = [],
          chartMetrics = []
        } = calculateChartMetric(spChart, widget, currencySign))
        return (
          <ReportChartWidget
            widget={widget}
            data={chartData}
            metrics={chartMetrics}
            onRemove={handleOnRemoveWidget}
            customizing={customizingSection}
            section="brandGrowth"
            highlighting={false}
          />
        )
      case 'sb_brand_vs_non_brand':
        ({
          chartData = [],
          chartMetrics = []
        } = calculateChartMetric(sbChart, widget, currencySign))
        return (
          <ReportChartWidget
            widget={widget}
            data={chartData}
            metrics={chartMetrics}
            onRemove={handleOnRemoveWidget}
            customizing={customizingSection}
            section="brandGrowth"
            highlighting={false}
          />
        )
      case 'sp_brand_vs_non_brand_pie':
        spChart.forEach(record => {
          brandedSale += record.branded_sale ? record.branded_sale : 0
          nonBrandedSale += record.non_branded_sale ? record.non_branded_sale : 0
        })
        data = [
          { name: `Branded (${currencySign}):`, value: brandedSale, color: '#ed4c0c', fullName: 'Branded'},
          { name: `Non-Branded (${currencySign}):`, value: nonBrandedSale, color: '#97e80c', fullName: 'Non-Branded'},
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            onRemove={handleOnRemoveWidget}
            customizing={customizingSection}
            section="brandGrowth"
            highlighting={false}
          />
        )
      case 'sb_brand_vs_non_brand_pie':
        sbChart.forEach(record => {
          brandedSale += record.branded_sale ? record.branded_sale : 0
          nonBrandedSale += record.non_branded_sale ? record.non_branded_sale : 0
        })
        data = [
          { name: `Branded (${currencySign}):`, value: brandedSale, color: '#ed4c0c', fullName: 'Branded'},
          { name: `Non-Branded (${currencySign}):`, value: nonBrandedSale, color: '#97e80c', fullName: 'Non-Branded'},
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            onRemove={handleOnRemoveWidget}
            customizing={customizingSection}
            section="brandGrowth"
            highlighting={false}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <SectionHeader
        title="Brand Growth"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <Grid fluid>
            {
              (customizingSection || noteWidgetView) && (
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section="brandGrowth"
                />
              )
            }
            {
              sectionList.map(section => (
                <React.Fragment key={section.metricType}>
                  <Divider>{ section.name }</Divider>
                  <Row>
                    {
                      (viewWidgets || []).filter(w => w.metricType === section.metricType)
                      .map(widget => (
                        (widget.view || customizingSection) ? (
                          <Col key={widget.key} xs={6}>
                            { renderTileWidget(widget) }
                          </Col>
                        ) : null
                      ))
                    }
                  </Row>
                </React.Fragment>
              ))
            }
            <Divider>Branded vs Non-Branded Sales</Divider>
            <Row>
              { (viewWidgets || []).filter(w => w.metricType === 'brand_vs_non_brand').map(w => (w.view || customizingSection) ? (
                <Col xs={12} key={w.key}>
                  {renderChartWidget(w)}
                </Col>
              ) : null)}
            </Row>
          </Grid>
        )
      }
    </>
  )
}

export default withReportSection(BrandGrowthSection, {
  sectionId: 'brand_growth',
  reportSectionKey: 'brandGrowth',
})
