import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import CustomTable from '../../CommonComponents/CustomTableComponent'
import CheckboxComponent from '../../CommonComponents/CheckboxComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'
import TableCampaignCell from '../../CommonComponents/TableCampaignCell'
import CustomTooltip from '../../CommonComponents/CustomTooltip'

import { getBidLimits, isAsin } from '../../../services/helper'
import { selectCurrentAccount } from '../../../redux/reducers/header'
import { matchTypeOptions } from '../../../utils/defaultValues'

const lookbackOptions = [
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 180, label: '180 days' },
  { value: 365, label: '365 days' },
]

const StepBid = ({
  targets,
  payload,
  selectedAdgroups,
  forTargetEx,
  forPtEx,
  forCategory,
  isAddingTargets,
  onChange,
  onChangeAudience,
  onChangeLookback,
}) => {
  const currentAccount = useSelector(selectCurrentAccount)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [hasKtAdgroup, setHasKtAdgroup] = useState(false)
  const [allMatchType, setAllMatchType] = useState()
  const [allBid, setAllBid] = useState(0)
  // true if selected campaigns contains a SD campaign with T00030 tactic.
  // For such campaigns, they need to select audience type and lookback.
  const [isSDAudience, setIsSDAudience] = useState(false)
  const [audienceView, setAudienceView] = useState(false)
  const [audiencePurchase, setAudiencePurchase] = useState(false)
  const [lookback, setLookback] = useState(lookbackOptions[2])

  // Initialize payload.
  useEffect(() => {
    const extendedTargets = []
    let foundSdCampaign = false
    let foundKtAdgroup = false
    adgroupsToAddTargetsData.forEach((adgroup) => {
      if (!selectedAdgroups.includes(adgroup.adgroup_id.toString())) {
        return
      }

      const campaign = campaignsWithHistory.find(c =>
        c.campaign_id.toString() === adgroup.campaign_id.toString()
      )
      if (!campaign) {
        return
      }

      if (campaign.campaignType === 'sd'
        && campaign.tactic === 'T00030') {
        foundSdCampaign = true
      }

      // SD campaigns don't support keyword targeting.
      const isKtAdgroup = campaign.campaignType !== 'sd'
        && parseInt(adgroup.targets, 10) === 0
      if (isKtAdgroup) {
        foundKtAdgroup = true
      }

      // Create a list of payload.
      const meta = {
        campaignId: adgroup.campaign_id,
        campaignName: campaign.campaign,
        campaignType: campaign.campaignType,
        targetingType: campaign.targeting_type,
        tactic: campaign.tactic,
        cost_type: campaign.cost_type,
        adgroupId: adgroup.adgroup_id,
        adgroupName: adgroup.name,
        isKtAdgroup,
      }

      const bidLimits = getBidLimits(
        campaign.campaignType,
        currentAccount?.country_id,
        campaign.cost_type
      )

      if (forCategory) {
        // For category targeting.
        targets.forEach((target) => {
          extendedTargets.push({
            ...target,
            bid: parseFloat(adgroup.default_bid || bidLimits[0]),
            ...meta,
            compositeId: `${adgroup.campaign_id}-${adgroup.adgroup_id}-${target.id}`,
          })
        })
      } else {
        let targetsToUse = targets
        // For product targeting ad groups, filter ASINs only.
        if (!isKtAdgroup) {
          targetsToUse = targetsToUse
            .filter(target => isAsin(target.target))
        }

        targetsToUse.forEach((target) => {
          if (!target.target) {
            return
          }
          extendedTargets.push({
            ...target,
            target: target.target.toUpperCase(),
            matchType: matchTypeOptions[0],
            bid: parseFloat(adgroup.default_bid || bidLimits[0]),
            ...meta,
            compositeId: `${adgroup.campaign_id}-${adgroup.adgroup_id}-${target.target.toUpperCase()}`,
          })
        })
      }
    })

    onChange(extendedTargets)

    // Check if there is SD campaign with T00030 tactic.
    if (forPtEx && foundSdCampaign) {
      setIsSDAudience(true)
      onChangeAudience([])
      onChangeLookback(lookback.value)
    }

    // Set if there is any keyword targeting ad group.
    setHasKtAdgroup(foundKtAdgroup)
  }, []) // eslint-disable-line

  const handleAllMatchTypeApply = () => {
    if (allMatchType && allMatchType.value) {
      onChange(payload.map(record => (
        Object.assign({}, record, {
          matchType: allMatchType,
        })
      )))
    }
  }

  const handleAllBidApply = () => {
    if (allBid === '' || isNaN(allBid)) {
      toast.show({
        title: 'Warning',
        description: 'Please enter a bid price.',
      })
      return
    }

    onChange(payload.map(record => (
      Object.assign({}, record, {
        bid: parseFloat(allBid),
      })
    )))
  }

  const handleCpcApply = () => {
    onChange(payload.map(record => (
      Object.assign({}, record, {
        bid: parseFloat(record.cpc || 0),
      })
    )))
  }

  const handleMatchTypeChange = compositeId => (selected) => {
    const newPayload = payload.map((record) => {
      if (record.compositeId !== compositeId) {
        return record
      }
      return Object.assign({}, record, {
        matchType: selected,
      })
    })
    onChange(newPayload)
  }

  const handleBidChange = compositeId => (event) => {
    const newPayload = payload.map((record) => {
      if (record.compositeId !== compositeId) {
        return record
      }
      return Object.assign({}, record, {
        bid: parseFloat(event.target.value),
      })
    })
    onChange(newPayload)
  }

  const handleAudienceViewCheck = (checked) => {
    setAudienceView(checked)
    const audiences = []
    if (checked) {
      audiences.push('view')
    }
    if (audiencePurchase) {
      audiences.push('purchase')
    }
    onChangeAudience(audiences)
  }

  const handleAudiencePurchaseCheck = (checked) => {
    setAudiencePurchase(checked)
    const audiences = []
    if (audienceView) {
      audiences.push('view')
    }
    if (checked) {
      audiences.push('purchase')
    }
    onChangeAudience(audiences)
  }

  const handleLookbackChange = (selected) => {
    setLookback(selected)
    onChangeLookback(selected.value)
  }

  const renderActions = () => (
    <div className="bid-action-container">
      {
        hasKtAdgroup && (
          <div className="bid-action-wrapper">
            <label>
              Change all match types
            </label>
            <div className="bid-action-control">
              <Select
                value={allMatchType}
                options={matchTypeOptions}
                placeholder="Choose Match Type"
                className="match-type-selector"
                classNamePrefix="match-type-selector"
                onChange={setAllMatchType}
              />
              <button
                type="button"
                className="btn btn-green"
                disabled={!allMatchType}
                onClick={handleAllMatchTypeApply}
              >
                Apply
              </button>
            </div>
          </div>
        )
      }
      <div className="bid-action-wrapper">
        <label>
          Update all bids
        </label>
        <div className="bid-action-control">
          <input
            type="number"
            min={0}
            value={allBid}
            onChange={(event) => { setAllBid(event.target.value) }}
          />
          <button
            type="button"
            className="btn btn-green"
            onClick={handleAllBidApply}
          >
            Apply
          </button>
        </div>
      </div>
      {
        !forTargetEx && (
          <div className="bid-action-wrapper">
            <label>&nbsp;</label>
            <div className="bid-action-control">
              <button
              type="button"
              className="btn btn-green"
              onClick={handleCpcApply}
            >
              Use Ave CPC
            </button>
            </div>
          </div>
        )
      }
      {
        // For padding.
        !hasKtAdgroup && <div className="bid-action-wrapper" />
      }
      {
        // For padding.
        forTargetEx && <div className="bid-action-wrapper" />
      }
    </div>
  )

  const renderAudiences = () => (
    <div className="bid-action-container">
      <div className="bid-action-wrapper">
        <label>
          Audiences
        </label>
        <div className="bid-action-control">
          <div className="checkbox-wrapper">
            <CheckboxComponent
              label="View remarketing"
              checked={audienceView}
              onChange={handleAudienceViewCheck}
            />
            <CustomTooltip>
              <p>Reach shoppers who viewed your advertised products
              or other similar products, and product categories.</p>
            </CustomTooltip>
          </div>
          <div className="checkbox-wrapper">
            <CheckboxComponent
              label="Purchase remarketing"
              checked={audiencePurchase}
              onChange={handleAudiencePurchaseCheck}
            />
            <CustomTooltip>
              <p>Reach shoppers who purchased your advertised products
              or other related products, and product categories.</p>
            </CustomTooltip>
          </div>
        </div>
      </div>
      <div className="bid-action-wrapper">
        <label>
          Lookback
        </label>
        <div className="bid-action-control">
          <Select
            value={lookback}
            options={lookbackOptions}
            className="lookback-selector"
            onChange={handleLookbackChange}
          />
        </div>
      </div>
    </div>
  )

  const renderTarget = (record) => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col">
        { record.adgroupName }
      </div>
      <div className="table-col">
        { record.target }
      </div>
      {
        hasKtAdgroup && (
          <div className="table-col">
            {
              record.isKtAdgroup && (
                <Select
                  value={record.matchType}
                  options={matchTypeOptions}
                  placeholder="Choose Match Type"
                  className="match-type-selector"
                  onChange={handleMatchTypeChange(record.compositeId)}
                />
              )
            }
          </div>
        )
      }
      <div className="table-col col-bid">
        <input
          type="number"
          min={0}
          value={record.bid}
          onChange={handleBidChange(record.compositeId)}
        />
      </div>
    </>
  )

  return (
    <div className={`table-content${isAddingTargets ? ' loading' : ''}`}>
      { isAddingTargets && <LoaderComponent /> }
      { renderActions() }
      { isSDAudience && renderAudiences() }
      <CustomTable
        className="table-bids"
        records={payload}
        idField="compositeId"
        searchFields={['target']}
        noCheckBox
        renderRecord={renderTarget}
      >
        <div className="table-col">
          Campaign
        </div>
        <div className="table-col">
          Ad Group
        </div>
        <div className="table-col">
          { !forCategory ? 'Target' : 'Category' }
        </div>
        {
          hasKtAdgroup && (
            <div className="table-col">Match Type</div>
          )
        }
        <div className="table-col">Bid</div>
      </CustomTable>
    </div>
  )
}

export default StepBid
