import { TOGGLE_DASHBOARD_TABLE } from '../actionTypes/dashboard.js'

export const initialState = {
  maxTable: false,
}

const dashboard = (state = initialState, action) => {
	switch (action.type) {
    case TOGGLE_DASHBOARD_TABLE:
      return {
        ...state,
        maxTable: !state.maxTable,
      }
    default:
      return state
  }
}

export default dashboard
