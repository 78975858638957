import React, { useState } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../CommonComponents/ToastComponent/toast'

const SBSubPagesOrderModal = ({ subPages, creativeSubPages, onChange, onClose }) => {
  const creativeSubPageIds = creativeSubPages.map(subPage => subPage.id)

  const [orderedSubPages, setOrderedSubPages] = useState(creativeSubPageIds.map(
    subPageId => subPages.find(subPage => subPage.id === subPageId)
  ))

  const onSave = () => {
    if (orderedSubPages.length !== 3) {
      toast.show({
        title: 'Danger',
        description: 'Select 3 pages.',
      })
      return
    }
    onChange(orderedSubPages.map(subPage => ({
      id: subPage.id,
      name: subPage.name,
      url: subPage.url,
      product: subPage.products[0],
    })))
  }

  const onAdd = (subPage) => {
    if (orderedSubPages.length >= 3) {
      toast.show({
        title: 'Danger',
        description: 'You can select up to 3 pages.',
      })
      return
    }
    setOrderedSubPages(prev => ([...prev, subPage]))
  }

  const onRemove = (subPage) => {
    setOrderedSubPages(orderedSubPages.filter(page =>
      page.id !== subPage.id
    ))
  }

  const renderSubPageDetails = subPage => (
    <>
      <img src={subPage.products[0].image} alt={subPage.products[0].name} />
      <div className="product-info">
        <div className="product-name">{subPage.name}</div>
      </div>
    </>
  )

  const renderUnordered = () => (
    subPages.map((subPage) => {
      const found = orderedSubPages.find(ordered =>
        ordered.id === subPage.id
      )
      if (found) {
        return null
      }
      return (
        <div key={subPage.id} className="product-wrapper">
          { renderSubPageDetails(subPage) }
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => onAdd(subPage)}
          >
            Add
          </button>
        </div>
      )
    })
  )

  return (
    <Modal className="sb-product-ordering-modal" backdrop="static" show size="lg">
      <Modal.Body>
        <div className="column-container">
          { renderUnordered() }
        </div>
        <div className="column-container">
          {
            orderedSubPages.map(subPage => (
              <div key={subPage.id} className="product-wrapper">
                { renderSubPageDetails(subPage) }
                <button
                  type="button"
                  className="btn btn-red"
                  onClick={() => onRemove(subPage)}
                >
                  Remove
                </button>
              </div>
            ))
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-primary" onClick={() => onSave()}>
          Confirm
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SBSubPagesOrderModal