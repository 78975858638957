import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/creatable'
import { useAuth0 } from '@auth0/auth0-react'
import { Icon, IconButton } from 'rsuite'

import { getAllTags, selectTags, createAndAddTagToCampaigns } from '../../../redux/actions/tag'

const TagSelector = ({
  classNamePrefix = '',
  exclusable = false,
  inclusive = true,
  selectedCampaignIds = [],
  onChange,
  onChangeInclusive,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isLoading = useSelector(state => state.tag.isLoading)
  const tags = useSelector(state => state.tag.tags)
  const selectedTags = useSelector(state => state.tag.selectedTags)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const options = useMemo(() => {
    const opts = []
    tags.forEach((record) => {
      opts.push({
        label: record.tag_name,
        value: record.id,
      })
    })
    opts.push({
      label: 'No tag',
      value: 0,
    })
    return opts
  }, [tags])

  useEffect(() => {
    onChange(selectedTags || [])
  }, [selectedTags]) // eslint-disable-line

  const handleFocus = async () => {
    if (!isLoaded && !isLoading) {
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAllTags(accessToken))
      setIsLoaded(true)
    }
  }

  const handleChange = (value) => {
    dispatch(selectTags(value))
  }

  const handleCreate = async (newTag) => {
    const accessToken = await getAccessTokenSilently()
    setIsCreating(true)
    const newTagInfo = await dispatch(createAndAddTagToCampaigns(accessToken, newTag, selectedCampaignIds))
    await dispatch(
      selectTags([
        ...selectedTags,
        {
          value: newTagInfo.id,
          label: newTagInfo.tag_name,
        }
      ])
    )
    setIsCreating(false)
  }

  return (
    <div className="tags-select-wrapper">
      <CreatableSelect
        className="tags-select"
        classNamePrefix={classNamePrefix}
        isMulti
        isLoading={isLoading || isCreating}
        options={options}
        value={selectedTags || []}
        placeholder="Tags"
        onChange={handleChange}
        onFocus={handleFocus}
        onCreateOption={handleCreate}
      />
      {
        exclusable && (
          <IconButton
            title={inclusive ? 'Inclusive' : 'Exclusive'}
            className="select-inclusive-btn"
            icon={
              <Icon icon={inclusive ? 'dot-circle-o' : 'circle-o'} />
            }
            onClick={() => { onChangeInclusive(!inclusive) }}
          />
        )
      }
    </div>
  )
}

export default TagSelector
