import React from 'react'
import { Modal } from 'rsuite'

const FilterDeleteModal = ({ name, onDelete, onClose }) => {
  return (
    <Modal backdrop="static" show={name !== ''} size="xs">
      <Modal.Body>
        Are you sure you want to delete a filter: <strong>{ name }</strong>?
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={name === ''}
          onClick={onDelete}
        >
          Delete
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={onClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FilterDeleteModal
