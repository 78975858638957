import { callGet, callPost } from '../../services/axios.js'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { TURN_RULES_SUCCESS } from '../actionTypes/ruleManager.js'
import { RULE_TYPE_RR } from '../../utils/defaultValues.js'

export const loadKeywords = (accessToken, { campaignId, campaignType, page, countPerPage, search }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/rank/loadKeywords', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    page,
    countPerPage,
    search,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to load keywords.',
    })
    return []
  })
}

export const loadRrRules = (accessToken, skus) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/rank/loadRules', {
    userId: currentUserId,
    skus,
  }, accessToken).then(response => response.data)
}

export const saveRrRules = (accessToken, rules, deletedRuleIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/rank/saveRules', {
    userId: currentUserId,
    rules,
    deletedRuleIds,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Successfully saved rules.',
    })

    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save rules.',
    })

    return {}
  })
}

export const turnRrRules = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/rank/turnRules', {
    userId: currentUserId,
    campaignIds,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Toggled successfully.',
    })
    dispatch({
      type: TURN_RULES_SUCCESS,
      data: {
        type: RULE_TYPE_RR,
        campaignIds,
      },
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to toggle.',
    })
  })
}
