/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Button } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import CampaignTableComponent from '../CampaignTableComponent'
import VideoLink from '../CommonComponents/VideoLink'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import TargetSearchResult from './TargetSearchResult'
import DupResult from './DupResult'

import {
  findTargets,
  saveFoundTargets,
  findDups,
  saveFoundDuplicates,
  getTargetCharts,
} from '../../redux/actions/bulkEngine'
import { checkStatus, monitorJob } from '../../redux/actions/job'

import {
  JOB_TYPE_BULK_TARGET_SEARCH,
  JOB_TYPE_BULK_DUPLICATE_TARGETS,
  MODULE_NAME_TARGET_SEARCH,
  MODULE_NAME_DUPLICATE_TARGETS,
} from '../../utils/defaultValues'

const MODULE_TARGET_SEARCH = 'target_search'
const MODULE_DUPLICATE_TARGETS = 'duplicate_targets'

const moduleList = [
  {
    key: MODULE_TARGET_SEARCH,
    name: MODULE_NAME_TARGET_SEARCH,
    description: (
      <>
        <p>Research any target across your campaigns in seconds.</p>
        <p>Find valuable information such as profit, match type and ad spend.</p>
      </>
    ),
  },
  {
    key: MODULE_DUPLICATE_TARGETS,
    name: MODULE_NAME_DUPLICATE_TARGETS,
    description: (
      <>
        <p>
          Discover where your duplicate targets are performing
          at their peak and where they are not.
        </p>
      </>
    )
  },
]

export const tutorialList = {
  '': {
    title: 'Bulk Engine Overview',
    videoList: [
      { title: 'Bulk Engine Overview', url: 'https://www.loom.com/embed/8aade68f957449d5ab685182612c39ad' },
    ],
  },
  [MODULE_TARGET_SEARCH]: {
    title: MODULE_NAME_TARGET_SEARCH,
    videoList: [
      { title: MODULE_NAME_TARGET_SEARCH, url: 'https://www.loom.com/embed/50c79aa6dbfc4848ae9cc6d5e4a4fd58' },
    ],
  },
  [MODULE_DUPLICATE_TARGETS]: {
    title: MODULE_NAME_DUPLICATE_TARGETS,
    videoList: [
      { title: MODULE_NAME_DUPLICATE_TARGETS, url: 'https://www.loom.com/embed/594877045af04ad4ac9161330ce2ca23' },
    ],
  },
}

const TargetSearchTable = ({ openedJob, showNotedCampaigns, initialRender, onHasResult }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const campaignIdsSelected = useSelector(state => state.campaign.campaignIdsSelected)

  const statusCheckerInterval = useRef(null)

  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [chartData, setChartData] = useState({})
  const [activeModule, setActiveModule] = useState('')
  const [keyword, setKeyword] = useState('')
  const [internalStartDate, setInternalStartDate] = useState()
  const [internalEndDate, setInternalEndDate] = useState()
  const [hasResults, setHasResults] = useState(false)
  const [isCampaignsExpanded, setIsCampaignsExpanded] = useState(false)
  // Keep whether a `lifetime` range is selected for each module.
  const [lifetimeForModules, setLifetimeForModules] = useState({})
  const [includeZeroSpend, setIncludeZeroSpend] = useState(false)

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      if (statusCheckerInterval.current) {
        clearInterval(statusCheckerInterval.current) // eslint-disable-line
      }
    }
  }, [])

  useEffect(() => {
    setHasResults(false)
    setIsCampaignsExpanded(false)
  }, [currentUserId])

  useEffect(() => {
    if (openedJob) {
      if (openedJob.type === JOB_TYPE_BULK_TARGET_SEARCH) {
        setActiveModule(MODULE_TARGET_SEARCH)
        setKeyword(openedJob.payload.keyword)
        setIncludeZeroSpend(openedJob.payload.includeZeroSpend)

        dispatch(saveFoundTargets(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_DUPLICATE_TARGETS) {
        setActiveModule(MODULE_DUPLICATE_TARGETS)

        dispatch(saveFoundDuplicates(openedJob.results))
      }

      setHasResults(true)
    }
  }, [openedJob, dispatch])

  useEffect(() => {
    onHasResult(hasResults)
  }, [hasResults]) // eslint-disable-line

  const handleModuleSelect = (module) => {
    setActiveModule(module.key)
    setHasResults(false)
  }

  const doSearch = (startDate, endDate, includeAll) => {
    if (lifetimeForModules[activeModule]) {
      startDate = null
      endDate = null
    } else {
      setInternalStartDate(startDate)
      setInternalEndDate(endDate)
    }

    if (activeModule === MODULE_TARGET_SEARCH) {
      return loadFindTargetsResults(
        keyword,
        startDate,
        endDate,
        typeof includeAll !== 'undefined' ? includeAll : includeZeroSpend,
      )
    }

    return loadFindDupsResults(
      startDate,
      endDate
    )
  }

  const handleFind = () => {
    setHasResults(false)
    doSearch(currentStartDate, currentEndDate)
  }

  const handleDateChange = (startDate, endDate) => {
    setLifetimeForModules(Object.assign({}, lifetimeForModules, {
      [activeModule]: startDate === null && endDate === null,
    }))
    doSearch(startDate, endDate)
  }

  const handleIncludeZeroSpend = (include) => {
    setIncludeZeroSpend(include)
    doSearch(currentStartDate, currentEndDate, include)
  }

  const handleChartLoad = async (type) => {
    setIsLoadingChart(true)
    const accessToken = await getAccessTokenSilently()
    const data = await dispatch(getTargetCharts(
      accessToken,
      keyword,
      internalStartDate,
      internalEndDate,
      type,
    ))
    setChartData(data)
    setIsLoadingChart(false)
  }

  const loadFindTargetsResults = async (keywordToSearch, startDate, endDate, includeZeroSpendOption) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(findTargets(
      accessToken,
      keywordToSearch,
      startDate,
      endDate,
      includeZeroSpendOption,
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_TARGET_SEARCH,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_TARGET_SEARCH)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadFindDupsResults = async (startDate, endDate) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(findDups(
      accessToken,
      startDate,
      endDate
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_DUPLICATE_TARGETS,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_DUPLICATE_TARGETS)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const checkJob = (jobId, type) => {
    if (statusCheckerInterval.current) {
      clearInterval(statusCheckerInterval.current)
    }
    statusCheckerInterval.current = setInterval(async () => {
      // @todo: Prevent another status check API call
      // while a previous one is in flight.
      const accessToken = await getAccessTokenSilently()
      dispatch(checkStatus(accessToken, jobId)).then(({ url, data }) => {
        if (url) {
          if (type === JOB_TYPE_BULK_TARGET_SEARCH) {
            dispatch(saveFoundTargets(data))
          } else if (type === JOB_TYPE_BULK_DUPLICATE_TARGETS) {
            dispatch(saveFoundDuplicates(data))
          } else {
            clearInterval(statusCheckerInterval.current)
            statusCheckerInterval.current = null
            return
          }

          setHasResults(true)
          setIsCampaignsExpanded(false)
          clearInterval(statusCheckerInterval.current)
          statusCheckerInterval.current = null
        }
      }).catch((error) => {
        clearInterval(statusCheckerInterval.current)
        statusCheckerInterval.current = null
      })
    }, 5000)
  }

  const renderTopSection = () => {
    if (hasResults && !isCampaignsExpanded) {
      return null
    }

    if (activeModule === MODULE_TARGET_SEARCH) {
      return (
        <div className="keyword-search-box">
          <input
            type="text"
            placeholder="Enter a target to search..."
            value={keyword}
            onChange={(event) => { setKeyword(event.target.value) }}
          />
          <button
            type="button"
            className="btn btn-blue"
            disabled={keyword === '' || !(campaignIdsSelected || []).length}
            onClick={handleFind}
          >
            { !(campaignIdsSelected || []).length ? 'Select Campaigns to Continue' : 'Search' }
          </button>
          <CustomTooltip placement="right">
            <p>
              See how your top targets are performing across your ads.
            </p>
            <p>
              Enter a Keyword or ASIN, select campaigns and click "Search".
            </p>
          </CustomTooltip>
        </div>
      )
    }

    if (activeModule === MODULE_DUPLICATE_TARGETS) {
      return (
        <div className="keyword-search-box">
          <button
            type="button"
            className="btn btn-blue"
            disabled={!(campaignIdsSelected || []).length}
            onClick={handleFind}
          >
            { !(campaignIdsSelected || []).length ? 'Select Campaigns to Continue' : 'Search' }
          </button>
          <CustomTooltip placement="right">
            <p>
              Discover where your targets are performing at their peak and where they are not.
            </p>
            <p>
              Select campaigns below and click "Search".
            </p>
          </CustomTooltip>
        </div>
      )
    }
    return null
  }

  const renderModuleSelector = (dropdownLabel) => {
    return (
      <div className="module-selector-container">
        <div className="module-selector-left">
          <Dropdown title={dropdownLabel} renderTitle={children => {
            return (
              <Button appearance="primary">
                {children}
                <span className="toggle-caret" />
              </Button>
            )
          }}>
            {
              moduleList.map(module => (
                <Dropdown.Item
                  key={module.key}
                  active={module.key === activeModule}
                  onSelect={() => { handleModuleSelect(module) }}
                >
                  { module.name }
                  <CustomTooltip placement="right">
                    { module.description }
                  </CustomTooltip>
                </Dropdown.Item>
              ))
            }
          </Dropdown>
          { renderTopSection() }
        </div>
        <VideoLink
          modalTitle={tutorialList[activeModule].title}
          videoList={tutorialList[activeModule].videoList}
        />
      </div>
    )
  }

  const renderCampaignSelection = () => {
    if (hasResults && !isCampaignsExpanded) {
      return (
        <div className="campaign-selection-state">
          <strong>{ (campaignIdsSelected || []).length }</strong> campaigns selected.
          <a
            href="#"
            onClick={(event) => { event.preventDefault(); setIsCampaignsExpanded(true) }}
          >
            Change
          </a>
        </div>
      )
    }
    return null
  }

  const renderResults = () => {
    if (!hasResults) {
      return null
    }

    if (activeModule === MODULE_TARGET_SEARCH) {
      return (
        <TargetSearchResult
          startDate={internalStartDate}
          endDate={internalEndDate}
          includeZeroSpend={includeZeroSpend}
          isLoadingChart={isLoadingChart}
          chartData={chartData}
          onChangeDate={handleDateChange}
          onLoadChart={handleChartLoad}
          onIncludeZeroSpend={handleIncludeZeroSpend}
        />
      )
    }
    if (activeModule === MODULE_DUPLICATE_TARGETS) {
      return (
        <DupResult
          onChangeDate={handleDateChange}
        />
      )
    }
    return null
  }

  let dropdownLabel = 'Choose Module'
  if (activeModule) {
    const active = moduleList.find(module => module.key === activeModule)
    if (active) {
      dropdownLabel = active.name
    }
  }

  return (
    <div className={`bulk-engine-container target-search-container${(isLoading || isLoadingCampaigns) ? ' loading' : ''}`}>
      { (isLoading || isLoadingCampaigns) && <LoaderComponent /> }
      { renderModuleSelector(dropdownLabel) }
      { renderCampaignSelection() }
      <CampaignTableComponent
        className={hasResults && !isCampaignsExpanded ? 'hidden' : ''}
        fromBulkEngine
        showNotedCampaigns={showNotedCampaigns}
        initialRender={initialRender}
        onLoadStart={() => setIsLoadingCampaigns(true)}
        onLoadEnd={() => setIsLoadingCampaigns(false)}
      />
      { renderResults() }
    </div>
  )
}

export default TargetSearchTable
