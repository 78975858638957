import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import MainLayout from '../../layout/MainLayout'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import CardModal from '../Setting/CardModal'

import { reactivate } from '../../redux/actions/auth'
import { selectCurrentAccount } from '../../redux/reducers/header'
import { parseDate } from '../../services/helper'

const ReactivationPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)

  const [isReactivating, setIsReactivating] = useState(false)
  const [needPaymentMethod, setNeedPaymentMethod] = useState(false)

  const handleReactivate = async () => {
    setIsReactivating(true)
    const accessToken = await getAccessTokenSilently()
    const success = await dispatch(reactivate(accessToken))
    setIsReactivating(false)
    if (!success) {
      // The only case when it fails is when there is no payment method.
      setNeedPaymentMethod(true)
    }
  }

  if (!currentAccount || !currentAccount.cancelledAt) {
    history.push('/dashboard')
    return null
  }

  return (
    <MainLayout>
      <div className="reactivation-page">
        <div className="page-header">
          <div className="page-title">
            Your subscription has been cancelled.
          </div>
        </div>
        <div className="page-content">
          { isReactivating && <LoaderComponent /> }
          Your subscription has been cancelled on&nbsp;
          { format(parseDate(currentAccount.cancelledAt), 'MMM d, yyyy HH:mm') }.<br /><br />
          <button
            type="button"
            className="btn btn-blue"
            disabled={isReactivating}
            onClick={handleReactivate}
          >
            Reactivate my account
          </button>
          <br />
          <br />
          <small>
            Or, please contact support using the question mark icon
            in your Carbon6 navigation bar.
          </small>
          <CardModal
            show={needPaymentMethod}
            onHide={() => { setNeedPaymentMethod(false) }}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default ReactivationPage
