import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Modal, Radio, RadioGroup } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import NegativeTargetingSection from '../../../../CampaignCreator/Shared/NegativeTargetingSection'
import SortableTable from '../../../../CommonComponents/SortableTableComponent'
import CustomTable from '../../../../CommonComponents/CustomTableComponent'
import { toast } from '../../../../CommonComponents/ToastComponent/toast'
import StepBreadcrumb from '../../../../CommonComponents/StepBreadcrumb'

import { tableSorter } from '../../../../../services/helper'

import {
  getAdgroupsToAddTargets,
  createNegatives,
} from '../../../../../redux/actions/bulkEngine'
import { selectCurrentAccount } from '../../../../../redux/reducers/header'
import { negativeMatchTypeOptions } from '../../../../../utils/defaultValues'

const STEP_TARGET = 1
const STEP_ADGROUP = 2

const adgroupColumns = [
  { key: 'name', name: 'Ad Group' },
  { key: 'count', name: 'Number of Keywords/Targets', sortable: false },
]

const AddNegativesModal = ({
  campaignId,
  campaignType,
  targetingType,
  targetType,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [step, setStep] = useState(STEP_TARGET)
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [negativeTargetings, setNegativeTargetings] = useState([])
  const [targetLevel, setTargetLevel] = useState('adgroup')
  const [matchType, setMatchType] = useState(null)
  const [selectedAdgroups, setSelectedAdgroups] = useState([])
  const [newNegativeKeywordList, setNewNegativeKeywordList] = useState('')
  const [isGettingAdgroups, setIsGettingAdgroups] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    setStep(STEP_TARGET)
    setSelectedAdgroups([])
    setTargetLevel('adgroup')
  }, []) // eslint-disable-line

  const handleConfirm = async () => {
    if (step !== STEP_ADGROUP) {
      if (!negativeKeywords.length && !negativeTargetings.length) {
        toast.show({
          title: 'Warning',
          description: 'Please enter negatives.',
        })
        return
      }

      (async () => {
        setIsGettingAdgroups(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getAdgroupsToAddTargets(
          accessToken,
          {
            [campaignType]: [campaignId.toString()],
          },
          false // This needs to be cast to boolean explicitly.
        ))
        setIsGettingAdgroups(false)
      })()

      setStep(step + 1)
      return
    }

    if (targetLevel === 'adgroup' && !selectedAdgroups.length) {
      toast.show({
        title: 'Warning',
        description: 'Please select at least 1 adgroup.',
      })
      return
    }

    let negativeKeywordList = []
    const negativeTargetingList = []
    if (targetType === 'keyword') {
      const payload = negativeKeywords.map(keyword => ({
        campaignId,
        adgroupId: 0,
        campaignType,
        search: keyword.keywordText,
        matchType: keyword.matchType,
        // Below information are used for logging in backend.
        adgroupName: '',
      }))

      if (targetLevel === 'adgroup') {
        const adgroupNamesById = {}
        adgroupsToAddTargetsData.forEach((adgroup) => {
          adgroupNamesById[adgroup.adgroup_id] = adgroup.name
        })

        negativeKeywordList = []
        payload.forEach((record) => {
          selectedAdgroups.forEach((adgroupId) => {
            negativeKeywordList.push(Object.assign({}, record, {
              adgroupId,
              adgroupName: adgroupNamesById[adgroupId] || '',
            }))
          })
        })
      } else {
        negativeKeywordList = payload
      }
    } else if (targetType === 'product') {
      if (targetLevel === 'campaign') {
        negativeTargetings.forEach((target) => {
          const search = target.type === 'brand' ? target.id : target.asin
          if (!search) {
            return
          }
          negativeTargetingList.push({
            campaignId,
            adgroupId: 0,
            campaignType,
            type: target.type === 'brand' ? 'asinBrandSameAs' : 'asinSameAs',
            search,
            // Below information are used for logging in backend.
            adgroupName: '',
          })
        })
      } else {
        (adgroupsToAddTargetsData || []).forEach((adgroup) => {
          if (!selectedAdgroups.includes(adgroup.adgroup_id)) {
            return
          }
          negativeTargetings.forEach((target) => {
            const search = target.type === 'brand' ? target.id : target.asin
            if (!search) {
              return
            }
            negativeTargetingList.push({
              campaignId,
              adgroupId: adgroup.adgroup_id,
              campaignType,
              type: target.type === 'brand' ? 'asinBrandSameAs' : 'asinSameAs',
              search,
              // Below information are used for logging in backend.
              adgroupName: adgroup.name,
            })
          })
        })
      }
    }

    if (!negativeKeywordList.length && !negativeTargetingList.length) {
      toast.show({
        title: 'Warning',
        description: 'Nothing to add.',
      })
      return
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const success = await dispatch(createNegatives(
      accessToken,
      negativeKeywordList,
      negativeTargetingList,
      targetLevel,
    ))
    setIsAdding(false)
    if (success) {
      onClose()
    }
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleNegativesAdd = () => {
    if (newNegativeKeywordList === '') {
      toast.show({
        title: 'Warning',
        description: 'Please enter the negative keyword text.',
      })
      return
    }
    if (matchType === null) {
      toast.show({
        title: 'Warning',
        description: 'Please select the match type.',
      })
      return
    }
    const newKeywords = [...negativeKeywords]
    const enteredNegativeKeywords = newNegativeKeywordList.split('\n').map(keyword => keyword.trim().toLowerCase())
    let hasInvalid = false
    enteredNegativeKeywords.forEach((keywordText) => {
      if (/^\s*$/.test(keywordText)) {
        return ''
      }

      const duplicate = newKeywords.find(kw => (
        keywordText === kw.keywordText
        && matchType.value === kw.matchType
      ))

      if (!duplicate) {
        newKeywords.push({
          id: `${keywordText}-${matchType.value}`,
          matchType: matchType.value,
          keywordText,
        })
      } else {
        toast.show({
          title: 'Warning',
          description: `This negative keyword ("${keywordText}") is already added.`,
        })
        hasInvalid = true
      }
    })
    setNegativeKeywords(newKeywords)
    if (!hasInvalid) {
      setMatchType(null)
      setNewNegativeKeywordList('')
    }
  }

  const handleMatchTypeChange = (match, keyword) => {
    const duplicate = negativeKeywords.find(kw => (
      keyword.keywordText === kw.keywordText
        && match.value === kw.matchType
    ))

    if (duplicate) {
      toast.show({
        title: 'Warning',
        description: 'This negative keyword is already added.',
      })
      return
    }

    setNegativeKeywords(negativeKeywords.map((kw) => {
      if (kw.id.toString() === keyword.id.toString()) {
        return { ...kw, matchType: match.value }
      }
      return kw
    }))
  }

  const handleRemove = (record) => {
    setNegativeKeywords(negativeKeywords.filter(
      kw => kw.id.toString() !== record.id.toString()
    ))
  }

  const renderSteps = () => {
    const stepList = [
      {
        value: STEP_TARGET,
        name: targetType === 'keyword' ? 'Add negative keywords' : 'Add negative targets',
      },
      {
        value: STEP_ADGROUP,
        name: 'Choose ad groups',
      },
    ]

    return (
      <StepBreadcrumb
        stepList={stepList}
        currentStep={step}
      />
    )
  }

  const renderNk = (record) => {
    const matchType = negativeMatchTypeOptions.find(option =>
      option.value === record.matchType
    )
    return (
      <>
        <div className="table-col">{ record.keywordText }</div>
        <div className="table-col">
          <Select
            options={negativeMatchTypeOptions}
            value={matchType}
            onChange={val => handleMatchTypeChange(val, record)}
          />
        </div>
        <div className="table-col col-action">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => { handleRemove(record) }}
          >
            Remove
          </button>
        </div>
      </>
    )
  }

  const renderNks = () => (
    <>
      <div className="nk-input-section">
        <div className="input-field-wrapper">
          <label>Match Type</label>
          <Select
            options={negativeMatchTypeOptions}
            value={matchType}
            onChange={setMatchType}
          />
        </div>
        <textarea
          placeholder="Enter negative keywords separated by a new line."
          rows={5}
          value={newNegativeKeywordList}
          onChange={(event) => { setNewNegativeKeywordList(event.target.value) }}
        />
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => handleNegativesAdd()}
        >
          Add Negative Keyword
        </button>
      </div>
      <div className="section-container no-padding">
        <div className="section-title">
          Negative Keywords
        </div>
        <div className="section-note">
          Negative keywords prevent your ads from displaying
          when a shopper's search terms match your negative keywords.
          You can exclude irrelevant searches, reducing your advertising cost.
        </div>
        <CustomTable
          className="table-nks"
          isLoading={isGettingAdgroups || isAdding}
          records={negativeKeywords}
          idField="id"
          noCheckBox
          searchFields={['keywordText']}
          noRecordText="No negative keywords added."
          renderRecord={renderNk}
        >
          <div className="table-col">Keyword</div>
          <div className="table-col">Match Type</div>
          <div className="table-col"></div>
        </CustomTable>
      </div>
    </>
  )

  const renderAdgroup = (adgroup) => (
    <>
      <div className="table-col col-adgroup">
        { adgroup.name }
      </div>
      <div className="table-col">
        { parseInt(adgroup.keywords, 10) + parseInt(adgroup.targets, 10) }
      </div>
    </>
  )

  const renderContents = () => {
    if (step === STEP_TARGET) {
      if (targetType === 'keyword') {
        return renderNks()
      }

      return (
        <NegativeTargetingSection
          className="no-padding"
          negativeTargetings={negativeTargetings}
          targetingType={targetingType}
          forNegatives
          onChange={setNegativeTargetings}
        />
      )
    }

    // Sellers can add campaign negative targets only to SP auto campaigns.
    let canAddToCampaigns = false
    if (campaignType === 'sp') {
      if (currentAccount?.seller_type === 'seller') {
        if (targetingType === 'auto') {
          canAddToCampaigns = true
        }
      } else {
        canAddToCampaigns = true
      }
    }

    return (
      <>
        {
          canAddToCampaigns && (
            <RadioGroup
              value={targetLevel}
              inline
              onChange={setTargetLevel}
            >
              <Radio value="adgroup">Ad Group Level</Radio>
              <Radio value="campaign">Campaign Level</Radio>
            </RadioGroup>
          )
        }
        {
          targetLevel === 'adgroup' && (
            <SortableTable
              columns={adgroupColumns}
              defaultSort={['name', 'asc']}
              sorter={tableSorter(['name'])}
              className="table-adgroups"
              records={adgroupsToAddTargetsData || []}
              idField="adgroup_id"
              searchFields={['name']}
              selectedRecords={selectedAdgroups}
              onChange={setSelectedAdgroups}
              isLoading={isGettingAdgroups || isAdding}
              renderRecord={renderAdgroup}
            />
          )
        }
      </>
    )
  }

  const renderFooter = () => (
    <Modal.Footer>
      {
        step !== STEP_TARGET && (
          <button
            type="button"
            className="rs-btn rs-btn-default"
            onClick={handleBack}
          >
            Back
          </button>
        )
      }
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isGettingAdgroups || isAdding}
        onClick={() => handleConfirm()}
      >
        { step === STEP_ADGROUP ? 'Confirm' : 'Next' }
      </button>
      <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className="add-negatives-modal" backdrop="static" show size="lg">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          { targetType === 'keyword' ? 'Add Negative Keywords' : 'Add Negative Targets' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="creator-section">
        { renderSteps() }
        { renderContents() }
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default AddNegativesModal
