/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Toggle } from 'rsuite'
import * as Icon from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import SearchBox from '../../components/CommonComponents/SearchBox'
import CheckboxComponent from '../../components/CommonComponents/CheckboxComponent'
import PaginationComponent from '../../components/CommonComponents/PaginationComponent'
import BrandNameInput from './BrandNameInput'

import { SP_SETTINGS_REDIRECT_URI } from '../../config/api'
import {
  STORAGE_KEY_STATE,
  STORAGE_KEY_ORIGIN,
  accountTypeWithDspOptions,
} from '../../utils/defaultValues'
import { amazonCountryList } from '../../utils/country'
import { getAuthorizationUrl, getISODate, isNonEndemic } from '../../services/helper'

import {
  getAvailableProfiles,
  saveBrandName,
  addRelatedAccounts,
  reactivate,
} from '../../redux/actions/auth'
import {getAmazonScopeList} from '../../utils/amazon'

const DEFAULT_PAGE_SIZE = 10

const connectionOptions = [
  { label: '- All -', value: '' },
  { label: 'Connected', value: 'connected' },
  { label: 'Disconnected', value: 'disconnected' },
]

const AccountTable = ({ onAdd }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const accountList = useSelector(state => state.header.accountList)
  const isReauthSp = useSelector(state => state.header.isReauthSp)

  const [keyword, setKeyword] = useState('')
  const [pageStart, setPageStart] = useState(0)
  const [pageEnd, setPageEnd] = useState(DEFAULT_PAGE_SIZE)

  const [profileList, setProfileList] = useState([])
  const [filteredProfiles, setFilteredProfiles] = useState([])
  const [selectedProfileIds, setSelectedProfileIds] = useState([])
  const [brandOptions, setBrandOptions] = useState([])
  const [currentType, setCurrentType] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')
  const [currentConnection, setCurrentConnection] = useState('')
  const [currentBrand, setCurrentBrand] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  // Load profiles.
  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      try {
        const profiles = await dispatch(getAvailableProfiles(
          accessToken,
          abortCtrl.signal,
        ))
        setProfileList(profiles)
        setIsLoading(false)

        // Compile options to filter by Seller Centra account (brand name)
        const brands = []
        profiles.forEach((profile) => {
          if (profile.brandName && !brands.includes(profile.brandName)) {
            brands.push(profile.brandName)
          }
        })

        const options = [
          { label: '- All -', value: '' },
        ]
        brands.forEach((brand) => {
          options.push({
            label: brand,
            value: brand,
          })
        })

        setBrandOptions(options)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoading(false)
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const profilesToShow = useMemo(() => {
    const connectedProfiles = []
    const disconnectedProfiles = []
    profileList.forEach((profile) => {
      if (profile.accountInfo.type === 'agency'
        && profile.accountInfo.subType === 'AMAZON_ATTRIBUTION') {
        return
      }

      if (currentType && currentType.value !== '') {
        if (currentType.value === 'dsp') {
          if (!(profile.accountInfo.type === 'agency'
            && !profile.accountInfo.subType)) {
            return
          }
        } else if (currentType.value !== profile.accountInfo.type.toLowerCase()) {
          return
        }
      }

      if (currentCountry
        && currentCountry.value !== ''
        && currentCountry.value !== profile.countryCode.toUpperCase()) {
        return
      }

      if (currentBrand
        && currentBrand.value !== ''
        && currentBrand.value !== profile.brandName) {
        return
      }

      const account = accountList.find(record => record.profile_id === profile.profileId)

      if (currentConnection
        && ((currentConnection.value === 'connected' && !account)
          || (currentConnection.value === 'disconnected' && account))) {
        return
      }

      if (account) {
        profile.account = account
        profile.customBrandName = account.brand_name || ''

        connectedProfiles.push(profile)
      } else {
        disconnectedProfiles.push(profile)
      }
    })
    return [...connectedProfiles, ...disconnectedProfiles]
  }, [currentType, currentCountry, currentConnection, currentBrand,
    profileList, accountList])

  useEffect(() => {
    doFilter()
  }, [profilesToShow]) // eslint-disable-line

  // Filter profiles.
  const doFilter = () => {
    let records
    if (keyword === '') {
      records = profilesToShow
    } else {
      const lowerCased = keyword.toLowerCase()
      records = profilesToShow.filter(record => (
        ['brandName', 'sellerId', 'customBrandName'].find(field => (
          (record[field] || '').toLowerCase().indexOf(lowerCased) !== -1
        ))
      ))
    }

    setFilteredProfiles(records)
  }

  const doSelect = (profileIds) => {
    const existingProfileIds = accountList.map(profile => parseInt(profile.profile_id, 10))
    const filtered = profileIds.filter(profileId => (
      existingProfileIds.indexOf(profileId) === -1
    ))
    setSelectedProfileIds(filtered)
  }

  const handleConnect = async () => {
    const profiles = []
    profileList.forEach((profile) => {
      if (selectedProfileIds.indexOf(profile.profileId) === -1) {
        return
      }
      profiles.push({
        profileId: profile.profileId,
        countryCode: profile.countryCode.toLowerCase(),
        sellerStringId: profile.accountInfo.sellerStringId,
        brandEntityId: profile.accountInfo.brandEntityId,
        brandName: profile.accountInfo.brandName,
        type: profile.accountInfo.type.toLowerCase(),
        subType: (profile.accountInfo.subType || '').toLowerCase(),
        related: parseInt(profile.related, 10),
      })
    })

    if (!profiles.length) {
      toast.show({
        title: 'Danger',
        description: 'Please select at least one disconnected account.',
      })
      return
    }

    setIsAdding(true)

    const accessToken = await getAccessTokenSilently()
    dispatch(addRelatedAccounts(accessToken, profiles)).then(() => {
      setIsAdding(false)
      toast.show({
        title: 'Success',
        description: 'Additional accounts successfully added! Please allow up to 24 hours to collect data.',
      })
    }).catch((error) => {
      setIsAdding(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const handleReactivate = (userId) => async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(reactivate(accessToken, userId))
    setIsLoading(false)
  }

  const handleAdsApiInitiate = userId => (event) => {
    event.preventDefault()

    const state = (new Date()).valueOf()
    // Save state to sessionStorage for later validation.
    window.sessionStorage.setItem(STORAGE_KEY_STATE, state)
    window.sessionStorage.setItem(STORAGE_KEY_ORIGIN, `re_authorize${userId}`)

    window.amazon.Login.authorize({
      scope: getAmazonScopeList(),
      response_type: 'code',
      popup: false,
      state,
    }, SP_SETTINGS_REDIRECT_URI)
  }

  const handleBrandNameSave = async (account, brandName) => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(saveBrandName(accessToken, account.user_id, brandName))
  }

  // When a select-all checkbox is clicked.
  const handleCheckAll = (event) => {
    event.preventDefault()
    doSelect(filteredProfiles.map(record => record.profileId))
  }

  // When a unselect-all checkbox is clicked.
  const handleUncheckAll = (event) => {
    event.preventDefault()
    doSelect([])
  }

  // When a select-all-on-page checkbox is clicked.
  const handleCheckAllOnPage = (checked) => {
    if (checked) {
      doSelect(filteredProfiles.slice(pageStart, pageEnd).map(record => record.profileId))
    } else {
      const oldList = [...selectedProfileIds]
      const filteredIds = filteredProfiles.map(record => record.profileId)
      const newList = oldList.filter(record => !filteredIds.includes(record))
      doSelect(newList)
    }
  }

  // When a record is selected/deselected.
  const handleCheck = record => (checked) => {
    const newList = [...selectedProfileIds]
    if (checked) {
      newList.push(record.profileId)
    } else {
      newList.splice(newList.indexOf(record.profileId), 1)
    }
    doSelect(newList)
  }

  const handleCheckAllDisconnected = (checked) => {
    if (!checked) {
      doSelect([])
    } else {
      const profileIdsToSelect = []
      filteredProfiles.slice(pageStart, pageEnd).forEach((profile) => {
        if (!profile.account) {
          profileIdsToSelect.push(profile.profileId)
        }
      })
      doSelect(profileIdsToSelect)
    }
  }

  // When a search keyword is changed.
  const handleKeywordPress = (event) => {
    if (event.key !== 'Enter') {
      return
    }

    doFilter()

    // Un-check when searching.
    doSelect([])
  }

  // Callback for a paginator to load records.
  const loadData = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStart((pageNum - 1) * pageRows)
      setPageEnd(pageNum * pageRows)
    } else {
      setPageStart(0)
      setPageEnd(filteredProfiles.length)
    }
  }

  // Check if all disconnected records on page are selected.
  const checkIfAllSelected = () => {
    if (!selectedProfileIds.length) {
      return false
    }

    return typeof filteredProfiles.slice(pageStart, pageEnd).find(record => (
      selectedProfileIds.indexOf(record.profileId) === -1
      && !record.account
    )) === 'undefined'
  }

  // Show a selection state. Mock an Amazon functionality.
  const renderSelectionState = () => {
    if (!checkIfAllSelected()) {
      return null
    }

    const disconnectedProfiles = filteredProfiles.filter(profile => !profile.account)
    if (!disconnectedProfiles.length) {
      return null
    }

    if (selectedProfileIds.length === disconnectedProfiles.length) {
      // When there is only one page, nothing to show.
      if (pageStart === 0 && pageEnd >= disconnectedProfiles.length) {
        return null
      }

      return (
        <div className="table-row selection-row">
          <div className="table-col">
            All&nbsp;<strong>{ disconnectedProfiles.length }</strong>
            &nbsp;disconnected profiles are selected.
            &nbsp;<a href="#" onClick={handleUncheckAll}>
              Clear selection
            </a>
          </div>
        </div>
      )
    }

    return (
      <div className="table-row selection-row">
        <div className="table-col">
          All&nbsp;<strong>{ selectedProfileIds.length }</strong>
          &nbsp;disconnected profiles on this page are selected.
          &nbsp;<a href="#" onClick={handleCheckAll}>
            Select all { disconnectedProfiles.length } disconnected profiles
          </a>
        </div>
      </div>
    )
  }

  // Render an action bar.
  const renderAction = () => {
    return (
      <div className="table-header">
        <div className="table-header-left">
          <SearchBox
            keyword={keyword}
            onChange={setKeyword}
            onKeyPress={handleKeywordPress}
          />
        </div>
        <div className="table-header-right">
          <Select
            placeholder="Type"
            options={accountTypeWithDspOptions}
            value={currentType}
            onChange={setCurrentType}
          />
          <Select
            placeholder="Country"
            options={amazonCountryList}
            value={currentCountry}
            onChange={setCurrentCountry}
          />
          <Select
            placeholder="Connection"
            options={connectionOptions}
            value={currentConnection}
            onChange={setCurrentConnection}
          />
          <Select
            placeholder="Account Brand"
            options={brandOptions}
            value={currentBrand}
            onChange={setCurrentBrand}
          />
          <button
            type="button"
            className="btn btn-green"
            onClick={onAdd}
          >
            + Add Accounts
          </button>
          {
            selectedProfileIds.length !== 0 && (
              <button
                type="button"
                className="btn btn-blue"
                disabled={isAdding}
                onClick={handleConnect}
              >
                Connect
              </button>
            )
          }
        </div>
      </div>
    )
  }

  const renderAdsApi = (profile) => {
    if (!profile.account) {
      return null
    }

    return (
      <>
        {
          profile.account.tokenError !== 'ads' ? (
            <Icon.FiCheckCircle size={16} color="#93df8d" />
          ) : (
            <Icon.FiAlertTriangle size={16} color="#ffd156" />
          )
        }
        <a
          href="#"
          className={`${typeof window.amazon === 'undefined' ? 'disabled' : ''}`}
          onClick={handleAdsApiInitiate(profile.account.user_id)}
        >
          Re-authorize
        </a>
      </>
    )
  }

  const renderSpApi = (profile) => {
    if (!profile.account) {
      return null
    }

    // DSP accounts do not need SP API connection.
    if (profile.accountInfo.type === 'agency' && !profile.accountInfo.subType) {
      return null
    }

    return (
      <>
        {
          profile.account.hasSpCode && profile.account.tokenError !== 'sp' ? (
            <Icon.FiCheckCircle size={16} color="#93df8d" />
          ) : (
            <Icon.FiAlertTriangle size={16} color="#ffd156" />
          )
        }
        <a
          href={getAuthorizationUrl(profile.countryCode.toLowerCase(), 'reauth_sp', profile.accountInfo.type.toLowerCase() === 'seller')}
          onClick={() => {
            window.sessionStorage.setItem(STORAGE_KEY_ORIGIN, profile.account.user_id)
          }}
        >
          { profile.account.hasSpCode ? 'Re-authorize' : 'Authorize' }
        </a>
        <a
          href="https://www.carbon6.io/ppce-help/does-entourage-2-0-work-for-agencies?&tref=c6"
          target="_blank"
          rel="noopener noreferrer"
        >
          More info...
        </a>
      </>
    )
  }

  const renderAccount = (profile) => {
    let brandName
    if (profile.brandName) {
      brandName = `${profile.brandName} (${profile.sellerId})`
    } else {
      brandName = profile.sellerId || 'N/A'
    }

    let type = profile.accountInfo.type
    if (type === 'agency' && !profile.accountInfo.subType) {
      type = 'DSP'
    } else if (type === 'vendor' && isNonEndemic(profile.account)) {
      type = 'Non-Endemic'
    }

    return (
      <>
        <div className="table-col">
            <span
            className={`flag flag-${profile.countryCode}`}
            title={profile.countryCode.toUpperCase()}
          >
            &nbsp;
          </span>
          { brandName }
        </div>
        <div className="table-col col-brand-name">
          {
            profile.account && (
              <BrandNameInput
                account={profile.account}
                onSave={handleBrandNameSave}
              />
            )
          }
        </div>
        <div className="table-col col-connection-status">
          <Toggle
            checked={profile.account ? true : false}
            disabled
            size="sm"
          />
          { profile.account ? 'Connected' : 'Disconnected' }
        </div>
        <div className="table-col col-type">
          { type }
        </div>
        <div className="table-col col-subscription">
          {
            profile.account && !profile.account.cancelledAt && (
              <span className="state-active">
                Active
              </span>
            )
          }
          {
            profile.account && profile.account.cancelledAt && (
              <>
                <span className="state-cancelled">
                  Cancelled
                </span>
                <a
                  href="#"
                  onClick={handleReactivate(profile.account.user_id)}
                >
                  Reactivate
                </a>
              </>
            )
          }
        </div>
        <div className="table-col">
          {
            profile.account && profile.account.created_at && (
              <>
                { getISODate(profile.account.created_at) }
              </>
            )
          }
        </div>
        <div className="table-col col-ads-api">
          { renderAdsApi(profile) }
        </div>
        <div className="table-col col-sp-api">
          { renderSpApi(profile) }
        </div>
      </>
    )
  }

  const renderConnectionRow = (connected) => {
    let checked = false
    if (!connected) {
      if (selectedProfileIds.length) {
        checked = typeof filteredProfiles.slice(pageStart, pageEnd).find(record => (
          selectedProfileIds.indexOf(record.profileId) === -1
          && !record.account
        )) === 'undefined'
      }
    }

    return (
      <div className="table-row connection-row">
        <div className="table-col col-check">
          {
            !connected && (
              <CheckboxComponent
                checked={checked}
                onChange={handleCheckAllDisconnected}
              />
            )
          }
        </div>
        <div className="table-col col-connection">
          { connected ? 'Connected' : 'Disconnected' }
        </div>
      </div>
    )
  }

  const renderRecordSubset = (records, connected) => {
    return records.map(record => (
      <div
        key={record.profileId}
        className="table-row"
      >
        <div className="table-col col-check">
          {
            !connected && (
              <CheckboxComponent
                checked={selectedProfileIds.indexOf(record.profileId) !== -1}
                onChange={handleCheck(record)}
              />
            )
          }
        </div>
        { renderAccount(record) }
      </div>
    ))
  }

  const renderRecords = () => {
    if (!filteredProfiles.length) {
      return (
        <div className="table-row">
          <div className="table-col">
            No accounts found
          </div>
        </div>
      )
    }

    const connectedProfiles = []
    const disconnectedProfiles = []
    filteredProfiles.slice(pageStart, pageEnd).forEach((profile) => {
      if (profile.account) {
        connectedProfiles.push(profile)
      } else {
        disconnectedProfiles.push(profile)
      }
    })

    return (
      <>
        {
          connectedProfiles.length > 0 && (
            <>
              { renderConnectionRow(true) }
              { renderRecordSubset(connectedProfiles, true) }
            </>
          )
        }
        {
          disconnectedProfiles.length > 0 && (
            <>
              { renderConnectionRow(false) }
              { renderRecordSubset(disconnectedProfiles, false) }
            </>
          )
        }
      </>
    )
  }

  return (
    <div className={`custom-table-component table-accounts ${isReauthSp || isLoading ? 'loading' : ''}`}>
      { (isReauthSp || isLoading) && <LoaderComponent /> }
      { renderAction() }
      <div className="table-body has-action">
        <div className="table-row content-header">
          <div className="table-col col-check">
            <CheckboxComponent
              checked={checkIfAllSelected()}
              onChange={handleCheckAllOnPage}
            />
          </div>
          <div className="table-col">Account</div>
          <div className="table-col">Brand Name</div>
          <div className="table-col">Connection</div>
          <div className="table-col">Type</div>
          <div className="table-col">Subscription</div>
          <div className="table-col">Added at</div>
          <div className="table-col">Advertising API</div>
          <div className="table-col">Selling Partner API</div>
        </div>
        { renderSelectionState() }
        { renderRecords() }
      </div>
      <PaginationComponent
        total={filteredProfiles.length}
        loadData={loadData}
      />
    </div>
  )
}

export default AccountTable
