import React, { useState } from 'react'
import Select from 'react-select'

import CustomTable from '../CustomTableComponent'
import { toast } from '../ToastComponent/toast'

import { matchTypeOptions } from '../../../utils/defaultValues'

const KeywordInputSection = ({ keywords, onChange }) => {
  const [matchType, setMatchType] = useState(null)
  const [defaultBid, setDefaultBid] = useState(0)
  const [keywordText, setKeywordText] = useState('')

  const handleDefaultBidChange = (value) => {
    const bidValue = parseFloat(value)
    if (isNaN(bidValue)) {
      setDefaultBid(0)
    } else {
      setDefaultBid(bidValue)
    }
  }

  const handleMatchTypeChange = (record, match) => {
    onChange(keywords.map(k =>
      k.id.toString() === record.id.toString() ? { ...k, matchType: match.value } : k
    ))
  }

  const handleAdd = () => {
    if (keywordText === '') {
      toast.show({
        title: 'Warning',
        description: 'Please enter the keyword text.',
      })
      return
    }
    if (matchType === null) {
      toast.show({
        title: 'Warning',
        description: 'Please select the match type.',
      })
      return
    }
    if (isNaN(defaultBid) || defaultBid === 0 || defaultBid === '') {
      toast.show({
        title: 'Warning',
        description: 'Bid should be at least $0.02.',
      })
      return
    }

    const newKeywords = [...keywords]
    const enteredKeywords = keywordText.split('\n').map(keyword => keyword.trim().toLowerCase())
    let hasInvalid = false
    enteredKeywords.forEach((keywordText) => {
      if (/^\s*$/.test(keywordText)) {
        return ''
      }

      const duplicate = newKeywords.find(kw => (
        keywordText === kw.keywordText
        && matchType.value === kw.matchType.toLowerCase()
      ))

      if (!duplicate) {
        newKeywords.push({
          id: `${keywordText}-${matchType.value}`,
          matchType: matchType.value,
          keywordText,
          keywordBid: defaultBid,
        })
      } else {
        toast.show({
          title: 'Warning',
          description: 'This keyword is already added.',
        })
        hasInvalid = true
      }
    })

    onChange(newKeywords)
    if (!hasInvalid) {
      setMatchType(null)
      setKeywordText('')
      setDefaultBid(0)
    }
  }

  const handleRemove = (record) => {
    onChange(keywords.filter(k => k.id.toString() !== record.id.toString()))
  }

  const handleBidChange = (record, event) => {
    const bidValue = parseFloat(event.currentTarget.value)
    if (isNaN(bidValue) || bidValue === 0 || bidValue === '') {
      toast.show({
        title: 'Warning',
        description: 'Bid should be at least $0.02.',
      })
      return
    }
    onChange(keywords.map(k =>
      k.id.toString() === record.id.toString() ? { ...k, keywordBid: bidValue } : k
    ))
  }

  const renderKeyword = (record) => {
    const matchType = matchTypeOptions.find(option => option.value === record.matchType)
    return (
      <>
        <div className="table-col">{ record.keywordText }</div>
        <div className="table-col">
          <Select
            options={matchTypeOptions}
            value={matchType}
            onChange={value => handleMatchTypeChange(record, value)}
          />
        </div>
        <div className="table-col">
          <input
            type="number"
            value={record.keywordBid}
            onChange={event => { handleBidChange(record, event) }}
          />
        </div>
        <div className="table-col col-action">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => { handleRemove(record) }}
          >
            Remove
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="section-container">
        <div className="keyword-input-section">
          <div className="input-field-container">
            <div className="input-field-wrapper">
              <label>Match Type</label>
              <Select
                options={matchTypeOptions}
                value={matchType}
                onChange={setMatchType}
              />
            </div>
            <div className="input-field-wrapper">
              <label>Default Bid</label>
              <input
                type="number"
                value={defaultBid}
                onChange={(event) => { handleDefaultBidChange(event.target.value)} }
              />
            </div>
          </div>
          <textarea
            placeholder="Enter keywords separated by a new line."
            rows={5}
            value={keywordText}
            onChange={(event) => { setKeywordText(event.target.value) }}
          />
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => handleAdd()}
          >
            Add Keyword
          </button>
        </div>
      </div>
      <div className="section-container">
        <div className="section-title">
          Keywords
        </div>
        <div className="section-note">
          Your keywords (word combinations and phrases) are used
          to match your ads with search terms shoppers are using
          to find products.
        </div>
        <CustomTable
          className="table-keywords"
          records={keywords}
          idField="id"
          noCheckBox
          searchFields={['keywordText']}
          noRecordText="No keywords added."
          renderRecord={renderKeyword}
        >
          <div className="table-col">Keyword</div>
          <div className="table-col">Match Type</div>
          <div className="table-col">Bid</div>
          <div className="table-col"></div>
        </CustomTable>
      </div>
    </>
  )
}

export default KeywordInputSection
