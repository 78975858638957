import {
  NEGATIVE_MATCH_TYPE_EXACT,
  NEGATIVE_MATCH_TYPE_PHRASE,
} from '../utils/defaultValues'
import { checkSpecialCharacter, getBudgetLimits } from './helper'

export const validateCampaignName = (name, forSeller) => {
  if (!name) {
    return 'Please enter a campaign name.'
  }

  // The maximum length of a campaign name is 128 characters
  // for sellers and 116 characters for vendors.
  let limit
  if (forSeller) {
    limit = 128
  } else {
    limit = 116
  }
  if (name.length > limit) {
    return `Campaign name should not be longer than ${limit} characters.`
  }

  return null
}

export const validateBudget = (
  value,
  adType,
  countryCode,
  forSeller,
  isDaily = true,
) => {
  const budget = parseFloat(value)
  if (isNaN(budget)) {
    return 'Please enter a campaign budget.'
  }

  const [min, max] = getBudgetLimits(
    adType,
    countryCode,
    forSeller,
    isDaily,
  )

  if (budget < min || budget > max) {
    return `The daily budget range is from ${min} to ${max.toLocaleString()}.`
  }

  return null
}

export const validateAdgroupName = (name) => {
  if (!name) {
    return 'Please enter an ad group name.'
  }

  if (name.length > 255) {
    return 'Ad group name should not be longer than 255 characters.'
  }

  return null
}

export const validateTargets = (targets, budget, bidLimits) => {
  let error = null
  targets.find((record) => {
    const bid = parseFloat(record.bid)
    if (bid < bidLimits[0] || bid > bidLimits[1]) {
      error = `Bid is out of range (must be in [${bidLimits[0]}, ${bidLimits[1]}])`
    } else if (bid > budget) {
      error = `Bid is higher than the campaign budget: ${budget}`
    }
    return error !== null
  })
  return error
}

export const validateKeywordLength = (keywords, keywordField = 'keywordText') => {
  let error = null
  keywords.find((record) => {
    const keywordText = record[keywordField]
    if (checkSpecialCharacter(keywordText)) {
      error = 'One or some keywords contain invalid characters.'
    } else if (keywordText.length > 80) {
      error = 'The maximum length of characters in a keyword is 80.'
    } else {
      const wordCount = keywordText.split(/\s+/).length
      if (wordCount > 10) {
        error = 'The maximum number of parts for a keyword is 10.'
      }
    }
    return error !== null
  })
  return error
}

export const validateNKLength = (keywords, keywordField = 'keywordText') => {
  let error = null
  keywords.find((record) => {
    const keywordText = record[keywordField]
    if (checkSpecialCharacter(keywordText)) {
      error = 'One or some keywords contain invalid characters.'
    } else if (keywordText.length > 80) {
      error = 'The maximum length of characters in a keyword is 80.'
    } else {
      const wordCount = keywordText.split(/\s+/).length
      if (record.matchType === NEGATIVE_MATCH_TYPE_EXACT && wordCount > 10) {
        error = 'For negative exact match type, the negative keyword can have up to 10 parts.'
      } else if (record.matchType === NEGATIVE_MATCH_TYPE_PHRASE && wordCount > 4) {
        error = 'The maximum number of parts for a negative keyword is 4.'
      }
    }
    return error !== null
  })
  return error
}
