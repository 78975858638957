import React, { useState } from 'react'

import BulkResultContainer from '../BulkResultContainer'
import TargetSearchKeywordTable from './TargetSearchKeywordTable'
import TargetSearchTargetTable from './TargetSearchTargetTable'
import TargetSearchSTTable from './TargetSearchSTTable'

const TAB_KEYWORD = 'keyword'
const TAB_TARGET = 'target'
const TAB_SEARCH_TERM = 'search_term'

const tabList = [
  {
    value: TAB_KEYWORD,
    label: 'Keywords',
  },
  {
    value: TAB_SEARCH_TERM,
    label: 'Search Terms',
  },
  {
    value: TAB_TARGET,
    label: 'ASINs',
  },
]

const TargetSearchResult = (props) => {
  const [currentTab, setCurrentTab] = useState(TAB_KEYWORD)

  return (
    <BulkResultContainer>
      <div className="target-search-result-container">
        <div className="tab-list">
          {
            tabList.map((tab) => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? "tab selected" : "tab"}
                onClick={() => { setCurrentTab(tab.value)}}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        {
          currentTab === TAB_KEYWORD && (
            <TargetSearchKeywordTable {...props} />
          )
        }
        {
          currentTab === TAB_TARGET && (
            <TargetSearchTargetTable {...props} />
          )
        }
        {
          currentTab === TAB_SEARCH_TERM && (
            <TargetSearchSTTable {...props} />
          )
        }
      </div>
    </BulkResultContainer>
  )
}

export default TargetSearchResult
