import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import {
  GET_SKUS_SUCCEED,
  GET_PRODUCT_SUCCEED,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_START,
  GET_PRODUCT_KPI_START,
  GET_PRODUCT_KPI_SUCCEED,
  GET_PRODUCT_KPI_FAIL,
  GET_PRODUCT_CHART_DATA_START,
  GET_PRODUCT_CHART_DATA_SUCCEED,
  GET_PRODUCT_CHART_DATA_FAIL,
  GET_PRODUCTS_SUCCEED,
  UPDATE_BULK_COGS_SUCCESS,
  UPDATE_PRODUCT_COG_START,
  UPDATE_PRODUCT_COG_SUCCESS,
  UPDATE_PRODUCT_COG_FAIL,
  GET_PRODUCT_KEYWORDS_START,
  GET_PRODUCT_KEYWORDS_SUCCESS,
  GET_PRODUCT_KEYWORDS_FAIL,
  GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED,
} from '../actionTypes/product'

export const getSummary = (accessToken, type, productId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/product/getSummary', accessToken, {
    userId: currentUserId,
    type,
    productId,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get summary.',
    })
    return null
  })
}

export const getProductKeywords = (accessToken, id) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  dispatch({ type: GET_PRODUCT_KEYWORDS_START })
  callGet('/product/getKeywordsWithRanking', accessToken, {
    userId: currentUserId,
    id,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    dispatch({ type: GET_PRODUCT_KEYWORDS_SUCCESS, data: response.data })
  }).catch(() => {
    dispatch({
      type: GET_PRODUCT_KEYWORDS_FAIL
    })
  })
}

export const updateProductCog = (accessToken, id, cog) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  dispatch({
    type: UPDATE_PRODUCT_COG_START,
  })

  callPost('/product/updateCog', {
    userId: currentUserId,
    id,
    cog,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'COG updated successfully.',
    })
    dispatch({
      type: UPDATE_PRODUCT_COG_SUCCESS,
      data: {
        id,
        cog
      },
    })
  }).catch((error) => {
    dispatch({
      type: UPDATE_PRODUCT_COG_FAIL,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update product COG.',
    })
  })
}

// Get a list of products for dashboard products table.
export const getProducts = (
  accessToken,
  startDate = undefined,
  endDate = undefined,
  signal = undefined,
) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callGet('/product/getProducts', accessToken, {
    userId: currentUserId,
    startDate: startDate ? startDate : getISODate(currentStartDate),
    endDate: endDate ? endDate : getISODate(currentEndDate),
  }, signal).then((response) => {
    dispatch({
      type: GET_PRODUCTS_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

// Get a list of products for product selector.
export const getProductList = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/product/getProductList', accessToken, {
    userId: currentUserId,
  }).then((response) => response.data)
}

export const getSkus = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignCreator/getSkus', accessToken, {
    userId: currentUserId,
  }).then((response) => {
    dispatch({
      type: GET_SKUS_SUCCEED,
      data: response.data,
    })
  }).catch(() => {
    //
  })
}

export const getProductById = (accessToken, id) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  const startDate = getISODate(currentStartDate)
  const endDate = getISODate(currentEndDate)

  dispatch({ type: GET_PRODUCT_START })
  callGet('/product/getProduct', accessToken, {
    id,
    userId: currentUserId,
    startDate,
    endDate,
  }).then((response) => {
    dispatch({
      type: GET_PRODUCT_SUCCEED,
      data: response.data,
    })
  }).catch(() => {
    dispatch({
      type: GET_PRODUCT_FAIL
    })
  })

  dispatch({ type: GET_PRODUCT_KPI_START })
  callGet('/product/getKpiData', accessToken, {
    id,
    userId: currentUserId,
    startDate,
    endDate,
  }).then((response) => {
    dispatch({
      type: GET_PRODUCT_KPI_SUCCEED,
      data: response.data,
    })
  }).catch(() => {
    dispatch({
      type: GET_PRODUCT_KPI_FAIL
    })
  })

  dispatch({ type: GET_PRODUCT_CHART_DATA_START })
  callGet('/product/getChart', accessToken, {
    id,
    userId: currentUserId,
    startDate,
    endDate,
  }).then((response) => {
    dispatch({ type: GET_PRODUCT_CHART_DATA_SUCCEED, data: response.data })
  }).catch(() => {
    dispatch({
      type: GET_PRODUCT_CHART_DATA_FAIL
    })
  })
}

// upload bulk cogs for product table
export const updateBulkCogs = (accessToken, skus) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/product/updateCogInBulk', {
    userId: currentUserId,
    skus,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: `${response.data.successSkus.length} SKUs have been updated.`,
    })
    if (response.data.invalidSkus && response.data.invalidSkus.length > 0) {
      const strInvalidSkus = response.data.invalidSkus.map(sku => sku.sku)
      toast.show({
        title: 'Warning',
        description: `${strInvalidSkus.join(', ')} are invalid.`,
      })
    }
    dispatch({
      type: UPDATE_BULK_COGS_SUCCESS,
      data: {
        successSkus: response.data.successSkus,
      }
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update COGs.',
    })
  })
}

export const getCampaignsForProduct = (accessToken, productId) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  callGet('/product/getCampaigns', accessToken, {
    userId: currentUserId,
    productId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    dispatch({
      type: GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to load campaigns for a product.',
    })
  })
}
