import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../../CommonComponents/CustomTableComponent'

import { createNegatives } from '../../../redux/actions/bulkEngine'

const ModalAddNegativeAsins = ({ showModal, campaignType, currentAdGroupId, terms = [], onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const [isAdding, setIsAdding] = useState(false)
  const [selectedAsins, setSelectedAsins] = useState([])
  const [selectedAdgroups, setSelectedAdgroups] = useState([])

  useEffect(() => {
    setSelectedAsins(terms.map(term => term.asin))
  }, [terms])

  const adgroupList = useMemo(() => {
    let adgroups = (currentAdGroups || []).filter(adgroup => (
      (!adgroup.state || (adgroup.state.toLowerCase() === 'enabled'))
      && adgroup.targetType === 'products'
    ))
    if (currentAdGroupId) {
      adgroups = adgroups.filter(adgroup =>
        adgroup.adgroupid.toString() === currentAdGroupId.toString()
      )
    }

    return adgroups
  }, [currentAdGroups, currentAdGroupId])

  const handleAdd = async () => {
    const negatives = []

    const adgroupNamesById = {}
    currentAdGroups.forEach((adgroup) => {
      adgroupNamesById[adgroup.adgroupid] = adgroup.name
    })

    selectedAsins.forEach((asin) => {
      selectedAdgroups.forEach((adgroupId) => {
        negatives.push({
          campaignId: currentDetail.campaign_id,
          adgroupId,
          campaignType,
          search: asin,
          // Below information are used for logging in backend.
          adgroupName: adgroupNamesById[adgroupId],
        })
      })
    })

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(createNegatives(accessToken, negatives, [], 'adgroup'))
    setIsAdding(false)
  }

  const renderNegative = negative => (
    <>
      <div className="table-col">
        { negative.asin }
      </div>
    </>
  )

  const renderAdgroup = adgroup => (
    <>
      <div className="table-col">
        { adgroup.name }
      </div>
    </>
  )

  const renderNegatives = () => {
    return (
      <div className="section-container">
        <div className="section-title">
          Confirm Negative Asins
        </div>
        <CustomTable
          className="table-negatives"
          records={terms}
          idField="asin"
          noSearch
          selectedRecords={selectedAsins}
          renderRecord={renderNegative}
          onChange={setSelectedAsins}
        >
          <div className="table-col">Name</div>
        </CustomTable>
      </div>
    )
  }

  const renderAdgroups = () => {
    return (
      <div className="section-container">
        <div className="section-title">
          Select Ad Group
        </div>
        <CustomTable
          className="table-adgroups"
          records={adgroupList}
          idField="adgroupid"
          noSearch
          selectedRecords={selectedAdgroups}
          renderRecord={renderAdgroup}
          onChange={setSelectedAdgroups}
        >
          <div className="table-col">Name</div>
        </CustomTable>
      </div>
    )
  }

  return (
    <Modal backdrop="static" className="modal-add-negative-asins" show={showModal} size="lg">
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Add Negative ASINs to Ad Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="section-row">
          { renderNegatives() }
          { renderAdgroups() }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={!selectedAsins.length || !selectedAdgroups.length || isAdding}
          onClick={handleAdd}
        >
          Add Negative ASINs
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => { onClose() }}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAddNegativeAsins
