import React, { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useAuth0 } from '@auth0/auth0-react'

const SelectAsyncPaginate = ({
  keyValue, className = '', classNamePrefix,
  components, loadData, selection,
  isDisabled, placeholder, field,
  onChange, onLoad = null,
}) => {
  const { getAccessTokenSilently } = useAuth0()

  const [keyword, setKeyword] = useState('')

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const accessToken = await getAccessTokenSilently()
    const response = await loadData(accessToken, page, searchQuery || '')
    if (onLoad) {
      onLoad(response)
    }

    return {
      options: response,
      hasMore: response.length >= 1,
      additional: {
        page: page + 1,
      },
    }
  }

  const handleInputChange = (inputValue, { action }) => {
    if (action !== 'input-blur'
      && action !== 'menu-close'
      && action !== 'set-value') {
      setKeyword(inputValue)
    }
  }

  return (
    <AsyncPaginate
      key={keyValue}
      className={className}
      classNamePrefix={classNamePrefix}
      loadOptions={loadOptions}
      getOptionLabel={record => record[field]}
      getOptionValue={record => record[field]}
      value={selection}
      components={components}
      isClearable={false}
      isMulti
      isDisabled={isDisabled}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder={placeholder}
      inputValue={keyword}
      onChange={onChange}
      onInputChange={handleInputChange}
      additional={{
        page: 1,
      }}
    />
  )
}

export default SelectAsyncPaginate
