import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import BulkResultContainer from '../BulkResultContainer'
import NegativeCreatorModal from './NegativeCreatorModal'

import {
  getAsinImages,
} from '../../redux/actions/bulkEngine'

import {
  tableSorter,
  calcDerivedMetrics,
  capitalizeFirstLetter,
  copyToClipboard,
  getExportValueForColumn,
  groupRecords,
  isAsin,
} from '../../services/helper'

import { bulkSTColumnList, MODULE_NAME_ST_OP } from '../../utils/defaultValues'

import { matchTypes } from '../../utils/filterDef'

export const FILTER_NAME_ST_OP = 'bulkStOp'

export const searchTermFilters = [
  { value: '', label: 'Search Terms/ASINs' },
  { value: 'st', label: 'Search Terms Only' },
  { value: 'asin', label: 'ASINs Only' },
]

const initialColumns = [
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'target', name: 'Associated Target', className: 'col-target' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'target', name: 'Associated Target', className: 'col-target' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const StOpResult = ({
  newTermOnly,
  stFilter,
  campaignsById,
  adgroupNamesById,
  onChangeNewTermOnly,
  onChangeStFilter,
  onChangeDate,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const stTableColumns = useSelector(state => state.pageGlobal.stTableColumns)

  const stOpData = useSelector(state => state.bulkEngine.stOpData)

  const [groupMode, setGroupMode] = useState(false)
  const [searchTerms, setSearchTerms] = useState([])
  const [groupedSearchTerms, setGroupedSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [showNegativeCreatorModal, setShowNegativeCreatorModal] = useState(false)
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [isGettingImages, setIsGettingImages] = useState(false)

  useEffect(() => {
    const extendedSearchTerms = []; // semi-colon is a must here.
    if (stOpData) {
      stOpData.forEach((record) => {
        const _isAsin = isAsin(record.search)
        if ((stFilter.value === 'st' && _isAsin)
          || (stFilter.value === 'asin' && !_isAsin)) {
          return
        }

        if (selectedMatchType.value !== '') {
          if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
            return
          }
        }

        const search = record.search.trim().toLowerCase()
        const target = (record.target === '(_targeting_auto_)'
          || record.target === '(_targeting_auto_, 1)') ? '*' : record.target

        const campaignDetail = campaignsById[record.campaign_id] || {}

        extendedSearchTerms.push({
          ...calcDerivedMetrics(record),
          id: `${record.campaign_id}-${record.adgroup_id}-${search}-${target}-${record.match_type}`,
          matchType: capitalizeFirstLetter(record.match_type),
          campaignName: campaignDetail.name || '',
          campaignType: campaignDetail.type || '',
          targetingType: campaignDetail.targetingType || '',
          adgroupName: adgroupNamesById[record.adgroup_id] || '',
          search,
          target,
        })
      })
    }

    setSearchTerms(extendedSearchTerms)
  }, [stOpData, campaignsById, adgroupNamesById, stFilter, selectedMatchType])

  useEffect(() => {
    if (groupMode) {
      setGroupedSearchTerms(
        groupRecords(
          searchTerms,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedSearchTerms([])
    }
  }, [searchTerms, groupMode])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const selection = campaignTableColumns.filter(c => ![
      'campaign',
      'target_acos',
      'daily_budget',
      'st_impr_rank',
      'st_impr_share',
    ].includes(c.key))
    if ((stTableColumns || []).includes('st_impr_rank')) {
      selection.push({
        key: 'st_impr_rank',
        label: 'Search Term Impression Rank',
        name: 'ST Imp. Rank',
      })
    }
    if ((stTableColumns || []).includes('st_impr_share')) {
      selection.push({
        key: 'st_impr_share',
        label: 'Search Term Impression Share %',
        name: 'ST Imp. Share',
      })
    }
    return [
      [...initialColumns, ...selection],
      [...initialColumnsGroup, ...selection],
      selection,
    ]
  }, [campaignTableColumns, stTableColumns])

  const handleCopy = () => {
    const sts = searchTerms.filter(st => (
      selectedSearchTerms.indexOf(st.id) !== -1
    )).map(st => st.search.trim())

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedSearchTerms.length} search term${selectedSearchTerms.length > 1 ? 's' : ''}.`
    })
  }

  const handleAsinImageFetch = async () => {
    const asins = []
    searchTerms.forEach((record) => {
      if (isAsin(record.search)) {
        asins.push(record.search.toUpperCase())
      }
    })

    if (asins.length) {
      setIsGettingImages(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAsinImages(accessToken, asins, 'stOp'))
      setIsGettingImages(false)
    }
  }

  const renderFilter = () => {
    const hasAsin = typeof searchTerms.find(record => (
      isAsin(record.search)
    )) !== 'undefined'

    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By search terms"
          onChange={setGroupMode}
        />
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="New Only"
            checked={newTermOnly}
            onChange={onChangeNewTermOnly}
          />
          <CustomTooltip placement="right">
            <p>Remove words/ASINs already added as negative to selected campaigns and ad groups.</p>
          </CustomTooltip>
        </div>
        <div className="select-wrapper">
          <Select
            classNamePrefix="st-filter-selector"
            options={searchTermFilters}
            value={stFilter}
            onChange={onChangeStFilter}
          />
        </div>
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { setCurrentFilterName(FILTER_NAME_ST_OP) }}
          >
            Refine Filter
          </button>
        </div>
        {
          hasAsin && (
            <div className="button-wrapper">
              <button
                type="button"
                className="btn btn-blue"
                disabled={isGettingImages}
                onClick={handleAsinImageFetch}
              >
                Show ASIN Images
              </button>
            </div>
          )
        }
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }
    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setShowNegativeCreatorModal(true) }}
        >
          Add Negative{selectedSearchTerms.length > 1 ? 's' : ''} to Campaign{selectedSearchTerms.length > 1 ? 's' : ''}
        </button>
        <button
          type="button"
          className="btn btn-green"
          onClick={() => { handleCopy() }}
        >
          Copy
        </button>
      </>
    )
  }

  const renderSearchCell = record => (
    <div className="table-col col-search-term" title={record.search}>
      {
        typeof record.image !== 'undefined' && (
          <img
            src={record.image}
            alt={record.search}
          />
        )
      }
      <strong className="contents">{ record.search }</strong>
    </div>
  )

  const renderRecord = record => (
    <>
      { renderSearchCell(record) }
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col col-target" title={record.target}>
        <span className="contents">
          { record.target }
        </span>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-search-term">Totals:</div>
      <div className="table-col" />
      <div className="table-col col-target" />
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'search') {
        return `"${(record.search || '').replace(/"/g, '""')}"`
      }
      if (column.key === 'matchType') {
        return record.matchType
      }
      if (column.key === 'target') {
        return `"${(record.target || '').replace(/"/g, '""')}"`
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-search-term">
        { record.children.length } search terms
      </div>
      <div className="table-col" />
      <div className="table-col col-target" />
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      { renderSearchCell(record) }
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col col-target" title={record.target}>
        <span className="contents">
          { record.target }
        </span>
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-search-term" />
      <div className="table-col" />
      <div className="table-col col-target" />
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const sts = searchTerms.filter(st => (
    selectedSearchTerms.indexOf(st.id) !== -1
  ))

  return (
    <BulkResultContainer>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            isLoading={isGettingImages}
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-search-terms"
            records={groupedSearchTerms}
            idField="campaign_id"
            searchFields={['search', 'target']}
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_ST_OP}
            useFilterModal
            columnEditorId="bulkStOpResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_ST_OP}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={onChangeDate}
            sorterChild={tableSorter(['search', 'matchType', 'target', 'adgroupName'])}
            idFieldChild="id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isGettingImages}
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['search', 'matchType', 'target', 'campaignName', 'adgroupName'])}
            className="table-search-terms"
            records={searchTerms}
            idField="id"
            searchFields={['search', 'target']}
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_ST_OP}
            useFilterModal
            columnEditorId="bulkStOpResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_ST_OP}
            getExportData={getExportData}
            renderRecord={renderRecord}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={onChangeDate}
          />
        )
      }
      {
        showNegativeCreatorModal && (
          <NegativeCreatorModal
            searchTerms={sts}
            onClose={() => { setShowNegativeCreatorModal(false) }}
          />
        )
      }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={MODULE_NAME_ST_OP}
            onApply={() => { setCurrentFilterName('') }}
            onClose={() => { setCurrentFilterName('') }}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default StOpResult
