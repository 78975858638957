/* eslint-disable jsx-a11y/anchor-is-valid */
// Campaign overview.
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../CommonComponents/ToastComponent/toast'
import VideoLink from '../CommonComponents/VideoLink'
import TemplateSelector from './TemplateSelector'

import { getSpTemplates } from '../../redux/actions/ap'
import { showRuleManager } from '../../redux/actions/ruleManager'

import {
  RULE_TYPE_IR,
  RULE_TYPE_EP,
  RULE_TYPE_RR,
  RULE_TYPE_CP,
  RULE_TYPE_WP,
  RULE_TYPE_DP,
  campaignTypeMap,
  ruleStickerNames,
} from '../../utils/defaultValues'

const videoList = [
  { title: 'Template Video', url: 'https://www.loom.com/embed/29a27bb03bbf451dbafeb179b199e747' },
]

const stickerTypes = [
  { field: 'hasDp', name: ruleStickerNames[RULE_TYPE_DP], type: RULE_TYPE_DP },
  { field: 'hasWp', name: ruleStickerNames[RULE_TYPE_WP], type: RULE_TYPE_WP },
  { field: 'hasCp', name: ruleStickerNames[RULE_TYPE_CP], type: RULE_TYPE_CP },
  { field: 'hasRr', name: ruleStickerNames[RULE_TYPE_RR], type: RULE_TYPE_RR },
  { field: 'hasEp', name: ruleStickerNames[RULE_TYPE_EP], type: RULE_TYPE_EP },
  { field: 'hasIr', name: ruleStickerNames[RULE_TYPE_IR], type: RULE_TYPE_IR },
]

const CampaignInfo = ({ campaign, settings, onSelectTemplate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)
  const campaignId = useSelector(state => state.ap.campaignId)
  const templates = useSelector(state => state.ap.templates)
  const isLoadingTemplates = useSelector(state => state.ap.isLoadingTemplates)
  const isTemplatesLoaded = useSelector(state => state.ap.isTemplatesLoaded)

  const [templateSelectorVisible, setTemplateSelectorVisible] = useState(false)

  useEffect(() => {
    let abortCtrl
    if (templateSelectorVisible
      && !isTemplatesLoaded
      && !isLoadingTemplates
      && currentUserId) {
      abortCtrl = new AbortController();
      (async () => {
        const accessToken = await getAccessTokenSilently()
        dispatch(getSpTemplates(accessToken, abortCtrl.signal))
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [templateSelectorVisible]) // eslint-disable-line

  if (!campaign) {
    return null
  }

  let targetingType
  if (campaign.basic[0].type === 'sp') {
    if (campaign.basic[0].targeting_type === 'auto') {
      targetingType = 'Auto'
    } else {
      targetingType = 'Manual'
    }
  }

  const handleTemplateSelect = (event) => {
    event.preventDefault()
    setTemplateSelectorVisible(true)
  }

  const handleTemplateApply = (templateId) => {
    onSelectTemplate(templateId)
    setTemplateSelectorVisible(false)

    toast.show({
      title: 'Info',
      description: 'You need to click on `Apply` button '
        + 'to save your selection and apply the template.',
      duration: 5000,
    })
  }

  const handleStickerClick = ruleType => () => {
    if (ruleType === RULE_TYPE_RR) {
      return
    }

    const details = campaign.basic[0]

    dispatch(showRuleManager(
      ruleType,
      [{
        campaign_id: campaignId,
        name: details.name,
        campaignType: details.type,
      }],
    ))
  }

  const renderStickers = () => {
    const enabledStickers = []
    stickerTypes.forEach((type) => {
      if (!campaign.basic[0][type.field]) {
        return
      }
      enabledStickers.push(type)
    })

    if (!enabledStickers.length) {
      return null
    }

    return (
      <div className="rule-stickers">
        {
          enabledStickers.map(type => (
            <span
              key={type.type}
              className="rule-sticker"
            >
              <span onClick={handleStickerClick(type.type)}>
                { type.name }
              </span>
            </span>
          ))
        }
      </div>
    )
  }

  let template
  if (settings.ap_template_id) {
    template = templates.find(template => (
      parseInt(template.id, 10) === parseInt(settings.ap_template_id, 10)
    ))

    if (!template) {
      template = campaign.template
    }
  }

  return (
    <div className="campaign-info">
      <div className="campaign-detail">
        {
          typeof campaignId !== 'undefined' && (
            <Link
              to={`/campaign/${campaignId}/${campaign.basic[0].type}`}
              title={campaign.basic[0].name}
            >
              { campaign.basic[0].name }
            </Link>
          )
        }
        <div className="campaign-type">
          { targetingType && <span>{ targetingType }</span> }
          <span>{ campaignTypeMap[campaign.basic[0].type] }</span>
        </div>
        {
          ((campaign.ap && !campaign.ap.adgroup_id) || (!campaign.ap && !settings.adgroup_id)) && (
            <div className="campaign-template">
              <a href="#" onClick={handleTemplateSelect}>
                {
                  !template ? 'No template used'
                  : (
                    <span>
                      Template: <strong>{ template.name }</strong>
                    </span>
                  )
                }
              </a>
              <VideoLink
                videoList={videoList}
              />
            </div>
          )
        }
        <TemplateSelector
          show={templateSelectorVisible}
          templates={templates}
          settings={settings}
          isLoading={isLoadingTemplates}
          onChange={handleTemplateApply}
          onCancel={() => { setTemplateSelectorVisible(false) }}
        />
      </div>
      { renderStickers() }
    </div>
  )
}

export default CampaignInfo
