import { combineReducers } from 'redux'

import header from './header'
import auth from './auth'
import campaign from './campaign'
import campaignLog from './campaignLog'
import product from './product'
import dashboard from './dashboard'
import ap from './ap'
import campaignCreator from './campaignCreator'
import coin from './coin'
import activityLog from './activityLog'
import campaignDetail from './campaignDetail'
import productDetail from './productDetail'
import pageGlobal from './pageGlobal'
import bulkEngine from './bulkEngine'
import tag from './tag'
import accountHealth from './accountHealth'
import ruleManager from './ruleManager'
import dsp from './dsp'
import job from './job'

const appReducer = combineReducers({
  header,
  auth,
  campaign,
  campaignLog,
  product,
  dashboard,
  ap,
  campaignCreator,
  coin,
  activityLog,
  campaignDetail,
  productDetail,
  pageGlobal,
  bulkEngine,
  tag,
  accountHealth,
  ruleManager,
  dsp,
  job,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
