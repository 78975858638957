import React from 'react'

const TargetingList = ({ targetings, dailyBudget = 0, adType, defaultBid, onChange }) => {
  const handleBidChange = (event, target) => {
    onChange(targetings.map((item) => {
      if (
        (
          typeof target.id !== 'undefined'
          && item.id.toString() === target.id.toString()
        )
        ||
        (
          typeof target.asin !== 'undefined'
          && item.asin === target.asin
        )
      ) {
        return {
          ...item,
          bid: event.target.value,
        }
      }
      return item
    }))
  }

  const handleRemove = (target) => {
    onChange(targetings.filter(item => {
      if (target.type !== item.type) {
        return true
      }
      if (target.type === 'category') {
        return target.id.toString() !== item.id.toString()
      }
      return target.asin !== item.asin
    }))
  }

  const handleSuggestionApply = (type, target) => {
    if (!target.suggestedBid) {
      return
    }
    let bid = defaultBid
    switch (type) {
      case 'suggest':
        bid = target.suggestedBid.recommended
        break;
      case 'min':
        bid = target.suggestedBid.rangeLower
        break;
      case 'max':
        bid = target.suggestedBid.rangeUpper
        break;
      default:
        break;
    }

    onChange(targetings.map((item) => {
      if (
        (
          item.type === 'product'
          && target.type === 'product'
          && item.asin === target.asin
        )
        ||
        item.id.toString() === target.id.toString()
      ) {
        return {
          ...item,
          bid,
        }
      }
      return item
    }))
  }

  if (!targetings.length) {
    return (
      <div className="no-targeting-desc">
        No targeting added.
      </div>
    )
  }

  return (
    <div className="targeting-list">
      {
        targetings.map(target => (
          <div
            key={target.type === 'product' ? target.asin : `${target.id}-${target.brandId || ''}`}
            className="targeting-item"
          >
            <div className="targeting-info">
              {
                target.type !== 'product' && (
                  <div className="category-path">
                    { target.path }
                  </div>
                )
              }
              <div className="targeting-name">
                { target.type !== 'product' ? 'Category' : 'Product' }:&nbsp;
                { target.name }
              </div>
              {
                target.type === 'product' && (
                  <div className="targeting-meta">
                    ASIN: { target.asin }
                  </div>
                )
              }
              {
                target.type === 'refine' && (
                  <div className="targeting-meta">
                    Brand: { target.brandName }
                  </div>
                )
              }
            </div>
            {
              adType === 'sd' && target.suggestedBid && (
                <div className="suggested-bid-container">
                  Suggested Bid:&nbsp;
                  {
                    `${target.suggestedBid.recommended}: ${target.suggestedBid.rangeLower}~${target.suggestedBid.rangeUpper}`
                  }
                  <div className="apply-section">
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={() => { handleSuggestionApply('suggest', target) }}
                    >
                      Apply Suggest
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={() => { handleSuggestionApply('min', target) }}
                    >
                      Apply Min
                    </button>
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={() => { handleSuggestionApply('max', target) }}
                    >
                      Apply Max
                    </button>
                  </div>
                </div>
              )
            }
            <div className="targeting-action">
              <div>
                <input
                  type="number"
                  value={target.bid}
                  onChange={(event) => { handleBidChange(event, target) }}
                />
                {
                  adType === 'sp' && parseFloat(target.bid) > parseFloat(dailyBudget) && (
                    <div className="warning-message">
                      Exceeds daily budget.
                    </div>
                  )
                }
                {
                  adType === 'sd' && parseFloat(target.bid) >= parseFloat(dailyBudget) / 2 && (
                    <div className="warning-message">
                      Bid must be less than half the value of your budget.
                    </div>
                  )
                }
              </div>
              <span className="close-icon" title="Remove" onClick={() => { handleRemove(target) }}>
                &times;
              </span>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default TargetingList
