import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { Toggle, InputNumber } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CampaignSelector from '../CommonComponents/CampaignSelector'
import BidRuleSection from '../RuleManagerComponents/BidRuleSection'
import CampaignRuleSection from '../RuleManagerComponents/CampaignRuleSection'
import ModifierRuleSection from '../RuleManagerComponents/ModifierRuleSection'
import KeywordSelector from './KeywordSelector'

import { loadCampaigns } from '../../redux/actions/ruleManager'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
} from '../../utils/defaultValues'

const CAMPAIGN_SOURCE_AMAZON = 'amazon'
const CAMPAIGN_SOURCE_EXTERNAL = 'external'

const campaignSourceList = [
  { value: CAMPAIGN_SOURCE_AMAZON, label: 'Amazon PPC' },
  { value: CAMPAIGN_SOURCE_EXTERNAL, label: 'External traffic' },
]

const RuleMetric = ({ skus, rule, isUpdatingStatus, onChange, onRemove, onStatusChange }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [includeSb, setIncludeSb] = useState(false)
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  const currentValues = useMemo(() => {
    const values = {
      status: true,
      campaignSource: null,
      campaignId: null,
      campaignType: null,
      keywords: [],
      rankLimit: 0,
      rankDays: 0,
      bidAction: null,
      bidValue: null,
      campaignAction: null,
      campaignValue: null,
      campaignLimit: null,
      tosModAction: null,
      tosModValue: null,
      productModAction: null,
      productModValue: null,
      restModAction: null,
      restModValue: null,
      ruleDays: 0,
    }

    if (typeof rule.status !== 'undefined') {
      values.status = rule.status
    }

    if (rule.campaign_source) {
      values.campaignSource = rule.campaign_source
    }

    if (rule.campaign_id) {
      if (!rule.campaign_source) {
        if (rule.campaignType === 'sp' || rule.campaignType === 'sb') {
          values.campaignSource = campaignSourceList.find(source => (
            source.value === CAMPAIGN_SOURCE_AMAZON
          ))
        }
      }

      values.campaignId = rule.campaign_id
      values.campaignType = rule.campaignType

      if (values.campaignType === 'sb') {
        setIncludeSb(true)
      }
    }

    if (rule.keywords) {
      values.keywords = rule.keywords.map(keyword => ({
        keyword,
      }))
    }

    if (rule.rank_limit) {
      values.rankLimit = rule.rank_limit
    }

    if (rule.rank_days) {
      values.rankDays = rule.rank_days
    }

    if (rule.slots && rule.slots.length) {
      rule.slots.forEach((actionRule) => {
        if (actionRule.t === RULE_TYPE_BID) {
          values.bidAction = ruleBidActionList.find(option =>
            option.value === actionRule.a
          )
          if (actionRule.v) {
            values.bidValue = actionRule.v
          }
        } else if (actionRule.t === RULE_TYPE_CAMPAIGN) {
          values.campaignAction = ruleCampaignActionList.find(option =>
            option.value === actionRule.a
          )

          if (actionRule.v) {
            values.campaignValue = actionRule.v
          }

          if (actionRule.l) {
            values.campaignLimit = actionRule.l
          }
        } else if (actionRule.t === RULE_TYPE_TOS_MODIFIER) {
          values.tosModAction = ruleModifierActionList.find(option =>
            option.value === actionRule.a
          )

          if (actionRule.v) {
            values.tosModValue = actionRule.v
          }
        } else if (actionRule.t === RULE_TYPE_PRODUCT_MODIFIER) {
          values.productModAction = ruleModifierActionList.find(option =>
            option.value === actionRule.a
          )

          if (actionRule.v) {
            values.productModValue = actionRule.v
          }
        } else if (actionRule.t === RULE_TYPE_REST_MODIFIER) {
          values.restModAction = ruleModifierActionList.find(option =>
            option.value === actionRule.a
          )

          if (actionRule.v) {
            values.restModValue = actionRule.v
          }
        }
      })
    }

    if (rule.rule_days) {
      values.ruleDays = rule.rule_days
    }

    return values
  }, [rule])

  useEffect(() => {
    if (!currentValues.campaignSource) {
      return
    }
    if (currentValues.campaignSource.value === CAMPAIGN_SOURCE_AMAZON) {
      if (skus.length) {
        (async () => {
          const accessToken = await getAccessTokenSilently()
          setIsLoadingCampaigns(true)
          const data = await dispatch(loadCampaigns(accessToken, true, includeSb, skus, true))
          setIsLoadingCampaigns(false)
          setCampaigns(data)
        })()

      }
    }
  }, [currentValues.campaignSource, includeSb, skus, dispatch]) // eslint-disable-line

  const selectedCampaign = useMemo(() => {
    if (!currentValues.campaignId) {
      return null
    }
    return campaigns.find(record => (
      record.id.toString() === currentValues.campaignId.toString()
    ))
  }, [campaigns, currentValues.campaignId])

  const doChange = (updatedValues) => {
    const payload = {
      status: true,
      campaign_source: null,
      campaign_id: null,
      campaignType: null,
      keywords: [],
      rank_limit: 0,
      rank_days: 0,
      slots: [],
      rule_days: 0,
    }

    if (typeof updatedValues.status !== 'undefined') {
      payload.status = updatedValues.status
    }

    if (updatedValues.campaignSource) {
      payload.campaign_source = updatedValues.campaignSource
    }

    if (updatedValues.campaignId) {
      payload.campaign_id = updatedValues.campaignId
    }

    if (updatedValues.campaignType) {
      payload.campaignType = updatedValues.campaignType
    }

    if (updatedValues.keywords) {
      payload.keywords = updatedValues.keywords.map((record) => {
        if (typeof record === 'string') {
          return record
        }
        return record.keyword
      })
    }

    if (updatedValues.rankLimit) {
      payload.rank_limit = updatedValues.rankLimit
    }

    if (updatedValues.rankDays) {
      payload.rank_days = updatedValues.rankDays
    }

    if (updatedValues.bidAction) {
      const actionRule = {
        t: RULE_TYPE_BID,
        a: updatedValues.bidAction.value,
      }

      if (actionRule.a === RULE_BID_ACTION_PAUSE) {
        payload.slots.push(actionRule)
      } else {
        if (updatedValues.bidValue) {
          actionRule.v = parseFloat(updatedValues.bidValue)
        } else {
          actionRule.v = 0
        }
        payload.slots.push(actionRule)
      }
    }

    if (updatedValues.campaignAction) {
      const actionRule = {
        t: RULE_TYPE_CAMPAIGN,
        a: updatedValues.campaignAction.value,
      }

      if (actionRule.a === RULE_CAMPAIGN_ACTION_PAUSE) {
        payload.slots.push(actionRule)
      } else {
        if (updatedValues.campaignValue) {
          actionRule.v = parseFloat(updatedValues.campaignValue)
        } else {
          actionRule.v = 0
        }
        if (updatedValues.campaignLimit) {
          actionRule.l = parseFloat(updatedValues.campaignLimit)
        } else {
          actionRule.l = 0
        }
        payload.slots.push(actionRule)
      }
    }

    if (updatedValues.tosModAction) {
      payload.slots.push({
        t: RULE_TYPE_TOS_MODIFIER,
        a: updatedValues.tosModAction.value,
        v: updatedValues.tosModValue ? parseFloat(updatedValues.tosModValue) : 0,
      })
    }

    if (updatedValues.productModAction) {
      payload.slots.push({
        t: RULE_TYPE_PRODUCT_MODIFIER,
        a: updatedValues.productModAction.value,
        v: updatedValues.productModValue ? parseFloat(updatedValues.productModValue) : 0,
      })
    }

    if (updatedValues.restModAction) {
      payload.slots.push({
        t: RULE_TYPE_REST_MODIFIER,
        a: updatedValues.restModAction.value,
        v: updatedValues.restModValue ? parseFloat(updatedValues.restModValue) : 0,
      })
    }

    if (updatedValues.ruleDays) {
      payload.rule_days = updatedValues.ruleDays
    }

    onChange(payload)
  }

  const handleChange = field => (value) => {
    const updatedValues = Object.assign({}, currentValues, {
      [field]: value,
    })

    doChange(updatedValues)
  }

  const handleStatusChange = async (value) => {
    if (!rule.id.toString().includes('new-')) {
      await onStatusChange(rule.campaign_id)
    }
    handleChange('status')(value)
  }

  const handleCampaignSelect = (ids) => {
    const campaign = campaigns.find(record => (
      record.id.toString() === ids[0].toString()
    ))

    const updatedValues = Object.assign({}, currentValues, {
      campaignId: campaign.id,
      campaignType: campaign.type,
    })

    doChange(updatedValues)
  }

  const handleKeywordSelect = (keywordObjects) => {
    const updatedValues = Object.assign({}, currentValues, {
      keywords: keywordObjects.map(record => record.keyword)
    })

    doChange(updatedValues)
  }

  const handleBidClear = () => {
    const updatedValues = Object.assign({}, currentValues, {
      bidAction: null,
      bidValue: null,
    })
    doChange(updatedValues)
  }

  const handleCampaignClear = () => {
    const updatedValues = Object.assign({}, currentValues, {
      campaignAction: null,
      campaignValue: null,
      campaignLimit: null,
    })
    doChange(updatedValues)
  }

  const handleModifierClear = ruleType => () => {
    let actionField
    let valueField
    if (ruleType === RULE_TYPE_TOS_MODIFIER) {
      actionField = 'tosModAction'
      valueField = 'tosModValue'
    } else if (ruleType === RULE_TYPE_PRODUCT_MODIFIER) {
      actionField = 'productModAction'
      valueField = 'productModValue'
    } else if (ruleType === RULE_TYPE_REST_MODIFIER) {
      actionField = 'restModAction'
      valueField = 'restModValue'
    }
    const updatedValues = Object.assign({}, currentValues, {
      [actionField]: null,
      [valueField]: null,
    })
    doChange(updatedValues)
  }

  const renderStatus = () => {
    return (
      <div className="metric-header">
        <Toggle
          checked={currentValues.status}
          checkedChildren="On"
          unCheckedChildren="Off"
          disabled={isUpdatingStatus}
          onChange={handleStatusChange}
        />
        <button
          type="button"
          className="btn btn-red"
          onClick={onRemove}
        >
          Remove
        </button>
      </div>
    )
  }

  const renderCampaignSource = () => {
    return (
      <Select
        options={campaignSourceList}
        placeholder="Select traffic source"
        value={currentValues.campaignSource}
        onChange={handleChange('campaignSource')}
      />
    )
  }

  const renderCampaignSelector = () => {
    return (
      <div className="campaign-selector-wrapper">
        {
          currentValues.campaignSource &&
          currentValues.campaignSource.value === CAMPAIGN_SOURCE_AMAZON && (
            <CheckboxComponent
              label="Include Sponsored Brands campaigns"
              checked={includeSb}
              onChange={setIncludeSb}
            />
          )
        }
        <CampaignSelector
          campaigns={campaigns}
          selectedCampaignIds={currentValues.campaignId ? [currentValues.campaignId.toString()] : []}
          isLoading={isLoadingCampaigns}
          isMulti={false}
          onChange={handleCampaignSelect}
        />
      </div>
    )
  }

  const renderKeywordSelector = () => {
    return (
      <KeywordSelector
        campaign={selectedCampaign}
        selectedKeywords={currentValues.keywords}
        onChange={handleKeywordSelect}
      />
    )
  }

  const renderRankCriteria = () => {
    return (
      <div className="rank-criteria-container">
        <label>Rank falls below</label>
        <InputNumber
          min={0}
          placeholder="Set value"
          value={currentValues.rankLimit}
          onChange={handleChange('rankLimit')}
        />
        <label>for</label>
        <InputNumber
          min={0}
          placeholder="No. of days"
          value={currentValues.rankDays}
          onChange={handleChange('rankDays')}
        />
        <label>days</label>
      </div>
    )
  }

  const renderBidRule = () => {
    return (
      <BidRuleSection
        isReadOnly={false}
        action={currentValues.bidAction}
        value={currentValues.bidValue}
        onActionChange={handleChange('bidAction')}
        onValueChange={handleChange('bidValue')}
        onClear={handleBidClear}
      />
    )
  }

  const renderCampaignRule = () => {
    return (
      <CampaignRuleSection
        isReadOnly={false}
        action={currentValues.campaignAction}
        value={currentValues.campaignValue}
        limit={currentValues.campaignLimit}
        onActionChange={handleChange('campaignAction')}
        onValueChange={handleChange('campaignValue')}
        onLimitChange={handleChange('campaignLimit')}
        onClear={handleCampaignClear}
      />
    )
  }

  const renderModifierRule = () => {
    return (
      <>
        <ModifierRuleSection
          name="Top of Search modifier"
          isReadOnly={false}
          action={currentValues.tosModAction}
          value={currentValues.tosModValue}
          onActionChange={handleChange('tosModAction')}
          onValueChange={handleChange('tosModValue')}
          onClear={handleModifierClear(RULE_TYPE_TOS_MODIFIER)}
        />
        <ModifierRuleSection
          name="Product Pages modifier"
          isReadOnly={false}
          action={currentValues.productModAction}
          value={currentValues.productModValue}
          onActionChange={handleChange('productModAction')}
          onValueChange={handleChange('productModValue')}
          onClear={handleModifierClear(RULE_TYPE_PRODUCT_MODIFIER)}
        />
        <ModifierRuleSection
          name="Rest of the Search modifier"
          isReadOnly={false}
          action={currentValues.restModAction}
          value={currentValues.restModValue}
          onActionChange={handleChange('restModAction')}
          onValueChange={handleChange('restModValue')}
          onClear={handleModifierClear(RULE_TYPE_REST_MODIFIER)}
        />
      </>
    )
  }

  const renderRuleDays = () => {
    return (
      <div className="rule-days-container">
        <label>Apply rules until keyword(s) remain at that rank for</label>
        <InputNumber
          min={0}
          placeholder="No. of days"
          value={currentValues.ruleDays}
          onChange={handleChange('ruleDays')}
        />
        <label>days</label>
      </div>
    )
  }

  return (
    <div className="rule-metric-container">
      { renderStatus() }
      { renderCampaignSource() }
      { renderCampaignSelector() }
      { renderKeywordSelector() }
      { renderRankCriteria() }
      { renderBidRule() }
      { renderCampaignRule() }
      { currentValues.campaignType === 'sp' && renderModifierRule() }
      { renderRuleDays() }
    </div>
  )
}

export default RuleMetric
