import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import ComparisonView from '../../components/ComparisonView'

import { getSummary } from '../../redux/actions/dashboard'
import {
  changeHighlightWidgetState,
  changeViewWidgetState,
} from '../../redux/actions/pageGlobal'

import { COMPARISON_VIEW_TYPE_MONTHLY } from '../../utils/defaultValues'

const ComparisonViewSection = ({ widget, customizing = false, onRemove }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [highlighted, setHighlighted] = useState(widget.highlighted)
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState([])

  useEffect(() => {
    if (currentUserId && !isLoading) {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsLoading(true)
        const data = await dispatch(getSummary(accessToken, COMPARISON_VIEW_TYPE_MONTHLY))
        setSummary(data)
        setIsLoading(false)
      })()
    }
  }, [currentUserId]) // eslint-disable-line

  useEffect(() => {
    const sectionWidget = reportWidgetSetting['allSponsoredAds'].find((w) => w.key === widget.key)
    setHighlighted(sectionWidget.highlighted)
  }, [reportWidgetSetting]) //eslint-disable-line

  const handleClickHighlightWidget = () => {
    dispatch(changeHighlightWidgetState('allSponsoredAds', widget.key))
  }

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState('allSponsoredAds', widget.key))
    onRemove(widget.key)
  }

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          <IconButton
            icon={<Icon icon="star" />}
            size="xs"
            className={`highlight-report-widget ${highlighted ? 'active' : ''}`}
            circle
            onClick={handleClickHighlightWidget}
          />
          <span>
            { widget.title }
          </span>
        </div>
        <div className="widget-action">
          {
            customizing && (
              <IconButton
                icon={<Icon icon="check" />}
                color={widget.view ? 'green' : 'violet'}
                circle
                onClick={handleClickHideWidget}
              />
            )
          }
        </div>
      </div>
      <div className="report-widget-content">
        <ComparisonView
          isLoading={isLoading}
          summary={summary}
        />
      </div>
    </div>
  )
}

export default ComparisonViewSection
