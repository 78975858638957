import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'
import Select from 'react-select'

import CustomTable from '../CommonComponents/CustomTableComponent'
import VideoLink from '../CommonComponents/VideoLink'

const bidStrategyList = [
  {
    value: 'legacyForSales',
    label: 'Dynamic bids - down only',
  },
  {
    value: 'autoForSales',
    label: 'Dynamic bids - up and down',
  },
  {
    value: 'manual',
    label: 'Fixed bids',
  },
]

const videoList = [
  { title: 'Bids and Placements', url: 'https://www.loom.com/embed/436b965991be4c5a9edfe80b61b402f1' },
]

const UpdateBiddingsModal = ({ campaigns, onUpdate, onClose }) => {
  const [selectedCampaigns, setSelectedCampaigns ] = useState([])
  const [allBidStrategy, setAllBidStrategy] = useState(bidStrategyList[0])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const campaignList = campaigns.map((campaign) => {
      const bidding = typeof campaign.bidding === 'string'
        ? JSON.parse(campaign.bidding)
        : campaign.bidding

      const strategy = bidStrategyList.find(option => (
        bidding.strategy === option.value
      ))

      return {
        campaign_id: campaign.campaign_id,
        name: campaign.campaign,
        strategy: strategy || bidStrategyList[2],
        adjustments: bidding.adjustments,
      }
    })

    setSelectedCampaigns(campaignList)
  }, [campaigns]) // eslint-disable-line

  const handleStrategyChange = (campaignId, option) => {
    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      if (campaign.campaign_id === campaignId) {
        campaign.strategy = option
      }
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleApplyAll = () => {
    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      campaign.strategy = allBidStrategy
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleSave = async () => {
    setIsSaving(true)
    await onUpdate(selectedCampaigns.map(campaign => ({
      campaign_id: campaign.campaign_id,
      strategy: campaign.strategy.value,
      adjustments: campaign.adjustments,
    })))
    setIsSaving(false)
    onClose()
  }

  const renderActions = () => (
    <>
      <Select
        options={bidStrategyList}
        value={allBidStrategy}
        onChange={(option) => { setAllBidStrategy(option) }}
      />
      <button
        type="button"
        className="btn btn-green"
        onClick={() => handleApplyAll()}
      >
        Apply All
      </button>
    </>
  )

  const renderCampaign = record => (
    <>
      <div className="table-col" title={record.name}>
        <div className="campaign-name">
          { record.name }
        </div>
      </div>
      <div className="table-col">
        <Select
          options={bidStrategyList}
          value={record.strategy}
          onChange={(option) => { handleStrategyChange(record.campaign_id, option) }}
        />
      </div>
    </>
  )

  return (
    <Modal className="update-biddings-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Update Bidding Strategy (SP Only)
          <VideoLink
            videoList={videoList}
            modalTitle="Bids and Placements"
            linkName=''
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          isLoading={isSaving}
          records={selectedCampaigns}
          idField="campaign_id"
          noCheckBox
          searchFields={['name']}
          renderRecord={renderCampaign}
          renderTopRight={renderActions}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col">Bid Strategy</div>
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          disabled={isSaving}
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateBiddingsModal
