// Smart pilot manager.
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'
import { format, formatISO } from 'date-fns'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import Header from './Header'
import Footer from './Footer'
import OpSection from './OpSection'
import ExSection from './ExSection'
import CampaignSelector from './CampaignSelector'

import { hideRuleManager } from '../../redux/actions/ruleManager'
import { getAp, getApAdgroup, saveAp, saveApMultiple,
  saveSpTemplate, turnRule, getSpTemplate } from '../../redux/actions/ap'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'
import {
  getDefaultAPSettings,
  parseAPSettings,
  apSettings as apSettingsTemplate,
  ignoreOutsideClick,
} from '../../services/helper'

const TAB_OP = 'op'
const TAB_EX = 'ex'

const tabList = [
  { value: TAB_OP, label: 'Optimization' },
  { value: TAB_EX, label: 'Expansion' },
]

const APMComponent = () => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)
  const campaignId = useSelector(state => state.ap.campaignId)
  const adgroupId = useSelector(state => state.ap.adgroupId)
  const isLoading = useSelector(state => state.ap.isLoading)
  const campaign = useSelector(state => state.ap.campaign)

  const { getAccessTokenSilently } = useAuth0()

  // Current smart pilot settings.
  const [settings, setSettings] = useState(getDefaultAPSettings())
  const [templateSettings, setTemplateSettings] = useState({})

  // Switch between Optimization and Expansion tabs.
  const [activeTab, setActiveTab] = useState(TAB_OP)

  // Whether to display a campaign selector to choose campaigns
  // to apply smart pilot settings.
  const [isCampaignSelectorVisible, setCampaignSelectorVisible] = useState(false)

  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  // Load smart pilot settings.
  useEffect(() => {
    let abortCtrl
    // TODO: If we have a campaign basic information stored in Redux,
    // we can re-use it, instead of running another DB query.
    if (campaignId) {
      abortCtrl = new AbortController();

      (async () => {
        const accessToken = await getAccessTokenSilently()
        await dispatch(getAp(accessToken, campaignId, abortCtrl.signal))

        if (adgroupId) {
          dispatch(getApAdgroup(
            accessToken,
            campaignId,
            adgroupId,
            abortCtrl.signal,
          ))
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, []) // eslint-disable-line

  // Update local state with loaded settings.
  useEffect(() => {
    if (campaign) {
      if (campaign.ap) {
        setSettings(campaign.ap)
      } else {
        let targetAcos
        if (campaign.basic.length && campaign.basic[0].target_acos) {
          targetAcos = parseFloat(campaign.basic[0].target_acos)
        } else {
          targetAcos = currentAccount?.average_acos || 30
        }
        setSettings(getDefaultAPSettings(null, targetAcos))
      }
      if (campaign.template) {
        setTemplateSettings(campaign.template)
      } else {
        setTemplateSettings({})
      }
    }
  }, [campaign]) // eslint-disable-line

  // Change a setting value.
  const onChange = (name, value) => {
    setSettings(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  // Hide APM pane.
  const onClose = () => {
    dispatch(hideRuleManager())
  }

  const onOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      onClose()
    }
  }

  // When selecting an ad group from selector.
  const onAdgroupSelect = async (adgroupIdToSelect, toSave) => {
    if (toSave) {
      onSave()
    }

    const accessToken = await getAccessTokenSilently()
    if (adgroupIdToSelect) {
      dispatch(getApAdgroup(
        accessToken,
        campaignId,
        adgroupIdToSelect,
      ))
    } else {
      dispatch(getAp(accessToken, campaignId))
    }
  }

  // When clicking on `Apply to multiple campaigns` button.
  const onApplyToMultiple = () => {
    setCampaignSelectorVisible(true)
  }

  // Sanitize settings before saving.
  const _sanitizeSettings = () => {
    setSaveError(null)

    if (parseFloat(settings['op_adv_genius_bid_min_acos'])
      > parseFloat(settings['op_adv_genius_bid_max_acos'])) {
      setSaveError('Please enter a valid ACoS range for Unprofitable Targets.')
      return null
    }

    if (parseFloat(settings['copy_op_adv_genius_bid_min_acos'])
      > parseFloat(settings['copy_op_adv_genius_bid_max_acos'])) {
      setSaveError('Please enter a valid ACoS range for Unprofitable Targets.')
      return null
    }

    const payload = Object.assign({}, settings, {
      user_id: currentAccount.user_id,
      campaign_id: campaignId,
      campaign_name: campaign?.basic[0]?.name,
      time_zone: format(new Date(), 'xxx'),
    })

    Object.keys(apSettingsTemplate).forEach((setting) => {
      if (apSettingsTemplate[setting].type === 'json_array') {
        // TODO: Do we need to escape values to be query-safe?
        // `filter` method here removes NULL values.
        payload[setting] = JSON.stringify(settings[setting].filter(s => s === 0 || s))
      } else if (apSettingsTemplate[setting].type === 'concat') {
        payload[setting] = settings[setting].join(',')
      } else if (apSettingsTemplate[setting].type === 'date') {
        payload[setting] = settings[setting]
          ? formatISO(settings[setting]) : settings[setting]
      } else if (apSettingsTemplate[setting].type === 'int') {
        const value = parseInt(settings[setting], 10)
        if (!isNaN(value)) {
          payload[setting] = value
        } else {
          payload[setting] = apSettingsTemplate[setting].default || 0
        }
      }
    })

    return payload
  }

  // Save smart pilot settings for a given campaign and ad group.
  const onSave = async () => {
    const payload = _sanitizeSettings()
    if (payload) {
      setIsSaving(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(saveAp(accessToken, payload))
      setIsSaving(false)
      onChange('is_active', true)
    }
  }

  // Apply to multiple campaigns.
  const onApply = async (campaignIds) => {
    const payload = _sanitizeSettings()
    if (payload) {
      payload.campaignIds = campaignIds
      setIsSaving(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(saveApMultiple(accessToken, payload))
      setIsSaving(false)
      if (campaignIds.indexOf(campaignId.toString()) !== -1) {
        onChange('is_active', true)
      }
    }
  }

  // Save settings as template.
  const onSaveTemplate = async (name, needApply) => {
    const payload = _sanitizeSettings()
    if (payload) {
      setIsSaving(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(saveSpTemplate(accessToken, name, needApply, payload))
      setIsSaving(false)
    }
  }

  // Turn smart pilot on/off
  const onTurnOnOff = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(turnRule(
      accessToken,
      campaignId,
      settings.adgroup_id,
      !!(!settings.is_active),
    ))
    setIsSaving(false)
    if (response) {
      onChange('is_active', !settings.is_active)
    }
  }

  // Select a template to apply (not yet saved to database)
  const onSelectTemplate = async (templateId) => {
    onChange('ap_template_id', templateId)

    if (templateId) {
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSpTemplate(accessToken, templateId))
      setTemplateSettings(parseAPSettings(response.data))
    } else {
      setTemplateSettings({})
    }
  }

  const renderBody = () => {
    if (!isCampaignSelectorVisible) {
      return (
        <>
          <div className="pane-body">
            {
              activeTab === TAB_OP &&
              <OpSection
                campaign={campaign}
                settings={Object.assign({}, settings, templateSettings)}
                isNonEndemicAccount={isNonEndemicAccount}
                onChange={onChange}
              />
            }
            {
              activeTab === TAB_EX &&
              <ExSection
                campaign={campaign}
                settings={Object.assign({}, settings, templateSettings)}
                onChange={onChange}
              />
            }
          </div>
          <Footer
            settings={settings}
            isLoading={isLoading}
            isSaving={isSaving}
            saveError={saveError}
            onApplyToMultiple={onApplyToMultiple}
            onSave={onSave}
            onSaveTemplate={onSaveTemplate}
            onClose={onClose}
          />
        </>
      )
    }

    return (
      <CampaignSelector
        isSaving={isSaving}
        saveError={saveError}
        onApply={onApply}
        onCancel={() => { setCampaignSelectorVisible(false) }}
      />
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className="apm-component">
        { (isLoading || isSaving) && <LoaderComponent className="sp-loader" /> }
        <Header
          campaign={campaign}
          settings={settings}
          isCampaignSelectorVisible={isCampaignSelectorVisible}
          tabList={tabList}
          activeTab={activeTab}
          isNonEndemicAccount={isNonEndemicAccount}
          onSetActiveTab={setActiveTab}
          onAdgroupSelect={onAdgroupSelect}
          onTurnOnOff={onTurnOnOff}
          onSelectTemplate={onSelectTemplate}
          onClose={onClose}
        />
        { renderBody() }
      </div>
    </OutsideClickHandler>
  )
}

export default APMComponent
