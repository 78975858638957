import React from 'react'

const OpPlacement = ({ details }) => {
  if (!details.placement_active) {
    return null
  }

  return (
    <div className="setting-subsection">
      <div className="subsection-name">
        Placement Optimization
      </div>
      <div className="setting-wrapper">
        Placement Optimization:
        <span className="setting-value">On</span>
      </div>
      <div className="setting-wrapper">
        Maximum Bid Adjustment:
        <span className="setting-value">
          { parseFloat(details.placement_max_change).toFixed(1) }%
        </span>
      </div>
    </div>
  )
}

export default OpPlacement
