import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { differenceInDays, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { scheduleCall } from '../../redux/actions/tutorial'

import { ReactComponent as CallSvg } from '../../assets/svg/call.svg'

const CALL_LIMIT = 14 // days since sign up.

const CallComponent = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.auth.user)
  const { getAccessTokenSilently } = useAuth0()

  const createdTime = utcToZonedTime(parseISO(currentUser.created_at), 'UTC')

  useEffect(function loadVideoAsk() {
    if (!currentUser.created_at || differenceInDays(new Date(), createdTime) < CALL_LIMIT) {
      const config = {
        kind: 'widget',
        url: 'https://www.videoask.com/f6701rfsr',
        options: {
          widgetType: 'VideoThumbnailWindowTall',
          text: 'Schedule Your Fast Track Call!',
          backgroundColor: '#072257',
          position: 'bottom-right',
          dismissible: true
        }
      }

      if (typeof window.videoask !== 'undefined' && !localStorage.getItem('hasSeenVideoAskWidget')) {
        window.videoask.loadEmbed(config, {
          onCloseWidget: () => {
            localStorage.setItem('hasSeenVideoAskWidget', true)
          }
        })
      }
    }
  }, []); // eslint-disable-line

  const handleScheduleClick = async () => {
    const accessToken = await getAccessTokenSilently()
    dispatch(scheduleCall(accessToken))
  }

  if (!currentUser.created_at || differenceInDays(new Date(), createdTime) >= CALL_LIMIT) {
    return null
  }

  return (
    <div className="call-component">
      <div className="description-box">
        <CallSvg />
        <div className="call-text">
          <div className="call-name">
            Introductory Call With Entourage Expert
          </div>
          <div className="call-description">
            Learn how to slash ACOS and scale faster using Entourage.
          </div>
        </div>
      </div>
      <a
        href="https://www.videoask.com/f6701rfsr"
        rel="noopener noreferrer"
        target="_blank"
        onClick={handleScheduleClick}
      >
        Schedule a call
      </a>
    </div>
  )
}

export default CallComponent