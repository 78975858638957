import React, { useState } from 'react'

import CustomTable from '../../CommonComponents/CustomTableComponent'

import AudienceModal from '../../CampaignCreator/Shared/AudienceModal'

const AudienceSection = ({ targetings, onChange }) => {
  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const handleBidChange = (event, target) => {
    onChange(targetings.map((item) => {
      if (item.id.toString() === target.id.toString()
        && item.audienceType === target.audienceType) {
        return {
          ...item,
          bid: event.target.value,
        }
      }
      return item
    }))
  }

  const handleRemove = (target) => {
    onChange(targetings.filter(item => {
      if (target.type !== item.type && item.audienceType === target.audienceType) {
        return true
      }
      return target.id.toString() !== item.id.toString()
    }))
  }

  const getTargetingName = (target) => {
    if (target.type === 'audience_category' || target.type === 'audience_refine') {
      return `Category: ${target.name}`
    }
    if (target.type === 'audience_product') {
      return target.name
    }
    if (target.type === 'audience') {
      return `Audiences: ${target.name}`
    }
  }

  const handleDefaultBidApply = () => {
    let targets = targetings.map((targeting) => {
      targeting.bid = defaultBid
      return targeting
    })
    onChange(targets)
  }

  const renderAction = () => (
    <>
      <label>Default Bid</label>
      <input
        type="text"
        value={defaultBid}
        onChange={(event) => { setDefaultBid(event.target.value) }}
      />
      <button
        type="button"
        className="btn btn-blue"
        onClick={handleDefaultBidApply}
      >
        Apply
      </button>
    </>
  )

  const renderTarget = record => (
    <>
      <div className="table-col col-target">
        { getTargetingName(record) }
        {
          record.type !== 'audience' && (
            <div className="targeting-meta">
              Lookback: {record.lookback ? record.lookback.value : 30} days
            </div>
          )
        }
        {
          record.type === 'audience_refine' && (
            <div className="targeting-meta">
              Brand: { record.brandName }
            </div>
          )
        }
      </div>
      <div className="table-col">
        <input
          type="number"
          value={record.bid}
          onChange={(event) => { handleBidChange(event, record) }}
        />
      </div>
      <div className="table-col col-action">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => { handleRemove(record) }}
        >
          Remove
        </button>
      </div>
    </>
  )

  return (
    <div className="section-container">
      <div className="section-title">
        Audiences
        <div className="button-container">
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { setOpenModal(true) }}
          >
            Choose Audience
          </button>
          {
            targetings.length > 0 && (
              <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
                Remove All
              </button>
            )
          }
        </div>
      </div>
      <div className="section-note">
        When adding Audiences as targets to multiple campaigns,
        only Audience targeting enabled ad groups will be available
        for selection on the next screen.
      </div>
      <CustomTable
        className="table-audiences"
        records={targetings}
        idField="id"
        noCheckBox
        searchFields={['name']}
        noRecordText="No targeting added."
        renderTopRight={renderAction}
        renderRecord={renderTarget}
      >
        <div className="table-col">Target</div>
        <div className="table-col">Bid</div>
        <div className="table-col"></div>
      </CustomTable>
      <AudienceModal
        show={openModal}
        defaultBid={defaultBid}
        targetings={targetings}
        onChange={onChange}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default AudienceSection
