import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import Select from 'react-select'
import * as Icon from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import { toast } from '../CommonComponents/ToastComponent/toast'

import {
  archiveNegativeTargets,
  getAsinImages,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  tableSorter,
  capitalizeFirstLetter,
  parseTargetExp,
  getAmazonAsinLink,
  groupRecords,
  copyToClipboard,
} from '../../services/helper'
import { campaignTypes, targetingTypes } from '../../utils/filterDef'
import { JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS } from '../../utils/defaultValues'

const columns = [
  { key: 'target', name: 'Target', className: 'col-target' },
  { key: 'targetingType', name: 'Type' },
  { key: 'level', name: 'Level' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const columnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'childCount', name: 'Number of Negatives', parentOnly: true },
  { key: 'target', name: 'Target', className: 'col-target' },
  { key: 'targetingType', name: 'Type' },
  { key: 'level', name: 'Level' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const AdvancedNegativeTargetTable = ({ campaignsById, adgroupNamesById }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)

  const advancedNegativeData = useSelector(state => state.bulkEngine.advancedNegativeData)

  const [groupMode, setGroupMode] = useState(false)
  const [targets, setTargets] = useState([])
  const [groupedTargets, setGroupedTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [selectedAdType, setSelectedAdType] = useState(campaignTypes[0])
  const [selectedTargetingType, setSelectedTargetingType] = useState(targetingTypes[0])
  const [isArchiving, setIsArchiving] = useState(false)
  const [isGettingImages, setIsGettingImages] = useState(false)

  useEffect(() => {
    // Remove duplicate entries.
    const records =  [...new Map(
      ((advancedNegativeData || {}).negativeTargets || [])
      .map(item => [item.target_id, item]))
      .values()
    ]

    const extended = [];
    records.forEach((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}

      const campaignType = campaignDetail.type || ''
      const negativeTargetingType = capitalizeFirstLetter(record.targeting_type || 'Manual')

      if (selectedAdType.value !== '' && campaignType.toLowerCase() !== selectedAdType.value) {
        return
      }

      if (selectedTargetingType.value !== '') {
        if (negativeTargetingType.toLowerCase() !== selectedTargetingType.value) {
          return
        }
      }

      const target = parseTargetExp(record.expression)
      let asin = ''
      if (target.indexOf('asin=') === 0) {
        try {
          const parsed = JSON.parse(record.expression)
          asin = parsed[0].value
        } catch (e) {
          //
        }
      }
      extended.push({
        ...record,
        campaignName: campaignDetail.name || '',
        campaignType,
        targetingType: campaignDetail.targetingType || '',
        adgroupName: record.adgroup_id
          ? adgroupNamesById[record.adgroup_id] || '' : '',
        target,
        asin,
        negativeTargetingType,
        level: record.adgroup_id ? 'Ad group level' : 'Campaign level',
      })
    })

    setTargets(extended)
  }, [advancedNegativeData, campaignsById,
    adgroupNamesById, selectedAdType, selectedTargetingType])

  useEffect(() => {
    if (groupMode) {
      setGroupedTargets(
        groupRecords(
          targets,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedTargets([])
    }
  }, [targets, groupMode])

  const handleCopy = () => {
    const dataToCopy = targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).map(record => record.target.trim())

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} target${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const handleArchive = async () => {
    const negatives = targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    ))

    if (!negatives.length) {
      return
    }

    setIsArchiving(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(archiveNegativeTargets(
      accessToken,
      negatives,
    ))
    setIsArchiving(false)

    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS,
      ))
    }
  }

  const handleAsinImageFetch = async () => {
    const asins = [];
    (targets || []).forEach((record) => {
      if (record.asin !== '') {
        asins.push(record.asin.toUpperCase())
      }
    })

    if (asins.length) {
      setIsGettingImages(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAsinImages(accessToken, asins, 'advancedNegative'))
      setIsGettingImages(false)
    }
  }

  const renderFilter = () => {
    const hasAsin = typeof (targets || []).find(record => (
      record.asin !== ''
    )) !== 'undefined'

    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By targets"
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Ad Type</span>
          <Select
            classNamePrefix="ad-type-selector"
            options={campaignTypes}
            value={selectedAdType}
            onChange={setSelectedAdType}
          />
        </div>
        <div className="select-wrapper">
          <span>Targeting Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={targetingTypes}
            value={selectedTargetingType}
            onChange={setSelectedTargetingType}
          />
        </div>
        {
          hasAsin && (
            <div className="button-wrapper">
              <button
                type="button"
                className="btn btn-blue"
                disabled={isGettingImages}
                onClick={handleAsinImageFetch}
              >
                Show ASIN Images
              </button>
            </div>
          )
        }
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedTargets.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-green"
          onClick={handleCopy}
        >
          Copy
        </button>
        <button
          type="button"
          className="btn btn-red"
          disabled={isArchiving}
          onClick={() => { handleArchive() }}
        >
          Remove Negative{selectedTargets.length > 1 ? 's' : ''}
        </button>
      </>
    )
  }

  const renderTargetCell = record => (
    <div className="table-col col-target" title={record.target}>
      {
        typeof record.image !== 'undefined' && (
          <img
            src={record.image}
            alt={record.target}
          />
        )
      }
      <div className="target-info">
        <strong className="contents">
          { record.target }
        </strong>
        {
          record.asin !== '' && (
            <a
              href={getAmazonAsinLink(currentAccount, record.asin)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon.FiExternalLink size={16} />
            </a>
          )
        }
      </div>
    </div>
  )

  const renderTarget = record => (
    <>
      { renderTargetCell(record) }
      <div className="table-col">
        { record.negativeTargetingType }
      </div>
      <div className="table-col">
        { record.level }
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
    </>
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col">
        { record.children.length }
      </div>
    </>
  )

  const renderChild = record => (
    <>
      { renderTargetCell(record) }
      <div className="table-col">
        { record.negativeTargetingType }
      </div>
      <div className="table-col">
        { record.level }
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
    </>
  )

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            isLoading={isArchiving || isGettingImages}
            columns={columnsGroup}
            defaultSort={['target', 'asc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-negative-targets"
            records={groupedTargets}
            idField="campaign_id"
            searchFields={['target']}
            selectedRecords={selectedTargets}
            noSearch
            hasSticky
            renderRecord={renderParent}
            renderTopRight={renderAction}
            onChange={setSelectedTargets}
            showParentColumnsOnly
            hasSearchChild
            sorterChild={tableSorter(['adgroupName', 'level', 'target', 'targetingType'])}
            idFieldChild="target_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isArchiving || isGettingImages}
            columns={columns}
            defaultSort={['target', 'asc']}
            sorter={tableSorter(['campaignName', 'adgroupName', 'level', 'target', 'targetingType'])}
            className="table-negative-targets"
            records={targets || []}
            idField="target_id"
            searchFields={['target']}
            selectedRecords={selectedTargets}
            hasSticky
            renderRecord={renderTarget}
            renderTopRight={renderAction}
            onChange={setSelectedTargets}
          />
        )
      }
    </>
  )
}

export default AdvancedNegativeTargetTable
