import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { format, parseISO } from 'date-fns'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import ConfirmModal from '../CommonComponents/ConfirmModal'
import PortfolioBudgetModal from './PortfolioBudgetModal'

import { getPortfoliosWithCampaigns } from '../../redux/actions/portfolio'
import { updatePortfolios } from '../../redux/actions/campaignDetail'
import { portfolioColumnList } from '../../utils/defaultValues'

import {
  calcDerivedMetrics,
  formatValue,
  tableSorter,
  getExportValueForColumn,
} from '../../services/helper'

const initialColumns = [
  { key: 'name', name: 'Portfolio', className: 'col-portfolio', parentOnly: true },
  { key: 'budget', name: 'Budget Setting', className: 'col-budget', parentOnly: true, sortable: false },
  { key: 'action', name: '', className: 'col-action' },
  { key: 'campaign', name: 'Campaigns', className: 'col-campaign' },
]

const PortfolioTableComponent = ({ startDate, endDate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const portfolioTableColumns = useSelector(state => state.pageGlobal.portfolioTableColumns)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const isUpdatingPortfolio = useSelector(state => state.campaignDetail.isUpdatingPortfolio)

  const [isLoading, setIsLoading] = useState(false)
  const [portfolios, setPortfolios] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [removeCampaign, setRemoveCampaign] = useState(null)
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState([])
  const [portfoliosToUpdate, setPortfoliosToUpdate] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController()
    loadPortfolios(abortCtrl.signal)

    return () => {
      abortCtrl.abort()
    }
  }, [currentStartDate, currentEndDate, startDate, endDate]) // eslint-disable-line

  const [columns, columnSelection] = useMemo(() => {
    const availableColumns = portfolioTableColumns.filter(c => c.key !== 'portfolio')
    return [
      [...initialColumns, ...availableColumns],
      availableColumns,
    ]
  }, [portfolioTableColumns])

  const loadPortfolios = async (signal = undefined) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getPortfoliosWithCampaigns(
      accessToken,
      startDate || currentStartDate,
      endDate || currentEndDate,
      signal,
    ))
    setIsLoading(false)
    setPortfolios(response.map((portfolio) => {
      let daily_budget = 0
      let cost = 0
      let revenue = 0
      let impressions = 0
      let clicks = 0
      let orders = 0
      let ntb_orders = 0
      let ntb_sales = 0

      portfolio.campaigns.forEach((campaign) => {
        daily_budget += campaign.daily_budget
        cost += campaign.cost
        revenue += campaign.revenue
        impressions += campaign.impressions
        clicks += campaign.clicks
        orders += campaign.orders
        ntb_orders += campaign.ntb_orders
        ntb_sales += campaign.ntb_sales
      })

      return calcDerivedMetrics({
        ...portfolio,
        daily_budget,
        cost,
        revenue,
        impressions,
        clicks,
        orders,
        ntb_orders,
        ntb_sales,
        children: portfolio.campaigns.map(c => ({
          ...c,
          name: portfolio.name, // For search
        })),
      })
    }))
  }

  const handleRemoveCampaign = (campaign) => {
    setRemoveCampaign(() => ({...campaign}))
    setConfirmText(`Are you sure want to remove this campaign (${campaign.campaign}) from the portfolio: "${campaign.name}"?`)
    setShowConfirmModal(true)
  }

  const handleRemoveConfirm = async (isConfirmed) => {
    if (isConfirmed) {
      const accessToken = await getAccessTokenSilently()
      await dispatch(updatePortfolios(
        accessToken,
        [{
          campaignId: removeCampaign.campaign_id,
          campaignType: removeCampaign.campaignType,
        }],
      ))
      loadPortfolios()
    }
    setShowConfirmModal(false)
  }

  const handleBudgetsUpdate = () => {
    const selected = portfolios.filter(portfolio => (
      selectedPortfolioIds.includes(portfolio.portfolio_id)
    ))

    setPortfoliosToUpdate(selected)
  }

  const handleCloseBudgetModal = (success = false) => {
    setPortfoliosToUpdate([])
    if (success) {
      loadPortfolios()
    }
  }

  const renderActions = () => {
    if (!selectedPortfolioIds.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={handleBudgetsUpdate}
        >
          Update Budgets
        </button>
      </>
    )
  }

  const renderBudget = (budget) => {
    if (!budget) {
      return null
    }

    let parsed
    try {
      parsed = JSON.parse(budget)
    } catch {
      return null
    }

    let dateRange
    if (parsed.policy === 'dateRange') {
      dateRange = format(parseISO(parsed.startDate), 'MM/dd/yyyy')

      if (parsed.endDate) {
        dateRange = `${dateRange} - ${format(parseISO(parsed.endDate), 'MM/dd/yyyy')}`
      }
    } else if (parsed.policy === 'MonthlyRecurring') {
      dateRange = 'Monthly'

      if (parsed.endDate) {
        dateRange = `${dateRange}: ${format(parseISO(parsed.endDate), 'MM/dd/yyyy')}`
      }
    } else if (parsed.policy === 'daily') {
      dateRange = `Daily: ${format(parseISO(parsed.startDate), 'MM/dd/yyyy')}`

      if (parsed.endDate) {
        dateRange = `${dateRange} - ${format(parseISO(parsed.endDate), 'MM/dd/yyyy')}`
      }
    }

    return (
      <span className="budget-setting-contents">
        { formatValue(parsed.amount, 'number') }
        &nbsp;
        { parsed.currencyCode }
        &nbsp;
        { dateRange }
      </span>
    )
  }

  const renderPortfolio = record => (
    <>
      <div className="table-col col-portfolio">
        { record.name }
      </div>
      <div className="table-col col-budget">
        { renderBudget(record.budget) }
      </div>
      <div className="table-col col-action" />
      <div className="table-col col-campaign">
        { record.children.length }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderCampaign = record => (
    <>
      <div className="table-col">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => handleRemoveCampaign(record)}
        >
          Remove
        </button>
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotal = record => (
    <>
      <div className="table-col col-portfolio">
        Totals:
      </div>
      <div className="table-col col-budget"/>
      <div className="table-col col-action" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        tableComponent={GroupTable}
        isLoading={isLoading || isUpdatingPortfolio}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['name'])}
        className="table-portfolio"
        records={portfolios}
        idField="portfolio_id"
        searchFields={['name']}
        noCheckBox
        hasSticky
        columnEditorId="portfolioTable"
        columnList={portfolioColumnList}
        columnSelection={columnSelection}
        columnEditorNoReset={false}
        getExportData={getExportData}
        exportFileName="Portfolios"
        renderRecord={renderPortfolio}
        renderTotal={renderTotal}
        renderTopRight={renderActions}
        sorterChild={tableSorter(['campaign'])}
        idFieldChild="campaign_id"
        renderChild={renderCampaign}
        extend
        noCheckBoxParent={false}
        selectedRecordsParent={selectedPortfolioIds}
        onChangeParent={setSelectedPortfolioIds}
      />
      <ConfirmModal
        show={showConfirmModal}
        onConfirm={handleRemoveConfirm}
        text={confirmText}
      />
      {
        portfoliosToUpdate.length > 0 && (
          <PortfolioBudgetModal
            portfolios={portfoliosToUpdate}
            onClose={handleCloseBudgetModal}
          />
        )
      }
    </>
  )
}

export default PortfolioTableComponent
