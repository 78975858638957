import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import {
  GET_CAMPAIGNS_WITH_HISTORY_SUCCESS,
  GET_CAMPAIGNS_DETAIL,
  GET_CAMPAIGNS_HISTORY,
  UPDATE_CAMPAIGN_STATE_SUCCEED,
  UPDATE_CAMPAIGN_NAMES_SUCCEED,
  UPDATE_BIDDINGS_SUCCEED,
  UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED,
  UPDATE_CAMPAIGNS_TAGS_SUCCEED,
  GET_CAMPAIGNS_WITH_TAG_SUCCESS,
  UPDATE_BULK_DAILY_BUDGET_SUCCEED,
  ADD_BULK_NOTES_SUCCEED,
  DELETE_CAMPAIGN_NOTE_SUCCEED,
  SELECT_CAMPAIGNS,
  SELECT_AD_TYPE_FILTER,
} from '../actionTypes/campaign'

// Retrieve campaigns with history.
export const getCampaignsWithHistory = (accessToken, startDate, endDate, signal = undefined) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/campaign/getCampaigns', accessToken, {
    userId: currentUserId,
    startDate,
    endDate,
  }, signal).then((response) => {
    dispatch({
      type: GET_CAMPAIGNS_WITH_HISTORY_SUCCESS,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get campaigns.',
      })
    }
  })
}

export const getCampaignsDetail = (accessToken, campaignIds) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()
  return callPost('/campaign/getCampaignsDetail', {
    userId: currentUserId,
    campaignIds,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    dispatch({
      type: GET_CAMPAIGNS_DETAIL,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get campaigns details.',
    })
  })
}

export const getCampaignsHistory = (accessToken, campaignIds) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()
  return callPost('/campaign/getCampaignsHistory', {
    userId: currentUserId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    campaignIds,
  }, accessToken).then((response) => {
    dispatch({
      type: GET_CAMPAIGNS_HISTORY,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get campaigns history.',
    })
  })
}

// Update campaign state.
export const updateCampaignsState = (accessToken, campaigns, state, immediate) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/updateStates', {
    userId: currentUserId,
    campaigns,
    state,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processCampaignStatesUpdate(response.data, state))
    }
    return response
  })
}

export const processCampaignStatesUpdate = (response, state) => (dispatch) => {
  if (response.errors.length) {
    toast.show({
      title: 'Danger',
      description: 'Failed to update campaign states.<br />'
        + response.errors.join('<br />'),
    })
  } else {
    toast.show({
      title: 'Success',
      description: 'Campaign states have been successfully updated.'
    })
  }

  dispatch({
    type: UPDATE_CAMPAIGN_STATE_SUCCEED,
    data: {
      idsUpdated: response.idsUpdated,
      state,
    },
  })
}

export const updateCampaignNames = (accessToken, campaigns, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/updateNames', {
    userId: currentUserId,
    campaigns,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processCampaignNamesUpdate(response.data, campaigns))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update campaign names.',
    })
  })
}

export const processCampaignNamesUpdate = (response, campaigns) => (dispatch) => {
  if (response.errors.length) {
    toast.show({
      title: 'Danger',
      description: 'Failed to update campaign names.<br />'
        + response.errors.join('<br />'),
    })
  } else {
    dispatch({
      type: UPDATE_CAMPAIGN_NAMES_SUCCEED,
      data: {
        payload: campaigns,
        updated: response.idsUpdated,
      },
    })
    toast.show({
      title: 'Success',
      description: 'Campaign names have been successfully updated.'
    })
  }
}

export const updateBiddings = (accessToken, campaigns, updateBaseBid = false, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/updateBiddings', {
    userId: currentUserId,
    campaigns,
    updateBaseBid,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processCampaignBiddingsUpdate(response.data))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update placement.',
    })
  })
}

export const processCampaignBiddingsUpdate = (response) => (dispatch) => {
  if (response.successList.length) {
    dispatch({
      type: UPDATE_BIDDINGS_SUCCEED,
      data: response.successList,
    })
  }

  if (!response.errors.length) {
    toast.show({
      title: 'Success',
      description: 'Placement has been successfully updated.',
    })
  } else {
    toast.show({
      title: 'Danger',
      description: 'Failed to update for some campaigns:<br /><ul>'
        + response.errors.map(error => `<li>${error}</li>`).join('')
        + '</ul>',
    })
  }
}

// Update Target ACoS to campaigns
export const updateTargetAcos = (accessToken, campaigns, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/updateTargetAcos', {
    userId: currentUserId,
    campaigns,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processTargetAcosUpdate(campaigns))
    }
    return response
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update Target ACoS.',
    })
  })
}

export const processTargetAcosUpdate = (campaigns) => (dispatch) => {
  dispatch({
    type: UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED,
    data: campaigns,
  })
  toast.show({
    title: 'Success',
    description: 'Target ACoS has been successfully updated.',
  })
}

export const updateCampaignTags = (payload) => {
  return {
    type: UPDATE_CAMPAIGNS_TAGS_SUCCEED,
    payload,
  }
}

// Retrieve campaigns with tags.
export const getCampaignsWithTag = (accessToken, startDate, endDate, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/campaign/getCampaigns', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
    forTag: true,
  }, signal).then((response) => {
    dispatch({
      type: GET_CAMPAIGNS_WITH_TAG_SUCCESS,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get campaigns with tags.',
      })
    }
  })
}

export const getCreative = (accessToken, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/campaign/getCreative', accessToken, {
    userId: currentUserId,
    campaignId,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get creative.',
    })
  })
}

export const updateBudget = (accessToken, campaigns, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/updateBudget', {
    userId: currentUserId,
    campaigns,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      return dispatch(processBudgetUpdate(campaigns, response.data))
    }
    return response
  }).catch((error) => {
    return Promise.reject(error?.response?.data || 'Failed to update Daily Budgets.')
  })
}

export const processBudgetUpdate = (campaigns, response) => (dispatch) => {
  dispatch({
    type: UPDATE_BULK_DAILY_BUDGET_SUCCEED,
    data: {
      payload: campaigns,
      updated: response,
    },
  })
  toast.show({
    title: 'Success',
    description: 'Daily Budgets has been successfully updated.'
  })
  return response
}

export const addNotes = (accessToken, notes) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/addNotes', {
    userId: currentUserId,
    notes,
  }, accessToken).then((response) => {
    dispatch({
      type: ADD_BULK_NOTES_SUCCEED,
      data: response.data,
    })
    toast.show({
      title: 'Success',
      description: 'Notes has been successfully added.'
    })
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: Promise.reject(error?.response?.data || 'Failed to add Notes.')
    })
  })
}

export const deleteNote = (accessToken, note) => (dispatch) => {
  return callPost(`/campaign/deleteNote/${note.id}`, {}, accessToken).then(() => {
    dispatch({
      type: DELETE_CAMPAIGN_NOTE_SUCCEED,
      data: note,
    })
    return note
  }).catch(error => Promise.reject(error?.response?.data || 'Failed to delete Note.'))
}

export const getSummary = (accessToken, campaignIds, type) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaign/getSummary', accessToken, {
    userId: currentUserId,
    type,
    campaignIds,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || `Failed to get ${type} campaigns data.`,
    })
  })
}

export const getStream = (accessToken, campaigns) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callPost('/campaign/getStream', {
    userId: currentUserId,
    campaigns,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get campaigns Stream Data.',
    })
  })
}

export const getStats = (accessToken, campaignIds) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callPost('/campaign/getStats', {
    userId: currentUserId,
    campaignIds,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get campaigns Stats Data.',
    })
  })
}

export const getFilteredCampaigns = (accessToken, filters) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaign/getFilteredCampaigns', {
    userId: currentUserId,
    filters,
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get campaigns with selected attributes.',
    })
  })
}

export const getSdCreativePreview = (accessToken, campaignId, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaign/getSdCreativePreview', accessToken, {
    userId: currentUserId,
    campaignId,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to generate creative preview.',
      })
    }
  })
}

export const selectCampaigns = (campaignIds) => {
  return {
    type: SELECT_CAMPAIGNS,
    data: campaignIds,
  }
}

export const selectAdTypeFilter = (adType) => {
  return {
    type: SELECT_AD_TYPE_FILTER,
    data: adType,
  }
}
