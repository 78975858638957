import React, { useState } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../CommonComponents/ToastComponent/toast'

const SBProductsOrderModal = ({ products, creativeProducts, onChange, onClose }) => {
  const creativeProductIds = creativeProducts.map(product => product.id)

  const [orderedProducts, setOrderedProducts] = useState(creativeProductIds.map(
    productId => products.find(product => product.id === productId)
  ))

  const onSave = () => {
    if (orderedProducts.length !== 3) {
      toast.show({
        title: 'Danger',
        description: 'Select 3 products.',
      })
      return
    }
    onChange(orderedProducts)
  }

  const onAdd = (product) => {
    if (orderedProducts.length >= 3) {
      toast.show({
        title: 'Danger',
        description: 'You can select up to 3 products.',
      })
      return
    }
    setOrderedProducts(prev => ([...prev, product]))
  }

  const onRemove = (product) => {
    setOrderedProducts(orderedProducts.filter(prod =>
      prod.id !== product.id
    ))
  }

  const renderProductDetails = product => (
    <>
      <img src={product.image} alt={product.name} />
      <div className="product-info">
        <div className="product-name">{product.name}</div>
        <div className="product-detail">
          <span>Price: {product.price}</span>
          <span>ASIN: {product.asin}</span>
          <span>SKU: {product.sku}</span>
        </div>
      </div>
    </>
  )

  const renderUnordered = () => (
    products.map((product) => {
      const found = orderedProducts.find(ordered =>
        ordered.id === product.id
      )
      if (found) {
        return null
      }
      return (
        <div key={product.id} className="product-wrapper">
          { renderProductDetails(product) }
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => onAdd(product)}
          >
            Add
          </button>
        </div>
      )
    })
  )

  return (
    <Modal className="sb-product-ordering-modal" backdrop="static" show size="full">
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Click products to add them in the order you wish them to appear
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="column-container">
          { renderUnordered() }
        </div>
        <div className="column-container">
          {
            orderedProducts.map(product => (
              <div key={product.id} className="product-wrapper">
                { renderProductDetails(product) }
                <button
                  type="button"
                  className="btn btn-red"
                  onClick={() => onRemove(product)}
                >
                  Remove
                </button>
              </div>
            ))
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-primary" onClick={() => onSave()}>
          Confirm
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SBProductsOrderModal
