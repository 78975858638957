import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Row,
  Col,
} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportChartWidget from './ReportChartWidget'
import ReportNoteWidget from './ReportNoteWidget'
import ReportPieChartWidget from './ReportPieChartWidget'
import ReportTableWidget from './ReportTableWidget'

import {
  getSpendByTargetingType,
  getSpendByKeywordsLength
} from '../../redux/actions/accountHealth'
import { getPortfoliosWithCampaigns } from '../../redux/actions/portfolio'

import {
  formatCurrency,
  formatValue,
  parsePerformanceSummary
} from '../../services/helper'
import { reportWidgets } from '../../utils/defaultValues'

const skuColumns = [
  { key: 'product', name: 'Product'},
  { key: 'cost', name: 'Spend' },
  { key: 'cost_percent', name: '%' },
]

const portfolioColumns = [
  { key: 'portfolio', name: 'Portfolio'},
  { key: 'cost', name: 'Spend' },
  { key: 'cost_percent', name: '%' },
]

const tagColumns = [
  { key: 'tag', name: 'Tag'},
  { key: 'cost', name: 'Spend' },
  { key: 'cost_percent', name: '%' },
]

const BudgetUtilizationSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)
  const allSponsoredMetrics = useSelector(state => state.accountHealth.allSponsoredMetrics)
  const allSponsoredChart = useSelector(state => state.accountHealth.allSponsoredChart)
  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const spChart = useSelector(state => state.accountHealth.spChart)
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sbChart = useSelector(state => state.accountHealth.sbChart)
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)
  const sdChart = useSelector(state => state.accountHealth.sdChart)
  const performanceData = useSelector(state => state.accountHealth.performanceData)
  const placementSummary = useSelector(state => state.accountHealth.performanceData.placementSummary)

  const productList = useSelector(state => state.product.productList)
  const listTags = useSelector(state => state.tag.tags)
  const campaignsWithTag = useSelector(state => state.campaign.campaignsWithTag)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [portfolios, setPortfolios] = useState([])
  const [costMetricsFromTargets, setCostMetricsFromTargets] = useState(null)
  const [costMetricsFromKeywordLength, setCostMetricsFromKeywordLength] = useState(null)
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')

  const { placementTotal } = useMemo(() => {
    return parsePerformanceSummary(placementSummary)
  }, [placementSummary])

  const expensiveProductList = useMemo(() => {
    return productList.sort((a, b) => b.cost - a.cost).slice(0, 10).map(c => ({
      asin: c.asin,
      sku: c.sku,
      thumbnail: c.image || '',
      cost: c.cost,
      productId: c.id,
    }))
  }, [productList])

  const expensiveTagList = useMemo(() => {
    return listTags.map((tag) => {
      const campaigns = (campaignsWithTag || []).filter(c =>
        c.tags.includes(tag.id)
      )

      let cost = 0

      campaigns.forEach((campaign) => {
        cost += parseFloat(campaign.cost || 0)
      })

      return {
        ...tag,
        cost,
      }
    }).sort((a, b) => b.cost - a.cost).slice(0, 10)
  }, [listTags, campaignsWithTag])

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_budget_utilization || '')
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      if (showSection && !isLoaded) {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const [data1, data2, data3] = await Promise.all([
            dispatch(getSpendByTargetingType(accessToken, abortCtrl.signal)),
            dispatch(getSpendByKeywordsLength(accessToken, abortCtrl.signal)),
            dispatch(getPortfoliosWithCampaigns(
              accessToken,
              startDate,
              endDate,
              abortCtrl.signal,
            ))
          ])
          setCostMetricsFromTargets(data1)
          setCostMetricsFromKeywordLength(data2)
          setPortfolios(
            data3.map((portfolio) => {
              let cost = 0
              portfolio.campaigns.forEach((campaign) => {
                cost += campaign.cost
              })

              return {
                ...portfolio,
                cost,
              }
            })
            .sort((a, b) => b.cost - a.cost)
            .slice(0, 10)
          )
          setIsLoading(false)
          setIsLoaded(true)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, showSection, startDate, endDate, currentUserId]) // eslint-disable-line

  useEffect(() => {
    let reportSettings = reportWidgetSetting
      ? reportWidgetSetting.budgetUtilization
      : []

    if (!reportSettings || !reportSettings.length) {
      reportSettings = reportWidgets.budgetUtilization
    }

    setViewWidgets(
      (reportSettings || []).map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_budget_utilization',
      sectionValue: note,
    })
  }

  const renderChartWidget = (widget) => {
    let chartData = [];
    let chartMetrics = [];

    switch(widget.key) {
      case 'cost_trend_by_ad_type':
        chartMetrics = [
          {
            key: 'sp',
            label: `SP Ad Spend (${currencySign})`,
            stroke: '#8884d8',
            name: `Sp AD Spend (${currencySign})`
          },{
            key: 'sb',
            label: 'SB Ad Spend',
            stroke: '#82ca9d',
            name: `SB Ad Spend (${currencySign})`
          },{
            key: 'sd',
            label: 'SD Ad Spend',
            stroke: '#25c835',
            name: `SD Ad Spend (${currencySign})`
          }
        ]
        spChart.forEach(item => {
          chartData.push({
            date: format(item.date, 'MMM dd'),
            sp: item.cost,
            sb: 0,
            sd: 0
          })
        })
        sbChart.forEach(item => {
          const index = chartData.findIndex(c => c.date === format(item.date, 'MMM dd'))
          if (index !== -1) {
            chartData[index].sb = item.cost
          }
        })
        sdChart.forEach(item => {
          const index = chartData.findIndex(c => c.date === format(item.date, 'MMM dd'))
          if (index !== -1) {
            chartData[index].sd = item.cost
          }
        })
        break
      case 'cost_trend_by_branded':
        chartMetrics = [
          {
            key: 'branded',
            label: `Branded (${currencySign})`,
            stroke: '#8884d8',
            name: `Branded AD Spend (${currencySign})`
          },{
            key: 'non_branded',
            label: `Non Branded (${currencySign})`,
            stroke: '#82ca9d',
            name: `Non-Branded Ad Spend (${currencySign})`
          }
        ];
        allSponsoredChart.forEach(item => {
          chartData.push({
            date: format(item.date, 'MMM dd'),
            branded: item.branded_cost.toFixed(2),
            non_branded: item.non_branded_cost.toFixed(2)
          })
        })
        break
      default:
        return null
    }

    return (
      <ReportChartWidget
        widget={widget}
        data={chartData}
        metrics={chartMetrics}
        onRemove={handleOnRemoveWidget}
        customizing={customizingSection}
        section="budgetUtilization"
      />
    )
  }

  const renderPieChartWidget = (widget) => {
    let data = []
    switch(widget.key) {
      case 'cost_by_ad_type':
        data = [
          { name: `SP (${currencySign})`, value: spMetrics ? spMetrics.cost : 0 , color: '#31cceb', fullName: 'SP Sales'},
          { name: `SB (${currencySign})`, value: sbMetrics ? sbMetrics.cost : 0, color: '#cf1d23', fullName: 'SB Sales'},
          { name: `SD (${currencySign})`, value: sdMetrics ? sdMetrics.cost : 0, color: '#97e80c', fullName: 'SD Sales'},
        ]
        break
      case 'cost_by_match_type':
        const matchTypeSummary = performanceData ? performanceData.matchTypeSummary : []
        data = [
          { name: `Exact (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[0].cost : 0, color: '#ed4c0c', fullName: 'EXACT'},
          { name: `Broad (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[1].cost : 0, color: '#97e80c', fullName: 'BROAD'},
          { name: `Phrase (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[2].cost : 0, color: '#cf1d23', fullName: 'PHRASE'},
        ]
        break
      case 'cost_by_target':
        data = [
          { name: `Keyword (${currencySign}):`, value: costMetricsFromTargets ? costMetricsFromTargets.costByKeywordTargeting : 0, color: '#ed4c0c', fullName: 'Keyword'},
          { name: `ASIN (${currencySign}):`, value: costMetricsFromTargets ? costMetricsFromTargets.costByAsinTargeting : 0, color: '#cf1d23', fullName: 'ASIN'},
        ]
        break
      case 'cost_by_targeting_type':
        data = [
          { name: `Manual (${currencySign}):`, value: costMetricsFromTargets ? costMetricsFromTargets.costByManualTargeting : 0, color: '#31cceb', fullName: 'Manual'},
          { name: `Auto (${currencySign}):`, value: costMetricsFromTargets ? costMetricsFromTargets.costByAutoTargeting : 0, color: '#cf1d23', fullName: 'Auto'},
        ]
        break
      case 'cost_by_placement':
        data = [
          { name: `Top (${currencySign}):`, value: placementTotal['costTop'], color: '#0ce8dd', fullName: 'Top of Search'},
          { name: `Product (${currencySign}):`, value: placementTotal['costDetail'], color: '#97e80c', fullName: 'Product Page'},
          { name: `Rest (${currencySign}):`, value: placementTotal['costOther'], color: '#cf1d23', fullName: 'Rest of Search'},
        ]
        break
      case 'cost_by_keyword':
        data = [
          { name: `One word:`, value: costMetricsFromKeywordLength?.oneWordCost, color: '#8884d8', fullName: 'One word'},
          { name: `Two words:`, value: costMetricsFromKeywordLength?.twoWordsCost, color: '#82ca9d', fullName: 'Two words'},
          { name: `Three words:`, value: costMetricsFromKeywordLength?.threeWordsCost, color: '#ffd156', fullName: 'Three words'},
          { name: `Four words:`, value: costMetricsFromKeywordLength?.fourWordsCost, color: '#25c835', fullName: 'Four words'},
          { name: `Five words:`, value: costMetricsFromKeywordLength?.fiveWordsCost, color: '#ff7B56', fullName: 'Five words'},
        ]
        break
      default:
        return null
    }
    return (
      <ReportPieChartWidget
        widget={widget}
        data={data}
        onRemove={handleOnRemoveWidget}
        customizing={customizingSection}
        section="budgetUtilization"
      />
    )
  }

  const renderSKURecord = (record) => (
    <>
      <div className="table-col">
        <div className="product-col">
          <div className="product-thumbnail">
            {
              record.thumbnail !== '' && (
                <img src={record.thumbnail} alt={record.sku} />
              )
            }
          </div>
          <div className="product-asin">
            { record.asin }
          </div>
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(allSponsoredMetrics?.cost > 0 ? record.cost / allSponsoredMetrics?.cost * 100 : 0, 'percent', 2) }
      </div>
    </>
  )

  const renderPortfolioRecord = (record) => (
    <>
      <div className="table-col col-portfolio">
        { record.name }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        {
          record.acos !== 0
            ? formatValue(
                allSponsoredMetrics?.cost > 0
                  ? record.cost / allSponsoredMetrics?.cost * 100
                  : 0,
                'percent',
                2
              )
            : '--'
        }
      </div>
    </>
  )

  const renderTagRecord = record => (
    <>
      <div className="table-col col-tag">
        { record.tag_name }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        {
          record.acos !== 0
            ? formatValue(
                allSponsoredMetrics?.cost > 0
                  ? record.cost / allSponsoredMetrics?.cost * 100
                  : 0,
                'percent',
                2
              )
            : '--'
        }
      </div>
    </>
  )

  const renderWidgets = () => {
    if (!viewWidgets || !viewWidgets.length) {
      return null
    }

    const tableWidgets = []
    const pieChartWidgets = []
    const chartWidgets = []
    viewWidgets.forEach((widget) => {
      if (!widget.view && !customizingSection) {
        return
      }

      if (widget.widgetType === 'table') {
        if (widget.key === 'cost_by_sku') {
          tableWidgets.push((
            <Col key={widget.key} xs={24} sm={12} md={8}>
              <ReportTableWidget
                widget={widget}
                columns={skuColumns}
                records={expensiveProductList || []}
                customizing={customizingSection}
                idField="productId"
                renderRecord={renderSKURecord}
                sortBy="cost"
                sortOrder="desc"
                section="budgetUtilization"
                onRemove={handleOnRemoveWidget}
              />
            </Col>
          ))
        } else if (widget.key === 'cost_by_portfolio') {
          tableWidgets.push((
            <Col key={widget.key} xs={24} sm={12} md={8}>
              <ReportTableWidget
                widget={widget}
                columns={portfolioColumns}
                records={portfolios}
                customizing={customizingSection}
                idField="portfolio_id"
                renderRecord={renderPortfolioRecord}
                sortBy="cost"
                sortOrder="desc"
                section="budgetUtilization"
                onRemove={handleOnRemoveWidget}
              />
            </Col>
          ))
        } else if (widget.key === 'cost_by_tag') {
          tableWidgets.push((
            <Col key={widget.key} xs={24} sm={12} md={8}>
              <ReportTableWidget
                widget={widget}
                columns={tagColumns}
                records={expensiveTagList || []}
                customizing={customizingSection}
                idField="id"
                renderRecord={renderTagRecord}
                sortBy="cost"
                sortOrder="desc"
                section="budgetUtilization"
                onRemove={handleOnRemoveWidget}
              />
            </Col>
          ))
        }
      } else if (widget.widgetType === 'pieChart') {
        pieChartWidgets.push((
          <Col key={widget.key} xs={12}>
            { renderPieChartWidget(widget) }
          </Col>
        ))
      } else if (widget.widgetType === 'chart') {
        chartWidgets.push((
          <Col key={widget.key} xs={12}>
            { renderChartWidget(widget) }
          </Col>
        ))
      }
    })

    return (
      <>
        {
          tableWidgets.length > 0 && (
            <Row>
              { tableWidgets }
            </Row>
          )
        }
        {
          (pieChartWidgets.length > 0 || chartWidgets.length > 0) && (
            <Row>
              { pieChartWidgets }
              { chartWidgets }
            </Row>
          )
        }
      </>
    )
  }

  return (
    <>
      <SectionHeader
        title="Budget Utilization"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <Grid className={isLoading ? 'loading' : ''} fluid>
            {(customizingSection || noteWidgetView) && (
              <Col xs={24}>
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section="budgetUtilization"
                />
              </Col>
            )}
            { isLoading && <LoaderComponent /> }
            { renderWidgets() }
          </Grid>
        )
      }
    </>
  )
}

export default withReportSection(BudgetUtilizationSection, {
  sectionId: 'budget_utilization',
  reportSectionKey: 'budgetUtilization',
})
