/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const TemplateOption = (props) => {
  const { innerRef, innerProps, getStyles, isSelected, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
      className={`template-option${isSelected ? ' selected' : ''}`}
    >
      <span>{ data.name }</span>
      <span className="action-links">
        <a href="#" onClick={(event) => { data.onEdit(event, data.id) }}>
          Edit
        </a>
        <a href="#" onClick={(event) => { event.preventDefault(); data.onSeeDetails(data.id) }}>
          See what's included
        </a>
      </span>
    </div>
  )
}

export default TemplateOption
