import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import {
  getTests,
  createTest,
} from '../../redux/actions/productDetail'

const timeframeList = [7, 14, 21, 28]

const ProductTestingAddComponent = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [name, setName] = useState('')
  const [timeframe, setTimeframe] = useState(7)
  const [beforeData, setBeforeData] = useState({
    title: '',
    summary: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    bullet4: '',
    bullet5: '',
    backend1: '',
    backend2: '',
    backend3: '',
    backend4: '',
    backend5: '',
    price: '',
    discount: '',
    coupon: '',
  })
  const [afterData, setAfterData] = useState({
    title: '',
    summary: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    bullet4: '',
    bullet5: '',
    backend1: '',
    backend2: '',
    backend3: '',
    backend4: '',
    backend5: '',
    price: '',
    discount: '',
    coupon: '',
  })
  const [isCreating, setIsCreating] = useState(false)

  const updateBeforeData = field => (event) => {
    setBeforeData(prev => ({
      ...prev,
      [field]: event.target.value,
    }))
  }

  const updateAfterData = field => (event) => {
    setAfterData(prev => ({
      ...prev,
      [field]: event.target.value,
    }))
  }

  const onAddTest = async () => {
    setIsCreating(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(createTest(accessToken, {
      name,
      timeframe,
      before_data: beforeData,
      after_data: afterData,
    })).then(() => {
      setIsCreating(false)
      dispatch(getTests(accessToken))
      onClose()
    }).catch(() => {
      setIsCreating(false)
      toast.show({
        title: 'Danger',
        description: 'Failed to create a test.',
      })
    })
  }

  const renderMeta = () => (
    <div className="test-meta">
      <div className="test-meta-left">
        <span className="test-name-label">Test Name:</span>
        <input
          type="text"
          value={name}
          onChange={(e) => { setName(e.target.value) }}
        />
      </div>
      <div className="test-meta-right">
        {
          timeframeList.map(option => (
            <button
              key={option}
              type="button"
              className="btn btn-light-blue"
              onClick={() => { setTimeframe(option) }}
            >
              { option } Days
            </button>
          ))
        }
        <input
          type="text"
          value={timeframe}
          onChange={(event) => { setTimeframe(event.target.value) }}
        />
        <span>Days</span>
      </div>
    </div>
  )

  const renderNotes = () => (
    <div className="test-notes">
      <div className="notes-column">
        <div className="notes-header">A Values</div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Title</span>
            <textarea
              value={beforeData['title']}
              onChange={updateBeforeData('title')}
            />
          </div>
          <div className="notes-box">
            <span>Summary</span>
            <textarea
              value={beforeData['summary']}
              onChange={updateBeforeData('summary')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Bullets</span>
            <textarea
              value={beforeData['bullet1']}
              onChange={updateBeforeData('bullet1')}
            />
          </div>
          <div className="notes-box">
            <span>&nbsp;</span>
            <textarea
              value={beforeData['bullet2']}
              onChange={updateBeforeData('bullet2')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={beforeData['bullet3']}
              onChange={updateBeforeData('bullet3')}
            />
          </div>
          <div className="notes-box">
            <textarea
              value={beforeData['bullet4']}
              onChange={updateBeforeData('bullet4')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={beforeData['bullet5']}
              onChange={updateBeforeData('bullet5')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Backend Keywords</span>
            <textarea
              value={beforeData['backend1']}
              onChange={updateBeforeData('backend1')}
            />
          </div>
          <div className="notes-box">
            <span>&nbsp;</span>
            <textarea
              value={beforeData['backend2']}
              onChange={updateBeforeData('backend2')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={beforeData['backend3']}
              onChange={updateBeforeData('backend3')}
            />
          </div>
          <div className="notes-box">
            <textarea
              value={beforeData['backend4']}
              onChange={updateBeforeData('backend4')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={beforeData['backend5']}
              onChange={updateBeforeData('backend5')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Price</span>
            <input
              type="text"
              value={beforeData['price']}
              onChange={updateBeforeData('price')}
            />
          </div>
          <div className="notes-box">
            <span>Discount</span>
            <input
              type="text"
              value={beforeData['discount']}
              onChange={updateBeforeData('discount')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Coupon</span>
            <input
              type="text"
              value={beforeData['coupon']}
              onChange={updateBeforeData('coupon')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
      </div>
      <div className="notes-column">
        <div className="notes-header">B Values</div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Title</span>
            <textarea
              value={afterData['title']}
              onChange={updateAfterData('title')}
            />
          </div>
          <div className="notes-box">
            <span>Summary</span>
            <textarea
              value={afterData['summary']}
              onChange={updateAfterData('summary')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Bullets</span>
            <textarea
              value={afterData['bullet1']}
              onChange={updateAfterData('bullet1')}
            />
          </div>
          <div className="notes-box">
            <span>&nbsp;</span>
            <textarea
              value={afterData['bullet2']}
              onChange={updateAfterData('bullet2')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={afterData['bullet3']}
              onChange={updateAfterData('bullet3')}
            />
          </div>
          <div className="notes-box">
            <textarea
              value={afterData['bullet4']}
              onChange={updateAfterData('bullet4')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={afterData['bullet5']}
              onChange={updateAfterData('bullet5')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Backend Keywords</span>
            <textarea
              value={afterData['backend1']}
              onChange={updateAfterData('backend1')}
            />
          </div>
          <div className="notes-box">
            <span>&nbsp;</span>
            <textarea
              value={afterData['backend2']}
              onChange={updateAfterData('backend2')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={afterData['backend3']}
              onChange={updateAfterData('backend3')}
            />
          </div>
          <div className="notes-box">
            <textarea
              value={afterData['backend4']}
              onChange={updateAfterData('backend4')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <textarea
              value={afterData['backend5']}
              onChange={updateAfterData('backend5')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Price</span>
            <input
              type="text"
              value={afterData['price']}
              onChange={updateAfterData('price')}
            />
          </div>
          <div className="notes-box">
            <span>Discount</span>
            <input
              type="text"
              value={afterData['discount']}
              onChange={updateAfterData('discount')}
            />
          </div>
        </div>
        <div className="notes-row">
          <div className="notes-box">
            <span>Coupon</span>
            <input
              type="text"
              value={afterData['coupon']}
              onChange={updateAfterData('coupon')}
            />
          </div>
          <div className="notes-box"></div>
        </div>
      </div>
    </div>
  )

  return (
    <Modal className="testing-add-modal" backdrop="static" size="lg" show={show}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>A/B Split Testing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isCreating && <LoaderComponent /> }
        { renderMeta() }
        { renderNotes() }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isCreating}
          onClick={onAddTest}
        >
          Add Test
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProductTestingAddComponent
