import React, { useMemo } from 'react'
import { FiCheck } from 'react-icons/fi'

const typeList = [
  {
    value: 'keyword',
    name: 'Keyword targeting',
    description: 'Choose keywords to help your products appear in shopper searches.',
  },
  {
    value: 'product',
    name: 'Product targeting',
    description: 'Choose specific products, categories, brands, or other product features to target your ads.',
  },
  {
    value: 'audience',
    name: 'Audiences targeting',
    description: 'Choose which audiences you want to see your ads.',
  },
]

const TargetingSelector = ({ manualTarget, noKeyword = false, noAudience = false, onChange }) => {
  const targetList = useMemo(() => {
    let list = typeList
    if (noKeyword) {
      list = list.filter(type => type.value !== 'keyword')
    }
    if (noAudience) {
      list = list.filter(type => type.value !== 'audience')
    }
    return list
  }, [noKeyword, noAudience])

  return (
    <div className="section-container targeting-type-selector">
      <div className="section-title">Targeting Option</div>
      <div className="section-note">
        Targeting uses keywords and products to help your ads appear in search and detail pages.
      </div>
      <div className="selector-wrapper">
        {
          targetList.map(type => (
            <div
              key={type.value}
              className={`selector${manualTarget === type.value ? ' selected' : ''}`}
              onClick={() => onChange(type.value)}
            >
              <FiCheck />
              <div className="selector-content">
                <div className="selector-title">{ type.name }</div>
                <div className="selector-note">{ type.description }</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default TargetingSelector
