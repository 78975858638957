import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import SortableTable from '../../CommonComponents/SortableTableComponent'

import { tableSorter } from '../../../services/helper'

import { campaignTypeMap, stateLabels } from '../../../utils/defaultValues'
import { campaignTypes, STATE_OPTION_ENABLED } from '../../../utils/filterDef'

const statuses = [
  { value: '', label: 'All' },
  { value: ['enabled', 'paused'], label: 'Active & Paused' },
  STATE_OPTION_ENABLED,
  { value: 'paused', label: 'Paused Only' },
]

const columns = [
  { key: 'campaign', name: 'Campaign' },
]

const StepCampaign = ({ campaignList, selectedCampaigns, forPtEx, forCategory, onSelect }) => {
  const [campaigns, setCampaigns] = useState([])
  const [selectedType, setSelectedType] = useState(campaignTypes[0])
  const [selectedState, setSelectedState] = useState(STATE_OPTION_ENABLED)

  useEffect(() => {
    setCampaigns(campaignList.filter((campaign) => {
      if (selectedType.value !== ''
        && campaign.campaignType.toLowerCase() !== selectedType.value) {
        return false
      }

      if (selectedState.value !== '') {
        let selectedValue = selectedState.value
        if (!Array.isArray(selectedValue)) {
          selectedValue = [selectedValue]
        }
        if (selectedValue.indexOf(campaign.state.toLowerCase()) === -1) {
          return false
        }
      }

      if (campaign.campaignType === 'sp'
        && campaign.targeting_type === 'auto') {
        return false
      }
      if (campaign.campaignType === 'sd') {
        if (campaign.tactic !== 'T00020' && campaign.tactic !== 'T00030') {
          return false
        }
        if (!(forPtEx === true && forCategory === true)
          && campaign.tactic === 'T00030') {
          return false
        }
      }
      return true
    }).map(campaign => ({
      ...campaign,
      campaign_id: campaign.campaign_id.toString(),
    })))
  }, [campaignList, selectedType, selectedState]) // eslint-disable-line

  const renderFilter = () => {
    return (
      <>
        <div className="filter-wrapper">
          <label>Ad Type</label>
          <Select
            className="select-wrapper"
            options={campaignTypes}
            value={selectedType}
            onChange={setSelectedType}
          />
        </div>
        <div className="filter-wrapper">
          <label>Campaign Status</label>
          <Select
            className="select-wrapper"
            options={statuses}
            value={selectedState}
            onChange={setSelectedState}
          />
        </div>
      </>
    )
  }

  const renderCampaign = (record) => {
    let targetingType
    if (record.campaignType === 'sp') {
      targetingType = 'Manual'
    }

    return (
      <>
        <div className="table-col col-campaign">
          <div className="campaign-status">
            <div className={`status ${record.state.toLowerCase() === 'enabled' ? 'on' : 'off'}`}>
              <div className="bullet"></div>
              <span>{ stateLabels[record.state.toLowerCase()] }</span>
            </div>
          </div>
          <div className="campaign-name" title={record.campaign}>
            { record.campaign }
          </div>
          <div className="campaign-detail">
            {
              targetingType && <span>{ targetingType }</span>
            }
            <span>
              { campaignTypeMap[record.campaignType] }
            </span>
          </div>
        </div>
      </>
    )
  }

  return (
    <SortableTable
      columns={columns}
      defaultSort={['campaign', 'asc']}
      sorter={tableSorter(['campaign'])}
      className="table-campaigns"
      records={campaigns}
      idField="campaign_id"
      searchFields={['campaign']}
      selectedRecords={selectedCampaigns}
      renderTopRight={renderFilter}
      renderRecord={renderCampaign}
      onChange={onSelect}
    />
  )
}

export default StepCampaign
