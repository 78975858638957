import {
  MONITOR_JOB,
  UPDATE_JOB_STATUS,
  REMOVE_JOB_FROM_MONITOR,
  CLOSE_JOB_MONITOR,
} from '../actionTypes/job'

export const initialState = {
  jobsToMonitor: [],
}

const job = (state = initialState, action) => {
  switch (action.type) {
    case MONITOR_JOB:
      return {
        ...state,
        jobsToMonitor: [
          ...(state.jobsToMonitor || []),
          action.data,
        ],
      }
    case UPDATE_JOB_STATUS:
      return {
        ...state,
        jobsToMonitor: (state.jobsToMonitor || []).map((job) => {
          if (job.id !== action.data.id) {
            return job
          }
          return {
            ...job,
            status: action.data.status,
          }
        }),
      }
    case REMOVE_JOB_FROM_MONITOR:
      return {
        ...state,
        jobsToMonitor: (state.jobsToMonitor || []).filter((job) => {
          return job.id !== action.data.id
        }),
      }
    case CLOSE_JOB_MONITOR:
      return {
        ...state,
        jobsToMonitor: [],
      }
    default:
      return state
  }
}

export default job
