import React, { useEffect, useMemo, useState } from 'react'
import { Toggle } from 'rsuite'
import { getTimezoneOffset } from 'date-fns-tz'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import { getRuleDescription } from '../../services/helper'

const COLOR_FILLED = '#93DF8D'
const COLOR_FROM_TEMPLATE = '#FFD156'
const COLOR_NO_DATA = '#D4D6D7'
const COLOR_B10 = '#96D7F6'
const COLOR_B25 = '#2DB6F5'
const COLOR_M50 = '#E4DDF7'
const COLOR_U25 = '#C1ADFA'
const COLOR_U10 = '#263238'

const RANGE_EXISTING_RULES = 'existing_rules'
const RANGE_CLICKS_NO_SALES = 'clicks_no_sales'
const RANGE_B10 = 'b10'
const RANGE_B25 = 'b25'
const RANGE_M50 = 'm50'
const RANGE_U25 = 'u25'
const RANGE_U10 = 'u10'

const dowLabels = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
const timeLabels = [
  '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
  '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
  '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
  '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
]

// Convert times by adding a time zone difference.
const convertTimes = (times, diff) => {
  if (!diff) {
    return times
  }
  return times.map((slotNumber) => {
    slotNumber += diff
    if (slotNumber >= 24 * 7) {
      slotNumber %= 24 * 7
    } else  if (slotNumber < 0) {
      slotNumber += 24 * 7
    }
    return slotNumber
  })
}

const SlotSelector = ({ activeTab = 'acos', fromEditor = false, stats, slots, currentTemplates,
  timezone, selectedSlots, onSlotSelect }) => {
  const [showNumbers, setShowNumbers] = useState(false)
  const [selectedRanges, setSelectedRanges] = useState({})
  const [clickFilterChecked, setClickFilterChecked] = useState(false)
  const [clickFilterValue, setClickFilterValue] = useState(0)
  const [spendFilterChecked, setSpendFilterChecked] = useState(false)
  const [spendFilterValue, setSpendFilterValue] = useState(0)
  const [metricFilterChecked, setMetricFilterChecked] = useState(false)
  const [metricFilterValue, setMetricFilterValue] = useState(0)

  const slotsWithRules = useMemo(() => {
    let collection = []
    slots.forEach((slot) => {
      collection = collection.concat(slot.s)
    })

    const currentOffset = getTimezoneOffset(timezone)
    currentTemplates.forEach((template) => {
      const templateOffset = getTimezoneOffset(template.timezone)
      const diff = Math.round((currentOffset - templateOffset) / 1000 / 60 / 60)

      template.slots.forEach((slot) => {
        collection = collection.concat(convertTimes(slot.s, diff))
      })
    })
    return collection
  }, [slots, currentTemplates, timezone])

  // Calculate values/labels for each slot.
  const values = useMemo(() => {
    return stats.map((statsForDay) => {
      return statsForDay.map((record) => {
        let value = 0
        let noData = true
        let notFiltered = false

        if (activeTab === 'acos') {
          if (
            (
              clickFilterChecked
              && !isNaN(clickFilterValue)
              && parseInt(record.clicks, 10) < parseInt(clickFilterValue, 10)
            )
            ||
            (
              spendFilterChecked
              && !isNaN(spendFilterValue)
              && parseFloat(record.cost) < parseFloat(spendFilterValue)
            )
          ) {
            notFiltered = true
          }

          if (parseFloat(record.revenue)) {
            value = parseFloat(record.cost) / parseFloat(record.revenue) * 100
            noData = false
          }
        } else if (activeTab === 'impressions') {
          value = parseInt(record.impressions, 10)
          noData = false
        } else if (activeTab === 'clicks') {
          if (parseInt(record.clicks, 10)) {
            value = parseInt(record.clicks, 10)
            noData = false
          }
        } else if (activeTab === 'cpc') {
          if (parseInt(record.clicks, 10)) {
            value = parseFloat(record.cost) / parseInt(record.clicks, 10)
            noData = false
          }
        } else if (activeTab === 'conversion') {
          // For conversion rates.
          if (parseInt(record.clicks, 10)) {
            value = parseInt(record.orders, 10) / parseInt(record.clicks, 10) * 100
            noData = false
          }
        } else if (activeTab === 'cost') {
          value = parseFloat(record.cost)
          noData = false
        } else if (activeTab === 'orders') {
          value = parseInt(record.orders, 10)
          noData = false
        }

        if (
          metricFilterChecked
          && !isNaN(metricFilterValue)
          && value < parseFloat(metricFilterValue, 10)
        ) {
          notFiltered = true
        }

        return {
          value,
          noData,
          notFiltered,
          clicks: record.clicks,
          clicksNoSales: parseInt(record.clicks, 10) > 0
            && parseFloat(record.revenue) === 0,
        }
      })
    })
  }, [activeTab, stats, clickFilterChecked, clickFilterValue,
    spendFilterChecked, spendFilterValue, metricFilterChecked, metricFilterValue])

  // Calculate position of each slot as percentage.
  const valuesWithPercent = useMemo(() => {
    if (!values.length) {
      return [...Array(7).keys()].map(() => (
        [...Array(24).keys()].map(() => ({
          value: 0,
          noData: true,
          clicksNoSales: false,
          percent: 0,
        }))
      ))
    }

    const flattened = []
    values.forEach((dayValues) => [
      dayValues.forEach((value) => {
        if (value.noData || value.notFiltered) {
          return
        }
        flattened.push(value.value)
      })
    ])

    const sorted = flattened.sort((a, b) => a - b)

    return values.map(dayValues => (
      dayValues.map((value) => {
        if (!sorted.length) {
          return value
        }

        const pos = sorted.indexOf(value.value)
        value.percent = Math.round(((pos + 1) / sorted.length) * 100)
        return value
      })
    ))
  }, [values])

  // Get a list of available slots for each range.
  const slotsByRange = useMemo(() => {
    const byRange = {
      [RANGE_EXISTING_RULES]: slotsWithRules,
      [RANGE_CLICKS_NO_SALES]: [],
      [RANGE_B10]: [],
      [RANGE_B25]: [],
      [RANGE_M50]: [],
      [RANGE_U25]: [],
      [RANGE_U10]: [],
    }

    valuesWithPercent.forEach((dayValues, dow) => {
      dayValues.forEach((value, time) => {
        const slotNumber = dow * 24 + time

        if (slotsWithRules.includes(slotNumber)) {
          return
        }

        if (value.notFiltered) {
          return
        }

        if (value.clicksNoSales) {
          byRange[RANGE_CLICKS_NO_SALES].push(slotNumber)
        }

        if (value.noData) {
          return
        }

        if (value.percent <= 10) {
          byRange[RANGE_B10].push(slotNumber)
        }
        if (value.percent <= 25) {
          byRange[RANGE_B25].push(slotNumber)
        }
        if (value.percent > 25 && value.percent < 75) {
          byRange[RANGE_M50].push(slotNumber)
        }
        if (value.percent >= 75) {
          byRange[RANGE_U25].push(slotNumber)
        }
        if (value.percent >= 90) {
          byRange[RANGE_U10].push(slotNumber)
        }
      })
    })

    return byRange
  }, [valuesWithPercent, slotsWithRules])

  const rangeList = useMemo(() => {
    if (activeTab !== 'acos') {
      return [
        { value: RANGE_EXISTING_RULES, label: 'Existing Rules' },
        { value: RANGE_CLICKS_NO_SALES, label: 'Clicks/No Sales' },
        { value: RANGE_B10, label: 'Bottom 10%', color: COLOR_B10 },
        { value: RANGE_B25, label: 'Bottom 25%', color: COLOR_B25 },
        { value: RANGE_M50, label: 'Middle 50%', color: COLOR_M50 },
        { value: RANGE_U25, label: 'Upper 25%', color: COLOR_U25 },
        { value: RANGE_U10, label: 'Upper 10%', color: COLOR_U10 },
      ]
    }

    return [
      { value: RANGE_EXISTING_RULES, label: 'Existing Rules' },
      { value: RANGE_CLICKS_NO_SALES, label: 'Clicks/No Sales' },
      { value: RANGE_U10, label: 'Bottom 10%', color: COLOR_U10 },
      { value: RANGE_U25, label: 'Bottom 25%', color: COLOR_U25 },
      { value: RANGE_M50, label: 'Middle 50%', color: COLOR_M50 },
      { value: RANGE_B25, label: 'Upper 25%', color: COLOR_B25 },
      { value: RANGE_B10, label: 'Upper 10%', color: COLOR_B10 },
    ]
  }, [activeTab])

  useEffect(() => {
    // If all slots for a given range are selected,
    // tick a corresponding checkbox.
    const newSelection = {}
    rangeList.forEach((range) => {
      const deselected = slotsByRange[range.value].find(slot =>
        !selectedSlots.includes(slot)
      )
      newSelection[range.value] = slotsByRange[range.value].length > 0
        && typeof deselected === 'undefined'
    })
    setSelectedRanges(newSelection)
  }, [selectedSlots, slotsByRange, rangeList])

  // Find existing rule info for a given slot if there is any.
  const findRule = (slotNumber) => {
    let rules = null
    slots.forEach((slot) => {
      if (slot.s.includes(slotNumber)) {
        rules = slot
      }
    })

    if (!rules) {
      const currentOffset = getTimezoneOffset(timezone)
      // Exit early by using find(), instead of forEach().
      currentTemplates.find((template) => {
        const templateOffset = getTimezoneOffset(template.timezone)
        const diff = Math.round((currentOffset - templateOffset) / 1000 / 60 / 60)

        return template.slots.find((slot) => {
          const selection = convertTimes(slot.s, diff)
          if (selection.includes(slotNumber)) {
            rules = Object.assign({}, slot, {
              s: selection,
              template,
            })
            return true
          }
          return false
        })
      })
    }

    return rules
  }

  const handleRangeSelect = (range, checked) => {
    let newSelection
    if ([RANGE_B10, RANGE_B25, RANGE_M50, RANGE_U25, RANGE_U10].includes(range)) {
      const filtered = []
      valuesWithPercent.forEach((dayValues, dow) => {
        dayValues.forEach((value, time) => {
          if (value.noData || value.notFiltered) {
            return
          }

          if ((range === RANGE_B10 && value.percent <= 10)
            || (range === RANGE_B25 && value.percent <= 25)
            || (range === RANGE_M50 && value.percent > 25 && value.percent < 75)
            || (range === RANGE_U25 && value.percent >= 75)
            || (range === RANGE_U10 && value.percent >= 90)) {
            filtered.push(dow * 24 + time)
          }
        })
      })

      if (checked) {
        newSelection = [
          ...new Set([
            ...selectedSlots,
            ...filtered,
          ]),
        ]

        newSelection.sort((a, b) => a - b)
      } else {
        newSelection = selectedSlots.filter(slot => !filtered.includes(slot))
      }
    } else if (range === RANGE_EXISTING_RULES) {
      if (checked) {
        newSelection = [...slotsWithRules]
      } else {
        newSelection = []
      }
    } else if (range === RANGE_CLICKS_NO_SALES) {
      const clicksNoSalesList = []
      valuesWithPercent.forEach((dayValues, dow) => {
        dayValues.forEach((value, time) => {
          if (value.clicksNoSales && !value.notFiltered) {
            clicksNoSalesList.push(dow * 24 + time)
          }
        })
      })

      if (checked) {
        newSelection = [
          ...new Set([
            ...selectedSlots,
            ...clicksNoSalesList,
          ]),
        ]

        newSelection.sort((a, b) => a - b)
      } else {
        newSelection = selectedSlots.filter(slot => !clicksNoSalesList.includes(slot))
      }
    }

    if (range !== RANGE_EXISTING_RULES) {
      newSelection = newSelection.filter(slotNumber => !slotsWithRules.includes(slotNumber))
    }

    onSlotSelect(newSelection)
  }

  const handleSlotSelect = slotNumber => () => {
    let newSelection
    if (selectedSlots.includes(slotNumber)) {
      newSelection = selectedSlots.filter(slot => slot !== slotNumber)
    } else {
      newSelection = [
        ...selectedSlots,
        slotNumber,
      ]
    }

    let hasSlotsWithRules = false
    let hasSlotsWithoutRules = false
    const activeRules = {}
    newSelection.forEach((selectedSlot) => {
      if (!slotsWithRules.includes(selectedSlot)) {
        // If there are some slots selected without existing rules.
        hasSlotsWithoutRules = true
      } else {
        hasSlotsWithRules = true

        const rule = findRule(selectedSlot)
        if (rule) {
          activeRules[rule.s.join()] = rule
        }
      }
    })

    let ruleToShow = null
    // If some of selected slots have existing rules,
    // and the others have no existing rules,
    // there are no common rules to show.
    if (hasSlotsWithRules && !hasSlotsWithoutRules) {
      // If more than one rule are applied to different slots,
      // we cannot show them.
      const keys = Object.keys(activeRules)
      if (keys.length === 1) {
        ruleToShow = activeRules[keys[0]]
      }
    }

    // Sort slots by ascending order.
    onSlotSelect(newSelection.sort((a, b) => a - b), ruleToShow)
  }

  const handleDowClick = dow => () => {
    const slotsForDow = [...Array(24).keys()].map(hour => dow * 24 + hour)

    let newSelection
    const unselected = slotsForDow.find(slotNumber =>
      !selectedSlots.includes(slotNumber)
    )
    if (typeof unselected !== 'undefined') {
      // If there is any unselected time for a give DoW,
      // select all times of a given DoW.
      newSelection = [
        ...selectedSlots,
        ...slotsForDow,
      ]
    } else {
      // If all times of a given DoW are selected, deselect them all.
      newSelection = selectedSlots.filter(slotNumber =>
        !slotsForDow.includes(slotNumber)
      )
    }

    onSlotSelect([...new Set(newSelection)].sort((a, b) => a - b))
  }

  const handleHourClick = hour => () => {
    const slotsForHour = [...Array(7).keys()].map(dow => dow * 24 + hour)

    let newSelection
    const unselected = slotsForHour.find(slotNumber =>
      !selectedSlots.includes(slotNumber)
    )
    if (typeof unselected !== 'undefined') {
      // If there is any unselected time for a give time,
      // select all times of a given time.
      newSelection = [
        ...selectedSlots,
        ...slotsForHour,
      ]
    } else {
      // If all times of a given time are selected, deselect them all.
      newSelection = selectedSlots.filter(slotNumber =>
        !slotsForHour.includes(slotNumber)
      )
    }

    onSlotSelect([...new Set(newSelection)].sort((a, b) => a - b))
  }

  const handleSelectionClear = () => {
    onSlotSelect([])
  }

  const renderRangeSelector = () => {
    if (!stats.length) {
      return null
    }

    return (
      <div className="range-selector">
        {
          rangeList.map(range => (
            <CheckboxComponent
              key={range.value}
              label={(
                <>
                  {
                    typeof range.color !== 'undefined' && (
                      <span className="bullet" style={{ backgroundColor: range.color }}></span>
                    )
                  }
                  { range.label }
                </>
              )}
              checked={!!selectedRanges[range.value]}
              onChange={(checked) => { handleRangeSelect(range.value, checked) }}
            />
          ))
        }
      </div>
    )
  }

  const renderTabSpecificFilter = () => {
    let label
    if (activeTab === 'acos') {
      label = 'With at least this ACoS'
    } else if (activeTab === 'impressions') {
      label = 'With at least this many impressions'
    } else if (activeTab === 'clicks') {
      label = 'With at least this many clicks'
    } else if (activeTab === 'cpc') {
      label = 'With at least this CPC'
    } else if (activeTab === 'conversion') {
      label = 'With at least this conversion rate'
    } else if (activeTab === 'cost') {
      label = 'With at least this spend'
    } else if (activeTab === 'orders') {
      label = 'With at least this many orders'
    }

    return (
      <div className="filter-wrapper">
        { label }
        <div className="filter-value">
          <CheckboxComponent
            checked={metricFilterChecked}
            onChange={setMetricFilterChecked}
          />
          <input
            type="number"
            min="0"
            disabled={!metricFilterChecked}
            value={metricFilterValue}
            onChange={(event) => { setMetricFilterValue(event.target.value) }}
          />
        </div>
      </div>
    )
  }

  const renderSlot = (value, dayIndex, dow) => {
    const slotNumber = dow * 24 + dayIndex

    const hasRules = slotsWithRules.includes(slotNumber)
    let existingRules = null
    let campaignInfo = null
    let templateInfo = null

    let color = COLOR_B10
    let textColor = '#000'
    if (hasRules) {
      color = fromEditor ? COLOR_FROM_TEMPLATE : COLOR_FILLED
      existingRules = findRule(slotNumber)
      if (existingRules) {
        if (existingRules.campaign) {
          campaignInfo = existingRules.campaign
        }
        if (existingRules.template) {
          templateInfo = existingRules.template
          color = COLOR_FROM_TEMPLATE
        }

        existingRules = existingRules.r
      }
    } else if (stats.length) {
      if (value.noData || value.notFiltered) {
        color = COLOR_NO_DATA
      } else if (value.percent <= 10) {
        color = COLOR_B10
      } else if (value.percent <= 25) {
        color = COLOR_B25
      } else if (value.percent < 75) {
        color = COLOR_M50
      } else if (value.percent < 90) {
        color = COLOR_U25
      } else {
        color = COLOR_U10
        textColor = '#fff'
      }
    }

    let label = ''
    if (showNumbers) {
      label = value.value

      if (activeTab === 'impressions') {
        if (label >= 1000) {
          if (label < 1000 * 1000) {
            label = `${Math.floor(label / 1000)}k`
          } else if (label < 1000 * 1000 * 1000) {
            label = `${Math.floor(label / 1000 / 1000)}m`
          } else {
            label = `${Math.floor(label / 1000 / 1000 / 1000)}g`
          }
        }
      } else {
        if (value.noData) {
          if (activeTab === 'acos' && value.clicksNoSales) {
            label = value.clicks
          } else {
            label = '-'
          }
        } else {
          if (activeTab === 'acos') {
            label = `${parseFloat(label).toFixed(0)}%`
          } else if (activeTab === 'clicks' || activeTab === 'orders') {
            //
          } else {
            label = parseFloat(label).toFixed(1)
          }
        }
      }
    }

    let title = ''
    if (activeTab === 'acos' && value.clicksNoSales) {
      title = `${value.clicks} clicks`
    } else if (value.noData) {
      title = '-'
    } else {
      if (activeTab === 'acos') {
        title = `${parseFloat(value.value).toFixed(2)}%`
      } else {
        title = value.value
      }
    }

    const slotEl = (
      <span
        key={dayIndex}
        className="slot-wrapper"
        style={{
          backgroundColor: color,
          color: textColor,
        }}
        title={title}
        onClick={handleSlotSelect(slotNumber)}
      >
        {
          selectedSlots.includes(slotNumber) && (
            <span className="select-indicator"></span>
          )
        }
        { label }
      </span>
    )

    if (!hasRules) {
      return slotEl
    }

    return (
      <CustomTooltip key={dayIndex} icon={slotEl}>
        {
          campaignInfo !== null && (
            <p>
              Campaign: <strong>{ campaignInfo.name }</strong>
            </p>
          )
        }
        {
          existingRules.map(rule => (
            <p key={rule.t}>
              { getRuleDescription(rule) }
            </p>
          ))
        }
        <p>
          {
            templateInfo !== null ? (
              <em>From template: <strong>{ templateInfo.name }</strong></em>
            ) : (
              <em>Custom rules</em>
            )
          }
        </p>
      </CustomTooltip>
    )
  }

  const renderSlots = () => {
    return (
      <div className="slot-list">
        {
          valuesWithPercent.map((dayValues, dow) => (
            <div key={dow} className="slot-row">
              <span className="dow-label" onClick={handleDowClick(dow)}>
                { dowLabels[dow] }
              </span>
              <div className="slot-stripe">
                { dayValues.map((value, dayIndex) => renderSlot(value, dayIndex, dow)) }
              </div>
            </div>
          ))
        }
        <div className="slot-row">
          <span className="dow-label"></span>
          <div className="slot-stripe">
            {
              timeLabels.map((label, time) => (
                <span
                  key={time}
                  className="time-label"
                  onClick={handleHourClick(time)}
                >
                  { label }
                </span>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="slot-selector">
      <div className="note-container">
        <div>
          <div className="slot-selector-note">
            You may add custom fields by clicking on each one individually.
            <CustomTooltip>
              <p>
                Unselected items from the grid will have
                the default bid or Smart pilot recommended bids.
              </p>
            </CustomTooltip>
          </div>
          {
            !fromEditor && (
              <div className="slot-selector-note">
                <span className="filled-color filled-green">
                </span>
                Times with existing rules.
              </div>
            )
          }
          <div className="slot-selector-note">
            <span className="filled-color filled-yellow">
            </span>
            Times with existing rules from a template.
          </div>
        </div>
        <div className="action-container">
          {
            selectedSlots.length > 0 && (
              <button
                type="button"
                className="btn btn-red"
                onClick={handleSelectionClear}
              >
                Clear Selection
              </button>
            )
          }
          {
            stats.length > 0 && (
              <Toggle
                checked={showNumbers}
                checkedChildren="Show numbers"
                unCheckedChildren="Hide numbers"
                onChange={setShowNumbers}
              />
            )
          }
        </div>
      </div>
      <div className="filter-container">
        {
          activeTab === 'acos' && (
            <>
              <div className="filter-wrapper">
                With at least this many clicks
                <div className="filter-value">
                  <CheckboxComponent
                    checked={clickFilterChecked}
                    onChange={setClickFilterChecked}
                  />
                  <input
                    type="number"
                    min="0"
                    disabled={!clickFilterChecked}
                    value={clickFilterValue}
                    onChange={(event) => { setClickFilterValue(event.target.value) }}
                  />
                </div>
              </div>
              <div className="filter-wrapper">
                With at least this amount of spend
                <div className="filter-value">
                  <CheckboxComponent
                    checked={spendFilterChecked}
                    onChange={setSpendFilterChecked}
                  />
                  <input
                    type="number"
                    min="0"
                    disabled={!spendFilterChecked}
                    value={spendFilterValue}
                    onChange={(event) => { setSpendFilterValue(event.target.value) }}
                  />
                </div>
              </div>
            </>
          )
        }
        { renderTabSpecificFilter() }
      </div>
      { renderRangeSelector() }
      { renderSlots() }
    </div>
  )
}

export default SlotSelector
