import React from 'react'
import { Progress } from 'rsuite'
import { FiAlertTriangle } from 'react-icons/fi'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import CustomTooltip from '../CommonComponents/CustomTooltip'

import {
  formatValue,
  formatCurrency,
} from '../../services/helper'

const ChartOverview = ({ salesStats, hasSpCode, currencySign, currencyRate }) => {
  let trueACoS = 0
  let organicPercent = 0
  let ppcPercent = 0
  let isRevenueBetter = true
  let isAcosBetter = true
  if (salesStats) {
    if (salesStats.sales) {
      trueACoS = salesStats.ppcCost / salesStats.sales * 100
      organicPercent = (salesStats.sales - salesStats.ppcRevenue) / salesStats.sales * 100
      ppcPercent = salesStats.ppcRevenue / salesStats.sales * 100
    }

    let pastTrueAcos = 0
    if (salesStats.past.sales) {
      pastTrueAcos = salesStats.past.ppcCost / salesStats.past.sales * 100
    }

    isRevenueBetter = salesStats.sales > salesStats.past.sales
    isAcosBetter = trueACoS > pastTrueAcos
  }

  return (
    <div className="chart-overview">
      <div className="overview-header">
        Overview
      </div>
      <div className="overview-metric">
        <div className="metric-name">
          Gross Revenue
          {
            !hasSpCode && (
              <CustomTooltip icon={<FiAlertTriangle size={16} color="#ffd156" />}>
                <p>
                  Please go to the Settings page to add the Selling Partner API connection.
                </p>
                <p>
                  Without the Selling Partner API connection,
                  Entourage will not show the organic revenue.
                </p>
              </CustomTooltip>
            )
          }
        </div>
        <div className="metric-note">
          Organic and advertising revenues combined.
        </div>
        <div className="metric-value">
          <span>
            { formatCurrency(salesStats ? salesStats.sales : 0, currencySign, currencyRate) }
          </span>
          {
            isRevenueBetter
            ? <BsCaretUpFill size={16} color="#FBAB34" />
            : <BsCaretDownFill size={16} color="#D93842" />
          }
        </div>
        <div className="metric-value-tax">
          Tax: { formatCurrency(salesStats ? salesStats.taxes : 0, currencySign, currencyRate) }
        </div>
      </div>
      <div className="overview-metric">
        <div className="metric-name">
          TACOS
          <CustomTooltip>
            <p>TACOS is the sum of PPC revenue plus organic revenue, divided by AD Spend.</p>
            <p>Since it takes organic revenue into account, it allows you to see
            how much you are spending on PPC relative to overall revenue.</p>
            <p>You can also see the beneficial affect that your PPC is having
            on organic sales by tracking this number.</p>
          </CustomTooltip>
        </div>
        <div className="metric-value">
          <span>
            { formatValue(trueACoS, 'percent', 2) }
          </span>
          {
            isAcosBetter
            ? <BsCaretUpFill size={16} color="#303AB2" />
            : <BsCaretDownFill size={16} color="#D93842" />
          }
        </div>
        <div className="revenue-split-list">
          <div className="revenue-split">
            <div className="revenue-name">
              Organic Revenue
              <CustomTooltip>
                <p>The amount of revenue generated from non-advertising sales.</p>
                <p>If you see negative organic revenue, Amazon may be experiencing
                delayed attribution (assigning revenue to certain orders).</p>
                <p>This is corrected once Amazon updates the data they send to us.</p>
              </CustomTooltip>
            </div>
            <div className="revenue-value">
              { formatValue(organicPercent, 'percent', 2) }
            </div>
          </div>
          <div className="revenue-split">
            <div className="revenue-name">
              PPC Revenue
            </div>
            <div className="revenue-value">
              { formatValue(ppcPercent, 'percent', 2) }
            </div>
          </div>
        </div>
        <Progress.Line
          percent={organicPercent}
          showInfo={false}
        />
      </div>
    </div>
  )
}

export default ChartOverview
