import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import TargetingSelector from '../CommonComponents/TargetingSelector'
import SortableTable from '../CommonComponents/SortableTableComponent'
import VideoLink from '../CommonComponents/VideoLink'
import StepBreadcrumb from '../CommonComponents/StepBreadcrumb'
import { toast } from '../CommonComponents/ToastComponent/toast'
import KeywordInputSection from '../CommonComponents/KeywordInputSection'
import ProductInputSection from '../CommonComponents/ProductInputSection'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import AudienceSection from './AddTargets/AudienceSection'

import {
  getAdgroupsToAddTargets,
  addTargets,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { getBidLimits, tableSorter } from '../../services/helper'
import { JOB_TYPE_BULK_CREATE_TARGETS } from '../../utils/defaultValues'

const STEP_TARGET = 1
const STEP_ADGROUP = 2

const stepList = [
  { value: STEP_TARGET, name: 'Add targets' },
  { value: STEP_ADGROUP, name: 'Choose ad groups' },
]

const adgroupColumns = [
  { key: 'campaignName', name: 'Campaign' },
  { key: 'name', name: 'Ad Group' },
  { key: 'count', name: 'Number of Keywords/Targets', sortable: false },
]

const MAX_KEYWORDS_PER_ADGROUP = 1000
const MAX_TARGETS_PER_ADGROUP = 10000 // 10k

const videoList = [
  { title: 'Add Targets', url: 'https://www.loom.com/embed/804c3911da16445ab53d927c36c80990' },
]

const AddTargetingModal = ({ show, campaigns, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)
  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [manualTarget, setManualTarget] = useState('keyword')
  const [step, setStep] = useState(STEP_TARGET)
  const [keywords, setKeywords] = useState([])
  const [targetings, setTargetings] = useState([])
  const [adgroupList, setAdgroupList] = useState([])
  const [selectedAdgroups, setSelectedAdgroups] = useState([])
  const [isGettingAdgroups, setIsGettingAdgroups] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (show) {
      setStep(STEP_TARGET)
      setSelectedAdgroups([])
    }
  }, [show]) // eslint-disable-line

  useEffect(() => {
    // When there are only SD campaigns, the keyword targeting is ineligible,
    // so we need to select the product targeting by default.
    let newTarget = 'product'
    if (isNonEndemicAccount) {
      newTarget = 'audience'
    } else {
      const nonSdCampaign = campaigns.find(campaign =>
        campaign.campaignType !== 'sd'
      )
      if (nonSdCampaign) {
        newTarget = 'keyword'
      }
    }

    setManualTarget(newTarget)
  }, [campaigns, isNonEndemicAccount])

  useEffect(() => {
    const campaignsById = {}
    campaigns.forEach((campaign) => {
      campaignsById[campaign.campaign_id] = {
        name: campaign.campaign,
        type: campaign.campaignType,
        targetingType: campaign.targeting_type,
        tactic: campaign.tactic,
        costType: campaign.cost_type,
      }
    })

    let adgroups = (adgroupsToAddTargetsData || []).map((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}

      return {
        ...record,
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targeting_type: campaignDetail.targetingType || '',
        tactic: campaignDetail.tactic || '',
        costType: campaignDetail.costType || '',
      }
    })

    if (manualTarget === 'keyword') {
      adgroups = adgroups.filter(adgroup => parseInt(adgroup.targets) === 0)
    } else if (manualTarget === 'product' || manualTarget === 'audience') {
      adgroups = adgroups.filter(adgroup => parseInt(adgroup.keywords) === 0)
    }
    setAdgroupList(adgroups)
  }, [adgroupsToAddTargetsData, campaigns, manualTarget])

  const handleConfirm = async () => {
    if (step !== STEP_ADGROUP) {
      if (!keywords.length && !targetings.length) {
        toast.show({
          title: 'Warning',
          description: 'Please input at least 1 keyword/target.',
        })
        return
      }

      const payload = {}
      if (manualTarget !== 'audience') {
        campaigns.forEach((campaign) => {
          if (campaign.campaignType === 'sd' && campaign.tactic === 'T00030') {
            return
          }

          if (!payload[campaign.campaignType]) {
            payload[campaign.campaignType] = []
          }
          payload[campaign.campaignType].push(campaign.campaign_id.toString())
        })
      } else {
        campaigns.forEach((campaign) => {
          if (!(campaign.campaignType === 'sd' && campaign.tactic === 'T00030')) {
            return
          }
          if (!payload[campaign.campaignType]) {
            payload[campaign.campaignType] = []
          }
          payload[campaign.campaignType].push(campaign.campaign_id.toString())
        })
      }

      if (!Object.keys(payload).length) {
        toast.show({
          title: 'Warning',
          description: 'There is no eligible campaign for a given targeting type.',
        })
        return
      }

      (async () => {
        setIsGettingAdgroups(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getAdgroupsToAddTargets(
          accessToken,
          payload,
          false // This needs to be cast to boolean explicitly.
        ))
        setIsGettingAdgroups(false)
      })()

      setStep(step + 1)
      return
    }

    // Check if there is an ad group with keywords/targets more than allowed.
    const invalid = adgroupsToAddTargetsData.find((adgroup) => {
      if (selectedAdgroups.indexOf(adgroup.adgroup_id) === -1) {
        return false
      }

      if (parseInt(adgroup.targets, 10) > 0
        && (parseInt(adgroup.targets, 10) + targetings.length) > MAX_TARGETS_PER_ADGROUP) {
        toast.show({
          title: 'Warning',
          description: `You can add up to ${MAX_TARGETS_PER_ADGROUP} product targets per ad group.`,
        })
        return true
      } else if (parseInt(adgroup.keywords, 10) > 0
        && (parseInt(adgroup.keywords, 10) + keywords.length) > MAX_KEYWORDS_PER_ADGROUP) {
        toast.show({
          title: 'Warning',
          description: `You can add up to ${MAX_KEYWORDS_PER_ADGROUP} keywords per ad group.`,
        })
        return true
      }
      return false
    })

    if (invalid) {
      return
    }

    if (selectedAdgroups.length === 0) {
      toast.show({
        title: 'Warning',
        description: 'Please select at least 1 adgroup.',
      })
      return
    }

    const targetingList = []
    const errors = []
    if (manualTarget === 'keyword') {
      adgroupList.forEach((adgroup) => {
        if (selectedAdgroups.indexOf(adgroup.adgroup_id) === -1
          || adgroup.campaignType === 'sd') {
          return
        }

        const bidLimits = getBidLimits(
          adgroup.campaignType,
          currentAccount?.country_id,
          adgroup.costType
        )

        keywords.forEach((keyword) => {
          if (keyword.keywordBid < bidLimits[0] || keyword.keywordBid > bidLimits[1]) {
            errors.push(`The bid price for "${keyword.keywordText}" should be between ${bidLimits[0]} and ${bidLimits[1]}.`)
            return
          }

          targetingList.push({
            isKtAdgroup: true,
            compositeId: `${adgroup.campaign_id}-${adgroup.adgroup_id}-${keyword.keywordText}`,
            campaignId: adgroup.campaign_id,
            campaignType: adgroup.campaignType,
            adgroupId: adgroup.adgroup_id,
            target: keyword.keywordText,
            matchType: keyword.matchType,
            bid: keyword.keywordBid,
            adgroupName: adgroup.name,
          })
        })
      })
    } else if (manualTarget === 'product') {
      adgroupList.forEach((adgroup) => {
        if (selectedAdgroups.indexOf(adgroup.adgroup_id) === -1) {
          return
        }

        const bidLimits = getBidLimits(
          adgroup.campaignType,
          currentAccount?.country_id,
          adgroup.costType
        )

        targetings.forEach((target) => {
          const bid = parseFloat(target.bid)
          if (bid < bidLimits[0] || bid > bidLimits[1]) {
            errors.push(`The bid price for "${target.name || target.asin}" should be between ${bidLimits[0]} and ${bidLimits[1]}.`)
            return
          }

          const payload = {
            campaignId: adgroup.campaign_id,
            campaignType: adgroup.campaignType,
            adgroupId: adgroup.adgroup_id,
            type: target.type,
            bid,
            tactic: adgroup.tactic,
            adgroupName: adgroup.name,
          }

          if (target.type === 'category') {
            payload.id = target.id
            payload.target = target.name
            payload.compositeId = `${adgroup.campaign_id}-${adgroup.adgroup_id}-${target.id}`
          } else if (target.type === 'product') {
            if (!target.asin) {
              return
            }
            payload.target = target.asin
            payload.compositeId = `${adgroup.campaign_id}-${adgroup.adgroup_id}-${target.asin}`
          } else if (target.type === 'refine') {
            payload.id = target.id
            payload.target = target.name
            payload.compositeId = `${adgroup.campaign_id}-${adgroup.adgroup_id}-${target.id}`
            payload.refineRules = {
              brandId: target.brandId,
              brandName: target.brandName,
              ratingValue: target.ratingValue,
              priceFrom: target.priceFrom,
              priceTo: target.priceTo,
            }
          }

          targetingList.push(payload)
        })
      })
    } else if (manualTarget === 'audience') {
      adgroupList.forEach((adgroup) => {
        if (selectedAdgroups.indexOf(adgroup.adgroup_id) === -1
          || adgroup.campaignType !== 'sd') {
          return
        }

        const bidLimits = getBidLimits(
          adgroup.campaignType,
          currentAccount?.country_id,
          adgroup.costType
        )

        targetings.forEach((target) => {
          const bid = parseFloat(target.bid)
          if (bid < bidLimits[0] || bid > bidLimits[1]) {
            errors.push(`The bid price for "${target.name}" should be between ${bidLimits[0]} and ${bidLimits[1]}.`)
            return
          }

          const payload = {
            campaignId: adgroup.campaign_id,
            campaignType: adgroup.campaignType,
            adgroupId: adgroup.adgroup_id,
            type: target.type,
            bid,
            tactic: adgroup.tactic,
            adgroupName: adgroup.name,
          }

          if (target.type === 'audience_category'
            || target.type === 'audience_product'
            || target.type === 'audience_refine') {
            if (target.type !== 'audience_refine') {
              payload.type = 'audience'
            } else {
              payload.refineRules = {
                brandId: target.brandId,
                ratingValue: target.ratingValue,
                priceFrom: target.priceFrom,
                priceTo: target.priceTo,
              }
            }
            payload.audience = target.audienceType ? target.audienceType : 'views'
            payload.id = target.id.toString().replace(target.audienceType, '')
            payload.target = target.name
            payload.lookback = target.lookback ? target.lookback.value : '30'
            payload.compositeId = `${adgroup.campaign_id}-${adgroup.adgroup_id}-${payload.id}`
          } else if (target.type === 'audience') {
            payload.audience = 'audience'
            payload.id = target.id.toString()
            payload.target = target.name
            payload.compositeId = `${adgroup.campaign_id}-${adgroup.adgroup_id}-${payload.id}`
          }

          targetingList.push(payload)
        })
      })
    }

    if (errors.length) {
      toast.show({
        title: 'Warning',
        description: errors.join('<br />'),
      })
      return
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(addTargets(
      accessToken,
      targetingList,
      manualTarget === 'audience',
      [],
      0,
      false,
    ))
    setIsAdding(false)
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_CREATE_TARGETS,
      ))
      onClose()
    }
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const renderSteps = () => {
    return (
      <StepBreadcrumb
        stepList={stepList}
        currentStep={step}
      />
    )
  }

  const renderAdgroup = (adgroup) => (
    <>
      <TableCampaignCell
        record={adgroup}
        noLink
      />
      <div className="table-col col-adgroup">
        { adgroup.name }
      </div>
      <div className="table-col">
        { parseInt(adgroup.keywords, 10) + parseInt(adgroup.targets, 10) }
      </div>
    </>
  )

  const renderInputSection = () => {
    if (manualTarget === 'keyword') {
      return (
        <KeywordInputSection
          keywords={keywords}
          onChange={setKeywords}
        />
      )
    }

    if (manualTarget === 'product') {
      return (
        <ProductInputSection
          targetings={targetings}
          onChange={setTargetings}
        />
      )
    }

    return (
      <AudienceSection
        targetings={targetings}
        onChange={setTargetings}
      />
    )
  }

  const renderContents = () => {
    if (step === STEP_TARGET) {
      let noKeyword = true
      let noAudience = true
      campaigns.forEach((campaign) => {
        if (campaign.campaignType === 'sd' && campaign.tactic === 'T00030') {
          noAudience = false
        }
        if (campaign.campaignType !== 'sd') {
          noKeyword = false
        }
      })

      return (
        <>
          {
            !isNonEndemicAccount && (
              <TargetingSelector
                manualTarget={manualTarget}
                noKeyword={noKeyword}
                noAudience={noAudience}
                onChange={setManualTarget}
              />
            )
          }
          { renderInputSection() }
        </>
      )
    }

    return (
      <SortableTable
        columns={adgroupColumns}
        defaultSort={['campaignName', 'asc']}
        sorter={tableSorter(['campaignName', 'name'])}
        className="table-adgroups"
        records={adgroupList}
        idField="adgroup_id"
        searchFields={['campaignName', 'name']}
        selectedRecords={selectedAdgroups}
        isLoading={isGettingAdgroups || isAdding}
        renderRecord={renderAdgroup}
        onChange={setSelectedAdgroups}
      />
    )
  }

  const renderFooter = () => (
    <Modal.Footer>
      {
        step !== STEP_TARGET && (
          <button
            type="button"
            className="rs-btn rs-btn-default"
            onClick={handleBack}
          >
            Back
          </button>
        )
      }
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isGettingAdgroups || isAdding}
        onClick={() => handleConfirm()}
      >
        { step === STEP_ADGROUP ? 'Confirm' : 'Next' }
      </button>
      <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className="add-target-to-campaigns-modal" backdrop="static" show={show} size="lg">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          { !isNonEndemicAccount ? 'Add Keywords/Targets' : 'Add Targets' }
          <VideoLink
            videoList={videoList}
            modalTitle="Add Targets"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="creator-section">
        { renderSteps() }
        { renderContents() }
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default AddTargetingModal
