import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns'
import { Button, Dropdown, Icon, Nav } from 'rsuite'

import MainLayout from '../../layout/MainLayout'
import DateRangeComponent from '../../components/CommonComponents/DateRangeComponent'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import GeneratedReportsModal from '../../components/GeneratedReportModal'

import { generateAuditReport } from '../../redux/actions/accountHealth'
import { checkStatus } from '../../redux/actions/job'

import { getTemporaryDownloadLink, parseDate } from '../../services/helper'

const AuditReport = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const statusCheckerInterval = useRef(null)

  // By default, select last 60 days.
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 62)))
  const [endDate, setEndDate] = useState(endOfDay(subDays(new Date(), 2)))
  const [isGenerating, setIsGenerating] = useState(false)
  const [showGeneratedReportsModal, setShowGeneratedReportsModal] = useState(false)

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      if (statusCheckerInterval.current) {
        clearInterval(statusCheckerInterval.current) // eslint-disable-line
      }
    }
  }, [])

  const handleDateRangeChange = (dates) => {
    const newStart = parseDate(dates[0])
    const newEnd = parseDate(dates[1])

    if (newStart.toString() === 'Invalid Date'
      || newEnd.toString() === 'Invalid Date') {
      return
    }

    setStartDate(dates[0])
    setEndDate(dates[1])
  }

  const handleGenerate = async () => {
    setIsGenerating(true)
    try {
      const accessToken = await getAccessTokenSilently()

      const response = await dispatch(generateAuditReport(accessToken, startDate, endDate))

      statusCheckerInterval.current = setInterval(async () => {
        // @todo: Prevent another status check API call
        // while a previous one is in flight.
        const accessToken = await getAccessTokenSilently()
        try {
          const { url } = await dispatch(checkStatus(accessToken, response.data.jobId))
          if (url) {
            toast.show({
              title: 'Success',
              description: 'The report has been generated. '
                + `You can <a href="${getTemporaryDownloadLink(url)}" target="_blank">download it here</a>`,
              duration: 10000,
            })

            setIsGenerating(false)
            clearInterval(statusCheckerInterval.current)
            statusCheckerInterval.current = null
          }
        } catch (error) {
          toast.show({
            title: 'Danger',
            description: error,
          })

          setIsGenerating(false)
          clearInterval(statusCheckerInterval.current)
          statusCheckerInterval.current = null
        }
      }, 5000)
    } catch (error) {
      setIsGenerating(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to generate audit report.',
      })
    }
  }

  return (
    <MainLayout>
      <div className="audit-report-page">
        <div className="page-header">
          <Nav appearance='subtle' activeKey={'audit-report'}>
            <Nav.Item componentClass={Link} to="/account-report">Account Reports</Nav.Item>
            <Nav.Item componentClass={Link} to="/agency-view">Agency View</Nav.Item>
            <Nav.Item eventKey="audit-report">Audit Report</Nav.Item>
          </Nav>
        </div>
        <div className="page-content">
          <div className="report-content">
            <DateRangeComponent
              value={[startDate, endDate]}
              onChange={handleDateRangeChange}
            />
            <Dropdown
              title="Download"
              placement="bottomEnd"
              disabled={isGenerating}
              renderTitle={children => (
                <Button appearance="primary">
                  { isGenerating ? 'Generating report...' : children }
                </Button>
              )}
            >
              <Dropdown.Item
                icon={<Icon icon="file-excel-o" />}
                onSelect={handleGenerate}
              >
                Generate
              </Dropdown.Item>
              <Dropdown.Item divider />
              <Dropdown.Item
                icon={<Icon icon="list" />}
                onSelect={() => setShowGeneratedReportsModal(true)}
              >
                Generated Reports
              </Dropdown.Item>
            </Dropdown>
          </div>
          {
            showGeneratedReportsModal && (
              <GeneratedReportsModal
                reportType="audit_report"
                onClose={() => { setShowGeneratedReportsModal(false) }}
              />
            )
          }
        </div>
      </div>
    </MainLayout>
  )
}

export default AuditReport
