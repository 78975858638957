import { createSelector } from 'reselect'
import { endOfDay, startOfDay, subDays } from 'date-fns'

import {
  GET_ALL_ACCOUNT_SUCCEED,
  SET_SELECTED_USER_INFO,
  GET_CURRENCY_RATE_SUCCEED,
  SET_CURRENCY_INFO,
  SET_DATE_RANGE,
} from '../actionTypes/header'

import {
  SAVE_NOTIFICATION_SUCCEED,
  SAVE_UNIVERSAL_SUCCEED,
  SAVE_BRAND_NAME_SUCCEED,
  REAUTH_SP_START,
  REAUTH_SP_SUCCEED,
  REAUTH_ADS_SUCCEED,
  REAUTH_SP_FAILED,
  REACTIVATE_SUCCEED,
} from '../actionTypes/auth'
import { isNonEndemic } from '../../services/helper'

const selectAccountList = state => state.header.accountList
const selectCurrentUserId = state => state.header.currentUserId

export const selectCurrentAccount = createSelector(
  selectAccountList,
  selectCurrentUserId,
  (accountList, currentUserId) => {
    return accountList.find(account => (
      parseInt(account.user_id, 10) === parseInt(currentUserId, 10)
    ))
  }
)

export const selectIsNonEndemicAccount = createSelector(
  selectCurrentAccount,
  account => isNonEndemic(account),
)

const initialState = {
  accountList: [],
  currentUserId: '',
  currencyRateList: {},
  // Country code of a current currency.
  currencyCode: 'us',
  currencyRate: 1,
  currencySign: '$',
  currentStartDate: startOfDay(subDays(new Date(), 29)),
  currentEndDate: endOfDay(new Date()),
  isReauthSp: false,
}

const header = (state = initialState, action) => {
	switch (action.type) {
    case GET_ALL_ACCOUNT_SUCCEED:
      return {
        ...state,
        accountList: action.data,
      }
    case SET_SELECTED_USER_INFO:
      return {
        ...state,
        currentUserId: action.data.user_id,
      }
    case GET_CURRENCY_RATE_SUCCEED:
      return {
        ...state,
        currencyRateList: action.data && action.data.length > 0 && JSON.parse(action.data[0].response),
      }
    case SET_CURRENCY_INFO:
      return {
        ...state,
        currencyCode: action.data.currencyCode,
        currencyRate: action.data.currencyRate,
        currencySign: action.data.currencySign,
      }
    case SET_DATE_RANGE:
      return {
        ...state,
        currentStartDate: action.data.startDate,
        currentEndDate: action.data.endDate
      }
    case SAVE_NOTIFICATION_SUCCEED:
      const { userId, monthlyAlert, weeklyAlert } = action.data
      return {
        ...state,
        accountList: state.accountList.map(account => {
          if (account.user_id === userId) {
            return {
              ...account,
              monthly_alert: monthlyAlert,
              weekly_alert: weeklyAlert,
            }
          }
          return account
        }),
      }
    case SAVE_UNIVERSAL_SUCCEED:
      const { userId: universalUserId, profitMargin, acos, timezone } = action.data
      return {
        ...state,
        accountList: state.accountList.map(account => {
          if (account.user_id === universalUserId) {
            return {
              ...account,
              average_profit: profitMargin,
              average_acos: acos,
              timezone,
            }
          }
          return account
        }),
      }
    case SAVE_BRAND_NAME_SUCCEED:
      return {
        ...state,
        accountList: state.accountList.map((account) => {
          if (parseInt(account.user_id, 10) === parseInt(action.data.userId, 10)) {
            return {
              ...account,
              brand_name: action.data.brandName,
            }
          }
          return account
        }),
      }
    case REAUTH_SP_START:
      return {
        ...state,
        isReauthSp: true,
      }
    case REAUTH_SP_SUCCEED:
      return {
        ...state,
        isReauthSp: false,
        accountList: state.accountList.map((account) => {
          if (parseInt(action.data.userId, 10) === parseInt(account.user_id, 10)) {
            return {
              ...account,
              hasSpCode: true,
              tokenError: '',
            }
          }
          return account
        }),
      }
    case REAUTH_ADS_SUCCEED:
      return {
        ...state,
        isReauthSp: false,
        accountList: state.accountList.map((account) => {
          if (parseInt(action.data.userId, 10) === parseInt(account.user_id, 10)) {
            return {
              ...account,
              tokenError: '',
            }
          }
          return account
        }),
      }
    case REAUTH_SP_FAILED:
      return {
        ...state,
        isReauthSp: false,
      }
    case REACTIVATE_SUCCEED:
      return {
        ...state,
        accountList: state.accountList.map((account) => {
          if (action.data.userId === parseInt(account.user_id, 10)) {
            return {
              ...account,
              cancelledAt: null,
            }
          }
          return account
        }),
      }
    default:
      return state
  }
}

export default header