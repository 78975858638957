export const FIND_TARGETS_SUCCEED = 'FIND_TARGETS_SUCCEED'

export const ADJUST_KEYWORD_BIDS_SUCCEED = 'ADJUST_KEYWORD_BIDS_SUCCEED'

export const ADJUST_TARGET_BIDS_SUCCEED = 'ADJUST_TARGET_BIDS_SUCCEED'

export const FIND_DUPS_SUCCEED = 'FIND_DUPS_SUCCEED'

export const UPDATE_KEYWORD_STATES_SUCCEED = 'UPDATE_KEYWORD_STATES_SUCCEED'

export const UPDATE_TARGET_STATES_SUCCEED = 'UPDATE_TARGET_STATES_SUCCEED'

export const GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED = 'GET_ADGROUPS_TO_ADD_TARGETS_SUCCEED'

export const GET_SKU_OP_SUCCEED = 'GET_SKU_OP_SUCCEED'

export const UPDATE_PA_STATES_SUCCEED = 'UPDATE_PA_STATES_SUCCEED'

export const GET_TARGET_OP_SUCCEED = 'GET_TARGET_OP_SUCCEED'

export const GET_ST_OP_SUCCEED = 'GET_ST_OP_SUCCEED'

export const ADD_NEGATIVES_OP_SUCCEED = 'ADD_NEGATIVES_OP_SUCCEED'

export const GET_NEGATIVE_FINDER_SUCCEED = 'GET_NEGATIVE_FINDER_SUCCEED'

export const GET_PLACEMENT_OP_SUCCEED = 'GET_PLACEMENT_OP_SUCCEED'

export const GET_ADVANCED_NEGATIVE_SUCCEED = 'GET_ADVANCED_NEGATIVE_SUCCEED'

export const ARCHIVE_NKS_SUCCEED = 'ARCHIVE_NKS_SUCCEED'

export const ARCHIVE_NTS_SUCCEED = 'ARCHIVE_NTS_SUCCEED'

export const CREATE_AD_GROUP_SUCCEED = 'CREATE_AD_GROUP_SUCCEED'

export const GET_ASIN_IMAGES_SUCCEED = 'GET_ASIN_IMAGES_SUCCEED'
