import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'
import { BsCaretDownFill } from 'react-icons/bs'

import MainLayout from '../../layout/MainLayout'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import VideoLink from '../CommonComponents/VideoLink'

import ProductDashboardComponent from './ProductDashboardComponent'
import ProductKeywordComponent from './ProductKeywordComponent'
import ProductTestingComponent from './ProductTestingComponent'
import ProductFilterComponent from './ProductFilterComponent'

import {
  getProductById,
  updateProductCog,
  getCampaignsForProduct,
} from '../../redux/actions/product'

import {
  formatValue,
  getAmazonAsinLink,
} from '../../services/helper'
import { selectCurrentAccount } from '../../redux/reducers/header'

const videoList = [
  { title: 'Product Dashboard Video', url: 'https://www.loom.com/embed/0ec635b02a3543f8aa353d607250b3bb' },
]

const TAB_DASHBOARD = 'dashboard'
const TAB_KEYWORD = 'keyword'
const TAB_TESTING = 'testing'

const tabList = [
  { value: TAB_DASHBOARD, label: 'Dashboard' },
  { value: TAB_KEYWORD, label: 'Keyword' },
  { value: TAB_TESTING, label: 'A/B Testing' },
]

const ProductDetailComponent = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const isProductLoading = useSelector(state => state.product.isProductLoading)
  const isProductKpiLoading = useSelector(state => state.product.isProductKpiLoading)
  const isUpdateProductCog = useSelector(state => state.product.isUpdateProductCog)
  const curProduct = useSelector(state => state.product.curProduct)

  const [currentTab, setCurrentTab] = useState('dashboard')
  const [showProductFilter, setShowProductFilter] = useState(false)
  const [productCog, setProductCog] = useState(0)

  useEffect(() => {
    if (!curProduct || !curProduct.cog) {
      return
    }
    setProductCog(formatValue(curProduct.cog, 'number'))
  }, [dispatch, curProduct])

  useEffect(() => {
    const ele = document.getElementsByClassName('main-content')[0]
    ele.scrollTo({
      top: 0,
      left: 0
    })
  }, []) // eslint-disable-line

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getProductById(accessToken, id))
      dispatch(getCampaignsForProduct(accessToken, id))
    })()
  }, [dispatch, id, currentUserId, currentStartDate, currentEndDate]) // eslint-disable-line

  const onUpdateAcos = async () => {
    const accessToken = await getAccessTokenSilently()
    dispatch(updateProductCog(accessToken, curProduct.id, productCog))
  }

  const isLoading = isProductLoading
    || isProductKpiLoading
    || isUpdateProductCog

  return (
    <MainLayout>
      <div className={`product-detail-component${isLoading ? ' loading' : ''}`}>
        { isLoading && <LoaderComponent /> }
        <div className="page-header">
          { showProductFilter &&
            <OutsideClickHandler onOutsideClick={() => { setShowProductFilter(false) }}>
              <ProductFilterComponent onClose={()=> { setShowProductFilter(false) }} />
            </OutsideClickHandler>
          }
          <div className="page-title-wrapper">
            <div className="page-title" onClick={() => { setShowProductFilter(true) }}>
              {
                curProduct.image && curProduct.image !== '' ? (
                  <>
                    <img src={curProduct.image} alt={curProduct.name}/>
                    <span>{curProduct.name}</span>
                  </>
                ) : (
                  <span className="no-image">{curProduct.name}</span>
                )
              }
              <BsCaretDownFill />
            </div>
            <div className="button-wrapper">
              <a
                href={getAmazonAsinLink(currentAccount, curProduct.asin)}
                className="btn btn-white"
                target="_blank"
                rel="noreferrer noopener"
              >
                Open in Amazon
              </a>
              <VideoLink
                videoList={videoList}
                modalTitle='Product Dashboard'
              />
            </div>
          </div>
        </div>
        <div className="page-tabs">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={`page-tab${currentTab === tab.value ? ' selected' : ''}`}
                onClick={() => setCurrentTab(tab.value)}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        { currentTab === TAB_DASHBOARD &&
          <ProductDashboardComponent
            productCog={productCog}
            setProductCog={setProductCog}
            onUpdateAcos={onUpdateAcos}
          />
        }
        { currentTab === TAB_KEYWORD &&
          <ProductKeywordComponent />
        }
        { currentTab === TAB_TESTING &&
          <ProductTestingComponent />
        }
      </div>
    </MainLayout>
  )
}

export default ProductDetailComponent
