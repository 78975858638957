import React from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import AdTypeSection from './AdTypeSection'

const Component = withReportSection(AdTypeSection, {
  sectionId: 'all_sponsored_ads',
  reportSectionKey: 'allSponsoredAds',
})

const AllSponsoredAds = ({
  settings,
  isLoadingSettings,
  onLoadStats,
  onSaveSettings,
}) => {
  const allSponsoredMetrics = useSelector(state => state.accountHealth.allSponsoredMetrics)
  const allSponsoredChart = useSelector(state => state.accountHealth.allSponsoredChart)
  const allSponsoredPastMetrics = useSelector(state => state.accountHealth.allSponsoredPastMetrics)
  const salesData = useSelector(state => state.accountHealth.salesData)
  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)
  const performanceData = useSelector(state => state.accountHealth.performanceData)

  return (
    <Component
      title="All Sponsored Ads"
      sectionKey="allSponsoredAds"
      noteKey="notes_all_ad_types"
      customMetricSection="all"
      settings={settings}
      isLoadingSettings={isLoadingSettings}
      onSaveSettings={onSaveSettings}
      results={allSponsoredMetrics}
      pastResults={allSponsoredPastMetrics}
      salesData={salesData}
      charts={allSponsoredChart}
      spMetrics={spMetrics}
      sbMetrics={sbMetrics}
      sdMetrics={sdMetrics}
      matchTypeSummary={performanceData ? performanceData.matchTypeSummary : []}
      onLoadStats={onLoadStats}
    />
  )
}

export default AllSponsoredAds
