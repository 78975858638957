import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'
import DupKeywordModal from './DupKeywordModal'

import {
  capitalizeFirstLetter,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
} from '../../services/helper'

import { bulkBidColumnList } from '../../utils/defaultValues'

const initialColumns = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
]

const DupKeywordTable = ({ onChangeDate }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const findDupsData = useSelector(state => state.bulkEngine.findDupsData)

  const [keywords, setKeywords] = useState([])
  const [selectedKeyword, setSelectedKeyword] = useState(null)

  useEffect(() => {
    const campaignsById = {}
    campaignsWithHistory.forEach((campaign) => {
      campaignsById[campaign.campaign_id] = {
        name: campaign.campaign,
        type: campaign.campaignType,
        targetingType: campaign.targeting_type,
        costType: campaign.cost_type,
      }
    })

    const extendedKeywords = [];
    Object.keys(findDupsData.keywords || {}).forEach((keyword) => {
      const total = {
        revenue: 0,
        cost: 0,
        impressions: 0,
        clicks: 0,
        orders: 0,
        units: 0,
        ntb_orders: 0,
        ntb_sales: 0,
        viewable_impressions: 0,
      }

      const children = [];
      findDupsData.keywords[keyword].forEach((record) => {
        const campaignDetail = campaignsById[record.campaign_id] || {}

        const campaignType = campaignDetail.type || ''

        // Filter by ad type.
        if (filterValues && filterValues['dupResult'] && filterValues['dupResult']['campaignType']) {
          if (campaignType.toLowerCase() !== filterValues['dupResult']['campaignType'].value) {
            return
          }
        }

        total.revenue += parseFloat(record.revenue || 0)
        total.cost += parseFloat(record.cost || 0)
        total.impressions += parseInt(record.impressions || 0, 10)
        total.clicks += parseInt(record.clicks || 0, 10)
        total.orders += parseInt(record.orders || 0, 10)
        total.units += parseInt(record.units || 0, 10)
        total.ntb_orders += parseInt(record.ntb_orders || 0, 10)
        total.ntb_sales += parseFloat(record.ntb_sales || 0)
        total.viewable_impressions += parseInt(record.viewable_impressions || 0, 10)

        children.push({
          ...calcDerivedMetrics(record),
          campaignName: campaignDetail.name || '',
          campaignType,
          targetingType: campaignDetail.targetingType || '',
          costType: campaignDetail.costType || '',
          matchType: capitalizeFirstLetter(record.match_type),
        })
      })

      if (children.length) {
        extendedKeywords.push({
          keyword,
          ...calcDerivedMetrics(total),
          children,
        })
      }
    })

    setKeywords(extendedKeywords)
  }, [findDupsData, campaignsWithHistory, filterValues])

  const [columns, columnSelection] = useMemo(() => {
    const availableColumns = campaignTableColumns.filter(c1 => bulkBidColumnList.find(c2 => c2.key === c1.key))
    return [
      [...initialColumns, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns])

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong className="contents">
          { record.keyword }
        </strong>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-keyword">Totals:</div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'keyword') {
        return record.keyword
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['keyword'])}
        className="table-keywords"
        records={keywords || []}
        idField="keyword"
        searchFields={['keyword']}
        noCheckBox
        hasSticky
        hasDateRange
        hasLifetimeRange
        filterName="dupResult"
        columnEditorId="dupKeywordResult"
        columnList={bulkBidColumnList}
        columnSelection={columnSelection}
        exportFileName="Find Duplicate Targets"
        getExportData={getExportData}
        renderRecord={renderKeyword}
        renderTotal={renderTotal}
        onClickRecord={setSelectedKeyword}
        onChangeDate={onChangeDate}
      />
      <DupKeywordModal
        duplicatedKeyword={selectedKeyword}
        onClose={() => { setSelectedKeyword(null) }}
      />
    </>
  )
}

export default DupKeywordTable
