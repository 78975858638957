/** Define filter fields for various pages */

import { ruleNames } from "./defaultValues"

export const matchTypes = [
  { value: '', label: 'All' },
  { value: 'broad', label: 'Broad' },
  { value: 'phrase', label: 'Phrase' },
  { value: 'exact', label: 'Exact' },
]

export const STATE_OPTION_ENABLED = { value: 'enabled', label: 'Active Only' }

export const keywordStatuses = [
  { value: '', label: 'Active & Paused' },
  { value: 'enabled', label: 'Active Only' },
]

export const campaignTypes = [
  { value: 'sp', label: 'Sponsored Product' },
  { value: 'sb', label: 'Sponsored Brand' },
  { value: 'sd', label: 'Sponsored Display' },
]

export const CAMPAIGN_ACTIVE_STATUS_OPTION = {
  value: 'enabled',
  label: 'Active',
  type: 'status',
}

export const campaignOptions = [
  CAMPAIGN_ACTIVE_STATUS_OPTION,
  { value: 'paused', label: 'Paused', type: 'status' },
  { value: 'sp', label: 'Sponsored Product', type: 'ad_type' },
  { value: 'sb', label: 'Sponsored Brand', type: 'ad_type' },
  { value: 'sd', label: 'Sponsored Display', type: 'ad_type' },
]

export const targetingTypes = [
  { value: '', label: 'All' },
  { value: 'auto', label: 'Automatic' },
  { value: 'manual', label: 'Manual' },
]

export const negativeMatchTypes = [
  { value: '', label: 'All' },
  { value: 'negativeexact', label: 'Negative Exact' },
  { value: 'negativephrase', label: 'Negative Phrase' },
]

const campaignAttributeOptions = [
  { value: 'is_sp_active', label: 'Smart Pilot On' },
  { value: 'product_targeting', label: 'Product Targeting' },
  { value: 'keyword_targeting', label: 'Keyword Targeting' },
  { value: 'exact', label: 'Exact Match' },
  { value: 'phrase', label: 'Phrase Match' },
  { value: 'broad', label: 'Broad Match' },
  { value: 'auto_campaign', label: 'Automatic Campaigns' },
  { value: 'placement', label: 'Active Placement Modifiers' },
  { value: 'legacy', label: 'Dynamic Bids - Down Only' },
  { value: 'manual', label: 'Fixed Bid' },
  { value: 'auto', label: 'Dynamic Bids - Up and Down' },
  { value: 'rule_dayparting', label: ruleNames.dp },
  { value: 'rule_weekparting', label: ruleNames.wp },
  { value: 'rule_performance', label: ruleNames.cp },
  { value: 'rule_event_planning', label: ruleNames.ep },
]

const campaignDetailSku = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'cpc', label: 'Ave CPC' },
]

const campaignDetailBidKeyword = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const campaignDetailBidTarget = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const campaignDetailSt = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
]

const campaignDetailKeywordCleaner = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'revenue', label: 'Sales' },
  { key: 'cpc', label: 'Ave CPC' },
]

const campaignDetailNegative = [
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'roas', label: 'ROAS' },
]

const campaignDetailPlacement = [
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
  { key: 'cpc', label: 'Ave CPC' },
]

const campaignTable = [
  {
    key: 'attributes',
    label: 'Campaigns with ...',
    type: 'multi-select',
    options: campaignAttributeOptions,
  },
  {
    key: 'negative_attributes',
    label: 'Campaigns Without ...',
    type: 'multi-select',
    options: campaignAttributeOptions,
    isNegative: true,
  },
  { key: 'acos', label: 'ACoS %' },
  { key: 'daily_budget', label: 'Daily Budget' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'roas', label: 'ROAS' },
  { key: 'conversion', label: 'Conversion %' },
  { key: 'ntb_orders', label: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales' },
  { key: 'ntb_sales_percent', label: 'NTB Sales %' },
  { key: 'viewable_impressions', label: 'Viewable Imp' },
  { key: 'vtr', label: 'VTR %' },
  { key: 'vctr', label: 'VCTR %' },
  { key: 'video_views_25', label: 'First Quartile' },
  { key: 'video_views_50', label: 'Midpoint' },
  { key: 'video_views_75', label: 'Third Quartile' },
  { key: 'video_views_100', label: 'Complete' },
  { key: 'video_5s_views', label: '5 Sec View' },
  { key: 'view_5_seconds_rate', label: '5 Sec VR %' },
]

const budgetRecommendationTable = [
  { key: 'acos', label: 'ACoS %' },
  { key: 'daily_budget', label: 'Daily Budget' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'revenue', label: 'Sales' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'roas', label: 'ROAS' },
  { key: 'conversion', label: 'Conversion %' },
]

const productTable = [
  { key: 'cog', label: 'Cost Of Goods' },
  { key: 'break_even_cpa', label: 'Break Even CPA' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'acos', label: 'ACoS %' },
]

const portfolioTable = [
  { key: 'daily_budget', label: 'Daily Budget' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cost', label: 'Spend' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'orders', label: 'Orders' },
  { key: 'revenue', label: 'Sales' },
  { key: 'acos', label: 'ACoS %' },
]

const productCampaignTable = [
  { key: 'campaignType', label: 'Ad Type', type: 'select', options: campaignTypes },
  { key: 'targeting_type', label: 'Targeting', type: 'select', options: targetingTypes },
  {
    key: 'state',
    label: 'Campaign Status',
    type: 'select', options: [
      { value: '', label: 'All' },
      { value: ['enabled', 'paused'], label: 'Active & Paused' },
      STATE_OPTION_ENABLED,
      { value: 'paused', label: 'Paused Only' },
      { value: 'archived', label: 'Archived' },
    ],
  },
  { key: 'acos', label: 'ACoS %' },
  { key: 'daily_budget', label: 'Daily Budget' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'clicks', label: 'Clicks' },
]

const targetSearchResult = [
  { key: 'acos', label: 'ACoS %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const dupResult = [
  { key: 'campaignType', label: 'Ad Type', type: 'select', options: campaignTypes, customProcessing: true },
  { key: 'acos', label: 'ACoS %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const stEx = [
  { key: 'acos', label: 'Target ACoS Range, %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
]

const ptExAsin = [
  { key: 'acos', label: 'Target ACoS Range, %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
]

const ptExCategory = [
  { key: 'acos', label: 'Target ACoS Range, %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
]

const bulkSkuOp = [
  { key: 'target_acos', label: 'Above Target ACoS', type: 'target_acos' },
  { key: 'acos', label: 'ACoS %', depends: 'target_acos', dependValue: false },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const bulkTargetOp = [
  { key: 'target_acos', label: 'Above Target ACoS', type: 'target_acos' },
  { key: 'acos', label: 'ACoS %', depends: 'target_acos', dependValue: false },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const bulkStOp = [
  { key: 'target_acos', label: 'Above Target ACoS', type: 'target_acos' },
  { key: 'acos', label: 'ACoS %', depends: 'target_acos', dependValue: false },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const bulkAdvancedOp = [
  { key: 'acos', label: 'ACoS %' },
  { key: 'orders', label: 'Orders' },
  { key: 'cost', label: 'Spend' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion' },
]

const tagTable = [
  { key: 'daily_budget', label: 'Daily Budget' },
  { key: 'impressions', label: 'Impressions' },
  { key: 'clicks', label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cost', label: 'Spend' },
  { key: 'cpc', label: 'Ave CPC' },
  { key: 'orders', label: 'Orders' },
  { key: 'revenue', label: 'Sales' },
  { key: 'acos', label: 'ACoS %' },
]

export const bulkUpdateCampaignTypes = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Rank', label: 'Rank' },
  { value: 'Defense', label: 'Defense' },
  { value: 'Scale', label: 'Scale' },
  { value: 'Catch All', label: 'Catch All' },
  { value: 'Low Bid', label: 'Low Bid' },
  { value: 'High Bid', label: 'High Bid' },
  { value: 'ACoS Scraping', label: 'ACoS Scraping' }
]

export const bulkUpdateMatchTypes = [
  { value: 'Broad', label: 'Broad' },
  { value: 'Phrase', label: 'Phrase' },
  { value: 'Exact', label: 'Exact' },
  { value: 'Modified Broad', label: 'Modified Broad' }
]

export const bulkUpdateSponsoredProducts = [
  { value: 'TOS', label: 'TOS' },
  { value: 'PP', label: 'PP' },
  { value: 'Down Only', label: 'Down Only' },
  { value: 'Up-Down', label: 'Up-Down' },
  { value: 'Fixed', label: 'Fixed' }
]

export const bulkUpdateSponsoredDisplay = [
  { value: 'Sponsored Display', label: 'Sponsored Display' },
  { value: 'OpReach ', label: 'OpReach' },
  { value: 'OpVisits', label: 'OpVisits' },
  { value: 'OpConv', label: 'OpConv' },
  { value: 'Contextual', label: 'Contextual' },
  { value: 'Audiences', label: 'Audiences' },
  { value: 'Lifestyle', label: 'Lifestyle' },
  { value: 'Interests', label: 'Interests' },
  { value: 'Life events', label: 'Life events' },
  { value: 'In-market', label: 'In-market' },
  { value: 'V remarketing', label: 'V remarketing' },
  { value: 'P remarketing', label: 'P remarketing' },
  { value: 'Custom Image', label: 'Custom Image' },
  { value: 'Custom headline', label: 'Custom headline' },
  { value: '7', label: '7' },
  { value: '14', label: '14' },
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
  { value: '180', label: '180' },
  { value: '360', label: '360' },
]

export const bulkUpdateSponsoredBrand = [
  { value: 'Storefront', label: 'Storefront' },
  { value: 'Spotlight', label: 'Spotlight' },
  { value: 'Custom image', label: 'Custom image' }
]

const filterDef = {
  campaignDetailSku,
  campaignDetailBidKeyword,
  campaignDetailBidTarget,
  campaignDetailSt,
  campaignDetailKeywordCleaner,
  campaignDetailNegative,
  campaignDetailPlacement,
  campaignTable,
  budgetRecommendationTable,
  productTable,
  portfolioTable,
  tagTable,
  productCampaignTable,
  targetSearchResult,
  dupResult,
  stEx,
  ptExAsin,
  ptExCategory,
  bulkSkuOp,
  bulkTargetOp,
  bulkStOp,
  bulkAdvancedOp,
  bulkUpdateCampaignTypes,
  bulkUpdateMatchTypes,
  bulkUpdateSponsoredProducts,
  bulkUpdateSponsoredDisplay,
  bulkUpdateSponsoredBrand
}

export default filterDef
