import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Toggle, TagGroup, Tag } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import DashboardChartComponent from '../DashboardChartComponent'

import { getStats } from '../../redux/actions/campaign'

import ActivityLogTable from '../CommonComponents/ActivityLogTable'

const ChartViewModal = ({ campaigns, onClose, onCloseCampaign }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isStatsLoading, setIsStatsLoading] = useState(false)
  const [stats, setStats] = useState(null)
  const [isLogView, setIsLogView] = useState(false)
  const [isShowCampaigns, setIsShowCampaigns] = useState(true)

  useEffect(() => {
    if (!campaigns.length) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsStatsLoading(true)
      dispatch(getStats(accessToken, campaigns.map(c => c.campaign_id))).then((res) => {
        setStats(res)
        setIsStatsLoading(false)
      }).catch(() => setIsStatsLoading(false))
    })()
  }, [campaigns]) // eslint-disable-line

  return (
    <Modal
      className={`chart-view-modal ${isStatsLoading ? 'loading' : ''}`}
      backdrop="static"
      show
      size="lg"
      overflow={false}
    >
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          { (campaigns || []).length === 1 ? `Chart View: ${campaigns[0].campaign}` : 'Chart View' }
          {
            (campaigns || []).length > 1 ? (
              <Toggle
                checked={isShowCampaigns}
                checkedChildren="Show Campaigns"
                unCheckedChildren="Hide Campaigns"
                onChange={setIsShowCampaigns}
              />
            ) : (
              <Toggle
                checked={isLogView}
                checkedChildren="Show Logs"
                unCheckedChildren="Hide Logs"
                onChange={setIsLogView}
              />
            )
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (campaigns.length > 1 && isShowCampaigns) && (
            <TagGroup>
              {
                campaigns.map(c => (
                  <Tag key={c.campaign_id} closable onClose={() => { onCloseCampaign(c.campaign_id) }}>
                    {c.campaign}
                  </Tag>
                ))
              }
            </TagGroup>
          )
        }
        <DashboardChartComponent
          isLoading={isStatsLoading}
          isOverview={false}
          isLogView={isLogView}
          stats={stats}
        />
        {
          campaigns.length > 1 && (
            <div className="log-view-hint">
              Log Views available for Single Campaign only.
            </div>
          )
        }
        {
          isLogView && (
            <ActivityLogTable
              isLoading={isStatsLoading}
              currentLogs={stats?.logs || []}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChartViewModal
