import React from 'react'

const Header = ({ isSaved, onClose }) => {
  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Rank
          </span>
        </div>
        <div className="right-column">
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      {
        !isSaved && (
          <div className="save-instruction">
            You need to click on "Save Rules" to save your changes.
          </div>
        )
      }
    </div>
  )
}

export default Header
