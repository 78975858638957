import React, { useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton, Button, ButtonGroup, Input, SelectPicker } from 'rsuite'

import { addNewCustomMetric } from '../../redux/actions/pageGlobal'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { mainMetricList } from '../../utils/defaultValues'

const operatorList = [
  { value: 'plus', label: '+'},
  { value: 'minus', label: '-'},
  { value: 'division', label: '/'},
  { value: 'multiple', label: '*'},
  { value: 'percent', label: '%'},
]

const NewCustomMetricWidget = ({ section }) => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const availableMetrics = useMemo(() => {
    if (!isNonEndemicAccount) {
      return mainMetricList
    }
    return mainMetricList.filter(metric => (
      ![
        'organic_revenue',
        'revenue',
        'orders',
        'ntb_orders',
        'ntb_sales',
        'ntb_units',
        'videoViews25',
        'videoViews50',
        'videoViews75',
        'videoViews100',
        'video5sViews',
      ].includes(metric.value)
    ))
  }, [isNonEndemicAccount])

  const [step, setStep] = useState(0)
  const [metricTitle, setMetricTitle] = useState('')
  const [xMetric, setXMetric] = useState(null)
  const [yMetric, setYMetric] = useState(null)
  const [operator, setOperator] = useState('plus')

  const handleSaveNewMetric = () => {
    const operatorLabel = operatorList.find(opt => opt.value === operator).label
    dispatch(addNewCustomMetric({
      title: metricTitle,
      xMetric: xMetric.value,
      yMetric: yMetric.value,
      operator,
      section,
      type: operator === 'percent' ? operator : 'number',
      tooltip: `${xMetric.label} ${operatorLabel} ${yMetric.label}`
    }))
    initWidget()
  }

  const handleDiscard = () => {
    initWidget()
  }

  const initWidget = () => {
    setStep(0)
    setMetricTitle('')
    setXMetric(null)
    setYMetric(null)
    setOperator('plus')
  }

  const renderContents = () => {
    if (step === 0) {
      return (
        <div className="create-new-metric-btn">
          <IconButton
            icon={<Icon icon="plus"/>}
            size="md"
            appearance="primary"
            placement="left"
            onClick={() => setStep(1)}
          >
            Custom Metric
          </IconButton>
        </div>
      )
    }

    if (step === 1) {
      return (
        <div className="step input-name">
          <Input
            placeholder="New Metric Name Here"
            size="sm"
            value={metricTitle}
            onChange={(v) => setMetricTitle(v)}
          />
        </div>
      )
    }

    if (step === 2) {
      return (
        <div className="step select-first-metric">
          <span className="new-metric-title">{metricTitle}</span>
          <SelectPicker
            className="metric-selector"
            data={availableMetrics.filter(m => m.available.includes(section))}
            placeholder="Select First Metric"
            searchable={false}
            cleanable={false}
            value={xMetric ? xMetric.value : ''}
            onChange={(v) => setXMetric(p => {
              const { value, label } = availableMetrics.find(m => m.value === v)
              return { value, label }
            })}
          />
        </div>
      )
    }

    if (step === 3) {
      return (
        <div className="step select-first-metric">
          <span className="new-metric-title">{metricTitle}</span>
          <SelectPicker
            className="metric-selector"
            data={availableMetrics.filter(m => m.available.includes(section))}
            placeholder="Select Second Metric"
            searchable={false}
            cleanable={false}
            value={yMetric ? yMetric.value : ''}
            onChange={(v) => setYMetric(p => {
              const { value, label } = availableMetrics.find(m => m.value === v)
              return { value, label }
            })}
          />
        </div>
      )
    }

    return (
      <div className="step select-first-metric">
        <span className="new-metric-title">{metricTitle}</span>
        <div className="new-metric-formula">
          <span>{xMetric.label}</span>
          <SelectPicker
            className="operator-selector"
            data={operatorList}
            placeholder="Select Second Metric"
            searchable={false}
            cleanable={false}
            value={operator}
            onChange={(v) => setOperator(v)}
          />
          <span>{yMetric.label}</span>
        </div>
      </div>
    )
  }

  const isDisableNextBtn = (step === 1 && metricTitle === '')
    || (step === 2 && !xMetric)
    || (step === 3 && !yMetric)

  return (
    <div className="report-widget">
      <div className="report-widget-content">
        { renderContents() }
        {
          step > 0 && (
            <div className="step-action-btn">
              <Button appearance="primary" size="xs" onClick={handleDiscard}>Discard</Button>
              <ButtonGroup>
                <Button appearance="primary" size="xs" disabled={step === 1} onClick={() => setStep(p => p - 1)}>Back</Button>
                {(step !== 4) ? (
                  <Button appearance="primary" size="xs" onClick={() => setStep(p => p + 1)} disabled={isDisableNextBtn}>Next</Button>
                ) : (
                  <Button appearance="primary" size="xs" onClick={handleSaveNewMetric}>Done</Button>
                )}
              </ButtonGroup>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default NewCustomMetricWidget
