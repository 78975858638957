import React, { useEffect, useMemo } from 'react'
import { addDays } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

import RuleEvent from './RuleEvent'
import { getISODate, parseDate } from '../../services/helper'

const RuleSection = ({ hasSpCampaign, slots, currentTemplates, timezone, onChange }) => {
  const templateRulesWithId = useMemo(() => {
    const rules = []
    const currentOffset = getTimezoneOffset(timezone)
    currentTemplates.forEach((template, index) => {
      const templateOffset = getTimezoneOffset(template.timezone)
      const diff = Math.round((currentOffset - templateOffset) / 1000 / 60 / 60 / 24)

      template.slots.forEach((slot) => {
        rules.push(Object.assign({}, slot, {
          id: `${Math.floor(Math.random() * 1000).toString()}-${index}-template`,
          s: getISODate(addDays(parseDate(slot.s), diff)),
          e: getISODate(addDays(parseDate(slot.e), diff)),
          r: slot.r.map((rule, ruleIndex) => (
            Object.assign({}, rule, {
              id: `${Math.floor(Math.random() * 1000).toString()}-${ruleIndex}`,
            })
          )),
          template,
        }))
      })
    })

    return rules
  }, [currentTemplates, timezone])

  useEffect(() => {
    if (!slots.length && !currentTemplates.length) {
      onChange([
        {
          id: `${Math.floor(Math.random() * 1000).toString()}-${slots.length}`,
        },
      ])
    }
  }, [slots, currentTemplates]) // eslint-disable-line

  const handleSectionAdd = () => {
    onChange([
      ...slots,
      {
        id: `${Math.floor(Math.random() * 1000).toString()}-${slots.length}`,
      },
    ])
  }

  const handleSectionChange = slotId => (values) => {
    onChange(slots.map((slot) => {
      if (slot.id !== slotId) {
        return slot
      }
      return {
        id: slot.id,
        ...values,
      }
    }))
  }

  const handleSectionRemove = slotId => () => {
    onChange(slots.filter(slot => (
      slot.id !== slotId
    )))
  }

  return (
    <div className="rule-section">
      <div className="section-header">
        Add/Edit Rules
      </div>
      {
        slots.map(slot => (
          <RuleEvent
            key={slot.id}
            slot={slot}
            hasSpCampaign={hasSpCampaign}
            onChange={handleSectionChange(slot.id)}
            onRemove={handleSectionRemove(slot.id)}
          />
        ))
      }
      {
        templateRulesWithId.map(slot => (
          <RuleEvent
            key={slot.id}
            slot={slot}
            hasSpCampaign={hasSpCampaign}
            fromTemplate
          />
        ))
      }
      <div className="section-footer">
        <button
          type="button"
          className="btn btn-white"
          onClick={handleSectionAdd}
        >
          + Add new
        </button>
      </div>
    </div>
  )
}

export default RuleSection
