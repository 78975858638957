import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'rsuite'
import { FiInfo } from 'react-icons/fi'

import { LOGIN_CLIENT_ID } from '../../config/api'

import { signup } from '../../redux/actions/auth'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import AmazonSection from './SignupAuditAmazonSection'

import {
  regionList,
  STORAGE_KEY_REGION
} from '../../utils/defaultValues'

const SignupAuditComplete = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const signupBasicInfo = useSelector(state => state.auth.signupBasicInfo)
  const isADCodeGetting = useSelector(state => state.auth.isADCodeGetting)
  const adRefreshToken = useSelector(state => state.auth.adRefreshToken)
  const profileList = useSelector(state => state.auth.profileList)

  const [region, setRegion] = useState(regionList[0])
  const [selectedProfileIds, setSelectedProfileIds] = useState([])
  const [isSigning, setIsSigning] = useState(false)

  // Inject Amazon LWA script.
  useEffect(() => {
    let amazonRoot = document.getElementById('amazon-root')
    if (!amazonRoot) {
      amazonRoot = document.createElement('div')
      amazonRoot.setAttribute('id', 'amazon-root')
      document.body.appendChild(amazonRoot)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        window.onAmazonLoginReady = function() {
          amazon.Login.setClientId('${LOGIN_CLIENT_ID}');
        };
        (function(d) {
          var a = d.createElement('script'); a.type = 'text/javascript';
          a.async = true; a.id = 'amazon-login-sdk';
          a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
          d.getElementById('amazon-root').appendChild(a);
        })(document);
      `
      document.body.appendChild(script)
    }

    const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
    if (savedRegion) {
      const saved = regionList.find(option => option.value === savedRegion)
      if (saved) {
        setRegion(saved)
      }
    }
  }, [])

  useEffect(() => {
    if (signupBasicInfo === null) {
      history.push('/signup-audit')
    }
  }, []) // eslint-disable-line

  const handleRegionChange = (option) => {
    setRegion(option)
    window.sessionStorage.setItem(STORAGE_KEY_REGION, option.value)
  }

  const handleComplete = async () => {
    setIsSigning(true)

    const profiles = []
    profileList.forEach((profile) => {
      if (selectedProfileIds.indexOf(profile.profileId) === -1) {
        return
      }
      profiles.push({
        profileId: profile.profileId,
        countryCode: profile.countryCode.toLowerCase(),
        sellerStringId: profile.accountInfo.sellerStringId,
        brandEntityId: profile.accountInfo.brandEntityId,
        brandName: profile.accountInfo.brandName,
        type: profile.accountInfo.type.toLowerCase(),
        subType: (profile.accountInfo.subType || '').toLowerCase(),
      })
    })

    if (!profiles.length) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: 'Please select accounts.',
      })
      return
    }

    window.sessionStorage.removeItem(STORAGE_KEY_REGION)

    dispatch(signup({
      firstName: signupBasicInfo.firstName,
      lastName: signupBasicInfo.lastName,
      email: signupBasicInfo.email,
      couponCode: '',
      adRefreshToken,
      billingFirstName: signupBasicInfo ? signupBasicInfo.firstName : '',
      billingLastName: signupBasicInfo ? signupBasicInfo.lastName : '',
      stripeToken: null,
      buyUpsell: false,
      profiles,
    })).then(() => {
      window.gtag('event', 'singup_complete')
      setIsSigning(false)
      window.location.href = 'https://go.oncehub.com/AdSpendInvestigators'
    }).catch((error) => {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const isFilled = adRefreshToken
    && !isSigning
    && selectedProfileIds.length > 0

  const isSelectedAmazon = adRefreshToken && selectedProfileIds.length > 0

  return (
    <div className="signup-complete-page">
      <Modal backdrop="static" show size="sm" className="signup-complete-modal">
        <Modal.Header closeButton={false}>
          <Modal.Title>
            Final Step: Choose Your Amazon Accounts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${adRefreshToken ? '' : 'overflow-none'}`}>
          <div className="modal-description">
            <FiInfo size={16} color="#303AB2" />
            Trusted by thousands of sellers. Money Back Guarantee. Cancel anytime.
          </div>
          <AmazonSection
            isLoading={isADCodeGetting}
            adRefreshToken={adRefreshToken}
            region={region}
            regionList={regionList}
            profileList={profileList}
            selectedProfileIds={selectedProfileIds}
            onChange={handleRegionChange}
            onSelectProfileIds={setSelectedProfileIds}
          />
        </Modal.Body>
        { isSigning && <LoaderComponent /> }
        {
          isSelectedAmazon && (
            <Modal.Footer>
              <button
                type="button"
                className="rs-btn rs-btn-primary"
                disabled={!isFilled}
                onClick={handleComplete}
              >
                Continue
              </button>
            </Modal.Footer>
          )
        }
      </Modal>
    </div>
  )
}

export default SignupAuditComplete
