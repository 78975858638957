import {
  RULE_TYPE_IR,
  RULE_TYPE_IR_TEMPLATE_EDITOR,
  RULE_TYPE_EP,
  RULE_TYPE_EP_TEMPLATE_EDITOR,
  RULE_TYPE_RR,
  RULE_TYPE_CP,
  RULE_TYPE_CP_TEMPLATE_EDITOR,
  RULE_TYPE_WP,
  RULE_TYPE_WP_TEMPLATE_EDITOR,
  RULE_TYPE_DP,
  RULE_TYPE_DP_TEMPLATE_EDITOR,
  RULE_TYPE_SP_TEMPLATE_EDITOR,
  RULE_TYPE_SP,
} from '../../utils/defaultValues'

import { CHECK_AUTH_SUCCEED } from '../actionTypes/auth'
import { GET_ALL_ACCOUNT_START } from '../actionTypes/header'
import { GET_DASHBOARD_CAMPAIGN_RULES } from '../actionTypes/dashboard.js'

import {
  RULE_MANAGER_SHOW,
  GET_TEMPLATE_TO_EDIT,
  UPDATE_CAMPAIGN_SELECTION,
  TURN_RULES_SUCCESS,
  DELETE_FROM_TEMPLATE_SUCCESS,
} from '../actionTypes/ruleManager'

import {
  CREATE_DP_TEMPLATE,
  UPDATE_DP_TEMPLATE,
} from '../actionTypes/dayparting'

import {
  CREATE_WP_TEMPLATE,
  UPDATE_WP_TEMPLATE,
} from '../actionTypes/weekparting'

import {
  CREATE_EP_TEMPLATE,
  UPDATE_EP_TEMPLATE,
} from '../actionTypes/eventPlanning'

import {
  CREATE_IR_TEMPLATE,
  UPDATE_IR_TEMPLATE,
} from '../actionTypes/inventory'

import { TURN_AP_RULE_SUCCESS } from '../actionTypes/ap'

const initialState = {
  visibleRuleManager: '',
  campaigns: [],
  products: [],
  templateToEdit: null,
  campaignRulesData: [],
}

const ruleManager = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_AUTH_SUCCEED:
      return {
        ...initialState,
      }
    case GET_ALL_ACCOUNT_START:
      return {
        ...state,
        visibleRuleManager: '',
      }
    case RULE_MANAGER_SHOW:
      if ([
        RULE_TYPE_DP,
        RULE_TYPE_WP,
        RULE_TYPE_CP,
        RULE_TYPE_RR,
        RULE_TYPE_EP,
        RULE_TYPE_IR,
      ].includes(action.ruleType)) {
        return {
          ...initialState,
          visibleRuleManager: action.ruleType || '',
          campaigns: action.campaigns,
          products: action.products,
          campaignRulesData: state.campaignRulesData || [],
        }
      }
      if ([
        RULE_TYPE_SP_TEMPLATE_EDITOR,
        RULE_TYPE_DP_TEMPLATE_EDITOR,
        RULE_TYPE_WP_TEMPLATE_EDITOR,
        RULE_TYPE_CP_TEMPLATE_EDITOR,
        RULE_TYPE_EP_TEMPLATE_EDITOR,
        RULE_TYPE_IR_TEMPLATE_EDITOR,
      ].includes(action.ruleType)) {
        return {
          ...state,
          visibleRuleManager: action.ruleType || '',
          templateToEdit: action.template,
        }
      }
      return {
        ...state,
        visibleRuleManager: action.ruleType || '',
      }
    case GET_TEMPLATE_TO_EDIT:
    case CREATE_DP_TEMPLATE:
    case UPDATE_DP_TEMPLATE:
    case CREATE_WP_TEMPLATE:
    case UPDATE_WP_TEMPLATE:
    case CREATE_EP_TEMPLATE:
    case UPDATE_EP_TEMPLATE:
    case CREATE_IR_TEMPLATE:
    case UPDATE_IR_TEMPLATE:
      return {
        ...state,
        templateToEdit: action.template,
      }
    case UPDATE_CAMPAIGN_SELECTION:
      return {
        ...state,
        campaigns: action.campaigns,
      }
    case GET_DASHBOARD_CAMPAIGN_RULES:
      return {
        ...state,
        campaignRulesData: action.data.map((campaign) => {
          let rules = []

          // Inventory rules
          if (typeof campaign.ir !== 'undefined') {
            rules.push({
              id: RULE_TYPE_IR,
              type: RULE_TYPE_IR,
              campaign,
              status: campaign.ir,
              templates: campaign.ir_templates || [],
            })
          }

          // Event planning/seasonality
          if (typeof campaign.ep !== 'undefined') {
            rules.push({
              id: RULE_TYPE_EP,
              type: RULE_TYPE_EP,
              campaign,
              status: campaign.ep,
              templates: campaign.ep_templates || [],
            })
          }

          // Rank rules
          if (typeof campaign.rr !== 'undefined') {
            rules.push({
              id: RULE_TYPE_RR,
              type: RULE_TYPE_RR,
              campaign,
              status: campaign.rr,
              templates: [],
            })
          }

          // Campaign perfornace
          if (typeof campaign.cp !== 'undefined') {
            rules.push({
              id: RULE_TYPE_CP,
              type: RULE_TYPE_CP,
              campaign,
              status: campaign.cp,
              templates: campaign.cp_templates || [],
            })
          }

          // Weekparting
          if (typeof campaign.wp !== 'undefined') {
            rules.push({
              id: RULE_TYPE_WP,
              type: RULE_TYPE_WP,
              campaign,
              status: campaign.wp,
              templates: campaign.wp_templates || [],
            })
          }

          // Dayparting
          if (typeof campaign.dp !== 'undefined') {
            rules.push({
              id: RULE_TYPE_DP,
              type: RULE_TYPE_DP,
              campaign,
              status: campaign.dp,
              templates: campaign.dp_templates || [],
            })
          }

          if (typeof campaign.sp_campaign_level !== 'undefined'
            || (typeof campaign.sp_adgroup_levels !== 'undefined'
              && Object.keys(campaign.sp_adgroup_levels).length)) {

            // Smart pilot/campaign level
            if (typeof campaign.sp_campaign_level !== 'undefined') {
              rules.push({
                id: RULE_TYPE_SP,
                type: RULE_TYPE_SP,
                campaign,
                name: 'Smart Pilot (Campaign Level)',
                status: campaign.sp_campaign_level,
                basicEnabled: campaign.sp_campaign_level_basic,
                advancedEnabled: campaign.sp_campaign_level_advanced,
                templates: campaign.sp_template ? [campaign.sp_template] : [],
              })
            }

            // Smart pilot/ad group level
            if (typeof campaign.sp_adgroup_levels !== 'undefined'
              && Object.keys(campaign.sp_adgroup_levels).length) {
              Object.keys(campaign.sp_adgroup_levels).forEach((adgroupId) => {
                rules.push({
                  id: `${RULE_TYPE_SP}-${adgroupId}`,
                  type: RULE_TYPE_SP,
                  campaign,
                  adgroup: campaign.sp_adgroup_levels[adgroupId],
                  name: 'Smart Pilot (Ad Group Level)',
                  status: campaign.sp_adgroup_levels[adgroupId].status,
                  basicEnabled: campaign.sp_adgroup_levels[adgroupId].basic,
                  advancedEnabled: campaign.sp_adgroup_levels[adgroupId].advanced,
                  templates: [],
                })
              })
            }
          }

          return Object.assign(campaign, {
            children: rules,
          })
        }),
      }
    case TURN_AP_RULE_SUCCESS:
      return {
        ...state,
        campaignRulesData: (state.campaignRulesData || []).map((campaign) => {
          if (campaign.campaign_id.toString() !== action.data.campaignId) {
            return campaign
          }
          return Object.assign({}, campaign, {
            children: campaign.children.map((child) => {
              if (child.type !== RULE_TYPE_SP) {
                return child
              }
              if ((action.data.adgroupId && action.data.adgroupId !== '0' && !child.adgroup)
                || ((!action.data.adgroupId || action.data.adgroupId === '0') && child.adgroup)) {
                return child
              }
              return Object.assign({}, child, {
                status: action.data.status,
              })
            }),
          })
        }),
      }
    case TURN_RULES_SUCCESS:
      return {
        ...state,
        campaignRulesData: (state.campaignRulesData || []).map((campaign) => {
          if (!action.data.campaignIds.includes(campaign.campaign_id.toString())) {
            return campaign
          }
          return Object.assign({}, campaign, {
            children: campaign.children.map((child) => {
              if (child.type !== action.data.type) {
                return child
              }
              return Object.assign({}, child, {
                status: !child.status,
              })
            }),
          })
        }),
      }
    case DELETE_FROM_TEMPLATE_SUCCESS:
      return {
        ...state,
        campaignRulesData: (state.campaignRulesData || []).map((campaign) => {
          if (!action.data.campaignId
            || action.data.campaignId.toString() !== campaign.campaign_id.toString()) {
            return campaign
          }
          return Object.assign({}, campaign, {
            children: campaign.children.map((child) => {
              if (child.type !== action.data.type) {
                return child
              }
              return Object.assign({}, child, {
                templates: child.templates.filter(tpl => tpl.id !== action.data.templateId),
              })
            }),
          })
        }),
      }
    default:
      return state
  }
}

export default ruleManager
