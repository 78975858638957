import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import AudienceModal from './Shared/AudienceModal'
import SuggestedBidTypeSelector from './Shared/SuggestedBidTypeSelector'
import DefaultBidSection from './Shared/DefaultBidSection'

import { getSdBidSuggestions } from '../../redux/actions/campaignCreator'
import { parseTargeting } from '../../services/helper'

const AudienceSection = ({
  targetings,
  isSuggestionsLoading,
  dailyBudget,
  bidInfo,
  products,
  onFind,
  onChange,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const parseTargetings = () => {
    const targets = []
    targetings.filter(targeting => targeting.audienceType === 'views').forEach((targeting) => {
      if (['audience_category', 'audience_refine', 'audience_product', 'audience'].indexOf(targeting.type) === -1) {
        return
      }

      const payload = parseTargeting(targeting)
      if (!payload) {
        return
      }

      payload.targeting = targeting

      targets.push(payload)
    })
    return targets
  }

  const handleFind = () => {
    setOpenModal(true)
    onFind()
  }

  const handleBidChange = (event, target) => {
    onChange(targetings.map((item) => {
      if (item.id.toString() === target.id.toString()
        && item.audienceType === target.audienceType) {
        return {
          ...item,
          bid: event.target.value,
        }
      }
      return item
    }))
  }

  const handleRemove = (target) => {
    onChange(targetings.filter(item => {
      if (target.type !== item.type
        && item.audienceType === target.audienceType) {
        return true
      }
      return target.id.toString() !== item.id.toString()
    }))
  }

  const getTargetingName = (target) => {
    if (target.type === 'audience_category'
      || target.type === 'audience_refine') {
      return `Category: ${target.name}`
    }
    if (target.type === 'audience_product') {
      return target.name
    }
    if (target.type === 'audience') {
      return `Audiences: ${target.name}`
    }
  }

  const handleGetSuggestedBid = async () => {
    setOpenModal(false)

    if (!products.length || !targetings.length) {
      return
    }

    const asins = products.map((product) => ({
      asin: product.asin,
    }))

    const bidParams = parseTargetings(targetings).map((suggestedTarget) => {
      return {
        targeting: suggestedTarget,
        param: {
          products: asins,
          bidOptimization: bidInfo.bidOp,
          costType: bidInfo.bidOp === 'reach' ? 'vcpm' : 'cpc',
          targetingClauses: [
            {
              targetingClause: {
                expressionType: 'manual',
                expression: suggestedTarget.expression,
              },
            },
          ],
        },
      }
    })

    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSdBidSuggestions(accessToken, bidParams))
    if (!response || !response.length) {
      return
    }

    onChange(targetings.map((targeting) => {
      const suggestedBid = response.filter(bid => (
        bid.targeting.targeting.id.toString() === targeting.id.toString()
      ))
      if (suggestedBid.length) {
        return {
          ...targeting,
          suggestedBid: suggestedBid[0].suggestedBid,
        }
      }
      return targeting
    }))
  }

  const handleApplyBid = (type, target) => {
    if (!target.suggestedBid) {
      return
    }
    let bid = defaultBid
    switch (type) {
      case 'suggest':
        bid = target.suggestedBid.recommended
        break
      case 'min':
        bid = target.suggestedBid.rangeLower
        break
      case 'max':
        bid = target.suggestedBid.rangeUpper
        break
      default:
        break
    }

    onChange(targetings.map((item) => {
      if (item.id.toString() === target.id.toString()) {
        return {
          ...item,
          bid,
        }
      }
      return item
    }))
  }

  const renderTargetings = () => {
    if (!targetings.length) {
      return (
        <div className="no-targeting-desc">
          No targeting added.
        </div>
      )
    }

    return (
      <div className="targeting-list">
        {
          targetings.map(target => (
            <div key={target.id} className="targeting-item">
              <div className="targeting-info">
                <div className="targeting-name">
                  { getTargetingName(target) }
                </div>
                {
                  target.type !== 'audience' && (
                    <div className="targeting-meta">
                      Lookback: {target.lookback ? target.lookback.value : 30} days
                    </div>
                  )
                }
                {
                  target.type === 'audience_refine' && (
                    <div className="targeting-meta">
                      Brand: { target.brandName }
                    </div>
                  )
                }
              </div>
              {
                target.suggestedBid && (
                  <div className="suggested-bid-container">
                    Suggested Bid:&nbsp;
                    {
                      `${target.suggestedBid.recommended} : ${target.suggestedBid.rangeLower}~${target.suggestedBid.rangeUpper}`
                    }
                    <div className="apply-section">
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleApplyBid('suggest', target) }}
                      >
                        Apply Suggest
                      </button>
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleApplyBid('min', target) }}
                      >
                        Apply Min
                      </button>
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleApplyBid('max', target) }}
                      >
                        Apply Max
                      </button>
                    </div>
                  </div>
                )
              }
              <div className="targeting-action">
                <div>
                  <input
                    type="number"
                    value={target.bid}
                    onChange={(event) => { handleBidChange(event, target) }}
                  />
                  {
                    parseFloat(target.bid) >= parseFloat(dailyBudget) / 2 && (
                      <div className="warning-message">
                        Bid must be less than half the value of your budget.
                      </div>
                    )
                  }
                </div>
                <span className="close-icon" title="Remove" onClick={() => { handleRemove(target) }}>
                  &times;
                </span>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-title">
        <span>Audiences</span>
        <div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFind}
          >
            Choose Audience
          </button>
          {
            targetings.length > 0 && (
              <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
                Remove All
              </button>
            )
          }
        </div>
      </div>
      <div className="section-note">
        Reach audiences based on product detail page views, or use our prebuilt audience segments.
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <DefaultBidSection
            targetings={targetings}
            defaultBid={defaultBid}
            onChange={onChange}
            onDefaultBidChange={setDefaultBid}
          />
        </div>
        <div className="field-wrapper">
          <SuggestedBidTypeSelector
            targetings={targetings}
            onChange={onChange}
          />
        </div>
      </div>
      { renderTargetings() }
      <AudienceModal
        show={openModal}
        defaultBid={defaultBid}
        targetings={targetings}
        isSuggestionsLoading={isSuggestionsLoading}
        onChange={onChange}
        onClose={handleGetSuggestedBid}
      />
    </div>
  )
}

export default AudienceSection
