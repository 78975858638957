// Campaign performance rule manager.
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import Header from './Header'
import Footer from '../RuleManagerComponents/Footer'
import CampaignSelector from '../RuleManagerComponents/CampaignSelector'
import RuleSection from './RuleSection'

import {
  turnRules,
  hideRuleManager,
  updateCampaignSelection,
} from '../../redux/actions/ruleManager'
import {
  loadCpRules,
  saveCpRules,
  saveCpTemplate,
} from '../../redux/actions/performance'
import { selectCurrentAccount } from '../../redux/reducers/header'

import { ignoreOutsideClick, isRuleComplete } from '../../services/helper'
import { RULE_TYPE_CP } from '../../utils/defaultValues'

export const isSlotComplete = (slot) => {
  if (!slot.m
    || !slot.c
    || typeof slot.v === 'undefined'
    || !slot.l
    || !slot.r
    || !slot.r.length) {
    return false
  }

  const completeRule = slot.r.find(isRuleComplete)

  if (!completeRule) {
    return false
  }

  return true
}

const PerformanceManager = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const campaigns = useSelector(state => state.ruleManager.campaigns)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRules, setIsLoadingRules] = useState(true)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

  // Indicate if a rule is saved to database.
  const [isSaved, setIsSaved] = useState(false)
  const [isOn, setIsOn] = useState(true)
  const [timezone, setTimezone] = useState(
    currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [slots, setSlots] = useState([])
  const [currentTemplates, setCurrentTemplates] = useState([])

  const [isCampaignSelectorVisible, setCampaignSelectorVisible] = useState(false)

  // Load existing rules.
  useEffect(() => {
    if (!campaigns.length) {
      setIsLoadingRules(false)
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(loadCpRules(
        accessToken,
        campaigns.map(campaign => campaign.campaign_id)
      ))
      let newIsOn = true
      let newTimezone
        = currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      let newSlots = []
      let newCurrentTemplates = []

      response.forEach((record) => {
        let campaign = null
        if (campaigns.length > 1) {
          campaign = campaigns.find(c =>
            c.campaign_id.toString() === record.campaign_id
          )
        }

        newIsOn = record.status
        if (record.timezone) {
          newTimezone = record.timezone
        }
        if (record.slots) {
          newSlots = newSlots.concat(record.slots.map(slot =>
            Object.assign({}, slot, {
              campaign,
            })
          ))
        }
        if (record.templates) {
          newCurrentTemplates = newCurrentTemplates.concat(
            record.templates.map(template =>
              Object.assign({}, template, {
                campaign,
              })
            )
          )
        }
      })

      const templateMap = new Map(newCurrentTemplates.map(template =>
        [template.id, template]
      ))

      setIsSaved(response.length !== 0)
      setIsOn(newIsOn)
      setTimezone(newTimezone)
      setSlots(newSlots.map((slot, index) => (
        Object.assign({}, slot, {
          id: `${Math.floor(Math.random() * 1000).toString()}-${index}`,
        })
      )))
      // Remove duplicate templates.
      setCurrentTemplates([...templateMap.values()])

      setIsLoadingRules(false)
    })()
  }, [dispatch, campaigns]) // eslint-disable-line

  const hasSpCampaign = useMemo(() => {
    return typeof campaigns.find(campaign =>
      campaign.campaignType === 'sp'
    ) !== 'undefined'
  }, [campaigns])

  const isSavable = useMemo(() => {
    if (currentTemplates.length > 0) {
      return true
    }
    return slots.filter(isSlotComplete).length > 0
  }, [slots, currentTemplates])

  // Hide pane.
  const handleClose = () => {
    dispatch(hideRuleManager())
  }

  // When clicking outside of the manager panel.
  const handleOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      handleClose()
    }
  }

  const handleIsOnChange = async (value) => {
    setIsUpdatingStatus(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(turnRules(
      accessToken,
      RULE_TYPE_CP,
      campaigns.map(campaign => campaign.campaign_id)
    ))
    if (response) {
      setIsOn(value)
    }
    setIsUpdatingStatus(false)
  }

  // When removing a campaign from header.
  const handleCampaignRemove = (campaignId) => {
    dispatch(updateCampaignSelection(
      campaigns.filter(campaign => campaign.campaign_id !== campaignId)
    ))
  }

  const stripIds = (slotsToSave) => {
    return slotsToSave.filter(isSlotComplete).map((slot) => {
      const slotWithoutId = Object.assign({}, slot)
      delete slotWithoutId.id

      if (typeof slotWithoutId.campaign !== 'undefined') {
        delete slotWithoutId.campaign
      }

      slotWithoutId.r = slotWithoutId.r.filter(isRuleComplete)

      return slotWithoutId
    })
  }

  const handleApply = async () => {
    if (!campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    let filteredSlots = slots
    if (campaigns.length > 1) {
      // When multiple campaigns are selected, only
      // new rules are passed to backend.
      filteredSlots = filteredSlots.filter(slot => !slot.campaign)
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveCpRules(
      accessToken,
      campaigns.map(campaign => campaign.campaign_id),
      isOn,
      timezone,
      stripIds(filteredSlots),
      currentTemplates.map(template => template.id),
    ))
    setIsSaved(true)
    setIsLoading(false)
  }

  const handleTemplateSave = async (name, needApply) => {
    if (needApply && !campaigns.length) {
      toast.show({
        title: 'Warning',
        description: 'At least one campaign needs to be selected.',
      })
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const templateSaved = await dispatch(saveCpTemplate(
      accessToken,
      name,
      stripIds(slots),
      needApply,
      campaigns.map(campaign => campaign.campaign_id),
    ))
    setIsLoading(false)
    if (needApply && templateSaved) {
      setCurrentTemplates(prev => ([
        ...prev,
        templateSaved,
      ]))
    }
  }

  // When templates are selected from the template selector.
  const handleTemplateChange = (templates) => {
    setCurrentTemplates(templates)

    toast.show({
      title: 'Info',
      description: 'You need to click on `Save Rules` button '
        + 'to save your selection and apply the template.',
      duration: 5000,
    })
  }

  const handleApplyMultiple = () => {
    setCampaignSelectorVisible(true)
  }

  const handleApplyToMultiple = async (campaignIds) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(saveCpRules(
      accessToken,
      campaignIds,
      isOn,
      timezone,
      stripIds(slots),
      currentTemplates.map(template => template.id),
    ))
    setIsLoading(false)
  }

  const renderBody = () => {
    if (!isCampaignSelectorVisible) {
      return (
        <>
          <div className="pane-body">
            <RuleSection
              hasSpCampaign={hasSpCampaign}
              slots={slots}
              currentTemplates={currentTemplates}
              onChange={setSlots}
            />
          </div>
          <Footer
            isLoading={isLoading || isLoadingRules}
            isSavable={isSavable}
            onApply={handleApply}
            onApplyToMultiple={handleApplyMultiple}
            onSaveTemplate={handleTemplateSave}
            onClose={handleClose}
          />
        </>
      )
    }

    return (
      <CampaignSelector
        isSaving={isLoading}
        onApply={handleApplyToMultiple}
        onCancel={() => { setCampaignSelectorVisible(false) }}
      />
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className="performance-manager-component">
        { (isLoading || isLoadingRules || isUpdatingStatus) && <LoaderComponent className="rule-loader" /> }
        <Header
          campaigns={campaigns}
          isSaved={isSaved}
          isOn={isOn}
          timezone={timezone}
          currentTemplates={currentTemplates}
          isCampaignSelectorVisible={isCampaignSelectorVisible}
          isUpdatingStatus={isUpdatingStatus}
          onIsOnChange={handleIsOnChange}
          onTimezoneChange={setTimezone}
          onCampaignRemove={handleCampaignRemove}
          onTemplateChange={handleTemplateChange}
          onClose={handleClose}
        />
        { renderBody() }
      </div>
    </OutsideClickHandler>
  )
}

export default PerformanceManager
