import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportStreamViewWidget from './ReportStreamViewWidget'

import { getStreamData } from '../../redux/actions/accountHealth'

import { reportWidgets } from '../../utils/defaultValues'

const HourlyDataSection = ({ showSection, onToggle }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [streamData, setStreamData] = useState([])
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      if (showSection && !isLoaded) {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const data = await dispatch(getStreamData(
            accessToken,
            abortCtrl.signal,
          ))
          setStreamData(data)
          setIsLoading(false)
          setIsLoaded(true)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [currentUserId, showSection, startDate, endDate]); //eslint-disable-line

  useEffect(() => {
    let hourlyData = reportWidgetSetting ? reportWidgetSetting.hourlyData : []
    if (!hourlyData || !hourlyData.length) {
      hourlyData = reportWidgets.hourlyData
    }
    setViewWidgets(hourlyData.map(w => ({
      ...w,
      view: !!w.default,
    })))
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  return (
    <div className={isLoading ? 'loading' : ''}>
      <SectionHeader
        title="Stream Data by Day and Hour"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
      />
      <div className={`report-widget-content${!showSection ? ' hide' : ''}`}>
        {
          viewWidgets.length > 0 && (viewWidgets[0].view || customizingSection) && (
            <ReportStreamViewWidget
              widget={viewWidgets[0]}
              isLoading={isLoading}
              customizing={customizingSection}
              streams={streamData}
              section="hourlyData"
              onRemove={handleOnRemoveWidget}
            />
          )
        }
      </div>
    </div>
  )
}

export default withReportSection(HourlyDataSection, {
  sectionId: 'hourly_data',
  reportSectionKey: 'hourlyData',
})
