import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../CommonComponents/SortableTableComponent'

import {
  getNegativeKWData,
  getKeywordRelatedData,
  getKeywordData,
} from '../../../../redux/actions/campaignDetail'

import {
  formatValue,
  formatCurrency,
  capitalizeFirstLetter,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
} from "../../../../services/helper"

import { matchTypes } from '../../../../utils/filterDef'

const columns = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'orders', name: 'Orders' },
  { key: 'acos', name: 'ACoS %' },
  { key: 'revenue', name: 'Sales' },
  { key: 'cost', name: 'Spend' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'cpc', name: 'Ave CPC' },
]

const KeywordTable = ({ campaignType, currentAdGroupId }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)

  const [isLoading, setIsLoading] = useState(false)
  const [keywordData, setKeywordData] = useState([])
  const [keywords, setKeywords] = useState([])
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])

  useEffect(() => {
    if (!currentDetail) {
      return
    }

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const data = await dispatch(getKeywordData(
        accessToken,
        currentDetail.campaign_id,
      ))
      setKeywordData(data)
      setIsLoading(false)
    })()
  }, [currentDetail ? currentDetail.campaign_id : null, currentStartDate, currentEndDate]) // eslint-disable-line

  useEffect(() => {
    if (!keywordData.length) {
      return
    }

    let filtereKeywords = []
    if (!currentAdGroupId) {
      filtereKeywords = keywordData[0].map(record => ({
        ...record,
        id: `${record['keyword']}-${record['match_type']}`,
      }))
    } else {
      filtereKeywords = keywordData[1].filter(keyword =>
        keyword.adgroup_id.toString() === currentAdGroupId.toString()
      ).map(record => ({
        ...record,
        id: `${record['adgroup_id']}-${record['keyword']}-${record['match_type']}`,
      }))
    }

    if (selectedMatchType.value !== '') {
      filtereKeywords = filtereKeywords.filter(record => (
        (record.match_type || '').toLowerCase() === selectedMatchType.value
      ))
    }

    filtereKeywords = filtereKeywords.map(keyword => ({
      ...calcDerivedMetrics(keyword),
      matchType: capitalizeFirstLetter(keyword.match_type),
    }))

    setKeywords(filtereKeywords)
  }, [keywordData, currentAdGroupId, selectedMatchType])

  const handleKeywordClick = async (keyword) => {
    const accessToken = await getAccessTokenSilently()

    dispatch(getKeywordRelatedData(accessToken, {
      campaignId: currentDetail.campaign_id,
      keyword: keyword.keyword,
      matchType: keyword.match_type,
      acosProfitZone: currentAcos,
    }))

    dispatch(getNegativeKWData(accessToken, {
      campaignId: currentDetail.campaign_id,
      campaignType,
    }))

    document.querySelector('.main-content').scrollTop
      = document.querySelector('.results-container').getBoundingClientRect().top
  }

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        { record.keyword }
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cpc, currencySign, currencyRate) }
      </div>
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <div className="filter-container">
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
      </div>
      <SortableTable
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['keyword', 'matchType'])}
        className="table-keywords"
        records={keywords}
        idField="id"
        searchFields={['keyword']}
        noCheckBox
        hasDateRange
        filterName="campaignDetailKeywordCleaner"
        hasSticky
        isLoading={isLoading}
        exportFileName="CampaignDetailKeywords"
        getExportData={getExportData}
        renderRecord={renderKeyword}
        onClickRecord={handleKeywordClick}
      />
    </>
  )
}

export default KeywordTable
