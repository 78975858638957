import React, { useState } from 'react'
import { Modal, Radio, RadioGroup } from 'rsuite'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

const otherOption = 'Other Reasons'

const reasonList = [
  'Missing Features We Need',
  'Switching To Another Product Or Service',
  'Not Using It Enough (Or At All)',
  'Shutting Down Business',
  'Too Expensive',
  'Bugs Or Support Problems',
  otherOption,
]

const CancellationModal = ({ show, onCancel, onHide }) => {
  const [reason, setReason] = useState(otherOption)
  const [otherReason, setOtherReason] = useState('')
  const [isCancelling, setIsCancelling] = useState(false)

  const handleCancel = async () => {
    if (reason === otherOption && !otherReason) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your reason to cancel.',
      })
      return
    }

    setIsCancelling(true)
    onCancel(reason === otherOption ? otherReason : reason).then(() => {
      setIsCancelling(false)
      toast.show({
        title: 'Success',
        description: 'Subscription successfully cancelled.',
      })
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to cancel subscription.',
      })
      setIsCancelling(false)
    })
  }

  return (
    <Modal className="settings-cancellation-modal" backdrop="static" show={show} size="md">
      <Modal.Header onHide={() => { onHide() }}>
        <Modal.Title>
          We're sorry to see you go
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isCancelling && <LoaderComponent /> }
        <RadioGroup
          value={reason}
          onChange={setReason}
        >
          {
            reasonList.map(option => (
              <Radio key={option} value={option}>{ option }</Radio>
            ))
          }
        </RadioGroup>
        {
          reason === otherOption && (
            <input
              type="text"
              value={otherReason}
              onChange={(event) => { setOtherReason(event.target.value) }}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isCancelling}
          onClick={handleCancel}
        >
          Cancel Subscription
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onHide() }}
        >
          Keep Using Subscription
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CancellationModal
