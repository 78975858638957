import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const tabList = [
  { value: 'acos', label: 'ACoS' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'clicks', label: 'Clicks' },
  { value: 'cpc', label: 'CPC' },
  { value: 'conversion', label: 'Conversion %' },
  { value: 'cost', label: 'Spend' },
  { value: 'orders', label: 'Orders' },
]

const TabSelector = ({ activeTab, onTabChange }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const tabsToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return tabList
    }
    return tabList.filter(tab => (
      ![
        'acos',
        'conversion',
        'orders',
      ].includes(tab.value)
    ))
  }, [isNonEndemicAccount])

  return (
    <div className="tab-selector">
      {
        tabsToRender.map(tab => (
          <span
            key={tab.value}
            className={activeTab === tab.value ? 'active' : ''}
            onClick={() => { onTabChange(tab.value) }}
          >
            { tab.label }
          </span>
        ))
      }
    </div>
  )
}

export default TabSelector
