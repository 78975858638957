/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Toggle, Tooltip, Whisper } from 'rsuite'

const HeaderCampaign = ({ campaigns, currentTemplates,
  onTemplateSelect, onTemplateChange, onRemove }) => {
  const [isShowCampaigns, setIsShowCampaigns] = useState(true)

  const handleTemplateRemove = (event) => {
    event.preventDefault()
    onTemplateChange([])
  }

  const renderTemplateRemove = () => {
    if (!currentTemplates.length) {
      return null
    }

    return (
      <a
        href="#"
        className="template-remove"
        onClick={handleTemplateRemove}
      >
        Remove Template{ currentTemplates.length > 1 ? 's' : '' }
      </a>
    )
  }

  const renderTemplate = () => {
    return (
      <>
        <a
          href="#"
          className="template-name"
          onClick={onTemplateSelect}
        >
          {
            currentTemplates.length > 0 ? (
              <Whisper placement="right" trigger="hover" speaker={(
                <Tooltip>
                  <ul>
                    {
                      currentTemplates.map(template => (
                        <li key={template.id}>
                          { template.name }
                        </li>
                      ))
                    }
                  </ul>
                </Tooltip>
              )}>
                <span>
                  { currentTemplates.length } template{currentTemplates.length > 1 ? 's' : ''} applied
                </span>
              </Whisper>
            ) : 'No template used'
          }
        </a>
        { renderTemplateRemove() }
      </>
    )
  }

  const renderCampaigns = () => {
    if (!isShowCampaigns) {
      return null
    }

    return (
      <div className="campaign-list">
        {
          campaigns.map(campaign => (
            <span
              key={campaign.campaign_id}
              className="campaign-wrapper"
            >
              { campaign.name }
              <span
                className="remove-campaign-button"
                onClick={() => { onRemove(campaign.campaign_id) }}
              >
                &times;
              </span>
            </span>
          ))
        }
      </div>
    )
  }

  return (
    <>
      <Toggle
        checked={isShowCampaigns}
        checkedChildren="Show Campaigns"
        unCheckedChildren="Hide Campaigns"
        onChange={setIsShowCampaigns}
      />
      { renderTemplate() }
      { renderCampaigns() }
    </>
  )
}

export default HeaderCampaign
