import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import { toast } from '../CommonComponents/ToastComponent/toast'

import BulkResultContainer from '../BulkResultContainer'

import { updatePaStates } from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  tableSorter,
  calcDerivedMetrics,
  capitalizeFirstLetter,
  getExportValueForColumn,
  groupRecords,
  copyToClipboard,
  getAmazonAsinLink,
} from '../../services/helper'

import {
  bulkSKUColumnList,
  JOB_TYPE_BULK_UPDATE_PA_STATES,
  MODULE_NAME_SKU_OP,
} from '../../utils/defaultValues'

export const FILTER_NAME_SKU_OP = 'bulkSkuOp'

const initialColumns = [
  { key: 'image', name: 'Image', sortable: false, exportable: false },
  { key: 'sku', name: 'SKU' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'image', name: 'Image', sortable: false, exportable: false },
  { key: 'sku', name: 'SKU' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const statusList = [
  { value: '', label: 'Active & Paused' },
  { value: 'enabled', label: 'Active Only' },
  { value: 'paused', label: 'Paused Only' },
]

const SkuOpResult = ({ campaignsById, adgroupNamesById, onChangeDate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)

  const skuOpData = useSelector(state => state.bulkEngine.skuOpData)

  const [groupMode, setGroupMode] = useState(false)
  const [skus, setSkus] = useState([])
  const [groupedSkus, setGroupedSkus] = useState([])
  const [selectedSkus, setSelectedSkus] = useState([])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [selectedStatus, setSelectedStatus] = useState(statusList[0])
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)

  useEffect(() => {
    let extendedSkus = [];
    (skuOpData || []).forEach((record) => {
      if (selectedStatus.value !== '') {
        if ((record.state || '').toLowerCase() !== selectedStatus.value) {
          return
        }
      }

      const campaignDetail = campaignsById[record.campaign_id] || {}

      extendedSkus.push({
        ...calcDerivedMetrics(record),
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targetingType: campaignDetail.targetingType || '',
        adgroupName: adgroupNamesById[record.adgroup_id] || '',
      })
    })

    // Remove duplicate entries.
    extendedSkus =  [
      ...new Map(
        extendedSkus
        .map(item => [item.ad_id, item])
      )
      .values()
    ]

    setSkus(extendedSkus)
  }, [skuOpData, campaignsById, adgroupNamesById, selectedStatus])

  useEffect(() => {
    if (groupMode) {
      setGroupedSkus(
        groupRecords(
          skus,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedSkus([])
    }
  }, [skus, groupMode])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const availableColumns = campaignTableColumns.filter(c1 => bulkSKUColumnList.find(c2 => c2.key === c1.key))
    return [
      [...initialColumns, ...availableColumns],
      [...initialColumnsGroup, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns])

  const isSeller = currentAccount?.seller_type === 'seller'
  if (!isSeller) {
    initialColumns[1].key = 'asin'
    initialColumns[1].name = 'ASIN'
    initialColumnsGroup[3].key = 'asin'
    initialColumnsGroup[3].name = 'ASIN'
  }

  const handleChangeState = async (state) => {
    const productAds = skus
      .filter(sku => selectedSkus.indexOf(sku.ad_id) !== -1)
      .map(sku => ({
        campaignType: sku.campaignType,
        ad_id: sku.ad_id,
        // Below information are used for logging in backend.
        campaign_id: sku.campaign_id,
        campaignName: sku.campaignName,
        adgroup_id: sku.adgroup_id,
        adgroupName: sku.adgroupName,
        sku: sku.sku,
        asin: sku.asin,
        originalState: sku.state,
      }))

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updatePaStates(
      accessToken,
      productAds,
      state,
      false,
    ))
    setIsUpdatingStates(false)
    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_PA_STATES,
        state,
      ))
    }
  }

  const handleCopyAsin = () => {
    const asins = skus.filter(record => (
      selectedSkus.indexOf(record.ad_id) !== -1
    )).map(record => record.asin.trim())

    copyToClipboard(asins.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${asins.length} ASIN${asins.length > 1 ? 's' : ''}.`
    })
  }

  const handleCopySku = () => {
    const skusToCopy = skus.filter(record => (
      selectedSkus.indexOf(record.ad_id) !== -1
    )).map(record => record.sku.trim())

    copyToClipboard(skusToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${skusToCopy.length} SKU${skusToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren={isSeller ? 'By SKUs' : 'By ASINs'}
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Status</span>
          <Select
            classNamePrefix="status-selector"
            options={statusList}
            value={selectedStatus}
            onChange={setSelectedStatus}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { setCurrentFilterName(FILTER_NAME_SKU_OP) }}
          >
            Refine Filter
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedSkus.length) {
      return null
    }

    const isEnableDisabled = typeof skus.find(record => (
      selectedSkus.indexOf(record.ad_id) !== -1
      && record.state.toLowerCase() !== 'enabled'
    )) === 'undefined'

    const isPauseDisabled = typeof skus.find(record => (
      selectedSkus.indexOf(record.ad_id) !== -1
      && record.state.toLowerCase() !== 'paused'
    )) === 'undefined'

    return (
      <>
        <button
          type="button"
          className="btn btn-green"
          onClick={() => { handleCopyAsin() }}
        >
          Copy ASINs
        </button>
        {
          isSeller && (
            <button
              type="button"
              className="btn btn-green"
              onClick={() => { handleCopySku() }}
            >
              Copy SKUs
            </button>
          )
        }
        <button
          type="button"
          className="btn btn-green"
          disabled={isUpdatingStates || isEnableDisabled}
          onClick={() => { handleChangeState('enabled') }}
        >
          Enable
        </button>
        <button
          type="button"
          className="btn btn-red"
          disabled={isUpdatingStates || isPauseDisabled}
          onClick={() => { handleChangeState('paused') }}
        >
          Pause
        </button>
      </>
    )
  }

  const renderSku = record => (
    <>
      <div className="table-col">
        <a
          href={getAmazonAsinLink(currentAccount, record.asin)}
          title={record.name}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={record.image} alt={record.name} />
        </a>
      </div>
      <div className="table-col col-sku">
        <strong title={isSeller ? record.sku : record.asin}>
          { isSeller ? record.sku : record.asin }
        </strong>
        <div className="meta-data">
          { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col">Totals:</div>
      <div className="table-col col-sku" />
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'sku' || column.key === 'asin') {
        return `${record[column.key]} (${capitalizeFirstLetter(record.state)})`
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col" />
      <div className="table-col col-sku">
        { record.children.length } { isSeller ? 'SKUs' : 'ASINs' }
      </div>
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col">
        <a href={record.url} title={record.name} target="_blank" rel="noopener noreferrer">
          <img src={record.image} alt={record.name} />
        </a>
      </div>
      <div className="table-col col-sku">
        <strong>
          { isSeller ? record.sku : record.asin }
        </strong>
        <div className="meta-data">
          { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col" />
      <div className="table-col col-sku" />
      <div className="table-col col-adgroup" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <BulkResultContainer>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            isLoading={isUpdatingStates}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-skus"
            records={groupedSkus}
            idField="campaign_id"
            searchFields={['sku', 'asin', 'name']}
            selectedRecords={selectedSkus}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_SKU_OP}
            useFilterModal
            columnEditorId="skuOpResult"
            columnList={bulkSKUColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_SKU_OP}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedSkus}
            onChangeDate={onChangeDate}
            sorterChild={tableSorter(['state', 'sku', 'asin', 'adgroupName'])}
            idFieldChild="ad_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isUpdatingStates}
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['state', 'sku', 'asin', 'campaignName', 'adgroupName'])}
            className="table-skus"
            records={skus}
            idField="ad_id"
            searchFields={['sku', 'asin', 'name']}
            selectedRecords={selectedSkus}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_SKU_OP}
            useFilterModal
            columnEditorId="skuOpResult"
            columnList={bulkSKUColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_SKU_OP}
            getExportData={getExportData}
            renderRecord={renderSku}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedSkus}
            onChangeDate={onChangeDate}
          />
        )
      }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={MODULE_NAME_SKU_OP}
            onApply={() => { setCurrentFilterName('') }}
            onClose={() => { setCurrentFilterName('') }}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default SkuOpResult
