import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'
import { toast } from '../CommonComponents/ToastComponent/toast'
import BidAdjustComponent from '../CommonComponents/BidAdjustComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'

import {
  updateTargetStates,
  updateTargetBids,
} from '../../redux/actions/bulkEngine'

import {
  formatCurrency,
  parseAsinTarget,
  tableSorter,
  calcNewBid,
} from '../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
} from '../../utils/defaultValues'
import { selectCurrentAccount } from '../../redux/reducers/header'

const columns = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroup_name', name: 'Ad Group', className: 'col-adgroup' },
  { key: 'state', name: 'State' },
  { key: 'bid', name: 'Current Bid' },
  ...bulkBidColumnList,
]

const DupTargetModal = ({ duplicatedTarget, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)

  const [targets, setTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)

  useEffect(() => {
    if (duplicatedTarget) {
      setTargets(duplicatedTarget.children)
    }
  }, [duplicatedTarget])

  const handlePause = async () => {
    let targetsChanged = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      targetsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        target_text: duplicatedTarget.target_text,
        originalState: record.state,
      })
    })

    // Remove duplicate entries.
    targetsChanged =  [...new Map(targetsChanged.map(item => [item.targetId, item])).values()]

    if (targetsChanged.length) {
      setIsUpdatingStates(true)
      const accessToken = await getAccessTokenSilently()
      const targetIdsUpdated = await dispatch(updateTargetStates(
        accessToken,
        targetsChanged,
        'paused',
      ))
      setIsUpdatingStates(false)
      if (targetIdsUpdated) {
        setTargets(duplicatedTarget.children.map((record) => {
          if (targetIdsUpdated.includes(record.target_id.toString())) {
            return {
              ...record,
              state: 'paused',
            }
          }
          return record
        }))
      }
    }
  }

  const handleAdjustBid = async () => {
    let targetsChanged = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.campaignType,
        currentAccount?.country_id,
        record.costType
      )

      targetsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        target_text: duplicatedTarget.target_text,
        originalBid: record.bid,
      })
    })

    // Remove duplicate entries.
    targetsChanged =  [...new Map(targetsChanged.map(item => [item.targetId, item])).values()]

    if (!targetsChanged.length) {
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your targets.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const targetIdsUpdated = await dispatch(updateTargetBids(
      accessToken,
      targetsChanged,
    ))
    setIsUpdatingBids(false)
    setTargets(duplicatedTarget.children.map((record) => {
      if (targetIdsUpdated.includes(record.target_id.toString())) {
        const payload = targetsChanged.find(p => (
          p.targetId.toString() === record.target_id.toString()
        ))
        if (payload) {
          return {
            ...record,
            bid: payload.bid,
          }
        }
      }
      return record
    }))
  }

  const renderAction = () => {
    if (!selectedTargets.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isPauseDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={handlePause}
          >
            Pause
          </button>
          <button
            type="button"
            className="btn btn-light-blue"
            onClick={() => { setIsShowAdjustBid(true) }}
          >
            Adjust Bid
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderTarget = record => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col col-adgroup" title={record.adgroup_name}>
        <span className="contents">
          { record.adgroup_name }
        </span>
      </div>
      <div className="table-col col-state">
        { record.state.toLowerCase() }
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      {
        bulkBidColumnList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={campaignTableColumns}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <Modal className="dup-target-modal" backdrop="static" full show={duplicatedTarget !== null}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Duplicated Target: { duplicatedTarget ? parseAsinTarget(duplicatedTarget.target_text) : '' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortableTable
          columns={columns}
          defaultSort={['cost', 'desc']}
          sorter={tableSorter(['campaignName', 'adgroup_name', 'state'])}
          className="table-targets"
          records={targets || []}
          idField="target_id"
          searchFields={['campaignName', 'adgroup_name']}
          selectedRecords={selectedTargets}
          hasSticky
          columnEditorId="dupTargetModal"
          columnList={bulkBidColumnList}
          columnSelection={campaignTableColumns}
          isLoading={isUpdatingStates || isUpdatingBids}
          renderRecord={renderTarget}
          renderTopRight={renderAction}
          onChange={setSelectedTargets}
        />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DupTargetModal
