import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportPerformanceWidget from './ReportPerformanceWidget'

import { calcDerivedMetrics } from '../../services/helper'

const PerformanceByMatchType = ({ showSection, onToggle }) => {
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const matchTypeSummary = useSelector(state => state.accountHealth.performanceData.matchTypeSummary)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])

  useEffect(() => {
    const performanceByMatchType = reportWidgetSetting ? reportWidgetSetting.performanceByMatchType : []
    setViewWidgets(
      performanceByMatchType.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const renderWidget = (widget) => {
    let data = {
      clicks: 0,
      orders: 0,
      impressions: 0,
      revenue: 0.00,
      cost: 0.00
    }
    switch(widget.key) {
      case 'exact':
        if (matchTypeSummary && matchTypeSummary.length > 0) {
          data = matchTypeSummary[0]
        }
        break
      case 'broad':
        if (matchTypeSummary && matchTypeSummary.length > 0) {
          data = matchTypeSummary[1]
        }
        break
      case 'phrase':
        if (matchTypeSummary && matchTypeSummary.length > 0) {
          data = matchTypeSummary[2]
        }
        break
      default:
        return null
    }

    return (
      <ReportPerformanceWidget
        key={widget.key}
        widget={widget}
        data={calcDerivedMetrics(data)}
        onRemove={handleOnRemoveWidget}
        customizing={customizingSection}
        section="performanceByMatchType"
      />
    )
  }

  return (
    <div>
      <SectionHeader
        title="Performance By Match Type"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
      />
      {
        showSection && (
          <>
            {
              viewWidgets.map(w => (w.view || customizingSection) ? (
              renderWidget(w)) : null)
            }
          </>
        )
      }
    </div>
  )
}

export default withReportSection(PerformanceByMatchType, {
  sectionId: 'performanceByMatchType',
  reportSectionKey: 'performanceByMatchType',
})
