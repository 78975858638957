/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import Section from './Section'
import BillingAmazon from './BillingAmazon'
import BillingInfo from './BillingInfo'
import BillingInvoice from './BillingInvoice'
import BillingSubscription from './BillingSubscription'

const TAB_CONNECTION = 'connection'
const TAB_BILLING = 'billing'
const TAB_PAYMENT = 'payment'
const TAB_SUBSCRIPTION = 'subscription'

const tabList = [
  { tab: TAB_CONNECTION, name: 'Amazon Account Connections' },
  { tab: TAB_BILLING, name: 'Billing Information' },
  { tab: TAB_PAYMENT, name: 'Payment History' },
  { tab: TAB_SUBSCRIPTION, name: 'Subscriptions' },
]

const SectionBilling = () => {
  const [activeTab, setActiveTab] = useState(TAB_CONNECTION)

  const renderContents = () => {
    if (activeTab === TAB_CONNECTION) {
      return <BillingAmazon />
    }
    if (activeTab === TAB_BILLING) {
      return <BillingInfo />
    }
    if (activeTab === TAB_PAYMENT) {
      return <BillingInvoice />
    }
    if (activeTab === TAB_SUBSCRIPTION) {
      return <BillingSubscription />
    }
  }

  return (
    <Section
      title="Billing"
      bodyClass="section-billing"
      isCollapsed={false}
    >
      <div className="tab-list">
        {
          tabList.map(tab => (
            <button
              key={tab.tab}
              type="button"
              className={`tab${activeTab === tab.tab ? ' selected' : ''}`}
              onClick={() => { setActiveTab(tab.tab) }}
            >
              { tab.name }
            </button>
          ))
        }
      </div>
      { renderContents() }
    </Section>
  )
}

export default SectionBilling
