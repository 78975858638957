import React from 'react'

import OpKeywordEx from './OpKeywordEx'
import OpPlacement from './OpPlacement'
import OpKeywordIgnore from './OpKeywordIgnore'

const OpKeywordGeneral = ({ details }) => {
  return (
    <>
      <div className="setting-subsection">
        <div className="subsection-name">
        </div>
        <div className="setting-wrapper">
          Minimum Bid:
          <span className="setting-value">{ details.min_bid_price }</span>
        </div>
        <div className="setting-wrapper">
          Maximum Bid:
          <span className="setting-value">{ details.max_bid_price }</span>
        </div>
      </div>
      <OpKeywordEx details={details} />
      <OpPlacement details={details} />
      <OpKeywordIgnore details={details} />
    </>
  )
}

export default OpKeywordGeneral
