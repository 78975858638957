import {
  CHECK_AUTH_SUCCEED,
  SIGNUP_BASIC_START,
  SIGNUP_BASIC_SUCCEED,
  SIGNUP_BASIC_FAILED,
  SIGNUP_NAME_INPUT,
  AD_CODE_START,
  AD_CODE_SUCCEED,
  AD_CODE_FAILED,
  SIGNUP_SUCCEED,
  ADD_ACCOUNT_OPEN,
} from '../actionTypes/auth.js'

export const initialState = {
  user: {},
  isSigningBasic: false,
  signupBasicInfo: null,
  isADCodeGetting: false,
  adRefreshToken: '',
  profileList: [],
}

const auth = (state = initialState, action) => {
	switch (action.type) {
    case CHECK_AUTH_SUCCEED:
      return {
        ...state,
        user: action.data.user,
      }
    case SIGNUP_BASIC_START:
      return {
        ...state,
        isSigningBasic: true,
        signupBasicInfo: null,
        isADCodeGetting: false,
        adRefreshToken: '',
        profileList: [],
      }
    case SIGNUP_BASIC_SUCCEED:
      return {
        ...state,
        isSigningBasic: false,
        signupBasicInfo: action.data,
      }
    case SIGNUP_BASIC_FAILED:
      return {
        ...state,
        isSigningBasic: false,
      }
    case SIGNUP_NAME_INPUT:
      return {
        ...state,
        signupBasicInfo: Object.assign({}, state.signupBasicInfo || {}, action.data),
      }
    case AD_CODE_START:
      return {
        ...state,
        isADCodeGetting: true,
        adRefreshToken: '',
        profileList: [],
      }
    case AD_CODE_SUCCEED:
      return {
        ...state,
        isADCodeGetting: false,
        adRefreshToken: action.data.refreshToken,
        profileList: action.data.profiles,
      }
    case AD_CODE_FAILED:
      return {
        ...state,
        isADCodeGetting: false,
      }
    case SIGNUP_SUCCEED:
      return {
        ...state,
        isSigningBasic: false,
        signupBasicInfo: null,
        isADCodeGetting: false,
        adRefreshToken: '',
        profileList: [],
      }
    case ADD_ACCOUNT_OPEN:
      return {
        ...state,
        isADCodeGetting: false,
        adRefreshToken: '',
        profileList: [],
      }
    default:
      return state
  }
}

export default auth