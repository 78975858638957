import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../../../CommonComponents/ToastComponent/toast'
import TableCell from '../../../../../CommonComponents/TableCell'

import ModalAddNegatives from '../../../../modals/ModalAddNegatives'

import {
  getSTData,
  getNegativeKWData,
  getNegativeAsinData,
} from '../../../../../../redux/actions/campaignDetail'

import {
  capitalizeFirstLetter,
  copyToClipboard,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
  isAsin,
} from '../../../../../../services/helper'

import { bulkSTColumnList } from '../../../../../../utils/defaultValues'

const columns = [
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'keyword', name: 'Associated Target', className: 'col-keyword' },
  ...bulkSTColumnList,
]

const STTab = ({ campaignType, currentAdGroupId, hideKeywords, hideAsins,
  hideNegatedTerms, isProfitable, selectedMatchType }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)
  const stData = useSelector(state => state.campaignDetail.stData)
  const negativeKWData = useSelector(state => state.campaignDetail.negativeKWData)

  const { id: campaignId } = useParams()

  const [searchTerms, setSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [showAddNegativesModal, setShowAddNegativesModal] = useState(false)
  const [negativeAsinData, setNegativeAsinData] = useState([])

  // Find search terms.
  useEffect(() => {
    let abortCtrl
    if (currentStartDate && currentEndDate) {
      abortCtrl = new AbortController();
      (async () => {
        const accessToken = await getAccessTokenSilently()
        dispatch(getSTData(accessToken, {
          campaignId,
          campaignType,
          isProfitable,
          stOnly: hideKeywords,
          targetAcos: currentAcos || 0,
        }, abortCtrl.signal))
      })()
    }
    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentStartDate, currentEndDate, campaignId, currentAcos, hideKeywords, isProfitable]) // eslint-disable-line

  // Find negative keywords.
  useEffect(() => {
    if (!currentStartDate || !currentEndDate || !hideNegatedTerms) {
      return
    }

    let adGroupIds
    if (currentAdGroupId) {
      adGroupIds = currentAdGroupId
    } else if (currentAdGroups && currentAdGroups.length > 0) {
      adGroupIds = currentAdGroups.map(adGroup => adGroup.adgroupid).join()
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getNegativeKWData(accessToken, {
        campaignId,
        campaignType,
      }))

      const data = await dispatch(getNegativeAsinData(accessToken, {
        campaignType,
        campaignId,
        adGroupIds,
      }))
      setNegativeAsinData(data)
    })()
  }, [currentStartDate, currentEndDate, campaignId, currentAdGroupId, hideNegatedTerms]) // eslint-disable-line

  // Filter found search terms.
  useEffect(() => {
    if (!stData || !stData.length) {
      return
    }

    let filteredSTs = []
    if (!currentAdGroupId) {
      filteredSTs = stData[0]
    } else {
      filteredSTs = stData[1].filter(st =>
        st.adgroup_id.toString() === currentAdGroupId.toString()
      )
    }

    // Remove ASINs.
    if (hideAsins) {
      filteredSTs = filteredSTs.filter(st => !isAsin(st.search))
    }

    // New terms only.
    if (hideNegatedTerms) {
      if (negativeKWData && negativeKWData.length) {
        filteredSTs = filteredSTs.filter(st =>
          !negativeKWData.find(
            negative => negative.keyword_text.trim() === st.search.trim()
          )
        )
      }

      if (negativeAsinData && negativeAsinData.length) {
        filteredSTs = filteredSTs.filter(st =>
          negativeAsinData.indexOf(st.search.trim().toUpperCase()) === -1
        )
      }
    }

    if (selectedMatchType.value !== '') {
      filteredSTs = filteredSTs.filter(record =>
        (record.match_type || '').toLowerCase() === selectedMatchType.value
      )
    }

    filteredSTs = filteredSTs.map(record => ({
      ...calcDerivedMetrics(record),
      matchType: capitalizeFirstLetter(record.match_type),
      keywordText: record.keyword === '(_targeting_auto_)' ? '*' : record.keyword,
    }))

    setSearchTerms(filteredSTs)
  }, [stData, currencyRate, currencySign, currentAdGroupId, negativeKWData,
    negativeAsinData, hideAsins, hideNegatedTerms, selectedMatchType])

  const handleCopy = () => {
    const sts = searchTerms.filter(st => (
      selectedSearchTerms.indexOf(st.id) !== -1
    )).map(st => st.search.trim())

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedSearchTerms.length} search term${selectedSearchTerms.length > 1 ? 's' : ''}.`
    })
  }

  const handleSearchTermClick = (searchTerm) => () => {
    copyToClipboard(searchTerm)
    toast.show({
      title: 'Success',
      description: 'Search term copied.',
    })
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    return (
      <>
        {
          !isProfitable && (
            <button
              type="button"
              className="btn btn-blue"
              onClick={() => { setShowAddNegativesModal(true) }}
            >
              Add negative{selectedSearchTerms.length > 0 ? 's' : ''} to campaign
            </button>
          )
        }
        <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
          Copy
        </button>
      </>
    )
  }

  const renderST = record => (
    <>
      <div
        className="table-col col-search-term"
        title={record.search}
        onClick={handleSearchTermClick(record.search)}
      >
        <strong className="contents">{ record.search }</strong>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col col-keyword">
        { record.keywordText }
      </div>
      {
        bulkSTColumnList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const selectedSTs = (searchTerms || []).filter(st => (
    selectedSearchTerms.indexOf(st.id) !== -1
  ))

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['search', 'matchType', 'keyword'])}
        className="table-search-terms"
        records={searchTerms || []}
        idField="id"
        searchFields={['search']}
        selectedRecords={selectedSearchTerms}
        hasSticky
        hasDateRange
        filterName="campaignDetailSt"
        exportFileName="CampaignDetailSearchTerm"
        getExportData={getExportData}
        renderRecord={renderST}
        renderTopRight={renderAction}
        onChange={setSelectedSearchTerms}
      />
      {
        !isProfitable && (
          <ModalAddNegatives
            showModal={showAddNegativesModal}
            terms={selectedSTs}
            modalType="st"
            campaignType={campaignType}
            currentAdGroupId={currentAdGroupId}
            onClose={() => { setShowAddNegativesModal(false) }}
          />
        )
      }
    </>
  )
}

export default STTab
