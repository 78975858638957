import React from 'react'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import Lookback from './Lookback'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'

const OpKeywordBasic = ({ campaign, settings, isNonEndemicAccount, onChange }) => {
  let isAutoSP = false
  if (campaign) {
    // For auto targeting Sponsored Products, Advanced settings are disabled.
    isAutoSP = campaign.basic[0].type === 'sp'
      && campaign.basic[0].targeting_type === 'auto'
  }

  let hasEnabeldAdvanced = false
  if (!isAutoSP) {
    const advancedSettings = [
      'op_adv_zero_sale_active',
      'op_adv_high_acos_active',
      'op_adv_genius_bid_active',
      'op_adv_low_ctr_active',
      'op_adv_low_conversion_active',
    ]

    hasEnabeldAdvanced = typeof advancedSettings.find(field => (
      settings[field] || settings[`copy_${field}`]
    )) !== 'undefined'
  }

  return (
    <div className={`keyword-basic-container${hasEnabeldAdvanced ? ' disabled' : ''}`}>
      {
        !isAutoSP && hasEnabeldAdvanced && (
          <div className="override-overlay">
            When Advanced Settings conflict with Basic Settings,
            your Advanced Settings will overrule Basic Settings.
            To run only Basic Settings, please deselect the Advanced Settings
            currently in use.
          </div>
        )
      }
      <div className="checkbox-info-wrapper">
        <CheckboxComponent
          label="We'll update individual target bids daily"
          checked={settings.op_basic_active}
          onChange={(checked) => { onChange('op_basic_active', checked) }}
        />
        {
          !isNonEndemicAccount ? (
            <CustomTooltip>
              <p>Set Target ACoS along with the minimum and maximum bid allowed.</p>
              <p>Our system will automatically adjust (raise or lower bids)
              for each individual target to meet your Target ACoS.</p>
              <p>You can also adjust the minimum click and impression thresholds
              that must be hit over the lookback period before our system takes action.</p>
            </CustomTooltip>
          ) : (
            <CustomTooltip>
              <p>Set CPC/VCPM Target along with the minimum and maximum bid allowed.</p>
              <p>Our system will automatically adjust (raise or lower bids)
              for each individual target to meet your CPC/VCPM Target.</p>
              <p>You can also adjust the minimum click and impression thresholds
              that must be hit over the lookback period before our system takes action.</p>
            </CustomTooltip>
          )
        }
      </div>
      <FieldRow disabled={!settings.op_basic_active}>
        <FieldNumber
          label={!isNonEndemicAccount ? 'Target ACoS' : 'CPC/VCPM Target'}
          settings={settings}
          field="target_acos"
          tooltip={`Bids will update daily and target this ${!isNonEndemicAccount ? 'ACoS' : 'CPC'}.`}
          onChange={onChange}
        />
        <FieldNumber
          label="Min Clicks"
          settings={settings}
          field="min_clicks"
          tooltip="The minimum number of clicks that an individual target must have  during the lookback period before the smart pilot takes action."
          onChange={onChange}
        />
        <FieldNumber
          label="Min Impressions"
          settings={settings}
          field="min_impressions"
          tooltip="The minimum number of impressions that an individual target must have during the lookback period before the smart pilot takes action."
          onChange={onChange}
        />
      </FieldRow>
      <Lookback field="op_lookback" settings={settings} onChange={onChange} />
    </div>
  )
}

export default OpKeywordBasic
