import {
  GET_TESTS_START,
  GET_TESTS_SUCCEED,
  GET_CURRENT_TEST_START,
  GET_CURRENT_TEST_SUCCEED,
  GET_CURRENT_TEST_FAIL,
  GET_KEYWORDS_START,
  GET_KEYWORDS_SUCCEED,
} from '../actionTypes/productDetail'

export const initialState = {
  isLoadingTests: false,
  tests: [],
  isLoadingTest: false,
  currentTest: {},
  isGettingKeywords: false,
  keywords: [],
}

const productDetail = (state = initialState, action) => {
  switch (action.type) {
    case GET_KEYWORDS_START:
      return {
        ...state,
        isGettingKeywords: true
      }
    case GET_KEYWORDS_SUCCEED:
      return {
        ...state,
        isGettingKeywords: false,
        keywords: action.data
      }
    case GET_TESTS_START:
      return {
        ...state,
        isLoadingTests: true,
      }
    case GET_TESTS_SUCCEED:
      return {
        ...state,
        isLoadingTests: false,
        tests: action.data,
      }
    case GET_CURRENT_TEST_START:
      return {
        ...state,
        isLoadingTest: true,
        currentTest: {},
      }
    case GET_CURRENT_TEST_SUCCEED:
      return {
        ...state,
        isLoadingTest: false,
        currentTest: action.data,
      }
    case GET_CURRENT_TEST_FAIL:
      return {
        ...state,
        isLoadingTest: false,
      }
    default:
      return state
  }
}

export default productDetail