/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Select from 'react-select'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import SBSubPagesOrderModal from './SBSubPagesOrderModal'

const ProductOption = (props) => {
  const { innerRef, innerProps, getStyles, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
      className="product-option"
    >
      {
        data.image !== '' ? (
          <img src={data.image} alt={data.name} />
        ) : (
          <span className="placeholder-image" title={data.name}>
            Image
          </span>
        )
      }
      <div className="product-info">
        <span className="product-name" title={data.name}>{data.name}</span>
        <span className="product-sku">ASIN: {data.asin} | SKU: {data.sku}</span>
      </div>
    </div>
  )
}

const SBCreativeStorePages = ({ subPages, creativeSubPages, onChange }) => {
  const [openModal, setOpenModal] = useState(false)
  const [subPageIdsToShowSelector, setSubPageIdsToShowSelector] = useState([])
  const [subPageProducts, setSubPageProducts] = useState({})

  const handleNameChange = subPageId => (event) => {
    const name = event.target.value
    onChange(
      subPages.map((subPage) => {
        if (subPage.id !== subPageId) {
          return subPage
        }
        return {
          ...subPage,
          name,
        }
      }),
      creativeSubPages.map((subPage) => {
        if (subPage.id !== subPageId) {
          return subPage
        }
        return {
          ...subPage,
          name,
        }
      }),
    )
  }

  const handleOrderChange = (orderedSubPages) => {
    setOpenModal(false)
    onChange(subPages, orderedSubPages)
  }

  const handleImageChange = creativeSubPage => (event) => {
    event.preventDefault()
    setSubPageProducts(prev => ({
      ...prev,
      [creativeSubPage.id]: creativeSubPage.product,
    }))
    setSubPageIdsToShowSelector(prev => ([
      ...prev,
      creativeSubPage.id,
    ]))
  }

  const handleProductSelect = creativeSubPage => (option) => {
    setSubPageProducts(prev => ({
      ...prev,
      [creativeSubPage.id]: option,
    }))
  }

  const handleImageChangeDone = creativeSubPage => (event) => {
    event.preventDefault()
    onChange(
      subPages,
      creativeSubPages.map((subPage) => {
        if (subPage.id !== creativeSubPage.id) {
          return subPage
        }
        return {
          ...subPage,
          product: subPageProducts[creativeSubPage.id],
        }
      }),
    )
    setSubPageIdsToShowSelector(prev => prev.filter(id =>
      id !== creativeSubPage.id
    ))
  }

  const renderProductSelector = (creativeSubPage) => {
    if (!subPageIdsToShowSelector.includes(creativeSubPage.id)) {
      return (
        <a
          href="#"
          onClick={handleImageChange(creativeSubPage)}
        >
          Change Image
        </a>
      )
    }

    const subPage = subPages.find(subPage =>
      subPage.id === creativeSubPage.id
    )

    return (
      <>
        <Select
          className="product-selector"
          options={subPage.products}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={subPageProducts[creativeSubPage.id]}
          components={{ Option: ProductOption }}
          onChange={handleProductSelect(creativeSubPage)}
        />
        <a
          href="#"
          onClick={handleImageChangeDone(creativeSubPage)}
        >
          Done
        </a>
      </>
    )
  }

  return (
    <div className="customizer-section">
      <div className="field-section-name">
        Store Pages
        <CustomTooltip placement="right">
          <p>You can promote any Store page (except for the home page)
            in your ad.</p>
        </CustomTooltip>
      </div>
      <div className="field-section-contents">
        <div className="sub-page-list">
          {
            creativeSubPages.map(subPage => (
              <div key={subPage.id} className="sub-page-wrapper">
                <div className="sub-page-name">
                  <a
                    href={subPage.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    { subPage.name }
                  </a>
                </div>
                <div className="sub-page-details">
                  <img
                    src={subPage.product.image}
                    alt={subPage.product.name}
                  />
                  <div className="display-name-wrapper">
                    Display name
                    <textarea
                      value={subPage.name}
                      onChange={handleNameChange(subPage.id)}
                    />
                    { renderProductSelector(subPage) }
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setOpenModal(true) }}
        >
          Change Order
        </button>
      </div>
      {
        openModal && (
          <SBSubPagesOrderModal
            subPages={subPages}
            creativeSubPages={creativeSubPages}
            onChange={handleOrderChange}
            onClose={() => { setOpenModal(false) }}
          />
        )
      }
    </div>
  )
}

export default SBCreativeStorePages
