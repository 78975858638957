import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { parseISO, format } from 'date-fns'

import CustomTable from '../CommonComponents/CustomTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import {
  getJobs,
  deleteJob,
} from '../../redux/actions/job'

import {
  JOB_STATUS_COMPLETED,
  JOB_TYPE_BULK_ST_OP,
  JOB_TYPE_BULK_NEGATIVE,
  JOB_TYPE_BULK_TARGET_EX,
  JOB_TYPE_BULK_ST_EX,
  JOB_TYPE_BULK_PRODUCT_EX,
  JOB_TYPE_BULK_TARGET_SEARCH,
  jobStatusLabels,
  JOB_TYPE_AUDIT_REPORT,
} from '../../utils/defaultValues'
import {
  formatValue,
  getISODate,
  getJobName,
  getTemporaryDownloadLink,
} from '../../services/helper'

const BulkJobs = ({ onOpenResults }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController()
    retrieveJobs(abortCtrl.signal)

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const retrieveJobs = async (signal = undefined) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    try {
      const response = await dispatch(getJobs(accessToken, 'bulk_engine', signal))
      setJobs(response)
      setIsLoading(false)
    } catch (isCancelled) {
      if (!isCancelled) {
        setIsLoading(false)
      }
    }
  }

  const handleDownload = (url) => {
    window.open(getTemporaryDownloadLink(url), 'blank')
  }

  const handleOpen = async (job) => {
    setIsLoading(true)
    onOpenResults(job.id).catch((error) => {
      setIsLoading(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to open results.',
      })
    })
  }

  const handleDelete = async (jobId) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteJob(accessToken, jobId)).then(() => {
      setIsLoading(false)
      setJobs(jobs.filter(job => parseInt(job.id, 10) !== parseInt(jobId, 10)))
    }).catch((error) => {
      setIsLoading(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to delete a report.',
      })
    })
  }

  const renderTop = () => (
    <>
      <button type="button" className="btn btn-blue" onClick={() => { retrieveJobs() }}>
        Refresh
      </button>
      <div>
        Generated Reports remain available for 30 days.
      </div>
    </>
  )

  const getRemarks = (record) => {
    if (record.type === JOB_TYPE_BULK_ST_OP) {
      return record.payload.newTermOnly ? 'New Only' : ''
    }
    if (record.type === JOB_TYPE_BULK_NEGATIVE) {
      return (
        <>
          Target ACoS: { parseFloat(record.payload.targetAcos).toFixed(1) }
          {
            record.payload.stOnly ? (
              <>
                <br />
                Remove keywords
              </>
            ) : ''
          }
        </>
      )
    }
    if (record.type === JOB_TYPE_BULK_TARGET_EX) {
      return `${record.payload.targets.length} targets`
    }
    if (record.type === JOB_TYPE_BULK_ST_EX) {
      return (
        <>
          Target ACoS Range:&nbsp;
          { parseFloat(record.payload.acosStart).toFixed(1) }
          &nbsp;~&nbsp;
          { parseFloat(record.payload.acosEnd).toFixed(1) }
          {
            record.payload.stOnly ? (
              <>
                <br />
                Remove keywords
              </>
            ) : ''
          }
        </>
      )
    }
    if (record.type === JOB_TYPE_BULK_PRODUCT_EX) {
      return (
        <>
          Target ACoS Range:&nbsp;
          { parseFloat(record.payload.acosStart).toFixed(1) }
          &nbsp;~&nbsp;
          { parseFloat(record.payload.acosEnd).toFixed(1) }
          {
            (record.payload.typeTarget === 'asin' && record.payload.newAsinOnly) ? (
              <>
                <br />
                New ASINs Only
              </>
            ) : ''
          }
        </>
      )
    }
    if (record.type === JOB_TYPE_BULK_TARGET_SEARCH) {
      return (
        <>
          Target: { record.payload.keyword }
          {
            record.payload.includeZeroSpend ? (
              <>
                <br />
                Include zero spend targets
              </>
            ) : ''
          }
        </>
      )
    }
    return ''
  }

  const renderCampaignInfo = (record) => {
    if (record.type === JOB_TYPE_AUDIT_REPORT) {
      return null
    }

    const campaignNames = (record.payload.campaigns || [])
    let suffix = null
    if (record.payload.campaignIds.length
      && record.payload.campaignIds.length > campaignNames.length) {
      const diff = record.payload.campaignIds.length - campaignNames.length
      suffix = (
        <em>
          and {diff} more campaign{diff > 1 ? 's' : ''}
        </em>
      )
    }

    return (
      <>
        { formatValue(record.payload.campaignIds.length, 'number', 0) }
        {
          campaignNames.length > 0 && (
            <CustomTooltip placement="right" trigger="click" className="popup-campaign-names">
              <ul>
                {
                  campaignNames.map(campaign => (
                    <li key={campaign}>{campaign}</li>
                  ))
                }
              </ul>
              { suffix }
            </CustomTooltip>
          )
        }
      </>
    )
  }

  const renderJob = (record) => (
    <>
      <div className="table-col">
        <strong>{ getJobName(record.type, record.payload) }</strong>
      </div>
      <div className="table-col">
        { record.payload.startDate ? getISODate(record.payload.startDate) : '' }
      </div>
      <div className="table-col">
        { record.payload.endDate ? getISODate(record.payload.endDate) : '' }
      </div>
      <div className="table-col">
        { renderCampaignInfo(record) }
      </div>
      <div className="table-col">
        {
          record.type !== JOB_TYPE_AUDIT_REPORT
          ? formatValue(record.results_count, 'number', 0)
          : ''
        }
      </div>
      <div className="table-col">
        { getRemarks(record) }
      </div>
      <div className="table-col">
        { jobStatusLabels[record.status] }
      </div>
      <div className="table-col">
        { format(parseISO(record.created_at), 'yyyy-MM-dd HH:mm') }
      </div>
      <div className="table-col">
        {
          record.status === JOB_STATUS_COMPLETED && (
            <>
              {
                record.type !== JOB_TYPE_AUDIT_REPORT && (
                  <IconButton
                    icon={<Icon icon="folder-open"/>}
                    size="md"
                    appearance="primary"
                    title="Open"
                    onClick={() => handleOpen(record)}
                  />
                )
              }
              <IconButton
                icon={<Icon icon="download"/>}
                size="md"
                appearance="default"
                title="Download"
                onClick={() => handleDownload(record.url)}
              />
            </>
          )
        }
        <IconButton
          icon={<Icon icon="trash-o"/>}
          size="md"
          appearance="subtle"
          title="Delete"
          onClick={() => handleDelete(record.id)}
        />
      </div>
    </>
  )

  return (
    <div className={`bulk-engine-container${isLoading ? ' loading' : ''}`}>
      <CustomTable
        className="table-jobs"
        records={jobs}
        isLoading={isLoading}
        idField="id"
        noCheckBox
        noSearch
        renderRecord={renderJob}
        renderTopRight={renderTop}
      >
        <div className="table-col">Report Type</div>
        <div className="table-col">Start Date</div>
        <div className="table-col">End Date</div>
        <div className="table-col">Campaigns</div>
        <div className="table-col">Results</div>
        <div className="table-col">Remarks</div>
        <div className="table-col">Status</div>
        <div className="table-col">Requested At</div>
        <div className="table-col"></div>
      </CustomTable>
    </div>
  )
}

export default BulkJobs
