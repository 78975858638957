import React from 'react'
import { useDispatch } from 'react-redux'
import { components } from 'react-select'

import SelectAsyncPaginate from '../CommonComponents/SelectAsyncPaginate'

import { loadKeywords } from '../../redux/actions/rank'

// https://github.com/JedWatson/react-select/issues/4170#issuecomment-682465724
const ValueContainer = (props) => {
  const { options, children, getValue } = props
  const selectCount = getValue().length
  let contents = children
  if (selectCount > 0) {
    if (selectCount === options.length) {
      contents = (
        <>
          All keywords selected
          { children[1] }
        </>
      )
    } else if (selectCount >= 10) {
      contents = (
        <>
          { selectCount } keywords selected
          { children[1] }
        </>
      )
    }
  }
  return (
    <components.ValueContainer {...props}>
      { contents }
    </components.ValueContainer>
  )
}

const KeywordSelector = ({ campaign, selectedKeywords, onChange }) => {
  const dispatch = useDispatch()

  const keywordLoader = (accessToken, page, search) => {
    if (!campaign) {
      return []
    }

    return dispatch(loadKeywords(accessToken, {
      campaignId: campaign.id,
      campaignType: campaign.type,
      page,
      search,
      countPerPage: 10,
    }))
  }

  return (
    <SelectAsyncPaginate
      keyValue={`keywords-${campaign ? campaign.id : ''}`}
      className="keyword-selector"
      classNamePrefix="keyword-selector"
      components={{ ValueContainer }}
      loadData={keywordLoader}
      selection={selectedKeywords}
      isDisabled={!campaign}
      placeholder="Select keywords to track"
      field="keyword"
      onChange={onChange}
    />
  )
}

export default KeywordSelector
