import React from 'react'

import Frequency from './Frequency'

const settings = [
  {
    setting: 'op_adv_zero_sale_active',
    name: 'Zero Sale Targets',
    fields: [
      { field: 'op_adv_zero_sale_clicks', name: 'Clicks and no sales' },
    ],
    prefix: 'op_adv_zero_sale_',
  },
  {
    setting: 'copy_op_adv_zero_sale_active',
    name: 'Zero Sale Targets (clone)',
    fields: [
      { field: 'copy_op_adv_zero_sale_clicks', name: 'Clicks and no sales' },
    ],
    prefix: 'copy_op_adv_zero_sale_',
  },
  {
    setting: 'op_adv_high_acos_active',
    name: 'High ACoS Targets',
    fields: [
      { field: 'op_adv_high_acos_acos', name: 'ACoS %' },
      { field: 'op_adv_high_acos_clicks', name: 'With at least this many clicks' },
    ],
    prefix: 'op_adv_high_acos_',
  },
  {
    setting: 'copy_op_adv_high_acos_active',
    name: 'High ACoS Targets (clone)',
    fields: [
      { field: 'copy_op_adv_high_acos_acos', name: 'ACoS %' },
      { field: 'copy_op_adv_high_acos_clicks', name: 'With at least this many clicks' },
    ],
    prefix: 'copy_op_adv_high_acos_',
  },
  {
    setting: 'op_adv_genius_bid_active',
    name: 'Unprofitable Targets',
    fields: [
      { field: 'op_adv_genius_bid_min_acos', name: 'ACoS from' },
      { field: 'op_adv_genius_bid_max_acos', name: 'to' },
      { field: 'op_adv_genius_bid_clicks', name: 'With at least this many clicks' },
    ],
    prefix: 'op_adv_genius_bid_',
  },
  {
    setting: 'copy_op_adv_genius_bid_active',
    name: 'Unprofitable Targets (clone)',
    fields: [
      { field: 'copy_op_adv_genius_bid_min_acos', name: 'ACoS from' },
      { field: 'copy_op_adv_genius_bid_max_acos', name: 'to' },
      { field: 'copy_op_adv_genius_bid_clicks', name: 'With at least this many clicks' },
    ],
    prefix: 'copy_op_adv_genius_bid_',
  },
  {
    setting: 'op_adv_low_ctr_active',
    name: 'Low CTR Targets',
    fields: [
      { field: 'op_adv_low_ctr_impressions', name: 'Minimum impressions to trigger' },
      { field: 'op_adv_low_ctr_ctr', name: 'CTR targets' },
    ],
    prefix: 'op_adv_low_ctr_',
  },
  {
    setting: 'copy_op_adv_low_ctr_active',
    name: 'Low CTR Targets (clone)',
    fields: [
      { field: 'copy_op_adv_low_ctr_impressions', name: 'Minimum impressions to trigger' },
      { field: 'copy_op_adv_low_ctr_ctr', name: 'CTR targets' },
    ],
    prefix: 'copy_op_adv_low_ctr_',
  },
  {
    setting: 'op_adv_low_conversion_active',
    name: 'Low Converting Targets',
    fields: [
      { field: 'op_adv_low_conversion_conversion', name: 'Conversion or less' },
    ],
    prefix: 'op_adv_low_conversion_',
  },
  {
    setting: 'copy_op_adv_low_conversion_active',
    name: 'Low Converting Targets (clone)',
    fields: [
      { field: 'copy_op_adv_low_conversion_conversion', name: 'Conversion or less' },
    ],
    prefix: 'copy_op_adv_low_conversion_',
  },
]

const actionLabels = {
  'Pause Keyword/Target': 'Pause Keyword/Target',
  'Lower Bid By %': 'Lower Bid By %',
  'Lower Bid By $': 'Lower Bid By $',
  raise_by_percent: 'Raise Bid By %',
  raise_by_absolute: 'Raise Bid By $',
  'Lower Bid to Max CPC': 'Apply Genius Bid',
  cpc: 'Ave CPC',
  cpc_raise_by_absolute: 'Ave CPC + ($)',
  cpc_lower_by_absolute: 'Ave CPC - ($)',
  cpc_raise_by_percent: 'Ave CPC + (%)',
  cpc_lower_by_percent: 'Ave CPC - (%)',
}

const OpKeywordAdvanced = ({ details }) => {
  const renderAdvSetting = ({ setting, name, fields, prefix }) => {
    if (!details[setting]) {
      return null
    }

    const action = details[`${prefix}action`]

    return (
      <div key={setting} className="setting-subsection">
        <div className="subsection-name">
          { name }
        </div>
        {
          fields.map(field => (
            <div key={field.field} className="setting-wrapper">
              { field.name }:
              <span className="setting-value">{ details[field.field] }</span>
            </div>
          ))
        }
        {
          details[`${prefix}cost_active`] === true && (
            <div className="setting-wrapper">
              Target has at least this amount of spend:
              <span className="setting-value">
                { parseFloat(details[`${prefix}cost_threshold`]).toFixed(1) }
              </span>
            </div>
          )
        }
        <div className="setting-wrapper">
          Frequency:
          <span className="setting-value">
            <Frequency details={details} prefix={prefix} />
          </span>
        </div>
        <div className="setting-wrapper">
          Lookback Period (days):
          <span className="setting-value">
            { details[`${prefix}lookback`] }
          </span>
        </div>
        {
          action !== null && action !== '' && action !== 'undefined' && (
            <div className="setting-wrapper">
              Action:
              <span className="setting-value">
                { actionLabels[action] }
                {
                  (action === 'Lower Bid By %'
                    || action === 'Lower Bid By $'
                    || action === 'raise_by_percent'
                    || action === 'raise_by_absolute'
                    || action === 'cpc_raise_by_absolute'
                    || action === 'cpc_lower_by_absolute'
                    || action === 'cpc_raise_by_percent'
                    || action === 'cpc_lower_by_percent') && (
                    `: ${details[`${prefix}action_value`]}`
                  )
                }
              </span>
            </div>
          )
        }
      </div>
    )
  }

  return settings.map(renderAdvSetting)
}

export default OpKeywordAdvanced
