import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { FiExternalLink } from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { getTargets } from '../../redux/actions/campaignDetail'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  formatCurrency,
  parseTargetExp,
  getAmazonCategoryLink,
  getAmazonAsinLink,
  capitalizeFirstLetter
} from '../../services/helper'

const matchTypeOptions = {
  exact: 'Exact',
  phrase: 'Phrase',
  broad: 'Broad',
  pat: 'PAT'
}

const TargetsModal = ({ campaignId, campaignType, adgroups, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)

  const [isLoading, setIsLoading] = useState(false)
  const [targets, setTargets] = useState([])
  const [selectedAdgroup, setSelectedAdgroup] = useState(null)

  useEffect(() => {
    if (adgroups.length > 0) {
      setSelectedAdgroup(adgroups[0])
    }
  }, [adgroups])

  useEffect(() => {
    if (!selectedAdgroup) {
      return
    }

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const data = await dispatch(getTargets(accessToken, {
        campaignId,
        campaignType,
        adgroupId: selectedAdgroup.adgroup_id,
        targetType: selectedAdgroup.targetType,
      }))
      setTargets(data)
      setIsLoading(false)
    })()
  }, [ // eslint-disable-line
    campaignId, // eslint-disable-line
    campaignType,
    selectedAdgroup,
  ])

  const handleAdgroupSelect = adgroup => () => {
    setTargets([])
    setSelectedAdgroup(adgroup)
  }

  const renderTargetCell = (record) => {
    const target = parseTargetExp(record.target_text)
    if (!target) {
      return null
    }
    let link
    if (target.indexOf('asin=') === 0) {
      try {
        const parsed = JSON.parse(record.target_text)
        link = (
          <a
            href={getAmazonAsinLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        link = null
      }
    } else if (target.indexOf('category=') === 0) {
      try {
        const parsed = JSON.parse(record.target_exp)
        link = (
          <a
            href={getAmazonCategoryLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        link = null
      }
    }

    return (
      <div className="table-col col-target" title={target}>
        <div className="target-text-wrapper">
          <strong>
            { target }
          </strong>
          { link }
        </div>
      </div>
    )
  }

  const renderKeyword = (record) => (
    <>
      <div className="table-col">
        <strong>{ record.keyword }</strong>
      </div>
      <div className="table-col">
        { matchTypeOptions[(record.match_type || '').toLowerCase()] }
      </div>
      <div className="table-col">
        { capitalizeFirstLetter(record.state) }
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderTarget = (record) => (
    <>
      { renderTargetCell(record) }
      <div className="table-col">
        { capitalizeFirstLetter(record.state) }
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderTable = () => {
    if (!selectedAdgroup) {
      return null
    }

    if (selectedAdgroup.targetType === 'keywords') {
      return (
        <CustomTable
          className="table-keywords"
          isLoading={isLoading}
          records={targets}
          noCheckBox
          idField="keyword_id"
          searchFields={['keyword']}
          renderRecord={renderKeyword}
        >
          <div className="table-col">Keyword</div>
          <div className="table-col">Match Type</div>
          <div className="table-col">State</div>
          <div className="table-col">Current Bid</div>
        </CustomTable>
      )
    }

    return (
      <CustomTable
        className="table-keywords"
        isLoading={isLoading}
        records={targets}
        noCheckBox
        idField="target_id"
        searchFields={['target_exp', 'target_text']}
        renderRecord={renderTarget}
      >
        <div className="table-col">Target</div>
        <div className="table-col">State</div>
        <div className="table-col">Current Bid</div>
      </CustomTable>
    )
  }

  return (
    <Modal className="targets-view-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Targets / Keywords
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="adgroup-selector">
          {
            adgroups.length > 0 && adgroups.map(adgroup => (
              <button
                key={adgroup.adgroup_id}
                type="button"
                className={`btn ${adgroup.adgroup_id === (selectedAdgroup?.adgroup_id || '') ? 'btn-blue' : 'btn-white'}`}
                onClick={handleAdgroupSelect(adgroup)}
              >
                { adgroup.name }
              </button>
            ))
          }
        </div>
        { renderTable() }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TargetsModal
