import React from 'react'

import FlowContainer from './FlowContainer'
import YoutubeVideoCollection from './YoutubeVideoCollection'

const videoList = [
  {
    name: 'Rules Overview',
    videoId: 'skqTEO0wWhE',
  },
  {
    name: 'Dayparting',
    videoId: 'DSMq8tMB4p4',
  },
  {
    name: 'Weekparting',
    videoId: 'iofqdocXWsM',
  },
  {
    name: 'Campaign Performance',
    videoId: '4rToPluXB3I',
  },
]

const Rules = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Rules"
      {...props}
    >
      <YoutubeVideoCollection videoList={videoList} onWatch={onWatch}/>
    </FlowContainer>
  )
}

export default Rules
