import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'

import {
  getProductById,
  getProductList,
} from '../../redux/actions/product'

const ProductFilterComponent = ({ onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)

  const [isLoading, setIsLoading] = useState(false)
  const [productList, setProductList] = useState([])

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      const products = await dispatch(getProductList(accessToken))
      setIsLoading(false)
      setProductList(products)
    })()
  }, [currentUserId]) // eslint-disable-line

  const handleClick = async (record) => {
    const accessToken = await getAccessTokenSilently()
    dispatch(getProductById(accessToken, record.id))
    history.push(`/product/${record.id}`)
    onClose()
  }

  const renderProduct = record => (
    <>
      <div className="table-col">
        {
          record.image && record.image !== '' ? (
            <>
              <img src={record.image} alt={record.name} />
              <span>{record.name}</span>
            </>
          ) : (
            <span className="no-image">{record.name}</span>
          )
        }
      </div>
    </>
  )

  return (
    <div className="product-filter-component">
      <CustomTable
        isLoading={isLoading}
        className="table-product-selector"
        records={productList}
        idField="id"
        searchFields={['name', 'sku']}
        noCheckBox
        searchPlaceholder="Search by name or SKU to find product"
        onClickRecord={handleClick}
        renderRecord={renderProduct}
      >
        <div className="table-col">Product</div>
      </CustomTable>
    </div>
  )
}

export default ProductFilterComponent
