import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { parseISO, format } from 'date-fns'

import CustomTable from '../CommonComponents/CustomTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'

import {
  getJobs,
  deleteJob,
} from '../../redux/actions/job'

import { JOB_STATUS_COMPLETED, jobStatusLabels } from '../../utils/defaultValues'
import { getISODate, getTemporaryDownloadLink } from '../../services/helper'

const GeneratedReportsModal = ({
  reportType,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    retrieveJobs()
  }, []) // eslint-disable-line

  const retrieveJobs = async () => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    try {
      const response = await dispatch(getJobs(accessToken, reportType))
      setJobs(response)
      setIsLoading(false)
    } catch (isCancelled) {
      if (!isCancelled) {
        setIsLoading(false)
      }
    }
  }

  const handleDownload = (url) => {
    window.open(getTemporaryDownloadLink(url), 'blank')
  }

  const handleDelete = async (jobId) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteJob(accessToken, jobId)).then(() => {
      setIsLoading(false)
      setJobs(jobs.filter(job => parseInt(job.id, 10) !== parseInt(jobId, 10)))
    }).catch((error) => {
      setIsLoading(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to delete a report.',
      })
    })
  }

  const renderHeader = () => (
    <Modal.Header onHide={() => { onClose(false)}}>
      <Modal.Title>
        Generated Reports
      </Modal.Title>
    </Modal.Header>
  )

  const renderTop = () => (
    <>
      <button type="button" className="btn btn-blue" onClick={() => { retrieveJobs() }}>
        Refresh
      </button>
      <div>
        Generated Reports remain available for 30 days.
      </div>
    </>
  )

  const renderJob = (record) => (
    <>
      <div className="table-col">
        { record.payload.endDate ? getISODate(record.payload.startDate) : '' }
      </div>
      <div className="table-col">
        {  record.payload.endDate ? getISODate(record.payload.endDate) : '' }
      </div>
      <div className="table-col">
        { jobStatusLabels[record.status] }
      </div>
      <div className="table-col">
        { format(parseISO(record.created_at), 'yyyy-MM-dd HH:mm') }
      </div>
      <div className="table-col">
        {
          record.status === JOB_STATUS_COMPLETED && (
            <IconButton
              icon={<Icon icon="download"/>}
              size="md"
              appearance="primary"
              title="Download"
              onClick={() => handleDownload(record.url)}
            />
          )
        }
        <IconButton
          icon={<Icon icon="trash-o"/>}
          size="md"
          appearance="subtle"
          title="Delete"
          onClick={() => handleDelete(record.id)}
        />
      </div>
    </>
  )

  return (
    <Modal className="generated-reports-modal" backdrop="static" show={true} size="lg">
      { renderHeader() }
      <Modal.Body>
        <CustomTable
          className="table-jobs"
          records={jobs}
          isLoading={isLoading}
          idField="id"
          noCheckBox
          noSearch
          renderRecord={renderJob}
          renderTopRight={renderTop}
        >
          <div className="table-col">Start Date</div>
          <div className="table-col">End Date</div>
          <div className="table-col">Status</div>
          <div className="table-col">Requested At</div>
          <div className="table-col"></div>
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default GeneratedReportsModal
