import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Toggle, Button, ButtonGroup, Icon } from 'rsuite'

import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTable from '../CommonComponents/CustomTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'

import { selectCurrentAccount } from '../../redux/reducers/header'
import { validateBudget } from '../../services/validator'

const INCREASE_TYPE = 1
const DECREASE_TYPE = -1

const UpdateDailyBudgetModal = ({ campaigns, onUpdate, onClose }) => {
  const currentAccount = useSelector(selectCurrentAccount)
  
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [defaultBudget, setDefaultBudget] = useState(10)
  const [isPercentageOption, setIsPercentageOption] = useState(false)
  const [percentageMethod, setPercentageMethod] = useState(INCREASE_TYPE)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setSelectedCampaigns(JSON.parse(JSON.stringify(campaigns)))
  }, [campaigns]) // eslint-disable-line

  const handleChangeDefaultBudget = (value) => {
    if (value <= 0) {
      toast.show({
        title: 'Warning',
        description: 'Daily Budget must be higher than 0.',
      })
      return
    }
    setDefaultBudget(value)
  }

  const handleChangeBudget = (campaignId, value) => {
    if (value <= 0) {
      toast.show({
        title: 'Warning',
        description: 'Daily Budget must be higher than 0.',
      })
      return
    }

    setSelectedCampaigns(selectedCampaigns =>
      selectedCampaigns.map(campaign =>
        campaign.campaign_id.toString() === campaignId.toString()
          ? { ...campaign, daily_budget: value }
          : campaign
      )
    )
  }

  const handleApplyAll = () => {
    if (isPercentageOption) {
      const rate = (100 + defaultBudget * percentageMethod) / 100
      setSelectedCampaigns(selectedCampaigns =>
        selectedCampaigns.map(campaign => ({
          ...campaign, daily_budget: campaign.daily_budget * rate
        }))
      )
    } else {
      setSelectedCampaigns(selectedCampaigns =>
        selectedCampaigns.map(campaign => ({ ...campaign, daily_budget: defaultBudget }))
      )
    }
  }

  const handleSave = async () => {
    for (let i = 0; i < selectedCampaigns.length; i++) {
      const error = validateBudget(
        selectedCampaigns[i].daily_budget,
        selectedCampaigns[i].campaignType,
        currentAccount?.country_id,
        currentAccount?.seller_type === 'seller'
      )
      if (error) {
        toast.show({
          title: 'Warning',
          description: error,
        })
        return
      }
    }

    setIsSaving(true)
    await onUpdate(selectedCampaigns)
    setIsSaving(false)
  }

  const renderAction = () => (
    <>
      <Toggle
        checked={isPercentageOption}
        checkedChildren={ <Icon icon="percent" /> }
        unCheckedChildren={ <Icon icon="usd" /> }
        size="lg"
        onChange={() => { setIsPercentageOption(!isPercentageOption) }}
      />
      <label className="action-title">
        {isPercentageOption ? "Percentage (%):" : "Default Daily Budget:"}
      </label>
      <input
        type="number"
        min="0"
        value={defaultBudget}
        onChange={(event) => { handleChangeDefaultBudget(event.target.value) }}
      />
      {isPercentageOption && (
        <ButtonGroup className="percent-control-btn-group">
          <Button
            className={`percent-control-btn ${percentageMethod === INCREASE_TYPE ? 'active' : ''}`}
            appearance="subtle"
            onClick={() => { setPercentageMethod(INCREASE_TYPE) }}
          >
            <Icon icon='sequence-up' />
          </Button>
          <Button
            className={`percent-control-btn ${percentageMethod === DECREASE_TYPE ? 'active' : ''}`}
            appearance="subtle"
            onClick={() => { setPercentageMethod(DECREASE_TYPE) }}
          >
            <Icon icon='sequence-down' />
          </Button>
        </ButtonGroup>
      )}
      <button type="button" className="btn btn-green" onClick={() => handleApplyAll()} >
        Apply All
      </button>
    </>
  )

  const renderRecord = record => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col">
        <input
          type="number"
          min={0}
          value={record.daily_budget || 0}
          onChange={(event) => { handleChangeBudget(record.campaign_id, event.target.value) }}
        />
      </div>
    </>
  )

  return (
    <Modal className="daily-budget-modal" backdrop="static" size="md" show>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Update Daily Budget
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          className="table-budget-rules"
          isLoading={isSaving}
          records={selectedCampaigns || []}
          noCheckBox
          idField="campaign_id"
          noSearch
          renderTopRight={renderAction}
          renderRecord={renderRecord}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col">Daily Budget</div>
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          disabled={isSaving}
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateDailyBudgetModal
