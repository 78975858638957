import React from 'react'

import SBCampaignCreativeComponent from '../../../../CommonComponents/SBCampaignCreativeComponent'
import SDCreativePreview from '../../../../CommonComponents/SDCreativePreview'

const CampaignCreative = ({ campaignId, campaignType, products }) => {
  const renderContents = () => {
    if (campaignType === 'sd') {
      return (
        <SDCreativePreview
          campaignId={campaignId}
          products={products}
        />
      )
    }

    return (
      <SBCampaignCreativeComponent
        campaignId={campaignId}
      />
    )
  }

  return (
    <div className="section">
      <div className="section-header">
        <h4>Campaign Creative</h4>
      </div>
      { renderContents() }
    </div>
  )
}

export default CampaignCreative
