import React from 'react'

import SubSection from '../SubSection'
import OpNTA from './OpNTA'
import OpNPT from '../OpNPT'

const OpNegative = ({ settings, onChange, ...props }) => {
  return (
    <SubSection name="Negative Target Automation" {...props}>
      <OpNTA
        isOriginal
        settings={settings}
        onChange={onChange}
      />
      {
        settings.copy_nta_active && (
          <OpNTA
            settings={settings}
            onChange={onChange}
          />
        )
      }
      <OpNPT
        isOriginal
        settings={settings}
        onChange={onChange}
      />
      {
        settings.copy_npt_active && (
          <OpNPT
            settings={settings}
            onChange={onChange}
          />
        )
      }
    </SubSection>
  )
}

export default OpNegative
