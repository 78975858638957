import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  GET_TESTS_START,
  GET_TESTS_SUCCEED,
  GET_CURRENT_TEST_SUCCEED,
  GET_CURRENT_TEST_START,
  GET_KEYWORDS_START,
  GET_KEYWORDS_SUCCEED,
  GET_CURRENT_TEST_FAIL,
} from '../actionTypes/productDetail'

export const getKeywords = (accessToken) => (dispatch, getState) => {
  const {
    header: { currentUserId, currentStartDate, currentEndDate },
    product: { curProduct },
  } = getState()

  dispatch({
    type: GET_KEYWORDS_START
  })
  callGet('/product/getKeywords', accessToken, {
    userId: currentUserId,
    id: curProduct.id,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    dispatch({
      type: GET_KEYWORDS_SUCCEED,
      data: response.data,
    })
  })
}

export const createTest = (accessToken, data) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    product: { curProduct },
  } = getState()
  return callPost('/product/createTest', {
    userId: currentUserId,
    identifier: curProduct.identifier,
    name: data.name,
    timeframe: data.timeframe,
    before_data: data.before_data,
    after_data: data.after_data,
  }, accessToken)
}

export const getTestById = (accessToken, id) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  dispatch({ type: GET_CURRENT_TEST_START })
  return callGet('/product/getTest', accessToken, {
    userId: currentUserId,
    id,
  }).then((response) => {
    dispatch({
      type: GET_CURRENT_TEST_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_CURRENT_TEST_FAIL,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get test results.',
    })
    return Promise.reject()
  })
}

export const getTests = (accessToken) => (dispatch, getState) => {
  const {
    header: { currentUserId },
    product: { curProduct },
  } = getState()
  dispatch({ type: GET_TESTS_START })
  callGet('/product/getTests', accessToken, {
    userId: currentUserId,
    id: curProduct.id,
  }).then((response) => {
    dispatch({
      type: GET_TESTS_SUCCEED,
      data: response.data,
    })
  })
}
