import React, { useState } from 'react'

import BulkResultContainer from '../BulkResultContainer'
import AdvancedNegativeKeywordTable from './AdvancedNegativeKeywordTable'
import AdvancedNegativeTargetTable from './AdvancedNegativeTargetTable'

const TAB_KEYWORD = 'keyword'
const TAB_TARGET = 'target'

const tabList = [
  {
    value: TAB_KEYWORD,
    label: 'Keywords',
  },
  {
    value: TAB_TARGET,
    label: 'Targets',
  },
]

const AdvancedNegativeResult = ({ campaignsById, adgroupNamesById }) => {
  const [currentTab, setCurrentTab] = useState(TAB_KEYWORD)

  return (
    <BulkResultContainer>
      <div className="advanced-negative-result-container">
        <div className="tab-list">
          {
            tabList.map((tab) => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? "tab selected" : "tab"}
                onClick={() => { setCurrentTab(tab.value)}}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        {
          currentTab === TAB_KEYWORD && (
            <AdvancedNegativeKeywordTable
              campaignsById={campaignsById}
              adgroupNamesById={adgroupNamesById}
            />
          )
        }
        {
          currentTab === TAB_TARGET && (
            <AdvancedNegativeTargetTable
              campaignsById={campaignsById}
              adgroupNamesById={adgroupNamesById}
            />
          )
        }
      </div>
    </BulkResultContainer>
  )
}

export default AdvancedNegativeResult
