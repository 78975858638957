/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from 'react'
import { DateRangePicker } from 'rsuite'
import { isAfter, isBefore } from 'date-fns'

import RuleEntry, { ruleTypeList } from './RuleEntry'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  RULE_TYPE_EXCLUDE_DATA,
} from '../../utils/defaultValues'
import { getISODate, parseDate } from '../../services/helper'

const RuleEvent = ({ slot, hasSpCampaign, fromTemplate, onChange, onRemove }) => {
  const [timeframe, setTimeframe] = useState([])
  const [rules, setRules] = useState([])
  const [error, setError] = useState('')

  const isReadOnly = useMemo(() => {
    return fromTemplate === true
      || !!(slot.campaign || (slot.template && slot.template.campaign))
  }, [fromTemplate, slot])

  const campaignInfo = useMemo(() => {
    if (slot.campaign) {
      return slot.campaign
    }
    if (slot.template && slot.template.campaign) {
      return slot.template.campaign
    }
    return null
  }, [slot])

  useEffect(() => {
    if (!slot.s || !slot.e) {
      return
    }

    setTimeframe([
      parseDate(slot.s),
      parseDate(slot.e),
    ])

    setRules(slot.r)
  }, [slot])

  useEffect(() => {
    if (!timeframe || !timeframe.length || !rules.length || isReadOnly) {
      return
    }

    const rulesByType = {}
    let saveError = ''
    rules.forEach((rule) => {
      if (!rule.t) {
        return
      }

      if (rulesByType[rule.t]) {
        const type = ruleTypeList.find(record => (
          record.value === rule.t
        ))
        saveError = `There are more than one rule for the ${type.label}.`
        return
      }

       if (rule.rampUp && !isBefore(parseDate(rule.rampUp), parseDate(timeframe[0]))) {
        saveError = 'Ramp up date must be earlier than the event start date.'
        return
      }

      if (rule.rampDown && !isAfter(parseDate(rule.rampDown), parseDate(timeframe[1]))) {
        saveError = 'Ramp down date must be later than the event end date.'
        return
      }

      rulesByType[rule.t] = rule
    })

    if (saveError) {
      setError(saveError)
      return
    }

    setError('')

    const values = {
      s: getISODate(timeframe[0]),
      e: getISODate(timeframe[1]),
      r: []
    }

    // The order is important here for comparison.
    if (rulesByType[RULE_TYPE_BID]) {
      values.r.push(rulesByType[RULE_TYPE_BID])
    }
    if (rulesByType[RULE_TYPE_CAMPAIGN]) {
      values.r.push(rulesByType[RULE_TYPE_CAMPAIGN])
    }
    if (rulesByType[RULE_TYPE_TOS_MODIFIER]) {
      values.r.push(rulesByType[RULE_TYPE_TOS_MODIFIER])
    }
    if (rulesByType[RULE_TYPE_PRODUCT_MODIFIER]) {
      values.r.push(rulesByType[RULE_TYPE_PRODUCT_MODIFIER])
    }
    if (rulesByType[RULE_TYPE_REST_MODIFIER]) {
      values.r.push(rulesByType[RULE_TYPE_REST_MODIFIER])
    }
    if (rulesByType[RULE_TYPE_EXCLUDE_DATA]) {
      values.r.push(rulesByType[RULE_TYPE_EXCLUDE_DATA])
    }

    if (!values.r.length) {
      return
    }

    if (slot.s) {
      const slotWithoutId = Object.assign({}, slot)
      delete slotWithoutId.id
      if (JSON.stringify(values) === JSON.stringify(slotWithoutId)) {
        return
      }
    }

    onChange(values)
  }, [timeframe, rules]) // eslint-disable-line

  const handleRuleAdd = (event) => {
    event.preventDefault()

    setRules(prev => ([
      ...prev,
      {
        id: `${Math.floor(Math.random() * 1000).toString()}-${rules.length}`,
      },
    ]))
  }

  const handleRuleChange = ruleId => (values) => {
    setRules(rules.map((rule) => {
      if (rule.id !== ruleId) {
        return rule
      }
      return {
        id: rule.id,
        ...values,
      }
    }))
  }

  const handleRuleRemove = ruleId => () => {
    setRules(rules.filter(rule => (
      rule.id !== ruleId
    )))
  }

  return (
    <div className="rule-event-container">
      <div className="timeframe-row">
        <div className="timeframe-wrapper">
          {
            !isReadOnly && (
              <div className="timeframe-note">
                Set time frame and rules for it
              </div>
            )
          }
          {
            campaignInfo !== null && (
              <div className="template-note">
                Campaign: <strong>{ campaignInfo.name }</strong>
              </div>
            )
          }
          {
            fromTemplate && (
              <div className="template-note">
                These rules are from the template: <strong>{ slot.template.name }</strong>.
              </div>
            )
          }
          <DateRangePicker
            ranges={[]}
            format="MMM D, YYYY"
            placement="right"
            disabled={isReadOnly}
            value={timeframe}
            onChange={setTimeframe}
          />
        </div>
        {
          !isReadOnly && (
            <button
            type="button"
            className="btn btn-red"
            onClick={onRemove}
          >
            Remove
          </button>
          )
        }
      </div>
      {
        !isReadOnly && (
          <a
            href="#"
            onClick={handleRuleAdd}
          >
            + Add rule
          </a>
        )
      }
      {
        rules.map(rule => (
          <RuleEntry
            key={rule.id}
            rule={rule}
            hasSpCampaign={hasSpCampaign}
            isReadOnly={isReadOnly}
            onChange={handleRuleChange(rule.id)}
            onRemove={handleRuleRemove(rule.id)}
          />
        ))
      }
      {
        error !== '' && (
          <div className="error-message">
            { error }
          </div>
        )
      }
    </div>
  )
}

export default RuleEvent
