import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Icon,
  IconButton,
  Input
} from 'rsuite'

import { changeViewNoteState } from '../../redux/actions/pageGlobal'
import { MAX_REPORT_NOTE_CHARACTER_LENGTH } from '../../utils/defaultValues'

function calcHeight(value) {
  const numberOfLineBreaks = (value.match(/\n/g) || []).length;
  const newHeight = (numberOfLineBreaks + 1) * 20 + 16;
  return newHeight;
}

const ReportNoteWidget = ({
  noteWidgetView = true,
  customizing = false,
  note,
  section,
  onChange,
  onRemove,
}) => {
  const dispatch = useDispatch()
  const [leftCharacters, setLeftCharacters] = useState(MAX_REPORT_NOTE_CHARACTER_LENGTH)

  useEffect(() => {
    const textarea = document.querySelector('.report-note-textarea');
    const calculatedHight = calcHeight(textarea.value)
    textarea.style.height = (calculatedHight > 100 ? calculatedHight : 100) + 'px';
  }, [note])

  const handleClickHideWidget = () => {
    dispatch(changeViewNoteState(section))
    onRemove(!noteWidgetView)
  }

  const handleChangeNote = (v) => {
    if (v.length > MAX_REPORT_NOTE_CHARACTER_LENGTH) {
      onChange(v.slice(0, MAX_REPORT_NOTE_CHARACTER_LENGTH))
      setLeftCharacters(0)
    } else {
      onChange(v)
      setLeftCharacters(MAX_REPORT_NOTE_CHARACTER_LENGTH - v.length)
    }
  }

  const helperText = leftCharacters > 1 ? 'Characters' : 'Character'

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          Note Included
        </div>
        <div className="widget-action">
          {customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={noteWidgetView ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )}
        </div>
      </div>
      <Input
        className="report-note-textarea"
        componentClass="textarea"
        rows={4}
        placeholder={`Maximum ${MAX_REPORT_NOTE_CHARACTER_LENGTH} Characters`}
        value={note}
        onChange={(v) => handleChangeNote(v)}
      />
      <div className="report-note-helper-text">
        { (leftCharacters < MAX_REPORT_NOTE_CHARACTER_LENGTH && leftCharacters > 0) ? `${leftCharacters} ${helperText} Left` : '' }
      </div>
    </div>
  )
}

export default ReportNoteWidget
