import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../../CommonComponents/CustomTooltip'

import CreativeLogo from './CreativeLogo'
import CreativeHeadline from './CreativeHeadline'
import CreativeCustomImage from './CreativeCustomImage'
import CropImageModal from './CropImageModal'
import BrandSelector from './BrandSelector'
import SDAdPreview from '../SDAdPreview'
import SDAdVideoPreview from '../SDAdVideoPreview'

import 'react-image-crop/dist/ReactCrop.css'

import { createAsset } from '../../../redux/actions/campaignCreator'
import { selectCurrentAccount } from '../../../redux/reducers/header'
import { getBase64 } from '../../../services/helper'

const SDCreativeSection = ({ adFormat, products, basicInfo, onCrop, onChange }) => {
  const inputLogoRef = useRef()
  const inputCustomRef = useRef()
  const inputVideoRef = useRef()

  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)

  const [openCropModal, setOpenCropModal] = useState(false)

  const [brandEntityId, setBrandEntityId] = useState('')

  const [isSaving, setIsSaving] = useState(false)

  const [logoSrc, setLogoSrc] = useState(null)
  const [croppedLogo, setCroppedLogo] = useState(null)
  const [logoImage, setLogoImage] = useState(null)

  const [customSrc, setCustomSrc] = useState(null)
  const [croppedCustom, setCroppedCustom] = useState(null)
  const [croppedCustomSquare, setCroppedCustomSquare] = useState(null)
  const [customImage, setCustomImage] = useState(null)
  const [customCrop, setCustomCrop] = useState(null)
  const [customSquareCrop, setCustomSquareCrop] = useState(null)

  const [cropInfo, setCropInfo] = useState({
    src: null,
    type: 'logo',
    cropImage: null,
  })

  const [videoBlob, setVideoBlob] = useState(null)

  const handleBrandSelect = (brandData) => {
    setBrandEntityId(brandData.brandEntityId)
  }

  const handleImageCrop = (croppedImage, croppingCoordinates, cropType, image) => {
    if (cropType === 'logo') {
      setCroppedLogo(croppedImage)
      setLogoImage(image)
      onCrop(cropType, croppingCoordinates)
    } else if (cropType === 'custom') {
      setCroppedCustom(croppedImage)
      setCustomImage(image)
      setCustomCrop(croppingCoordinates)
      onCrop(cropType, croppingCoordinates, customSquareCrop)
    } else if (cropType === 'customSquare') {
      setCroppedCustomSquare(croppedImage)
      setCustomSquareCrop(croppingCoordinates)
      onCrop(cropType, customCrop, croppingCoordinates)
    }
    setOpenCropModal(false)
  }

  const handleImageUpload = forLogo => (e) => {
    if (!e.target.files || !e.target.files.length) {
      return
    }

    if (e.target.files[0].size > 1048576 && forLogo) {
      toast.show({
        title: 'Warning',
        description: 'File size must be smaller than 1MB.',
      })
      return
    }

    if (e.target.files[0].size > 5242880 && !forLogo) {
      toast.show({
        title: 'Warning',
        description: 'File size must be smaller than 5MB.',
      })
      return
    }

    if (e.target.files[0].type !== 'image/jpeg'
      && e.target.files[0].type !== 'image/png') {
      toast.show({
        title: 'Warning',
        description: 'File format must be PNG or JPEG',
      })
      return
    }

    // This synthetic event is reused for performance reasons,
    // so we need to keep these values in variables.
    const rawFile = e.target.files[0]
    const fileName = rawFile.name
    const contentType = rawFile.type

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const img = document.createElement('img')
      img.addEventListener('load', async () => {
        if ((img.width < 600 || img.height < 100) && forLogo) {
          toast.show({
            title: 'Warning',
            description: 'Dimensions must be at least 600 x 100 px',
          })
          return
        }

        if ((img.width < 1200 || img.height < 628) && !forLogo) {
          toast.show({
            title: 'Warning',
            description: 'Dimensions must be at least 1200 x 628 px',
          })
          return
        }

        const objectUrl = URL.createObjectURL(rawFile)
        if (forLogo) {
          setLogoSrc(objectUrl)
          setCroppedLogo(objectUrl)
          onCrop('logo')
        } else {
          setCustomSrc(objectUrl)
          setCroppedCustom(objectUrl)
          setCroppedCustomSquare(objectUrl)
          setCustomCrop(null)
          setCustomSquareCrop(null)
          // Set the default square crop ordinates,
          // because it's required by Amazon.
          onCrop('custom', null, {
            top: 0,
            left: 0,
            width: Math.min(img.width, img.height),
            height: Math.min(img.width, img.height),
          })
        }

        setIsSaving(true)
        const accessToken = await getAccessTokenSilently()
        dispatch(createAsset(accessToken, {
          assetType: 'IMAGE',
          assetSubType: forLogo ? 'LOGO' : 'OTHER_IMAGE',
          fileName,
          contentType,
          base64: reader.result,
          brandEntityId,
          tags: ['SD'],
        })).then((response) => {
          if (forLogo) {
            onChange('logoAsset', response)
          } else {
            onChange('customImageAsset', response)
          }
        }).catch((description) => {
          toast.show({
            title: 'Danger',
            description,
          })
        }).finally(() => {
          setIsSaving(false)
        })
      })
      img.src = reader.result
    })
    reader.readAsDataURL(e.target.files[0])
  }

  const handleVideoUpload = (e) => {
    if (!e.target.files || !e.target.files.length) {
      return
    }

    if (e.target.files[0].size > 500 * 1024 * 1024) {
      toast.show({
        title: 'Warning',
        description: 'Only less than 500 MB video file is supported.',
      })
      return
    }

    const file = e.target.files[0]
    const fileName = file.name
    const contentType = file.type

    setVideoBlob(URL.createObjectURL(file))

    setIsSaving(true)
    getBase64(file, async (result) => {
      const accessToken = await getAccessTokenSilently()
      dispatch(createAsset(accessToken, {
        assetType: 'VIDEO',
        assetSubType: 'BACKGROUND_VIDEO',
        fileName,
        contentType,
        base64: result,
        brandEntityId,
        tags: ['SD'],
      })).then((response) => {
        onChange('videoAsset', response)
      }).catch((description) => {
        toast.show({
          title: 'Danger',
          description,
        })
      }).finally(() => {
        setIsSaving(false)
      })
    })
  }

  const handleCropModalOpen = (type) => {
    let cropSrc
    let cropImage
    if (type === 'logo') {
      cropSrc = logoSrc
      cropImage = logoImage
    } else {
      cropSrc = customSrc
      cropImage = customImage
    }
    setCropInfo({
      src: cropSrc,
      type,
      cropImage,
    })
    setOpenCropModal(true)
  }

  const renderBrandSection = () => {
    if (currentAccount?.seller_type !== 'seller'
      || (!basicInfo.hasLogo && !basicInfo.hasCustomImage)) {
      return null
    }

    return (
      <div className="customizer-section">
        <div className="field-section-name">
          Brand
          <CustomTooltip placement="right">
            <p>Brand needs to be selected for sellers.</p>
          </CustomTooltip>
        </div>
        <div className="field-section-contents">
          <BrandSelector onChange={handleBrandSelect} />
        </div>
      </div>
    )
  }

  const renderLogoSection = () => {
    return (
      <div className="customizer-section">
        <CreativeLogo
          basicInfo={basicInfo}
          adType="sd"
          inputRef={inputLogoRef}
          logoSrc={logoSrc}
          onChange={onChange}
          onUpload={handleImageUpload(true)}
          onCrop={() => { handleCropModalOpen('logo') }}
        />
      </div>
    )
  }

  const renderHeadlineSection = () => {
    return (
      <CreativeHeadline
        basicInfo={basicInfo}
        onChange={onChange}
      />
    )
  }

  const renderCustomImageSection = () => {
    return (
      <CreativeCustomImage
        basicInfo={basicInfo}
        inputRef={inputCustomRef}
        customSrc={customSrc}
        croppedCustomSquare={croppedCustomSquare}
        onChange={onChange}
        onUpload={handleImageUpload(false)}
        onCrop={handleCropModalOpen}
      />
    )
  }

  const renderVideoSection = () => {
    return (
      <div className="customizer-section">
        <div className="field-section-name">
          Video
          <CustomTooltip placement="right">
            <p><strong>Video specs</strong></p>
            <ul>
              <li><strong>Aspect ratio:</strong> 16:9</li>
              <li><strong>Dimensions:</strong> 1920x1080 (min)</li>
              <li><strong>Max file size:</strong> 500MB</li>
              <li><strong>File format:</strong> H.264, MPEG-2, MPEG-4</li>
              <li><strong>Length:</strong> 6-45 sec</li>
              <li><strong>Frame rate:</strong> 23.976, 24, 25, 29.97, 29.98, or 30 fps</li>
              <li><strong>Bitrate:</strong> 1 Mbps (min)</li>
              <li><strong>Video stream:</strong> 1 only</li>
            </ul>
            <p><strong>Audio specs</strong></p>
            <ul>
              <li><strong>Language:</strong> Must match ad locale</li>
              <li><strong>Sample rate:</strong> 44.1kHz or 48kHz</li>
              <li><strong>Codec:</strong> PCM or AAC</li>
              <li><strong>Bit rate:</strong> 96 kbps (min)</li>
              <li><strong>Format:</strong> Stereo or mono</li>
              <li><strong>Audio stream:</strong> 1 only</li>
            </ul>
          </CustomTooltip>
        </div>
        <div className="field-section-contents creative-video-container">
          <input
            ref={inputVideoRef}
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
          />
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { inputVideoRef.current.value = null; inputVideoRef.current.click() }}
          >
            Upload video
          </button>
          {
            videoBlob !== null && (
              <video
                src={videoBlob}
                width="240"
                height="135"
                controls
              />
            )
          }
        </div>
      </div>
    )
  }

  const renderCustomizer = () => {
    if (adFormat === 'image') {
      return (
        <div className="customizer-container">
          { renderLogoSection() }
          { renderHeadlineSection() }
          { renderCustomImageSection() }
          { renderBrandSection() }
        </div>
      )
    }
    return (
      <div className="customizer-container">
        { renderVideoSection() }
        { renderLogoSection() }
        { renderHeadlineSection() }
      </div>
    )
  }

  return (
    <div className={`section-container creative-section${isSaving ? ' loading' : ''}`}>
      { isSaving && <LoaderComponent /> }
      <div className="section-title">
        Creative
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <div className="field-name">
            Customize your creative
            <CustomTooltip placement="right">
              <p>Creative can be customised if your campaign advertises 100 or fewer products.</p>
            </CustomTooltip>
          </div>
          { renderCustomizer() }
        </div>
        <div className="field-wrapper">
          <div className="field-name">
            Ad preview
            <CustomTooltip placement="right">
              <p>Your ad may look slightly different than what you see
                in this preview as we continually test both new
                and existing features to determine which characteristics drive ad performance.</p>
            </CustomTooltip>
          </div>
          {
            adFormat === 'image' ? (
              <SDAdPreview
                products={products}
                hasLogo={basicInfo.hasLogo}
                hasHeadline={basicInfo.hasHeadline}
                hasCustomImage={basicInfo.hasCustomImage}
                croppedLogo={croppedLogo}
                headline={basicInfo.headline}
                croppedCustom={croppedCustom}
              />
            ) : (
              <SDAdVideoPreview
                products={products}
                hasLogo={basicInfo.hasLogo}
                hasHeadline={basicInfo.hasHeadline}
                videoBlob={videoBlob}
                croppedLogo={croppedLogo}
                headline={basicInfo.headline}
              />
            )
          }
        </div>
      </div>
      <CropImageModal
        show={openCropModal}
        cropInfo={cropInfo}
        onSelect={handleImageCrop}
        onClose={() => { setOpenCropModal(false) }}
      />
    </div>
  )
}

export default SDCreativeSection
