import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Modal, DatePicker } from 'rsuite'
import Select from 'react-select'
import { format, isBefore, startOfDay } from 'date-fns'

import { toast } from '../CommonComponents/ToastComponent/toast'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import TimezoneSelector from '../RuleManagerComponents/TimezoneSelector'
import { selectCurrentAccount } from '../../redux/reducers/header'
import { updateBudgets } from '../../redux/actions/portfolio'
import { CURRENCY_LIST, parseDate } from '../../services/helper'

const POLICY_DATE_RANGE = 'dateRange'
const POLICY_MONTHLY = 'monthlyRecurring'
const POLICY_DAILY = 'daily'

const policyOptions = [
  { value: POLICY_DATE_RANGE, label: 'Date Range' },
  { value: POLICY_MONTHLY, label: 'Monthly Recurring' },
  { value: POLICY_DAILY, label: 'Daily Recurring' },
]

const PortfolioBudgetModal = ({ portfolios, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)

  const [policy, setPolicy] = useState(null)
  const [amount, setAmount] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [timezone, setTimezone] = useState(
    currentAccount?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [isUpdating, setIsUpdating] = useState(false)

  const handleAmountChange = (event) => {
    setAmount(event.target.value)
  }

  const handleUpdate = async () => {
    if (isNaN(amount) || parseFloat(amount) <= 0) {
      toast.show({
        title: 'Warning',
        description: 'The budget amount must be greater than 0.',
      })
      return
    }

    if (policy.value !== POLICY_MONTHLY && !startDate) {
      toast.show({
        title: 'Warning',
        description: 'Please specify the start date.',
      })
      return
    }

    const payload = {
      policy: policy.value,
      amount: parseFloat(amount),
    }

    if (policy.value !== POLICY_MONTHLY) {
      payload.startDate = format(parseDate(startDate), 'yyyyMMdd')
    }

    if (endDate) {
      payload.endDate = format(parseDate(endDate), 'yyyyMMdd')
    }

    if (policy.value === POLICY_DAILY) {
      payload.timezone = timezone
    }

    setIsUpdating(true)
    const accessToken = await getAccessTokenSilently()
    const successIds = await dispatch(updateBudgets(
      accessToken,
      portfolios.map(portfolio => parseInt(portfolio.portfolio_id, 10)),
      payload,
    ))
    setIsUpdating(false)

    if (successIds.length) {
      toast.show({
        title: 'Success',
        description: 'Successfully updated budgets.',
      })

      onClose(true)
    } else {
      toast.show({
        title: 'Danger',
        description: 'Failed to update budgets.',
      })
    }
  }

  const renderPortfolios = () => {
    return (
      <div className="portfolio-list">
        {
          portfolios.map(portfolio => (
            <span
              key={portfolio.portfolio_id}
              className="portfolio-wrapper"
            >
              { portfolio.name }
            </span>
          ))
        }
      </div>
    )
  }

  const renderPolicies = () => {
    return (
      <div className="policy-wrapper">
        <label>
          Policy:
        </label>
        <Select
          className="policy-selector"
          classNamePrefix="policy-selector"
          options={policyOptions}
          placeholder="Choose policy"
          value={policy}
          onChange={setPolicy}
        />
      </div>
    )
  }

  const renderAmount = () => {
    if (!policy) {
      return null
    }

    return (
      <div className="amount-wrapper">
        <label>
          Amount:
        </label>
        <input
          type="number"
          min="0"
          value={amount}
          onChange={handleAmountChange}
        />
        <span>
          { CURRENCY_LIST[currentAccount?.country_id || 'us'] || 'USD' }
        </span>
      </div>
    )
  }

  const renderStartDate = () => {
    if (!policy || policy.value === POLICY_MONTHLY) {
      return null
    }

    return (
      <div className="date-wrapper">
        <label>
          Start Date:
        </label>
        <DatePicker
          value={startDate}
          format="MMM D, YYYY"
          oneTap
          disabledDate={date => isBefore(date, startOfDay(new Date())) }
          onChange={(date) => { setStartDate(date) }}
        />
      </div>
    )
  }

  const renderEndDate = () => {
    if (!policy) {
      return null
    }

    return (
      <div className="date-wrapper">
        <label>
          End Date:
        </label>
        <DatePicker
          value={endDate}
          format="MMM D, YYYY"
          placeholder="No end date"
          oneTap
          disabledDate={date => isBefore(date, parseDate(startDate)) }
          onChange={(date) => { setEndDate(date) }}
        />
      </div>
    )
  }

  const renderTimezone = () => {
    if (!policy || policy.value !== POLICY_DAILY) {
      return null
    }

    return (
      <TimezoneSelector
        timezone={timezone}
        onChange={setTimezone}
      />
    )
  }

  return (
    <Modal className="portfolio-budget-modal" backdrop="static" size="sm" show>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Update Budgets
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isUpdating && <LoaderComponent /> }
        <div className="contents-area">
          { renderPortfolios() }
          { renderPolicies() }
          { renderAmount() }
          { renderStartDate() }
          { renderEndDate() }
          { renderTimezone() }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={policy === null || isUpdating}
          onClick={handleUpdate}
        >
          Update
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PortfolioBudgetModal
