import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import ProductTableComponent from '../../components/ProductTableComponent'
import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportTableWidget from './ReportTableWidget'
import ReportNoteWidget from './ReportNoteWidget'

import { getProducts } from '../../redux/actions/product'
import { changeViewReportTableTableState } from '../../redux/actions/pageGlobal'
import {
  formatCurrency,
  formatValue,
  getISODate,
} from '../../services/helper'

const columns = [
  { key: 'product', name: 'Product'},
  { key: 'cost', name: 'Spend' },
  { key: 'sales', name: 'AD Sales' },
  { key: 'acos', name: 'ACoS'},
  { key: 'cpc', name: 'Max CPC'},
  { key: 'ctr', name: 'CTR'},
  { key: 'impressions', name: 'Impressions'},
  { key: 'conversionRate', name: 'Conversion %'},
]

const ProductSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()

  const { getAccessTokenSilently } = useAuth0()

  const productList = useSelector(state => state.product.productList)

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentUserId = useSelector(state => state.header.currentUserId)

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const showReportProductTable = useSelector(state => state.pageGlobal.showReportProductTable)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')
  const [highestRevenueList, setHighestRevenueList] = useState([])
  const [expensiveList, setExpensiveList] = useState([])
  const [tableView, setTableView] = useState(showReportProductTable)

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      if (showSection && !isLoaded) {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          await dispatch(getProducts(
            accessToken,
            getISODate(startDate),
            getISODate(endDate),
            abortCtrl.signal,
          ))
          setIsLoading(false)
          setIsLoaded(true)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, showSection, startDate, endDate, currentUserId]) // eslint-disable-line

  useEffect(() => {
    if (!isLoading && productList.length > 0) {
      setHighestRevenueList(productList.sort((a, b) => b.revenue - a.revenue).map(c => ({
        asin: c.asin,
        sku: c.sku,
        thumbnail: c.image || '',
        cost: c.cost,
        sales: c.revenue,
        acos: c.revenue > 0 ? c.cost / c.revenue * 100 : 0.00,
        cpc: c.max_cpc > 0 ? c.max_cpc : 0.00,
        ctr: c.impressions > 0 ? c.clicks / c.impressions * 100 : 0.00,
        impressions: c.impressions,
        conversionRate: c.clicks > 0 ? c.orders / c.clicks * 100 : 0.00,
        productId: c.id
      })))
      setExpensiveList(productList.sort((a, b) => b.cost - a.cost).map(c => ({
        asin: c.asin,
        sku: c.sku,
        thumbnail: c.image || '',
        cost: c.cost,
        sales: c.revenue,
        acos: c.revenue > 0 ? c.cost / c.revenue * 100 : 0.00,
        cpc: c.max_cpc > 0 ? c.max_cpc : 0.00,
        ctr: c.impressions > 0 ? c.clicks / c.impressions * 100 : 0.00,
        impressions: c.impressions,
        conversionRate: c.clicks > 0 ? c.orders / c.clicks * 100 : 0.00,
        productId: c.id
      })))
    }
  }, [isLoading, productList])

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_products || '')
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const products = reportWidgetSetting ? reportWidgetSetting.products : []
    setViewWidgets(products.map(w => ({
      ...w,
      view: !!w.default,
    })))
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_product',
      sectionValue: note,
    })
  }

  const handleHideTable = () => {
    dispatch(changeViewReportTableTableState())
    setTableView(p => !p)
  }

  const renderRecord = (record) => (
    <>
      <div className="table-col">
        <div className="product-col">
          <div className="product-thumbnail">
            {record.thumbnail !== '' && (
              <img src={record.thumbnail} alt={record.sku}/>
            ) }
          </div>
          <div className="product-asin">
            { record.asin }
          </div>
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.sales, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { record.acos !== 0 ? formatValue(record.acos, 'percent', 2) : '-' }
      </div>
      <div className="table-col">
        { record.cpc !== 0 ? formatCurrency(record.cpc, currencySign, currencyRate) : '-' }
      </div>
      <div className="table-col">
        { record.ctr !== 0 ? formatValue(record.ctr, 'percent', 2) : '-' }
      </div>
      <div className="table-col">
        { record.impressions !== 0 ? formatValue(record.impressions, 'number', 0) : '-' }
      </div>
      <div className="table-col">
        { record.conversionRate !== 0 ? formatValue(record.conversionRate, 'percent', 2) : '-' }
      </div>
    </>
  )

  return (
    <>
      <SectionHeader
        title="Your Products"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            {
              (customizingSection || noteWidgetView) && (
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section="products"
                />
              )
            }
            {
              viewWidgets.length > 0 && (viewWidgets[0].view || customizingSection) && (
                <ReportTableWidget
                  widget={viewWidgets[0]}
                  columns={columns}
                  records={highestRevenueList}
                  onRemove={handleOnRemoveWidget}
                  customizing={customizingSection}
                  idField="productId"
                  renderRecord={renderRecord}
                  sortBy="sales"
                  sortOrder="desc"
                  isLoading={isLoading}
                  section="products"
                  noPagination={false}
                />
              )
            }
            {
              viewWidgets.length > 0 && (viewWidgets[1].view || customizingSection) && (
                <ReportTableWidget
                  widget={viewWidgets[1]}
                  columns={columns}
                  records={expensiveList}
                  onRemove={handleOnRemoveWidget}
                  customizing={customizingSection}
                  idField="productId"
                  renderRecord={renderRecord}
                  sortBy="cost"
                  sortOrder="desc"
                  isLoading={isLoading}
                  section="products"
                  noPagination={false}
                />
              )
            }
            {
              (customizingSection || tableView) && (
                <div className="report-widget">
                  <div className="report-widget-header">
                    <div className="widget-title">
                      Products
                    </div>
                    <div className="widget-action">
                      {customizingSection && (
                        <IconButton
                          icon={<Icon icon="check" />}
                          color={tableView ? 'green' : ''}
                          circle
                          onClick={handleHideTable}
                        />
                      )}
                    </div>
                  </div>
                  <div className="report-widget-content">
                    <ProductTableComponent fromAccountReport />
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </>
  )
}

export default withReportSection(ProductSection, {
  sectionId: 'products',
  reportSectionKey: 'products',
})
