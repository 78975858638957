import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import DspTable from './DspTable'

import {
  getOrders,
} from '../../redux/actions/dsp'
import { calcDerivedMetrics } from '../../services/dspHelper'

const initialColumns = [
  { key: 'order', name: 'Order', width: 200, fixed: true, resizable: true}
]

const DspOrderTable = ({ selectedDspAccount, currentAdvertiser }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const campaignColumns = useSelector(state => state.dsp.campaignColumns)

  const [isLoading, setIsLoading] = useState(false)
  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    if (selectedDspAccount) {
      loadData()
    }
  }, [selectedDspAccount, campaignColumns, currentStartDate, currentEndDate]) // eslint-disable-line

  const loadData = async () => {
    const accessToken = await getAccessTokenSilently()
    setIsLoading(true)
    const list = await dispatch(getOrders(
      accessToken,
      selectedDspAccount,
      campaignColumns
    ))
    setOrderList(list.map(record => ({
      order: record.order_name,
      ...calcDerivedMetrics(record),
    })))
    setIsLoading(false)
  }

  const columns = useMemo(() => {
    return [
      ...initialColumns,
      ...campaignColumns,
    ]
  }, [campaignColumns])

  const filteredOrders = useMemo(() => (
    orderList.filter(o => (
      !(currentAdvertiser && o.advertiser_id !== currentAdvertiser.advertiserId)
    ))
  ), [orderList, currentAdvertiser])

  return (
    <div className="dsp-content">
      <DspTable
        columns={columns}
        data={filteredOrders}
        loading={isLoading}
        autoHeight
        sortColumn="impressions"
        sortType="desc"
        searchField="order"
      />
    </div>
  )
}

export default DspOrderTable
