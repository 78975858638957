/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import LogoPng from '../../assets/img/logo.png'

import AuthSideComponent from '../../components/AuthSideComponent'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  STORAGE_KEY_REGION,
  STORAGE_KEY_ACCOUNT_TYPE,
} from '../../utils/defaultValues'
import { AUDIT_ORCHESTRATOR_URL } from '../../config/api'

import {
  signupBasic,
  signupNameInput,
  checkAuditStatus,
} from '../../redux/actions/auth'

const ACCOUNT_TYPE_SELLER_VENDOR = 'seller_vendor'
const ACCOUNT_TYPE_AGENCY = 'agency'

const accountTypeList = [
  {
    type: ACCOUNT_TYPE_SELLER_VENDOR,
    name: 'Seller or Vendor',
    note: (
      <>
        Seller means you are selling your products directly to customers,
        through the Amazon marketplace.<br />
        Vendor means Amazon buys your products from you,
        then resells them to their customers.
      </>
    ),
  },
  {
    type: ACCOUNT_TYPE_AGENCY,
    name: 'Agency',
    note: 'Providing services to other businesses or individuals on behalf of a client.',
  },
]

const SignupPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const signupBasicInfo = useSelector(state => state.auth.signupBasicInfo)
  const isSigningBasic = useSelector(state => state.auth.isSigningBasic)

  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0()

  const [isAuditChecking, setIsAuditChecking] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [accountType, setAccountType] = useState(ACCOUNT_TYPE_SELLER_VENDOR)

  useEffect(() => {
    const qs = new URLSearchParams(location.search)
    const state = qs.get('state')
    if (state) {
      const savedQuery = window.sessionStorage.getItem(state)
      if (savedQuery) {
        history.push({
          pathname: location.pathname,
          search: savedQuery,
        })
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!user) {
      return
    }

    setIsAuditChecking(true)
    dispatch(checkAuditStatus(user.email)).then((response) => {
      const type = response.flow || 'NORMAL'
      if (type === 'SUCCESS') {
        window.location.href = `${AUDIT_ORCHESTRATOR_URL}/?tool-id=PPC_ENTOURAGE`
      } else if (type === 'AUDIT') {
        checkEmail(response.auth0Id, true)
      } else {
        checkEmail(user.sub)
      }
    })
  }, [dispatch, user]) // eslint-disable-line

  const checkEmail = (auth0Id, isAuditFlow = false) => {
    return dispatch(signupBasic(auth0Id, isAuditFlow)).then((response) => {
      if (!response.isValid) {
        if (!isAuditFlow) {
          history.push('/dashboard')
        } else {
          window.location.href = `${AUDIT_ORCHESTRATOR_URL}/?tool-id=PPC_ENTOURAGE&completed-audit=true`
        }
      } else {
        if (typeof window.biSignup !== 'undefined') {
          window.biSignup({
            name: `${response.firstName} ${response.lastName}`,
            email: response.email,
            tool_name: 'PPC Entourage'
          })
        }

        window.sessionStorage.removeItem(STORAGE_KEY_REGION)

        setIsAuditChecking(false)
      }
    }).catch((error) => {
      setIsAuditChecking(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const handleAccountTypeChange = type => () => {
    setAccountType(type)
    window.sessionStorage.setItem(STORAGE_KEY_ACCOUNT_TYPE, type)
  }

  const handleConnect = () => {
    if (!signupBasicInfo || !signupBasicInfo.firstName || !signupBasicInfo.lastName) {
      if (!firstName || !lastName) {
        toast.show({
          title: 'Danger',
          description: 'Please enter your first and last names.',
        })
        return
      }

      dispatch(signupNameInput(firstName, lastName))
    }

    history.push('/signup-complete')
  }

  const renderNameInput = () => {
    if (signupBasicInfo && signupBasicInfo.firstName && signupBasicInfo.lastName) {
      return null
    }

    return (
      <div className="name-input-container">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          required
          onChange={(event) => { setFirstName(event.target.value) }}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          required
          onChange={(event) => { setLastName(event.target.value) }}
        />
      </div>
    )
  }

  if (isLoading || isAuditChecking) {
    return <LoaderComponent />
  }

  if (!isAuthenticated) {
    let returnTo = '/signup'
    // Save query parameters.
    if (location.search) {
      const storageKey = `query-${Math.floor(Math.random() * 1000)}`
      window.sessionStorage.setItem(storageKey, location.search)
      returnTo = `${returnTo}?state=${storageKey}`
    }

    loginWithRedirect({
      appState: {
        returnTo,
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    })
    return
  }

  return (
    <div className="auth-layout">
      <div className={`auth-page${isSigningBasic ? ' loading' : ''}`}>
        { isSigningBasic && <LoaderComponent /> }
        <div className="auth-form">
          <div className="logo-header">
            <img src={LogoPng} alt="PPC Entourage" />
            PPC Entourage
          </div>
          <div className="form-title">
            Sign Up
          </div>
          <div className="form-sub-title">
            Sign up for PPC Entourage + Carbon6
          </div>
          { renderNameInput() }
          <div className="type-container">
            <div className="section-title">
              Choose your primary account
            </div>
            {
              accountTypeList.map(type => (
                <div
                  key={type.type}
                  className={`type-wrapper${accountType === type.type ? ' active' : ''}`}
                  onClick={handleAccountTypeChange(type.type)}
                >
                  <div className="radio-wrapper">
                    <input
                      type="radio"
                      name="account-type"
                      value={type.type}
                      checked={accountType === type.type}
                      onChange={handleAccountTypeChange(type.type)}
                    />
                    { type.name }
                  </div>
                  <div className="type-note">
                    { type.note }
                  </div>
                </div>
              ))
            }
          </div>
          <button
            type="button"
            className="btn btn-navy"
            onClick={handleConnect}
          >
            Connect Amazon Accounts
          </button>
        </div>
      </div>
      <AuthSideComponent>
        Sign Up for PPC Entourage + Carbon6 account to get started.
      </AuthSideComponent>
    </div>
  )
}

export default SignupPage
