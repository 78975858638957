import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Modal} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import NegativeTargetingSection from '../CampaignCreator/Shared/NegativeTargetingSection'
import SortableTable from '../CommonComponents/SortableTableComponent'
import CustomTable from '../CommonComponents/CustomTableComponent'
import VideoLink from '../CommonComponents/VideoLink'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import { toast } from '../CommonComponents/ToastComponent/toast'
import StepBreadcrumb from '../CommonComponents/StepBreadcrumb'
import TargetingSelector from '../CommonComponents/TargetingSelector'

import { getAssociativeCampaigns, tableSorter } from '../../services/helper'

import {
  getAdgroupsToAddTargets,
  createNegatives,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import {
  JOB_TYPE_BULK_CREATE_NEGATIVES,
  negativeMatchTypeOptions,
} from '../../utils/defaultValues'

const STEP_TARGET = 1
const STEP_ADGROUP = 2

const stepList = [
  { value: STEP_TARGET, name: 'Add negatives' },
  { value: STEP_ADGROUP, name: 'Choose ad groups' },
]

const adgroupColumns = [
  { key: 'campaign', name: 'Campaign' },
  { key: 'name', name: 'Ad Group' },
  { key: 'count', name: 'Number of Keywords/Targets', sortable: false },
]

const videoList = [
  { title: 'Add Negatives', url: 'https://www.loom.com/embed/804c3911da16445ab53d927c36c80990' },
]

const AddNegativeModal = ({ show, campaigns, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [manualTarget, setManualTarget] = useState('keyword')
  const [step, setStep] = useState(STEP_TARGET)
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [negativeTargetings, setNegativeTargetings] = useState([])
  const [targetLevel, setTargetLevel] = useState('adgroup')
  const [matchType, setMatchType] = useState(null)
  const [adgroupList, setAdgroupList] = useState([])
  const [selectedAdgroups, setSelectedAdgroups] = useState([])
  const [newNegativeKeywordList, setNewNegativeKeywordList] = useState('')
  const [isGettingAdgroups, setIsGettingAdgroups] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (show) {
      setStep(STEP_TARGET)
      setSelectedAdgroups([])
      setTargetLevel('adgroup')
    }
  }, [show, dispatch])

  useEffect(() => {
    const campaignsById = getAssociativeCampaigns(campaigns)

    let adgroups = (adgroupsToAddTargetsData || []).map((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}

      return {
        ...record,
        campaign: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targeting_type: campaignDetail.targetingType || '',
      }
    })

    setAdgroupList(adgroups)
  }, [adgroupsToAddTargetsData]) // eslint-disable-line

  const handleConfirm = async () => {
    if (step !== STEP_ADGROUP) {
      if (!negativeKeywords.length && !negativeTargetings.length) {
        toast.show({
          title: 'Warning',
          description: 'Please enter negatives.',
        })
        return
      }

      (async () => {
        const payload = {}
        campaigns.forEach((campaign) => {
          if (!payload[campaign.campaignType]) {
            payload[campaign.campaignType] = []
          }
          payload[campaign.campaignType].push(campaign.campaign_id.toString())
        })

        setIsGettingAdgroups(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getAdgroupsToAddTargets(
          accessToken,
          payload,
          false // This needs to be cast to boolean explicitly.
        ))
        setIsGettingAdgroups(false)
      })()

      setStep(step + 1)
      return
    }

    if (!selectedAdgroups.length) {
      toast.show({
        title: 'Warning',
        description: 'Please select at least 1 adgroup.',
      })
      return
    }

    const nkList = []
    const negativeTargetingList = []
    if (manualTarget === 'keyword') {
      adgroupList.forEach((adgroup) => {
        if (!selectedAdgroups.includes(adgroup.adgroup_id)) {
          return
        }

        negativeKeywords.forEach((keyword) => {
          nkList.push({
            campaignId: adgroup.campaign_id,
            adgroupId: adgroup.adgroup_id,
            campaignType: adgroup.campaignType,
            search: keyword.keywordText,
            matchType: keyword.matchType,
            // Below information are used for logging in backend.
            adgroupName: adgroup.name,
          })
        })
      })
    } else if (manualTarget === 'product') {
      adgroupList.forEach((adgroup) => {
        if (!selectedAdgroups.includes(adgroup.adgroup_id)) {
          return
        }
        negativeTargetings.forEach((target) => {
          negativeTargetingList.push({
            campaignId: adgroup.campaign_id,
            adgroupId: adgroup.adgroup_id,
            campaignType: adgroup.campaignType,
            type: target.type === 'brand' ? 'asinBrandSameAs' : 'asinSameAs',
            search: target.type === 'brand' ? target.id : target.asin,
            // Below information are used for logging in backend.
            adgroupName: adgroup.name,
          })
        })
      })
    }

    if (!nkList.length && !negativeTargetingList.length) {
      toast.show({
        title: 'Warning',
        description: 'Nothing to add.',
      })
      return
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(createNegatives(
      accessToken,
      nkList,
      negativeTargetingList,
      targetLevel,
      false,
    ))
    setIsAdding(false)
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_CREATE_NEGATIVES,
      ))
      onClose()
    }
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleNegativesAdd = () => {
    if (newNegativeKeywordList === '') {
      toast.show({
        title: 'Warning',
        description: 'Please enter the negative keyword text.',
      })
      return
    }
    if (matchType === null) {
      toast.show({
        title: 'Warning',
        description: 'Please select the match type.',
      })
      return
    }
    const newKeywords = [...negativeKeywords]
    const enteredNegativeKeywords = newNegativeKeywordList.split('\n').map(keyword => keyword.trim().toLowerCase())
    let hasInvalid = false
    enteredNegativeKeywords.forEach((keywordText) => {
      if (/^\s*$/.test(keywordText)) {
        return ''
      }

      const duplicate = newKeywords.find(kw => (
        keywordText === kw.keywordText
        && matchType.value === kw.matchType
      ))

      if (!duplicate) {
        newKeywords.push({
          id: `${keywordText}-${matchType.value}`,
          matchType: matchType.value,
          keywordText,
        })
      } else {
        toast.show({
          title: 'Warning',
          description: `This negative keyword ("${keywordText}") is already added.`,
        })
        hasInvalid = true
      }
    })
    setNegativeKeywords(newKeywords)
    if (!hasInvalid) {
      setMatchType(null)
      setNewNegativeKeywordList('')
    }
  }

  const handleMatchTypeChange = (match, keyword) => {
    const duplicate = negativeKeywords.find(kw => (
      keyword.keywordText === kw.keywordText
        && match.value === kw.matchType
    ))

    if (duplicate) {
      toast.show({
        title: 'Warning',
        description: 'This negative keyword is already added.',
      })
      return
    }

    setNegativeKeywords(negativeKeywords.map((kw) => {
      if (kw.id.toString() === keyword.id.toString()) {
        return { ...kw, matchType: match.value }
      }
      return kw
    }))
  }

  const handleRemove = (record) => {
    setNegativeKeywords(negativeKeywords.filter(
      kw => kw.id.toString() !== record.id.toString()
    ))
  }

  const renderSteps = () => {
    return (
      <StepBreadcrumb
        stepList={stepList}
        currentStep={step}
      />
    )
  }

  const renderNk = (record) => {
    const matchType = negativeMatchTypeOptions.find(option =>
      option.value === record.matchType
    )
    return (
      <>
        <div className="table-col">{ record.keywordText }</div>
        <div className="table-col">
          <Select
            options={negativeMatchTypeOptions}
            value={matchType}
            onChange={val => handleMatchTypeChange(val, record)}
          />
        </div>
        <div className="table-col col-action">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => { handleRemove(record) }}
          >
            Remove
          </button>
        </div>
      </>
    )
  }

  const renderNks = () => (
    <>
      <div className="nk-input-section">
        <div className="input-field-wrapper">
          <label>Match Type</label>
          <Select
            options={negativeMatchTypeOptions}
            value={matchType}
            onChange={setMatchType}
          />
        </div>
        <textarea
          placeholder="Enter negative keywords separated by a new line."
          rows={5}
          value={newNegativeKeywordList}
          onChange={(event) => { setNewNegativeKeywordList(event.target.value) }}
        />
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => handleNegativesAdd()}
        >
          Add Negative Keyword
        </button>
      </div>
      <div className="section-container">
        <div className="section-title">
          Negative Keywords
        </div>
        <div className="section-note">
          Negative keywords prevent your ads from displaying
          when a shopper's search terms match your negative keywords.
          You can exclude irrelevant searches, reducing your advertising cost.
        </div>
        <CustomTable
          className="table-nks"
          isLoading={isGettingAdgroups || isAdding}
          records={negativeKeywords}
          idField="id"
          noCheckBox
          searchFields={['keywordText']}
          noRecordText="No negative keywords added."
          renderRecord={renderNk}
        >
          <div className="table-col">Keyword</div>
          <div className="table-col">Match Type</div>
          <div className="table-col"></div>
        </CustomTable>
      </div>
    </>
  )

  const renderAdgroup = (adgroup) => (
    <>
      <TableCampaignCell
        record={adgroup}
        noLink
      />
      <div className="table-col col-adgroup">
        { adgroup.name }
      </div>
      <div className="table-col">
        { parseInt(adgroup.keywords, 10) + parseInt(adgroup.targets, 10) }
      </div>
    </>
  )

  const renderContents = () => {
    if (step === STEP_TARGET) {
      return (
        <>
          <TargetingSelector
            manualTarget={manualTarget}
            noAudience
            onChange={setManualTarget}
          />
          {
            manualTarget === 'keyword' && renderNks()
          }
          {
            manualTarget === 'product' && (
              <NegativeTargetingSection
                negativeTargetings={negativeTargetings}
                forNegatives
                onChange={setNegativeTargetings}
              />
            )
          }
        </>
      )
    }

    return (
      <SortableTable
        columns={adgroupColumns}
        defaultSort={['campaign', 'asc']}
        sorter={tableSorter(['campaign', 'name'])}
        className="table-adgroups"
        records={adgroupList}
        idField="adgroup_id"
        searchFields={['campaign', 'name']}
        selectedRecords={selectedAdgroups}
        onChange={setSelectedAdgroups}
        isLoading={isGettingAdgroups || isAdding}
        renderRecord={renderAdgroup}
      />
    )
  }

  const renderFooter = () => (
    <Modal.Footer>
      {
        step !== STEP_TARGET && (
          <button
            type="button"
            className="rs-btn rs-btn-default"
            onClick={handleBack}
          >
            Back
          </button>
        )
      }
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isGettingAdgroups || isAdding}
        onClick={() => handleConfirm()}
      >
        { step === STEP_ADGROUP ? 'Confirm' : 'Next' }
      </button>
      <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className="add-negative-to-campaigns-modal" backdrop="static" show={show} size="lg">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Add Negatives
          <VideoLink
            videoList={videoList}
            modalTitle="Add Negatives"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="creator-section">
        { renderSteps() }
        { renderContents() }
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default AddNegativeModal
