import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { TagPicker } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { FiTrash2 } from 'react-icons/fi'

import ConfirmModal from '../ConfirmModal'
import { deleteTagPermanent } from '../../../redux/actions/tag'

const CustomTagPicker = (props) => {
  const dispatch = useDispatch()
  const tagPickerRef = useRef(null)
  const { getAccessTokenSilently } = useAuth0()

  const [showConfirm, setShowConfirm] = useState(false)
  const [confirmText, setConfirmText] = useState("")
  const [deleteTag, setDeleteTag] = useState(null)

  const handleClickRemoveTag = (tag) => {
    setShowConfirm(true)
    setConfirmText(`Are you sure to delete "${tag.label}" Tag permanently?`)
    setDeleteTag({...tag})
    tagPickerRef.current.handleCloseDropdown()
  }

  const handleConfirm = async (isConfirmed) => {
    if (isConfirmed && deleteTag) {
      const accessToken = await getAccessTokenSilently()
      dispatch(deleteTagPermanent(accessToken, deleteTag.value))
    }
    setShowConfirm(false)
  }

  return (
    <>
      <TagPicker
        {...props}
        renderMenuItem={(label, item) => ((
          <div className="tag-picker-item">
            <span>
              { label }
            </span>
            <button
              type="button"
              onClick={() => { handleClickRemoveTag(item) }}
            >
              <FiTrash2 title="Remove tag" />
            </button>
          </div>
        ))}
        ref={tagPickerRef}
      />
      <ConfirmModal
        show={showConfirm}
        onConfirm={handleConfirm}
        text={confirmText}
      />
    </>
  )
}

export default CustomTagPicker
