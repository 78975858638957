import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../CommonComponents/ToastComponent/toast'
import BidAdjustComponent from '../../../CommonComponents/BidAdjustComponent'
import TableCell from '../../../CommonComponents/TableCell'
import GeniusBidCell from '../../../GeniusBidCell'

import {
  updateKeywordBids,
  updateKeywordStates,
} from '../../../../redux/actions/bulkEngine'

import {
  formatCurrency,
  capitalizeFirstLetter,
  copyToClipboard,
  tableSorter,
  calcDerivedMetrics,
  calcMaxCpc,
  calcNewBid,
  getExportValueForColumn,
} from '../../../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
} from '../../../../utils/defaultValues'

import { matchTypes, keywordStatuses } from '../../../../utils/filterDef'
import { selectCurrentAccount } from '../../../../redux/reducers/header'

const columns = [
  { key: 'keyword', name: 'Keyword/Target', className: 'col-keyword' },
  { key: 'bid', name: 'Current Bid' },
  { key: 'maxCpc', name: 'Genius Bid', className: 'col-genius-bid' },
  ...bulkBidColumnList,
]

const KeywordTable = ({ isLoading, campaignType }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const bidData = useSelector(state => state.campaignDetail.bidData)

  const [keywords, setKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [selectedStatus, setSelectedStatus] = useState(keywordStatuses[0])
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)

  useEffect(() => {
    const extendedKeywords = [];
    (bidData || []).forEach((record) => {
      if (selectedMatchType.value !== '') {
        if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
          return
        }
      }

      if (selectedStatus.value !== '') {
        if ((record.state || '').toLowerCase() !== selectedStatus.value) {
          return
        }
      }

      const derived = calcDerivedMetrics(record)
      derived.target_acos = currentAcos

      let adType
      if (campaignType === 'sb') {
        adType = currentDetail.video_adgroup_ids.includes(
          record.adgroup_id.toString()
        ) ? 'sbv' : campaignType
      } else {
        adType = campaignType
      }

      extendedKeywords.push({
        ...derived,
        matchType: capitalizeFirstLetter(record.match_type),
        adType,
        maxCpc: calcMaxCpc(
          derived,
          adType,
          currentAccount?.country_id,
          currentDetail.cost_type,
        ),
      })
    })

    setKeywords(extendedKeywords)
  }, [currentAcos, bidData, selectedMatchType, selectedStatus, // eslint-disable-line
    campaignType, currentAccount?.country_id])

  const handleCopy = () => {
    const keywordTexts = keywords.filter(keyword => (
      selectedKeywords.indexOf(keyword.keyword_id) !== -1
    )).map(keyword => keyword.keyword.trim())

    copyToClipboard([...new Set(keywordTexts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedKeywords.length} keyword${selectedKeywords.length > 1 ? 's' : ''}.`
    })
  }

  const handleChangeState = async (state) => {
    if (currentDetail && currentDetail.targeting_type === 'auto') {
      toast.show({
        title: 'Warning',
        description: 'Auto campaign cannot change keyword bid.',
      })
      return
    }

    const keywordsToChange = keywords.filter(keyword => (
      selectedKeywords.indexOf(keyword.keyword_id) !== -1
      && keyword.state.toLowerCase() !== 'archived'
      && keyword.keyword !== '(_targeting_auto_)'
    )).map((keyword) => {
      const adgroup = currentAdGroups.find(record =>
        record.adgroupid === keyword.adgroup_id.toString()
      )
      return {
        campaignType,
        keywordId: keyword.keyword_id,
        campaignId: keyword.campaign_id,
        adGroupId: keyword.adgroup_id,
        adgroupName: adgroup ? adgroup.name : '',
        keyword: keyword.keyword,
        matchType: keyword.match_type,
        originalState: keyword.state,
      }
    })

    if (!keywordsToChange.length) {
      toast.show({
        title: 'Warning',
        description: 'Archived keywords cannot be changed.',
      })
      return
    }

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateKeywordStates(accessToken, keywordsToChange, state))
    setIsUpdatingStates(false)
  }

  const handleAdjustBid = async () => {
    const adgroupNamesById = {}
    currentAdGroups.forEach((adgroup) => {
      adgroupNamesById[adgroup.adgroupid] = adgroup.name
    })

    let keywordsChanged = []
    keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.adType,
        currentAccount?.country_id,
        currentDetail.cost_type
      )

      keywordsChanged.push({
        campaignId: record.campaign_id,
        campaignType,
        adGroupId: record.adgroup_id,
        keywordId: record.keyword_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: adgroupNamesById[record.adgroup_id] || '',
        keyword: record.keyword,
        originalBid: parseFloat(record.bid || 0),
        matchType: record.matchType,
      })
    })

    // Remove duplicate entries.
    keywordsChanged =  [...new Map(keywordsChanged.map(item => [item.keywordId, item])).values()]

    if (!keywordsChanged.length) {
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your keywords',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateKeywordBids(accessToken, keywordsChanged))
    setIsUpdatingBids(false)
  }

  const handleChangeToMaxBid = async () => {
    if (currentDetail && currentDetail.targeting_type === 'auto') {
      toast.show({
        title: 'Warning',
        description: 'Auto campaign cannot change keyword bid.',
      })
      return
    }

    const adgroupNamesById = {}
    currentAdGroups.forEach((adgroup) => {
      adgroupNamesById[adgroup.adgroupid] = adgroup.name
    })

    let keywordsToChange = []
    keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
    )).forEach((record) => {
      if (record.maxCpc && record.keyword !== '(_targeting_auto_)') {
        keywordsToChange.push({
          campaignId: record.campaign_id,
          campaignType,
          adGroupId: record.adgroup_id,
          keywordId: record.keyword_id,
          bid: parseFloat(parseFloat(record.maxCpc).toFixed(2)),
          // Below information are used for logging in backend.
          adgroupName: adgroupNamesById[record.adgroup_id] || '',
          keyword: record.keyword,
          originalBid: parseFloat(record.bid || 0),
          matchType: record.matchType,
        })
      }
    })

    if (!keywordsToChange.length) {
      toast.show({
        title: 'Warning',
        description: 'Selected keyword(s) has invalid suggested bid. '
          + 'The minimum bid allowed is $0.15 and '
          + 'auto campaign cannot change keyword bid. Please check your keywords.',
      })
      return
    }

    // Remove duplicate records.
    keywordsToChange = [...new Map(keywordsToChange.map(item => [item.keywordId, item])).values()]

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateKeywordBids(accessToken, keywordsToChange))
    setIsUpdatingBids(false)
  }

  const renderActionItems = () => {
    if (!selectedKeywords.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            disabled={isUpdatingStates || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button type="button" className="btn btn-light-blue" onClick={() => { setIsShowAdjustBid(true) }}>
            Adjust Bid
          </button>
          <button type="button" className="btn btn-blue" onClick={handleChangeToMaxBid}>
            Change to Genius Bid
          </button>
          <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
            Copy
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderAction = () => {
    return (
      <>
        { renderActionItems() }
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="select-wrapper">
          <span>Status</span>
          <Select
            classNamePrefix="match-type-selector"
            options={keywordStatuses}
            value={selectedStatus}
            onChange={setSelectedStatus}
          />
        </div>
      </>
    )
  }

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.matchType } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <GeniusBidCell
        record={Object.assign({}, currentDetail || {}, record)}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
      {
        bulkBidColumnList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <SortableTable
      columns={columns}
      defaultSort={['cost', 'desc']}
      sorter={tableSorter(['state', 'keyword', 'matchType'])}
      className="table-keywords"
      records={keywords || []}
      idField="keyword_id"
      searchFields={['keyword']}
      selectedRecords={selectedKeywords}
      hasSticky
      hasDateRange
      filterName="campaignDetailBidKeyword"
      isLoading={isUpdatingBids || isUpdatingStates || isLoading}
      exportFileName="CampaignDetailBid"
      getExportData={getExportData}
      renderRecord={renderKeyword}
      renderTopRight={renderAction}
      onChange={setSelectedKeywords}
    />
  )
}

export default KeywordTable
