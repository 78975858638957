import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv';
import { Dropdown } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import SmartPilotTemplateSelector from '../APMComponent/TemplateSelector'
import DaypartingTemplateSelector from '../DaypartingManager/TemplateSelector'
import WeekpartingTemplateSelector from '../WeekpartingManager/TemplateSelector'
import PerformanceTemplateSelector from '../PerformanceManager/TemplateSelector'
import EventPlanningTemplateSelector from '../EventPlanningManager/TemplateSelector'
import InventoryTemplateSelector from '../InventoryManager/TemplateSelector'

import AddToNewPortfolio from './AddToNewPortfolio'
import AddToExistingPortfolio from './AddToExistingPortfolio'
import UpdateCampaignNameModal from './UpdateCampaignNameModal'
import UpdatePlacementsModal from './UpdatePlacementsModal'
import UpdateBiddingsModal from './UpdateBiddingsModal'
import UpdateTargetAcosModal from './UpdateTargetAcosModal'
import UpdateTagsModal from './UpdateTagsModal'
import AddTargetingModal from './AddTargetingModal'
import AddNegativeModal from './AddNegativeModal'
import AddProductsModal from './AddProductsModal'
import UpdateDailyBudgetModal from './UpdateDailyBudgetModal'
import AddNotesModal from './AddNotesModal'
import ComparisonDataModal from './ComparisonDataModal'
import HourlyDataModal from './HourlyDataModal'
import ChartViewModal from './ChartViewModal'

import {
  TAB_PORTFOLIO as PORTFOLIO_VIEW,
  TAB_TAG as TAG_VIEW,
  TAB_RULE as RULE_VIEW
} from '../TableCollection'

import {
  showANPAction,
  showAEPAction,
} from '../../redux/actions/pageGlobal'

import {
  updateCampaignsState,
  updateCampaignNames,
  updateBiddings,
  updateTargetAcos,
  updateBudget,
  addNotes,
} from '../../redux/actions/campaign'

import {
  turnRules,
  applyTemplates,
} from '../../redux/actions/ruleManager'

import {
  getSpTemplates,
  turnBulk,
  applyTemplateBulk,
} from '../../redux/actions/ap'

import {
  showSpManager,
  showRuleManager,
} from '../../redux/actions/ruleManager'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'
import { monitorJob } from '../../redux/actions/job'

import {
  COMPARISON_VIEW_TYPE_WEEKLY,
  COMPARISON_VIEW_TYPE_MONTHLY,
  RULE_TYPE_IR,
  RULE_TYPE_EP,
  RULE_TYPE_CP,
  RULE_TYPE_DP,
  RULE_TYPE_WP,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
} from '../../utils/defaultValues'
import LoaderComponent from "../CommonComponents/LoaderComponent";

const ActionBar = ({
  selectedCampaigns,
  dataToExport,
  hasExpanded,
  onViewCreativeAndDetail,
  onViewMetricsChart,
  onClearView,
  onDeselectFromModal,
  onChangeViewMethod,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const spTemplates = useSelector(state => state.ap.templates)
  const isLoadingSpTemplates = useSelector(state => state.ap.isLoadingTemplates)
  const isSpTemplatesLoaded = useSelector(state => state.ap.isTemplatesLoaded)

  const showANP = useSelector(state => state.pageGlobal.showANP)
  const showAEP = useSelector(state => state.pageGlobal.showAEP)

  const [spTemplateSelectorVisible, setSpTemplateSelectorVisible] = useState(false)
  const [dpTemplateSelectorVisible, setDpTemplateSelectorVisible] = useState(false)
  const [isApplyingTemplates, setIsApplyingTemplates] = useState(false)
  const [wpTemplateSelectorVisible, setWpTemplateSelectorVisible] = useState(false)
  const [cpTemplateSelectorVisible, setCpTemplateSelectorVisible] = useState(false)
  const [epTemplateSelectorVisible, setEpTemplateSelectorVisible] = useState(false)
  const [irTemplateSelectorVisible, setIrTemplateSelectorVisible] = useState(false)

  const [showUpdateCampaignName, setShowUpdateCampaignName] = useState(false)
  const [showUpdateBiddingModal, setShowUpdateBiddingsModal] = useState(false)
  const [showAddTargeting, setShowAddTargeting] = useState(false)
  const [showAddNegative, setShowAddNegative] = useState(false)
  const [showUpdatePlacementModal, setShowUpdatePlacementModal] = useState(false)
  const [showUpdateTargetAcos, setShowUpdateTargetAcos] = useState(false)
  const [showAddProducts, setShowAddProducts] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [showUpdateDailyBudget, setShowUpdateDailyBudget] = useState(false)
  const [showAddNotes, setShowAddNotes] = useState(false)
  const [showWoWDataModal, setShowWoWDataModal] = useState(false)
  const [showMoMDataModal, setShowMoMDataModal] = useState(false)
  const [showHourlyDataModal, setShowHourlyDataModal] = useState(false)
  const [showChartViewModal, setShowChartViewModal] = useState(false)
  const [isAddingBulkNotes, setIsAddingBulkNotes] = useState(false)
  const [isApplyingSpTemplate, setIsApplyingSpTemplate] = useState(false)
  const [isTurningRule, setIsTurningRule] = useState(false)

  useEffect(() => {
    if (spTemplateSelectorVisible
      && !isSpTemplatesLoaded
      && !isLoadingSpTemplates
      && currentAccount) {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        dispatch(getSpTemplates(accessToken))
      })()
    }
  }, [spTemplateSelectorVisible]) // eslint-disable-line

  const isBiddingSP = useMemo(() => {
    const found = selectedCampaigns.find(campaign => (
      campaign.campaignType !== 'sp'
    ))
    return typeof found === 'undefined'
  }, [selectedCampaigns])

  // Add to new portfolio.
  const handleANPShow = () => {
    dispatch(showANPAction(selectedCampaigns))
  }

  // Add to existing portfolio.
  const handleAEPShow = () => {
    dispatch(showAEPAction(selectedCampaigns))
  }

  const handleRuleManagerShow = type => () => {
    dispatch(showRuleManager(
      type,
      selectedCampaigns.map(campaign => ({
        campaign_id: campaign.campaign_id,
        name: campaign.campaign,
        campaignType: campaign.campaignType,
      })),
    ))
  }

  const handleRuleToggle = (type, value = null) => async () => {
    const accessToken = await getAccessTokenSilently()
    setIsTurningRule(true)
    dispatch(turnRules(
      accessToken,
      type,
      selectedCampaigns.map(campaign => campaign.campaign_id),
      value
    )).then(() => {
      setIsTurningRule(false)
    })
  }

  const handleRuleTemplateApply = type => async (templates) => {
    const accessToken = await getAccessTokenSilently()
    setIsApplyingTemplates(true)
    await dispatch(applyTemplates(
      accessToken,
      type,
      selectedCampaigns.map(campaign => campaign.campaign_id),
      templates.map(tpl => tpl.id),
    ))

    setIsApplyingTemplates(false)
    if (type === RULE_TYPE_IR) {
      setIrTemplateSelectorVisible(false)
    } else if (type === RULE_TYPE_EP) {
      setEpTemplateSelectorVisible(false)
    } else if (type === RULE_TYPE_CP) {
      setCpTemplateSelectorVisible(false)
    } else if (type === RULE_TYPE_WP) {
      setWpTemplateSelectorVisible(false)
    } else if (type === RULE_TYPE_DP) {
      setDpTemplateSelectorVisible(false)
    }
  }

  const handleSpManagerShow = () => {
    dispatch(showSpManager(selectedCampaigns[0].campaign_id))
  }

  const handleSpToggle = async () => {
    setIsApplyingSpTemplate(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(turnBulk(
      accessToken,
      selectedCampaigns.map(campaign => campaign.campaign_id)
    ))
    setIsApplyingSpTemplate(false)
  }

  const handleUpdateStateBulk = async (state) => {
    const campaignsChanged = selectedCampaigns
      .map(campaign => ({
        campaignId: campaign.campaign_id,
        campaignType: campaign.campaignType,
      }))

    const accessToken = await getAccessTokenSilently()
    dispatch(updateCampaignsState(
      accessToken,
      campaignsChanged,
      state,
      false,
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES,
        state,
      ))
    })
  }

  const handleUpdatedCampaigns = async (updatedCampaigns) => {
    const campaignsChanged = updatedCampaigns
      .map((campaign) => {
        const found = selectedCampaigns.find(record =>
          record.campaign_id = campaign.campaign_id
        )
        return {
          campaignId: campaign.campaign_id,
          name: campaign.campaign,
          campaignType: campaign.campaignType,
          originalName: found.campaign,
        }
      })
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateCampaignNames(
      accessToken,
      campaignsChanged,
      false,
    ))
    dispatch(monitorJob(
      response.data.jobId,
      JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES,
      campaignsChanged,
    ))
    setShowUpdateCampaignName(false)
  }

  const handleBiddingsUpdate = async (updatedCampaigns) => {
    const campaignsChanged = updatedCampaigns
      .map((campaign) => {
        const found = selectedCampaigns.find(record =>
          record.campaign_id = campaign.campaign_id
        )

        return {
          campaignId: campaign.campaign_id,
          bidding: {
            strategy: campaign.strategy,
            adjustments: campaign.adjustments,
          },
          oldBidding: found.bidding || {},
        }
      })

    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateBiddings(
      accessToken,
      campaignsChanged,
      false,
      false,
    ))
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
      ))
    }
  }

  const handleUpdateTargetAcosCampaigns = async (updatedCampaigns) => {
    const campaignsChanged = updatedCampaigns.map((campaign) => {
      const found = selectedCampaigns.find(record =>
        record.campaign_id.toString() === campaign.campaign_id.toString()
      )
      return {
        campaignId: campaign.campaign_id,
        campaignType: campaign.campaignType,
        acos: campaign.target_acos,
        originalAcos: found.target_acos,
      }
    })

    const accessToken = await getAccessTokenSilently()
    return dispatch(updateTargetAcos(accessToken, campaignsChanged, false)).then((response) => {
      setShowUpdateTargetAcos(false)

      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS,
        campaignsChanged,
      ))
    }).catch(() => {
      setShowUpdateTargetAcos(false)
    })
  }

  const handleSpTemplateApply = async (templateId) => {
    setIsApplyingSpTemplate(true)
    const campaignIds = selectedCampaigns.map(campaign => campaign.campaign_id)
    const accessToken = await getAccessTokenSilently()
    await dispatch(applyTemplateBulk(
      accessToken,
      templateId,
      campaignIds,
      format(new Date(), 'xxx'),
    ))
    setIsApplyingSpTemplate(false)
    setSpTemplateSelectorVisible(false)
  }

  const handleViewCreativeAndDetails = () => {
    onViewCreativeAndDetail(true, selectedCampaigns.map(campaign => campaign.campaign_id))
  }

  const handleUpdateDailyBudget = async (updatedCampaigns) => {
    const campaignsChanged = updatedCampaigns.map((campaign) => {
      const found = selectedCampaigns.find(record =>
        record.campaign_id.toString() === campaign.campaign_id.toString()
      )
      return {
        campaignId: campaign.campaign_id,
        campaignType: campaign.campaignType,
        dailyBudget: campaign.daily_budget,
        originalBudget: found.daily_budget,
      }
    })

    const accessToken = await getAccessTokenSilently()
    try {
      const response = await dispatch(updateBudget(accessToken, campaignsChanged, false))
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS,
        campaignsChanged,
      ))
    } finally {
      setShowUpdateDailyBudget(false)
    }
  }

  const handleAddNotes = async (notes) => {
    setIsAddingBulkNotes(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(addNotes(accessToken, notes))
    setIsAddingBulkNotes(false)
  }

  const spsdCampaignsSelected = typeof selectedCampaigns.find(campaign => (
    campaign.campaignType === 'sp'
    || campaign.campaignType === 'sd'
  )) !== 'undefined'

  const renderActionsDropdown = () => {
    return (
      <Dropdown title="Actions" disabled={!selectedCampaigns.length}>
        <Dropdown.Item panel>
          <strong>Basic</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { handleUpdateStateBulk('paused') }}>
          Pause
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { handleUpdateStateBulk('enabled') }}>
          Unpause
        </Dropdown.Item>
        {
          !isNonEndemicAccount && (
            <Dropdown.Item onSelect={() => { setShowUpdateTargetAcos(true) }}>
              Update Target ACoS
            </Dropdown.Item>
          )
        }
        <Dropdown.Item onSelect={() => { setShowUpdateDailyBudget(true) }}>
          Update Daily Budgets
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Organize</strong>
        </Dropdown.Item>
        {
          !isNonEndemicAccount && (
            <>
              <Dropdown.Item onSelect={() => { handleANPShow() }}>
                Add To New Portfolio
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => { handleAEPShow() }}>
                Add To Existing Portfolio
              </Dropdown.Item>
            </>
          )
        }
        <Dropdown.Item onSelect={() => { setShowTagsModal(true) }}>
          Tags
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setShowUpdateCampaignName(true) }}>
          Update Campaign Names
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setShowAddNotes(true) }}>
          Add Notes
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Update</strong>
        </Dropdown.Item>
        {
          !isNonEndemicAccount && (
            <>
              <Dropdown.Item
                disabled={!isBiddingSP}
                onSelect={() => { setShowUpdateBiddingsModal(true) }}
              >
                Update Bidding Strategy (SP Only)
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!isBiddingSP}
                onSelect={() => { setShowUpdatePlacementModal(true) }}
              >
                Placement Optimization (SP Only)
              </Dropdown.Item>
              <Dropdown.Item
                disabled={!spsdCampaignsSelected}
                onSelect={() => { setShowAddProducts(true) }}
              >
                Add Products to Advertise
              </Dropdown.Item>
            </>
          )
        }
        <Dropdown.Item onSelect={() => { setShowAddTargeting(true) }}>
          Add Targets
        </Dropdown.Item>
        {
          !isNonEndemicAccount && (
            <Dropdown.Item
              onSelect={() => { setShowAddNegative(true) }}
            >
              Add Negatives
            </Dropdown.Item>
          )
        }
      </Dropdown>
    )
  }

  return (
    <>
      { renderActionsDropdown() }
      { isTurningRule &&
        <LoaderComponent></LoaderComponent>}
        <Dropdown title="Rules" disabled={!selectedCampaigns.length || isTurningRule}>
        <Dropdown.Item panel>
          <strong>Smart Pilot</strong>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={selectedCampaigns.length !== 1}
          onSelect={handleSpManagerShow}
        >
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleSpToggle}>
          Toggle On/Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setSpTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Dayparting</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_DP)}>
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_DP, true)}>
          Turn On
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_DP, false)}>
          Turn Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setDpTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Weekparting</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_WP)}>
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_WP, true)}>
          Turn On
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_WP, false)}>
          Turn Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setWpTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Campaign Performance</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_CP)}>
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_CP)}>
          Toggle On/Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setCpTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Event Planning/Seasonality</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_EP)}>
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_EP)}>
          Toggle On/Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setEpTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item>
        {/* <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Inventory</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_IR)}>
          Start
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleRuleToggle(RULE_TYPE_IR)}>
          Toggle On/Off
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { setIrTemplateSelectorVisible(true) }}>
          Apply Template
        </Dropdown.Item> */}
      </Dropdown>
      <Dropdown title="Views">
        <Dropdown.Item
          disabled={!hasExpanded}
          onSelect={() => { onClearView() }}
        >
          Clear View
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>View Options</strong>
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { onChangeViewMethod(PORTFOLIO_VIEW) }}>
          Portfolios View
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { onChangeViewMethod(TAG_VIEW) }}>
          Tags View
        </Dropdown.Item>
        <Dropdown.Item onSelect={() => { onChangeViewMethod(RULE_VIEW) }}>
          Rules View
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Table Views</strong>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { handleViewCreativeAndDetails() }}
        >
          Campaigns Details / Creative
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { onViewMetricsChart() }}
        >
          Metrics Over Time
        </Dropdown.Item>
        <Dropdown.Item divider />
        <Dropdown.Item panel>
          <strong>Aggregated</strong>
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { setShowChartViewModal(true) }}
        >
          Chart View + Change Log
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { setShowHourlyDataModal(true) }}
        >
          Hourly Data
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { setShowWoWDataModal(true) }}
        >
          Week over Week
        </Dropdown.Item>
        <Dropdown.Item
          disabled={!selectedCampaigns.length}
          onSelect={() => { setShowMoMDataModal(true) }}
        >
          Month over Month
        </Dropdown.Item>
      </Dropdown>
      {
        selectedCampaigns.length > 0 && (
          <Link
            to="/bulk-engine"
            className="btn btn-blue"
          >
            Start Bulk Engine
          </Link>
        )
      }
      {
        dataToExport && dataToExport.length > 0 && (
          <CSVLink
            data={dataToExport}
            filename="My Campaign.csv"
            className="btn btn-blue"
            title="Export to CSV"
          >
            Export to CSV
          </CSVLink>
        )
      }
      <SmartPilotTemplateSelector
        show={spTemplateSelectorVisible}
        templates={spTemplates}
        isLoading={isLoadingSpTemplates}
        isApplying={isApplyingSpTemplate}
        onChange={handleSpTemplateApply}
        onCancel={() => { setSpTemplateSelectorVisible(false) }}
      />
      <DaypartingTemplateSelector
        show={dpTemplateSelectorVisible}
        isApplying={isApplyingTemplates}
        onChange={handleRuleTemplateApply(RULE_TYPE_DP)}
        onClose={() => { setDpTemplateSelectorVisible(false) }}
      />
      <WeekpartingTemplateSelector
        show={wpTemplateSelectorVisible}
        isApplying={isApplyingTemplates}
        onChange={handleRuleTemplateApply(RULE_TYPE_WP)}
        onClose={() => { setWpTemplateSelectorVisible(false) }}
      />
      <PerformanceTemplateSelector
        show={cpTemplateSelectorVisible}
        isApplying={isApplyingTemplates}
        onChange={handleRuleTemplateApply(RULE_TYPE_CP)}
        onClose={() => { setCpTemplateSelectorVisible(false) }}
      />
      <EventPlanningTemplateSelector
        show={epTemplateSelectorVisible}
        isApplying={isApplyingTemplates}
        onChange={handleRuleTemplateApply(RULE_TYPE_EP)}
        onClose={() => { setEpTemplateSelectorVisible(false) }}
      />
      <InventoryTemplateSelector
        show={irTemplateSelectorVisible}
        isApplying={isApplyingTemplates}
        onChange={handleRuleTemplateApply(RULE_TYPE_IR)}
        onClose={() => { setIrTemplateSelectorVisible(false) }}
      />
      { showANP && <AddToNewPortfolio campaigns={selectedCampaigns} /> }
      { showAEP && <AddToExistingPortfolio campaigns={selectedCampaigns} /> }
      { showUpdateCampaignName &&
        <UpdateCampaignNameModal
          campaigns={selectedCampaigns}
          onUpdate={handleUpdatedCampaigns}
          onClose={() => { setShowUpdateCampaignName(false) }}
        />
      }
      { showUpdateBiddingModal &&
        <UpdateBiddingsModal
          campaigns={selectedCampaigns}
          onUpdate={handleBiddingsUpdate}
          onClose={() => { setShowUpdateBiddingsModal(false) }}
        />
      }
      { showUpdatePlacementModal &&
        <UpdatePlacementsModal
          campaigns={selectedCampaigns}
          onUpdate={handleBiddingsUpdate}
          onClose={() => { setShowUpdatePlacementModal(false) }}
        />
      }
      { showAddTargeting &&
        <AddTargetingModal
          show={showAddTargeting}
          campaigns={selectedCampaigns}
          onClose={() => { setShowAddTargeting(false) }}
        />
      }
      { showAddNegative &&
        <AddNegativeModal
          show={showAddNegative}
          campaigns={selectedCampaigns}
          onClose={() => { setShowAddNegative(false) }}
        />
      }
      <UpdateTargetAcosModal
        campaigns={selectedCampaigns}
        accountAcos={currentAccount?.average_acos || 30}
        show={showUpdateTargetAcos}
        onUpdate={handleUpdateTargetAcosCampaigns}
        onClose={() => { setShowUpdateTargetAcos(false) }}
      />
      { showAddProducts &&
        <AddProductsModal
          show={showAddProducts}
          campaigns={selectedCampaigns}
          onClose={() => { setShowAddProducts(false) }}
        />
      }
      { showTagsModal && (
        <UpdateTagsModal
          campaigns={selectedCampaigns}
          onClose={() => { setShowTagsModal(false) }}
        />
      )}
      {
        showUpdateDailyBudget && (
          <UpdateDailyBudgetModal
            campaigns={selectedCampaigns}
            onUpdate={handleUpdateDailyBudget}
            onClose={() => { setShowUpdateDailyBudget(false) }}
          />
        )
      }
      {
        showAddNotes && (
          <AddNotesModal
            isLoading={isAddingBulkNotes}
            campaigns={selectedCampaigns}
            onSave={handleAddNotes}
            onClose={() => { setShowAddNotes(false) }}
          />
        )
      }
      {
        showHourlyDataModal && (
          <HourlyDataModal
            campaigns={selectedCampaigns}
            onClose={() => { setShowHourlyDataModal(false) }}
            onCloseCampaign={onDeselectFromModal}
          />
        )
      }
      {
        showWoWDataModal && (
          <ComparisonDataModal
            campaigns={selectedCampaigns}
            type={COMPARISON_VIEW_TYPE_WEEKLY}
            onClose={() => { setShowWoWDataModal(false) }}
            onCloseCampaign={onDeselectFromModal}
          />
        )
      }
      {
        showMoMDataModal && (
          <ComparisonDataModal
            campaigns={selectedCampaigns}
            type={COMPARISON_VIEW_TYPE_MONTHLY}
            onClose={() => { setShowMoMDataModal(false) }}
            onCloseCampaign={onDeselectFromModal}
          />
        )
      }
      {
        showChartViewModal && (
          <ChartViewModal
            campaigns={selectedCampaigns}
            onClose={() => { setShowChartViewModal(false) }}
            onCloseCampaign={onDeselectFromModal}
          />
        )
      }
    </>
  )
}

export default ActionBar
