import React from 'react'

const SBCreativeBrandName = ({ basicInfo, onChange }) => {
  return (
    <div className="customizer-section">
      <div className="field-section-name">
        Brand Name
      </div>
      <div className="field-section-contents">
        <input
          type="text"
          value={basicInfo.brandName}
          onChange={(event) => { onChange('brandName', event.target.value) }}
        />
      </div>
    </div>
  )
}

export default SBCreativeBrandName
