import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'
import * as Icon from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'

import BulkResultContainer from '../BulkResultContainer'
import TargetAddModal from './TargetAddModal'

import {
  copyToClipboard,
  tableSorter,
  getAmazonSearchLink,
  isAsin,
} from '../../services/helper'
import { selectCurrentAccount } from '../../redux/reducers/header'

const columns = [
  { key: 'target', name: 'Target' },
]

const TargetExResult = ({ targetExData }) => {
  const currentAccount = useSelector(selectCurrentAccount)

  const [targets, setTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [targetsPayload, setTargetsPayload] = useState([])

  useEffect(() => {
    setTargets(Object.keys(targetExData).map(key => ({
      target: targetExData[key],
    })))
  }, [targetExData]) // eslint-disable-line

  const handleCopy = () => {
    const tgs = targets.filter(tg => (
      selectedTargets.indexOf(tg.target) !== -1
    )).map(tg => tg.target.trim())

    copyToClipboard([...new Set(tgs)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedTargets.length} target${selectedTargets.length > 1 ? 's' : ''}.`
    })
  }

  const handleBreakWords = () => {
    const newTargetList = []
    const targets = []
    Object.keys(targetExData).forEach((key) => {
      const broken = targetExData[key].match(/\S+\s*/g)
      broken.forEach((word) => {
        const target = word.trim()
        if (targets.includes(target)) {
          return
        }
        targets.push(target)
        newTargetList.push({
          target,
        })
      })
    })
    setTargets(newTargetList)
  }

  const handleAddToExisting = () => {
    setTargetsPayload(targets.filter(tg => (
      selectedTargets.indexOf(tg.target) !== -1
    )).map(tg => ({
      target: tg.target.trim(),
    })))
    setIsAddModalVisible(true)
  }

  const renderAction = () => {
    const asinTargets = selectedTargets.filter(isAsin)

    return (
      <>
        <button type="button" className="btn btn-green" onClick={handleBreakWords}>
          Individual Words
        </button>
        {
          selectedTargets.length > 0 && (
            <>
              <button type="button" className="btn btn-green" onClick={handleCopy}>
                Copy
              </button>
              <button type="button" className="btn btn-blue" onClick={handleAddToExisting}>
                Add to Existing Campaigns
              </button>
              <Dropdown
                title="Add to New Campaign"
                placement="bottomEnd"
                toggleClassName="btn-new"
              >
                <Dropdown.Item componentClass={Link} to={{
                  pathname: '/campaigns/new/sp',
                  state: {
                    targets: selectedTargets,
                  },
                }}>
                  Sponsored Product Campaign
                </Dropdown.Item>
                <Dropdown.Item componentClass={Link} to={{
                  pathname: '/campaigns/new/sb',
                  state: {
                    targets: selectedTargets,
                  },
                }}>
                  Sponsored Brand Campaign
                </Dropdown.Item>
                <Dropdown.Item
                  componentClass={Link}
                  to={{
                    pathname: '/campaigns/new/sd',
                    state: {
                      targets: asinTargets,
                    },
                  }}
                  disabled={asinTargets.length === 0}
                >
                  Sponsored Display Campaign
                </Dropdown.Item>
              </Dropdown>
            </>
          )
        }
      </>
    )
  }

  const renderTarget = record => (
    <>
      <div className="table-col col-target" title={record.target}>
        { record.target }
        <a
          href={getAmazonSearchLink(currentAccount, record.target)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.FiExternalLink size={16} />
        </a>
      </div>
    </>
  )

  return (
    <BulkResultContainer>
      <SortableTable
        columns={columns}
        defaultSort={['target', 'asc']}
        sorter={tableSorter(['target'])}
        className="table-targets"
        records={targets || []}
        idField="target"
        searchFields={['target']}
        selectedRecords={selectedTargets}
        renderRecord={renderTarget}
        renderTopRight={renderAction}
        onChange={setSelectedTargets}
      />
      <TargetAddModal
        show={isAddModalVisible}
        forTargetEx
        targets={targetsPayload}
        onClose={() => { setIsAddModalVisible(false) }}
      />
    </BulkResultContainer>
  )
}

export default TargetExResult
