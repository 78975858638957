import React from 'react'

import FlowContainer from './FlowContainer'
import YoutubeVideoCollection from './YoutubeVideoCollection'

const videoList = [
  {
    name: 'Genius Bid',
    videoId: '-nJlU5AZm1c',
  },
]

const GeniusBid = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Genius Bid"
      {...props}
    >
      <YoutubeVideoCollection videoList={videoList} onWatch={onWatch}/>
    </FlowContainer>
  )
}

export default GeniusBid
