import { callPost } from '../../services/axios.js'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  CREATE_IR_TEMPLATE,
  UPDATE_IR_TEMPLATE,
} from '../actionTypes/inventory.js'

export const loadIrRules = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/inventory/loadRules', {
    userId: currentUserId,
    campaignIds,
  }, accessToken).then(response => response.data)
}

export const saveIrRules = (accessToken, campaignIds, status, slots, templateIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/inventory/saveRules', {
    userId: currentUserId,
    campaignIds,
    status,
    slots,
    templateIds,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Successfully saved rules.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save rules.',
    })
  })
}

export const saveIrTemplate = (accessToken, name, slots, needApply = false, campaignIds = []) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/inventory/saveTemplate', {
    name,
    slots,
    needApply,
    userId: currentUserId,
    campaignIds,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Successfully saved template.',
    })

    dispatch({
      type: CREATE_IR_TEMPLATE,
      template: response.data,
    })

    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save template.',
    })
    return null
  })
}

export const updateIrTemplate = (accessToken, templateId, name, slots) => (dispatch) => {
  return callPost('/inventory/updateTemplate', {
    templateId,
    name,
    slots,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Successfully updated template.',
    })

    dispatch({
      type: UPDATE_IR_TEMPLATE,
      template: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update template.',
    })
  })
}
