import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import VideoLink from '../../CommonComponents/VideoLink'
import LoaderComponent from '../../CommonComponents/LoaderComponent'
import TemplateOption from '../../CommonComponents/TemplateOption'
import OpKeywordGeneral from './OpKeywordGeneral'
import OpKeywordBasic from './OpKeywordBasic'
import OpKeywordAdvanced from './OpKeywordAdvanced'
import OpNTA from './OpNTA'
import OpNPT from './OpNPT'

import { getSpTemplate } from '../../../redux/actions/ap'
import { showTemplateEditor } from '../../../redux/actions/ruleManager'
import { RULE_TYPE_SP } from '../../../utils/defaultValues'

const videoList = [
  { title: 'Template Video', url: 'https://www.loom.com/embed/29a27bb03bbf451dbafeb179b199e747' },
]

const TemplateSelector = ({
  show,
  templates,
  settings = {},
  isLoading,
  isApplying = false,
  onChange,
  onCancel,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [details, setDetails] = useState(null)

  useEffect(() => {
    if (show) {
      setDetails(null)
    }
  }, [show])

  useEffect(() => {
    const found = templates.find(template => (
      parseInt(template.id, 10) === parseInt(settings.ap_template_id || 0, 10)
    ))
    if (found) {
      setSelectedTemplate(found)
    } else {
      setSelectedTemplate(null)
    }
  }, [templates]) // eslint-disable-line

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template)

    // When another template is in `more details` mode,
    // present the selected template's contents.
    if (details || loadingDetails) {
      handleSeeDetails(template.id)
    }
  }

  const handleEdit = (event, templateId) => {
    event.preventDefault()
    event.stopPropagation()
    if (details && templateId === details.id) {
      dispatch(showTemplateEditor(RULE_TYPE_SP, details))
    } else {
      dispatch(showTemplateEditor(RULE_TYPE_SP, templateId))
    }
    onCancel()
  }

  const handleSeeDetails = async (templateId) => {
    setLoadingDetails(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSpTemplate(accessToken, templateId))
    setLoadingDetails(false)
    setDetails(response.data)
  }

  const extendedTemplates = templates.map(template => ({
    ...template,
    onEdit: handleEdit,
    onSeeDetails: handleSeeDetails,
  }))

  const renderDetails = () => {
    if (!details || loadingDetails) {
      return null
    }

    return (
      <div className="template-details">
        <div className="template-name">
          Name: <strong>{ details.name }</strong>
        </div>
        <div className="setting-section">
          <div className="section-name">
            Target Bid Optimization
          </div>
          <OpKeywordGeneral details={details} />
          <OpKeywordBasic details={details} />
          <OpKeywordAdvanced details={details} />
        </div>
        <OpNTA details={details} />
        <OpNTA details={details} clone />
        <OpNPT details={details} />
        <OpNPT details={details} clone />
      </div>
    )
  }

  return (
    <Modal className="template-selector-modal" overflow={false} backdrop="static" show={show} size="sm">
      <Modal.Body>
        { (isApplying || loadingDetails) && <LoaderComponent /> }
        <div className="template-label">
          <span>Optimization Templates</span>
          <VideoLink
            videoList={videoList}
          />
        </div>
        <Select
          options={extendedTemplates}
          getOptionLabel={template => template.name}
          getOptionValue={template => template.id}
          value={selectedTemplate}
          components={{ Option: TemplateOption }}
          isLoading={isLoading}
          isDisabled={isApplying}
          placeholder="Select template..."
          onChange={handleTemplateChange}
        />
        { renderDetails() }
      </Modal.Body>
      <Modal.Footer>
        {
          settings.ap_template_id &&
          (selectedTemplate
            && parseInt(selectedTemplate.id, 10) === parseInt(settings.ap_template_id, 10)) && (
            <button
              type="button"
              className="rs-btn rs-btn-default rs-btn-red"
              disabled={isApplying}
              onClick={() => { onChange(null) }}
            >
              Remove Template from Campaign
            </button>
          )
        }
        {
          selectedTemplate && (
            <button
              type="button"
              className="rs-btn rs-btn-default rs-btn-green"
              disabled={isApplying}
              onClick={(event) => { handleEdit(event, selectedTemplate.id) }}
            >
              Edit Template
            </button>
          )
        }
        {
          selectedTemplate &&
          parseInt(settings.ap_template_id || 0, 10) !== parseInt(selectedTemplate.id, 10) && (
            <button
              type="button"
              className="rs-btn rs-btn-primary"
              disabled={isApplying}
              onClick={() => { onChange(selectedTemplate.id) }}
            >
              Apply Template
            </button>
          )
        }
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          disabled={isApplying}
          onClick={onCancel}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TemplateSelector
