/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Button } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import VideoLink from '../CommonComponents/VideoLink'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import CampaignTableComponent from '../CampaignTableComponent'

import TargetExSection from './TargetExSection'
import TargetExResult from './TargetExResult'
import StExSection from './StExSection'
import StExResult, { FILTER_NAME_ST_EX } from './StExResult'
import PtExSection, {
  SUB_MODULE_ASIN,
  SUB_MODULE_CATEGORY,
  MODULE_NAME_PT_EX,
} from './PtExSection'
import PtExAsinResult, { FILTER_NAME_PT_EX_ASIN } from './PtExAsinResult'
import PtExCategoryResult, { FILTER_NAME_PT_EX_CATEGORY } from './PtExCategoryResult'

import {
  findNewTargets,
  findSts,
  findPts,
} from '../../redux/actions/bulkEngine'
import { checkStatus, monitorJob } from '../../redux/actions/job'
import { applyFilter } from '../../redux/actions/pageGlobal'
import { setDateRange } from '../../redux/actions/header'

import {
  JOB_TYPE_BULK_TARGET_EX,
  JOB_TYPE_BULK_ST_EX,
  JOB_TYPE_BULK_PRODUCT_EX,
  MODULE_NAME_TARGET_EX,
  MODULE_NAME_ST_EX,
} from '../../utils/defaultValues'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const MODULE_TARGET_EX = 'target_ex'
const MODULE_ST_EX = 'st_ex'
const MODULE_PT_EX = 'pt_ex'

const moduleList = [
  {
    key: MODULE_TARGET_EX,
    name: MODULE_NAME_TARGET_EX,
    description: (
      <>
        <p>Enter in a list of targets to see if you are already targeting them in any campaigns.</p>
      </>
    ),
  },
  {
    key: MODULE_ST_EX,
    name: MODULE_NAME_ST_EX,
    description: (
      <>
        <p>Our system will quickly find your best search terms and convert them into new keywords/targets.</p>
      </>
    ),
  },
  {
    key: MODULE_PT_EX,
    name: MODULE_NAME_PT_EX,
    description: (
      <>
        <p>Finds ASINS and Categories that have converted so you can expand.</p>
      </>
    ),
  },
]

export const tutorialList = {
  '': {
    title: 'Bulk Engine Overview',
    videoList: [
      { title: 'Bulk Engine Overview', url: 'https://www.loom.com/embed/8aade68f957449d5ab685182612c39ad' },
    ],
  },
  [MODULE_TARGET_EX]: {
    title: MODULE_NAME_TARGET_EX,
    videoList: [
      { title: MODULE_NAME_TARGET_EX, url: 'https://www.loom.com/embed/276758843ca34bec9392af01d8485e75' },
    ],
  },
  [MODULE_ST_EX]: {
    title: MODULE_NAME_ST_EX,
    videoList: [
      { title: MODULE_NAME_ST_EX, url: 'https://www.loom.com/embed/fed58c4c440d4b82869250e4761ac044' },
    ],
  },
  [MODULE_PT_EX]: {
    title: MODULE_NAME_PT_EX,
    videoList: [
      { title: MODULE_NAME_PT_EX, url: 'https://www.loom.com/embed/fed58c4c440d4b82869250e4761ac044' },
    ],
  },
}

const DEFAULT_ACOS_MIN = 0.1
const DEFAULT_ACOS_MAX = 40

const BulkExpansion = ({ openedJob, showNotedCampaigns, initialRender, onHasResult }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const campaignIdsSelected = useSelector(state => state.campaign.campaignIdsSelected)

  const statusCheckerInterval = useRef(null)

  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [activeModule, setActiveModule] = useState('')
  const [activeTypeTarget, setActiveTypeTarget] = useState(!isNonEndemicAccount ? SUB_MODULE_ASIN : SUB_MODULE_CATEGORY)
  const [isLoading, setIsLoading] = useState(false)
  const [exData, setExData] = useState([])
  // For ST ex.
  const [hideKeywords, setHideKeywords] = useState(true)
  const [hideAsins, setHideAsins] = useState(true)
  // For PT ex.
  const [newAsinOnly, setNewAsinOnly] = useState(true)
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [hasResults, setHasResults] = useState(false)
  const [isCampaignsExpanded, setIsCampaignsExpanded] = useState(false)
  // Keep whether a `lifetime` range is selected for each module.
  const [lifetimeForModules, setLifetimeForModules] = useState({})

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      if (statusCheckerInterval.current) {
        clearInterval(statusCheckerInterval.current) // eslint-disable-line
      }
    }
  }, [])

  useEffect(() => {
    setCurrentFilterName('')
    setHasResults(false)
    setIsCampaignsExpanded(false)
  }, [currentUserId])

  useEffect(() => {
    if (openedJob) {
      if (openedJob.type === JOB_TYPE_BULK_TARGET_EX) {
        setActiveModule(MODULE_TARGET_EX)
        setExData(openedJob.results)
      } else if (openedJob.type === JOB_TYPE_BULK_ST_EX) {
        setActiveModule(MODULE_ST_EX)
        setHideKeywords(openedJob.payload.stOnly)
        setExData(openedJob.results)
      } else if (openedJob.type === JOB_TYPE_BULK_PRODUCT_EX) {
        setActiveModule(MODULE_PT_EX)
        setActiveTypeTarget(openedJob.payload.typeTarget)
        setNewAsinOnly(openedJob.payload.newAsinOnly)
        setExData(openedJob.results)
      }

      if (openedJob.type !== JOB_TYPE_BULK_TARGET_EX) {
        setLifetimeForModules(Object.assign({}, lifetimeForModules, {
          [activeModule]: openedJob.payload.startDate === null
          && openedJob.payload.endDate === null,
        }))

        if (openedJob.payload.startDate && openedJob.payload.endDate) {
          dispatch(setDateRange({
            startDate: openedJob.payload.startDate,
            endDate: openedJob.payload.endDate,
          }))
        }
      }

      setHasResults(true)
    }
  }, [openedJob, dispatch]) // eslint-disable-line

  useEffect(() => {
    onHasResult(hasResults)
  }, [hasResults]) // eslint-disable-line

  const handleModuleSelect = (module) => {
    if (activeModule !== module.key) {
      setHasResults(false)
      setActiveModule(module.key)
    }
  }

  const handleFindTargets = async (targets) => {
    setHasResults(false)
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(findNewTargets(
      accessToken,
      targets
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_TARGET_EX,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_TARGET_EX)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const doFindSts = async (startDate, endDate, stOnly, values) => {
    if (lifetimeForModules[activeModule]) {
      startDate = null
      endDate = null
    }

    let filters
    if (values) {
      filters = values
    } else {
      filters = (filterValues || {})[FILTER_NAME_ST_EX] || {}
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(findSts(
      accessToken,
      startDate,
      endDate,
      filters['acosMin'],
      filters['acosMax'],
      stOnly
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_ST_EX,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_ST_EX)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleStDateChange = (startDate, endDate) => {
    setLifetimeForModules(Object.assign({}, lifetimeForModules, {
      [activeModule]: startDate === null && endDate === null,
    }))
    doFindSts(startDate, endDate, hideKeywords)
  }

  const handleFindSts = () => {
    // Update filter values in results section.
    const values = { ...((filterValues || {})[FILTER_NAME_ST_EX] || {}) }
    values['acosMin'] = DEFAULT_ACOS_MIN
    values['acosMax'] = DEFAULT_ACOS_MAX
    dispatch(applyFilter(FILTER_NAME_ST_EX, values))

    setCurrentFilterName(FILTER_NAME_ST_EX)
  }

  // Handle toggling 'Remove Keywords' checkbox for ST Ex.
  const handleChangeHideKeywords = (checked) => {
    setHideKeywords(checked)
    setHasResults(false)
    doFindSts(currentStartDate, currentEndDate, checked)
  }

  const doFindPts = async (typeTarget, newAsinOnlyValue, startDate, endDate, values) => {
    if (lifetimeForModules[typeTarget]) {
      startDate = null
      endDate = null
    }

    let filters
    if (values) {
      filters = values
    } else {
      if (typeTarget === SUB_MODULE_ASIN) {
        filters = (filterValues || {})[FILTER_NAME_PT_EX_ASIN] || {}
      } else {
        filters = (filterValues || {})[FILTER_NAME_PT_EX_CATEGORY] || {}
      }
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(findPts(
      accessToken,
      startDate,
      endDate,
      filters['acosMin'] || DEFAULT_ACOS_MIN,
      filters['acosMax'] || DEFAULT_ACOS_MAX,
      typeTarget,
      newAsinOnlyValue,
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_PRODUCT_EX,
        {
          typeTarget,
        }
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_PRODUCT_EX)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleFindPts = (typeTarget) => {
    setActiveTypeTarget(typeTarget)
    if (typeTarget === SUB_MODULE_ASIN) {
      // Update filter values in results section.
      const valuesAsin = { ...((filterValues || {})[FILTER_NAME_PT_EX_ASIN] || {}) }
      valuesAsin['acosMin'] = DEFAULT_ACOS_MIN
      valuesAsin['acosMax'] = DEFAULT_ACOS_MAX
      dispatch(applyFilter(FILTER_NAME_PT_EX_ASIN, valuesAsin))

      setCurrentFilterName(FILTER_NAME_PT_EX_ASIN)
    } else {
      // Update filter values in results section.
      const valuesCategory = { ...((filterValues || {})[FILTER_NAME_PT_EX_CATEGORY] || {}) }
      if (!isNonEndemicAccount) {
        valuesCategory['acosMin'] = DEFAULT_ACOS_MIN
        valuesCategory['acosMax'] = DEFAULT_ACOS_MAX
      }
      dispatch(applyFilter(FILTER_NAME_PT_EX_CATEGORY, valuesCategory))

      setCurrentFilterName(FILTER_NAME_PT_EX_CATEGORY)
    }
  }

  const handlePtDateChange = (startDate, endDate) => {
    setLifetimeForModules(Object.assign({}, lifetimeForModules, {
      [activeTypeTarget]: startDate === null && endDate === null,
    }))
    doFindPts(activeTypeTarget, newAsinOnly, startDate, endDate)
  }

  const handleFind = (payload) => {
    if (activeModule === MODULE_TARGET_EX) {
      handleFindTargets(payload)
    } else if (activeModule === MODULE_ST_EX) {
      handleFindSts()
    } else if (activeModule === MODULE_PT_EX) {
      handleFindPts(payload)
    }
  }

  // Handle toggling 'New ASIN only' checkbox for PT Ex.
  const handleChangeNewAsinOnly = (checked) => {
    setNewAsinOnly(checked)
    setActiveTypeTarget(SUB_MODULE_ASIN)
    setHasResults(false)
    doFindPts(SUB_MODULE_ASIN, checked, currentStartDate, currentEndDate)
  }

  const handleFilterApply = (values) => {
    setCurrentFilterName('')
    setHasResults(false)
    if (activeModule === MODULE_ST_EX) {
      doFindSts(currentStartDate, currentEndDate, hideKeywords, values)
    } else if (activeModule === MODULE_PT_EX) {
      doFindPts(activeTypeTarget, newAsinOnly, currentStartDate, currentEndDate, values)
    }
  }

  const handleFilterValidate = (values) => {
    if (isNonEndemicAccount) {
      return null
    }

    const { acosMin, acosMax } = values

    if (acosMin === '' || isNaN(acosMin) || parseFloat(acosMin) < 0
      || acosMax === '' || isNaN(acosMax) || parseFloat(acosMax) < 0) {
      return 'Please enter ACoS greater than or equal to 0.'
    }

    if (parseFloat(acosMin) > parseFloat(acosMax)) {
      return 'The start range of ACoS cannot be greater than the end range.'
    }

    return null
  }

  const checkJob = (jobId, type) => {
    if (statusCheckerInterval.current) {
      clearInterval(statusCheckerInterval.current)
    }
    setExData([])
    statusCheckerInterval.current = setInterval(async () => {
      // @todo: Prevent another status check API call
      // while a previous one is in flight.
      const accessToken = await getAccessTokenSilently()
      dispatch(checkStatus(accessToken, jobId)).then(({ url, data }) => {
        if (url) {
          if (type === JOB_TYPE_BULK_TARGET_EX
            || type === JOB_TYPE_BULK_ST_EX
            || type === JOB_TYPE_BULK_PRODUCT_EX) {
            setExData(data)
            setHasResults(true)
            setIsCampaignsExpanded(false)
          }
          clearInterval(statusCheckerInterval.current)
          statusCheckerInterval.current = null
        }
      }).catch(() => {
        clearInterval(statusCheckerInterval.current)
        statusCheckerInterval.current = null
      })
    }, 5000)
  }

  const availableModules = useMemo(() => {
    if (!isNonEndemicAccount) {
      return moduleList
    }
    return moduleList.filter(module => (
      ![
        MODULE_TARGET_EX,
        MODULE_ST_EX,
      ].includes(module.key)
    ))
  }, [isNonEndemicAccount])

  const renderTopSection = () => {
    if (hasResults && !isCampaignsExpanded) {
      return null
    }

    const isCampaignsSelected = (campaignIdsSelected || []).length > 0
    if (activeModule === MODULE_TARGET_EX) {
      return (
        <TargetExSection
          isCampaignsSelected={isCampaignsSelected}
          onFind={handleFind}
        />
      )
    }

    if (activeModule === MODULE_ST_EX) {
      return (
        <StExSection
          isCampaignsSelected={isCampaignsSelected}
          onFind={handleFind}
        />
      )
    }

    if (activeModule === MODULE_PT_EX) {
      return (
        <PtExSection
          isCampaignsSelected={isCampaignsSelected}
          isNonEndemicAccount={isNonEndemicAccount}
          onFind={handleFind}
        />
      )
    }

    return null
  }

  const renderModuleSelector = (dropdownLabel) => {
    return (
      <div className="module-selector-container">
        <div className="module-selector-left">
          <Dropdown title={dropdownLabel} renderTitle={children => {
            return (
              <Button appearance="primary">
                {children}
                <span className="toggle-caret" />
              </Button>
            )
          }}>
            {
              availableModules.map(module => (
                <Dropdown.Item
                  key={module.key}
                  active={module.key === activeModule}
                  onSelect={() => { handleModuleSelect(module) }}
                >
                  { module.name }
                  {
                    typeof module.description !== 'undefined' && (
                      <CustomTooltip placement="right">
                        { module.description }
                      </CustomTooltip>
                    )
                  }
                </Dropdown.Item>
              ))
            }
          </Dropdown>
          { renderTopSection() }
        </div>
        <VideoLink
          modalTitle={tutorialList[activeModule].title}
          videoList={tutorialList[activeModule].videoList}
        />
      </div>
    )
  }

  const renderCampaignSelection = () => {
    if (hasResults && !isCampaignsExpanded) {
      return (
        <div className="campaign-selection-state">
          <strong>{ (campaignIdsSelected || []).length }</strong> campaigns selected.
          <a
            href="#"
            onClick={(event) => { event.preventDefault(); setIsCampaignsExpanded(true) }}
          >
            Change
          </a>
        </div>
      )
    }
    return null
  }

  const renderResults = () => {
    if (!hasResults) {
      return null
    }

    if (activeModule === MODULE_TARGET_EX) {
      return (
        <TargetExResult
          targetExData={exData}
        />
      )
    }
    if (activeModule === MODULE_ST_EX) {
      return (
        <StExResult
          stExData={exData}
          hideKeywords={hideKeywords}
          hideAsins={hideAsins}
          onChangeHideKeywords={handleChangeHideKeywords}
          onChangeHideAsins={setHideAsins}
          onChangeDate={handleStDateChange}
          onApplyFilter={(values) => { doFindSts(currentStartDate, currentEndDate, hideKeywords, values) }}
        />
      )
    }
    if (activeModule === MODULE_PT_EX) {
      if (activeTypeTarget === SUB_MODULE_ASIN) {
        return (
          <PtExAsinResult
            ptExData={exData}
            newAsinOnly={newAsinOnly}
            onChangeNewAsinOnly={handleChangeNewAsinOnly}
            onChangeDate={handlePtDateChange}
            onApplyFilter={(values) => { doFindPts(activeTypeTarget, newAsinOnly, currentStartDate, currentEndDate, values) }}
          />
        )
      }
      return (
        <PtExCategoryResult
          ptExData={exData}
          onChangeDate={handlePtDateChange}
          onApplyFilter={(values) => { doFindPts(activeTypeTarget, newAsinOnly, currentStartDate, currentEndDate, values) }}
        />
      )
    }
    return null
  }

  let dropdownLabel = 'Choose Module'
  if (activeModule) {
    const active = moduleList.find(module => module.key === activeModule)
    if (active) {
      dropdownLabel = active.name
    }
  }

  return (
    <div className={`bulk-engine-container bulk-expansion-container${(isLoading || isLoadingCampaigns) ? ' loading' : ''}`}>
      { (isLoading || isLoadingCampaigns) && <LoaderComponent /> }
      <div className="module-description">
        Choose Module, Select Campaigns, Refine Filters and Go!
      </div>
      { renderModuleSelector(dropdownLabel) }
      { renderCampaignSelection() }
      <CampaignTableComponent
        className={hasResults && !isCampaignsExpanded ? 'hidden' : ''}
        fromBulkEngine
        showNotedCampaigns={showNotedCampaigns}
        initialRender={initialRender}
        onLoadStart={() => setIsLoadingCampaigns(true)}
        onLoadEnd={() => setIsLoadingCampaigns(false)}
      />
      { renderResults() }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={dropdownLabel}
            onApply={handleFilterApply}
            onClose={() => { setCurrentFilterName('') }}
            onValidate={handleFilterValidate}
          />
        )
      }
    </div>
  )
}

export default BulkExpansion
