import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import StreamView from '../../components/CommonComponents/StreamView'

import { changeViewWidgetState } from '../../redux/actions/pageGlobal'

const ReportStreamViewWidget = ({ widget, isLoading, customizing, streams, section, onRemove }) => {
  const dispatch = useDispatch()

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  const renderHeader = () => (
    <div className="report-widget-header">
      <div className="widget-title">
        { widget.title }
      </div>
      <div className="widget-action">
        {
          customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={widget.view ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )
        }
      </div>
    </div>
  )

  const renderContents = () => {
    return (
      <div className="report-widget-content stream-content">
        <StreamView isLoading={isLoading} streams={streams} noDatePicker />
      </div>
    )
  }

  return (
    <div className="report-widget">
      { renderHeader() }
      { renderContents() }
    </div>
  )
}

export default ReportStreamViewWidget
