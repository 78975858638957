import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FiDownload, FiColumns } from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import PaginationComponent from '../CommonComponents/PaginationComponent'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import DateRangeComponent from '../CommonComponents/DateRangeComponent'
import ColumnEditor from '../CommonComponents/ColumnEditor'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import SearchBox from '../CommonComponents/SearchBox'

import { setDateRange } from '../../redux/actions/header'
import {
  showColumnEditorAction,
  selectColumns,
} from '../../redux/actions/pageGlobal'

const DEFAULT_PAGE_SIZE = 10

const PlacementOpResultTable = (props) => {
  const {
    defaultSort,
    sorterChild,
    records,
    selectedRecords = [],
    idField,
    idFieldChild,
    searchFields,
    // Unique ID to differentiate tables.
    columnEditorId,
    columns,
    columnsChild,
    columnList,
    columnSelection,
    isLoading = false,
    exportFileName = '',
    // Callback to get exportable data for a record.
    getExportData,
    renderRecord,
    renderChild,
    // Callback to render aggregation row.
    renderTotalChild,
    renderTopRight,
    onChange = () => {},
    onChangeDate = null,
    children,
  } = props

  const dispatch = useDispatch()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleColumnEditorId = useSelector(state => state.pageGlobal.visibleColumnEditorId)

  const [keyword, setKeyword] = useState('')
  const [filteredRecords, setFilteredRecords] = useState(records)
  const [pageStart, setPageStart] = useState(0)
  const [pageEnd, setPageEnd] = useState(DEFAULT_PAGE_SIZE)
  const [stickyOffset, setStickyOffset] = useState(null)
  const [expansionState, setExpansionState] = useState({})
  // Whether a `lifetime` range option is selected.
  const [lifetimeSelected, setLifetimeSelected] = useState(false)

  const refBody = useRef(null)
  const refHeader = useRef(null)
  const refAction = useRef(null)

  useEffect(() => {
    doFilter()
  }, [records]) // eslint-disable-line

  // Attach a scroll event listener.
  useEffect(() => {
    const mainContent = document.querySelector('.main-content')
    mainContent.addEventListener('scroll', handleScroll)

    return () => {
      mainContent.removeEventListener('scroll', handleScroll)
    }
  }, []) // eslint-disable-line

  // Adjust positions of sticky header/footer.
  useEffect(() => {
    if (stickyOffset === null) {
      return
    }
    refAction.current.style.top = `${stickyOffset}px`
    const offset = refAction.current.clientHeight
    refHeader.current.style.top = `${stickyOffset + offset}px`
  }, [stickyOffset]) // eslint-disable-line

  // Listen for scroll event on main contents area.
  const handleScroll = () => {
    if (refBody.current) {
      const { top } = refBody.current.getBoundingClientRect()
      if (top < 0) {
        setStickyOffset(-top)
        return
      }
    }
    setStickyOffset(null)
  }

  // Filter records.
  const doFilter = () => {
    let recordsToHandle = []
    if (keyword === '') {
      recordsToHandle = records
    } else {
      const lowerCased = keyword.toLowerCase()
      recordsToHandle = records.filter(record => (
        searchFields.find(field => (
          record[field].toLowerCase().indexOf(lowerCased) !== -1
        ))
      ))
    }
    setFilteredRecords(recordsToHandle)
  }

  const handleKeywordPress = (event) => {
    if (event.key === 'Enter') {
      doFilter()

      // Un-check when searching.
      onChange([])
    }
  }

  const handleRangeChange = ([startDate, endDate]) => {
    if (startDate !== null && endDate !== null) {
      dispatch(setDateRange({
        startDate,
        endDate,
      }))
      setLifetimeSelected(false)
    } else {
      setLifetimeSelected(true)
    }

    onChangeDate(startDate, endDate)
  }

  const handleShowColumnEditor = () => {
    dispatch(showColumnEditorAction(columnEditorId))
  }

  // Callback for ColumnEditor.
  const handleColumnChange = (selectedColumns) => {
    return selectColumns(selectedColumns, columnEditorId)
  }

  // Check if all records on page are selected.
  const checkIfAllSelected = () => {
    if (!selectedRecords.length) {
      return false
    }

    const recordsOnPage = filteredRecords.slice(pageStart, pageEnd)

    return typeof recordsOnPage.find(record => (
      selectedRecords.indexOf(record[idField]) === -1
    )) === 'undefined'
  }

  // When a select-all-on-page checkbox is clicked.
  const handleCheckAllOnPage = (checked) => {
    if (checked) {
      onChange(filteredRecords.slice(pageStart, pageEnd).map(record => record[idField]))
    } else {
      const filteredIds = filteredRecords.map(record => record[idField])
      const newList = selectedRecords.filter(record => !filteredIds.includes(record))
      onChange(newList)
    }
  }

  // When a record is selected/deselected.
  const handleCheck = record => (checked) => {
    const newList = [...selectedRecords]
    if (checked) {
      newList.push(record[idField])
    } else {
      newList.splice(newList.indexOf(record[idField]), 1)
    }
    onChange(newList)
  }

  // Expand/collapse parent records.
  const handleExpand = (record) => {
    setExpansionState(Object.assign({}, expansionState, {
      [record[idField]]: expansionState[record[idField]] === true ? false : true,
    }))
  }

  const loadData = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStart((pageNum - 1) * pageRows)
      setPageEnd(pageNum * pageRows)
    } else {
      setPageStart(0)
      setPageEnd(filteredRecords.length)
    }
  }

  const renderAction = () => {
    let tableActionClass = 'table-header'
    if (stickyOffset !== null) {
      tableActionClass = `${tableActionClass} sticky`
    }

    return (
      <div className={tableActionClass} ref={refAction}>
        <div className="table-header-left">
          <SearchBox
            keyword={keyword}
            onChange={setKeyword}
            onKeyPress={handleKeywordPress}
          />
        </div>
        <div className="table-header-right">
          { renderTopRight() }
          <DateRangeComponent
            placement="bottomEnd"
            value={!lifetimeSelected ? [currentStartDate, currentEndDate] : [null, null]}
            hasLifetimeRange
            onChange={handleRangeChange}
          />
          <CSVLink
            data={csvToExport}
            filename={`${exportFileName}.csv`}
            className="export-link"
            title="Export to CSV"
          >
            <FiDownload size={16} />
          </CSVLink>
          <FiColumns
            title="Column Customizer"
            color="#979797"
            onClick={handleShowColumnEditor}
          />
        </div>
      </div>
    )
  }

  const renderRecords = () => {
    if (!filteredRecords.length) {
      return (
        <div className="table-row">
          <div className="table-col">
            No records found.
          </div>
        </div>
      )
    }

    return filteredRecords.slice(pageStart, pageEnd).map((record, index) => {
      const checked = selectedRecords.indexOf(record[idField]) !== -1
      const isExpanded = expansionState[record[idField]] === true
      return (
        <Fragment key={record[idField]}>
          <div
            className={`table-row${record.className ? ` ${record.className}` : ''}`}
            onClick={() => { handleExpand(record) }}
          >
            <div
              className="table-col col-check"
              onClick={(event) => { event.stopPropagation() }}
            >
              <CheckboxComponent
                checked={checked}
                onChange={handleCheck(record)}
              />
            </div>
            <div className="table-col col-expand">
              <span className="expand-icon" title={isExpanded ? 'Collapse' : 'Expand'}>
                { isExpanded ? '-' : '+' }
              </span>
            </div>
            { renderRecord(record, false, pageStart + index) }
          </div>
          {
            isExpanded && (
              <div className="child-table-wrapper">
                <SortableTable
                  defaultSort={defaultSort}
                  sorter={sorterChild}
                  records={record.children}
                  idField={idFieldChild}
                  noPagination
                  noCheckBox
                  noHeaderCheckBox
                  noSearch
                  columns={columnsChild}
                  columnList={columnList}
                  columnSelection={columnSelection}
                  renderRecord={renderChild}
                  renderTotal={renderTotalChild}
                />
              </div>
            )
          }
        </Fragment>
      )
    })
  }

  // Prepare data to export.
  const csvToExport = useMemo(() => {
    let dataToExport = []

    // Add row for headings.
    const exportableColumns = []
    columns.concat(columnsChild).forEach((column) => {
      if (column.exportable === false) {
        return
      }

      const found = columnList.find(c => c.key === column.key)
      if (found && !columnSelection.find(c => c.key === column.key)) {
        return
      }

      exportableColumns.push(column)
    })
    dataToExport.push(exportableColumns.map(column => column.plainName || column.name || column.label))

    filteredRecords.forEach((record) => {
      dataToExport = dataToExport.concat(
        record.children.map(child => getExportData(exportableColumns, child))
      )
    })

    return dataToExport
  }, [columns, columnsChild, columnList, columnSelection, filteredRecords, getExportData])

  // Prepare CSS classes for table body.
  let tableBodyClass = 'table-body'
  tableBodyClass = `${tableBodyClass} has-action`
  if (stickyOffset !== null) {
    tableBodyClass = `${tableBodyClass} sticky`
  }

  return (
    <div className={`custom-table-component table-placements ${isLoading ? 'loading' : ''}`}>
      { isLoading && <LoaderComponent /> }
      { renderAction() }
      <div className={tableBodyClass} ref={refBody}>
        <div className="table-row content-header" ref={refHeader}>
          <div className="table-col col-check">
            <CheckboxComponent
              checked={checkIfAllSelected()}
              onChange={handleCheckAllOnPage}
            />
          </div>
          <div className="table-col col-expand" />
          { children }
        </div>
        { renderRecords() }
      </div>
      <PaginationComponent
        total={filteredRecords.length}
        loadData={loadData}
      />
      {
        showColumnEditor && visibleColumnEditorId === columnEditorId &&
        <ColumnEditor
          columnList={columnList}
          currentSelection={columnSelection}
          noReset
          onApply={handleColumnChange}
        />
      }
    </div>
  )
}

export default PlacementOpResultTable
