import { AMAZON_TEST_ACCOUNT } from '../config/api'

const AMAZON_SCOPE_LIST = [
  'profile',
  'profile:user_id',
  'cpc_advertising:campaign_management',
]

export const getAmazonScopeList = () => {
  if (!AMAZON_TEST_ACCOUNT) {
    return AMAZON_SCOPE_LIST
  }
  return [
    ...AMAZON_SCOPE_LIST,
    'advertising::test:create_account'
  ]
}
