// Smart pilot template editor.
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useAuth0 } from '@auth0/auth0-react'
import { formatISO } from 'date-fns'

import { hideRuleManager } from '../../../redux/actions/ruleManager'
import {
  getSpTemplate,
  saveSpTemplate,
  updateSpTemplate,
  deleteSpTemplate,
} from '../../../redux/actions/ap'
import {
  getDefaultAPSettings,
  apSettings as apSettingsTemplate,
  parseAPSettings,
  ignoreOutsideClick,
} from '../../../services/helper'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import Header from './Header'
import Footer from './Footer'
import OpSection from './OpSection'

const TemplateEditorComponent = () => {
  const dispatch = useDispatch()

  const templateToEdit = useSelector(state => state.ruleManager.templateToEdit)

  const { getAccessTokenSilently } = useAuth0()

  // Current template settings.
  const [name, setName] = useState('')
  const [settings, setSettings] = useState(getDefaultAPSettings())

  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState(null)

  // Update local state with loaded settings.
  useEffect(() => {
    if (!templateToEdit) {
      setName('')
      setSettings(getDefaultAPSettings())

      return
    }

    // It means the template ID is passed, not the whole object,
    // so we need to retrieve template details.
    if (typeof templateToEdit !== 'object') {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsSaving(true)
        await dispatch(getSpTemplate(accessToken, templateToEdit))
        setIsSaving(false)
      })()
      return
    }

    setName(templateToEdit.name)
    setSettings(parseAPSettings(templateToEdit))
  }, [templateToEdit]) // eslint-disable-line

  // Change a setting value.
  const onChange = (name, value) => {
    setSettings(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  // Hide template editor.
  const onClose = () => {
    dispatch(hideRuleManager())
  }

  const onOutsideClick = (event) => {
    if (!ignoreOutsideClick(event)) {
      onClose()
    }
  }

  // Sanitize settings before saving.
  const _sanitizeSettings = () => {
    setSaveError(null)

    if (parseFloat(settings['op_adv_genius_bid_min_acos'])
      > parseFloat(settings['op_adv_genius_bid_max_acos'])) {
      setSaveError('Please enter a valid ACoS range for Unprofitable Targets.')
      return null
    }

    if (parseFloat(settings['copy_op_adv_genius_bid_min_acos'])
      > parseFloat(settings['copy_op_adv_genius_bid_max_acos'])) {
      setSaveError('Please enter a valid ACoS range for Unprofitable Targets.')
      return null
    }

    const payload = Object.assign({}, settings, {
      template_id: templateToEdit ? templateToEdit.id : null,
      name,
    })

    Object.keys(apSettingsTemplate).forEach((setting) => {
      if (typeof settings[setting] === 'undefined') {
        return
      }

      if (apSettingsTemplate[setting].type === 'json_array') {
        // TODO: Do we need to escape values to be query-safe?
        // `filter` method here removes NULL values.
        payload[setting] = JSON.stringify(settings[setting].filter(s => s === 0 || s))
      } else if (apSettingsTemplate[setting].type === 'concat') {
        payload[setting] = settings[setting].join(',')
      } else if (apSettingsTemplate[setting].type === 'date') {
        payload[setting] = settings[setting]
          ? formatISO(settings[setting]) : settings[setting]
      } else if (apSettingsTemplate[setting].type === 'int') {
        const value = parseInt(settings[setting], 10)
        if (!isNaN(value)) {
          payload[setting] = value
        } else {
          payload[setting] = apSettingsTemplate[setting].default || 0
        }
      }
    })

    return payload
  }

  // Save smart pilot template settings.
  const onSave = async () => {
    const payload = _sanitizeSettings()
    if (payload) {
      const accessToken = await getAccessTokenSilently()
      if (templateToEdit) {
        setIsSaving(true)
        await dispatch(updateSpTemplate(accessToken, payload))
        setIsSaving(false)
      } else {
        if (name === '') {
          setSaveError('Please enter a template name.')
          return
        }

        setIsSaving(true)
        await dispatch(saveSpTemplate(accessToken, name, false, payload))
        setIsSaving(false)
      }
    }
  }

  const onSaveAsAnother = async () => {
    if (templateToEdit && templateToEdit.name === name) {
      setSaveError('Please enter a name different than the original one.')
      return
    }

    const payload = _sanitizeSettings()
    if (payload) {
      const accessToken = await getAccessTokenSilently()
      setIsSaving(true)
      await dispatch(saveSpTemplate(accessToken, name, false, payload))
      setIsSaving(false)
    }
  }

  const onDelete = async () => {
    const accessToken = await getAccessTokenSilently()
    setIsSaving(true)
    await dispatch(deleteSpTemplate(accessToken, templateToEdit.id))
    setIsSaving(false)
    onClose()
  }

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className="template-editor-component">
        { isSaving && <LoaderComponent /> }
        <Header
          name={name}
          onChangeName={setName}
          onClose={onClose}
        />
        <div className="pane-body">
          <OpSection
            settings={settings}
            onChange={onChange}
          />
        </div>
        <Footer
          isSaving={isSaving}
          isEditable={templateToEdit !== null}
          saveError={saveError}
          onSave={onSave}
          onSaveAsAnother={onSaveAsAnother}
          onDelete={onDelete}
          onClose={onClose}
        />
      </div>
    </OutsideClickHandler>
  )
}

export default TemplateEditorComponent
