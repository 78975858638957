/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Modal } from 'rsuite'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FiInfo } from 'react-icons/fi'

import {
  SP_BETA,
  SP_REDIRECT_URI,
  MARGINS_URL,
  LOGIN_CLIENT_ID,
  STRIPE_PUB_KEY,
  AUDIT_ORCHESTRATOR_URL,
} from '../../config/api'

import { signupAdsCode, signup, getStripeSecret } from '../../redux/actions/auth'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
// import CheckboxComponent from '../../components/CommonComponents/CheckboxComponent'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import AmazonSection from './AmazonSection'
import BillingSection from './BillingSection'

import {
  regionList,
  STORAGE_KEY_STATE,
  STORAGE_KEY_REGION,
  STORAGE_KEY_ACCOUNT_TYPE,
} from '../../utils/defaultValues'

const STEP_ACCOUNT = 'STEP_ACCOUNT'
const STEP_PAYMENT = 'STEP_PAYMENT'

const SignupComplete = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const signupBasicInfo = useSelector(state => state.auth.signupBasicInfo)
  const isADCodeGetting = useSelector(state => state.auth.isADCodeGetting)
  const adRefreshToken = useSelector(state => state.auth.adRefreshToken)
  const profileList = useSelector(state => state.auth.profileList)

  const [step, steStep] = useState(STEP_ACCOUNT)
  const [region, setRegion] = useState(regionList[0])
  const [selectedProfileIds, setSelectedProfileIds] = useState([])
  const [billing, setBilling] = useState({
    firstName: signupBasicInfo ? signupBasicInfo.firstName : '',
    lastName: signupBasicInfo ? signupBasicInfo.lastName : '',
  })
  const [isBillingComplete, setIsBillingComplete] = useState(false)
  const [coupon, setCoupon] = useState('')
  // const [buyUpsell, setBuyUpsell] = useState(false)
  const [isSigning, setIsSigning] = useState(false)

  // Inject Amazon LWA script.
  useEffect(() => {
    let amazonRoot = document.getElementById('amazon-root')
    if (!amazonRoot) {
      amazonRoot = document.createElement('div')
      amazonRoot.setAttribute('id', 'amazon-root')
      document.body.appendChild(amazonRoot)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        window.onAmazonLoginReady = function() {
          amazon.Login.setClientId('${LOGIN_CLIENT_ID}');
        };
        (function(d) {
          var a = d.createElement('script'); a.type = 'text/javascript';
          a.async = true; a.id = 'amazon-login-sdk';
          a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
          d.getElementById('amazon-root').appendChild(a);
        })(document);
      `
      document.body.appendChild(script)
    }

    const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
    if (savedRegion) {
      const saved = regionList.find(option => option.value === savedRegion)
      if (saved) {
        setRegion(saved)
      }
    }
  }, [])

  // Respond to Amazon returns.
  useEffect(() => {
    const qs = new URLSearchParams(location.search)
    const callbackUrl = qs.get('amazon_callback_uri')
    const spCode = qs.get('spapi_oauth_code')
    const code = qs.get('code')

    if (callbackUrl) {
      // After authorizing our app, users are redirected to another page
      // to get the LWA (Login with Amazon) code.
      const amazonState = qs.get('amazon_state')
      const state = (new Date()).valueOf()
      // Save state to sessionStorage for later validation.
      window.sessionStorage.setItem(STORAGE_KEY_STATE, state)

      let url = `${callbackUrl}?redirect_uri=${encodeURI(SP_REDIRECT_URI)}`
        + `&amazon_state=${amazonState}&state=${state}`
      if (SP_BETA) {
        url = `${url}&version=beta`
      }
      window.location.href = url
    } else if (spCode) {
      let state = qs.get('state')

      if (state === 'reauth_sp') {
        // This is for Settings page to add accounts.
        history.push({
          pathname: '/settings',
          search: location.search,
        })
      } else if (state === 'fix-sp') {
        // This is for fixing invalid SP API tokens.
        history.push({
          pathname: '/dashboard',
          search: location.search,
        })
      } else if (state === 'marginsmigration') {
        // This is for MWS migration in Margins.
        window.location.href = `${MARGINS_URL}${location.search}`
      } else if ((state || '').indexOf('marginsmanager') === 0) {
        // This is for account manager in Margins.
        const accountGroupId = state.substring(14)
        window.location.href = `${MARGINS_URL}/account-manager${location.search}&account_group_id=${accountGroupId}`
      }
    } else if (code) {
      let state = qs.get('state')
      const savedState = window.sessionStorage.getItem(STORAGE_KEY_STATE)
      if (state === savedState && !isADCodeGetting) {
        window.sessionStorage.removeItem(STORAGE_KEY_STATE)

        const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
        dispatch(signupAdsCode(code, SP_REDIRECT_URI, savedRegion || region.value)).then(() => {
          if (state.indexOf('audit-') === 0) {
            history.push('/signup-audit-complete')
          } else if (state.indexOf('special-') === 0) {
            history.push('/amz-webinar-signup-complete')
          }
        }).catch((error) => {
          toast.show({
            title: 'Danger',
            description: error,
          })
        })
      }
    } else if (signupBasicInfo === null) {
      history.push('/signup')
    }
  }, []) // eslint-disable-line

  const completeSignup = (billingFirstName, billingLastName, stripeToken = null) => {
    setIsSigning(true)

    const profiles = []
    profileList.forEach((profile) => {
      if (selectedProfileIds.indexOf(profile.profileId) === -1) {
        return
      }
      profiles.push({
        profileId: profile.profileId,
        countryCode: profile.countryCode.toLowerCase(),
        sellerStringId: profile.accountInfo.sellerStringId,
        brandEntityId: profile.accountInfo.brandEntityId,
        brandName: profile.accountInfo.brandName,
        type: profile.accountInfo.type.toLowerCase(),
        subType: (profile.accountInfo.subType || '').toLowerCase(),
      })
    })

    if (!profiles.length) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: 'Please select accounts.',
      })
      return
    }

    window.sessionStorage.removeItem(STORAGE_KEY_REGION)

    const accountType = window.sessionStorage.getItem(STORAGE_KEY_ACCOUNT_TYPE)

    dispatch(signup({
      firstName: signupBasicInfo.firstName,
      lastName: signupBasicInfo.lastName,
      email: signupBasicInfo.email,
      couponCode: coupon,
      adRefreshToken,
      billingFirstName: billingFirstName,
      billingLastName: billingLastName,
      stripeToken,
      buyUpsell: false,
      profiles,
      isAgency: accountType === 'agency',
    })).then(() => {
      window.gtag('event', 'signup_complete')
      setIsSigning(false)

      if (signupBasicInfo && signupBasicInfo.isAuditFlow) {
        window.location.href = `${AUDIT_ORCHESTRATOR_URL}/?tool-id=PPC_ENTOURAGE&completed-audit=true`
      } else {
        history.push('/tutorial')
      }
    }).catch((error) => {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const handleRegionChange = (option) => {
    setRegion(option)
    window.sessionStorage.setItem(STORAGE_KEY_REGION, option.value)
  }

  const handleBillingChange = (name, value) => {
    setBilling(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleBack = (event) => {
    event.preventDefault()
    steStep(STEP_ACCOUNT)
  }

  const handleContinue = () => {
    if (signupBasicInfo && signupBasicInfo.isAuditFlow) {
      completeSignup(signupBasicInfo.firstName, signupBasicInfo.lastName)
    } else {
      steStep(STEP_PAYMENT)
    }
  }

  const handleComplete = async () => {
    if (!stripe || !elements || isSigning || !isBillingComplete) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    setIsSigning(true)
    const { token, error } = await stripe.createToken(cardElement, {
      name: `${billing.firstName} ${billing.lastName}`,
    })

    if (!token) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: error.message,
      })
      return
    }

    const secret = await dispatch(getStripeSecret())

    const result = await stripe.confirmCardPayment(secret.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result && result.error) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: result.error.message,
      })
      return
    }

    completeSignup(billing.firstName, billing.lastName, token.id)
  }

  const renderContents = () => {
    if (step === STEP_ACCOUNT) {
      return (
        <>
          <div className="modal-description">
            <FiInfo size={16} color="#303AB2" />
            Trusted by thousands of sellers. Cancel anytime.
          </div>
          <AmazonSection
            isLoading={isADCodeGetting}
            adRefreshToken={adRefreshToken}
            region={region}
            regionList={regionList}
            profileList={profileList}
            selectedProfileIds={selectedProfileIds}
            isAuditFlow={signupBasicInfo && signupBasicInfo.isAuditFlow}
            onChange={handleRegionChange}
            onSelectProfileIds={setSelectedProfileIds}
          />
        </>
      )
    }

    const accountType = window.sessionStorage.getItem(STORAGE_KEY_ACCOUNT_TYPE)

    return (
      <>
        <BillingSection
          billing={billing}
          onChange={handleBillingChange}
          onComplete={() => { setIsBillingComplete(true) }}
        />
        {
          accountType === 'agency' ? (
            <div className="modal-description">
              <FiInfo size={16} color="#303AB2" />
              <span>
                You’ll be charged <strong>1.75%</strong> of monthly Ad spend
                across connected accounts after free trial.
              </span>
            </div>
          ) : (
            <>
              <div className="coupon-container">
                <label>
                  Coupon code (optional)
                </label>
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  value={coupon}
                  onChange={(event) => { setCoupon(event.target.value) }}
                />
              </div>
              <div className="modal-description">
                <FiInfo size={96} color="#303AB2" />
                <span>
                  At the end of your 7 day free trial, you'll be charged <strong>2.9%</strong> of ad spend based on the last 30 days of data
                  to keep your account active for the next month. Your 7 days are completely free,
                  and if you cancel before the end of your trial,
                  you will not be charged. Monthly billing is automatic.
                  During your trial, you may see a small temporary verification charge used by our credit card processor
                  to verify your method of payment.
                </span>
              </div>
            </>
          )
        }
        {/*<div className="upsell-section">*/}
        {/*  <CheckboxComponent*/}
        {/*    label="YES, Add One Time Coaching Offer for $97"*/}
        {/*    checked={buyUpsell}*/}
        {/*    onChange={setBuyUpsell}*/}
        {/*  />*/}
        {/*  <div className="upsell-contents">*/}
        {/*    <div className="upsell-header">*/}
        {/*      One Time Offer*/}
        {/*    </div>*/}
        {/*    <div className="upsell-description">*/}
        {/*      Take advantage of our huge one-time discount for new Entourage members*/}
        {/*      and become a part of our advanced coaching program for only&nbsp;*/}
        {/*      <span className="upsell-price">$97 (normal price $197).</span>*/}
        {/*    </div>*/}
        {/*    <div className="upsell-warning">*/}
        {/*      After you leave this page, you will never see this offer again.*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    )
  }

  const renderFooter = () => {
    if (step === STEP_ACCOUNT) {
      if (!adRefreshToken) {
        return null
      }

      return (
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-blue"
            disabled={!selectedProfileIds.length || isSigning}
            onClick={handleContinue}
          >
            Continue
          </button>
        </Modal.Footer>
      )
    }

    const isFilled = billing.firstName && billing.lastName

    return (
      <Modal.Footer className="text-center">
        <button
          type="button"
          className="btn btn-blue btn-signup"
          disabled={!isFilled || isSigning || !isBillingComplete}
          onClick={handleComplete}
        >
          Start free trial now
        </button>
        <a
          href="#"
          onClick={handleBack}
        >
          Go Back
        </a>
      </Modal.Footer>
    )
  }

  return (
    <div className="signup-complete-page">
      <Modal backdrop="static" show size="sm" className="signup-complete-modal">
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {
              step === STEP_ACCOUNT ? 'Choose Your Amazon Accounts' : 'Checkout'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${adRefreshToken ? '' : 'overflow-none'}`}>
          { renderContents() }
        </Modal.Body>
        { isSigning && <LoaderComponent /> }
        { renderFooter() }
      </Modal>
    </div>
  )
}

const WrapperWithStripe = (props) => {
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUB_KEY))
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <SignupComplete {...props} />
    </Elements>
  )
}

export default WrapperWithStripe
