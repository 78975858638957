import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import LoomVideoCollection from './LoomVideoCollection'

const topAttachments = [
  { name: 'Entourage 2.0 Playbook.pdf', url: 'https://go.ppcentourage.com/hubfs/Amazon%20Ads%20Playbook%20Series%20V2%202021.pdf?hsLang=en' },
]

const attachments = [
  { name: 'Launch Keyword Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=81687217' },
  { name: 'Audience Finder Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=1641638220' },
]

const videoList = [
  { name: 'SP1 - Keyword Launch', videoId: 'ab057c34404148cca65c8f1063bc6835' },
  { name: 'SP2 - ASIN Launch', videoId: 'f5431792421443fa9bf6c9cd1efaf7fa' },
  { name: 'SP3 - Auto Targeting', videoId: '96de30c19fcd4127a0d4995d5226681a' },
  { name: 'SP4 - Auto Low', videoId: 'fd472928d93d4e22babe3e9aed41a563' },
  { name: 'SP5 - Keyword Discovery', videoId: '6aa982550c0b43f98b369bf50b7c2ca5' },
  { name: 'SP6 - Category Discovery', videoId: 'caeef8a5b1b34ac1a1bf315b2e892fae' },
  { name: 'SP8 - Keyword ACoS Scraping', videoId: 'de95b94448dc41878402c9b5d7098d8b' },
  { name: 'SP9 - ASIN ACoS Scraping', videoId: '39824640978b472d9556a919f6e135f1' },
  { name: 'SP10 - Refined Category Targeting', videoId: 'a188664ab4dd417b97ce34ee02150bf9' },
  { name: 'SP11 - Keyword Scale', videoId: 'c6180e5f610c4058adba740b0ffc9ec2' },
  { name: 'SP12 - ASIN Scale', videoId: 'c6180e5f610c4058adba740b0ffc9ec2' },
  { name: 'SP 13 - Brand Protection', videoId: 'ad898cb06b4a485da076c7ff95d7d133' },
  { name: 'SP 14- ASIN Protection', videoId: 'afd100bdab744a28accb3805473f15fb' },
]

const SPAds = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Sponsored Product Ads"
      {...props}
    >
      <AttachmentList attachments={topAttachments} />
      <AttachmentList attachments={attachments} />
      <LoomVideoCollection videoList={videoList} />
    </FlowContainer>
  )
}

export default SPAds
