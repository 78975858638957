import React from 'react'
import { useSelector } from 'react-redux'

import HeaderComponent from '../../components/HeaderComponent'
import SideMenuComponent from '../../components/SideMenuComponent'
import CallComponent from '../../components/HeaderComponent/CallComponent'
import TokenErrorComponent from '../../components/HeaderComponent/TokenErrorComponent'
import DataFetchComponent from '../../components/HeaderComponent/DataFetchComponent'
import CoinPaneComponent from '../../components/CoinPaneComponent'
import JobCheckerComponent from '../../components/HeaderComponent/JobCheckerComponent'

import SmartPilotManager from '../../components/APMComponent'
import SmartPilotTemplateEditor from '../../components/APMComponent/TemplateEditor'
import DaypartingManager from '../../components/DaypartingManager'
import DaypartingTemplateEditor from '../../components/DaypartingManager/TemplateEditor'
import WeekpartingManager from '../../components/WeekpartingManager'
import WeekpartingTemplateEditor from '../../components/WeekpartingManager/TemplateEditor'
import PerformanceManager from '../../components/PerformanceManager'
import PerformanceTemplateEditor from '../../components/PerformanceManager/TemplateEditor'
import RankManager from '../../components/RankManager'
import EventPlanningManager from '../../components/EventPlanningManager'
import EventPlanningTemplateEditor from '../../components/EventPlanningManager/TemplateEditor'
import InventoryManager from '../../components/InventoryManager'
import InventoryTemplateEditor from '../../components/InventoryManager/TemplateEditor'

import {
  RULE_TYPE_IR,
  RULE_TYPE_IR_TEMPLATE_EDITOR,
  RULE_TYPE_EP,
  RULE_TYPE_EP_TEMPLATE_EDITOR,
  RULE_TYPE_RR,
  RULE_TYPE_CP,
  RULE_TYPE_CP_TEMPLATE_EDITOR,
  RULE_TYPE_WP,
  RULE_TYPE_WP_TEMPLATE_EDITOR,
  RULE_TYPE_DP,
  RULE_TYPE_DP_TEMPLATE_EDITOR,
  RULE_TYPE_SP,
  RULE_TYPE_SP_TEMPLATE_EDITOR,
} from '../../utils/defaultValues.js'

const MainLayout = ({ children }) => {
  const maxTable = useSelector(state => state.dashboard.maxTable)
  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleFilterName = useSelector(state => state.pageGlobal.visibleFilterName)
  const showANP = useSelector(state => state.pageGlobal.showANP)
  const showAEP = useSelector(state => state.pageGlobal.showAEP)
  const showCoinPane = useSelector(state => state.pageGlobal.showCoinPane)
  const showCampaignNotePane = useSelector(state => state.pageGlobal.showCampaignNotePane)
  const visibleRuleManager = useSelector(state => state.ruleManager.visibleRuleManager)

  const renderRuleManager = () => {
    if (visibleRuleManager === RULE_TYPE_IR) {
      return <InventoryManager />
    }
    if (visibleRuleManager === RULE_TYPE_IR_TEMPLATE_EDITOR) {
      return <InventoryTemplateEditor />
    }
    if (visibleRuleManager === RULE_TYPE_EP) {
      return <EventPlanningManager />
    }
    if (visibleRuleManager === RULE_TYPE_EP_TEMPLATE_EDITOR) {
      return <EventPlanningTemplateEditor />
    }
    if (visibleRuleManager === RULE_TYPE_RR) {
      return <RankManager />
    }
    if (visibleRuleManager === RULE_TYPE_CP) {
      return <PerformanceManager />
    }
    if (visibleRuleManager === RULE_TYPE_CP_TEMPLATE_EDITOR) {
      return <PerformanceTemplateEditor />
    }
    if (visibleRuleManager === RULE_TYPE_WP) {
      return <WeekpartingManager />
    }
    if (visibleRuleManager === RULE_TYPE_WP_TEMPLATE_EDITOR) {
      return <WeekpartingTemplateEditor />
    }
    if (visibleRuleManager === RULE_TYPE_DP) {
      return <DaypartingManager />
    }
    if (visibleRuleManager === RULE_TYPE_DP_TEMPLATE_EDITOR) {
      return <DaypartingTemplateEditor />
    }
    if (visibleRuleManager === RULE_TYPE_SP) {
      return <SmartPilotManager />
    }
    if (visibleRuleManager === RULE_TYPE_SP_TEMPLATE_EDITOR) {
      return <SmartPilotTemplateEditor />
    }
    return null
  }

  const needOverlay = showColumnEditor
    || visibleFilterName !== ''
    || showANP
    || showAEP
    || showCoinPane
    || showCampaignNotePane
    || visibleRuleManager !== ''

  return (
    <div className="main-layout">
      { !maxTable && <SideMenuComponent /> }
      <div className="main-content">
        <HeaderComponent />
        <CallComponent />
        <TokenErrorComponent />
        <DataFetchComponent />
        <JobCheckerComponent />
        { children }
        { showCoinPane && <CoinPaneComponent /> }
        { renderRuleManager() }
      </div>
      { needOverlay && <div className="overlay"></div> }
    </div>
  )
}

export default MainLayout
