import React from 'react'

import Section from '../Section'
import OpKeyword from '../OpKeyword'
import OpNegative from './OpNegative'

const SUBSECTION_KEYWORD = 'keyword'
const SUBSECTION_NEGATIVE = 'negative'

const OpSection = (props) => {
  return (
    <Section>
      <OpKeyword
        id={SUBSECTION_KEYWORD}
        fromTemplateEditor
        {...props}
      />
      <OpNegative id={SUBSECTION_NEGATIVE} {...props} />
    </Section>
  )
}

export default OpSection
