import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import Select from 'react-select'
import { Icon, IconButton, List } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../LoaderComponent'
import { toast } from '../ToastComponent/toast'

import { hideColumnEditorAction } from '../../../redux/actions/pageGlobal'
import {
  getCampaignColumnSettings,
  createCampaignColumnSetting,
  updateCampaignColumnSetting,
  deleteCampaignColumnSetting,
} from '../../../redux/actions/columnSetting'

import { campaignColumnSetting, campaignColumnList } from '../../../utils/defaultValues'

const CampaignColumnEditor = ({ currentSelection, hideDailyBudget, isNonEndemicAccount, onApply }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [savedSettingList, setSavedSettingList] = useState([])
  const [selectedSetting, setSelectedSetting] = useState(null)
  const [preSetting, setPreSetting] = useState(null)
  const [columnList, setColumnList] = useState(currentSelection || [])
  const [isSaving, setIsSaving] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isLoadingSettingList, setIsLoadingSettingList] = useState(false)
  const [newSettingName, setNewSettingName] = useState('')

  const availableColumns = useMemo(() => {
    if (!isNonEndemicAccount) {
      return campaignColumnList
    }
    return campaignColumnList.filter(column => (
      ![
        'target_acos',
        'revenue',
        'acos',
        'orders',
        'conversion',
        'roas',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
        'video_views_25',
        'video_views_50',
        'video_views_75',
        'video_views_100',
        'video_5s_views',
        'view_5_seconds_rate',
      ].includes(column.key)
    ))
  }, [isNonEndemicAccount])

  useEffect(() => {
    if (preSetting && preSetting.setting.length) {
      setColumnList(preSetting.setting)
      setSelectedSetting(null)
    }
  }, [preSetting])

  useEffect(() => {
    if (selectedSetting && selectedSetting.setting.length) {
      setColumnList(selectedSetting.setting)
      setPreSetting(null)
    }
  }, [selectedSetting])

  const handleApply = () => {
    dispatch(onApply(columnList.filter(c =>
      !hideDailyBudget || c.key !== 'daily_budget')
    ))
  }

  const handleSortColumns = ({ oldIndex, newIndex }) => {
    const moveColumn = columnList.splice(oldIndex, 1)
    const newColumns = [...columnList]
    newColumns.splice(newIndex, 0, moveColumn[0])
    setColumnList([...newColumns])
  }

  const handleSelectColumn = (column) => {
    setColumnList(prev => ([...prev, column]))
  }

  const handleRemoveColumn = (column) => {
    setColumnList(p => p.filter(s => s.key !== column.key))
  }

  const handleFocusSavedSetting = async () => {
    if (!savedSettingList.length && !isLoadingSettingList) {
      setIsLoadingSettingList(true)
      const accessToken = await getAccessTokenSilently()
      const res = await dispatch(getCampaignColumnSettings(accessToken))
      setIsLoadingSettingList(false)
      setSavedSettingList(res.map(setting => ({
        value: setting.id,
        label: setting.name,
        ...setting
      })))
    }
  }

  const handleCreateSetting = async () => {
    if (!columnList.length) {
      toast.show({
        title: 'Warning',
        description: 'Please select at least one column.',
      })
      return
    }

    if (!newSettingName) {
      toast.show({
        title: 'Warning',
        description: 'Please fill out the Template name.',
      })
      return
    }
    setIsSaving(true)

    const accessToken = await getAccessTokenSilently()
    try {
      const res = await dispatch(createCampaignColumnSetting(
        accessToken,
        newSettingName,
        columnList
      ))
      const newSetting = {
        value: res.id,
        label: res.name,
        ...res,
      }
      setSavedSettingList([
        ...savedSettingList,
        newSetting,
      ])
      setSelectedSetting(newSetting)
      setIsSaving(false)
      setIsAdding(false)
      toast.show({
        title: 'Success',
        description: 'A new Template has been saved successfully.',
      })
    } catch (e) {
      setIsSaving(false)
    }
  }

  const handleUpdateSetting = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    try {
      await dispatch(updateCampaignColumnSetting(
        accessToken,
        selectedSetting.value,
        columnList,
      ))

      setSavedSettingList(p =>
        p.map(setting => setting.value === selectedSetting.value ?
          { ...setting, setting: columnList }
          : setting
        )
      )
      setIsSaving(false)
      toast.show({
        title: 'Success',
        description: 'The Template has been updated successfully.',
      })
    } catch {
      setIsSaving(false)
    }
  }

  const handleDeleteSetting = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteCampaignColumnSetting(accessToken, selectedSetting.value)).then(() => {
      setIsSaving(false)
      setSavedSettingList(p => p.filter(s => s.value !== selectedSetting.value))
      setSelectedSetting(null)
    }).catch(() => { setIsSaving(false) })
  }

  const renderColumnSettingSelectPanel = () => (
    <div className={`setting-item ${isSaving ? 'loading' : ''}`}>
      { isSaving && <LoaderComponent/> }
      <span>User-defined Template</span>
      <Select
        className="saved-template-select"
        placeholder="Select Saved Template"
        options={savedSettingList || []}
        value={selectedSetting}
        isLoading={isLoadingSettingList}
        onFocus={(handleFocusSavedSetting)}
        onChange={setSelectedSetting}
      />
      {
        isAdding ? (
          <div className="new-setting">
            <input
              type="text"
              placeholder="New Column Setting Name"
              value={newSettingName}
              onChange={(e) => { setNewSettingName(e.target.value) }}
            />
            <IconButton
              icon={<Icon icon="check" />}
              appearance="primary"
              title="Save Setting"
              onClick={handleCreateSetting}
              disabled={newSettingName === ''}
            />
            <IconButton
              icon={<Icon icon="close" />}
              title="Cancel"
              onClick={() => { setIsAdding(false) }}
            />
          </div>
        ) : (
          <div className="setting-select-action">
            <button
              type="button"
              className="btn btn-white"
              onClick={() => { setIsAdding(true) }}
              disabled={isAdding}>
              Add
            </button>
            <button
              type="button"
              className="btn btn-white"
              onClick={handleUpdateSetting}
              disabled={isAdding || !selectedSetting}>
              Update
            </button>
            <button
              type="button"
              className="btn btn-white"
              onClick={handleDeleteSetting}
              disabled={isAdding || !selectedSetting}>
              Delete
            </button>
          </div>
        )
      }
    </div>
  )

  const renderColumn = (column, index) => {
    if (
      !column.key
      ||
      (hideDailyBudget && column.key === 'daily_budget')
      ||
      (
        isNonEndemicAccount
        && [
          'target_acos',
          'revenue',
          'acos',
          'orders',
          'conversion',
          'roas',
          'ntb_orders',
          'ntb_orders_percent',
          'ntb_sales',
          'ntb_sales_percent',
          'video_views_25',
          'video_views_50',
          'video_views_75',
          'video_views_100',
          'video_5s_views',
          'view_5_seconds_rate',
        ].includes(column.key)
      )
    ) {
      return null
    }
    return (
      <List.Item
        key={column.key}
        className="column-item"
        index={index}
        disabled={column.key === 'campaign'}
        collection={column.fixed ? 0 : 1}
      >
        <Icon className="handle-column" icon="ellipsis-v" size="lg" />
        <span className="column-label">{column.label}</span>
        <Icon
          className={`remove-column ${column.key === 'campaign' ? 'invisible' : ''}`}
          icon="close"
          onClick={() => { handleRemoveColumn(column) }}
        />
      </List.Item>
    )
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => { dispatch(hideColumnEditorAction()) }}
    >
      <div className="column-editor">
        <div className="pane-header">
          <div className="pane-title">Table Columns Setting</div>
          <span className="close-icon" onClick={() => { dispatch(hideColumnEditorAction()) }}>
            &times;
          </span>
        </div>
        <div className="pane-body">
          <div className="column-sub-panel">
            <span className="sub-panel-title">Template</span>
            <div className="setting-item">
              <span>Pre-defined Template</span>
              <Select
                className="pre-defined-template-select"
                options={campaignColumnSetting}
                value={preSetting}
                placeholder="Select Pre-Defined Template"
                onChange={setPreSetting}
              />
            </div>
            { renderColumnSettingSelectPanel() }
          </div>
          <div className="column-sub-panel">
            <span className="sub-panel-title">Columns</span>
            <div className="setting-item">
              <span>Add Columns</span>
              <Select
                className="column-select"
                options={availableColumns.filter(item =>
                  !(hideDailyBudget && item.key === 'daily_budget') && !columnList.find(c => item.key === c.key)
                )}
                value={null}
                placeholder="Select Your Column"
                onChange={handleSelectColumn}
              />
            </div>
            <div className="column-item-list">
              <List sortable pressDelay={300} onSort={handleSortColumns}>
                { columnList.map(renderColumn) }
              </List>
            </div>
          </div>
        </div>
        <div className="pane-footer">
          <button type="button" className="btn btn-blue" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default CampaignColumnEditor
