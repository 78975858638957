import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'
import Select from 'react-select'
import { FiAlertTriangle, FiTrash2 } from 'react-icons/fi'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import DateRangeComponent from '../../components/CommonComponents/DateRangeComponent'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'
import PortfolioSelector from '../../components/CommonComponents/PortfolioSelector'
import EmptyCard from './EmptyCard'

import { setDateRange } from '../../redux/actions/header'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { formatValue, formatCurrency, calcDerivedMetrics } from '../../services/helper'
import { metricList } from '../../utils/defaultValues'

const METRIC_SETTING = 'DASHBOARD_METRICS'
const PORTFOLIO_METRIC_SETTING = 'DASHBOARD_PORTFOLIO_METRICS'
const METRIC_LIMIT = 8

const KpiList = ({ salesStats, stats, adTypes, currentAdType,
  hasSpCode, onChangeAdType, isLoading,
  currencySign, currencyRate, currentStartDate, currentEndDate,
  onChangePortfolios }) => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [cards, setCards] = useState([])
  const [isCustomView, setIsCustomView] = useState(false)

  useEffect(() => {
    loadSavedMetrics(currentAdType?.value === 'portfolio')
  }, [currentAdType])

  const [currentKpi, pastKpi] = useMemo(() => {
    if (!stats) {
      return [null, null]
    }
    return [
      calcDerivedMetrics(stats.kpi),
      calcDerivedMetrics(stats.past.kpi),
    ]
  }, [stats])

  const loadSavedMetrics = (forPortfolio = false) => {
    const savedMetrics = window.localStorage.getItem(
      forPortfolio ? PORTFOLIO_METRIC_SETTING : METRIC_SETTING
    )
    if (savedMetrics) {
      let metrics = []
      try {
        metrics = JSON.parse(savedMetrics)
      } catch (e) {
        // Shhh... keep silence.
      }
      const availableMetrics = Object.keys(metricList)
      setCards(metrics.filter(metric => availableMetrics.includes(metric)))
    } else {
      setCards(
        Object.keys(metricList).slice(
          // The first metric is organic revenue,
          // which is unavailable for portfolios.
          forPortfolio ? 1 : 0,
          METRIC_LIMIT,
        )
      )
    }
  }

  const calcMetricValue = (metric) => {
    let isBetter = true
    let value = 0
    switch (metric) {
      case 'organicRevenue':
        if (salesStats) {
          const tmpSales = salesStats.sales && salesStats.sales > salesStats.ppcRevenue
            ? salesStats.sales
            : salesStats.ppcRevenue
          const tmpPastSales = salesStats.past.sales && salesStats.past.sales > salesStats.past.ppcRevenue
            ? salesStats.past.sales
            : salesStats.past.ppcRevenue
          isBetter = (tmpSales - salesStats.ppcRevenue)
            >= (tmpPastSales - salesStats.past.ppcRevenue)
        }
        value = formatCurrency(
          salesStats && salesStats.sales > salesStats.ppcRevenue
            ? salesStats.sales - salesStats.ppcRevenue
            : 0,
          currencySign,
          currencyRate
        )
        break
      case 'revenue':
      case 'cost':
      case 'ntb_sales':
      case 'vcpm':
        if (currentKpi) {
          value = currentKpi[metric]
          isBetter = value >= pastKpi[metric]
        }
        value = formatCurrency(value, currencySign, currencyRate)
        break
      case 'impressions':
      case 'orders':
      case 'ntb_orders':
      case 'ntb_units':
      case 'viewable_impressions':
        if (currentKpi) {
          value = currentKpi[metric]
          isBetter = value >= pastKpi[metric]
        }
        value = formatValue(value, 'number', 0)
        break
      case 'clicks':
        let ctr = 0
        if (currentKpi) {
          ctr = currentKpi.ctr
          isBetter = ctr >= pastKpi.ctr
        }
        value = `${formatValue(stats ? stats.kpi.clicks : 0, 'number', 0)} / ${formatValue(ctr, 'percent', 2)}`
        break
      case 'conversion':
      case 'ntb_sales_percent':
      case 'vtr':
      case 'vctr':
        if (currentKpi) {
          value = currentKpi[metric]
          isBetter = value >= pastKpi[metric]
        }
        value = formatValue(value, 'percent')
        break
      case 'acos':
        if (currentKpi) {
          value = currentKpi[metric]
          isBetter = value >= pastKpi[metric]
        }
        value = formatValue(value, 'percent', 1)
        break
      case 'cpc':
      case 'roas':
        if (currentKpi) {
          value = currentKpi[metric]
          isBetter = value >= pastKpi[metric]
        }
        value = formatValue(value, 'number')
        break
      default:
        return ''
    }

    return (
      <>
        { value }
        {
          isBetter
          ? <BsCaretUpFill size={16} color="#2EB73C" />
          : <BsCaretDownFill size={16} color="#D93842" />
        }
      </>
    )
  }

  const handleCancelView = () => {
    loadSavedMetrics()
    setIsCustomView(false)
  }

  const handleSaveView = () => {
    const newCards = cards.filter(metric => metric)

    setCards(newCards)
    setIsCustomView(false)

    window.localStorage.setItem(
      currentAdType?.value === 'portfolio' ? PORTFOLIO_METRIC_SETTING : METRIC_SETTING,
      JSON.stringify(newCards),
    )
  }

  const handleMetricAdd = index => (metric) => {
    const newCards = [...cards]
    newCards[index] = metric
    setCards(newCards)
  }

  const handleEmptyMetricAdd = () => {
    setCards([
      ...cards,
      '',
    ])
  }

  const handleRemove = index => () => {
    const newCards = [...cards]
    newCards[index] = ''
    setCards(newCards)
  }

  const handleDateRangeChange = ([startDate, endDate]) => {
    dispatch(setDateRange({
      startDate,
      endDate,
    }))
  }

  const renderAdTypeSelector = () => {
    if (isNonEndemicAccount) {
      return null
    }

    if (currentAdType.value === 'portfolio') {
      return (
        <>
          <button
            type="button"
            className="btn btn-red"
            onClick={() => onChangeAdType(adTypes[0])}
          >
            Go Back to Menu
          </button>
          <PortfolioSelector
            onChange={onChangePortfolios}
          />
        </>
      )
    }

    return (
      <Select
        className="ad-type-selector"
        options={adTypes}
        value={currentAdType}
        onChange={onChangeAdType}
      />
    )
  }

  const renderCreatorDropdown = () => {
    if (isNonEndemicAccount) {
      return (
        <Dropdown
          title="+ New"
          placement="bottomEnd"
          toggleClassName="new-campaign-toggle"
        >
          <Dropdown.Item componentClass={Link} to="/campaigns/new/sd">
            Sponsored Display Campaign
          </Dropdown.Item>
        </Dropdown>
      )
    }

    return (
      <Dropdown
        title="+ New"
        placement="bottomEnd"
        toggleClassName="new-campaign-toggle"
      >
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sp">
          Sponsored Product Campaign
        </Dropdown.Item>
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sb">
          Sponsored Brand Campaign
        </Dropdown.Item>
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sd">
          Sponsored Display Campaign
        </Dropdown.Item>
      </Dropdown>
    )
  }

  const renderTooltip = (metric) => {
    if (isCustomView || typeof metricList[metric].tooltip === 'undefined') {
      return null
    }

    if (metric === 'organicRevenue' && !hasSpCode) {
      return (
        <CustomTooltip
          placement="bottom"
          icon={<FiAlertTriangle size={16} color="#ffd156" />}
        >
          <p>
            Please go to the Settings page to add the Selling Partner API connection.
          </p>
          <p>
            Without the Selling Partner API connection,
            Entourage will not show the organic revenue.
          </p>
        </CustomTooltip>
      )
    }

    return (
      <CustomTooltip placement="bottom">
        <span dangerouslySetInnerHTML={{
          __html: metricList[metric].tooltip,
        }} />
      </CustomTooltip>
    )
  }

  const renderFullCard = (metric, index) => (
    <div className="kpi-card-inner">
      <div className="metric-name">
        { metricList[metric].name }
        {
          isCustomView && (
            <FiTrash2
              className="trash-icon"
              title="Remove Metric"
              size={14}
              onClick={handleRemove(index)}
            />
          )
        }
        { renderTooltip(metric) }
      </div>
      <div className="metric-value">
        { calcMetricValue(metric) }
      </div>
    </div>
  )

  const renderCard = (metric, index) => {
    if (!isCustomView && !metric) {
      return null
    }

    if (isNonEndemicAccount
      && [
        'organicRevenue',
        'revenue',
        'acos',
        'orders',
        'conversion',
        'roas',
        'ntb_orders',
        'ntb_sales',
        'ntb_units',
        'ntb_sales_percent',
      ].includes(metric)) {
      return null
    }

    return (
      <div key={`${index}-${metric}`} className={`kpi-card${isLoading ? ' loading' : ''}`}>
        {
          metric
          ? renderFullCard(metric, index)
          : (
            <EmptyCard
              metricList={metricList}
              cards={cards}
              isNonEndemicAccount={isNonEndemicAccount}
              noOrganic={currentAdType.value === 'portfolio'}
              onChange={handleMetricAdd(index)}
            />
          )
        }
      </div>
    )
  }

  return (
    <>
      <div className="page-header">
        <div className="kpi-header-left">
          { renderAdTypeSelector() }
          <DateRangeComponent
            value={[currentStartDate, currentEndDate]}
            onChange={handleDateRangeChange}
          />
        </div>
        <div className="kpi-header-right">
          {
            isCustomView ? (
              <>
                <button type="button" className="kpi-button" onClick={handleCancelView}>
                  Cancel
                </button>
                <button type="button" className="kpi-button" onClick={handleSaveView}>
                  Save View
                </button>
              </>
            ) : (
              <button
                type="button"
                className="kpi-button"
                onClick={() => { setIsCustomView(true) }}
              >
                Customize View
              </button>
            )
          }
          { renderCreatorDropdown() }
        </div>
      </div>
      <div className={`kpi-container${isLoading ? ' loading' : ''}`}>
        { isLoading && <LoaderComponent /> }
        { cards.map(renderCard) }
        {
          isCustomView && (
            <div
              className="plus-icon-container"
              title="Add Metric"
              onClick={handleEmptyMetricAdd}
            >
              +
            </div>
        )}
      </div>
    </>
  )
}

export default KpiList
