import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal} from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { getNegativeKWData } from '../../redux/actions/campaignDetail'
import { NEGATIVE_MATCH_TYPE_PHRASE } from '../../utils/defaultValues'

const NegativeKeywordsModal = ({
  campaignId,
  campaignType,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNegativeKWLoading = useSelector(state => state.campaignDetail.isNegativeKWLoading)
  const negativeKWData = useSelector(state => state.campaignDetail.negativeKWData)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getNegativeKWData(accessToken, {
        campaignId,
        campaignType,
      }))
    })()
  }, [campaignId, campaignType, dispatch]) // eslint-disable-line

  const renderKeyword = (record) => (
    <>
      <div className="table-col">
        <strong>{record.keyword_text}</strong>
      </div>
      <div className="table-col">
        {
          record.match_type === NEGATIVE_MATCH_TYPE_PHRASE
          ? 'Negative Phrase'
          : 'Negative Exact'
        }
      </div>
      {
        campaignType === 'sp' && (
          <div className="table-col">
            { typeof record.campaign_level !== 'undefined' ? 'Campaign Level' : 'Ad Group Level' }
          </div>
        )
      }
    </>
  )

  return (
    <Modal className="negative-keywords-view-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Negative Keywords
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          className="table-keywords"
          isLoading={isNegativeKWLoading}
          records={negativeKWData || []}
          noCheckBox={true}
          idField="keyword_id"
          searchFields={['keyword_text']}
          renderRecord={renderKeyword}
        >
          <div className="table-col col-campaign">Keyword</div>
          <div className="table-col col-campaign">Match Type</div>
          {
            campaignType === 'sp' && (
              <div className="table-col col-campaign">Campaign/Ad Group Level</div>
            )
          }
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NegativeKeywordsModal
