import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

import { updateBillingInfo } from '../../redux/actions/auth'
import { countryList } from '../../utils/country'

const BillingInfoModal = ({ show, onHide }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleUpdate = async () => {
    if (!firstName) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your first name.',
      })
      return
    }

    if (!lastName) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your last name.',
      })
      return
    }

    if (!line1) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your address line 1.',
      })
      return
    }

    if (!city) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your city.',
      })
      return
    }

    if (!country) {
      toast.show({
        title: 'Danger',
        description: 'Please select your country.',
      })
      return
    }

    if (!state) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your state.',
      })
      return
    }

    setIsUpdating(true)

    const payload = {
      first_name: firstName,
      last_name: lastName,
      line1,
      line2,
      city,
      zip,
      country: country.value,
      state,
    }

    const accessToken = await getAccessTokenSilently()
    dispatch(updateBillingInfo(accessToken, payload)).then(() => {
      setIsUpdating(false)
      toast.show({
        title: 'Success',
        description: 'Billing information successfully updated.',
      })

      onHide(payload)
    }).catch((error) => {
      setIsUpdating(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to update billing information.',
      })
    })
  }

  return (
    <Modal className="settings-billing-modal" backdrop="static" show={show} size="md">
      <Modal.Header onHide={() => { onHide() }}>
        <Modal.Title>
          Update Billing Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isUpdating && <LoaderComponent /> }
        <div className="field-row">
          <div className="field-wrapper">
            <label>First Name <span className="required">*</span></label>
            <input
              type="text"
              value={firstName}
              onChange={(event) => { setFirstName(event.target.value) }}
            />
          </div>
          <div className="field-wrapper">
            <label>Last Name <span className="required">*</span></label>
            <input
              type="text"
              value={lastName}
              onChange={(event) => { setLastName(event.target.value) }}
            />
          </div>
        </div>
        <div className="field-row">
          <div className="field-wrapper">
            <label>Address Line 1 <span className="required">*</span></label>
            <input
              type="text"
              value={line1}
              onChange={(event) => { setLine1(event.target.value) }}
            />
          </div>
          <div className="field-wrapper">
            <label>Address Line 2</label>
            <input
              type="text"
              value={line2}
              onChange={(event) => { setLine2(event.target.value) }}
            />
          </div>
        </div>
        <div className="field-row">
          <div className="field-wrapper">
            <label>City <span className="required">*</span></label>
            <input
              type="text"
              value={city}
              onChange={(event) => { setCity(event.target.value) }}
            />
          </div>
          <div className="field-wrapper">
            <label>Zip</label>
            <input
              type="text"
              value={zip}
              onChange={(event) => { setZip(event.target.value) }}
            />
          </div>
        </div>
        <div className="field-row">
          <div className="field-wrapper">
            <label>Country <span className="required">*</span></label>
            <Select
              options={countryList}
              value={country}
              onChange={setCountry}
            />
          </div>
          <div className="field-wrapper">
            <label>State <span className="required">*</span></label>
            <input
              type="text"
              value={state}
              onChange={(event) => { setState(event.target.value) }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isUpdating}
          onClick={handleUpdate}
        >
          Update
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onHide() }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default BillingInfoModal
