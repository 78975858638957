import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { updateTargetAcos } from '../../redux/actions/campaign'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const AdgroupSelector = ({
  campaignType,
  currentAdgroup,
  showAllAdgroup = false,
  onAdgroupChange,
  onCpcTargetChange = () => {},
  children,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const [acos, setAcos] = useState(0)
  const [cpcTarget, setCpcTarget] = useState(0)
  const [isUpdatingAcos, setIsUpdatingAcos] = useState(false)

  useEffect(() => {
    setAcos(currentAcos || 0)
  }, [currentAcos])

  const handleAcosChange = e => {
    if (!e.target.value) {
      return
    }
    setAcos(e.target.value)
  }

  const handleCpcTargetChange = e => {
    if (!e.target.value) {
      return
    }
    setCpcTarget(e.target.value)
    onCpcTargetChange(e.target.value)
  }

  const handleSaveAcos = async () => {
    setIsUpdatingAcos(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateTargetAcos(accessToken, [{
      campaignId: currentDetail.campaign_id,
      campaignType,
      acos,
      originalAcos: currentAcos,
    }]))
    setIsUpdatingAcos(false)
  }

  const renderAcos = () => {
    return (
      <>
        <span>Target ACoS (%)</span>
        <input value={acos} type="number" onChange={handleAcosChange} />
        {
          parseFloat(currentAcos || 0) !== parseFloat(acos || 0) && (
            <button
              type="button"
              className="btn btn-red"
              disabled={isUpdatingAcos}
              onClick={handleSaveAcos}
            >
              Save
            </button>
          )
        }
      </>
    )
  }

  const renderCpcTarget = () => {
    return (
      <>
        <span>CPC/VCPM Target</span>
        <input
          value={cpcTarget}
          type="number"
          onChange={handleCpcTargetChange}
        />
      </>
    )
  }

  return (
    <div className="adgroup-selector">
      <div className="adgroup-content">
        Ad group:
        {
          showAllAdgroup && (
            <button
              type="button"
              className={`btn ${!currentAdgroup ? 'btn-blue' : 'btn-white'}`}
              onClick={() => { onAdgroupChange(null) }}
            >
              All ad groups
            </button>
          )
        }
        {
          (currentAdGroups || []).map(adgroup => (
            <button
              key={adgroup.adgroupid}
              type="button"
              className={`btn ${
                currentAdgroup
                && currentAdgroup.adgroupid.toString() === adgroup.adgroupid.toString()
                ? 'btn-blue'
                : 'btn-white'}`}
              onClick={() => { onAdgroupChange(adgroup) }}
            >
              { adgroup.name }
            </button>
          ))
        }
      </div>
      <div className="acos-container">
        { !isNonEndemicAccount ? renderAcos() : renderCpcTarget() }
        { children }
      </div>
    </div>
  )
}

export default AdgroupSelector
