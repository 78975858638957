import React from 'react'

import CustomTooltip from '../CustomTooltip'

const StepBreadcrumb = ({ stepList, currentStep }) => {
  return (
    <div className="step-container">
      {
        stepList.map(stepInfo => (
          <span
            key={stepInfo.value}
            className={`step-wrapper${currentStep === stepInfo.value ? ' active' : ''}`}
          >
            { stepInfo.name }
            {
              typeof stepInfo.description !== 'undefined' && (
                <CustomTooltip placement="right">
                  { stepInfo.description }
                </CustomTooltip>
              )
            }
          </span>
        ))
      }
    </div>
  )
}

export default StepBreadcrumb
