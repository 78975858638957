/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Tag } from 'rsuite'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'

import { toast } from '../CommonComponents/ToastComponent/toast'

import {
  checkStatus,
  closeJobMonitor,
  removeJobFromMonitor,
  updateJobStatus,
} from '../../redux/actions/job'

import { getJobName, getTemporaryDownloadLink } from '../../services/helper'
import {
  JOB_BULK_REPORT_TYPES,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_FAILED,
  JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS,
  JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS,
  JOB_TYPE_BULK_CREATE_NEGATIVES,
  JOB_TYPE_BULK_CREATE_PRODUCT_ADS,
  JOB_TYPE_BULK_CREATE_TARGETS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS,
  JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
  JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
  JOB_TYPE_BULK_UPDATE_PA_STATES,
  JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
  JOB_TYPE_BULK_UPDATE_TARGET_STATES,
  JOB_TYPE_ACTIVITY_LOG_DOWNLOAD,
} from '../../utils/defaultValues'
import {
  processBudgetUpdate,
  processCampaignBiddingsUpdate,
  processCampaignNamesUpdate,
  processCampaignStatesUpdate,
  processTargetAcosUpdate,
} from '../../redux/actions/campaign'
import { processCampaignPortfoliosUpdate } from '../../redux/actions/campaignDetail'
import {
  processKeywordBidsUpdate,
  processKeywordStatesUpdate,
  processNegativesCreate,
  processNksArchive,
  processNtsArchive,
  processPaStatesUpdate,
  processTargetBidsUpdate,
  processTargetStatesUpdate,
  processTargetsAdd,
} from '../../redux/actions/bulkEngine'

const JobCheckerComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const jobsToMonitor = useSelector(state => state.job.jobsToMonitor)

  const checkerIntervals = useRef({})

  const [isVisible, setIsVisible] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const [reportUrls, setReportUrls] = useState({})

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      const intervals = checkerIntervals.current // eslint-disable-line
      Object.keys(intervals).forEach((jobId) => {
        clearInterval(intervals[jobId])
      })
    }
  }, [])

  useEffect(() => {
    if (jobsToMonitor.length) {
      setIsVisible(true);

      (async () => {
        await setupIntervals()
      })()
    } else {
      setIsVisible(false)
    }
  }, [jobsToMonitor]) // eslint-disable-line

  const setupIntervals = async () => {
    const accessToken = await getAccessTokenSilently()

    jobsToMonitor.forEach((job) => {
      if (job.status === JOB_STATUS_COMPLETED || job.status === JOB_STATUS_FAILED) {
        return
      }

      if (checkerIntervals.current[job.id]) {
        return
      }

      checkerIntervals.current[job.id] = setInterval(() => {
        dispatch(checkStatus(accessToken, job.id)).then(({ status, url, data }) => {
          if (status !== JOB_STATUS_COMPLETED) {
            return
          }

          if (JOB_BULK_REPORT_TYPES.includes(job.type)) {
            toast.show({
              title: 'Success',
              description: 'The report has been generated. '
                + `You can <a href="${getTemporaryDownloadLink(url)}" target="_blank">download it here</a>, `
                + 'or browse it from the "Generated Reports" tab.',
              duration: 10000,
            })
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES) {
            dispatch(processCampaignStatesUpdate(
              data,
              job.payload
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS) {
            dispatch(processTargetAcosUpdate(
              job.payload
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS) {
            dispatch(processBudgetUpdate(
              job.payload,
              data
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS) {
            dispatch(processCampaignPortfoliosUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES) {
            dispatch(processCampaignNamesUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS) {
            dispatch(processCampaignBiddingsUpdate(
              data,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_PA_STATES) {
            dispatch(processPaStatesUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_KEYWORD_STATES) {
            dispatch(processKeywordStatesUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS) {
            dispatch(processKeywordBidsUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_TARGET_STATES) {
            dispatch(processTargetStatesUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_UPDATE_TARGET_BIDS) {
            dispatch(processTargetBidsUpdate(
              data,
              job.payload,
            ))
          } else if (job.type === JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS) {
            dispatch(processNksArchive(
              data,
            ))
          } else if (job.type === JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS) {
            dispatch(processNtsArchive(
              data,
            ))
          } else if (job.type === JOB_TYPE_BULK_CREATE_PRODUCT_ADS) {
            toast.show({
              title: 'Success',
              description: `Added ${data.count} product${data.count > 1 ? 's' : ''} successfully.`,
            })
          } else if (job.type === JOB_TYPE_BULK_CREATE_TARGETS) {
            processTargetsAdd(data)
          } else if (job.type === JOB_TYPE_BULK_CREATE_NEGATIVES) {
            dispatch(processNegativesCreate(
              data,
            ))
          } else if (job.type === JOB_TYPE_ACTIVITY_LOG_DOWNLOAD) {
            const temporaryLink = getTemporaryDownloadLink(url)
            toast.show({
              title: 'Success',
              description: 'The activity logs report has been generated. '
                + `You can <a href="${temporaryLink}" target="_blank">download it here</a>, `,
              duration: 10000,
            })
            setReportUrls({
              ...reportUrls,
              [job.id]: temporaryLink
            })
          }

          dispatch(updateJobStatus(job.id, JOB_STATUS_COMPLETED))

          clearInterval(checkerIntervals.current[job.id])
          delete checkerIntervals.current[job.id]
        }).catch((error) => {
          toast.show({
            title: 'Danger',
            description: error,
          })

          dispatch(updateJobStatus(job.id, JOB_STATUS_FAILED))

          clearInterval(checkerIntervals.current[job.id])
          delete checkerIntervals.current[job.id]
        })
      }, 5000)
    })
  }

  const handleReportOpen = jobId => (event) => {
    event.preventDefault()
    history.push({
      pathname: 'bulk-engine',
      state: {
        jobId,
      }
    })
  }

  const handleJobRemove = jobId => () => {
    if (checkerIntervals.current[jobId]) {
      clearInterval(checkerIntervals.current[jobId])
      delete checkerIntervals.current[jobId]
    }

    if (reportUrls[jobId]) {
      let removedUrls = reportUrls
      delete removedUrls[jobId]
      setReportUrls(removedUrls)
    }

    dispatch(removeJobFromMonitor(jobId))
  }

  const handleHide = (event) => {
    event.preventDefault()

    const intervals = checkerIntervals.current
    Object.keys(intervals).forEach((jobId) => {
      clearInterval(intervals[jobId])
    })

    dispatch(closeJobMonitor())
  }

  const renderExpandCollapse = () => {
    if (isCollapsed) {
      return <FiMaximize2 onClick={() => { setIsCollapsed(false) }} />
    }
    return <FiMinimize2 onClick={() => { setIsCollapsed(true) }} />
  }

  const renderHeader = () => {
    let title = 'Bulk action/report status'
    if (isCollapsed) {
      const count = jobsToMonitor.filter(job => !job.status).length
      if (count > 1) {
        title = `${count} action/reports in progress`
      } else if (count === 1) {
        title = `${count} action/report in progress`
      } else {
        title = 'No action/report in progress'
      }
    }

    return (
      <div className="pane-header">
        <div className="pane-title">
          { title }
        </div>
        { renderExpandCollapse() }
      </div>
    )
  }

  const renderStatus = (job) => {
    if (!job.status) {
      return <Tag color="blue">In progress</Tag>
    }

    if (job.status === JOB_STATUS_COMPLETED) {
      return (
        <>
          {
            JOB_BULK_REPORT_TYPES.includes(job.type) && (
              <a
                href="#"
                onClick={handleReportOpen(job.id)}
              >
                Open
              </a>
            )
          }
          {
            (job.type === JOB_TYPE_ACTIVITY_LOG_DOWNLOAD && reportUrls[job.id]) && (
              <a
                href={reportUrls[job.id]}
                target="_blank"
                rel="noreferrer"
              >
                Open
              </a>
            )
          }
          <Tag color="green">Completed</Tag>
        </>
      )
    }

    return <Tag color="red">Failed</Tag>
  }

  const renderJob = (job) => {
    return (
      <div key={job.id} className="report-item">
        <span className="report-name">
          { getJobName(job.type, job.payload) }
        </span>
        { renderStatus(job) }
        <span
          className="remove-icon"
          onClick={handleJobRemove(job.id)}
        >
          &times;
        </span>
      </div>
    )
  }

  const renderBody = () => {
    if (isCollapsed) {
      return null
    }

    return (
      <div className="report-list">
        { jobsToMonitor.map(renderJob) }
      </div>
    )
  }

  const renderFooter = () => {
    if (isCollapsed) {
      return null
    }

    return (
      <div className="pane-footer">
        <a href="#" onClick={handleHide}>
          Hide
        </a>
      </div>
    )
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className="job-checker-component">
      { renderHeader() }
      { renderBody() }
      { renderFooter() }
    </div>
  )
}

export default JobCheckerComponent
