import { callGet, callPost } from '../../services/axios.js'
import { JOB_STATUS_FAILED } from '../../utils/defaultValues.js'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import {
  CLOSE_JOB_MONITOR,
  MONITOR_JOB,
  REMOVE_JOB_FROM_MONITOR,
  UPDATE_JOB_STATUS,
} from '../actionTypes/job.js'

export const getJobs = (accessToken, type, signal = undefined) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/job/getJobs', accessToken, {
    userId: currentUserId,
    type,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve generated reports.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getJobResults = (accessToken, jobId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/job/getJobResults', accessToken, {
    userId: currentUserId,
    jobId,
  })
}

export const deleteJob = (accessToken, jobId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/job/deleteJob', {
    userId: currentUserId,
    jobId,
  }, accessToken)
}

export const checkStatus = (accessToken, jobId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/job/checkStatus', accessToken, {
    userId: currentUserId,
    jobId,
  }).then((response) => {
    if (response.data.status === JOB_STATUS_FAILED) {
      return Promise.reject({
        response: {
          data: {
            message: response.data.error || 'Failed to process a job.',
          },
        },
      })
    }
    return response.data
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to check a job status.')
  })
}

export const monitorJob = (id, type, payload = null) => (dispatch) => {
  dispatch({
    type: MONITOR_JOB,
    data: {
      id,
      type,
      payload,
    },
  })
}

export const updateJobStatus = (id, status) => (dispatch) => {
  dispatch({
    type: UPDATE_JOB_STATUS,
    data: {
      id,
      status,
    },
  })
}

export const removeJobFromMonitor = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_JOB_FROM_MONITOR,
    data: {
      id,
    },
  })
}

export const closeJobMonitor = () => (dispatch) => {
  dispatch({
    type: CLOSE_JOB_MONITOR,
  })
}
