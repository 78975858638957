import React from 'react'

import FlowContainer from './FlowContainer'
import LoomVideoCollection from './LoomVideoCollection'

import { tutorialList as BulkOpTutorials } from '../../components/BulkOp'
import { tutorialList as BulkExTutorials } from '../../components/BulkExpansion'
import { tutorialList as TargetSearchTutorials } from '../../components/TargetSearchTable'

const BulkOverviewFlow = ({ ...props }) => {
  const videoList = []

  const tutorialListCollection = [
    BulkOpTutorials,
    BulkExTutorials,
    TargetSearchTutorials,
  ]

  tutorialListCollection.forEach((tutorialList, index) => {
    Object.keys(tutorialList).forEach((key) => {
      if (index !== 0 && key === '') {
        return
      }

      tutorialList[key].videoList.forEach((video) => {
        const embedPos = video.url.indexOf('embed/')
        if (embedPos !== -1) {
          videoList.push({
            name: video.title,
            videoId: video.url.substr(embedPos + 6),
          })
        }
      })
    })
  })

  return (
    <FlowContainer
      name="Bulk Engine Overview Flow"
      {...props}
    >
      <LoomVideoCollection videoList={videoList} />
      <div className="flow-text">
        <p><strong>Just how powerful is the all new Entourage 2.0 Bulk Engine?</strong></p>
        <p>Powerful enough to eliminate painful spreadsheets.</p>
        <p>The Bulk Engine is designed to help you get so much done in way less time.</p>
        <p>Simply find the meaningful data using the Bulk Engine and take action in a matter of minutes.</p>
        <p>You can optimize or expand targets across hundreds of campaigns, all at once!</p>
        <p>Completing this flow is a high value task.</p>
        <p>Let's go!</p>
      </div>
    </FlowContainer>
  )
}

export default BulkOverviewFlow
