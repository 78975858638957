import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import CustomTooltip from '../../components/CommonComponents/CustomTooltip'

import {
  changeHighlightWidgetState,
  changeViewWidgetState
} from '../../redux/actions/pageGlobal'
import { formatCurrency, formatValue } from '../../services/helper'

const ReportTileWidget = ({
  widget,
  value,
  diff = null,
  isBetter = true,
  customizing = false,
  section = '',
  suffix = '',
  highlighting = true,
  onRemove,
}) => {
  const dispatch = useDispatch()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const isHighlighted = useMemo(() => {
    if (!section) {
      return widget.highlighted
    }
    const sectionWidget = reportWidgetSetting[section].find((w) => w.key === widget.key)
    return sectionWidget.highlighted
  }, [section, widget, reportWidgetSetting])

  const className = useMemo(() => {
    if (widget.key === 'cost'
      || widget.key === 'unproductive_ad_spend'
      || widget.key === 'productive_ad_spend_rate'
      || widget.key === 'productive_ad_spend') {
      return 'neutral-trending'
    }
    return isBetter ? 'positive-trending' : 'negative-trending'
  }, [widget, isBetter])

  const handleClickHighlightWidget = () => {
    dispatch(changeHighlightWidgetState(section, widget.key))
  }

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  const renderValue = () => {
    if (widget.type === 'currency') {
      return formatCurrency(value, currencySign, currencyRate)
    }
    return formatValue(value, widget.type, widget.decimal)
  }

  const renderDiff = () => {
    if (!diff) {
      return null
    }

    let sign = ''
    if (diff.value > 0) {
      sign = '+'
    }

    return (
      <div className="widget-prev-value">
        <span className={className}>
          { sign }{ formatValue(diff.value, 'number', widget.decimal) } ({ formatValue(diff.percent, 'percent', 2) })
        </span>
      </div>
    )
  }

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          {highlighting && (
            <IconButton
              icon={<Icon icon="star" />}
              size="xs"
              className={`highlight-report-widget ${isHighlighted ? 'active' : ''}`}
              circle
              onClick={handleClickHighlightWidget}
            />
          )}
          <span>
            { widget.title } { suffix }
          </span>
          {
            widget.tooltip && (
              <CustomTooltip placement="top">
                <p>{ widget.tooltip }</p>
              </CustomTooltip>
            )
          }
        </div>
        <div className="widget-action">
          {
            customizing && (
              <IconButton
                icon={<Icon icon="check" />}
                color={widget.view ? 'green' : 'violet'}
                circle
                onClick={handleClickHideWidget}
              />
            )
          }
        </div>
      </div>
      <div className="report-widget-content">
        <div className="widget-value">
          { renderValue() }
        </div>
        { renderDiff() }
      </div>
    </div>
  )
}

export default ReportTileWidget
