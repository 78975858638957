import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FiExternalLink } from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'

import { showSpManager } from '../../redux/actions/ruleManager'

import { productCampaignColumnList } from '../../utils/defaultValues'

import { tableSorter, getExportValueForColumn } from '../../services/helper'

import { campaignTypeMap, stateLabels } from '../../utils/defaultValues'

const ProductCampaignTableComponent = () => {
  const dispatch = useDispatch()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const productCampaignTableColumns = useSelector(state => state.pageGlobal.productCampaignTableColumns)
  const campaignsForProduct = useSelector(state => state.product.campaignsForProduct)

  const handleSmartPilotManagerShow = (campaign) => {
    dispatch(showSpManager(campaign.campaign_id))
  }

  const renderRecord = (record) => {
    let targetingType
    if (record.campaignType === 'sp') {
      if (record.targeting_type === 'auto') {
        targetingType = 'Auto'
      } else {
        targetingType = 'Manual'
      }
    }

    return (
      <>
        <div className="table-col col-campaign">
          <div className="campaign-status">
            <div className={`status ${record.state.toLowerCase() === 'enabled' ? 'on' : 'off'}`}>
              <div className="bullet"></div>
              <span>{ stateLabels[record.state.toLowerCase()] }</span>
            </div>
            <div className={`status ${record.is_ap_active ? 'on' : 'off'}`}>
              <div className="bullet"></div>
              <span>Smart Pilot { record.is_ap_active ? 'On' : 'Off' }</span>
            </div>
            <FiExternalLink
              title="Open Smart Pilot"
              onClick={() => { handleSmartPilotManagerShow(record) }}
            />
          </div>
          <Link
            to={`/campaign/${record.campaign_id}/${record.campaignType}`}
            className="campaign-name"
            title={record.campaign}
          >
            { record.campaign }
          </Link>
          <div className="campaign-detail">
            {
              targetingType && <span>{ targetingType }</span>
            }
            <span>
              { campaignTypeMap[record.campaignType] }
            </span>
          </div>
        </div>
        {
          productCampaignTableColumns.map((column) => {
            if (column.key === 'campaign') {
              return null
            }
            return (
              <TableCell
                key={column.key}
                record={record}
                columnKey={column.key}
                columnSelection={productCampaignTableColumns}
                currencySign={currencySign}
                currencyRate={currencyRate}
              />
            )
          })
        }
      </>
    )
  }

  const renderTotal = (summary) => {
    summary.roas = 0
    summary.ntb_orders_percent = 0
    summary.ntb_sales_percent = 0

    if (parseFloat(summary.cost || 0)) {
      summary.roas = parseFloat(summary.revenue || 0)
        / parseFloat(summary.cost || 0)
    }
    if (parseInt(summary.orders || 0, 10)) {
      summary.ntb_orders_percent = parseInt(summary.ntb_orders || 0, 10)
        / parseInt(summary.orders || 0, 10) * 100
    }
    if (parseFloat(summary.revenue || 0)) {
      summary.ntb_sales_percent = parseFloat(summary.ntb_sales || 0)
        / parseFloat(summary.revenue || 0) * 100
    }

    return (
      <>
        <div className="table-col col-campaign">
          Totals:
        </div>
        {
          productCampaignTableColumns.map((column) => {
            if (column.key === 'campaign') {
              return null
            }
            return (
              <TableCell
                key={column.key}
                record={summary}
                columnKey={column.key}
                columnSelection={productCampaignTableColumns}
                currencySign={currencySign}
                currencyRate={currencyRate}
              />
            )
          })
        }
      </>
    )
  }

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        columns={productCampaignTableColumns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['campaign'])}
        className="table-campaigns"
        records={campaignsForProduct || []}
        idField="campaign_id"
        searchFields={['campaign']}
        noCheckBox
        hasSticky
        filterName="productCampaignTable"
        columnEditorId="productCampaign"
        columnEditorNoReset={false}
        columnList={productCampaignColumnList}
        columnSelection={productCampaignTableColumns}
        getExportData={getExportData}
        exportFileName="Campaigns in product"
        renderRecord={renderRecord}
        renderTotal={renderTotal}
      />
    </>
  )
}

export default ProductCampaignTableComponent
