/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Modal} from 'rsuite'

import SortableTable from '../CommonComponents/SortableTableComponent'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import CustomTable from '../CommonComponents/CustomTableComponent'
import VideoLink from '../CommonComponents/VideoLink'
import StepBreadcrumb from '../CommonComponents/StepBreadcrumb'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'

import { tableSorter, formatCurrency } from '../../services/helper'
import { getAdgroupsToAddTargets } from '../../redux/actions/bulkEngine'
import { createProductsInBulk } from '../../redux/actions/campaignCreator'
import { getSkus } from '../../redux/actions/product'
import { monitorJob } from '../../redux/actions/job'
import { JOB_TYPE_BULK_CREATE_PRODUCT_ADS } from '../../utils/defaultValues'

const STEP_ADGROUP = 1
const STEP_ADD_PRODUCTS = 2
const TAB_SEARCH = 'search'
const TAB_ENTER = 'enter'

const stepList = [
  { value: STEP_ADGROUP, name: 'Choose ad groups' },
  { value: STEP_ADD_PRODUCTS, name: 'Choose products' },
]

const tabList = [
  { value: TAB_SEARCH, name: 'Search' },
  { value: TAB_ENTER, name: 'Enter list' },
]

const columns = [
  { key: 'name', name: 'Ad Group' },
  { key: 'campaignName', name: 'Campaign' },
  { key: 'count', name: 'Number of Keywords/Targets', sortable: false },
]

const videoList = [
  { title: 'Add Products to Advertise', url: 'https://www.loom.com/embed/804c3911da16445ab53d927c36c80990' },
]

const AddProductsModal = ({ show, campaigns, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const adgroupsToAddProducts = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)
  const skus = useSelector(state => state.product.skus)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(STEP_ADGROUP)
  const [currentTab, setCurrentTab] = useState(TAB_SEARCH)
  const [adgroupList, setAdgroupList] = useState([])
  const [selectedAdgroups, setSelectedAdgroups] = useState([])
  const [asinList, setAsinList] = useState('')
  const [selectedProductIds, setSelectedProductIds] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [isGettingAdgroups, setIsGettingAdgroups] = useState(false)

  useEffect(() => {
    if (!show) {
      return
    }
    if (!isLoading) {
      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getSkus(accessToken))
        setIsLoading(true)
      })()
    }
    setStep(STEP_ADGROUP)
    setSelectedAdgroups([])
  }, [show]) // eslint-disable-line

  useEffect(() => {
    if (!campaigns.length) {
      return
    }

    const payload = {}
    campaigns.forEach((campaign) => {
      if (campaign.campaignType !== 'sp'
        && campaign.campaignType !== 'sd') {
        return
      }

      if (!payload[campaign.campaignType]) {
        payload[campaign.campaignType] = []
      }
      payload[campaign.campaignType].push(campaign.campaign_id.toString())
    });

    (async () => {
      setIsGettingAdgroups(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAdgroupsToAddTargets(
        accessToken,
        payload,
        false // This needs to be cast to boolean explicitly.
      ))
      setIsGettingAdgroups(false)
    })()
  }, [campaigns, dispatch]) // eslint-disable-line

  useEffect(() => {
    if (!adgroupsToAddProducts.length || isGettingAdgroups || !campaigns.length) {
      return
    }

    setAdgroupList((adgroupsToAddProducts || []).map(adgroup => {
      const adgroupCampaign = campaigns.find(campaign =>
        campaign.campaign_id.toString() === adgroup.campaign_id.toString()
      )
      if (!adgroupCampaign) {
        return adgroup
      }
      return {
        ...adgroup,
        campaignName: adgroupCampaign.campaign || '',
        campaignType: adgroupCampaign.campaignType || '',
        targeting_type: adgroupCampaign.targeting_type || '',
      }
    }))
  }, [adgroupsToAddProducts, isGettingAdgroups]) // eslint-disable-line

  const handleConfirm = async () => {
    if (step !== STEP_ADD_PRODUCTS) {
      setStep(step + 1)
      return
    }

    let selectedProducts = []
    if (currentTab === TAB_SEARCH) {
      selectedProducts = skus.filter(sku => selectedProductIds.includes(sku.id))
    } else if (currentTab === TAB_ENTER) {
      const asins = asinList.split('\n').map(asin => asin.trim().toLowerCase())
      selectedProducts = skus.filter(sku => asins.includes(sku.asin.toLowerCase()))
    }

    if (!selectedProductIds.length) {
      toast.show({
        title: 'Warning',
        description: 'Please select more than one product.',
      })
      return
    }

    setIsAdding(true)
    const payload = []
    adgroupList.filter(adgroup =>
      selectedAdgroups.includes(adgroup.adgroup_id)
    ).forEach((adgroup) => {
      selectedProducts.forEach((product) => {
        payload.push({
          campaignId: adgroup.campaign_id,
          campaignType: adgroup.campaignType,
          adgroupId: adgroup.adgroup_id,
          sku: product.sku,
          asin: product.asin,
        })
      })
    })

    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(createProductsInBulk(
      accessToken,
      payload,
      false,
    ))
    setIsAdding(false)
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_CREATE_PRODUCT_ADS,
      ))

      onClose()
    }
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  const renderSteps = () => (
    <StepBreadcrumb
      stepList={stepList}
      currentStep={step}
    />
  )

  const renderAdgroup = (adgroup) => (
    <>
      <div className="table-col col-adgroup">
        { adgroup.name }
      </div>
      <TableCampaignCell
        record={adgroup}
        noLink
      />
      <div className="table-col">
        { parseInt(adgroup.keywords, 10) + parseInt(adgroup.targets, 10) }
      </div>
    </>
  )

  const renderSku = sku => (
    <>
      <div className="table-col col-product">
        {
          sku.image !== '' ? (
            <img src={sku.image} alt={sku.name} />
          ) : (
            <span className="image-placeholder">No image</span>
          )
        }
        <div className="product-info">
          <div className="product-name" title={sku.name}>
            { sku.name }
          </div>
          <div className="product-asin-info">
            ASIN: { sku.asin } | SKU: { sku.sku }
          </div>
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(sku.price, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderContents = () => {
    if (step === STEP_ADGROUP) {
      return (
        <SortableTable
          columns={columns}
          defaultSort={['campaignName', 'asc']}
          sorter={tableSorter(['campaignName', 'name'])}
          className="table-adgroups"
          records={adgroupList}
          idField="adgroup_id"
          searchFields={['campaignName', 'name']}
          selectedRecords={selectedAdgroups}
          noRecordText={'No records found.'}
          isLoading={isGettingAdgroups}
          renderTopRight={null}
          renderRecord={renderAdgroup}
          onChange={setSelectedAdgroups}
        />
      )
    }

    return (
      <>
        <div className="tab-list">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? 'selected' : ''}
                onClick={() => { setCurrentTab(tab.value) }}
              >
                { tab.name }
              </button>
            ))
          }
        </div>
        {
          currentTab === TAB_SEARCH && (
            <CustomTable
              className="table-products"
              records={skus || []}
              selectedRecords={selectedProductIds}
              idField="id"
              searchFields={['name', 'sku', 'asin']}
              renderRecord={renderSku}
              onChange={setSelectedProductIds}
            >
              <div className="table-col col-product">Product</div>
              <div className="table-col">Price</div>
            </CustomTable>
          )
        }
        {
          currentTab === TAB_ENTER && (
            <textarea
              className="asin-list"
              placeholder="Enter ASINs separated by a new line."
              rows={5}
              value={asinList}
              onChange={(event) => { setAsinList(event.target.value) }}
            />
          )
        }
      </>
    )
  }

  const isConfirmDisabled = isAdding
    || (step === STEP_ADGROUP && !selectedAdgroups.length)

  const renderFooter = () => (
    <Modal.Footer>
      {
        step !== STEP_ADGROUP && (
          <button
            type="button"
            className="rs-btn rs-btn-default"
            onClick={handleBack}
          >
            Back
          </button>
        )
      }
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isConfirmDisabled}
        onClick={handleConfirm}
      >
        { step === STEP_ADD_PRODUCTS ? 'Confirm' : 'Next' }
      </button>
      <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal
      className={`product-modal${isAdding || isGettingAdgroups || isLoading ? ' loading' : ''}`}
      backdrop="static"
      show={show}
      size="lg"
    >
      { isAdding && <LoaderComponent /> }
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Add Products to Advertise
          <VideoLink
            videoList={videoList}
            modalTitle="Add Products to advertise"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { renderSteps() }
        { renderContents() }
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default AddProductsModal
