import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../CommonComponents/ToastComponent/toast'
import CheckboxComponent from '../../../CommonComponents/CheckboxComponent'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'
import ModalAddNegatives from '../../modals/ModalAddNegatives'

import {
  formatValue,
  formatCurrency,
  copyToClipboard,
  tableSorter,
  getExportValueForColumn,
  isAsin,
} from '../../../../services/helper'

import { prepList } from '../../../../utils/defaultValues'

const regExpForSpecialChars = new RegExp('&#34;', 'g')

const columns = [
  { key: 'search', name: 'Words', className: 'col-word' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Unprofitable Clicks' },
  { key: 'revenue', name: 'Sales' },
  { key: 'cost', name: 'Wasted AD Spend' },
  { key: 'yearlyCost', name: 'Approximate Yearly Savings' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'acos', name: 'ACoS' },
  { key: 'roas', name: 'ROAS' },
]

const KeywordTable = ({ campaignType, currentAdgroupId, dateDiff,
  hideKeywords, setHideKeywords }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const isNegativeWordDataLoading = useSelector(state => state.campaignDetail.isNegativeWordDataLoading)
  const negativeWordData = useSelector(state => state.campaignDetail.negativeWordData)
  const existingNegativeData = useSelector(state => state.campaignDetail.existingNegativeData)

  const [negatives, setNegatives] = useState([])
  const [selectedNegatives, setSelectedNegatives] = useState([])
  const [showAddNegativesModal, setShowAddNegativesModal] = useState(false)
  const [hideAsins, setHideAsins] = useState(true)
  const [removeNumbers, setRemoveNumbers] = useState(true)
  const [removePreps, setRemovePreps] = useState(true)

  useEffect(() => {
    if (!negativeWordData || !negativeWordData.length) {
      return
    }

    let searchTermList
    if (currentAdgroupId) {
      searchTermList = negativeWordData[1]
        .filter(searchTerm =>
          searchTerm.adgroup_id.toString() === currentAdgroupId.toString()
        )
        .map(record => Object.assign(record, {
          id: `${record.adgroup_id}-${record.search}`,
        }))
    } else {
      searchTermList = negativeWordData[0].map(record => Object.assign(record, {
        id: record.search,
      }))
    }

    let positiveWordList = []
    const negativeList = []
    const targetAcos = parseFloat(currentAcos || 40)
    searchTermList.forEach((searchTerm) => {
      if (searchTerm.acos !== null && parseFloat(searchTerm.acos) <= targetAcos) {
        // Get a list of profitable words.
        const search = searchTerm.search.replace(regExpForSpecialChars, '').toLowerCase()
        positiveWordList = positiveWordList.concat(search.trim().split(/\s+/g))
      } else {
        negativeList.push(searchTerm)
      }
    })
    positiveWordList = [...new Set(positiveWordList)].map(keyword => keyword.trim())

    // Get a list of unprofitable words.
    let negativeWordList = []
    negativeList.forEach((searchTerm) => {
      const search = searchTerm.search.replace(regExpForSpecialChars, '')
      search.trim().split(/\s+/g).forEach((word) => {
        if (positiveWordList.indexOf(word.toLowerCase()) !== -1) {
          return
        }

        if ((existingNegativeData || []).indexOf(word.toLowerCase()) !== -1) {
          return
        }

        // Remove ASINs.
        if (hideAsins && isAsin(word)) {
          return
        }

        // Remove numbers.
        if (removeNumbers && !isNaN(word)) {
          return
        }

        // Remove Prepositions.
        if (removePreps && prepList.indexOf(word.toLowerCase()) !== -1) {
          return
        }

        const existingIndex = negativeWordList.findIndex(negative => (
          negative.search === word
        ))

        if (existingIndex === -1) {
          negativeWordList.push({
            id: searchTerm.id,
            search: word,
            revenue: parseFloat(searchTerm.revenue),
            cost: parseFloat(searchTerm.cost),
            impressions: parseInt(searchTerm.impressions, 10),
            clicks: parseInt(searchTerm.clicks, 10),
            orders: parseInt(searchTerm.orders, 10),
          })
        } else {
          negativeWordList[existingIndex].revenue += parseFloat(searchTerm.revenue)
          negativeWordList[existingIndex].cost += parseFloat(searchTerm.cost)
          negativeWordList[existingIndex].impressions += parseFloat(searchTerm.impressions)
          negativeWordList[existingIndex].clicks += parseFloat(searchTerm.clicks)
          negativeWordList[existingIndex].orders += parseFloat(searchTerm.orders)
        }
      })
    })
    negativeWordList = negativeWordList.map((word) => {
      let ctr = 0
      let acos = 0
      let roas = 0
      if (word.impressions) {
        ctr = word.clicks / word.impressions * 100
      }
      if (parseFloat(word.revenue)) {
        acos = parseFloat(word.cost) / parseFloat(word.revenue) * 100
      }
      if (parseFloat(word.cost)) {
        roas = parseFloat(word.revenue) / parseFloat(word.cost)
      }

      return {
        ...word,
        ctr,
        acos,
        roas,
        yearlyCost: word.cost / dateDiff * 365,
      }
    })

    setNegatives(negativeWordList)
  }, [currentAdgroupId, currentAcos, negativeWordData, existingNegativeData, // eslint-disable-line
    hideAsins, removeNumbers, removePreps])

  const handleCopy = () => {
    copyToClipboard([...new Set(selectedNegatives)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedNegatives.length} search word${selectedNegatives.length > 1 ? 's' : ''}.`
    })
  }

  const renderAction = () => {
    if (!selectedNegatives.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setShowAddNegativesModal(true) }}
        >
          Add negative{selectedNegatives.length > 1 ? 's' : ''} to campaign
        </button>
        <button type="button" className="btn btn-green" onClick={handleCopy}>
          Copy
        </button>
      </>
    )
  }

  const renderNegative = record => (
    <>
      <div className="table-col col-word">
        { record.search }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.ctr, 'percent') }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent', 1) }
      </div>
      <div className="table-col">
        { formatValue(record.roas, 'number', 2) }
      </div>
    </>
  )

  const selectedWords = negatives.filter(negative => (
    selectedNegatives.indexOf(negative.search) !== -1
  ))

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <div className="filter-container">
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Keywords"
            checked={hideKeywords}
            onChange={setHideKeywords}
          />
          <CustomTooltip placement="right">
            <p>Sometimes a keyword and search term are the same
            (ex: Exact match types), many times they are not.</p>
            <p>Checking this box means ONLY actual customer search
            terms will be revealed, not keywords.</p>
          </CustomTooltip>
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove ASINS"
            checked={hideAsins}
            onChange={setHideAsins}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Numbers"
            checked={removeNumbers}
            onChange={setRemoveNumbers}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Prepositions"
            checked={removePreps}
            onChange={setRemovePreps}
          />
          <CustomTooltip placement="right">
            <p>{ prepList.join(', ') }</p>
          </CustomTooltip>
        </div>
      </div>
      <SortableTable
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter('search')}
        className="table-search-words"
        records={negatives}
        idField="search"
        searchFields={['search']}
        selectedRecords={selectedNegatives}
        hasSticky
        hasDateRange
        filterName="campaignDetailNegative"
        isLoading={isNegativeWordDataLoading}
        getExportData={getExportData}
        exportFileName="Keywords in Campaign"
        renderRecord={renderNegative}
        renderTopRight={renderAction}
        onChange={setSelectedNegatives}
      />
      <ModalAddNegatives
        terms={selectedWords}
        modalType="negative-finder"
        campaignType={campaignType}
        showModal={showAddNegativesModal}
        currentAdGroupId={currentAdgroupId}
        onClose={() => { setShowAddNegativesModal(false) }}
      />
    </>
  )
}

export default KeywordTable
