import { callPost } from '../../services/axios'

import {
  REVERT_LOG_START,
  REVERT_LOG_SUCCESS,
  REVERT_LOG_FAIL,
} from '../actionTypes/campaignLog'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

export const revertLog = (accessToken, logId, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  dispatch({
    type: REVERT_LOG_START,
  })

  return callPost('/campaignlog/revert', {
    userId: currentUserId,
    logId,
  }, accessToken).then((response) => {
    dispatch({
      type: REVERT_LOG_SUCCESS,
      data: {
        logId,
        campaignId,
        response: response.data,
      },
    })

    toast.show({
      title: 'Success',
      description: 'Successfully reverted.',
    })
  }).catch((error) => {
    dispatch({
      type: REVERT_LOG_FAIL,
    })

    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to revert the log.',
    })
  })
}
