import React from 'react'

const DefaultBidSection = ({ targetings, defaultBid, forKeyword, onChange, onDefaultBidChange }) => {
  const handleApply = () => {
    if (!forKeyword) {
      onChange(targetings.map(item => ({
        ...item,
        bid: defaultBid,
      })))
    } else {
      onChange(targetings.map(item => ({
        ...item,
        keywordBid: defaultBid,
      })))
    }
  }

  return (
    <div className="default-bid-section">
      Default Bid:
      <input
        type="text"
        value={defaultBid}
        onChange={(event) => { onDefaultBidChange(event.target.value) }}
      />
      <button type="button" className="btn btn-blue" onClick={handleApply}>
        Apply
      </button>
    </div>
  )
}

export default DefaultBidSection
