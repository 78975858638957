import React, { useState } from 'react'

const SBAdSection = ({ name, onChange }) => {
  const [errorName, setErrorName] = useState('')

  const handleChange = (event) => {
    const value = event.target.value
    if (value === '') {
      setErrorName('Please enter an ad name.')
    } else {
      setErrorName('')
    }
    onChange(value)
  }

  return (
    <div className="section-container">
      <div className="section-title">
        Create an ad
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <div className="field-name">
            Ad name
          </div>
          <input
            type="text"
            value={name}
            onChange={handleChange}
          />
          {
            errorName !== '' && (
              <div className="warning-message">
                { errorName }
              </div>
            )
          }
        </div>
        <div className="field-wrapper" />
      </div>
    </div>
  )
}

export default SBAdSection
