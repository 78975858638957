/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import TemplateSelectorFooter from '../../RuleManagerComponents/TemplateSelectorFooter'

import SelectionShower from './SelectionShower'

import { getRuleDescription } from '../../../services/helper'

import { showTemplateEditor, loadTemplates } from '../../../redux/actions/ruleManager'
import { RULE_TYPE_DP } from '../../../utils/defaultValues'

const TemplateSelector = ({ show, currentTemplates = [],
  isApplying = false, onChange, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [selectedTemplates, setSelectedTemplates] = useState([])

  useEffect(() => {
    if (show && !isLoading) {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsLoading(true)
        setSelectedTemplates([])

        const response = await dispatch(loadTemplates(accessToken, RULE_TYPE_DP))
        setIsLoading(false)
        setTemplates(response.data)

        if (currentTemplates.length) {
          const existingIds = currentTemplates.map(template => template.id)
          setSelectedTemplates(response.data.filter(template =>
            existingIds.includes(template.id)
          ))
        }
      })()
    }
  }, [show]) // eslint-disable-line

  const handleTemplateApply = () => {
    const existingIds = currentTemplates.map(template => template.id)
    const existingTemplates = []
    const newTemplates = []

    // The priority of templates depends on the order of applied dates.
    // So we put existing templates first, and then new templates.
    selectedTemplates.forEach((template) => {
      if (existingIds.includes(template.id)) {
        existingTemplates.push(template)
      } else {
        newTemplates.push(template)
      }
    })

    onChange([
      ...existingTemplates,
      ...newTemplates,
    ])
  }

  const handleEdit = templateId => (event) => {
    event.preventDefault()
    dispatch(showTemplateEditor(
      RULE_TYPE_DP,
      templates.find(template => template.id === templateId)
    ))
    onClose()
  }

  const handleRemove = templateId => (event) => {
    event.preventDefault()
    setSelectedTemplates(selectedTemplates.filter(tpl =>
      tpl.id !== templateId
    ))
  }

  const renderTemplate = (template) => {
    return (
      <div key={template.id} className="template-details">
        <div className="template-header">
          <div className="template-name">
            { template.name }
          </div>
          <div className="action-buttons">
            <a
              href="#"
              onClick={handleEdit(template.id)}
            >
              Edit
            </a>
            <a href="#"
              className="remove-link"
              onClick={handleRemove(template.id)}
            >
              Remove
            </a>
          </div>
        </div>
        <div className="timezone-setting">
          Timezone: <strong>{ template.timezone }</strong>
        </div>
        {
          template.slots.map(slot => (
            <div key={slot.s.join()} className="slot-rules">
              <SelectionShower slotSelection={slot.s} />
              {
                slot.r.map(rule => (
                  <div key={rule.t} className="rule-details">
                    { getRuleDescription(rule) }
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    )
  }

  const renderBody = () => (
    <Modal.Body>
      { isApplying && <LoaderComponent /> }
      <Select
        options={templates}
        getOptionLabel={template => template.name}
        getOptionValue={template => template.id}
        value={selectedTemplates}
        isLoading={isLoading}
        placeholder="Select template..."
        isMulti
        onChange={setSelectedTemplates}
      />
      {
        selectedTemplates.length === 0 && (
          <div className="note">
            Click on template to see rules.
          </div>
        )
      }
      { selectedTemplates.map(renderTemplate) }
    </Modal.Body>
  )

  return (
    <Modal
      className="dayparting-template-selector-modal"
      overflow={false}
      backdrop="static"
      show={show}
      size="sm"
    >
      <Modal.Header onHide={onClose}>
        <Modal.Title>
          Dayparting Template
        </Modal.Title>
      </Modal.Header>
      { renderBody() }
      <TemplateSelectorFooter
        selectedTemplates={selectedTemplates}
        currentTemplates={currentTemplates}
        isApplying={isApplying}
        onApply={handleTemplateApply}
        onClose={onClose}
      />
    </Modal>
  )
}

export default TemplateSelector
