import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import { toast } from '../CommonComponents/ToastComponent/toast'

import { archiveNks } from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'

import {
  tableSorter,
  groupRecords,
  copyToClipboard,
} from '../../services/helper'
import { campaignTypes, negativeMatchTypes } from '../../utils/filterDef'
import {
  JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS,
  NEGATIVE_MATCH_TYPE_PHRASE,
} from '../../utils/defaultValues'

const columns = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'level', name: 'Level' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const columnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'childCount', name: 'Number of Negatives', parentOnly: true },
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'level', name: 'Level' },
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
]

const AdvancedNegativeKeywordTable = ({ campaignsById, adgroupNamesById }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const advancedNegativeData = useSelector(state => state.bulkEngine.advancedNegativeData)

  const [groupMode, setGroupMode] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [groupedKeywords, setGroupedKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [selectedAdType, setSelectedAdType] = useState(campaignTypes[0])
  const [selectedMatchType, setSelectedMatchType] = useState(negativeMatchTypes[0])
  const [isArchiving, setIsArchiving] = useState(false)

  useEffect(() => {
    // Remove duplicate entries.
    const records =  [...new Map(
      ((advancedNegativeData || {}).negativeKeywords || [])
      .map(item => [item.keyword_id, item]))
      .values()
    ]

    const extendedKeywords = [];
    records.forEach((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}

      const campaignType = campaignDetail.type || ''

      if (selectedAdType.value !== '') {
        if (campaignType.toLowerCase() !== selectedAdType.value) {
          return
        }
      }

      if (selectedMatchType.value !== '') {
        if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
          return
        }
      }

      extendedKeywords.push({
        ...record,
        campaignName: campaignDetail.name || '',
        campaignType,
        targetingType: campaignDetail.targetingType || '',
        adgroupName: record.adgroup_id
          ? adgroupNamesById[record.adgroup_id] || '' : '',
        matchType: record.match_type,
        level: record.adgroup_id ? 'Ad group level' : 'Campaign level',
      })
    })

    setKeywords(extendedKeywords)
  }, [advancedNegativeData, campaignsById,
    adgroupNamesById, selectedAdType, selectedMatchType])

  useEffect(() => {
    if (groupMode) {
      setGroupedKeywords(
        groupRecords(
          keywords,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedKeywords([])
    }
  }, [keywords, groupMode])

  const handleCopy = () => {
    const dataToCopy = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
    )).map(record => record.keyword.trim())

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} keyword${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const handleArchive = async () => {
    const negatives = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
    ))

    if (!negatives.length) {
      return
    }

    setIsArchiving(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(archiveNks(accessToken, negatives))
    setIsArchiving(false)

    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS,
      ))
    }
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By keywords"
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Ad Type</span>
          <Select
            classNamePrefix="ad-type-selector"
            options={campaignTypes}
            value={selectedAdType}
            onChange={setSelectedAdType}
          />
        </div>
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={negativeMatchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedKeywords.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-green"
          onClick={handleCopy}
        >
          Copy
        </button>
        <button
          type="button"
          className="btn btn-red"
          disabled={isArchiving}
          onClick={() => { handleArchive() }}
        >
          Remove Negative{selectedKeywords.length > 1 ? 's' : ''}
        </button>
      </>
    )
  }

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong className="contents">
          { record.keyword }
        </strong>
      </div>
      <div className="table-col">
        {
          record.matchType === NEGATIVE_MATCH_TYPE_PHRASE
          ? 'Negative Phrase'
          : 'Negative Exact'
        }
      </div>
      <div className="table-col">
        { record.level }
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
    </>
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col">
        { record.children.length }
      </div>
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong className="contents">
          { record.keyword }
        </strong>
      </div>
      <div className="table-col">
        {
          record.matchType === NEGATIVE_MATCH_TYPE_PHRASE
          ? 'Negative Phrase'
          : 'Negative Exact'
        }
      </div>
      <div className="table-col">
        { record.level }
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
    </>
  )

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            isLoading={isArchiving}
            columns={columnsGroup}
            defaultSort={['keyword', 'asc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-negative-keywords"
            records={groupedKeywords}
            idField="campaign_id"
            searchFields={['keyword']}
            selectedRecords={selectedKeywords}
            noSearch
            hasSticky
            renderRecord={renderParent}
            renderTopRight={renderAction}
            onChange={setSelectedKeywords}
            showParentColumnsOnly
            hasSearchChild
            sorterChild={tableSorter(['adgroupName', 'level', 'keyword', 'matchType'])}
            idFieldChild="keyword_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isArchiving}
            columns={columns}
            defaultSort={['keyword', 'asc']}
            sorter={tableSorter(['campaignName', 'adgroupName', 'level', 'keyword', 'matchType'])}
            className="table-negative-keywords"
            records={keywords || []}
            idField="keyword_id"
            searchFields={['keyword']}
            selectedRecords={selectedKeywords}
            hasSticky
            renderRecord={renderKeyword}
            renderTopRight={renderAction}
            onChange={setSelectedKeywords}
          />
        )
      }
    </>
  )
}

export default AdvancedNegativeKeywordTable
