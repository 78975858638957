import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import YoutubeVideoCollection from './YoutubeVideoCollection'

const attachments = [
  { name: 'Optimization Basics Flow.pdf', url: 'https://drive.google.com/file/d/10z5LPGS0fKQJ_6dm1iDD8SWYcvrRM7rL/view?usp=sharing' },
]

const videoList = [
  {
    name: 'Optimization Basics Flow. Let\'s Go!',
    videoId: 'uPIszrgAhto',
  },
]

const OpBasicFlow = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Optimization Basics + Campaign Dashboard Flow"
      coin={10}
      {...props}
    >
      <YoutubeVideoCollection videoList={videoList} onWatch={onWatch}/>
      <div className="flow-timestamps">
        <div className="timestamp-section">
          <div className="title">Optimization Basics</div>
          <div className="content">
            <span className="timestamp">SKU OPT 3:40</span>
            <span className="timestamp">Bid OPT 5:52</span>
            <span className="timestamp">Search Term OPT 9:59</span>
            <span className="timestamp">Negative Word/ASIN Finder 14:03</span>
            <span className="timestamp">Placement OPT 16:10</span>
          </div>
        </div>
        <div className="timestamp-section">
          <div className="title">Campaign Dashboard</div>
        </div>
      </div>
      <div className="flow-text">
        <p><strong>Optimizing your campaigns inside of Entourage 2.0 is simple, fast, and easy!</strong></p>
        <p>There are 5 basic methods that that any seller can use to optimize.</p>
        <p>Entourage 2.0 has all of those methods neatly laid out on the campaign dashboard.</p>
        <p>You can also use the Bulk Engine to apply the methods across hundreds of campaigns all at once.</p>
        <p>Many of these methods can also be automated with the Smart Pilot!</p>
        <p>In this video series flow, i'll break down the 5 ways to optimize and how to leverage the power of Entourage 2.0 to help save you money.</p>
        <p>Let's go!</p>
      </div>
      <AttachmentList attachments={attachments} />
    </FlowContainer>
  )
}

export default OpBasicFlow
