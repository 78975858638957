import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Toggle } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import BidAdjustComponent from '../CommonComponents/BidAdjustComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import GeniusBidCell from '../GeniusBidCell'

import {
  FILTER_NAME_TARGET_OP,
  FILTER_NAME_ADVANCED_OP,
} from './TargetOpResult'

import {
  updateKeywordStates,
  updateKeywordBids,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  formatCurrency,
  formatValue,
  capitalizeFirstLetter,
  tableSorter,
  calcDerivedMetrics,
  calcMaxCpc,
  getExportValueForColumn,
  groupRecords,
  copyToClipboard,
  calcNewBid,
} from '../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
  MODULE_NAME_TARGET_OP,
  BULK_ACTION_NO_IMPRESSION,
  JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
  JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
} from '../../utils/defaultValues'

import {
  matchTypes,
  keywordStatuses,
} from '../../utils/filterDef'

const TargetOpKeywordTable = ({ sortBy, keyMetric, activeModule, selectedStatus,
  campaignsById, adgroupNamesById, onChangeDate, onSelectStatus }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)

  const targetOpData = useSelector(state => state.bulkEngine.targetOpData)

  const [groupMode, setGroupMode] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [groupedKeywords, setGroupedKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [removeTargetAuto, setRemoveTargetAuto] = useState(true)
  const [removeZeroSpend, setRemoveZeroSpend] = useState(true)
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)

  useEffect(() => {
    const extendedKeywords = []; // semi-colon is required here.
    ((targetOpData || {}).keywords || []).forEach((record) => {
      if (removeTargetAuto && record.keyword === '(_targeting_auto_)') {
        return
      }

      if (activeModule !== BULK_ACTION_NO_IMPRESSION
        && removeZeroSpend
        && parseFloat(record.cost || 0) === 0) {
        return
      }

      if (selectedMatchType.value !== '') {
        if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
          return
        }
      }

      if (selectedStatus.value !== '') {
        if ((record.state || '').toLowerCase() !== selectedStatus.value) {
          return
        }
      }

      const derived = calcDerivedMetrics(record)

      const campaignDetail = campaignsById[record.campaign_id] || {}

      const campaignType = campaignDetail.type || ''

      let adType
      if (campaignType === 'sb') {
        adType = campaignDetail.videoAdgroupIds.includes(
          record.adgroup_id.toString()
        ) ? 'sbv' : campaignType
      } else {
        adType = campaignType
      }

      extendedKeywords.push({
        ...derived,
        campaignName: campaignDetail.name || '',
        campaignType,
        adType,
        adgroupName: adgroupNamesById[record.adgroup_id] || '',
        targetingType: campaignDetail.targetingType || '',
        costType: campaignDetail.costType || '',
        bidding: campaignDetail.bidding,
        matchType: capitalizeFirstLetter(record.match_type),
        maxCpc: calcMaxCpc(
          derived,
          adType,
          currentAccount?.country_id,
          campaignDetail.costType || '',
        ),
      })
    })

    setKeywords(extendedKeywords)
  }, [targetOpData, removeTargetAuto, removeZeroSpend, campaignsById,
    adgroupNamesById, activeModule, selectedMatchType,
    selectedStatus, currentAccount?.country_id])

  useEffect(() => {
    if (groupMode) {
      setGroupedKeywords(
        groupRecords(
          keywords,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedKeywords([])
    }
  }, [keywords, groupMode])

  let columns = [
    { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
    { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
    { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
  ]

  let columnsGroup = [
    { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
    { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
    { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
    { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
  ]

  const columnSelection = campaignTableColumns.filter(c1 => bulkBidColumnList.find(c2 => c2.key === c1.key))

  if (!keyMetric) {
    const metricColumns = [
      { key: 'bid', name: 'Current Bid' },
      {
        key: 'maxCpc',
        name: (
          <span>
            Genius Bid
            <CustomTooltip placement="right">
              <p>A minimum of 3 clicks is required before we suggest a “Genius Bid”.</p>
            </CustomTooltip>
          </span>
        ),
        plainName: 'Genius Bid',
        className: 'col-genius-bid',
      },
      ...columnSelection,
    ]

    columns = columns.concat(metricColumns)
    columnsGroup = columnsGroup.concat(metricColumns)
  } else {
    const keyColumn = bulkBidColumnList.find(c => c.key === keyMetric)

    const metricColumns = [
      keyColumn,
      { key: 'bid', name: 'Current Bid' },
      {
        key: 'maxCpc',
        name: (
          <span>
            Genius Bid
            <CustomTooltip placement="right">
              <p>A minimum of 3 clicks is required before we suggest a “Genius Bid”.</p>
            </CustomTooltip>
          </span>
        ),
        plainName: 'Genius Bid',
        className: 'col-genius-bid',
      },
      ...columnSelection.filter(c => c.key !== keyMetric),
    ]

    columns = columns.concat(metricColumns)
    columnsGroup = columnsGroup.concat(metricColumns)
  }

  const handleChangeState = async (state) => {
    const nonAuto = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    ))

    if (!nonAuto.length) {
      toast.show({
        title: 'Warning',
        description: 'You cannot change states of Target Auto.',
      })
      return
    }

    const keywordsChanged = nonAuto.map(record => ({
      campaignId: record.campaign_id,
      campaignType: record.campaignType,
      adGroupId: record.adgroup_id,
      keywordId: record.keyword_id,
      // Below information are used for logging in backend.
      adgroupName: record.adgroupName,
      keyword: record.keyword,
      matchType: record.matchType,
      originalState: record.state,
    }))

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateKeywordStates(
      accessToken,
      keywordsChanged,
      state,
      false,
    ))
    setIsUpdatingStates(false)
    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
        state,
      ))
    }
  }

  const handleChangeToMaxBid = async () => {
    const nonAuto = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    ))

    if (!nonAuto.length) {
      toast.show({
        title: 'Warning',
        description: 'You cannot change bid prices of Target Auto.',
      })
      return
    }

    let keywordsToChange = []

    nonAuto.forEach((record) => {
      if (!record.maxCpc) {
        return
      }
      keywordsToChange.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        keywordId: record.keyword_id,
        bid: parseFloat(record.maxCpc.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroupName,
        keyword: record.keyword,
        originalBid: record.bid,
        matchType: record.matchType,
      })
    })

    if (!keywordsToChange.length) {
      toast.show({
        title: 'Warning',
        description: 'Selected keyword(s) has invalid genius bid. '
          + 'The minimum bid allowed is $0.15 and '
          + 'auto campaign cannot change keyword bid. Please check your keywords.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateKeywordBids(
      accessToken,
      keywordsToChange,
      false,
    ))
    setIsUpdatingBids(false)
    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
        keywordsToChange,
      ))
    }
  }

  const handleAdjustBid = async () => {
    const nonAuto = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    ))

    if (!nonAuto.length) {
      toast.show({
        title: 'Warning',
        description: 'There is no keyword to update.',
      })
      return
    }

    let keywordsChanged = []
    nonAuto.forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.adType,
        currentAccount?.country_id,
        record.costType,
      )

      keywordsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        keywordId: record.keyword_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroupName,
        keyword: record.keyword,
        originalBid: record.bid,
        matchType: record.matchType,
      })
    })

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateKeywordBids(
      accessToken,
      keywordsChanged,
      false,
    ))
    setIsUpdatingBids(false)
    setIsShowAdjustBid(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
        keywordsChanged,
      ))
    }
  }

  const handleRefineFilter = () => {
    setCurrentFilterName(activeModule !== '' ? FILTER_NAME_ADVANCED_OP : FILTER_NAME_TARGET_OP)
  }

  const handleCopy = () => {
    const dataToCopy = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
    )).map(record => record.keyword.trim())

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} keyword${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By keywords"
          onChange={setGroupMode}
        />
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Target Auto"
            checked={removeTargetAuto}
            onChange={setRemoveTargetAuto}
          />
          <CustomTooltip placement="right">
            <p>
              Results that show "Target Auto" represent legacy Automatic Campaigns.
            </p>
            <p>
              These campaigns do not contain Automatic Targeting types (Close Match, Loose Match, etc.)
              and therefore can't be adjusted from this section.
            </p>
            <p>
              Keeping this box checked will remove them from results.
            </p>
          </CustomTooltip>
        </div>
        {
          activeModule !== BULK_ACTION_NO_IMPRESSION && (
            <div className="checkbox-wrapper">
              <CheckboxComponent
                label="Exclude Targets with Zero Spend"
                checked={removeZeroSpend}
                onChange={setRemoveZeroSpend}
              />
            </div>
          )
        }
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="select-wrapper">
          <span>Status</span>
          <Select
            classNamePrefix="status-selector"
            options={keywordStatuses}
            value={selectedStatus}
            onChange={onSelectStatus}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleRefineFilter}
          >
            Refine Filter
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedKeywords.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => { handleCopy() }}
          >
            Copy
          </button>
          <button
            type="button"
            className="btn btn-green"
            disabled={isUpdatingStates || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button
            type="button"
            className="btn btn-light-blue"
            onClick={() => { setIsShowAdjustBid(true) }}
          >
            Adjust Bid
          </button>
          <button
            type="button"
            className="btn btn-blue"
            disabled={isUpdatingBids}
            onClick={handleChangeToMaxBid}
          >
            Change to Genius Bid
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.matchType } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <GeniusBidCell
        record={record}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
      {
        campaignTableColumns.filter(c1 => bulkBidColumnList.find(c2 => c2.key === c1.key)).map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-keyword">Totals:</div>
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(summary.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col"></div>
      <div className="table-col col-genius-bid"></div>
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={summary}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'keyword') {
        return `${record.keyword} (${record.matchType} | ${capitalizeFirstLetter(record.state)})`
      }
      if (column.key === 'bid') {
        return formatCurrency(record.bid, currencySign, currencyRate)
      }
      if (column.key === 'maxCpc') {
        return typeof record.maxCpc !== 'undefined'
          ? formatCurrency(record.maxCpc, currencySign, currencyRate)
          : 'N/A'
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-keyword">
        { record.children.length } keywords
      </div>
      <div className="table-col col-adgroup" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col" />
      <div className="table-col col-genius-bid" />
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.matchType } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <div className="table-col col-genius-bid">
        {
          typeof record.maxCpc !== 'undefined'
          ? formatCurrency(record.maxCpc, currencySign, currencyRate)
          : 'N/A'
        }
      </div>
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-keyword" />
      <div className="table-col col-adgroup" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(summary.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col" />
      <div className="table-col col-genius-bid" />
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={summary}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            isLoading={isUpdatingStates || isUpdatingBids}
            columns={columnsGroup}
            defaultSort={sortBy}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-keywords"
            records={groupedKeywords}
            idField="campaign_id"
            searchFields={['keyword']}
            selectedRecords={selectedKeywords}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={activeModule !== '' ? FILTER_NAME_ADVANCED_OP : FILTER_NAME_TARGET_OP}
            useFilterModal
            columnEditorId="targetOpKeywordResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_TARGET_OP}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedKeywords}
            onChangeDate={onChangeDate}
            sorterChild={tableSorter(['adgroupName', 'state', 'keyword', 'matchType'])}
            idFieldChild="keyword_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isUpdatingStates || isUpdatingBids}
            columns={columns}
            defaultSort={sortBy}
            sorter={tableSorter(['campaignName', 'adgroupName', 'state', 'keyword', 'matchType'])}
            className="table-keywords"
            records={keywords || []}
            idField="keyword_id"
            searchFields={['keyword']}
            selectedRecords={selectedKeywords}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={activeModule !== '' ? FILTER_NAME_ADVANCED_OP : FILTER_NAME_TARGET_OP}
            useFilterModal
            columnEditorId="targetOpKeywordResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_TARGET_OP}
            getExportData={getExportData}
            renderRecord={renderKeyword}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedKeywords}
            onChangeDate={onChangeDate}
          />
        )
      }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={activeModule !== '' ? 'Advanced Optimization' : MODULE_NAME_TARGET_OP}
            onApply={() => { setCurrentFilterName('') }}
            onClose={() => { setCurrentFilterName('') }}
          />
        )
      }
    </>
  )
}

export default TargetOpKeywordTable
