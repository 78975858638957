export const GET_SKUS_SUCCEED = 'GET_SKUS_SUCCEED'

export const GET_PRODUCT_SUCCEED = 'GET_PRODUCT_SUCCEED'
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL'
export const GET_PRODUCT_START   = 'GET_PRODUCT_START'

export const GET_PRODUCT_KPI_START = 'GET_PRODUCT_KPI_START'
export const GET_PRODUCT_KPI_SUCCEED = 'GET_PRODUCT_KPI_SUCCEED'
export const GET_PRODUCT_KPI_FAIL = 'GET_PRODUCT_KPI_FAIL'

export const GET_PRODUCT_CHART_DATA_START = 'GET_PRODUCT_CHART_DATA_START'
export const GET_PRODUCT_CHART_DATA_SUCCEED = 'GET_PRODUCT_CHART_DATA_SUCCEED'
export const GET_PRODUCT_CHART_DATA_FAIL = 'GET_PRODUCT_CHART_DATA_FAIL'

export const UPDATE_BULK_COGS_SUCCESS = 'UPDATE_BULK_COGS_SUCCESS'

export const GET_PRODUCTS_SUCCEED = 'GET_PRODUCTS_SUCCEED'

export const UPDATE_PRODUCT_COG_START = 'UPDATE_PRODUCT_COG_START'
export const UPDATE_PRODUCT_COG_SUCCESS = 'UPDATE_PRODUCT_COG_SUCCESS'
export const UPDATE_PRODUCT_COG_FAIL = 'UPDATE_PRODUCT_COG_FAIL'

export const GET_PRODUCT_KEYWORDS_START = 'GET_PRODUCT_KEYWORDS_START'
export const GET_PRODUCT_KEYWORDS_SUCCESS = 'GET_PRODUCT_KEYWORDS_SUCCESS'
export const GET_PRODUCT_KEYWORDS_FAIL = 'GET_PRODUCT_KEYWORDS_FAIL'

export const GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED = 'GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED'
