export const COLUMN_EDITOR_SHOW = 'COLUMN_EDITOR_SHOW'
export const COLUMN_EDITOR_HIDE = 'COLUMN_EDITOR_HIDE'

export const SELECT_COLUMNS = 'SELECT_COLUMNS'

export const APPLY_PRODUCT_COLUMN_CHANGES = 'APPLY_PRODUCT_COLUMN_CHANGES'

export const ANP_SHOW = 'ANP_SHOW'
export const ANP_HIDE = 'ANP_HIDE'

export const AEP_SHOW = 'AEP_SHOW'
export const AEP_HIDE = 'AEP_HIDE'

export const COIN_PANE_TOGGLE = 'COIN_PANE_TOGGLE'

export const SHOW_FILTER  = 'SHOW_FILTER'
export const HIDE_FILTER  = 'HIDE_FILTER'
export const APPLY_FILTER = 'APPLY_FILTER'

export const UPDATE_STAR_REPORT_WIDGET = 'UPDATE_STAR_REPORT_WIDGET'

export const UPDATE_VIEW_REPORT_WIDGET = 'UPDATE_VIEW_REPORT_WIDGET'

export const SHOW_OR_HIDE_REPORT_SECTION = 'SHOW_OR_HIDE_REPORT_SECTION'

export const SHOW_OR_HIDE_REPORT_NOTE = 'SHOW_OR_HIDE_REPORT_NOTE'

export const SHOW_OR_HIDE_REPORT_NAME = 'SHOW_OR_HIDE_REPORT_NAME'

export const SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE = 'SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE'

export const SHOW_OR_HIDE_REPORT_TAG_TABLE = 'SHOW_OR_HIDE_REPORT_TAG_TABLE'

export const SHOW_OR_HIDE_REPORT_PRODUCT_TABLE = 'SHOW_OR_HIDE_REPORT_PRODUCT_TABLE'

export const ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH = 'ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH'

export const REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH = 'REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH'

export const UPDATE_REPORT_WIDGET_DATA = 'UPDATE_REPORT_WIDGET_DATA'

export const LOAD_REPORT_TEMPLATE = 'LOAD_REPORT_TEMPLATE'

export const CAMPAIGN_NOTE_PANE_SHOW = 'CAMPAIGN_NOTE_PANE_SHOW'
export const CAMPAIGN_NOTE_PANE_HIDE = 'CAMPAIGN_NOTE_PANE_HIDE'
