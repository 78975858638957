import React, { useState } from 'react'
import CustomTooltip from '../../CommonComponents/CustomTooltip'

const SDAdSection = ({ adInfo, onChange }) => {
  const [errorName, setErrorName] = useState('')
  const [errorLandingPage, setErrorLandingPage] = useState('')

  const handleNameChange = (event) => {
    const value = event.target.value
    if (value === '') {
      setErrorName('Please enter an ad name.')
    } else {
      setErrorName('')
    }
    onChange('name', value)
  }

  const handleLandingPageChange = (event) => {
    const value = event.target.value
    const reg = /^https?/i
    if (value === '' || !reg.test(value)) {
      setErrorLandingPage('Please enter a valid landing page URL.')
    } else {
      setErrorLandingPage('')
    }
    onChange('landingPage', value)
  }

  return (
    <div className="section-container sd-ad-section">
      <div className="section-title">
        Create an ad
      </div>
      <div className="section-note">
        Ads are what are shown when a shopper sees the campaign
        on a website or app.
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <div className="field-name">
            Ad name
          </div>
          <input
            type="text"
            value={adInfo.name}
            onChange={handleNameChange}
          />
          {
            errorName !== '' && (
              <div className="warning-message">
                { errorName }
              </div>
            )
          }
        </div>
        <div className="field-wrapper">
          <div className="field-name">
            Landing page
            <CustomTooltip placement="right">
              <p>The landing page is where shoppers are directed
                after they interact with your ad.</p>
            </CustomTooltip>
          </div>
          <input
            type="text"
            value={adInfo.landingPage}
            onChange={handleLandingPageChange}
          />
          {
            errorLandingPage !== '' && (
              <div className="warning-message">
                { errorLandingPage }
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default SDAdSection
