import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../CommonComponents/ToastComponent/toast'
import StepBreadcrumb from '../../CommonComponents/StepBreadcrumb'

import StepCampaign from './StepCampaign'
import StepAdgroup from './StepAdgroup'
import StepBid from './StepBid'

import { addTargets } from '../../../redux/actions/bulkEngine'
import { monitorJob } from '../../../redux/actions/job'
import { selectCurrentAccount } from '../../../redux/reducers/header'

import { getBidLimits } from '../../../services/helper'
import { JOB_TYPE_BULK_CREATE_TARGETS } from '../../../utils/defaultValues'

const STEP_CAMPAIGN = 1
const STEP_ADGROUP = 2
const STEP_BID = 3

const stepList = [
  {
    value: STEP_CAMPAIGN,
    name: 'Choose campaigns',
    description: (
      <>
        <p>
          Sponsored Products automatic targeting campaigns
          are not eligible to target keywords/products manually.
        </p>
        <p>
          Sponsored Displays campaigns with legacy tactic types
          are not eligible to target products/categories.
        </p>
      </>
    ),
  },
  { value: STEP_ADGROUP, name: 'Choose ad groups' },
  { value: STEP_BID, name: 'Confirm match type and bids' },
]

const MAX_KEYWORDS_PER_ADGROUP = 1000
const MAX_TARGETS_PER_ADGROUP = 10000 // 10k

// forCategory - true if this is for Product Targeting/Category Targeting.
const TargetAddModal = ({
  show,
  targets,
  forTargetEx,
  forStEx,
  forPtEx,
  forCategory,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [step, setStep] = useState(STEP_CAMPAIGN)
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [selectedAdgroups, setSelectedAdgroups] = useState([])
  const [payload, setPayload] = useState([])
  const [audience, setAudience] = useState([])
  const [lookback, setLookback] = useState()
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    if (show) {
      setStep(STEP_CAMPAIGN)
      setSelectedCampaigns([])
      setSelectedAdgroups([])
      setPayload([])
    }
  }, [show]) // eslint-disable-line

  const handleBack = () => {
    if (step === STEP_ADGROUP) {
      setSelectedAdgroups([])
    }
    setStep(step - 1)
  }

  const handleConfirm = async () => {
    if (step !== STEP_BID) {
      // For the step to choose ad groups.
      if (step === STEP_ADGROUP) {
        // Check if there is an ad group with keywords/targets more than allowed.
        const invalid = adgroupsToAddTargetsData.find((adgroup) => {
          if (!selectedAdgroups.includes(adgroup.adgroup_id.toString())) {
            return false
          }

          if (parseInt(adgroup.targets, 10) > 0
            && (parseInt(adgroup.targets, 10) + targets.length) > MAX_TARGETS_PER_ADGROUP) {
            toast.show({
              title: 'Warning',
              description: `You can add up to ${MAX_TARGETS_PER_ADGROUP} product targets per ad group.`,
            })
            return true
          } else if (parseInt(adgroup.keywords, 10) > 0
            && (parseInt(adgroup.keywords, 10) + targets.length) > MAX_KEYWORDS_PER_ADGROUP) {
            toast.show({
              title: 'Warning',
              description: `You can add up to ${MAX_KEYWORDS_PER_ADGROUP} keywords per ad group.`,
            })
            return true
          }
          return false
        })

        if (invalid) {
          return
        }
      }

      setStep(step + 1)
      return
    }

    // Add keywords/targets depending on ad group selected.
    // Check if there is a target with an invalid bid price.
    const errors = []
    payload.forEach((target) => {
      if (target.bid === '' || isNaN(target.bid)) {
        errors.push(`Enter a bid price for "${target.target}"`)
        return
      }

      const bid = parseFloat(target.bid)
      const bidLimits = getBidLimits(
        target.campaignType,
        currentAccount?.country_id,
        target.cost_type
      )
      if (bid < bidLimits[0] || bid > bidLimits[1]) {
        errors.push(`The bid price for "${target.target}" should be between ${bidLimits[0]} and ${bidLimits[1]}.`)
      }
    })
    if (errors.length) {
      toast.show({
        title: 'Warning',
        description: errors.join('<br />'),
      })
      return
    }

    // Fill in match type values.
    const sanitizedPayload = payload.map(record => (
      Object.assign({}, record, {
        matchType: record.matchType ? record.matchType.value : '',
      })
    ))

    if (!sanitizedPayload.length) {
      toast.show({
        title: 'Warning',
        description: 'There is nothing to add.',
      })
      return
    }

    if (forPtEx) {
      const sdCampaign = sanitizedPayload.find(campaign => (
        campaign.campaignType === 'sd'
        && campaign.tactic === 'T00030'
      ))

      if (sdCampaign && !audience.length) {
        toast.show({
          title: 'Warning',
          description: 'Please choose an audience.',
        })
        return
      }
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(addTargets(
      accessToken,
      sanitizedPayload,
      forCategory ? true: false,
      audience,
      lookback,
      false,
    ))
    setIsAdding(false)
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_CREATE_TARGETS,
      ))
      onClose()
    }
  }

  const renderSteps = () => {
    return (
      <StepBreadcrumb
        stepList={stepList}
        currentStep={step}
      />
    )
  }

  const renderContents = () => {
    if (step === STEP_CAMPAIGN) {
      return (
        <StepCampaign
          campaignList={campaignsWithHistory}
          selectedCampaigns={selectedCampaigns}
          forPtEx={forPtEx}
          forCategory={forCategory}
          onSelect={setSelectedCampaigns}
        />
      )
    }

    if (step === STEP_ADGROUP) {
      return (
        <StepAdgroup
          selectedCampaigns={selectedCampaigns}
          selectedAdgroups={selectedAdgroups}
          forPtEx={forPtEx}
          onSelect={setSelectedAdgroups}
        />
      )
    }

    if (step === STEP_BID) {
      return (
        <StepBid
          targets={targets}
          payload={payload}
          selectedAdgroups={selectedAdgroups}
          forTargetEx={forTargetEx}
          forPtEx={forPtEx}
          forCategory={forCategory}
          isAddingTargets={isAdding}
          onChange={setPayload}
          onChangeAudience={setAudience}
          onChangeLookback={setLookback}
        />
      )
    }

    return null
  }

  let isConfirmDisabled = isAdding
  if (step === STEP_CAMPAIGN && selectedCampaigns.length === 0) {
    isConfirmDisabled = true
  } else if (step === STEP_ADGROUP && selectedAdgroups.length === 0) {
    isConfirmDisabled = true
  }

  return (
    <Modal className="target-add-modal" backdrop="static" size="lg" show={show}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Add { forStEx ? 'Search Terms' : 'Targets' } to Campaign
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { renderSteps() }
        { renderContents() }
      </Modal.Body>
      <Modal.Footer>
        {
          step !== STEP_CAMPAIGN && (
            <button
              type="button"
              className="rs-btn rs-btn-default"
              onClick={handleBack}
            >
              Back
            </button>
          )
        }
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isConfirmDisabled}
          onClick={handleConfirm}
        >
          { step === STEP_BID ? 'Confirm' : 'Next' }
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TargetAddModal
