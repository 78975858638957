import React, { useState } from 'react'

import CustomTable from '../../CommonComponents/CustomTableComponent'
import LoaderComponent from '../../CommonComponents/LoaderComponent'

const TAB_SUGGESTED = 'suggested'
const TAB_SEARCH = 'search'
const TAB_ENTER = 'enter'

const tabList = [
  { value: TAB_SUGGESTED, name: 'Suggested' },
  { value: TAB_SEARCH, name: 'Search' },
  { value: TAB_ENTER, name: 'Enter list' },
]

const ProductTable = ({ isLoading, products, targetings, defaultBid,
  suggestedProducts, onSearch, onSearchAsins, onTarget }) => {
  const [currentTab, setCurrentTab] = useState(TAB_SUGGESTED)
  const [asinList, setAsinList] = useState('')
  const [isAsinsSearching, setAsinsSearching] = useState(false)

  const handleTarget = (product) => {
    const duplicate = targetings
      .filter(category => category.type === 'product')
      .find(category => category.asin === product.asin)

    if (duplicate) {
      return
    }

    onTarget(prevTargetings => ([
      {
        ...product,
        id: product.asin,
        isTargeted: true,
        type: 'product',
        bid: defaultBid,
      },
      ...prevTargetings,
    ]))
  }

  const handleAsinsTarget = async () => {
    const asins = asinList.split('\n').map(asin => asin.trim().toUpperCase()).join(',')
    setAsinsSearching(true)
    const response = await onSearchAsins(asins)
    setAsinsSearching(false)

    const productByAsin = {}
    response.forEach((product) => {
      productByAsin[product.asin] = product
    })

    asinList.toUpperCase().split('\n').forEach((asin) => {
      if (productByAsin[asin]) {
        handleTarget(productByAsin[asin])
      } else {
        handleTarget({
          asin,
          ASIN: asin,
          name: '',
        })
      }
    })
  }

  const renderProduct = (product) => {
    const isExisting = targetings.filter(item => item.type === 'product')
      .find(item => item.asin === product.asin)

    return (
      <>
        <div className="table-col col-product">
          <img src={product.image} alt={product.name} />
          <div className="product-info">
            <div className="product-name" title={product.name}>
              { product.name }
            </div>
            <div className="product-asin-info">
              ASIN: { product.asin }
            </div>
          </div>
        </div>
        <div className="table-col">
          {
            isExisting ? (
            <button type="button" className="btn btn-blue disabled">Targeted</button>
            ) : (
            <button type="button" className="btn btn-blue" onClick={() => { handleTarget(product) }}>
              Target
            </button>
            )
          }
        </div>
      </>
    )
  }

  return (
    <div className={`product-tab-container${(isLoading || isAsinsSearching) ? ' loading' : ''}`}>
      { (isLoading || isAsinsSearching) && <LoaderComponent/> }
      <div className="sub-tab-list">
        {
          tabList.map(tab => (
            <button
              key={tab.value}
              type="button"
              className={currentTab === tab.value ? 'selected' : ''}
              onClick={() => { setCurrentTab(tab.value) }}
            >
              { tab.name }
            </button>
          ))
        }
      </div>
      {
        currentTab === TAB_SUGGESTED && (
          <CustomTable
            className="table-products"
            records={suggestedProducts}
            noCheckBox
            idField="asin"
            searchFields={['name', 'asin']}
            renderRecord={renderProduct}
          >
            <div className="table-col col-product">Product</div>
            <div className="table-col">
            </div>
          </CustomTable>
        )
      }
      {
        currentTab === TAB_SEARCH && (
          <CustomTable
            className="table-products"
            records={products}
            noCheckBox
            idField="asin"
            searchFields={['name', 'asin']}
            renderRecord={renderProduct}
            onSearch={onSearch}
          >
            <div className="table-col col-product">Product</div>
            <div className="table-col">
            </div>
          </CustomTable>
        )
      }
      {
        currentTab === TAB_ENTER && (
          <>
            <textarea
              className="asin-list"
              placeholder="Enter ASINs separated by a new line."
              rows={5}
              value={asinList}
              onChange={(event) => { setAsinList(event.target.value) }}
            />
            <button
              type="button"
              className="btn btn-blue"
              disabled={asinList === ''}
              onClick={handleAsinsTarget}
            >
              Target
            </button>
          </>
        )
      }
    </div>
  )
}

export default ProductTable
