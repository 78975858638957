import {
  COLUMN_EDITOR_SHOW,
  COLUMN_EDITOR_HIDE,
  SELECT_COLUMNS,
  APPLY_PRODUCT_COLUMN_CHANGES,
  ANP_SHOW,
  ANP_HIDE,
  AEP_SHOW,
  AEP_HIDE,
  COIN_PANE_TOGGLE,
  SHOW_FILTER,
  HIDE_FILTER,
  APPLY_FILTER,
  UPDATE_STAR_REPORT_WIDGET,
  UPDATE_VIEW_REPORT_WIDGET,
  SHOW_OR_HIDE_REPORT_SECTION,
  SHOW_OR_HIDE_REPORT_NOTE,
  SHOW_OR_HIDE_REPORT_NAME,
  SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE,
  SHOW_OR_HIDE_REPORT_TAG_TABLE,
  SHOW_OR_HIDE_REPORT_PRODUCT_TABLE,
  ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
  REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
  UPDATE_REPORT_WIDGET_DATA,
  LOAD_REPORT_TEMPLATE,
  CAMPAIGN_NOTE_PANE_SHOW,
  CAMPAIGN_NOTE_PANE_HIDE,
} from '../actionTypes/pageGlobal'

export const applyProductColumnChanges = (data) => {
  return {
    type: APPLY_PRODUCT_COLUMN_CHANGES,
    data
  }
}

export const selectColumns = (data, columnEditorId = '') => {
  return {
    type: SELECT_COLUMNS,
    data,
    columnEditorId,
  }
}

export const showColumnEditorAction = (id = '') => {
  return {
    type: COLUMN_EDITOR_SHOW,
    id,
  }
}

export const hideColumnEditorAction = () => {
  return {
    type: COLUMN_EDITOR_HIDE
  }
}

// Show add to new portfolio
export const showANPAction = (campaigns) => {
  return {
    type: ANP_SHOW,
    campaigns
  }
}

// Hide add to new portfolio.
export const hideANPAction = () => {
  return {
    type: ANP_HIDE,
  }
}

// Show add to existing portfolio
export const showAEPAction = (campaigns) => {
  return {
    type: AEP_SHOW,
    campaigns
  }
}

// Hide add to existing portfolio.
export const hideAEPAction = () => {
  return {
    type: AEP_HIDE,
  }
}

export const toggleCoinPane = () => {
  return {
    type: COIN_PANE_TOGGLE,
  }
}

export const showFilter = (filterName) => {
  return {
    type: SHOW_FILTER,
    data: filterName,
  }
}

export const hideFilter = (filterName) => {
  return {
    type: HIDE_FILTER,
    data: filterName,
  }
}

export const applyFilter = (filterName, values) => {
  return {
    type: APPLY_FILTER,
    data: {
      filterName,
      values,
    },
  }
}

export const changeHighlightWidgetState = (section, widget) => {
  return  {
    type: UPDATE_STAR_REPORT_WIDGET,
    data: {
      section,
      widget
    }
  }
}

export const changeViewWidgetState = (section, widget) => {
  return {
    type: UPDATE_VIEW_REPORT_WIDGET,
    data: {
      section,
      widget
    }
  }
}

export const changeViewSectionState = (section) => {
  return {
    type: SHOW_OR_HIDE_REPORT_SECTION,
    data: {
      section
    }
  }
}

export const changeViewNoteState = (section) => {
  return {
    type: SHOW_OR_HIDE_REPORT_NOTE,
    data: {
      section
    }
  }
}

export const changeViewReportNameState = () => {
  return {
    type: SHOW_OR_HIDE_REPORT_NAME,
  }
}

export const changeViewReportPortfolioTableState = () => {
  return {
    type: SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE,
  }
}

export const changeViewReportTagTableState = () => {
  return {
    type: SHOW_OR_HIDE_REPORT_TAG_TABLE,
  }
}

export const changeViewReportTableTableState = () => {
  return {
    type: SHOW_OR_HIDE_REPORT_PRODUCT_TABLE,
  }
}

export const addNewCustomMetric = (params) => {
  return {
    type: ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
    data: params
  }
}

export const removeCustomMetric = (widget) => {
  return {
    type: REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
    data: widget
  }
}

export const updateReportWidgetData = (section, widget, data) => {
  return {
    type: UPDATE_REPORT_WIDGET_DATA,
    section,
    widget,
    data
  }
}

export const loadReportTemplate = (contents) => {
  return {
    type: LOAD_REPORT_TEMPLATE,
    data: contents,
  }
}

export const showCampaignNote = () => {
  return {
    type: CAMPAIGN_NOTE_PANE_SHOW,
  }
}

export const hideCampaignNote = () => {
  return {
    type: CAMPAIGN_NOTE_PANE_HIDE,
  }
}
