import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { differenceInSeconds } from 'date-fns'

import { ReactComponent as UnionSvg } from '../../assets/svg/login/union.svg'
import WelcomeBgSvg from '../../assets/svg/welcome-bg.svg'
import WelcomeTimerSvg from '../../assets/svg/welcome-timer.svg'

const TIMER_LIMIT = 24 * 60 * 60

const STORAGE_KEY_FOR_HIDE = 'hide_welcome_dialog'

const WelcomeDialog = () => {
  const currentUser = useSelector(state => state.auth.user)

  const [timeLeft, setTimeLeft] = useState(0)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (!currentUser.created_at) {
      return
    }

    const secondsSinceSignup = differenceInSeconds(currentUser.created_at * 1000, new Date())
    if (secondsSinceSignup >= TIMER_LIMIT) {
      return
    }

    setTimeLeft(TIMER_LIMIT - secondsSinceSignup)
    const interval = setInterval(() => {
      setTimeLeft(prev => prev - 60)
    }, 1000 * 60)

    return () => {
      clearInterval(interval)
    }
  }, []) // eslint-disable-line

  const handleHide = () => {
    setHide(true)
    localStorage.setItem(STORAGE_KEY_FOR_HIDE, 'true')
  }

  if (timeLeft <= 0 || localStorage.getItem(STORAGE_KEY_FOR_HIDE) !== null || hide) {
    return null
  }

  let hour = Math.floor(timeLeft / 3600)
  if (hour < 10) {
    hour = `0${hour}`
  }

  let minute = Math.floor((timeLeft % 3600) / 60)
  if (minute < 10) {
    minute = `0${minute}`
  }

  return (
    <Modal className="welcome-dialog" backdrop="static" show>
      <div className="left-section">
        <img className="left-bg" src={WelcomeBgSvg} alt="Background" />
        <UnionSvg />
        <div className="timer-image-wrapper">
          <img src={WelcomeTimerSvg} alt="Timer" />
        </div>
        <div className="timer-contents">
          <div className="timer-heading">
            Your data will arrive
          </div>
          <div className="timer-subheading">
            in less than
          </div>
          <div className="timer-value">
            {hour}:{minute}
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="welcome-heading">
          Welcome to the PPC Entourage Family!
        </div>
        <div className="welcome-email">
          We'll email you as soon as your data has arrived
        </div>
        <div className="welcome-subheading">
          In the meantime...
        </div>
        <div className="welcome-description">
          Finish the <strong>Quick Results Flow</strong>.
          This way, you'll be ready to take action as soon as your data comes.
          You'll also earn some Genius Coins which you can redeem
          in the marketplace for cool things like ad creatives, coaching and more.
        </div>
        <div className="button-container">
          <button type="button" className="btn btn-blue" onClick={handleHide}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default WelcomeDialog
