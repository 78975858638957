import React, { useState } from 'react'
import { Dropdown } from 'rsuite'

import CustomTooltip from '../CommonComponents/CustomTooltip'

import {
  MODULE_NAME_PT_EX_ASIN,
  MODULE_NAME_PT_EX_CATEGORY,
} from '../../utils/defaultValues'

export const MODULE_NAME_PT_EX = 'Product Expansion'

export const SUB_MODULE_ASIN = 'asin'
export const SUB_MODULE_CATEGORY = 'category'

const subModuleList = [
  {
    key: SUB_MODULE_ASIN,
    name: MODULE_NAME_PT_EX_ASIN,
    description: (
      <>
        <p>
          Our system will find related products that have generated
          the most sales for you and allow you to target them
          in a Product Targeting campaign/ad group.
        </p>
      </>
    ),
  },
  {
    key: SUB_MODULE_CATEGORY,
    name: MODULE_NAME_PT_EX_CATEGORY,
    description: (
      <>
        <p>
          Our system will find the categories related to products that have generated
          the most sales for you and allow you to target these categories
          in a Product Targeting campaign/ad group.
        </p>
      </>
    ),
  },
]

const PtExSection = ({ isCampaignsSelected, isNonEndemicAccount, onFind }) => {
  const [activeSubModule, setActiveSubModule] = useState(!isNonEndemicAccount ? SUB_MODULE_ASIN : SUB_MODULE_CATEGORY)

  const handleSubModuleSelect = (module) => {
    if (activeSubModule !== module.key) {
      setActiveSubModule(module.key)
    }
  }

  const renderAction = () => {
    if (!activeSubModule) {
      return null
    }

    const label = activeSubModule === SUB_MODULE_ASIN ? 'Find ASINs' : 'Find Categories'

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          disabled={!isCampaignsSelected}
          onClick={() => { onFind(activeSubModule) }}
        >
          { !isCampaignsSelected ? 'Select Campaigns to Continue' : label }
        </button>
      </>
    )
  }

  const renderTooltip = () => {
    if (!activeSubModule) {
      return null
    }

    if (activeSubModule === SUB_MODULE_ASIN) {
      return (
        <CustomTooltip placement="right">
          <ul>
            <li>First, determine your target ACoS %.</li>
            <li>Select the campaigns that you wish to pull ASINs from. Click "Find ASINs".</li>
            <li>
              The results will show you all products that have generated sales for you within your target ACoS.
              You may then add them to a new or existing Product Targeting campaign or ad group.
            </li>
          </ul>
        </CustomTooltip>
      )
    }

    if (activeSubModule === SUB_MODULE_CATEGORY) {
      return (
        <CustomTooltip placement="right">
          <ul>
            <li>First, determine your target ACoS %.</li>
            <li>Select the campaigns that you wish to pull category information from. Click "Find Categories".</li>
            <li>
              The results will show you all categories related to products that have
              generated sales for you within your target ACoS. You may then add them
              to a new or existing Product Targeting campaign or ad group.
            </li>
          </ul>
        </CustomTooltip>
      )
    }

    return null
  }

  let dropdownLabel = 'Select Sub Module'
  if (activeSubModule) {
    const active = subModuleList.find(module => module.key === activeSubModule)
    if (active) {
      dropdownLabel = active.name
    }
  }

  return (
    <>
      {
        !isNonEndemicAccount && (
          <Dropdown title={dropdownLabel}>
            {
              subModuleList.map(module => (
                <Dropdown.Item
                  key={module.key}
                  active={module.key === activeSubModule}
                  onSelect={() => { handleSubModuleSelect(module) }}
                >
                  { module.name }
                  <CustomTooltip placement="right">
                    { module.description }
                  </CustomTooltip>
              </Dropdown.Item>
              ))
            }
          </Dropdown>
        )
      }
      { renderAction() }
      { renderTooltip() }
    </>
  )
}

export default PtExSection
