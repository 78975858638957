/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import Select from 'react-select'
import { Icon, IconButton, Toggle } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { FiHome } from 'react-icons/fi'
import { BsCaretDownFill } from 'react-icons/bs'

import CoinComponent from './CoinComponent'

import {
  setSelectedUserInfo,
  setCurrencyInfo,
} from '../../redux/actions/header'

import { initiateRefresh } from '../../redux/actions/dashboard'

import { currencyList, countryList } from '../../utils/defaultValues'
import { getAccountLabel, getCurrencyForCountry } from '../../services/helper'
import { selectCurrentAccount } from '../../redux/reducers/header'

const HeaderComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user, getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencyCode = useSelector(state => state.header.currencyCode)
  const currencyRateList = useSelector(state => state.header.currencyRateList)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const accountList = useSelector(state => state.header.accountList)

  const [currentCurrency, setCurrentCurrency] = useState(currencyList[0])
  const [calcCurrencyRateList, setCalcCurrencyRateList] = useState({})
  const [showCancelled, setShowCancelled] = useState(false)
  const [accountSortedBy, setAccountSortedBy] = useState('spend')
  const [selectedCountry, setSelectedCountry] = useState(countryList[0])
  const [isInitiatingRefresh, setIsInitiatingRefresh] = useState(false)
  const [searchBy, setSearchBy] = useState('')

  useEffect(() => {
    if (!currencyRateList || !Object.keys(currencyRateList).length) {
      return
    }
    calculateCurrencyRateList()
  }, [currencyRateList]) // eslint-disable-line

  useEffect(() => {
    if (currentCurrency.code !== currencyCode && currencyCode) {
      const currency = currencyList.find(currency => currency.code === currencyCode)
      setCurrentCurrency(currency)
    }
  }, [currencyCode]) // eslint-disable-line

  useEffect(() => {
    if (!accountList || !accountList.length) {
      return
    }

    let initialSet = false
    let accountToShow
    if (currentUserId) {
      accountToShow = accountList.find((account) => (
        parseInt(account.user_id, 10) === parseInt(currentUserId, 10)
        && (account.seller_type !== 'agency' ||  account.sub_type !== 'dsp')
      ))
    }

    // It means you've switched from one customer to another.
    if (!accountToShow) {
      initialSet = true

      const nonDspAccounts = accountList.filter(account => (
        account.seller_type !== 'agency' ||  account.sub_type !== 'dsp'
      )).sort((account1, account2) => {
        const account1CurrencyCode = getCurrencyForCountry(account1.country_id).currencyCode

        const account1CurrencyRate = calcCurrencyRateList.rates
          ? calcCurrencyRateList.rates[account1CurrencyCode]
          : 0

        const account2CurrencyCode = getCurrencyForCountry(account2.country_id).currencyCode
        const account2CurrencyRate = calcCurrencyRateList.rates
          ? calcCurrencyRateList.rates[account2CurrencyCode]
          : 0

        return account2.totalSpend / account2CurrencyRate
          - account1.totalSpend / account1CurrencyRate
      })
      if (nonDspAccounts.length) {
        accountToShow = nonDspAccounts[0]
      }
    }

    if (
      parseInt(currentAccount?.user_id || 0, 10)
      !== parseInt(accountToShow.user_id, 10)
    ) {
      (async () => {
        const accessToken = await getAccessTokenSilently()

        dispatch(setSelectedUserInfo(accessToken, {
          ...accountToShow,
        }, initialSet))
      })()
    } else if (
        (
          (currentAccount?.cancelledAt || accountToShow.cancelledAt)
          && currentAccount?.cancelledAt !== accountToShow.cancelledAt
        )
        ||
        currentAccount?.fetchingData !== accountToShow.fetchingData
    ) {
      dispatch(setSelectedUserInfo(null, {
        ...accountToShow,
      }, true))
    }

    const accountCurrency = getCurrencyForCountry(accountToShow.country_id)
    if (currencyCode !== accountCurrency.code) {
      dispatch(setCurrencyInfo({
        currencyCode: accountCurrency.code,
        currencyRate: 1,
        currencySign: accountCurrency.sign
      }))
    }
  }, [accountList]) // eslint-disable-line

  if (currentAccount?.cancelledAt
    && history.location.pathname !== '/reactivation') {
    history.push('/reactivation')
  }

  // calculate the rate with base currency
  const calculateCurrencyRateList = (baseCurrency = 'USD') => {
    let calculatedResult = { ...currencyRateList }

    const baseToUSD = parseFloat(calculatedResult.rates[baseCurrency])
    calculatedResult.base = baseCurrency
    if (baseToUSD !== 1) {
      Object.keys(calculatedResult.rates).forEach((rate) => {
        calculatedResult.rates[rate] = parseFloat(calculatedResult.rates[rate]) / baseToUSD
      })
    }

    setCalcCurrencyRateList(calculatedResult)
    return calculatedResult
  }

  const handleCurrencyChange = (event, selectedCurrency) => {
    event.preventDefault()
    setCurrentCurrency(selectedCurrency)

    const accountCurrencyCode = getCurrencyForCountry(currentAccount?.country_id).currencyCode

    const isDefaultCurrency = selectedCurrency.currencyCode === accountCurrencyCode

    let calculatedResult = calcCurrencyRateList
    if (!isDefaultCurrency) {
      calculatedResult = calculateCurrencyRateList(accountCurrencyCode)
    }

    dispatch(setCurrencyInfo({
      currencyCode: selectedCurrency.code,
      currencyRate: isDefaultCurrency ? 1 : calculatedResult.rates[selectedCurrency.currencyCode],
      currencySign: selectedCurrency.sign
    }))
  }

  const handleAccountChange = (event, account) => {
    event.preventDefault()
    const accountCurrency = getCurrencyForCountry(account.country_id);

    (async () => {
      const accessToken = await getAccessTokenSilently()

      dispatch(setSelectedUserInfo(accessToken, {
        ...account,
      }))
    })()

    dispatch(setCurrencyInfo({
      currencyCode: accountCurrency.code,
      currencyRate: 1,
      currencySign: accountCurrency.sign
    }))

    if (history.location.pathname.indexOf('/campaign/') === 0
      || history.location.pathname.indexOf('/product/') === 0) {
      history.push('/dashboard')
    }
  }

  const handleShowCancelled = (event) => {
    event.preventDefault()
    setShowCancelled(!showCancelled)
  }

  const handleRefreshInitiate = async () => {
    setIsInitiatingRefresh(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(initiateRefresh(accessToken))
    setIsInitiatingRefresh(false)
  }

  const accountToRender = useMemo(() => (
    (accountList || []).filter(account => {
      if (account.seller_type === 'agency' && account.sub_type === 'dsp') {
        return false
      }

      if (!showCancelled && account.cancelledAt) {
        return false
      }
      if (selectedCountry.value !== 'all' && selectedCountry.value !== account.country_id) {
        return false
      }
      let brandName = ''
      if (account.brand_name) {
        brandName = account.brand_name
      } else {
        brandName = account.sellerid || ''
      }
      if (searchBy && !brandName.toLowerCase().includes(searchBy.toLowerCase())) {
        return false
      }
      return true
    }).sort((account1, account2) => {
      if (accountSortedBy === 'name') {
        let brandName1
        if (account1.brand_name) {
          brandName1 = account1.brand_name
        } else {
          brandName1 = account1.sellerid || 'N/A'
        }

        let brandName2
        if (account2.brand_name) {
          brandName2 = account2.brand_name
        } else {
          brandName2 = account2.sellerid || 'N/A'
        }

        return brandName1.localeCompare(brandName2)
      }
      const account1CurrencyCode = getCurrencyForCountry(account1.country_id).currencyCode

      const account1CurrencyRate = calcCurrencyRateList.rates
        ? calcCurrencyRateList.rates[account1CurrencyCode]
        : 0

      const account2CurrencyCode = getCurrencyForCountry(account2.country_id).currencyCode
      const account2CurrencyRate = calcCurrencyRateList.rates
        ? calcCurrencyRateList.rates[account2CurrencyCode]
        : 0

      return account2.totalSpend / account2CurrencyRate
        - account1.totalSpend / account1CurrencyRate
    })
  ), [accountList, showCancelled, accountSortedBy, selectedCountry, searchBy, calcCurrencyRateList])

  const hasCancelled = useMemo(() => (
    typeof (accountList || []).find(account => account.cancelledAt) !== 'undefined'
  ), [accountList])

  const renderAccountToggle = () => {
    return (
      <a href="#" className="dropdown-toggle" onClick={(event) => { event.preventDefault() }}>
        { getAccountLabel(currentAccount) }
        <span className="initials-display">
          { user?.email ? user?.email.charAt(0) : '' }
        </span>
        <BsCaretDownFill size={10} />
      </a>
    )
  }

  const renderAccounts = () => {
    return accountToRender.map(account => (
      <a
        key={account.user_id}
        href="#"
        onClick={(event) => { handleAccountChange(event, account) }}
      >
        { getAccountLabel(account) }
      </a>
    ))
  }

  return (
    <div className="header-component">
      <div className="header-left">
        <Link to="/dashboard" title="Dashboard">
          <FiHome color="#A2A2A2" />
        </Link>
      </div>
      <div className="header-right">
        <IconButton
          icon={<Icon icon="refresh" />}
          size="md"
          appearance="default"
          title="We'll Refresh data from Amazon. Maximum 2 per day"
          disabled={isInitiatingRefresh}
          onClick={handleRefreshInitiate}
        />
        <CoinComponent />
        <div className="currency dropdown" tabIndex="0">
          <a href="#" className="dropdown-toggle" onClick={(event) => { event.preventDefault() }}>
            <span className={`flag ${currentCurrency.flag}`}>&nbsp;</span>
            <span className="value-text">{currentCurrency.text}</span>
            <span className="value-text">{currentCurrency.sign}</span>
          </a>
          <div className="dropdown-content">
            {currencyList.map(currency =>
              <a
                key={currency.code}
                href="#"
                onClick={event => { handleCurrencyChange(event, currency) }}
              >
                <span className={`flag ${currency.flag}`}>&nbsp;</span>
                <span className="currency-text">{currency.text}</span>
                <span className="currency-sign">{currency.sign}</span>
              </a>
            )}
          </div>
        </div>
        <div className="account-list dropdown" tabIndex={0}>
          { renderAccountToggle() }
          <div className="dropdown-content">
            <div className="account-filter">
              <input
                className="filter-input"
                placeholder="Type Here to Search..."
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            <div className="sort-options">
              <div className="sort-option-panel">
                <Select
                  className="account-country-selector"
                  options={countryList}
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                />
              </div>
              <div className="sort-option-panel">
                <Toggle
                  size="xs"
                  checked={accountSortedBy === 'spend'}
                  checkedChildren="Spend"
                  unCheckedChildren="Name"
                  onChange={(checked) => setAccountSortedBy(checked ? 'spend' : 'name')}
                />
              </div>
            </div>
            { renderAccounts() }
            {
              hasCancelled && (
                <a
                  href="#"
                  className="account-toggle"
                  onClick={handleShowCancelled}
                >
                  { !showCancelled ? 'Show cancelled accounts' : 'Hide cancelled accounts'}
                </a>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent
