import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import KeywordTable from './KeywordTable'
import TargetTable from './TargetTable'

import {
  getBidData,
  getBidTargetData,
} from '../../../../redux/actions/campaignDetail'

const BidTab = ({ campaignType, currentAdgroup, isProfitable,
  isNonEndemicAccount, cpcTarget }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let abortCtrl
    if (campaignType && currentAdgroup && currentDetail) {
      abortCtrl = new AbortController();
      (async () => {
        const params = {
          campaignId: currentDetail.campaign_id,
          adgroupId: currentAdgroup.adgroupid,
          campaignType,
          targetAcos: currentAcos,
          isProfitable,
          isNonEndemicAccount,
          cpcTarget,
        }

        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        if (currentAdgroup.targetType === 'products') {
          await dispatch(getBidTargetData(accessToken, params, abortCtrl.signal))
        } else {
          await dispatch(getBidData(accessToken, params, abortCtrl.signal))
        }
        setIsLoading(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [ // eslint-disable-line
    campaignType,
    currentDetail ? currentDetail.campaign_id : null, // eslint-disable-line
    currentAdgroup,
    currentStartDate,
    currentEndDate,
    isProfitable,
  ])

  if (!currentAdgroup) {
    return null
  }

  if (currentAdgroup.targetType !== 'products') {
    return (
      <KeywordTable
        isLoading={isLoading}
        campaignType={campaignType}
      />
    )
  }

  return (
    <TargetTable
      isLoading={isLoading}
      campaignType={campaignType}
      campaignId={currentDetail?.campaign_id}
      adgroupId={currentAdgroup?.adgroupid}
    />
  )
}

export default BidTab
