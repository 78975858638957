// Smart pilot manager pane header.
import React from 'react'
import { Toggle } from 'rsuite'

import CampaignInfo from './CampaignInfo'
import AdgroupSelector from './AdgroupSelector'
import TabSelector from './TabSelector'

const Header = (props) => {
  const { campaign, settings, isCampaignSelectorVisible, tabList, activeTab,
    isNonEndemicAccount, onAdgroupSelect, onSetActiveTab,
    onTurnOnOff, onSelectTemplate, onClose } = props

  let isAutoSP = false
  let isSD = false
  let hasKeywordAdgroups = true
  let hasProductAdgroups = true
  if (campaign) {
    isAutoSP = campaign.basic[0].type === 'sp'
      && campaign.basic[0].targeting_type === 'auto'

    // For Sponsored Displays, Search Term Expansion is disabled.
    isSD = campaign.basic[0].type === 'sd'

    hasKeywordAdgroups = campaign.keywordAdgroups.length !== 0
    hasProductAdgroups = campaign.productAdgroups.length !== 0
  }

  const hasExModules =
    (
      (!isSD && hasKeywordAdgroups)
      ||
      hasProductAdgroups
    ) && !isNonEndemicAccount

  const level = settings.adgroup_id === null || settings.adgroup_id === 0 ? 'Campaign' : 'Ad group'

  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Edit Smart Pilot
          </span>
          <Toggle
            checked={settings.is_active}
            checkedChildren={`${level} level on`}
            unCheckedChildren={`${level} level off`}
            onChange={onTurnOnOff}
          />
        </div>
        <div className="right-column">
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      <CampaignInfo
        campaign={campaign}
        settings={settings}
        onSelectTemplate={onSelectTemplate}
      />
      {
        !isCampaignSelectorVisible && (
          <AdgroupSelector
            campaign={campaign}
            settings={settings}
            onChange={onAdgroupSelect}
          />
        )
      }
      {
        !isCampaignSelectorVisible && !isAutoSP && hasExModules && (
          <TabSelector
            tabList={tabList}
            activeTab={activeTab}
            onSetActiveTab={onSetActiveTab}
          />
        )
      }
    </div>
  )
}

export default Header
