import React from 'react'
import Select from 'react-select'

import { toast } from '../ToastComponent/toast'

import {
  adjustBidOptions,
  ADJUST_BID_CPC,
} from '../../../utils/defaultValues'

const BidAdjustComponent = ({ adjustBidOption, bidValue, isAdjusting,
  onChangeAdjustBidOption, onChangeBidValue, onApply, onCancel }) => {
  const handleApply = () => {
    if (adjustBidOption.value !== ADJUST_BID_CPC
      && (bidValue === '' || isNaN(bidValue))) {
      toast.show({
        title: 'Warning',
        description: 'Please enter the valid bid value.',
      })
      return
    }

    onApply()
  }

  return (
    <div className="adjust-bid-section">
      <Select
        className="adjust-option-selector"
        value={adjustBidOption}
        options={adjustBidOptions}
        onChange={onChangeAdjustBidOption}
      />
      {
        adjustBidOption.value !== ADJUST_BID_CPC && (
          <input
            type="number"
            placeholder="Enter Bid Value"
            value={bidValue}
            onChange={(event) => { onChangeBidValue(event.target.value) }}
          />
        )
      }
      <button
        type="button"
        className="btn btn-blue"
        disabled={isAdjusting}
        onClick={handleApply}
      >
        Apply
      </button>
      <button
        type="button"
        className="btn btn-white"
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
  )
}

export default BidAdjustComponent
