import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Radio, RadioGroup } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import { createNegatives } from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'

import { validateNKLength } from '../../services/validator'
import {
  JOB_TYPE_BULK_CREATE_NEGATIVES,
  NEGATIVE_MATCH_TYPE_EXACT,
  NEGATIVE_MATCH_TYPE_PHRASE,
} from '../../utils/defaultValues'
import { isAsin } from '../../services/helper'

const NegativeCreatorModal = ({
  searchTerms,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [targetLevel, setTargetLevel] = useState('campaign')
  const [matchType, setMatchType] = useState(NEGATIVE_MATCH_TYPE_EXACT)
  const [isAdding, setIsAdding] = useState(false)

  useEffect(() => {
    const hasSingleWordSt = searchTerms.find(record => (
      record.search.trim().split(/\s+/g).length === 1
    ))

    if (hasSingleWordSt) {
      // When any single word is selected to add as negative,
      // add them as negative phrase.
      // Otherwise, for full search terms, as negative exact.
      setMatchType(NEGATIVE_MATCH_TYPE_PHRASE)
    }
  }, [searchTerms])

  const {
    hasAsin,
    hasSPSDCampaigns,
  } = useMemo(() => {
    let _hasAsin = false
    let _hasSPSDCampaigns = false
    searchTerms.forEach((record) => {
      if (isAsin(record.search)) {
        _hasAsin = true
      }
      // If only search terms for SB campaigns are selected,
      // disable adding to ad group level.
      if (record.campaignType === 'sp') {
        _hasSPSDCampaigns = true
      }
    })

    return {
      hasAsin: _hasAsin,
      hasSPSDCampaigns: _hasSPSDCampaigns,
    }
  }, [searchTerms])

  const handleAdd = async () => {
    const negativesToAdd = []
    const negativesForAuto = []
    searchTerms.forEach((record) => {
      // For auto campaigns, ASINs are added as negative targeting.
      if (
        record.campaignType === 'sp'
        && record.targetingType === 'auto'
        && isAsin(record.search)
      ) {
        negativesForAuto.push(record)
        return
      }
      negativesToAdd.push(record)
    })

    const negativeKeywords = negativesToAdd.map(record => ({
      campaignId: record.campaign_id,
      adgroupId: record.adgroup_id,
      campaignType: record.campaignType,
      search: record.search,
      matchType,
      // Below information are used for logging in backend.
      adgroupName: record.adgroupName,
    }))

    const invalidError = validateNKLength(negativeKeywords, 'search')
    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    const negativeTargets = []
    negativesForAuto.forEach((record) => {
      negativeTargets.push({
        campaignId: record.campaign_id,
        adgroupId: record.adgroup_id,
        campaignType: record.campaignType,
        search: record.search,
        // Below information are used for logging in backend.
        adgroupName: record.adgroupName,
      })
    })

    if (!negativeKeywords.length && !negativeTargets.length) {
      toast.show({
        title: 'Warning',
        description: 'Nothing to add.',
      })
      return
    }

    setIsAdding(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(createNegatives(
      accessToken,
      negativeKeywords,
      negativeTargets,
      targetLevel,
      false,
    ))
    setIsAdding(false)
    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_CREATE_NEGATIVES,
      ))
      onClose()
    }
  }

  return (
    <Modal backdrop="static" className="negative-creator-modal" show size="md">
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Add { searchTerms.length } Search Term{ searchTerms.length > 1 ? 's' : '' } As Negatives
          <CustomTooltip placement="right" icon={<span className="view-link">View search terms</span>}>
            <ul>
              {
                searchTerms.map(record => (
                  <li key={`${record.campaign_id}-${record.adgroup_id}-${record.search}`}>
                    {record.search}
                  </li>
                ))
              }
            </ul>
          </CustomTooltip>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isAdding && <LoaderComponent /> }
        <div className="description">
          We’ll automatically add negatives to the correct ad groups/campaigns.
        </div>
        <div className="section-wrapper">
          <div className="section-container">
            <div className="section-title">
              1) Choose Ad Group or Campaign Level
            </div>
            <RadioGroup
              value={targetLevel}
              onChange={setTargetLevel}
            >
              <Radio value="adgroup" disabled={!hasSPSDCampaigns}>Ad Group Level</Radio>
              <Radio value="campaign">Campaign Level</Radio>
            </RadioGroup>
          </div>
          <div className="section-container">
            <div className="section-title">
              2) Confirm Negative Match Type and Add Negatives
            </div>
            {
              hasAsin && (
                <CheckboxComponent
                  label="Add ASINs as Negative Product Targeting"
                  checked
                  disabled
                />
              )
            }
            <RadioGroup
              value={matchType}
              onChange={setMatchType}
            >
              <Radio value={NEGATIVE_MATCH_TYPE_EXACT}>
                Add All Negatives As Negative Exact
                <small>Recommended</small>
              </Radio>
              <Radio value={NEGATIVE_MATCH_TYPE_PHRASE}>
                Add All Negatives As Negative Phrase
                <small className="warning">
                  Negative Phrase match is a high risk optimization</small>
              </Radio>
            </RadioGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isAdding}
          onClick={handleAdd}
        >
          Add Negatives
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onClose() }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NegativeCreatorModal
