import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'
import { Toggle } from 'rsuite'
import * as Icon from 'react-icons/fi'
import Select from 'react-select'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import BulkResultContainer from '../BulkResultContainer'
import StExSelection from './StExSelection'
import TargetAddModal from './TargetAddModal'

import {
  tableSorter,
  capitalizeFirstLetter,
  calcDerivedMetrics,
  getAmazonSearchLink,
  getExportValueForColumn,
  groupRecords,
  getAssociativeCampaigns,
  isAsin,
} from '../../services/helper'

import { bulkSTColumnList, MODULE_NAME_ST_EX } from '../../utils/defaultValues'
import { matchTypes } from '../../utils/filterDef'
import { selectCurrentAccount } from '../../redux/reducers/header'

const initialColumns = [
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'keywordText', name: 'Associated Target', className: 'col-keyword' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'keywordText', name: 'Associated Target', className: 'col-keyword' },
]

const wordOptions = [
  { value: '', label: 'All' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5+' },
]

export const FILTER_NAME_ST_EX = 'stEx'

const StExResult = ({ stExData, hideKeywords, hideAsins,
  onChangeHideKeywords, onChangeHideAsins, onChangeDate, onApplyFilter }) => {
  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const stTableColumns = useSelector(state => state.pageGlobal.stTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const [groupMode, setGroupMode] = useState(false)
  const [searchTerms, setSearchTerms] = useState([])
  const [groupedSearchTerms, setGroupedSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [stSelection, setStSelection] = useState([])
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [targetsPayload, setTargetsPayload] = useState([])
  const [selectedWord, setSelectedWord] = useState(wordOptions[0])
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [origFilters, setOrigFilters] = useState({})

  // Filter found search terms.
  useEffect(() => {
    if (!stExData.length) {
      return
    }

    const campaignsById = getAssociativeCampaigns(campaignsWithHistory)

    const filteredSTs = []

    stExData.forEach((record) => {
      // Remove ASINs.
      if (hideAsins && (isAsin(record.search))) {
        return
      }

      if (selectedWord.value !== '') {
        const wordCount = parseInt(selectedWord.value, 10)
        if (wordCount !== 5) {
          if (record.search.split(/\s+/).length !== wordCount) {
            return
          }
        } else if (record.search.split(/\s+/).length < wordCount) {
          return
        }
      }

      if (selectedMatchType.value !== ''
        && (record.match_type || '').toLowerCase() !== selectedMatchType.value) {
        return
      }

      const campaignDetail = campaignsById[record.campaign_id] || {}

      filteredSTs.push({
        ...calcDerivedMetrics(record),
        matchType: capitalizeFirstLetter(record.match_type),
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targetingType: campaignDetail.targetingType || '',
        keywordText: (record.keyword === '(_targeting_auto_)'
          || record.keyword === '(_targeting_auto_, 1)') ? '*' : record.keyword,
      })
    })

    setSearchTerms(filteredSTs)
  }, [stExData, campaignsWithHistory, hideAsins, selectedWord, selectedMatchType])

  useEffect(() => {
    if (groupMode) {
      setGroupedSearchTerms(
        groupRecords(
          searchTerms,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedSearchTerms([])
    }
  }, [searchTerms, groupMode])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const selection = campaignTableColumns.filter(c =>
      ![
        'campaign',
        'target_acos',
        'daily_budget',
        'start_date',
        'st_impr_rank',
        'st_impr_share',
      ].includes(c.key)
    )
    if ((stTableColumns || []).includes('st_impr_rank')) {
      selection.push({
        key: 'st_impr_rank',
        label: 'Search Term Impression Rank',
        name: 'ST Imp. Rank',
      })
    }
    if ((stTableColumns || []).includes('st_impr_share')) {
      selection.push({
        key: 'st_impr_share',
        label: 'Search Term Impression Share %',
        name: 'ST Imp. Share',
      })
    }
    return [
      [...initialColumns, ...selection],
      [...initialColumnsGroup, ...selection],
      selection,
    ]
  }, [campaignTableColumns, stTableColumns])

  const handleCopy = () => {
    const existingSts = stSelection.map(record => record.search)

    const newSts = searchTerms.filter(record => (
      selectedSearchTerms.indexOf(record.id) !== -1
      && existingSts.indexOf(record.search) === -1
    )).map(record => ({
      search: record.search,
    }))

    setStSelection(prev => ([
      ...prev,
      ...newSts,
    ]))
  }

  const handleAddToExisting = (sts) => {
    if (typeof sts === 'undefined') {
      setTargetsPayload(searchTerms.filter(record => (
        selectedSearchTerms.indexOf(record.id) !== -1
      )).map(record => ({
        target: record.search.trim(),
        cpc: record.cpc,
      })))
    } else {
      // From copied search terms section.
      setTargetsPayload(sts.map(record => ({
        target: record,
      })))
    }
    setIsAddModalVisible(true)
  }

  const handleFilterRefine = () => {
    setCurrentFilterName(FILTER_NAME_ST_EX)
    setOrigFilters((filterValues || {})[FILTER_NAME_ST_EX] || {})
  }

  const handleFilterApply = (values) => {
    // When acos values are changed, call API again.
    if (parseFloat(values.acosMin || 0) !== parseFloat(origFilters.acosMin || 0)
      || parseFloat(values.acosMax || 0) !== parseFloat(origFilters.acosMax || 0)) {
      onApplyFilter(values)
    }
    setCurrentFilterName('')
  }

  const handleFilterValidate = (values) => {
    const { acosMin, acosMax } = values

    if (acosMin === '' || isNaN(acosMin) || parseFloat(acosMin) < 0
      || acosMax === '' || isNaN(acosMax) || parseFloat(acosMax) < 0) {
      return 'Please enter ACoS greater than or equal to 0.'
    }

    if (parseFloat(acosMin) > parseFloat(acosMax)) {
      return 'The start range of ACoS cannot be greater than the end range.'
    }

    return null
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By search terms"
          onChange={setGroupMode}
        />
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Keywords"
            checked={hideKeywords}
            onChange={onChangeHideKeywords}
          />
          <CustomTooltip placement="right">
            <p>Sometimes a keyword and search term are the same
            (ex: Exact match types), many times they are not.</p>
            <p>Checking this box means that only search terms that are not yet keywords will be revealed.
            You can turn these new-found search terms into keywords by adding them to campaigns.</p>
          </CustomTooltip>
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove ASINs"
            checked={hideAsins}
            onChange={onChangeHideAsins}
          />
        </div>
        <div className="select-wrapper">
          <span>Word Count</span>
          <Select
            classNamePrefix="word-count-selector"
            options={wordOptions}
            value={selectedWord}
            onChange={setSelectedWord}
          />
        </div>
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFilterRefine}
          >
            Refine Filter
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    const targets = searchTerms.filter(record => (
      selectedSearchTerms.indexOf(record.id) !== -1
    )).map(record => record.search.trim())

    return (
      <>
        <button type="button" className="btn btn-light-blue" onClick={handleCopy}>
          Copy to List Below
        </button>
        <button type="button" className="btn btn-blue" onClick={() => { handleAddToExisting() }}>
          Add to Existing Campaigns
        </button>
        <Dropdown
          title="Add to New Campaign"
          placement="bottomEnd"
          toggleClassName="btn-new"
        >
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sp',
            state: {
              targets,
            },
          }}>
            Sponsored Product Campaign
          </Dropdown.Item>
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sb',
            state: {
              targets,
            },
          }}>
            Sponsored Brand Campaign
          </Dropdown.Item>
        </Dropdown>
      </>
    )
  }

  const renderST = record => (
    <>
      <div className="table-col col-search-term" title={record.search}>
        <strong className="contents">{ record.search }</strong>
        <a
          href={getAmazonSearchLink(currentAccount, record.search)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.FiExternalLink size={16} />
        </a>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col col-keyword" title={record.keywordText}>
        <span className="contents">
          { record.keywordText }
        </span>
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-search-term">Totals:</div>
      <div className="table-col" />
      <div className="table-col col-keyword" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'search') {
        return `"${(record.search || '').replace(/"/g, '""')}"`
      }
      if (column.key === 'matchType') {
        return record.matchType
      }
      if (column.key === 'keywordText') {
        return `"${(record.keywordText || '').replace(/"/g, '""')}"`
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-search-term">
        { record.children.length } search terms
      </div>
      <div className="table-col" />
      <div className="table-col col-keyword" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-search-term" title={record.search}>
        <strong className="contents">{ record.search }</strong>
        <a
          href={getAmazonSearchLink(currentAccount, record.search)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.FiExternalLink size={16} />
        </a>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      <div className="table-col col-keyword" title={record.keywordText}>
        <span className="contents">
          { record.keywordText }
        </span>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-search-term" />
      <div className="table-col" />
      <div className="table-col col-keyword" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <BulkResultContainer>
      <div className="section-label">
        Select search term and take appropriate action
      </div>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-search-terms"
            records={groupedSearchTerms}
            idField="campaign_id"
            searchFields={['search']}
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_ST_EX}
            useFilterModal
            columnEditorId="stExResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_ST_EX}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={onChangeDate}
            onFilterValidate={handleFilterValidate}
            sorterChild={tableSorter(['search', 'matchType', 'keywordText'])}
            idFieldChild="id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['search', 'matchType', 'keywordText'])}
            className="table-search-terms"
            records={searchTerms || []}
            idField="id"
            searchFields={['search']}
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_ST_EX}
            useFilterModal
            columnEditorId="stExResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_ST_EX}
            getExportData={getExportData}
            renderRecord={renderST}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={onChangeDate}
            onFilterValidate={handleFilterValidate}
          />
        )
      }
      <StExSelection
        searchTerms={stSelection}
        onChange={setStSelection}
        onAddToExisting={handleAddToExisting}
      />
      <TargetAddModal
        show={isAddModalVisible}
        targets={targetsPayload}
        forStEx
        onClose={() => { setIsAddModalVisible(false) }}
      />
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={MODULE_NAME_ST_EX}
            onApply={handleFilterApply}
            onClose={() => { setCurrentFilterName('') }}
            onValidate={handleFilterValidate}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default StExResult
