import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import { getSkus } from '../../../redux/actions/product'
import { createProductsInBulk } from '../../../redux/actions/campaignCreator'

import CustomTable from '../../CommonComponents/CustomTableComponent'
import LoaderComponent from '../../CommonComponents/LoaderComponent'
import { toast } from '../../CommonComponents/ToastComponent/toast'

import { formatCurrency } from '../../../services/helper'

const TAB_SEARCH = 'search'
const TAB_ENTER = 'enter'

const tabList = [
  { value: TAB_SEARCH, name: 'Search' },
  { value: TAB_ENTER, name: 'Enter list' },
]

const AddProductsModal = ({ show, onConfirm, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const skus = useSelector(state => state.product.skus)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const [isLoading, setIsLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(TAB_SEARCH)
  const [selectedIds, setSelectedIds] = useState([])
  const [asinList, setAsinList] = useState('')
  const [adgroupOptions, setAdgroupOptions] = useState([])
  const [selectedAdGroup, setSelectedAdGroup] = useState(null)
  const [isAddingProducts, setIsAddingProducts] = useState(false)

  useEffect(() => {
    if (show && !isLoading) {
      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getSkus(accessToken))
        setIsLoading(false)
      })()
      setAdgroupOptions(currentAdGroups.map(adGroup => ({
        value: adGroup.adgroupid,
        label: adGroup.name,
        ...adGroup
      })))
    }
  }, [show]) // eslint-disable-line

  const handleConfirm = async () => {
    let selectedProducts = []
    if (currentTab === TAB_SEARCH) {
      selectedProducts = skus.filter(sku => selectedIds.includes(sku.id))
    } else if (currentTab === TAB_ENTER) {
      const asins = asinList.split('\n').map(asin => asin.trim().toLowerCase())
      selectedProducts = skus.filter(sku => asins.includes(sku.asin.toLowerCase()))
    }

    if (selectedAdGroup == null) {
      toast.show({
        title: 'Warning',
        description: 'Ad Group is not selected yet.',
      })
      return
    }

    if (selectedProducts.length === 0) {
      toast.show({
        title: 'Warning',
        description: 'Please select the products to add to advertise.',
      })
      return
    }

    const payload = selectedProducts.map(product => ({
      campaignId: currentDetail.campaign_id,
      campaignType: currentDetail.campaignType,
      adgroupId: selectedAdGroup.adgroupid,
      sku: product.sku,
      asin: product.asin,
    }))

    setIsAddingProducts(true)
    const accessToken = await getAccessTokenSilently()
    const success = await dispatch(createProductsInBulk(accessToken, payload))
    setIsAddingProducts(false)
    if (success) {
      onConfirm()
    }
  }

  const renderSku = sku => (
    <>
      <div className="table-col col-product">
        {
          sku.image !== '' ? (
            <img src={sku.image} alt={sku.name} />
          ) : (
            <span className="image-placeholder">No image</span>
          )
        }
        <div className="product-info">
          <div className="product-name" title={sku.name}>
            { sku.name }
          </div>
          <div className="product-asin-info">
            ASIN: { sku.asin } | SKU: { sku.sku }
          </div>
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(sku.price, currencySign, currencyRate) }
      </div>
    </>
  )

  return (
    <Modal className={`product-modal${isLoading ? ' loading' : ''}`} backdrop="static" show={show} size="lg">
      <Modal.Header onHide={() => onClose()}>
        <Modal.Title>
          Add Products to Advertise: { currentDetail ? currentDetail.name : '' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={isAddingProducts ? 'loading' : ''}>
        { (isLoading || isAddingProducts)  && <LoaderComponent /> }
        <div className="flex">
          <div className="add-product-adgroup-select">
            <Select
              classNamePrefix="adgroup-selector"
              placeholder="Select Ad Group to Add Products"
              options={adgroupOptions}
              value={selectedAdGroup}
              onChange={setSelectedAdGroup}
            />
          </div>
        </div>
        <div className="tab-list">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? 'selected' : ''}
                onClick={() => { setCurrentTab(tab.value) }}
              >
                { tab.name }
              </button>
            ))
          }
        </div>
        {
          currentTab === TAB_SEARCH && (
            <CustomTable
              className="table-products"
              records={skus || []}
              selectedRecords={selectedIds}
              idField="id"
              searchFields={['name', 'sku', 'asin']}
              renderRecord={renderSku}
              onChange={setSelectedIds}
            >
              <div className="table-col col-product">Product</div>
              <div className="table-col">Price</div>
            </CustomTable>
          )
        }
        {
          currentTab === TAB_ENTER && (
            <textarea
              className="asin-list"
              placeholder="Enter ASINs separated by a new line."
              rows={5}
              value={asinList}
              onChange={(event) => { setAsinList(event.target.value) }}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isAddingProducts}
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddProductsModal
