import React, { useState } from 'react'
import Select from 'react-select'

const previewTypes = [
  { value: 'PAGE_RIGHT', label: 'Amazon product page right' },
  { value: 'PAGE_MIDDLE', label: 'Amazon product page middle' },
  { value: 'MOBILE_RECT', label: 'Amazon mobile rectangle' },
  { value: 'MOBILE_LEADERBOARD', label: 'Mobile leaderboard' },
  { value: 'WIDE', label: 'Wide skyscraper' },
  { value: 'MED_RECT', label: 'Medium rectangle' },
  { value: 'LEADERBOARD', label: 'Leaderboard' },
  { value: 'BILLBOARD', label: 'Billboard' },
  { value: 'LARGE_RECT', label: 'Large rectangle' },
  { value: 'STRIPE', label: 'Amazon top stripe' },
]

const SDAdPreview = ({ products, hasLogo, hasHeadline, hasCustomImage,
  croppedLogo, headline, croppedCustom }) => {
  const [previewType, setPreviewType] = useState(previewTypes[0])

  const renderImageSection = (product, itemToRender) => {
    const slots = []

    let productEl = null
    if (product) {
      productEl = (
        <div className="image-slot" key="product-image">
          <img
            src={product.image}
            alt={product.name}
          />
        </div>
      )
    }

    const headlineEl = (
      <div className="image-slot" key="headline">
        <div className="headline">{ headline }</div>
      </div>
    )

    const logoEl = (
      <div className="image-slot" key="logo-image">
        <img src={croppedLogo} alt="logo" />
      </div>
    )

    const customEl = (
      <div className="image-slot" key="custom-image">
        <img src={croppedCustom} alt="logo" />
      </div>
    )

    const logoHeadlineEl = (
      <div className="image-slot logo-headline-slot" key="headline">
        <img src={croppedLogo} alt="logo" />
        <div className="headline">{ headline }</div>
      </div>
    )

    if (previewType.value === 'PAGE_RIGHT') {
      switch (itemToRender) {
        case 1:
          slots.push(customEl)
          break
        case 2:
          slots.push(productEl)
          slots.push(headlineEl)
          break
        case 3:
          slots.push(customEl)
          slots.push(headlineEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(customEl)
          slots.push(logoEl)
          break
        case 6:
          slots.push(productEl)
          slots.push(logoHeadlineEl)
          break
        case 7:
          slots.push(logoEl)
          slots.push(customEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'PAGE_MIDDLE') {
      switch (itemToRender) {
        case 1:
          slots.push(customEl)
          slots.push(productEl)
          break
        case 2:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(customEl)
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(logoEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        case 6:
        case 7:
          slots.push(logoEl)
          slots.push(headlineEl)
          slots.push(productEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'MOBILE_RECT') {
      switch (itemToRender) {
        case 1:
        case 3:
          slots.push(customEl)
          break
        case 4:
        case 5:
        case 6:
        case 7:
          slots.push(logoEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'MOBILE_LEADERBOARD') {
      switch (itemToRender) {
        case 3:
          slots.push(customEl)
          slots.push(productEl)
          break
        case 4:
        case 6:
          slots.push(logoEl)
          break
        case 1:
        case 5:
        case 7:
          slots.push(customEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'WIDE') {
      switch (itemToRender) {
        case 1:
          slots.push(productEl)
          slots.push(customEl)
          break
        case 2:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(headlineEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(logoEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        case 6:
          slots.push(logoEl)
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 7:
          slots.push(logoEl)
          slots.push(headlineEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'MED_RECT') {
      switch (itemToRender) {
        case 1:
          slots.push(customEl)
          break
        case 2:
          slots.push(productEl)
          slots.push(headlineEl)
          break
        case 3:
          slots.push(customEl)
          slots.push(headlineEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(customEl)
          slots.push(logoEl)
          break
        case 6:
          slots.push(productEl)
          slots.push(logoHeadlineEl)
          break
        case 7:
          slots.push(logoEl)
          slots.push(customEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'LEADERBOARD') {
      switch (itemToRender) {
        case 1:
          slots.push(customEl)
          break
        case 2:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(customEl)
          slots.push(headlineEl)
          break
        case 4:
        case 6:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
        case 7:
          slots.push(logoEl)
          slots.push(customEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'BILLBOARD') {
      switch (itemToRender) {
        case 1:
          slots.push(productEl)
          slots.push(customEl)
          break
        case 2:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(customEl)
          slots.push(productEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(logoEl)
          slots.push(customEl)
          break
        case 6:
          slots.push(logoHeadlineEl)
          slots.push(productEl)
          break
        case 7:
          slots.push(logoHeadlineEl)
          slots.push(customEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'LARGE_RECT') {
      switch (itemToRender) {
        case 1:
          slots.push(customEl)
          slots.push(productEl)
          break
        case 2:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(headlineEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        case 4:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 5:
          slots.push(logoEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        case 6:
          slots.push(logoEl)
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 7:
          slots.push(logoHeadlineEl)
          slots.push(customEl)
          slots.push(productEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'STRIPE') {
      switch (itemToRender) {
        case 1:
        case 3:
        case 5:
        case 7:
          slots.push(customEl)
          break
        case 2:
          slots.push(productEl)
          break
        case 4:
        case 6:
          slots.push(logoEl)
          break
        default:
          break
      }
    }

    if (!slots.length) {
      slots.push(productEl)
    }

    return (
      <div className="image-wrapper">
        { slots }
      </div>
    )
  }

  const renderInfoSection = (product, itemToRender) => {
    const slots = []

    let productEl = null
    let productImageEl = null
    if (product) {
      productEl = (
        <div className="product-name" key="product-name">
          { product.name }
        </div>
      )

      productImageEl = (
        <div className="product-image" key="product-image">
          <img
            src={product.image}
            alt={product.name}
          />
        </div>
      )
    }

    const logoImageEl = (
      <div className="product-image" key="logo-image">
        <img src={croppedLogo} alt="logo" />
      </div>
    )

    const headlineEl = (
      <div className="headline" key="headline">
        { headline }
      </div>
    )

    const shopBtnEl = (
      <button
        type="button"
        className="btn btn-shop"
      >
        Shop now
      </button>
    )

    const productHeadlineEl = (
      <div className="product-headline" key="product-headline">
        <div className="headline">
          { headline }
        </div>
        {
          product && (
            <div className="product-name">
              { product.name }
            </div>
          )
        }
      </div>
    )

    if (previewType.value === 'PAGE_RIGHT') {
      if (itemToRender === 7) {
        slots.push(headlineEl)
      }
    } else if (previewType.value === 'PAGE_MIDDLE') {
      //
    } else if (previewType.value === 'MOBILE_RECT') {
      switch (itemToRender) {
        case 2:
        case 3:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 7:
          slots.push(headlineEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'MOBILE_LEADERBOARD') {
      switch (itemToRender) {
        case 1:
        case 4:
        case 7:
          slots.push(productEl)
          slots.push(productImageEl)
          break
        case 5:
          slots.push(productEl)
          slots.push(logoImageEl)
          break
        case 6:
          slots.push(headlineEl)
          slots.push(productImageEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'WIDE') {
      slots.push(productEl)
      slots.push(shopBtnEl)
    } else if (previewType.value === 'MED_RECT') {
      if (itemToRender === 7) {
        slots.push(headlineEl)
      }
    } else if (previewType.value === 'LEADERBOARD') {
      switch (itemToRender) {
        case 1:
          slots.push(productEl)
          slots.push(productImageEl)
          break
        case 6:
        case 7:
          slots.push(headlineEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'BILLBOARD') {
      switch (itemToRender) {
        case 3:
          slots.push(headlineEl)
          slots.push(productEl)
          break
        case 5:
        case 7:
          slots.push(productImageEl)
          slots.push(productEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'LARGE_RECT') {
      slots.push(productEl)
      slots.push(shopBtnEl)
    } else if (previewType.value === 'STRIPE') {
      switch (itemToRender) {
        case 1:
          slots.push(productEl)
          slots.push(productImageEl)
          slots.push(shopBtnEl)
          break
        case 2:
          slots.push(productHeadlineEl)
          slots.push(shopBtnEl)
          break
        case 3:
          slots.push(productHeadlineEl)
          slots.push(productImageEl)
          slots.push(shopBtnEl)
          break
        case 4:
          slots.push(productEl)
          slots.push(productImageEl)
          slots.push(shopBtnEl)
          break
        case 5:
          slots.push(productEl)
          slots.push(logoImageEl)
          slots.push(shopBtnEl)
          break
        case 6:
          slots.push(headlineEl)
          slots.push(productImageEl)
          slots.push(shopBtnEl)
          break
        case 7:
          slots.push(headlineEl)
          slots.push(logoImageEl)
          slots.push(productImageEl)
          break
        default:
          slots.push(productEl)
          slots.push(shopBtnEl)
          break
      }
    }

    if (!slots.length) {
      slots.push(productEl)
    }

    return (
      <div className="info-wrapper">
        { slots }
      </div>
    )
  }

  const renderPreview = () => {
    const product = products[0]
    const isLogoActive = !!(hasLogo && croppedLogo)
    const isHeadlineActive = !!(hasHeadline && headline !== '')
    const isCustomActive = !!(hasCustomImage && croppedCustom)

    let className
    if (previewType.value === 'PAGE_RIGHT') {
      className = 'preview-page-right'
    } else if (previewType.value === 'PAGE_MIDDLE') {
      className = 'preview-page-middle'
    } else if (previewType.value === 'MOBILE_RECT') {
      className = 'preview-mobile-rect'
    } else if (previewType.value === 'MOBILE_LEADERBOARD') {
      className = 'preview-mobile-leaderboard'
    } else if (previewType.value === 'WIDE') {
      className = 'preview-wide'
    } else if (previewType.value === 'MED_RECT') {
      className = 'preview-med-rect'
    } else if (previewType.value === 'LEADERBOARD') {
      className = 'preview-leaderboard'
    } else if (previewType.value === 'BILLBOARD') {
      className = 'preview-billboard'
    } else if (previewType.value === 'LARGE_RECT') {
      className = 'preview-large-rect'
    } else if (previewType.value === 'STRIPE') {
      className = 'preview-stripe'
    }

    const itemToRender = (isLogoActive ? 1 : 0) * 4
      + (isHeadlineActive ? 1 : 0) * 2
      + (isCustomActive ? 1 : 0)

    return (
      <div className={`preview-contents ${className}`}>
        { renderImageSection(product, itemToRender) }
        { renderInfoSection(product, itemToRender) }
      </div>
    )
  }

  return (
    <div className="sd-preview-container">
      <Select
        value={previewType}
        options={previewTypes}
        onChange={setPreviewType}
      />
      { renderPreview() }
    </div>
  )
}

export default SDAdPreview
