import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import VideoLink from '../../../CommonComponents/VideoLink'
import TableCell from '../../../CommonComponents/TableCell'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'
import AdgroupSelector from '../../AdgroupSelector'

import { getSkuData } from '../../../../redux/actions/campaignDetail'
import { updatePaStates } from '../../../../redux/actions/bulkEngine'

import {
  tableSorter,
  calcDerivedMetrics,
  getAmazonAsinLink,
  getExportValueForColumn,
  capitalizeFirstLetter,
} from '../../../../services/helper'

import { bulkSKUColumnList } from '../../../../utils/defaultValues'
import { selectCurrentAccount } from '../../../../redux/reducers/header'

const columns = [
  { key: 'state', name: 'State' },
  { key: 'image', name: 'Image', sortable: false },
  { key: 'sku', name: 'SKU', className: 'col-sku' },
  ...bulkSKUColumnList,
]

const videoList = [
  { title: 'Sku Optimization Video', url: 'https://www.loom.com/embed/04bd6686f0f344ac9ca78e240613ccad' },
]

const SKUOPTab = ({ campaignType }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const [currentAdgroup, setCurrentAdgroup] = useState(null)
  const [skuData, setSkuData] = useState([])
  const [skus, setSkus] = useState([])
  const [selectedSkus, setSelectedSkus] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)

  // Retrieve SKUs.
  useEffect(() => {
    if (!currentDetail || !currentStartDate || !currentEndDate) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      const data = await dispatch(getSkuData(accessToken, {
        campaignId: currentDetail.campaign_id,
        campaignType,
      }))
      setSkuData(data)
      setIsLoading(false)
    })()
  }, [currentDetail ? currentDetail.campaign_id : null, currentStartDate, currentEndDate]) // eslint-disable-line

  // Filter found SKUs.
  useEffect(() => {
    if (!skuData.length) {
      return
    }

    let filteredSKUs = []
    if (!currentAdgroup) {
      filteredSKUs = skuData
    } else {
      filteredSKUs = skuData.filter(sku =>
        sku.adgroup_id.toString() === currentAdgroup.adgroupid.toString()
      )
    }

    setSkus(filteredSKUs.map((record) => {
      const derived = calcDerivedMetrics(record)
      return {
        ...derived,
        className: (currentAcos && derived.acos > parseFloat(currentAcos))
          || (parseFloat(record.cost) && !derived.acos)
          ? 'bg-red'
          : '',
      }
    }))
  }, [currentAdGroups, currentAcos, currentAdgroup, skuData]) // eslint-disable-line

  const isSeller = currentAccount?.seller_type === 'seller'
  if (!isSeller) {
    columns[2].key = 'asin'
    columns[2].name = 'ASIN'
  }

  const handleChangeSkuState = async (state) => {
    const adgroupNamesById = {}
    currentAdGroups.forEach((adgroup) => {
      adgroupNamesById[adgroup.adgroupid] = adgroup.name
    })

    const productAds = skus
      .filter(record => selectedSkus.indexOf(record.adId) !== -1)
      .map(sku => ({
        campaignType,
        ad_id: parseInt(sku.adId, 10),
        // Below information are used for logging in backend.
        campaign_id: currentDetail.campaign_id,
        adgroup_id: sku.adgroup_id,
        adgroupName: adgroupNamesById[sku.adgroup_id] || '',
        sku: sku.sku,
        asin: sku.asin,
        originalState: sku.state,
      }))

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const updatedIds = await dispatch(updatePaStates(
      accessToken,
      productAds,
      state
    ))
    setIsUpdatingStates(false)

    setSkuData(skuData.map((record) => {
      if (updatedIds.includes(record.adId.toString()))  {
        record.state = state
      }
      return record
    }))
  }

  const renderAction = () => {
    if (!selectedSkus.length) {
      return null
    }

    const isEnableDisabled = typeof skus.find(record => (
      selectedSkus.indexOf(record.adId) !== -1
      && record.state.toLowerCase() !== 'enabled'
    )) === 'undefined'

    const isPauseDisabled = typeof skus.find(record => (
      selectedSkus.indexOf(record.adId) !== -1
      && record.state.toLowerCase() !== 'paused'
    )) === 'undefined'

    return (
      <>
        <button
          type="button"
          className="btn btn-green"
          disabled={isUpdatingStates || isEnableDisabled}
          onClick={() => { handleChangeSkuState('enabled') }}
        >
          Enable
        </button>
        <button
          type="button"
          className="btn btn-red"
          disabled={isUpdatingStates || isPauseDisabled}
          onClick={() => { handleChangeSkuState('paused') }}
        >
          Pause
        </button>
      </>
    )
  }

  const renderSKU = record => (
    <>
      <div className="table-col col-state">
        { record.state.toLowerCase() }
      </div>
      <div className="table-col">
        <a
          href={getAmazonAsinLink(currentAccount, record.asin)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={record.image} alt={isSeller ? record.sku : record.asin} />
        </a>
      </div>
      <div className="table-col col-sku">
        { isSeller ? record.sku : record.asin }
      </div>
      {
        bulkSKUColumnList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'SKU') {
        return `${record[column.key]} (${capitalizeFirstLetter(record.state)})`
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  if (campaignType === 'sb') {
    return null
  }

  return (
    <div className="campaign-detail-sku-op campaign-detail-tab">
      <div className="tab-info">
        <div className="tab-title">
          SKU Optimization
          <CustomTooltip placement="right">
            <p>If a SKU is highlighted in red, its ACoS is above
            your set Target ACoS Range.</p>
            <p>Consider pausing that SKU.</p>
          </CustomTooltip>
        </div>
        <div className="tab-description">
          Find the skus that are above your target ACoS.
        </div>
        <VideoLink
          videoList={videoList}
          modalTitle='Sku Optimization'
        />
      </div>
      <AdgroupSelector
        campaignType={campaignType}
        currentAdgroup={currentAdgroup}
        showAllAdgroup
        onAdgroupChange={setCurrentAdgroup}
      />
      <SortableTable
        isLoading={isLoading || isUpdatingStates}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['state', isSeller ? 'sku' : 'asin'])}
        className="table-skus"
        records={skus}
        idField="adId"
        searchFields={[isSeller ? 'sku' : 'asin']}
        selectedRecords={selectedSkus}
        hasSticky
        hasDateRange
        filterName="campaignDetailSku"
        getExportData={getExportData}
        exportFileName="Campaign Detail SKU"
        renderRecord={renderSKU}
        renderTopRight={renderAction}
        onChange={setSelectedSkus}
      />
    </div>
  )
}

export default SKUOPTab
