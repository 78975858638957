import React from 'react'

import Section from './Section'
import ExST from './ExST'
import ExProduct from './ExProduct'

const SUBSECTION_ST = 'st'
const SUBSECTION_PRODUCT = 'product'

const ExSection = (props) => {
  const { campaign } = props

  let isSD = false
  let hasKeywordAdgroups = true
  let hasProductAdgroups = true
  if (campaign) {
    // For Sponsored Displays, Search Term Expansion is disabled.
    isSD = campaign.basic[0].type === 'sd'

    hasKeywordAdgroups = campaign.keywordAdgroups.length !== 0
    hasProductAdgroups = campaign.productAdgroups.length !== 0
  }

  return (
    <Section>
      {
        !isSD && hasKeywordAdgroups &&
          <ExST
            id={SUBSECTION_ST}
            videoList={[{ title: 'Search Term Expansion Video', url: 'https://www.loom.com/embed/71e0a153afec4ca384d957d605bf43d9' }]}
            {...props}
          />
      }
      {
        hasProductAdgroups && <ExProduct id={SUBSECTION_PRODUCT} {...props} />
      }
    </Section>
  )
}

export default ExSection
