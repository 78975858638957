/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { ReactComponent as LogoSvg } from '../../assets/svg/logo-full.svg'
import { ReactComponent as LogoSmallSvg } from '../../assets/svg/logo-small.svg'

import CampaignSvg from '../../assets/svg/menu-campaign.svg'
import CampaignOnSvg from '../../assets/svg/menu-campaign-on.svg'
import BulkSvg from '../../assets/svg/menu-bulk.svg'
import BulkOnSvg from '../../assets/svg/menu-bulk-on.svg'
// import MarginSvg from '../../assets/svg/menu-margin.svg'
import SettingSvg from '../../assets/svg/menu-setting.svg'
import SettingOnSvg from '../../assets/svg/menu-setting-on.svg'
import ReportSvg from '../../assets/svg/menu-report.svg'
import ReportOnSvg from '../../assets/svg/menu-report-on.svg'
import ActivitySvg from '../../assets/svg/menu-activity.svg'
import ReferralSvg from '../../assets/svg/menu-referral.svg'
import TutorialSvg from '../../assets/svg/menu-tutorial.svg'
import MarketSvg from '../../assets/svg/menu-market.svg'

import { ReactComponent as CollapseSvg } from '../../assets/svg/menu-collapse.svg'
import { ReactComponent as ExpandSvg } from '../../assets/svg/menu-expand.svg'

const menuList = [
  {
    name: 'Main',
    items: [
      { key: 'campaigns', to: '/campaigns', name: 'Command Center', icon: CampaignSvg, iconActive: CampaignOnSvg, iconW: 18, iconH: 18 },
      { key: 'bulk', to: '/bulk-engine', name: 'Bulk Engine', icon: BulkSvg, iconActive: BulkOnSvg, iconW: 18, iconH: 18 },
      // { key: 'margins', url: 'https://app.ppcentourage.com/margins/login', name: 'Margins', icon: MarginSvg },
    ],
  },
  {
    name: 'Account',
    items: [
      { key: 'setting', to: '/settings', name: 'Settings', icon: SettingSvg, iconActive: SettingOnSvg, iconW: 18, iconH: 18 },
      { key: 'health_v2', to: '/account-report', name: 'Reports', icon: ReportSvg, iconActive: ReportOnSvg, iconW: 18, iconH: 18 },
      { key: 'log', to: '/activity-log', name: 'Activity Log', icon: ActivitySvg, iconW: 18, iconH: 18 },
      { key: 'referrals', url: 'https://www.carbon6.io/affiliates', name: 'Referrals', icon: ReferralSvg, iconW: 18, iconH: 18 },
    ],
  },
  {
    name: 'Help',
    items: [
      { key: 'tutorial', to: '/tutorial', name: 'University', icon: TutorialSvg, iconW: 18, iconH: 18 },
      { key: 'marketplace', to: '/marketplace', name: 'Marketplace', icon: MarketSvg, iconW: 12, iconH: 16 },
    ],
  },
]

const SideMenuComponent = () => {
  const location = useLocation()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [mouseIn, setMouseIn] = useState('')

  useEffect(() => {
    function updateCollapse() {
      if (window.innerWidth <= 576) {
        setIsCollapsed(true)
      }
    }
    window.addEventListener('resize', updateCollapse)
    updateCollapse()
    return () => window.removeEventListener('resize', updateCollapse)
  }, [])

  useEffect(() => {
    if (location.pathname.includes('/account-report')) {
      setIsCollapsed(true)
    }
  }, [location.pathname])

  const renderItem = (item) => {
    if (isNonEndemicAccount && item.key === 'marketplace') {
      return null
    }

    if (item.to) {
      return (
        <Link
          key={item.key}
          to={item.to}
          className="side-menu-item"
          onMouseMove={() => { setMouseIn(item.key) }}
          onMouseOut={() => { setMouseIn('') }}>
          <span className="image-wrapper">
            <img
              src={(mouseIn === item.key || location.pathname.includes(item.to)) ? item.iconActive || item.icon : item.icon}
              alt={item.name}
              title={item.name}
              width={item.iconW}
              height={item.iconH}
            />
          </span>
          <span className="menu-name">{ item.name }</span>
        </Link>
      )
    }

    if (item.url) {
      return (
        <div key={item.key} className="side-menu-item">
          <span className="image-wrapper">
            <img
              src={item.icon}
              alt={item.name}
              title={item.name}
              width={item.iconW}
              height={item.iconH}
            />
          </span>
          <a href={item.url} target="_blank" rel="noopener noreferrer" title={item.name}>
            { item.name }
          </a>
        </div>
      )
    }

    return (
      <div key={item.key} className="side-menu-item">
        <span className="image-wrapper">
          <img
            src={item.icon}
            alt={item.name}
            title={item.name}
            width={item.iconW}
            height={item.iconH}
          />
        </span>
        <span className="menu-name">{ item.name }</span>
      </div>
    )
  }

  const renderSubMenu = subMenu => (
    <div key={subMenu.name} className="side-menu-submenu">
      {
        !isCollapsed && (
          <div className="submenu-header">
            { subMenu.name }
          </div>
        )
      }
      <div className="submenu-contents">
        { subMenu.items.map(renderItem) }
      </div>
    </div>
  )

  return (
		<div className={`side-menu-component${!isCollapsed ? '' : ' collapsed'}`}>
      <div className="side-menu-header">
        <Link to="/dashboard">
          {
            !isCollapsed
            ? <LogoSvg title="PPC Entourage" />
            : <LogoSmallSvg title="PPC Entourage" />
          }
        </Link>
      </div>
      <div className="side-menu-contents">
        { menuList.map(renderSubMenu) }
      </div>
      <div className="side-menu-footer">
        {
          !isCollapsed
          ? <CollapseSvg title="Collapse" onClick={() => { setIsCollapsed(true) }} />
          : <ExpandSvg title="Expand" onClick={() => { setIsCollapsed(false) }} />
        }
      </div>
		</div>
  )
}

export default SideMenuComponent
