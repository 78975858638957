import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportPerformanceWidget from './ReportPerformanceWidget'

import { calcDerivedMetrics } from '../../services/helper'

const PerformanceByAdType = ({ showSection, onToggle }) => {
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])

  useEffect(() => {
    const performanceByAdType = reportWidgetSetting ? reportWidgetSetting.performanceByAdType : []
    setViewWidgets(
      performanceByAdType.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const renderWidget = (widget) => {
    let metrics
    switch (widget.key) {
      case 'sponsoredProduct':
        metrics = spMetrics
        break
      case 'sponsoredBrand':
        metrics = sbMetrics
        break
      case 'sponsoredDisplay':
        metrics = sdMetrics
        break
      default:
        return null
    }

    if (!metrics) {
      return null
    }

    return (
      <ReportPerformanceWidget
        key={widget.key}
        widget={widget}
        data={calcDerivedMetrics(metrics)}
        customizing={customizingSection}
        onRemove={handleOnRemoveWidget}
        section="performanceByAdType"
      />
    )
  }

  return (
    <div>
      <SectionHeader
        title="Performance By Ad Type"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
      />
      {
        showSection && (
          <>
            {
              viewWidgets.map(w => (w.view || customizingSection) ? (
              renderWidget(w)) : null)
            }
          </>
        )
      }
    </div>
  )
}

export default withReportSection(PerformanceByAdType, {
  sectionId: 'performanceByAdType',
  reportSectionKey: 'performanceByAdType',
})
