import React from 'react'
import { useDispatch } from 'react-redux'
// import { Dropdown } from 'rsuite'
import { FiColumns } from 'react-icons/fi'
// import { FiEye } from 'react-icons/fi'
// import { useAuth0 } from '@auth0/auth0-react'
import { CSVLink } from 'react-csv';

import SearchBox from '../CommonComponents/SearchBox'
import { showColumnEditorAction } from '../../redux/actions/pageGlobal'
// import ImportFileContent from '../CommonComponents/ImportFileContent'

// import { updateBulkCogs } from '../../redux/actions/product'
// import { showRuleManager } from '../../redux/actions/ruleManager'
// import { RULE_TYPE_RR } from '../../utils/defaultValues'

// const sampleCogCsv = "data:text/csv;charset=utf-8,SKU,COG\nLight-gray99,10\n4th-refil,5"

const ActionBar = ({
  dataToExport,
  searchKey,
  searchInclusive = true,
  selectedProducts,
  fromAccountReport = false,
  onSearchKeyChange,
  onChangeSearchInclusive,
}) => {
  const dispatch = useDispatch()
  // const { getAccessTokenSilently } = useAuth0()

  // const productList = useSelector(state => state.product.productList)

  // const [showFileUpload, setShowFileUpload] = useState(false)
  // const [fileImportBtnName, setFileImportBtnName] = useState('Upload Bulk COGS')
  // const [isUploading, setIsUploading] = useState(false)

  // const handleUpload = async (data, fileName) => {
  //   const results = data.split(/\n/).map(product => product.split(','))
  //   const header = results.shift()
  //   const skuIndex = header.findIndex(col => col.toLowerCase().trim() === 'sku')
  //   const cogIndex = header.findIndex(col => col.toLowerCase().trim() === 'cog')
  //   if (skuIndex === -1 || cogIndex === -1) {
  //     toast.show({
  //       title: 'Danger',
  //       description: 'Unable to detect the csv headers. Please make sure you have the '
  //         + 'both (\'sku\' and \'cog\') headers in your file.',
  //     })
  //     return
  //   }
  //   // @fixme: invalidSkus and successfulSkus are not used now.
  //   const invalidSkus = []
  //   const successfulSkus = {}
  //   const insertValues = []
  //   results.forEach((result) => {
  //     const sku = result[skuIndex]
  //     const cog = parseFloat((result[cogIndex] || '').replace(/\$/g, ''))
  //     if (isNaN(cog)) {
  //       invalidSkus.push({
  //         sku,
  //         reason: 'Invalid value for COG.',
  //       })
  //     } else if (productList.findIndex(skuWithProfit => skuWithProfit.sku === sku) === -1) {
  //       invalidSkus.push({
  //         sku,
  //         reason: 'Skus was not found.',
  //       })
  //     } else if (successfulSkus[sku] && successfulSkus[sku] !== cog) {
  //       invalidSkus.push({
  //         sku,
  //         reason: 'SKU has duplicate entries and the last value by the order is saved as final one. '
  //           + 'Please fix it and upload again if you don\'t agree.',
  //       })
  //     } else {
  //       successfulSkus[sku] = cog
  //       insertValues.push({
  //         sku,
  //         cog,
  //       })
  //     }
  //   })
  //   if (insertValues.length === 0) {
  //     toast.show({
  //       title: 'Danger',
  //       description: 'There were no valid items in csv file',
  //     })
  //     return
  //   }

  //   const accessToken = await getAccessTokenSilently()
  //   setIsUploading(true)
  //   await dispatch(updateBulkCogs(accessToken, insertValues))
  //   setIsUploading(false)
  //   setFileImportBtnName(fileName)
  //   setShowFileUpload(false)
  // }

  // const handleRuleManagerShow = type => () => {
  //   dispatch(showRuleManager(
  //     type,
  //     [],
  //     selectedProducts
  //   ))
  // }

  const handleShowColumnEditor = () => {
    dispatch(showColumnEditorAction())
  }

  return (
    <div className="table-header">
      <div className="table-header-left">
        <SearchBox
          keyword={searchKey}
          style={{ width: 190 }}
          exclusable
          searchInclusive={searchInclusive}
          onChangeSearchInclusive={onChangeSearchInclusive}
          onChange={onSearchKeyChange}
        />
        <CSVLink
          data={dataToExport}
          filename="My products.csv"
          className="btn btn-blue btn-export-csv"
          title="Export to CSV"
        >
          Export to CSV
        </CSVLink>
        {/* <button
          type="button"
          className="btn btn-green btn-upload"
          disabled={isUploading}
          onClick={() => { setShowFileUpload(true) }}
        >
          { fileImportBtnName }
        </button>
        <ImportFileContent
          showFileUpload={showFileUpload}
          loadData={handleUpload}
          hideFileUpload={() => { setShowFileUpload(false) }}
        />
        <Whisper placement="right" trigger="hover" speaker={(
          <Tooltip>
            <p>Please click on this icon to download the template csv file of cogs.</p>
          </Tooltip>
        )}>
          <a
            href={encodeURI(sampleCogCsv)}
            className="download-cog-csv"
            download="cog.csv"
          >
            <FiEye size={16} />
          </a>
        </Whisper> */}
        {/* <Dropdown title="Rules" disabled={!selectedProducts.length}>
          <Dropdown.Item panel>
            <strong>Rank</strong>
          </Dropdown.Item>
          <Dropdown.Item onSelect={handleRuleManagerShow(RULE_TYPE_RR)}>
            Start
          </Dropdown.Item>
        </Dropdown> */}
      </div>
      {
        fromAccountReport && (
          <div className="table-header-right">
            <FiColumns color="#979797" onClick={handleShowColumnEditor} />
          </div>
        )
      }
    </div>
  )
}

export default ActionBar
