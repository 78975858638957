import React from 'react'
import Select from 'react-select'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'

export const BID_ADJUSTMENT_ACTION_DEC = 'decrease'

const bidAdjustmentActionOptions =[
  { value: 'increase', label: 'Increase by' },
  { value: BID_ADJUSTMENT_ACTION_DEC, label: 'Decrease by' },
]

const SBBidSection = ({ bidOptimization, bidAdjustmentAction, bidAdjustmentValue,
  onBidOptimizationChange, onBidAdjustmentActionChange, onBidAdjustmentValueChange }) => {
  const renderCustom = () => {
    if (bidOptimization) {
      return null
    }

    let error = false
    if (bidAdjustmentAction === BID_ADJUSTMENT_ACTION_DEC) {
      if (parseInt(bidAdjustmentValue, 10) < 0
        || parseInt(bidAdjustmentValue, 10) > 99) {
        error = 'The adjustment value should be between 0 and 99'
      }
    } else if (parseInt(bidAdjustmentValue, 10) < 0
      || parseInt(bidAdjustmentValue, 10) > 900) {
      error = 'The adjustment value should be between 0 and 900'
    }

    return (
      <div className="custom-bid-adjustment-section">
        <label>
          Set a custom bid adjustment
        </label>
        <div className="adjustment-value">
          <Select
            options={bidAdjustmentActionOptions}
            value={bidAdjustmentActionOptions.find(option => option.value === bidAdjustmentAction)}
            onChange={(option) => { onBidAdjustmentActionChange(option.value) }}
          />
          <input
            type="number"
            min={0}
            value={bidAdjustmentValue}
            onChange={(event) => { onBidAdjustmentValueChange(event.target.value)}}
          />
          % for placements other than top of search
        </div>
        {
          error && (
            <div className="warning-message">
              { error }
            </div>
          )
        }
      </div>
    )
  }

  return (
    <div className="section-container sb-bid-section">
      <div className="section-title">
        Bidding
      </div>
      <CheckboxComponent
        label="Allow Amazon to automatically optimize bids for placements other than top of search."
        checked={bidOptimization}
        onChange={onBidOptimizationChange}
      />
      <div className="auto-bidding-note">
        Automatic bidding or bid adjustments will not be
        applied to the ads with video ad format
      </div>
      { renderCustom() }
    </div>
  )
}

export default SBBidSection
