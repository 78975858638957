// Actions for DSP manager.
import { callPost } from '../../services/axios'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import { APPLY_CAMPAIGN_COLUMNS } from '../actionTypes/dsp'
import { getISODate } from '../../services/helper'

const getRequestFields = (columns) => {
  let fields = []
  if (!columns.length) {
    return []
  }

  columns.forEach(c => {
    if (c.required) {
      fields = [...new Set([...fields, ...c.required])]
    } else {
      fields.push(c.key)
    }
  })
  return fields
}

export const applyCampaignColumn = (columns) => (dispatch) => {
  dispatch({
    type: APPLY_CAMPAIGN_COLUMNS,
    data: columns,
  })
}

export const getAdvertisers = (accessToken, selectedDspAccount, columns) => (dispatch, getState) => {
  const {
    header: {
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callPost('/dsp/getAdvertisers', {
    userId: selectedDspAccount.user_id,
    selectedFields: getRequestFields(columns),
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get advertisers.'
    })
    return []
  })
}

export const getOrders = (accessToken, selectedDspAccount, columns) => (dispatch, getState) => {
  const {
    header: {
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callPost('/dsp/getOrders', {
    userId: selectedDspAccount.user_id,
    selectedFields: getRequestFields(columns),
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get orders.'
    })
    return []
  })
}

export const getLineItems = (accessToken, selectedDspAccount, columns) => (dispatch, getState) => {
  const {
    header: {
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callPost('/dsp/getLineItems', {
    userId: selectedDspAccount.user_id,
    selectedFields: getRequestFields(columns),
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get line items.'
    })
    return []
  })
}
