import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import VideoLink from '../CommonComponents/VideoLink'
import CustomTable from '../CommonComponents/CustomTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'

const videoList = [
  { title: 'Update Target ACoS', url: 'https://www.loom.com/embed/e5ce9e44830142e9b08a792f6bf7a4ca' },
]

const UpdateTargetAcosModal = ({ campaigns, accountAcos, show, onUpdate, onClose }) => {
  const [selectedCampaigns, setSelectedCampaigns ] = useState([])
  const [defaultTargetAcos, setDefaultTargetAcos] = useState(accountAcos)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setSelectedCampaigns(JSON.parse(JSON.stringify(campaigns)))
  }, [campaigns]) // eslint-disable-line

  const handleDefaultTargetAcos = (value) => {
    if (parseFloat(value) < 0) {
      toast.show({
        title: 'Warning',
        description: 'Percentage value must be higher than 0.',
      })
      return
    }
    setDefaultTargetAcos(value)
  }

  const handleAcosChange = (campaignId, value) => {
    if (parseFloat(value) < 0) {
      toast.show({
        title: 'Warning',
        description: 'Percentage value must be higher than 0.',
      })
      return
    }

    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      if (campaign.campaign_id.toString() === campaignId.toString()) {
        campaign.target_acos = value
      }
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleApplyAll = () => {
    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      campaign.target_acos = defaultTargetAcos
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleSave = async () => {
    setIsSaving(true)
    await onUpdate(selectedCampaigns)
    setIsSaving(false)
  }

  const renderAction = () => (
    <>
      <label>
        Default Target ACoS (%)
      </label>
      <input
        type="number"
        min="0"
        value={defaultTargetAcos}
        onChange={(event) => { handleDefaultTargetAcos(event.target.value) }}
      />
      <button type="button" className="btn btn-green" onClick={() => handleApplyAll()} >
        Apply All
      </button>
    </>
  )

  const renderRecord = record => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col">
        <input
          type="number"
          min={0}
          value={record.target_acos || accountAcos}
          onChange={(event) => { handleAcosChange(record.campaign_id, event.target.value) }}
        />
      </div>
    </>
  )

  return (
    <Modal className="target-acos-modal" backdrop="static" size="md" show={show}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Update Target ACoS
          <VideoLink
            videoList={videoList}
            modalTitle="Update Target ACoS"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          className="table-keywords"
          isLoading={isSaving}
          records={selectedCampaigns || []}
          noCheckBox
          idField="campaign_id"
          noSearch
          renderTopRight={renderAction}
          renderRecord={renderRecord}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col">Target ACoS (%)</div>
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          disabled={isSaving}
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateTargetAcosModal
