import React from 'react'

import CustomTagPicker from '../../../../CommonComponents/CustomTagPicker'

const CampaignDetails = ({ name, acos, tagList, selectedTags,
  isNonEndemicAccount, onNameChange, onAcosChange, onTagChange }) => {
  const handleNameChange = (event) => {
    if (event.target.value) {
      onNameChange(event.target.value)
    }
  }

  const handleAcosChange = (event) => {
    if (event.target.value) {
      onAcosChange(parseFloat(event.target.value))
    }
  }

  return (
    <div className="sub-section">
      <div className="vertical-sub-section">
        <div className="section-title">
          Campaign Details
        </div>
        <div className="input-row">
          Name
          <input type="text" value={name} onChange={handleNameChange} />
        </div>
        {
          !isNonEndemicAccount && (
            <div className="input-row">
              Target ACoS (%)
              <input type="number" value={acos} onChange={handleAcosChange} />
            </div>
          )
        }
      </div>
      <div className="vertical-sub-section">
        <div className="section-title">Tags</div>
        <CustomTagPicker
          creatable
          placeholder="Select Tags"
          data={tagList}
          value={selectedTags}
          menuStyle={{ width: 400 }}
          onChange={onTagChange}
        />
      </div>
    </div>
  )
}

export default CampaignDetails
