import {
  GET_SKUS_SUCCEED,
  GET_PRODUCT_SUCCEED,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_START,
  GET_PRODUCT_KPI_START,
  GET_PRODUCT_KPI_SUCCEED,
  GET_PRODUCT_KPI_FAIL,
  GET_PRODUCT_CHART_DATA_START,
  GET_PRODUCT_CHART_DATA_SUCCEED,
  GET_PRODUCT_CHART_DATA_FAIL,
  GET_PRODUCTS_SUCCEED,
  UPDATE_BULK_COGS_SUCCESS,
  UPDATE_PRODUCT_COG_START,
  UPDATE_PRODUCT_COG_SUCCESS,
  UPDATE_PRODUCT_COG_FAIL,
  GET_PRODUCT_KEYWORDS_START,
  GET_PRODUCT_KEYWORDS_SUCCESS,
  GET_PRODUCT_KEYWORDS_FAIL,
  GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED,
} from '../actionTypes/product.js'

export const initialState = {
  curProduct: {},
  curProductKpi: {},
  curProductChart: [],
  skus: [],
  campaignsForProduct: [],
  productList: [],
  isProductLoading: false,
  isProductKpiLoading: false,
  isProductChartLoading: false,
  isUpdateProductCog: false,
  isLoadingProductKeywords: false,
  productKeywords: [],
}

const product = (state = initialState, action) => {
	switch (action.type) {
    case GET_PRODUCT_KEYWORDS_START:
      return {
        ...state,
        isLoadingProductKeywords: true,
        productKeywords: []
      }
    case GET_PRODUCT_KEYWORDS_SUCCESS:
      return {
        ...state,
        isLoadingProductKeywords: false,
        productKeywords: action.data
      }
    case GET_PRODUCT_KEYWORDS_FAIL:
      return {
        ...state,
        isLoadingProductKeywords: false,
        productKeywords: [],
      }
    case UPDATE_PRODUCT_COG_START:
      return {
        ...state,
        isUpdateProductCog: true
      }
    case UPDATE_PRODUCT_COG_SUCCESS:
      return {
        ...state,
        productList: (state.productList || []).map(((product) => {
          if (product.id.toString() !== action.data.id.toString()) {
            return product
          }
          return Object.assign({}, product, {
            cog: action.data.cog,
          })
        })),
        curProduct: action.data.id.toString() === state.curProduct.id.toString()
          ?
          {
            ...state.curProduct,
            cog: action.data.cog,
          }
          : state.curProduct,
        isUpdateProductCog: false,
      }
    case UPDATE_PRODUCT_COG_FAIL:
      return {
        ...state,
        isUpdateProductCog: false,
      }
    case GET_PRODUCTS_SUCCEED:
      return {
        ...state,
        productList: action.data,
      }
    case GET_SKUS_SUCCEED:
      return {
        ...state,
        skus: action.data,
      }
    case GET_PRODUCT_SUCCEED:
      return {
        ...state,
        curProduct: action.data,
        isProductLoading: false,
      }
    case GET_PRODUCT_FAIL:
      return {
        ...state,
        isProductLoading: false
      }
    case GET_PRODUCT_START:
      return {
        ...state,
        isProductLoading: true
      }
    case GET_PRODUCT_KPI_START:
      return {
        ...state,
        isProductKpiLoading: true
      }
    case GET_PRODUCT_KPI_SUCCEED:
      return {
        ...state,
        isProductKpiLoading: false,
        curProductKpi: action.data
      }
    case GET_PRODUCT_KPI_FAIL:
      return {
        ...state,
        isProductKpiLoading: false,
      }
    case GET_PRODUCT_CHART_DATA_START:
      return {
        ...state,
        isProductChartLoading: true
      }
    case GET_PRODUCT_CHART_DATA_SUCCEED:
      return {
        ...state,
        isProductChartLoading: false,
        curProductChart: action.data
      }
    case GET_PRODUCT_CHART_DATA_FAIL:
      return {
        ...state,
        isProductChartLoading: false,
      }
    case UPDATE_BULK_COGS_SUCCESS:
      const updatedProductList = [...state.productList]
      const changedSkus = action.data.successSkus || []
      if (changedSkus.length) {
        changedSkus.forEach((changedSku) => {
          const index = updatedProductList.findIndex(tmpSku => tmpSku.sku === changedSku.sku)
          if (index !== -1) {
            updatedProductList[index].cog = changedSku.cog
          }
        })
      }
      return {
        ...state,
        productList: updatedProductList,
      }
    case GET_CAMPAIGNS_FOR_PRODUCT_SUCCEED:
      return {
        ...state,
        campaignsForProduct: action.data
      }
    default:
      return state
  }
}

export default product