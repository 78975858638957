import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import CampaignKpi from './sections/CampaignKpi'
import CampaignInfo from './sections/CampaignInfo'
import CampaignCreative from './sections/CampaignCreative'
import StreamView from '../../../CommonComponents/StreamView'
import TargetStreamView from './sections/TargetStreamView'
import NegativeKeywordSection from './sections/NegativeKeywordSection'
import NegativeTargetSection from './sections/NegativeTargetSection'
import AddNegativesModal from './sections/AddNegativesModal'
import ActivityLogTable from '../../../CommonComponents/ActivityLogTable'

import { getKpi, getLogs, getStream } from '../../../../redux/actions/campaignDetail'

const CampaignDashboardComponent = ({ isNonEndemicAccount }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentLogs = useSelector(state => state.campaignDetail.currentLogs)

  const { id: campaignId, campaignType } = useParams()

  const [kpiData, setKpiData] = useState({})
  const [chartData, setChartData] = useState([])
  const [visibleNegativesType, setVisibleNegativesType] = useState(null)
  const [isKpiLoading, setIsKpiLoading] = useState(false)
  const [isStreamLoading, setIsStreamLoading] = useState(false)
  const [isLogsLoading, setIsLogsLoading] = useState(false)
  const [currentStreams, setCurrentStreams] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();
    (async () => {
      const accessToken = await getAccessTokenSilently()

      Promise.all([
        (async () => {
          setIsStreamLoading(true)
          try {
            const data = await dispatch(getStream(
              accessToken,
              campaignId,
              campaignType,
              abortCtrl.signal,
            ))
            setCurrentStreams(data)
            setIsStreamLoading(false)
          } catch (isCancelled) {
            if (!isCancelled) {
              setIsStreamLoading(false)
            }
          }
        })(),
        (async () => {
          setIsKpiLoading(true)
          try {
            const data = await dispatch(getKpi(
              accessToken,
              campaignId,
              campaignType,
              abortCtrl.signal,
            ))
            if (data) {
              setKpiData(data.kpi)
              setChartData(data.chart)
            }
            setIsKpiLoading(false)
          } catch (isCancelled) {
            if (!isCancelled) {
              setIsKpiLoading(false)
            }
          }
        })(),
        (async () => {
          setIsLogsLoading(true)
          try {
            await dispatch(getLogs(
              accessToken,
              campaignId,
              abortCtrl.signal,
            ))
            setIsLogsLoading(false)
          } catch (isCancelled) {
            if (!isCancelled) {
              setIsLogsLoading(false)
            }
          }
        })(),
      ])
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [campaignId, currentStartDate, currentEndDate]) // eslint-disable-line

  return (
    <div className="campaign-detail-dashboard">
      <CampaignKpi
        kpi={kpiData}
        chart={chartData}
        isLoading={isKpiLoading}
        isNonEndemicAccount={isNonEndemicAccount}
        rate={currencyRate}
        sign={currencySign}
      />
      <CampaignInfo
        campaignId={campaignId}
        isNonEndemicAccount={isNonEndemicAccount}
      />
      {
        currentDetail && campaignType !== 'sp' && (
          <CampaignCreative
            campaignId={campaignId}
            campaignType={campaignType}
            products={currentDetail.products || []}
          />
        )
      }
      <StreamView
        isLoading={isStreamLoading}
        streams={currentStreams}
      />
      <TargetStreamView
        isLoading={isStreamLoading}
        streams={currentStreams}
      />
      {
        !isNonEndemicAccount && (
          <>
            {
              (!currentDetail || campaignType !== 'sd') && (
                <NegativeKeywordSection
                  campaignId={campaignId}
                  campaignType={campaignType}
                  onNegativesAdd={() => { setVisibleNegativesType('keyword') }}
                />
              )
            }
            <NegativeTargetSection
              campaignId={campaignId}
              campaignType={campaignType}
              onNegativesAdd={() => { setVisibleNegativesType('product') }}
            />
          </>
        )
      }
      <ActivityLogTable
        isLoading={isLogsLoading}
        currentLogs={currentLogs}
      />
      {
        visibleNegativesType && (
          <AddNegativesModal
            campaignId={campaignId}
            campaignType={currentDetail.campaignType}
            targetingType={currentDetail.targeting_type}
            targetType={visibleNegativesType}
            onClose={() => { setVisibleNegativesType(null) }}
          />
        )
      }
    </div>
  )
}

export default CampaignDashboardComponent
