import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import ConfirmModal from '../CommonComponents/ConfirmModal'
import CustomTagPicker from '../CommonComponents/CustomTagPicker'
import VideoLink from '../CommonComponents/VideoLink'
import CustomTable from '../CommonComponents/CustomTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'

import {
  getAllTags,
  getBulkTagsForCampaigns,
  createAndAddTagToCampaigns,
  createBulkTagsForCampaigns,
  removeBulkTagsFromCampaigns
} from '../../redux/actions/tag'
import { updateCampaignTags } from '../../redux/actions/campaign'

const videoList = [
  { title: 'Tags', url: 'https://www.loom.com/embed/cfe89042856547148042e517d15b8975' },
]

const UpdateTagsModal = ({ campaigns, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isTagLoading = useSelector(state => state.tag.isLoading)
  const tags = useSelector(state => state.tag.tags)
  const bulkTagsForCampaigns = useSelector(state => state.tag.bulkTagsForCampaigns)

  const [selectedCampaigns, setSelectedCampaigns ] = useState([])
  const [tagList, setTagList] = useState([])
  const [selectedTags, setSelectedTags] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedBulkTags, setSelectedBulkTags] = useState([])
  const [isLoadingForCampaigns, setIsLoadingForCampaigns] = useState(false)
  const [isCreatingTag, setIsCreatingTag] = useState(false)
  const [isCreatingTagsForCampaigns, setIsCreatingTagsForCampaigns] = useState(false)
  const [isRemovingTags, setIsRemovingTags] = useState(false)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAllTags(accessToken))
    })()
  }, [dispatch]) // eslint-disable-line

  useEffect(() => {
    const initCampaigns = JSON.parse(JSON.stringify(campaigns))
    const campaignIds = initCampaigns.map(campaign => campaign.campaign_id)

    setSelectedCampaigns(initCampaigns.sort(
      (a, b) => a.campaign.toLowerCase() < b.campaign.toLowerCase() ? -1 : 1
    ));

    (async () => {
      setIsLoadingForCampaigns(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getBulkTagsForCampaigns(accessToken, campaignIds))
      setIsLoadingForCampaigns(false)
    })()

  }, [campaigns]) // eslint-disable-line

  useEffect(() => {
    if (!isTagLoading && tags.length > 0) {
      setTagList(tags.map(t => ({
        label: t.tag_name,
        value: t.id,
      })))
    }
  }, [tags, isTagLoading])

  useEffect(() => {
    if (!isLoadingForCampaigns) {
      setSelectedTags({...bulkTagsForCampaigns})
    }
  }, [isLoadingForCampaigns, bulkTagsForCampaigns])

  const handleUpdatedCampaigns = async () => {
    setIsCreatingTagsForCampaigns(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(createBulkTagsForCampaigns(accessToken, selectedTags))
    setIsCreatingTagsForCampaigns(false)

    dispatch(updateCampaignTags(selectedTags))
    onClose()
  }

  const handleTagPickerChange = async (value, campaignId) => {
    if (value === null) {
      setSelectedTags(p => ({
        ...p,
        [campaignId]: []
      }))
      return
    }

    const added = value.filter(v => !selectedTags[campaignId].includes(v))
    const removed = selectedTags[campaignId].filter(t => !value.includes(t))

    if (added.length) {
      const shouldCreate = tagList.findIndex(t => t.value === added[0]) === -1
      if (shouldCreate) {
        setIsCreatingTag(true)
        const accessToken = await getAccessTokenSilently()
        const data = await dispatch(createAndAddTagToCampaigns(accessToken, added[0], []))
        setIsCreatingTag(false)

        setSelectedTags(p => {
          const t = {...p}
          if (!t[campaignId]) {
            t[campaignId] = []
          }
          if (!t[campaignId].includes(data.id)) {
            t[campaignId].push(data.id)
          }
          return t
        })
      } else {
        setSelectedTags(p => {
          const t = {...p}
          if (!t[campaignId]) {
            t[campaignId] = []
          }
          t[campaignId].push(added[0])
          return t
        })
      }
    } else if (removed.length) {
      setSelectedTags(p => ({
        ...p,
        [campaignId]: p[campaignId].filter(t => t !== removed[0])
      }))
    }
  }

  const handleModalConfirm = (confirmed) => {
    if (confirmed) {
      removeAllTags()
    }
    setShowConfirmModal(false)
  }

  const removeAllTags = async () => {
    setSelectedTags(p => {
      let t = {...p}
      Object.keys(p).forEach((campaign_id) => {
        t[campaign_id] = []
      })
      return t
    })

    setIsRemovingTags(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(removeBulkTagsFromCampaigns(accessToken, Object.keys(selectedTags)))
    setIsRemovingTags(false)
  }

  const handleBulkTagsChange = async (value) => {
    if (value === null) {
      setSelectedBulkTags([])
      return
    }
    const added = value.filter(v => !selectedBulkTags.includes(v))
    const removed = selectedBulkTags.filter(t => !value.includes(t))
    if (added.length) {
      const isCreated = tagList.findIndex(t => t.value === added[0]) === -1
      if (isCreated) {
        setIsCreatingTag(true)
        const accessToken = await getAccessTokenSilently()
        const data = await dispatch(createAndAddTagToCampaigns(accessToken, added[0], []))
        setIsCreatingTag(false)

        setSelectedTags(p => {
          const t = {...p}
          Object.keys(p).forEach((campaignId) => {
            if (!t[campaignId].includes(data.id)) {
              t[campaignId].push(data.id)
            }
          })
          return t
        })
        setSelectedBulkTags(p => [...p, data.id])
      } else {
        setSelectedTags(p => {
          const t = {...p}
          Object.keys(p).forEach((campaignId) => {
            if (!t[campaignId].includes(added[0])) {
              t[campaignId].push(added[0])
            }
          })
          return t
        })
        setSelectedBulkTags(p => [...p, added[0]])
      }
    } else if (removed.length) {
      setSelectedTags(p => {
        const t = {...p}
        Object.keys(p).forEach(campaignId => {
          t[campaignId] = p[campaignId].filter(tId => tId !== removed[0])
        })
        return t
      })
      setSelectedBulkTags(p => p.filter(t => t !== removed[0]))
    }
  }

  const isDataLoading = isTagLoading
    || isLoadingForCampaigns
    || isCreatingTag
    || isCreatingTagsForCampaigns
    || isRemovingTags

  const renderAction = () => (
    <>
      <CustomTagPicker
        creatable
        placeholder="Select or Add Tags"
        data={tagList}
        style={{ width: 300 }}
        value={selectedBulkTags}
        onChange={(v, e) => { handleBulkTagsChange(v) }}
      />
      <button
        type="button"
        className="btn btn-red"
        onClick={() => setShowConfirmModal(true)}
      >
        Remove All Tags
      </button>
    </>
  )

  const renderCampaign = record => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col col-tag">
        <CustomTagPicker
          creatable
          className="custom-tag-picker"
          placeholder="Select or Add Tags"
          data={tagList}
          value={selectedTags[record.campaign_id] || []}
          onChange={(v) => { handleTagPickerChange(v, record.campaign_id) }}
        />
      </div>
    </>
  )

  const renderFooter = () => (
    <Modal.Footer>
      <button type="button" className="rs-btn rs-btn-primary" onClick={() => handleUpdatedCampaigns()}>
        Save
      </button>
      <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className="update-tags-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Tags
          <VideoLink
            videoList={videoList}
            modalTitle="Tags"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          records={selectedCampaigns}
          isLoading={isDataLoading}
          className="table-tags"
          idField="campaign_id"
          noCheckBox
          searchFields={['campaign']}
          renderTopRight={renderAction}
          renderRecord={renderCampaign}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col">Tags</div>
        </CustomTable>
        <ConfirmModal
          text="Are you sure to delete all tags?"
          show={showConfirmModal}
          onConfirm={handleModalConfirm}
        />
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default UpdateTagsModal
