import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiMaximize2, FiMinimize2, FiFilter, FiColumns } from 'react-icons/fi'

import DateRangeComponent from '../CommonComponents/DateRangeComponent'

import MyCampaignTable from '../MyCampaignTable'
import ProductTableComponent from '../ProductTableComponent'
import PortfolioTableComponent from '../PortfolioTableComponent'
import TagTableComponent from '../TagTableComponent'
import RuleTableComponent from '../RuleTableComponent'
import BudgetRecommendationTable from '../BudgetRecommendationTable'
import DspComponent from '../DspComponent'

import { ReactComponent as TaskOnSvg } from '../../assets/svg/task.svg'
import { ReactComponent as TaskOffSvg } from '../../assets/svg/task-passive.svg'

import { toggleDashboardTable } from '../../redux/actions/dashboard'
import { setDateRange } from '../../redux/actions/header'
import {
  showFilter,
  showColumnEditorAction,
} from '../../redux/actions/pageGlobal'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const TAB_CAMPAIGN = 'campaign'
const TAB_PRODUCT = 'product'
const TAB_BUDGET = 'budget'
const TAB_DSP = 'dsp'
export const TAB_PORTFOLIO = 'portfolio'
export const TAB_TAG = 'tag'
export const TAB_RULE = 'rule'

const tabList = [
  { tab: TAB_CAMPAIGN, name: 'My Campaigns' },
  { tab: TAB_PRODUCT, name: 'My Products' },
  { tab: TAB_BUDGET, name: 'Budgets' },
  { tab: TAB_DSP, name: 'DSP' },
]

const filterNameList = {
  [TAB_CAMPAIGN]: 'campaignTable',
  [TAB_PRODUCT]: 'productTable',
}

const TableCollection = () => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const maxTable = useSelector(state => state.dashboard.maxTable)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const accountList = useSelector(state => state.header.accountList)

  const [activeTab, setActiveTab] = useState(TAB_CAMPAIGN)
  const [isShowNotedCampaigns, setIsShowNotedCampaigns] = useState(false)

  const tabsToRender = useMemo(() => {
    const hasDspAccount = accountList.find(account =>
      account.seller_type === 'agency' && account.sub_type === 'dsp'
    )
    let filtered
    if (hasDspAccount) {
      filtered = tabList
    } else {
      filtered = tabList.filter(tab => tab.tab !== TAB_DSP)
    }

    if (isNonEndemicAccount) {
      filtered = tabList.filter(tab =>
        tab.tab !== TAB_PRODUCT && tab.tab !== TAB_PORTFOLIO
      )
    }

    return filtered
  }, [accountList, isNonEndemicAccount])

  const handleDateRangeChange = ([ startDate, endDate ]) => {
    dispatch(setDateRange({
      startDate,
      endDate,
    }))
  }

  const handleFilterShow = () => {
    dispatch(showFilter(filterNameList[activeTab]))
  }

  const handleColumnEditorShow = () => {
    dispatch(showColumnEditorAction())
  }

  const renderMaxMin = () => {
    if (!maxTable) {
      return (
        <FiMaximize2
          color='#979797'
          onClick={() => { dispatch(toggleDashboardTable()) }}
        />
      )
    }
    return (
      <FiMinimize2
        color='#979797'
        onClick={() => { dispatch(toggleDashboardTable()) }}
      />
    )
  }

  const renderContents = () => {
    if (activeTab === TAB_CAMPAIGN) {
      return (
        <MyCampaignTable
          showNotedCampaigns={isShowNotedCampaigns}
          onChangeViewMethod={setActiveTab}
        />
      )
    }
    if (activeTab === TAB_PRODUCT) {
      return <ProductTableComponent />
    }
    if (activeTab === TAB_PORTFOLIO) {
      return <PortfolioTableComponent />
    }
    if (activeTab === TAB_TAG) {
      return <TagTableComponent />
    }
    if (activeTab === TAB_BUDGET) {
      return <BudgetRecommendationTable />
    }
    if (activeTab === TAB_RULE) {
      return <RuleTableComponent />
    }
    if (activeTab === TAB_DSP) {
      return <DspComponent />
    }
    return null
  }

  const renderAction = () => (
    <>
      {
        (activeTab === TAB_CAMPAIGN || activeTab === TAB_PRODUCT) && (
          <FiFilter
            title="Filter"
            color="#979797"
            onClick={handleFilterShow}
          />
        )
      }
      {
        activeTab === TAB_CAMPAIGN && (
          isShowNotedCampaigns ? (
            <TaskOnSvg
              title="Show Noted Campaigns"
              onClick={() => { setIsShowNotedCampaigns(false) }}
            />
          ) : (
            <TaskOffSvg
              title="Show Noted Campaigns"
              onClick={() => { setIsShowNotedCampaigns(true) }}
            />
          )
        )
      }
      {
        (activeTab === TAB_CAMPAIGN || activeTab === TAB_PRODUCT) && (
          <FiColumns
            title="Column Customizer"
            color="#979797"
            onClick={handleColumnEditorShow}
          />
        )
      }
    </>
  )

  return (
    <div className="table-collection">
      <div className="table-tabs">
        <div className="table-tab-left">
          {
            tabsToRender.map(tab => {
              let tabName = tab.name
              if ((activeTab === TAB_PORTFOLIO || activeTab === TAB_RULE || activeTab === TAB_TAG)
                && tab.tab === TAB_CAMPAIGN) {
                tabName = 'Back To My Campaigns'
              }
              return (
                <button
                  key={tab.tab}
                  type="button"
                  className={`table-tab${activeTab === tab.tab ? ' selected' : ''}`}
                  onClick={() => { setActiveTab(tab.tab) }}
                >
                  { tabName }
                </button>
              )
            })
          }
        </div>
        <div className="table-tab-right">
          {
            activeTab !== TAB_RULE && (
              <>
                <DateRangeComponent
                  value={[currentStartDate, currentEndDate]}
                  placement="bottomEnd"
                  onChange={handleDateRangeChange}
                />
                { renderAction() }
              </>
            )
          }
          { renderMaxMin() }
        </div>
      </div>
      { renderContents() }
    </div>
  )
}

export default TableCollection
