import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'rsuite'

import LoaderComponent from '../CommonComponents/LoaderComponent'

import {
  formatValue,
  formatCurrency,
} from '../../services/helper'

const ProductTestingDetailComponent = ({ show, onClose }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentTest = useSelector(state => state.productDetail.currentTest)
  const isLoadingTest = useSelector(state => state.productDetail.isLoadingTest)

  const renderMeta = () => (
    <div className="test-meta">
      <span className="test-name-label">Test Name:</span>
      <strong>{ currentTest.name }</strong>
    </div>
  )

  const renderKpis = (kpi) => (
    <div className="results-column">
      <div className="results-row">
        <div className="results-box results-revenue">
          <span>Gross Revenue</span>
          <span className="results-value">
            { formatCurrency(kpi.revenue, currencySign, currencyRate) }
          </span>
        </div>
      </div>
      <div className="results-row">
        <div className="results-box results-spend">
          <span>Ad Spend</span>
          <span className="results-value">
            { formatCurrency(kpi.cost, currencySign, currencyRate) }
          </span>
        </div>
        <div className="results-box results-impression">
          <span>Impressions</span>
          <span className="results-value">
            { formatValue(kpi.impressions, 'number', 0) }
          </span>
        </div>
      </div>
      <div className="results-row">
        <div className="results-box results-clicks">
          <span>Clicks</span>
          <span className="results-value">
            { formatValue(kpi.clicks, 'number', 0) }
          </span>
        </div>
        <div className="results-box results-ctr">
          <span>CTR(%)</span>
          <span className="results-value">
            { formatValue(kpi.ctr, 'percent') }
          </span>
        </div>
      </div>
      <div className="results-row">
        <div className="results-box results-conversion">
          <span>Conversion Rate(%)</span>
          <span className="results-value">
            { formatValue(kpi.conversion, 'percent') }
          </span>
        </div>
        <div className="results-box results-acos">
          <span>ACoS(%)</span>
          <span className="results-value">
            { formatValue(kpi.acos, 'percent') }
          </span>
        </div>
      </div>
    </div>
  )

  const renderResults = () => (
    <div className="test-results">
      { renderKpis(currentTest.currentStats) }
      { renderKpis(currentTest.prevStats) }
    </div>
  )

  const renderNotesCol = (data, name) => (
    <div className="notes-column">
      <div className="notes-header">
        { name }
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <span>Title</span>
          <textarea value={data.title} disabled />
        </div>
        <div className="notes-box">
          <span>Summary</span>
          <textarea value={data.summary} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <span>Bullets</span>
          <textarea value={data.bullet1} disabled />
        </div>
        <div className="notes-box">
          <span>&nbsp;</span>
          <textarea value={data.bullet2} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <textarea value={data.bullet3} disabled />
        </div>
        <div className="notes-box">
          <textarea value={data.bullet4} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <textarea value={data.bullet5} disabled />
        </div>
        <div className="notes-box">
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <span>Backend Keywords</span>
          <textarea value={data.backend1} disabled />
        </div>
        <div className="notes-box">
          <span>&nbsp;</span>
          <textarea value={data.backend2} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <textarea value={data.backend3} disabled />
        </div>
        <div className="notes-box">
          <textarea value={data.backend4} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <textarea value={data.backend5} disabled />
        </div>
        <div className="notes-box">
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <span>Price</span>
          <input type="text" value={data.price} disabled />
        </div>
        <div className="notes-box">
          <span>Discount</span>
          <input type="text" value={data.discount} disabled />
        </div>
      </div>
      <div className="notes-row">
        <div className="notes-box">
          <span>Coupon</span>
          <input type="text" value={data.coupon} disabled />
        </div>
        <div className="notes-box"></div>
      </div>
    </div>
  )

  const renderNotes = () => (
    <div className="test-notes">
      { renderNotesCol(currentTest.before_data, 'Statistics A') }
      { renderNotesCol(currentTest.after_data, 'Statistics B') }
    </div>
  )

  return (
    <Modal className="testing-detail-modal" backdrop="static" size="lg" show={show}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>A/B Split Testing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (!show || isLoadingTest || Object.keys(currentTest) === 0) ? (
            <LoaderComponent />
          ) : (
            <>
              { renderMeta() }
              { renderResults() }
              { renderNotes() }
            </>
          )
        }
      </Modal.Body>
    </Modal>
  )
}

export default ProductTestingDetailComponent
