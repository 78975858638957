import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import AdTypeSection from './AdTypeSection'

import { parsePerformanceSummary } from '../../services/helper'

const Component = withReportSection(AdTypeSection, {
  sectionId: 'sponsored_products',
  reportSectionKey: 'sponsoredProductAds',
})

const SponsoredProductsAds = ({
  settings,
  isLoadingSettings,
  onLoadStats,
  onSaveSettings,
}) => {
  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const spChart = useSelector(state => state.accountHealth.spChart)
  const spPastMetrics = useSelector(state => state.accountHealth.spPastMetrics)
  const spPerformanceData = useSelector(state => state.accountHealth.spPerformanceData)

  const { placementTotal, bidTotal } = useMemo(() => {
    return parsePerformanceSummary(spPerformanceData.placementSummary)
  }, [spPerformanceData])

  return (
    <Component
      title="Sponsored Products"
      sectionKey="sponsoredProductAds"
      noteKey="notes_sp"
      customMetricSection="sp"
      settings={settings}
      isLoadingSettings={isLoadingSettings}
      onSaveSettings={onSaveSettings}
      results={spMetrics}
      pastResults={spPastMetrics}
      charts={spChart}
      matchTypeSummary={spPerformanceData ? spPerformanceData.matchTypeSummary : []}
      placementTotal={placementTotal}
      bidTotal={bidTotal}
      onLoadStats={onLoadStats}
    />
  )
}

export default SponsoredProductsAds
