import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import {
  GET_ALL_SPONSORED_DATA_SUCCEED,
  SET_REPORT_DATE_RANGE,
  GET_HEALTH_SALES_DATA_SUCCEED,
  GET_SPONSORED_PRODUCTS_DATA_SUCCEED,
  GET_SPONSORED_BRANDS_DATA_SUCCEED,
  GET_SPONSORED_DISPLAY_DATA_SUCCEED,
  GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED,
  GET_PLACEMENT_PERFORMANCE_SP_DATA,
  GET_PLACEMENT_PERFORMANCE_SB_DATA,
} from '../actionTypes/accountHealth'

export const initialState = {
  startDate: startOfMonth(subMonths(new Date(), 1)),
  endDate: endOfMonth(subMonths(new Date(), 1)),
  allSponsoredMetrics: null,
  allSponsoredChart: [],
  allSponsoredPastMetrics: null,
  salesData: null,
  spMetrics: null,
  spChart: [],
  spPastMetrics: null,
  sbMetrics: null,
  sbChart: [],
  sbPastMetrics: null,
  sdMetrics: null,
  sdChart: [],
  sdPastMetrics: null,
  performanceData: [],
  spPerformanceData: [],
  sbPerformanceData: [],
}

const header = (state = initialState, action) => {
	switch (action.type) {
    case SET_REPORT_DATE_RANGE:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
      }
    case GET_ALL_SPONSORED_DATA_SUCCEED:
      return {
        ...state,
        allSponsoredMetrics: action.data.kpi,
        allSponsoredChart: action.data.charts,
        allSponsoredPastMetrics: action.data.past.kpi,
      }
    case GET_HEALTH_SALES_DATA_SUCCEED:
      return {
        ...state,
        salesData: action.data
      }
    case GET_SPONSORED_PRODUCTS_DATA_SUCCEED:
      return {
        ...state,
        spMetrics: action.data.kpi,
        spChart: action.data.charts,
        spPastMetrics: action.data.past.kpi,
      }
    case GET_SPONSORED_BRANDS_DATA_SUCCEED:
      return {
        ...state,
        sbMetrics: action.data.kpi,
        sbChart: action.data.charts,
        sbPastMetrics: action.data.past.kpi,
      }
    case GET_SPONSORED_DISPLAY_DATA_SUCCEED:
      return {
        ...state,
        sdMetrics: action.data.kpi,
        sdChart: action.data.charts,
        sdPastMetrics: action.data.past.kpi,
      }
    case GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED:
      return {
        ...state,
        performanceData: action.data
      }
    case GET_PLACEMENT_PERFORMANCE_SP_DATA:
      return {
        ...state,
        spPerformanceData: action.data
    }
    case GET_PLACEMENT_PERFORMANCE_SB_DATA:
      return {
        ...state,
        sbPerformanceData: action.data
    }
    default:
      return state
  }
}

export default header