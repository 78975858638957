import React from 'react'
import Select from 'react-select'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import FieldRow from './FieldRow'

const maxList = [
  { value: 10, label: '10%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
]

const OpPlacement = ({ campaign, settings, fromTemplateEditor, onChange }) => {
  let isEligible = false
  if (fromTemplateEditor) {
    isEligible = true
  } else {
    let isAutoSP = false
    if (campaign) {
      isAutoSP = campaign.basic[0].type === 'sp'
        && campaign.basic[0].targeting_type === 'auto'
    }

    let hasEnabledAdvanced = false
    if (!isAutoSP) {
      const advancedSettings = [
        'op_adv_zero_sale_active',
        'op_adv_high_acos_active',
        'op_adv_genius_bid_active',
        'op_adv_low_ctr_active',
        'op_adv_low_conversion_active',
      ]

      hasEnabledAdvanced = typeof advancedSettings.find(field => (
        settings[field] || settings[`copy_${field}`]
      )) !== 'undefined'
    }

    isEligible = settings.op_basic_active
      || hasEnabledAdvanced
      || settings.ex_impressions_active
      || settings.ex_clicks_active
      || settings.ex_cost_active
      || settings.ex_revenue_active
  }

  const handleMaxChangeSet = (item) => {
    onChange('placement_max_change', item.value)
  }

  const maxChange = maxList.find(option => (
    parseFloat(option.value) === parseFloat(settings.placement_max_change)
  ))

  return (
    <div className={`placement-op-container${!isEligible ? ' disabled' : ''}`}>
      <span className="container-label">
        Placement Optimization
      </span>
      <div className="checkbox-info-wrapper">
        <CheckboxComponent
          label="We'll adjust the base bid and placements."
          checked={settings.placement_active}
          onChange={(checked) => { onChange('placement_active', checked) }}
        />
        <CustomTooltip>
          <p>
            How it works: Every 14 days, our system will run placement optimization
            to help match placement performance to the target ACoS.
          </p>
          <p>
            Then, taking the new placement modifiers into account,
            we'll adjust the bids of each target.
          </p>
          <p>
            To protect against large swings in bids, use the drop down
            to adjust the maximum % of change. For example, if you set 100%,
            then placements and base bids will both adjust
            to the full recommendation every 2 weeks.
          </p>
          <p>
            Sellers have found this to be too aggressive,
            so you may wish to start with a smaller percentage such as 10 or 25%.
            This means that we will apply only 10% of the recommendation
            to both the placements and base bids every two weeks to help the bids adjust slowly.
          </p>
        </CustomTooltip>
      </div>
      <FieldRow>
        <div className="field-wrapper">
          <div className="field-name">
            % of Full Recommendation
          </div>
          <Select
            options={maxList}
            placeholder="% of Full Recommendation"
            isDisabled={!settings.placement_active}
            value={maxChange}
            onChange={handleMaxChangeSet}
          />
        </div>
        <div className="field-wrapper" />
        <div className="field-wrapper" />
      </FieldRow>
    </div>
  )
}

export default OpPlacement
