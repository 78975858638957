import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Toggle } from 'rsuite'
import { getDay, getHours, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import LoaderComponent from '../LoaderComponent'
import SortableTable from '../SortableTableComponent'
import TableCell from '../TableCell'
import DateRangeComponent from '../DateRangeComponent'
import CustomTooltip from '../CustomTooltip'
import StreamChart from '../StreamChart'

import { setDateRange } from '../../../redux/actions/header'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'

import {
  dowList,
  STREAM_VIEW_MODE_DAY,
  STREAM_VIEW_MODE_DAY_HOUR,
  STREAM_VIEW_MODE_HOUR,
} from '../../../utils/defaultValues'

import { calcDerivedMetrics, tableSorter } from '../../../services/helper'

const modeList = [
  { value: STREAM_VIEW_MODE_DAY, label: 'By Day' },
  { value: STREAM_VIEW_MODE_DAY_HOUR, label: 'By Day and Hour' },
  { value: STREAM_VIEW_MODE_HOUR, label: 'By Hour' },
]

const metricColumns = [
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion Rate %', name: 'Conv %' },
]

const dayColumns = [
  { key: 'key', name: 'Day' },
  ...metricColumns,
]

const dayHourColumns = [
  { key: 'key', name: 'Day and Hour' },
  ...metricColumns,
]

const hourColumns = [
  { key: 'key', name: 'Hour' },
  ...metricColumns,
]

const StreamView = ({ isLoading, streams, noDatePicker = false }) => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const [currentMode, setCurrentMode] = useState(modeList[0])
  const [viewAsTable, setViewAsTable] = useState(true)

  const stats = useMemo(() => {
    // Fill up data for every available slot.
    const dataByKey = {}
    if (currentMode.value === STREAM_VIEW_MODE_DAY) {
      for (let day = 0; day <= 6; day += 1) {
        dataByKey[day] = {
          revenue: 0,
          cost: 0,
          impressions: 0,
          clicks: 0,
          orders: 0,
          units: 0,
        }
      }
    } else if (currentMode.value === STREAM_VIEW_MODE_DAY_HOUR) {
      for (let day = 0; day <= 6; day += 1) {
        for (let hour = 0; hour <= 23; hour += 1) {
          const key = `${day}-${hour < 10 ? `0${hour}` : hour}`
          dataByKey[key] = {
            revenue: 0,
            cost: 0,
            impressions: 0,
            clicks: 0,
            orders: 0,
            units: 0,
          }
        }
      }
    } else if (currentMode.value === STREAM_VIEW_MODE_HOUR) {
      for (let hour = 0; hour <= 23; hour += 1) {
        const key = hour < 10 ? `0${hour}` : hour
        dataByKey[key] = {
          revenue: 0,
          cost: 0,
          impressions: 0,
          clicks: 0,
          orders: 0,
          units: 0,
        }
      }
    }

    (streams || []).forEach((stream) => {
      const pstTime = utcToZonedTime(parseISO(stream.time_window + 'Z'), 'America/Los_Angeles')

      let key
      if (currentMode.value === STREAM_VIEW_MODE_DAY) {
        key = getDay(pstTime)
      } else if (currentMode.value === STREAM_VIEW_MODE_DAY_HOUR) {
        let hour = getHours(pstTime)
        if (hour < 10) {
          hour = `0${hour}`
        }
        key = `${getDay(pstTime)}-${hour}`
      } else if (currentMode.value === STREAM_VIEW_MODE_HOUR) {
        let hour = getHours(pstTime)
        if (hour < 10) {
          hour = `0${hour}`
        }
        key = hour
      }
      dataByKey[key].revenue += parseFloat(stream.revenue || 0)
      dataByKey[key].cost += parseFloat(stream.cost)
      dataByKey[key].impressions += parseInt(stream.impressions, 10)
      dataByKey[key].clicks += parseInt(stream.clicks, 10)
      dataByKey[key].orders += parseInt(stream.orders || 0, 10)
      dataByKey[key].units += parseInt(stream.units || 0, 10)
    })

    const dataArray = Object.keys(dataByKey).sort().map((key) => {
      let label
      if (currentMode.value === STREAM_VIEW_MODE_DAY) {
        label = dowList[key]
      } else if (currentMode.value === STREAM_VIEW_MODE_DAY_HOUR) {
        const [dow, hour] = key.split('-')
        label = `${dowList[dow]}-${hour} hour`
      } else if (currentMode.value === STREAM_VIEW_MODE_HOUR) {
        label = `${key} hour`
      }

      return {
        key,
        label,
        ...calcDerivedMetrics(dataByKey[key]),
      }
    })
    return dataArray
  }, [streams, currentMode])

  const [columnsToRender, metricColumnsToRender] = useMemo(() => {
    let columns
    if (currentMode.value === STREAM_VIEW_MODE_DAY) {
      columns = dayColumns
    } else if (currentMode.value === STREAM_VIEW_MODE_DAY_HOUR) {
      columns = dayHourColumns
    } else if (currentMode.value === STREAM_VIEW_MODE_HOUR) {
      columns = hourColumns
    }

    let metricCols = metricColumns
    if (isNonEndemicAccount) {
      const columnsToExclude = [
        'revenue',
        'acos',
        'orders',
        'conversion',
      ]

      columns = columns.filter(column => (
        !columnsToExclude.includes(column.key)
      ))

      metricCols = metricCols.filter(column => (
        !columnsToExclude.includes(column.key)
      ))
    }

    return [columns, metricCols]
  }, [isNonEndemicAccount, currentMode.value])

  const handleChangeDateRange = ([startDate, endDate]) => {
    dispatch(setDateRange({
      startDate,
      endDate,
    }))
  }

  const renderMode = () => (
    <div className="mode-container">
      {
        !noDatePicker && (
          <DateRangeComponent
            value={[currentStartDate, currentEndDate]}
            placement="bottomEnd"
            onChange={handleChangeDateRange}
          />
        )
      }
      <Select
        value={currentMode}
        options={modeList}
        onChange={setCurrentMode}
      />
      <Toggle
        checked={viewAsTable}
        checkedChildren="View as table"
        unCheckedChildren="View as chart"
        onChange={setViewAsTable}
      />
    </div>
  )

  const renderContents = () => {
    if (isLoading) {
      return <LoaderComponent />
    }

    if (viewAsTable) {
      return renderTable()
    }

    return (
      <StreamChart stats={stats || []} />
    )
  }

  const renderRecord = record => (
    <>
      <div className="table-col">
        { record.label }
      </div>
      {
        metricColumnsToRender.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTable = () => {
    return (
      <SortableTable
        columns={columnsToRender}
        defaultSort={['key', 'asc']}
        sorter={tableSorter()}
        records={stats || []}
        idField="key"
        noSearch
        noCheckBox
        renderRecord={renderRecord}
      />
    )
  }

  return (
    <div className="section">
      <div className="section-header">
        <div className="stream-header-wrapper">
          <h4>Metrics by Day and Hour</h4>
          <CustomTooltip placement="right">
            <p>We are using Pacific Time Zone.</p>
          </CustomTooltip>
        </div>
        { renderMode() }
      </div>
      <div className="stream-data-container">
        { renderContents() }
      </div>
    </div>
  )
}

export default StreamView
