import React from 'react'
import Select from 'react-select'
import { useLocation } from 'react-router-dom'
import { FiInfo } from 'react-icons/fi'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import AccountSelector from './AccountSelector'

import { SP_REDIRECT_URI } from '../../config/api'
import { STORAGE_KEY_STATE } from '../../utils/defaultValues'
import {getAmazonScopeList} from '../../utils/amazon'

const SignupAuditAmazonSection = (props) => {
  const { isLoading, adRefreshToken, region, regionList,
    profileList, selectedProfileIds, onChange, onSelectProfileIds } = props

  const location = useLocation()

  const handleClickGrantAccess = () => {
    window.gtag('event', 'singup_region_select_button')

    if (typeof window.amazon === 'undefined') {
      setTimeout(() => {
        if (typeof window.amazon === 'undefined') {
          toast.show({
            title: 'Danger',
            description: 'The Amazon script is not loaded.',
            duration: 20000,
          })
          return
        }
        initiateLWA()
      }, 5000)
      return
    }
    initiateLWA()
  }

  const initiateLWA = () => {
    const state = 'audit-' + (new Date()).valueOf()
    // Save state to sessionStorage for later validation.
    window.sessionStorage.setItem(STORAGE_KEY_STATE, state)

    window.amazon.Login.authorize({
      scope: getAmazonScopeList(),
      response_type: 'code',
      popup: false,
      state,
    }, SP_REDIRECT_URI)
  }

  const renderTooltip = () => {
    if (!adRefreshToken) {
      return (
        <div className="amazon-description">
          <FiInfo size={16} color="#303AB2" />
          <div>
            <p>
              Connecting with Amazon is needed in order to receive your customized insights,
              solutions and data attribution to reduce wasted ad spend.
            </p>
            <p>
              If you have multiple accounts, it may take several minutes to pull them all.
            </p>
          </div>
        </div>
      )
    }

    return (
      <span className="amazon-account-select-tip">
        Scroll down to select Amazon accounts and authorize PPCE
      </span>
    )
  }

  const renderContents = () => {
    if (adRefreshToken) {
      return (
        <AccountSelector
          profileList={profileList}
          selectedProfileIds={selectedProfileIds}
          onSelect={onSelectProfileIds}
        />
      )
    }

    const qs = new URLSearchParams(location.search)
    const callbackUrl = qs.get('amazon_callback_uri')

    if (isLoading || callbackUrl) {
      return <LoaderComponent />
    }

    return (
      <div className="region-selector">
        <label>
          Choose Region
        </label>
        <Select
          className="region-list"
          classNamePrefix="region-list"
          value={region}
          options={regionList}
          onChange={onChange}
        />
        <button
          type="button"
          className="btn btn-blue"
          onClick={handleClickGrantAccess}
        >
          Grant Access
        </button>
      </div>
    )
  }

  return (
    <div className="signup-section">
      <div className="section-name">
        Amazon Integration
        { renderTooltip() }
      </div>
      { renderContents() }
    </div>
  )
}

export default SignupAuditAmazonSection
