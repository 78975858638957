/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import TargetExInputModal from './TargetExInputModal'

const TargetExSection = ({ isCampaignsSelected, onFind }) => {
  const [isInputModalVisible, setIsInputModalVisible] = useState(false)
  const [targets, setTargets] = useState([])

  const handleInputModalOpen = (event) => {
    event.preventDefault()
    setIsInputModalVisible(true)
  }

  const handleTargetEnter = (enteredTargets) => {
    setTargets((enteredTargets || '').toLowerCase().split('\n')
      .filter(target => target !== ''))
    setIsInputModalVisible(false)
  }

  const handleFind = () => {
    onFind(targets)
  }

  let searchDisabled = false
  let buttonLabel = 'Find New Targets'
  if (!isCampaignsSelected) {
    searchDisabled = true
    buttonLabel = 'Select Campaigns to Continue'
  } else if (!targets.length) {
    searchDisabled = true
    buttonLabel = 'Enter Targets'
  }

  return (
    <>
      <a
        href="#"
        onClick={handleInputModalOpen}
      >
        Enter Targets
        { targets.length > 0 ? ` (${targets.length} target${targets.length > 1 ? 's' : ''} entered)` : '' }
      </a>
      <button
        type="button"
        className="btn btn-blue"
        disabled={searchDisabled}
        onClick={handleFind}
      >
        { buttonLabel }
      </button>
      <CustomTooltip placement="right">
        <ul>
          <li>
            Enter a list of targets or upload from bulk target research tools.
          </li>
          <li>
            Select the campaigns that you wish to check your targets against and click "Find New Targets".
          </li>
          <li>
            A list will populate containing only targets that don't already exist in the campaigns you checked against.
          </li>
          <li>
            You may then add these new targets to a new or existing campaign.
          </li>
          <li>
            Additional option: Click on "Individual words" to break the words down into their individual components.
          </li>
          <li>
            Keep researching and finding new target lists to check inside of this module.
          </li>
        </ul>
      </CustomTooltip>
      <TargetExInputModal
        show={isInputModalVisible}
        existingTargets={targets}
        onConfirm={handleTargetEnter}
        onClose={() => { setIsInputModalVisible(false) }}
      />
    </>
  )
}

export default TargetExSection
