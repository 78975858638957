import React from 'react'

import SBKeywordSection from './SBKeywordSection'
import NegativeKeywordSection from './NegativeKeywordSection'
import SBTargetingSection from './SBTargetingSection'
import NegativeTargetingSection from './NegativeTargetingSection'

const SBTargetInputSection = ({
  manualTarget,
  keywords,
  negativeKeywords,
  targetings,
  negativeTargetings,
  isSuggestionsLoading,
  keywordSuggestions,
  categorySuggestions,
  productSuggestions,
  onFind,
  onKeywordsSelect,
  onNksSelect,
  onTargetsSelect,
  onNtsSelect,
}) => {
  if (manualTarget === 'keyword') {
    return (
      <>
        <SBKeywordSection
          keywords={keywords}
          isSuggestionsLoading={isSuggestionsLoading}
          suggestions={keywordSuggestions}
          onFind={onFind(false)}
          onChange={onKeywordsSelect}
        />
        <NegativeKeywordSection
          adType="sb"
          negativeKeywords={negativeKeywords}
          onChange={onNksSelect}
        />
      </>
    )
  }

  return (
    <>
      <SBTargetingSection
        targetings={targetings}
        isSuggestionsLoading={isSuggestionsLoading}
        categorySuggestions={categorySuggestions}
        productSuggestions={productSuggestions}
        onFind={onFind(true)}
        onChange={onTargetsSelect}
      />
      <NegativeTargetingSection
        negativeTargetings={negativeTargetings}
        onChange={onNtsSelect}
      />
    </>
  )
}

export default SBTargetInputSection
