import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTable from '../CommonComponents/CustomTableComponent'
import VideoLink from '../CommonComponents/VideoLink'

import { PLACEMENT_TOP, PLACEMENT_PRODUCT_PAGE, PLACEMENT_REST_OF_SEARCH } from '../../utils/defaultValues'

const videoList = [
  { title: 'Bids and Placements', url: 'https://www.loom.com/embed/436b965991be4c5a9edfe80b61b402f1' },
]

const UpdatePlacementsModal = ({ campaigns, onUpdate, onClose }) => {
  const [bulkPercent, setBulkPercent] = useState({
    productPage: 0,
    top: 0,
    restSearch: 0,
  })
  const [selectedCampaigns, setSelectedCampaigns ] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const campaignList = campaigns.map((campaign) => {
      const bidding = typeof campaign.bidding === 'string'
        ? JSON.parse(campaign.bidding)
        : campaign.bidding

      let top = 0
      let productPage = 0
      let restSearch = 0
      bidding.adjustments.forEach((adjustment) => {
        if (adjustment.predicate === PLACEMENT_TOP) {
          top = adjustment.percentage
        } else if (adjustment.predicate === PLACEMENT_PRODUCT_PAGE) {
          productPage = adjustment.percentage
        } else {
          restSearch = adjustment.percentage
        }
      })

      return {
        campaign_id: campaign.campaign_id,
        name: campaign.campaign,
        strategy: bidding.strategy,
        top,
        productPage,
        restSearch,
      }
    })

    setSelectedCampaigns(campaignList)
  }, [campaigns]) // eslint-disable-line

  const handleBulkPercentChange = (predicate, value) => {
    if (isNaN(value) || parseFloat(value) < 0) {
      toast.show({
        title: 'Warning',
        description: 'Percentage value must be higher than 0.',
      })
      return
    }

    setBulkPercent(prev => ({
      ...prev,
      [predicate]: parseFloat(value),
    }))
  }

  const handleBulkApply = () => {
    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      campaign.top = bulkPercent.top
      campaign.productPage = bulkPercent.productPage
      campaign.restSearch = bulkPercent.restSearch
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleModifierChange = (campaignId, predicate, value) => {
    if (isNaN(value) || parseFloat(value) < 0) {
      toast.show({
        title: 'Warning',
        description: 'Percentage value must be higher than 0.',
      })
      return
    }

    const newSelectedCampaigns = [...selectedCampaigns]
    newSelectedCampaigns.forEach((campaign) => {
      if (campaign.campaign_id !== campaignId) {
        return
      }

      if (predicate === PLACEMENT_TOP) {
        campaign.top = parseFloat(value)
      } else if (predicate === PLACEMENT_PRODUCT_PAGE) {
        campaign.productPage = parseFloat(value)
      } else {
        campaign.restSearch = parseFloat(value)
      }
    })
    setSelectedCampaigns(newSelectedCampaigns)
  }

  const handleSave = async () => {
    setIsSaving(true)
    await onUpdate(selectedCampaigns.map(campaign => ({
      campaign_id: campaign.campaign_id,
      strategy: campaign.strategy,
      adjustments: [
        {
          predicate: PLACEMENT_TOP,
          percentage: campaign.top,
        },
        {
          predicate: PLACEMENT_PRODUCT_PAGE,
          percentage: campaign.productPage,
        },
        {
          predicate: PLACEMENT_REST_OF_SEARCH,
          percentage: campaign.restSearch,
        },
      ],
    })))
    setIsSaving(false)
    onClose()
  }

  const renderActions = () => (
    <div className="action-container">
      <div className="action-value-wrapper">
        <label>Product pages (%):</label>
        <input
          type="number"
          min={0}
          value={bulkPercent.productPage}
          onChange={(event) => { handleBulkPercentChange('productPage', event.target.value)}}
        />
      </div>
      <div className="action-value-wrapper">
        <label>Top of search (%):</label>
        <input
          type="number"
          min={0}
          value={bulkPercent.top}
          onChange={(event) => { handleBulkPercentChange('top', event.target.value)}}
        />
      </div>
      <div className="action-value-wrapper">
        <label>Rest of the search (%):</label>
        <input
          type="number"
          min={0}
          value={bulkPercent.restSearch}
          onChange={(event) => { handleBulkPercentChange('restSearch', event.target.value)}}
        />
      </div>
      <button
        type="button"
        className="btn btn-green"
        onClick={() => handleBulkApply()}
      >
        Apply All
      </button>
    </div>
  )

  const renderCampaign = (record) => (
    <>
      <div className="table-col" title={record.name}>
        <div className="campaign-name">
          { record.name }
        </div>
      </div>
      <div className="table-col">
        <input
          type="number"
          min={0}
          value={record.productPage}
          onChange={(event) => { handleModifierChange(record.campaign_id, PLACEMENT_PRODUCT_PAGE, event.target.value)}}
        />
      </div>
      <div className="table-col">
        <input
          type="number"
          min={0}
          value={record.top}
          onChange={(event) => { handleModifierChange(record.campaign_id, PLACEMENT_TOP, event.target.value)}}
        />
      </div>
      <div className="table-col">
        <input
          type="number"
          min={0}
          value={record.restSearch}
          onChange={(event) => { handleModifierChange(record.campaign_id, PLACEMENT_REST_OF_SEARCH, event.target.value)}}
        />
      </div>
    </>
  )

  return (
    <Modal className="update-placements-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Placement Optimization (SP Only)
          <VideoLink
            videoList={videoList}
            modalTitle="Bids and Placements"
            linkName=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { renderActions() }
        <CustomTable
          isLoading={isSaving}
          records={selectedCampaigns}
          idField="campaign_id"
          noCheckBox
          searchFields={['name']}
          renderRecord={renderCampaign}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col">Product pages (%)</div>
          <div className="table-col">Top of search (%)</div>
          <div className="table-col">Rest of the search (%)</div>
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isSaving}
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          disabled={isSaving}
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default UpdatePlacementsModal
