import React from 'react'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import AuthSideComponent from '../../components/AuthSideComponent'

const AuthPage = () => {
  return (
    <div className="auth-layout">
      <div className="auth-page loading">
        <LoaderComponent />
      </div>
      <AuthSideComponent />
    </div>
  )
}

export default AuthPage
