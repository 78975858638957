import { callPost } from '../../services/axios.js'

import { COIN_EARNED } from '../actionTypes/coin'

import { COIN_TYPE_PURCHASE_COACHING } from '../../utils/coin'

export const buyCoaching = (accessToken, topics) => (dispatch) => {
  return callPost('/marketplace/buyCoaching', {
    topics,
  }, accessToken).then((response) => {
    if (response.data.amount) {
      dispatch({
        // The name of action type is misleading, because coins
        // are spent here, not earned.
        type: COIN_EARNED,
        history: {
          type: COIN_TYPE_PURCHASE_COACHING,
          amount: response.data.amount,
        },
      })
    }
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message || 'Failed to buy the ad coaching.')
  })
}
