// Campaign selector to choose multiple campaigns
// to apply smart pilot settings.
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'
import { getApCampaignsToApply } from '../../redux/actions/ap'
import { campaignTypeMap } from '../../utils/defaultValues'

const CampaignSelector = ({ isSaving, saveError, onApply, onCancel }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const filterOpts = Object.keys(campaignTypeMap).map(type => ({
    value: type,
    label: campaignTypeMap[type],
  }))
  filterOpts.unshift({ value: '', label: 'All' })

  const [isLoading, setIsLoading] = useState(false)
  const [campaignsToApply, setCampaignsToApply] = useState([])
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([])
  const [filter, setFilter] = useState(filterOpts[0])

  // Load campaigns of all ad types.
  useEffect(() => {
    let abortCtrl
    if (!campaignsToApply.length && !isLoading) {
      abortCtrl = new AbortController();
      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const data = await dispatch(getApCampaignsToApply(
            accessToken,
            abortCtrl.signal,
          ))
          setCampaignsToApply(data)
          setIsLoading(false)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      })()
    }
    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, []) // eslint-disable-line

  const handleFilterChange = (option) => {
    setFilter(option)
    // Whenever filter is changed, un-select all campaigns.
    setSelectedCampaignIds([])
  }

  const handleApply = () => {
    onApply(selectedCampaignIds)
  }

  const renderFilter = () => (
    <Select
      className="ad-type-selector"
      options={filterOpts}
      value={filter}
      onChange={handleFilterChange}
    />
  )

  const renderCampaign = campaign => (
    <>
      <div className="table-col">
        { campaign.name }
      </div>
      <div className="table-col col-type">
        { campaignTypeMap[campaign.type] }
      </div>
    </>
  )

  const filteredCampaigns = useMemo(() => {
    if (!filter.value) {
      return campaignsToApply
    }
    return campaignsToApply.filter(campaign => (
      campaign.type === filter.value
    ))
  }, [filter, campaignsToApply])

  return (
    <>
      <div className="pane-body">
        <CustomTable
          isLoading={isLoading}
          className="table-campaigns-apply"
          records={filteredCampaigns}
          selectedRecords={selectedCampaignIds}
          idField="id"
          searchFields={['name']}
          renderRecord={renderCampaign}
          renderTopRight={renderFilter}
          onChange={setSelectedCampaignIds}
        >
          <div className="table-col">Campaign</div>
          <div className="table-col col-type">Type</div>
        </CustomTable>
      </div>
      <div className="pane-footer">
        {
          saveError !== null && (
            <div className="save-error">
              { saveError }
            </div>
          )
        }
        <button
          type="button"
          className="btn btn-blue"
          disabled={(selectedCampaignIds.length === 0) || isSaving}
          onClick={handleApply}
        >
          Apply
        </button>
        <button
          type="button"
          className="btn btn-white"
          disabled={isSaving}
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default CampaignSelector
