/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from 'react'
import { BsCaretDownFill } from 'react-icons/bs'

import BidRuleSection from '../RuleManagerComponents/BidRuleSection'
import CampaignRuleSection from '../RuleManagerComponents/CampaignRuleSection'
import ModifierRuleSection from '../RuleManagerComponents/ModifierRuleSection'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
} from '../../utils/defaultValues'

const RuleArea = ({ dowName, dowNumber, hasSpCampaign, ruleToShow,
  onChange, onAggregateRuleClear }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isSet, setIsSet] = useState(false)
  const [selectedBidAction, setSelectedBidAction] = useState(null)
  const [selectedBidValue, setSelectedBidValue] = useState(null)
  const [selectedCampaignAction, setSelectedCampaignAction] = useState(null)
  const [selectedCampaignValue, setSelectedCampaignValue] = useState(null)
  const [selectedCampaignLimit, setSelectedCampaignLimit] = useState(null)
  const [selectedTosModAction, setSelectedTosModAction] = useState(null)
  const [selectedTosModValue, setSelectedTosModValue] = useState(null)
  const [selectedProductModAction, setSelectedProductModAction] = useState(null)
  const [selectedProductModValue, setSelectedProductModValue] = useState(null)
  const [selectedRestModAction, setSelectedRestModAction] = useState(null)
  const [selectedRestModValue, setSelectedRestModValue] = useState(null)

  useEffect(() => {
    if (!ruleToShow || (isSet && !ruleToShow.template)) {
      return
    }

    setIsSet(true)
    ruleToShow.r.forEach((rule) => {
      if (rule.t === RULE_TYPE_BID) {
        setSelectedBidAction(ruleBidActionList.find(option =>
          option.value === rule.a
        ))

        if (rule.v) {
          setSelectedBidValue(rule.v)
        }
      } else if (rule.t === RULE_TYPE_CAMPAIGN) {
        setSelectedCampaignAction(ruleCampaignActionList.find(option =>
          option.value === rule.a
        ))

        if (rule.v) {
          setSelectedCampaignValue(rule.v)
        }
        if (rule.l) {
          setSelectedCampaignLimit(rule.l)
        }
      } else if (rule.t === RULE_TYPE_TOS_MODIFIER) {
        setSelectedTosModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedTosModValue(rule.v)
      } else if (rule.t === RULE_TYPE_PRODUCT_MODIFIER) {
        setSelectedProductModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedProductModValue(rule.v)
      } else if (rule.t === RULE_TYPE_REST_MODIFIER) {
        setSelectedRestModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedRestModValue(rule.v)
      }
    })
  }, [ruleToShow]) // eslint-disable-line

  const rulesToApply = useMemo(() => {
    const rules = []
    if (selectedBidAction) {
      const rule = {
        t: RULE_TYPE_BID,
        a: selectedBidAction.value,
      }
      if (rule.a === RULE_BID_ACTION_PAUSE) {
        rules.push(rule)
      } else if (selectedBidValue) {
        rule.v = parseFloat(selectedBidValue)
        rules.push(rule)
      }
    }

    if (selectedCampaignAction) {
      const rule = {
        t: RULE_TYPE_CAMPAIGN,
        a: selectedCampaignAction.value,
      }
      if (rule.a === RULE_CAMPAIGN_ACTION_PAUSE) {
        rules.push(rule)
      } else if (selectedCampaignValue) {
        rule.v = parseFloat(selectedCampaignValue)
        if (selectedCampaignLimit) {
          rule.l = parseFloat(selectedCampaignLimit)
        }
        rules.push(rule)
      }
    }

    if (selectedTosModAction && selectedTosModValue) {
      rules.push({
        t: RULE_TYPE_TOS_MODIFIER,
        a: selectedTosModAction.value,
        v: parseFloat(selectedTosModValue),
      })
    }

    if (selectedProductModAction && selectedProductModValue) {
      rules.push({
        t: RULE_TYPE_PRODUCT_MODIFIER,
        a: selectedProductModAction.value,
        v: parseFloat(selectedProductModValue),
      })
    }

    if (selectedRestModAction && selectedRestModValue) {
      rules.push({
        t: RULE_TYPE_REST_MODIFIER,
        a: selectedRestModAction.value,
        v: parseFloat(selectedRestModValue),
      })
    }

    return rules
  }, [selectedBidAction, selectedBidValue,
    selectedCampaignAction, selectedCampaignValue, selectedCampaignLimit,
    selectedTosModAction, selectedTosModValue,
    selectedProductModAction, selectedProductModValue,
    selectedRestModAction, selectedRestModValue])

  const isReadOnly = useMemo(() => {
    // When multi-campaigns are selected, each individual
    // campaign's original rules are read-only.
    return !!(ruleToShow
      && (
        ruleToShow.campaign
        || (ruleToShow.template && ruleToShow.template.campaign)
      ))
  }, [ruleToShow])

  useEffect(() => {
    if (isReadOnly) {
      return
    }

    if (ruleToShow && ruleToShow.template) {
      const templateRules = ruleToShow.template.slots.find(slot => (
        slot.d === dowNumber
      ))
      if (JSON.stringify(templateRules.r) === JSON.stringify(rulesToApply)) {
        return
      }
    }
    onChange(dowNumber, rulesToApply)
  }, [rulesToApply, isReadOnly]) // eslint-disable-line

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const handleBidClear = () => {
    setSelectedBidAction(null)
    setSelectedBidValue(null)
  }

  const handleCampaignClear = () => {
    setSelectedCampaignAction(null)
    setSelectedCampaignValue(null)
    setSelectedCampaignLimit(null)
  }

  const handleModifierClear = ruleType => () => {
    if (ruleType === RULE_TYPE_TOS_MODIFIER) {
      setSelectedTosModAction(null)
      setSelectedTosModValue(null)
    } else if (ruleType === RULE_TYPE_PRODUCT_MODIFIER) {
      setSelectedProductModAction(null)
      setSelectedProductModValue(null)
    } else if (ruleType === RULE_TYPE_REST_MODIFIER) {
      setSelectedRestModAction(null)
      setSelectedRestModValue(null)
    }
  }

  const handleClearAll = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (isReadOnly) {
      onAggregateRuleClear(dowNumber)
    }

    handleBidClear()
    handleCampaignClear()
    handleModifierClear(RULE_TYPE_TOS_MODIFIER)()
    handleModifierClear(RULE_TYPE_PRODUCT_MODIFIER)()
    handleModifierClear(RULE_TYPE_REST_MODIFIER)()
  }

  const renderHeader = () => {
    let ruleIndicator = null
    if (rulesToApply.length) {
      let templateInfo = ''
      if (ruleToShow && ruleToShow.template) {
        templateInfo = ` from the template "${ruleToShow.template.name}"`
      }

      ruleIndicator = (
        <span className="rule-indicator">
          { rulesToApply.length } active rule{ rulesToApply.length > 1 ? 's' : ''}
          { templateInfo }
        </span>
      )
    }

    return (
      <div
        className={`dow-section-header${isExpanded ? ' expanded' : ''}`}
        onClick={handleToggle}
      >
        <span className="dow-name">
          <BsCaretDownFill size={12} color='#979797' />
          { dowName }
          { ruleIndicator }
        </span>
        <a
          href="#"
          onClick={handleClearAll}
        >
          Clear All
        </a>
      </div>
    )
  }

  const renderBidRule = () => {
    return (
      <BidRuleSection
        isReadOnly={isReadOnly}
        action={selectedBidAction}
        value={selectedBidValue}
        onActionChange={setSelectedBidAction}
        onValueChange={setSelectedBidValue}
        onClear={handleBidClear}
      />
    )
  }

  const renderCampaignRule = () => {
    return (
      <CampaignRuleSection
        isReadOnly={isReadOnly}
        action={selectedCampaignAction}
        value={selectedCampaignValue}
        limit={selectedCampaignLimit}
        onActionChange={setSelectedCampaignAction}
        onValueChange={setSelectedCampaignValue}
        onLimitChange={setSelectedCampaignLimit}
        onClear={handleCampaignClear}
      />
    )
  }

  const renderModifierRule = () => {
    return (
      <>
        <ModifierRuleSection
          name="Top of Search modifier"
          isReadOnly={isReadOnly}
          action={selectedTosModAction}
          value={selectedTosModValue}
          onActionChange={setSelectedTosModAction}
          onValueChange={setSelectedTosModValue}
          onClear={handleModifierClear(RULE_TYPE_TOS_MODIFIER)}
        />
        <ModifierRuleSection
          name="Product Pages modifier"
          isReadOnly={isReadOnly}
          action={selectedProductModAction}
          value={selectedProductModValue}
          onActionChange={setSelectedProductModAction}
          onValueChange={setSelectedProductModValue}
          onClear={handleModifierClear(RULE_TYPE_PRODUCT_MODIFIER)}
        />
        <ModifierRuleSection
          name="Rest of the Search modifier"
          isReadOnly={isReadOnly}
          action={selectedRestModAction}
          value={selectedRestModValue}
          onActionChange={setSelectedRestModAction}
          onValueChange={setSelectedRestModValue}
          onClear={handleModifierClear(RULE_TYPE_REST_MODIFIER)}
        />
      </>
    )
  }

  const renderBody = () => {
    if (!isExpanded) {
      return null
    }

    let campaignInfo = null
    if (ruleToShow) {
      if (ruleToShow.campaign) {
        campaignInfo = ruleToShow.campaign
      } else if (ruleToShow.template && ruleToShow.template.campaign) {
        campaignInfo = ruleToShow.template.campaign
      }
    }

    return (
      <div className="dow-section-body">
        {
          ruleToShow !== null
          && typeof ruleToShow !== 'undefined'
          && typeof ruleToShow.template !== 'undefined'
          && !isReadOnly
          && (
            <div className="section-note">
              These rules are from the template: <strong>{ ruleToShow.template.name }</strong>.
              You can overwrite them by changing and applying rules to selected times here.<br />
              You can also update the template by clicking on the template above.
            </div>
          )
        }
        {
          campaignInfo !== null && (
            <div className="section-note">
              Campaign: <strong>{ campaignInfo.name }</strong>
            </div>
          )
        }
        { renderBidRule() }
        { renderCampaignRule() }
        { hasSpCampaign && renderModifierRule() }
      </div>
    )
  }

  return (
    <div className="dow-section">
      { renderHeader() }
      { renderBody() }
    </div>
  )
}

export default RuleArea
