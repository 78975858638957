/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Select from 'react-select'

import CustomTooltip from '../CommonComponents/CustomTooltip'

import FieldRow from './FieldRow'

const FREQUENCY_DAILY = 'daily'
const FREQUENCY_WEEKLY = 'weekly'
// FIXME: Make this as monthly.
const FREQUENCY_MONTHLY = 'month'

const Frequency = (props) => {
  const {
    disabled,
    prefix,
    settings,
    onChange,
  } = props

  const frequencyList = [
    { value: FREQUENCY_DAILY, label: 'Daily' },
    { value: FREQUENCY_WEEKLY, label: 'Weekly' },
    { value: FREQUENCY_MONTHLY, label: 'Monthly' },
  ]

  const dowList =  [
    { value: 0, label: 'Sun' },
    { value: 1, label: 'Mon' },
    { value: 2, label: 'Tue' },
    { value: 3, label: 'Wed' },
    { value: 4, label: 'Thu' },
    { value: 5, label: 'Fri' },
    { value: 6, label: 'Sat' },
  ]

  const monthList = []
  for (let day = 1; day <= 31; day += 1) {
    monthList.push({
      value: day,
      label: day,
    })
  }

  const weeklyValue = dowList.filter(dow => (
    (settings[`${prefix}freq_weekly`] || []).indexOf(dow.value) !== -1
  ))

  const monthlyValue = monthList.find(option => (
    option.value === parseInt(settings[`${prefix}freq_monthly`], 10)
  ))

  const handleWeeklyChange = (options) => {
    onChange(`${prefix}freq_weekly`, options.map(option => option.value))
  }

  const handleMonthlyChange = (option) => {
    onChange(`${prefix}freq_monthly`, option.value)
  }

  const renderValue = () => {
    if (settings[`${prefix}freq_type`] === FREQUENCY_WEEKLY) {
      return (
        <div key="weekly-value" className="field-wrapper no-label-wrapper">
          <Select
            className="select-wrapper"
            options={dowList}
            placeholder="Choose days of week."
            isMulti
            value={weeklyValue}
            onChange={handleWeeklyChange}
          />
        </div>
      )
    }

    if (settings[`${prefix}freq_type`] === FREQUENCY_MONTHLY) {
      return (
        <div key="monthly-value" className="field-wrapper no-label-wrapper">
          <Select
            className="select-wrapper"
            options={monthList}
            placeholder="Choose day of month."
            value={monthlyValue}
            onChange={handleMonthlyChange}
          />
          <CustomTooltip>
            <p>Select which day of the month you'd like optimizations to occur.</p>
            <p>For example, if you choose '14,' your optimizations
            will take place on the 14th day of each month.</p>
          </CustomTooltip>
        </div>
      )
    }

    return (
      <div className="field-wrapper">
      </div>
    )
  }

  return (
    <FieldRow disabled={disabled}>
      <div className="field-wrapper">
        <div className="field-name">
          Frequency setting
        </div>
        <div className="frequency-list">
          {
            frequencyList.map(option => (
              <a
                href="#"
                key={option.value}
                className={`frequency-value ${option.value === settings[`${prefix}freq_type`] ? 'active' : ''}`}
                onClick={(event) => { event.preventDefault(); onChange(`${prefix}freq_type`, option.value) }}
              >
                { option.label }
              </a>
            ))
          }
        </div>
      </div>
      { renderValue() }
    </FieldRow>
  )
}

export default Frequency
