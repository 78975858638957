import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { IconButton, Icon } from 'rsuite'

import CustomTooltip from '../../components/CommonComponents/CustomTooltip'

import {
  changeViewWidgetState,
  changeHighlightWidgetState
} from '../../redux/actions/pageGlobal'

const ReportChartWidget = ({
  widget,
  data,
  metrics,
  customizing = false,
  section = '',
  suffix = '',
  highlighting = true,
  onRemove,
}) => {
  const dispatch= useDispatch()

  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [highlighted, setHighlighted] = useState(widget.highlighted)
  useEffect(() => {
    if (section !== '') {
      if (reportWidgetSetting[section]) {
        const sectionWidget = reportWidgetSetting[section].find((w) => w.key === widget.key)
        setHighlighted(sectionWidget.highlighted)
      } else {
        setHighlighted(true)
      }
    }
  }, [section, reportWidgetSetting, widget])

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  const handleClickHighlightWidget = () => {
    dispatch(changeHighlightWidgetState(section, widget.key))
  }

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          {highlighting && (
            <IconButton
              icon={<Icon icon="star" />}
              size="xs"
              className={`highlight-report-widget ${highlighted ? 'active' : ''}`}
              circle
              onClick={handleClickHighlightWidget}
            />
          )}
          <span>
            {`${widget.title} ${suffix}`}
          </span>
          {
            widget.tooltip && (
              <CustomTooltip placement="top">
                <p>{ widget.tooltip }</p>
              </CustomTooltip>
            )
          }
        </div>
        <div className="widget-action">
          {customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={widget.view ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )}
        </div>
      </div>
      <div className="report-chart-widget-content">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            {(metrics || []).map(m => (
              <Line
                type="monotone"
                connectNulls
                key={m.key}
                name={m.name}
                dataKey={m.key}
                stroke={m.stroke}
                activeDot={{ r: 3 }}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ReportChartWidget
