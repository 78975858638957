import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'

import { ReactComponent as MoreDotsSvg } from '../../assets/svg/more-dots.svg'
// import ImgBlueArrowRight from '../../assets/img/blue-right.png'
// import ImgBlueArrowDown from '../../assets/img/blue-down.png'

import {
  formatCurrency,
  formatValue,
  getAmazonAsinLink,
} from '../../services/helper'

const TableRow = ({ product, selectedColumns, currentAccount, selectedProducts,
  currencySign, currencyRate, onSelect }) => {
  // const [cog, setCog] = useState(parseFloat(product.cog || 0))

  const isSelected = useMemo(() => {
    return typeof selectedProducts.find(selected => (
      selected.id === product.id
    )) !== 'undefined'
  }, [selectedProducts, product])

  // const handleCogSave = () => {
  //   if (isNaN(cog)) {
  //     toast.show({
  //       title: 'Warning',
  //       description: 'Please enter a numeric value for COG.',
  //     })
  //     return
  //   }

  //   onSaveCog(product.id, parseFloat(cog))
  // }

  const renderName = () => (
    <div key="name" className="table-col product-name">
      <img src={product.image} alt={product.name} />
      <div className="product-info">
        {
          product.abtest_status === 'running' ? (
            <span className="test-on">A/B Test On</span>
          ) : (
            <span className="test-off">A/B Test Off</span>
          )
        }
        <Link
          to={`/product/${product.id}`}
          target="_blank"
          rel="noopener noreferrer"
          title={product.name}
        >
          { product.name }
        </Link>
        <div className="product-meta" title={product.asin}>
          { product.asin }
        </div>
        <div className="product-meta" title={product.sku}>
          { product.sku }
        </div>
        {/* {
          expandedId && expandedId.toString() === product.id.toString() ? (
            <img
              src={ImgBlueArrowDown}
              alt={product.name}
              onClick={()=> { onExpand() }}
            />
          ) : (
            <img
              src={ImgBlueArrowRight}
              alt={product.name}
              onClick={()=> { onExpand(product.id) }}
            />
          )
        } */}
      </div>
    </div>
  )

  // const renderCog = () => {
  //   if (!selectedColumns.find(c => c.key === 'cog')) {
  //     return null
  //   }

  //   const isChanged = parseFloat(product.cog) !== parseFloat(cog)

  //   return (
  //     <div className="table-col col-cog" key="cog">
  //       <>
  //         <input
  //           type="number"
  //           value={cog}
  //           min={0}
  //           onChange={(event) => { setCog(event.target.value) }}
  //         />
  //         {
  //           isChanged && (
  //             <div className="cog-btn-container">
  //               <button
  //                 type="button"
  //                 className="btn btn-blue"
  //                 onClick={handleCogSave}
  //               >
  //                 Save
  //               </button>
  //               <button
  //                 type="button"
  //                 className="btn btn-white"
  //                 onClick={() => { setCog(parseFloat(product.cog || 0)) }}
  //               >
  //                 Cancel
  //               </button>
  //             </div>
  //           )
  //         }
  //       </>
  //     </div>
  //   )
  // }

  // const renderDetails = () => {
  //   if (expandedId.toString() !== product.id.toString()) {
  //     return null
  //   }

  //   return (
  //     <div className="product-detail">
  //       <div className="keyword-row keyword-header">
  //         <div className="keyword-col">Keyword</div>
  //         <div className="keyword-col">Spend</div>
  //         <div className="keyword-col">Impressions</div>
  //         <div className="keyword-col">Number Of Orders</div>
  //         <div className="keyword-col">Max CPC</div>
  //       </div>
  //       {
  //         productKeywords.length ? productKeywords.map((keyword)=> (
  //           <div key={keyword.keyword} className="keyword-row">
  //             <div className="keyword-col">{keyword.keyword}</div>
  //             <div className="keyword-col">{formatCurrency(keyword.cost, currencySign, currencyRate)}</div>
  //             <div className="keyword-col">{formatValue(keyword.impressions, 'number', 0)}</div>
  //             <div className="keyword-col">{formatValue(keyword.orders, 'number', 0)}</div>
  //             <div className="keyword-col">{formatValue(keyword.max_cpc, 'number')}</div>
  //           </div>
  //         )) : (
  //           <div className="keyword-no-data">
  //             { isLoadingProductKeywords && <LoaderComponent /> }
  //             No Data
  //           </div>
  //         )
  //       }
  //     </div>
  //   )
  // }

  const metricList = [
    // {
    //   column: 'profit_margin',
    //   value: formatCurrency(product.profit_margin, currencySign, currencyRate),
    // },
    // {
    //   column: 'break_even_cpa',
    //   className: 'break-even',
    //   value: formatCurrency(product.break_even_cpa, currencySign, currencyRate),
    // },
    {
      column: 'clicks_order_ratio',
      value: formatValue(product.clicks_order_ratio, 'number', 2),
    },
    // {
    //   column: 'max_cpc',
    //   value: formatCurrency(product.max_cpc, currencySign, currencyRate),
    // },
    {
      column: 'active_campaigns',
      className: 'active-campaigns',
      value: (
        <>
          <span>Total: {formatValue(product.active_campaigns, 'number', 0)}</span>
          <span>
            SP: {formatValue(product.active_sp_campaigns, 'number', 0)}&nbsp;
            | SD: {formatValue(product.active_sd_campaigns, 'number', 0)}
          </span>
        </>
      )
    },
    // {
    //   column: 'true_acos',
    //   value: formatValue(product.cost ? product.cost / product.sales * 100 : 0, 'percent'),
    // },
    {
      column: 'revenue',
      value: formatCurrency(product.revenue, currencySign, currencyRate),
    },
    // {
    //   column: 'sales',
    //   className: 'organic-sales',
    //   value: (
    //     <>
    //       <span>
    //         { formatCurrency(product.sales - product.revenue, currencySign, currencyRate) }
    //       </span>
    //       <span>
    //         { formatCurrency(product.revenue, currencySign, currencyRate) }
    //       </span>
    //     </>
    //   ),
    // },
    // {
    //   column: 'sales_ratio',
    //   className: 'organic-sales-ratio',
    //   value: (
    //     <>
    //       <span>
    //         { formatValue(product.sales_ratio, 'percent') }
    //       </span>
    //       <span>
    //         { formatValue(product.sales ? product.revenue / product.sales * 100 : 0, 'percent') }
    //       </span>
    //     </>
    //   ),
    // },
    {
      column: 'ad_spend_margin',
      className: 'ad-spend-margin',
      value: formatValue(product.ad_spend_margin, 'percent'),
    },
    {
      column: 'clicks',
      value: formatValue(product.clicks, 'number', 0),
    },
    {
      column: 'ctr',
      value: formatValue(product.ctr, 'percent'),
    },
    {
      column: 'cost',
      value: formatCurrency(product.cost, currencySign, currencyRate),
    },
    {
      column: 'orders',
      value: formatValue(product.orders, 'number', 0),
    },
    {
      column: 'acos',
      value: formatValue(product.acos, 'percent', 1),
    },
  ]

  return (
    <>
      <div className="table-row">
        <div className="table-col">
          <CheckboxComponent
            checked={isSelected}
            onChange={(checked) => { onSelect(checked, product) } }
          />
        </div>
        {
          selectedColumns.map(column => {
            if (column.key === 'product') {
              return renderName()
            }
            // if (column.key === 'cog') {
            //   return renderCog()
            // }
            const metric = metricList.find(m => m.column === column.key)
            if (!metric) {
              return null
            }
            return (
              <div key={metric.column} className={`table-col ${metric.className || ''}`}>
                { metric.value }
              </div>
            )
          })
        }
        <div className="table-col">
          <Dropdown
            title={(<MoreDotsSvg />)}
            noCaret
            placement="leftEnd"
          >
            <Dropdown.Item
              componentClass={Link}
              to={`/product/${product.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Dashboard
            </Dropdown.Item>
            <Dropdown.Item
              href={getAmazonAsinLink(currentAccount, product.asin)}
              target="_blank">
              Open in Amazon
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      {/* { renderDetails() } */}
    </>
  )
}

export default TableRow
