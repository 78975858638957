import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import { getSbStores } from '../../redux/actions/campaignCreator'

const SBStoreSelection = ({ isSpotlight, onChange, onStoreChange }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [stores, setStores] = useState([])
  const [selectedStorePages, setSelectedStorePages] = useState([])
  const [selectedPageUrl, setSelecedPageUrl] = useState('')

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSbStores(accessToken))
      setStores(response)
      setIsLoading(false)
    })()
  }, []) // eslint-disable-line

  const handleStoreSelect = async (option) => {
    setSelectedStorePages(option.storePageInfo)
    if (isSpotlight) {
      onStoreChange(option.storePageInfo)
    }
  }

  const handlePageSelect = (option) => {
    onChange(option.storePageUrl)
    setSelecedPageUrl(option.storePageUrl)
  }

  return (
    <>
      <div className="field-row">
        <div className="field-wrapper">
          <div className="field-name">
            Choose a Store
          </div>
          <Select
            isLoading={isLoading}
            options={stores}
            getOptionValue={option => option.storeName}
            getOptionLabel={option => option.storeName}
            onChange={handleStoreSelect}
          />
        </div>
        <div className="field-wrapper">
          {
            !isSpotlight && (
              <>
                <div className="field-name">
                  Choose a page
                </div>
                <Select
                  options={selectedStorePages}
                  getOptionValue={option => option.storePageId}
                  getOptionLabel={option => option.storePageName}
                  onChange={handlePageSelect}
                />
                {
                  selectedPageUrl !== '' && (
                    <a
                      href={selectedPageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See page
                    </a>
                  )
                }
              </>
            )
          }
        </div>
      </div>
      {
        stores.length === 0 && !isLoading && (
          <div className="warning-message">
            No Stores in your account. Create a store for your brand on Amazon for free.
          </div>
        )
      }
    </>
  )
}

export default SBStoreSelection
