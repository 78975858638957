import React, { useMemo } from 'react'

import {
  formatCurrency,
  formatValue,
} from '../../services/helper'

const TableFooter = ({ columns, tableColumns, products, isSticky, refFooter, currencyRate, currencySign }) => {
  const total = useMemo(() => {
    const summary = {
      clicks_order_ratio: 0,
      max_cpc: 0,
      revenue: 0,
      clicks: 0,
      ctr: 0,
      cost: 0,
      orders: 0,
      acos: 0,
      count_with_clicks: 0,
    }

    products.forEach((record) => {
      if (record.clicks) {
        summary.count_with_clicks += 1
      }

      summary.clicks_order_ratio += record.clicks_order_ratio
      summary.max_cpc += record.max_cpc
      summary.revenue += record.revenue
      summary.clicks += parseInt(record.clicks || 0, 10)
      summary.ctr += record.ctr
      summary.cost += record.cost
      summary.orders += parseInt(record.orders || 0, 10)
      summary.acos += record.acos
    })

    if (summary.count_with_clicks) {
      summary.clicks_order_ratio /= summary.count_with_clicks
      summary.max_cpc /= summary.count_with_clicks
      summary.ctr /= summary.count_with_clicks
      summary.acos /= summary.count_with_clicks
    }

    return summary
  }, [products])

  const getValue = (key) => {
    if (['clicks_order_ratio', 'max_cpc', 'ctr', 'acos'].includes(key)) {
      return formatValue(total[key], 'number')
    }
    if (['clicks', 'orders'].includes(key)) {
      return formatValue(total[key], 'number', 0)
    }
    if (['revenue', 'cost'].includes(key)) {
      return formatCurrency(total[key], currencySign, currencyRate)
    }
    return null
  }

  return (
    <div className={`table-row content-footer${isSticky ? ' sticky' : ''}`} ref={refFooter}>
      <div className="table-col"></div>
      <div className="table-col product-name">Totals:</div>
      {
        tableColumns.map(column => {
          const metric = columns.find(c => c.key === column.key)
          return (
            metric && (
              <div
                key={metric.key}
                className={`table-col ${metric.className || ''}`}
              >
                { getValue(metric.key) }
              </div>
            )
          )}
        )
      }
      <div className="table-col"></div>
    </div>
  )
}

export default TableFooter
