import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import PaginationComponent from '../CommonComponents/PaginationComponent'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import CampaignColumnEditor from '../CommonComponents/ColumnEditor/CampaignColumnEditor'
import TableFilter from '../CommonComponents/TableFilter'
import CampaignNotePane from '../CommonComponents/CampaignNotePane'

import ThumbHistory from './ThumbHistory'
import ActionBar from './ActionBar'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableFooter from './TableFooter'

import {
  updateTargetAcos,
  getCampaignsWithHistory,
  getCampaignsDetail,
  getCampaignsHistory,
  updateBudget,
  getFilteredCampaigns,
  selectCampaigns,
} from '../../redux/actions/campaign'

import { getAllTags } from '../../redux/actions/tag'
import {
  selectColumns,
  showCampaignNote,
  hideCampaignNote,
} from '../../redux/actions/pageGlobal'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

import filterDef, { CAMPAIGN_ACTIVE_STATUS_OPTION } from '../../utils/filterDef'
import { getISODate, matchFilter, tableSorter } from '../../services/helper'
import { campaignColumnSetting, CAMPAIGN_TABLE_FILTER } from '../../utils/defaultValues'

const BULK_ENGINE_ROWS_PER_PAGE = 'BULK_ENGINE_ROWS_PER_PAGE'

const columnsToExport = [
  'campaign', 'campaignType', 'targeting_Type', 'targeting_acos', 'state',
  'daily_budget', 'cost_type', 'budgetrule_status', 'revenue',
  'cost', 'impressions', 'clicks', 'ntb_sales', 'ntb_orders', 'start_date',
  'is_ap_active', 'ctr', 'acos', 'conversion', 'cpc', 'roas', 'ntb_sales_percent',
  'ntb_orders_percent', 'view_5_seconds_rate', 'vtr', 'vctr', 'vcpm',
  'brand_entity_id', 'is_multi_adgroups_enabled', 'goal', 'viewable_impressions',
  'video_views_25', 'video_views_50', 'video_views_75', 'video_views_100',
  'video_5s_views', 'video_adgroup_ids', 'headline'
];

const CampaignTableComponent = ({
  className = '',
  hideSb,
  hideSd,
  fromBulkEngine = false,
  customActionBar,
  showNotedCampaigns = false,
  initialRender = true,
  onChangeViewMethod,
  onLoadStart = null,
  onLoadEnd = null,
}) => {
  const dispatch = useDispatch()
  const acosRef = useRef()
  const dailyBudgetRef = useRef()
  const refBody = useRef(null)
  const refHeader = useRef(null)
  const refTotal = useRef(null)
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleFilterName = useSelector(state => state.pageGlobal.visibleFilterName)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const visibleColumnEditorId = useSelector(state => state.pageGlobal.visibleColumnEditorId)
  const showCampaignNotePane = useSelector(state => state.pageGlobal.showCampaignNotePane)

  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const campaignIdsSelected = useSelector(state => state.campaign.campaignIdsSelected)
  const adTypeSelected = useSelector(state => state.campaign.adTypeSelected)

  const isTurningBulk = useSelector(state => state.ap.isTurningBulk)
  const isTagsLoading = useSelector(state => state.tag.isLoading)

  const [acosCampaignId, setAcosCampaignId] = useState('')
  const [dailyBudgetCampaignId, setDailyBudgetCampaignId] = useState('')
  const [selectedPortfolioIds, setSelectedPortfolioIds] = useState([])
  const [portfolioSelectionInclusive, setPortfolioSelectionInclusive] = useState(true)
  const [selectedTagIds, setSelectedTagIds] = useState([])
  const [tagSelectionInclusive, setTagSelectionInclusive] = useState(true)
  const [expandedCampaign, setExpandedCampaign] = useState([])
  const [shownHistoryCampaigns, setShownHistoryCampaigns] = useState([])

  const [pageStartNum, setPageStartNum] = useState(0)
  const [pageEndNum, setPageEndNum] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [searchInclusive, setSearchInclusive] = useState(true)
  const [sortColumnName, setSortColumnName] = useState('cost')
  const [sortDirection, setSortDirection] = useState('desc')

  const [historyPayload, setHistoryPayload] = useState({})

  const [stickyOffset, setStickyOffset] = useState(null)

  const [tableHeight, setTableHeight] = useState('auto')
  const [resizing, setResizing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTagsLoaded, setIsTagsLoaded] = useState(false)
  const [filteredCampaignIds, setFilteredCampaignIds] = useState([])
  const [isLoadingFilteredCampaigns, setIsLoadingFilteredCampaigns] = useState(false)
  const [noteCampaign, setNoteCampaign] = useState(null)
  const [isDetailLoading, setIsDetailsLoading] = useState(false)
  const [isHistoryLoading, setIsHistoryLoading] = useState(false)
  const [isUpdatingAcos, setIsUpdatingAcos] = useState(false)
  const [isUpdatingBudget, setIsUpdatingBudget] = useState(false)
  const [localAdTypeFilter, setLocalAdTypeFilter] = useState([CAMPAIGN_ACTIVE_STATUS_OPTION])

  const campaignColIndex = 0
  const campaignColRef = useRef()
  const campaignColMinWidth = 205

  useEffect(() => {
    setTableHeight(refBody.current.offsetHeight)
  }, []) // eslint-disable-line

  // Listener for mouse move event. Used to resize the campaign name column.
  const handleMouseMove = useCallback(
    (e) => {
      const leftPos = campaignColRef.current.getBoundingClientRect().left
      const width = e.clientX - leftPos >= campaignColMinWidth
        ? e.clientX - leftPos
        : campaignColRef.current.offsetWidth
      campaignColRef.current.style.minWidth = `${width}px`
      let rowElement = campaignColRef.current.parentNode
      while (rowElement) {
        rowElement.childNodes[campaignColIndex + 1].style.minWidth = `${width}px`
        rowElement = rowElement.nextSibling
      }
    },
    []
  )

  // Listener for mouse up event. Used to resize the campaign name column.
  const handleMouseUp = useCallback(() => {
    setResizing(false)
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }, [setResizing]) // eslint-disable-line

  const handleStartResizing = () => {
    setResizing(true)

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const refreshData = (abortCtrl = undefined) => {

    (async () => {
      setSelectedPortfolioIds([])
      if (onLoadStart) {
        onLoadStart()
      } else {
        setIsLoading(true)
      }

      const accessToken = await getAccessTokenSilently()
      ;
      await dispatch(getCampaignsWithHistory(
          accessToken,
          getISODate(currentStartDate),
          getISODate(currentEndDate),
          abortCtrl ? abortCtrl.signal : new AbortController().signal
      ))
      if (onLoadStart) {
        onLoadEnd()
      } else {
        setIsLoading(false)
      }
    })()

  }

  useEffect(() => {
    let abortCtrl
    // In case of secondary render, such as in case of
    // switching tabs on the bulk engine page, no need to
    // re-load campaigns.
    if (currentUserId
      && (initialRender || !campaignsWithHistory || !campaignsWithHistory.length)) {
      abortCtrl = new AbortController();


      refreshData(abortCtrl)
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentUserId, currentStartDate, currentEndDate, initialRender]) // eslint-disable-line

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }

    function handleClick(e) {
      if (acosRef && acosRef.current && !acosRef.current.contains(e.target)) {
        setAcosCampaignId('')
      }
      if (dailyBudgetRef && dailyBudgetRef.current && !dailyBudgetRef.current.contains(e.target)) {
        setDailyBudgetCampaignId('')
      }
    }
  }, [])

  useEffect(() => {
    const mainContent = document.querySelector('.main-content')
    mainContent.addEventListener('scroll', handleScroll)

    return () => {
      mainContent.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (stickyOffset === null) {
      return
    }
    refHeader.current.style.top = `${stickyOffset}px`
    refTotal.current.style.top = `${stickyOffset + refHeader.current.clientHeight}px`
  }, [stickyOffset])

  useEffect(() => {
    let attributes = []
    let negativeAttributes = []
    let skus = []
    if ((filterValues[CAMPAIGN_TABLE_FILTER]?.attributes || []).length) {
      attributes = filterValues[CAMPAIGN_TABLE_FILTER].attributes.map(a => ({ key: a.value }))
    }
    if ((filterValues[CAMPAIGN_TABLE_FILTER]?.negative_attributes || []).length) {
      negativeAttributes = filterValues[CAMPAIGN_TABLE_FILTER].negative_attributes.map(a =>
        ({ key: a.value, isNegative: true })
      )
    }
    if ((filterValues[CAMPAIGN_TABLE_FILTER]?.skus || []).length) {
      skus = filterValues[CAMPAIGN_TABLE_FILTER].skus.map(s => s.sku)
    }

    if (!attributes.length && !negativeAttributes.length && !skus.length) {
      setFilteredCampaignIds([])
      return
    }

    if (attributes.filter(a => negativeAttributes.find(n => n.key === a.key)).length) {
      setFilteredCampaignIds(['empty'])
    } else {
      (async () => {
        setIsLoadingFilteredCampaigns(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const response = await dispatch(getFilteredCampaigns(accessToken, [
            ...attributes,
            ...negativeAttributes,
            {
              key: 'skus',
              skus
            }
          ]))
          setFilteredCampaignIds(response.length ? response : ['empty'])
        } finally {
          setIsLoadingFilteredCampaigns(false)
        }
      })()
    }
  }, [ // eslint-disable-line
    filterValues[CAMPAIGN_TABLE_FILTER]?.attributes, // eslint-disable-line
    filterValues[CAMPAIGN_TABLE_FILTER]?.negative_attributes, // eslint-disable-line
    filterValues[CAMPAIGN_TABLE_FILTER]?.skus // eslint-disable-line
  ])

  let filteredCampaigns = useMemo(() => {
    return (campaignsWithHistory || []).filter((record) => {
      if (hideSb && record.campaignType === 'sb') {
        return false
      }

      if (hideSd && record.campaignType === 'sd') {
        return false
      }

      if (
        record.campaign
        && searchKey
        && (
          (searchInclusive && !record.campaign.toLowerCase().includes(searchKey.toLowerCase()))
          || (!searchInclusive && record.campaign.toLowerCase().includes(searchKey.toLowerCase()))
        )
      ) {
        return false
      }

      let adTypeFilter
      if (!fromBulkEngine) {
        adTypeFilter = adTypeSelected || []
      } else {
        adTypeFilter = localAdTypeFilter
      }

      if (adTypeFilter.length) {
        if (
          adTypeFilter.filter(o => o.type === 'status').length
          && !adTypeFilter.find(o => o.value === record.state.toLowerCase())
        ) {
          return false
        }
        if (
          adTypeFilter.filter(o => o.type === 'ad_type').length
          && !adTypeFilter.find(o => o.value === record.campaignType.toLowerCase())
        ) {
          return false
        }
      }

      if (
        selectedPortfolioIds.length > 0
        && (
          (portfolioSelectionInclusive && !selectedPortfolioIds.includes(parseInt(record.portfolio_id, 10)))
          || (!portfolioSelectionInclusive && selectedPortfolioIds.includes(parseInt(record.portfolio_id, 10)))
        )
      ) {
        return false
      }

      if (selectedTagIds.length > 0) {
        const tagged = record.tags.find(t => selectedTagIds.includes(parseInt(t, 10)))
          // For `no tag` option.
          || (selectedTagIds.includes(0) && record.tags.length === 0)
        if ((tagSelectionInclusive && !tagged) || (!tagSelectionInclusive && tagged)) {
          return false
        }
      }

      if (!matchFilter(
        record,
        filterDef[CAMPAIGN_TABLE_FILTER],
        (filterValues || {})[CAMPAIGN_TABLE_FILTER] || {})
      ) {
        return false
      }

      if (showNotedCampaigns && !record.notes?.length) {
        return false
      }

      if (
        filteredCampaignIds.length
        &&
        !filteredCampaignIds.find(campaignId =>
          campaignId.toString() === record.campaign_id.toString()
        )
      ) {
        return false
      }

      return true
    })
  }, [
    campaignsWithHistory,
    fromBulkEngine,
    adTypeSelected,
    localAdTypeFilter,
    selectedPortfolioIds,
    portfolioSelectionInclusive,
    selectedTagIds,
    tagSelectionInclusive,
    searchKey,
    searchInclusive,
    filterValues,
    hideSb,
    hideSd,
    showNotedCampaigns,
    filteredCampaignIds,
  ])

  filteredCampaigns = useMemo(() => (
    tableSorter(['campaign', 'start_date'])
    (filteredCampaigns, [sortColumnName, sortDirection])
  ), [filteredCampaigns, sortColumnName, sortDirection])

  useEffect(() => {
    const filteredIds = filteredCampaigns.map(campaign =>
      campaign.campaign_id.toString()
    )

    const newSelection = campaignIdsSelected.filter(campaignId => (
      filteredIds.includes(campaignId)
    ))

    if (newSelection.length !== campaignIdsSelected.length) {
      dispatch(selectCampaigns(newSelection))
    }
  }, [filteredCampaigns]) // eslint-disable-line

  const selectedCampaigns = useMemo(() => {
    if (!campaignsWithHistory) {
      return []
    }

    return campaignsWithHistory.filter((campaign) => {
      const campaignId = campaign.campaign_id.toString()
      return campaignIdsSelected.includes(campaignId)
    })
  }, [campaignsWithHistory, campaignIdsSelected])

  const setCampaignColWidth = (width = campaignColMinWidth) => {
    campaignColRef.current.style.minWidth = `${width}px`
    let rowElement = campaignColRef.current.parentNode
    while (rowElement) {
      rowElement.childNodes[campaignColIndex + 1].style.minWidth = `${width}px`
      rowElement = rowElement.nextSibling
    }
  }

  const handleExpandCampaignCol = () => {
    let width = campaignColMinWidth
    const rows = document.getElementsByClassName('table-row')
    for (let index = 0; index < rows.length; index++) {
      const campaignCol = rows[index]
        .childNodes[campaignColIndex + 1]
        .getElementsByClassName('campaign-name')[0]
      if (campaignCol) {
        width = Math.max(width, campaignCol.scrollWidth)
      }
    }

    setCampaignColWidth(width)
  }

  const handleCollapseCampaignCol = () => {
    setCampaignColWidth()
  }

  const handleScroll = () => {
    if (refBody.current) {
      const { top } = refBody.current.getBoundingClientRect()
      if (top < 0) {
        setStickyOffset(-top)
        return
      }
    }
    setStickyOffset(null)
  }

  const loadPageCampaigns = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStartNum((pageNum - 1) * pageRows)
      setPageEndNum(pageNum * pageRows)
    } else {
      setPageStartNum(0)
      setPageEndNum(filteredCampaigns.length)
    }
  }

  const doSelectCampaigns = (campaigns) => {
    const campaignIds = campaigns.map(
      campaign => campaign.campaign_id.toString()
    )

    dispatch(selectCampaigns(campaignIds))
  }

  const handleSaveAcos = async (campaign) => {
    const acosValue = parseFloat(acosRef.current.value, 10)
    if (!acosValue || isNaN(acosValue)) {
      toast.show({
        title: 'Warning',
        description: 'Please enter Target ACoS.',
      })
      return
    }

    if (acosValue <= 0) {
      toast.show({
        title: 'Warning',
        description: 'Target ACoS should be greater than 0.',
      })
      return
    }

    setIsUpdatingAcos(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateTargetAcos(accessToken, [{
      campaignId: campaign.campaign_id,
      campaignType: campaign.campaignType,
      acos: acosValue,
      originalAcos: campaign.target_acos || (currentAccount?.average_acos || 30),
    }]))
    setIsUpdatingAcos(false)
  }

  const handleSaveDailyBudget = async (campaign) => {
    const newDailyBudget = parseFloat(dailyBudgetRef.current.value, 10)
    if (!newDailyBudget) {
      return
    }
    const newBudget = newDailyBudget
    if (isNaN(newBudget) || newBudget < 1) {
      toast.show({
        title: 'Warning',
        description: 'Minimum value is $1. Please enter a higher number.',
      })
      return
    }

    setIsUpdatingBudget(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateBudget(accessToken, [{
      campaignId: campaign.campaign_id,
      campaignType: campaign.campaignType,
      dailyBudget: newBudget,
      originalBudget: campaign.daily_budget,
    }]))
    setIsUpdatingBudget(false)
  }

  const handleSelect = (checked, data) => {
    if (checked) {
      doSelectCampaigns([...selectedCampaigns, data])
    } else {
      doSelectCampaigns(selectedCampaigns.filter(item =>
        item.campaign_id.toString() !== data.campaign_id.toString()
      ))
    }
  }

  const handleClickHistory = (chartData, columnKey, campaign, metric) => {
    if (!shownHistoryCampaigns.includes(campaign.campaign_id)) {
      return
    }

    setHistoryPayload({
      metric,
      metricKey: columnKey,
      campaign: campaign.campaign,
      data: (chartData || []).map(item => ({
        date: item.report_date,
        value: item[columnKey] ? item[columnKey] : 0,
      })),
    })
  }

  const handleViewCreativeAndDetail = async (expanded, campaignIds) => {
    if (expanded) {
      const selectedIds = expandedCampaign.filter(
        p => !campaignIds.includes(p)
      ).concat(campaignIds.filter(c => !expandedCampaign.includes(c)))

      setExpandedCampaign(selectedIds)

      if (selectedIds.length) {
        setIsDetailsLoading(true)
        const accessToken = await getAccessTokenSilently()
        dispatch(getCampaignsDetail(accessToken, selectedIds)).then(() => {
          setIsDetailsLoading(false)
        })

        if (!isTagsLoaded && !isTagsLoading) {
          dispatch(getAllTags(accessToken))
          setIsTagsLoaded(true)
        }
      }
    } else {
      setExpandedCampaign(expandedCampaign.filter(item =>
        !campaignIds.includes(item)
      ))
    }
  }

  const loadHistoryData = async (campaignIds) => {
    setIsHistoryLoading(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(getCampaignsHistory(accessToken, campaignIds)).then(() => {
      setIsHistoryLoading(false)
    })
  }

  const handleHistoryShow = async () => {
    const campaignIds = selectedCampaigns.map(campaign => campaign.campaign_id)
    const selectedIds = shownHistoryCampaigns.filter(
      p => !campaignIds.includes(p)
    ).concat(campaignIds.filter(c => !shownHistoryCampaigns.includes(c)))

    setShownHistoryCampaigns(selectedIds)

    if (selectedIds.length) {
      loadHistoryData(selectedIds)
    }
  }

  const handleToggleHistoryView = async (show, campaignId) => {
    if (show) {
      const campaignIds = [...shownHistoryCampaigns, campaignId]
      setShownHistoryCampaigns(campaignIds)
      loadHistoryData(campaignIds)
    } else {
      setShownHistoryCampaigns(p => p.filter(shownCampaign => shownCampaign !== campaignId))
    }
  }

  const sortColumn = (field) => {
    setSortDirection(sortColumnName === field && sortDirection === 'asc' ? 'desc' : 'asc')
    setSortColumnName(field)
  }

  const handleRowsPerPageChange = (rowsPerPage) => {
    if (fromBulkEngine) {
      window.localStorage.setItem(BULK_ENGINE_ROWS_PER_PAGE, rowsPerPage)
    }
  }

  const handleClearView = () => {
    const campaignIds = selectedCampaigns.map(c => c.campaign_id)
    setExpandedCampaign(p => p.filter(c => !campaignIds.includes(c)))
    setShownHistoryCampaigns(p => p.filter(c => !campaignIds.includes(c)))
  }

  const handleDeselectFromModal = (campaignId) => {
    doSelectCampaigns(p => p.filter(c =>
      c.campaign_id.toString() !== campaignId.toString()
    ))
  }

  const handleShowNotePane = (campaign) => {
    dispatch(showCampaignNote())
    setNoteCampaign(campaign)
  }

  const handleHideNotePane = () => {
    dispatch(hideCampaignNote())
    setNoteCampaign(null)
  }

  const handlePortfolioSelect = (ids) => {
    setSelectedPortfolioIds(ids)
    doSelectCampaigns([])
  }

  const handleTagSelect = (tags) => {
    setSelectedTagIds(tags)
  }

  const campaignToExport = useMemo(() => {
    return filteredCampaigns.map(c => {
      const extractedObj = {};
      columnsToExport.forEach(key => {
        if (c.hasOwnProperty(key)) {
          extractedObj[key] = c[key];
        }
      });
      return extractedObj;
    });
  }, [filteredCampaigns]);

  let pageStart = pageStartNum
  let pageEnd = pageEndNum
  if (pageEndNum > filteredCampaigns.length) {
    const numberPerPage = pageEndNum - pageStartNum
    const totalPage =  Math.ceil(filteredCampaigns.length / numberPerPage)
    pageStart = (totalPage - 1) * numberPerPage
    pageEnd = filteredCampaigns.length
  }

  const columnSelection = useMemo(() => {
    if (!campaignTableColumns.length
      || !campaignTableColumns[0]
      || typeof campaignTableColumns[0] !== 'object'
      || campaignTableColumns[0].key !== 'campaign') {
        return campaignColumnSetting[0].setting
      }
      return campaignTableColumns
  }, [campaignTableColumns])

  const campaignElements = filteredCampaigns.slice(pageStart, pageEnd).map(record => (
    <TableRow
      key={record.campaign_id}
      isLoading={isLoading}
      isDetailLoading={isDetailLoading}
      campaign={record}
      selectedCampaigns={selectedCampaigns}
      selectedColumns={columnSelection}
      acosCampaignId={acosCampaignId}
      acosRef={acosRef}
      dailyBudgetCampaignId={dailyBudgetCampaignId}
      dailyBudgetRef={dailyBudgetRef}
      currencySign={currencySign}
      currencyRate={currencyRate}
      startDate={currentStartDate}
      endDate={currentEndDate}
      accountAcos={currentAccount?.average_acos || 30}
      hideDailyBudget={fromBulkEngine}
      isNonEndemicAccount={isNonEndemicAccount}
      expandedRow={(expandedCampaign || []).includes(record.campaign_id)}
      showHistory={(shownHistoryCampaigns || []).includes(record.campaign_id)}
      onSelect={handleSelect}
      onOpenAcosPopup={setAcosCampaignId}
      onSaveAcos={handleSaveAcos}
      onCancelAcos={() => { setAcosCampaignId('') }}
      onOpenDailyBudgetPopup={setDailyBudgetCampaignId}
      onSaveDailyBudget={handleSaveDailyBudget}
      onCancelDailyBudget={() => { setDailyBudgetCampaignId('') }}
      onClickHistory={handleClickHistory}
      onExpandRow={handleViewCreativeAndDetail}
      onToggleHistoryView={handleToggleHistoryView}
      onShowNotePane={handleShowNotePane}
    />
  ))

  let tableBodyClass = 'table-body'
  if (stickyOffset !== null) {
    tableBodyClass = `${tableBodyClass} sticky`
  }

  const isDataLoading = isLoading
    || isUpdatingAcos
    || isUpdatingBudget
    || isTurningBulk
    || isDetailLoading
    || isHistoryLoading
    || isLoadingFilteredCampaigns

  let pageRows = 10
  if (fromBulkEngine) {
    const saved = window.localStorage.getItem(BULK_ENGINE_ROWS_PER_PAGE)
    if (saved) {
      if (saved !== 'all') {
        pageRows = parseInt(saved, 10)
      } else {
        pageRows = saved
      }
    } else {
      pageRows = 5
    }
  }

  return (
    <div className={`campaign-table-component ${className}${isDataLoading ? ' loading' : ''}`}>
      { isDataLoading && <LoaderComponent /> }
      {
        showColumnEditor && visibleColumnEditorId === '' &&
        <CampaignColumnEditor
          currentSelection={columnSelection}
          hideDailyBudget={fromBulkEngine}
          isNonEndemicAccount={isNonEndemicAccount}
          onApply={selectColumns}
        />
      }
      {
        visibleFilterName === CAMPAIGN_TABLE_FILTER &&
        <TableFilter
          title="Campaign Table Filters"
          filterName={CAMPAIGN_TABLE_FILTER}
        />
      }
      {
        showCampaignNotePane && noteCampaign &&
          <CampaignNotePane
            campaign={noteCampaign}
            onChange={setNoteCampaign}
            onClose={handleHideNotePane}
          />
      }
      <ActionBar
        searchKey={searchKey}
        searchInclusive={searchInclusive}
        portfolioSelectionInclusive={portfolioSelectionInclusive}
        tagSelectionInclusive={tagSelectionInclusive}
        selectedCampaigns={selectedCampaigns}
        dataToExport={campaignToExport}
        customActionBar={customActionBar}
        hasExpanded={expandedCampaign.length || shownHistoryCampaigns.length}
        fromBulkEngine={fromBulkEngine}
        localAdTypeFilter={localAdTypeFilter}
        onChangeSearch={setSearchKey}
        onPortfolioSelect={handlePortfolioSelect}
        onTagSelect={handleTagSelect}
        onViewCreativeAndDetail={handleViewCreativeAndDetail}
        onViewMetricsChart={handleHistoryShow}
        onClearView={handleClearView}
        onDeselectFromModal={handleDeselectFromModal}
        onChangeViewMethod={onChangeViewMethod}
        onChangeSearchInclusive={setSearchInclusive}
        onChangePortfolioSelectionInclusive={setPortfolioSelectionInclusive}
        onChangeTagSelectionInclusive={setTagSelectionInclusive}
        onChangeAdTypeFilter={setLocalAdTypeFilter}
      />
      <div className={tableBodyClass} ref={refBody}>
        <TableHeader
          campaigns={filteredCampaigns}
          selectedCampaigns={selectedCampaigns}
          selectedColumns={columnSelection}
          refHeader={refHeader}
          tableHeight={tableHeight}
          resizing={resizing}
          campaignColRef={campaignColRef}
          hideDailyBudget={fromBulkEngine}
          isNonEndemicAccount={isNonEndemicAccount}
          onSelect={doSelectCampaigns}
          onSort={sortColumn}
          onCollapseCampaignCol={handleCollapseCampaignCol}
          onExpandCampaignCol={handleExpandCampaignCol}
          onStartResizing={handleStartResizing}
        />
        <TableFooter
          campaigns={filteredCampaigns}
          selectedColumns={columnSelection}
          currencySign={currencySign}
          currencyRate={currencyRate}
          refTotal={refTotal}
          hideDailyBudget={fromBulkEngine}
          isNonEndemicAccount={isNonEndemicAccount}
        />
        { campaignElements }
      </div>
      <PaginationComponent
        total={filteredCampaigns.length}
        pageRows={pageRows}
        loadData={loadPageCampaigns}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <ThumbHistory
        title={`Campaign: ${historyPayload.campaign}`}
        areaData={historyPayload.data || []}
        metric={historyPayload.metric}
        metricKey={historyPayload.metricKey}
        startDate={currentStartDate}
        endDate={currentEndDate}
        onClose={() => { setHistoryPayload({}) }}
      />
    </div>
  )
}

export default CampaignTableComponent
