import React from 'react'
import { Modal } from 'rsuite'

import SBCampaignCreativeComponent from '../CommonComponents/SBCampaignCreativeComponent'
import SDCreativePreview from '../CommonComponents/SDCreativePreview'

const CreativeModal = ({ campaignId, campaignType, products, onClose }) => {
  const renderContents = () => {
    if (campaignType === 'sb') {
      return (
        <SBCampaignCreativeComponent campaignId={campaignId} />
      )
    }
    if (campaignType === 'sd') {
      return (
        <SDCreativePreview campaignId={campaignId} products={products} />
      )
    }
  }

  return (
    <Modal className="creative-modal" backdrop="static" show size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Campaign Creative
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { renderContents() }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreativeModal
