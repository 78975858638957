import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { components } from 'react-select'

import SelectAsyncPaginate from '../SelectAsyncPaginate'

import { loadSkus } from '../../../redux/actions/ruleManager'

const Option = (props) => {
  const { innerRef, innerProps, getStyles, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
      className="sku-option"
    >
      {
        data.image !== '' ? (
          <img src={data.image} alt={data.name} />
        ) : (
          <span className="placeholder-image" title={data.name}>
            Image
          </span>
        )
      }
      <div className="product-info">
        <span className="product-name" title={data.name}>{data.name}</span>
        <span className="product-sku">SKU: {data.sku}</span>
      </div>
    </div>
  )
}

// https://github.com/JedWatson/react-select/issues/4170#issuecomment-682465724
const ValueContainer = (props) => {
  const { options, children, getValue } = props
  const selectCount = getValue().length
  let contents = children
  if (selectCount > 0) {
    if (selectCount === options.length) {
      contents = (
        <>
          All SKUs selected
          { children[1] }
        </>
      )
    } else if (selectCount >= 10) {
      contents = (
        <>
          { selectCount } SKUs selected
          { children[1] }
        </>
      )
    }
  }
  return (
    <components.ValueContainer {...props}>
      { contents }
    </components.ValueContainer>
  )
}

const MultiValueLabel = (props) => {
  const { data } = props
  return (
    <components.MultiValueLabel {...props}>
      {
        data.image !== '' ? (
          <img src={data.image} alt={data.name} title={data.name} />
        ) : (
          <span className="placeholder-image" title={data.name}>
            Image
          </span>
        )
      }

    </components.MultiValueLabel>
  )
}

const SkuSelector = ({ selectedSkus, disabled = false, showAllButton = true, onChange }) => {
  const dispatch = useDispatch()

  const [loadedSkus, setLoadedSkus] = useState([])

  const loadData = (accessToken, page, search) => {
    return dispatch(loadSkus(accessToken, {
      page,
      search,
      countPerPage: 10,
    }))
  }

  const handleLoad = (skus) => {
    setLoadedSkus(prev => ([...prev, ...skus]))
  }

  const handleSelectAll = () => {
    if (selectedSkus === 'all' || selectedSkus.length === loadedSkus.length) {
      // If all is selected, un-select all.
      onChange([])
    } else {
      // Select all.
      onChange('all')
    }
  }

  return (
    <div className="smart-select-wrapper">
      <SelectAsyncPaginate
        keyValue="skus"
        classNamePrefix="sku-selector"
        components={{ Option, ValueContainer, MultiValueLabel }}
        loadData={loadData}
        selection={selectedSkus !== 'all' ? selectedSkus : []}
        isDisabled={disabled}
        placeholder="Select SKUs..."
        field="sku"
        onChange={onChange}
        onLoad={handleLoad}
      />
      { showAllButton && (
        <button
          type="button"
          className="btn btn-white"
          disabled={disabled}
          onClick={handleSelectAll}
        >
          {
            (selectedSkus !== 'all' && (selectedSkus.length === 0 || selectedSkus.length !== loadedSkus.length))
            ? 'Select All'
            : 'Unselect All'
          }
        </button>
      )}
    </div>
  )
}

export default SkuSelector
