import React, { useEffect } from 'react'

import RuleMetric from './RuleMetric'

const RuleSection = ({ skus, rules, isUpdatingStatus, onChange, onStatusChange }) => {
  useEffect(() => {
    if (!rules.length) {
      handleSectionAdd()
    }
  }, [rules]) // eslint-disable-line

  const handleSectionAdd = () => {
    onChange([
      ...rules,
      {
        id: `new-${Math.floor(Math.random() * 1000).toString()}`,
      },
    ])
  }

  const handleSectionChange = ruleId => (values) => {
    onChange(rules.map((rule) => {
      if (rule.id !== ruleId) {
        return rule
      }
      return {
        id: rule.id,
        ...values,
      }
    }))
  }

  const handleSectionRemove = ruleId => () => {
    onChange(rules.filter(rule => (
      rule.id !== ruleId
    )))
  }

  return (
    <div className="rule-section">
      <div className="section-header">
        Add/Edit Rules
      </div>
      {
        rules.map(rule => (
          <RuleMetric
            key={rule.id}
            skus={skus}
            rule={rule}
            isUpdatingStatus={isUpdatingStatus}
            onChange={handleSectionChange(rule.id)}
            onRemove={handleSectionRemove(rule.id)}
            onStatusChange={onStatusChange}
          />
        ))
      }
      <div className="section-footer">
        <button
          type="button"
          className="btn btn-white"
          onClick={handleSectionAdd}
        >
          + Add new
        </button>
      </div>
    </div>
  )
}

export default RuleSection
