import React from 'react'

const dowLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const timeLabels = [
  '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM',
  '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
  '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM',
  '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM',
]

const SelectionShower = ({ slotSelection }) => {
  const renderSlot = (slotNumber) => {
    return (
      <span key={slotNumber} className="slot-display">
        <span className="slot-inner">
          { dowLabels[Math.floor(slotNumber / 24)] }
          &nbsp;
          { timeLabels[slotNumber % 24] }
        </span>
      </span>
    )
  }

  return (
    <div className="selection-shower">
      { slotSelection.map(renderSlot) }
    </div>
  )
}

export default SelectionShower
