import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FiMaximize2, FiMinimize2, FiFilter, FiColumns } from 'react-icons/fi'

import MainLayout from '../../layout/MainLayout'
import BulkOp from '../../components/BulkOp'
import BulkExpansion from '../../components/BulkExpansion'
import TargetSearchTable from '../../components/TargetSearchTable'
import BulkJobs from '../../components/BulkJobs'
import DateRangeComponent from '../../components/CommonComponents/DateRangeComponent'

import { ReactComponent as TaskOnSvg } from '../../assets/svg/task.svg'
import { ReactComponent as TaskOffSvg } from '../../assets/svg/task-passive.svg'

import { toggleDashboardTable } from '../../redux/actions/dashboard'
import { setDateRange } from '../../redux/actions/header'
import {
  showFilter,
  showColumnEditorAction,
} from '../../redux/actions/pageGlobal'
import { getJobResults } from '../../redux/actions/job'
import { selectCampaigns } from '../../redux/actions/campaign'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import {
  JOB_TYPE_BULK_SKU_OP,
  JOB_TYPE_BULK_BID_OP,
  JOB_TYPE_BULK_ST_OP,
  JOB_TYPE_BULK_NEGATIVE,
  JOB_TYPE_BULK_PLACEMENT_OP,
  JOB_TYPE_BULK_ADVANCED_NEGATIVE,
  JOB_TYPE_BULK_TARGET_EX,
  JOB_TYPE_BULK_ST_EX,
  JOB_TYPE_BULK_PRODUCT_EX,
  JOB_TYPE_BULK_TARGET_SEARCH,
  JOB_TYPE_BULK_DUPLICATE_TARGETS,
} from '../../utils/defaultValues'

const TAB_BULK_OP = 'bulk_op'
const TAB_BULK_EX = 'bulk_ex'
const TAB_TARGET_SEARCH = 'target_search'
const TAB_BULK_JOBS = 'bulk_jobs'

const tabList = [
  { tab: TAB_BULK_OP, name: 'Bulk Optimization' },
  { tab: TAB_BULK_EX, name: 'Bulk Expansion' },
  { tab: TAB_TARGET_SEARCH, name: 'Target Search' },
  { tab: TAB_BULK_JOBS, name: 'Generated Reports' },
]

const BulkEditorPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const maxTable = useSelector(state => state.dashboard.maxTable)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const [activeTab, setActiveTab] = useState(TAB_BULK_OP)
  const [openedJob, setOpenedJob] = useState('')
  const [isShowNotedCampaigns, setIsShowNotedCampaigns] = useState(false)
  const [hasResult, setHasResult] = useState(false)
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if (location.state && location.state.jobId) {
      (async () => {
        await handleOpenResults(location.state.jobId)
      })()
    }
  }, [location.state]) // eslint-disable-line

  const switchTab = (tab) => {
    setActiveTab(tab)
    if (campaignsWithHistory.length) {
      setInitialRender(false)
    }
  }

  // Called when a saved job results is opened from bulk jobs tab.
  const handleOpenResults = async (jobId) => {
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getJobResults(accessToken, jobId))

    const { type, payload, results } = response.data

    if (type === JOB_TYPE_BULK_SKU_OP
      || type === JOB_TYPE_BULK_BID_OP
      || type === JOB_TYPE_BULK_ST_OP
      || type === JOB_TYPE_BULK_NEGATIVE
      || type === JOB_TYPE_BULK_PLACEMENT_OP
      || type === JOB_TYPE_BULK_ADVANCED_NEGATIVE) {
      switchTab(TAB_BULK_OP)
    } else if (type === JOB_TYPE_BULK_TARGET_EX
      || type === JOB_TYPE_BULK_ST_EX
      || type === JOB_TYPE_BULK_PRODUCT_EX) {
      switchTab(TAB_BULK_EX)
    } else if (type === JOB_TYPE_BULK_TARGET_SEARCH
      || type === JOB_TYPE_BULK_DUPLICATE_TARGETS) {
      switchTab(TAB_TARGET_SEARCH)
    } else {
      return
    }

    setOpenedJob({
      type,
      payload,
      results,
    })

    dispatch(selectCampaigns(payload.campaignIds.map(id => id.toString())))
  }

  const handleDateRangeChange = ([ startDate, endDate ]) => {
    dispatch(setDateRange({
      startDate,
      endDate,
    }))
  }

  const handleFilterShow = () => {
    dispatch(showFilter('campaignTable'))
  }

  const handleColumnEditorShow = () => {
    dispatch(showColumnEditorAction())
  }

  const renderTab = (tab) => {
    if (isNonEndemicAccount && tab.tab === TAB_TARGET_SEARCH) {
      return null
    }

    return (
      <button
        key={tab.tab}
        type="button"
        className={`table-tab${activeTab === tab.tab ? ' selected' : ''}`}
        onClick={() => { switchTab(tab.tab) }}
      >
        { tab.name }
      </button>
    )
  }

  const renderMaxMin = () => {
    if (!maxTable) {
      return (
        <FiMaximize2
          color='#979797'
          onClick={() => { dispatch(toggleDashboardTable()) }}
        />
      )
    }
    return (
      <FiMinimize2
        color='#979797'
        onClick={() => { dispatch(toggleDashboardTable()) }}
      />
    )
  }

  const renderAction = () => {
    if (hasResult) {
      return null
    }
    return (
      <>
        <FiFilter title="Filter" color="#979797" onClick={handleFilterShow} />
        {
          isShowNotedCampaigns ? (
            <TaskOnSvg title="Show Noted Campaigns" onClick={() => { setIsShowNotedCampaigns(false) }} />
          ) : (
            <TaskOffSvg title="Show Noted Campaigns" onClick={() => { setIsShowNotedCampaigns(true) }} />
          )
        }
        <FiColumns
          title="Column Customizer"
          color="#979797"
          onClick={handleColumnEditorShow}
        />
      </>
    )
  }

  const renderContents = () => {
    if (activeTab === TAB_BULK_OP) {
      return (
        <BulkOp
          openedJob={openedJob}
          showNotedCampaigns={isShowNotedCampaigns}
          initialRender={initialRender}
          onHasResult={setHasResult}
        />
      )
    }
    if (activeTab === TAB_BULK_EX) {
      return (
        <BulkExpansion
          openedJob={openedJob}
          showNotedCampaigns={isShowNotedCampaigns}
          initialRender={initialRender}
          onHasResult={setHasResult}
        />
      )
    }
    if (activeTab === TAB_TARGET_SEARCH) {
      return (
        <TargetSearchTable
          openedJob={openedJob}
          showNotedCampaigns={isShowNotedCampaigns}
          initialRender={initialRender}
          onHasResult={setHasResult}
        />
      )
    }
    if (activeTab === TAB_BULK_JOBS) {
      return (
        <BulkJobs
          onOpenResults={handleOpenResults}
        />
      )
    }
    return null
  }

  return (
    <MainLayout>
      <div className="bulk-editor-page">
        <div className="page-content">
          <div className="table-collection">
            <div className="table-tabs">
              <div className="table-tab-left">
                { tabList.map(renderTab) }
              </div>
              <div className="table-tab-right">
                <DateRangeComponent
                  value={[currentStartDate, currentEndDate]}
                  placement="bottomEnd"
                  onChange={handleDateRangeChange}
                />
                { renderAction() }
                { renderMaxMin() }
              </div>
            </div>
            { renderContents() }
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default BulkEditorPage
