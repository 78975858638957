import React, { useState } from 'react'
import * as Icon from 'react-icons/fi'

const TreeComponent = (props) => {
  const { actionLabel = 'Target', nodes, nodesTargeted, parentNode = null, parentExpandedNodes = {},
    onTarget, onRefine } = props

  const [expandedNodes, setExpandedNodes] = useState(parentExpandedNodes)

  const handleTarget = (node) => {
    onTarget(node, parentNode, expandedNodes)
  }

  const showChild = (node) => {
    if (node.ch.length === 0) {
      return
    }

    const newExpandedNode = {
      ...expandedNodes
    }

    if (newExpandedNode[node.id]) {
      newExpandedNode[node.id].isShow = !newExpandedNode[node.id].isShow
    } else {
      newExpandedNode[node.id] = {
        isShow: true,
        data: node.ch,
        path: parentNode && newExpandedNode[parentNode.id]
          ? `${newExpandedNode[parentNode.id].path}/${node.na}`
          : `/${node.na}`,
      }
    }
    setExpandedNodes(newExpandedNode)
  }

  return (
    <div className="tree-list">
      {
        nodes.map((node) => {
          let isAdded = false
          if (nodesTargeted) {
            const matching = nodesTargeted
              .filter(target =>
                target.type !== 'product'
                && target.id.toString() === node.id.toString()
              )
            isAdded = matching.length > 0
          }
          return (
            <div className="tree-item" key={node.id} >
              <div className="tree-contents">
                <button
                  type="button"
                  className="node-info"
                  onClick={() => { showChild(node) }}
                >
                  {
                    node.ch.length > 0 ? expandedNodes && expandedNodes[node.id] && expandedNodes[node.id].isShow ?
                    <Icon.FiMinus/> : <Icon.FiPlus/> : <></>
                  }
                  { node.na }
                </button>
                <div className="item-action">
                  {
                    isAdded ? (
                      <button type="button" className="btn btn-blue disabled">
                        { actionLabel }ed
                      </button>
                    ) : (
                      <button className="btn btn-blue" onClick={() => { handleTarget(node) }}>
                        { actionLabel }
                      </button>
                    )
                  }
                  <button type="button" className="btn btn-green" onClick={() => { onRefine(node) }}>Refine</button>
                </div>
              </div>
              <div className="tree-child">
                {
                  expandedNodes && expandedNodes[node.id] && expandedNodes[node.id].isShow && (
                    <TreeComponent
                      actionLabel={actionLabel}
                      nodes={node.ch}
                      parentNode={node}
                      nodesTargeted={nodesTargeted}
                      parentExpandedNodes={expandedNodes}
                      onTarget={onTarget}
                      onRefine={onRefine}
                    />
                  )
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default TreeComponent
