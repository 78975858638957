import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Toggle, TagGroup, Tag } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import ComparisonView from '../ComparisonView'

import { getSummary } from '../../redux/actions/campaign'

import { COMPARISON_VIEW_TYPE_WEEKLY } from '../../utils/defaultValues'

const ComparisonDataModal = ({ campaigns, type, onClose, onCloseCampaign }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [campaignSummary, setCampaignSummary] = useState([])
  const [isShowCampaigns, setIsShowCampaigns] = useState(true)

  useEffect(() => {
    if (!campaigns.length) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsSummaryLoading(true)
      dispatch(getSummary(
        accessToken,
        campaigns.map(c => c.campaign_id),
        type
      )).then(res => {
        setCampaignSummary(res)
        setIsSummaryLoading(false)
      }).catch(() => {
        setIsSummaryLoading(false)
      })
    })()
  }, [campaigns, type]) // eslint-disable-line

  return (
    <Modal className={`comparison-data-modal ${isSummaryLoading ? 'loading' : ''}`} backdrop="static" show size="lg" overflow={false}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          { type === COMPARISON_VIEW_TYPE_WEEKLY ? 'Week over Week' : 'Month over Month' }
          {
            (campaigns || []).length > 0 && (
              <Toggle
                checked={isShowCampaigns}
                checkedChildren="Show Campaigns"
                unCheckedChildren="Hide Campaigns"
                onChange={setIsShowCampaigns}
              />
            )
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (campaigns.length > 0 && isShowCampaigns) && (
            <TagGroup>
              {
                campaigns.map(c => (
                  <Tag
                    key={c.campaign_id}
                    closable={campaigns.length > 1}
                    onClose={() => { onCloseCampaign(c.campaign_id) }}
                  >
                    {c.campaign}
                  </Tag>
                ))
              }
            </TagGroup>
          )
        }
        <div className="comparison-content">
          <ComparisonView
            isLoading={isSummaryLoading}
            summary={campaignSummary}
            noSales
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ComparisonDataModal
