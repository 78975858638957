import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import LogoPng from '../../assets/img/logo.png'

import AuthSideComponent from '../../components/AuthSideComponent'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import { signupBasic, signupNameInput } from '../../redux/actions/auth'

import { STORAGE_KEY_REGION } from '../../utils/defaultValues'

const SignupAuditPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isSigningBasic = useSelector(state => state.auth.isSigningBasic)
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  useEffect(() => {
    if (!user) {
      return
    }

    dispatch(signupBasic(user.sub)).then((response) => {
      if (!response.isValid) {
        history.push('/dashboard')
      } else {
        if (typeof window.biSignup !== 'undefined') {
          window.biSignup({
            name: `${response.firstName} ${response.lastName}`,
            email: response.email,
            tool_name: 'PPC Entourage'
          })
        }

        window.sessionStorage.removeItem(STORAGE_KEY_REGION)

        if (response.firstName && response.lastName) {
          history.push('/signup-audit-complete')
        }
      }
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }, [dispatch, user]) // eslint-disable-line

  const handleConnect = () => {
    if (!firstName || !lastName) {
      toast.show({
        title: 'Danger',
        description: 'Please enter your first and last names.',
      })
      return
    }

    dispatch(signupNameInput(firstName, lastName))

    history.push('/signup-audit-complete')
  }

  if (isLoading || isSigningBasic) {
    return <LoaderComponent />
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: '/signup-audit',
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    })
    return
  }

  return (
    <div className="auth-layout">
      <div className="auth-page">
        <div className="auth-form">
          <div className="logo-header">
            <img src={LogoPng} alt="PPC Entourage" />
            PPC Entourage
          </div>
          <div className="form-title">
            Sign Up
          </div>
          <div className="form-sub-title">
            Sign up for PPC Entourage + Carbon6
          </div>
          <div className="name-input-container">
            <input
              type="text"
              placeholder="First Name"
              value={firstName}
              required
              onChange={(event) => { setFirstName(event.target.value) }}
            />
            <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              required
              onChange={(event) => { setLastName(event.target.value) }}
            />
          </div>
          <button
            type="button"
            className="btn btn-navy"
            onClick={handleConnect}
          >
            Connect Amazon Accounts
          </button>
        </div>
      </div>
      <AuthSideComponent>
        Sign Up for PPC Entourage + Carbon6 account to get started.
      </AuthSideComponent>
    </div>
  )
}

export default SignupAuditPage
