import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tree } from 'rsuite'
import { FiLoader } from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTooltip from '../../CommonComponents/CustomTooltip'

import { getAudiences } from '../../../redux/actions/campaignCreator'

const AudienceTree = ({ targetings, onTarget }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [expandValues, setExpandValues] = useState([])

  const [data, setData] = useState([
    {
      value: 'Lifestyle',
      label: 'Lifestyle',
      description: 'Audiences whose shopping and streaming behaviors suggest certain lifestyle preferences and affinities',
      path: ['Lifestyle'],
      children: [],
    },
    {
      value: 'Interest',
      label: 'Interest',
      description: 'Audiences whose shopping activity suggests a durable interest in specific categories',
      path: ['Interest'],
      children: [],
    },
    {
      value: 'Life event',
      label: 'Life event',
      description: 'Audiences with recent activity around life moments such as moving or getting married',
      path: ['Life event'],
      children: [],
    },
    {
      value: 'In-market',
      label: 'In-market',
      description: 'Audiences whose recent activity suggests they are likely to buy products in a certain category',
      path: ['In-market'],
      children: [],
    },
  ])

  const [loadingValues, setLoadingValues] = useState([])

  const handleOnExpand = async (expandItemValues, activeNode, concat) => {
    const new_expandValues = Object.assign(expandValues)

    if (new_expandValues.includes(activeNode.value)) {
      new_expandValues.splice(new_expandValues.indexOf(activeNode.value), 1)
      setExpandValues(new_expandValues)

    } else {
      new_expandValues.push(activeNode.value)
      setExpandValues(new_expandValues)
    }
    if (typeof activeNode.children === 'undefined') {
      return
    }

    if (loadingValues.includes(activeNode.value)) {
      return
    }

    setLoadingValues(prev => ([
      ...prev,
      activeNode.value,
    ]))

    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getAudiences(accessToken, activeNode.path))

    setLoadingValues(loadingValues.filter(
      value => value !== activeNode.value
    ))

    const children = []
    response.categories.forEach((category) => {
      children.push({
        value: category,
        label: category,
        path: activeNode.path.concat(category),
        children: [],
      })
    })

    let newExpandValues = expandValues.filter(value => !response.categories.includes(value))
    setExpandValues(newExpandValues)

    response.audiences.forEach((audience) => {
      children.push({
        value: audience.value,
        label: audience.label,
        description: audience.description,
      })
    })
    setData(concat(data, children))
  }

  const renderTreeIcon = (node) => {
    if (loadingValues.includes(node.value)) {
      return <FiLoader size={16} />
    }
    return null
  }

  const renderTreeNode = (node) => {
    const isExisting = targetings.find(targeting => (
      targeting.type === 'audience'
      && targeting.id.toString() === node.value.toString()
    ))

    return (
      <span className="audience-tree-node">
        <span className="label-wrapper">
          { node.label }
          {
            node.description && (
              <CustomTooltip placement="right">
                { node.description }
              </CustomTooltip>
            )
          }
        </span>
        {
          typeof node.children === 'undefined' && (
            isExisting ? (
              <button type="button" className="btn btn-blue disabled">
                Added
              </button>
            ) : (
              <button type="button" className="btn btn-blue" onClick={() => { onTarget(node) }}>
                Add
              </button>
            )
          )
        }
      </span>
    )
  }

  return (
    <Tree
      className="audience-tree"
      data={data}
      renderTreeIcon={renderTreeIcon}
      renderTreeNode={renderTreeNode}
      expandItemValues={expandValues}
      onExpand={handleOnExpand}
    />
  )
}

export default AudienceTree
