import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH } from '../../utils/defaultValues'

const AddNotesModal = ({ campaigns, onSave, onClose, isLoading }) => {

  const [notes, setNotes] = useState([])
  const [bulkNote, setBulkNote] = useState('')

  useEffect(() => {
    if (campaigns.length) {
      setNotes(campaigns.map(c => ({
          campaignId: c.campaign_id,
          note: '',
        })
      ))
    }
  }, [campaigns])

  const applyNoteToCampaigns = () => {
    setNotes(prevNotes => prevNotes.map(note => ({
      ...note,
      note: bulkNote
    })))
  }

  const handleClear = (campaignId) => {
    setNotes(prevNotes => prevNotes.map(note =>
      note.campaignId.toString() === campaignId.toString() ? { ...note, note: ''} : note)
    )
  }

  const handleBulkNoteChange = (note) => {
    if (note.length <= MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH) {
      setBulkNote(note)
    }
  }

  const handleChangeNote = (campaignId, newNote) => {
    if (newNote.length < MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH) {
      setNotes(prevNotes => prevNotes.map(
        note => note.campaignId.toString() === campaignId.toString() ? { ...note, note: newNote} : note
      ))
    }
  }

  const renderActions = () => (
    <>
      <div className="bulk-note-section">
        <textarea
          className="campaign-note-textarea"
          rows={4}
          placeholder={`Maximum ${MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH} Characters`}
          value={bulkNote}
          onChange={(v) => handleBulkNoteChange(v.target.value)}
        />
        <button
          type="button"
          className="btn btn-blue"
          disabled={bulkNote === ''}
          onClick={applyNoteToCampaigns}
        >
          Apply To All
        </button>
      </div>
    </>
  )

  const renderCampaign = record => {
    const note = notes.find(n => n.campaignId.toString() === record.campaign_id.toString())
    return (
      <>
        <div className="table-col col-campaign">
          { record.campaign }
        </div>
        <div className="table-col col-note">
          <textarea
            className="campaign-note-textarea"
            rows={4}
            placeholder={`Maximum ${MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH} Characters`}
            value={ note ? note.note : ''}
            onChange={(v) => handleChangeNote(record.campaign_id, v.target.value)}
          />
        </div>
        <div className="table-col col-action">
          <button
            type="button"
            className="btn btn-red"
            onClick={() => handleClear(record.campaign_id)}
          >
            Clear
          </button>
        </div>
      </>
    )
  }

  const renderFooter = () => (
    <Modal.Footer>
      <button
        type="button"
        className="rs-btn rs-btn-primary"
        disabled={isLoading}
        onClick={() => { onSave(notes) }}
      >
        Save
      </button>
      <button
        type="button"
        className="rs-btn rs-btn-subtle"
        onClick={() => onClose()}
      >
        Close
      </button>
    </Modal.Footer>
  )

  return (
    <Modal className={`add-notes-modal ${isLoading ? 'loading' : ''}`} backdrop="static" show size="lg" overflow={false}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Add Notes to Campaigns
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          records={campaigns}
          idField="campaign_id"
          noCheckBox
          noSearch
          renderRecord={renderCampaign}
          renderTopRight={renderActions}
          isLoading={isLoading}
        >
          <div className="table-col col-campaign">Campaign</div>
          <div className="table-col col-note">Note</div>
          <div className="table-col col-action"></div>
        </CustomTable>
      </Modal.Body>
      { renderFooter() }
    </Modal>
  )
}

export default AddNotesModal
