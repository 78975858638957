import React from 'react'

import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'
import OpPlacement from './OpPlacement'
import OpKeywordIgnore from './OpKeywordIgnore'
import OpKeywordEx from './OpKeywordEx'

const OpKeywordGeneral = ({ campaign, settings, fromTemplateEditor,
  isNonEndemicAccount, onChange }) => {
  let isSP = false
  let isAutoSP = false
  let hasPlacement = false
  if (campaign) {
    isSP = campaign.basic[0].type === 'sp'
    isAutoSP = isSP && campaign.basic[0].targeting_type === 'auto'

    if (isSP
      && !settings.placement_active
      && campaign.basic[0].bidding
      && campaign.basic[0].bidding !== 'NULL') {
      try {
        let bidding = JSON.parse(campaign.basic[0].bidding)
        if (typeof bidding === 'string') {
          bidding = JSON.parse(bidding)
          if (bidding.adjustments) {
            hasPlacement = typeof bidding.adjustments.find(adjustment => (
              parseFloat(adjustment.percentage) > 0
            )) !== 'undefined'
          }
        }
      } catch (e) {
        //
      }
    }
  }

  return (
    <>
      {
        hasPlacement && (
          <div className="placement-warning">
            Placement Modifiers are in place, and you should turn on
            Placement Optimization in Smart Pilot for the best results.
          </div>
        )
      }
      <FieldRow>
        <FieldNumber
          label="Minimum Bid"
          settings={settings}
          field="min_bid_price"
          tooltip="The lowest possible bid price the smart pilot will drop the bid to for individual targets in this campaign/ad group."
          onChange={onChange}
        />
        <FieldNumber
          label="Maximum Bid"
          settings={settings}
          field="max_bid_price"
          tooltip="The highest possible bid price the smart pilot will raise the bid to for individual targets in this campaign/ad group."
          onChange={onChange}
        />
      </FieldRow>
      <OpKeywordEx
        settings={settings}
        isNonEndemicAccount={isNonEndemicAccount}
        onChange={onChange}
      />
      { ((isSP && !settings.adgroup_id) || fromTemplateEditor) &&
        <OpPlacement
          campaign={campaign}
          settings={settings}
          fromTemplateEditor={fromTemplateEditor}
          onChange={onChange}
        />
      }
      {
        !isAutoSP && !isNonEndemicAccount && (
          <OpKeywordIgnore settings={settings} onChange={onChange} />
        )
      }
    </>
  )
}

export default OpKeywordGeneral
