/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Select from 'react-select'

import { ReactComponent as CloneSvg } from '../../assets/svg/clone.svg'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import FieldRow from './FieldRow'
import Frequency from './Frequency'
import OpKeywordAdvancedAction from './OpKeywordAdvancedAction'
import CheckInput from './CheckInput'

const OpKeywordAdvancedSetting = (props) => {
  const {
    name,
    prefix,
    tooltip = null,
    settings,
    // `true` if a setting is an original one,
    // `false` if it's a cloned one.
    isOriginal = false,
    noGeniusBid = false,
    onChange,
    children,
  } = props

  const lookbackList = [
    { value: 7, label: 7 },
    { value: 14, label: 14 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ]

  const field = `${prefix}active`

  // For a copied setting, prefix all field names with `copy_`.
  const getFieldName = name => (
    isOriginal ? name : `copy_${name}`
  )

  const lookback = lookbackList.find(option => (
    option.value === settings[getFieldName(`${prefix}lookback`)]
  ))

  // Handle clicking on 'Clone'.
  const handleClone = (event) => {
    event.preventDefault()
    onChange(`copy_${field}`, true)
  }

  const handleLookbackChange = (item) => {
    onChange(getFieldName(`${prefix}lookback`), item.value)
  }

  return (
    <div className="keyword-advanced-setting">
      <div className="setting-header">
        <CheckboxComponent
          label={isOriginal ? name : `${name} (clone)`}
          labelClassName="checkbox-label"
          checked={settings[getFieldName(field)]}
          onChange={(checked) => { onChange(getFieldName(field), checked) }}
        />
        {
          isOriginal && tooltip !== null && (
            <CustomTooltip>
              { tooltip }
            </CustomTooltip>
          )
        }
        {
          isOriginal && !settings[`copy_${field}`] && (
            <a href="#" className="clone-button" onClick={handleClone}>
              <CloneSvg />
              Clone
            </a>
          )
        }
      </div>
      <div className="setting-body">
        <Frequency
          disabled={!settings[getFieldName(field)]}
          prefix={getFieldName(prefix)}
          settings={settings}
          onChange={onChange}
        />
        {
          React.Children.map(children, child => (
            React.cloneElement(child, {
              disabled: !settings[getFieldName(field)],
            })
          ))
        }
        <FieldRow disabled={!settings[getFieldName(field)]}>
          <CheckInput
            label="Target has at least this amount of spend"
            field={getFieldName(`${prefix}cost_active`)}
            inputField={getFieldName(`${prefix}cost_threshold`)}
            settings={settings}
            onChange={onChange}
          />
        </FieldRow>
        <FieldRow disabled={!settings[getFieldName(field)]}>
          <div className="field-wrapper">
            <div className="field-name">
              Lookback Period (days)
              <CustomTooltip>
                <p>The amount of historical days we'll use to make optimizations.</p>
                <p>By default, we add 3 days to this number for better data accuracy.</p>
                <p>For example: If you choose 30 days, we use data from 33 days ago
                up until 3 days ago.</p>
              </CustomTooltip>
            </div>
            <Select
              classNamePrefix="lookback-selector"
              options={lookbackList}
              placeholder="Choose period"
              value={lookback}
              onChange={handleLookbackChange}
            />
          </div>
          <OpKeywordAdvancedAction
            prefix={getFieldName(prefix)}
            settings={settings}
            noGeniusBid={noGeniusBid}
            onChange={onChange}
          />
        </FieldRow>
      </div>
    </div>
  )
}

export default OpKeywordAdvancedSetting
