import React from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import AdTypeSection from './AdTypeSection'

const Component = withReportSection(AdTypeSection, {
  sectionId: 'sponsored_brands',
  reportSectionKey: 'sponsoredBrandAds',
})

const SponsoredBrandsAds = ({
  settings,
  isLoadingSettings,
  onLoadStats,
  onSaveSettings,
}) => {
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sbChart = useSelector(state => state.accountHealth.sbChart)
  const sbPastMetrics = useSelector(state => state.accountHealth.sbPastMetrics)
  const sbPerformanceData = useSelector(state => state.accountHealth.sbPerformanceData)

  return (
    <Component
      title="Sponsored Brands"
      sectionKey="sponsoredBrandAds"
      noteKey="notes_sb"
      customMetricSection="sb"
      settings={settings}
      isLoadingSettings={isLoadingSettings}
      onSaveSettings={onSaveSettings}
      results={sbMetrics}
      pastResults={sbPastMetrics}
      charts={sbChart}
      matchTypeSummary={sbPerformanceData ? sbPerformanceData.matchTypeSummary : []}
      onLoadStats={onLoadStats}
    />
  )
}

export default SponsoredBrandsAds
