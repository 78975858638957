import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../CommonComponents/SortableTableComponent'
import TableCampaignCell from '../../CommonComponents/TableCampaignCell'
import ModalAddAdgroup from '../../CampaignDetailComponent/modals/ModalAddAdgroup'

import { getAdgroupsToAddTargets } from '../../../redux/actions/bulkEngine'

import { getAssociativeCampaigns, tableSorter } from '../../../services/helper'

const columns = [
  { key: 'campaign_name', name: 'Campaign' },
  { key: 'name', name: 'Ad Group' },
  { key: 'count', name: 'Number of Keywords/Targets', sortable: false },
]

const StepAdgroup = ({ selectedCampaigns, selectedAdgroups, forPtEx, onSelect }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const adgroupsToAddTargetsData = useSelector(state => state.bulkEngine.adgroupsToAddTargetsData)

  const [isGettingAdgroups, setIsGettingAdgroups] = useState(false)
  const [adgroupCreatorVisible, setAdgroupCreatorVisible] = useState(false)

  useEffect(() => {
    const payload = {}
    campaignsWithHistory.filter(campaign =>
      selectedCampaigns.includes(campaign.campaign_id.toString())
    ).forEach((campaign) => {
      if (!payload[campaign.campaignType]) {
        payload[campaign.campaignType] = []
      }
      payload[campaign.campaignType].push(campaign.campaign_id.toString())
    });

    (async () => {
      setIsGettingAdgroups(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAdgroupsToAddTargets(
        accessToken,
        payload,
        forPtEx ? true : false // This needs to be cast to boolean explicitly.
      ))
      setIsGettingAdgroups(false)
    })()
  }, []) // eslint-disable-line

  const adgroupList = useMemo(() => {
    const campaignsById = getAssociativeCampaigns(campaignsWithHistory)

    return (adgroupsToAddTargetsData || []).map((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}
      return {
        ...record,
        adgroup_id: record.adgroup_id.toString(),
        campaign_name: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targeting_type: campaignDetail.targetingType || '',
      }
    })
  }, [adgroupsToAddTargetsData]) // eslint-disable-line

  const handleAdgroupCreate = (adgroupId) => {
    setAdgroupCreatorVisible(false)
    onSelect([
      ...selectedAdgroups,
      adgroupId.toString(),
    ])
  }

  // For v3 SB campaigns, we cannot create a new ad group to an existing campaign.
  const eligibleCampaigns = useMemo(() => (
    campaignsWithHistory.filter(campaign =>
      selectedCampaigns.includes(campaign.campaign_id.toString())
      && (campaign.campaignType !== 'sb'
        || campaign.is_multi_adgroups_enabled)
    )
  ), [campaignsWithHistory, selectedCampaigns])

  const renderAction = () => {
    if (!eligibleCampaigns.length) {
      return null
    }

    return (
      <button
        type="button"
        className="btn btn-green"
        onClick={() => { setAdgroupCreatorVisible(true) }}
      >
        Create New Ad Group
      </button>
    )
  }

  const renderAdgroup = (adgroup) => (
    <>
      <TableCampaignCell
        record={adgroup}
        noLink
      />
      <div className="table-col col-adgroup">
        { adgroup.name }
      </div>
      <div className="table-col">
        { parseInt(adgroup.keywords, 10) + parseInt(adgroup.targets, 10) }
      </div>
    </>
  )

  return (
    <>
      <SortableTable
        columns={columns}
        defaultSort={['campaign_name', 'asc']}
        sorter={tableSorter(['campaign_name', 'name'])}
        className="table-adgroups"
        records={adgroupList}
        idField="adgroup_id"
        searchFields={['campaign_name', 'name']}
        selectedRecords={selectedAdgroups}
        noRecordText={ forPtEx ? 'No ad groups for product targeting found.' : 'No records found.'}
        isLoading={isGettingAdgroups}
        renderTopRight={renderAction}
        renderRecord={renderAdgroup}
        onChange={onSelect}
      />
      {
        adgroupCreatorVisible && (
          <ModalAddAdgroup
            campaigns={eligibleCampaigns}
            onCreate={handleAdgroupCreate}
            onCancel={() => { setAdgroupCreatorVisible(false) }}
          />
        )
      }
    </>
  )
}

export default StepAdgroup
