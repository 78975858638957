import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'
import { FiExternalLink } from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import CustomTooltip from '../CommonComponents/CustomTooltip'

import BulkResultContainer from '../BulkResultContainer'
import { MODULE_NAME_PT_EX } from './PtExSection'
import TargetAddModal from './TargetAddModal'

import {
  tableSorter,
  copyToClipboard,
  calcDerivedMetrics,
  getAmazonCategoryLink,
  getExportValueForColumn,
} from '../../services/helper'

import { bulkNTBColumnList, MODULE_NAME_PT_EX_CATEGORY } from '../../utils/defaultValues'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

const initialColumns = [
  { key: 'name', name: 'Category', className: 'col-category' },
  { key: 'associated', name: 'Associated ASINs', className: 'col-asin' },
]

export const FILTER_NAME_PT_EX_CATEGORY = 'ptExCategory'

const PtExCategoryResult = ({ ptExData, onChangeDate, onApplyFilter }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)

  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [targetsPayload, setTargetsPayload] = useState([])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [origFilters, setOrigFilters] = useState({})

  // Filter found search terms.
  useEffect(() => {
    if (!ptExData.length) {
      return
    }

    setCategories(ptExData.map(record => ({
      ...calcDerivedMetrics(record),
      associated: record.asins ? record.asins.length : 0,
    })))
  }, [ptExData]) // eslint-disable-line

  const handleCopy = () => {
    const categoryList = categories.filter(record => (
      selectedCategories.indexOf(record.id) !== -1
    )).map(record => record.name)

    copyToClipboard([...new Set(categoryList)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${categoryList.length} ${categoryList.length > 1 ? 'categories' : 'category'}.`
    })
  }

  const [columns, columnSelection] = useMemo(() => {
    let availableColumns = campaignTableColumns.filter(c1 =>
      bulkNTBColumnList.find(c2 => c2.key === c1.key)
    )

    if (isNonEndemicAccount) {
      availableColumns = availableColumns.filter(column => (
        ![
          'revenue',
          'acos',
          'orders',
          'conversion',
          'ntb_orders',
          'ntb_orders_percent',
          'ntb_sales',
          'ntb_sales_percent',
        ].includes(column.key)
      ))
    }

    return [
      [...initialColumns, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns, isNonEndemicAccount])

  const handleAddToExisting = () => {
    setTargetsPayload(categories.filter(record => (
      selectedCategories.indexOf(record.id) !== -1
    )).map(record => ({
      id: record.id,
      target: record.name,
      cpc: record.cpc,
    })))
    setIsAddModalVisible(true)
  }

  const handleFilterRefine = () => {
    setCurrentFilterName(FILTER_NAME_PT_EX_CATEGORY)
    setOrigFilters((filterValues || {})[FILTER_NAME_PT_EX_CATEGORY] || {})
  }

  const handleFilterApply = (values) => {
    // When acos values are changed, call API again.
    if (parseFloat(values.acosMin || 0) !== parseFloat(origFilters.acosMin || 0)
      || parseFloat(values.acosMax || 0) !== parseFloat(origFilters.acosMax || 0)) {
      onApplyFilter(values)
    }
    setCurrentFilterName('')
  }

  const handleFilterValidate = (values) => {
    if (isNonEndemicAccount) {
      return null
    }

    const { acosMin, acosMax } = values

    if (acosMin === '' || isNaN(acosMin) || parseFloat(acosMin) < 0
      || acosMax === '' || isNaN(acosMax) || parseFloat(acosMax) < 0) {
      return 'Please enter ACoS greater than or equal to 0.'
    }

    if (parseFloat(acosMin) > parseFloat(acosMax)) {
      return 'The start range of ACoS cannot be greater than the end range.'
    }

    return null
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFilterRefine}
          >
            Refine Filter
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedCategories.length) {
      return null
    }

    const selection = categories.filter(record => (
      selectedCategories.indexOf(record.id) !== -1
    )).map(record => ({
      id: record.id,
      name: record.name,
      path: record.path,
      parent: record.parent,
    }))

    return (
      <>
        <button type="button" className="btn btn-green" onClick={handleCopy}>
          Copy
        </button>
        <button type="button" className="btn btn-blue" onClick={handleAddToExisting}>
          Add to Existing Campaigns
        </button>
        <Dropdown
          title="Add to New Campaign"
          placement="bottomEnd"
          toggleClassName="btn-new"
        >
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sp',
            state: {
              categories: selection,
              productTargeting: true,
            },
          }}>
            Sponsored Product Campaign
          </Dropdown.Item>
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sb',
            state: {
              categories: selection,
              productTargeting: true,
            },
          }}>
            Sponsored Brand Campaign
          </Dropdown.Item>
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sd',
            state: {
              categories: selection,
            },
          }}>
            Sponsored Display Campaign
          </Dropdown.Item>
        </Dropdown>
      </>
    )
  }

  const renderCategory = record => (
    <>
      <div className="table-col col-category" title={record.name || ''}>
        { record.name || '' }
        <a
          href={getAmazonCategoryLink(currentAccount, record.id)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiExternalLink size={16} />
        </a>
      </div>
      <div className="table-col col-asin">
        { record.associated }
        {
          record.associated > 0 && (
            <CustomTooltip placement="right">
              <ul>
                {
                  record.asins.map(asin => (
                    <li key={asin}>{asin}</li>
                  ))
                }
              </ul>
            </CustomTooltip>
          )
        }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-category">Totals:</div>
      <div className="table-col col-asin" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'name') {
        return record.name || ''
      }
      if (column.key === 'associated') {
        return record.associated
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <BulkResultContainer>
      <div className="section-label">
        Select category and take appropriate action
      </div>
      { renderFilter() }
      <SortableTable
        columns={columns}
        defaultSort={['name', 'asc']}
        sorter={tableSorter(['name'])}
        className="table-categories"
        records={categories}
        idField="id"
        searchFields={['name']}
        selectedRecords={selectedCategories}
        hasSticky
        hasDateRange
        hasLifetimeRange
        filterName={FILTER_NAME_PT_EX_CATEGORY}
        useFilterModal
        columnEditorId="ptExCategoryResult"
        columnList={bulkNTBColumnList}
        columnSelection={columnSelection}
        exportFileName={MODULE_NAME_PT_EX_CATEGORY}
        getExportData={getExportData}
        renderRecord={renderCategory}
        renderTotal={renderTotal}
        renderTopRight={renderAction}
        onChange={setSelectedCategories}
        onChangeDate={onChangeDate}
        onFilterValidate={handleFilterValidate}
      />
      <TargetAddModal
        show={isAddModalVisible}
        targets={targetsPayload}
        forPtEx
        forCategory
        onClose={() => { setIsAddModalVisible(false) }}
      />
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={MODULE_NAME_PT_EX}
            onApply={handleFilterApply}
            onClose={() => { setCurrentFilterName('') }}
            onValidate={handleFilterValidate}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default PtExCategoryResult
