import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import { getSbBrands } from '../../../redux/actions/campaignCreator'

const SBBrandSelector = ({ onChange }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [brandData, setBrandData] = useState([])
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const data = await dispatch(getSbBrands(accessToken))
      setBrandData(data)
      setIsLoading(false)
    })()
  }, []) // eslint-disable-line

  const handleChange = (option) => {
    setSelected(option)
    onChange(option)
  }

  return (
    <Select
      isLoading={isLoading}
      options={brandData}
      getOptionValue={option => option.brandEntityId}
      getOptionLabel={option => option.brandRegistryName}
      value={selected}
      onChange={handleChange}
    />
  )
}

export default SBBrandSelector
