import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import { SP_BETA, SP_SETTINGS_REDIRECT_URI, LOGIN_CLIENT_ID } from '../../config/api'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import AccountSelector from './AccountSelector'

import {
  signupAdsCode,
  addAccounts,
  reauthorizeSp,
  reauthorizeAds,
} from '../../redux/actions/auth'

import {
  regionList,
  STORAGE_KEY_REGION,
  STORAGE_KEY_STATE,
  STORAGE_KEY_ORIGIN,
} from '../../utils/defaultValues'
import {getAmazonScopeList} from '../../utils/amazon'

const SectionAdd = ({ expanded, onClose }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { getAccessTokenSilently } = useAuth0()

  const isADCodeGetting = useSelector(state => state.auth.isADCodeGetting)
  const adRefreshToken = useSelector(state => state.auth.adRefreshToken)
  const profileList = useSelector(state => state.auth.profileList)
  const accountList = useSelector(state => state.header.accountList)
  const isReauthSp = useSelector(state => state.header.isReauthSp)

  const [region, setRegion] = useState(regionList[0])
  const [selectedProfileIds, setSelectedProfileIds] = useState([])
  const [isAdding, setIsAdding] = useState(false)
  const [coupon, setCoupon] = useState('');

  // Inject Amazon LWA script.
  useEffect(() => {
    let amazonRoot = document.getElementById('amazon-root')
    if (!amazonRoot) {
      amazonRoot = document.createElement('div')
      amazonRoot.setAttribute('id', 'amazon-root')
      document.body.appendChild(amazonRoot)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        window.onAmazonLoginReady = function() {
          amazon.Login.setClientId('${LOGIN_CLIENT_ID}');
        };
        (function(d) {
          var a = d.createElement('script'); a.type = 'text/javascript';
          a.async = true; a.id = 'amazon-login-sdk';
          a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
          d.getElementById('amazon-root').appendChild(a);
        })(document);
      `
      document.body.appendChild(script)
    }

    const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
    if (savedRegion) {
      const saved = regionList.find(option => option.value === savedRegion)
      if (saved) {
        setRegion(saved)
      }
    }
  }, [])

  // Respond to Amazon returns.
  useEffect(() => {
    const qs = new URLSearchParams(location.search)
    const callbackUrl = qs.get('amazon_callback_uri')
    const spCode = qs.get('spapi_oauth_code')
    const code = qs.get('code')

    if (callbackUrl) {
      // After authorizing our app, users are redirected to another page
      // to get the LWA (Login with Amazon) code.
      const amazonState = qs.get('amazon_state')
      const state = (new Date()).valueOf()
      // Save state to sessionStorage for later validation.
      window.sessionStorage.setItem(STORAGE_KEY_STATE, state)
      let url = `${callbackUrl}?redirect_uri=${encodeURI(SP_SETTINGS_REDIRECT_URI)}`
        + `&amazon_state=${amazonState}&state=${state}`
      if (SP_BETA) {
        url = `${url}&version=beta`
      }
      window.location.href = url
    } else if (spCode && !isReauthSp) {
      window.sessionStorage.removeItem(STORAGE_KEY_STATE);

      (async () => {
        const accessToken = await getAccessTokenSilently()
        dispatch(reauthorizeSp(
          accessToken,
          parseInt(window.sessionStorage.getItem(STORAGE_KEY_ORIGIN), 10),
          spCode
        )).catch((error) => {
          toast.show({
            title: 'Danger',
            description: error,
          })
        })
      })()
    } else if (code) {
      const state = qs.get('state')
      const savedState = window.sessionStorage.getItem(STORAGE_KEY_STATE)
      if (state === savedState && !isADCodeGetting) {
        window.sessionStorage.removeItem(STORAGE_KEY_STATE)

        const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
        dispatch(signupAdsCode(code, SP_SETTINGS_REDIRECT_URI, savedRegion || region.value))
        .then((response) => {
          const origin = window.sessionStorage.getItem(STORAGE_KEY_ORIGIN) || ''
          if (origin.indexOf('re_authorize') === 0) {
            (async () => {
              const accessToken = await getAccessTokenSilently()
              dispatch(reauthorizeAds(
                accessToken,
                parseInt(origin.substring('re_authorize'.length), 10),
                response.refreshToken,
              ))
            })()
          }
        }).catch((error) => {
          toast.show({
            title: 'Danger',
            description: error,
          })
        })
      }
    }
  }, []) // eslint-disable-line

  const initiateLWA = () => {
    const state = (new Date()).valueOf()
    // Save state to sessionStorage for later validation.
    window.sessionStorage.setItem(STORAGE_KEY_STATE, state)

    window.amazon.Login.authorize({
      scope: getAmazonScopeList(),
      response_type: 'code',
      popup: false,
      state,
    }, SP_SETTINGS_REDIRECT_URI)
  }

  const handleClickGrantAccess = () => {
    window.sessionStorage.setItem(STORAGE_KEY_ORIGIN, 'add_account')

    if (typeof window.amazon === 'undefined') {
      setTimeout(() => {
        if (typeof window.amazon === 'undefined') {
          toast.show({
            title: 'Danger',
            description: 'The Amazon script is not loaded.',
            duration: 20000,
          })
          return
        }
        initiateLWA()
      }, 5000)
      return
    }
    initiateLWA()
  }

  const handleAdd = async () => {
    const profiles = []
    profileList.forEach((profile) => {
      if (selectedProfileIds.indexOf(profile.profileId) === -1) {
        return
      }
      profiles.push({
        profileId: profile.profileId,
        countryCode: profile.countryCode.toLowerCase(),
        sellerStringId: profile.accountInfo.sellerStringId,
        brandEntityId: profile.accountInfo.brandEntityId,
        brandName: profile.accountInfo.brandName,
        type: profile.accountInfo.type.toLowerCase(),
        subType: (profile.accountInfo.subType || '').toLowerCase(),
      })
    })

    if (!profiles.length) {
      toast.show({
        title: 'Danger',
        description: 'Please select accounts.',
      })
      return
    }

    window.sessionStorage.removeItem(STORAGE_KEY_REGION)

    setIsAdding(true)

    const accessToken = await getAccessTokenSilently()
    dispatch(addAccounts(accessToken, {
      adRefreshToken,
      couponCode: coupon,
      profiles,
    })).then(() => {
      setIsAdding(false)
      toast.show({
        title: 'Success',
        description: 'Additional accounts successfully added! Please allow up to 24 hours to collect data.',
      })
      onClose()
    }).catch((error) => {
      setIsAdding(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const handleRegionChange = (option) => {
    setRegion(option)
    window.sessionStorage.setItem(STORAGE_KEY_REGION, option.value)
  }

  const renderRegionSelector = () => {
    return (
      <div className="region-selector">
        <label>
          Choose Region
        </label>
        <Select
          className="region-list"
          classNamePrefix="region-list"
          isSearchable={false}
          value={region}
          options={regionList}
          onChange={handleRegionChange}
        />
        <button
          type="button"
          className="btn btn-blue"
          onClick={handleClickGrantAccess}
        >
          Grant Access
        </button>
      </div>
    )
  }

  return (
    <Modal className="settings-account-add-modal" backdrop="static" size="lg" show={expanded}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Add seller, vendor or DSP accounts
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { (isADCodeGetting || isReauthSp || isAdding) && <LoaderComponent /> }
        { renderRegionSelector() }
        {
          adRefreshToken && (
            <>
              <AccountSelector
                profileList={profileList}
                accountList={accountList}
                selectedProfileIds={selectedProfileIds}
                onSelect={setSelectedProfileIds}
              />
              <div className="coupon-container">
                <label>
                  Coupon code (optional)
                </label>
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  value={coupon}
                  onChange={(event) => { setCoupon(event.target.value) }}
                />
              </div>
            </>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        {
          adRefreshToken && (
            <button
              type="button"
              className="rs-btn rs-btn-primary"
              disabled={(selectedProfileIds.length === 0) || isAdding}
              onClick={handleAdd}
            >
              Add Accounts
            </button>
          )
        }
        <button
          type="button"
          className="rs-btn rs-btn-default"
          onClick={onClose}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SectionAdd
