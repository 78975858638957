import React from 'react'

import SkuSelector from '../CommonComponents/SkuSelector'
import CustomTooltip from '../CommonComponents/CustomTooltip'

const ExSkus = ({ selectedSkus, disabled, onChange }) => {
  return (
    <div className={`step-wrapper ${disabled ? 'disabled' : ''}`}>
      <div className="step-desc">
        <strong>Step 2)</strong> Select SKU's so we can find the right campaigns to pull from.
        Then click on Load Campaigns below.
        <CustomTooltip>
          <p>In this step, you are selecting which products and campaigns
          you’d like the system to pull data from in order to search
          for potential products to target with your advertising.</p>
          <p>Select a product, and then relevant campaigns in which
          that product appears will become available for selection as well.</p>
        </CustomTooltip>
      </div>
      <SkuSelector
        selectedSkus={selectedSkus}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}

export default ExSkus
