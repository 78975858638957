import { callGet, callPost } from '../../services/axios'

export const loadFilters = (accessToken, type, signal) => () => {
  return callGet('/filter/load', accessToken, {
    type,
  }, signal).then(res => res.data)
}

export const saveFilter = (accessToken, type, name, value) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/filter/save', {
    userId: currentUserId,
    type,
    name,
    value,
  }, accessToken).then(res => res.data)
}

export const deleteFilter = (accessToken, id) => () => {
  return callPost('/filter/delete', {
    id,
  }, accessToken)
}

export const updateFilter = (accessToken, filterId, name, value) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/filter/update', {
    userId: currentUserId,
    filterId,
    name,
    value,
  }, accessToken)
}
