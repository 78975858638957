import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <LoaderComponent />
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: '/dashboard',
      },
    })
    return
  }

  return (
    <Redirect
      to='/dashboard'
    />
  )
}

export default LoginPage
