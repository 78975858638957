import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiExternalLink } from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportTableWidget from './ReportTableWidget'
import ReportNoteWidget from './ReportNoteWidget'

import { getTargetsReport } from '../../redux/actions/accountHealth'

import {
  formatCurrency,
  formatValue,
  getAmazonCategoryLink,
  getAmazonAsinLink,
  parseTargetExp
} from '../../services/helper'
import { selectCurrentAccount } from '../../redux/reducers/header'

const columns = [
  { key: 'target', name: 'Target', sortable: false},
  { key: 'impressions', name: 'Impressions', sortable: false},
  { key: 'clicks', name: 'Clicks', sortable: false},
  { key: 'orders', name: 'Orders', sortable: false},
  { key: 'cost', name: 'Spend', sortable: false },
  { key: 'sales', name: 'Sales', sortable: false },
  { key: 'acos', name: 'ACoS', sortable: false},
  { key: 'ntbOrder', name: 'NTB Orders', sortable: false},
  { key: 'ntb_sales', name: 'NTB Sales', sortable: false},
  { key: 'ntb_units', name: 'NTB Units', sortable: false},
  { key: 'roas', name: 'ROAS', sortable: false},
]

const TargetSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [targetsReport, setTargetsReport] = useState({})
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')

  useEffect(() => {
    const abortCtrl = new AbortController();
    (async () => {
      if (showSection && !isLoaded) {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const data = await dispatch(getTargetsReport(accessToken, abortCtrl.signal))
          setTargetsReport(data)
          setIsLoading(false)
          setIsLoaded(true)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      }
    })()
    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, showSection, startDate, endDate]) // eslint-disable-line

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_target || '')
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const targets = reportWidgetSetting ? reportWidgetSetting.targets : []
    setViewWidgets(
      targets.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_target',
      sectionValue: note,
    })
  }

  const renderRecord = (record) => {
    const target = parseTargetExp(record.target_text)
    let link
    if (target.indexOf('asin=') === 0) {
      try {
        const parsed = JSON.parse(record.target_text)
        link = (
          <a
            href={getAmazonAsinLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiExternalLink size={16} />
          </a>
        )
      } catch (e) {}
    } else if (target.indexOf('category=') === 0) {
      try {
        const parsed = JSON.parse(record.target_exp)
        link = (
          <a
            href={getAmazonCategoryLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiExternalLink size={16} />
          </a>
        )
      } catch (e) {}
    }

    return (
      <>
        <div className="table-col col-target">
          <div className="target-text-wrapper">
            <strong>
              { target }
            </strong>
            { link }
          </div>
        </div>
        <div className="table-col">
          {record.impressions !== 0 ? formatValue(record.impressions, 'number', 0) : '-'}
        </div>
        <div className="table-col">
          {record.clicks !== 0 ? formatValue(record.clicks, 'number', 0) : '-'}
        </div>
        <div className="table-col">
          {record.orders !== 0 ? formatValue(record.orders, 'number', 0) : '-'}
        </div>
        <div className="table-col">
          { formatCurrency(record.cost, currencySign, currencyRate) }
        </div>
        <div className="table-col">
          { formatCurrency(record.revenue, currencySign, currencyRate) }
        </div>
        <div className="table-col">
          { record.revenue > 0 ? formatValue(record.cost / record.revenue * 100, 'percent', 2) : '-' }
        </div>
        <div className="table-col">
          { record.ntb_orders > 0 ? formatValue(record.ntb_orders, 'number', 0) : '-' }
        </div>
        <div className="table-col">
          { record.ntb_sales > 0 ? formatCurrency(record.ntb_sales, currencySign, currencyRate) : '-' }
        </div>
        <div className="table-col">
          { record.ntb_units > 0 ? formatValue(record.ntb_units, 'number', 0) : '-' }
        </div>
        <div className="table-col">
          { record.cost > 0 ? formatValue(record.revenue / record.cost, 'number', 2) : '-' }
        </div>
      </>
    )
  }

  const renderTables = () => {
    if (!viewWidgets.length) {
      return null
    }

    const metrics = ['revenue', 'profitable', 'expensive']

    return viewWidgets.map((viewWidget, index) => {
      if (!viewWidget.view && !customizingSection) {
        return null
      }
      return (
        <ReportTableWidget
          key={metrics[index]}
          widget={viewWidget}
          columns={columns}
          records={(targetsReport || {})[metrics[index]] || []}
          customizing={customizingSection}
          idField="target_id"
          renderRecord={renderRecord}
          isLoading={isLoading}
          onRemove={handleOnRemoveWidget}
          section="targets"
        />
      )
    })
  }

  return (
    <>
      <SectionHeader
        title="Your Targets"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            {
              (customizingSection || noteWidgetView) && (
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section="targets"
                />
              )
            }
            { renderTables() }
          </>
        )
      }
    </>
  )
}

export default withReportSection(TargetSection, {
  sectionId: 'targets',
  reportSectionKey: 'targets',
})
