import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from 'rsuite'

import LoaderComponent from '../CommonComponents/LoaderComponent'

import { getSbBrandLogos } from '../../redux/actions/campaignCreator'

const SBBrandLogoModal = ({ onSelect, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [logos, setLogos] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSbBrandLogos(
        accessToken,
        abortCtrl.signal,
      ))
      setLogos(response)
      setIsLoading(false)
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const renderLogo = logo => (
    <div key={logo.assetId} className="logo-wrapper">
      <div className="logo-contents">
        <img src={logo.url} alt={logo.name} />
        <div className="logo-info">
          <div className="logo-name">{ logo.name }</div>
          <div className="logo-dimension">
            { logo.width } x { logo.height }px
          </div>
        </div>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => onSelect(logo)}
        >
          Select
        </button>
      </div>
    </div>
  )

  return (
    <Modal className={`sb-brand-logo-modal${isLoading ? ' loading' : ''}`} backdrop="static" show size="lg">
      <Modal.Body>
        { isLoading && <LoaderComponent /> }
        <div className="logo-list">
          { logos.map(renderLogo) }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SBBrandLogoModal
