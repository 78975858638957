import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import CustomTable from '../../../../../CommonComponents/CustomTableComponent'
import { toast } from '../../../../../CommonComponents/ToastComponent/toast'
import ModalAddNegatives from '../../../../modals/ModalAddNegatives'

import { formatValue, copyToClipboard } from '../../../../../../services/helper'

const columns = [
  { key: 'search', name: 'Search Term' },
  { key: 'orders', name: 'Orders' },
  { key: 'clicks', name: 'Clicks' },
]

const NoSaleTable = ({ campaignType, currentAdGroupId, hideNegatedTerms }) => {
  const stData = useSelector(state => state.campaignDetail.stData)
  const negativeKWData = useSelector(state => state.campaignDetail.negativeKWData)

  const [searchTerms, setSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [showAddNegativesModal, setShowAddNegativesModal] = useState(false)

  // Filter found search terms.
  useEffect(() => {
    if (!stData || !stData.length) {
      return
    }

    let filteredSTs = []
    if (!currentAdGroupId) {
      filteredSTs = stData[0]
    } else {
      filteredSTs = stData[1].filter(st =>
        st.adgroup_id.toString() === currentAdGroupId.toString()
      )
    }

    // New terms only.
    if (hideNegatedTerms) {
      if (negativeKWData && negativeKWData.length) {
        filteredSTs = filteredSTs.filter(st =>
          !negativeKWData.find(
            negative => negative.keyword_text.trim() === st.search.trim()
          )
        )
      }
    }

    filteredSTs = filteredSTs.filter(st =>
      parseInt(st.clicks, 10) >= 5 && parseInt(st.orders, 10) === 0
    )

    filteredSTs = filteredSTs.map(st => ({
      ...st,
      clicks: formatValue(st.clicks, 'removeZeroDecimal'),
      orders: formatValue(st.orders, 'removeZeroDecimal'),
    }))

    setSearchTerms(filteredSTs)
  }, [stData, currentAdGroupId, negativeKWData, hideNegatedTerms])

  const handleCopy = () => {
    const sts = searchTerms.filter(st => (
      selectedSearchTerms.indexOf(st.id) !== -1
    )).map(st => st.search.trim())

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedSearchTerms.length} search term${selectedSearchTerms.length > 1 ? 's' : ''}.`
    })
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setShowAddNegativesModal(true) }}
        >
          Add negative{selectedSearchTerms.length > 1 ? 's' : ''} to campaign
        </button>
        <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
          Copy
        </button>
      </>
    )
  }

  const renderST = st => (
    <>
      <div className="table-col col-search-term">
        { st.search }
      </div>
      <div className="table-col">
        { st.orders }
      </div>
      <div className="table-col">
        { st.clicks }
      </div>
    </>
  )

  const selectedSTs = searchTerms.filter(st => (
    selectedSearchTerms.indexOf(st.id) !== -1
  ))

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return record[column.key] || '';
    })
  )

  return (
    <div className="table-container">
      <div className="table-title">
        5 Clicks No Sales
      </div>
      <CustomTable
        className="table-search-terms"
        records={searchTerms}
        idField="id"
        searchFields={['search']}
        selectedRecords={selectedSearchTerms}
        hasDateRange
        columns={columns}
        exportFileName="NoSale in CampaignDetailSearchTerm"
        getExportData={getExportData}
        renderRecord={renderST}
        renderTopRight={renderAction}
        onChange={setSelectedSearchTerms}
      >
        <div className="table-col col-search-term">Search Term</div>
        <div className="table-col">Orders</div>
        <div className="table-col">Clicks</div>
      </CustomTable>
      <ModalAddNegatives
        showModal={showAddNegativesModal}
        terms={selectedSTs}
        modalType="st"
        campaignType={campaignType}
        currentAdGroupId={currentAdGroupId}
        onClose={() => { setShowAddNegativesModal(false) }}
      />
    </div>
  )
}

export default NoSaleTable
