import React  from 'react'

import OpKeywordSale from './OpKeywordSale'
import OpKeywordCtr from './OpKeywordCtr'
import OpKeywordConvert from './OpKeywordConvert'
import OpKeywordAcos from './OpKeywordAcos'
import OpKeywordProfit from './OpKeywordProfit'

const OpKeywordAdvanced = (props) => (
  <>
    <OpKeywordProfit
      name="ACoS Range (Genius Bid)"
      prefix="op_adv_genius_bid_"
      {...props}
    />
    <OpKeywordAcos
      name="High ACoS Targets"
      prefix="op_adv_high_acos_"
      {...props}
    />
    <OpKeywordSale
      name="Zero Sale Targets"
      prefix="op_adv_zero_sale_"
      {...props}
    />
    <OpKeywordConvert
      name="Low Converting Targets"
      prefix="op_adv_low_conversion_"
      {...props}
    />
    <OpKeywordCtr
      name="Low CTR Targets"
      prefix="op_adv_low_ctr_"
      {...props}
    />
  </>
)
export default OpKeywordAdvanced
