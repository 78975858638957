import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Nav } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import MainLayout from '../../layout/MainLayout'
import ReportHeader from './ReportHeader'
import AccountOverview from './AccountOverview'
import ReportSideNav from './ReportSideNav'
import AllSponsoredAds from './AllSponsoredAds'
import SponsoredProductsAds from './SponsoredProductsAds'
import SponsoredBrandsAds from './SponsoredBrandsAds'
import SponsoredDisplayAds from './SponsoredDisplayAds'
import BrandGrowthSection from './BrandGrowthSection'
import BudgetUtilizationSection from './BudgetUtilizationSection'
import HourlyDataSection from './HourlyDataSection'
import PortfolioSection from './PortfolioSection'
import TagSection from './TagSection'
import CampaignSection from './CampaignSection'
import ProductSection from './ProductSection'
import KeywordSection from './KeywordSection'
import TargetSection from './TargetSection'
import PerformanceByAdType from './PerformanceByAdType'
import PerformanceByPlacement from './PerformanceByPlacement'
import PerformanceByBidType from './PerformanceByBidType'
import PerformanceByMatchType from './PerformanceByMatchType'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

import {
  getSettings,
  getReportSalesStats,
  getPerformanceData,
  saveSettings,
  saveBrandLogo,
  deleteBrandLogo,
  updateBrandTerms,
  getReportStats,
} from '../../redux/actions/accountHealth'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const AccountReportPage = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [isLoadingSettings, setIsLoadingSettings] = useState(false)
  const [settings, setSettings] = useState({})
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const loadSettings = async (accessToken, signal) => {
    setIsLoadingSettings(true)
    const response = await dispatch(getSettings(accessToken, signal))
    setSettings(response)
    setIsLoadingSettings(false)
  }

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoadingData(true)
      const accessToken = await getAccessTokenSilently()
      await Promise.all([
        loadSettings(accessToken, abortCtrl.signal),
        !isNonEndemicAccount
          ? dispatch(getReportSalesStats(
            accessToken,
            abortCtrl.signal
          ))
          : Promise.resolve(),
        !isNonEndemicAccount
          ? dispatch(getPerformanceData(
            accessToken,
            '',
            abortCtrl.signal
          ))
          : Promise.resolve(),
      ])
      setIsLoadingData(false)
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, startDate, endDate, isNonEndemicAccount]) // eslint-disable-line

  const handleSaveSettings = async (payload) => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(saveSettings(accessToken, payload))
    setSettings(prev => ({
      ...prev,
      ...response,
    }))
    setIsSaving(false)
  }

  const handleSaveLogo = async (logoBase64String, extension) => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(saveBrandLogo(accessToken, logoBase64String, extension))
    setSettings(prev => ({
      ...prev,
      ...response,
    }))
    setIsSaving(false)
  }

  const handleDeleteLogo = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(deleteBrandLogo(accessToken))
    setSettings(prev => ({
      ...prev,
      logo_url: '',
    }))
    setIsSaving(false)
  }

  const handleUpdateBrandTerms = async (brandTerms) => {
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateBrandTerms(accessToken, brandTerms))
    setSettings(prev => ({
      ...prev,
      brand_terms: response,
    }))
  }

  const handleLoadStats = async (
    sectionKey,
    signal = undefined,
  ) => {
    setIsLoadingData(true)
    const accessToken = await getAccessTokenSilently()
    if (sectionKey === 'allSponsoredAds') {
      await dispatch(getReportStats(accessToken, '', signal))
    } else if (sectionKey === 'sponsoredProductAds') {
      await Promise.all([
        dispatch(getReportStats(accessToken, 'sp', signal)),
        dispatch(getPerformanceData(accessToken, 'sp', signal)),
      ])
    } else if (sectionKey === 'sponsoredBrandAds') {
      await Promise.all([
        dispatch(getReportStats(accessToken, 'sb', signal)),
        dispatch(getPerformanceData(accessToken, 'sb', signal)),
      ])
    } else if (sectionKey === 'sponsoredDisplayAds') {
      await dispatch(getReportStats(accessToken, 'sd', signal))
    } else if (sectionKey === 'all') {
      await Promise.all([
        dispatch(getReportStats(accessToken, '', signal)),
        dispatch(getReportStats(accessToken, 'sp', signal)),
        dispatch(getReportStats(accessToken, 'sb', signal)),
        dispatch(getReportStats(accessToken, 'sd', signal)),
      ])
    }
    setIsLoadingData(false)
  }

  const isLoading = isLoadingSettings
    || isLoadingData
    || isSaving

  return (
    <MainLayout>
      <div className={`account-health-page${isLoading ? ' loading' : ''}`}>
        { isLoading && <LoaderComponent/>}
        <div className="page-header">
          <Nav appearance="subtle" activeKey="health">
            <Nav.Item eventKey='health'>Account Reports</Nav.Item>
            <Nav.Item componentClass={Link} to="/agency-view">Agency View</Nav.Item>
            <Nav.Item componentClass={Link} to="/audit-report">Audit Report</Nav.Item>
          </Nav>
        </div>
        <div className="page-content">
          <div className="report-content">
            <ReportHeader
              settings={settings}
              onLoadStats={handleLoadStats}
              onSaveLogo={handleSaveLogo}
              onDeleteLogo={handleDeleteLogo}
              onUpdateBrandTerms={handleUpdateBrandTerms}
            />
            <AccountOverview
              settings={settings}
              isLoadingSettings={isLoadingSettings}
              onSaveSettings={handleSaveSettings}
            />
            {
              !isNonEndemicAccount && (
                <>
                  <AllSponsoredAds
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onLoadStats={handleLoadStats}
                    onSaveSettings={handleSaveSettings}
                  />
                  <SponsoredProductsAds
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onLoadStats={handleLoadStats}
                    onSaveSettings={handleSaveSettings}
                  />
                  <SponsoredBrandsAds
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onLoadStats={handleLoadStats}
                    onSaveSettings={handleSaveSettings}
                  />
                </>
              )
            }
            <SponsoredDisplayAds
              settings={settings}
              isLoadingSettings={isLoadingSettings}
              onLoadStats={handleLoadStats}
              onSaveSettings={handleSaveSettings}
            />
            {
              !isNonEndemicAccount && (
                <>
                  <BrandGrowthSection
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onLoadStats={handleLoadStats}
                    onSaveSettings={handleSaveSettings}
                  />
                  <BudgetUtilizationSection
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onSaveSettings={handleSaveSettings}
                  />
                </>
              )
            }
            <HourlyDataSection />
            {
              !isNonEndemicAccount && (
                <PortfolioSection
                  settings={settings}
                  isLoadingSettings={isLoadingSettings}
                  onSaveSettings={handleSaveSettings}
                />
              )
            }
            <TagSection
              settings={settings}
              isLoadingSettings={isLoadingSettings}
              onSaveSettings={handleSaveSettings}
            />
            <CampaignSection
              settings={settings}
              isLoadingSettings={isLoadingSettings}
              onSaveSettings={handleSaveSettings}
            />
            {
              !isNonEndemicAccount && (
                <>
                  <ProductSection
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onSaveSettings={handleSaveSettings}
                  />
                  <KeywordSection
                    settings={settings}
                    isLoadingSettings={isLoadingSettings}
                    onSaveSettings={handleSaveSettings}
                  />
                </>
              )
            }
            <TargetSection
              settings={settings}
              isLoadingSettings={isLoadingSettings}
              onSaveSettings={handleSaveSettings}
            />
            {
              !isNonEndemicAccount && (
                <>
                  <PerformanceByAdType />
                  <PerformanceByPlacement />
                  <PerformanceByBidType />
                  <PerformanceByMatchType />
                </>
              )
            }
          </div>
          <ReportSideNav isNonEndemicAccount={isNonEndemicAccount} />
        </div>
      </div>
    </MainLayout>
  )
}

export default AccountReportPage
