import React from 'react'

import TableFilterShower from '../CommonComponents/TableFilterShower'
import SearchBox from '../CommonComponents/SearchBox'
import AdTypeMultiSelector from '../CommonComponents/AdTypeMultiSelector'
import PortfolioSelector from '../CommonComponents/PortfolioSelector'
import TagSelector from '../CommonComponents/TagSelector'
import { CAMPAIGN_TABLE_FILTER } from '../../utils/defaultValues'

const ActionBar = ({
  searchKey,
  searchInclusive = true,
  portfolioSelectionInclusive = true,
  tagSelectionInclusive = true,
  selectedCampaigns,
  dataToExport,
  customActionBar,
  hasExpanded = false,
  fromBulkEngine,
  localAdTypeFilter,
  onChangeSearch,
  onPortfolioSelect,
  onTagSelect,
  onViewCreativeAndDetail,
  onViewMetricsChart,
  onClearView,
  onDeselectFromModal,
  onChangeViewMethod,
  onChangeSearchInclusive,
  onChangePortfolioSelectionInclusive,
  onChangeTagSelectionInclusive,
  onChangeAdTypeFilter,
}) => {
  const handlePortfolioSelect = (options) => {
    onPortfolioSelect(options.map(opt => parseInt(opt.value, 10)))
  }

  const handleTagsSelect = (options) => {
    onTagSelect(options.map(opt => parseInt(opt.value, 10)))
  }

  const CustomActionBar = customActionBar

  return (
    <div className="table-header">
      <div className="table-header-left">
        <div className="table-header-left-inner">
          <SearchBox
            keyword={searchKey}
            style={{ width: 190 }}
            exclusable
            searchInclusive={searchInclusive}
            onChangeSearchInclusive={onChangeSearchInclusive}
            onChange={onChangeSearch}
          />
          <AdTypeMultiSelector
            isShared={!fromBulkEngine}
            className="ad-type-select"
            classNamePrefix="ad-type-select"
            defaultValue={localAdTypeFilter}
            onChange={onChangeAdTypeFilter}
          />
          <PortfolioSelector
            classNamePrefix="portfolio-select"
            exclusable
            inclusive={portfolioSelectionInclusive}
            onChange={handlePortfolioSelect}
            onChangeInclusive={onChangePortfolioSelectionInclusive}
          />
          <TagSelector
            classNamePrefix="tags-select"
            exclusable
            inclusive={tagSelectionInclusive}
            selectedCampaignIds={(selectedCampaigns || []).map(c => c.campaign_id.toString())}
            onChange={handleTagsSelect}
            onChangeInclusive={onChangeTagSelectionInclusive}
          />
          {
            CustomActionBar && (
              <CustomActionBar
                selectedCampaigns={selectedCampaigns}
                dataToExport={dataToExport}
                hasExpanded={hasExpanded}
                onViewCreativeAndDetail={onViewCreativeAndDetail}
                onViewMetricsChart={onViewMetricsChart}
                onClearView={onClearView}
                onDeselectFromModal={onDeselectFromModal}
                onChangeViewMethod={onChangeViewMethod}
              />
            )
          }
        </div>
        <TableFilterShower
          filterName={CAMPAIGN_TABLE_FILTER}
          noTitle
        />
      </div>
    </div>
  )
}

export default ActionBar
