import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import rootReducer from './reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        'activityLog',
        'ap',
        'bulkEngine',
        'campaign',
        'campaignCreator',
        'campaignDetail',
        'productDetail',
        'tag',
        'accountHealth',
        'ruleManager',
        'dsp',
        'job',
    ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
)

// Used to create the persisted store, persistor will be used in the next step
const  persistor = persistStore(store)

export {
    store,
    persistor,
}
