import React, { useState, useEffect } from 'react'
import { Modal } from 'rsuite'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { toast } from '../../CommonComponents/ToastComponent/toast'

const CropImageModal = ({ show, cropInfo, onSelect, onClose }) => {
  const { src, type, cropImage } = cropInfo

  const [crop, setCrop] = useState({ unit: '%', width: 30 })
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (type === 'logo' || type === 'sbLogo') {
      setCrop({ unit: '%', width: 30 })
    } else if (type === 'custom') {
      setCrop({ aspect: 1200 / 628 })
    } else {
      setCrop({ aspect: 1 })
    }
    setImage(cropImage)
  }, [cropInfo]) // eslint-disable-line

  const handleConfirm = () => {
    const canvas = document.createElement('canvas')
    const imageCrop = crop
    if (crop.width === 0 || crop.height === 0) {
      imageCrop.x = 0
      imageCrop.y = 0
      imageCrop.width = image.width
      imageCrop.height = image.height
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = imageCrop.width
    canvas.height = imageCrop.height

    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    const croppingCoordinates = {
      left: Math.round(crop.x * scaleX),
      top: Math.round(crop.y * scaleY),
      width: Math.round(crop.width * scaleX),
      height: Math.round(crop.height * scaleY),
    }

    if (
      (croppingCoordinates.width < 600 || croppingCoordinates.height < 100)
      && type === 'logo') {
      toast.show({
        title: 'Warning',
        description: 'Dimensions must be at least 600 x 100 px.',
      })
      return
    }

    if (
      (croppingCoordinates.width < 1200 || croppingCoordinates.height < 628)
      && type === 'custom') {
      toast.show({
        title: 'Warning',
        description: 'Dimensions must be at least 1200 x 628 px.',
      })
      return
    }

    if (
      (croppingCoordinates.width < 628 || croppingCoordinates.height < 628)
      && type === 'customSquare') {
      toast.show({
        title: 'Warning',
        description: 'Crop Image dimensions must be at least 628 x 628 px.',
      })
      return
    }

    if (
      (croppingCoordinates.width < 400 || croppingCoordinates.height < 400)
      && type === 'sbLogo') {
      toast.show({
        title: 'Warning',
        description: 'Dimensions must be at least 400 x 400 px.',
      })
      return
    }

    canvas.width = imageCrop.width * pixelRatio
    canvas.height = imageCrop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      imageCrop.x * scaleX,
      imageCrop.y * scaleY,
      imageCrop.width * scaleX,
      imageCrop.height * scaleY,
      0,
      0,
      imageCrop.width,
      imageCrop.height,
    )

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg')
    onSelect(base64Image, croppingCoordinates, type, image)
  }

  return (
    <Modal backdrop="static" show={show} size="sm">
      <Modal.Body>
        <div className="crop-field-wrapper">
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={setImage}
            onChange={setCrop}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropImageModal
