import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'rsuite'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import NegativeCreatorModal from '../BulkOp/NegativeCreatorModal'

import { tableSorter } from '../../services/helper'

import { bulkSTColumnList } from '../../utils/defaultValues'

const columns = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroup_name', name: 'Ad Group', className: 'col-adgroup' },
  { key: 'target', name: 'Associated Target' },
  { key: 'matchType', name: 'Match Type' },
  ...bulkSTColumnList,
]

const DupSTModal = ({ duplicatedSearchTerm, onClose }) => {
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)

  const [searchTerms, setSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [negativesToAdd, setNegativesToAdd] = useState([])

  useEffect(() => {
    setSearchTerms(duplicatedSearchTerm.children.map(record => ({
      ...record,
      id: `${record.campaign_id}-${record.adgroup_id}-${record.target}-${record.match_type}`,
    })))
  }, [duplicatedSearchTerm])

  const handleAddNegative = () => {
    const sts = searchTerms.filter(st => (
      selectedSearchTerms.indexOf(st.id) !== -1
    )).map(st => ({
      ...st,
      adgroupName: st.adgroup_name,
    }))
    setNegativesToAdd(sts)
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={handleAddNegative}
        >
          Add as Negative
        </button>
      </>
    )
  }

  const renderRecord = record => (
    <>
      <TableCampaignCell
        record={record}
        noLink
      />
      <div className="table-col col-adgroup" title={record.adgroup_name}>
        <span className="contents">
          { record.adgroup_name }
        </span>
      </div>
      <div className="table-col col-associated-target" title={record.target}>
        <span className="contents">
          { record.target }
        </span>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      {
        bulkSTColumnList.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={campaignTableColumns}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <Modal className="dup-search-term-modal" backdrop="static" full show>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Duplicated Search Term: { duplicatedSearchTerm.search }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortableTable
          columns={columns}
          defaultSort={['cost', 'desc']}
          sorter={tableSorter(['campaignName', 'adgroup_name', 'target', 'matchType'])}
          className="table-search-terms"
          records={searchTerms}
          idField="id"
          searchFields={['campaignName', 'adgroup_name', 'target']}
          selectedRecords={selectedSearchTerms}
          hasSticky
          columnEditorId="dupSearchTermModal"
          columnList={bulkSTColumnList}
          columnSelection={campaignTableColumns}
          renderRecord={renderRecord}
          renderTopRight={renderAction}
          onChange={setSelectedSearchTerms}
        />
        {
          negativesToAdd.length > 0 && (
            <NegativeCreatorModal
              searchTerms={negativesToAdd}
              onClose={() => { setNegativesToAdd([]) }}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default DupSTModal
