import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import MainLayout from '../../layout/MainLayout'
import CampaignSelector from './campaignSelector'
import DashboardTab from './tabs/dashboard'
import SKUOPTab from './tabs/sku'
import BidOPTab from './tabs/bid'
import KeywordCleanerTab from './tabs/keywordCleaner'
import STOPTab from './tabs/searchTerm'
import NegativeOPTab from './tabs/negative'
import PlacementOPTab from './tabs/placement'
import CampaignNotePane from '../CommonComponents/CampaignNotePane'

import { getAdgroups, getDetails } from '../../redux/actions/campaignDetail'
import { updateCampaignsState } from '../../redux/actions/campaign'
import { hideCampaignNote, showCampaignNote } from '../../redux/actions/pageGlobal'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { ReactComponent as TaskSvg } from '../../assets/svg/task.svg'

const CampaignDetailComponent = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const showCampaignNotePane = useSelector(state => state.pageGlobal.showCampaignNotePane)

  const { id: campaignId, campaignType } = useParams()

  const [currentTab, setCurrentTab] = useState('dashboard')

  useEffect(() => {
    document.querySelector('.main-content').scrollTop = 0
  }, [])

  useEffect(() => {
    if (!currentStartDate || !currentStartDate) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getDetails(accessToken, campaignId, campaignType))
      dispatch(getAdgroups(accessToken, campaignId, campaignType))
    })()
  }, [campaignId, currentStartDate, currentEndDate]) // eslint-disable-line

  const tabList = [
    {
      value: 'dashboard',
      label: 'Dashboard',
      isShow: true,
    },
    {
      value: 'sku',
      label: 'SKU Op',
      isShow: campaignType !== 'sb' && !isNonEndemicAccount,
    },
    {
      value: 'bid',
      label: 'Bid Op',
      isShow: true,
    },
    {
      value: 'keyword',
      label: 'Keyword Cleaner',
      isShow: campaignType !== 'sb'
        && campaignType !== 'sd'
        && !(currentDetail && currentDetail.targeting_type === 'auto')
    },
    {
      value: 'search',
      label: 'Search Term Op',
      isShow: campaignType !== 'sd',
    },
    {
      value: 'negative',
      label: 'Negative Word/ASIN Finder',
      isShow: campaignType !== 'sd',
    },
    {
      value: 'placement',
      label: 'Placement Op',
      isShow: campaignType !== 'sb' && campaignType !== 'sd',
    }
  ]

  const handleTabClick = tab => () => {
    if (currentDetail) {
      setCurrentTab(tab.value)
    }
  }

  const handleStateChange = async (enabled) => {
    const accessToken = await getAccessTokenSilently()
    return dispatch(updateCampaignsState(
      accessToken,
      [{ campaignId, campaignType }],
      enabled ? 'enabled' : 'paused',
      true,
    ))
  }

  const renderContents = () => {
    if (currentTab === 'dashboard') {
      return (
        <DashboardTab
          isNonEndemicAccount={isNonEndemicAccount}
        />
      )
    }

    if (currentTab === 'sku' ) {
      return (
        <SKUOPTab
          campaignType={campaignType}
        />
      )
    }

    if (currentTab === 'bid') {
      return (
        <BidOPTab
          campaignType={campaignType}
          isNonEndemicAccount={isNonEndemicAccount}
        />
      )
    }

    if (currentTab === 'keyword') {
      return (
        <KeywordCleanerTab
          campaignType={campaignType}
        />
      )
    }

    if (currentTab === 'search') {
      return (
        <STOPTab
          campaignType={campaignType}
        />
      )
    }

    if (currentTab === 'negative') {
      return (
        <NegativeOPTab
          campaignType={campaignType}
        />
      )
    }

    if (currentTab === 'placement') {
      return (
        <PlacementOPTab
          campaignType={campaignType}
        />
      )
    }
  }

  return (
    <MainLayout>
      <div className="campaign-detail-component">
        { currentDetail && showCampaignNotePane &&
          <CampaignNotePane
            campaign={currentDetail}
            onClose={() => { dispatch(hideCampaignNote()) }}
          />
        }
        <div className="page-header">
          <div className="page-title">
            <CampaignSelector
              campaignId={campaignId}
              campaignState={currentDetail?.state || ''}
              onSelect={() => { setCurrentTab('dashboard') }}
              onStateChange={handleStateChange}
            />
            {
              currentDetail && (
                <TaskSvg
                  className="task-icon"
                  title="Open Notes"
                  onClick={() => { dispatch(showCampaignNote()) }}
                />
              )
            }
          </div>
        </div>
        <div className="page-tabs">
          {
            tabList.map((tab) => (
              tab.isShow && (
                <button
                  key={tab.value}
                  type="button"
                  className={`page-tab${currentTab === tab.value ? ' selected' : ''}`}
                  onClick={handleTabClick(tab)}
                >
                  { tab.label }
                </button>
              )
            ))
          }
        </div>
        { renderContents() }
      </div>
    </MainLayout>
  )
}

export default CampaignDetailComponent
