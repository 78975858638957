import { callPost } from '../../services/axios.js'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  CREATE_EP_TEMPLATE,
  UPDATE_EP_TEMPLATE,
} from '../actionTypes/eventPlanning.js'

export const getEpStats = (accessToken, spsdCampaignIds, sbCampaignIds, timezone) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/eventPlanning/getStats', {
    userId: currentUserId,
    spsdCampaignIds,
    sbCampaignIds,
    timezone,
  }, accessToken)
}

export const loadEpRules = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/eventPlanning/loadRules', {
    userId: currentUserId,
    campaignIds,
  }, accessToken).then(response => response.data)
}

export const saveEpRules = (accessToken, campaignIds, status, timezone,
  slots, templateIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/eventPlanning/saveRules', {
    userId: currentUserId,
    campaignIds,
    status,
    timezone,
    slots,
    templateIds,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Successfully saved rules.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save rules.',
    })
  })
}

export const saveEpTemplate = (accessToken, name, timezone, slots,
  needApply = false, campaignIds = []) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/eventPlanning/saveTemplate', {
    name,
    timezone,
    slots,
    needApply,
    userId: currentUserId,
    campaignIds,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Successfully saved template.',
    })

    dispatch({
      type: CREATE_EP_TEMPLATE,
      template: response.data,
    })

    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save template.',
    })
    return null
  })
}

export const updateEpTemplate = (accessToken, templateId, name, timezone, slots) => (dispatch) => {
  return callPost('/eventPlanning/updateTemplate', {
    templateId,
    name,
    timezone,
    slots,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Successfully updated template.',
    })

    dispatch({
      type: UPDATE_EP_TEMPLATE,
      template: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update template.',
    })
  })
}
