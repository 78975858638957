import React, { useState, useEffect } from 'react'

import CustomTable from '../CommonComponents/CustomTableComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'

const ExProductAdgroups = ({ campaign, settings, disabled, onChange }) => {
  const [selectedAdgroupIds, setSelectedAdgroupIds] = useState([])
  const [adgroupValues, setAdgroupValues] = useState({})

  useEffect(() => {
    const values = {}
    const selected = []
    campaign.productAdgroups.forEach((adgroup) => {
      const found = settings.ex_pt_adgroups.find(value => (
        value.adgroupId.toString() === adgroup.id
      ))

      values[adgroup.id] = {
        bid: found ? found.bid : 0.75,
      }

      if (found) {
        selected.push(adgroup.id)
      }
    })

    setSelectedAdgroupIds(selected)
    setAdgroupValues(values)
  }, [campaign, settings.adgroup_id]) // eslint-disable-line

  // Propagate selection change.
  const propagateChange = (list) => {
    onChange('ex_pt_adgroups', list)
    setSelectedAdgroupIds(list.map(value => value.adgroupId))
  }

  // Handle changes of records selection in custom table component.
  const handleSelectionChange = (adgroupIds) => {
    const compositeValue = adgroupIds.map(id => ({
      adgroupId: id,
      bid: adgroupValues[id].bid,
    }))

    propagateChange(compositeValue)
  }

  const applyBidChange = (adgroupId, bid) => {
    const values = {...adgroupValues}
    values[adgroupId].bid = bid
    setAdgroupValues(values)

    // Propagate changes to parent components.
    const compositeValue = [...settings.ex_pt_adgroups]
    compositeValue.forEach((value) => {
      if (value.adgroupId === adgroupId) {
        value.bid = bid
      }
    })

    propagateChange(compositeValue)
  }

  const handleBidChange = (adgroupId = 0) => (event) => {
    applyBidChange(adgroupId, event.target.value)
  }

  // Validate if a bid value is equal to, or greater than 0.15.
  const validateBid = (adgroupId = 0) => () => {
    if (adgroupValues[adgroupId].bid === ''
      || isNaN(adgroupValues[adgroupId].bid)
      || parseFloat(adgroupValues[adgroupId].bid) < 0.15) {
      applyBidChange(adgroupId, 0.15)
    }
  }

  const renderAdgroup = (adgroup, checked) => (
    <>
      <div className="table-col">
        { adgroup.name }
      </div>
      <div className="table-col">
        <input
          type="number"
          min="0.15"
          disabled={!checked}
          value={adgroupValues[adgroup.id] ? adgroupValues[adgroup.id].bid : 0}
          onChange={handleBidChange(adgroup.id)}
          onBlur={validateBid(adgroup.id)}
        />
      </div>
    </>
  )

  const renderSection = () => {
    return (
      <CustomTable
        className="table-adgroups"
        records={campaign.productAdgroups}
        idField="id"
        searchFields={['name']}
        selectedRecords={selectedAdgroupIds}
        renderRecord={renderAdgroup}
        onChange={handleSelectionChange}
      >
        <div className="table-col">Ad Group</div>
        <div className="table-col">Bid</div>
      </CustomTable>
    )
  }

  return (
    <div className={`table-wrapper ${disabled ? 'disabled' : ''}`}>
      <div className="table-wrapper-header">
        <strong>Step 5)</strong>&nbsp;Select Ad Groups, specify bid price for newly added ASINs.
        <CustomTooltip>
          <p>This is where you will select which ad groups to add competitor ASINs
          to in order to target them with advertising.</p>
          <p>If no ad groups are present, this means you do not have
          any Product Targeting ad groups created within this campaign.</p>
          <p>Product Targeting ad groups can be added in Campaign Expansion.</p>
        </CustomTooltip>
      </div>
      <div className="table-header-desc">
        { campaign.productAdgroups.length } ad groups available for selection.
      </div>
      { renderSection() }
    </div>
  )
}

export default ExProductAdgroups
