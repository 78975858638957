import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import ConfirmModal from '../CommonComponents/ConfirmModal'

import {
  getAllTags,
  removeTagsfromCampaign
} from '../../redux/actions/tag'
import { getCampaignsWithTag } from '../../redux/actions/campaign'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'
import { tagColumnList } from '../../utils/defaultValues'

import {
  calcDerivedMetrics,
  tableSorter,
  getExportValueForColumn,
} from '../../services/helper'

const initialColumns = [
  { key: 'tag_name', name: 'Tag', className: 'col-tag', parentOnly: true },
  { key: 'action', name: '', className: '' },
  { key: 'campaign', name: 'Campaigns', className: 'col-campaign' },
]

const TagTableComponent = ({
  hasSticky = true,
  hasDateRange = false,
  useFilterModal = false,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const tagTableColumns = useSelector(state => state.pageGlobal.tagTableColumns)

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const campaignsWithTag = useSelector(state => state.campaign.campaignsWithTag)

  const listTags = useSelector(state => state.tag.tags)
  const isTagLoading = useSelector(state => state.tag.isLoading)
  const isRemovingTag = useSelector(state => state.tag.isRemovingTag)

  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [removeCampaign, setRemoveCampaign] = useState(null)

  const columnsToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return tagColumnList
    }
    return tagColumnList.filter(column => (
      ![
        'revenue',
        'acos',
        'orders',
        'conversion',
        'roas',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
      ].includes(column.key)
    ))
  }, [isNonEndemicAccount])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAllTags(accessToken, abortCtrl.signal))
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getCampaignsWithTag(
        accessToken,
        currentStartDate,
        currentEndDate,
        abortCtrl.signal,
      ))
      setIsLoading(false)
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [currentStartDate, currentEndDate]) // eslint-disable-line

  const handleRemoveCampaign = (campaign) => {
    setRemoveCampaign(p => ({...campaign}))
    setConfirmText(`Are you sure want to remove this Campaign (${campaign.campaign}) from the Tag: "${campaign.name}"?`)
    setShowConfirmModal(true)
  }

  const handleModalConfirm = async (isConfirmed) => {
    if (isConfirmed) {
      const accessToken = await getAccessTokenSilently()
      await dispatch(removeTagsfromCampaign(
        accessToken,
        [removeCampaign.tagId],
        removeCampaign.campaign_id
      ))
      setIsLoading(true)
      await dispatch(getCampaignsWithTag(
        accessToken,
        currentStartDate,
        currentEndDate,
      ))
      setIsLoading(false)
    }
    setShowConfirmModal(false)
  }

  const tagList = useMemo(() => {
    return listTags.map((tag) => {
      const campaigns = (campaignsWithTag || []).filter(c =>
        c.tags.includes(tag.id)
      )

      let daily_budget = 0
      let cost = 0
      let revenue = 0
      let impressions = 0
      let clicks = 0
      let orders = 0
      let ntb_orders = 0
      let ntb_sales = 0

      campaigns.forEach((campaign) => {
        daily_budget += parseFloat(campaign.daily_budget || 0)
        cost += parseFloat(campaign.cost || 0)
        revenue += parseFloat(campaign.revenue || 0)
        impressions += parseInt(campaign.impressions || 0, 10)
        clicks += parseInt(campaign.clicks || 0, 10)
        orders += parseInt(campaign.orders || 0, 10)
        ntb_orders += parseInt(campaign.ntb_orders || 0, 10)
        ntb_sales += parseFloat(campaign.ntb_sales || 0)
      })

      return calcDerivedMetrics({
        ...tag,
        daily_budget,
        cost,
        revenue,
        impressions,
        clicks,
        orders,
        ntb_orders,
        ntb_sales,
        children: campaigns.map(c => ({
          ...c,
          name: tag.tag_name, // For search
          tagId: tag.id
        })),
      })
    })
  }, [listTags, campaignsWithTag])

  const [columns, columnSelection] = useMemo(() => {
    let availableColumns = tagTableColumns.filter(c => c.key !== 'tag')
    if (isNonEndemicAccount) {
      availableColumns = availableColumns.filter(column => (
        ![
          'revenue',
          'acos',
          'orders',
          'conversion',
          'roas',
          'ntb_orders',
          'ntb_orders_percent',
          'ntb_sales',
          'ntb_sales_percent',
        ].includes(column.key)
      ))
    }
    return [
      [...initialColumns, ...availableColumns],
      availableColumns,
    ]
  }, [tagTableColumns, isNonEndemicAccount])

  const renderTag = record => (
    <>
      <div className="table-col col-tag">
        { record.tag_name }
      </div>
      <div className="table-col"/>
      <div className="table-col col-campaign">
        { record.children.length }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderCampaign = record => (
    <>
      <div className="table-col">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => handleRemoveCampaign(record)}
        >
          Remove
        </button>
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotal = record => (
    <>
      <div className="table-col col-tag">
        Totals:
      </div>
      <div className="table-col" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        tableComponent={GroupTable}
        isLoading={isLoading || isTagLoading || isRemovingTag}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['name'])}
        className="table-tag"
        records={tagList}
        idField="id"
        searchFields={['name', 'campaign']}
        noCheckBox
        hasSticky={hasSticky}
        hasDateRange={hasDateRange}
        filterName="tagTable"
        useFilterModal={useFilterModal}
        columnEditorId="tagTable"
        columnList={columnsToRender}
        columnSelection={columnSelection}
        columnEditorNoReset={false}
        renderRecord={renderTag}
        renderTotal={renderTotal}
        sorterChild={tableSorter(['campaign'])}
        idFieldChild="campaign_id"
        renderChild={renderCampaign}
        getExportData={getExportData}
        exportFileName="Tags"
        extend
      />
      <ConfirmModal
        show={showConfirmModal}
        onConfirm={handleModalConfirm}
        text={confirmText}
      />
    </>
  )
}

export default TagTableComponent
