import React from 'react'

import { ReactComponent as LoaderSvg } from '../../../assets/svg/logo-small.svg'

const LoaderComponent = ({ className = '' }) => (
  <div className={`loader-component ${className}`}>
    <LoaderSvg />
  </div>
)

export default LoaderComponent
