import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { differenceInDays } from 'date-fns'

import VideoLink from '../../../CommonComponents/VideoLink'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'

import KeywordTable from './KeywordTable'
import TargetTable from './TargetTable'

import {
  getNegativeWordData,
  getNegativeTargetData,
} from '../../../../redux/actions/campaignDetail'
import { parseDate } from '../../../../services/helper'
import AdgroupSelector from '../../AdgroupSelector'

const videoList = [
  { title: 'Negative Optimization Video', url: 'https://www.loom.com/embed/d4ff75392a6347f390b9c4413a86d174' },
]

const NegativeOPTab = ({ campaignType }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const { id: campaignId } = useParams()

  const [currentAdgroup, setCurrentAdgroup] = useState(null)
  const [hideKeywords, setHideKeywords] = useState(true)

  const targetType = useMemo(() => {
    if (!currentAdgroup) {
      return 'keywords'
    }
    return currentAdgroup.targetType === 'products' ? 'products' : 'keywords'
  }, [currentAdgroup])

  useEffect(() => {
    if (!currentStartDate || !currentEndDate) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      if (targetType === 'keywords') {
        let adgroupIds
        if (currentAdgroup) {
          adgroupIds = [currentAdgroup.adgroupid]
        } else {
          adgroupIds = currentAdGroups.map(adgroup => adgroup.adgroupid)
        }

        if (!adgroupIds) {
          return
        }

        dispatch(getNegativeWordData(accessToken, {
          campaignId: campaignId,
          adgroupIds,
          campaignType,
          hideKeywords,
        }))
      } else {
        dispatch(getNegativeTargetData(accessToken, {
          campaignId: campaignId,
          adgroupId: currentAdgroup.adgroupid,
          targetAcos: currentAcos || 0,
        }))
      }
    })()
    // eslint-disable-next-line
  }, [
    campaignId,
    targetType,
    currentAdgroup,
    currentAdGroups.map(adgroup => adgroup.adgroupid).join(), // eslint-disable-line
    currentStartDate,
    currentEndDate,
    currentAcos,
    hideKeywords,
  ])

  if (campaignType === 'sd') {
    return null
  }

  const dateDiff = differenceInDays(parseDate(currentEndDate), parseDate(currentStartDate)) || 1

  return (
    <div className="campaign-detail-negative-op campaign-detail-tab">
      <div className="tab-info">
        <div className="tab-title">
          Negative Word/ASIN Finder
          <CustomTooltip placement="right">
            <p>Automatically finds individual words that are leading to lost sales.</p>
            <p>Read through this list carefully and decide which words you want to add as negative phrase match.</p>
          </CustomTooltip>
        </div>
        <div className="tab-description">
          Finds Unique Words/ASINS That Have Not Been Profitable.
        </div>
        <VideoLink
          videoList={videoList}
          modalTitle='Negative Optimization'
        />
      </div>
      <AdgroupSelector
        campaignType={campaignType}
        currentAdgroup={currentAdgroup}
        showAllAdgroup
        onAdgroupChange={setCurrentAdgroup}
      />
      {
        targetType === 'keywords' && (
          <KeywordTable
            currentAdgroupId={currentAdgroup?.adgroupid}
            campaignType={campaignType}
            dateDiff={dateDiff}
            hideKeywords={hideKeywords}
            setHideKeywords={setHideKeywords}
          />
        )
      }
      {
        targetType === 'products' && (
          <TargetTable
            currentAdgroupId={currentAdgroup?.adgroupid}
            campaignType={campaignType}
            dateDiff={dateDiff}
          />
        )
      }
    </div>
  )
}

export default NegativeOPTab
