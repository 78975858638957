/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { LOGIN_CLIENT_ID, SP_SETTINGS_REDIRECT_URI } from '../../config/api'
import {
  STORAGE_KEY_STATE,
  STORAGE_KEY_ORIGIN,
} from '../../utils/defaultValues'
import { toast } from '../CommonComponents/ToastComponent/toast'

import {
  signupAdsCode,
  reauthorizeSp,
  reauthorizeAds,
} from '../../redux/actions/auth'
import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'
import {
  getAuthorizationUrl,
  getRegionForCountry,
} from '../../services/helper'
import {getAmazonScopeList} from '../../utils/amazon'

const STORAGE_KEY_FOR_HIDE = 'hide_sp_token_missing'

const TokenErrorComponent = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const { getAccessTokenSilently } = useAuth0()

  const [isAuthorizing, setIsAuthorizing] = useState(false)
  const [showSpTokenError, setShowSpTokenError] = useState(true)

  // Inject Amazon LWA script.
  useEffect(() => {
    if (!currentAccount || currentAccount.tokenError !== 'ads') {
      return
    }

    let amazonRoot = document.getElementById('amazon-root')
    if (!amazonRoot) {
      amazonRoot = document.createElement('div')
      amazonRoot.setAttribute('id', 'amazon-root')
      document.body.appendChild(amazonRoot)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        window.onAmazonLoginReady = function() {
          amazon.Login.setClientId('${LOGIN_CLIENT_ID}');
        };
        (function(d) {
          var a = d.createElement('script'); a.type = 'text/javascript';
          a.async = true; a.id = 'amazon-login-sdk';
          a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
          d.getElementById('amazon-root').appendChild(a);
        })(document);
      `
      document.body.appendChild(script)
    }
  }, [currentAccount])

  useEffect(() => {
    if (!currentAccount || (!currentAccount.tokenError && currentAccount.hasSpCode)) {
      return
    }

    const qs = new URLSearchParams(location.search)
    const spCode = qs.get('spapi_oauth_code')
    const code = qs.get('code')

    if (isAuthorizing) {
      return
    }

    if (spCode) {
      if (qs.get('state') !== 'fix-sp') {
        return
      }

      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsAuthorizing(true)
        dispatch(reauthorizeSp(accessToken, currentAccount.user_id, spCode)).then(() => {
          setIsAuthorizing(false)
        }).catch((error) => {
          setIsAuthorizing(false)
          toast.show({
            title: 'Danger',
            description: error,
          })
        })
      })()
    } else if (code) {
      let state = qs.get('state')
      const savedState = window.sessionStorage.getItem(STORAGE_KEY_STATE)
      const origin = window.sessionStorage.getItem(STORAGE_KEY_ORIGIN) || ''
      if (state === savedState && origin === 'fix-ads') {
        window.sessionStorage.removeItem(STORAGE_KEY_STATE);

        (async () => {
          const accessToken = await getAccessTokenSilently()
          setIsAuthorizing(true)
          dispatch(signupAdsCode(
            code,
            SP_SETTINGS_REDIRECT_URI,
            getRegionForCountry(currentAccount.country_id)
          )).then(response => (
            dispatch(reauthorizeAds(
              accessToken,
              currentAccount.user_id,
              response.refreshToken,
            )).then(() => {
              setIsAuthorizing(false)
            })
          )).catch((error) => {
            setIsAuthorizing(false)
            toast.show({
              title: 'Danger',
              description: error,
            })
          })
        })()
      }
    }
  }, [currentAccount]) // eslint-disable-line

  const handleAdsApiInitiate = () => {
    if (typeof window.amazon === 'undefined') {
      toast.show({
        title: 'Danger',
        description: 'The Amazon script is not loaded.',
        duration: 20000,
      })
      return
    }

    const state = (new Date()).valueOf()
    // Save state to sessionStorage for later validation.
    window.sessionStorage.setItem(STORAGE_KEY_STATE, state)
    window.sessionStorage.setItem(STORAGE_KEY_ORIGIN, 'fix-ads')

    window.amazon.Login.authorize({
      scope: getAmazonScopeList(),
      response_type: 'code',
      popup: false,
      state,
    }, SP_SETTINGS_REDIRECT_URI)
  }

  const handleDismiss = (event) => {
    event.preventDefault()
    localStorage.setItem(STORAGE_KEY_FOR_HIDE, 'true')
    setShowSpTokenError(false)
  }

  if (!currentAccount || (!currentAccount.tokenError && currentAccount.hasSpCode)) {
    return null
  }

  const renderLink = () => {
    if (currentAccount.tokenError === 'sp') {
      const link = getAuthorizationUrl(
        currentAccount.country_id,
        'fix-sp',
        currentAccount.seller_type === 'seller'
      )
      return (
        <a
          href={link}
          className={`btn btn-red${isAuthorizing ? ' disabled' : ''}`}
        >
          { isAuthorizing ? 'Authorizing' : 'Authorize' }
        </a>
      )
    }

    if (currentAccount.tokenError === 'ads') {
      return (
        <button
          type="button"
          className="btn btn-red"
          disabled={isAuthorizing}
          onClick={handleAdsApiInitiate}
        >
          { isAuthorizing ? 'Authorizing' : 'Authorize' }
        </button>
      )
    }

    if (!currentAccount.hasSpCode) {
      const link = getAuthorizationUrl(
        currentAccount.country_id,
        'fix-sp',
        currentAccount.seller_type === 'seller'
      )
      return (
        <>
          <a
            href={link}
            className={`btn btn-red${isAuthorizing ? ' disabled' : ''}`}
          >
            { isAuthorizing ? 'Authorizing' : 'Authorize' }
          </a>
          <a
            href="#"
            onClick={handleDismiss}
          >
            Dismiss
          </a>
        </>
      )
    }

    return null
  }

  let message = 'Re-authorize Entourage to access your data.'
  let error
  if (currentAccount.tokenError === 'ads'
    || currentAccount.tokenError === 'sp') {
    error = 'Your API authorizations are invalid now.'
  } else if (!currentAccount.hasSpCode) {
    if (!showSpTokenError
      || localStorage.getItem(STORAGE_KEY_FOR_HIDE) !== null
      || isNonEndemicAccount) {
      return null
    }

    message = 'Authorize Entourage to access your data.'
    error = 'The API authorization is needed to pull organic sales.'
  }

  return (
    <div className="notification-box">
      <div className="call-text">
        <div className="call-name">
          { message }
        </div>
        <div className="call-description">
          { error }
        </div>
      </div>
      { renderLink() }
    </div>
  )
}

export default TokenErrorComponent
