import React from 'react'

import CheckboxComponent from '../../CommonComponents/CheckboxComponent'
import CustomTooltip from '../../CommonComponents/CustomTooltip'

const CreativeCustomImage = ({ basicInfo, inputRef,
  required = false, needSquare = true,
  customSrc, croppedCustomSquare, onChange, onUpload, onCrop }) => {
  return (
    <div className="customizer-section">
      <div className="field-section-name">
        {
          required ? (
            <>Custom Image</>
          ) : (
            <CheckboxComponent
              label="Custom image"
              checked={basicInfo.hasCustomImage}
              onChange={(checked) => { onChange('hasCustomImage', checked) }}
            />
          )
        }
        <CustomTooltip placement="right">
          <p>Image requirements</p>
          <p>- Image size: 1200 x 628 px or larger</p>
          <p>- File size: 5MB or smaller</p>
          <p>- File format: PNG, JPEG, or GIF</p>
          <p>- Content: No text, graphics, or logo added to the image</p>
        </CustomTooltip>
      </div>
      {
        (basicInfo.hasCustomImage || required) && (
          <div className="field-section-contents">
            <div className="brand-logo-container">
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onUpload}
              />
              <div className="brand-logo-wrapper">
                <img src={customSrc} alt="" />
              </div>
              <div className="action-wrapper">
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => { inputRef.current.value = null; inputRef.current.click() }}
                >
                  Add Image
                </button>
                <button
                  type="button"
                  className="btn btn-blue"
                  disabled={!customSrc}
                  onClick={() => { onCrop('custom') }}
                >
                  Crop Image
                </button>
              </div>
            </div>
            {
              needSquare && (
                <div className="crop-square-section">
                  <div className="crop-square-title">
                    Crop image as sqaure
                    <CustomTooltip placement="right">
                      <p>Your image can show in many ad placements,
                        but it might be too wide for some of them.
                        In case it's too wide, select the part of the image
                        you want to show.</p>
                    </CustomTooltip>
                  </div>
                  <div className="brand-logo-wrapper">
                    <img src={croppedCustomSquare} alt="" />
                  </div>
                  <button
                    type="button"
                    className="btn btn-blue"
                    disabled={!customSrc}
                    onClick={() => { onCrop('customSquare') }}
                  >
                    Crop
                  </button>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default CreativeCustomImage
