import React from 'react'
import Select from 'react-select'

import CustomTooltip from '../CommonComponents/CustomTooltip'

const lookbackList = [
  { value: 14, label: 'Past 14 days' },
  { value: 30, label: 'Past 30 days' },
  { value: 60, label: 'Past 60 days' },
  { value: 90, label: 'Past 90 days' },
]

const LookbackSelector = ({ lookback, onChange }) => {
  const lookbackOption = lookbackList.find(option =>
    option.value === lookback
  )

  const handleLookbackChange = (option) => {
    onChange(option.value)
  }

  return (
    <div className="lookback-wrapper">
      <label>
        Lookback window:
      </label>
      <CustomTooltip>
        <p>
          The Marketing Stream data is pushed starting the first day of your free trial,
          and will accumulate over time.
        </p>
      </CustomTooltip>
      <Select
        options={lookbackList}
        placeholder="Choose period"
        value={lookbackOption}
        onChange={handleLookbackChange}
      />
    </div>
  )
}

export default LookbackSelector
