import React, { useEffect, useRef } from 'react'

const BulkResultContainer = ({ children }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollIntoView()
  }, [])

  return (
    <div className="bulk-result-container" ref={containerRef}>
      { children }
    </div>
  )
}

export default BulkResultContainer
