import React from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import LoaderComponent from '../CommonComponents/LoaderComponent'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'
import {
  formatCurrency,
  formatValue,
  calcDerivedMetrics,
  parseDate,
} from '../../services/helper'

const metricList = {
  organicRevenue: 'Organic Revenue',
  revenue: 'PPC Revenue',
  sales: 'Total Revenue',
  cost: 'Ad Spend',
  impressions: 'Impressions',
  clicks: 'Clicks/CTR(%)',
  orders: 'Orders',
  conversion: 'Conversion(%)',
  acos: 'ACoS(%)',
  trueAcos: 'TACOS(%)',
  cpc: 'Ave CPC',
  roas: 'ROAS',
  ntb_orders: 'NTB Orders',
  ntb_sales: 'NTB Sales',
  ntb_units: 'NTB Units',
  ntb_sales_percent: '% of NTB Sales',
}

const columnsToExclude = [
  'organicRevenue',
  'sales',
  'revenue',
  'trueAcos',
  'acos',
  'orders',
  'conversion',
  'roas',
  'ntb_orders',
  'ntb_sales',
  'ntb_units',
  'ntb_sales_percent',
]

const ComparisonView = ({ isLoading, summary, noSales = false }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const renderMetric = (slot, index, metric) => {
    let isBetter = true
    let value

    const current = calcDerivedMetrics(slot)
    const prev = calcDerivedMetrics(summary[index - 1])
    switch (metric) {
      case 'organicRevenue':
        value = formatCurrency(current.sales - current.revenue, currencySign, currencyRate)
        isBetter = current.sales - current.revenue >= (prev.sales - prev.revenue)
        break
      case 'revenue':
      case 'sales':
      case 'cost':
      case 'ntb_sales':
        value = formatCurrency(current[metric], currencySign, currencyRate)
        isBetter = current[metric] >= prev[metric]
        break
      case 'impressions':
      case 'orders':
      case 'ntb_orders':
      case 'ntb_units':
        value = formatValue(current[metric], 'number', 0)
        isBetter = current[metric] >= prev[metric]
        break
      case 'clicks':
        value = `${formatValue(current.clicks, 'number', 0)} / ${formatValue(current.ctr, 'percent', 2)}`
        isBetter = current.ctr >= prev.ctr
        break
      case 'conversion':
      case 'ntb_sales_percent':
        value = formatValue(current[metric], 'percent')
        isBetter = current[metric] >= prev[metric]
        break
      case 'acos':
        value = formatValue(current[metric], 'percent', 1)
        isBetter = current[metric] >= prev[metric]
        break
      case 'trueAcos':
        const trueAcos = current.sales ? (current.cost / current.sales) * 100 : 0
        const prevAcos = prev.sales ? (prev.cost / prev.sales) * 100 : 0
        value = formatValue(trueAcos, 'percent', 1)
        isBetter = trueAcos >= prevAcos
        break
      case 'cpc':
      case 'roas':
        value = formatValue(current[metric], 'number')
        isBetter = current[metric] >= prev[metric]
        break
      default:
        return null
    }

    return (
      <>
        { value }
        {
          isBetter
          ? <BsCaretUpFill size={16} color="#2EB73C" />
          : <BsCaretDownFill size={16} color="#D93842" />
        }
      </>
    )
  }

  const renderSlot = (slot, index) => {
    // The first slot is present to calculate trends
    // compared to previous week/month.
    if (index === 0) {
      return null
    }

    return (
      <div key={index} className="summary-card">
        <div className="summary-heading">
          { format(parseDate(slot.start), 'MM/dd') } ~ { format(parseDate(slot.end), 'MM/dd') }
        </div>
        {
          Object.keys(metricList).map((metric) => {
            if (noSales
              && (metric === 'organicRevenue'
                || metric === 'sales'
                || metric === 'trueAcos')) {
              return null
            }

            if (isNonEndemicAccount
              && columnsToExclude.includes(metric)) {
              return null
            }

            return (
              <div key={metric} className="metric-value">
                { renderMetric(slot, index, metric) }
              </div>
            )
          })
        }
      </div>
    )
  }

  if (isLoading || !summary) {
    return (
      <div className="comparison-view-container">
        <LoaderComponent />
      </div>
    )
  }

  return (
    <div className="comparison-view-container">
      <div className="summary-card summary-metric">
        <div className="summary-heading">&nbsp;</div>
        {
          Object.keys(metricList).map((metric) => {
            if (noSales
              && (metric === 'organicRevenue'
                || metric === 'sales'
                || metric === 'trueAcos')) {
              return null
            }

            if (isNonEndemicAccount
              && columnsToExclude.includes(metric)) {
              return null
            }

            return (
              <div key={metric} className="metric-value">
                { metricList[metric] }
              </div>
            )
          })
        }
      </div>
      { summary.map(renderSlot) }
    </div>
  )
}

export default ComparisonView
