export const GET_CAMPAIGNS_WITH_HISTORY_SUCCESS = 'GET_CAMPAIGNS_WITH_HISTORY_SUCCESS'

export const GET_CAMPAIGNS_DETAIL = 'GET_CAMPAIGNS_DETAIL'

export const GET_CAMPAIGNS_HISTORY = 'GET_CAMPAIGNS_HISTORY'

export const UPDATE_CAMPAIGN_STATE_SUCCEED = 'UPDATE_CAMPAIGN_STATE_SUCCEED'

export const UPDATE_CAMPAIGN_NAMES_SUCCEED = 'UPDATE_CAMPAIGN_NAMES_SUCCEED'

export const UPDATE_BIDDINGS_SUCCEED = 'UPDATE_BIDDINGS_SUCCEED'

export const UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED = 'UPDATE_CAMPAIGNS_TARGET_ACOS_SUCCEED'

export const UPDATE_CAMPAIGNS_TAGS_SUCCEED = 'UPDATE_CAMPAIGNS_TAGS_SUCCEED'

export const GET_CAMPAIGNS_WITH_TAG_SUCCESS = 'GET_CAMPAIGNS_WITH_TAG_SUCCESS'

export const UPDATE_BULK_DAILY_BUDGET_SUCCEED = 'UPDATE_BULK_DAILY_BUDGET_SUCCEED'

export const ADD_BULK_NOTES_SUCCEED = 'ADD_BULK_NOTES_SUCCEED'

export const DELETE_CAMPAIGN_NOTE_SUCCEED = 'DELETE_CAMPAIGN_NOTE_SUCCEED'

export const SELECT_CAMPAIGNS = 'SELECT_CAMPAIGNS'

export const SELECT_AD_TYPE_FILTER = 'SELECT_AD_TYPE_FILTER'
