import React from 'react'
import { Link } from 'react-router-dom'

import { campaignTypeMap, stateLabels } from '../../../utils/defaultValues'

const TableCampaignCell = ({ record, noLink = false, showState = false }) => {
  const name = record.campaignName || record.campaign || record.campaign_name || record.name
  const targetingType = record.targetingType || record.targeting_type

  return (
    <div className="table-col col-campaign" title={name}>
      {
        showState && (
          <div className={`campaign-status ${record.state.toLowerCase() === 'enabled' ? 'on' : 'off'}`}>
            { stateLabels[record.state.toLowerCase()] }
          </div>
        )
      }
      <div className="contents">
        {noLink ? (
          name
        ) : (
          <Link
            to={`/campaign/${record.campaign_id}/${record.campaignType}`}
            className="campaign-name"
            title={name}
            target="_blank"
          >
            { name }
          </Link>
        )}
      </div>
      <div className="campaign-detail">
        {
          record.campaignType === 'sp' && (
            <span>
              { targetingType === 'auto' ? 'Auto' : 'Manual' }
            </span>
          )
        }
        <span>
          { campaignTypeMap[record.campaignType] }
        </span>
      </div>
    </div>
  )
}

export default TableCampaignCell
