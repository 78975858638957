import React from 'react'

import CustomTooltip from '../../CommonComponents/CustomTooltip'
import CheckboxComponent from '../../CommonComponents/CheckboxComponent'

const CreativeLogo = ({ basicInfo = {}, adType, inputRef, logoSrc, required = false,
  onChange, onUpload, onLogoSelect, onCrop }) => {
  return (
    <div className="customizer-section">
      <div className="field-section-name">
        {
          required ? (
            <>Brand Logo</>
          ) : (
            <CheckboxComponent
              label="Brand logo"
              checked={basicInfo.hasLogo}
              onChange={(checked) => { onChange('hasLogo', checked) }}
            />
          )
        }
        <CustomTooltip placement="right">
          <p>Logo requirements</p>
          {
            adType === 'sb' && (
              <>
                <p>- Dimensions are at least 400 x 400 px</p>
                <p>- File size is smaller than 1MB</p>
                <p>- File format is PNG, JPEG or GIF</p>
                <p>- Logo must fill the image or have a white or transparent background</p>
              </>
            )
          }
          {
            adType === 'sd' && (
              <>
                <p>- Dimensions are at least 600 x 100 px</p>
                <p>- File size is smaller than 1MB</p>
                <p>- File format is PNG or JPEG</p>
              </>
            )
          }
        </CustomTooltip>
      </div>
      {
        (basicInfo.hasLogo || required) && (
          <div className="field-section-contents">
            <div className="brand-logo-container">
              <input
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onUpload}
              />
              <div className="brand-logo-wrapper">
                {
                  logoSrc !== null && <img src={logoSrc} alt="Logo" />
                }
              </div>
              <div className="action-wrapper">
                {
                  adType === 'sb' && (
                    <button
                      type="button"
                      className="btn btn-blue"
                      onClick={onLogoSelect}
                    >
                      Select Logo
                    </button>
                  )
                }
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => { inputRef.current.value = null; inputRef.current.click() }}
                >
                  Upload New Logo
                </button>
                <button
                  type="button"
                  className="btn btn-blue"
                  disabled={!logoSrc}
                  onClick={onCrop}
                >
                  Crop Logo
                </button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default CreativeLogo
