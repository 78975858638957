import React from 'react'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'

const TableHeader = ({ columns, tableColumns, isSticky,
  refHeader, refProductCol, tableHeight, resizing, isSelected,
  onSort, onExpandProductName, onCollapseProductName, onResize, onSelect }) => (
  <div className={`table-row content-header${isSticky ? ' sticky' : ''}`} ref={refHeader}>
    <div className="table-col">
      <CheckboxComponent
        checked={isSelected}
        onChange={onSelect}
      />
    </div>
    <div
      className="table-col product-name"
      ref={refProductCol}
      onClick={() => { onSort('name') }}
    >
      Product
      <FiMinimize2 className="min-svg" size={16} color="#979797" onClick={onCollapseProductName} />
      <FiMaximize2 className="max-svg" size={16} color="#979797" onClick={onExpandProductName} />
      <div
        className={`resize-handle ${resizing ? 'active' : 'idle'}`}
        style={{ height: tableHeight }}
        onMouseDown={() => { onResize(true) }}
      />
    </div>
    {
      tableColumns.map(column => {
        const metric = columns.find(c => c.key === column.key)
        return (
          metric && (
            <div
              key={metric.key}
              className={`table-col ${metric.className || ''}`}
              onClick={() => { onSort(metric.key) }}
            >
              { metric.name }
              {
                typeof metric.tooltip !== 'undefined' && (
                  <CustomTooltip placement="right">
                    { metric.tooltip }
                  </CustomTooltip>
                )
              }
            </div>
          )
        )
      })
    }
    <div className="table-col"></div>
  </div>
)

export default TableHeader
