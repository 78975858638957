import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../CommonComponents/ToastComponent/toast'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CampaignSelector from '../CommonComponents/CampaignSelector'
import { loadCampaigns } from '../../redux/actions/ruleManager'

const ExCampaigns = ({ field, skus, forASINs, settings, disabled, isLoaded, onLoad, onChange }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [includeSB, setIncludeSB] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    // When loading a previously saved setting, if campaigns are
    // selected, enable the step to choose campaigns.
    if (settings
      && settings[field]
      && settings[field].length
      && !isLoaded) {
      getCampaigns(true, true, [])
    }
  }, [settings]) // eslint-disable-line

  const getCampaigns = async (includeSpSd, includeSb, skus) => {
    onLoad(true)
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const data = await dispatch(loadCampaigns(
      accessToken,
      includeSpSd,
      includeSb,
      skus
    ))
    setIsLoading(false)
    setCampaigns(data)
  }

  const loadData = (includeSb) => {
    let includeSpSd = false
    let skuQuery = []
    if (skus === 'all' || skus.length) {
      skuQuery = skus !== 'all' ? skus.map(sku => sku.sku) : []
      includeSpSd = true
    }

    getCampaigns(includeSpSd, includeSb, skuQuery)
  }

  const handleLoad = () => {
    if (skus !== 'all' && !skus.length && !includeSB) {
      toast.show({
        title: 'Warning',
        description: 'Please select some SKU\'s.',
      })
      return
    }

    loadData(includeSB)
  }

  const handleIncludeSB = async (checked) => {
    setIncludeSB(checked)

    if (isLoaded && checked && !isLoading) {
      loadData(checked)
    }
  }

  const handleChange = (ids) => {
    onChange(field, ids)
  }

  const selected = settings[field].map(id => id.toString())

  return (
    <div className={`table-wrapper ${disabled ? 'disabled' : ''}`}>
      <div className="table-wrapper-header">
        <button
          type="button"
          className="btn btn-green"
          disabled={isLoading || disabled}
          onClick={handleLoad}
        >
          Load campaigns
        </button>
        <CheckboxComponent
          label="Include Sponsored Brands campaigns"
          checked={includeSB}
          disabled={disabled}
          onChange={handleIncludeSB}
        />
      </div>
      <div className={`table-wrapper-header ${!isLoaded ? 'disabled' : ''}`}>
        <strong>Step 3)</strong>&nbsp;Select Campaigns to pull {forASINs ? 'ASINs' : 'Search Terms'} from.
      </div>
      <CampaignSelector
        campaigns={campaigns}
        selectedCampaignIds={selected}
        isLoaded={isLoaded}
        isLoading={isLoading}
        onChange={handleChange}
      />
    </div>
  )
}

export default ExCampaigns
