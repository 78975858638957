import React, { useState } from 'react'
import Select from 'react-select'

import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
} from '../../../utils/defaultValues'

import PrimePng from '../../../assets/img/prime.png'

const nonVideoPreviewTypes = [
  { value: 'MOBILE_TOP', label: 'Mobile: Top of search' },
  { value: 'MOBILE_DETAIL', label: 'Mobile: Inline search and detail page' },
  { value: 'DESKTOP_TOP', label: 'Desktop: Top of search' },
  { value: 'DESKTOP_DETAIL', label: 'Desktop: Search footer and detail page' },
]

const videoPreviewTypes = [
  { value: 'MOBILE_TOP', label: 'Mobile: Top of search' },
  { value: 'MOBILE_DETAIL', label: 'Mobile: Inline and bottom of search' },
  { value: 'DESKTOP_TOP', label: 'Desktop: Top of search' },
  { value: 'DESKTOP_DETAIL', label: 'Desktop: Inline and bottom of search' },
]

const SBAdPreview = ({
  adFormat,
  basicInfo,
  creativeProducts,
  creativeSubPages,
  croppedLogo,
  videoBlob,
}) => {
  let previewTypes
  if (adFormat !== AD_FORMAT_VIDEO) {
    previewTypes = nonVideoPreviewTypes
  } else {
    previewTypes = videoPreviewTypes
  }

  const [previewType, setPreviewType] = useState(previewTypes[0])

  const renderCollectionMobileTop = () => {
    return (
      <div className="preview-contents">
        <div className="headline">
          { basicInfo.headline }
        </div>
        <div className="image-stripe">
          <div className="brand-item">
            {
              croppedLogo && <img src={croppedLogo} alt="Logo" />
            }
            <div className="brand-name">
              Shop { basicInfo.brandName }
            </div>
          </div>
          {
            creativeProducts.map(product => (
              <div key={product.id} className="product-item">
                <img
                  className="product-image"
                  src={product.image}
                  alt={product.name}
                />
                <div className="product-name">
                  { product.name }
                </div>
                <img
                  className="prime-image"
                  src={PrimePng}
                  alt={product.name}
                />
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  const renderProducts = () => {
    if (adFormat === AD_FORMAT_SPOTLIGHT) {
      return creativeSubPages.map(subPage => (
        <div key={subPage.id} className="product-item">
          <img
            className="product-image"
            src={subPage.product.image}
            alt={subPage.product.name}
          />
          <div className="product-name">
            { subPage.name }
          </div>
        </div>
      ))
    }

    return creativeProducts.map(product => (
      <div key={product.id} className="product-item">
        <img
          className="product-image"
          src={product.image}
          alt={product.name}
        />
        <div className="product-name">
          { product.name }
        </div>
      </div>
    ))
  }

  const renderNonCollectionMobileTop = () => {
    let className = ''
    if (adFormat === AD_FORMAT_VIDEO) {
      className = 'preview-contents-for-video'
    }

    return (
      <div className={`preview-contents ${className}`}>
        <div className="spotlight-top-section">
          {
            croppedLogo && <img src={croppedLogo} alt="Logo" />
          }
          {
            adFormat === AD_FORMAT_SPOTLIGHT && (
              <div className="headline">
                { basicInfo.headline }
              </div>
            )
          }
        </div>
        {
          adFormat === AD_FORMAT_VIDEO && (
            <video
              src={videoBlob}
              width="320"
              height="180"
              controls
            />
          )
        }
        <div className="image-stripe">
          { renderProducts() }
        </div>
        {
          adFormat === AD_FORMAT_SPOTLIGHT && (
            <div className="spotlight-bottom-section">
              Shop the { basicInfo.brandName } Store on Amazon &gt;
            </div>
          )
        }
      </div>
    )
  }

  const renderCollectionDesktopTop = () => {
    return (
      <div className="preview-contents">
        <div className="image-stripe">
          {
            creativeProducts.map(product => (
              <div key={product.id} className="product-item">
                <img
                  className="product-image"
                  src={product.image}
                  alt={product.name}
                />
                <div className="product-name">
                  ${ parseFloat(product.price).toFixed(2) }
                </div>
                <img
                  className="prime-image"
                  src={PrimePng}
                  alt={product.name}
                />
              </div>
            ))
          }
          <div className="brand-headline-item">
            <div className="headline">
              { basicInfo.headline }
            </div>
            <div className="brand-name">
              Shop { basicInfo.brandName }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderNonCollectionDesktopTop = () => {
    return (
      <div className="preview-contents">
        <div className="spotlight-top-section">
          {
            croppedLogo && <img src={croppedLogo} alt="Logo" />
          }
          <div className="brand-headline-item">
            <div className="headline">
              { basicInfo.headline }
            </div>
            <div className="brand-name">
              Shop the { basicInfo.brandName } Store on Amazon &gt;
            </div>
          </div>
        </div>
        <div className="image-stripe">
          {
            adFormat === AD_FORMAT_VIDEO && (
              <video
                src={videoBlob}
                width="320"
                height="180"
                controls
              />
            )
          }
          { renderProducts() }
        </div>
      </div>
    )
  }

  const renderPreview = () => {
    if (previewType.value === 'MOBILE_TOP') {
      if (adFormat === AD_FORMAT_COLLECTION) {
        return renderCollectionMobileTop()
      }
      return renderNonCollectionMobileTop()
    }

    if (previewType.value === 'MOBILE_DETAIL') {
      if (adFormat === AD_FORMAT_VIDEO) {
        return renderNonCollectionMobileTop()
      }

      return (
        <div className="preview-contents">
          <div className="mobile-detail-section">
            <div className="image-stripe">
              <div className="brand-item">
                {
                  croppedLogo && <img src={croppedLogo} alt="Logo" />
                }
                <div className="brand-name">
                  Shop { basicInfo.brandName }
                </div>
              </div>
            </div>
            <div className="headline">
              { basicInfo.headline }
            </div>
            <div className="shop-link-item">
              Shop now
            </div>
          </div>
        </div>
      )
    }

    if (previewType.value === 'DESKTOP_TOP') {
      if (adFormat === AD_FORMAT_COLLECTION) {
        return renderCollectionDesktopTop()
      }
      return renderNonCollectionDesktopTop()
    }

    if (previewType.value === 'DESKTOP_DETAIL') {
      if (adFormat === AD_FORMAT_VIDEO) {
        return renderNonCollectionDesktopTop()
      }

      let product
      if (adFormat === AD_FORMAT_COLLECTION) {
        product = creativeProducts[0]
      } else {
        product = creativeSubPages[0].product
      }

      return (
        <div className="preview-contents">
          <div className="image-stripe">
            <div className="product-item">
              <img
                className="product-image"
                src={product.image}
                alt={product.name}
              />
            </div>
            <div className="brand-headline-item">
              {
                croppedLogo && <img src={croppedLogo} alt="Logo" />
              }
              <div className="headline">
                { basicInfo.headline }
              </div>
              <div className="brand-name">
                Shop { basicInfo.brandName }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="sb-preview-container">
      <Select
        value={previewType}
        options={previewTypes}
        onChange={setPreviewType}
      />
      { renderPreview() }
    </div>
  )
}

export default SBAdPreview
