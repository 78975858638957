import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'
import ProductTestingDetailComponent from './ProductTestingDetailComponent'
import ProductTestingAddComponent from './ProductTestingAddComponent'

import { getTests, getTestById } from '../../redux/actions/productDetail'
import { getISODate, parseDate } from '../../services/helper'
import { isAfter } from 'date-fns'

const columns = [
  { key: 'name', name: 'Name' },
  { key: 'fromDate', name: 'Date Started' },
  { key: 'toDate', name: 'Date Finished' },
]

const ProductTestingComponent = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isLoadingTests = useSelector(state => state.productDetail.isLoadingTests)
  const tests = useSelector(state => state.productDetail.tests)

  const [showAddModal, setShowAddModal] = useState(false)
  const [showDetailModal, setShowDetailModal] = useState(false)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getTests(accessToken))
    })()
  }, [dispatch]) // eslint-disable-line

  const handleTestClick = async (test) => {
    const accessToken = await getAccessTokenSilently()
    dispatch(getTestById(accessToken, test.id)).catch(() => {
      setShowDetailModal(false)
    })
    setShowDetailModal(true)
  }

  const extendedTests = useMemo(() => {
    return tests.map(test => ({
      ...test,
      className: isAfter(parseDate(test.to_date), new Date()) ? 'progress' : '',
      fromDate: getISODate(test.from_date),
      toDate: getISODate(test.to_date),
    }))
  }, [tests])

  const renderAction = () => (
    <>
      <button
        type="button"
        className="btn btn-red"
        onClick={() => { setShowAddModal(true) }}
      >
        Add A/B Test
      </button>
    </>
  )

  const renderTest = record => (
    <>
      <div className="table-col">
        { record.name }
      </div>
      <div className="table-col">
        { record.fromDate }
      </div>
      <div className="table-col">
        { record.toDate }
      </div>
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return record[column.key] || '';
    })
  )

  return (
    <div className="product-detail-testing">
      <div className="testing-header">
        Click on add A/B test to start.
        You will then name your test, select the length (in days) of your test,
        and enter the before and after values you are testing.
      </div>
      <CustomTable
        className="table-tests"
        isLoading={isLoadingTests}
        records={extendedTests}
        idField="id"
        noCheckBox
        searchFields={['name']}
        columns={columns}
        getExportData={getExportData}
        exportFileName="Product Detail A/B Testing"
        onClickRecord={handleTestClick}
        renderTopRight={renderAction}
        renderRecord={renderTest}
      >
        <div className="table-col">Name</div>
        <div className="table-col">Date Started</div>
        <div className="table-col">Date Finished</div>
      </CustomTable>
      <ProductTestingAddComponent
        show={showAddModal}
        onClose={() => { setShowAddModal(false) }}
      />
      <ProductTestingDetailComponent
        show={showDetailModal}
        onClose={() => { setShowDetailModal(false) }}
      />
    </div>
  )
}

export default ProductTestingComponent
