import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { IconButton, Icon } from 'rsuite'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportTableWidget from './ReportTableWidget'
import ReportGroupbTableWidget from './ReportGroupTableWidget'
import ReportNoteWidget from './ReportNoteWidget'
import KeywordTrackInputModal from './KeywordTrackInputModal'

import { getKeywordsReport } from '../../redux/actions/accountHealth'

import {
  formatCurrency,
  formatValue,
  capitalizeFirstLetter,
  groupRecords,
  tableSorter
} from '../../services/helper'
import { campaignTypeMap } from '../../utils/defaultValues'

const columns = [
  { key: 'keyword', name: 'Keyword' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'impressions', name: 'Impressions' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'orders', name: 'Orders' },
  { key: 'cost', name: 'Spend' },
  { key: 'sales', name: 'Sales' },
  { key: 'acos', name: 'ACoS' },
  { key: 'ntbOrder', name: 'NTB Orders' },
  { key: 'ntb_sales', name: 'NTB Sales' },
  { key: 'ntb_units', name: 'NTB Units' },
  { key: 'roas', name: 'ROAS' },
]

const columnsKeywordsGroup = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword', parentOnly: true },
  { key: 'category', name: 'Category', sortable: false, },
  { key: 'campaign', name: 'Campaign', className: 'col-campaign' },
  { key: 'target', name: 'Associated Target', className: 'col-target' },
  { key: 'match_type', name: 'Match Type' },
  { key: 'impressions', name: 'Impressions' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'orders', name: 'Orders' },
  { key: 'cost', name: 'Spend' },
  { key: 'sales', name: 'Sales' },
  { key: 'acos', name: 'ACoS' },
  { key: 'roas', name: 'ROAS' },
]

const KeywordSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [keywordsReport, setKeywordsReport] = useState({})
  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')
  const [keywordsToTrack, setKeywordsToTrack] = useState([])
  const [isEditingKeywords, setIsEditingKeywords] = useState(false)

  const loadReports = async (signal) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    try {
      const data = await dispatch(getKeywordsReport(accessToken, signal))
      setKeywordsReport({
        ...data,
        keywords: groupRecords(
          data.keywords,
          'keyword',
          [],
        ),
      })
      setIsLoading(false)
      setIsLoaded(true)
    } catch (isCancelled) {
      if (!isCancelled) {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      if (showSection && !isLoaded) {
        loadReports(abortCtrl.signal)
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [dispatch, showSection, startDate, endDate]) // eslint-disable-line

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_keyword || '')

      if (settings.keywords) {
        setKeywordsToTrack(settings.keywords.split(','))
      }
    }
  }, [settings, isLoadingSettings])

  useEffect(() => {
    const keywords = reportWidgetSetting ? reportWidgetSetting.keywords : []
    setViewWidgets(
      keywords.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w =>
      w.key === widgetKey ? {...w, view: !w.view} : w
    ))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_keyword',
      sectionValue: note,
    })
  }

  const handleSaveKeywords = async (enteredTargets) => {
    setIsEditingKeywords(false)

    const contents = (enteredTargets || '')
      .toLowerCase()
      .split('\n')
      .filter(target => target !== '')

    setKeywordsToTrack(contents)

    await onSaveSettings({
      sectionKey: 'keywords',
      sectionValue: contents.join(),
    })
    loadReports()
  }

  const renderTrackedKeywords = () => {
    return (
      <div className="keywords-track-section">
        <label>
          Keyword Tracker
        </label>
        {
          keywordsToTrack.length < 5 ? (
            <div className="keywords-list">
              {
                keywordsToTrack.map(keyword => (
                  <span key={keyword} className="keyword-wrapper">
                    { keyword }
                  </span>
                ))
              }
            </div>
          ) : (
            <span>
              { keywordsToTrack.length } targets to track
            </span>
          )
        }
        <IconButton
          icon={<Icon icon="pencil" />}
          appearance="subtle"
          size="xs"
          onClick={() => setIsEditingKeywords(true)}
        />
      </div>
    )
  }

  const renderRecord = (record) => (
    <>
      <div className="table-col">
        {record.keyword}
      </div>
      <div className="table-col">
        {capitalizeFirstLetter(record.match_type)}
      </div>
      <div className="table-col">
        {record.impressions !== 0 ? formatValue(record.impressions, 'number', 0) : '-'}
      </div>
      <div className="table-col">
        {record.clicks !== 0 ? formatValue(record.clicks, 'number', 0) : '-'}
      </div>
      <div className="table-col">
        {record.orders !== 0 ? formatValue(record.orders, 'number', 0) : '-'}
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { record.revenue > 0 ? formatValue(record.cost / record.revenue * 100, 'percent', 2) : '-' }
      </div>
      <div className="table-col">
        { record.ntb_orders > 0 ? formatValue(record.ntb_orders, 'number', 0) : '-' }
      </div>
      <div className="table-col">
        { record.ntb_sales > 0 ? formatCurrency(record.ntb_sales, currencySign, currencyRate) : '-' }
      </div>
      <div className="table-col">
        { record.ntb_units > 0 ? formatValue(record.ntb_units, 'number', 0) : '-' }
      </div>
      <div className="table-col">
        { record.cost > 0 ? formatValue(record.revenue / record.cost, 'number', 2) : '-' }
      </div>
    </>
  )

  const renderGroupParent = record => (
    <>
      <div className="table-col col-keyword">
        { record.keyword || record.search }
      </div>
      <div className="table-col">
      </div>
      <div className="table-col col-campaign">
      </div>
      <div className="table-col col-target">
      </div>
      <div className="table-col">
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'number', 0) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent', 2) }
      </div>
      <div className="table-col">
        { formatValue(record.roas, 'number', 2) }
      </div>
    </>
  )

  const renderGroupTotal = record => (
    <>
      <div className="table-col col-keyword">
        Totals:
      </div>
      <div className="table-col">
      </div>
      <div className="table-col col-campaign">
      </div>
      <div className="table-col col-target">
      </div>
      <div className="table-col">
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'number', 0) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent', 2) }
      </div>
      <div className="table-col">
        { formatValue(record.roas, 'number', 2) }
      </div>
    </>
  )

  const renderGroupChild = record => (
    <>
      <div className="table-col">
        { record.isSearchTerm ? 'Search Term' : 'Keyword' }
      </div>
      <div className="table-col col-campaign">
        <span className="contents" title={record.campaignName}>
          { record.campaignName }
        </span>
        <span className="campaign-detail">
          { campaignTypeMap[record.adType] }
        </span>
      </div>
      <div className="table-col col-target" title={record.target}>
        <span className="contents">
          { record.target }
        </span>
      </div>
      <div className="table-col">
        { record.match_type }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'number', 0) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'number', 0) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent', 2) }
      </div>
      <div className="table-col">
        { formatValue(record.roas, 'number', 2) }
      </div>
    </>
  )

  const renderTables = () => {
    if (!viewWidgets.length) {
      return null
    }

    return viewWidgets.map((viewWidget) => {
      if (!viewWidget.view && !customizingSection) {
        return null
      }

      let metric
      let isGroupTable = false
      if (viewWidget.key === 'highest_revenue') {
        metric = 'revenue'
      } else if (viewWidget.key === 'profitable_keyword') {
        metric = 'profitable'
      } else if (viewWidget.key === 'expensive_keyword') {
        metric = 'expensive'
      } else if (viewWidget.key === 'keywords_track') {
        metric = 'keywords'
        isGroupTable = true
      } else {
        return null
      }

      if (!isGroupTable) {
        return (
          <ReportTableWidget
            key={metric}
            widget={viewWidget}
            columns={columns}
            records={keywordsReport[metric] || []}
            customizing={customizingSection}
            idField="keyword_id"
            renderRecord={renderRecord}
            isLoading={isLoading}
            onRemove={handleOnRemoveWidget}
            section="keywords"
          />
        )
      }

      return (
        <ReportGroupbTableWidget
          key={metric}
          widget={viewWidget}
          columns={columnsKeywordsGroup}
          sortBy={['cost', 'desc']}
          sorter={tableSorter(['keyword'])}
          sorterChild={tableSorter(['campaignName', 'target', 'match_type'])}
          records={keywordsReport[metric] || []}
          customizing={customizingSection}
          idField="keyword"
          idFieldChild="childKey"
          searchFields={['keyword']}
          isLoading={isLoading}
          onRemove={handleOnRemoveWidget}
          section="keywords"
          renderParent={renderGroupParent}
          renderTotalGroup={renderGroupTotal}
          renderChild={renderGroupChild}
        />
      )
    })
  }

  return (
    <>
      <SectionHeader
        title="Your Keywords"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            { renderTrackedKeywords() }
            {
              (customizingSection || noteWidgetView) && (
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section="keywords"
                />
              )
            }
            { renderTables() }
            {
              isEditingKeywords && (
                <KeywordTrackInputModal
                  existingTargets={keywordsToTrack}
                  onConfirm={handleSaveKeywords}
                  onClose={() => { setIsEditingKeywords(false) }}
                />
              )
            }
          </>
        )
      }
    </>
  )
}

export default withReportSection(KeywordSection, {
  sectionId: 'keywords',
  reportSectionKey: 'keywords',
})
