// Smart pilot manager pane footer.
import React, { useState } from 'react'
import { Dropdown, Modal } from 'rsuite'

import CustomTooltip from '../CommonComponents/CustomTooltip'

const Footer = ({
  settings,
  saveError,
  isLoading,
  isSaving,
  onApplyToMultiple,
  onSave,
  onSaveTemplate,
  onClose,
}) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [templateName, setTemplateName] = useState('')
  const [needApply, setNeedApply] = useState(false)

  const handleSaveSelect = apply => () => {
    setTemplateName('')
    setNeedApply(apply)
    setShowTemplateModal(true)
  }

  const handleTemplateSave = () => {
    onSaveTemplate(templateName, needApply)
    setShowTemplateModal(false)
  }

  return (
    <div className="pane-footer">
      {
        saveError !== null && (
          <div className="save-error">
            { saveError }
          </div>
        )
      }
      <Dropdown
        title="More actions"
        placement="topStart"
        disabled={isLoading || isSaving}
      >
        {
          (settings.adgroup_id === null || settings.adgroup_id === 0) && (
            <Dropdown.Item onSelect={onApplyToMultiple}>
              Apply to multiple campaigns
              <CustomTooltip>
                <p>What will be applied to other campaigns?</p>
                <p>Campaign level settings from this campaign including basic target optimization,
                advanced target optimization, target expansion and negative target automation
                will be applied to selected campaigns.</p>
                <p>NOTE: Ad group level settings, search term expansion,
                and product targeting expansion will NOT be applied to selected campaigns.</p>
              </CustomTooltip>
            </Dropdown.Item>
          )
        }
        <Dropdown.Item onSelect={handleSaveSelect(false)}>
          Save as template
          <CustomTooltip>
            <p>What will be saved in my template?</p>
            <p>Campaign level settings from this campaign including basic target optimization,
            advanced target optimization, target expansion and negative target automation
            will be saved to template.</p>
            <p>NOTE: Ad group level settings, search term expansion,
            and product targeting expansion will NOT be saved to template.</p>
          </CustomTooltip>
        </Dropdown.Item>
        <Dropdown.Item onSelect={handleSaveSelect(true)}>
          Save as template and apply
          <CustomTooltip>
            <p>What will be saved in my template?</p>
            <p>Campaign level settings from this campaign including basic target optimization,
            advanced target optimization, target expansion and negative target automation
            will be saved to template.</p>
            <p>NOTE: Ad group level settings, search term expansion,
            and product targeting expansion will NOT be saved to template.</p>
          </CustomTooltip>
        </Dropdown.Item>
      </Dropdown>
      <Modal backdrop="static" show={showTemplateModal} size="xs">
        <Modal.Body>
          <input
            type="text"
            className="input-template-name"
            value={templateName}
            placeholder="Enter a template name"
            onChange={(event) => { setTemplateName(event.target.value) }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="rs-btn rs-btn-primary"
            disabled={(templateName === '') || isSaving}
            onClick={handleTemplateSave}
          >
            Save
          </button>
          <button type="button" className="rs-btn rs-btn-subtle" onClick={() => { setShowTemplateModal(false) }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <button
        type="button"
        className="btn btn-blue"
        disabled={isLoading || isSaving}
        onClick={onSave}
      >
        Apply Changes
      </button>
      <button
        type="button"
        className="btn btn-white"
        disabled={isSaving}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  )
}

export default Footer
