import React from 'react'
import { formatISO, isDate, parseISO, format } from 'date-fns'

import { SP_APP_ID, SP_BETA, endpoint } from '../config/api'
import {
  campaignTypeMap,
  ADJUST_BID_RAISE_ABSOLUTE,
  ADJUST_BID_LOWER_ABSOLUTE,
  ADJUST_BID_RAISE_PERCENT,
  ADJUST_BID_LOWER_PERCENT,
  ADJUST_BID_CPC,
  ADJUST_BID_CPC_RAISE_ABSOLUTE,
  ADJUST_BID_CPC_LOWER_ABSOLUTE,
  ADJUST_BID_CPC_RAISE_PERCENT,
  ADJUST_BID_CPC_LOWER_PERCENT,
  currencyList,
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  RULE_TYPE_EXCLUDE_DATA,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_DEC,
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
  LANDING_PAGE_TYPE_NEW_LANDING_PAGE,
  LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE,
  LANDING_PAGE_TYPE_STORE,
  GOAL_BRAND_IMPRESSION_SHARE,
  JOB_TYPE_BULK_SKU_OP,
  JOB_TYPE_BULK_BID_OP,
  JOB_TYPE_BULK_ST_OP,
  JOB_TYPE_BULK_NEGATIVE,
  JOB_TYPE_BULK_PLACEMENT_OP,
  JOB_TYPE_BULK_TARGET_EX,
  JOB_TYPE_BULK_ST_EX,
  JOB_TYPE_BULK_ADVANCED_NEGATIVE,
  JOB_TYPE_BULK_PRODUCT_EX,
  JOB_TYPE_BULK_TARGET_SEARCH,
  JOB_TYPE_BULK_DUPLICATE_TARGETS,
  MODULE_NAME_SKU_OP,
  MODULE_NAME_TARGET_OP,
  MODULE_NAME_ST_OP,
  MODULE_NAME_NEGATIVE,
  MODULE_NAME_PLACEMENT_OP,
  MODULE_NAME_TARGET_EX,
  MODULE_NAME_ST_EX,
  MODULE_NAME_TARGET_SEARCH,
  MODULE_NAME_DUPLICATE_TARGETS,
  MODULE_NAME_ADVANCED,
  MODULE_NAME_PT_EX_ASIN,
  MODULE_NAME_PT_EX_CATEGORY,
  BULK_ACTION_EXPENSIVE,
  BULK_ACTION_NO_IMPRESSION,
  BULK_ACTION_LOW_CONVERSION,
  BULK_ACTION_NO_SALES,
  BULK_ACTION_NAME_EXPENSIVE,
  BULK_ACTION_NAME_NO_IMPRESSION,
  BULK_ACTION_NAME_LOW_CONVERSION,
  BULK_ACTION_NAME_NO_SALES,
  BULK_ACTION_NAME_NEGATIVES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
  JOB_TYPE_BULK_CREATE_PRODUCT_ADS,
  JOB_TYPE_BULK_CREATE_TARGETS,
  JOB_TYPE_BULK_CREATE_NEGATIVES,
  JOB_TYPE_BULK_UPDATE_PA_STATES,
  JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
  JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
  JOB_TYPE_BULK_UPDATE_TARGET_STATES,
  JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
  JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS,
  JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS,
  JOB_TYPE_ACTIVITY_LOG_DOWNLOAD,
  JOB_TYPE_AUDIT_REPORT,
} from '../utils/defaultValues'

const removeSpecialChar = (value) => {
  if (!value) {
    return 0
  }
  const valueStr = value.toString()
  return valueStr.replace(/[$,%]/g, '')
}

export const parseDate = value => {
  if (isDate(value)) {
    return value
  }
  if (typeof value === 'number') {
    return value
  }
  return parseISO(value)
}

export const getISODate = value => (
  formatISO(parseDate(value), { representation: 'date' })
)

// Assuming that we allow 2 numbers at most after a decimal point,
// we don't check if we place commas after a decimal point.
// By assuming so, we don't need to use `negative lookbehind` regex
// which is not supported on Safari.
export const formatValue = (value, type, decimals = 2, currencySign = '$') => {
  const valueSanitized = removeSpecialChar(value)
  if (isNaN(valueSanitized)) {
    return value
  }
  const valueFixed = parseFloat(valueSanitized || 0).toFixed(decimals)
  switch (type) {
    case 'currency':
      return currencySign + valueFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    case 'percent':
      return valueFixed + '%'
    case 'number':
      return valueFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    case 'removeZeroDecimal':
      return parseFloat(valueFixed).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    default:
      return valueFixed
  }
}

export const formatCurrency = (
  value,
  currencySign,
  currencyRate,
  decimals = 2,
  trimForBigNumbers = false,
) => {
  if (!trimForBigNumbers) {
    return formatValue(value * currencyRate, 'currency', decimals, currencySign)
  }

  // If `trimForBigNumbers` is true, for big numbers (> 100,000),
  // we omit decimal numbers.
  const realValue = value * currencyRate
  if (realValue <= 100000) {
    return formatValue(realValue, 'currency', decimals, currencySign)
  }
  return formatValue(realValue, 'currency', 0, currencySign)
}

export const parseMoneyAsFloat = (value, currency_sign) => {
  if (!value) {
    return 0
  }
  const valueStr = value.toString()
  return parseFloat(valueStr.replace(new RegExp(`[$,%${currency_sign}]`, 'g'), ''))
}

export const checkSpecialCharacter = (str) => {
  const regexp = /[`ģń~!@#$%^*()_|=?;:",<>{}[]\\\/]/gi
  return str.match(regexp)
}

export const capitalizeFirstLetter = (string) => {
  if (!string || string === '') {
    return ''
  }
  const [firstLetter, ...restLetters] = string.toLowerCase()
  return firstLetter.toUpperCase() + restLetters.join('')
}

// Default smart pilot settings.
export const apSettings = {
  // Ad group selection.
  adgroup_id: { default: 0, type: 'int' },
  op_lookback: { default: 30, type: 'int' },
  ex_lookback: { default: 90, type: 'int' },
  ex_st_lookback: { default: 90, type: 'int' },
  ex_pt_lookback: { default: 90, type: 'int' },
  // Keyword basic settings.
  op_basic_active: { default: true },
  target_acos: { default: 30 },
  min_bid_price: { default: 0.15 },
  max_bid_price: { default: 2 },
  min_clicks: { default: 10, type: 'int' },
  min_impressions: { default: 1000, type: 'int' },
  placement_active: { default: false },
  placement_max_change: { default: 10 },
  // Keyword advanced settings.
  // Zero sale targets
  op_adv_zero_sale_active: { default: false },
  op_adv_zero_sale_clicks: { default: 8, type: 'int' },
  op_adv_zero_sale_freq_type: { default: 'daily' },
  op_adv_zero_sale_freq_weekly: { default: [], type: 'json_array' },
  op_adv_zero_sale_freq_monthly: { default: '' },
  op_adv_zero_sale_lookback: { default: 7, type: 'int' },
  op_adv_zero_sale_action: { default: '' },
  op_adv_zero_sale_action_value: { default: '' },
  op_adv_zero_sale_cost_active: { default: false },
  op_adv_zero_sale_cost_threshold: { default: 0 },

  copy_op_adv_zero_sale_active: { default: false },
  copy_op_adv_zero_sale_clicks: { default: 8, type: 'int' },
  copy_op_adv_zero_sale_freq_type: { default: 'daily' },
  copy_op_adv_zero_sale_freq_weekly: { default: [], type: 'json_array' },
  copy_op_adv_zero_sale_freq_monthly: { default: '' },
  copy_op_adv_zero_sale_lookback: { default: 7, type: 'int' },
  copy_op_adv_zero_sale_action: { default: '' },
  copy_op_adv_zero_sale_action_value: { default: '' },
  copy_op_adv_zero_sale_cost_active: { default: false },
  copy_op_adv_zero_sale_cost_threshold: { default: 0 },
  // Low CTR targets
  op_adv_low_ctr_active: { default: false },
  op_adv_low_ctr_impressions: { default: 1000, type: 'int' },
  op_adv_low_ctr_ctr: { default: 0.15 },
  op_adv_low_ctr_freq_type: { default: 'daily' },
  op_adv_low_ctr_freq_weekly: { default: [], type: 'json_array' },
  op_adv_low_ctr_freq_monthly: { default: '' },
  op_adv_low_ctr_lookback: { default: 7, type: 'int' },
  op_adv_low_ctr_action: { default: '' },
  op_adv_low_ctr_action_value: { default: '' },
  op_adv_low_ctr_cost_active: { default: false },
  op_adv_low_ctr_cost_threshold: { default: 0 },

  copy_op_adv_low_ctr_active: { default: false },
  copy_op_adv_low_ctr_impressions: { default: 1000, type: 'int' },
  copy_op_adv_low_ctr_ctr: { default: 0.15 },
  copy_op_adv_low_ctr_freq_type: { default: 'daily' },
  copy_op_adv_low_ctr_freq_weekly: { default: [], type: 'json_array' },
  copy_op_adv_low_ctr_freq_monthly: { default: '' },
  copy_op_adv_low_ctr_lookback: { default: 7, type: 'int' },
  copy_op_adv_low_ctr_action: { default: '' },
  copy_op_adv_low_ctr_action_value: { default: '' },
  copy_op_adv_low_ctr_cost_active: { default: false },
  copy_op_adv_low_ctr_cost_threshold: { default: 0 },
  // Low Converting targets
  op_adv_low_conversion_active: { default: false },
  op_adv_low_conversion_conversion: { default: 5 },
  op_adv_low_conversion_freq_type: { default: 'daily' },
  op_adv_low_conversion_freq_weekly: { default: [], type: 'json_array' },
  op_adv_low_conversion_freq_monthly: { default: '' },
  op_adv_low_conversion_lookback: { default: 7, type: 'int' },
  op_adv_low_conversion_action: { default: '' },
  op_adv_low_conversion_action_value: { default: '' },
  op_adv_low_conversion_cost_active: { default: false },
  op_adv_low_conversion_cost_threshold: { default: 0 },

  copy_op_adv_low_conversion_active: { default: false },
  copy_op_adv_low_conversion_conversion: { default: 5 },
  copy_op_adv_low_conversion_freq_type: { default: 'daily'} ,
  copy_op_adv_low_conversion_freq_weekly: { default: [], type: 'json_array' },
  copy_op_adv_low_conversion_freq_monthly: { default: '' },
  copy_op_adv_low_conversion_lookback: { default: 7, type: 'int' },
  copy_op_adv_low_conversion_action: { default: '' },
  copy_op_adv_low_conversion_action_value: { default: '' },
  copy_op_adv_low_conversion_cost_active: { default: false },
  copy_op_adv_low_conversion_cost_threshold: { default: 0 },
  // High ACoS targets
  op_adv_high_acos_active: { default: false },
  op_adv_high_acos_acos: { default: 100 },
  op_adv_high_acos_clicks: { default: 8, type: 'int' },
  op_adv_high_acos_freq_type: { default: 'daily' },
  op_adv_high_acos_freq_weekly: { default: [], type: 'json_array' },
  op_adv_high_acos_freq_monthly: { default: '' },
  op_adv_high_acos_lookback: { default: 7, type: 'int' },
  op_adv_high_acos_action: { default: '' },
  op_adv_high_acos_action_value: { default: '' },
  op_adv_high_acos_cost_active: { default: false },
  op_adv_high_acos_cost_threshold: { default: 0 },

  copy_op_adv_high_acos_active: { default: false },
  copy_op_adv_high_acos_acos: { default: 100 },
  copy_op_adv_high_acos_clicks: { default: 8, type: 'int' },
  copy_op_adv_high_acos_freq_type: { default: 'daily' },
  copy_op_adv_high_acos_freq_weekly: { default: [], type: 'json_array' },
  copy_op_adv_high_acos_freq_monthly: { default: '' },
  copy_op_adv_high_acos_lookback: { default: 7, type: 'int' },
  copy_op_adv_high_acos_action: { default: '' },
  copy_op_adv_high_acos_action_value: { default: '' },
  copy_op_adv_high_acos_cost_active: { default: false },
  copy_op_adv_high_acos_cost_threshold: { default: 0 },
  // Unprofitable targets
  op_adv_genius_bid_active: { default: false },
  op_adv_genius_bid_min_acos: { default: 50 },
  op_adv_genius_bid_max_acos: { default: 80 },
  op_adv_genius_bid_clicks: { default: 8, type: 'int' },
  op_adv_genius_bid_freq_type: { default: 'daily' },
  op_adv_genius_bid_freq_weekly: { default: [], type: 'json_array' },
  op_adv_genius_bid_freq_monthly: { default: '' },
  op_adv_genius_bid_lookback: { default: 7, type: 'int' },
  op_adv_genius_bid_action: { default: '' },
  op_adv_genius_bid_action_value: { default: '' },
  op_adv_genius_bid_cost_active: { default: false },
  op_adv_genius_bid_cost_threshold: { default: 0 },

  copy_op_adv_genius_bid_active: { default: false },
  copy_op_adv_genius_bid_min_acos: { default: 50 },
  copy_op_adv_genius_bid_max_acos: { default: 80 },
  copy_op_adv_genius_bid_clicks: { default: 8, type: 'int' },
  copy_op_adv_genius_bid_freq_type: { default: 'daily' },
  copy_op_adv_genius_bid_freq_weekly: { default: [], type: 'json_array' },
  copy_op_adv_genius_bid_freq_monthly: { default: '' },
  copy_op_adv_genius_bid_lookback: { default: 7, type: 'int' },
  copy_op_adv_genius_bid_action: { default: '' },
  copy_op_adv_genius_bid_action_value: { default: '' },
  copy_op_adv_genius_bid_cost_active: { default: false },
  copy_op_adv_genius_bid_cost_threshold: { default: 0 },
  // Ignore keywords
  ignore_keywords: { default: [], type: 'json_array' },
  // Negative Target Automation.
  nta_active: { default: false },
  nta_negate_asins_active: { default: true },
  nta_add_to_campaigns: { default: false },
  nta_add_to_adgroups: { default: false },
  nta_clicks_active: { default: false },
  nta_clicks: { default: 10, type: 'int' },
  nta_impressions_active: { default: false },
  nta_impressions: { default: 1000 , type: 'int'},
  nta_cost_active: { default: false },
  nta_cost: { default: 10 },
  nta_ctr_active: { default: false },
  nta_ctr: { default: 0.1 },
  nta_ctr_greater_active: { default: false },
  nta_ctr_greater: { default: 0.1 },
  nta_freq_type: { default: 'daily' },
  nta_freq_weekly: { default: [], type: 'json_array' },
  nta_freq_monthly: { default: '' },
  nta_lookback: { default: 30, type: 'int' },

  copy_nta_active: { default: false },
  copy_nta_negate_asins_active: { default: true },
  copy_nta_add_to_campaigns: { default: false },
  copy_nta_add_to_adgroups: { default: false },
  copy_nta_clicks_active: { default: false },
  copy_nta_clicks: { default: 10, type: 'int' },
  copy_nta_impressions_active: { default: false },
  copy_nta_impressions: { default: 1000, type: 'int' },
  copy_nta_cost_active: { default: false },
  copy_nta_cost: { default: 10 },
  copy_nta_ctr_active: { default: false },
  copy_nta_ctr: { default: 0.1 },
  copy_nta_ctr_greater_active: { default: false },
  copy_nta_ctr_greater: { default: 0.1 },
  copy_nta_freq_type: { default: 'daily' },
  copy_nta_freq_weekly: { default: [], type: 'json_array' },
  copy_nta_freq_monthly: { default: '' },
  copy_nta_lookback: { default: 30, type: 'int' },
  // Negative Product Targeting.
  npt_active: { default: false },
  npt_clicks: { default: 3, type: 'int' },
  npt_freq_type: { default: 'daily' },
  npt_freq_weekly: { default: [], type: 'json_array' },
  npt_freq_monthly: { default: '' },
  npt_lookback: { default: 30, type: 'int' },

  copy_npt_active: { default: false },
  copy_npt_clicks: { default: 3, type: 'int' },
  copy_npt_freq_type: { default: 'daily' },
  copy_npt_freq_weekly: { default: [], type: 'json_array' },
  copy_npt_freq_monthly: { default: '' },
  copy_npt_lookback: { default: 30, type: 'int' },
  // Split testing.
  // FIXME: Some database records are corrupted to have
  // `test_active` as true and `test_lookback`,
  // `test_start_date` as NULL.
  test_active: { default: false },
  test_lookback: { default: 14, type: 'int' },
  test_send_email: { default: false },
  test_start_date: { default: null, type: 'date' },
  // Keyword/Targeting Expansion.
  ex_impressions_unit: { default: '%' },
  ex_impressions_active: { default: false },
  ex_impressions_impressions: { default: 100, type: 'int' },
  ex_clicks_active: { default: false },
  ex_clicks: { default: 0, type: 'int' },
  ex_cost_active: { default: false },
  ex_cost: { default: 0 },
  ex_revenue_active: { default: false },
  ex_revenue: { default: 0 },
  ex_impressions_amount: { default: 10, type: 'int' },
  ex_impressions_max_bid_price: { default: 3 },
  ex_freq_type: { default: 'daily' },
  ex_freq_weekly: { default: [], type: 'json_array' },
  ex_freq_monthly: { default: '' },
  // Search Term Expansion.
  ex_st_active: { default: false },
  ex_st_acos: { default: 30 },
  ex_st_campaigns: { default: [], type: 'concat' },
  ex_st_orders: { default: 3, type: 'int' },
  ex_st_new_only: { default: true },
  ex_st_ctr_active: { default: false },
  ex_st_ctr: { default: 0 },
  ex_st_conversion_active: { default: false },
  ex_st_conversion: { default: 0 },
  ex_st_adgroups: { default: [], type: 'json_array' },
  ex_st_negate_parent: { default: false },
  ex_st_freq_type: { default: 'daily' },
  ex_st_freq_weekly: { default: [], type: 'json_array' },
  ex_st_freq_monthly: { default: '' },
  // Product Targeting.
  ex_pt_active: { default: false },
  ex_pt_acos: { default: 30 },
  ex_pt_campaigns: { default: [], type: 'concat' },
  ex_pt_orders: { default: 3, type: 'int' },
  ex_pt_new_only: { default: true },
  ex_pt_adgroups: { default: [], type: 'json_array' },
  ex_pt_negate_parent: { default: false },
  ex_pt_freq_type: { default: 'daily' },
  ex_pt_freq_weekly: { default: [], type: 'json_array' },
  ex_pt_freq_monthly: { default: '' },
}

export const getDefaultAPSettings = (adgroupId, targetAcos) => {
  const settings = {}
  Object.keys(apSettings).forEach((key) => {
    settings[key] = apSettings[key].default
  })
  // If there is no AP record saved for a given ad group,
  // set `adgroup_id` setting so that a correct ad group
  // is selected in the selector.
  if (adgroupId) {
    settings.adgroup_id = adgroupId
  }
  if (targetAcos) {
    settings.target_acos = targetAcos
  }
  return settings
}

// Parse JSON string of array.
const parseJsonArray = (value) => {
  try {
    return JSON.parse(value || '[]')
  } catch (e) {
    return []
  }
}

// Parse a string value of concatenated array elements into array.
const parseConcatenatedArray = (value) => {
  if (Array.isArray(value)) {
    return value
  }
  return !value ? [] : value.split(',')
}

// Parse smart pilot settings retrieved from backend.
export const parseAPSettings = (ap) => {
  Object.keys(ap).forEach((key) => {
    if (!apSettings[key]) {
      return
    }
    if (apSettings[key].type === 'json_array') {
      // Parse array-type settings.
      // `filter` method here removes NULL values.
      ap[key] = parseJsonArray(ap[key]).filter(s => s === 0 || s)
    } else if (apSettings[key].type === 'concat') {
      ap[key] = parseConcatenatedArray(ap[key])
    } else if (apSettings[key].type === 'date') {
      // Parse date strings to date objects.
      ap[key] = ap[key] ? new Date(ap[key]) : ap[key]
    } else if (apSettings[key].type === 'int') {
      let value = ap[key]
      if (
        typeof ap[key] === 'undefined'
        || ap[key] === null
        || isNaN(ap[key])
      ) {
        value = apSettings[key].default
      }
      ap[key] = parseInt(value, 10)
    } else {
      // Set default values.
      if (typeof ap[key] === 'undefined'
        || ap[key] === null
        || ap[key] === '') {
        ap[key] = apSettings[key].default
      }
    }
  })

  return ap
}

// Categorize adgroups into keyword targetings and product targetings.
export const categorizeAdgroups = (adgroups, targetings) => {
  const targetingIds = targetings.map(targeting => targeting.adgroup_id.toString())
  const keywordAdgroups = []
  const productAdgroups = []
  adgroups.forEach((adgroup) => {
    if (targetingIds.indexOf(adgroup.id.toString()) === -1) {
      keywordAdgroups.push(Object.assign({}, adgroup, {
        id: adgroup.id.toString(),
      }))
    } else {
      productAdgroups.push(Object.assign({}, adgroup, {
        id: adgroup.id.toString(),
      }))
    }
  })
  return { keywordAdgroups, productAdgroups }
}

// Copy text to clipboard.
export const copyToClipboard = (text) => {
  const textField = document.createElement('textarea')
  textField.innerHTML = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

// Return a sorter function to sort rows in tables.
// Accept a list of field names whose data type is string.
// sortOrder - asc/desc
export const tableSorter = (stringFields = []) => (items, [sortBy, sortOrder]) => {
  const sortedItems = [...items]

  sortedItems.sort((a, b) => {
    let comparison
    if (stringFields.indexOf(sortBy) !== -1) {
      comparison = (a[sortBy] || '').toLowerCase().localeCompare((b[sortBy] || '').toLowerCase())
    } else {
      comparison = parseFloat(a[sortBy] || 0) - parseFloat(b[sortBy] || 0)
    }
    return sortOrder === 'asc' ? comparison : -comparison
  })

  return sortedItems
}

// Return a user-friendly description for log types.
export const getLogDescription = (logType, description, createdAt) => {
  switch (logType) {
    case 'ap_dayparting': return 'Dayparting/Seasonality'
    case 'ap_kw_adv': return 'Target Bid Optimization Advanced Settings'
    case 'ap_kw_basic': return 'Target Bid Optimization Basic Settings'
    case 'ap_kw_ex': return 'Target Bid Expansion'
    case 'ap_npt': return 'Negative Product Targeting'
    case 'ap_nta_asin': return 'Negative Target Automation/Add ASINs as Negatives'
    case 'ap_pt_ex': return 'Product Targeting Expansion'
    case 'ap_setup': return 'Smart Pilot Setup'
    case 'ap_setup_multiple': return 'Smart Pilot - Multiple Campaigns'
    case 'ap_test': return 'Smart Pilot – AB Test'
    case 'ap_update': return 'Smart Pilot Update'
    case 'ap_placement': return 'Placement Optimization'
    case 'rule_dayparting':
      return `${description} at ${format(parseISO(createdAt), 'HH:mm')}`
    // Fallback to description.
    case 'ad_create':
    case 'ad_state_change':
    case 'adgroup_create':
    case 'adgroup_name_change':
    case 'adgroup_state_change':
    case 'ap_nta':
    case 'ap_st_ex':
    case 'ap_st_negate':
    case 'bid_placement_change':
    case 'bulk_keyword_bid_change':
    case 'bulk_keyword_state_change':
    case 'bulk_negative_word':
    case 'bulk_sku':
    case 'bulk_st':
    case 'campaign_acos_update':
    case 'campaign_create':
    case 'campaign_name_change':
    case 'campaign_state_change':
    case 'daily_budget_change':
    case 'default_bid_change':
    case 'keyword_add':
    case 'keyword_bid_change':
    case 'keyword_state_change':
    case 'negative_add_adgroup':
    case 'negative_add_campaign':
    case 'negative_remove_adgroup':
    case 'negative_remove_campaign':
    case 'nk_add':
    case 'nk_add_campaign':
    case 'npt_add_adgroup':
    case 'npt_add_campaign':
    case 'sku_state_change':
    default:
      return description
  }
}

const parseIndividualTargetExp = (exp) => {
  const { type, value } = exp
  if (type === 'asinSameAs') {
    return `asin="${value}"`
  }
  if (type === 'asinCategorySameAs') {
    return `category="${value}"`
  }
  if (type === 'asinBrandSameAs') {
    return `brand="${value}"`
  }
  if (type === 'asinGenreSameAs') {
    return `genre="${value}"`
  }
  if (type === 'asinExpandedFrom') {
    return `expanded="${value}"`
  }
  if (type === 'queryHighRelMatches') {
    return 'close-match'
  }
  if (type === 'queryBroadRelMatches') {
    return 'loose-match'
  }
  if (type === 'asinSubstituteRelated') {
    return 'substitutes'
  }
  if (type === 'asinAccessoryRelated') {
    return 'complements'
  }
  if (type === 'similarProduct') {
    return 'similar-product'
  }
  if (type === 'exactProduct') {
    return 'exact-product'
  }
  if (type === 'relatedProduct') {
    return 'related-product'
  }
  if (type === 'audienceSameAs') {
    return value
  }
  if (type === 'lookback') {
    return `lookback=${value}`
  }
  if (type === 'asinReviewRatingGreaterThan') {
    return `review rating>${value}`
  }
  if (type === 'asinReviewRatingLessThan') {
    return `review rating<${value}`
  }
  if (type === 'asinReviewRatingBetween') {
    return `review rating:${value}`
  }
  if (type === 'asinPriceGreaterThan') {
    return `price>${value}`
  }
  if (type === 'asinPriceLessThan') {
    return `price<${value}`
  }
  if (type === 'asinPriceBetween') {
    return `price:${value}`
  }
  if (type === 'asinAgeRangeSameAs') {
    return `age range:${value}`
  }
  if (type === 'asinIsPrimeShippingEligible') {
    return `prime shipping eligible:${value}`
  }
  if (type === 'negative') {
    return 'negative'
  }
  if (type === 'audience') {
    const children = value.map(v => parseIndividualTargetExp(v)).join(' ')
    return `audience(${children})`
  }
  if (type === 'views') {
    const children = value.map(v => parseIndividualTargetExp(v)).join(' ')
    return `views(${children})`
  }
  if (type === 'purchases') {
    const children = value.map(v => parseIndividualTargetExp(v)).join(' ')
    return `purchases(${children})`
  }
  return JSON.stringify(exp)
}

// Parse targeting expression and return human-friendly expression.
export const parseTargetExp = (exp) => {
  try {
    const parsed = JSON.parse(exp)
    if (!Array.isArray(parsed) || !parsed.length) {
      return exp
    }
    return parsed.map(v => parseIndividualTargetExp(v)).join(' ')
  } catch (e) {
    return exp
  }
}

// Parse ASIN targeting expression and return ASIN.
export const parseAsinTarget = (exp) => {
  const resolvedExp = parseTargetExp(exp)

  const textPos = resolvedExp.indexOf('asin=')
  if (textPos !== -1) {
    return resolvedExp.substr(textPos + 6, 10)
  }
  return resolvedExp
}

// Check if a record matches a list of filters.
export const matchFilter = (record, filterList, filterValues) => {
  const unmatching = filterList.filter((filter) => {
    // This type of filters are processed using a custom logic
    // by individual tables/components.
    if (filter.customProcessing) {
      return false
    }

    if (filter.type === 'select') {
      if (filterValues[filter.key] && filterValues[filter.key].value) {
        let selectedValue = filterValues[filter.key].value
        if (!Array.isArray(selectedValue)) {
          selectedValue = [selectedValue]
        }
        if (selectedValue.indexOf((record[filter.key] || '').toLowerCase()) === -1) {
          return true
        }
      }
    } else if (filter.type === 'target_acos') {
      if (filterValues[filter.key] === true
        && record.acos
        && record.acos < parseFloat(record[filter.key])) {
        return true
      }
    } else {
      const min = filterValues[`${filter.key}Min`] || ''
      if (min !== '' && !isNaN(min)) {
        if (parseFloat(record[filter.key] || 0) < parseFloat(min)) {
          return true
        }
      }

      const max = filterValues[`${filter.key}Max`] || ''
      if (max !== '' && !isNaN(max)) {
        if (parseFloat(record[filter.key] || 0) > parseFloat(max)) {
          return true
        }
      }
    }

    return false
  })
  return unmatching.length === 0
}

// Calculate derived metrics, such as ACoS, CTR, etc.
export const calcDerivedMetrics = (record) => {
  let ctr = 0
  let cpc = 0
  let conversion = 0
  let click_order = 0
  let acos = 0
  let roas = 0
  let ntb_orders_percent = 0
  let ntb_sales_percent = 0
  let vtr = 0
  let vctr = 0
  let view_5_seconds_rate = 0
  let vcpm = 0

  if (parseInt(record.impressions, 10)) {
    ctr = parseInt(record.clicks, 10) / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.clicks, 10)) {
    cpc = parseFloat(record.cost) / parseInt(record.clicks, 10)
    conversion = parseInt(record.orders, 10) / parseInt(record.clicks, 10) * 100
  }
  if (parseInt(record.orders, 10)) {
    click_order = parseInt(record.clicks, 10) / parseInt(record.orders, 10)
    ntb_orders_percent = parseInt(record.ntb_orders || 0, 10) / parseInt(record.orders, 10) * 100
  }
  if (parseFloat(record.revenue)) {
    acos = parseFloat(record.cost) / parseFloat(record.revenue) * 100
    ntb_sales_percent = parseFloat(record.ntb_sales || 0) / parseFloat(record.revenue) * 100
  }
  if (parseFloat(record.cost)) {
    roas = parseFloat(record.revenue) / parseFloat(record.cost)
  }
  if (parseInt(record.impressions, 10)) {
    vtr = parseInt(record.viewable_impressions, 10)
      / parseInt(record.impressions, 10) * 100
  }
  if (parseInt(record.viewable_impressions, 10)) {
    vctr = parseInt(record.clicks, 10) / parseInt(record.viewable_impressions, 10) * 100
    vcpm = parseFloat(record.cost) / (parseInt(record.viewable_impressions, 10) / 1000)
  }
  if (parseInt(record.impressions, 10)) {
    view_5_seconds_rate = parseInt(record.video_5s_views || 0, 10) / parseInt(record.impressions, 10) * 100
  }

  return {
    ...record,
    ctr,
    cpc,
    conversion,
    click_order,
    acos,
    roas,
    ntb_orders_percent,
    ntb_sales_percent,
    vtr,
    vctr,
    view_5_seconds_rate,
    vcpm,
  }
}

export const CURRENCY_LIST = {
  us: 'USD',
  ca: 'CAD',
  mx: 'MXN',
  br: 'BRL',
  gb: 'GBP',
  de: 'EUR',
  fr: 'EUR',
  es: 'EUR',
  it: 'EUR',
  nl: 'EUR',
  ae: 'AED',
  se: 'SEK',
  pl: 'PLN',
  tr: 'TRY',
  eg: 'EGP',
  sa: 'SAR',
  in: 'INR',
  jp: 'JPY',
  au: 'AUD',
  sg: 'SGD',
  be: 'EUR',
}

/**
 * Return bid limits enforced by Amazon.
 * @see https://advertising.amazon.com/API/docs/en-us/reference/concepts/limits
 */
export const getBidLimits = (adType, countryCode, costType = '') => {
  const currency = CURRENCY_LIST[countryCode] || ''
  if (adType === 'sp') {
    switch (currency) {
      case 'GBP':
      case 'USD':
      case 'CAD':
      case 'EUR':
        return [0.02, 1000];
      case 'CNY':
        return [0.1, 1000];
      case 'JPY':
        return [2, 100000];
      case 'INR':
        return [1, 5000];
      case 'AED':
        return [0.24, 184];
      case 'MXN':
        return [0.1, 20000];
      case 'AUD':
        return [0.1, 1410];
      case 'BRL':
        return [0.07, 3700];
      case 'SGD':
        return [0.02, 1100];
      case 'SEK':
        return [0.18, 9300];
      case 'PLN':
        return [0.04, 2000];
      case 'TRY':
        return [0.05, 2500];
      case 'EGP':
        return [0.15, 5.5];
      case 'SAR':
        return [0.1, 3670];
      default:
        return [0.02, 1000];
    }
  } else if (adType === 'sb') {
    switch (currency) {
      case 'GBP':
        return costType !== 'VCPM' ? [0.1, 31] : [4, 5000];
      case 'USD':
        return costType !== 'VCPM' ? [0.1, 49] : [8, 5000];
      case 'CAD':
        return costType !== 'VCPM' ? [0.1, 49] : [11, 5000];
      case 'EUR':
        if (countryCode === 'de') {
          return costType !== 'VCPM' ? [0.1, 39] : [5, 5000];
        }
        if (countryCode === 'fr'
          || countryCode === 'es'
          || countryCode === 'it') {
          return costType !== 'VCPM' ? [0.1, 39] : [4, 5000];
        }
        if (countryCode === 'nl') {
          return costType !== 'VCPM' ? [0.1, 39] : [6, 1560];
        }
        if (countryCode === 'be') {
          return costType !== 'VCPM' ? [0.1, 39] : [4, 1560];
        }
        return [0.1, 39];
      case 'CNY':
        return [1, 50];
      case 'JPY':
        return costType !== 'VCPM' ? [10, 7760] : [560, 310400];
      case 'INR':
        return costType !== 'VCPM' ? [1, 500] : [84, 80000];
      case 'AED':
        return costType !== 'VCPM' ? [0.4, 184] : [44, 7360];
      case 'MXN':
        return costType !== 'VCPM' ? [0.1, 20000] : [128, 800000];
      case 'AUD':
        return costType !== 'VCPM' ? [0.1, 70] : [14, 2800];
      case 'BRL':
        return costType !== 'VCPM' ? [0.53, 200] : [37, 8000];
      case 'SGD':
        return costType !== 'VCPM' ? [0.14, 100] : [6, 4000];
      case 'SEK':
        return costType !== 'VCPM' ? [0.9, 500] : [36, 20000];
      case 'PLN':
        return costType !== 'VCPM' ? [0.2, 200] : [92, 8000];
      case 'TRY':
        return costType !== 'VCPM' ? [0.2, 200] : [74, 8000];
      case 'EGP':
        return costType !== 'VCPM' ? [0.7, 400] : [160, 16000];
      case 'SAR':
        return costType !== 'VCPM' ? [0.4, 184] : [28, 7360];
      default:
        return [0.1, 49];
    }
  } else if (adType === 'sbv') {
    switch (currency) {
      case 'GBP':
        return costType !== 'VCPM' ? [0.15, 31] : [8, 5000];
      case 'USD':
        return costType !== 'VCPM' ? [0.25, 49] : [12, 5000];
      case 'CAD':
        return costType !== 'VCPM' ? [0.15, 49] : [11, 5000];
      case 'EUR':
        if (countryCode === 'de' || countryCode === 'es') {
          return costType !== 'VCPM' ? [0.15, 39] : [7, 5000];
        }
        if (countryCode === 'fr') {
          return costType !== 'VCPM' ? [0.15, 39] : [8, 5000];
        }
        if (countryCode === 'it') {
          return costType !== 'VCPM' ? [0.15, 39] : [6, 5000];
        }
        if (countryCode === 'nl') {
          return costType !== 'VCPM' ? [0.15, 39] : [8, 1560];
        }
        if (countryCode === 'be') {
          return costType !== 'VCPM' ? [0.15, 39] : [6, 1560];
        }
        return [0.15, 39];
      case 'MXN':
        return costType !== 'VCPM' ? [0.15, 20000] : [80, 800000];
      case 'AUD':
        return costType !== 'VCPM' ? [0.15, 70] : [20, 2800];
      case 'JPY':
        return costType !== 'VCPM' ? [15, 7760] : [800, 310400];
      case 'AED':
        return costType !== 'VCPM' ? [0.6, 184] : [28, 7360];
      case 'BRL':
        return costType !== 'VCPM' ? [0.8, 25000] : [53, 8000];
      case 'SGD':
        return costType !== 'VCPM' ? [0.2, 1400] : [29, 4000];
      case 'SEK':
        return costType !== 'VCPM' ? [1.3, 500] : [276, 20000];
      case 'INR':
        return costType !== 'VCPM' ? [1.5, 500] : [200, 80000];
      case 'PLN':
        return costType !== 'VCPM' ? [0.3, 200] : [180, 8000];
      case 'TRY':
        return costType !== 'VCPM' ? [0.3, 200] : [106, 8000];
      case 'EGP':
        return costType !== 'VCPM' ? [1, 400] : [300, 16000];
      case 'SAR':
        return costType !== 'VCPM' ? [0.6, 184] : [40, 7360];
      default:
        return [0.25, 49];
    }
  } else if (adType === 'sd') {
    // For SD, limits are different for CPC and VCPM campaigns.
    switch (currency) {
      case 'GBP':
      case 'USD':
      case 'CAD':
      case 'EUR':
        return costType !== 'vcpm' ? [0.02, 1000] : [1, 1000];
      case 'CNY':
        return [0.1, 1000];
      case 'JPY':
        return costType !== 'vcpm' ? [2, 100000] : [100, 100000];
      case 'INR':
        return costType !== 'vcpm' ? [1, 5000] : [4, 5000];
      case 'AED':
        return costType !== 'vcpm' ? [0.2, 3670] : [1, 3670];
      case 'MXN':
        return costType !== 'vcpm' ? [0.1, 20000] : [5, 20000];
      case 'AUD':
        return costType !== 'vcpm' ? [0.2, 1000] : [1, 1000];
      case 'BRL':
        return costType !== 'vcpm' ? [0.07, 3700] : [2, 3700];
      case 'SGD':
        return costType !== 'vcpm' ? [0.14, 1410] : [4, 1410];
      case 'SEK':
        return costType !== 'vcpm' ? [0.18, 1000] : [1, 1000];
      case 'PLN':
        return costType !== 'vcpm' ? [0.02, 1000] : [1, 1000];
      case 'TRY':
        return costType !== 'vcpm' ? [0.05, 2500] : [1.85, 2500];
      case 'EGP':
        return costType !== 'vcpm' ? [0.15, 7400] : [5, 7400];
      case 'SAR':
        return costType !== 'vcpm' ? [0.1, 3670] : [4, 3670];
      default:
        return [0.02, 1000];
    }
  }
  return [0.02, 1000]
}

/**
 * Return budget limits enforced by Amazon.
 * @see https://advertising.amazon.com/API/docs/en-us/reference/concepts/limits
 */
export const getBudgetLimits = (
  adType,
  countryCode,
  forSeller = true,
  isDaily = true,
) => {
  if (adType === 'sp') {
    switch (countryCode) {
      case 'us':
      case 'ca':
      case 'gb':
      case 'de':
      case 'fr':
      case 'it':
      case 'es':
      case 'nl':
      case 'be':
        return [1, 1000000]
      case 'in':
        return [50, 21000000]
      case 'jp':
        return [100, 21000000]
      case 'cn':
        return [1, 21000000]
      case 'au':
        return [1.4, 1500000]
      case 'mx':
        return [1, 21000000]
      case 'ae':
      case 'sa':
        return [4, 3700000]
      case 'br':
        return [1.32, 5300000]
      case 'sg':
        return [1.39, 1300000]
      case 'tr':
        return [2, 2500000]
      case 'pl':
        return [2, 2000000]
      case 'se':
        return [9, 9300000]
      case 'eg':
        return [7, 7400000]
      default:
        return [1, 1000000]
    }
  }

  if (adType === 'sb') {
    switch (countryCode) {
      case 'us':
      case 'ca':
      case 'gb':
      case 'de':
      case 'fr':
      case 'it':
      case 'es':
      case 'be':
        return isDaily ? [1, 1000000] : [100, 20000000]
      case 'in':
        if (forSeller) {
          return isDaily ? [100, 21000000] : [5000, 200000000]
        }
        return isDaily ? [500, 21000000] : [5000, 200000000]
      case 'jp':
        return isDaily ? [100, 21000000] : [10000, 21000000]
      case 'cn':
        return isDaily ? [1, 21000000] : [100, 200000000]
      case 'au':
        return isDaily ? [1.4, 1500000] : [141, 28000000]
      case 'mx':
        return isDaily ? [1, 21000000] : [1000, 200000000]
      case 'ae':
      case 'sa':
      case 'br':
      case 'nl':
      case 'sg':
        return isDaily ? [4, 3700000] : [367, 74000000]
      case 'tr':
        return isDaily ? [2, 2700000] : [250, 53000000]
      case 'pl':
        return isDaily ? [2, 2400000] : [200, 47000000]
      case 'se':
        return isDaily ? [9, 9400000] : [900, 187000000]
      case 'eg':
        return isDaily ? [7, 7600000] : [750, 151000000]
      default:
        return isDaily ? [1, 1000000] : [100, 20000000]
    }
  }

  if (adType === 'sd') {
    switch (countryCode) {
      case 'us':
      case 'ca':
      case 'gb':
      case 'de':
      case 'fr':
      case 'it':
      case 'es':
        return forSeller ? [1, 1000000] : [1, 50000]
      case 'in':
        return forSeller ? [50, 21000000] : [50, 5000000]
      case 'jp':
        return forSeller ? [100, 21000000] : [100, 5000000]
      case 'au':
        return [1.4, 1500000]
      case 'mx':
        return [1, 21000000]
      case 'ae':
        return [4, 3700000]
      case 'br':
        return [1.32, 5300000]
      case 'nl':
      case 'se':
      case 'pl':
      case 'be':
        return [1, 1000000]
      case 'sg':
        return [4, 1300000]
      case 'eg':
        return [7, 7600000]
      case 'sa':
        return [4, 3700000]
      case 'tr':
        return [2, 2700000]
      default:
        return forSeller ? [1, 1000000] : [1, 50000]
    }
  }
}

export const calcMaxCpc = (record, adType, countryCode, costType = '') => {
  let maxCpc
  if (parseInt(record.clicks, 10) >= 3) {
    if (parseInt(record.units, 10) && record.click_order) {
      maxCpc = (parseFloat(record.revenue) / parseInt(record.units, 10))
        * (record.target_acos / 100)
        / record.click_order
    } else if (typeof record.units === 'undefined') {
      // For SB campaigns, the number of units is not available.
      maxCpc = parseFloat(record.revenue) * (record.target_acos / 100) / parseInt(record.clicks, 10)
    }

    const [minBid, maxBid] = getBidLimits(adType, countryCode, costType)
    maxCpc = Math.max(Math.min(maxCpc, maxBid), minBid)
  }
  return maxCpc
}

// Group records.
export const groupRecords = (records, groupBy, parentFields) => {
  const results = {}
  records.forEach((record) => {
    if (!results[record[groupBy]] || !results[record[groupBy]].children) {
      const data = {
        [groupBy]: record[groupBy],
        cost: 0,
        revenue: 0,
        impressions: 0,
        clicks: 0,
        orders: 0,
        yearlyCost: 0,
        st_impr_rank: 0,
        st_impr_share: 0,
        viewable_impressions: 0,
        children: [],
      }
      parentFields.forEach((field) => {
        data[field] = record[field]
      })
      results[record[groupBy]] = data
    }

    results[record[groupBy]].children.push(record)
    if (typeof record.cost !== 'undefined') {
      results[record[groupBy]].cost += parseFloat(record.cost)
      results[record[groupBy]].revenue += parseFloat(record.revenue)
      results[record[groupBy]].impressions += parseInt(record.impressions, 10)
      results[record[groupBy]].clicks += parseInt(record.clicks, 10)
      results[record[groupBy]].orders += parseInt(record.orders, 10)
    }
    results[record[groupBy]].yearlyCost += parseFloat(record.yearlyCost || 0)
    results[record[groupBy]].st_impr_rank += parseFloat(record.st_impr_rank || 0)
    results[record[groupBy]].st_impr_share += parseFloat(record.st_impr_share || 0)
    results[record[groupBy]].viewable_impressions += parseInt(record.viewable_impressions || 0, 10)
  })

  return Object.keys(results).map((key) => {
    const record = results[key]
    record.st_impr_rank /= record.children.length
    record.st_impr_share /= record.children.length
    return calcDerivedMetrics(record)
  })
}

const amazonLinkList = {
  us: 'www.amazon.com',
  ca: 'www.amazon.ca',
  mx: 'www.amazon.com.mx',
  br: 'www.amazon.com.br',
  gb: 'www.amazon.co.uk',
  de: 'www.amazon.de',
  fr: 'www.amazon.fr',
  it: 'www.amazon.it',
  es: 'www.amazon.es',
  in: 'www.amazon.in',
  nl: 'www.amazon.nl',
  se: 'www.amazon.se',
  pl: 'www.amazon.pl',
  tr: 'www.amazon.com.tr',
  ae: 'www.amazon.ae',
  eg: 'www.amazon.eg',
  sa: 'www.amazon.sa',
  jp: 'www.amazon.co.jp',
  au: 'www.amazon.com.au',
  sg: 'www.amazon.sg',
  cn: 'www.amazon.cn',
  be: 'www.amazon.com.be',
}

// Get Amazon links for a provided account/country.
const getAmazonLink = (account) => {
  if (!account || !account.country_id) {
    return amazonLinkList['us']
  }
  return amazonLinkList[account.country_id] || amazonLinkList['us']
}

export const getAmazonAsinLink = (account, asin) => (
  `https://${getAmazonLink(account)}/gp/product/${asin}`
)

export const getAmazonCategoryLink = (account, categoryId) => (
  `https://${getAmazonLink(account)}/b?node=${categoryId}`
)

export const getAmazonSearchLink = (account, search) => (
  `https://${getAmazonLink(account)}/s/ref=nb_sb_noss?url=search-alias%3Daps&field-keywords=${encodeURIComponent(search)}`
)

// Filter logs based on type.
export const filterLogs = (logs, type) => {
  if (!type) {
    return logs
  }

  let filteredLogs = [...logs]
  switch (type.value) {
    case 'ap_kw_basic':
    case 'ap_kw_adv':
    case 'ap_kw_ex':
    case 'ap_npt':
    case 'ap_dayparting':
    case 'ap_placement':
    case 'rule_dayparting':
    case 'rule_weekparting':
    case 'rule_performance':
    case 'rule_event_planning':
      filteredLogs = filteredLogs.filter(log => (
        log.log_type === type.value
      ))
      break
    case 'ap_nta':
      filteredLogs = filteredLogs.filter(log => (
        log.log_type === 'ap_nta' || log.log_type === 'ap_nta_asin'
      ))
      break
    case 'ap_st_ex':
      filteredLogs = filteredLogs.filter(log => (
        log.log_type === 'ap_st_ex' || log.log_type === 'ap_st_negate'
      ))
      break
    case 'ap_pt_ex':
      filteredLogs = filteredLogs.filter(log => (
        log.log_type === 'ap_pt_ex' || log.log_type === 'ap_pt_negate'
      ))
      break
    default:
      break
  }

  return filteredLogs
}

export const parsePerformanceSummary = (placementSummary = {}) => {
  const bidTotal = {}
  const types = ['auto', 'legacy', 'manual']
  types.forEach((type) => {
    const values = placementSummary[type]
    const capitalType = type.charAt(0).toUpperCase() + type.slice(1)
    if (values) {
      bidTotal[`impressions${capitalType}`] = values['impressionsTop'] + values['impressionsDetail'] + values['impressionsOther']
      bidTotal[`revenue${capitalType}`] = values['revenueTop'] + values['revenueDetail'] + values['revenueOther']
      bidTotal[`clicks${capitalType}`] = values['clicksTop'] + values['clicksDetail'] + values['clicksOther']
      bidTotal[`orders${capitalType}`] = values['ordersTop'] + values['ordersDetail'] + values['ordersOther']
      bidTotal[`cost${capitalType}`] = values['costTop'] + values['costDetail'] + values['costOther']
    }

    bidTotal[`ctr${capitalType}`] = bidTotal[`impressions${capitalType}`]
      ? bidTotal[`clicks${capitalType}`] / bidTotal[`impressions${capitalType}`] * 100.0 : 0
    bidTotal[`acos${capitalType}`] = bidTotal[`revenue${capitalType}`]
      ? bidTotal[`cost${capitalType}`] / bidTotal[`revenue${capitalType}`] * 100.0 : 0
    bidTotal[`conv${capitalType}`] = bidTotal[`clicks${capitalType}`]
      ? bidTotal[`orders${capitalType}`] / bidTotal[`clicks${capitalType}`] * 100.0 : 0
    bidTotal[`cpc${capitalType}`] = bidTotal[`clicks${capitalType}`]
      ? bidTotal[`cost${capitalType}`] / bidTotal[`clicks${capitalType}`] : 0
  })

  const placementTotal = {}
  for (let key in placementSummary.auto) {
    placementTotal[key] = placementSummary.auto[key]
      + placementSummary.manual[key]
      + placementSummary.legacy[key]
  }

  placementTotal['ctrTop'] = placementTotal['impressionsTop']
    ? placementTotal['clicksTop'] / placementTotal['impressionsTop'] * 100.0 : 0
  placementTotal['acosTop'] = placementTotal['revenueTop']
    ? placementTotal['costTop'] / placementTotal['revenueTop'] * 100.0 : 0
  placementTotal['convTop'] = placementTotal['clicksTop']
    ? placementTotal['ordersTop'] / placementTotal['clicksTop'] * 100.0 : 0
  placementTotal['cpcTop'] = placementTotal['clicksTop']
    ? placementTotal['costTop'] / placementTotal['clicksTop'] : 0

  placementTotal['ctrDetail'] = placementTotal['impressionsDetail']
    ? placementTotal['clicksDetail'] / placementTotal['impressionsDetail'] * 100.0 : 0
  placementTotal['acosDetail'] = placementTotal['revenueDetail']
    ? placementTotal['costDetail'] / placementTotal['revenueDetail'] * 100.0 : 0
  placementTotal['convDetail'] = placementTotal['clicksDetail']
    ? placementTotal['ordersDetail'] / placementTotal['clicksDetail'] * 100.0 : 0
  placementTotal['cpcDetail'] = placementTotal['clicksDetail']
    ? placementTotal['costDetail'] / placementTotal['clicksDetail'] : 0

  placementTotal['ctrOther'] = placementTotal['impressionsOther']
    ? placementTotal['clicksOther'] / placementTotal['impressionsOther'] * 100.0 : 0
  placementTotal['acosOther'] = placementTotal['revenueOther']
    ? placementTotal['costOther'] / placementTotal['revenueOther'] * 100.0 : 0
  placementTotal['convOther'] = placementTotal['clicksOther']
    ? placementTotal['ordersOther'] / placementTotal['clicksOther'] * 100.0 : 0
  placementTotal['cpcOther'] = placementTotal['clicksOther']
    ? placementTotal['costOther'] / placementTotal['clicksOther'] : 0

  return {
    bidTotal,
    placementTotal,
  }
}

// Get exportable data for a given column.
// Used for aggregation as well.
export const getExportValueForColumn = (record, columnKey, currencySign, currencyRate) => {
  if (columnKey === 'campaignName') {
    let targetingType = ''
    if (record.campaignType === 'sp') {
      targetingType = record.targetingType === 'auto' ? 'Auto, ' : 'Manual, '
    }
    return `${record.campaignName} (${targetingType}${campaignTypeMap[record.campaignType]})`
  }
  if (columnKey === 'adgroupName') {
    return record.adgroupName
  }
  if (columnKey === 'orders' || columnKey === 'impressions' || columnKey === 'clicks'
    || columnKey === 'ntb_orders' || columnKey === 'viewable_impressions') {
    return formatValue(record[columnKey], 'removeZeroDecimal')
  } else if (columnKey === 'acos' || columnKey === 'ctr' || columnKey === 'conversion'
    || columnKey === 'ntb_orders_percent' || columnKey === 'ntb_sales_percent') {
    return formatValue(record[columnKey], 'percent')
  } else if (columnKey === 'revenue' || columnKey === 'cost' || columnKey === 'cpc'
    || columnKey === 'ntb_sales') {
    return formatCurrency(record[columnKey], currencySign, currencyRate)
  } else if (columnKey === 'st_impr_rank') {
    if (parseFloat(record[columnKey])) {
      return formatValue(Math.ceil(parseFloat(record[columnKey])), 'removeZeroDecimal')
    }
  } else if (columnKey === 'st_impr_share') {
    if (parseFloat(record[columnKey])) {
      return formatValue(record[columnKey], 'percent')
    }
  }
  return record[columnKey] || ''
}

export const getAccountLabel = (account) => {
  if (!account) {
    return 'N/A'
  }

  let brandName
  if (account.brand_name) {
    brandName = account.brand_name
  } else {
    brandName = account.sellerid || 'N/A'
  }
  return (
    <>
      <span
        className={`flag flag-${account.country_id}`}
        title={account.country_id.toUpperCase()}
      >
        &nbsp;
      </span>
      { brandName }
    </>
  )
}

export const prettyString = (text, limit = 50) => {
  if (text.length <= limit) {
    return text
  }

  return text.slice(0, limit) + '...'
}

export const getRegionForCountry = (countryCode, forSeller = true) => {
  if (['us', 'ca', 'mx', 'br'].indexOf(countryCode) !== -1) {
    return 'na'
  }
  if (!forSeller && countryCode === 'in') {
    return 'in'
  }
  if (['gb', 'fr', 'de', 'es', 'it', 'nl', 'in', 'ae',
    'se', 'pl', 'tr', 'eg', 'sa', 'be'].indexOf(countryCode) !== -1) {
    return 'eu'
  }
  return 'fe'
}

export const getAuthorizationUrl = (countryCode, state, forSeller) => {
  const region = getRegionForCountry(countryCode, forSeller)

  // List of seller central URLs for each region.
  const sellerCentralUrls = {
    na: 'https://sellercentral.amazon.com',
    eu: 'https://sellercentral-europe.amazon.com',
    fe: 'https://sellercentral.amazon.com.au',
  }

  const vendorCentralUrls = {
    na: 'https://vendorcentral.amazon.com',
    eu: 'https://vendorcentral.amazon.co.uk',
    fe: 'https://vendorcentral.amazon.com.au',
    in: 'https://www.vendorcentral.in',
  }

  let siteUrl
  if (forSeller) {
    siteUrl = sellerCentralUrls[region]
  } else {
    siteUrl = vendorCentralUrls[region]
  }

  let url = `${siteUrl}/apps/authorize/consent?application_id=${SP_APP_ID}`
  if (state) {
    url = `${url}&state=${state}`
  }
  if (SP_BETA) {
    url = `${url}&version=beta`
  }
  return url
}

export const calculateMetricsDifference = (currentSource, pastSource, salesData, metricType) => {
  let isBetter = true
  let difference = 0
  let diffPercent = 0
  let value = 0

  if (currentSource) {
    currentSource = calcDerivedMetrics(currentSource)
  }
  if (pastSource) {
    pastSource = calcDerivedMetrics(pastSource)
  }

  switch (metricType) {
    case 'organic_revenue':
      const sales = salesData?.sales - salesData?.ppcRevenue
      const pastSales = salesData?.past.sales - salesData?.past.ppcRevenue
      difference = sales - pastSales
      diffPercent = pastSales > 0 ? Math.abs(difference) / pastSales * 100 : 0.00
      isBetter = difference > 0
      value = sales
      break
    case 'ppc_revenue_all':
      difference = salesData?.ppcRevenue - salesData?.past.ppcRevenue
      diffPercent = salesData?.ppcRevenue > 0 ? Math.abs(difference) / salesData?.ppcRevenue * 100 : 0.00
      isBetter = difference > 0
      value = salesData?.ppcRevenue
      break
    case 'true_acos':
      const trueAcos = salesData?.ppcCost / salesData?.sales * 100
      const pastTrueAcos = salesData?.past.ppcCost / salesData?.past.sales * 100
      difference = trueAcos - pastTrueAcos
      diffPercent = Math.abs(difference) / pastTrueAcos * 100
      isBetter = difference < 0
      value = trueAcos
      break
    case 'cost':
    case 'cpc':
    case 'acos':
    case 'click_order':
      if (currentSource && pastSource) {
        value = currentSource[metricType]
        difference = value - pastSource[metricType]
        diffPercent = pastSource[metricType] > 0 ? Math.abs(difference) / pastSource[metricType] * 100 : 0.00
        isBetter = difference < 0
      }
      break
    case 'revenue':
    case 'impressions':
    case 'clicks':
    case 'ntb_orders':
    case 'ntb_sales':
    case 'ntb_units':
    case 'ctr':
    case 'conversion':
    case 'viewable_impressions':
    case 'roas':
    case 'ntb_sales_percent':
    case 'vtr':
    case 'vctr':
    case 'vcpm':
      if (currentSource && pastSource) {
        value = currentSource[metricType]
        difference = value - pastSource[metricType]
        diffPercent = pastSource[metricType] > 0 ? Math.abs(difference) / pastSource[metricType] * 100 : 0.00
        isBetter = difference >= 0
      }
      break
    case 'unproductive_ad_spend':
      const unproductiveAdSpend = currentSource?.clicks > 0
        ? currentSource?.cost * (1 - currentSource?.orders / currentSource?.clicks)
        : 0.00
      const pastUnproductiveAdSpend = pastSource?.clicks > 0
        ? pastSource?.cost * (1 - pastSource?.orders / pastSource?.clicks)
        : 0.00
      difference = unproductiveAdSpend - pastUnproductiveAdSpend
      diffPercent = pastUnproductiveAdSpend > 0 ? Math.abs(difference) / pastUnproductiveAdSpend * 100 : 0.00
      isBetter = difference < 0
      value = unproductiveAdSpend
      break
    case 'productive_ad_spend_rate':
      const productiveAdSpendRate = currentSource?.clicks > 0 ? currentSource?.orders / currentSource?.clicks * 100 : 0.00
      const pastProductiveAdSpendRate = pastSource?.clicks > 0 ? pastSource?.orders / pastSource?.clicks * 100 : 0.00
      difference = productiveAdSpendRate - pastProductiveAdSpendRate
      diffPercent = pastProductiveAdSpendRate > 0 ? Math.abs(difference) / pastProductiveAdSpendRate * 100 : 0.00
      isBetter = difference < 0
      value = productiveAdSpendRate
      break
    case 'productive_ad_spend':
      const productiveAdSpend = currentSource?.clicks > 0 ? currentSource?.cost * currentSource?.orders / currentSource?.clicks : 0.00
      const pastProductiveAdSpend = pastSource?.clicks > 0 ? pastSource?.cost * pastSource?.orders / pastSource?.clicks : 0.00
      difference = productiveAdSpend - pastProductiveAdSpend
      diffPercent = pastProductiveAdSpend > 0 ? Math.abs(difference) / pastProductiveAdSpend * 100 : 0.00
      isBetter = difference < 0
      value = productiveAdSpend
      break
    case 'cost_per_1000_impressions':
      const costPer1000Impression = currentSource?.impressions > 0 ? currentSource?.cost / currentSource?.impressions * 1000 : 0.00
      const pastCostPer1000Impressions = pastSource?.impressions > 0 ? pastSource?.cost / pastSource?.impressions * 1000 : 0.00
      difference = costPer1000Impression - pastCostPer1000Impressions
      diffPercent = pastCostPer1000Impressions > 0 ? Math.abs(difference) / pastCostPer1000Impressions * 100 : 0.00
      isBetter = difference < 0
      value = costPer1000Impression
      break
    case 'cost_per_conversion':
      const costPerConversion = currentSource?.orders > 0 ? currentSource?.cost / currentSource?.orders : 0.00
      const pastCostPerConversion = pastSource?.orders > 0 ? pastSource?.cost / pastSource?.orders : 0.00
      difference = costPerConversion - pastCostPerConversion
      diffPercent = pastCostPerConversion > 0 ? Math.abs(difference) / pastCostPerConversion * 100 : 0.00
      isBetter = difference < 0
      value = costPerConversion
      break
    case 'video_25_views':
      difference = currentSource?.videoViews25 - pastSource?.videoViews25
      diffPercent = pastSource?.videoViews25 > 0 ? Math.abs(difference) / pastSource?.videoViews25 * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.videoViews25
      break
    case 'video_50_views':
      difference = currentSource?.videoViews50 - pastSource?.videoViews50
      diffPercent = pastSource?.videoViews50 > 0 ? Math.abs(difference) / pastSource?.videoViews50 * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.videoViews50
      break
    case 'video_75_views':
      difference = currentSource?.videoViews75 - pastSource?.videoViews75
      diffPercent = pastSource?.videoViews75 > 0 ? Math.abs(difference) / pastSource?.videoViews75 * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.videoViews75
      break
    case 'video_100_views':
      difference = currentSource?.videoViews100 - pastSource?.videoViews100
      diffPercent = pastSource?.videoViews100 > 0 ? Math.abs(difference) / pastSource?.videoViews100 * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.videoViews100
      break
    case 'view_5_seconds':
      difference = currentSource?.video5sViews - pastSource?.video5sViews
      diffPercent = pastSource?.video5sViews > 0 ? Math.abs(difference) / pastSource?.video5sViews * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.video5sViews
      break
    case 'view_5_seconds_rate':
      const view5sRate = currentSource?.impressions > 0 ? currentSource?.video5sViews / currentSource?.impressions * 100 : 0.00
      const pastView5sRate = pastSource?.impressions > 0 ? pastSource?.video5sViews / pastSource?.impressions * 100 : 0.00
      difference = view5sRate - pastView5sRate
      diffPercent = pastView5sRate > 0 ? Math.abs(difference) / pastView5sRate * 100 : 0.00
      isBetter = difference > 0
      value = view5sRate
      break
    case 'branded_sale':
      difference = currentSource?.brandedSales - pastSource?.brandedSales
      diffPercent = pastSource?.brandedSales > 0 ? Math.abs(difference) / pastSource?.brandedSales * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.brandedSales
      break
    case 'non_branded_sale':
      difference = currentSource?.nonBrandedSales - pastSource?.nonBrandedSales
      diffPercent = pastSource?.nonBrandedSales > 0 ? Math.abs(difference) / pastSource?.nonBrandedSales * 100 : 0.00
      isBetter = difference >= 0
      value = currentSource?.nonBrandedSales
      break
    default:
      break
  }

  return {
    isBetter,
    difference,
    diffPercent,
    value,
  }
}

export const calculateChartMetric = (source, widget, currencySign) => {
  let chartData = []
  let chartMetrics = []
  switch (widget.key) {
    case 'spend_vs_sales':
      chartMetrics = [
        {
          key: 'cost',
          label: `AD Spend (${currencySign})`,
          stroke: '#8884d8',
          name: `AD Spend (${currencySign})`
        },{
          key: 'sales',
          label: 'Revenue',
          stroke: '#82ca9d',
          name: `Sales (${currencySign})`
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          cost: item.cost > 0 ? parseFloat(item.cost.toFixed(widget.decimal || 2)) : 0,
          sales: item.sales > 0 ? parseFloat(item.sales.toFixed(widget.decimal || 2)) : 0
        })
      })
      break
    case 'trending_acos':
      chartMetrics = [
        {
          key: 'acos',
          stroke: '#ffd156',
          name: 'Advertising Cost of Sales (%)'
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          acos: item.revenue !== 0 ? parseFloat((item.cost / item.revenue * 100).toFixed(widget.decimal || 2)) : null,
        })
      })
      break
    case 'trending_ctr':
      chartMetrics = [
        {
          key: 'ctr',
          stroke: '#25c835',
          name: 'Clicks Through Rates (%)'
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          ctr: item.impressions !== 0 ? parseFloat((item.clicks / item.impressions * 100).toFixed(widget.decimal || 2)) : null,
        })
      })
      break
    case 'trending_conversion_rate':
      chartMetrics = [
        {
          key: 'convRate',
          stroke: '#FF7B56',
          name: 'Conversion Rates (%)'
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          convRate: item.clicks !== 0
            ? parseFloat((item.orders / item.clicks * 100).toFixed(widget.decimal || 2))
            : null,
        })
      })
      break
    case 'trending_cpc':
      chartMetrics = [
        {
          key: 'cpc',
          stroke: '#072257',
          name: `Cost Per Click (${currencySign})`
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          cpc: item.clicks > 0
            ? parseFloat((item.cost / item.clicks).toFixed(widget.decimal || 2))
            : null,
        })
      })
      break
    case 'unproductive_ad_spend_rate':
      chartMetrics = [
        {
          key: 'unproductiveAdSpendRate',
          stroke: '#FF7B56',
          name: 'Awareness Ad Spend Rates (%)'
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          unproductiveAdSpendRate: item.clicks > 0
            ? parseFloat(((1 - item.orders / item.clicks) * 100).toFixed(widget.decimal || 2))
            : null,
        })
      })
      break
    case 'trending_ntb_revenue':
      chartMetrics = [
        {
          key: 'ntbRevenue',
          stroke: '#3dade8',
          name: widget.title + ` (${currencySign})`
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          ntbRevenue: item.ntb_revenue > 0
            ? parseFloat(item.ntb_revenue.toFixed(widget.decimal || 2))
            : 0,
        })
      })
      break
    case 'trending_ntb_units':
      chartMetrics = [
        {
          key: 'ntb_units',
          stroke: '#8884d8',
          name: widget.title
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          ntb_units: item.ntb_units,
        })
      })
      break
    case 'brand_vs_non_brand':
    case 'sp_brand_vs_non_brand':
    case 'sb_brand_vs_non_brand':
      chartMetrics = [
        {
          key: 'branded',
          label: `Branded Sales (${currencySign})`,
          stroke: '#8884d8',
          name: `Branded Sales (${currencySign})`
        },{
          key: 'non_branded',
          label: `Non-Branded Sales (${currencySign})`,
          stroke: '#82ca9d',
          name: `Non-Branded Sales (${currencySign})`
        }
      ];
      (source || []).forEach(item => {
        chartData.push({
          date: format(parseDate(item.date), 'MMM dd'),
          branded: item.branded_sale
            ? parseFloat(item.branded_sale.toFixed(widget.decimal || 2))
            : 0,
          non_branded: item.non_branded_sale
            ? parseFloat(item.non_branded_sale.toFixed(widget.decimal || 2))
            : 0
        })
      })
      break
    default:
      break
  }

  return {
    chartMetrics,
    chartData,
  }
}

export const calculateMetricDiff = (currentSource, pastSource) => {
  const diffResult = {
    impressions: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    clicks: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    orders: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    cost: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    revenue: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    cpc: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    ctr: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    conversion: {
      diff: 0,
      diffPercent: 0,
      status: true
    },
    acos: {
      diff: 0,
      diffPercent: 0,
      status: true
    }
  }
  if (currentSource && pastSource) {
    diffResult.impressions.diff = currentSource.impressions - pastSource.impressions
    diffResult.impressions.diffPercent = pastSource.impressions > 0 ? diffResult.impressions.diff / pastSource.impressions * 100 : 0
    diffResult.impressions.status = diffResult.impressions.diff > 0
    diffResult.clicks.diff = currentSource.clicks - pastSource.clicks
    diffResult.clicks.diffPercent = pastSource.clicks > 0 ? diffResult.clicks.diff / pastSource.clicks * 100 : 0
    diffResult.clicks.status = diffResult.clicks.diff > 0
    diffResult.orders.diff = currentSource.orders - pastSource.orders
    diffResult.orders.diffPercent = pastSource.orders > 0 ? diffResult.orders.diff / pastSource.orders * 100 : 0
    diffResult.orders.status = diffResult.orders.diff > 0
    diffResult.cost.diff = currentSource.cost - pastSource.cost
    diffResult.cost.diffPercent = pastSource.cost > 0 ? diffResult.cost.diff / pastSource.cost * 100 : 0
    diffResult.cost.status = diffResult.cost.diff > 0
    diffResult.revenue.diff = currentSource.revenue - pastSource.revenue
    diffResult.revenue.diffPercent = pastSource.revenue > 0 ? diffResult.revenue.diff / pastSource.revenue * 100 : 0
    diffResult.revenue.status = diffResult.revenue.diff > 0
    diffResult.cpc.diff = currentSource.cpc - pastSource.cpc
    diffResult.cpc.diffPercent = pastSource.cpc > 0 ? diffResult.cpc.diff / pastSource.cpc * 100 : 0
    diffResult.cpc.status = diffResult.cpc.diff > 0
    diffResult.ctr.diff = currentSource.ctr - pastSource.ctr
    diffResult.ctr.diffPercent = pastSource.ctr > 0 ? diffResult.ctr.diff / pastSource.ctr * 100 : 0
    diffResult.ctr.status = diffResult.ctr.diff > 0
    diffResult.conversion.diff = currentSource.conversion - pastSource.conversion
    diffResult.conversion.diffPercent = pastSource.conversion > 0 ? diffResult.conversion.diff / pastSource.conversion * 100 : 0
    diffResult.conversion.status = diffResult.conversion.diff > 0
    diffResult.acos.diff = currentSource.acos - pastSource.acos
    diffResult.acos.diffPercent = pastSource.acos > 0 ? diffResult.acos.diff / pastSource.acos * 100 : 0
    diffResult.acos.status = diffResult.acos.diff < 0
  }
  return diffResult
}

export const calculateCustomMetrics = (currentSource, pastSource, salesData = null, customWidget) => {
  let value = 0.00,
    pastValue = 0,
    difference = 0,
    diffPercent = 0,
    isBetter = true,
    x = 0,
    y = 0,
    pastX = 0,
    pastY = 0

  if (customWidget.xMetric === 'organic_revenue') {
    x = salesData?.sales - salesData?.ppcRevenue
    pastX = salesData?.past.sales - salesData?.past.ppcRevenue
  } else {
    x = currentSource ? currentSource[customWidget.xMetric] : 0
    pastX = pastSource ? pastSource[customWidget.xMetric] : 0
  }

  if (customWidget.yMetric === 'organic_revenue') {
    y = salesData?.sales - salesData?.ppcRevenue
    pastY = salesData?.past.sales - salesData?.past.ppcRevenue
  } else {
    y = currentSource ? currentSource[customWidget.yMetric] : 0
    pastY = pastSource ? pastSource[customWidget.yMetric] : 0
  }

  switch(customWidget.operator) {
    case 'plus':
      value = x + y
      pastValue = pastX + pastY
      break
    case 'minus':
      value = x - y
      pastValue = pastX - pastY
      break
    case 'division':
      value = y > 0 ? (x / y) : 0.00
      pastValue = pastY > 0 ? (pastX / pastY) : 0.00
      break
    case 'multiple':
      value = x * y
      pastValue = pastX * pastY
      break
    case 'percent':
      value = y > 0 ? (x / y * 100) : 0.00
      pastValue = pastY > 0 ? (pastX / pastY * 100) : 0.00
      break
    default:
      break
  }
  difference = value - pastValue
  isBetter = difference > 0
  diffPercent = pastValue > 0 ? Math.abs(difference) / pastValue * 100 : 0.00
  return { value: value.toFixed(2), isBetter, difference, diffPercent}
}

export const getTemporaryDownloadLink = (url) => {
  if (url.indexOf('/api') === 0) {
    url = url.substr(4)
  }
  return `${endpoint}${url}`
}

export const calcNewBid = (record, bidOption, bidValue, campaignType, countryCode, costType = '') => {
  let newBid = parseFloat(bidValue)
  if (bidOption === ADJUST_BID_RAISE_ABSOLUTE) {
    newBid = parseFloat(record.bid || 0) + newBid
  } else if (bidOption === ADJUST_BID_LOWER_ABSOLUTE) {
    newBid = parseFloat(record.bid || 0) - newBid
  } else if (bidOption === ADJUST_BID_RAISE_PERCENT) {
    newBid = parseFloat(record.bid || 0) * (1 + (newBid / 100))
  } else if (bidOption === ADJUST_BID_LOWER_PERCENT) {
    newBid = parseFloat(record.bid || 0) * (1 - (newBid / 100))
  } else if (bidOption === ADJUST_BID_CPC) {
    newBid = parseFloat(record.cpc || 0)
  } else if (bidOption === ADJUST_BID_CPC_RAISE_ABSOLUTE) {
    newBid = parseFloat(record.cpc || 0) + newBid
  } else if (bidOption === ADJUST_BID_CPC_LOWER_ABSOLUTE) {
    newBid = parseFloat(record.cpc || 0) - newBid
  } else if (bidOption === ADJUST_BID_CPC_RAISE_PERCENT) {
    newBid = parseFloat(record.cpc || 0) * (1 + (newBid / 100))
  } else if (bidOption === ADJUST_BID_CPC_LOWER_PERCENT) {
    newBid = parseFloat(record.cpc || 0) * (1 - (newBid / 100))
  }

  const [minBid, maxBid] = getBidLimits(campaignType, countryCode, costType)
  return Math.max(Math.min(newBid, maxBid), minBid)
}

export const getCurrencyForCountry = countryCode => (
  currencyList.find(currency => (
    currency.code === countryCode
    || (currency.code === 'eu'
      && ['fr', 'de', 'it', 'es', 'nl', 'be'].indexOf(countryCode) !== -1)
  ))
)

export const getRuleDescription = (rule) => {
  let ruleName = ''
  let actionName = ''
  let value = ''
  if (rule.t === RULE_TYPE_BID) {
    ruleName = 'Bid'
    actionName = ruleBidActionList.find(action =>
      action.value === rule.a
    ).label

    if (rule.a !== RULE_BID_ACTION_PAUSE) {
      value = `${rule.v}%`
    }
  } else if (rule.t === RULE_TYPE_CAMPAIGN) {
    ruleName = 'Campaign budget/status'
    actionName = ruleCampaignActionList.find(action =>
      action.value === rule.a
    ).label
    if (rule.a !== RULE_CAMPAIGN_ACTION_PAUSE) {
      value = `${rule.v}%`

      if (typeof rule.l !== 'undefined' && rule.l !== null) {
        value = `${value}, ${rule.a === RULE_CAMPAIGN_ACTION_DEC ? 'Min' : 'Max'} budget: $${rule.l.toFixed(2)}`
      }
    }
  } else if (rule.t === RULE_TYPE_TOS_MODIFIER) {
    ruleName = 'Top of Search modifier'
    actionName = ruleModifierActionList.find(action =>
      action.value === rule.a
    ).label
    value = `${rule.v}%`
  } else if (rule.t === RULE_TYPE_PRODUCT_MODIFIER) {
    ruleName = 'Product Pages modifier'
    actionName = ruleModifierActionList.find(action =>
      action.value === rule.a
    ).label
    value = `${rule.v}%`
  } else if (rule.t === RULE_TYPE_REST_MODIFIER) {
    ruleName = 'Rest of the Search modifier'
    actionName = ruleModifierActionList.find(action =>
      action.value === rule.a
    ).label
    value = `${rule.v}%`
  } else if (rule.t === RULE_TYPE_EXCLUDE_DATA) {
    ruleName = 'Exclude data'
  }

  return (
    <>
      <strong>{ ruleName }</strong>
      &nbsp;{ actionName }
      &nbsp;{ value }
    </>
  )
}

export const isRuleComplete = rule => (
  rule.a
  && ((
    rule.t === RULE_TYPE_BID
    && (rule.a === RULE_BID_ACTION_PAUSE || rule.v)
  ) || (
    rule.t === RULE_TYPE_CAMPAIGN
    && (rule.a === RULE_CAMPAIGN_ACTION_PAUSE || rule.v)
  ) || (
    rule.t === RULE_TYPE_TOS_MODIFIER && rule.v
  ) || (
    rule.t === RULE_TYPE_PRODUCT_MODIFIER && rule.v
  ) || (
    rule.t === RULE_TYPE_REST_MODIFIER && rule.v
  ))
)

export const getAssociativeCampaigns = (campaigns) => {
  const campaignsById = {}
  campaigns.forEach((campaign) => {
    campaignsById[campaign.campaign_id] = {
      name: campaign.campaign,
      type: campaign.campaignType,
      targetingType: campaign.targeting_type,
      costType: campaign.cost_type,
      bidding: campaign.bidding,
      videoAdgroupIds: campaign.video_adgroup_ids || [],
    }
  })
  return campaignsById
}

export const parseTargeting = (targeting, needResolve = false) => {
  const payload = {
    bid: parseFloat(targeting.bid),
    expression: [],
  }

  const expressionResolve = []

  switch (targeting.type) {
    case 'category':
      payload.expression.push({
        type: 'asinCategorySameAs',
        value: targeting.id.toString(),
      })
      if (needResolve) {
        expressionResolve.push({
          type: 'asinCategorySameAs',
          value: targeting.name,
        })
      }
      break
    case 'product':
      if (!targeting.asin) {
        return null
      }
      payload.expression.push({
        type: 'asinSameAs',
        value: targeting.asin,
      })
      if (needResolve) {
        expressionResolve.push({
          type: 'asinSameAs',
          value: targeting.asin,
        })
      }
      break
    case 'refine':
      payload.expression.push({
        type: 'asinCategorySameAs',
        value: targeting.id.toString(),
      })
      if (needResolve) {
        expressionResolve.push({
          type: 'asinCategorySameAs',
          value: targeting.name,
        })
      }

      if (targeting.brandId) {
        payload.expression.push({
          type: 'asinBrandSameAs',
          value: targeting.brandId.toString(),
        })
        if (needResolve) {
          expressionResolve.push({
            type: 'asinBrandSameAs',
            value: targeting.brandName,
          })
        }
      }

      if (targeting.ratingValue) {
        payload.expression.push({
          type: 'asinReviewRatingBetween',
          value: targeting.ratingValue,
        })
        if (needResolve) {
          expressionResolve.push({
            type: 'asinReviewRatingBetween',
            value: targeting.ratingValue,
          })
        }
      }

      if (targeting.priceFrom && targeting.priceTo) {
        payload.expression.push({
          type: 'asinPriceBetween',
          value: `${targeting.priceFrom}-${targeting.priceTo}`,
        })
        if (needResolve) {
          expressionResolve.push({
            type: 'asinPriceBetween',
            value: `${targeting.priceFrom}-${targeting.priceTo}`,
          })
        }
      } else if (targeting.priceFrom && !targeting.priceTo) {
        payload.expression.push({
          type: 'asinPriceGreaterThan',
          value: targeting.priceFrom,
        })
        if (needResolve) {
          expressionResolve.push({
            type: 'asinPriceGreaterThan',
            value: targeting.priceFrom,
          })
        }
      } else if (!targeting.priceFrom && targeting.priceTo) {
        payload.expression.push({
          type: 'asinPriceLessThan',
          value: targeting.priceTo,
        })
        if (needResolve) {
          expressionResolve.push({
            type: 'asinPriceLessThan',
            value: targeting.priceTo,
          })
        }
      }
      break
    case 'audience_category':
      payload.expression.push({
        type: targeting.audienceType ? targeting.audienceType : 'views',
        value: [
          {
            type: 'asinCategorySameAs',
            value: targeting.id.toString().replace(targeting.audienceType, ''),
          },
          {
            type: 'lookback',
            value: targeting.lookback ? targeting.lookback.value : '30',
          },
        ],

      })
      break
    case 'audience_product':
      payload.expression.push({
        type: targeting.audienceType ? targeting.audienceType : 'views',
        value: [
          {
            type: targeting.id.replace(targeting.audienceType, ''),
          },
          {
            type: 'lookback',
            value: targeting.lookback ? targeting.lookback.value : '30',
          },
        ],
      })
      break
    case 'audience_refine':
      const values = []
      values.push({
        type: 'asinCategorySameAs',
        value: targeting.id.toString().replace(targeting.audienceType, ''),
      })

      if (targeting.brandId) {
        values.push({
          type: 'asinBrandSameAs',
          value: targeting.brandId.toString(),
        })
      }

      if (targeting.ratingValue) {
        values.push({
          type: 'asinReviewRatingBetween',
          value: targeting.ratingValue,
        })
      }

      if (targeting.priceFrom && targeting.priceTo) {
        values.push({
          type: 'asinPriceBetween',
          value: `${targeting.priceFrom}-${targeting.priceTo}`,
        })
      } else if (targeting.priceFrom && !targeting.priceTo) {
        values.push({
          type: 'asinPriceGreaterThan',
          value: targeting.priceFrom,
        })
      } else if (!targeting.priceFrom && targeting.priceTo) {
        values.push({
          type: 'asinPriceLessThan',
          value: targeting.priceTo,
        })
      }
      values.push({
        type: 'lookback',
        value: targeting.lookback ? targeting.lookback.value : '30',
      })
      payload.expression.push({
        type: targeting.audienceType ? targeting.audienceType : 'views',
        value: values,
      })
      break
    case 'audience':
      payload.expression.push({
        type: 'audience',
        value: [
          {
            type: 'audienceSameAs',
            value: targeting.id.toString(),
          },
        ],
      })
      break
    default:
      return null
  }

  if (needResolve) {
    payload.expressionResolve = expressionResolve
  }

  return payload
}

export const parseNtExp = (product) => {
  if (product.type === 'brand') {
    return [{
      type: 'asinBrandSameAs',
      value: product.id,
    }]
  }
  if (product.type === 'product') {
    return [{
      type: 'asinSameAs',
      value: product.asin,
    }]
  }
  return []
}

export const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function () {
    //
  }
}

export const isNonEndemic = account => (
  account
  && account.seller_type === 'vendor'
  && account.sub_type === 'non-endemic'
)

export const compileSdCreative = (
  basicInfo,
  logoCrop,
  customCrop,
  customSquareCrop,
  adFormat = 'image',
) => {
  const creativeProps = {}
  if (basicInfo.hasLogo && basicInfo.logoAsset) {
    const brandLogo = {
      assetId: basicInfo.logoAsset.assetId,
      assetVersion: basicInfo.logoAsset.versionId
    }
    if (logoCrop) {
      brandLogo.croppingCoordinates = logoCrop
    }
    creativeProps.brandLogo = brandLogo
  }

  if (basicInfo.hasHeadline && basicInfo.headline !== '') {
    creativeProps.headline = basicInfo.headline
  }

  if (adFormat === 'image'
    && basicInfo.hasCustomImage
    && basicInfo.customImageAsset
  ) {
    const rectCustomImage = {
      assetId: basicInfo.customImageAsset.assetId,
      assetVersion: basicInfo.customImageAsset.versionId
    }
    const squareCustomImage = {
      assetId: basicInfo.customImageAsset.assetId,
      assetVersion: basicInfo.customImageAsset.versionId
    }
    if (customCrop) {
      rectCustomImage.croppingCoordinates = customCrop
    }
    if (customSquareCrop) {
      squareCustomImage.croppingCoordinates = customSquareCrop
    }
    creativeProps.rectCustomImage = rectCustomImage
    creativeProps.squareCustomImage = squareCustomImage
  }

  if (adFormat === 'video') {
    creativeProps.video = {
      assetId: basicInfo.videoAsset.assetId,
      assetVersion: basicInfo.videoAsset.versionId
    }
  }

  return creativeProps
}

export const compileSbAd = (
  adFormat,
  landingPageType,
  adName,
  basicInfo,
  goal,
  products,
  creativeProducts,
  creativeSubPages,
  storePageUrl,
  logoCrop,
  customCrop,
) => {
  if (adFormat === AD_FORMAT_COLLECTION) {
    let landingPage
    if (landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE) {
      landingPage = {
        asins: products.map(product => product.asin),
        pageType: 'PRODUCT_LIST',
      }
    } else {
      landingPage = {
        url: storePageUrl,
        pageType: 'STORE',
      }
    }

    const adDetails = {
      landingPage,
      name: adName,
      creative: {
        asins: creativeProducts.map(product => product.asin),
        brandName: basicInfo.brandName,
        brandLogoAssetID: basicInfo.logoAsset.assetId,
        headline: basicInfo.headline,
      },
    }

    if (logoCrop) {
      adDetails.creative.brandLogoCrop = logoCrop
    }

    if (
      (
        landingPageType === LANDING_PAGE_TYPE_STORE
        && goal === GOAL_BRAND_IMPRESSION_SHARE
      )
      ||
      (
        landingPageType === LANDING_PAGE_TYPE_NEW_LANDING_PAGE
        && basicInfo.hasCustomImage
        && basicInfo.customImageAsset
      )
    ) {
      adDetails.creative.customImageAssetId = basicInfo.customImageAsset.assetId
      if (customCrop) {
        adDetails.creative.customImageCrop = customCrop
      }
    }

    return adDetails
  }

  if (adFormat === AD_FORMAT_SPOTLIGHT) {
    const adDetails = {
      landingPage: {
        url: storePageUrl,
      },
      name: adName,
      creative: {
        brandName: basicInfo.brandName,
        subpages: creativeSubPages.map(subPage => ({
          pageTitle: subPage.name,
          asin: subPage.product.asin,
          url: subPage.url,
        })),
        brandLogoAssetID: basicInfo.logoAsset.assetId,
        headline: basicInfo.headline,
      },
    }

    if (logoCrop) {
      adDetails.creative.brandLogoCrop = logoCrop
    }

    return adDetails
  }

  if (adFormat === AD_FORMAT_VIDEO) {
    if (landingPageType === LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE) {
      return {
        name: adName,
        creative: {
          asins: creativeProducts.map(product => product.asin),
          videoAssetIds: [basicInfo.videoAsset.assetId],
        },
      }
    }

    // Brand video ads.
    return {
      landingPage: {
        pageType: 'STORE',
        url: storePageUrl,
      },
      name: adName,
      creative: {
        asins: creativeProducts.map(product => product.asin),
        videoAssetIds: [basicInfo.videoAsset.assetId],
        brandName: basicInfo.brandName,
        brandLogoAssetID: basicInfo.logoAsset.assetId,
        headline: basicInfo.headline,
      },
    }
  }
}

// Get a human-readable job name for a given type.
export const getJobName = (type, payload) => {
  if (type === JOB_TYPE_BULK_BID_OP) {
    if (payload.action === BULK_ACTION_EXPENSIVE) {
      return `${MODULE_NAME_ADVANCED} / ${BULK_ACTION_NAME_EXPENSIVE}`
    }
    if (payload.action === BULK_ACTION_NO_IMPRESSION) {
      return `${MODULE_NAME_ADVANCED} / ${BULK_ACTION_NAME_NO_IMPRESSION}`
    }
    if (payload.action === BULK_ACTION_LOW_CONVERSION) {
      return `${MODULE_NAME_ADVANCED} / ${BULK_ACTION_NAME_LOW_CONVERSION}`
    }
    if (payload.action === BULK_ACTION_NO_SALES) {
      return `${MODULE_NAME_ADVANCED} / ${BULK_ACTION_NAME_NO_SALES}`
    }
  } else if (type === JOB_TYPE_BULK_ADVANCED_NEGATIVE) {
    return `${MODULE_NAME_ADVANCED} / ${BULK_ACTION_NAME_NEGATIVES}`
  } else if (type === JOB_TYPE_BULK_PRODUCT_EX) {
    if (payload.typeTarget === 'asin') {
      return MODULE_NAME_PT_EX_ASIN
    }
    return MODULE_NAME_PT_EX_CATEGORY
  }

  const jobTypeLabels = {
    [JOB_TYPE_BULK_SKU_OP]: MODULE_NAME_SKU_OP,
    [JOB_TYPE_BULK_BID_OP]: MODULE_NAME_TARGET_OP,
    [JOB_TYPE_BULK_ST_OP]: MODULE_NAME_ST_OP,
    [JOB_TYPE_BULK_NEGATIVE]: MODULE_NAME_NEGATIVE,
    [JOB_TYPE_BULK_PLACEMENT_OP]: MODULE_NAME_PLACEMENT_OP,
    [JOB_TYPE_BULK_TARGET_EX]: MODULE_NAME_TARGET_EX,
    [JOB_TYPE_BULK_ST_EX]: MODULE_NAME_ST_EX,
    [JOB_TYPE_BULK_TARGET_SEARCH]: MODULE_NAME_TARGET_SEARCH,
    [JOB_TYPE_BULK_DUPLICATE_TARGETS]: MODULE_NAME_DUPLICATE_TARGETS,
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES]: 'Update campaign states',
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS]: 'Update campaign target ACoS',
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS]: 'Update campaign budgets',
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS]: 'Update campaign portfolios',
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES]: 'Update campaign names',
    [JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS]: 'Update campaign biddings',
    [JOB_TYPE_BULK_UPDATE_PA_STATES]: 'Update product ads states',
    [JOB_TYPE_BULK_UPDATE_KEYWORD_STATES]: 'Update keyword states',
    [JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS]: 'Update keyword bids',
    [JOB_TYPE_BULK_UPDATE_TARGET_STATES]: 'Update target states',
    [JOB_TYPE_BULK_UPDATE_TARGET_BIDS]: 'Update target bids',
    [JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS]: 'Archive negative keywords',
    [JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS]: 'Archive negative targets',
    [JOB_TYPE_BULK_CREATE_PRODUCT_ADS]: 'Add product ads',
    [JOB_TYPE_BULK_CREATE_TARGETS]: 'Add targets',
    [JOB_TYPE_BULK_CREATE_NEGATIVES]: 'Add negatives',
    [JOB_TYPE_ACTIVITY_LOG_DOWNLOAD]: 'Download activity logs',
    [JOB_TYPE_AUDIT_REPORT]: 'Audit Report',
  }

  return jobTypeLabels[type]
}

export const ignoreOutsideClick = (event) => {
  // Date range picker renders itself as a popup outside the pane,
  // causing clicking on it to close the pane. Below fixes this behavior.
  if (document.getElementsByClassName('rs-picker-daterange-menu').length) {
    if (document.getElementsByClassName('rs-picker-daterange-menu')[0].contains(event.target)) {
      return true
    }
  }
  // Date picker renders itself as a popup outside pane,
  // causing clicking on it to close pane. Below fixes this behavior.
  if (document.getElementsByClassName('rs-picker-date-menu').length) {
    if (document.getElementsByClassName('rs-picker-date-menu')[0].contains(event.target)) {
      return true
    }
  }
  // Modal dialog renders itself as a popup outside the pane,
  // causing clicking on it to close the  pane. Below fixes this behavior.
  if (document.getElementsByClassName('rs-modal-dialog').length) {
    if (document.getElementsByClassName('rs-modal-dialog')[0].contains(event.target)) {
      return true
    }
  }
  if (document.getElementsByClassName('rs-modal-backdrop').length) {
    if (document.getElementsByClassName('rs-modal-backdrop')[0].contains(event.target)) {
      return true
    }
  }
  // Toast renders itself as a popup outside the pane,
  // causing clicking on it to close the pane. Below fixes this behavior.
  if (document.getElementsByClassName('notification').length) {
    if (document.getElementsByClassName('notification')[0].contains(event.target)) {
      return true
    }
  }
  if (document.getElementsByClassName('job-checker-component').length) {
    if (document.getElementsByClassName('job-checker-component')[0].contains(event.target)) {
      return true
    }
  }
  return false
}

/**
 * Check if a given search term is ASIN or not.
 * @param {String} searchTerm
 * @returns true if ASIN, false otherwise.
 */
export const isAsin = (searchTerm) => (
  /^[0-9a-z]{10}$/ig.test(searchTerm)
)
