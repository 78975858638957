import React from 'react'
import { Modal } from 'rsuite'

import TrendingChart from '../CommonComponents/TrendingChart'

const ThumbHistory = ({ title, metric, metricKey, areaData, onClose, startDate, endDate }) => {
  if (typeof metric === 'undefined') {
    return null
  }

  return (
    <Modal
      backdrop
      className="thumb-history-modal"
      show
      size="lg"
      onClick={onClose}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          { title }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="metric">
          Metric: <strong>{ metric }</strong>
        </div>
        <div className="chart-wrapper">
          <TrendingChart
            metricKey={metricKey}
            showXAxis
            showYAxis
            showToolTip
            chartData={areaData}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ThumbHistory
