import React, { useState } from 'react'

import BulkResultContainer from '../BulkResultContainer'
import DupKeywordTable from './DupKeywordTable'
import DupTargetTable from './DupTargetTable'
import DupSTTable from './DupSTTable'

const TAB_KEYWORD = 'keyword'
const TAB_SEARCH_TERM = 'search_term'
const TAB_TARGET = 'target'

const tabList = [
  {
    value: TAB_KEYWORD,
    label: 'Keywords',
  },
  {
    value: TAB_SEARCH_TERM,
    label: 'Search Terms',
  },
  {
    value: TAB_TARGET,
    label: 'ASINs',
  },
]

const DupResult = ({ onChangeDate }) => {
  const [currentTab, setCurrentTab] = useState(TAB_KEYWORD)

  const renderContents = () => {
    if (currentTab === TAB_KEYWORD) {
      return (
        <DupKeywordTable
          onChangeDate={onChangeDate}
        />
      )
    }

    if (currentTab === TAB_TARGET) {
      return (
        <DupTargetTable
          onChangeDate={onChangeDate}
        />
      )
    }

    if (currentTab === TAB_SEARCH_TERM) {
      return (
        <DupSTTable
          onChangeDate={onChangeDate}
        />
      )
    }
  }

  return (
    <BulkResultContainer>
      <div className="target-search-result-container dup-result-container">
        <div className="tab-list">
          {
            tabList.map((tab) => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? "tab selected" : "tab"}
                onClick={() => { setCurrentTab(tab.value)}}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        <div className="section-label">
          Click the Keyword, Search Term or ASIN to see locations of duplicates
        </div>
        { renderContents() }
      </div>
    </BulkResultContainer>
  )
}

export default DupResult
