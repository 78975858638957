/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Modal } from 'rsuite'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { FiInfo } from 'react-icons/fi'

import {
  LOGIN_CLIENT_ID,
  STRIPE_PUB_KEY,
} from '../../config/api'

import { signup, getStripeSecret } from '../../redux/actions/auth'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import AmazonSection from './SignupSpecialAmazonSection'
import BillingSection from './BillingSection'

import {
  regionList,
  STORAGE_KEY_REGION,
} from '../../utils/defaultValues'

const STEP_ACCOUNT = 'STEP_ACCOUNT'
const STEP_PAYMENT = 'STEP_PAYMENT'

const SignupSpecialComplete = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const signupBasicInfo = useSelector(state => state.auth.signupBasicInfo)
  const isADCodeGetting = useSelector(state => state.auth.isADCodeGetting)
  const adRefreshToken = useSelector(state => state.auth.adRefreshToken)
  const profileList = useSelector(state => state.auth.profileList)

  const [step, steStep] = useState(STEP_ACCOUNT)
  const [region, setRegion] = useState(regionList[0])
  const [selectedProfileIds, setSelectedProfileIds] = useState([])
  const [billing, setBilling] = useState({
    firstName: signupBasicInfo ? signupBasicInfo.firstName : '',
    lastName: signupBasicInfo ? signupBasicInfo.lastName : '',
  })
  const [isBillingComplete, setIsBillingComplete] = useState(false)
  const [isSigning, setIsSigning] = useState(false)

  // Inject Amazon LWA script.
  useEffect(() => {
    let amazonRoot = document.getElementById('amazon-root')
    if (!amazonRoot) {
      amazonRoot = document.createElement('div')
      amazonRoot.setAttribute('id', 'amazon-root')
      document.body.appendChild(amazonRoot)

      const script = document.createElement('script')
      script.setAttribute('type', 'text/javascript')
      script.innerHTML = `
        window.onAmazonLoginReady = function() {
          amazon.Login.setClientId('${LOGIN_CLIENT_ID}');
        };
        (function(d) {
          var a = d.createElement('script'); a.type = 'text/javascript';
          a.async = true; a.id = 'amazon-login-sdk';
          a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
          d.getElementById('amazon-root').appendChild(a);
        })(document);
      `
      document.body.appendChild(script)
    }

    const savedRegion = window.sessionStorage.getItem(STORAGE_KEY_REGION)
    if (savedRegion) {
      const saved = regionList.find(option => option.value === savedRegion)
      if (saved) {
        setRegion(saved)
      }
    }
  }, [])

  useEffect(() => {
    if (signupBasicInfo === null) {
      history.push('/amz-webinar-signup')
    }
  }, []) // eslint-disable-line

  const completeSignup = (billingFirstName, billingLastName, stripeToken = null) => {
    setIsSigning(true)

    const profiles = []
    profileList.forEach((profile) => {
      if (selectedProfileIds.indexOf(profile.profileId) === -1) {
        return
      }
      profiles.push({
        profileId: profile.profileId,
        countryCode: profile.countryCode.toLowerCase(),
        sellerStringId: profile.accountInfo.sellerStringId,
        brandEntityId: profile.accountInfo.brandEntityId,
        brandName: profile.accountInfo.brandName,
        type: profile.accountInfo.type.toLowerCase(),
        subType: (profile.accountInfo.subType || '').toLowerCase(),
      })
    })

    if (!profiles.length) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: 'Please select accounts.',
      })
      return
    }

    window.sessionStorage.removeItem(STORAGE_KEY_REGION)

    dispatch(signup({
      firstName: signupBasicInfo.firstName,
      lastName: signupBasicInfo.lastName,
      email: signupBasicInfo.email,
      couponCode: '',
      adRefreshToken,
      billingFirstName: billingFirstName,
      billingLastName: billingLastName,
      stripeToken,
      buyUpsell: false,
      profiles,
      isAgency: true,
      isSpecial: true,
    })).then(() => {
      window.gtag('event', 'signup_complete')
      setIsSigning(false)
      window.location.href = 'https://go.oncehub.com/amazonwebinar'
    }).catch((error) => {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const handleRegionChange = (option) => {
    setRegion(option)
    window.sessionStorage.setItem(STORAGE_KEY_REGION, option.value)
  }

  const handleBillingChange = (name, value) => {
    setBilling(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleBack = (event) => {
    event.preventDefault()
    steStep(STEP_ACCOUNT)
  }

  const handleContinue = () => {
    steStep(STEP_PAYMENT)
  }

  const handleComplete = async () => {
    if (!stripe || !elements || !isBillingComplete) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    setIsSigning(true)
    const { token, error } = await stripe.createToken(cardElement, {
      name: `${billing.firstName} ${billing.lastName}`,
    })

    if (!token) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: error.message,
      })
      return
    }

    const secret = await dispatch(getStripeSecret())

    const result = await stripe.confirmCardPayment(secret.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result && result.error) {
      setIsSigning(false)
      toast.show({
        title: 'Danger',
        description: result.error.message,
      })
      return
    }

    completeSignup(billing.firstName, billing.lastName, token.id)
  }

  const renderContents = () => {
    if (step === STEP_ACCOUNT) {
      return (
        <>
          <div className="modal-description">
            <FiInfo size={16} color="#303AB2" />
            Trusted by thousands of sellers. Money Back Guarantee. Cancel anytime.
          </div>
          <AmazonSection
            isLoading={isADCodeGetting}
            adRefreshToken={adRefreshToken}
            region={region}
            regionList={regionList}
            profileList={profileList}
            selectedProfileIds={selectedProfileIds}
            onChange={handleRegionChange}
            onSelectProfileIds={setSelectedProfileIds}
          />
        </>
      )
    }

    return (
      <>
        <BillingSection
          billing={billing}
          onChange={handleBillingChange}
          onComplete={() => { setIsBillingComplete(true) }}
        />
        <div className="modal-description">
          <span>
            You’ll be charged <strong>1.75%</strong> of monthly Ad spend
            across connected accounts after free trial.
            After you enter your payment details, we will begin fetching your data from Amazon
            and you will be redirected to our scheduling page
            so that you may book your first Entourage training session.
            We will also email you as soon as your data arrives.
          </span>
        </div>
      </>
    )
  }

  const renderFooter = () => {
    if (step === STEP_ACCOUNT) {
      if (!adRefreshToken) {
        return null
      }

      return (
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-blue"
            disabled={!selectedProfileIds.length || isSigning}
            onClick={handleContinue}
          >
            Continue
          </button>
        </Modal.Footer>
      )
    }

    const isFilled = billing.firstName
      && billing.lastName
      && !isSigning

    return (
      <Modal.Footer className="text-center">
        <button
          type="button"
          className="btn btn-blue btn-signup"
          disabled={!isFilled || !isBillingComplete}
          onClick={handleComplete}
        >
          Start free trial now
        </button>
        <a
          href="#"
          onClick={handleBack}
        >
          Go Back
        </a>
      </Modal.Footer>
    )
  }

  return (
    <div className="signup-complete-page">
      <Modal backdrop="static" show size="sm" className="signup-complete-modal">
        <Modal.Header closeButton={false}>
          <Modal.Title>
            {
              step === STEP_ACCOUNT ? 'Choose Your Amazon Accounts' : 'Checkout'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${adRefreshToken ? '' : 'overflow-none'}`}>
          { renderContents() }
        </Modal.Body>
        { isSigning && <LoaderComponent /> }
        { renderFooter() }
      </Modal>
    </div>
  )
}

const WrapperWithStripe = (props) => {
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUB_KEY))
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <SignupSpecialComplete {...props} />
    </Elements>
  )
}

export default WrapperWithStripe
