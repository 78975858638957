export const GET_ALL_SPONSORED_DATA_SUCCEED = 'GET_ALL_SPONSORED_DATA_SUCCEED'

export const GET_SPONSORED_PRODUCTS_DATA_SUCCEED = 'GET_SPONSORED_PRODUCTS_DATA_SUCCEED'

export const GET_SPONSORED_BRANDS_DATA_SUCCEED = 'GET_SPONSORED_BRANDS_DATA_SUCCEED'

export const GET_SPONSORED_DISPLAY_DATA_SUCCEED = 'GET_SPONSORED_DISPLAY_DATA_SUCCEED'

export const SET_REPORT_DATE_RANGE = 'SET_REPORT_DATE_RANGE'

export const GET_HEALTH_SALES_DATA_SUCCEED = 'GET_HEALTH_SALES_DATA_SUCCEED'

export const GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED = 'GET_PLACEMENT_PERFORMANCE_DATA_SUCCEED'
export const GET_PLACEMENT_PERFORMANCE_SP_DATA = 'GET_PLACEMENT_PERFORMANCE_SP_DATA'
export const GET_PLACEMENT_PERFORMANCE_SB_DATA = 'GET_PLACEMENT_PERFORMANCE_SB_DATA'
