import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../CommonComponents/ToastComponent/toast'
import VideoLink from '../../../CommonComponents/VideoLink'
import PlacementModal from '../../../CommonComponents/PlacementModal'
import CustomTooltip from '../../../CommonComponents/CustomTooltip'

import { updateBiddings, updateTargetAcos } from '../../../../redux/actions/campaign'

import {
  getPlacementData,
  applyPlacementBidding,
} from '../../../../redux/actions/campaignDetail'

import {
  formatValue,
  formatCurrency,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
} from '../../../../services/helper'

import {
  placementList,
  MAX_PLACEMENT_BID_CHANGE,
} from '../../../../utils/defaultValues'

const columns = [
  { key: 'placementText', name: 'Placement', className: 'col-placement' },
  { key: 'strategy', name: 'Bidding Strategy', className: 'col-strategy' },
  { key: 'currentBidAdjustment', name: 'Current Modifier' },
  { key: 'newBidAdjustment', name: 'Recomm Modifier' },
  { key: 'baseBid', name: 'Recomm Base Bid' },
  { key: 'revenue', name: 'Sales' },
  { key: 'cost', name: 'Spend' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'cpc', name: 'Ave CPC' },
  { key: 'orders', name: 'Orders' },
  { key: 'acos', name: 'ACoS %' },
]

const videoList = [
  { title: 'Placement Optimization Video', url: 'https://www.loom.com/embed/123a861345b74c9cbaec11e85495462d' },
]

const PlacementOPTab = ({ campaignType }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const { id: campaignId } = useParams()

  const [isLoading, setIsLoading] = useState(false)
  const [placementData, setPlacementData] = useState({})
  const [placements, setPlacements] = useState([])
  const [acos, setAcos] = useState(0)
  const [hasInvalid, setHasInvalid] = useState(false)
  const [open, setOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isUpdatingAcos, setIsUpdatingAcos] = useState(false)

  useEffect(() => {
    if (!currentAcos) {
      return
    }
    setAcos(currentAcos)
  }, [currentAcos])

  useEffect(() => {
    if (!campaignId || !currentStartDate || !currentEndDate) {
      return
    }
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const data = await dispatch(getPlacementData(accessToken, campaignId))
      setPlacementData(data)
      setIsLoading(false)
    })()
  }, [campaignId, currentStartDate, currentEndDate]) // eslint-disable-line

  useEffect(() => {
    let strategy = 'Fixed bid'
    const adjustmentsByPlacement = {}
    if (currentDetail.bidding) {
      if (currentDetail.bidding.strategy) {
        if (currentDetail.bidding.strategy === 'legacyForSales') {
          strategy = 'Dynamic bids - down only'
        } else if (currentDetail.bidding.strategy === 'autoForSales') {
          strategy = 'Dynamic bids - up and down'
        } else {
          strategy = 'Fixed bid'
        }
      }

      if (currentDetail.bidding.adjustments) {
        currentDetail.bidding.adjustments.forEach((adjustment) => {
          adjustmentsByPlacement[adjustment.predicate] = adjustment.percentage
        })
      }
    }

    let worstAcos = 0
    let hasInvalidPlacement = false
    const records = Object.keys(placementList).map((placement) => {
      const currentBidAdjustment = adjustmentsByPlacement[placement] || 0

      const revenue = placementData[placement] ? parseFloat(placementData[placement].revenue || 0) : 0

      if (!revenue) {
        hasInvalidPlacement = true
      }

      const derived = calcDerivedMetrics({
        placement,
        placementText: placementList[placement],
        strategy,
        currentBidAdjustment,
        revenue,
        cost: placementData[placement] ? (placementData[placement].cost || 0) : 0,
        clicks: placementData[placement] ? (placementData[placement].clicks || 0) : 0,
        impressions: placementData[placement] ? (placementData[placement].impressions || 0) : 0,
        orders: placementData[placement] ? (placementData[placement].orders || 0) : 0,
      })

      if (derived.acos && (!worstAcos || worstAcos < derived.acos)) {
        worstAcos = derived.acos
      }

      return derived
    })

    let baseBid = 0
    if (worstAcos) {
      baseBid = Math.ceil(acos / worstAcos * 100) - 100
    }

    setPlacements(records.map((record) => {
      let newBidAdjustment = 0
      if (record.acos) {
        const acosAfterBaseBidChange = record.acos + (record.acos * baseBid / 100)
        if (acosAfterBaseBidChange) {
          const raisedBy = acos / acosAfterBaseBidChange * 100
          newBidAdjustment = Math.min(
            Math.ceil(
              Math.max((100 + record.currentBidAdjustment) * raisedBy / 100 - 100, 0)
            ),
            MAX_PLACEMENT_BID_CHANGE
          )
        }
      }

      return Object.assign(record, {
        baseBid,
        newBidAdjustment,
      })
    }))

    setHasInvalid(hasInvalidPlacement)
  }, [currentDetail, placementData, acos])

  const handleChangeAcos = (event) => {
    if (!event.target.value) {
      return
    }
    setAcos(event.target.value)
  }

  const handleSaveAcos = async () => {
    setIsUpdatingAcos(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateTargetAcos(accessToken, [{
      campaignId,
      campaignType,
      acos,
      originalAcos: currentAcos,
    }]))
    setIsUpdatingAcos(false)
  }

  const handleAdjustBid = async (bidValue) => {
    const bidding = Object.assign({}, currentDetail.bidding || {})
    const adjustments = [...bidding.adjustments || []]
    Object.keys(bidValue).forEach(placement => {
      const index = adjustments.findIndex(adjustment =>
        adjustment.predicate === placement
      )
      if (index !== -1) {
        adjustments[index] = Object.assign({}, adjustments[index], {
          percentage: bidValue[placement],
        })
      } else {
        adjustments.push({
          predicate: placement,
          percentage: bidValue[placement],
        })
      }
    })
    bidding.adjustments = adjustments

    setIsUpdating(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateBiddings(accessToken, [{
      campaignId,
      bidding,
      oldBidding: currentDetail.bidding || {},
    }]))
    setIsUpdating(false)
  }

  const handleApplyRecommendation = async (percent) => {
    if (hasInvalid) {
      toast.show({
        title: 'Warning',
        description: 'All placements should have at least one sale to apply recommendations.',
      })
      return
    }

    const bidding = Object.assign({}, (currentDetail || {}).bidding || {})

    const oldAdjustments = {};
    (bidding.adjustments || []).forEach((adjustment) => {
      oldAdjustments[adjustment.predicate] = adjustment.percentage
    })

    bidding.adjustments = []
    placements.forEach((placement) => {
      const oldPercent = oldAdjustments[placement.placement] || 0

      bidding.adjustments.push({
        predicate: placement.placement,
        percentage: oldPercent
          + Math.round((placement.newBidAdjustment - oldPercent) / 100 * percent),
      })
    })

    setIsUpdating(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(applyPlacementBidding(accessToken, {
      campaignId,
      bidding,
      oldBidding: (currentDetail || {}).bidding || {},
      baseBid: Math.round(placements[0].baseBid / 100 * percent),
    }))
    setIsUpdating(false)
  }

  const renderAcos = () => {
    const isSameAcos = currentAcos === acos

    return (
      <div className="acos-container">
        <span>Target ACoS (%)</span>
        <input
          type="number"
          value={acos}
          onChange={handleChangeAcos}
        />
        {
          !isSameAcos && (
            <button
              type="button"
              className="btn btn-red"
              disabled={isUpdatingAcos}
              onClick={handleSaveAcos}
            >
              Save
            </button>
          )
        }
      </div>
    )
  }

  const renderAction = () => (
    <>
      <button
        className="btn btn-blue optimize-placement-button"
        onClick={() => { setOpen(true) }}
      >
        Optimize Placement
      </button>
      <PlacementModal
        open={open}
        isLoading={isUpdating}
        adjustments={currentDetail?.bidding?.adjustments || []}
        onAdjustBid={handleAdjustBid}
        onApplyRecommend={handleApplyRecommendation}
        onClose={() => { setOpen(false) }}
      />
    </>
  )

  const renderPlacement = record => (
    <>
      <div className="table-col col-placement">
        { record.placementText }
      </div>
      <div className="table-col col-strategy">
        { record.strategy }
      </div>
      <div className="table-col">
        { formatValue(record.currentBidAdjustment, 'percent', 0) }
      </div>
      <div className="table-col">
        {
          hasInvalid ? 'N/A' : formatValue(record.newBidAdjustment, 'percent', 0)
        }
      </div>
      <div className="table-col">
        {
          hasInvalid ? 'N/A' : formatValue(record.baseBid, 'percent', 0)
        }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.ctr, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cpc, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent') }
      </div>
    </>
  )

  if (campaignType !== 'sp') {
    return null
  }

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <div className="campaign-detail-placement-op campaign-detail-tab">
      <div className="tab-info">
        <div className="tab-title">
          Placement Optimization
          <CustomTooltip placement="right">
            <p>
              By applying the recommendations, you’ll be adjusting
              the base bid of each target while adjusting the placements
              for both top of search and product pages
              to help match your overall target ACoS for the campaign.
            </p>
            <p>
              You can also adjust each placement separately by selecting them first.
            </p>
          </CustomTooltip>
        </div>
        <VideoLink
          videoList={videoList}
          modalTitle='Placement Optimization'
        />
        { renderAcos() }
      </div>
      <SortableTable
        isLoading={isLoading}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['placementText', 'strategy'])}
        className="table-placements"
        records={placements}
        idField="placement"
        searchFields={['placement']}
        noCheckBox
        hasSticky
        hasDateRange
        filterName="campaignDetailPlacement"
        exportFileName="CampaignDetailPlacement"
        getExportData={getExportData}
        renderRecord={renderPlacement}
        renderTopRight={renderAction}
      />
    </div>
  )
}

export default PlacementOPTab
