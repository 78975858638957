import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { FiTrash2 } from 'react-icons/fi'

import ConfirmModal from '../../../../CommonComponents/ConfirmModal'
import { toast } from '../../../../CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../../../CommonComponents/LoaderComponent'
import ModalAddAdgroup from '../../../../CampaignDetailComponent/modals/ModalAddAdgroup'
import CustomTooltip from '../../../../CommonComponents/CustomTooltip'
import CampaignDetails from './CampaignDetails'
import AddProductsModal from '../../../modals/AddProductsModal'
import AddTargetsModal from './AddTargetsModal'

import {
  updateTargetAcos,
  updateBiddings,
  updateCampaignNames,
} from '../../../../../redux/actions/campaign'

import { updatePortfolios } from '../../../../../redux/actions/campaignDetail'

import {
  getAllTags,
  getAllTagsForCampaign,
  createAndAddTagToCampaigns,
  addTagToCampaign,
  removeTagsfromCampaign
} from '../../../../../redux/actions/tag'
import { getPortfolios } from '../../../../../redux/actions/portfolio'

import {
  PLACEMENT_TOP,
  PLACEMENT_PRODUCT_PAGE,
  PLACEMENT_REST_OF_SEARCH,
} from '../../../../../utils/defaultValues'

const biddingStrategyOptions = [
  {
    value: 'legacyForSales',
    label: 'Dynamic bids - down only',
  },
  {
    value: 'autoForSales',
    label: 'Dynamic bids - up and down',
  },
  {
    value: 'manual',
    label: 'Fixed bids',
  },
]

const CampaignInfo = ({ campaignId, isNonEndemicAccount }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isLoading = useSelector(state => state.campaignDetail.isLoading)
  const isUpdatingPortfolio = useSelector(state => state.campaignDetail.isUpdatingPortfolio)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)
  const currentAdGroups = useSelector(state => state.campaignDetail.currentAdGroups)

  const isTagLoading = useSelector(state => state.tag.isLoading)
  const tags = useSelector(state => state.tag.tags)
  const isLoadingTagsForCampaign = useSelector(state => state.tag.isLoadingTagsForCampaign)
  const tagsForCampaign = useSelector(state => state.tag.tagsForCampaign)
  const isRemovingTag = useSelector(state => state.tag.isRemovingTag)

  const newPortfolioRef = useRef()

  const [campaignName, setCampaignName] = useState('')
  const [acos, setAcos] = useState(0)
  const [topSearch, setTopSearch] = useState(0)
  const [productPages, setProductPages] = useState(0)
  const [restOfSearch, setRestOfSearch] = useState(0)
  const [biddingStrategy, setBiddingStrategy] = useState({
    value: 'legacyForSales',
    label: 'Dynamic bids - down only',
  })
  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalType, setModalType] = useState('')
  const [selectedPortfolio, setSelectedPortfolio] = useState()
  const [showPortfolioSection, setShowPortfolioSection] = useState(false)
  const [adgroupCreatorVisible, setAdgroupCreatorVisible] = useState(false)
  const [openAddProductsModal, setOpenAddProductsModal] = useState(false)
  const [openAddTargetsModal, setOpenAddTargetsModal] = useState(false)
  const [tagList, setTagList] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [isCreating, setIsCreating] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isUpdatingName, setIsUpdatingName] = useState(false)
  const [isUpdatingAcos, setIsUpdatingAcos] = useState(false)
  const [isUpdatingBidding, setIsUpdatingBidding] = useState(false)
  const [isLoadingPortfolios, setIsLoadingPortfolios] = useState(false)
  const [portfolioOptions, setPortfolioOptions] = useState([])

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAllTags(accessToken))
    })()
  }, [dispatch]) // eslint-disable-line

  useEffect(() => {
    setAcos(currentAcos || 0)
  }, [currentAcos])

  useEffect(() => {
    if (!currentDetail) {
      return
    }

    if (currentDetail.bidding && currentDetail.bidding !== 'NULL') {
      let bidding = currentDetail.bidding
      if (typeof currentDetail.bidding === 'string') {
        try {
          bidding = JSON.parse(JSON.parse(currentDetail.bidding))
        } catch (e) {
          //
        }
      }

      if (bidding.strategy) {
        const option = biddingStrategyOptions.find(option =>
          option.value === bidding.strategy
        )
        if (option) {
          setBiddingStrategy(option)
        }
      }

      if (bidding.adjustments) {
        bidding.adjustments.forEach((item) => {
          if (item.predicate === PLACEMENT_TOP) {
            setTopSearch(item.percentage)
          } else if (item.predicate === PLACEMENT_PRODUCT_PAGE) {
            setProductPages(item.percentage)
          } else {
            setRestOfSearch(item.percentage)
          }
        })
      }
    }

    setCampaignName(currentDetail.name)
  }, [currentDetail]) // eslint-disable-line

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getAllTagsForCampaign(accessToken, campaignId))
    })()
  }, [campaignId]) // eslint-disable-line

  useEffect(() => {
    if (!isTagLoading && tags.length > 0) {
      const data = tags.map(t => ({
        label: t.tag_name,
        value: t.id,
      }))
      setTagList(data)
    }
  }, [tags, isTagLoading])

  useEffect(() => {
    if (!isLoadingTagsForCampaign) {
      const data = tagsForCampaign.map(t => t.id)
      setSelectedTags(data)
    }
  }, [tagsForCampaign, isLoadingTagsForCampaign])

  useEffect(() => {
    let abortCtrl
    if (showPortfolioSection) {
      abortCtrl = new AbortController();

      (async () => {
        setIsLoadingPortfolios(true)
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getPortfolios(
          accessToken,
          abortCtrl.signal,
        ))
        setPortfolioOptions(response.map(portfolio => ({
          value: portfolio.portfolio_id,
          label: portfolio.name,
        })))
        setIsLoadingPortfolios(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [showPortfolioSection]) // eslint-disable-line

  const isBiddingSame = useMemo(() => {
    let bidding = {}
    if (currentDetail && currentDetail.bidding && currentDetail.bidding !== 'NULL') {
      bidding = currentDetail.bidding
      if (typeof currentDetail.bidding === 'string') {
        try {
          bidding = JSON.parse(JSON.parse(currentDetail.bidding))
        } catch (e) {
          //
        }
      }
    }

    let originalTopSearch = 0
    let originalProductPages = 0
    let originalRestOfSearch = 0
    if (bidding.adjustments) {
      bidding.adjustments.forEach((item) => {
        if (item.predicate === PLACEMENT_TOP) {
          originalTopSearch = item.percentage
        } else if (item.predicate === PLACEMENT_PRODUCT_PAGE) {
          originalProductPages = item.percentage
        } else {
          originalRestOfSearch = item.percentage
        }
      })
    }

    return (biddingStrategy.value === bidding.strategy || '')
      && (topSearch === originalTopSearch)
      && (productPages === originalProductPages)
      && (restOfSearch === originalRestOfSearch)
  }, [currentDetail, biddingStrategy.value,
    topSearch, productPages, restOfSearch])

  const isSame = useMemo(() => {
    let same = true
    if (currentDetail) {
      same = campaignName === currentDetail.name && acos === currentAcos
      if (currentDetail.campaignType === 'sp') {
        same = same && isBiddingSame
      }
    }
    return same
  }, [currentDetail, campaignName, acos, currentAcos, isBiddingSame])

  // change bidding strategy
  const handleChangeBiddingStrategy = selectedOption => {
    setBiddingStrategy(selectedOption)
  }

  const handleChangeTopSearch = e => {
    if (!e.target.value) {
      return
    }
    setTopSearch(parseFloat(e.target.value))
  }

  const handleChangeProductPages = e => {
    if (!e.target.value) {
      return
    }
    setProductPages(parseFloat(e.target.value))
  }

  const handleChangeRestofSearch = e => {
    if (!e.target.value) {
      return
    }
    setRestOfSearch(parseFloat(e.target.value))
  }

  const doUpdateBidding = async () => {
    setIsUpdatingBidding(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateBiddings(accessToken, [{
      campaignId,
      bidding: {
        strategy: biddingStrategy.value,
        adjustments: [
          {
            predicate: PLACEMENT_TOP,
            percentage: parseInt(topSearch, 10)
          },
          {
            predicate: PLACEMENT_PRODUCT_PAGE,
            percentage: parseInt(productPages, 10)
          },
          {
            predicate: PLACEMENT_REST_OF_SEARCH,
            percentage: parseInt(restOfSearch, 10)
          },
        ],
      },
      oldBidding: currentDetail.bidding || {},
    }]))
    setIsUpdatingBidding(false)
  }

  const doUpdateName = async () => {
    setIsUpdatingName(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateCampaignNames(accessToken, [{
      campaignId,
      campaignType: currentDetail.campaignType,
      name: campaignName,
      originalName: currentDetail.name,
    }]))
    setIsUpdatingName(false)
  }

  const doUpdateAcos = async () => {
    setIsUpdatingAcos(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateTargetAcos(accessToken, [{
      campaignId,
      campaignType: currentDetail.campaignType,
      acos,
      originalAcos: currentAcos,
    }]))
    setIsUpdatingAcos(false)
  }

  const handleSaveCampaign = async () => {
    if (currentDetail.campaignType === 'sp' && !isBiddingSame) {
      doUpdateBidding()
    }

    if (campaignName !== currentDetail.name) {
      doUpdateName()
    }

    if (parseFloat(acos) !== parseFloat(currentAcos)) {
      doUpdateAcos()
    }
  }

  const handleModalConfirm = (confirmed) => {
    setShowModal(false)

    if (!confirmed) {
      return
    }

    switch (modalType) {
      case 'new portfolio':
        handleAddNewPortfolio()
        break;
      case 'existing portfolio':
        doPortfolioUpdate(
          selectedPortfolio.value,
          selectedPortfolio.label,
        )
        break;
      case 'remove portfolio':
        doPortfolioUpdate()
        break;
      default:
        break;
    }
  }

  const handleClickAddPortfolio = () => {
    if (!newPortfolioRef.current.value || newPortfolioRef.current.value === '') {
      toast.show({
        title: 'Warning',
        description: 'Portfolio name can not be empty.',
      })
      return
    }
    setModalText('Are you sure you want to create a new portfolio?')
    setModalType('new portfolio')
    setShowModal(true)
  }

  const handleAddNewPortfolio = async () => {
    doPortfolioUpdate(
      null,
      null,
      newPortfolioRef.current.value,
    )

    setShowPortfolioSection(false)
  }

  const handleClickAddExistingPortfolio = () => {
    if (!selectedPortfolio) {
      toast.show({
        title: 'Warning',
        description: 'No portfolio is selected.',
      })
      return
    }
    setModalText('Are you sure you want to add to an existing portfolio?')
    setModalType('existing portfolio')
    setShowModal(true)
  }

  const handleClickRemovePortfolio = () => {
    setModalText('Are you sure you want to remove the campaign from portfolio?')
    setModalType('remove portfolio')
    setShowModal(true)
  }

  const doPortfolioUpdate = async (
    portfolioId = null,
    portfolioName = null,
    newPortfolioName = null,
  ) => {
    const accessToken = await getAccessTokenSilently()
    await dispatch(updatePortfolios(
      accessToken,
      [{
        campaignId,
        campaignType: currentDetail.campaignType,
      }],
      portfolioId,
      portfolioName,
      newPortfolioName,
    ))
    setShowPortfolioSection(false)
  }

  const handleTagPickerChange = async (value) => {
    const accessToken = await getAccessTokenSilently()

    if (value === null) {
      await dispatch(removeTagsfromCampaign(
        accessToken,
        selectedTags,
        campaignId
      ))
      setSelectedTags([])
      return
    }
    const added = value.filter(v => !selectedTags.includes(v))
    const removed = selectedTags.filter(t => !value.includes(t))

    if (added.length) {
      const isCreated = tagList.findIndex(t => t.value === added[0]) === -1
      if (!isCreated) {
        setIsAdding(true)
        await dispatch(addTagToCampaign(
          accessToken,
          added[0],
          campaignId
        ))
        setIsAdding(false)
        setSelectedTags(p => [...p, added[0]])
      } else {
        setIsCreating(true)
        const data = await dispatch(createAndAddTagToCampaigns(
          accessToken,
          added[0],
          [campaignId.toString()]
        ))
        setIsCreating(false)
        setTagList(p => [...p, {
          value: data.id,
          label: data.tag_name,
        }])
        setSelectedTags(p => [...p, data.id])
      }
    } else if (removed.length) {
      await dispatch(removeTagsfromCampaign(
        accessToken,
        [removed[0]],
        campaignId
      ))
      setSelectedTags(p => p.filter(t => t !== removed[0]))
    }
  }

  const targetType = useMemo(() => {
    if (!currentAdGroups
      || !currentAdGroups.length
      || !currentDetail) {
      return 'keyword'
    }

    if (currentDetail.campaignType === 'sd') {
      if (currentDetail.tactic === 'T00030') {
        return 'audience'
      }
      return 'product'
    }

    if (currentAdGroups.find(adgroup => adgroup.targetType === 'products')) {
      return 'product'
    }

    return 'keyword'
  }, [currentDetail, currentAdGroups])

  const renderPortfolioSection = () => {
    if (!currentDetail
      || (currentDetail.campaignType === 'sd' && currentDetail.costType === 'vcpm')) {
      return null
    }

    return (
      <div className="portfolio-info vertical-sub-section">
        <div className="section-title">Portfolio</div>
        <div className="selected-portfolio">
          {
            currentDetail.portfolio_id ? (
              <>
                <p>This campaign is currently in:</p>
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => { setShowPortfolioSection(!showPortfolioSection) }}
                >
                  { currentDetail.portfolio_name }
                </button>
                <FiTrash2
                  title="Remove current portfolio"
                  onClick={() => { handleClickRemovePortfolio() }}
                />
              </>
            ) : (
              <>
                <p>This campaign is not currently in a portfolio.</p>
                <button
                  type="button"
                  className="btn btn-blue"
                  onClick={() => { setShowPortfolioSection(!showPortfolioSection) }}
                >
                  Update Portfolio
                </button>
              </>
            )
          }
        </div>
        {
          showPortfolioSection && (
            <div className="portfolio-actions">
              <div className="action-wrapper">
                <button
                  type="button"
                  className="btn btn-green"
                  onClick={handleClickAddPortfolio}
                >
                  Add to new portfolio
                </button>
                <input
                  type="text"
                  placeholder="New portfolio name"
                  ref={newPortfolioRef}
                />
              </div>
              <div className="action-wrapper">
                <button
                  type="button"
                  className="btn btn-green"
                  onClick={handleClickAddExistingPortfolio}
                >
                  Add to existing portfolio
                </button>
                <Select
                  options={portfolioOptions}
                  isLoading={isLoadingPortfolios}
                  onChange={setSelectedPortfolio}
                />
              </div>
            </div>
          )
        }
      </div>
    )
  }

  const renderBiddingSection = () => {
    if (!currentDetail || currentDetail.campaignType !== 'sp') {
      return null
    }

    return (
      <>
        <div className="section-title">
          Bidding Strategy
          <CustomTooltip placement="right">
            <p>In addition to your bidding strategy, you can increase bids by up to 900%.</p>
          </CustomTooltip>
        </div>
        <div className="input-row">
          Campaign bidding strategy
          <Select
            className="bidding-strategy-selector"
            options={biddingStrategyOptions}
            value={biddingStrategy}
            onChange={handleChangeBiddingStrategy}
          />
        </div>
        <div className="input-row-pair">
          <div className="input-row">
            Top of search (first page)
            <input type="number" value={topSearch} onChange={handleChangeTopSearch} />
          </div>
          <div className="input-row">
            Product pages
            <input type="number" value={productPages} onChange={handleChangeProductPages} />
          </div>
          <div className="input-row">
            Rest of the search
            <input type="number" value={restOfSearch} onChange={handleChangeRestofSearch} />
          </div>
        </div>
      </>
    )
  }

  const renderAdgroupSection = () => {
    if (!currentDetail
      || (
        currentDetail.campaignType !== 'sp'
        && currentDetail.campaignType !== 'sd'
        && !currentDetail.is_multi_adgroups_enabled
      )) {
      return null
    }

    return (
      <div className="vertical-sub-section">
        <div className="section-title">Ad Group</div>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setAdgroupCreatorVisible(true) }}
        >
          Create New Ad Group
        </button>
        {
          adgroupCreatorVisible && (
            <ModalAddAdgroup
              campaignDetail={currentDetail}
              onCancel={() => { setAdgroupCreatorVisible(false) }}
            />
          )
        }
      </div>
    )
  }

  const renderAddProductsSection = () => {
    if (!currentDetail
      || (currentDetail.campaignType !== 'sp' && currentDetail.campaignType !== 'sd')
      || isNonEndemicAccount) {
      return null
    }

    return (
      <div className="vertical-sub-section">
        <div className="section-title">Products</div>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setOpenAddProductsModal(true) }}
        >
          Add Products
        </button>
        <AddProductsModal
          show={openAddProductsModal}
          onConfirm={() => { setOpenAddProductsModal(false) }}
          onClose={() => { setOpenAddProductsModal(false) }}
        />
      </div>
    )
  }

  const renderAddTargetsSection = () => {
    if (!currentDetail) {
      return null
    }

    return (
      <div className="vertical-sub-section">
        <div className="section-title">Targets</div>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setOpenAddTargetsModal(true) }}
        >
          Add Targets
        </button>
        <AddTargetsModal
          show={openAddTargetsModal}
          currentDetail={currentDetail}
          campaignType={currentDetail.campaignType}
          targetType={targetType}
          onClose={() => { setOpenAddTargetsModal(false) }}
        />
      </div>
    )
  }

  const isDataLoading = isLoading
    || isUpdatingAcos
    || isUpdatingPortfolio
    || isUpdatingBidding
    || isUpdatingName
    || isTagLoading
    || isLoadingTagsForCampaign
    || isCreating
    || isAdding
    || isRemovingTag

  return (
    <div className={`section section-info${isDataLoading ? ' loading' : ''}`}>
      { isDataLoading && <LoaderComponent /> }
      <div className="sub-section-container">
        <CampaignDetails
          name={campaignName}
          acos={acos}
          tagList={tagList}
          selectedTags={selectedTags}
          isNonEndemicAccount={isNonEndemicAccount}
          onNameChange={setCampaignName}
          onAcosChange={setAcos}
          onTagChange={handleTagPickerChange}
        />
        <div className="sub-section">
          { renderBiddingSection() }
        </div>
        <div className="sub-section">
          { renderPortfolioSection() }
          { renderAdgroupSection() }
          { renderAddProductsSection()}
          { renderAddTargetsSection()}
        </div>
      </div>
      {
        !isSame && (
          <button
            type="button"
            className="btn btn-blue"
            onClick={() => { handleSaveCampaign() }}
          >
            Save
          </button>
        )
      }
      <ConfirmModal
        show={showModal}
        onConfirm={handleModalConfirm}
        text={modalText}
      />
    </div>
  )
}

export default CampaignInfo
