import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TargetingSelector from '../CommonComponents/TargetingSelector'

import BasicInfo from './Shared/BasicInfo'
import AdgroupInfo from './Shared/AdgroupInfo'
import TargetingTypeSelector from './Shared/TargetingTypeSelector'
import NegativeKeywordSection from './Shared/NegativeKeywordSection'
import NegativeTargetingSection from './Shared/NegativeTargetingSection'
import ProductSection from './Shared/ProductSection'

import SPBidSection from './SPBidSection'
import SPKeywordSection from './SPKeywordSection'
import SPTargetingSection from './SPTargetingSection'

import { createSpCampaign, getSpSuggestions, getSpBidSuggestions } from '../../redux/actions/campaignCreator'
import { searchProduct } from '../../redux/actions/targeting'
import { selectCurrentAccount } from '../../redux/reducers/header'

import { PLACEMENT_TOP_V3, PLACEMENT_PRODUCT_PAGE_V3 } from '../../utils/defaultValues'
import {
  getBidLimits,
  getISODate,
  isAsin,
  parseNtExp,
  parseTargeting,
} from '../../services/helper'
import { validateAdgroupName, validateBudget, validateCampaignName, validateKeywordLength, validateNKLength, validateTargets } from '../../services/validator'

const targetList = [
  {
    value: 'auto',
    name: 'Automatic targeting',
    description: 'Save time and let Amazon target your ads to all relevant customer searches based on your product info.',
  },
  {
    value: 'manual',
    name: 'Manual targeting',
    description: 'Your ads appear when a customer\'s search matches keywords/targets that you provide.',
  },
]

const bidStrategyList = [
  {
    value: 'LEGACY_FOR_SALES',
    label: 'Dynamic bids - down only',
    description: 'We’ll lower your bids in real time when your ad may be less likely to convert to a sale.',
  },
  {
    value: 'AUTO_FOR_SALES',
    label: 'Dynamic bids - up and down',
    description: 'We’ll raise your bids (by a maximum of 100%) in real time when your ad may be more likely '
      + 'to convert to a sale, and lower your bids when less likely to convert to a sale.',
  },
  {
    value: 'MANUAL',
    label: 'Fixed bids',
    description: 'We’ll use your exact bid and any manual adjustments you set, and won’t change your bids based on likelihood of a sale.',
  },
]

const SPCampaignCreator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const currentAccount = useSelector(selectCurrentAccount)

  const { getAccessTokenSilently } = useAuth0()

  const [basicInfo, setBasicInfo] = useState({
    name: '',
    portfolio: '',
    dailyBudget: 1,
    acos: 25,
    startDate: new Date(),
    endDate: null,
  })

  const [bidInfo, setBidInfo] = useState({
    strategy: bidStrategyList[0],
    defaultBid: 0.75,
    topSearchBid: 0,
    productPagesBid: 0,
  })

  const [adgroupName, setAdgroupName] = useState('Ad group 1')
  const [target, setTarget] = useState('manual')
  const [manualTarget, setManualTarget] = useState('keyword')

  const [products, setProducts] = useState([])
  const [keywords, setKeywords] = useState([])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [targetings, setTargetings] = useState([])
  const [negativeTargetings, setNegativeTargetings] = useState([])

  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  useEffect(() => {
    if (!location.state) {
      return
    }
    if (location.state.targets && location.state.targets.length) {
      setKeywords(location.state.targets.map((target, index) => ({
        id: index,
        keywordText: target,
        matchType: 'broad',
        keywordBid: 0,
      })))

      const asinTargets = location.state.targets.filter(isAsin)

      if (asinTargets.length) {
        (async () => {
          const accessToken = await getAccessTokenSilently()
          const response = await dispatch(searchProduct(accessToken, {
            asins: asinTargets.join(),
          }))
          setTargetings(response.map(product => ({
            ...product,
            isTargeted: true,
            type: 'product',
            bid: 0,
          })))
        })()
      }
    }

    if (location.state.categories && location.state.categories.length) {
      setTargetings(location.state.categories.map(category => ({
        ...category,
        type: 'category',
        bid: 0,
      })))
    }

    if (location.state.productTargeting) {
      setManualTarget('product')
    }
  }, [location.state]) // eslint-disable-line

  const getBiddingStrategy = () => {
    const bidding = {
      strategy: bidInfo.strategy.value,
      placementBidding: [],
    }

    if (bidInfo.topSearchBid) {
      bidding.placementBidding.push({
        placement: PLACEMENT_TOP_V3,
        percentage: parseInt(bidInfo.topSearchBid, 10),
      })
    }

    if (bidInfo.productPagesBid) {
      bidding.placementBidding.push({
        placement: PLACEMENT_PRODUCT_PAGE_V3,
        percentage: parseInt(bidInfo.productPagesBid, 10),
      })
    }
    return bidding
  }

  const getProductTargets = () => {
    const targets = []
    targetings.forEach((targeting) => {
      const payload = parseTargeting(targeting, true)
      if (!payload) {
        return
      }

      payload.expressionType = 'manual'
      payload.bid = parseFloat(payload.bid)

      targets.push(payload)
    })
    return targets
  }

  const getNegativeProductTargets = () => {
    return negativeTargetings.map(product => ({
      expressionType: 'manual',
      expression: parseNtExp(product),
    }))
  }

  const handleBasicInfoChange = (name, value) => {
    const newInfo = Object.assign({}, basicInfo, {
      [name]: value,
    })
    setBasicInfo(newInfo)
  }

  const handleBidChange = (name, value) => {
    const newInfo = Object.assign({}, bidInfo, {
      [name]: value,
    })
    setBidInfo(newInfo)
  }

  const handleSuggestionLoad = forTarget => async () => {
    if (products.length) {
      setIsSuggestionsLoading(true)
      const accessToken = await getAccessTokenSilently()
      dispatch(getSpSuggestions(
        accessToken,
        products.map(product => product.asin),
        forTarget,
      ))
      setIsSuggestionsLoading(false)
    }
  }

  const handleKeywordsSelect = async (keywords, reload = false, newKeywords = []) => {
    setKeywords(keywords)

    if (reload && newKeywords.length && products.length) {
      const bidding = {
        strategy: bidInfo.strategy.value,
        adjustments: [
          {
            predicate: PLACEMENT_TOP_V3,
            percentage: parseInt(bidInfo.topSearchBid, 10),
          },
          {
            predicate: PLACEMENT_PRODUCT_PAGE_V3,
            percentage: parseInt(bidInfo.productPagesBid, 10),
          },
        ],
      }

      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSpBidSuggestions(
        accessToken,
        newKeywords,
        products.map(product => product.asin),
        bidding,
      ))

      setKeywords(keywords.map((keyword) => {
        const suggestedBid = response.filter(bid => (
          bid.keywordText === keyword.keywordText
          && bid.matchType === keyword.matchType
        ))
        if (suggestedBid.length) {
          return {
            ...keyword,
            suggestedBid: suggestedBid[0].suggestedBid,
          }
        }
        return keyword
      }))
    }
  }

  const handleSave = async () => {
    const forSeller = currentAccount?.seller_type === 'seller'

    let error = validateCampaignName(
      basicInfo.name,
      forSeller,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    error = validateBudget(
      basicInfo.dailyBudget,
      'sp',
      currentAccount?.country_id,
      forSeller,
    )
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    error = validateAdgroupName(adgroupName)
    if (error) {
      toast.show({
        title: 'Warning',
        description: error,
      })
      return
    }

    const newCampaign = {
      name: basicInfo.name,
      startDate: getISODate(basicInfo.startDate),
      targetingType: target,
      dailyBudget: parseFloat(basicInfo.dailyBudget),
      portfolioId: basicInfo.portfolio.portfolio_id,
      dynamicBidding: getBiddingStrategy(),
    }

    if (basicInfo.endDate !== '' && basicInfo.startDate < basicInfo.endDate)  {
      newCampaign.endDate = getISODate(basicInfo.endDate)
    }

    const productAds = []
    const productAdWithAsins = []

    products.forEach((product) => {
      productAds.push({
        sku: product.sku,
      })

      productAdWithAsins.push({
        asin: product.asin,
        sku: product.sku,
      })
    })

    const campaignData = {
      campaigns: [newCampaign],
      acos: basicInfo.acos,
      adGroups: [{
        name: adgroupName,
        defaultBid: parseFloat(bidInfo.defaultBid),
      }],
      productAdWithAsins,
      productAds,
    }

    const bidLimits = getBidLimits(
      'sp',
      currentAccount?.country_id,
    )

    let invalidError = null
    if (manualTarget === 'product') {
      campaignData.targets = getProductTargets()
      campaignData.negatives = getNegativeProductTargets()
      invalidError = validateTargets(
        campaignData.targets,
        newCampaign.dailyBudget,
        bidLimits,
      )
    } else {
      campaignData.keywords = keywords.map(kw => ({
        keywordText: kw.keywordText !== '(_targeting_auto_)' && kw.keywordText !== '' ? kw.keywordText : kw.search,
        matchType: kw.matchType.toLowerCase(),
        bid: parseFloat(kw.keywordBid),
      }))
      campaignData.negativeKws = negativeKeywords.map(kw => ({
        keywordText: kw.keywordText !== '(_targeting_auto_)' && kw.keywordText !== '' ? kw.keywordText : kw.search,
        matchType: kw.matchType,
      }))
      invalidError = validateTargets(
        campaignData.keywords,
        newCampaign.dailyBudget,
        bidLimits,
      )
      if (!invalidError) {
        invalidError = validateKeywordLength(campaignData.keywords)
      }
      if (!invalidError) {
        invalidError = validateNKLength(campaignData.negativeKws)
      }
    }

    if (invalidError) {
      toast.show({
        title: 'Warning',
        description: invalidError,
      })
      return
    }

    setIsCreating(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(createSpCampaign(accessToken, campaignData)).then(() => {
      toast.show({
        title: 'Success',
        description: 'The campaign has been successfully created! '
          + 'Your campaign will appear in Entourage as soon as we start collecting data.',
      })

      history.push('/dashboard')
    }).catch((description) => {
      toast.show({
        title: 'Danger',
        description,
      })
    }).finally(() => {
      setIsCreating(false)
    })
  }

  const renderPageHeader = () => {
    return (
      <div className="page-header">
        <div className="page-title">Create Sponsored Product Campaign</div>
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    )
  }

  const renderInputContents = () => {
    if (manualTarget === 'keyword') {
      return (
        <>
          <SPKeywordSection
            keywords={keywords}
            isSuggestionsLoading={isSuggestionsLoading}
            onFind={handleSuggestionLoad(false)}
            onChange={handleKeywordsSelect}
          />
          <NegativeKeywordSection
            negativeKeywords={negativeKeywords}
            bidInfo={bidInfo}
            onChange={setNegativeKeywords}
          />
        </>
      )
    }

    return (
      <>
        <SPTargetingSection
          targetings={targetings}
          isSuggestionsLoading={isSuggestionsLoading}
          dailyBudget={basicInfo.dailyBudget}
          onFind={handleSuggestionLoad(true)}
          onChange={setTargetings}
        />
        <NegativeTargetingSection
          negativeTargetings={negativeTargetings}
          onChange={setNegativeTargetings}
        />
      </>
    )
  }

  const renderManualSections = () => {
    if (target !== 'manual') {
      return null
    }

    return (
      <>
        <TargetingSelector
          manualTarget={manualTarget}
          noAudience
          onChange={setManualTarget}
        />
        { renderInputContents() }
      </>
    )
  }

  return (
    <div className={`sp-campaign-creator creator-section${isCreating ? ' loading' : ''}`}>
      { isCreating && <LoaderComponent /> }
      { renderPageHeader() }
      <div className="page-content">
        <BasicInfo
          type="sp"
          info={basicInfo}
          countryCode={currentAccount?.country_id}
          isSeller={currentAccount?.seller_type === 'seller'}
          onChange={handleBasicInfoChange}
        />
        <div className="section-container">
          <div className="field-row">
            <AdgroupInfo
              name={adgroupName}
              onChange={setAdgroupName}
            />
            <div className="field-wrapper">
            </div>
          </div>
          <SPBidSection
            info={bidInfo}
            strategyList={bidStrategyList}
            onChange={handleBidChange}
          />
        </div>
        <TargetingTypeSelector
          targetList={targetList}
          target={target}
          onChange={setTarget}
        />
        <ProductSection
          products={products}
          onChange={setProducts}
        />
        { renderManualSections() }
      </div>
      <div className="page-footer">
        <button
          type="button"
          className="btn btn-red"
          onClick={handleSave}
        >
          Launch Campaign
        </button>
      </div>
    </div>
  )
}

export default SPCampaignCreator
