import React from 'react'

import CustomTooltip from '../CommonComponents/CustomTooltip'

const StExSection = ({ isCampaignsSelected, onFind }) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-blue"
        disabled={!isCampaignsSelected}
        onClick={() => { onFind() }}
      >
        { !isCampaignsSelected ? 'Select Campaigns to Continue' : 'Find Search Terms' }
      </button>
      <CustomTooltip placement="right">
        <ul>
          <li>Home of The ACoS SCRAPING TECHNIQUE</li>
          <li>First, enter your target ACoS %.</li>
          <li>Select the campaigns that you wish to pull Search Terms from. Click "Find Search Terms".</li>
          <li>
            The results will appear below, showing you the search terms within
            your target ACoS that connected to your keywords/targets
            in the campaigns selected.
            You can sort these in a variety of ways.
          </li>
          <li>
            By default, only search terms that do not yet exist as keywords/targets
            in the campaigns selected will be displayed. To show all search terms
            instead of only new search terms, uncheck the "New Keywords/Targets Only" box.
          </li>
          <li>Consider adding these to a new campaign or ACoS Scraping ad group to get more exposure to your listing!</li>
          <li>Check weekly to find new opportunities.</li>
        </ul>
      </CustomTooltip>
    </>
  )
}

export default StExSection
