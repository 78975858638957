import {
  campaignColumnSetting,
  productColumnList,
  portfolioColumnList,
  productCampaignColumnList,
  tagColumnList,
  reportWidgets,
  reportSections,
  budgetRecommendationColumnList,
} from '../../utils/defaultValues'
import { GET_ALL_ACCOUNT_START } from '../actionTypes/header'
import {
  COLUMN_EDITOR_SHOW,
  COLUMN_EDITOR_HIDE,
  SELECT_COLUMNS,
  APPLY_PRODUCT_COLUMN_CHANGES,
  ANP_SHOW,
  ANP_HIDE,
  AEP_SHOW,
  AEP_HIDE,
  COIN_PANE_TOGGLE,
  SHOW_FILTER,
  HIDE_FILTER,
  APPLY_FILTER,
  UPDATE_STAR_REPORT_WIDGET,
  UPDATE_VIEW_REPORT_WIDGET,
  SHOW_OR_HIDE_REPORT_SECTION,
  SHOW_OR_HIDE_REPORT_NOTE,
  SHOW_OR_HIDE_REPORT_NAME,
  SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE,
  SHOW_OR_HIDE_REPORT_TAG_TABLE,
  SHOW_OR_HIDE_REPORT_PRODUCT_TABLE,
  ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
  REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH,
  UPDATE_REPORT_WIDGET_DATA,
  LOAD_REPORT_TEMPLATE,
  CAMPAIGN_NOTE_PANE_SHOW,
  CAMPAIGN_NOTE_PANE_HIDE,
} from '../actionTypes/pageGlobal'

export const initialState = {
  showColumnEditor: false,
  campaignTableColumns: campaignColumnSetting[0].setting,
  productCampaignTableColumns: productCampaignColumnList.filter(col => col.default),
  productTableColumns: productColumnList.filter(col => col.default),
  portfolioTableColumns: portfolioColumnList.filter(col => col.default),
  tagTableColumns: tagColumnList.filter(col => col.default),
  budgetTableColumns: budgetRecommendationColumnList.filter(col => col.default),
  stTableColumns: [],
  showANP: false,
  showAEP: false,
  showCoinPane: false,
  // The ID of column editor that is opened now.
  visibleColumnEditorId: '',
  // The name of filter that is opened now.
  visibleFilterName: '',
  // Current filter values for various pages.
  filterValues: {},
  reportWidgetSetting: reportWidgets,
  reportSectionSetting: reportSections,
  showReportNameWidget: true,
  showReportPortfolioTable: true,
  showReportTagTable: true,
  showReportProductTable: true,
  customMetricWidgets: [],
  showCampaignNotePane: false,
}

const pageGlobal = (state = initialState, action) => {
  const initializeReportWidgetSettings = () => {
    let settings = state.reportWidgetSetting
    if (typeof settings === 'undefined') {
      settings = reportWidgets
    } else {
      Object.keys(settings).forEach((key) => {
        settings[key].forEach((widget, index) => {
          if (!reportWidgets[key]) {
            return
          }

          const found = reportWidgets[key].find(w =>
            w.key === widget.key
          )
          if (!found) {
            // The widget with changed keys - we need to remove them
            // to avoid duplicate widgets.
            settings[key] = settings[key].splice(index, 1)
          }
        })
      })
    }

    const widgetSetting = {}
    Object.keys(reportWidgets).forEach((key) => {
      if (typeof settings[key] === 'undefined') {
        widgetSetting[key] = reportWidgets[key]
      } else {
        widgetSetting[key] = settings[key]
        reportWidgets[key].forEach((widget) => {
          const found = widgetSetting[key].find(w =>
            w.key === widget.key
          )
          if (!found) {
            widgetSetting[key].push(widget)
          }
        })
      }
    })
    return widgetSetting
  }

  const initializeReportSectionSetting = () => {
    const sectionSetting = {}
    let settings = state.reportSectionSetting
    if (typeof settings === 'undefined') {
      settings = reportSections
    }
    Object.keys(reportSections).forEach((key) => {
      if (typeof settings[key] === 'undefined') {
        sectionSetting[key] = reportSections[key]
      } else {
        sectionSetting[key] = settings[key]
      }
    })
    return sectionSetting
  }

  switch (action.type) {
    case GET_ALL_ACCOUNT_START:
      return {
        ...state,
        showColumnEditor: false,
        showANP: false,
        showAEP: false,
        showCoinPane: false,
        visibleFilterName: '',
        filterValues: {},
        reportWidgetSetting: initializeReportWidgetSettings(),
        reportSectionSetting: initializeReportSectionSetting(),
        campaignTableColumns: (
            !state.campaignTableColumns
            || typeof state.campaignTableColumns[0] !== 'object'
            || !state.campaignTableColumns[0]
          ) ? campaignColumnSetting[0].setting
          : state.campaignTableColumns,
        productTableColumns: (!state.productTableColumns
            || typeof state.productTableColumns[0] !== 'object'
          ) ? productColumnList.filter(col => col.default)
          : state.productTableColumns,
        portfolioTableColumns: (
            !state.portfolioTableColumns
            || typeof state.portfolioTableColumns[0] !== 'object'
            || !state.portfolioTableColumns[0]
          )
          ? portfolioColumnList.filter(col => col.default)
          : state.portfolioTableColumns,
        tagTableColumns: (
            !state.tagTableColumns
            || typeof state.tagTableColumns[0] !== 'object'
            || !state.tagTableColumns[0]
          )
          ? tagColumnList.filter(col => col.default)
          : state.tagTableColumns,
        budgetTableColumns: (
            !state.budgetTableColumns
            || typeof state.budgetTableColumns[0] !== 'object'
            || !state.budgetTableColumns[0]
          )
          ? budgetRecommendationColumnList.filter(col => col.default)
          : state.budgetTableColumns,
        productCampaignTableColumns: (
            !state.productCampaignTableColumns
            || typeof state.productCampaignTableColumns[0] !== 'object'
            || !state.productCampaignTableColumns[0]
            || state.productCampaignTableColumns[0].key !== 'campaign'
          )
          ? productCampaignColumnList.filter(col => col.default)
          : state.productCampaignTableColumns,
        showCampaignNotePane: false,
      }
    case COLUMN_EDITOR_SHOW:
      return {
        ...state,
        showColumnEditor: true,
        visibleColumnEditorId: action.id,
      }
    case COLUMN_EDITOR_HIDE:
      return {
        ...state,
        showColumnEditor: false,
        visibleColumnEditorId: '',
      }
    case SELECT_COLUMNS:
      let key = 'campaignTableColumns'
      if (action.columnEditorId === 'productCampaign') {
        key = 'productCampaignTableColumns'
      } else if (action.columnEditorId === 'portfolioTable') {
        key = 'portfolioTableColumns'
      } else if (action.columnEditorId === 'tagTable') {
        key = 'tagTableColumns'
      } else if (action.columnEditorId === 'budgetRecommendation') {
        key = 'budgetTableColumns'
      }
      const newState = {
        ...state,
        showColumnEditor: false,
      }
      if (
        action.columnEditorId === 'bulkStOpResult'
        || action.columnEditorId === 'stExResult'
        || action.columnEditorId === 'targetSearchSTResult'
      ) {
        const stCols = []
        if (action.data.find(c => c.key === 'st_impr_rank')) {
          stCols.push('st_impr_rank')
        }
        if (action.data.find(c => c.key === 'st_impr_share')) {
          stCols.push('st_impr_share')
        }
        newState.stTableColumns = stCols
        newState[key] = action.data.filter(c => c.key !== 'st_impr_rank' || c.key !== 'st_impr_share')
      } else {
        newState[key] = action.data
      }
      return newState
    case APPLY_PRODUCT_COLUMN_CHANGES:
      return {
        ...state,
        showColumnEditor: false,
        productTableColumns: action.data
      }
    case ANP_SHOW:
      return {
        ...state,
        showANP: true,
      }
    case ANP_HIDE:
      return {
        ...state,
        showANP: false,
      }
    case AEP_SHOW:
      return {
        ...state,
        showAEP: true,
      }
    case AEP_HIDE:
      return {
        ...state,
        showAEP: false,
      }
    case COIN_PANE_TOGGLE:
      return {
        ...state,
        showCoinPane: !state.showCoinPane,
      }
    case SHOW_FILTER:
      return {
        ...state,
        visibleFilterName: action.data,
      }
    case HIDE_FILTER:
      return {
        ...state,
        visibleFilterName: state.visibleFilterName === action.data
          ? ''
          : state.visibleFilterName,
      }
    case APPLY_FILTER:
      return {
        ...state,
        visibleFilterName: state.visibleFilterName === action.data.filterName
          ? ''
          : state.visibleFilterName,
        filterValues: Object.assign({}, state.filterValues, {
          [action.data.filterName]: action.data.values,
        })
      }
    case UPDATE_STAR_REPORT_WIDGET:
      return {
        ...state,
        reportWidgetSetting: {
          ...state.reportWidgetSetting,
          [action.data.section]: state.reportWidgetSetting[action.data.section].map((w) =>
            w.key === action.data.widget ? {
              ...w,
              highlighted: !(!!w.highlighted),
            } : w
          )
        }
      }
    case UPDATE_VIEW_REPORT_WIDGET:
      return {
        ...state,
        reportWidgetSetting: {
          ...state.reportWidgetSetting,
          [action.data.section]: state.reportWidgetSetting[action.data.section].map((w) =>
            w.key === action.data.widget ? {
              ...w,
              default: !(!!w.default),
            } : w
          )
        }
      }
    case SHOW_OR_HIDE_REPORT_SECTION:
      const section = state.reportSectionSetting[action.data.section]

      return {
        ...state,
        reportSectionSetting: {
          ...state.reportSectionSetting,
          [action.data.section]: {
            ...section,
            show: section ? !section.show : false,
          }
        }
      }
    case SHOW_OR_HIDE_REPORT_NOTE:
      const nSection = state.reportSectionSetting[action.data.section]

      return {
        ...state,
        reportSectionSetting: {
          ...state.reportSectionSetting,
          [action.data.section]: {
            ...nSection,
            note: !nSection.note
          }
        }
      }
    case SHOW_OR_HIDE_REPORT_NAME:
      return {
        ...state,
        showReportNameWidget: !state.showReportNameWidget
      }
    case SHOW_OR_HIDE_REPORT_PORTFOLIO_TABLE:
      return {
        ...state,
        showReportPortfolioTable: !state.showReportPortfolioTable
      }
    case SHOW_OR_HIDE_REPORT_TAG_TABLE:
      return {
        ...state,
        showReportTagTable: !state.showReportTagTable
      }
    case SHOW_OR_HIDE_REPORT_PRODUCT_TABLE:
      return {
        ...state,
        showReportProductTable: !state.showReportProductTable
      }
    case ADD_NEW_CUSTOM_METRIC_ON_ACCOUNT_HEALTH:
      return {
        ...state,
        customMetricWidgets: [...state.customMetricWidgets, action.data]
      }
    case REMOVE_CUSTOM_METRIC_ON_ACCOUNT_HEALTH:
      return {
        ...state,
        customMetricWidgets: state.customMetricWidgets.filter(m => (m.section !== action.data.section)
          || (m.xMetric !== action.data.xMetric)
          || (m.yMetric !== action.data.yMetric)
        )
      }
    case UPDATE_REPORT_WIDGET_DATA:
      return {
        ...state,
        reportWidgetSetting: {
          ...state.reportWidgetSetting,
          [action.section]: state.reportWidgetSetting[action.section].map((w) =>
              w.key === action.widget ? {...w, data: action.data} : w
            )
        }
      }
    case LOAD_REPORT_TEMPLATE:
      return {
        ...state,
        reportWidgetSetting: action.data.reportWidgetSetting,
        reportSectionSetting: action.data.reportSectionSetting,
      }
    case CAMPAIGN_NOTE_PANE_SHOW:
      return {
        ...state,
        showCampaignNotePane: true,
      }
    case CAMPAIGN_NOTE_PANE_HIDE:
      return {
        ...state,
        showCampaignNotePane: false,
      }
    default:
      return state
  }
}

export default pageGlobal
