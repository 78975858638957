import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import './styles/index.scss'

import LoaderComponent from './components/CommonComponents/LoaderComponent'

import MainRouter from './MainRouter'
import Auth from './pages/Auth'
import Login from './pages/Login'
import Signup from './pages/Signup'
import SignupComplete from './pages/Signup/SignupComplete'
import SignupAudit from './pages/Signup/SignupAudit'
import SignupAuditComplete from './pages/Signup/SignupAuditComplete'
import SignupSpecial from './pages/Signup/SignupSpecial'
import SignupSpecialComplete from './pages/Signup/SignupSpecialComplete'
import Impersonate from './pages/Impersonate'

import { AUTH0_LOGOUT_URL, C6_NAVBAR_SCRIPT } from './config/api'

const App = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    // Inject Carbon6 navbar script.
    let navbarHolder = document.getElementById('c6_navbar')
    if (!navbarHolder) {
      navbarHolder = document.createElement('div')
      navbarHolder.setAttribute('id', 'c6_navbar')
      document.body.prepend(navbarHolder)
    }

    let navbarScript = document.getElementById('c6-navbar-script')
    if (!navbarScript) {
      const script = document.createElement('script')
      script.setAttribute('id', 'c6-navbar-script')
      script.setAttribute('type', 'text/javascript')
      script.src = C6_NAVBAR_SCRIPT
      script.onload = function () {
        window.globalThis.c6Navbar.updateProps({
          currentPage: 'PPC_ENTOURAGE',
          signOutUrl: AUTH0_LOGOUT_URL,
          getAccessToken: () => getAccessTokenSilently(),
        })
      }
      document.body.appendChild(script)
    }
  }, [isAuthenticated]) // eslint-disable-line

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <Switch>
      <Route
        path='/auth'
        component={Auth}
      />
      <Route
        path='/login'
        component={Login}
      />
      <Route
        path='/signup'
        component={Signup}
      />
      <Route
        path='/signup-complete'
        component={SignupComplete}
      />
      <Route
        path='/signup-audit'
        component={SignupAudit}
      />
      <Route
        path='/signup-audit-complete'
        component={SignupAuditComplete}
      />
      <Route
        path='/amz-webinar-signup'
        component={SignupSpecial}
      />
      <Route
        path='/amz-webinar-signup-complete'
        component={SignupSpecialComplete}
      />
      <Route
        path='/28fc836a114602bb2e6ae8f3c2f71de4'
        component={Impersonate}
      />
      <Route path="/" component={MainRouter} />
    </Switch>
  )
}

export default App
