// Reducer for smart pilot manager.
import { RULE_MANAGER_SHOW } from '../actionTypes/ruleManager'
import {
  GET_AP,
  GET_AP_SUCCESS,
  GET_AP_FAIL,
  GET_AP_AD_GROUP,
  GET_AP_AD_GROUP_SUCCESS,
  GET_AP_AD_GROUP_FAIL,
  SAVE_AP_TEMPLATE_SUCCESS,
  GET_AP_TEMPLATES,
  GET_AP_TEMPLATES_SUCCESS,
  GET_AP_TEMPLATES_FAIL,
  TURN_AP_BULK,
  TURN_AP_BULK_SUCCESS,
  TURN_AP_BULK_FAIL,
  UPDATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from '../actionTypes/ap'

import { CHECK_AUTH_SUCCEED } from '../actionTypes/auth'

import {
  getDefaultAPSettings,
  parseAPSettings,
  categorizeAdgroups,
} from '../../services/helper'
import {
  RULE_TYPE_SP,
} from '../../utils/defaultValues'

export const initialState = {
  campaignId: null,
  adgroupId: null,
  campaign: null,
  templates: [],
  isLoading: false,
  isLoadingTemplates: false,
  // Once loaded, no need to re-load templates.
  isTemplatesLoaded: false,
  // Is in progress of turning on/off in bulk?
  isTurningBulk: false,
}

const ap = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_AUTH_SUCCEED:
      return {
        ...initialState,
      }
    // FIXME: Combine RULE_MANAGER_SHOW and GET_AP actions into one.
    case RULE_MANAGER_SHOW:
      if (action.ruleType === RULE_TYPE_SP) {
        return {
          ...initialState,
          campaignId: action.campaignId,
          adgroupId: action.adgroupId,
        }
      }
      return state
    case GET_AP:
      return {
        ...state,
        isLoading: true,
        campaign: null,
      }
    case GET_AP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaign: {
          ...action.data,
          ap: action.data.ap.length
            ? parseAPSettings(action.data.ap[0])
            : null,
          template: action.data.template ? parseAPSettings(action.data.template) : null,
          ...(categorizeAdgroups(action.data.adgroups, action.data.targetings)),
        },
      }
    case GET_AP_FAIL:
      return {
        ...state,
        isLoading: false,
        campaign: null,
      }
    case GET_AP_AD_GROUP:
      return {
        ...state,
        isLoading: true,
      }
    case GET_AP_AD_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaign: {
          ...state.campaign,
          ap: action.data.settings
            ? parseAPSettings(action.data.settings)
            : getDefaultAPSettings(action.adgroupId),
          template: action.data.template ? parseAPSettings(action.data.template) : null,
        },
      }
    case GET_AP_AD_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case SAVE_AP_TEMPLATE_SUCCESS:
      const templates = [...state.templates]
      templates.push(action.data)
      templates.sort((a, b) => a.name.localeCompare(b.name))

      const newState = {
        ...state,
        templates,
      }

      if (action.data.apData) {
        newState.campaign = {
          ...action.data.apData,
          ap: parseAPSettings(action.data.apData.ap[0]),
          template: parseAPSettings(action.data.apData.template),
          ...(categorizeAdgroups(action.data.apData.adgroups, action.data.apData.targetings)),
        }
      }

      return newState
    case GET_AP_TEMPLATES:
      return {
        ...state,
        isLoadingTemplates: true,
        templates: [],
      }
    case GET_AP_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingTemplates: false,
        isTemplatesLoaded: true,
        templates: action.data,
      }
    case GET_AP_TEMPLATES_FAIL:
      return {
        ...state,
        isLoadingTemplates: false,
      }
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.map((template) => {
          if (template.id === action.data.id) {
            return {
              ...template,
              name: action.data.name,
            }
          }
          return template
        })
      }
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates.filter(template => (
          template.id !== action.data
        ))
      }
    case TURN_AP_BULK:
      return {
        ...state,
        isTurningBulk: true,
      }
    case TURN_AP_BULK_SUCCESS:
      return {
        ...state,
        isTurningBulk: false,
      }
    case TURN_AP_BULK_FAIL:
      return {
        ...state,
        isTurningBulk: false,
      }
    default:
      return state
  }
}

export default ap
