import React from 'react'

import Frequency from './Frequency'

const ExKeyword = ({ details }) => {
  if (!details.ex_impressions_active
    && !details.ex_clicks_active
    && !details.ex_cost_active
    && !details.ex_revenue_active) {
    return null
  }

  return (
    <div className="setting-subsection">
      <div className="subsection-name">
        Target Bid Expansion
      </div>
      {
        details.ex_impressions_active && (
          <div className="setting-wrapper">
            If target has impressions less than:
            <span className="setting-value">
              { details.ex_impressions_impressions }
            </span>
          </div>
        )
      }
      {
        details.ex_clicks_active && (
          <div className="setting-wrapper">
            If target has clicks less than:
            <span className="setting-value">
              { details.ex_clicks }
            </span>
          </div>
        )
      }
      {
        details.ex_cost_active && (
          <div className="setting-wrapper">
            If target has ad spend less than:
            <span className="setting-value">
              { details.ex_cost }
            </span>
          </div>
        )
      }
      {
        details.ex_revenue_active && (
          <div className="setting-wrapper">
            If target has sales less than:
            <span className="setting-value">
              { details.ex_revenue }
            </span>
          </div>
        )
      }
      <div className="setting-wrapper">
        Increase bid by:
        <span className="setting-value">
          { details.ex_impressions_amount }
          { details.ex_impressions_unit }
        </span>
      </div>
      <div className="setting-wrapper">
        Max bid value:
        <span className="setting-value">
          { details.ex_impressions_max_bid_price }
        </span>
      </div>
      <div className="setting-wrapper">
        Frequency:
        <span className="setting-value">
          <Frequency details={details} prefix="ex_" />
        </span>
      </div>
      <div className="setting-wrapper">
        Lookback Period (days):
        <span className="setting-value">
          { details.ex_lookback }
        </span>
      </div>
    </div>
  )
}

export default ExKeyword
