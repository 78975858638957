export const dspCampaignColumnList = [
  { key: 'total_cost', label: 'Total Cost', valueType: 'currency' },
  { key: 'agency_fee', label: 'Agency Fee', valueType: 'currency' },
  { key: 'supply_cost', label: 'Supply Cost', valueType: 'currency' },
  { key: 'impressions', label: 'Impressions', name: 'Imp' },
  { key: 'viewable_impressions', label: 'Viewable Impressions', name: 'Viewable Imp' },
  { key: 'measurable_impressions', label: 'Measurable Impressions', name: 'Measurable Imp' },
  { key: 'measurable_rate', label: 'Measurable Rate', name: 'Measurable %', required: ['measurable_impressions', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'viewability_rate', label: 'Viewability Rate', name: 'Viewability %', required: ['viewable_impressions', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'click_throughs', label: 'Click-throughs' },
  { key: 'amazon_platform_fee', label: 'Amazon DSP Console Fee', name: 'Console Fee', valueType: 'currency' },
  { key: 'amazon_audience_fee', label: 'Amazon DSP Audience Fee', name: 'Audience Fee', valueType: 'currency' },
  { key: 'tp_fees', label: 'Third Party Fee', name: '3P Fee', valueType: 'currency' },
  { key: 'e_cpm', label: 'eCPM', required: ['total_cost', 'impressions'], valueType: 'currency' },
  { key: 'ctr', label: 'CTR', required: ['click_throughs', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cpc', label: 'eCPC', required: ['click_throughs', 'impressions'], valueType: 'currency' },
  { key: 'off_amazon_product_sales_14d', label: 'Off-Amazon Product Sales', valueType: 'currency' },
  { key: 'off_amazon_units_sold_14d', label: 'Off-Amazon Units Sold' },
  { key: 'off_amazon_roas', label: 'Off-Amazon ROAS', required: ['off_amazon_product_sales_14d', 'total_cost'], valueType: 'decimal' },
  { key: 'off_amazon_erpm', label: 'Off-Amazon ERPM', requried: ['off_amazon_product_sales_14d', 'impressions'], valueType: 'currency' },
  { key: 'combined_purchases', label: 'Combined Purchases', required: ['off_amazon_purchases_14d', 'total_purchases_14d'] },
  { key: 'combined_purchases_views', label: 'Combined Purchases Views', required: ['off_amazon_purchases_views_14d', 'total_purchases_views_14d'] },
  { key: 'combined_purchases_clicks', label: 'Combined Purchases Clicks', required: ['off_amazon_purchases_clicks_14d', 'total_purchases_clicks_14d'] },
  { key: 'combined_purchase_rate', label: 'Combined Purchases %', required: ['off_amazon_purchases_14d', 'total_purchases_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'combined_ecpp', label: 'Combined eCPP', required: ['off_amazon_purchases_14d', 'total_purchases_14d', 'total_cost'], valueType: 'currency' },
  { key: 'combined_product_sales', label: 'Combined Product Sales', valueType: 'currency' },
  { key: 'combined_units_sold', label: 'Combined Units Sold' },
  { key: 'combined_roas', label: 'Combined ROAS', required: ['combined_product_sales', 'total_cost'], valueType: 'decimal' },
  { key: 'combined_erpm', label: 'Combined eRPM', required: ['combined_product_sales', 'impressions'], valueType: 'currency' },
  { key: 'dpv_14d', label: 'DPV' },
  { key: 'dpv_views_14d', label: 'DPV Views' },
  { key: 'dpv_clicks_14d', label: 'DPV Clicks' },
  { key: 'dpvr', label: 'DPVR', required: ['dpv_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cpdpv', label: 'eCPDPV', required: ['dpv_14d', 'total_cost'], valueType: 'currency' },
  { key: 'p_rpv_14d', label: 'PRPV' },
  { key: 'p_rpv_views_14d', label: 'PRPV Views' },
  { key: 'p_rpv_clicks_14d', label: 'PRPV Clicks' },
  { key: 'p_rpvr', label: 'PRPV %', required: ['p_rpv_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cpprpv', label: 'eCPPRPV', required: ['p_rpv_14d', 'total_cost'], valueType: 'currency' },
  { key: 'atl_14d', label: 'ATL' },
  { key: 'atl_views_14d', label: 'ATL Views' },
  { key: 'atl_clicks_14d', label: 'ATL Clicks' },
  { key: 'atlr', label: 'ATLR', required: ['atl_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cp_atl', label: 'eCPATL', required: ['atl_14d', 'total_cost'], valueType: 'currency' },
  { key: 'atc_14d', label: 'ATC' },
  { key: 'atc_views_14d', label: 'ATC Views' },
  { key: 'atc_clicks_14d', label: 'ATC Clicks' },
  { key: 'atcr', label: 'ATCR', required: ['atc_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cp_atc', label: 'eCPATC', required: ['atc_14d', 'total_cost'], valueType: 'currency' },
  { key: 'purchases_14d', label: 'Purchases' },
  { key: 'purchases_views_14d', label: 'Purchases Views' },
  { key: 'purchases_clicks_14d', label: 'Purchases Clicks' },
  { key: 'purchase_rate', label: 'Purchase %', required: ['purchases_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'e_cpp', label: 'eCPP', required: ['purchases_14d', 'total_cost'], valueType: 'currency' },
  { key: 'new_to_brand_purchases_14d', label: 'NTB Purchases' },
  { key: 'new_to_brand_purchases_views_14d', label: 'NTB Purchases Views' },
  { key: 'new_to_brand_purchases_clicks_14d', label: 'NTB Purchases Clicks' },
  { key: 'ntb_purchase_rate', label: 'NTB Purchases %', required: ['new_to_brand_purchases_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'new_to_brand_ecpp', label: 'NTB eCPP', required: ['new_to_brand_purchases_14d', 'total_cost'], valueType: 'currency' },
  { key: 'units_sold_14d', label: 'Units Sold' },
  { key: 'sales_14d', label: 'Product Sales', valueType: 'currency' },
  { key: 'roas', label: 'ROAS', required: ['sales_14d', 'total_cost'], valueType: 'decimal' },
  { key: 'e_rpm', label: 'eRPM', required: ['sales_14d', 'impressions'], valueType: 'currency' },
  { key: 'new_to_brand_units_sold_14d', label: 'NTB Units Sold' },
  { key: 'new_to_brand_product_sales_14d', label: 'NTB Product Sales', valueType: 'currency' },
  { key: 'ntb_roas', label: 'NTB ROAS', required: ['new_to_brand_product_sales_14d', 'total_cost'], valueType: 'decimal' },
  { key: 'ntb_erpm', label: 'NTB eRPM', required: ['new_to_brand_product_sales_14d', 'impressions'], valueType: 'currency' },
  { key: 'purchase_click_rate', label: 'Purchase Click %', required: ['purchases_clicks_14d', 'impressions'] },
  { key: 'dpv_click_rate', label: 'DPV Click %', required: ['dpv_clicks_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'ecp_dpv_clicks', label: 'eCPDPV Clicks', required: ['dpv_clicks_14d', 'total_cost'], valueType: 'currency' },
  { key: 'ecpp_clicks', label: 'eCPP Clicks', required: ['purchases_clicks_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_purchase_click_rate', label: 'Total Purchase Click %', required: ['total_purchases_clicks_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'total_dpv_click_rate', label: 'Total DPV Click %', required: ['total_detail_page_clicks_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'total_ecpdpv_clicks', label: 'Total eCPDPV Clicks', required: ['total_detail_page_clicks_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_ecpp_clicks', label: 'Total eCPP Clicks', required: ['total_purchases_clicks_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_units_sold_14d', label: 'Total Units Sold' },
  { key: 'total_sales_14d', label: 'Total Product Sales', valueType: 'currency' },
  { key: 'total_roas', label: 'Total ROAS', required: ['total_sales_14d', 'total_cost'], valueType: 'decimal' },
  { key: 'total_erpm', label: 'Total eRPM', required: ['total_sales_14d', 'impressions'], valueType: 'currency' },
  { key: 'total_detail_page_views_14d', label: 'Total DPV' },
  { key: 'total_detail_page_view_views_14d', label: 'Total DPV Views' },
  { key: 'total_detail_page_clicks_14d', label: 'Total DPV Clicks' },
  { key: 'total_dpvr', label: 'Total DPVR', required: ['total_detail_page_views_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'total_ecpdpv', label: 'Total eCPDPV', required: ['total_detail_page_views_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_prpv_14d', label: 'Total PRPV' },
  { key: 'total_prpv_views_14d', label: 'Total PRPV Views' },
  { key: 'total_prpv_clicks_14d', label: 'Total PRPV Clicks' },
  { key: 'total_prpvr', label: 'Total PRPV %', required: ['total_prpv_14d', 'impressions'], valueType: 'percent', decimal: 3 },
  { key: 'total_ecpprpv', label: 'Total eCPPRPV', required: ['total_prpv_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_add_to_list_14d', label: 'Total ATL' },
  { key: 'total_add_to_list_views_14d', label: 'Total ATL Views' },
  { key: 'total_add_to_list_clicks_14d', label: 'Total ATL Clicks' },
  { key: 'total_atlr', label: 'Total ATLR', required: ['total_add_to_list_14d', 'impressions'], valueType: 'percent', decimal: 3  },
  { key: 'total_ecpatl', label: 'Total eCPATL', required: ['total_add_to_list_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_add_to_cart_14d', label: 'Total ATC' },
  { key: 'total_add_to_cart_views_14d', label: 'Total ATC Views' },
  { key: 'total_add_to_cart_clicks_14d', label: 'Total ATC Clicks' },
  { key: 'total_atcr', label: 'Total ATCR', required: ['total_add_to_cart_14d', 'impressions'], valueType: 'percent', decimal: 3  },
  { key: 'total_ecpatc', label: 'Total eCPATC', required: ['total_add_to_cart_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_purchases_14d', label: 'Total Purchases' },
  { key: 'total_purchases_views_14d', label: 'Total Purchases Views' },
  { key: 'total_purchases_clicks_14d', label: 'Total Purchases Clicks' },
  { key: 'total_purchase_rate', label: 'Total Purchase %', required: ['total_purchases_14d', 'impressions'], valueType: 'percent', decimal: 3  },
  { key: 'total_ecpp', label: 'Total eCPP', required: ['total_purchases_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_new_to_brand_purchases_14d', label: 'Total NTB Purchases' },
  { key: 'total_new_to_brand_purchases_views_14d', label: 'Total NTB Purchases Views' },
  { key: 'total_new_to_brand_purchases_clicks_14d', label: 'Total NTB Purchases Clicks' },
  { key: 'total_ntb_purchase_rate', label: 'Total NTB Purchases %', required: ['total_new_to_brand_purchases_14d', 'impressions'], valueType: 'percent', decimal: 3  },
  { key: 'total_ntb_ecpp', label: 'Total NTB eCPP', required: ['total_new_to_brand_purchases_14d', 'total_cost'], valueType: 'currency' },
  { key: 'total_new_to_brand_units_sold_14d', label: 'Total NTB Units Sold' },
  { key: 'total_new_to_brand_product_sales_14d', label: 'Total NTB Product Sales', valueType: 'currency' },
  { key: 'total_ntb_roas', label: 'Total NTB ROAS', required: ['total_new_to_brand_product_sales_14d', 'total_cost'], valueType: 'decimal' },
  { key: 'total_ntb_erpm', label: 'Total NTB eRPM', required: ['total_new_to_brand_product_sales_14d', 'impressions'], valueType: 'currency' },
]

export const dspCampaignColumnSetting = [
  {
    value: 'traffic',
    label: 'Traffic',
    setting: [
      'impressions', 'viewable_impressions', 'measurable_impressions', 'measurable_rate',
      'viewability_rate', 'click_throughs', 'e_cpm', 'ctr', 'e_rpm',
    ],
  }, {
    value: 'costs',
    label: 'Costs',
    setting: [
      'total_cost', 'agency_fee', 'supply_cost', 'amazon_platform_fee', 'amazon_audience_fee',
      'tp_fees', 'e_cpm', 'e_cpc', 'e_cpdpv', 'e_cpprpv', 'e_cp_atl', 'e_cp_atc', 'e_cpp',
      'new_to_brand_ecpp', 'e_rpm', 'total_erpm', 'total_ecpdpv', 'total_ecpprpv',
      'total_ecpatl', 'total_ecpatc', 'total_ecpp', 'total_ntb_ecpp', 'total_ntb_erpm',
    ],
  }, {
    value: 'rates',
    label: 'Rates',
    setting: [
      'measurable_rate', 'viewability_rate', 'ctr', 'dpvr', 'p_rpvr', 'atlr', 'atcr', 'purchase_rate',
      'ntb_purchase_rate', 'total_dpvr', 'total_prpvr', 'total_atlr', 'total_atcr', 'total_purchase_rate',
      'total_ntb_purchase_rate',
    ],
  }, {
    value: 'offAmazonConversions',
    label: 'Off-Amazon Conversions',
    setting: [
      'off_amazon_product_sales_14d', 'off_amazon_units_sold_14d', 'off_amazon_roas', 'off_amazon_erpm',
    ],
  }, {
    value: 'combinedConversions',
    label: 'Combined Conversions',
    setting: [
      'combined_purchases', 'combined_purchases_views', 'combined_purchases_clicks',
      'combined_purchase_rate', 'combined_ecpp', 'combined_product_sales', 'combined_roas',
      'combined_erpm',
    ],
  }, {
    value: 'eCommerceConversions',
    label: 'E-Commerce Conversions',
    setting: [
      'dpv_14d', 'dpv_views_14d', 'dpv_clicks_14d', 'dpvr', 'e_cpdpv', 'p_rpv_14d', 'p_rpv_views_14d',
      'p_rpv_clicks_14d', 'p_rpvr', 'e_cpprpv', 'atl_14d', 'atl_views_14d', 'atl_clicks_14d', 'atlr',
      'e_cp_atl', 'atc_14d', 'atc_views_14d', 'atc_clicks_14d', 'atcr', 'e_cp_atc', 'purchases_14d', 'purchases_views_14d', 'purchases_clicks_14d', 'purchase_rate', 'e_cpp',
      'new_to_brand_purchases_14d', 'new_to_brand_purchases_views_14d', 'new_to_brand_purchases_clicks_14d',
      'ntb_purchase_rate', 'new_to_brand_ecpp',
      'units_sold_14d', 'sales_14d', 'roas', 'e_rpm', 'new_to_brand_units_sold_14d',
      'new_to_brand_product_sales_14d', 'ntb_roas', 'ntb_erpm', 'purchase_click_rate', 'dpv_click_rate',
      'ecp_dpv_clicks', 'ecpp_clicks', 'total_purchase_click_rate', 'total_dpv_click_rate',
      'total_ecpp_clicks', 'total_ecpdpv_clicks', 'total_units_sold_14d', 'total_sales_14d',
      'total_roas', 'total_erpm', 'total_detail_page_views_14d', 'total_detail_page_view_views_14d',
      'total_detail_page_clicks_14d', 'total_dpvr', 'total_ecpdpv', 'total_prpv_14d',
      'total_prpv_views_14d', 'total_prpv_clicks_14d', 'total_prpvr', 'total_ecpprpv',
      'total_add_to_list_14d', 'total_add_to_list_views_14d', 'total_add_to_list_clicks_14d',
      'total_atlr', 'total_ecpatl', 'total_add_to_cart_14d', 'total_add_to_cart_views_14d',
      'total_add_to_cart_clicks_14d', 'total_atcr', 'total_ecpatc', 'total_purchases_14d',
      'total_purchases_views_14d', 'total_purchases_clicks_14d', 'total_purchase_rate',
      'total_ecpp', 'total_new_to_brand_purchases_14d', 'total_new_to_brand_purchases_views_14d',
      'total_new_to_brand_purchases_clicks_14d', 'total_ntb_purchase_rate', 'total_ntb_ecpp',
      'total_new_to_brand_units_sold_14d',
      'total_new_to_brand_product_sales_14d', 'total_ntb_roas', 'total_ntb_erpm'
    ]
  }
]
