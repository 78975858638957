/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Icon from 'react-icons/fi'

import CardModal from './CardModal'

const brandList = {
  visa: 'Visa',
  mastercard: 'MasterCard',
  american_express: 'American Express',
  discover: 'Discover',
  jcb: 'JCB',
  diners_club: 'Diner\'s Club',
}

const statusList = {
  valid: 'Valid',
  expiring: 'Expiring',
  expired: 'Expired',
  invalid: 'Invalid',
  pending_verification: 'Pending Verification',
}

const BillingCard = ({ isLoading, cardList, onCardChange }) => {
  const [showCardModal, setShowCardModal] = useState(false)

  const handleCardModalClose = (card) => {
    setShowCardModal(false)
    if (card) {
      onCardChange([card])
    }
  }

  return (
    <div>
      {
        !isLoading && cardList.length === 0 && (
          <>
            <p>
              No card information found.
            </p>
            <button
              type="button"
              className="btn btn-green"
              onClick={() => { setShowCardModal(true) }}
            >
              Add Payment Method
            </button>
          </>
        )
      }
      {
        cardList.map(card => (
          <div key={card.id} className="card-wrapper">
            <div className="card-header">
              <div className="card-brand">
                { brandList[card.card_brand] || 'Other' }
                <span className="card-status">
                  ({ statusList[card.status] })
                </span>
              </div>
              <a
                href="#"
                className="card-edit"
                title="Update Card"
                onClick={(event) => { event.preventDefault(); setShowCardModal(true) }}
              >
                <Icon.FiEdit3 />
              </a>
            </div>
            <div className="card-number">
              **** **** **** { card.card_last4 }
            </div>
            <div className="card-info">
              <div className="card-info-field">
                <div>Cardholder name</div>
                <div>{ card.card_first_name } { card.card_last_name }</div>
              </div>
              <div className="card-info-field">
                <div>Expires</div>
                <div>{ card.card_expiry_month }/{ card.card_expiry_year }</div>
              </div>
            </div>
          </div>
        ))
      }
      <CardModal
        show={showCardModal}
        onHide={handleCardModalClose}
      />
    </div>
  )
}

export default BillingCard
