import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import CustomTable from '../CustomTableComponent'

import { revertLog } from '../../../redux/actions/campaignLog'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'

import {
  getLogDescription,
  filterLogs,
  parseDate,
} from '../../../services/helper'

import {
  logTypeOptions,
  REVERTABLE_LOG_TYPES,
} from '../../../utils/defaultValues'

const CATEGORY_AP = 'ap'

const categoryList = [
  { value: '', label: 'All' },
  { value: CATEGORY_AP, label: 'Smart Pilot/Rules' },
]

const ActivityLogTable = ({ isLoading = false, currentLogs }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const isRevertingLog = useSelector(state => state.campaignLog.isRevertingLog)

  const [currentCategory, setCurrentCategory] = useState(categoryList[0])
  const [currentType, setCurrentType] = useState(logTypeOptions[0])

  const typesToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return logTypeOptions
    }
    return logTypeOptions.filter(type => (
      ![
        'ap_kw_adv',
        'ap_nta',
        'ap_npt',
        'ap_st_ex',
        'ap_pt_ex',
        'ap_placement',
      ].includes(type.value)
    ))
  }, [isNonEndemicAccount])

  const handleRevert = log => async () => {
    const accessToken = await getAccessTokenSilently()
    dispatch(revertLog(accessToken, log.id, log.campaign_id))
  }

  const renderAction = () => {
    return (
      <>
        <Select
          className="category-selector"
          value={currentCategory}
          options={categoryList}
          onChange={setCurrentCategory}
        />
        <Select
          className="type-selector"
          options={typesToRender}
          value={currentType}
          onChange={setCurrentType}
        />
      </>
    )
  }

  const renderLogAction = (log) => {
    if (REVERTABLE_LOG_TYPES.indexOf(log.log_type) === -1) {
      return null
    }

    if (log.reverted) {
      return (
        <button
          type="button"
          className="btn btn-red"
          disabled
        >
          Reverted
        </button>
      )
    }

    return (
      <button
        type="button"
        className="btn btn-red"
        disabled={isRevertingLog}
        onClick={handleRevert(log)}
      >
        Revert
      </button>
    )
  }

  const renderLog = log => (
    <>
      <div className="table-col col-type">
        { log.type }
      </div>
      <div className="table-col col-created-at">
        { format(parseDate(log.created_at), 'M/d') }
      </div>
      <div
        className="table-col col-description"
        dangerouslySetInnerHTML={{
          __html: getLogDescription(
            log.log_type,
            log.description,
            log.created_at,
          ),
        }}
      />
      <div className="table-col col-contents">
        <div
          dangerouslySetInnerHTML={{
            __html: log.contents,
          }}
        />
      </div>
      <div className="table-col col-action">
        { renderLogAction(log) }
      </div>
    </>
  )

  let filteredLogs = useMemo(() => {
    const filtered = filterLogs(currentLogs || [], currentType)
    if (currentCategory.value === CATEGORY_AP) {
      return filtered.filter((log) => {
        const logType = log.log_type || ''
        return logType.indexOf('ap_') === 0
          || logType.indexOf('rule_') === 0
      })
    }
    return filtered
  }, [currentLogs, currentType, currentCategory])

  return (
    <div className="section">
      <div className="section-header">
        <h4>Logs</h4>
      </div>
      <CustomTable
        isLoading={isLoading || isRevertingLog}
        className="table-logs"
        records={filteredLogs}
        idField="id"
        searchFields={['contents']}
        noCheckBox
        renderRecord={renderLog}
        renderTopRight={renderAction}
      >
        <div className="table-col col-type">Log Type</div>
        <div className="table-col col-created-at">Date</div>
        <div className="table-col col-description">Description</div>
        <div className="table-col col-contents">Detail</div>
        <div className="table-col col-action"></div>
      </CustomTable>
    </div>
  )
}

export default ActivityLogTable