/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BsCaretDownFill } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import OutsideClickHandler from 'react-outside-click-handler'
import { Toggle } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { getCampaignList } from '../../redux/actions/campaignDetail'

import { showRuleManager, showSpManager } from '../../redux/actions/ruleManager'

import {
  RULE_TYPE_CP,
  RULE_TYPE_DP,
  RULE_TYPE_EP,
  RULE_TYPE_IR,
  RULE_TYPE_RR,
  RULE_TYPE_SP,
  RULE_TYPE_WP,
  campaignTypeMap,
  ruleStickerNames,
} from '../../utils/defaultValues'

const stickerTypes = [
  { field: 'hasDp', name: ruleStickerNames[RULE_TYPE_DP], type: RULE_TYPE_DP },
  { field: 'hasWp', name: ruleStickerNames[RULE_TYPE_WP], type: RULE_TYPE_WP },
  { field: 'hasCp', name: ruleStickerNames[RULE_TYPE_CP], type: RULE_TYPE_CP },
  { field: 'hasRr', name: ruleStickerNames[RULE_TYPE_RR], type: RULE_TYPE_RR },
  { field: 'hasEp', name: ruleStickerNames[RULE_TYPE_EP], type: RULE_TYPE_EP },
  { field: 'hasIr', name: ruleStickerNames[RULE_TYPE_IR], type: RULE_TYPE_IR },
]

const CampaignSelector = ({ campaignId, campaignState, onSelect, onStateChange }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentDetail = useSelector(state => state.campaignDetail.currentDetail)

  const [showSelector, setShowSelector] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [campaigns, setCampaigns] = useState([])

  useEffect(() => {
    if (!showSelector || !currentStartDate || !currentEndDate) {
      return
    }

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getCampaignList(accessToken))
      setCampaigns(response)
      setIsLoading(false)
    })()
  }, [showSelector, currentUserId]) // eslint-disable-line

  const handleToggle = (event) => {
    event.preventDefault()
    setShowSelector(!showSelector)
  }

  const handleSelect = campaign => (event) => {
    event.preventDefault()

    history.push(`/campaign/${campaign.id}/${campaign.type}`)

    onSelect()

    setShowSelector(false)
  }

  const handleSmartPilotManagerShow = () => {
    dispatch(showSpManager(campaignId))
  }

  const handleRuleManagerShow = (sticker) => {
    dispatch(showRuleManager(sticker.type, [{
      campaign_id: currentDetail.campaign_id,
      name: currentDetail.name,
      campaignType: currentDetail.campaignType,
    }]))
  }

  const handleStateChange = async (enabled) => {
    setIsChanging(true)
    await onStateChange(enabled)
    setIsChanging(false)
  }

  const renderCampaign = (campaign) => {
    let targetingType
    if (campaign.type === 'sp') {
      if (campaign.targeting_type === 'auto') {
        targetingType = 'Auto'
      } else {
        targetingType = 'Manual'
      }
    }

    return (
      <>
        <div className="table-col">
          <a
            href="#"
            onClick={handleSelect(campaign)}
          >
            <span className="campaign-name">
              { campaign.name }
            </span>
            <span className="campaign-detail">
              {
                targetingType && <span>{ targetingType }</span>
              }
              <span>
                { campaignTypeMap[campaign.type] }
              </span>
            </span>
          </a>
        </div>
      </>
    )
  }

  const renderStickers = () => {
    if (!currentDetail) {
      return
    }
    return (
      <>
        <span className={`status ${currentDetail.is_ap_active ? 'on' : 'off'}`}>
          { ruleStickerNames[RULE_TYPE_SP] } {currentDetail.is_ap_active ? 'On' : 'Off'}
        </span>
        <FiExternalLink
          onClick={handleSmartPilotManagerShow}
        />
        {
          stickerTypes.map((sticker) => {
            if (!currentDetail[sticker.field]) {
              return null
            }

            return (
              <React.Fragment key={sticker.type}>
                <span className="status on">
                  { sticker.name }
                </span>
                {
                  sticker.type !== RULE_TYPE_RR && (
                    <FiExternalLink
                      onClick={() => { handleRuleManagerShow(sticker) }}
                    />
                  )
                }
              </React.Fragment>
            )
          })
        }
      </>
    )
  }

  const renderSelector = () => {
    if (!showSelector) {
      return null
    }

    const extendedCampaigns = campaigns.map(campaign => ({
      ...campaign,
      className: campaign.id === campaignId.toString() ? 'selected' : '',
    }))

    return (
      <div className="selector-contents">
        <CustomTable
          isLoading={isLoading}
          className="table-campaign-selector"
          records={extendedCampaigns}
          idField="id"
          searchFields={['name']}
          noCheckBox
          renderRecord={renderCampaign}
        >
          <div className="table-col">Campaign</div>
        </CustomTable>
      </div>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={() => { setShowSelector(false) }}>
      <div className="campaign-selector-container">
        <div className="campaign-name-wrapper">
          <a
            href="#"
            className="toggle-button"
            onClick={handleToggle}
          >
            { currentDetail?.name }
            <BsCaretDownFill />
          </a>
          { renderStickers() }
          <Toggle
            size="lg"
            checked={campaignState.toLowerCase() === 'enabled'}
            checkedChildren="Active"
            unCheckedChildren="Paused"
            disabled={isChanging}
            onChange={handleStateChange}
          />
        </div>
        { renderSelector() }
      </div>
    </OutsideClickHandler>
  )
}

export default CampaignSelector
