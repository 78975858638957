import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import withReportSection from './withReportSection'
import TagTableComponent from './TagTableComponent'
import SectionHeader from './SectionHeader'
import ReportNoteWidget from './ReportNoteWidget'

import { changeViewReportTagTableState } from '../../redux/actions/pageGlobal'
import { getISODate } from '../../services/helper'

const TagSection = ({
  settings,
  isLoadingSettings,
  onSaveSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
}) => {
  const dispatch = useDispatch()

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)
  const showReportTagTable = useSelector(state => state.pageGlobal.showReportTagTable)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [note, setNote] = useState('')
  const [tableView, setTableView] = useState(showReportTagTable)

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_tag || '')
    }
  }, [settings, isLoadingSettings])

  const handleHideTable = () => {
    dispatch(changeViewReportTagTableState())
    setTableView(p => !p)
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: 'notes_tag',
      sectionValue: note,
    })
  }

  return (
    <>
      <SectionHeader
        title="Your Tags"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <>
            {(customizingSection || tableView) && (
              <div className="report-widget">
                <div className="report-widget-header">
                  <div className="widget-title">
                    Tags
                  </div>
                  <div className="widget-action">
                    {customizingSection && (
                      <IconButton
                        icon={<Icon icon="check" />}
                        color={tableView ? 'green' : ''}
                        circle
                        onClick={handleHideTable}
                      />
                    )}
                  </div>
                </div>
                <div className="report-widget-content">
                  <TagTableComponent
                    startDate={getISODate(startDate)}
                    endDate={getISODate(endDate)}
                  />
                </div>
              </div>
            )}
            {(customizingSection || noteWidgetView) && (
              <ReportNoteWidget
                noteWidgetView={noteWidgetView}
                customizing={customizingSection}
                onRemove={setNoteWidgetView}
                note={note}
                onChange={setNote}
                section="tags"
              />
            )}
          </>
        )
      }
    </>
  )
}

export default withReportSection(TagSection, {
  sectionId: 'tags',
  reportSectionKey: 'tags',
})
