import React, { useState, useEffect, useMemo } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Progress } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { FiDownload } from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableCell from '../CommonComponents/TableCell'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import AdTypeMultiSelector from '../CommonComponents/AdTypeMultiSelector'
import PortfolioSelector from '../CommonComponents/PortfolioSelector'
import TagSelector from '../CommonComponents/TagSelector'
import UpdateDailyBudgetModal from '../MyCampaignTable/UpdateDailyBudgetModal'

import { getBudgetRecommendations } from '../../redux/actions/budgetRecommendation'
import { updateBudget } from '../../redux/actions/campaign'

import {
  formatCurrency,
  formatValue,
  tableSorter,
} from '../../services/helper'
import { budgetRecommendationColumnList } from '../../utils/defaultValues'

const biddingStrategyOptions = {
  legacyForSales : 'Dynamic bids - down only',
  autoForSales: 'Dynamic bids - up and down',
  manual: 'Fixed bids'
}

const formatRangeValue = (lower, upper, type, decimal, currencyRate = 0, currencySign = '$') => {
  if (upper > 0 || lower > 0) {
    if (type === 'currency') {
      return `${formatCurrency(lower, currencySign, currencyRate, decimal)} ~ `
        + formatCurrency(upper, currencySign, currencyRate, decimal)
    }
    return `${formatValue(lower, type, decimal)} ~ ${formatValue(upper, type, decimal)}`
  }
  return '--'
}

const columnsToExport = [
  'campaign', 'campaignType', 'targeting_Type', 'targeting_acos', 'state',
  'daily_budget', 'tactic', 'cost_type', 'budgetrule_status', 'revenue','cost',
  'impressions', 'clicks', 'ntb_sales', 'ntb_orders', 'start_date',
  'is_ap_active', 'ctr', 'acos', 'conversion', 'cpc', 'roas',
  'ntb_sales_percent', 'ntb_orders_percent', 'view_5_seconds_rate','vtr',
  'vctr', 'vcpm', 'tags', 'brand_entity_id', 'is_multi_adgroups_enabled', 'goal',
  'viewable_impressions', 'video_views_25', 'video_views_50', 'video_views_75',
  'video_views_100', 'video_5s_views', 'video_adgroup_ids', 'headline'
];

const BudgetRecommendationTable = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const budgetTableColumns = useSelector(state => state.pageGlobal.budgetTableColumns)

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingBudget, setIsUpdatingBudget] = useState(false)
  const [recommendationList, setRecommendationList] = useState([])
  const [selectedRecordIds, setSelectedRecordIds] = useState([])
  const [showUpdateDailyBudget, setShowUpdateDailyBudget] = useState(false)
  const [adTypeFilter, setAdTypeFilter] = useState([])
  const [portfolioFilter, setPortfolioFilter] = useState([])
  const [tagFilter, setTagFilter] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();

    loadData(abortCtrl.signal)

    return () => {
      abortCtrl.abort()
    }
  }, [currentStartDate, currentEndDate, currentUserId]) // eslint-disable-line

  const filteredCampaigns = useMemo(() => {
    let portfolioIds = []
    let tagIds = []
    if (portfolioFilter.length) {
      portfolioIds = portfolioFilter.map(p => p.value.toString())
    }
    if (tagFilter.length) {
      tagIds = tagFilter.map(t => t.value.toString())
    }

    return recommendationList.filter((record) => {
      if (!Object.keys(record.campaignInfo).length) {
        return true
      }

      if (adTypeFilter.length) {
        if (adTypeFilter.filter(o => o.type === 'status').length
          && !adTypeFilter.find(o => o.value === record.campaignInfo.state.toLowerCase())) {
          return false
        }

        if (adTypeFilter.filter(o => o.type === 'ad_type').length
          && !adTypeFilter.find(o => o.value === record.campaignInfo.campaignType)) {
          return false
        }
      }

      if (portfolioIds.length && !portfolioIds.includes((record.campaignInfo.portfolio_id || '').toString())) {
        return false
      }

      if (tagIds.length) {
        const tagged = record.campaignInfo.tags.find(t => tagIds.includes(t.toString()))
          // For `no tag` option.
          || (tagIds.includes('0') && record.campaignInfo.tags.length === 0)
        if (!tagged) {
          return false
        }
      }

      return true
    })
  }, [recommendationList, adTypeFilter, portfolioFilter, tagFilter])

  const campaignsToExport = useMemo(() => {
    return filteredCampaigns.map(c => {
      const extractedObj = {}
      columnsToExport.forEach(key => {
        if (c.hasOwnProperty(key)) {
          extractedObj[key] = c[key]
        }
      });
      return extractedObj
    })
  }, [filteredCampaigns])

  const loadData = async (signal) => {
    setIsLoading(true)
    try {
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getBudgetRecommendations(accessToken, signal))
      setRecommendationList(response.map(record => ({
        ...record.campaignInfo,
        ...record,
        campaignName: record.campaignInfo.campaign || '', // For table search
      })))
      setIsLoading(false)
    } catch (isCancelled) {
      if (!isCancelled) {
        setIsLoading(false)
      }
    }
  }

  const doUpdateBudget = async (campaignsChanged) => {
    setIsUpdatingBudget(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(updateBudget(accessToken, campaignsChanged))
    setIsUpdatingBudget(false)
    setRecommendationList(p => p.map(item => {
      const found = campaignsChanged.find(c =>
        c.campaignId.toString() === item.campaign_id.toString()
      )
      if (found) {
        return {
          ...item,
          daily_budget: found.dailyBudget,
        }
      }
      return item
    }))
  }

  const handleUpdateDailyBudget = async (updatedCampaigns) => {
    const selectedRecommendation = recommendationList.filter(
      item => selectedRecordIds.includes(item.id)
    )
    const campaignsChanged = updatedCampaigns.map((campaign) => {
      const found = selectedRecommendation.find(record =>
        record.campaign_id.toString() === campaign.campaign_id.toString()
      )
      return {
        campaignId: campaign.campaign_id,
        campaignType: campaign.campaignType,
        dailyBudget: campaign.daily_budget,
        originalBudget: found?.daily_budget || 0.00,
      }
    })

    setShowUpdateDailyBudget(false)
    doUpdateBudget(campaignsChanged)
  }

  const handleAdjustRecommendation = async () => {
    const selectedRecommendation = recommendationList.filter(
      item => selectedRecordIds.includes(item.id)
    )
    const campaignsChanged = selectedRecommendation.map(record => ({
      campaignId: record.campaign_id,
      campaignType: record.campaignType,
      dailyBudget: record.suggested_budget,
      originalBudget: record.daily_budget || 0.00,
    }))

    doUpdateBudget(campaignsChanged)
  }

  const checkAdjustRecommendation = () => {
    let disabled = false
    recommendationList.filter(
      item => selectedRecordIds.includes(item.id)
    ).every(item => {
      if (!item.suggested_budget
        || parseFloat(item.suggested_budget) === parseFloat(item.daily_budget)) {
        disabled = true
      }
      return !disabled // Stop loop when disabled is true
    })
    return disabled
  }

  const renderRecord = record => budgetTableColumns.map((column) => {
    if (column.key === 'campaign') {
      return <TableCampaignCell key={column.key} record={record} />
    }

    if (column.key === 'bidding_strategy') {
      if (record.campaignType !== 'sp') {
        return <div key={column.key} className="table-col col-bidding-strategy" />
      }

      const bidding = record.bidding ? (
        typeof record.bidding === 'object'
          ? record.bidding
          : (
            typeof JSON.parse(record.bidding) === 'object'
              ? JSON.parse(record.bidding)
              : JSON.parse(JSON.parse(record.bidding))
            )
        ) : {}

      let strategy = 'Dynamic bids - down only'
      if (bidding.strategy && biddingStrategyOptions[bidding.strategy]) {
        strategy = biddingStrategyOptions[bidding.strategy]
      }

      return  (
        <div key={column.key} className="table-col col-bidding-strategy">
          { strategy }
        </div>
      )
    }

    if (column.key === 'percent_time_in_budget') {
      return (
        <div key={column.key} className="table-col col-percent-time">
          <Progress.Line
            percent={parseFloat(record.percent_time_in_budget) || 0.00}
            status="active"
          />
        </div>
      )
    }

    if (column.key === 'missed_impressions') {
      return (
        <div key={column.key} className="table-col missed-opportunity-col">
          <span className={record.missed_impressions_upper > 0 ? 'impressions' : ''}>
            {
              formatRangeValue(
                record.missed_impressions_lower,
                record.missed_impressions_upper,
                'number',
                0
              )
            }
          </span>
        </div>
      )
    }

    if (column.key === 'missed_clicks') {
      return (
        <div key={column.key} className="table-col missed-opportunity-col">
          <span className={record.missed_clicks_upper > 0 ? 'clicks' : ''}>
            {
              formatRangeValue(
                record.missed_clicks_lower,
                record.missed_clicks_upper,
                'number',
                0
              )
            }
          </span>
        </div>
      )
    }

    if (column.key === 'missed_sales') {
      return (
        <div key={column.key} className="table-col missed-opportunity-col">
          <span className={record.missed_sales_upper > 0 ? 'sales' : ''}>
            {
              formatRangeValue(
                record.missed_sales_lower,
                record.missed_sales_upper,
                'currency',
                2,
                currencyRate,
                currencySign
              )
            }
          </span>
        </div>
      )
    }

    if (column.key === 'suggested_budget') {
      return (
        <div key={column.key} className="table-col">
          { formatCurrency(record.suggested_budget || 0.00, currencySign, currencyRate) }
        </div>
      )
    }

    return (
      <TableCell
        key={column.key}
        record={record}
        columnKey={column.key}
        columnSelection={budgetTableColumns}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
    )
  })

  const renderAction = () => (
    <>
      <AdTypeMultiSelector
        onChange={setAdTypeFilter}
      />
      <PortfolioSelector
        onChange={setPortfolioFilter}
      />
      <TagSelector
        onChange={setTagFilter}
      />
      <Dropdown title="Actions" disabled={!selectedRecordIds.length} placement="bottomEnd">
        <Dropdown.Item onSelect={() => { setShowUpdateDailyBudget(true) }}>
          Adjust Daily Budget
        </Dropdown.Item>
        <Dropdown.Item
          disabled={checkAdjustRecommendation()}
          onSelect={handleAdjustRecommendation}
        >
          Adjust Recommended Budget
          <CustomTooltip placement="bottomEnd">
            Recommendation budget is already applied or not available for
            some of the selected campaigns.
          </CustomTooltip>
        </Dropdown.Item>
      </Dropdown>
      <CSVLink
        data={campaignsToExport}
        filename="Budgets.csv"
        className="export-link"
        title="Export to CSV"
      >
        <FiDownload size={16} />
      </CSVLink>
      {
        showUpdateDailyBudget && (
          <UpdateDailyBudgetModal
            campaigns={recommendationList.filter(item => selectedRecordIds.includes(item.id))}
            onUpdate={handleUpdateDailyBudget}
            onClose={() => { setShowUpdateDailyBudget(false) }}
          />
        )
      }
    </>
  )

  return (
    <SortableTable
      isLoading={isLoading || isUpdatingBudget}
      columns={budgetTableColumns}
      defaultSort={['cost', 'desc']}
      sorter={tableSorter(['campaignName'])}
      className="table-budget-recommendation"
      records={filteredCampaigns}
      idField="id"
      searchFields={['campaignName']}
      selectedRecords={selectedRecordIds}
      hasSticky
      columnEditorId="budgetRecommendation"
      columnList={budgetRecommendationColumnList}
      columnSelection={budgetTableColumns}
      filterName="budgetRecommendationTable"
      renderRecord={renderRecord}
      renderTopRight={renderAction}
      onChange={setSelectedRecordIds}
    />
  )
}

export default BudgetRecommendationTable