import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Grid,
  Row,
  Col,
  Radio,
  RadioGroup,
  DateRangePicker,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Nav,
  SelectPicker
} from 'rsuite'

import MainLayout from '../../layout/MainLayout'
import AggregatedTable from './AggregatedTable'
import AggregatedOverview from './AggregatedOverview'
import PieChartWidget from './PieChartWidget'

import { setReportDateRange } from '../../redux/actions/accountHealth'
import {
  getAggregatedData,
  getSalesByAdType,
} from '../../redux/actions/accountHealth'

import { getCurrencyForCountry, parseDate } from '../../services/helper'
import { currencyList } from '../../utils/defaultValues'

const { afterToday } = DateRangePicker

const adTypeItems = [
  { value: '', label: 'All Sponsored Ads'},
  { value: 'sp', label: 'Sponsored Product'},
  { value: 'sb', label: 'Sponsored Brand'},
  { value: 'sd', label: 'Sponsored Display'},
]

const AgencyView = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencySign = useSelector(state => state.header.currencySign)
  const currencyCode = useSelector(state => state.header.currencyCode)
  const currencyRateList = useSelector(state => state.header.currencyRateList)
  const currentUserId = useSelector(state => state.header.currentUserId)

  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const [isLoading, setIsLoading] = useState(false)
  const [aggregatedData, setAggregatedData] = useState([])
  const [isLoadingSales, setIsLoadingSales] = useState(false)
  const [salesByAdType, setSalesByAdType] = useState({})
  const [dateRangeType, setDateRangeType] = useState('month')
  const [currentStartDate, setCurrentStartDate] = useState(startDate)
  const [currentEndDate, setCurrentEndDate] = useState(endDate)
  const [diffDisplayType, setDiffDisplayType] = useState('diff')
  const [adType, setAdType] = useState('')

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      try {
        const data = await dispatch(getAggregatedData(
          accessToken,
          adType,
          abortCtrl.signal,
        ))
        setAggregatedData(data)
        setIsLoading(false)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoading(false)
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [adType, startDate, endDate, currentUserId, dispatch]) // eslint-disable-line

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoadingSales(true)
      const accessToken = await getAccessTokenSilently()
      try {
        const data = await dispatch(getSalesByAdType(
          accessToken,
          abortCtrl.signal,
        ))
        setSalesByAdType(data)
        setIsLoadingSales(false)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoadingSales(false)
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, [currentUserId, dispatch]) // eslint-disable-line

  const exchangeRates = useMemo(() => {
    if (!currencyRateList || !Object.keys(currencyRateList).length) {
      return {}
    }

    const currency = currencyList.find(c => c.code === currencyCode)

    const calculatedResult = { ...currencyRateList.rates }

    const baseToEuro = parseFloat(calculatedResult[currency.currencyCode])

    if (baseToEuro !== 1) {
      Object.keys(calculatedResult).forEach((rate) => {
        calculatedResult[rate] = parseFloat(calculatedResult[rate]) / baseToEuro
      })
    }

    return calculatedResult
  }, [currencyCode, currencyRateList])

  const totalCost = useMemo(() => {
    let sum = 0; // semi-colon is required.
    (aggregatedData || []).forEach((agency) => {
      const code = getCurrencyForCountry(agency.country).currencyCode
      sum += agency.accountKPI.cost / (exchangeRates[code] || 1)
    })
    return sum
  }, [aggregatedData, exchangeRates])

  const handleChangeDateRange = (dates) => {
    const newStart = parseDate(dates[0])
    const newEnd = parseDate(dates[1])

    if (newStart.toString() === 'Invalid Date'
      || newEnd.toString() === 'Invalid Date') {
      return
    }

    dispatch(setReportDateRange(
      dates[0],
      dates[1],
    ))
    setCurrentStartDate(dates[0])
    setCurrentEndDate(dates[1])
  }

  const revenuePieChartData = useMemo(() => {
    const sumSales = (sales) => {
      let sum = 0;
      if (!Array.isArray(sales)) {
        return sum
      }
      sales.forEach((sale) => {
        const code = getCurrencyForCountry(sale.country_id).currencyCode
        sum += sale.revenue / (exchangeRates[code] || 1)
      })
      return sum
    }

    return [
      { name: `SP (${currencySign})`, value: sumSales(salesByAdType.sp) , color: '#31cceb', fullName: 'SP Sales'},
      { name: `SB (${currencySign})`, value: sumSales(salesByAdType.sb), color: '#cf1d23', fullName: 'SB Sales'},
      { name: `SD (${currencySign})`, value: sumSales(salesByAdType.sd), color: '#97e80c', fullName: 'SD Sales'},
    ]
  }, [salesByAdType, exchangeRates]) // eslint-disable-line

  const brandedPieChartData = useMemo(() => {
    let brandedSale = 0
    let nonBrandedSale = 0; // semi-colon is required here.

    (aggregatedData || []).forEach((account) => {
      if (!account.accountKPI) {
        return
      }

      const code = getCurrencyForCountry(account.country).currencyCode

      brandedSale += parseFloat(account.accountKPI.brandedSales || 0) / (exchangeRates[code] || 1)
      nonBrandedSale += parseFloat(account.accountKPI.nonBrandedSales || 0) / (exchangeRates[code] || 1)
    })

    return [
      { name: `Branded (${currencySign}):`, value: brandedSale, color: '#ed4c0c', fullName: 'Branded'},
      { name: `Non-Branded (${currencySign}):`, value: nonBrandedSale, color: '#97e80c', fullName: 'Non-Branded'},
    ]
  }, [aggregatedData, exchangeRates]) // eslint-disable-line

  return (
    <MainLayout>
      <div className="agency-overview-page">
        <div className="page-header">
          <Nav appearance='subtle' activeKey={'agency'}>
            <Nav.Item componentClass={Link} to="/account-report">Account Reports</Nav.Item>
            <Nav.Item eventKey="agency">Agency View</Nav.Item>
            <Nav.Item componentClass={Link} to="/audit-report">Audit Report</Nav.Item>
          </Nav>
          <div className="aggregated-view-report-date">
            <RadioGroup
              className="flex"
              name="dateRangeTypeList"
              value={dateRangeType}
              onChange={setDateRangeType}
            >
              <Radio value="custom">Custom</Radio>
              <Radio value="week">Weekly</Radio>
              <Radio value="month">Monthly</Radio>
            </RadioGroup>
            <div className="header-row date-range-container">
              <DateRangePicker
                value={[currentStartDate, currentEndDate]}
                ranges={[]}
                limitEndYear={0}
                disabledDate={afterToday()}
                oneTap={dateRangeType !== 'custom'}
                hoverRange={dateRangeType}
                showOneCalendar={dateRangeType !== 'custom'}
                onChange={handleChangeDateRange}
              />
            </div>
            <SelectPicker
              className="ad-types-selector"
              data={adTypeItems}
              searchable={false}
              cleanable={false}
              value={adType}
              onChange={(v) => setAdType(v)}
            />
            <ButtonToolbar>
              <ButtonGroup>
                <Button
                  appearance={diffDisplayType === 'diff' ? 'primary' : 'default'}
                  onClick={() => setDiffDisplayType('diff')}
                >
                  Absolute
                </Button>
                <Button
                  appearance={diffDisplayType === 'diffPercent' ? 'primary' : 'default'}
                  onClick={() => setDiffDisplayType('diffPercent')}
                >
                  Percent
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </div>
        </div>
        <div className="page-content">
          <Grid fluid>
            <Row gutter={20} className="report-content">
              <Col xs={24}>
                <AggregatedOverview
                  isLoading={isLoading || isLoadingSales}
                  diffDisplayType={diffDisplayType}
                  accountList={aggregatedData || []}
                  adType={adType}
                  totalCost={totalCost}
                  exchangeRates={exchangeRates}
                />
              </Col>
              <Col xs={24}>
                <AggregatedTable
                  isLoading={isLoading || isLoadingSales}
                  diffDisplayType={diffDisplayType}
                  accountList={aggregatedData || []}
                  exchangeRates={exchangeRates}
                />
              </Col>
              <Col xs={12}>
                <PieChartWidget
                  title="Sales By Ad Type"
                  data={revenuePieChartData}
                />
              </Col>
              <Col xs={12}>
                <PieChartWidget
                  title={`Brand vs Non-Brand Sales ${adType !== '' ? '(' + adType.toUpperCase() + ')' : ''}`}
                  data={brandedPieChartData}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </MainLayout>
  )
}

export default AgencyView
