import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import BidAdjustComponent from '../CommonComponents/BidAdjustComponent'
import ThumbHistory from '../CampaignTableComponent/ThumbHistory'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'

import { ReactComponent as HistorySvg } from '../../assets/svg/history.svg'

import {
  updateKeywordBids,
  updateKeywordStates,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  formatCurrency,
  capitalizeFirstLetter,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
  groupRecords,
  copyToClipboard,
  calcNewBid,
} from '../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
  JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
  JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
} from '../../utils/defaultValues'

import { campaignTypes, matchTypes } from '../../utils/filterDef'

const initialColumns = [
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'bid', name: 'Current Bid' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'keyword', name: 'Keyword', className: 'col-keyword' },
  { key: 'bid', name: 'Current Bid' },
]

const campaignTypesWithAll = [
  { value: '', label: 'All' },
  ...campaignTypes,
]

const TargetSearchKeywordTable = ({ startDate, endDate, includeZeroSpend,
  isLoadingChart, chartData, onChangeDate, onLoadChart, onIncludeZeroSpend }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const findTargetsData = useSelector(state => state.bulkEngine.findTargetsData)

  const [groupMode, setGroupMode] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [groupedKeywords, setGroupedKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [selectedAdType, setSelectedAdType] = useState(campaignTypesWithAll[0])
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [historyData, setHistoryData] = useState({})
  const [historyPayload, setHistoryPayload] = useState({})
  const [isLifetimeVisible, setIsLifetimeVisible] = useState(false)
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)

  useEffect(() => {
    const campaignsById = {}
    campaignsWithHistory.forEach((campaign) => {
      campaignsById[campaign.campaign_id] = {
        name: campaign.campaign,
        type: campaign.campaignType,
        targetingType: campaign.targeting_type,
        costType: campaign.cost_type,
      }
    })

    const extendedKeywords = [];
    (findTargetsData.keywords || []).forEach((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}
      const campaignType = campaignDetail.type || ''

      if (selectedAdType.value !== '') {
        if (campaignType !== selectedAdType.value) {
          return
        }
      }

      if (selectedMatchType.value !== '') {
        if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
          return
        }
      }

      extendedKeywords.push({
        ...calcDerivedMetrics(record),
        campaignName: campaignDetail.name || '',
        campaignType,
        targetingType: campaignDetail.targetingType || '',
        costType: campaignDetail.costType || '',
        matchType: capitalizeFirstLetter(record.match_type),
      })
    })

    setKeywords(extendedKeywords)
    setGroupedKeywords(
      groupRecords(
        extendedKeywords,
        'campaign_id',
        ['campaignName', 'campaignType', 'targetingType']
      )
    )
  }, [findTargetsData, campaignsWithHistory, selectedAdType, selectedMatchType])

  useEffect(() => {
    const dataByKeyword = {};
    (chartData.keywords || []).forEach((record) => {
      const key = `${record.campaign_id}-${record.keyword_id}`
      if (!dataByKeyword[key]) {
        dataByKeyword[key] = []
      }
      dataByKeyword[key].push(calcDerivedMetrics(record))
    })
    setHistoryData(dataByKeyword)
  }, [chartData])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const availableColumns = campaignTableColumns.filter(c1 => bulkBidColumnList.find(c2 => c2.key === c1.key))
    return [
      [...initialColumns, ...availableColumns],
      [...initialColumnsGroup, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns])

  const handleChangeState = async (state) => {
    let keywordsChanged = []
    keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    )).forEach((record) => {
      keywordsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        keywordId: record.keyword_id,
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        keyword: record.keyword,
        matchType: record.matchType,
        originalState: record.state,
      })
    })

    // Remove duplicate entries.
    keywordsChanged =  [...new Map(keywordsChanged.map(item => [item.keywordId, item])).values()]

    if (!keywordsChanged.length) {
      return
    }

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateKeywordStates(
      accessToken,
      keywordsChanged,
      state,
      false,
    ))
    setIsUpdatingStates(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_KEYWORD_STATES,
        state,
      ))
    }
  }

  const handleAdjustBid = async () => {
    let keywordsChanged = []
    keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
      && record.keyword !== '(_targeting_auto_)'
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.campaignType,
        currentAccount?.country_id,
        record.costType
      )

      keywordsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        keywordId: record.keyword_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        keyword: record.keyword,
        originalBid: record.bid,
        matchType: record.matchType,
      })
    })

    // Remove duplicate entries.
    keywordsChanged =  [...new Map(keywordsChanged.map(item => [item.keywordId, item])).values()]

    if (!keywordsChanged.length) {
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your keywords.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateKeywordBids(
      accessToken,
      keywordsChanged,
      false,
    ))
    setIsUpdatingBids(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS,
        keywordsChanged,
      ))
    }
  }

  const handleShowHistory = () => {
    if (!isShowHistory) {
      onLoadChart('keywords')
      setIsShowHistory(true)
    } else {
      setIsShowHistory(false)
    }
  }

  const handleHistoryClick = (record, column) => {
    if (!isShowHistory) {
      return
    }

    setHistoryPayload({
      metric: column.label,
      metricKey: column.key,
      keyword: record.keyword,
      data: (historyData[`${record.campaign_id}-${record.keyword_id}`] || []).map(item => ({
        date: item.report_date,
        value: item[column.key] || 0,
      })),
    })
  }

  const handleCopy = () => {
    const dataToCopy = keywords.filter(record => (
      selectedKeywords.indexOf(record.keyword_id) !== -1
    )).map(record => record.keyword.trim())

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} keyword${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const handleDateChange = (startDate, endDate) => {
    onChangeDate(startDate, endDate)
    setIsLifetimeVisible(startDate === null && endDate === null)
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By keywords"
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Ad Type</span>
          <Select
            classNamePrefix="ad-type-selector"
            options={campaignTypesWithAll}
            value={selectedAdType}
            onChange={setSelectedAdType}
          />
        </div>
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Include zero spend targets"
            checked={includeZeroSpend}
            onChange={onIncludeZeroSpend}
          />
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedKeywords.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof keywords.find(record => (
        selectedKeywords.indexOf(record.keyword_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => { handleCopy() }}
          >
            Copy
          </button>
          <button
            type="button"
            className="btn btn-green"
            disabled={isUpdatingStates || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button
            type="button"
            className="btn btn-light-blue"
            onClick={() => { setIsShowAdjustBid(true) }}
          >
            Adjust Bid
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderSecondaryAction = () => {
    if (isLifetimeVisible) {
      return null
    }
    return (
      <HistorySvg title="History" onClick={handleShowHistory}/>
    )
  }

  const renderKeyword = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.matchType } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.keyword_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-keyword">Totals:</div>
      <div className="table-col col-campaign" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'keyword') {
        return `${record.keyword} (${record.matchType} | ${capitalizeFirstLetter(record.state)})`
      }
      if (column.key === 'bid') {
        return formatCurrency(record.bid, currencySign, currencyRate)
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-keyword">
        { record.children.length } keywords
      </div>
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.keyword_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-keyword" title={record.keyword}>
        <strong>
          { record.keyword }
        </strong>
        <div className="meta-data">
          { record.matchType } | { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.keyword_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-keyword" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const isLoading = isUpdatingBids
    || isUpdatingStates
    || isLoadingChart

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-keywords"
            records={groupedKeywords}
            idField="campaign_id"
            searchFields={['keyword']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedKeywords}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchKeywordResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            isLoading={isLoading}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedKeywords}
            onChangeDate={handleDateChange}
            sorterChild={tableSorter(['state', 'keyword', 'matchType'])}
            idFieldChild="keyword_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName', 'state', 'keyword', 'matchType'])}
            className="table-keywords"
            records={keywords || []}
            idField="keyword_id"
            searchFields={['keyword']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedKeywords}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchKeywordResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            isLoading={isLoading}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderKeyword}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedKeywords}
            onChangeDate={handleDateChange}
          />
        )
      }
      <ThumbHistory
        title={`Keyword: ${historyPayload.keyword}`}
        areaData={historyPayload.data || []}
        metric={historyPayload.metric}
        metricKey={historyPayload.metricKey}
        startDate={startDate}
        endDate={endDate}
        onClose={() => { setHistoryPayload({}) }}
      />
    </>
  )
}

export default TargetSearchKeywordTable
