import React, { useState } from 'react'

import VideoModal from '../../components/CommonComponents/VideoModal'

const LoomVideoCollection = ({ videoList }) => {
  const [selectedVideoName, setSelectedVideoName] = useState('')
  const [selectedVideoList, setSelectedVideoList] = useState([])

  const handleSelectVideo = (video) => {
    setSelectedVideoName(video.name)
    setSelectedVideoList([
      { title: video.name, url: `https://www.loom.com/embed/${video.videoId}` }
    ])
  }

  return (
    <>
      <div className="video-list">
        {
          videoList.map(video => (
            <div
              key={video.name}
              className="video-item"
              onClick={() => { handleSelectVideo(video) }}
            >
              <div className="video-name">
                { video.name }
              </div>
              <img
                src={
                  video.thumbnail ||
                  `https://cdn.loom.com/sessions/thumbnails/${video.videoId}-with-play.gif`
                }
                alt={video.name}
              />
            </div>
          ))
        }
      </div>
      <VideoModal
        showModal={selectedVideoName !== ''}
        videoList={selectedVideoList}
        onClose={() => { setSelectedVideoName(''); setSelectedVideoList([]) }}
        title={selectedVideoName}
      />
    </>
  )
}

export default LoomVideoCollection
