import React, { useState } from 'react'

import SuggestedBidTypeSelector from './SuggestedBidTypeSelector'
import KeywordModal from './KeywordModal'
import KeywordList from './KeywordList'
import DefaultBidSection from './DefaultBidSection'

const SBKeywordSection = ({ keywords, isSuggestionsLoading, suggestions, onFind, onChange }) => {
  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const handleFind = () => {
    setOpenModal(true)
    onFind()
  }

  const handleSelect = (keywordsToAdd) => {
    setOpenModal(false)

    let newKeywords = [...keywords]
    const keywordsToGetBid = []

    keywordsToAdd.forEach((keyword) => {
      const duplicate = keywords.find(kw => (
        keyword.keywordText === kw.keywordText
          && keyword.matchType === kw.matchType.toLowerCase()
      ))

      if (!duplicate) {
        newKeywords.push({
          keywordText: keyword.keywordText,
          matchType: keyword.matchType,
          keywordBid: defaultBid,
        })

        keywordsToGetBid.push({
          keyword: keyword.keywordText,
          matchType: keyword.matchType,
        })
      }
    })

    newKeywords = newKeywords.map((keyword, index) => ({
      ...keyword,
      id: keyword.id ? keyword.id : index,
    }))

    onChange(newKeywords, true, keywordsToGetBid)
  }

  return (
    <div className="section-container">
      <div className="section-title">
        Keywords
        <div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFind}
          >
            Add Keywords
          </button>
        </div>
      </div>
      <div className="section-note">
        Your keywords (word combinations and phrases) are used to match your ads
        with search terms shoppers are using to find products.
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <DefaultBidSection
            targetings={keywords}
            defaultBid={defaultBid}
            forKeyword
            onChange={onChange}
            onDefaultBidChange={setDefaultBid}
          />
        </div>
        <div className="field-wrapper">
          <SuggestedBidTypeSelector
            targetings={keywords}
            forKeyword
            onChange={onChange}
          />
        </div>
      </div>
      <KeywordList
        keywords={keywords}
        defaultBid={defaultBid}
        isForSb
        onChange={onChange}
      />
      <KeywordModal
        show={openModal}
        isLoading={isSuggestionsLoading}
        suggestedKeywords={suggestions}
        keywordsSelected={keywords}
        isForSb
        onSelect={handleSelect}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default SBKeywordSection
