import React from 'react'
import Select from 'react-select'

import { matchTypeOptions } from '../../../utils/defaultValues'

const KeywordList = ({ keywords, defaultBid, isForSb, onChange }) => {
  const handleMatchTypeChange = (match, keyword) => {
    onChange(keywords.map((kw) => {
      if (kw.id.toString() === keyword.id.toString()) {
        return {
          ...kw,
          matchType: match.value,
        }
      }
      return kw
    }))
  }

  const handleSuggestionApply = (type, keyword) => {
    if (!keyword.suggestedBid) {
      return
    }

    switch (type) {
      case 'suggest':
        if (!isForSb) {
          keyword.keywordBid = keyword.suggestedBid.suggested
        } else {
          keyword.keywordBid = keyword.suggestedBid.recommended
        }
        break;
      case 'min':
        keyword.keywordBid = keyword.suggestedBid.rangeStart
        break;
      case 'max':
        keyword.keywordBid = keyword.suggestedBid.rangeEnd
        break;
      default:
        keyword.keywordBid = defaultBid
        break;
    }

    onChange(keywords.map(kw => {
      if (kw.id.toString() === keyword.id.toString()) {
        return keyword
      }
      return kw
    }))
  }

  const handleBidChange = (e, keyword) => {
    e.preventDefault()
    keyword.keywordBid = e.currentTarget.value
    onChange(keywords.map(kw => {
      if (kw.id.toString() === keyword.id.toString()) {
        return keyword
      }
      return kw
    }))
  }

  const handleRemove = (id) => {
    onChange(keywords.filter(kw => kw.id.toString() !== id.toString()))
  }

  const getSuggestedBidText = (keyword) => {
    if (!keyword.suggestedBid) {
      return 'N/A'
    }

    let recommended
    if (!isForSb) {
      recommended = keyword.suggestedBid.suggested
    } else {
      recommended = keyword.suggestedBid.recommended
    }

    return `${recommended} : ${keyword.suggestedBid.rangeStart}~${keyword.suggestedBid.rangeEnd}`
  }

  if (!keywords.length) {
    return (
      <div className="no-keyword-desc">
        No keyword added.
      </div>
    )
  }

  return (
    <div className="keyword-container">
      {
        keywords.map((keyword) => {
          const matchType = matchTypeOptions.find(option => option.value === keyword.matchType)
          return (
            <div key={keyword.id} className="keyword-box">
              <div className="box-header">
                <div className="match-selector">
                  <label>Match Type</label>
                  <Select
                    classNamePrefix="match-select"
                    options={matchTypeOptions}
                    value={matchType}
                    onChange={val => handleMatchTypeChange(val, keyword)}
                  />
                </div>
                {
                  keyword.suggestedBid && (
                    <div className="apply-section">
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleSuggestionApply('suggest', keyword) }}
                      >
                        Apply Suggest
                      </button>
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleSuggestionApply('min', keyword) }}
                      >
                        Apply Min
                      </button>
                      <button
                        type="button"
                        className="btn btn-blue"
                        onClick={() => { handleSuggestionApply('max', keyword) }}
                      >
                        Apply Max
                      </button>
                    </div>
                  )
                }
                <span
                  className="close-icon"
                  title="Remove"
                  onClick={() => { handleRemove(keyword.id) }}
                >
                  &times;
                </span>
              </div>
              <div className="box-content">
                <div>
                  Keyword: { keyword.keywordText } ({ matchType.label })
                </div>
                <div>
                  Suggested Bid:&nbsp;
                  { getSuggestedBidText(keyword) }
                </div>
                <div>
                  Keyword Bid:&nbsp;
                  <input
                    type="text"
                    value={keyword.keywordBid}
                    onChange={e => { handleBidChange(e, keyword) }}
                  />
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default KeywordList
