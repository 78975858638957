import React from 'react'
import * as Icon from 'react-icons/fi'

import TimezoneSelector from '../RuleManagerComponents/TimezoneSelector'

const TemplateEditorHeader = ({ templateType, name, timezone, noTimezone,
  onNameChange, onTimezoneChange, onClose }) => {
  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Edit { templateType } Template
          </span>
        </div>
        <div className="right-column">
          {
            !noTimezone && (
              <TimezoneSelector
                timezone={timezone}
                onChange={onTimezoneChange}
              />
            )
          }
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      <div className="template-name-wrapper">
        <input
          type="text"
          placeholder="Template name"
          value={name}
          onChange={(event) => { onNameChange(event.target.value) }}
        />
        <Icon.FiEdit3 />
      </div>
    </div>
  )
}

export default TemplateEditorHeader
