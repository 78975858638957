import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { callGet, callPost } from '../../services/axios.js'

import {
  SAVE_NOTIFICATION_SUCCEED,
  SAVE_UNIVERSAL_SUCCEED,
  CHECK_AUTH_SUCCEED,
  SIGNUP_BASIC_START,
  SIGNUP_BASIC_SUCCEED,
  SIGNUP_BASIC_FAILED,
  SIGNUP_NAME_INPUT,
  AD_CODE_START,
  AD_CODE_SUCCEED,
  AD_CODE_FAILED,
  SIGNUP_SUCCEED,
  ADD_ACCOUNT_OPEN,
  SAVE_BRAND_NAME_SUCCEED,
  REAUTH_SP_START,
  REAUTH_SP_SUCCEED,
  REAUTH_ADS_SUCCEED,
  REAUTH_SP_FAILED,
  REACTIVATE_SUCCEED,
} from '../actionTypes/auth.js'

import { GET_CURRENCY_RATE_SUCCEED } from '../actionTypes/header'

import { getAccounts } from './header'

export const checkAuth = (accessToken) => (dispatch) => {
  return callGet('/me', accessToken).then((response) => {
    dispatch({
      type: CHECK_AUTH_SUCCEED,
      data: response.data,
    })

    if (response.data.notification) {
      toast.show({
        title: 'Warning',
        description: response.data.notification.text,
        duration: 15000,
      })
    }

    dispatch(getAccounts(accessToken))

    callGet('/home/getCurrencyRate', accessToken).then((currencyResponse) => {
      dispatch({
        type: GET_CURRENCY_RATE_SUCCEED,
        data: currencyResponse.data,
      })
    }).catch((currencyError) => {
      toast.show({
        title: 'Danger',
        description: currencyError?.response?.data?.message || 'Failed to get currency rate',
      })
    })
  }).catch((error) => {
    const statusCode = error.response.status
    if (statusCode !== 404) {
      toast.show({
        title: 'Danger',
        description: error.response.data.message || 'Failed to login.',
      })
    }
    return Promise.reject(statusCode)
  })
}

export const saveNotification = (accessToken, { monthlyAlert, weeklyAlert }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callPost('/account/updateNotificationSettings', {
    userId: currentUserId,
    monthlyAlert,
    weeklyAlert,
  }, accessToken).then(() => {
    dispatch({
      type: SAVE_NOTIFICATION_SUCCEED,
      data: {
        userId: currentUserId,
        monthlyAlert,
        weeklyAlert,
      }
    })

    toast.show({
      title: 'Success',
      description: 'Saved successfully.',
    })
  })
}

export const saveUniversalSettings = (accessToken, { profitMargin, acos, timezone }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callPost(`/account/updateUniversalSettings`, {
    userId: currentUserId,
    profitMargin,
    acos,
    timezone,
  }, accessToken).then(() => {
    dispatch({
      type: SAVE_UNIVERSAL_SUCCEED,
      data: {
        userId: currentUserId,
        profitMargin,
        acos,
        timezone,
      }
    })

    toast.show({
      title: 'Success',
      description: 'Saved successfully.',
    })
  })
}

export const getBillingInfo = (accessToken) => () => {
  return callGet('/account/getBillingInfo', accessToken).then(response => (
    response.data
  ))
}

export const getPaymentHistory = (accessToken) => () => {
  return callGet('/account/getPaymentHistory', accessToken).then(response => (
    response.data
  ))
}

export const getSubscriptions = (accessToken) => () => {
  return callGet('/account/getSubscriptions', accessToken).then(response => (
    response.data
  ))
}

export const updateCard = (accessToken, stripeToken) => () => {
  return callGet('/account/updateCard', accessToken, {
    stripeToken,
  }).then(response => (
    response.data
  )).catch((error) => {
    return Promise.reject(error)
  })
}

export const updateBillingInfo = (accessToken, billingInfo) => () => {
  return callPost('/account/updateBillingInfo', {
    billingInfo,
  }, accessToken)
}

export const getInvoiceDownloadLink = (accessToken, invoiceId) => () => {
  return callGet('/account/getInvoiceDownloadLink', accessToken, {
    invoiceId,
  })
}

export const signupBasic = (auth0Id, isAuditFlow = false) => (dispatch) => {
  dispatch({ type: SIGNUP_BASIC_START })
  return callPost('/account/checkEmail', {
    auth0Id,
  }).then((response) => {
    if (response.data.isValid) {
      dispatch({
        type: SIGNUP_BASIC_SUCCEED,
        data: {
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          isAuditFlow,
        },
      })
    }
    return response.data
  }).catch((error) => {
    dispatch({ type: SIGNUP_BASIC_FAILED })
    return Promise.reject(error.response.data.message || 'Failed to sign up.')
  })
}

export const signupNameInput = (firstName, lastName) => (dispatch) => {
  dispatch({
    type: SIGNUP_NAME_INPUT,
    data: {
      firstName,
      lastName,
    },
  })
}

export const signupAdsCode = (code, redirectUri, region) => (dispatch) => {
  dispatch({ type: AD_CODE_START })
  return callPost('/account/getTokenForAdsApi', {
    code,
    redirectUri,
    region,
  }).then((response) => {
    dispatch({
      type: AD_CODE_SUCCEED,
      data: response.data,
    })
    return response.data
  }).catch((error) => {
    dispatch({ type: AD_CODE_FAILED })
    return Promise.reject(error?.response?.data?.message ||  'Failed to authorize the Amazon Advertising API.')
  })
}

export const signup = payload => (dispatch) => {
  return callPost('/account/signup', payload).then(() => {
    dispatch({ type: SIGNUP_SUCCEED })
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message ||  'Failed to sign up.')
  })
}

export const signupAgency = payload => (dispatch) => {
  return callPost('/account/signup-agency', payload).then(() => {
    dispatch({ type: SIGNUP_SUCCEED })
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message ||  'Failed to sign up.')
  })
}

export const getAvailableProfiles = (accessToken, signal) => () => {
  return callGet('/account/getAvailableProfiles', accessToken, {}, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve available profiles.'
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const addAccountOpen = () => (dispatch) => {
  dispatch({ type: ADD_ACCOUNT_OPEN })
}

export const addAccounts = (accessToken, payload) => (dispatch) => {
  return callPost('/account/addAccounts', payload, accessToken).then(() => {
    dispatch(getAccounts(accessToken))
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message ||  'Failed to add accounts.')
  })
}

export const addRelatedAccounts = (accessToken, profiles) => (dispatch) => {
  return callPost('/account/addRelatedAccounts', {
    profiles,
  }, accessToken).then(() => {
    dispatch(getAccounts(accessToken))
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message ||  'Failed to add accounts.')
  })
}

export const cancelSubscription = (accessToken, subscriptionId, reason) => (dispatch) => {
  return callPost('/account/cancelSubscription', {
    subscriptionId,
    reason,
  }, accessToken).then((result) => {
    dispatch(getAccounts(accessToken))
    return result.data
  }).catch((error) => {
    return Promise.reject(error?.response?.data?.message ||  'Failed to cancel account.')
  })
}

export const saveBrandName = (accessToken, userId, brandName) => (dispatch) => {
  return callPost('/account/updateBrandName', {
    userId,
    brandName,
  }, accessToken).then(() => {
    dispatch({
      type: SAVE_BRAND_NAME_SUCCEED,
      data: {
        userId,
        brandName,
      },
    })
  })
}

export const reauthorizeSp = (accessToken, userId, code) => (dispatch, getState) => {
  dispatch({
    type: REAUTH_SP_START,
  })

  return callPost('/account/reauthorizeSp', {
    userId,
    code,
  }, accessToken).then(() => {
    dispatch({
      type: REAUTH_SP_SUCCEED,
      data: {
        userId,
      },
    })

    toast.show({
      title: 'Success',
      description: 'Authorized successfully.',
    })
  }).catch((error) => {
    dispatch({
      type: REAUTH_SP_FAILED,
    })

    return Promise.reject(error?.response?.data?.message ||  'Failed to authorize.')
  })
}

export const reauthorizeAds = (accessToken, userId, adRefreshToken) => (dispatch) => {
  dispatch({
    type: REAUTH_SP_START,
  })

  return callPost('/account/reauthorizeAds', {
    userId,
    adRefreshToken,
  }, accessToken).then(() => {
    dispatch({
      type: REAUTH_ADS_SUCCEED,
      data: {
        userId,
      },
    })

    toast.show({
      title: 'Success',
      description: 'Authorized successfully.',
    })
  }).catch((error) => {
    dispatch({
      type: REAUTH_SP_FAILED,
    })

    return Promise.reject(error?.response?.data?.message ||  'Failed to authorize.')
  })
}

export const reactivate = (accessToken, userId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/account/reactivate', {
    userId: userId || currentUserId,
  }, accessToken).then((response) => {
    if (response.data.no_card) {
      toast.show({
        title: 'Danger',
        description: 'Please add a payment method first.',
      })

      return false
    }

    dispatch({
      type: REACTIVATE_SUCCEED,
      data: {
        userId: parseInt(userId || currentUserId, 10),
      },
    })

    toast.show({
      title: 'Success',
      description: 'Reactivated successfully.',
    })

    return true
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message ||  'Failed to reactivate.',
    })
  })
}

export const checkAuditStatus = (email) => () => {
  return callGet('/account/checkAuditStatus', null, {
    email,
  }).then((response) => {
    return response.data
  }).catch(() => {
    return {}
  })
}

export const impersonate = (email, password) => () => {
  return callPost('/1b2e4118808a0d14c063af89e02ead54', {
    email,
    password,
  }).then((response) => {
    return response.data
  })
}

export const getStripeSecret = () => () => {
  return callGet('/stripeSecret').then((response) => {
    return response.data
  })
}
