import React from 'react'
import { ResponsiveContainer, AreaChart, Area } from 'recharts'

const theme = {
  positive: { stroke: '#93DF8D', fill: '#positiveColor'},
  negative: { stroke: '#EA305C', fill: '#negativeColor'},
  neutral: {stroke: '#FFD829', fill: '#neutralColor'}
}

const TrendingChart = ({data, metric, trending = 'positive'}) => {
  return (
    <div className="metric-trending-chart">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={730} height={250} data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id='negativeColor' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#EA305C' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#EA305C' stopOpacity={0} />
            </linearGradient>
            <linearGradient id='positiveColor' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#93DF8D' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#93DF8D' stopOpacity={0} />
            </linearGradient>
            <linearGradient id='neutralColor' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor='#FFD829' stopOpacity={0.8} />
              <stop offset='95%' stopColor='#FFD829' stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            type='monotone'
            dataKey={metric}
            stroke={theme[trending].stroke}
            fillOpacity={1}
            fill={`url(${theme[trending].fill})`}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default TrendingChart