import {
  GET_SP_SUGGESTIONS_SUCCEED,
  GET_SD_SUGGESTIONS_SUCCEED,
} from '../actionTypes/campaignCreator'

const initialState = {
  suggestedSPKeywords: [],
  suggestedSPCategories: [],
  suggestedSPProducts: [],
  suggestedSDProducts: [],
  suggestedSDCategories: [],
}

const campaignCreator = (state = initialState, action) => {
  switch (action.type) {
    case GET_SP_SUGGESTIONS_SUCCEED:
      return {
        ...state,
        suggestedSPKeywords: action.data.keywords,
        suggestedSPCategories: action.data.categories.filter(category => category.canBeTargeted),
        suggestedSPProducts: action.data.products,
      }
    case GET_SD_SUGGESTIONS_SUCCEED:
      return {
        ...state,
        suggestedSDProducts: action.data.products,
        suggestedSDCategories: action.data.categories,
      }
    default:
      return state
  }
}

export default campaignCreator
