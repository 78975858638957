import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from 'rsuite'

import SectionHeader from './SectionHeader'
import ReportNoteWidget from './ReportNoteWidget'
import TileSection from './TileSection'
import ChartSection from './ChartSection'
import ComparisonViewSection from './ComparisonViewSection'

const AdTypeSection = ({
  title,
  sectionKey,
  noteKey,
  customMetricSection,
  isLoading,
  settings,
  isLoadingSettings,
  onSaveSettings,
  results,
  pastResults,
  salesData = null,
  charts,
  spMetrics = undefined,
  sbMetrics = undefined,
  sdMetrics = undefined,
  matchTypeSummary = [],
  placementTotal = [],
  bidTotal = [],
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
  onLoadStats,
}) => {
  const currentUserId = useSelector(state => state.header.currentUserId)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)

  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const customMetricWidgets = useSelector(state => state.pageGlobal.customMetricWidgets)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])
  const [note, setNote] = useState('')

  useEffect(() => {
    const abortCtrl = new AbortController();

    if (showSection) {
      onLoadStats(sectionKey, abortCtrl.signal)
    }

    return () => {
      abortCtrl.abort()
    }
  }, [showSection, startDate, endDate, currentUserId]) // eslint-disable-line

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings[noteKey] || '')
    }
  }, [settings, isLoadingSettings, noteKey])

  useEffect(() => {
    const widgetSettings = reportWidgetSetting ? reportWidgetSetting[sectionKey] : []
    setViewWidgets(
      widgetSettings.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting, sectionKey])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w =>
      w.key === widgetKey ? {...w, view: !w.view} : w
    ))
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      sectionKey: noteKey,
      sectionValue: note,
    })
  }

  let comparisonWidget
  if (sectionKey === 'allSponsoredAds') {
    comparisonWidget = viewWidgets.find(w => w.key === 'comparison_mom_table')
  }

  return (
    <>
      <SectionHeader
        title={title}
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      {
        showSection && (
          <Grid className={isLoading ? 'loading' : ''} fluid>
            {
              (customizingSection || noteWidgetView) && (
                <ReportNoteWidget
                  noteWidgetView={noteWidgetView}
                  customizing={customizingSection}
                  onRemove={setNoteWidgetView}
                  note={note}
                  onChange={setNote}
                  section={sectionKey}
                />
              )
            }
            <TileSection
              sectionKey={sectionKey}
              isLoading={isLoading}
              customMetricSection={customMetricSection}
              viewWidgets={viewWidgets}
              customMetricWidgets={customMetricWidgets}
              results={results}
              pastResults={pastResults}
              salesData={salesData}
              customizingSection={customizingSection}
              onRemove={handleOnRemoveWidget}
            />
            <ChartSection
              sectionKey={sectionKey}
              isLoading={isLoading}
              viewWidgets={viewWidgets}
              charts={charts}
              spMetrics={spMetrics}
              sbMetrics={sbMetrics}
              sdMetrics={sdMetrics}
              salesData={salesData}
              matchTypeSummary={matchTypeSummary}
              placementTotal={placementTotal}
              bidTotal={bidTotal}
              customizingSection={customizingSection}
              currencySign={currencySign}
              onRemove={handleOnRemoveWidget}
            />
            {
              (sectionKey === 'allSponsoredAds'
                && comparisonWidget
                && (comparisonWidget.view || customizingSection)) && (
                <ComparisonViewSection
                  widget={viewWidgets[viewWidgets.length - 1]}
                  customizing={customizingSection}
                  onRemove={handleOnRemoveWidget}
                />
              )
            }
          </Grid>
        )
      }
    </>
  )
}

export default AdTypeSection
