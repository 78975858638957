import React, { useEffect, useState } from 'react'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

const Section = ({ title, note = null, isSaving = false, bodyClass,
  topRight = null, isCollapsed = true, children }) => {
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (!isCollapsed) {
      setCollapsed(false)
    }
  }, [isCollapsed])

  return (
    <div className={`page-section${isSaving ? ' loading' : ''}`}>
      <div
        className="section-title"
        onClick={() => { setCollapsed(!collapsed) }}
      >
        <span className="section-title-contents">
          { title }
          { collapsed ? <BsCaretDownFill size={12} color='#979797' /> : <BsCaretUpFill size={12} color='#979797' /> }
        </span>
        { topRight }
      </div>
      {
        note && (
          <div className="section-note">{ note }</div>
        )
      }
      {
        !collapsed && (
          <div className={`section-contents ${bodyClass}`}>
            { isSaving && <LoaderComponent /> }
            { children }
          </div>
        )
      }
    </div>
  )
}

export default Section
