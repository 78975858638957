import React, { useState, useEffect, useMemo } from 'react'

import BidRuleSection from '../RuleManagerComponents/BidRuleSection'
import CampaignRuleSection from '../RuleManagerComponents/CampaignRuleSection'
import ModifierRuleSection from '../RuleManagerComponents/ModifierRuleSection'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
} from '../../utils/defaultValues'

const RuleSection = ({ hasSpCampaign, ruleToShow, onChange }) => {
  const [isSet, setIsSet] = useState(false)
  const [selectedBidAction, setSelectedBidAction] = useState(null)
  const [selectedBidValue, setSelectedBidValue] = useState(null)
  const [selectedCampaignAction, setSelectedCampaignAction] = useState(null)
  const [selectedCampaignValue, setSelectedCampaignValue] = useState(null)
  const [selectedCampaignLimit, setSelectedCampaignLimit] = useState(null)
  const [selectedTosModAction, setSelectedTosModAction] = useState(null)
  const [selectedTosModValue, setSelectedTosModValue] = useState(null)
  const [selectedProductModAction, setSelectedProductModAction] = useState(null)
  const [selectedProductModValue, setSelectedProductModValue] = useState(null)
  const [selectedRestModAction, setSelectedRestModAction] = useState(null)
  const [selectedRestModValue, setSelectedRestModValue] = useState(null)

  useEffect(() => {
    setSelectedBidAction(null)
    setSelectedBidValue(null)
    setSelectedCampaignAction(null)
    setSelectedCampaignValue(null)
    setSelectedCampaignLimit(null)
    setSelectedTosModAction(null)
    setSelectedTosModValue(null)
    setSelectedProductModAction(null)
    setSelectedProductModValue(null)
    setSelectedRestModAction(null)
    setSelectedRestModValue(null)

    if (!ruleToShow) {
      return
    }

    ruleToShow.r.forEach((rule) => {
      if (rule.t === RULE_TYPE_BID) {
        setSelectedBidAction(ruleBidActionList.find(option =>
          option.value === rule.a
        ))

        if (rule.v) {
          setSelectedBidValue(rule.v)
        }
      } else if (rule.t === RULE_TYPE_CAMPAIGN) {
        setSelectedCampaignAction(ruleCampaignActionList.find(option =>
          option.value === rule.a
        ))

        if (rule.v) {
          setSelectedCampaignValue(rule.v)
        }

        if (rule.l) {
          setSelectedCampaignLimit(rule.l)
        }
      } else if (rule.t === RULE_TYPE_TOS_MODIFIER) {
        setSelectedTosModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedTosModValue(rule.v)
      } else if (rule.t === RULE_TYPE_PRODUCT_MODIFIER) {
        setSelectedProductModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedProductModValue(rule.v)
      } else if (rule.t === RULE_TYPE_REST_MODIFIER) {
        setSelectedRestModAction(ruleModifierActionList.find(option =>
          option.value === rule.a
        ))

        setSelectedRestModValue(rule.v)
      }
    })

    setIsSet(true)
  }, [ruleToShow])

  const rulesToApply = useMemo(() => {
    const rules = []
    if (selectedBidAction) {
      const rule = {
        t: RULE_TYPE_BID,
        a: selectedBidAction.value,
      }
      if (rule.a === RULE_BID_ACTION_PAUSE) {
        rules.push(rule)
      } else if (selectedBidValue) {
        rule.v = parseFloat(selectedBidValue)
        rules.push(rule)
      }
    }

    if (selectedCampaignAction) {
      const rule = {
        t: RULE_TYPE_CAMPAIGN,
        a: selectedCampaignAction.value,
      }
      if (rule.a === RULE_CAMPAIGN_ACTION_PAUSE) {
        rules.push(rule)
      } else if (selectedCampaignValue) {
        rule.v = parseFloat(selectedCampaignValue)
        if (selectedCampaignLimit) {
          rule.l = parseFloat(selectedCampaignLimit)
        }
        rules.push(rule)
      }
    }

    if (selectedTosModAction && selectedTosModValue) {
      rules.push({
        t: RULE_TYPE_TOS_MODIFIER,
        a: selectedTosModAction.value,
        v: parseFloat(selectedTosModValue),
      })
    }

    if (selectedProductModAction && selectedProductModValue) {
      rules.push({
        t: RULE_TYPE_PRODUCT_MODIFIER,
        a: selectedProductModAction.value,
        v: parseFloat(selectedProductModValue),
      })
    }

    if (selectedRestModAction && selectedRestModValue) {
      rules.push({
        t: RULE_TYPE_REST_MODIFIER,
        a: selectedRestModAction.value,
        v: parseFloat(selectedRestModValue),
      })
    }

    return rules
  }, [selectedBidAction, selectedBidValue,
    selectedCampaignAction, selectedCampaignValue, selectedCampaignLimit,
    selectedTosModAction, selectedTosModValue,
    selectedProductModAction, selectedProductModValue,
    selectedRestModAction, selectedRestModValue])

  // When multi-campaigns are selected, each individual
  // campaign's original rules are read-only.
  const isReadOnly = useMemo(() => {
    if (ruleToShow
      && (
        ruleToShow.campaign
        || (ruleToShow.template && ruleToShow.template.campaign)
      )
    ) {
      return true
    }
    return false
  }, [ruleToShow])

  useEffect(() => {
    if (isReadOnly) {
      return
    }

    if (ruleToShow) {
      if (
        (
          ruleToShow.template
          && JSON.stringify(ruleToShow.template.r) === JSON.stringify(rulesToApply)
        )
        || !isSet
        || JSON.stringify(ruleToShow.r) === JSON.stringify(rulesToApply)
      ) {
        return
      }
    } else if (!rulesToApply.length) {
      return
    }
    onChange(rulesToApply)
  }, [rulesToApply, isReadOnly]) // eslint-disable-line

  const handleBidClear = () => {
    setSelectedBidAction(null)
    setSelectedBidValue(null)
  }

  const handleCampaignClear = () => {
    setSelectedCampaignAction(null)
    setSelectedCampaignValue(null)
    setSelectedCampaignLimit(null)
  }

  const handleModifierClear = ruleType => () => {
    if (ruleType === RULE_TYPE_TOS_MODIFIER) {
      setSelectedTosModAction(null)
      setSelectedTosModValue(null)
    } else if (ruleType === RULE_TYPE_PRODUCT_MODIFIER) {
      setSelectedProductModAction(null)
      setSelectedProductModValue(null)
    } else if (ruleType === RULE_TYPE_REST_MODIFIER) {
      setSelectedRestModAction(null)
      setSelectedRestModValue(null)
    }
  }

  const renderHeader = () => {
    return (
      <>
        <div className="section-header">
          Add/Edit Rules
        </div>
        {
          ruleToShow !== null
          && typeof ruleToShow !== 'undefined'
          && typeof ruleToShow.template !== 'undefined'
          && !isReadOnly
          && (
            <div className="section-note">
              These rules are from the template: <strong>{ ruleToShow.template.name }</strong>.
              You can overwrite them by changing and applying rules to selected times here.<br />
              You can also update the template by clicking on the template above.
            </div>
          )
        }
      </>
    )
  }

  const renderBidRule = () => {
    return (
      <BidRuleSection
        isReadOnly={isReadOnly}
        action={selectedBidAction}
        value={selectedBidValue}
        onActionChange={setSelectedBidAction}
        onValueChange={setSelectedBidValue}
        onClear={handleBidClear}
      />
    )
  }

  const renderCampaignRule = () => {
    return (
      <CampaignRuleSection
        isReadOnly={isReadOnly}
        action={selectedCampaignAction}
        value={selectedCampaignValue}
        limit={selectedCampaignLimit}
        onActionChange={setSelectedCampaignAction}
        onValueChange={setSelectedCampaignValue}
        onLimitChange={setSelectedCampaignLimit}
        onClear={handleCampaignClear}
      />
    )
  }

  const renderModifierRule = () => {
    return (
      <>
        <ModifierRuleSection
          name="Top of Search modifier"
          isReadOnly={isReadOnly}
          action={selectedTosModAction}
          value={selectedTosModValue}
          onActionChange={setSelectedTosModAction}
          onValueChange={setSelectedTosModValue}
          onClear={handleModifierClear(RULE_TYPE_TOS_MODIFIER)}
        />
        <ModifierRuleSection
          name="Product Pages modifier"
          isReadOnly={isReadOnly}
          action={selectedProductModAction}
          value={selectedProductModValue}
          onActionChange={setSelectedProductModAction}
          onValueChange={setSelectedProductModValue}
          onClear={handleModifierClear(RULE_TYPE_PRODUCT_MODIFIER)}
        />
        <ModifierRuleSection
          name="Rest of the Search modifier"
          isReadOnly={isReadOnly}
          action={selectedRestModAction}
          value={selectedRestModValue}
          onActionChange={setSelectedRestModAction}
          onValueChange={setSelectedRestModValue}
          onClear={handleModifierClear(RULE_TYPE_REST_MODIFIER)}
        />
      </>
    )
  }

  return (
    <div className="rule-section">
      { renderHeader() }
      { renderBidRule() }
      { renderCampaignRule() }
      { hasSpCampaign && renderModifierRule() }
    </div>
  )
}

export default RuleSection
