import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import DateRangeComponent from '../CommonComponents/DateRangeComponent'

import KeywordTable from './KeywordTable'

import { setDateRange } from '../../redux/actions/header'

import { getKeywords } from '../../redux/actions/productDetail'

import { calcDerivedMetrics, getISODate } from '../../services/helper'

const tabs = [
  { value: 'below', label: 'Below Target ACoS'},
  { value: 'above', label: 'Above Target ACoS'},
  { value: 'zero', label: 'Targets With Zero Impressions'}
]

const ProductKeywordComponent = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const curProduct = useSelector(state => state.product.curProduct)
  const keywords = useSelector(state => state.productDetail.keywords)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const [activeTab, setActiveTab] = useState('zero')
  const [extendedKeywords, setExtendedKeywords] = useState([])

  useEffect(() => {
    setExtendedKeywords((keywords || []).map((record) => (
      calcDerivedMetrics(record)
    )))
  }, [keywords])

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getKeywords(accessToken))
    })()
  }, [dispatch, curProduct, currentUserId, currentStartDate, currentEndDate]) // eslint-disable-line

  const handleChangeDateRange = ([startDate, endDate]) => {
    dispatch(
      setDateRange({
        startDate: getISODate(startDate),
        endDate: getISODate(endDate),
      })
    )
  }

  const filteredKeywords = useMemo(() => {
    return extendedKeywords.filter((record) => {
      if (activeTab === 'below') {
        return record.acos <= record.target_acos
      }
      if (activeTab === 'above') {
        return record.acos > record.target_acos
      }
      if (activeTab === 'zero') {
        return parseInt(record.impressions, 10) === 0
      }
      return record
    })
  }, [extendedKeywords, activeTab])

  return (
    <div className="product-detail-keyword">
      <div className="detail-keyword-header">
        <div className="keyword-tabs">
          {
            tabs.map(tab => (
              <div
                key={tab.value}
                className={`tab${activeTab === tab.value ? ' selected' : ''}`}
                onClick={() => { setActiveTab(tab.value) }}
              >
                { tab.label }
              </div>
            ))
          }
        </div>
        <DateRangeComponent
          value={[currentStartDate, currentEndDate]}
          placement="bottomEnd"
          onChange={handleChangeDateRange}
        />
      </div>
      <KeywordTable
        keywords={filteredKeywords}
      />
    </div>
  )
}

export default ProductKeywordComponent
