import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import Select from 'react-select'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { format } from 'date-fns'

import { formatValue, formatCurrency } from '../../services/helper'

const metricList = [
  { name: 'PPC Revenue', key: 'revenue', color: '#fbab34' },
  { name: 'Ad Spend', key: 'cost', color: '#cfd2f5' },
  { name: 'Orders', key: 'orders', color: '#3dade8' },
  { name: 'Clicks', key: 'clicks', color: '#25c835' },
  { name: 'Impressions', key: 'impressions', color: '#93df8d' },
  { name: 'ACoS %', key: 'acos', color: '#ffd156' },
  { name: 'Conv %', key: 'conversion', color: '#ff7b56' },
]

const CHART_METRIC_SETTING = 'PRODUCT_CHART_METRICS'
const METRIC_LIMIT = 6

const ProductChartComponent = () => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const curProductChart = useSelector(state => state.product.curProductChart)

  const [chartData, setChartData] = useState([])
  const [chartMetrics, setChartMetrics] = useState([])
  const [isClickedAdd, setIsClickedAdd] = useState(false)

  const visibility = {}
  metricList.forEach((metric) => {
    visibility[metric.key] = metric.key !== 'impressions'
  })

  const [metricVisibility, setMetricVisibility] = useState(visibility)

  useEffect(() => {
    loadSavedMetrics()
  }, [])

  useEffect(() => {
    if (!curProductChart || curProductChart.length === 0) {
      return
    }
    setChartData(curProductChart.map((product) => {
      const revenue = parseFloat(product.revenue || 0)
      const cost = parseFloat(product.cost || 0)
      const orders = parseInt(product.orders || 0, 10)
      const clicks = parseInt(product.clicks || 0, 10)
      return {
        revenue: revenue * currencyRate,
        cost: cost * currencyRate,
        orders,
        clicks,
        impressions: parseInt(product.impressions || 0, 10),
        acos: revenue ? formatValue(cost / revenue * 100, 'number') : 0,
        conversion: clicks ? orders / clicks * 100 : 0,
        date: product.startdate ? new Date(product.startdate).valueOf() : new Date().valueOf(),
      }
    }))
  }, [curProductChart, currencyRate])

  const loadSavedMetrics = () => {
    const savedMetrics = window.localStorage.getItem(CHART_METRIC_SETTING)
    if (savedMetrics) {
      let metrics = []
      try {
        metrics = JSON.parse(savedMetrics)
      } catch (error) {
        // keep silence
      }
      setChartMetrics(metrics)
    } else {
      setChartMetrics(metricList.slice(0, METRIC_LIMIT))
    }
  }

  const handleMetricToggle = key => () => {
    const visibility = Object.assign({}, metricVisibility, {
      [key]: !metricVisibility[key]
    })
    setMetricVisibility(visibility)
  }

  const handleDeleteMetric = (index) => {
    let newChartMetrics = [...chartMetrics].filter((metric, ind) => ind !== index)
    setChartMetrics(newChartMetrics)
    window.localStorage.setItem(CHART_METRIC_SETTING, JSON.stringify(newChartMetrics))
  }

  const handleMetricAdd = (metric) => {
    const newChartMetrics = [...chartMetrics]
    const length = newChartMetrics.length
    const selectedMetric = metricList.find(item => item.key === metric.value)
    metricVisibility[selectedMetric.key] = false
    newChartMetrics[length] = selectedMetric
    setChartMetrics(newChartMetrics)
    setIsClickedAdd(false)
    window.localStorage.setItem(CHART_METRIC_SETTING, JSON.stringify(newChartMetrics))
  }

  const tickFormatter = (ts) => {
    if (!ts || isNaN(ts)) {
      return ''
    }
    return format(ts, 'MM/dd')
  }

  const formatter = (value, name) => {
    if (name === 'PPC Revenue' || name === 'Ad Spend') {
      return formatCurrency(value, currencySign, currencyRate)
    }
    if (name === 'ACoS %') {
      return formatValue(value, 'percent', 1)
    }
    if (name === 'Conv %') {
      return formatValue(value, 'percent', 2)
    }
    return formatValue(value, 'number', 0)
  }

  const renderLegend = ({ payload }) => (
    <ul className="legend-list">
      {
        payload.map((entry, index) => {
          const style = {
            borderColor: entry.payload.stroke,
          }

          if (metricVisibility[entry.dataKey]) {
            style.backgroundColor = entry.payload.stroke
          }
          return (
            <li key={entry.dataKey}>
              <button onClick={handleMetricToggle(entry.dataKey)}>
                <span className="bullet" style={style} />
                { entry.value }
              </button>
              <FiTrash2 onClick={() => { handleDeleteMetric(index) }}/>
            </li>
          )
        })
      }
      {
        isClickedAdd && (
          <li className="metric-selector-container">
            <Select
              className="metric-selector"
              classNamePrefix="metric-selector"
              options={metricList.filter(metric => chartMetrics.findIndex(item => item.key === metric.key) === -1).map(metric => (
                {
                  label: metric.name,
                  value: metric.key,
                }
              ))}
              onChange={handleMetricAdd}
            />
          </li>
        )
      }
      <li className="add-action">
        <FiPlus
          title="Add Metric"
          onClick={() => { setIsClickedAdd(true) }}
        />
      </li>
    </ul>
  )

  return (
    <div className="product-chart-component">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={300}
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: -15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" tickFormatter={tickFormatter} />
          <YAxis />
          <Tooltip
            labelFormatter={ts => format(ts, 'yyyy/MM/dd')}
            formatter={formatter}
          />
          {
            chartMetrics.map(metric => (
              metric && <Line
                key={metric.key}
                type="monotone"
                name={metric.name}
                dataKey={metric.key}
                stroke={metric.color}
                strokeWidth={2}
                dot={{ r: 2, fill: metric.color }}
                activeDot={true}
                hide={!metricVisibility[metric.key]}
              />
            ))
          }
          <Legend
            content={renderLegend}
            wrapperStyle={{
              bottom: -5,
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ProductChartComponent
