import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import CustomTooltip from '../../CommonComponents/CustomTooltip'
import CategoryTable from './CategoryTable'
import CategoryTree from './CategoryTree'
import AudienceTree from './AudienceTree'

import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import { getCategories } from '../../../redux/actions/targeting'

const TAB_VIEWS = 'views'
const TAB_AUDIENCES = 'audiences'
const TAB_PURCHASES = 'purchases'
const SUB_TAB_CATEGORY = 'category'
const SUB_TAB_PRODUCT = 'product'

const tabList = [
  {
    value: TAB_VIEWS,
    name: 'Views remarketing',
    description: 'Reach custom-built audiences who viewed product detail pages '
      + 'matching criteria you choose.',
  },
  {
    value: TAB_PURCHASES,
    name: 'Purchases remarketing',
    description: 'Reach custom audiences who purchased products '
      + 'matching criteria you choose.',
  },
  {
    value: TAB_AUDIENCES,
    name: 'Amazon audiences',
    description: 'Reach relevant audiences using our exclusive, '
      + 'prebuilt segments: Lifestyle, Interests, Life events, and In-market.',
  },
]

const subTabList = [
  { value: SUB_TAB_CATEGORY, name: 'Categories' },
  { value: SUB_TAB_PRODUCT, name: 'Products' },
]

const viewProductList = [
  { label: 'Advertised products', value: 'exactProduct', description: 'Your ads will be shown to shoppers who previously viewed the detail pages of your advertised products', audienceType: 'views' },
  { label: 'Similar to advertised products', value: 'similarProduct', description: 'Your ads will be shown to shoppers who previously viewed the detail pages of products that are similar to your advertised products', audienceType: 'views' },
]

const purchaseProductList = [
  { label: 'Advertised products', value: 'exactProduct', description: 'Your ads will be shown to shoppers who previously purchased your advertised products', audienceType: 'purchases' },
  { label: 'Related to advertised products', value: 'relatedProduct', description: 'Your ads will be shown to shoppers who previously purchased products that are related to your advertised products', audienceType: 'purchases' },
]

const lookbackOptions = [
  { value: '7', label: '7 days' },
  { value: '14', label: '14 days' },
  { value: '30', label: '30 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
]

const AudienceModal = ({ show, defaultBid, targetings,
  isSuggestionsLoading, onChange, onClose }) => {
  const dispatch = useDispatch()

  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const suggestedSDCategories = useSelector(state => state.campaignCreator.suggestedSDCategories)

  const [currentTab, setCurrentTab] = useState(TAB_VIEWS)
  const [currentSubTab, setCurrentSubTab] = useState(SUB_TAB_CATEGORY)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [lookback, setLookback] = useState(lookbackOptions[2])

  useEffect(() => {
    let abortCtrl

    if (show && !isLoaded) {
      (async () => {
        setIsCategoriesLoading(true)
        abortCtrl = new AbortController()
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getCategories(
          accessToken,
          abortCtrl.signal,
        ))
        setCategories(response)
        setIsLoaded(true)
        setIsCategoriesLoading(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [show]) // eslint-disable-line

  const handleCategoryTarget = (category) => {
    const duplicate = targetings.find(existingCategory => (
      (existingCategory.id === category.id + currentTab)
      && (existingCategory.type === 'audience_category'
        || existingCategory.type === 'audience_refine')
      && (existingCategory.audienceType === currentTab)
    ))

    if (duplicate) {
      return
    }

    onChange([
      {
        ...category,
        name: category.name || category.na,
        type: 'audience_category',
        bid: defaultBid,
        audienceType: currentTab,
        id: category.id + currentTab,
        lookback: lookback,
      },
      ...targetings,
    ])
  }

  const handleCategoryTargetAll = () => {
    const existingIds = targetings
      .filter(category => (
        (
          category.type === 'audience_category'
          || category.type === 'audience_refine'
        )
        && category.audienceType === currentTab
      ))
      .map(category => category.id)

    const newTargetings = [...targetings]
    suggestedSDCategories.forEach((category) => {
      if (!existingIds.includes(category.id + currentTab)) {
        newTargetings.push({
          ...category,
          type: 'audience_category',
          bid: defaultBid,
          audienceType: currentTab,
          id: category.id + currentTab,
          lookback: lookback,
        })
      }
    })

    onChange(newTargetings)
  }

  const handleCategoryRefine = (category, payload) => {
    const duplicate = targetings.find(existingCategory => (
      existingCategory.id === category.id + currentTab
      && (existingCategory.type === 'audience_category'
        || existingCategory.type === 'audience_refine')
      && (existingCategory.audienceType === currentTab)
      && (
        (!existingCategory.brandId && !payload.brandId)
        || (existingCategory.brandId.toString() === payload.brandId.toString())
      )
    ))

    if (duplicate) {
      return
    }

    onChange(prevTargetings => ([
      {
        ...category,
        type: 'audience_refine',
        bid: defaultBid,
        audienceType: currentTab,
        name: category.name || category.na,
        id: category.id + currentTab,
        lookback: lookback,
        ...payload,
      },
      ...prevTargetings,
    ]))
  }

  const handleCategoryTargetTree = (category, parentCategory = null, expandedNodes) => {
    const duplicate = targetings.find(existingCategory => (
      existingCategory.id === category.id + currentTab
      && (existingCategory.type === 'audience_category'
        || existingCategory.type === 'audience_refine')
      && (existingCategory.audienceType === currentTab)
    ))

    if (duplicate) {
      return
    }

    const newCategory = {
      ...category,
      name: category.na,
      type: 'audience_category',
      bid: defaultBid,
      audienceType: currentTab,
      id: category.id + currentTab,
      parentId: parentCategory ? parentCategory.id : null,
      lookback: lookback,
    }

    if (parentCategory) {
      newCategory.path = expandedNodes[parentCategory.id] && expandedNodes[parentCategory.id].path
        ? expandedNodes[parentCategory.id].path
        : category.na
    }

    onChange([ newCategory, ...targetings ])
  }

  const handleProductTarget = (viewProduct) => {
    onChange([
      {
        id: viewProduct.value + currentTab,
        name: viewProduct.label,
        type: 'audience_product',
        audienceType: currentTab,
        bid: defaultBid,
        lookback,
      },
      ...targetings,
    ])
  }

  const handleAudienceTarget = (audience) => {
    onChange([
      {
        id: audience.value,
        name: audience.label,
        type: 'audience',
        bid: defaultBid,
        audienceType: currentTab,
      },
      ...targetings,
    ])
  }

  const renderViewsTab = () => {
    if (currentTab !== TAB_VIEWS) {
      return null
    }

    if (currentSubTab === SUB_TAB_CATEGORY) {
      return (
        <>
          <div className="match-selector">
            <span className="match-title">Lookback:</span>
            <Select
              classNamePrefix="match-select"
              options={lookbackOptions}
              value={lookback}
              onChange={setLookback}
            />
          </div>
          <div className="category-tab-container">
            <CategoryTable
              actionLabel="Add"
              isLoading={isSuggestionsLoading}
              categories={suggestedSDCategories || []}
              targetings={targetings.filter(target => target.audienceType === currentTab)}
              onTarget={handleCategoryTarget}
              onTargetAll={handleCategoryTargetAll}
              onRefine={handleCategoryRefine}
            />
            <CategoryTree
              actionLabel="Add"
              isLoading={isCategoriesLoading}
              categories={categories}
              targetings={targetings.filter(target => target.audienceType === currentTab)}
              onTarget={handleCategoryTargetTree}
              onTargetSearch={handleCategoryTarget}
              onRefine={handleCategoryRefine}
            />
          </div>
        </>
      )
    }

    return (
      <div className="views-product-container">
        <div className="match-selector">
          <span className="match-title">Lookback:</span>
          <Select
            classNamePrefix="match-select"
            options={lookbackOptions}
            value={lookback}
            onChange={setLookback}
          />
        </div>
        {
          viewProductList.map((viewProduct) => {
            const isExisting = targetings.find(targeting => (
              (targeting.id === viewProduct.value + currentTab)
              && targeting.audienceType === currentTab
            ))

            return (
              <div key={viewProduct.value} className="view-item">
                <div className="item-label">
                  { viewProduct.label }
                  <CustomTooltip placement="right">
                    <p>{ viewProduct.description }</p>
                  </CustomTooltip>
                </div>
                {
                  isExisting ? (
                    <button type="button" className="btn btn-blue disabled">
                      Added
                    </button>
                  ) : (
                    <button type="button" className="btn btn-blue" onClick={() => { handleProductTarget(viewProduct) }}>
                      Add
                    </button>
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderPurchasesTab = () => {
    if (currentTab !== TAB_PURCHASES) {
      return null
    }

    if (currentSubTab === SUB_TAB_CATEGORY) {
      return (
        <>
          <div className="match-selector">
            <span className="match-title">Lookback:</span>
            <Select
              classNamePrefix="match-select"
              options={lookbackOptions}
              value={lookback}
              onChange={setLookback}
            />
          </div>
          <div className="category-tab-container">
            <CategoryTable
              actionLabel="Add"
              isLoading={isSuggestionsLoading}
              categories={suggestedSDCategories || []}
              targetings={targetings.filter(target => target.audienceType === currentTab)}
              onTarget={handleCategoryTarget}
              onTargetAll={handleCategoryTargetAll}
              onRefine={handleCategoryRefine}
              />
            <CategoryTree
              actionLabel="Add"
              isLoading={isCategoriesLoading}
              categories={categories}
              targetings={targetings.filter(target => target.audienceType === currentTab)}
              onTarget={handleCategoryTargetTree}
              onTargetSearch={handleCategoryTarget}
              onRefine={handleCategoryRefine}
            />
          </div>
        </>
      )
    }

    return (
      <div className="views-product-container">
        <div className="match-selector">
          <span className="match-title">Lookback:</span>
          <Select
            classNamePrefix="match-select"
            options={lookbackOptions}
            value={lookback}
            onChange={setLookback}
          />
        </div>
        {
          purchaseProductList.map((purchaseProduct) => {
            const isExisting = targetings.find(targeting => (
              (targeting.id === purchaseProduct.value + currentTab)
              && targeting.audienceType === currentTab
            ))

            return (
              <div key={purchaseProduct.value} className="view-item">
                <div className="item-label">
                  { purchaseProduct.label }
                  <CustomTooltip placement="right">
                    <p>{ purchaseProduct.description }</p>
                  </CustomTooltip>
                </div>
                {
                  isExisting ? (
                    <button type="button" className="btn btn-blue disabled">
                      Added
                    </button>
                  ) : (
                    <button type="button" className="btn btn-blue" onClick={() => { handleProductTarget(purchaseProduct) }}>
                      Add
                    </button>
                  )
                }
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderAudiencesTab = () => {
    if (currentTab !== TAB_AUDIENCES) {
      return null
    }

    return (
      <AudienceTree
        targetings={targetings}
        onTarget={handleAudienceTarget}
      />
    )
  }

  return (
    <Modal className="audience-modal" backdrop="static" show={show} size="lg">
      <Modal.Body>
        <div className="tab-list">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? 'selected' : ''}
                onClick={() => { setCurrentTab(tab.value) }}
              >
                { tab.name }
                {
                  tab.description && (
                    <CustomTooltip placement="right">
                      { tab.description }
                    </CustomTooltip>
                  )
                }
              </button>
            ))
          }
        </div>
        {
          (currentTab === TAB_VIEWS || currentTab === TAB_PURCHASES) && !isNonEndemicAccount && (
            <div className="sub-tab-list">
              {
                subTabList.map(tab => (
                  <button
                    key={tab.value}
                    type="button"
                    className={currentSubTab === tab.value ? 'selected' : ''}
                    onClick={() => { setCurrentSubTab(tab.value) }}
                  >
                    { tab.name }
                  </button>
                ))
              }
            </div>
          )
        }
        { renderViewsTab() }
        { renderPurchasesTab() }
        { renderAudiencesTab() }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AudienceModal
