import React, { useEffect, useMemo } from 'react'

import RuleMetric from './RuleMetric'

const RuleSection = ({ hasSpCampaign, slots, currentTemplates, onChange }) => {
  const templateRulesWithId = useMemo(() => {
    const rules = []
    currentTemplates.forEach((template, index) => {
      template.slots.forEach((slot) => {
        rules.push(Object.assign({}, slot, {
          id: `${Math.floor(Math.random() * 1000).toString()}-${index}-template`,
          template,
        }))
      })
    })
    return rules
  }, [currentTemplates])

  useEffect(() => {
    if (!slots.length && !currentTemplates.length) {
      onChange([
        {
          id: `${Math.floor(Math.random() * 1000).toString()}-${slots.length}`,
        },
      ])
    }
  }, [slots, currentTemplates]) // eslint-disable-line

  const handleSectionAdd = () => {
    onChange([
      ...slots,
      {
        id: `${Math.floor(Math.random() * 1000).toString()}-${slots.length}`,
      },
    ])
  }

  const handleSectionChange = slotId => (values) => {
    onChange(slots.map((slot) => {
      if (slot.id !== slotId) {
        return slot
      }
      return {
        id: slot.id,
        ...values,
      }
    }))
  }

  const handleSectionRemove = slotId => () => {
    onChange(slots.filter(slot => (
      slot.id !== slotId
    )))
  }

  return (
    <div className="rule-section">
      <div className="section-header">
        Add/Edit Rules
      </div>
      {
        slots.map(slot => (
          <RuleMetric
            key={slot.id}
            slot={slot}
            hasSpCampaign={hasSpCampaign}
            onChange={handleSectionChange(slot.id)}
            onRemove={handleSectionRemove(slot.id)}
          />
        ))
      }
      {
        templateRulesWithId.map(slot => (
          <RuleMetric
            key={slot.id}
            slot={slot}
            hasSpCampaign={hasSpCampaign}
            fromTemplate
          />
        ))
      }
      <div className="section-footer">
        <button
          type="button"
          className="btn btn-white"
          onClick={handleSectionAdd}
        >
          + Add new
        </button>
      </div>
    </div>
  )
}

export default RuleSection
