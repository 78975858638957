/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Button } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import VideoLink from '../CommonComponents/VideoLink'
import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import CampaignTableComponent from '../CampaignTableComponent'

import SkuOpResult, { FILTER_NAME_SKU_OP } from './SkuOpResult'
import TargetOpResult, { FILTER_NAME_TARGET_OP } from './TargetOpResult'
import StOpResult, {
  FILTER_NAME_ST_OP,
  searchTermFilters,
} from './StOpResult'
import NegativeResult from './NegativeResult'
import PlacementOpResult from './PlacementOpResult'
import AdvancedNegativeResult from './AdvancedNegativeResult'

import {
  getAdgroupsForCampaigns,
  getSkuOpData,
  saveSkuOpData,
  getTargetOpData,
  saveBidOpData,
  getStOpData,
  saveStOpData,
  getNegativeFinderData,
  saveNegativeFinderData,
  getPlacementOpData,
  savePlacementOpData,
  getAdvancedNegativeData,
  saveAdvancedNegativeData,
} from '../../redux/actions/bulkEngine'
import { checkStatus, monitorJob } from '../../redux/actions/job'
import { setDateRange } from '../../redux/actions/header'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { getAssociativeCampaigns } from '../../services/helper'

import {
  JOB_TYPE_BULK_SKU_OP,
  JOB_TYPE_BULK_BID_OP,
  JOB_TYPE_BULK_ST_OP,
  JOB_TYPE_BULK_NEGATIVE,
  JOB_TYPE_BULK_PLACEMENT_OP,
  JOB_TYPE_BULK_ADVANCED_NEGATIVE,
  MODULE_NAME_SKU_OP,
  MODULE_NAME_TARGET_OP,
  MODULE_NAME_ST_OP,
  MODULE_NAME_NEGATIVE,
  MODULE_NAME_PLACEMENT_OP,
  MODULE_NAME_ADVANCED,
  BULK_ACTION_NAME_EXPENSIVE,
  BULK_ACTION_NAME_NO_IMPRESSION,
  BULK_ACTION_NAME_LOW_CONVERSION,
  BULK_ACTION_NAME_NO_SALES,
  BULK_ACTION_NAME_NEGATIVES,
  BULK_ACTION_EXPENSIVE,
  BULK_ACTION_NO_IMPRESSION,
  BULK_ACTION_LOW_CONVERSION,
  BULK_ACTION_NO_SALES,
  BULK_ACTION_NEGATIVES,
} from '../../utils/defaultValues'

const MODULE_SKU = 'sku'
const MODULE_TARGET = 'target'
const MODULE_ST = 'st'
const MODULE_NEGATIVE = 'negative'
const MODULE_PLACEMENT = 'placement'
const MODULE_ADVANCED = 'advanced'

const moduleList = [
  {
    key: MODULE_SKU,
    name: MODULE_NAME_SKU_OP,
    description: (
      <>
        <p>
          Finds SKU’s (ads) that are hidden in campaigns and ad groups that are unprofitable.
        </p>
        <p>
          Compatible with Sponsored Product and Sponsored Display only.
        </p>
      </>
    ),
  },
  {
    key: MODULE_TARGET,
    name: MODULE_NAME_TARGET_OP,
    description: (
      <>
        <p>
          Easily find and optimize targets across all campaigns
          and ad groups at once.
        </p>
      </>
    ),
  },
  {
    key: MODULE_ST,
    name: MODULE_NAME_ST_OP,
    description: (
      <>
        <p>
          Searches multiple campaigns and ad groups to find search terms
          or ASINs that are losing you money.
        </p>
      </>
    ),
  },
  {
    key: MODULE_NEGATIVE,
    name: MODULE_NAME_NEGATIVE,
    description: (
      <>
        <p>
          Finds individual words hidden in search terms that are unprofitable
          so they can be negated (negative phrase match).
        </p>
      </>
    ),
  },
  {
    key: MODULE_PLACEMENT,
    name: MODULE_NAME_PLACEMENT_OP,
    description: (
      <>
        <p>
          By applying the recommendations, you’ll be adjusting
          the base bid of each target while adjusting the placements
          for both top of search and product pages
          to help match your overall target ACoS for the campaign.
        </p>
      </>
    ),
  },
  {
    key: MODULE_ADVANCED,
    name: MODULE_NAME_ADVANCED,
    description: (
      <>
        <p>
          In-depth optimization performed in bulk.
        </p>
        <p>
          Select the type of Advanced Optimization action you’d like to take.
        </p>
        <p>
          Then, select a date range and the campaigns you’d like to optimize.
        </p>
        <p>
          Then click Search.
        </p>
      </>
    ),
  },
]

// List of advanced op actions.
const actionList = [
  {
    value: BULK_ACTION_EXPENSIVE,
    label: BULK_ACTION_NAME_EXPENSIVE,
    description: (
      <>
        <p>
          Displays targets in order of highest to lowest CPC
          and allows you to adjust bids or pause targets.
        </p>
      </>
    ),
  },
  {
    value: BULK_ACTION_NO_IMPRESSION,
    label: BULK_ACTION_NAME_NO_IMPRESSION,
    description: (
      <>
        <p>
          Finds all targets in selected campaigns with zero impressions
          and allows you to adjust bids or pause targets.
        </p>
      </>
    ),
  },
  {
    value: BULK_ACTION_LOW_CONVERSION,
    label: BULK_ACTION_NAME_LOW_CONVERSION,
    description: (
      <>
        <p>
          Finds and displays targets with a Conversion Rate under 7%
          and allows you to adjust bids and statuses.
        </p>
      </>
    ),
  },
  {
    value: BULK_ACTION_NO_SALES,
    label: BULK_ACTION_NAME_NO_SALES,
    description: (
      <>
        <p>
          Finds all targets with ad spend but no sales
          and allows you to adjust bids and statuses.
        </p>
      </>
    ),
  },
  {
    value: BULK_ACTION_NEGATIVES,
    label: BULK_ACTION_NAME_NEGATIVES,
    description: (
      <>
        <p>
          Displays all Negative Keywords and Negative Targeting in selected campaigns
          and allows you to archive any or all of them.
        </p>
      </>
    ),
  },
]

export const tutorialList = {
  '': {
    title: 'Bulk Engine Overview',
    videoList: [
      { title: 'Bulk Engine Overview', url: 'https://www.loom.com/embed/8aade68f957449d5ab685182612c39ad' },
    ],
  },
  [MODULE_SKU]: {
    title: MODULE_NAME_SKU_OP,
    videoList: [
      { title: MODULE_NAME_SKU_OP, url: 'https://www.loom.com/embed/9616163435a94a7e8c14742a6d9b157b' },
    ],
  },
  [MODULE_TARGET]: {
    title: MODULE_NAME_TARGET_OP,
    videoList: [
      { title: MODULE_NAME_TARGET_OP, url: 'https://www.loom.com/embed/0b12967340884ceeade49532d1b5b0e9' },
    ],
  },
  [MODULE_ST]: {
    title: MODULE_NAME_ST_OP,
    videoList: [
      { title: MODULE_NAME_ST_OP, url: 'https://www.loom.com/embed/14bd02eb73d14ac984970771a4655b29' },
    ],
  },
  [MODULE_NEGATIVE]: {
    title: MODULE_NAME_NEGATIVE,
    videoList: [
      { title: MODULE_NAME_NEGATIVE, url: 'https://www.loom.com/embed/a516e13e6f164a2aa1d0fde6d4fc182b' },
    ],
  },
  [MODULE_PLACEMENT]: {
    title: MODULE_NAME_PLACEMENT_OP,
    videoList: [
      { title: MODULE_NAME_PLACEMENT_OP, url: 'https://www.loom.com/embed/b3d5f4021e254f888438d8219fa1176b' },
    ],
  },
  [MODULE_ADVANCED]: {
    title: MODULE_NAME_ADVANCED,
    videoList: [
      { title: MODULE_NAME_ADVANCED, url: 'https://www.loom.com/embed/039500d677f640b89a7939886575173e' },
    ],
  },
}

const BulkOp = ({ openedJob, showNotedCampaigns, initialRender, onHasResult }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const campaignIdsSelected = useSelector(state => state.campaign.campaignIdsSelected)

  const statusCheckerInterval = useRef(null)

  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false)
  const [activeModule, setActiveModule] = useState('')
  const [activeAction, setActiveAction] = useState('')
  const [adgroups, setAdgroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [newTermOnly, setNewTermOnly] = useState(true)
  const [hideAsins, setHideAsins] = useState(true)
  const [selectedStFilter, setSelectedStFilter] = useState(searchTermFilters[0])
  const [targetAcos, setTargetAcos] = useState(40)
  const [hideKeywords, setHideKeywords] = useState(true)
  const [removeNumbers, setRemoveNumbers] = useState(true)
  const [removePreps, setRemovePreps] = useState(true)
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [hasResults, setHasResults] = useState(false)
  const [isCampaignsExpanded, setIsCampaignsExpanded] = useState(false)
  // Keep whether a `lifetime` range is selected for each module.
  const [lifetimeForModules, setLifetimeForModules] = useState({})

  // Clean up interval handles.
  useEffect(() => {
    return () => {
      if (statusCheckerInterval.current) {
        clearInterval(statusCheckerInterval.current) // eslint-disable-line
      }
    }
  }, [])

  useEffect(() => {
    setCurrentFilterName('')
    setHasResults(false)
    setIsCampaignsExpanded(false)
  }, [currentUserId])

  useEffect(() => {
    if (openedJob) {
      if (openedJob.type === JOB_TYPE_BULK_SKU_OP) {
        setActiveModule(MODULE_SKU)

        dispatch(saveSkuOpData(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_BID_OP) {
        if (openedJob.payload.action) {
          setActiveModule(MODULE_ADVANCED)
          setActiveAction(openedJob.payload.action)
        } else {
          setActiveModule(MODULE_TARGET)
        }

        dispatch(saveBidOpData(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_ST_OP) {
        setActiveModule(MODULE_ST)
        setNewTermOnly(openedJob.payload.newTermOnly)

        dispatch(saveStOpData(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_NEGATIVE) {
        setActiveModule(MODULE_NEGATIVE)
        setTargetAcos(openedJob.payload.targetAcos)
        setHideKeywords(openedJob.payload.stOnly)

        dispatch(saveNegativeFinderData(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_PLACEMENT_OP) {
        setActiveModule(MODULE_PLACEMENT)
        dispatch(savePlacementOpData(openedJob.results))
      } else if (openedJob.type === JOB_TYPE_BULK_ADVANCED_NEGATIVE) {
        setActiveModule(MODULE_ADVANCED)
        setActiveAction(BULK_ACTION_NEGATIVES)

        dispatch(saveAdvancedNegativeData(openedJob.results))
      }

      if (openedJob.type !== JOB_TYPE_BULK_PLACEMENT_OP) {
        if (openedJob.payload.campaignIds && openedJob.payload.campaignIds.length) {
          loadAdgroups(openedJob.payload.campaignIds)
        }
      }
      if (openedJob.type !== JOB_TYPE_BULK_ADVANCED_NEGATIVE) {
        setLifetimeForModules(Object.assign({}, lifetimeForModules, {
          [activeModule]: openedJob.payload.startDate === null
          && openedJob.payload.endDate === null,
        }))

        if (openedJob.payload.startDate && openedJob.payload.endDate) {
          dispatch(setDateRange({
            startDate: openedJob.payload.startDate,
            endDate: openedJob.payload.endDate,
          }))
        }
      }
      setHasResults(true)
    }
  }, [openedJob, dispatch]) // eslint-disable-line

  useEffect(() => {
    onHasResult(hasResults)
  }, [hasResults]) // eslint-disable-line

  const loadAdgroups = async (campaignIds) => {
    const accessToken = await getAccessTokenSilently()
    setAdgroups(await dispatch(getAdgroupsForCampaigns(
      accessToken,
      campaignIds,
    )))
  }

  const handleModuleSelect = (module) => {
    if (activeModule !== module.key) {
      setHasResults(false)
      setActiveModule(module.key)
    }
  }

  const handleFind = () => {
    if (
      activeModule !== MODULE_PLACEMENT
      && (campaignIdsSelected || []).length
    ) {
      loadAdgroups(campaignIdsSelected)
    }

    let filterName
    // No filter for negative finder & advanced op.
    if (activeModule === MODULE_SKU) {
      filterName = FILTER_NAME_SKU_OP
    } else if (activeModule === MODULE_TARGET) {
      filterName = FILTER_NAME_TARGET_OP
    } else if (activeModule === MODULE_ST) {
      filterName = FILTER_NAME_ST_OP
    }

    if (!filterName) {
      setHasResults(false)
      getResults(
        currentStartDate,
        currentEndDate
      )
    } else {
      setCurrentFilterName(filterName)
    }
  }

  const getResults = (startDate, endDate, acos, hideKeywordsChecked) => {
    if (lifetimeForModules[activeModule]) {
      startDate = null
      endDate = null
    }

    if (activeModule === MODULE_SKU) {
      return loadSkuOpResults(
        startDate,
        endDate
      )
    } else if (activeModule === MODULE_TARGET) {
      return loadBidOpResults(
        startDate,
        endDate
      )
    } else if (activeModule === MODULE_ST) {
      return loadStOpResults(
        startDate,
        endDate,
        newTermOnly
      )
    } else if (activeModule === MODULE_NEGATIVE) {
      return loadNegativeResults(
        startDate,
        endDate,
        acos || targetAcos,
        typeof hideKeywordsChecked !== 'undefined' ? hideKeywordsChecked : hideKeywords
      )
    } else if (activeModule === MODULE_PLACEMENT) {
      return loadPlacementOpResults(
        startDate,
        endDate
      )
    } else if (activeModule === MODULE_ADVANCED) {
      if (activeAction === BULK_ACTION_EXPENSIVE
        || activeAction === BULK_ACTION_NO_IMPRESSION
        || activeAction === BULK_ACTION_LOW_CONVERSION
        || activeAction === BULK_ACTION_NO_SALES) {
        return loadBidOpResults(
          startDate,
          endDate,
          activeAction
        )
      } else if (activeAction === BULK_ACTION_NEGATIVES) {
        return loadAdvancedNegativeResults()
      }
    }
  }

  const handleFilterApply = () => {
    setCurrentFilterName('')
    setHasResults(false)

    getResults(
      currentStartDate,
      currentEndDate
    )
  }

  const handleDateChange = (startDate, endDate) => {
    setLifetimeForModules(Object.assign({}, lifetimeForModules, {
      [activeModule]: startDate === null && endDate === null,
    }))
    getResults(startDate, endDate)
  }

  const handleChangeNewTermOnly = (checked) => {
    setNewTermOnly(checked)
    loadStOpResults(
      currentStartDate,
      currentEndDate,
      checked
    )
  }

  const handleHideKeywordsChange = (checked) => {
    setHideKeywords(checked)
    getResults(
      currentStartDate,
      currentEndDate,
      targetAcos,
      checked
    )
  }

  const handleTargetAcosChange = (acos) => {
    setTargetAcos(acos)
    getResults(currentStartDate, currentEndDate, acos)
  }

  const loadSkuOpResults = async (startDate, endDate) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getSkuOpData(
      accessToken,
      startDate,
      endDate
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_SKU_OP,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_SKU_OP)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadBidOpResults = async (startDate, endDate, action = '') => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getTargetOpData(
      accessToken,
      startDate,
      endDate,
      action
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_BID_OP,
        {
          action,
        }
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_BID_OP)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadStOpResults = async (startDate, endDate, newTermOnlyFlag) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getStOpData(
      accessToken,
      startDate,
      endDate,
      newTermOnlyFlag
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_ST_OP,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_ST_OP)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadNegativeResults = async (startDate, endDate, acos, hideKeywordsChecked) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getNegativeFinderData(
      accessToken,
      startDate,
      endDate,
      acos,
      hideKeywordsChecked,
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_NEGATIVE,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_NEGATIVE)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadPlacementOpResults = async (startDate, endDate) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getPlacementOpData(
      accessToken,
      startDate,
      endDate
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_PLACEMENT_OP,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_PLACEMENT_OP)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const loadAdvancedNegativeResults = async () => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    return dispatch(getAdvancedNegativeData(
      accessToken,
    )).then((response) => {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_ADVANCED_NEGATIVE,
      ))

      checkJob(response.data.jobId, JOB_TYPE_BULK_ADVANCED_NEGATIVE)
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve results.',
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const checkJob = (jobId, type) => {
    if (statusCheckerInterval.current) {
      clearInterval(statusCheckerInterval.current)
    }
    statusCheckerInterval.current = setInterval(async () => {
      // @todo: Prevent another status check API call
      // while a previous one is in flight.
      const accessToken = await getAccessTokenSilently()
      dispatch(checkStatus(accessToken, jobId)).then(({ url, data }) => {
        if (url) {
          if (type === JOB_TYPE_BULK_SKU_OP) {
            dispatch(saveSkuOpData(data))
          } else if (type === JOB_TYPE_BULK_BID_OP) {
            dispatch(saveBidOpData(data))
          } else if (type === JOB_TYPE_BULK_ST_OP) {
            dispatch(saveStOpData(data))
          } else if (type === JOB_TYPE_BULK_NEGATIVE) {
            dispatch(saveNegativeFinderData(data))
          } else if (type === JOB_TYPE_BULK_PLACEMENT_OP) {
            dispatch(savePlacementOpData(data))
          } else if (type === JOB_TYPE_BULK_ADVANCED_NEGATIVE) {
            dispatch(saveAdvancedNegativeData(data))
          } else {
            clearInterval(statusCheckerInterval.current)
            statusCheckerInterval.current = null
            return
          }

          setHasResults(true)
          setIsCampaignsExpanded(false)
          clearInterval(statusCheckerInterval.current)
          statusCheckerInterval.current = null
        }
      }).catch((error) => {
        clearInterval(statusCheckerInterval.current)
        statusCheckerInterval.current = null
      })
    }, 5000)
  }

  const campaignsById = useMemo(() => (
    getAssociativeCampaigns(campaignsWithHistory)
  ), [campaignsWithHistory])

  const adgroupNamesById = useMemo(() => {
    const namesById = {}
    adgroups.forEach((adgroup) => {
      namesById[adgroup.adgroup_id] = adgroup.name
    })
    return namesById
  }, [adgroups])

  const [availableModules, availableActions] = useMemo(() => {
    if (!isNonEndemicAccount) {
      return [moduleList, actionList]
    }
    return [
      moduleList.filter(module => (
        ![
          MODULE_SKU,
          MODULE_ST,
          MODULE_NEGATIVE,
          MODULE_PLACEMENT,
        ].includes(module.key)
      )),
      actionList.filter(action => (
        ![
          BULK_ACTION_LOW_CONVERSION,
          BULK_ACTION_NO_SALES,
          BULK_ACTION_NEGATIVES,
        ].includes(action.value)
      )),
    ]
  }, [isNonEndemicAccount])

  const renderTopSection = () => {
    let searchDisabled = false
    let buttonLabel = 'Continue'
    let isModuleSelected = true
    if (!activeModule) {
      searchDisabled = true
      isModuleSelected = false
    } else if (!(campaignIdsSelected || []).length) {
      searchDisabled = true
      buttonLabel = 'Select Campaigns to Continue'
    } else if (activeModule === MODULE_ADVANCED && !activeAction) {
      searchDisabled = true
      buttonLabel = 'Choose Action'
    }

    let dropdownLabel = 'Actions'
    if (activeModule === MODULE_ADVANCED) {
      if (activeAction) {
        const active = actionList.find(module => module.value === activeAction)
        if (active) {
          dropdownLabel = active.label
        }
      }
    }
    return (
      <>
        {
          activeModule === MODULE_ADVANCED && (
            <Dropdown title={dropdownLabel}>
              {
                availableActions.map(action => (
                  <Dropdown.Item
                    key={action.value}
                    active={action.value === activeAction}
                    onSelect={() => { setActiveAction(action.value); setHasResults(false) }}
                  >
                    { action.label }
                    <CustomTooltip placement="right">
                      { action.description }
                    </CustomTooltip>
                  </Dropdown.Item>
                ))
              }
            </Dropdown>
          )
        }
        {
          ((!hasResults || isCampaignsExpanded) && isModuleSelected) && (
            <button
              type="button"
              className="btn btn-blue"
              disabled={searchDisabled}
              onClick={handleFind}
            >
              { buttonLabel }
            </button>
          )
        }
      </>
    )
  }

  const renderModuleSelector = (dropdownLabel) => {
    return (
      <div className="module-selector-container">
        <div className="module-selector-left">
          <Dropdown title={dropdownLabel} renderTitle={children => {
            return (
              <Button appearance="primary">
                {children}
                <span className="toggle-caret" />
              </Button>
            )
          }}>
            {
              availableModules.map(module => (
                <Dropdown.Item
                  key={module.key}
                  active={module.key === activeModule}
                  onSelect={() => { handleModuleSelect(module) }}
                >
                  { module.name }
                  <CustomTooltip placement="right">
                    { module.description }
                  </CustomTooltip>
                </Dropdown.Item>
              ))
            }
          </Dropdown>
          { renderTopSection() }
        </div>
        <VideoLink
          modalTitle={tutorialList[activeModule].title}
          videoList={tutorialList[activeModule].videoList}
        />
      </div>
    )
  }

  const renderCampaignSelection = () => {
    if (hasResults && !isCampaignsExpanded) {
      return (
        <div className="campaign-selection-state">
          <strong>{ (campaignIdsSelected || []).length }</strong> campaigns selected.
          <a
            href="#"
            onClick={(event) => { event.preventDefault(); setIsCampaignsExpanded(true) }}
          >
            Change
          </a>
        </div>
      )
    }
    return null
  }

  const renderResults = () => {
    if (!hasResults) {
      return null
    }

    if (activeModule === MODULE_SKU) {
      return (
        <SkuOpResult
          campaignsById={campaignsById}
          adgroupNamesById={adgroupNamesById}
          onChangeDate={handleDateChange}
        />
      )
    }
    if (activeModule === MODULE_TARGET) {
      return (
        <TargetOpResult
          campaignsById={campaignsById}
          adgroupNamesById={adgroupNamesById}
          isNonEndemicAccount={isNonEndemicAccount}
          onChangeDate={handleDateChange}
        />
      )
    }
    if (activeModule === MODULE_ST) {
      return (
        <StOpResult
          newTermOnly={newTermOnly}
          stFilter={selectedStFilter}
          campaignsById={campaignsById}
          adgroupNamesById={adgroupNamesById}
          onChangeNewTermOnly={handleChangeNewTermOnly}
          onChangeStFilter={setSelectedStFilter}
          onChangeDate={handleDateChange}
        />
      )
    }
    if (activeModule === MODULE_NEGATIVE) {
      return (
        <NegativeResult
          hideKeywords={hideKeywords}
          hideAsins={hideAsins}
          removeNumbers={removeNumbers}
          removePreps={removePreps}
          currentTargetAcos={targetAcos}
          campaignsById={campaignsById}
          adgroupNamesById={adgroupNamesById}
          onChangeHideKeywords={handleHideKeywordsChange}
          onChangeHideAsins={setHideAsins}
          onChangeRemoveNumbers={setRemoveNumbers}
          onChangeRemovePreps={setRemovePreps}
          onChangeDate={handleDateChange}
          onChangeTargetAcos={handleTargetAcosChange}
        />
      )
    }
    if (activeModule === MODULE_PLACEMENT) {
      return (
        <PlacementOpResult
          campaignsById={campaignsById}
          onChangeDate={handleDateChange}
        />
      )
    }
    if (activeModule === MODULE_ADVANCED) {
      if (activeAction !== BULK_ACTION_NEGATIVES) {
        return (
          <TargetOpResult
            activeModule={activeAction}
            campaignsById={campaignsById}
            adgroupNamesById={adgroupNamesById}
            isNonEndemicAccount={isNonEndemicAccount}
            onChangeDate={handleDateChange}
          />
        )
      }

      return (
        <AdvancedNegativeResult
          campaignsById={campaignsById}
          adgroupNamesById={adgroupNamesById}
        />
      )
    }
    return null
  }

  let dropdownLabel = 'Choose Module'
  if (activeModule) {
    const active = moduleList.find(module => module.key === activeModule)
    if (active) {
      dropdownLabel = active.name
    }
  }

  return (
    <div className={`bulk-engine-container bulk-op-container${(isLoading || isLoadingCampaigns) ? ' loading' : ''}`}>
      { (isLoading || isLoadingCampaigns) && <LoaderComponent /> }
      <div className="module-description">
        Choose Module, Select Campaigns and Ad Groups, Refine Filters and Go!
      </div>
      { renderModuleSelector(dropdownLabel) }
      { renderCampaignSelection() }
      <CampaignTableComponent
        className={hasResults && !isCampaignsExpanded ? 'hidden' : ''}
        hideSb={activeModule === MODULE_SKU || activeModule === MODULE_PLACEMENT}
        hideSd={activeModule === MODULE_ST || activeModule === MODULE_PLACEMENT}
        fromBulkEngine
        showNotedCampaigns={showNotedCampaigns}
        initialRender={initialRender}
        onLoadStart={() => setIsLoadingCampaigns(true)}
        onLoadEnd={() => setIsLoadingCampaigns(false)}
      />
      { renderResults() }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={dropdownLabel}
            onApply={handleFilterApply}
            onClose={() => { setCurrentFilterName('') }}
          />
        )
      }
    </div>
  )
}

export default BulkOp
