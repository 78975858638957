import { callGet, callPost } from '../../services/axios'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  GET_ALL_TAGS_START,
  GET_ALL_TAGS_SUCCEED,
  GET_ALL_TAGS_FAIL,
  GET_ALL_TAGS_FOR_CAMPAIGN_START,
  GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED,
  GET_ALL_TAGS_FOR_CAMPAIGN_FAIL,
  REMOVE_TAGS_FROM_CAMPAIGN_START,
  REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED,
  REMOVE_TAGS_FROM_CAMPAIGN_FAIL,
  GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED,
  DELETE_TAG_SUCCEED,
  SELECT_TAGS,
  CREATE_TAGS_FOR_CAMPAIGNS,
} from '../actionTypes/tag'

export const getAllTags = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  dispatch({ type: GET_ALL_TAGS_START})

  return callGet('/tag/getTags', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    dispatch({
      type: GET_ALL_TAGS_SUCCEED,
      data: response.data
    })
  }).catch(() => {
    dispatch({ type: GET_ALL_TAGS_FAIL })
  })
}

export const getAllTagsForCampaign = (accessToken, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  dispatch({ type: GET_ALL_TAGS_FOR_CAMPAIGN_START})

  return callGet('/tag/getTagsForCampaign', accessToken, {
    userId: currentUserId,
    campaignId
  }).then((response) => {
    dispatch({
      type: GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED,
      data: response.data
    })
  }).catch(() => {
    dispatch({ type: GET_ALL_TAGS_FOR_CAMPAIGN_FAIL})
  })
}

export const createAndAddTagToCampaigns = (accessToken, tagName, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/createAndAddTagToCampaigns', {
    userId: currentUserId,
    tagName,
    campaignIds,
  }, accessToken).then((response) => {
    dispatch({
      type: CREATE_TAGS_FOR_CAMPAIGNS,
      data: {
        tagId: response.data.id,
        tagName: response.data.tag_name,
        campaignIds,
      }
    })
    return response.data
  })
}

export const addTagToCampaign = (accessToken, tagId, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/addTagToCampaign', {
    userId: currentUserId,
    tagId,
    campaignId
  }, accessToken)
}

export const removeTagsfromCampaign = (accessToken, tagIds, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  dispatch({ type: REMOVE_TAGS_FROM_CAMPAIGN_START})

  return callPost('/tag/removeTagsfromCampaign', {
    userId: currentUserId,
    tagIds,
    campaignId
  }, accessToken).then(() => {
    dispatch({
      type: REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED
    })
  }).catch(() => {
    dispatch({ type: REMOVE_TAGS_FROM_CAMPAIGN_FAIL})
  })
}

export const getBulkTagsForCampaigns = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/getTagsForCampaigns', {
    userId: currentUserId,
    campaignIds
  }, accessToken).then((response) => {
    dispatch({
      type: GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED,
      data: response.data
    })
  })
}

/**
 *
 * @param {Object} bulkTags {
 *                            [campaign_id] : [...tags_array]
 *                          }
 */
export const createBulkTagsForCampaigns = (accessToken, bulkTags) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/addTagsToCampaigns', {
    userId: currentUserId,
    bulkTags
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Tags updated successfully.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update Tags.',
    })
  })
}

export const removeBulkTagsFromCampaigns = (accessToken, campaignIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/removeTagsFromCampaigns', {
    userId: currentUserId,
    campaignIds
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'All tags are deleted successfully.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to delete Tags.',
    })
  })
}

export const deleteTagPermanent = (accessToken, tagId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/tag/deleteTag', {
    userId: currentUserId,
    tagId
  }, accessToken).then((response) => {
    dispatch({
      type: DELETE_TAG_SUCCEED,
      data: tagId
    })
    return response.data
  })
}

export const selectTags = (tags) => (dispatch) => {
  dispatch({
    type: SELECT_TAGS,
    data: tags,
  })
}