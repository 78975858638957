import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'rsuite'
import { CSVLink } from 'react-csv'

import SearchBox from '../CommonComponents/SearchBox'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import DspColumnSettingModal from './DspColumnSettingModal'
import DspTableCell from './DspTableCell'

import { applyCampaignColumn } from '../../redux/actions/dsp'

const { Column, HeaderCell, Cell, Pagination } = Table

const DspTable = ({ columns, data, loading, autoHeight,
  sortColumn, sortType = 'asc', searchField, onAdvertiserChange = () => {} }) => {
  const dispatch = useDispatch()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const campaignColumns = useSelector(state => state.dsp.campaignColumns)

  const [page, setPage] = useState(1)
  const [displayLength, setDisplayLength] = useState(10)
  const [customSortColumn, setCustomSortColumn] = useState(sortColumn)
  const [customSortType, setCustomSortType] = useState(sortType)
  const [showColumnSetting, setShowColumnSetting] = useState(false)
  const [keyword, setKeyword] = useState('')

  const handleChangeLength = (value) => {
    setPage(1)
    setDisplayLength(value)
  }

  const rowData = useMemo(() => {
    return data.filter(v => {
      if (keyword && !v[searchField].toLowerCase().includes(keyword.toLowerCase())) {
        return false
      }
      return true
    }).sort((a, b) => {
      if (customSortColumn === 'advertiser' || customSortColumn === 'order') {
        return customSortType === 'asc'
          ? a[customSortColumn].localeCompare(b[customSortColumn])
          : b[customSortColumn].localeCompare(a[customSortColumn])
      }

      return customSortType === 'asc'
        ? a[customSortColumn] - b[customSortColumn]
        : b[customSortColumn] - a[customSortColumn]
    }).filter((_, i) => {
      const start = displayLength * (page - 1);
      const end = start + displayLength;
      return i >= start && i < end;
    })
  }, [displayLength, page, data, customSortColumn, customSortType, keyword]) // eslint-disable-line

  const handleSortColumn = (column, type) => {
    setCustomSortColumn(column)
    setCustomSortType(type)
  }

  const handleApplyColumnSetting = (columns) => {
    dispatch(applyCampaignColumn(columns))
    setShowColumnSetting(false)
  }

  const handleClickAdvertiser = (advertiser) => {
    onAdvertiserChange({
      advertiserId: advertiser.advertiser_id,
      name: advertiser.name,
    })
  }

  const dataToExport = useMemo(() => {
    return rowData.map(d => {
      const exportedData = Object.assign({}, d)
      delete exportedData['advertiser_id']
      delete exportedData['order_id']
      delete exportedData['line_item_id']
      return exportedData
    })
  }, [rowData])

  return (
    <div className={`dsp-table ${loading ? 'loading' : ''}`}>
      <div className="dsp-table-header">
        <SearchBox
          className="dsp-table-search-box"
          placeholder="Type to Search ..."
          keyword={keyword}
          onChange={setKeyword}
        />
        <div>
          <CSVLink
            data={dataToExport}
            filename="DSP Advertiser.csv"
            className="btn btn-white btn-export-csv"
            title="Export to CSV"
          >
            Export to CSV
          </CSVLink>
          <button
            type="button"
            className="btn btn-white"
            onClick={() => { setShowColumnSetting(true) }}
          >
            Column Settings
          </button>
        </div>
      </div>
      <Table
        data={rowData}
        loading={loading}
        autoHeight={autoHeight}
        bordered
        minHeight={500}
        sortColumn={customSortColumn}
        sortType={customSortType}
        renderLoading={() => loading && <LoaderComponent/> }
        onSortColumn={handleSortColumn}
      >
        {
          (columns || []).map(c => (
            <Column
              key={c.key}
              width={c.width ? c.width : 140}
              fixed={c.fixed}
              resizable={c.resizable}
              sortable
            >
              <HeaderCell className="table-header-cell">{c.label || c.name}</HeaderCell>
              <Cell dataKey={c.key}>
                {
                  rowData => {
                    if (c.key === 'advertiser') {
                      return (
                        <div className="col-advertiser" onClick={() => { handleClickAdvertiser(rowData) }}>
                          { rowData.advertiser }
                        </div>
                      )
                    } else {
                      return (
                        <DspTableCell
                          record={rowData}
                          column={c}
                          currencySign={currencySign}
                          currencyRate={currencyRate}
                        />
                      )
                    }
                  }
                }
              </Cell>
            </Column>
          ))
        }
      </Table>
      <Pagination
        lengthMenu={[
          {
            value: 10,
            label: 10
          },
          {
            value: 20,
            label: 20
          },
          {
            value: 50,
            label: 50
          }
        ]}
        activePage={page}
        displayLength={displayLength}
        total={data.length}
        onChangePage={setPage}
        onChangeLength={handleChangeLength}
      />
      {
        showColumnSetting && (
          <DspColumnSettingModal
            columns={campaignColumns}
            onApply={handleApplyColumnSetting}
            onClose={() => { setShowColumnSetting(false) }}
          />
        )
      }
    </div>
  )
}

export default DspTable
