import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../../../CommonComponents/ToastComponent/toast'
import CustomTable from '../../../../CommonComponents/CustomTableComponent'

import {
  getNegativeKeywords,
  archiveNegativeKeywords,
} from '../../../../../redux/actions/campaignDetail'

import { copyToClipboard } from '../../../../../services/helper'
import { NEGATIVE_MATCH_TYPE_PHRASE } from '../../../../../utils/defaultValues'

const NegativeKeywordSection = ({
  campaignId,
  campaignType,
  onNegativesAdd,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [selectedNegatives, setSelectedNegatives] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isArchiving, setIsArchiving] = useState(false)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      const data = await dispatch(getNegativeKeywords(
        accessToken,
        campaignId,
        campaignType,
      ))
      setNegativeKeywords(data)
      setIsLoading(false)
    })()
  }, [campaignId]) // eslint-disable-line

  const handleCopy = () => {
    const negatives = negativeKeywords.filter(negative => (
      selectedNegatives.indexOf(negative.keywordId) !== -1
    )).map(negative => negative.keywordText.trim())

    copyToClipboard(negatives.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedNegatives.length} negative keyword${selectedNegatives.length > 1 ? 's' : ''}.`
    })
  }

  const handleRemove = async () => {
    const negatives = negativeKeywords.filter(negative => (
      selectedNegatives.indexOf(negative.keywordId) !== -1
    ))
    const accessToken = await getAccessTokenSilently()
    setIsArchiving(true)
    const archivedIds = await dispatch(archiveNegativeKeywords(
      accessToken,
      campaignType,
      negatives,
    ))
    setNegativeKeywords(negativeKeywords.filter(negative => (
      !archivedIds.includes(negative.keywordId.toString())
    )))
    setIsArchiving(false)
  }

  const renderAction = () => {
    if (!selectedNegatives.length) {
      return null
    }

    return (
      <>
        <button type="button" className="btn btn-red" onClick={() => { handleRemove() }}>
          Remove from Negatives
        </button>
        <button type="button" className="btn btn-green" onClick={() => { handleCopy() }}>
          Copy Keywords
        </button>
      </>
    )
  }

  const renderNegative = negative => (
    <>
      <div className="table-col col-keyword">
        { negative.keywordText }
      </div>
      {
        campaignType === 'sp' && (
          <div className="table-col col-level">
            { typeof negative.campaign_level !== 'undefined' ? 'Campaign Level' : 'Ad Group Level' }
          </div>
        )
      }
      <div className="table-col col-match-type">
        {
          negative.matchType === NEGATIVE_MATCH_TYPE_PHRASE
          ? 'Negative Phrase'
          : 'Negative Exact'
        }
      </div>
    </>
  )

  return (
    <div className="section">
      <div className="section-header">
        <h4>Negative Keywords</h4>
        <button
          type="button"
          className="btn btn-blue"
          onClick={onNegativesAdd}
        >
          Add Negative Keywords
        </button>
      </div>
      <CustomTable
        isLoading={isLoading || isArchiving}
        className="table-negatives"
        records={negativeKeywords || []}
        idField="keywordId"
        searchFields={['keywordText']}
        selectedRecords={selectedNegatives}
        renderRecord={renderNegative}
        renderTopRight={renderAction}
        onChange={setSelectedNegatives}
      >
        <div className="table-col col-keyword">Keyword</div>
        {
          campaignType === 'sp' && (
            <div className="table-col col-level">Campaign/Ad Group Level</div>
          )
        }
        <div className="table-col col-match-type">Match Type</div>
      </CustomTable>
    </div>
  )
}

export default NegativeKeywordSection
