import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from 'rsuite'
import Select from 'react-select'
import { isBefore, startOfDay } from 'date-fns'

import CustomTooltip from '../../CommonComponents/CustomTooltip'
import BrandSelector from './BrandSelector'
import { getBudgetLimits, parseDate } from '../../../services/helper'
import { getPortfolios } from '../../../redux/actions/portfolio'

export const BUDGET_TYPE_DAILY = 'DAILY'
export const BUDGET_TYPE_LIFETIME = 'LIFETIME'

const sbBudgetTypeOptions = [
  { value: BUDGET_TYPE_DAILY, label: 'Daily' },
  { value: BUDGET_TYPE_LIFETIME, label: 'Lifetime' },
]

const BasicInfo = ({
  type,
  isNonEndemicAccount = false,
  info,
  countryCode,
  isSeller,
  onChange,
  onBrandChange,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [portfolios, setPortfolios] = useState([])

  useEffect(() => {
    let abortCtrl
    if (!isNonEndemicAccount) {
      abortCtrl = new AbortController();

      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getPortfolios(
          accessToken,
          abortCtrl.signal,
        ))
        setPortfolios(response)
        setIsLoading(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, []) // eslint-disable-line

  const renderPortfolio = () => {
    if (isNonEndemicAccount) {
      return (
        <div className="field-wrapper">
        </div>
      )
    }

    return (
      <div className="field-wrapper">
        <div className="field-name">
          Portfolio
          <CustomTooltip placement="right">
            <p>Portfolios are a group of campaigns that can have a budget cap.</p>
            <p>Portfolios enable you to group and organize campaigns.</p>
            <p>We recommend selecting a portfolio that best aligns with your marketing needs.</p>
          </CustomTooltip>
        </div>
        <Select
          classNamePrefix="portfolio-selector"
          options={portfolios}
          isLoading={isLoading}
          getOptionValue={option => option.portfolio_id}
          getOptionLabel={option => option.name}
          value={info.portfolio}
          onChange={(option) => { onChange('portfolio', option) }}
        />
      </div>
    )
  }

  const renderBudget = () => {
    let isDaily = true
    if (type === 'sb') {
      isDaily = info.budgetType === BUDGET_TYPE_DAILY
    }

    const budget = parseFloat(info.dailyBudget)
    let error = null
    if (isNaN(budget)) {
      error = 'Please enter a campaign budget.'
    } else {
      const [min, max] = getBudgetLimits(type, countryCode, isSeller, isDaily)
      if (budget < min || budget > max) {
        error = `The daily budget range is from ${min} to ${max.toLocaleString()}.`
      }
    }

    if (type === 'sp' || type === 'sd') {
      return (
        <>
          <div className="field-wrapper">
            <div className="field-name">
              Daily Budget
              <CustomTooltip placement="right">
                <p>The amount that you're willing to spend on this campaign each day.</p>
                <p>If the campaign spends less than your daily budget, that amount can be used
                to increase your daily budget up to 25% on other days of the calendar month.</p>
              </CustomTooltip>
            </div>
            <input
              type="number"
              min="0"
              value={info.dailyBudget}
              onChange={(event) => { onChange('dailyBudget', event.target.value) }}
            />
            {
              error !== null && (
                <div className="warning-message">{ error }</div>
              )
            }
          </div>
          <div className="field-wrapper">
            {
              !isNonEndemicAccount && (
                <>
                  <div className="field-name">
                    Target ACoS
                  </div>
                  <input
                    type="number"
                    min="0"
                    value={info.acos}
                    onChange={(event) => { onChange('acos', event.target.value) }}
                  />
                </>
              )
            }
          </div>
        </>
      )
    }

    return (
      <>
        <div className="field-wrapper">
          <div className="field-name">
            Budget
            <CustomTooltip placement="right">
              <p>Your budget determines how much you will spend on this campaign.
                You can edit your campaign to change your budget anytime.</p>
              <p>
                <strong>Daily:</strong> the amount that you are willing to spend on this campaign each day.
                If the campaign spends less than your daily budget, the leftover amount can be used
                to increase your daily budget up to 25% on other days of the calendar month.
              </p>
              <p>
                <strong>Lifetime:</strong> The total amount that you are willing to spend on this campaign.
              </p>
            </CustomTooltip>
          </div>
          <input
            type="number"
            min="0"
            value={info.dailyBudget}
            onChange={(event) => { onChange('dailyBudget', event.target.value) }}
          />
          {
            error !== null && (
              <div className="warning-message">{ error }</div>
            )
          }
        </div>
        <div className="field-wrapper">
          <div className="field-name">&nbsp;</div>
          <Select
            options={sbBudgetTypeOptions}
            value={sbBudgetTypeOptions.find(data => data.value === info.budgetType)}
            onChange={(option) => { onChange('budgetType', option.value) }}
          />
        </div>
      </>
    )
  }

  let errorName = null
  if (!info.name) {
    errorName = 'Please enter a campaign name.'
  } else {
    // The maximum length of a campaign name is 128 characters
    // for sellers and 116 characters for vendors.
    let limit
    if (isSeller) {
      limit = 128
    } else {
      limit = 116
    }
    if (info.name.length > limit) {
      errorName = `Campaign name should not be longer than ${limit} characters.`
    }
  }

  return (
    <div className="section-container">
      <div className="field-row">
        <div className="field-wrapper">
          <div className="field-name">
            Name
            <CustomTooltip placement="right">
              <p>A campaign name is only visible to you, so choose a name
              that you can easily identify and refer back to later.</p>
            </CustomTooltip>
          </div>
          <input
            type="text"
            value={info.name}
            onChange={(event) => { onChange('name', event.target.value) }}
          />
          {
            errorName !== null && (
              <div className="warning-message">{ errorName }</div>
            )
          }
        </div>
        { renderPortfolio() }
      </div>
      <div className="field-row">
        { renderBudget() }
        <div className="field-wrapper">
          <div className="field-name">
            Start Date
            <CustomTooltip placement="right">
              <p>This is the date your campaign will start.</p>
              <p>You can set a future start date to launch your ad campaign at a later date.</p>
            </CustomTooltip>
          </div>
          <DatePicker
            value={info.startDate}
            format="MMM D, YYYY"
            oneTap
            disabledDate={date => isBefore(date, startOfDay(new Date())) }
            onChange={(date) => { onChange('startDate', date) }}
          />
        </div>
        <div className="field-wrapper">
          <div className="field-name">
            End Date
            <CustomTooltip>
              <p>This is the date your campaign will end.</p>
              <p>To ensure your ads are always active so you don't miss impressions or clicks,
              choose "No end date." </p>
              <p>You can extend, shorten, or end your campaign at any time while it's running.</p>
            </CustomTooltip>
          </div>
          <DatePicker
            value={info.endDate}
            format="MMM D, YYYY"
            placeholder="No end date"
            oneTap
            disabledDate={date => isBefore(date, parseDate(info.startDate)) }
            onChange={(date) => { onChange('endDate', date) }}
          />
        </div>
      </div>
      {
        type === 'sb' && isSeller && (
          <div className="field-row">
            <div className="field-wrapper">
              <div className="field-name">
                Brand
                <CustomTooltip placement="right">
                  <p>Choose a brand for management of your campaign.</p>
                </CustomTooltip>
              </div>
              <BrandSelector onChange={onBrandChange} />
              </div>
            <div className="field-wrapper">
            </div>
          </div>
        )
      }
    </div>
  )
}

export default BasicInfo
