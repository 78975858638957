import React, { useState } from 'react'

import { toast } from '../../CommonComponents/ToastComponent/toast'
import NegativeTargetingModal from './NegativeTargetingModal'

const NegativeTargetingSection = ({
  className = '',
  negativeTargetings,
  targetingType,
  forNegatives = false,
  onChange,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [asins, setAsins] = useState('')

  const handleRemove = (product) => {
    onChange(negativeTargetings.filter(item => JSON.stringify(item) !== JSON.stringify(product)))
  }

  const handleClickAdd = () => {
    if (asins === '') {
      toast.show({
        title: 'Warning',
        description: 'Please enter the negative ASIN text.',
      })
      return
    }

    const asinList = asins.split('\n').map(asin => ({
      name: asin.trim().toUpperCase(),
      asin: asin.trim().toUpperCase(),
      type: 'product',
      isTargeted: true
    }))

    onChange([
      ...asinList,
      ...negativeTargetings,
    ])
  }

  const renderNegativeTargetings = () => {
    if (!negativeTargetings.length) {
      return (
        <div className="no-product-desc">
          {
            forNegatives ? 'No negatives added.' : 'No negative product added.'
          }
        </div>
      )
    }

    return (
      <div className="targeting-list">
        {
          negativeTargetings.map(target => (
            <div
              key={target.type === 'product' ? target.asin : target.id}
              className="targeting-item"
            >
              <div className="targeting-info">
                <div className="targeting-name">
                  { target.type === 'product' ? 'Product' : 'Brand' }:&nbsp;
                  { target.name }
                </div>
                {
                  target.type === 'product' && (
                    <div className="targeting-meta">
                      ASIN: { target.asin }
                    </div>
                  )
                }
              </div>
              <div className="targeting-action">
                <span
                  className="close-icon"
                  title="Remove"
                  onClick={() => { handleRemove(target) }}
                >
                  &times;
                </span>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className={`section-container ${className}`}>
      <div className="section-title">
        <span>
          { forNegatives ? 'Add Negatives' : 'Optional: Negative Product Targeting' }
        </span>
        <div>
          <button type="button" className="btn btn-blue" onClick={() => setOpenModal(true)}>
            Add Negative ASINs/Brands
          </button>
          {
            negativeTargetings.length > 0 && (
              <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
                Remove All
              </button>
            )
          }
        </div>
      </div>
      {
        !forNegatives && (
          <div className="section-note">
            Negative product targeting prevents your ads from displaying when a shopper's search
            matches your negative product selections. This helps exclude irrelevant searches,
            reducing your advertising cost.
          </div>
        )
      }
      <textarea
        placeholder="Enter negative ASINs separated by a new line."
        rows={5}
        value={asins}
        onChange={(e) => { setAsins(e.target.value) }}
      />
      <button
        type="button"
        className="btn btn-blue"
        onClick={handleClickAdd}
      >
        Add Negative ASINs
      </button>
      { renderNegativeTargetings() }
      <NegativeTargetingModal
        show={openModal}
        negativeTargetings={negativeTargetings}
        targetingType={targetingType}
        onChange={onChange}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default NegativeTargetingSection
