import React, { useMemo } from 'react'
import Select from 'react-select'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import FieldNumber from './FieldNumber'

// FIXME: Store integer constants, not strings.
const actionList = [
  { value: 'Pause Keyword/Target', label: 'Pause Keyword/Target' },
  { value: 'Lower Bid By %', label: 'Lower Bid By %' },
  { value: 'Lower Bid By $', label: 'Lower Bid By $' },
  { value: 'raise_by_percent', label: 'Raise Bid By %' },
  { value: 'raise_by_absolute', label: 'Raise Bid By $' },
  { value: 'Lower Bid to Max CPC', label: 'Apply Genius Bid' },
  { value: 'cpc', label: 'Ave CPC' },
  { value: 'cpc_raise_by_absolute', label: 'Ave CPC + ($)' },
  { value: 'cpc_lower_by_absolute', label: 'Ave CPC - ($)' },
  { value: 'cpc_raise_by_percent', label: 'Ave CPC + (%)' },
  { value: 'cpc_lower_by_percent', label: 'Ave CPC - (%)' },
]

const OpKeywordAdvancedAction = ({ prefix, settings, noGeniusBid, onChange }) => {
  const actionField = `${prefix}action`

  const filteredActionList = useMemo(() => {
    if (!noGeniusBid) {
      return actionList
    }
    return actionList.filter(action => action.value !== 'Lower Bid to Max CPC')
  }, [noGeniusBid])

  const action = filteredActionList.find(option => (
    option.value === settings[actionField]
  ))

  const handleActionChange = (item) => {
    onChange(actionField, item.value)
  }

  return (
    <>
      <div className="field-wrapper">
        <div className="field-name">
          Action
          {
            settings[actionField] === 'Lower Bid to Max CPC' && (
              <CustomTooltip>
                <p>Genius Bid takes into account your historical performance,
                target ACoS, Costs of Goods, and more.</p>
                <p>Genius Bid suggestions could be higher or lower than your current bid price
                based on the target ACoS you set.</p>
              </CustomTooltip>
            )
          }
        </div>
        <Select
          classNamePrefix="action-selector"
          options={filteredActionList}
          placeholder="Choose action"
          value={action}
          onChange={handleActionChange}
        />
      </div>
      {
        (settings[actionField] === 'Lower Bid By %'
          || settings[actionField] === 'Lower Bid By $'
          || settings[actionField] === 'raise_by_percent'
          || settings[actionField] === 'raise_by_absolute'
          || settings[actionField] === 'cpc_raise_by_absolute'
          || settings[actionField] === 'cpc_lower_by_absolute'
          || settings[actionField] === 'cpc_raise_by_percent'
          || settings[actionField] === 'cpc_lower_by_percent')
        ? (
          <FieldNumber
            field={`${prefix}action_value`}
            settings={settings}
            onChange={onChange}
          />
        ) : (
          <div className="field-wrapper">
          </div>
        )
      }
    </>
  )
}

export default OpKeywordAdvancedAction
