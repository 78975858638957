import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Toggle } from 'rsuite'
import * as Icon from 'react-icons/fi'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableCell from '../CommonComponents/TableCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'

import BulkResultContainer from '../BulkResultContainer'
import { MODULE_NAME_PT_EX } from './PtExSection'
import TargetAddModal from './TargetAddModal'

import {
  tableSorter,
  copyToClipboard,
  calcDerivedMetrics,
  getAmazonAsinLink,
  getExportValueForColumn,
  groupRecords,
  getAssociativeCampaigns,
} from '../../services/helper'

import { bulkNTBColumnList, MODULE_NAME_PT_EX_ASIN } from '../../utils/defaultValues'
import { selectCurrentAccount } from '../../redux/reducers/header'

const initialColumns = [
  { key: 'title', name: 'Product', className: 'col-product' },
  { key: 'asin', name: 'ASIN', className: 'col-asin' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'title', name: 'Product', className: 'col-product' },
  { key: 'asin', name: 'ASIN', className: 'col-asin' },
]

export const FILTER_NAME_PT_EX_ASIN = 'ptExAsin'

const PtExAsinResult = ({ ptExData, newAsinOnly,
  onChangeNewAsinOnly, onChangeDate, onApplyFilter }) => {
  const currentAccount = useSelector(selectCurrentAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const [groupMode, setGroupMode] = useState(false)
  const [asins, setAsins] = useState([])
  const [groupedAsins, setGroupedAsins] = useState([])
  const [selectedAsins, setSelectedAsins] = useState([])
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [targetsPayload, setTargetsPayload] = useState([])
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [origFilters, setOrigFilters] = useState({})

  // Filter found search terms.
  useEffect(() => {
    if (!ptExData.length) {
      return
    }

    const campaignsById = getAssociativeCampaigns(campaignsWithHistory)

    const filteredPTs = ptExData.map((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}
      return {
        ...calcDerivedMetrics(record),
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targetingType: campaignDetail.targetingType || '',
      }
    })

    setAsins(filteredPTs)
  }, [ptExData, campaignsWithHistory])

  useEffect(() => {
    if (groupMode) {
      setGroupedAsins(
        groupRecords(
          asins,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedAsins([])
    }
  }, [asins, groupMode])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const availableColumns = campaignTableColumns.filter(c1 => bulkNTBColumnList.find(c2 => c2.key === c1.key))
    return [
      [...initialColumns, ...availableColumns],
      [...initialColumnsGroup, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns])

  const handleCopy = () => {
    const asinList = asins.filter(record => (
      selectedAsins.indexOf(record.target_id) !== -1
    )).map(record => record.asin)

    copyToClipboard([...new Set(asinList)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${asinList.length} ASIN${asinList.length > 1 ? 's' : ''}.`
    })
  }

  const handleAddToExisting = () => {
    setTargetsPayload(asins.filter(record => (
      selectedAsins.indexOf(record.target_id) !== -1
    )).map(record => ({
      target: record.asin,
      cpc: record.cpc,
    })))
    setIsAddModalVisible(true)
  }

  const handleFilterRefine = () => {
    setCurrentFilterName(FILTER_NAME_PT_EX_ASIN)
    setOrigFilters((filterValues || {})[FILTER_NAME_PT_EX_ASIN] || {})
  }

  const handleFilterApply = (values) => {
    // When acos values are changed, call API again.
    if (parseFloat(values.acosMin || 0) !== parseFloat(origFilters.acosMin || 0)
      || parseFloat(values.acosMax || 0) !== parseFloat(origFilters.acosMax || 0)) {
      onApplyFilter(values)
    }
    setCurrentFilterName('')
  }

  const handleFilterValidate = (values) => {
    const { acosMin, acosMax } = values

    if (acosMin === '' || isNaN(acosMin) || parseFloat(acosMin) < 0
      || acosMax === '' || isNaN(acosMax) || parseFloat(acosMax) < 0) {
      return 'Please enter ACoS greater than or equal to 0.'
    }

    if (parseFloat(acosMin) > parseFloat(acosMax)) {
      return 'The start range of ACoS cannot be greater than the end range.'
    }

    return null
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By ASINs"
          onChange={setGroupMode}
        />
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="New ASINs Only"
            checked={newAsinOnly}
            onChange={onChangeNewAsinOnly}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFilterRefine}
          >
            Refine Filter
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedAsins.length) {
      return null
    }

    const targets = asins.filter(record => (
      selectedAsins.indexOf(record.target_id) !== -1
    )).map(record => record.asin)

    return (
      <>
        <button type="button" className="btn btn-green" onClick={handleCopy}>
          Copy
        </button>
        <button type="button" className="btn btn-blue" onClick={handleAddToExisting}>
          Add to Existing Campaigns
        </button>
        <Dropdown
          title="Add to New Campaign"
          placement="bottomEnd"
          toggleClassName="btn-new"
        >
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sp',
            state: {
              targets,
              productTargeting: true,
            },
          }}>
            Sponsored Product Campaign
          </Dropdown.Item>
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sb',
            state: {
              targets,
              productTargeting: true,
            },
          }}>
            Sponsored Brand Campaign
          </Dropdown.Item>
          <Dropdown.Item componentClass={Link} to={{
            pathname: '/campaigns/new/sd',
            state: {
              targets,
            },
          }}>
            Sponsored Display Campaign
          </Dropdown.Item>
        </Dropdown>
      </>
    )
  }

  const renderAsin = record => (
    <>
      <div className="table-col col-product" title={record.title || ''}>
        {
          typeof record.image !== 'undefined' && record.image !== '' && (
            <img
              src={record.image}
              alt={record.title || ''}
            />
          )
        }
        <span className="contents">
          { record.title || '' }
        </span>
      </div>
      <div className="table-col col-asin">
        <span className="contents">
          { record.asin }
        </span>
        <a
          href={getAmazonAsinLink(currentAccount, record.asin)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.FiExternalLink size={16} />
        </a>
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-product">Totals:</div>
      <div className="table-col col-asin" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'title') {
        return record.title || ''
      }
      if (column.key === 'asin') {
        return record.asin
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-product" />
      <div className="table-col col-asin">
        { record.children.length } ASINs
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-product" title={record.title || ''}>
        {
          typeof record.image !== 'undefined' && record.image !== '' && (
            <img
              src={record.image}
              alt={record.title || ''}
            />
          )
        }
        <span className="contents">
          { record.title || '' }
        </span>
      </div>
      <div className="table-col col-asin">
        <span className="contents">
          { record.asin }
        </span>
        <a
          href={getAmazonAsinLink(currentAccount, record.asin)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon.FiExternalLink size={16} />
        </a>
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-product" />
      <div className="table-col col-asin" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <BulkResultContainer>
      <div className="section-label">
        Select ASIN and take appropriate action
      </div>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['title', 'asc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-asins"
            records={groupedAsins}
            idField="campaign_id"
            searchFields={['title', 'asin']}
            selectedRecords={selectedAsins}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_PT_EX_ASIN}
            useFilterModal
            columnEditorId="ptExAsinResult"
            columnList={bulkNTBColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_PT_EX_ASIN}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedAsins}
            onChangeDate={onChangeDate}
            onFilterValidate={handleFilterValidate}
            sorterChild={tableSorter(['title', 'asin'])}
            idFieldChild="target_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            columns={columns}
            defaultSort={['title', 'asc']}
            sorter={tableSorter(['title', 'asin', 'campaignName'])}
            className="table-asins"
            records={asins}
            idField="target_id"
            searchFields={['title', 'asin']}
            selectedRecords={selectedAsins}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={FILTER_NAME_PT_EX_ASIN}
            useFilterModal
            columnEditorId="ptExAsinResult"
            columnList={bulkNTBColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_PT_EX_ASIN}
            getExportData={getExportData}
            renderRecord={renderAsin}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedAsins}
            onChangeDate={onChangeDate}
            onFilterValidate={handleFilterValidate}
          />
        )
      }
      <TargetAddModal
        show={isAddModalVisible}
        targets={targetsPayload}
        forPtEx
        onClose={() => { setIsAddModalVisible(false) }}
      />
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={MODULE_NAME_PT_EX}
            onApply={handleFilterApply}
            onClose={() => { setCurrentFilterName('') }}
            onValidate={handleFilterValidate}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default PtExAsinResult
