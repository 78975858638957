import React, { useState } from 'react'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import SubSection from './SubSection'
import Frequency from './Frequency'
import Lookback from './Lookback'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'
import CheckInput from './CheckInput'
import ExSkus from './ExSkus'
import ExCampaigns from './ExCampaigns'
import ExSTAdgroups from './ExSTAdgroups'

const ExST = ({ campaign, settings, onChange, ...props }) => {
  const [selectedSkus, setSelectedSkus] = useState([])
  const [isCampaignsLoaded, setIsCampaignsLoaded] = useState(false)

  return (
    <SubSection name="Search Term Expansion" {...props}>
      <div className="checkbox-info-wrapper">
        <CheckboxComponent
          label="Automatically pull winning Search Terms from other campaigns into this campaign"
          checked={settings.ex_st_active}
          onChange={(checked) => { onChange('ex_st_active', checked) }}
        />
      </div>
      <Frequency
        disabled={!settings.ex_st_active}
        prefix="ex_st_"
        settings={settings}
        onChange={onChange}
      />
      <Lookback
        disabled={!settings.ex_st_active}
        field="ex_st_lookback"
        settings={settings}
        onChange={onChange}
      />
      <FieldRow disabled={!settings.ex_st_active}>
        <FieldNumber
          label="<strong>Step 1)</strong> Find Search Terms with ACoS between 1 and"
          htmlLabel
          field="ex_st_acos"
          inline
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <ExSkus
        selectedSkus={selectedSkus}
        disabled={!settings.ex_st_active}
        onChange={setSelectedSkus}
      />
      <ExCampaigns
        field="ex_st_campaigns"
        skus={selectedSkus}
        settings={settings}
        disabled={!settings.ex_st_active}
        isLoaded={isCampaignsLoaded}
        onLoad={setIsCampaignsLoaded}
        onChange={onChange}
      />
      <div className={`st-criteria-label ${!settings.ex_st_active || !isCampaignsLoaded ? 'disabled' : ''}`}>
        <strong>Step 4)</strong> Pull Search Terms with the following criteria.
      </div>
      <FieldRow disabled={!settings.ex_st_active || !isCampaignsLoaded}>
        <FieldNumber
          label="Min orders"
          settings={settings}
          field="ex_st_orders"
          tooltip="We'll pull Search Terms from campaigns selected that have at least this many orders over the lookback period."
          onChange={onChange}
        />
        <CheckInput
          label="CTR% higher than"
          field="ex_st_ctr_active"
          inputField="ex_st_ctr"
          settings={settings}
          onChange={onChange}
        />
        <CheckInput
          label="Conversion% higher than"
          field="ex_st_conversion_active"
          inputField="ex_st_conversion"
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow disabled={!settings.ex_st_active || !isCampaignsLoaded}>
        <div className="field-wrapper">
          <div className="checkbox-info-wrapper">
            <CheckboxComponent
              label="New keywords only"
              checked={settings.ex_st_new_only}
              onChange={(checked) => { onChange('ex_st_new_only', checked) }}
            />
            <CustomTooltip>
              <p>If selected, our system will only pull the search terms that are not
                already a keyword in any other campaign.</p>
              <p>This is great if you're looking to expand and find new keyword opportunities.</p>
              <p>Unselect this feature if you are looking to graduate keywords from one campaign to another.</p>
              <p>For example: Unselect this box if you wish to find keywords that are
                performing well in broad match in other campaigns and move to exact match in this campaign.</p>
            </CustomTooltip>
          </div>
        </div>
        <div className="field-wrapper">
          <div className="checkbox-info-wrapper">
            <CheckboxComponent
              label="Add as negative exact in parent campaign"
              checked={settings.ex_st_negate_parent}
              onChange={(checked) => { onChange('ex_st_negate_parent', checked) }}
            />
            <CustomTooltip>
              <p>While adding winning search terms or ASINS into a new campaign,
              you may wish to negate them from the previous campaign.</p>
              <p>If you check this box, we'll use a negative exact at the ad group level
              on the search term/ASIN from the campaign which it was found. </p>
            </CustomTooltip>
          </div>
        </div>
      </FieldRow>
      {
        campaign && (
          <ExSTAdgroups
            campaign={campaign}
            settings={settings}
            disabled={!settings.ex_st_active || !isCampaignsLoaded}
            onChange={onChange}
          />
        )
      }
    </SubSection>
  )
}

export default ExST
