import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  Row,
  Col,
  Input,
  Icon,
  IconButton
} from 'rsuite'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportNoteWidget from './ReportNoteWidget'
import ReportTileWidget from './ReportTileWidget'
import ReportChartWidget from './ReportChartWidget'
import ReportPieChartWidget from './ReportPieChartWidget'
import ReportPerformanceWidget from './ReportPerformanceWidget'
import ComparisonViewSection from './ComparisonViewSection'
import PerformanceSegment from './PerformanceSegment'

import { changeViewReportNameState } from '../../redux/actions/pageGlobal'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import {
  calculateMetricsDifference,
  calculateChartMetric,
  parsePerformanceSummary,
} from '../../services/helper'

import { reportWidgetSuffix } from '../../utils/defaultValues'

const sectionList = [
  'allSponsoredAds',
  'sponsoredProductAds',
  'sponsoredBrandAds',
  'sponsoredDisplayAds',
]

const performanceTypes = [
  'performanceByAdType',
  'performanceByPlacement',
  'performanceByBidType',
  'performanceByMatchType',
]

const AccountOverview = ({
  settings,
  isLoadingSettings,
  showSection,
  noteWidgetView,
  setNoteWidgetView,
  onToggle,
  onSaveSettings,
}) => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencySign = useSelector(state => state.header.currencySign)

  const allSponsoredMetrics = useSelector(state => state.accountHealth.allSponsoredMetrics)
  const allSponsoredPastMetrics = useSelector(state => state.accountHealth.allSponsoredPastMetrics)
  const salesData = useSelector(state => state.accountHealth.salesData)
  const spMetrics = useSelector(state => state.accountHealth.spMetrics)
  const spPastMetrics = useSelector(state => state.accountHealth.spPastMetrics)
  const sbMetrics = useSelector(state => state.accountHealth.sbMetrics)
  const sbPastMetrics = useSelector(state => state.accountHealth.sbPastMetrics)
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)
  const sdPastMetrics = useSelector(state => state.accountHealth.sdPastMetrics)
  const allSponsoredChart = useSelector(state => state.accountHealth.allSponsoredChart)
  const spChart = useSelector(state => state.accountHealth.spChart)
  const sbChart = useSelector(state => state.accountHealth.sbChart)
  const sdChart = useSelector(state => state.accountHealth.sdChart)
  const performanceData = useSelector(state => state.accountHealth.performanceData)
  const spPerformanceData = useSelector(state => state.accountHealth.spPerformanceData)
  const sbPerformanceData = useSelector(state => state.accountHealth.sbPerformanceData)

  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const showReportNameWidget = useSelector(state => state.pageGlobal.showReportNameWidget)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [note, setNote] = useState('')
  const [reportNameView, setReportNameView] = useState(showReportNameWidget)
  const [reportName, setReportName] = useState('')

  const sections = useMemo(() => {
    if (!isNonEndemicAccount) {
      return sectionList
    }
    return ['sponsoredDisplayAds']
  }, [isNonEndemicAccount])

  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setNote(settings.notes_overview || '')
      setReportName(settings.name || '')
    }
  }, [settings, isLoadingSettings])

  const { placementTotal, bidTotal } = useMemo(() => {
    return parsePerformanceSummary(spPerformanceData.placementSummary)
  }, [spPerformanceData])

  const widgets = useMemo(() => {
    if (!reportWidgetSetting) {
      return null
    }

    const widgetsToShow = {
      tiles: {},
      charts: {},
      pieCharts: {},
      performances: {},
      momTableWidget: null,
    }

    sections.forEach((section) => {
      if (!reportWidgetSetting[section]) {
        return
      }

      reportWidgetSetting[section].forEach((widget) => {
        if (!widget.highlighted) {
          return
        }
        // By default, a widget type is tile.
        if (!widget.widgetType || widget.widgetType === 'tile') {
          if (!widgetsToShow.tiles[section]) {
            widgetsToShow.tiles[section] = []
          }
          widgetsToShow.tiles[section].push(widget)
        } else if (widget.widgetType === 'chart') {
          if (!widgetsToShow.charts[section]) {
            widgetsToShow.charts[section] = []
          }
          widgetsToShow.charts[section].push(widget)
        } else if (widget.widgetType === 'pieChart') {
          if (!widgetsToShow.pieCharts[section]) {
            widgetsToShow.pieCharts[section] = []
          }
          widgetsToShow.pieCharts[section].push(widget)
        }
      })
    })

    if (!isNonEndemicAccount) {
      performanceTypes.forEach((type) => {
        if (!reportWidgetSetting[type]) {
          return
        }

        reportWidgetSetting[type].forEach((widget) => {
          if (!widget.highlighted) {
            return
          }
          if (widget.widgetType === 'special') {
            if (!widgetsToShow.performances[type]) {
              widgetsToShow.performances[type] = []
            }
            widgetsToShow.performances[type].push(widget)
          }
        })
      })
    }

    if (reportWidgetSetting.allSponsoredAds) {
      widgetsToShow.momTableWidget = reportWidgetSetting.allSponsoredAds.find((w) =>
        w.widgetType === 'special'
        && w.key === 'comparison_mom_table'
        && w.highlighted
      )
    }

    return widgetsToShow
  }, [reportWidgetSetting, sections, isNonEndemicAccount])

  const handleHideReportName = () => {
    dispatch(changeViewReportNameState())
    setReportNameView(p => !p)
  }

  const handleSaveReport = async () => {
    await onSaveSettings({
      name: reportName,
      sectionKey: 'notes_overview',
      sectionValue: note,
    })
  }

  const renderTileWidget = (section, widget, currentMetrics, pastMetrics) => {
    const {
      isBetter = true,
      difference = 0.0,
      diffPercent = 0.00,
      value = 0.00
    } = calculateMetricsDifference(currentMetrics, pastMetrics, salesData, widget.key)

    return (
      <ReportTileWidget
        widget={widget}
        value={value}
        diff={{ value: difference, percent: diffPercent }}
        isBetter={isBetter}
        section={section}
        suffix={reportWidgetSuffix[section]}
      />
    )
  }

  const renderChartWidget = (section, widget, source) => {
    const {
      chartData = [],
      chartMetrics = []
    } = calculateChartMetric(source, widget, currencySign)

    return (
      <ReportChartWidget
        widget={widget}
        data={chartData}
        metrics={chartMetrics}
        section={section}
        suffix={reportWidgetSuffix[section]}
      />
    )
  }

  const renderAllAdsPieChartWidget = (widget) => {
    let data = []
    switch (widget.key) {
      case 'sales_by_ad_type':
        data = [
          {
            name: `SP (${currencySign})`,
            value: spMetrics ? spMetrics.revenue : 0 ,
            color: '#31cceb',
            fullName: 'SP Sales',
          },
          {
            name: `SB (${currencySign})`,
            value: sbMetrics ? sbMetrics.revenue : 0,
            color: '#cf1d23',
            fullName: 'SB Sales',
          },
          {
            name: `SD (${currencySign})`,
            value: sdMetrics ? sdMetrics.revenue : 0,
            color: '#97e80c',
            fullName: 'SD Sales',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            section="allSponsoredAds"
            suffix={reportWidgetSuffix['allSponsoredAds']}
          />
        )
      case 'organic_vs_ppc':
        data = [
          {
            name: `Organic Sales (${currencySign})`,
            value: salesData ? salesData.sales - salesData.ppcRevenue : 0,
            color: '#ed4c0c',
            fullName: 'Organic Sales',
          },
          {
            name: `PPC Sales (${currencySign})`,
            value: salesData ? salesData.ppcRevenue : 0,
            color: '#97e80c',
            fullName: 'PPC Sales',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            section="allSponsoredAds"
            suffix={reportWidgetSuffix['allSponsoredAds']}
          />
        )
      case 'sales_by_match_type':
        const matchTypeSummary = performanceData ? performanceData.matchTypeSummary : []
        data = [
          {
            name: `Exact (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[0].revenue : 0,
            color: '#ed4c0c',
            fullName: 'EXACT',
          },
          {
            name: `Broad (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[1].revenue : 0,
            color: '#97e80c',
            fullName: 'BROAD',
          },
          {
            name: `Phrase (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[2].revenue : 0,
            color: '#cf1d23',
            fullName: 'PHRASE',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            section="allSponsoredAds"
            suffix={reportWidgetSuffix['allSponsoredAds']}
          />
        )
      default:
        return null
    }
  }

  const renderSpPieChartWidget = (widget) => {
    let data = []
    switch(widget.key) {
      case 'sales_by_match_type':
        const matchTypeSummary = spPerformanceData ? spPerformanceData.matchTypeSummary : []
        data = [
          {
            name: `Exact (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[0].revenue : 0,
            color: '#ed4c0c',
            fullName: 'EXACT',
          },
          {
            name: `Broad (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[1].revenue : 0,
            color: '#97e80c',
            fullName: 'BROAD',
          },
          {
            name: `Phrase (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[2].revenue : 0,
            color: '#0ce8dd',
            fullName: 'PHRASE',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            section="sponsoredProductAds"
            suffix={reportWidgetSuffix['sponsoredProductAds']}
          />
        )
      case 'sales_by_placement':
        data = [
          {
            name: `Top (${currencySign}):`,
            value: placementTotal.revenueTop,
            color: '#0ce8dd',
            fullName: 'Top of Search',
          },
          {
            name: `Product (${currencySign}):`,
            value: placementTotal.revenueDetail,
            color: '#97e80c',
            fullName: 'Product Page',
          },
          {
            name: `Rest (${currencySign}):`,
            value: placementTotal.revenueOther,
            color: '#cf1d23',
            fullName: 'Rest of Search',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            suffix={reportWidgetSuffix.sponsoredProductAds}
            section="sponsoredProductAds"
          />
        )
      case 'sales_by_bid_type':
        data = [
          {
            name: `Dynamic bids - down only (${currencySign}):`,
            value: bidTotal.revenueLegacy,
            color: '#0ce8dd',
            fullName: 'Down Only',
          },
          {
            name: `Dynamic bids - up and down (${currencySign}):`,
            value: bidTotal.revenueAuto,
            color: '#97e80c',
            fullName: 'Up & Down',
          },
          {
            name: `Fixed Bid (${currencySign}):`,
            value: bidTotal.revenueManual,
            color: '#cf1d23',
            fullName: 'Fixed',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            suffix={reportWidgetSuffix.sponsoredProductAds}
            section="sponsoredProductAds"
          />
        )
      default:
        return null
    }
  }

  const renderSbPieChartWidget = (widget) => {
    let data = []
    switch(widget.key) {
      case 'sales_by_match_type':
        const matchTypeSummary = sbPerformanceData ? sbPerformanceData.matchTypeSummary : []
        data = [
          {
            name: `Exact (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[0].revenue : 0,
            color: '#ed4c0c',
            fullName: 'EXACT',
          },
          {
            name: `Broad (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[1].revenue : 0,
            color: '#97e80c',
            fullName: 'BROAD',
          },
          {
            name: `Phrase (${currencySign}):`,
            value: matchTypeSummary?.length > 0 ? matchTypeSummary[2].revenue : 0,
            color: '#cf1d23',
            fullName: 'PHRASE',
          },
        ]
        return (
          <ReportPieChartWidget
            widget={widget}
            data={data}
            section="sponsoredBrandAds"
            suffix={reportWidgetSuffix.sponsoredBrandAds}
          />
        )
      default:
        return null
    }
  }

  const renderAdTypeWidget = (widget) => {
    const metricList = {
      sponsoredProduct: spMetrics,
      sponsoredBrand: sbMetrics,
      sponsoredDisplay: sdMetrics,
    }

    const metric = metricList[widget.key]

    return (
      <ReportPerformanceWidget
        key={`performanceByAdType-${widget.key}`}
        widget={widget}
        data={{
          ...metric,
          ctr: metric?.impressions > 0 ? metric?.clicks / metric?.impressions * 100.0 : 0,
          acos: metric?.revenue > 0 ? metric?.cost / metric?.revenue * 100.0 : 0,
          conversion: metric?.clicks > 0 ? metric?.orders / metric?.clicks * 100.0 : 0,
          cpc: metric?.clicks > 0 ? metric?.cost / metric?.clicks : 0,
        }}
        section="performanceByAdType"
      />
    )
  }

  const renderPlacementWidget = (widget) => {
    let suffix
    if (widget.key === 'topOfSearch') {
      suffix = 'Top'
    } else if (widget.key === 'productPage') {
      suffix = 'Detail'
    } else if (widget.key === 'restOfSearch') {
      suffix = 'Other'
    }

    return (
      <ReportPerformanceWidget
        key={`performanceByPlacement-${widget.key}`}
        widget={widget}
        data={{
          ctr: placementTotal[`ctr${suffix}`],
          clicks: placementTotal[`clicks${suffix}`],
          impressions: placementTotal[`impressions${suffix}`],
          conversion: placementTotal[`conv${suffix}`],
          orders: placementTotal[`orders${suffix}`],
          acos: placementTotal[`acos${suffix}`],
          revenue: placementTotal[`revenue${suffix}`],
          cpc: placementTotal[`cpc${suffix}`]
        }}
        section="performanceByPlacement"
      />
    )
  }

  const renderBidTypeWidget = (widget) => {
    let suffix
    if (widget.key === 'legacy') {
      suffix = 'Legacy'
    } else if (widget.key === 'auto') {
      suffix = 'Auto'
    } else if (widget.key === 'manual') {
      suffix = 'Manual'
    }

    return (
      <ReportPerformanceWidget
        key={`performanceByBidType-${widget.key}`}
        widget={widget}
        data={{
          ctr: bidTotal[`ctr${suffix}`],
          clicks: bidTotal[`clicks${suffix}`],
          impressions: bidTotal[`impressions${suffix}`],
          conversion: bidTotal[`conv${suffix}`],
          orders: bidTotal[`orders${suffix}`],
          acos: bidTotal[`acos${suffix}`],
          revenue: bidTotal[`revenue${suffix}`],
          cpc: bidTotal[`cpc${suffix}`]
        }}
        section="performanceByBidType"
      />
    )
  }

  const renderMatchTypeWidget = (widget) => {
    const matchTypeSummary = performanceData ? performanceData.matchTypeSummary : []

    if (!matchTypeSummary || !matchTypeSummary.length) {
      return null
    }

    let data
    if (widget.key === 'exact') {
      data = matchTypeSummary[0]
    } else if (widget.key === 'broad') {
      data = matchTypeSummary[1]
    } else if (widget.key === 'phrase') {
      data = matchTypeSummary[2]
    }

    return (
      <ReportPerformanceWidget
        key={`performanceByMatchType-${widget.key}`}
        widget={widget}
        data={{
          ...data,
          ctr: data.impressions ? data.clicks / data.impressions * 100.0 : 0,
          acos: data.revenue ? data.cost / data.revenue * 100.0 : 0,
          conversion: data.clicks ? data.orders / data.clicks * 100.0 : 0,
          cpc: data.clicks ? data.cost / data.clicks : 0,
        }}
        section="performanceByMatchType"
      />
    )
  }

  const renderTiles = () => {
    if (!widgets) {
      return null
    }

    const metricList = {
      allSponsoredAds: [allSponsoredMetrics, allSponsoredPastMetrics],
      sponsoredProductAds: [spMetrics, spPastMetrics],
      sponsoredBrandAds: [sbMetrics, sbPastMetrics],
      sponsoredDisplayAds: [sdMetrics, sdPastMetrics],
    }

    const widgetsToRender = []
    sections.forEach((section) => {
      if (!widgets.tiles[section]) {
        return
      }

      widgets.tiles[section].forEach((widget) => {
        widgetsToRender.push((
          <Col key={`${section}-${widget.key}`} xs={6}>
            { renderTileWidget(section, widget, metricList[section][0], metricList[section][1]) }
          </Col>
        ))
      })
    })

    if (!widgetsToRender.length) {
      return null
    }

    return (
      <Row>
        { widgetsToRender }
      </Row>
    )
  }

  const renderCharts = () => {
    if (!widgets) {
      return null
    }

    const metricList = {
      allSponsoredAds: allSponsoredChart,
      sponsoredProductAds: spChart,
      sponsoredBrandAds: sbChart,
      sponsoredDisplayAds: sdChart,
    }

    const widgetsToRender = []
    sections.forEach((section) => {
      if (!widgets.charts[section]) {
        return
      }

      widgets.charts[section].forEach((widget) => {
        widgetsToRender.push((
          <Col key={`chart-${section}-${widget.key}`} xs={12}>
            { renderChartWidget(section, widget, metricList[section]) }
          </Col>
        ))
      })
    })

    return widgetsToRender
  }

  const renderPieCharts = () => {
    if (!widgets) {
      return null
    }

    const widgetsToRender = []
    sections.forEach((section) => {
      if (!widgets.pieCharts[section]) {
        return
      }

      widgets.pieCharts[section].forEach((widget) => {
        let pie = null
        if (section === 'allSponsoredAds') {
          pie = renderAllAdsPieChartWidget(widget)
        } else if (section === 'sponsoredProductAds') {
          pie = renderSpPieChartWidget(widget)
        } else if (section === 'sponsoredBrandAds') {
          pie = renderSbPieChartWidget(widget)
        }
        widgetsToRender.push((
          <Col key={`pie-chart-${section}-${widget.key}`} xs={12}>
            { pie }
          </Col>
        ))
      })
    })

    return widgetsToRender
  }

  const renderPerformances = () => {
    if (!widgets) {
      return null
    }

    const widgetsToRender = []
    performanceTypes.forEach((type) => {
      if (!widgets.performances[type]) {
        return
      }

      widgets.performances[type].forEach((widget) => {
        if (type === 'performanceByAdType') {
          widgetsToRender.push(renderAdTypeWidget(widget))
        } else if (type === 'performanceByPlacement') {
          widgetsToRender.push(renderPlacementWidget(widget))
        } else if (type === 'performanceByBidType') {
          widgetsToRender.push(renderBidTypeWidget(widget))
        } else if (type === 'performanceByMatchType') {
          widgetsToRender.push(renderMatchTypeWidget(widget))
        }
      })
    })

    return widgetsToRender
  }

  const renderContents = () => {
    if (!showSection) {
      return null
    }

    return (
      <>
        {(customizingSection || reportNameView) && (
          <div className="report-widget">
            <div className="report-widget-header">
              <div className="widget-title">
                Report Name
              </div>
              <div className="widget-action">
                {customizingSection && (
                  <IconButton
                    icon={<Icon icon="check" />}
                    color={reportNameView ? 'green' : 'violet'}
                    circle
                    onClick={handleHideReportName}
                  />
                )}
              </div>
            </div>
            <Input
              placeholder="Report Name"
              value={reportName}
              onChange={setReportName}
            />
          </div>
        )}
        {(customizingSection || noteWidgetView) && (
          <ReportNoteWidget
            noteWidgetView={noteWidgetView}
            customizing={customizingSection}
            onRemove={setNoteWidgetView}
            note={note}
            onChange={setNote}
            section="accountOverview"
          />
        )}
        <Grid fluid>
          { renderTiles() }
          <Row>
            { renderCharts() }
            { renderPieCharts() }
          </Row>
          { renderPerformances() }
          {
            widgets && widgets.momTableWidget && (
              <ComparisonViewSection
                widget={widgets.momTableWidget}
                customizing={false}
              />
            )
          }
          <PerformanceSegment />
        </Grid>
      </>
    )
  }

  return (
    <>
      <SectionHeader
        title="Account Overview"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
        onSave={handleSaveReport}
      />
      { renderContents() }
    </>
  )
}

export default withReportSection(AccountOverview, {
  sectionId: 'overview',
  reportSectionKey: 'accountOverview',
})
