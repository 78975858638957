/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'

import VideoLink from '../CommonComponents/VideoLink'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import SubSection from './SubSection'
import OpKeywordGeneral from './OpKeywordGeneral'
import OpKeywordBasic from './OpKeywordBasic'
import OpKeywordAdvanced from './OpKeywordAdvanced'

const basicVideos = [{
  title: 'Keyword Basic Optimization',
  url: 'https://www.loom.com/embed/6f43e6f51595496aa12bd2e4bd91e2d1',
}]

const advancedVideos = [{
  title: 'Keyword Advanced Optimization',
  url: 'https://www.loom.com/embed/393fe73cb2ce426a946b2561f6185bd1',
}]

const OpKeyword = ({ settings, fromTemplateEditor,
  isNonEndemicAccount, onChange, ...props }) => {
  const { campaign } = props

  const [currentTab, setCurrentTab] = useState('general')

  let isSP = false
  let isAutoSP = false
  if (campaign) {
    isSP = campaign.basic[0].type === 'sp'
    // For auto targeting Sponsored Products, Advanced settings are disabled.
    isAutoSP = isSP && campaign.basic[0].targeting_type === 'auto'
  }

  const handleTabSelect = tab => (event) => {
    event.preventDefault()
    setCurrentTab(tab)
  }

  const renderTabs = () => {
    return (
      <div className="tab-list">
        <a
          href="#"
          className={currentTab === 'general' ? "tab selected" : "tab"}
          onClick={handleTabSelect('general')}
        >
          <span className="link-name">
            General
            <CustomTooltip>
              General settings are applicable to both basic and advanced settings.
            </CustomTooltip>
          </span>
        </a>
        <a
          href="#"
          className={currentTab === 'basic' ? "tab selected" : "tab"}
          onClick={handleTabSelect('basic')}
        >
          Basic
          <VideoLink
            videoList={basicVideos}
            iconOnly
          />
        </a>
        {
          !isAutoSP && !isNonEndemicAccount && (
            <a
              href="#"
              className={currentTab === 'advanced' ? "tab selected" : "tab"}
              onClick={handleTabSelect('advanced')}
            >
              <span className="link-name">
                Advanced
                <CustomTooltip>
                  <p>Advanced settings allow you to create custom lookback periods
                  and frequency settings for a variety of useful optimization strategies.</p>
                  <p>You can also create a clone of each setting, allowing you
                  to run multiple optimizations over different lookback periods.</p>
                  <p>Advanced settings will override basic settings.</p>
                </CustomTooltip>
              </span>
              <VideoLink
                videoList={advancedVideos}
                iconOnly
              />
            </a>
          )
        }
      </div>
    )
  }

  const renderContents = () => {
    if (currentTab === 'general') {
      return (
        <OpKeywordGeneral
          campaign={campaign}
          settings={settings}
          fromTemplateEditor={fromTemplateEditor}
          isNonEndemicAccount={isNonEndemicAccount}
          onChange={onChange}
        />
      )
    }

    if (currentTab === 'basic') {
      return (
        <OpKeywordBasic
          campaign={campaign}
          settings={settings}
          isNonEndemicAccount={isNonEndemicAccount}
          onChange={onChange}
        />
      )
    }

    if (currentTab === 'advanced' && !isAutoSP) {
      return (
        <OpKeywordAdvanced
          settings={settings}
          onChange={onChange}
        />
      )
    }
  }

  return (
    <SubSection
      name="Target Bid Optimization"
      templateId={settings.ap_template_id}
      noReadOnly
      {...props}
    >
      { renderTabs() }
      <div className={`op-keyword-contents${settings.ap_template_id ? ' read-only' : ''}`}>
        { renderContents() }
      </div>
    </SubSection>
  )
}

export default OpKeyword
