import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'

import TableCollection from '../TableCollection'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const CommandCenterComponent = () => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const renderCreatorDropdown = () => {
    if (isNonEndemicAccount) {
      return (
        <Dropdown
          title="+ New"
          placement="bottomEnd"
          toggleClassName="btn-new"
        >
          <Dropdown.Item componentClass={Link} to="/campaigns/new/sd">
            Sponsored Display Campaign
          </Dropdown.Item>
        </Dropdown>
      )
    }

    return (
      <Dropdown
        title="+ New"
        placement="bottomEnd"
        toggleClassName="btn-new"
      >
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sp">
          Sponsored Product Campaign
        </Dropdown.Item>
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sb">
          Sponsored Brand Campaign
        </Dropdown.Item>
        <Dropdown.Item componentClass={Link} to="/campaigns/new/sd">
          Sponsored Display Campaign
        </Dropdown.Item>
      </Dropdown>
    )
  }

  return (
    <div className="command-center-page">
      <div className="page-header">
        <div className="page-title">Command Center</div>
        <div className="page-header-buttons">
          { renderCreatorDropdown() }
        </div>
      </div>
      <div className="page-content">
        <TableCollection />
      </div>
    </div>
  )
}

export default CommandCenterComponent
