import React from 'react'

import CampaignTableComponent from '../CampaignTableComponent'
import ActionBar from './ActionBar'

const MyCampaignTable = (props) => {
  return (
    <CampaignTableComponent
      customActionBar={ActionBar}
      {...props}
    />
  )
}

export default MyCampaignTable
