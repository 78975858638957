import React from 'react'
import { FiCheck } from 'react-icons/fi'

import { GOAL_PAGE_VISIT, GOAL_BRAND_IMPRESSION_SHARE } from '../../utils/defaultValues'

const goalList = [
  {
    value: GOAL_PAGE_VISIT,
    name: 'Drive page visits',
    description: 'Drive traffic to your landing page and detail page.<br /><br />'
      + 'Cost type: Cost per click (CPC)<br />'
      + 'Success metric: Clicks',
  },
  {
    value: GOAL_BRAND_IMPRESSION_SHARE,
    name: 'Grow brand impression share',
    description: 'Show your ads to shoppers who search for your brand or within your brand categories.<br /><br />'
      + 'Cost type: Cost per 1,000 viewable impressions (VCPM)<br />'
      + 'Success metric: Top-of-search impression share (IS)',
  },
]

const SBGoalSelector = ({ countryCode, goal, onChange }) => {
  // This parameter is only available in the following marketplaces
  // (US, CA, UK, DE, FR, IT, ES, IN, JP).
  if (![
    'us',
    'ca',
    'gb',
    'de',
    'fr',
    'it',
    'es',
    'in',
    'jp',
  ].includes(countryCode)) {
    return null
  }

  return (
    <div className="section-container targeting-type-selector">
      <div className="section-title">
        Goals
      </div>
      <div className="section-note">
        Choose a campaign goal that aligns with your business objective.
        We'll make bidding and targeting recommendations to help achieve this goal.
      </div>
      <div className="selector-wrapper">
        {
          goalList.map(goalInfo => (
            <div
              key={goalInfo.value}
              className={`selector${goal === goalInfo.value ? ' selected' : ''}`}
              onClick={() => onChange(goalInfo.value)}
            >
              <FiCheck />
              <div className="selector-content">
                <div className="selector-title">{ goalInfo.name }</div>
                <div
                  className="selector-note"
                  dangerouslySetInnerHTML={{
                    __html: goalInfo.description,
                  }}
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SBGoalSelector
