/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import { FiExternalLink } from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import AdTypeMultiSelector from '../CommonComponents/AdTypeMultiSelector'
import PortfolioSelector from '../CommonComponents/PortfolioSelector'
import TagSelector from '../CommonComponents/TagSelector'
import ConfirmModal from '../CommonComponents/ConfirmModal'

import { getCampaignRulesData } from '../../redux/actions/dashboard'
import {
  showSpManager,
  showRuleManager,
  showTemplateEditor,
  turnRules,
  deleteFromTemplate,
} from '../../redux/actions/ruleManager'
import { turnRule } from '../../redux/actions/ap'
import { turnRrRules } from '../../redux/actions/rank'

import { tableSorter } from '../../services/helper'

import {
  RULE_TYPE_SP,
  RULE_TYPE_RR,
  RULE_TYPE_CP,
  RULE_TYPE_WP,
  RULE_TYPE_DP,
  ruleNames,
  ruleStickerNames,
  RULE_TYPE_EP,
} from '../../utils/defaultValues'

const columns = [
  { key: 'name', name: 'Campaign', className: 'col-campaign' },
  { key: 'ruleCount', name: 'Active Rules' },
  { key: 'templateCount', name: 'Templates' },
]

const ruleOptions = [
  { label: ruleNames[RULE_TYPE_EP], value: RULE_TYPE_EP },
  { label: ruleNames[RULE_TYPE_CP], value: RULE_TYPE_CP },
  { label: ruleNames[RULE_TYPE_WP], value: RULE_TYPE_WP },
  { label: ruleNames[RULE_TYPE_DP], value: RULE_TYPE_DP },
  { label: ruleNames[RULE_TYPE_SP], value: RULE_TYPE_SP },
]

const CampaignsView = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const campaignRulesData = useSelector(state => state.ruleManager.campaignRulesData)

  const [isLoading, setIsLoading] = useState(false)
  const [adTypeFilter, setAdTypeFilter] = useState([])
  const [portfolioFilter, setPortfolioFilter] = useState([])
  const [tagFilter, setTagFilter] = useState([])
  const [selectedRuleTypes, setSelectedRuleTypes] = useState([])
  const [templateToRemove, setTemplateToRemove] = useState(null)
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      try {
        const accessToken = await getAccessTokenSilently()
        await dispatch(getCampaignRulesData(accessToken, abortCtrl.signal))
        setIsLoading(false)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoading(false)
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const filteredCampaigns = useMemo(() => {
    let portfolioIds = []
    let tagIds = []
    if (portfolioFilter.length) {
      portfolioIds = portfolioFilter.map(p => p.value.toString())
    }
    if (tagFilter.length) {
      tagIds = tagFilter.map(t => t.value.toString())
    }

    return (campaignRulesData || []).filter((record) => {
      if (adTypeFilter.length) {
        if (adTypeFilter.filter(o => o.type === 'status').length
          && !adTypeFilter.find(o => o.value === record.state.toLowerCase())) {
          return false
        }

        if (adTypeFilter.filter(o => o.type === 'ad_type').length
          && !adTypeFilter.find(o => o.value === record.campaignType)) {
          return false
        }
      }

      if (portfolioIds.length && !portfolioIds.includes((record.portfolio_id || '').toString())) {
        return false
      }

      if (tagIds.length) {
        const tagged = record.tags.find(t => tagIds.includes(t.toString()))
          // For `no tag` option.
          || (tagIds.includes('0') && record.tags.length === 0)
        if (!tagged) {
          return false
        }
      }

      if (selectedRuleTypes.length) {
        const ruleTypes = selectedRuleTypes.map(r => r.value)
        const match = record.children.find(child => ruleTypes.includes(child.type))
        if (!match) {
          return false
        }
      }

      return true
    }).map((record) => {
      // Count active rules & templates.
      let ruleCount = 0
      let hasActiveSp = false
      let templateCount = 0
      const activeRuleTypes = []
      record.children.forEach((rule) => {
        rule.campaignName = record.name // For search
        if (rule.status) {
          if (rule.type !== RULE_TYPE_SP) {
            ruleCount += 1
          } else {
            hasActiveSp = true
          }
          activeRuleTypes.push(rule.type)
        }

        templateCount += rule.templates.length
      })
      if (hasActiveSp) {
        ruleCount += 1
      }
      record.ruleCount = ruleCount
      record.templateCount = templateCount
      record.activeRuleTypes = [...new Set(activeRuleTypes)]
      return record
    })
  }, [campaignRulesData, adTypeFilter, portfolioFilter, tagFilter, selectedRuleTypes])

  const handleRuleToggle = (rule) => async (status) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()

    if (rule.type === RULE_TYPE_SP) {
      await dispatch(turnRule(
        accessToken,
        rule.campaign.campaign_id,
        rule.adgroup ? rule.adgroup.id : undefined,
        status,
      ))
    } else if (rule.type === RULE_TYPE_RR) {
      await dispatch(turnRrRules(
        accessToken,
        [rule.campaign.campaign_id.toString()],
      ))
    } else {
      await dispatch(turnRules(
        accessToken,
        rule.type,
        [rule.campaign.campaign_id.toString()],
      ))
    }

    setIsLoading(false)
  }

  const handleRuleManagerShow = (rule) => () => {
    if (rule.type === RULE_TYPE_SP) {
      dispatch(showSpManager(
        rule.campaign.campaign_id,
        rule.adgroup ? rule.adgroup.id : null,
      ))
    } else if (rule.type !== RULE_TYPE_RR) {
      dispatch(showRuleManager(rule.type, [{
        campaign_id: rule.campaign.campaign_id,
        name: rule.campaign.name,
        campaignType: rule.campaign.campaignType,
      }]))
    }
  }

  const handleTemplateEdit = (template, type) => (event) => {
    event.preventDefault()
    dispatch(showTemplateEditor(type, template.id))
  }

  const handleTemplateRemove = (template, rule) => (event) => {
    event.preventDefault()
    setTemplateToRemove({
      type: rule.type,
      templateId: template.id,
      campaignId: rule.campaign.campaign_id,
    })
    setShowRemoveConfirm(true)
  }

  const handleConfirmRemoval = async (confirmed) => {
    setShowRemoveConfirm(false)

    if (!confirmed) {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    await dispatch(deleteFromTemplate(
      accessToken,
      templateToRemove.type,
      templateToRemove.templateId,
      templateToRemove.campaignId,
    ))
    setIsLoading(false)
  }

  const renderFilter = () => (
    <>
      <AdTypeMultiSelector
        onChange={setAdTypeFilter}
      />
      <PortfolioSelector
        onChange={setPortfolioFilter}
      />
      <TagSelector
        onChange={setTagFilter}
      />
      <Select
        isMulti
        placeholder="Rules"
        options={ruleOptions}
        value={selectedRuleTypes}
        onChange={setSelectedRuleTypes}
      />
    </>
  )

  const renderRuleName = (rule) => {
    if (rule.type !== RULE_TYPE_SP) {
      return ruleNames[rule.type]
    }

    return (
      <span className="rule-name-contents">
        <span>{ rule.name }</span>
        {
          typeof rule.adgroup !== 'undefined' && (
            <span className="rule-sp-details">{ rule.adgroup.name }</span>
          )
        }
        {
          rule.advancedEnabled && (
            <span className="rule-sp-details">Advanced Settings</span>
          )
        }
        {
          !rule.advancedEnabled && rule.basicEnabled && (
            <span className="rule-sp-details">Basic Settings</span>
          )
        }
      </span>
    )
  }

  const renderRule = (rule) => (
    <>
      <div className="table-col col-rule">
        <Toggle
          checked={rule.status}
          onChange={handleRuleToggle(rule)}
        />
        <span
          className="rule-name"
          onClick={handleRuleManagerShow(rule)}
        >
          { renderRuleName(rule) }
          {
            rule.type !== RULE_TYPE_RR && (
              <FiExternalLink />
            )
          }
        </span>
      </div>
      <div className="table-col col-template">
        {
          rule.templates.length > 0 && (
            <ul>
              {
                rule.templates.map(tpl => (
                  <li key={tpl.id}>
                    { tpl.name }
                    <a
                      href="#"
                      onClick={handleTemplateEdit(tpl, rule.type)}
                    >
                      Edit
                    </a>
                    <span>|</span>
                    <a
                      href="#"
                      className="action-remove"
                      onClick={handleTemplateRemove(tpl, rule)}
                    >
                      Remove
                    </a>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    </>
  )

  const renderCampaign = record => (
    <>
      <TableCampaignCell
        record={record}
        showState
      />
      <div className="table-col col-stickers">
        {
          record.activeRuleTypes.map(type => (
            <span
              key={type}
              className="rule-sticker"
            >
              <span>
                { ruleStickerNames[type] }
              </span>
            </span>
          ))
        }
      </div>
      <div className="table-col">
        { record.templateCount }
      </div>
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return record[column.key] || '';
    })
  )

  return (
    <>
      <SortableTable
        tableComponent={GroupTable}
        isLoading={isLoading}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['name'])}
        className="table-campaign-rules"
        records={filteredCampaigns}
        idField="campaign_id"
        /*
         * This props of GroupTable is to search on the fields of sub items.
         * To be searchable on parents' fields, should pass the values to their sub items.
         * Here, name field is already existed in sub items, so added a new key as 'campaignName' to sub items
         */
        searchFields={['campaignName', 'name']}
        noCheckBox
        hasSticky
        renderTopRight={renderFilter}
        renderRecord={renderCampaign}
        childSortable={false}
        idFieldChild="id"
        renderChild={renderRule}
        getExportData={getExportData}
        exportFileName="Campaign View in Rules"
      />
      <ConfirmModal
        show={showRemoveConfirm}
        text='Are you sure you want to remove the campaign from the template?'
        onConfirm={handleConfirmRemoval}
      />
    </>
  )
}

export default CampaignsView
