import { useState, useEffect, useRef} from 'react'

const useScrollSpy = ( ids, options ) => {
  const [activeId, setActiveId] = useState(false);
  const observer = useRef(null);
  
  useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id))
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    }, options);
    elements.map((el) => {
      if (el) {
        return observer.current?.observe(el);
      }
      return null
    });
    return () => observer.current?.disconnect();
  }, [ids, options]);

  return activeId;
}

export default useScrollSpy