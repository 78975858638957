import React, { useEffect, useState } from 'react'
import { Modal, Radio, InputNumber } from 'rsuite'
import Select from 'react-select'

import LoaderComponent from '../LoaderComponent'
import CustomTooltip from '../CustomTooltip'

import { MAX_PLACEMENT_BID_CHANGE } from '../../../utils/defaultValues'

const MANUAL_ENTRY_OPTION = 'manual'
const RECOMMENDATION_OPTION = 'recommendation'

const recommendOptions = [
  { value: 10, label: '10%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
]

const initialBidValue = {
  placementTop: 0,
  placementProductPage: 0,
  placementRestOfSearch: 0,
}

const PlacementModal = ({
  open,
  adjustments = [],
  isLoading,
  onAdjustBid,
  onApplyRecommend,
  onClose,
}) => {
  const [activeOption, setActiveOption] = useState(MANUAL_ENTRY_OPTION)
  const [bidValue, setBidValue] = useState({...initialBidValue})
  const [percent, setPercent] = useState(recommendOptions[0])

  useEffect(() => {
    if (adjustments.length) {
      const oldBidValue = {...initialBidValue}
      Object.keys(oldBidValue).forEach(placement => {
        const old = adjustments.find(adjustment => adjustment.predicate === placement)
        if (old) {
          oldBidValue[placement] = old.percentage
        }
      })
      setBidValue({...oldBidValue})
    }
  }, [adjustments])

  const handleSave = async () => {
    if (activeOption === MANUAL_ENTRY_OPTION) {
      await onAdjustBid(bidValue)
    } else {
      await onApplyRecommend(percent.value)
    }
    onClose()
  }

  return (
    <Modal className="placement-modal" backdrop="static" show={open} size="sm" overflow={false}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title className="placement-modal-title">
          Optimize Placement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={isLoading ? 'loading' : ''}>
        { isLoading && <LoaderComponent/> }
        <div className={`optimize-option ${activeOption === MANUAL_ENTRY_OPTION ? 'active' : ''}`}>
          <Radio
            className="option-radio"
            checked={activeOption === MANUAL_ENTRY_OPTION}
            value={MANUAL_ENTRY_OPTION}
            onChange={setActiveOption}
          >
            Manual entry
          </Radio>
          <div className="option-content">
            <div className="option-item">
              <span>Top of Search</span>
              <InputNumber
                min={0}
                max={MAX_PLACEMENT_BID_CHANGE}
                disabled={activeOption === RECOMMENDATION_OPTION}
                value={bidValue.placementTop || 0}
                postfix="%"
                onChange={(v) => setBidValue({...bidValue, placementTop: parseInt(v, 10)})}
              />
            </div>
            <div className="option-item">
              <span>Product Page</span>
              <InputNumber
                min={0}
                max={MAX_PLACEMENT_BID_CHANGE}
                disabled={activeOption === RECOMMENDATION_OPTION}
                value={bidValue.placementProductPage || 0}
                postfix="%"
                onChange={(v) => setBidValue({...bidValue, placementProductPage: parseInt(v, 10)})}
              />
            </div>
            <div className="option-item">
              <span>Rest of the Search</span>
              <InputNumber
                min={0}
                max={MAX_PLACEMENT_BID_CHANGE}
                disabled={activeOption === RECOMMENDATION_OPTION}
                value={bidValue.placementRestOfSearch || 0}
                postfix="%"
                onChange={(v) => setBidValue({...bidValue, placementRestOfSearch: parseInt(v, 10)})}
              />
            </div>
          </div>
        </div>
        <div className={`optimize-option ${activeOption === RECOMMENDATION_OPTION ? 'active' : ''}`}>
          <div className="recommend-option">
            <Radio
              className="option-radio"
              checked={activeOption === RECOMMENDATION_OPTION}
              value={RECOMMENDATION_OPTION}
              onChange={setActiveOption}
            >
              Our recommendations
            </Radio>
            <CustomTooltip placement="right">
              <p>
                You may apply the full recommended changes to your bid prices
                and modifiers by selecting 100%.
              </p>
              <p>
                For users who wish to take a more gradual approach,
                select a lower percentage.
              </p>
              <p>
                Example: If the system recommends an increase of $1.00,
                but you select 50%, the system will only increase your bid by $0.50.
              </p>
            </CustomTooltip>
          </div>
          <div className="option-content">
            <div className="option-item">
              <span>Set Percentage</span>
              <Select
                className="bid-option-selector"
                options={recommendOptions}
                isDisabled={activeOption === MANUAL_ENTRY_OPTION}
                value={percent}
                onChange={setPercent}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Cancel
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isLoading}
          onClick={() => handleSave()}
        >
          Save & Update
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default PlacementModal
