import React from 'react'

import { formatValue, formatCurrency } from '../../services/helper'

const DspTableCell = ({ record, column, currencySign, currencyRate }) => {
  let value
  if (!record[column.key]) {
    value = '--'
  } else {
    if (column.valueType === 'decimal') {
      value = formatValue(
        record[column.key],
        'number',
        column.decimal ? column.decimal : 2
      )
    } else if (column.valueType === 'currency') {
      value = formatCurrency(record[column.key], currencySign, currencyRate)
    } else if (column.valueType === 'percent') {
      value = formatValue(
        record[column.key],
        'percent',
        column.decimal ? column.decimal : 2
        )
      } else {
      value = formatValue(record[column.key], 'removeZeroDecimal')
    }
  }

  return (
    <div className="table-col" title={value}>
      { value }
    </div>
  )
}

export default DspTableCell
