import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import SectionHeader from './SectionHeader'
import ReportPerformanceWidget from './ReportPerformanceWidget'

import { parsePerformanceSummary } from '../../services/helper'

const PerformanceByPlacement = ({ showSection, onToggle }) => {
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)
  const placementSummary = useSelector(state => state.accountHealth.performanceData.placementSummary)

  const [customizingSection, setCustomizingSection] = useState(false)
  const [viewWidgets, setViewWidgets] = useState([])

  useEffect(() => {
    const performanceByPlacement = reportWidgetSetting ? reportWidgetSetting.performanceByPlacement : []
    setViewWidgets(
      performanceByPlacement.map(w => ({
        ...w,
        view: !!w.default,
      }))
    )
  }, [reportWidgetSetting])

  const { placementTotal } = useMemo(() => {
    return parsePerformanceSummary(placementSummary)
  }, [placementSummary])

  const handleOnRemoveWidget = (widgetKey) => {
    setViewWidgets(widgets => widgets.map(w => w.key === widgetKey ? {...w, view: !w.view} : w))
  }

  const renderWidget = (widget) => {
    let suffix
    switch (widget.key) {
      case 'topOfSearch':
        suffix = 'Top'
        break
      case 'productPage':
        suffix = 'Detail'
        break
      case 'restOfSearch':
        suffix = 'Other'
        break
      default:
        return null
    }

    return (
      <ReportPerformanceWidget
        key={widget.key}
        widget={widget}
        data={{
          ctr: placementTotal[`ctr${suffix}`],
          clicks: placementTotal[`clicks${suffix}`],
          impressions: placementTotal[`impressions${suffix}`],
          conversion: placementTotal[`conv${suffix}`],
          orders: placementTotal[`orders${suffix}`],
          acos: placementTotal[`acos${suffix}`],
          revenue: placementTotal[`revenue${suffix}`],
          cpc: placementTotal[`cpc${suffix}`]
        }}
        customizing={customizingSection}
        onRemove={handleOnRemoveWidget}
        section="performanceByPlacement"
      />
    )
  }

  return (
    <div>
      <SectionHeader
        title="Performance By Placement"
        showSection={showSection}
        customizingSection={customizingSection}
        onToggle={onToggle}
        onCustomize={setCustomizingSection}
      />
      {
        showSection && (
          <>
            {
              viewWidgets.map(w => (w.view || customizingSection) ? (
              renderWidget(w)) : null)
            }
          </>
        )
      }
    </div>
  )
}

export default withReportSection(PerformanceByPlacement, {
  sectionId: 'performanceByPlacement',
  reportSectionKey: 'performanceByPlacement',
})
