/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'
import CancellationModal from './CancellationModal'

import { getSubscriptions, cancelSubscription } from '../../redux/actions/auth'

const statusList = {
  future: 'Future',
  in_trial: 'In Trial',
  active: 'Active',
  non_renewing: 'Non Renewing',
  paused: 'Paused',
  cancelled: 'Cancelled',
}

const getAccountLabel = (account) => {
  if (!account) {
    return ''
  }

  const country = (account.country_id || 'N/A').toUpperCase()
  let brandName
  if (account.brand_name) {
    brandName = account.brand_name
  } else {
    brandName = account.sellerid || 'N/A'
  }
  return `${country} - ${brandName}`
}

const getCouponInfo = (coupon, subscriptionCoupons) => {
  if (!coupon){
    return ''
  }

  let couponInfo = coupon.discount_type === 'percentage' ?  `${coupon.discount_percentage}% ` :  `${coupon.discount_amount} ${coupon.currency_code} `

  if (coupon.duration_type === 'forever'){
    couponInfo += coupon.duration_type
  } else {
    const applyTill = subscriptionCoupons.find((sCoupon) => sCoupon.coupon_id === coupon.id)?.apply_till;
    if (applyTill){
      couponInfo += `till ${format(applyTill * 1000, 'dd-MMM-yyyy')}`
    }

  }
  return couponInfo
}

const BillingSubscription = () => {
  const dispatch = useDispatch()
  const accountList = useSelector(state => state.header.accountList)
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionList, setSubscriptionList] = useState([])
  const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState(null)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      dispatch(getSubscriptions(accessToken)).then((response) => {
        setIsLoading(false)
        setSubscriptionList(response)
      }).catch(() => {
        setIsLoading(false)
      })
    })()
  }, []) // eslint-disable-line

  const handleCancel = async (reason) => {
    const accessToken = await getAccessTokenSilently()
    const subscription = await dispatch(cancelSubscription(accessToken, subscriptionIdToCancel, reason))

    setSubscriptionList(subscriptionList.map(subscr => ({
      ...subscr,
      status: subscription?.id === subscr.id ? subscription?.status: subscr.status,
      cancelled_at: subscription?.id === subscr.id ? subscription?.cancelled_at : subscr.cancelled_at
    })))

    setSubscriptionIdToCancel(null)
    return subscription
  }

  const renderSubscription = (subscription, _, index) => {
    const account = accountList.find(record => (
      record.subscription_id === subscription.id
    ))

    const timelines = []
    if (subscription.created_at) {
      timelines.push(`Signed up on `
        + `${format(subscription.created_at * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.trial_start) {
      timelines.push(`Your trial period started on `
        + `${format(subscription.trial_start * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.trial_end) {
      timelines.push(`Your trial expired on `
        + `${format(subscription.trial_end * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.activated_at) {
      timelines.push(`Your subscription was activated on `
        + `${format(subscription.activated_at * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.cancelled_at) {
      timelines.push(`Your subscription was (will be) cancelled on `
        + `${format(subscription.cancelled_at * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.current_term_start && subscription.current_term_end) {
      timelines.push(`Your current billing term is `
        + `${format(subscription.current_term_start * 1000, 'dd-MMM-yyyy')} to `
        + `${format(subscription.current_term_end * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.next_billing_at) {
      timelines.push(`Your next billing will be on `
        + `${format(subscription.next_billing_at * 1000, 'dd-MMM-yyyy')}.`)
    }
    if (subscription.billing_period && subscription.billing_period_unit) {
      timelines.push(`Your recurring charges every `
        + `${subscription.billing_period} ${subscription.billing_period_unit}(s).`)
    }

    let statusClass = ''
    if (subscription.status === 'active') {
      statusClass = 'status-active'
    } else if (subscription.status === 'cancelled' || !!subscription.cancelled_at) {
      statusClass = 'status-cancelled'
    }

    const planInfo = subscription.plan_id.indexOf('v2-new-pricing') < 0
      ? `${subscription.currency_code } ${ (subscription.plan_amount / 100).toFixed(2) }`
      : '2.9% of Ad Spend'

    const amountInfo = subscription.plan_id.indexOf('v2-new-pricing') < 0
      ? `${subscription.currency_code } ${ (subscription.plan_amount / 100).toFixed(2) }`
      : 'Calculated at End of cycle'

    return (
      <>
        <div className="table-col col-no">
          { index + 1 }
        </div>
        <div className="table-col col-account">
          { getAccountLabel(account) }<br/>
          <em>
            Subscription:<br/>{subscription.id}
          </em>
        </div>
        <div className={`table-col col-status ${statusClass}`}>
          { statusList[subscription.status] || subscription.status }
        </div>
        <div className="table-col col-amount">
          { planInfo }
        </div>
        <div className="table-col col-amount">
          <ul>
            { subscription.coupons.map((coupon, index) => (
              <li key={index}>
                {getCouponInfo(coupon, subscription.subscription_coupon)}
              </li>
            )) }
          </ul>
        </div>
        <div className="table-col col-amount">
          { amountInfo }
        </div>
        <div className="table-col col-timeline">
          <ul>
            { timelines.reverse().map((change, index) => (
              <li key={index}>
                { change }
              </li>
            )) }
          </ul>
        </div>
        <div className="table-col col-action">
          {
            (!subscription.cancelled_at) && (
              <a
                href="#"
                className="link-cancel"
                onClick={(event) => { event.preventDefault(); setSubscriptionIdToCancel(subscription.id) }}
              >
                Cancel
              </a>
            )
          }
        </div>
      </>
    )
  }

  const associatedSubscriptionIds = accountList.map(account => account.subscription_id)
  const filteredSubscriptions = subscriptionList.filter(subscription => (
    associatedSubscriptionIds.indexOf(subscription.id) !== -1
  ))

  return (
    <div>
      <CustomTable
        isLoading={isLoading}
        className="table-subscriptions"
        records={filteredSubscriptions}
        idField="id"
        noCheckBox
        noSearch
        renderRecord={renderSubscription}
      >
        <div className="table-col col-no">No.</div>
        <div className="table-col col-account">Account</div>
        <div className="table-col col-status">Status</div>
        <div className="table-col col-amount">Your Plan</div>
        <div className="table-col col-amount">Discount</div>
        <div className="table-col col-amount">Amount</div>
        <div className="table-col col-timeline">Timeline</div>
        <div className="table-col col-action"></div>
      </CustomTable>
      <CancellationModal
        show={subscriptionIdToCancel !== null}
        onCancel={handleCancel}
        onHide={() => { setSubscriptionIdToCancel(null) }}
      />
    </div>
  )
}

export default BillingSubscription
