import React from 'react'
import { Toggle } from 'rsuite'

import TimezoneSelector from '../RuleManagerComponents/TimezoneSelector'
import CampaignInfo from './CampaignInfo'

const Header = ({ campaigns, isSaved, isOn, timezone, currentTemplates,
  isCampaignSelectorVisible, isUpdatingStatus,
  onIsOnChange, onTimezoneChange,
  onCampaignRemove, onTemplateChange, onClose }) => {
  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Campaign Performance
          </span>
          <Toggle
            checked={isOn}
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={isUpdatingStatus}
            onChange={onIsOnChange}
          />
        </div>
        <div className="right-column">
          <TimezoneSelector
            timezone={timezone}
            onChange={onTimezoneChange}
          />
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      {
        !isCampaignSelectorVisible && (
          <>
            <CampaignInfo
              campaigns={campaigns}
              currentTemplates={currentTemplates}
              onTemplateChange={onTemplateChange}
              onRemove={onCampaignRemove}
            />
            {
              !isSaved && (
                <div className="save-instruction">
                  You need to click on "Save Rules" to save your changes.
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default Header
