import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import LoomVideoCollection from './LoomVideoCollection'

const topAttachments = [
  { name: 'Entourage 2.0 Playbook.pdf', url: 'https://go.ppcentourage.com/hubfs/Amazon%20Ads%20Playbook%20Series%20V2%202021.pdf?hsLang=en' },
]

const attachments = [
  { name: 'Launch Keyword Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=81687217' },
  { name: 'Audience Finder Cheat Sheet', url: 'https://docs.google.com/spreadsheets/d/1ku9rEtCIbnZYcD2L4w5c529UJ3RjRsIrVlWjZvl-otA/edit#gid=1641638220' },
]

const videoList = [
  { name: 'SB1 - Brand Discovery', videoId: '34ffaa47d2d048758065c85cb675bec4' },
  { name: 'SB2 - Product Line Discovery', videoId: '33ab56f7008b46f49a5d0a8c7b1296a6' },
  { name: 'SB4 - Refined Category/ACOS/ASIN Scraping', videoId: '8fb7ab77d6cc4493a75b2d71e371e07d' },
  { name: 'SB5 - Targeted Scale (Copy, Rinse, Repeat technique)', videoId: 'd5d5dbc3ac19426eb032e3ea34970327' },
  { name: 'SB6- Brand Protection', videoId: '51812fc3c00b4c0f9328d9d21ab6eac2' },
  { name: 'SB7 -Spotlight', videoId: 'd79571ac8b4b480a91c57f12429a76b7' },
]

const SBAds = ({ ...props }) => {
  return (
    <FlowContainer
      name="Sponsored Brand Ads"
      {...props}
    >
      <AttachmentList attachments={topAttachments} />
      <AttachmentList attachments={attachments} />
      <LoomVideoCollection videoList={videoList} />
    </FlowContainer>
  )
}

export default SBAds
