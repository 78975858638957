import React from 'react'

import OpKeywordAdvancedSetting from './OpKeywordAdvancedSetting'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'

// Low Converting Targets
const OpKeywordConvert = ({ settings, onChange, ...props }) => (
  <>
    <OpKeywordAdvancedSetting
      isOriginal
      settings={settings}
      onChange={onChange}
      {...props}
    >
      <FieldRow>
        <FieldNumber
          label="Targets"
          suffix="% Conversion or less"
          field="op_adv_low_conversion_conversion"
          settings={settings}
          onChange={onChange}
        />
        <div className="field-wrapper">
        </div>
      </FieldRow>
    </OpKeywordAdvancedSetting>
    {
      settings[`copy_${props.prefix}active`] && (
        <OpKeywordAdvancedSetting
          settings={settings}
          onChange={onChange}
          {...props}
        >
          <FieldRow>
            <FieldNumber
              label="Targets"
              suffix="% Conversion or less"
              field="copy_op_adv_low_conversion_conversion"
              settings={settings}
              onChange={onChange}
            />
            <div className="field-wrapper">
            </div>
          </FieldRow>
        </OpKeywordAdvancedSetting>
      )
    }
  </>
)

export default OpKeywordConvert
