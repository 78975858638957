import React, { useState } from 'react'
import Select from 'react-select'

import PrimePng from '../../../assets/img/prime.png'

const previewTypes = [
  { value: 'MOBILE_INLINE', label: 'Mobile: Inline and bottom of search' },
  { value: 'DESKTOP_INLINE', label: 'Desktop: Inline and bottom of search' },
]

const SBVAdPreview = ({ creativeProducts, videoBlob }) => {
  const [previewType, setPreviewType] = useState(previewTypes[0])

  const renderPreview = () => {
    let className
    if (previewType.value === 'MOBILE_INLINE') {
      className = 'vertical-stack'
    } else {
      className = 'horizontal-stack'
    }

    return (
      <div className={`preview-video-contents ${className}`}>
        <video
          src={videoBlob}
          width="320"
          height="180"
          controls
        />
        <div className="product-widget">
          <div className="product-image">
            <img
              src={creativeProducts[0].image}
              alt={creativeProducts[0].name}
            />
          </div>
          <div className="product-details">
            <div className="sponsor-label">
              Sponsored
            </div>
            <div className="product-name">
              { creativeProducts[0].name }
            </div>
            <div className="product-price">
              ${ parseFloat(creativeProducts[0].price).toFixed(2) }
            </div>
            <img
              className="prime-image"
              src={PrimePng}
              alt={creativeProducts[0].name}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="sb-preview-container">
      <Select
        value={previewType}
        options={previewTypes}
        onChange={setPreviewType}
      />
      { renderPreview() }
    </div>
  )
}

export default SBVAdPreview
