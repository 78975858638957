import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import LoaderComponent from '../LoaderComponent'

import SBAdPreview from '../../CampaignCreator/Shared/SBAdPreview'
import SBVAdPreview from '../../CampaignCreator/Shared/SBVAdPreview'

import { getCreative } from '../../../redux/actions/campaign'

import {
  AD_FORMAT_COLLECTION,
  AD_FORMAT_SPOTLIGHT,
  AD_FORMAT_VIDEO,
} from '../../../utils/defaultValues'

const SBCampaignCreativeComponent = ({ campaignId }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [creative, setCreative] = useState(null)

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getCreative(accessToken, campaignId))
      setCreative(response)
      setIsLoading(false)
    })()
  }, [dispatch]) // eslint-disable-line

  const renderContent = () => {
    if (!creative || !Object.keys(creative).length) {
      return null
    }

    const { adFormat, isBrandVideo, logo, info,
      products, video, subPages } = creative

    if (
      adFormat === AD_FORMAT_COLLECTION
      || (adFormat === AD_FORMAT_SPOTLIGHT)
      || (adFormat === AD_FORMAT_VIDEO && !isBrandVideo)
    ) {
      return (
        <SBAdPreview
          adFormat={adFormat}
          basicInfo={info}
          creativeProducts={products}
          creativeSubPages={subPages}
          croppedLogo={logo}
          videoBlob={video}
        />
      )
    }

    if (adFormat === AD_FORMAT_VIDEO && isBrandVideo) {
      return (
        <SBVAdPreview
          creativeProducts={products}
          videoBlob={video}
        />
      )
    }

    return null
  }

  return (
    <div className={`campaign-creative-section${isLoading ? ' loading' : ''}`}>
      { isLoading && <LoaderComponent /> }
      { renderContent() }
    </div>
  )
}

export default SBCampaignCreativeComponent
