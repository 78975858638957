import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Toggle } from 'rsuite'
import Select from 'react-select'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import NegativeCreatorModal from '../BulkOp/NegativeCreatorModal'
import ThumbHistory from '../CampaignTableComponent/ThumbHistory'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'

import { ReactComponent as HistorySvg } from '../../assets/svg/history.svg'

import {
  capitalizeFirstLetter,
  copyToClipboard,
  tableSorter,
  calcDerivedMetrics,
  getExportValueForColumn,
  groupRecords,
  getAssociativeCampaigns,
} from '../../services/helper'

import { bulkSTColumnList } from '../../utils/defaultValues'

import { campaignTypes, matchTypes } from '../../utils/filterDef'

const initialColumns = [
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'keyword', name: 'Associated Target', className: 'col-associated-target' },
  { key: 'matchType', name: 'Match Type' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'search', name: 'Search Term', className: 'col-search-term' },
  { key: 'keyword', name: 'Associated Target', className: 'col-associated-target' },
  { key: 'matchType', name: 'Match Type' },
]

const campaignTypesWithAll = [
  { value: '', label: 'All' },
  ...campaignTypes,
]

const TargetSearchSTTable = ({ startDate, endDate, includeZeroSpend,
  isLoadingChart, chartData, onChangeDate, onLoadChart, onIncludeZeroSpend }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const stTableColumns = useSelector(state => state.pageGlobal.stTableColumns)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)
  const findTargetsData = useSelector(state => state.bulkEngine.findTargetsData)

  const [groupMode, setGroupMode] = useState(false)
  const [searchTerms, setSearchTerms] = useState([])
  const [groupedSearchTerms, setGroupedSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [selectedAdType, setSelectedAdType] = useState(campaignTypesWithAll[0])
  const [selectedMatchType, setSelectedMatchType] = useState(matchTypes[0])
  const [showNegativeCreatorModal, setShowNegativeCreatorModal] = useState(false)
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [historyData, setHistoryData] = useState({})
  const [historyPayload, setHistoryPayload] = useState({})
  const [isLifetimeVisible, setIsLifetimeVisible] = useState(false)

  useEffect(() => {
    const campaignsById = getAssociativeCampaigns(campaignsWithHistory)

    const extendedSTs = [];
    (findTargetsData.searchTerms || []).forEach((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}
      const campaignType = campaignDetail.type || ''

      if (selectedAdType.value !== '') {
        if (campaignType !== selectedAdType.value) {
          return
        }
      }

      if (selectedMatchType.value !== '') {
        if ((record.match_type || '').toLowerCase() !== selectedMatchType.value) {
          return
        }
      }

      extendedSTs.push({
        ...calcDerivedMetrics(record),
        id: `${record.campaign_id}-${record.adgroup_id}-`
          + `${record.search}-${record.keyword}-${record.match_type}`,
        campaignName: campaignDetail.name || '',
        campaignType: campaignType,
        targetingType: campaignDetail.targetingType || '',
        adgroupName: record.adgroup_name || '',
        keyword: record.keyword === '(_targeting_auto_)' ? '*' : record.keyword,
        matchType: capitalizeFirstLetter(record.match_type),
      })
    })

    setSearchTerms(extendedSTs)
    setGroupedSearchTerms(
      groupRecords(
        extendedSTs,
        'campaign_id',
        ['campaignName', 'campaignType', 'targetingType']
      )
    )
  }, [findTargetsData, campaignsWithHistory, selectedAdType, selectedMatchType])

  useEffect(() => {
    const dataBySt = {};
    (chartData.searchTerms || []).forEach((record) => {
      const key = `${record.campaign_id}-${record.adgroup_id}-`
        + `${record.search}-${record.keyword}-${record.match_type}`
      if (!dataBySt[key]) {
        dataBySt[key] = []
      }
      dataBySt[key].push(calcDerivedMetrics(record))
    })
    setHistoryData(dataBySt)
  }, [chartData])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const selection = campaignTableColumns.filter(c1 =>
      bulkSTColumnList.find(c2 => c2.key === c1.key)
    )
    if ((stTableColumns || []).includes('st_impr_rank')) {
      selection.push({
        key: 'st_impr_rank',
        label: 'Search Term Impression Rank',
        name: 'ST Imp. Rank',
      })
    }
    if ((stTableColumns || []).includes('st_impr_share')) {
      selection.push({
        key: 'st_impr_share',
        label: 'Search Term Impression Share %',
        name: 'ST Imp. Share',
      })
    }
    return [
      [...initialColumns, ...selection],
      [...initialColumnsGroup, ...selection],
      selection,
    ]
  }, [campaignTableColumns, stTableColumns])

  const handleCopy = () => {
    const sts = searchTerms.filter(st => (
      selectedSearchTerms.indexOf(st.id) !== -1
    )).map(st => st.search.trim())

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedSearchTerms.length} search term${selectedSearchTerms.length > 1 ? 's' : ''}.`
    })
  }

  const handleShowHistory = () => {
    if (!isShowHistory) {
      onLoadChart('searchTerms')
      setIsShowHistory(true)
    } else {
      setIsShowHistory(false)
    }
  }

  const handleHistoryClick = (record, column) => {
    if (!isShowHistory) {
      return
    }

    const key = `${record.campaign_id}-${record.adgroup_id}-`
      + `${record.search}-${record.keyword}-${record.match_type}`

    setHistoryPayload({
      metric: column.label,
      metricKey: column.key,
      search: record.search,
      data: (historyData[key] || []).map(item => ({
        date: item.report_date,
        value: item[column.key] || 0,
      })),
    })
  }

  const handleDateChange = (startDate, endDate) => {
    onChangeDate(startDate, endDate)
    setIsLifetimeVisible(startDate === null && endDate === null)
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By search terms"
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Ad Type</span>
          <Select
            classNamePrefix="ad-type-selector"
            options={campaignTypesWithAll}
            value={selectedAdType}
            onChange={setSelectedAdType}
          />
        </div>
        <div className="select-wrapper">
          <span>Match Type</span>
          <Select
            classNamePrefix="match-type-selector"
            options={matchTypes}
            value={selectedMatchType}
            onChange={setSelectedMatchType}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Include zero spend targets"
            checked={includeZeroSpend}
            onChange={onIncludeZeroSpend}
          />
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setShowNegativeCreatorModal(true) }}
        >
          Add Negative{selectedSearchTerms.length > 1 ? 's' : ''} to Campaign{selectedSearchTerms.length > 1 ? 's' : ''}
        </button>
        <button
          type="button"
          className="btn btn-green"
          onClick={() => { handleCopy() }}
        >
          Copy
        </button>
      </>
    )
  }

  const renderSecondaryAction = () => {
    if (isLifetimeVisible) {
      return null
    }
    return (
      <HistorySvg title="History" onClick={handleShowHistory}/>
    )
  }

  const renderST = record => (
    <>
      <div className="table-col col-search-term" title={record.search}>
        <strong className="contents">
          { record.search }
        </strong>
      </div>
      <div className="table-col col-associated-target" title={record.keyword}>
        <span className="contents">
          { record.keyword }
        </span>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      <TableCampaignCell record={record} />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.adgroup_id}-`
              + `${record.search}-${record.keyword}-${record.match_type}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-search-term">Totals:</div>
      <div className="table-col col-associated-target" />
      <div className="table-col" />
      <div className="table-col col-campaign" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'search') {
        return record.search
      }
      if (column.key === 'keyword') {
        return record.keyword
      }
      if (column.key === 'matchType') {
        return record.matchType
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-search-term">
        { record.children.length } search terms
      </div>
      <div className="table-col col-associated-target" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.adgroup_id}-`
              + `${record.search}-${record.keyword}-${record.match_type}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-search-term" title={record.search}>
        <strong className="contents">
          { record.search }
        </strong>
      </div>
      <div className="table-col col-associated-target" title={record.keyword}>
        <span className="contents">
          { record.keyword }
        </span>
      </div>
      <div className="table-col">
        { record.matchType }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.adgroup_id}-`
              + `${record.search}-${record.keyword}-${record.match_type}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-search-term" />
      <div className="table-col col-associated-target" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const sts = searchTerms.filter(st => (
    selectedSearchTerms.indexOf(st.id) !== -1
  ))

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-search-terms"
            records={groupedSearchTerms}
            idField="campaign_id"
            searchFields={['search', 'keyword']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchSTResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            isLoading={isLoadingChart}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={handleDateChange}
            sorterChild={tableSorter(['search', 'keyword', 'matchType'])}
            idFieldChild="id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName', 'search', 'keyword', 'matchType'])}
            className="table-search-terms"
            records={searchTerms || []}
            idField="id"
            searchFields={['search', 'keyword']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedSearchTerms}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchSTResult"
            columnList={bulkSTColumnList}
            columnSelection={columnSelection}
            isLoading={isLoadingChart}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderST}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedSearchTerms}
            onChangeDate={handleDateChange}
          />
        )
      }
      {
        showNegativeCreatorModal && (
          <NegativeCreatorModal
            searchTerms={sts}
            onClose={() => { setShowNegativeCreatorModal(false) }}
          />
        )
      }
      <ThumbHistory
        title={`Search Term: ${historyPayload.search}`}
        areaData={historyPayload.data || []}
        metric={historyPayload.metric}
        metricKey={historyPayload.metricKey}
        startDate={startDate}
        endDate={endDate}
        onClose={() => { setHistoryPayload({}) }}
      />
    </>
  )
}

export default TargetSearchSTTable
