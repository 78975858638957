/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import CustomTooltip from '../../components/CommonComponents/CustomTooltip'

const KeywordTrackInputModal = ({
  existingTargets,
  onConfirm,
  onClose,
}) => {
  const fileEl = useRef(null)

  const [targets, setTargets] = useState('')

  useEffect(() => {
    setTargets(existingTargets.join('\n'))
  }, [existingTargets])

  const handleFileChange = (event) => {
    if (!event.target.files.length) {
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const lines = reader.result.split(/\n/)
      if (!lines.length) {
        toast.show({
          title: 'Warning',
          description: 'There is no target to upload.',
        })
        return
      }

      // The first line contains column headings.
      const targetIndex = lines[0].replace('\r', '').split(',')
        .findIndex(heading => heading.toLowerCase() === 'target')

      if (targetIndex === -1) {
        toast.show({
          title: 'Warning',
          description: 'Unable to detect CSV headers. '
            + 'Please make sure you have the "target" header in your file.',
        })
      }

      const newTargets = []
      for (let i = 1; i < lines.length; i += 1) {
        const fields = lines[i].replace('\r', '').split(',')
        if (typeof fields[targetIndex] === 'undefined') {
          continue;
        }

        newTargets.push(fields[targetIndex].replace('\r', '').split('"').join(''))
      }

      if (targets) {
        setTargets(`${targets}\n${newTargets.join('\n')}`)
      } else {
        setTargets(newTargets.join('\n'))
      }
    }

    reader.readAsText(event.target.files[0])
  }

  const handleUploadClick = (event) => {
    event.preventDefault()

    fileEl.current.value = null
    fileEl.current.click()
  }

  const handleConfirm = () => {
    if (targets.length > 1024) {
      toast.show({
        title: 'Warning',
        description: 'The maximum number of targets to track is reached.',
      })
      return
    }

    onConfirm(targets)
  }

  const nonEmptyTargets = (targets || '').split('\n').filter(target => target !== '')

  return (
    <Modal className="keyword-track-input-modal" backdrop="static" size="sm" show>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Enter Targets to Track
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="description-box">
          Enter one target per line, or&nbsp;
          <a
            href="#"
            onClick={handleUploadClick}
          >
            upload a CSV file.

            <CustomTooltip>
              Upload a CSV file with {'"'}target{'"'} header.
              Use a comma as a field separator and a new line for
              a new pair of values.
            </CustomTooltip>
          </a>
          <input
            type="file"
            ref={fileEl}
            accept=".csv"
            onChange={handleFileChange}
          />
        </p>
        <textarea
          value={targets}
          onChange={(event) => { setTargets(event.target.value) }}
        />
        <div>
          Total number: <strong>{ nonEmptyTargets.length }</strong>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default KeywordTrackInputModal
