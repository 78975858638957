// Actions for smart pilot manager.
import { callGet, callPost } from '../../services/axios'
import {
  GET_AP,
  GET_AP_SUCCESS,
  GET_AP_FAIL,
  GET_AP_AD_GROUP,
  GET_AP_AD_GROUP_SUCCESS,
  GET_AP_AD_GROUP_FAIL,
  SAVE_AP_SUCCESS,
  SAVE_AP_TEMPLATE_SUCCESS,
  GET_AP_TEMPLATES,
  GET_AP_TEMPLATES_SUCCESS,
  GET_AP_TEMPLATES_FAIL,
  TURN_AP_BULK,
  TURN_AP_BULK_SUCCESS,
  TURN_AP_BULK_FAIL,
  TURN_AP_RULE_SUCCESS,
  APPLY_TEMPLATE_BULK_SUCCESS,
  UPDATE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from '../actionTypes/ap'

import { GET_TEMPLATE_TO_EDIT } from '../actionTypes/ruleManager'

import { COIN_EARNED } from '../actionTypes/coin'

import { COIN_TYPE_AP_SETUP } from '../../utils/coin'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

export const getAp = (accessToken, campaignId, signal) => (dispatch, getState) => {
  dispatch({
    type: GET_AP,
  })

  const { header: { currentUserId } } = getState()

  return callGet('/ap/getSettings', accessToken, {
    userId: currentUserId,
    campaignId,
  }, signal).then((response) => {
    dispatch({
      type: GET_AP_SUCCESS,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_AP_FAIL,
    })
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to load Smart Pilot settings.',
      })
    }
  })
}

export const getApAdgroup = (accessToken, campaignId, adgroupId, signal) => (dispatch) => {
  dispatch({
    type: GET_AP_AD_GROUP,
  })

  callGet('/ap/getSettingsForAdgroup', accessToken, {
    campaignId,
    adgroupId,
  }, signal).then((response) => {
    dispatch({
      type: GET_AP_AD_GROUP_SUCCESS,
      data: response.data,
      adgroupId,
    })
  }).catch((error) => {
    dispatch({
      type: GET_AP_AD_GROUP_FAIL,
    })
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to load Smart Pilot settings.',
      })
    }
  })
}

export const getApTestResults = (accessToken, { campaignId, start, duration }, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/ap/getTestResults', accessToken, {
    userId: currentUserId,
    campaignId,
    start,
    duration,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

// Retrieve campaigns of all ad types to apply smart pilot settings.
// FIXME: If there are cached campaigns, use them.
export const getApCampaignsToApply = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/ap/getCampaignsToApply', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

// Retrieve a list of smart pilot templates.
export const getSpTemplates = (accessToken, signal) => (dispatch) => {
  dispatch({
    type: GET_AP_TEMPLATES,
  })

  callGet('/ap/getTemplates', accessToken, {}, signal).then((response) => {
    dispatch({
      type: GET_AP_TEMPLATES_SUCCESS,
      data: response.data,
    })
  }).catch(() => {
    dispatch({
      type: GET_AP_TEMPLATES_FAIL,
    })
  })
}

// Retrieve details of a given template.
export const getSpTemplate = (accessToken, templateId) => (dispatch) => {
  return callGet('/ap/getTemplate', accessToken, {
    templateId,
  }).then((response) => {
    dispatch({
      type: GET_TEMPLATE_TO_EDIT,
      template: response.data,
    })
    return response
  })
}

// Save settings for a given campaign.
export const saveAp = (accessToken, payload) => (dispatch) => {
  return callPost('/ap/setup', payload, accessToken).then((response) => {
    dispatch({
      type: SAVE_AP_SUCCESS,
      data: [payload.campaign_id.toString()],
    })

    if (response.data.amount) {
      dispatch({
        type: COIN_EARNED,
        history: {
          type: COIN_TYPE_AP_SETUP,
          amount: response.data.amount,
          newlyEarned: true,
        },
      })
    }

    toast.show({
      title: 'Success',
      description: 'Successfully applied Smart Pilot.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to apply Smart Pilot.',
    })
  })
}

// Save settings to multiple campaigns.
export const saveApMultiple = (accessToken, payload) => (dispatch) => {
  return callPost('/ap/setupMultiple', payload, accessToken).then((response) => {
    dispatch({
      type: SAVE_AP_SUCCESS,
      data: payload.campaignIds.map(campaignId => campaignId.toString()),
    })

    if (response.data.amount) {
      dispatch({
        type: COIN_EARNED,
        history: {
          type: COIN_TYPE_AP_SETUP,
          amount: response.data.amount,
          newlyEarned: true,
        },
      })
    }

    toast.show({
      title: 'Success',
      description: 'Successfully applied Smart Pilot to multiple campaigns.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to apply Smart Pilot to multiple campaigns.',
    })
  })
}

// Save settings as a template.
export const saveSpTemplate = (accessToken, name, needApply, payload) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  const tplPayload = Object.assign({
    name,
    needApply,
  }, payload)

  if (!tplPayload.user_id) {
    tplPayload.user_id = currentUserId
  }

  return callPost('/ap/saveTemplate', tplPayload, accessToken).then((response) => {
    dispatch({
      type: SAVE_AP_TEMPLATE_SUCCESS,
      data: {
        id: response.data.id,
        name,
        apData: response.data.apData,
        campaignId: payload.campaign_id,
        needApply,
      }
    })

    toast.show({
      title: 'Success',
      description: 'Successfully saved template.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to save template.',
    })
  })
}

// Turn on/off smart pilot in bulk.
export const turnBulk = (accessToken, campaignIds) => (dispatch, getState) => {
  dispatch({
    type: TURN_AP_BULK,
  })

  const { header: { currentUserId } } = getState()

  return callPost('/ap/turnBulk', {
    userId: currentUserId,
    campaignIds,
  }, accessToken).then((response) => {
    dispatch({
      type: TURN_AP_BULK_SUCCESS,
      data: {
        campaignIds: response.data.successIds.map(campaignId => campaignId.toString()),
      }
    })

    if (!response.data.failedCampaigns.length) {
      toast.show({
        title: 'Success',
        description: 'Toggled successfully.',
      })
    } else {
      let description = 'The following campaigns can\'t be turned on:<br />'
      description += response.data.failedCampaigns.map(({ name }) => `- ${name}`).join('<br />')
      description += '<br />You must first save settings for these campaigns.<br />'
      description += 'Visit the Smart Pilot for each campaign or use a template and apply settings.'
      toast.show({
        title: 'Danger',
        description,
        duration: 5000,
      })
    }
  }).catch((error) => {
    dispatch({
      type: TURN_AP_BULK_FAIL
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to toggle.',
    })
  })
}

// Turn on/off rule for an individual campaign/adgroup
export const turnRule = (accessToken, campaignId, adgroupId, status) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/ap/turnRule', {
    userId: currentUserId,
    campaignId,
    adgroupId,
    status,
  }, accessToken).then(() => {
    dispatch({
      type: TURN_AP_RULE_SUCCESS,
      data: {
        campaignId: campaignId.toString(),
        adgroupId: (adgroupId || '').toString(),
        status,
      },
    })

    toast.show({
      title: 'Success',
      description: 'Toggled successfully.',
    })

    return true
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to toggle.',
    })

    return false
  })
}

// Apply AP template in bulk.
export const applyTemplateBulk = (accessToken, templateId, campaignIds, timeZone) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/ap/applyTemplateBulk', {
    userId: currentUserId,
    templateId,
    campaignIds,
    timeZone,
  }, accessToken).then((response) => {
    dispatch({
      type: APPLY_TEMPLATE_BULK_SUCCESS,
      data: response.data,
    })

    toast.show({
      title: 'Success',
      description: 'The selected template has been applied successfully.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to apply the template.',
    })
  })
}

// Update settings for a given template.
export const updateSpTemplate = (accessToken, payload) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost(
    '/ap/updateTemplate',
    Object.assign(payload, { user_id: currentUserId }),
    accessToken
  ).then(() => {
    dispatch({
      type: UPDATE_TEMPLATE_SUCCESS,
      data: payload,
    })

    toast.show({
      title: 'Success',
      description: 'Successfully saved.',
    })
  })
}

// Delete a given template.
export const deleteSpTemplate = (accessToken, templateId) => (dispatch) => {
  return callPost('/ap/deleteTemplate', {
    templateId,
  }, accessToken).then(() => {
    dispatch({
      type: DELETE_TEMPLATE_SUCCESS,
      data: templateId,
    })

    toast.show({
      title: 'Success',
      description: 'Successfully deleted.',
    })
  })
}
