import React from 'react'
import { useSelector } from 'react-redux'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'
import MetricDifference from './MetricDifference'

import {
  formatValue,
  formatCurrency,
  calcDerivedMetrics,
  calculateMetricDiff,
  getCurrencyForCountry,
} from '../../services/helper'

import { defaultMetricValue } from '../../utils/defaultValues'

const AggregatedTable = ({ diffDisplayType, accountList, isLoading, exchangeRates }) => {
  const currencySign = useSelector(state => state.header.currencySign)

  const renderRecord = (record) => {
    const code = getCurrencyForCountry(record.country).currencyCode

    const derivedRecord = record.accountKPI ? calcDerivedMetrics(record.accountKPI) : { ...defaultMetricValue }
    const pastDerivedRecord = record.pastAccountKPI ? calcDerivedMetrics(record.pastAccountKPI) : { ...defaultMetricValue }

    derivedRecord.revenue /= (exchangeRates[code] || 1)
    derivedRecord.cost /= (exchangeRates[code] || 1)
    derivedRecord.cpc /= (exchangeRates[code] || 1)

    pastDerivedRecord.revenue /= (exchangeRates[code] || 1)
    pastDerivedRecord.cost /= (exchangeRates[code] || 1)
    pastDerivedRecord.cpc /= (exchangeRates[code] || 1)

    const difference = calculateMetricDiff(derivedRecord, pastDerivedRecord)

    let accountName = record.sellerId
    if (record.brandName) {
      accountName = (
        <>
          { record.brandName }<br />
          <small>({ record.sellerId })</small>
        </>
      )
    }

    return (
      <>
        <div className="table-col account-name">
          { accountName }
        </div>
        <div className="table-col country-code">
          {(record.country || '').toUpperCase()}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.impressions, 'number', 0) }</span>
          { (derivedRecord.impressions > 0 && pastDerivedRecord.impressions > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.impressions} />
          )}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.clicks, 'number', 0) }</span>
          { (derivedRecord.clicks > 0 && pastDerivedRecord.clicks > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.clicks} />
          )}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.orders, 'number', 0) }</span>
          { (derivedRecord.orders > 0 && pastDerivedRecord.orders > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.orders} />
          )}
        </div>
        <div className="table-col">
          <span>{ formatCurrency(derivedRecord.cost, currencySign, 1) }</span>
          { (derivedRecord.cost > 0 && pastDerivedRecord.cost > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.cost} decimals={[2, 2]} noTrending/>
          )}
        </div>
        <div className="table-col">
          <span>{ formatCurrency(derivedRecord.revenue, currencySign, 1) }</span>
          { (derivedRecord.revenue > 0 && pastDerivedRecord.revenue > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.revenue} decimals={[2, 2]}/>
          )}
        </div>
        <div className="table-col">
          <span>{ formatCurrency(derivedRecord.cpc, currencySign, 1) }</span>
          { (derivedRecord.cpc > 0 && pastDerivedRecord.cpc > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.cpc} decimals={[2, 2]}/>
          )}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.ctr, 'percent', 3) }</span>
          { (derivedRecord.ctr > 0 && pastDerivedRecord.ctr > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.ctr} decimals={[3, 3]}/>
          )}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.conversion, 'percent', 2) }</span>
          { (derivedRecord.conversion > 0 && pastDerivedRecord.conversion > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.conversion} decimals={[2, 2]}/>
          )}
        </div>
        <div className="table-col">
          <span>{ formatValue(derivedRecord.acos, 'percent', 2)}</span>
          { (derivedRecord.acos > 0 && pastDerivedRecord.acos > 0) && (
            <MetricDifference className="diff-value" displayType={diffDisplayType} difference={difference?.acos} decimals={[2, 2]}/>
          )}
        </div>
      </>
    )
  }

  return (
    <div className="report-widget">
      <CustomTable
        className="table-accounts"
        records={accountList || []}
        idField="user_id"
        searchFields={['brandName']}
        noCheckBox
        renderRecord={renderRecord}
        isLoading={isLoading}
        noSearch
      >
        <div className="table-col account-name">Account</div>
        <div className="table-col country-code">Country</div>
        <div className="table-col">Impressions</div>
        <div className="table-col">Clicks</div>
        <div className="table-col">Orders</div>
        <div className="table-col">Ad Spend</div>
        <div className="table-col">Revenue</div>
        <div className="table-col">CPC</div>
        <div className="table-col">CTR</div>
        <div className="table-col">Conversion %</div>
        <div className="table-col">ACoS</div>
      </CustomTable>
    </div>
  )
}

export default AggregatedTable
