import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  GET_DETAILS_START,
  GET_DETAILS_SUCCEED,
  GET_DETAILS_FAIL,
  GET_ADGROUPS_SUCCEED,
  GET_LOGS_SUCCEED,
  UPDATE_PORTFOLIO_START,
  UPDATE_PORTFOLIO_SUCCEED,
  UPDATE_PORTFOLIO_FAIL,
  GET_ST_DATA_START,
  GET_ST_DATA_SUCCEED,
  GET_ST_DATA_FAIL,
  GET_NEGATIVE_KW_DATA_START,
  GET_NEGATIVE_KW_DATA_SUCCEED,
  GET_NEGATIVE_KW_DATA_FAIL,
  GET_BID_DATA_SUCCEED,
  GET_BID_TARGET_DATA_SUCCEED,
  CHANGE_TARGET_SUCCEED,
  GET_NEGATIVE_WORD_DATA_START,
  GET_NEGATIVE_WORD_DATA_SUCCEED,
  GET_NEGATIVE_WORD_DATA_FAIL,
  GET_NEGATIVE_TARGET_DATA_START,
  GET_NEGATIVE_TARGET_DATA_SUCCEED,
  GET_NEGATIVE_TARGET_DATA_FAIL,
  APPLY_PLACEMENT_BIDDING_SUCCEED,
  GET_KEYWORD_DATA_START,
  GET_KEYWORD_RALATED_START,
  GET_KEYWORD_RALATED_SUCCEED,
  GET_KEYWORD_RALATED_FAIL,
  GET_NEGATIVE_TARGET_START,
  GET_NEGATIVE_TARGET_SUCCEED,
  GET_NEGATIVE_TARGET_FAIL,
} from '../actionTypes/campaignDetail'

export const getDetails = (accessToken, campaignId, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  dispatch({
    type: GET_DETAILS_START,
  })

  callGet('/campaignDetail/getDetails', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    if (response.data) {
      dispatch({
        type: GET_DETAILS_SUCCEED,
        data: response.data,
      })
    }
  }).catch(() => {
    dispatch({
      type: GET_DETAILS_FAIL,
    })
  })
}

export const getKpi = (accessToken, campaignId, campaignType, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/campaignDetail/getKpi', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve KPI data.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getAdgroups = (accessToken, campaignId, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  dispatch({
    type: GET_DETAILS_START,
  })

  callGet('/campaignDetail/getAdgroups', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
  }).then((response) => {
    dispatch({
      type: GET_ADGROUPS_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve ad groups.',
    })
  })
}

export const getNegativeKeywords = (accessToken, campaignId, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignDetail/getNegativeKeywords', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve negative keywords.',
    })
    return []
  })
}

export const getNegativeTargets = (accessToken, campaignId, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignDetail/getNegativeTargets', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve negative targets.',
    })
    return []
  })
}

export const getStream = (accessToken, campaignId, campaignType, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/campaignDetail/getStream', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve stream data.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getLogs = (accessToken, campaignId, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/campaignDetail/getLogs', accessToken, {
    userId: currentUserId,
    campaignId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    dispatch({
      type: GET_LOGS_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve logs.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getSummary = (accessToken, type, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignDetail/getSummary', accessToken, {
    userId: currentUserId,
    type,
    campaignId,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get summary.',
    })
    return []
  })
}

// Retrieve a list of campaigns for campaign selector.
export const getCampaignList = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignDetail/getCampaignList', accessToken, {
    userId: currentUserId,
  }).then(response => response.data)
}

export const updatePortfolios = (accessToken, campaigns,
  portfolioId = null, portfolioName = null, newPortfolioName = null, immediate = true) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  if (immediate) {
    dispatch({
      type: UPDATE_PORTFOLIO_START,
    })
  }
  return callPost('/campaign/updatePortfolios', {
    userId: currentUserId,
    campaigns,
    portfolioId,
    newPortfolioName,
    immediate,
  }, accessToken).then((response) => {
    if (immediate) {
      dispatch(processCampaignPortfoliosUpdate(
        response.data,
        portfolioName || newPortfolioName
      ))
    }
    return response
  }).catch((error) => {
    dispatch({ type: UPDATE_PORTFOLIO_FAIL })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update portfolios of campaigns.',
    })
  })
}

export const processCampaignPortfoliosUpdate = (response, portfolioName) => (dispatch, getState) => {
  const count = response.ids.length
  if (!count) {
    return
  }

  dispatch({
    type: UPDATE_PORTFOLIO_SUCCEED,
    data: {
      campaignIds: response.ids,
      portfolioId: response.portfolioId,
      portfolioName,
    },
  })

  let description = ''
  if (!response.portfolioId) {
    if (count === 1) {
      description = 'One campaign has been removed from old portfolio.'
    } else {
      description = `${count} campaigns have been removed from old portfolios.`
    }
  } else {
    if (count === 1) {
      description = 'The portfolio of one campaign has been updated.'
    } else {
      description = `The portfolios of ${count} campaigns have been updated.`
    }
  }

  toast.show({
    title: 'Success',
    description,
  })
}

export const archiveNegativeKeywords = (accessToken, campaignType, negatives) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  let sanitized
  if (campaignType === 'sp') {
    sanitized = negatives.map(negative => ({
      keywordId: parseInt(negative.keywordId, 10),
      isCampaignLevel: typeof negative.campaign_level !== 'undefined',
    }))
  } else {
    // For SB, we need campaign and ad group IDs as well.
    sanitized = negatives.map(negative => ({
      campaignId: negative.campaign_id,
      adGroupId: negative.adgroup_id,
      keywordId: negative.keyword_id,
    }))
  }

  return callPost('/campaignDetail/archiveNegativeKeywords', {
    userId: currentUserId,
    campaignType,
    negatives: sanitized,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Archived successfully.',
    })
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to archive negative keywords.'
    })
    return []
  })
}

export const archiveNegativeTargets = (accessToken, campaignType, negatives) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  let sanitized
  if (campaignType === 'sp') {
    sanitized = negatives.map(negative => ({
      targetId: parseInt(negative.target_id, 10),
      isCampaignLevel: typeof negative.campaign_level !== 'undefined',
    }))
  } else if (campaignType === 'sd') {
    sanitized = negatives.map(negative => ({
      targetId: parseInt(negative.target_id, 10),
    }))
  } else {
    sanitized = negatives.map(negative => ({
      targetId: parseInt(negative.target_id, 10),
      adGroupId: parseInt(negative.adgroup_id, 10),
    }))
  }

  return callPost('/campaignDetail/archiveNegativeTargets', {
    userId: currentUserId,
    campaignType,
    negatives: sanitized,
  }, accessToken).then((response) => {
    toast.show({
      title: 'Success',
      description: 'Archived successfully.',
    })
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to archive negative targets.',
    })
    return []
  })
}

export const getSkuData = (accessToken, { campaignId, campaignType }) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/campaignDetail/getSkuData', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get sku data.',
    })
    return []
  })
}

export const getSTData = (accessToken, data, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  const {
    campaignId,
    campaignType,
    isProfitable,
    stOnly,
    targetAcos,
  } = data

  dispatch({
    type: GET_ST_DATA_START,
  })

  callGet('/campaignDetail/getStData', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    isProfitable,
    stOnly,
    targetAcos,
  }, signal).then((response) => {
    dispatch({
      type: GET_ST_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_ST_DATA_FAIL,
    })
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to load search terms.',
      })
    }
  })
}

export const getNegativeKWData = (accessToken, { campaignId, campaignType }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  dispatch({
    type: GET_NEGATIVE_KW_DATA_START,
  })
  callGet('/campaignDetail/getNegativeKeywords', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
  }).then((response) => {
    dispatch({
      type: GET_NEGATIVE_KW_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_NEGATIVE_KW_DATA_FAIL,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get negative keywords.',
    })
  })
}

export const getNegativeAsinData = (accessToken, { campaignType, campaignId, adGroupIds }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/campaignDetail/getNegativeAsins', accessToken, {
    userId: currentUserId,
    campaignType,
    campaignId,
    adGroupIds,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get negative ASINs.',
    })
    return []
  })
}

export const getBidData = (accessToken, data, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  const {
    campaignId,
    adgroupId,
    campaignType,
    targetAcos,
    isProfitable,
  } = data

  return callGet('/campaignDetail/getBidData', accessToken, {
    userId: currentUserId,
    campaignId,
    adgroupId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    targetAcos,
    isProfitable,
  }, signal).then((response) => {
    dispatch({
      type: GET_BID_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to load keywords.',
      })
    }
  })
}

export const getBidTargetData = (accessToken, params, signal) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  const {
    campaignId,
    adgroupId,
    campaignType,
    targetAcos,
    isProfitable,
    isNonEndemicAccount = false,
    cpcTarget = 0,
  } = params

  return callGet('/campaignDetail/getBidTargetData', accessToken, {
    userId: currentUserId,
    campaignId,
    adgroupId: adgroupId,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    targetAcos,
    isProfitable,
    isNonEndemicAccount,
    cpcTarget,
  }, signal).then((response) => {
    dispatch({
      type: GET_BID_TARGET_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get targets.',
      })
    }
  })
}

export const changeTarget = (accessToken, targets, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/targeting/updateTargets', {
    userId: currentUserId,
    targets,
    campaignType,
  }, accessToken).then(() => {
    // @FIXME: Update store only for successfully updated targets.
    dispatch({
      type: CHANGE_TARGET_SUCCEED,
      data: {
        targets,
      },
    })
    toast.show({
      title: 'Success',
      description: 'Targets are successfully updated.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to update targets.',
    })
  })
}

export const getNegativeWordData = (accessToken, { campaignId, adgroupIds, campaignType, hideKeywords }) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  dispatch({
    type: GET_NEGATIVE_WORD_DATA_START,
  })
  callGet('/campaignDetail/getNegativeWordData', accessToken, {
    userId: currentUserId,
    campaignId,
    adgroupIds,
    campaignType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    stOnly: hideKeywords,
  }).then((response) => {
    dispatch({
      type: GET_NEGATIVE_WORD_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_NEGATIVE_WORD_DATA_FAIL,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get negative words.',
    })
  })
}

export const getNegativeTargetData = (accessToken, { campaignId, adgroupId, targetAcos }) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  dispatch({
    type: GET_NEGATIVE_TARGET_DATA_START,
  })
  callGet('/campaignDetail/getNegativeTargetData', accessToken, {
    userId: currentUserId,
    campaignId,
    adgroupId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
    targetAcos,
  }).then((response) => {
    dispatch({
      type: GET_NEGATIVE_TARGET_DATA_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_NEGATIVE_TARGET_DATA_FAIL,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get negative targets.',
    })
  })
}

export const getPlacementData = (accessToken, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()

  return callGet('/campaignDetail/getPlacementData', accessToken, {
    userId: currentUserId,
    campaignId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve placements',
    })
    return {}
  })
}

// Apply recommended placement bidding.
export const applyPlacementBidding = (accessToken, { campaignId, bidding, oldBidding, baseBid }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/campaignDetail/applyBiddingRecommendation', {
    userId: currentUserId,
    campaignId,
    bidding,
    oldBidding,
    baseBid,
  }, accessToken).then(() => {
    dispatch({
      type: APPLY_PLACEMENT_BIDDING_SUCCEED,
      data: {
        bidding,
      },
    })

    toast.show({
      title: 'Success',
      description: 'Successfully applied the recommended placement bids.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to apply the recommended placement bids.',
    })
  })
}

export const getKeywordData = (accessToken, campaignId) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  dispatch({
    type: GET_KEYWORD_DATA_START,
  })
  return callGet('/campaignDetail/getKeywordData', accessToken, {
    userId: currentUserId,
    campaignId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve keywords.',
    })
    return []
  })
}

export const getKeywordRelatedData = (accessToken, { campaignId, keyword, matchType, acosProfitZone }) => (dispatch, getState) => {
  const { header: { currentUserId, currentStartDate, currentEndDate } } = getState()
  dispatch({
    type: GET_KEYWORD_RALATED_START,
  })
  callGet('/campaign/getKeywordRelatedData', accessToken, {
    userId: currentUserId,
    campaignId,
    keyword,
    matchType,
    acosProfitZone,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
}).then((response) => {
    dispatch({
      type: GET_KEYWORD_RALATED_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    dispatch({
      type: GET_KEYWORD_RALATED_FAIL,
      data: error,
    })
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve unprofitable search terms.',
    })
  })
}

export const getNegativeTargetList = (accessToken, campaignId, campaignType) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  dispatch({
    type: GET_NEGATIVE_TARGET_START,
  })
  callGet('/campaignDetail/getNegativeTargets', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType
  }).then((response) => {
    dispatch({
      type: GET_NEGATIVE_TARGET_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve negative targets.',
    })
    dispatch({
      type: GET_NEGATIVE_TARGET_FAIL,
    })
  })
}

export const getTargets = (accessToken, { campaignId, campaignType, adgroupId, targetType }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/campaignDetail/getTargets', accessToken, {
    userId: currentUserId,
    campaignId,
    campaignType,
    adgroupId,
    targetType,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve targets.',
    })
    return []
  })
}
