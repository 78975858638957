import React, { useMemo } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { formatISO, getTime, startOfDay, subYears, getDate, format } from 'date-fns'

import { formatCurrency } from '../../services/helper'

const SalesChart = ({ stats, currencySign, currencyRate }) => {
  const [chartData, ticks] = useMemo(() => {
    const now = Math.floor(getTime(startOfDay(new Date())) / 1000)
    const yearAgo = Math.floor(getTime(startOfDay(subYears(new Date(), 1))) / 1000)

    const statByData = {}
    stats.forEach((record) => {
      statByData[record.purchase_date] = parseFloat(record.revenue)
    })

    const statForFullRange = []
    const ticks = []
    for (let ts = yearAgo; ts <= now; ts += 3600 * 24) {
      const dateStr = formatISO(ts * 1000, { representation: 'date' })

      const value = {
        purchase_date: dateStr,
        revenue: statByData[dateStr] || 0,
      }

      // Only for the first day of month, we show ticks.
      if (getDate(ts * 1000) === 1) {
        value.date = format(ts * 1000, 'MM-yy')
        ticks.push(format(ts * 1000, 'MM-yy'))
      }

      statForFullRange.push(value)
    }

    return [statForFullRange, ticks]
  }, [stats])

  const formatter = value => formatCurrency(value, currencySign, currencyRate )
  const labelFormatter = (_, value) => {
    if (value.length) {
      return value[0].payload.purchase_date
    }
    return ''
  }

  return (
    <div className="sales-chart-container">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={300}
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: -15,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" ticks={ticks} />
          <YAxis domain={[0, 'auto']} />
          <Tooltip
            formatter={formatter}
            labelFormatter={labelFormatter}
          />
          <Line
            type="monotone"
            name="Sales"
            dataKey="revenue"
            stroke="#ffa7d9"
            strokeWidth={2}
            activeDot={true}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default SalesChart
