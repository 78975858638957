import React, { useState } from 'react'
import Select from 'react-select'

const previewTypes = [
  { value: 'MOBILE', label: 'Amazon mobile rectangle' },
  { value: 'RECT', label: 'Rectangle' },
  { value: 'BILLBOARD', label: 'Billboard' },
]

const SDAdVideoPreview = ({ products, hasLogo, hasHeadline,
  videoBlob, croppedLogo, headline }) => {
  const [previewType, setPreviewType] = useState(previewTypes[0])

  const renderImageSection = (itemToRender) => {
    if (itemToRender === 0) {
      return null
    }

    const slots = []

    const logoEl = (
      <div className="product-image" key="logo-image">
        <img src={croppedLogo} alt="logo" />
      </div>
    )

    const headlineEl = (
      <div className="headline" key="headline">
        { headline }
      </div>
    )

    switch (itemToRender) {
      case 1:
        slots.push(headlineEl)
        break
      case 2:
        slots.push(logoEl)
        break
      case 3:
        slots.push(logoEl)
        slots.push(headlineEl)
        break
      default:
        break
    }

    return (
      <div className="info-wrapper">
        { slots }
      </div>
    )
  }

  const renderInfoSection = (product, itemToRender) => {
    const slots = []

    const productEl = (
      <div className="product-name" key="product-name">
        { product.name }
      </div>
    )

    const productImageEl = (
      <div className="product-image" key="product-image">
        <img
          src={product.image}
          alt={product.name}
        />
      </div>
    )

    const logoEl = (
      <div className="product-image" key="logo-image">
        <img src={croppedLogo} alt="logo" />
      </div>
    )

    const headlineEl = (
      <div className="headline" key="headline">
        { headline }
      </div>
    )

    const shopEl = (
      <div className="shop-link" key="shop-link">
        Shop now &gt;
      </div>
    )

    let className = ''
    if (previewType.value === 'MOBILE') {
      switch (itemToRender) {
        case 0:
        case 1:
          slots.push(productImageEl)
          slots.push(productEl)
          break
        case 2:
          slots.push(logoEl)
          slots.push(productEl)
          break
        case 3:
          slots.push(logoEl)
          slots.push(headlineEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'RECT') {
      switch (itemToRender) {
        case 0:
          slots.push(productImageEl)
          slots.push(productEl)
          slots.push(shopEl)
          break
        case 1:
          slots.push(productImageEl)
          slots.push(productEl)
          break
        case 2:
          slots.push(logoEl)
          slots.push(productEl)
          slots.push(shopEl)
          break
        case 3:
          slots.push(logoEl)
          slots.push(productEl)
          break
        default:
          break
      }
    } else if (previewType.value === 'BILLBOARD') {
      slots.push(productImageEl)
      slots.push(productEl)

      if (itemToRender === 0) {
        className = ' info-horizontal'
      }
    }

    return (
      <div className={`info-wrapper${className}`}>
        { slots }
      </div>
    )
  }

  const renderPreview = () => {
    const product = products[0]
    const isLogoActive = !!(hasLogo && croppedLogo)
    const isHeadlineActive = !!(hasHeadline && headline !== '')

    const forBillboard = previewType.value === 'BILLBOARD'
    let className
    if (previewType.value === 'MOBILE') {
      className = 'preview-page-video-mobile'
    } else if (previewType.value === 'RECT') {
      className = 'preview-page-video-rect'
    } else if (forBillboard) {
      className = 'preview-page-video-billboard'
    }

    const itemToRender = (isLogoActive ? 1 : 0) * 2
      + (isHeadlineActive ? 1 : 0)

    return (
      <div className={`preview-video-contents ${className}`}>
        { forBillboard && renderImageSection(itemToRender) }
        <video
          src={videoBlob}
          width={forBillboard ? 240 : 320}
          height={forBillboard ? 135 : 180}
          controls
        />
        { renderInfoSection(product, itemToRender) }
      </div>
    )
  }

  return (
    <div className="sd-preview-container">
      <Select
        value={previewType}
        options={previewTypes}
        onChange={setPreviewType}
      />
      { renderPreview() }
    </div>
  )
}

export default SDAdVideoPreview
