import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { InputNumber } from 'rsuite'

import BidRuleSection from '../RuleManagerComponents/BidRuleSection'
import CampaignRuleSection from '../RuleManagerComponents/CampaignRuleSection'
import ModifierRuleSection from '../RuleManagerComponents/ModifierRuleSection'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import {
  RULE_TYPE_BID,
  RULE_TYPE_CAMPAIGN,
  RULE_TYPE_TOS_MODIFIER,
  RULE_TYPE_PRODUCT_MODIFIER,
  RULE_TYPE_REST_MODIFIER,
  ruleBidActionList,
  ruleCampaignActionList,
  ruleModifierActionList,
  RULE_BID_ACTION_PAUSE,
  RULE_CAMPAIGN_ACTION_PAUSE,
} from '../../utils/defaultValues'

export const RULE_METRIC_ACOS = 'acos'
export const RULE_METRIC_ROAS = 'roas'
export const RULE_METRIC_IMPRESSIONS = 'impressions'
export const RULE_METRIC_CPC = 'cpc'
export const RULE_METRIC_CTR = 'ctr'
export const RULE_METRIC_CONVERSION = 'conversion'
export const RULE_METRIC_NTB_SALES = 'ntb_sales'

export const RULE_COMPARISON_BELOW = 'below'
export const RULE_COMPARISON_ABOVE = 'above'

const ruleMetricList = [
  { value: RULE_METRIC_ACOS, label: 'ACoS' },
  { value: RULE_METRIC_ROAS, label: 'ROAS' },
  { value: RULE_METRIC_IMPRESSIONS, label: 'Impressions' },
  { value: RULE_METRIC_CPC, label: 'Ave CPC' },
  { value: RULE_METRIC_CTR, label: 'CTR %' },
  { value: RULE_METRIC_CONVERSION, label: 'Conversion %' },
  { value: RULE_METRIC_NTB_SALES, label: 'NTB Sales' },
]

const ruleComparisonList = [
  { value: RULE_COMPARISON_BELOW, label: 'Below' },
  { value: RULE_COMPARISON_ABOVE, label: 'Above' },
]

const RuleMetric = ({ slot, hasSpCampaign, fromTemplate, onChange, onRemove }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const currentValues = useMemo(() => {
    const values = {
      metric: null,
      comparison: null,
      value: null,
      lookback: 30,
      bidAction: null,
      bidValue: null,
      campaignAction: null,
      campaignValue: null,
      campaignLimit: null,
      tosModAction: null,
      tosModValue: null,
      productModAction: null,
      productModValue: null,
      restModAction: null,
      restModValue: null,
    }

    if (slot.m) {
      values.metric = ruleMetricList.find(option =>
        option.value === slot.m
      )
    }

    if (slot.c) {
      values.comparison = ruleComparisonList.find(option =>
        option.value === slot.c
      )
    }

    if (slot.v) {
      values.value = slot.v
    }

    if (slot.l) {
      values.lookback = slot.l
    }

    if (slot.r && slot.r.length) {
      slot.r.forEach((rule) => {
        if (rule.t === RULE_TYPE_BID) {
          values.bidAction = ruleBidActionList.find(option =>
            option.value === rule.a
          )

          if (rule.v) {
            values.bidValue = rule.v
          }
        } else if (rule.t === RULE_TYPE_CAMPAIGN) {
          values.campaignAction = ruleCampaignActionList.find(option =>
            option.value === rule.a
          )

          if (rule.v) {
            values.campaignValue = rule.v
          }

          if (rule.l) {
            values.campaignLimit = rule.l
          }
        } else if (rule.t === RULE_TYPE_TOS_MODIFIER) {
          values.tosModAction = ruleModifierActionList.find(option =>
            option.value === rule.a
          )

          if (rule.v) {
            values.tosModValue = rule.v
          }
        } else if (rule.t === RULE_TYPE_PRODUCT_MODIFIER) {
          values.productModAction = ruleModifierActionList.find(option =>
            option.value === rule.a
          )

          if (rule.v) {
            values.productModValue = rule.v
          }
        } else if (rule.t === RULE_TYPE_REST_MODIFIER) {
          values.restModAction = ruleModifierActionList.find(option =>
            option.value === rule.a
          )

          if (rule.v) {
            values.restModValue = rule.v
          }
        }
      })
    }

    return values
  }, [slot])

  const isReadOnly = useMemo(() => {
    return fromTemplate === true
      || !!(slot.campaign || (slot.template && slot.template.campaign))
  }, [fromTemplate, slot])

  const campaignInfo = useMemo(() => {
    if (slot.campaign) {
      return slot.campaign
    }
    if (slot.template && slot.template.campaign) {
      return slot.template.campaign
    }
    return null
  }, [slot])

  const metricsToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return ruleMetricList
    }
    return ruleMetricList.filter(metric => (
      ![
        RULE_METRIC_ACOS,
        RULE_METRIC_ROAS,
        RULE_METRIC_CONVERSION,
        RULE_METRIC_NTB_SALES,
      ].includes(metric.value)
    ))
  }, [isNonEndemicAccount])

  const doChange = (updatedValues) => {
    const payload = {
      m: null,
      c: null,
      v: null,
      l: null,
      r: [],
    }

    if (updatedValues.metric) {
      payload.m = updatedValues.metric.value
    }

    if (updatedValues.comparison) {
      payload.c = updatedValues.comparison.value
    }

    if (updatedValues.value && !isNaN(updatedValues.value)) {
      payload.v = parseFloat(updatedValues.value)
    }

    if (updatedValues.lookback && !isNaN(updatedValues.lookback)) {
      payload.l = parseInt(updatedValues.lookback, 10)
    }

    if (updatedValues.bidAction) {
      const rule = {
        t: RULE_TYPE_BID,
        a: updatedValues.bidAction.value,
      }

      if (rule.a === RULE_BID_ACTION_PAUSE) {
        payload.r.push(rule)
      } else {
        if (updatedValues.bidValue) {
          rule.v = parseFloat(updatedValues.bidValue)
        } else {
          rule.v = 0
        }
        payload.r.push(rule)
      }
    }

    if (updatedValues.campaignAction) {
      const rule = {
        t: RULE_TYPE_CAMPAIGN,
        a: updatedValues.campaignAction.value,
      }

      if (rule.a === RULE_CAMPAIGN_ACTION_PAUSE) {
        payload.r.push(rule)
      } else {
        if (updatedValues.campaignValue) {
          rule.v = parseFloat(updatedValues.campaignValue)
        } else {
          rule.v = 0
        }
        if (updatedValues.campaignLimit) {
          rule.l = parseFloat(updatedValues.campaignLimit)
        } else {
          rule.l = 0
        }
        payload.r.push(rule)
      }
    }

    if (updatedValues.tosModAction) {
      payload.r.push({
        t: RULE_TYPE_TOS_MODIFIER,
        a: updatedValues.tosModAction.value,
        v: updatedValues.tosModValue ? parseFloat(updatedValues.tosModValue) : 0,
      })
    }

    if (updatedValues.productModAction) {
      payload.r.push({
        t: RULE_TYPE_PRODUCT_MODIFIER,
        a: updatedValues.productModAction.value,
        v: updatedValues.productModValue ? parseFloat(updatedValues.productModValue) : 0,
      })
    }

    if (updatedValues.restModAction) {
      payload.r.push({
        t: RULE_TYPE_REST_MODIFIER,
        a: updatedValues.restModAction.value,
        v: updatedValues.restModValue ? parseFloat(updatedValues.restModValue) : 0,
      })
    }

    onChange(payload)
  }

  const handleChange = field => (value) => {
    const updatedValues = Object.assign({}, currentValues, {
      [field]: value,
    })

    doChange(updatedValues)
  }

  const handleBidClear = () => {
    const updatedValues = Object.assign({}, currentValues, {
      bidAction: null,
      bidValue: null,
    })
    doChange(updatedValues)
  }

  const handleCampaignClear = () => {
    const updatedValues = Object.assign({}, currentValues, {
      campaignAction: null,
      campaignValue: null,
      campaignLimit: null,
    })
    doChange(updatedValues)
  }

  const handleModifierClear = ruleType => () => {
    let actionField
    let valueField
    if (ruleType === RULE_TYPE_TOS_MODIFIER) {
      actionField = 'tosModAction'
      valueField = 'tosModValue'
    } else if (ruleType === RULE_TYPE_PRODUCT_MODIFIER) {
      actionField = 'productModAction'
      valueField = 'productModValue'
    } else if (ruleType === RULE_TYPE_REST_MODIFIER) {
      actionField = 'restModAction'
      valueField = 'restModValue'
    }
    const updatedValues = Object.assign({}, currentValues, {
      [actionField]: null,
      [valueField]: null,
    })
    doChange(updatedValues)
  }

  const renderMetricRule = () => {
    return (
      <div className="metric-rule-section">
        <Select
          classNamePrefix="rule-select"
          options={ruleComparisonList}
          placeholder="Select action"
          isDisabled={isReadOnly}
          value={currentValues.comparison}
          onChange={handleChange('comparison')}
        />
        <InputNumber
          className="value-input"
          min={0}
          placeholder="Set value"
          disabled={isReadOnly}
          value={currentValues.value}
          onChange={handleChange('value')}
        />
        <span>for</span>
        <InputNumber
          className="value-input"
          min={0}
          placeholder="No. of days"
          disabled={isReadOnly}
          value={currentValues.lookback}
          onChange={handleChange('lookback')}
        />
        <span>days</span>
      </div>
    )
  }

  const renderBidRule = () => {
    return (
      <BidRuleSection
        isReadOnly={isReadOnly}
        action={currentValues.bidAction}
        value={currentValues.bidValue}
        onActionChange={handleChange('bidAction')}
        onValueChange={handleChange('bidValue')}
        onClear={handleBidClear}
      />
    )
  }

  const renderCampaignRule = () => {
    return (
      <CampaignRuleSection
        isReadOnly={isReadOnly}
        action={currentValues.campaignAction}
        value={currentValues.campaignValue}
        limit={currentValues.campaignLimit}
        onActionChange={handleChange('campaignAction')}
        onValueChange={handleChange('campaignValue')}
        onLimitChange={handleChange('campaignLimit')}
        onClear={handleCampaignClear}
      />
    )
  }

  const renderModifierRule = () => {
    return (
      <>
        <ModifierRuleSection
          name="Top of Search modifier"
          isReadOnly={isReadOnly}
          action={currentValues.tosModAction}
          value={currentValues.tosModValue}
          onActionChange={handleChange('tosModAction')}
          onValueChange={handleChange('tosModValue')}
          onClear={handleModifierClear(RULE_TYPE_TOS_MODIFIER)}
        />
        <ModifierRuleSection
          name="Product Pages modifier"
          isReadOnly={isReadOnly}
          action={currentValues.productModAction}
          value={currentValues.productModValue}
          onActionChange={handleChange('productModAction')}
          onValueChange={handleChange('productModValue')}
          onClear={handleModifierClear(RULE_TYPE_PRODUCT_MODIFIER)}
        />
        <ModifierRuleSection
          name="Rest of the Search modifier"
          isReadOnly={isReadOnly}
          action={currentValues.restModAction}
          value={currentValues.restModValue}
          onActionChange={handleChange('restModAction')}
          onValueChange={handleChange('restModValue')}
          onClear={handleModifierClear(RULE_TYPE_REST_MODIFIER)}
        />
      </>
    )
  }

  return (
    <div className="rule-metric-container">
      {
        campaignInfo !== null && (
          <div className="template-note">
            Campaign: <strong>{ campaignInfo.name }</strong>
          </div>
        )
      }
      {
        fromTemplate && (
          <div className="template-note">
            These rules are from the template: <strong>{ slot.template.name }</strong>.
          </div>
        )
      }
      <div className="metric-section">
        <Select
          classNamePrefix="rule-select"
          options={metricsToRender}
          placeholder="Select metric"
          isDisabled={isReadOnly}
          value={currentValues.metric}
          onChange={handleChange('metric')}
        />
        {
          !isReadOnly && (
            <button
            type="button"
            className="btn btn-red"
            onClick={onRemove}
          >
            Remove
          </button>
          )
        }
      </div>
      { renderMetricRule() }
      { renderBidRule() }
      { renderCampaignRule() }
      { hasSpCampaign && renderModifierRule() }
    </div>
  )
}

export default RuleMetric
