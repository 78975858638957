import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FiExternalLink } from 'react-icons/fi'

import PaginationComponent from '../../components/CommonComponents/PaginationComponent'
import SearchBox from '../../components/CommonComponents/SearchBox'

import { showSpManager } from '../../redux/actions/ruleManager'

import { revertLog } from '../../redux/actions/campaignLog'

import {
  getLogDescription,
  parseDate,
} from '../../services/helper'

import { REVERTABLE_LOG_TYPES } from '../../utils/defaultValues'
import { format } from 'date-fns'

const DEFAULT_PAGE_SIZE = 10

const LogTable = ({ logs, isRevertingLog }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [keyword, setKeyword] = useState('')
  const [filteredRecords, setFilteredRecords] = useState(logs)
  const [expansionState, setExpansionState] = useState({})
  const [pageStart, setPageStart] = useState(0)
  const [pageEnd, setPageEnd] = useState(DEFAULT_PAGE_SIZE)

  useEffect(() => {
    setFilteredRecords(logs)
  }, [logs])

  const handleSmartPilotManagerShow = campaignId => (event) => {
    event.preventDefault()
    dispatch(showSpManager(campaignId))
  }

  const handleExpand = (key) => {
    setExpansionState(Object.assign({}, expansionState, {
      [key]: expansionState[key] === true ? false : true,
    }))
  }

  const handleKeywordPress = (event) => {
    if (event.key !== 'Enter') {
      return
    }

    if (keyword === '') {
      setFilteredRecords(logs)
    } else {
      const lowerCased = keyword.toLowerCase()
      let tmpFilteredRecords = []
      logs.forEach(record => {
        const tmpRecord = {...record}
        tmpRecord.logs = tmpRecord.logs.filter(log => (
          Object.keys(log).find(field => (
            log[field] && JSON.stringify(log[field]).toLowerCase().indexOf(lowerCased) !== -1
          ))
        ))
        if (tmpRecord.logs && tmpRecord.logs.length > 0) {
          tmpFilteredRecords = [...tmpFilteredRecords, tmpRecord]
        }
      })
      setFilteredRecords(tmpFilteredRecords)
    }
    setPageStart(0)
    setPageEnd(DEFAULT_PAGE_SIZE)
  }

  const handleRevert = log => async () => {
    const accessToken = await getAccessTokenSilently()
    dispatch(revertLog(accessToken, log.id, log.campaign_id))
  }

  const loadData = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStart((pageNum - 1) * pageRows)
      setPageEnd(pageNum * pageRows - 1)
    } else {
      setPageStart(0)
      setPageEnd(filteredRecords.length)
    }
  }

  const renderLogAction = (log) => {
    if (REVERTABLE_LOG_TYPES.indexOf(log.log_type) === -1) {
      return null
    }

    if (log.reverted) {
      return (
        <button
          type="button"
          className="btn btn-red"
          disabled
        >
          Reverted
        </button>
      )
    }

    return (
      <button
        type="button"
        className="btn btn-red"
        disabled={isRevertingLog}
        onClick={handleRevert(log)}
      >
        Revert
      </button>
    )
  }

  const renderGroup = ({ key, logs: groupLogs }) => {
    const rows = []

    rows.push((
      <div key={key} className="table-row" onClick={() => { handleExpand(key) }}>
        <div className="table-col col-expand">
          <span className="expand-icon" title={expansionState[key] === true ? 'Collapse' : 'Expand'}>
            { expansionState[key] === true ? '-' : '+' }
          </span>
        </div>
        <div className="table-col col-created-at">
          { format(parseDate(groupLogs[0].created_at), 'M/d') }
        </div>
        <div className="table-col col-campaign">
          {
            groupLogs[0].name !== null && (
              <>
                <Link
                  to={`/campaign/${groupLogs[0].campaign_id}/${groupLogs[0].campaignType}`}
                  title={groupLogs[0].name}
                >
                  { groupLogs[0].name }
                  <FiExternalLink
                    title="Open Smart Pilot"
                    size={16}
                    onClick={handleSmartPilotManagerShow(groupLogs[0].campaign_id)}
                  />
                </Link>
              </>
            )
          }
        </div>
        <div className="table-col col-type">
          { groupLogs.length } update(s)
        </div>
        <div className="table-col col-description">
          <em>Click to view</em>
        </div>
        <div className="table-col col-contents">
          <em>Click to view</em>
        </div>
      </div>
    ))

    if (expansionState[key] === true) {
      groupLogs.forEach((log, index) => {
        let newContents = log.contents
        // TODO: move replace code to helper
        // eslint-disable-next-line
        newContents = newContents.replace(/[\[]+[{]/g, '( ')
        newContents = newContents.replace(/[}]+[\]]/g, ' )')
        newContents = newContents.replace(/[{}]/g, '')
        newContents = newContents.replace(/[""]/g, '')
        newContents = newContents.replace(/[,]/g, ', ')
        rows.push((
          <div key={`${key}-${index}`} className="table-row log-sub-row">
            <div className="table-col col-expand"></div>
            <div className="table-col col-created-at"></div>
            <div className="table-col col-campaign"></div>
            <div className="table-col col-type">
              { log.type }
            </div>
            <div
              className="table-col col-description"
              dangerouslySetInnerHTML={{
                __html: getLogDescription(
                  log.log_type,
                  log.description,
                  log.created_at,
                ),
              }}
            />
            <div className="table-col col-contents">
              <div
                dangerouslySetInnerHTML={{
                  __html: newContents,
                }}
              />
            </div>
            <div className="table-col col-action">
              { renderLogAction(log) }
            </div>
          </div>
        ))
      })
    }

    return rows
  }

  const renderRecords = () => {
    if (!filteredRecords.length) {
      return (
        <div className="table-row">
          <div className="table-col">
            No records found.
          </div>
        </div>
      )
    }

    let rows = []
    filteredRecords.slice(pageStart, pageEnd).forEach((group) => {
      rows = [
        ...rows,
        ...renderGroup(group),
      ]
    })
    return rows
  }

  return (
    <div className="table-logs">
      <div className="table-header">
        <div className="table-header-left">
          <SearchBox
            keyword={keyword}
            onChange={setKeyword}
            onKeyPress={handleKeywordPress}
          />
        </div>
      </div>
      <div className="table-body">
        <div className="table-row content-header">
          <div className="table-col col-expand"></div>
          <div className="table-col col-created-at">Date</div>
          <div className="table-col col-campaign">Campaign</div>
          <div className="table-col col-type">Log Type</div>
          <div className="table-col col-description">Description</div>
          <div className="table-col col-contents">Detail</div>
          <div className="table-col col-action"></div>
        </div>
        { renderRecords() }
      </div>
      <PaginationComponent
        pageNeighbours={2}
        total={filteredRecords.length}
        loadData={loadData}
      />
    </div>
  )
}

export default LogTable
