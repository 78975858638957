import {
  REVERT_LOG_START,
  REVERT_LOG_SUCCESS,
  REVERT_LOG_FAIL,
} from '../actionTypes/campaignLog'

import { GET_DETAILS_START } from '../actionTypes/campaignDetail'

export const initialState = {
  isRevertingLog: false,
}

const campaignLog = (state = initialState, action) => {
	switch (action.type) {
    case GET_DETAILS_START:
      return {
        ...state,
        isRevertingLog: false,
      }
    case REVERT_LOG_START:
      return {
        ...state,
        isRevertingLog: true,
      }
    case REVERT_LOG_SUCCESS:
    case REVERT_LOG_FAIL:
      return {
        ...state,
        isRevertingLog: false,
      }
    default:
      return state
  }
}

export default campaignLog