/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Nav } from 'rsuite'
import { BsCaretDownFill } from 'react-icons/bs'

import { getAccountLabel } from '../../services/helper'

const navItems = [
  { key: 'advertiser', name: 'Advertiser'},
  { key: 'order', name: 'Orders'},
  { key: 'lineItem', name: 'Line Items'},
]

const subNavItems = [
  { key: 'order', name: 'Orders'},
  { key: 'lineItem', name: 'Line Items'},
]

const DspNavigation = ({ selectedDspAccount, activeNav, currentAdvertiser,
  onAccountSelect, onNavSelect, onAdvertiserReset }) => {
  const accountList = useSelector(state => state.header.accountList)

  const [searchBy, setSearchBy] = useState('')

  const accountToRender = useMemo(() => (
    (accountList || []).filter(account => {
      if (account.seller_type !== 'agency' || account.sub_type !== 'dsp') {
        return false
      }
      let brandName = ''
      if (account.brand_name) {
        brandName = account.brand_name
      } else {
        brandName = account.sellerid || ''
      }
      if (searchBy && !brandName.toLowerCase().includes(searchBy.toLowerCase())) {
        return false
      }
      return true
    }).sort((account1, account2) => {
      let brandName1
      if (account1.brand_name) {
        brandName1 = account1.brand_name
      } else {
        brandName1 = account1.sellerid || 'N/A'
      }

      let brandName2
      if (account2.brand_name) {
        brandName2 = account2.brand_name
      } else {
        brandName2 = account2.sellerid || 'N/A'
      }

      return brandName1.localeCompare(brandName2)
    })
  ), [accountList, searchBy])

  const handleAccountChange = (event, account) => {
    event.preventDefault()
    onAccountSelect(account)
  }

  const renderAdvertiser = () => {
    return (
      <div className="dsp-advertiser-header">
        <span className="advertiser-info">
          { currentAdvertiser.name }
          <span
            className="close-icon"
            onClick={() => { onAdvertiserReset() }}
          >
            &times;
          </span>
        </span>
      </div>
    )
  }

  const renderAccounts = () => {
    return accountToRender.map(account => (
      <a
        key={account.user_id}
        href="#"
        onClick={(event) => { handleAccountChange(event, account) }}
      >
        { getAccountLabel(account) }
      </a>
    ))
  }

  const renderDspSelector = () => {
    return (
      <div className="dsp-account-selector">
        <div className="account-list dropdown" tabIndex="1">
          <button className="dropdown-toggle">
            { getAccountLabel(selectedDspAccount) }
            <BsCaretDownFill size={10} />
          </button>
          <div className="dropdown-content">
            <div className="account-filter">
              <input
                className="filter-input"
                placeholder="Type Here to Search..."
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
              />
            </div>
            { renderAccounts() }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="dsp-nav-panel">
      {
        currentAdvertiser ? renderAdvertiser() : renderDspSelector()
      }
      <Nav
        className="dsp-navigation"
        vertical
        activeKey={activeNav}
        onSelect={onNavSelect}
      >
        {
          (currentAdvertiser ? subNavItems : navItems).map(nav => (
            <Nav.Item key={nav.key} eventKey={nav.key}>
              { nav.name }
            </Nav.Item>
          ))
        }
      </Nav>
    </div>
  )
}

export default DspNavigation
