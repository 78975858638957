import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { differenceInDays } from 'date-fns'
import Select from 'react-select'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import TableCell from '../CommonComponents/TableCell'

import BulkResultContainer from '../BulkResultContainer'
import NegativeCreatorModal from './NegativeCreatorModal'
import NegativeDetailsModal from './NegativeDetailsModal'

import {
  formatCurrency,
  tableSorter,
  calcDerivedMetrics,
  copyToClipboard,
  getExportValueForColumn,
  groupRecords,
  parseDate,
  isAsin,
} from '../../services/helper'

import { prepList } from '../../utils/defaultValues'

const commonColumns = [
  { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Unprofitable Clicks' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'orders', name: 'Orders' },
  { key: 'conversion', name: 'Conv %' },
  { key: 'acos', name: 'Associated ACoS %' },
  { key: 'revenue', name: 'Associated Sales' },
  { key: 'cost', name: 'Wasted AD Spend' },
  { key: 'cpc', name: 'Ave CPC' },
  { key: 'yearlyCost', name: 'Approx Yearly Savings' },
]

const columns = [
  { key: 'search', name: 'Words', className: 'col-word' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  ...commonColumns,
]

const columnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'search', name: 'Words', className: 'col-word' },
  ...commonColumns,
]

const columnsWordGroup = [
  { key: 'search', name: 'Words', className: 'col-word', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  ...commonColumns,
]

const metricsToRender = [
  'impressions',
  'clicks',
  'ctr',
  'orders',
  'conversion',
  'acos',
  'revenue',
  'cost',
  'cpc',
]

const GROUP_BY_WORDS_CAMPAIGNS = 'BY_WORDS_CAMPAIGNS'
const GROUP_BY_WORDS = 'BY_WORDS'
const GROUP_BY_CAMPAIGNS = 'BY_CAMPAIGNS'

const groupByOptions = [
  { value: GROUP_BY_WORDS_CAMPAIGNS, label: 'By Words and Campaigns' },
  { value: GROUP_BY_WORDS, label: 'By Words' },
  { value: GROUP_BY_CAMPAIGNS, label: 'By Campaigns' },
]

const wordOptions = [
  { value: '', label: 'All' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5+' },
]

const NegativeResult = ({
  hideKeywords,
  hideAsins,
  removeNumbers,
  removePreps,
  currentTargetAcos,
  campaignsById,
  adgroupNamesById,
  onChangeHideKeywords,
  onChangeHideAsins,
  onChangeRemoveNumbers,
  onChangeRemovePreps,
  onChangeDate,
  onChangeTargetAcos,
}) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const negativeFinderData = useSelector(state => state.bulkEngine.negativeFinderData)

  const [groupMode, setGroupMode] = useState(groupByOptions[0])
  const [negatives, setNegatives] = useState([])
  const [groupedNegatives, setGroupedNegatives] = useState([])
  const [selectedNegatives, setSelectedNegatives] = useState([])
  const [targetAcos, setTargetAcos] = useState(currentTargetAcos)
  const [negativeToView, setNegativeToView] = useState(null)
  const [negativesToAdd, setNegativesToAdd] = useState([])
  const [selectedWord, setSelectedWord] = useState(wordOptions[0])
  const [dateDiff, setDateDiff] = useState(1)

  useEffect(() => {
    const _dateDiff = differenceInDays(
      parseDate(currentEndDate),
      parseDate(currentStartDate)
    ) || 1

    const extendedNegatives = []; // semi-colon is a must here.
    (negativeFinderData || []).forEach((record) => {
      // Remove ASINs.
      if (hideAsins && isAsin(record.search)) {
        return
      }

      // Remove numbers.
      if (removeNumbers && !isNaN(record.search)) {
        return
      }

      // Remove Prepositions.
      if (
        removePreps
        && prepList.indexOf(record.search.toLowerCase()) !== -1
      ) {
        return
      }

      const extendedRecord = { ...record }

      // For old reports, `searchTerms` is unavailable.
      if (typeof extendedRecord.searchTerms !== 'undefined') {
        // For legacy reports, `searchTerms` is an array of string,
        // and for newer ones, an array of objects.
        const isLegacy = typeof extendedRecord.searchTerms[0] === 'string'

        let matchingSearchTerms = extendedRecord.searchTerms
        if (selectedWord.value !== '') {
          const wordCount = parseInt(selectedWord.value, 10)
          matchingSearchTerms = extendedRecord.searchTerms.filter((st) => {
            let searchTermToCheck
            if (isLegacy) {
              searchTermToCheck = st
            } else {
              searchTermToCheck = st.searchTerm
            }

            if (wordCount !== 5) {
              if (searchTermToCheck.split(/\s+/).length !== wordCount) {
                return false
              }
            } else if (searchTermToCheck.split(/\s+/).length < wordCount) {
              // Check for 5+ words.
              return false
            }
            return true
          })
          if (!matchingSearchTerms.length) {
            return
          }
        }

        if (!isLegacy) {
          // For newer reports, we need to calculate negative word level
          // metrics on-the-fly.
          extendedRecord.revenue = 0
          extendedRecord.cost = 0
          extendedRecord.impressions = 0
          extendedRecord.clicks = 0
          extendedRecord.orders = 0

          matchingSearchTerms.forEach((st) => {
            extendedRecord.revenue += st.revenue
            extendedRecord.cost += st.cost
            extendedRecord.impressions += st.impressions
            extendedRecord.clicks += st.clicks
            extendedRecord.orders += st.orders
          })
        }

        extendedRecord.searchTerms = matchingSearchTerms
      }

      const campaignDetail = campaignsById[extendedRecord.campaign_id] || {}

      extendedNegatives.push({
        ...calcDerivedMetrics(extendedRecord),
        id: `${extendedRecord.campaign_id}-${extendedRecord.adgroup_id}-${extendedRecord.search}`,
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targetingType: campaignDetail.targetingType || '',
        adgroupName: adgroupNamesById[extendedRecord.adgroup_id] || '',
        yearlyCost: parseFloat(extendedRecord.cost) / _dateDiff * 365,
      })
    })

    setNegatives(extendedNegatives)
    setDateDiff(_dateDiff)
  }, [
    negativeFinderData,
    hideAsins,
    removeNumbers,
    removePreps,
    campaignsById,
    adgroupNamesById,
    currentStartDate,
    currentEndDate,
    selectedWord,
  ])

  useEffect(() => {
    if (groupMode.value === GROUP_BY_CAMPAIGNS) {
      setGroupedNegatives(
        groupRecords(
          negatives,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else if (groupMode.value === GROUP_BY_WORDS) {
      setGroupedNegatives(
        groupRecords(
          negatives,
          'search',
          []
        )
      )
    } else {
      setGroupedNegatives([])
    }
  }, [negatives, groupMode])

  const handleCopy = () => {
    const sts = negatives.filter(st => (
      selectedNegatives.indexOf(st.id) !== -1
    )).map(st => st.search)

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedNegatives.length} word${selectedNegatives.length > 1 ? 's' : ''}.`
    })
  }

  const handleAddNegatives = (records) => {
    if (!records) {
      const sts = negatives.filter(st => (
        selectedNegatives.indexOf(st.id) !== -1
      ))
      setNegativesToAdd(sts)
    } else {
      setNegativesToAdd(records)
    }
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Select
          classNamePrefix="group-by-selector"
          options={groupByOptions}
          value={groupMode}
          onChange={setGroupMode}
        />
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Keywords"
            checked={hideKeywords}
            onChange={onChangeHideKeywords}
          />
          <CustomTooltip placement="right">
            <p>Sometimes a keyword and search term are the same
            (ex: Exact match types), many times they are not.</p>
            <p>Checking this box means that only search terms that are not yet keywords will be revealed.
            You can turn these new-found search terms into keywords by adding them to campaigns.</p>
          </CustomTooltip>
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove ASINs"
            checked={hideAsins}
            onChange={onChangeHideAsins}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Numbers"
            checked={removeNumbers}
            onChange={onChangeRemoveNumbers}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Remove Prepositions"
            checked={removePreps}
            onChange={onChangeRemovePreps}
          />
          <CustomTooltip placement="right">
            <p>{ prepList.join(', ') }</p>
          </CustomTooltip>
        </div>
        <div className="select-wrapper">
          <span>Word Count</span>
          <Select
            classNamePrefix="word-count-selector"
            options={wordOptions}
            value={selectedWord}
            onChange={setSelectedWord}
          />
        </div>
        <div className="search-wrapper">
          <label>
            ACoS % &gt;
          </label>
          <input
            type="number"
            min="0.1"
            value={targetAcos}
            onChange={(event) => { setTargetAcos(event.target.value) }}
          />
          &nbsp; <strong>or 0</strong>&nbsp;&nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-blue"
            disabled={targetAcos === '' || isNaN(targetAcos)}
            onClick={() => { onChangeTargetAcos(targetAcos) }}
          >
            Search
          </button>
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedNegatives.length) {
      return null
    }
    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { handleAddNegatives() }}
        >
          Add Negative{selectedNegatives.length > 1 ? 's' : ''} to Campaign{selectedNegatives.length > 1 ? 's' : ''}
        </button>
        <button
          type="button"
          className="btn btn-green"
          onClick={() => { handleCopy() }}
        >
          Copy
        </button>
      </>
    )
  }

  const renderRecord = record => (
    <>
      <div
        className="table-col col-word" title={record.search}
        onClick={() => { setNegativeToView(record) }}
      >
        <strong className="contents">
          { record.search }
        </strong>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={record}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-word">Totals:</div>
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={summary}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(summary.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'search') {
        return record.search
      }
      if (column.key === 'yearlyCost') {
        return formatCurrency(record.yearlyCost, currencySign, currencyRate)
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-word">
        { record.children.length } words
      </div>
      <div className="table-col col-adgroup" />
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={record}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderChild = record => (
    <>
      <div
        className="table-col col-word"
        title={record.search}
        onClick={() => { setNegativeToView(record) }}
      >
        <strong className="contents">
          { record.search }
        </strong>
      </div>
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={record}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-word" />
      <div className="table-col col-adgroup" />
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={summary}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(summary.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  // For word grouped table.
  const renderWordParent = record => (
    <>
      <div
        className="table-col col-word"
        title={record.search}
        onClick={() => { setNegativeToView(record) }}
      >
        <strong className="contents">
          { record.search }
        </strong>
      </div>
      <div className="table-col col-check" />
      <div className="table-col col-campaign">
        { record.children.length } adgroups
      </div>
      <div className="table-col col-adgroup" />
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={record}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderWordChild = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={record}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderWordTotalGroup = summary => (
    <>
      <div className="table-col col-word">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        metricsToRender.map(metric => (
          <TableCell
            key={metric}
            record={summary}
            columnKey={metric}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
      <div className="table-col">
        { formatCurrency(summary.yearlyCost, currencySign, currencyRate) }
      </div>
    </>
  )

  const renderContents = () => {
    if (groupMode.value === GROUP_BY_CAMPAIGNS) {
      return (
        <SortableTable
          tableComponent={GroupTable}
          columns={columnsGroup}
          defaultSort={['clicks', 'desc']}
          sorter={tableSorter(['campaignName'])}
          className="table-grouped-negatives"
          records={groupedNegatives}
          idField="campaign_id"
          searchFields={['search']}
          selectedRecords={selectedNegatives}
          hasSticky
          hasDateRange
          hasLifetimeRange
          exportFileName="Negative Word Finder"
          getExportData={getExportData}
          renderRecord={renderParent}
          renderTotal={renderTotalGroup}
          renderTopRight={renderAction}
          onChange={setSelectedNegatives}
          onChangeDate={onChangeDate}
          sorterChild={tableSorter(['search', 'adgroupName'])}
          idFieldChild="id"
          renderChild={renderChild}
        />
      )
    }

    if (groupMode.value === GROUP_BY_WORDS) {
      return (
        <SortableTable
          tableComponent={GroupTable}
          columns={columnsWordGroup}
          defaultSort={['clicks', 'desc']}
          sorter={tableSorter(['search'])}
          className="table-grouped-negatives-by-words"
          records={groupedNegatives}
          idField="search"
          searchFields={['search']}
          selectedRecords={selectedNegatives}
          hasSticky
          hasDateRange
          hasLifetimeRange
          exportFileName="Negative Word Finder"
          getExportData={getExportData}
          renderRecord={renderWordParent}
          renderTotal={renderWordTotalGroup}
          renderTopRight={renderAction}
          onChange={setSelectedNegatives}
          onChangeDate={onChangeDate}
          sorterChild={tableSorter(['campaignName', 'adgroupName'])}
          idFieldChild="id"
          renderChild={renderWordChild}
        />
      )
    }

    if (groupMode.value === GROUP_BY_WORDS_CAMPAIGNS) {
      return (
        <SortableTable
          columns={columns}
          defaultSort={['clicks', 'desc']}
          sorter={tableSorter(['search', 'campaignName', 'adgroupName'])}
          className="table-negatives"
          records={negatives}
          idField="id"
          searchFields={['search']}
          selectedRecords={selectedNegatives}
          hasSticky
          hasDateRange
          hasLifetimeRange
          exportFileName="Negative Word Finder"
          getExportData={getExportData}
          renderRecord={renderRecord}
          renderTotal={renderTotal}
          renderTopRight={renderAction}
          onChange={setSelectedNegatives}
          onChangeDate={onChangeDate}
        />
      )
    }
  }

  return (
    <BulkResultContainer>
      { renderFilter() }
      { renderContents() }
      {
        negativesToAdd.length > 0 && (
          <NegativeCreatorModal
            searchTerms={negativesToAdd}
            onClose={() => { setNegativesToAdd([]) }}
          />
        )
      }
      {
        negativeToView !== null && (
          <NegativeDetailsModal
            negative={negativeToView}
            dateDiff={dateDiff}
            onAdd={handleAddNegatives}
            onClose={() => { setNegativeToView(null) }}
          />
        )
      }
    </BulkResultContainer>
  )
}

export default NegativeResult
