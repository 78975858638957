import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Modal } from 'rsuite'

import CustomTable from '../CommonComponents/CustomTableComponent'

import { getNegativeTargetList } from '../../redux/actions/campaignDetail'

import { capitalizeFirstLetter } from '../../services/helper'

const NegativeTargetsModal = ({ campaignId, campaignType, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNegativeWordDataLoading = useSelector(state => state.campaignDetail.isNegativeWordDataLoading)
  const negativeTargetData = useSelector(state => state.campaignDetail.negativeTargetData)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getNegativeTargetList(accessToken, campaignId, campaignType))
    })()
  }, [campaignId, dispatch]) // eslint-disable-line

  const renderNegative = (negative) => {
    let expression = negative.resolved_expression
    try {
      const parsed = JSON.parse(negative.resolved_expression)
      if (parsed.length) {
        expression = parsed[0].value
      }
    } catch (e) {
      expression = ''
    }
    return (
      <>
        <div className="table-col">
          <strong>{ expression }</strong>
        </div>
        <div className="table-col">
          { capitalizeFirstLetter(negative.targeting_type || 'Manual') }
        </div>
        { campaignType === 'sp' && (
          <div className="table-col">
            { typeof negative.campaign_level !== 'undefined' ? 'Campaign Level' : 'Ad Group Level' }
          </div>
        )}
      </>
    )
  }

  return (
    <Modal className="keyword-modal" backdrop="static" show={true} size="md">
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Negative Targets
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomTable
          className="table-keywords"
          isLoading={isNegativeWordDataLoading}
          records={negativeTargetData || []}
          noCheckBox={true}
          idField="target_id"
          searchFields={['resolved_expression']}
          renderRecord={renderNegative}
        >
          <div className="table-col col-campaign">Target</div>
          <div className="table-col col-campaign">Type</div>
          { campaignType === 'sp' && (
            <div className="table-col col-campaign">Campaign/Ad Group Level</div>
          )}
        </CustomTable>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NegativeTargetsModal
