import React, { useEffect, useState, useMemo }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../ToastComponent/toast'
import LoaderComponent from '../LoaderComponent'
import CheckboxComponent from '../CheckboxComponent'
import CustomTooltip from '../CustomTooltip'
import FilterSaveModal from './FilterSaveModal'
import FilterDeleteModal from './FilterDeleteModal'

import { applyFilter } from '../../../redux/actions/pageGlobal'
import { loadFilters, saveFilter, deleteFilter } from '../../../redux/actions/filter'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import filterDef from '../../../utils/filterDef'

const TableFilterModal = ({ filterName, currentModuleName, onApply, onClose, onValidate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)

  const [values, setValues] = useState({ ...((filterValues || {})[filterName] || {}) })
  const [filters, setFilters] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [filterToDelete, setFilterToDelete] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)

  // Load filters.
  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setFilters([])
      setIsLoading(true)
      dispatch(loadFilters(accessToken, filterName, abortCtrl.signal)).then((response) => {
        setIsLoading(false)
        setFilters(response)
      }).catch((error) => {
        if (error.code !== 'ERR_CANCELED') {
          setIsLoading(false)
          toast.show({
            title: 'Danger',
            description: 'Failed to load filters.',
          })
        }
      })
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const filtersToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return filterDef[filterName]
    }
    return filterDef[filterName].filter(filter => (
      ![
        'target_acos',
        'revenue',
        'acos',
        'orders',
        'conversion',
        'roas',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
        'video_views_25',
        'video_views_50',
        'video_views_75',
        'video_views_100',
        'video_5s_views',
        'view_5_seconds_rate',
      ].includes(filter.key)
    ))
  }, [isNonEndemicAccount, filterName])

  const handleApply = () => {
    if (onValidate) {
      const error = onValidate(values)
      if (error) {
        toast.show({
          title: 'Warning',
          description: error,
        })
        return
      }
    }
    dispatch(applyFilter(filterName, values))
    onApply(values)
  }

  const handleReset = () => {
    const newValues = { ...values }
    filtersToRender.forEach((filter) => {
      if (!filter.type || filter.type === 'range') {
        newValues[`${filter.key}Min`] = ''
        newValues[`${filter.key}Max`] = ''
      } else if (filter.type === 'target_acos'
        || filter.type === 'smart_pilot') {
        newValues[filter.key] = false
      } else {
        newValues[filter.key] = ''
      }
    })
    setValues(newValues)
    setSelectedFilter(null)
  }

  const handleFilterSelect = (option) => {
    setSelectedFilter(option)
    setValues(option.value)
  }

  // Open up a modal to enter a name and save filter values.
  const handleSave = () => {
    if (!Object.keys(values).length) {
      toast.show({
        title: 'Warning',
        description: 'Please enter at least one value.',
      })
      return
    }
    setIsSaveModalVisible(true)
  }

  const handleFilterSave = async (name) => {
    setIsSaveModalVisible(false)
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(saveFilter(accessToken, filterName, name, values)).then((response) => {
      setIsSaving(false)
      const newFilters = filters.concat([response])
      newFilters.sort((filter1, filter2) => filter1.name.localeCompare(filter2.name))
      setFilters(newFilters)
      toast.show({
        title: 'Success',
        description: 'Saved a filter successfully.',
      })
    }).catch(() => {
      setIsSaving(false)
      toast.show({
        title: 'Danger',
        description: 'Failed to save a filter.',
      })
    })
  }

  const handleFilterDelete = async () => {
    setIsDeleting(true)
    setFilterToDelete('')
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteFilter(accessToken, selectedFilter.id)).then(() => {
      setIsDeleting(false)
      setFilters(filters.filter(filter => filter.id !== selectedFilter.id))
      toast.show({
        title: 'Success',
        description: 'Deleted a filter successfully.',
      })
    }).catch(() => {
      setIsDeleting(false)
      toast.show({
        title: 'Danger',
        description: 'Failed to delete a filter.',
      })
    })
  }

  const setFilterValue = (key, value) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const renderFilter = (filter) => {
    let contents
    if (filter.type === 'select') {
      contents = (
        <Select
          options={filter.options}
          value={values[filter.key]}
          onChange={(data) => setFilterValue(filter.key, data)}
        />
      )
    } else if (filter.type === 'target_acos') {
      contents = (
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label={filter.label}
            checked={values[filter.key]}
            onChange={(checked) => { setFilterValue(filter.key, checked) }}
          />
          <CustomTooltip placement="right">
            <p>We’ll use your set Target ACoS for each campaign to find what needs to be optimized, fast!</p>
            <p>Results that are above your target ACoS will be revealed for each campaign.</p>
          </CustomTooltip>
        </div>
      )
    } else {
      let isDisabled = false
      if (filter.depends) {
        if (filter.dependValue !== false) {
          isDisabled = values[filter.depends] !== filter.dependValue
        } else {
          isDisabled = values[filter.depends] !== filter.dependValue
            && typeof values[filter.depends] !== 'undefined'
        }
      }
      contents = (
        <div className="filter-row-child">
          <input
            type="number"
            placeholder="Min"
            value={values[`${filter.key}Min`] || ''}
            disabled={isDisabled}
            onChange={(e) => setFilterValue(`${filter.key}Min`, e.target.value)}
          />
          <input
            type="number"
            placeholder="Max"
            value={values[`${filter.key}Max`] || ''}
            disabled={isDisabled}
            onChange={(e) => setFilterValue(`${filter.key}Max`, e.target.value)}
          />
        </div>
      )
    }

    return (
      <div key={filter.key} className="filter-row">
        {
          filter.type !== 'target_acos' && (
            <span>{ filter.label }</span>
          )
        }
        { contents }
      </div>
    )
  }

  return (
    <Modal className="table-filter-modal" backdrop="static" size="sm" show>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          { currentModuleName }: Filters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isSaving && <LoaderComponent /> }
        <Select
          className="filter-selector"
          options={filters}
          getOptionLabel={filter => filter.name}
          getOptionValue={filter => filter.id}
          value={selectedFilter}
          isLoading={isLoading}
          placeholder="Use saved filter..."
          onChange={handleFilterSelect}
        />
        { filtersToRender.map(renderFilter) }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-default rs-btn-green pull-left"
          disabled={isSaving}
          onClick={handleSave}
        >
          Save Filter
        </button>
        {
          selectedFilter !== null && (
            <button
              type="button"
              className="rs-btn rs-btn-default rs-btn-red pull-left"
              disabled={isDeleting}
              onClick={() => { setFilterToDelete(selectedFilter.name) }}
            >
              Delete Filter
            </button>
          )
        }
        <button
          type="button"
          className="rs-btn rs-btn-subtle pull-left"
          onClick={handleReset}
        >
          Reset Filter
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-primary pull-right"
          onClick={handleApply}
        >
          Continue to Results
        </button>
        <FilterSaveModal
          show={isSaveModalVisible}
          onSave={handleFilterSave}
          onClose={() => { setIsSaveModalVisible(false) }}
        />
        <FilterDeleteModal
          name={filterToDelete}
          onDelete={handleFilterDelete}
          onClose={() => { setFilterToDelete('') }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default TableFilterModal
