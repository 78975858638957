import React, { useMemo } from 'react'
import { Nav } from 'rsuite'

import { accountReportPageSideNavItems } from '../../utils/defaultValues'
import useScrollSpy from '../../hooks/useScrollSpy'

const ReportSideNav = ({ isNonEndemicAccount }) => {
  const navItems = useMemo(() => {
    if (!isNonEndemicAccount) {
      return accountReportPageSideNavItems
    }
    return accountReportPageSideNavItems.filter(nav => (
      ![
        'all_sponsored_ads',
        'sponsored_products',
        'sponsored_brands',
        'brand_growth',
        'budget_utilization',
        'portfolios',
        'products',
        'keywords',
        'performanceByAdType',
        'performanceByPlacement',
        'performanceByBidType',
        'performanceByMatchType',
      ].includes(nav.key)
    ))
  }, [isNonEndemicAccount])

  const activeId = useScrollSpy(
    navItems.map(item => item.key),
    { rootMargin: '0% 0% -40% 0%' }
  )

  const handleSelectSideNav = (selected, e) => {
    e.preventDefault()
    if (selected === 'overview') {
      const ele = document.getElementsByClassName('main-content')[0]
      ele.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } else {
      const element = document.getElementById(selected)
      element.scrollIntoView({ behavior: 'smooth'})
    }
  }

  const renderNavItem = (item) => {
    return (
      <Nav.Item key={item.key} eventKey={item.key} href={`#${item.key}`}>
        {item.text}
      </Nav.Item>
    )
  }

  return (
    <Nav
      className="report-sidebar-container"
      vertical
      reversed
      appearance="subtle"
      onSelect={handleSelectSideNav}
      activeKey={activeId}
    >
      { navItems.map(renderNavItem)}
    </Nav>
  )
}

export default ReportSideNav
