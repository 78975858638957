import React, { useState } from 'react'
import Select from 'react-select'
import { InputNumber } from 'rsuite'

import {
  ruleCampaignActionList,
  RULE_CAMPAIGN_ACTION_DEC,
  RULE_CAMPAIGN_ACTION_PAUSE,
} from '../../utils/defaultValues'

const CampaignRuleSection = ({ isReadOnly, action, value, limit,
  onActionChange, onValueChange, onLimitChange, onClear }) => {
  const [error, setError] = useState('')

  const validate = (newAction, newValue, newLimit) => {
    setError('')
    if (!newAction || newAction.value === RULE_CAMPAIGN_ACTION_PAUSE) {
      return
    }

    if (newAction.value === RULE_CAMPAIGN_ACTION_DEC) {
      if (!(!isNaN(parseFloat(newValue))
        && parseFloat(newValue) > 0
        && parseFloat(newValue) < 100)) {
        setError('The change must be greater than 0 and less than 100.')
      }
    } else {
      if (!(!isNaN(parseFloat(newValue))
        && parseFloat(newValue) > 0)) {
        setError('The change must be greater than 0.')
      }
    }

    if (newLimit !== ''
      && !(!isNaN(parseFloat(newLimit)) && parseFloat(newLimit) >= 0)) {
      setError('The max/min budget must be greater than or equal to 0.')
    }
  }

  const handleActionChange = (newAction) => {
    validate(newAction, value, limit)
    onActionChange(newAction)
  }

  const handleValueChange = (newValue) => {
    validate(action, newValue, limit)
    onValueChange(newValue)
  }

  const handleLimitChange = (newLimit) => {
    validate(action, value, newLimit)
    onLimitChange(newLimit)
  }

  return (
    <div className="rule-sub-section">
      <div className="sub-section-name">
        Campaign budget/status
      </div>
      <div className="sub-section-body">
        <div className="rule-settings-container">
          <Select
            classNamePrefix="rule-select"
            options={ruleCampaignActionList}
            placeholder="Select action"
            isDisabled={isReadOnly}
            value={action}
            onChange={handleActionChange}
          />
          {
            action && action.value !== RULE_CAMPAIGN_ACTION_PAUSE && (
              <>
                <InputNumber
                  className="value-input"
                  min={0}
                  placeholder="Set value"
                  postfix="%"
                  disabled={isReadOnly}
                  value={value}
                  onChange={handleValueChange}
                />
                <InputNumber
                  className="value-input"
                  min={0}
                  placeholder={action.value === RULE_CAMPAIGN_ACTION_DEC ? 'Min budget' : 'Max budget'}
                  postfix="$"
                  disabled={isReadOnly}
                  value={limit}
                  onChange={handleLimitChange}
                />
              </>
            )
          }
        </div>
        {
          action !== null && !isReadOnly && (
            <span
              className="clear-link"
              onClick={onClear}
            >
              Clear
            </span>
          )
        }
      </div>
      {
        error !== '' && (
          <div className="sub-section-error">
            { error }
          </div>
        )
      }
    </div>
  )
}

export default CampaignRuleSection
