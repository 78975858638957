import React from 'react'
import { FiCheck } from 'react-icons/fi'

const TargetingTypeSelector = ({ adType, targetList, target, onChange }) => {
  let title = 'Targeting'
  let note = ''
  if (adType === 'sb') {
    title = 'Ad Format'
    note = 'The landing page is where shoppers are directed after they interact with your ad.'
  } else if (adType === 'sdAdFormat') {
    title = 'Ad Format'
    note = 'Ad formats can help you create an ad based on what you want to promote.'
  }
  return (
    <div className="section-container targeting-type-selector">
      <div className="section-title">
        { title }
      </div>
      {
        note !== '' && (
          <div className="section-note">
            { note }
          </div>
        )
      }
      <div className="selector-wrapper">
        {
          targetList.map(targetInfo => (
            <div
              key={targetInfo.value}
              className={`selector${target === targetInfo.value ? ' selected' : ''}`}
              onClick={() => onChange(targetInfo.value)}
            >
              <FiCheck />
              <div className="selector-content">
                <div className="selector-title">{ targetInfo.name }</div>
                <div className="selector-note">{ targetInfo.description }</div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default TargetingTypeSelector
