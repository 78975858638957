import { GET_ACTIVITY_LOG_SUCCEED } from '../actionTypes/activityLog'
import { REVERT_LOG_SUCCESS } from '../actionTypes/campaignLog'

export const initialState = {
  logs: [],
}

const activityLog = (state = initialState, action) => {
	switch (action.type) {
    case GET_ACTIVITY_LOG_SUCCEED:
      return {
        ...state,
        logs: action.data,
      }
    case REVERT_LOG_SUCCESS:
      return {
        ...state,
        logs: (state.logs || []).map((log) => {
          if (parseInt(log.id, 10) !== parseInt(action.data.logId, 10)) {
            return log
          }
          return Object.assign({}, log, {
            reverted: true,
          })
        }),
      }
    default:
      return state
  }
}

export default activityLog
