/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FiFilePlus, FiFileMinus } from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import ConfirmModal from '../CommonComponents/ConfirmModal'

import { getRulesData } from '../../redux/actions/dashboard'
import {
  showTemplateEditor,
  deleteFromTemplate,
} from '../../redux/actions/ruleManager'

import {
  tableSorter,
  formatValue,
} from '../../services/helper'

import {
  RULE_TYPE_SP,
  RULE_TYPE_DP,
  RULE_TYPE_WP,
  RULE_TYPE_CP,
  RULE_TYPE_EP,
  RULE_TYPE_IR,
  ruleNames,
} from '../../utils/defaultValues'

const columns = [
  { key: 'name', name: 'Rule', className: 'col-rule', parentOnly: true },
  { key: 'templateCount', name: 'Active Templates', parentOnly: true },
  { key: 'campaignCount', name: 'Active Campaigns', parentOnly: true },
  { key: 'action', name: '', className: 'col-action', sortable: false, parentOnly: true },
  { key: 'templateName', name: 'Active Templates' },
  { key: 'templateCampaignCount', name: 'Active Campaigns' },
  { key: 'templateAction', name: '', className: 'col-action', sortable: false },
]

const ruleHierarchy = {
  [RULE_TYPE_IR]: 1,
  [RULE_TYPE_EP]: 2,
  [RULE_TYPE_CP]: 4,
  [RULE_TYPE_WP]: 5,
  [RULE_TYPE_DP]: 6,
  [RULE_TYPE_SP]: 7,
}

const RulesView = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [rules, setRules] = useState([])
  const [templateToRemoveFrom, setTemplateToRemoveFrom] = useState(null)
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      try {
        const accessToken = await getAccessTokenSilently()
        const response = await dispatch(getRulesData(accessToken, abortCtrl.signal))
        setRules(response.map((rule) => {
          const templates = []
          let campaignCount = 0
          rule.templates.forEach((template) => {
            template.templateName = template.name
            template.type = rule.type
            template.templateCampaignCount = template.campaigns.length
            campaignCount += template.campaigns.length
            templates.push(template)
          })

          return {
            ...rule,
            name: ruleNames[rule.type],
            hierarchy: ruleHierarchy[rule.type],
            templateCount: rule.templates.length,
            campaignCount,
            children: templates,
          }
        }))
        setIsLoading(false)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoading(false)
        }
      }
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const calcSummary = (records) => {
    const summary = {
      templateCount: 0,
      campaignCount: 0,
    }

    records.forEach((record) => {
      summary.templateCount += parseInt(record.templateCount, 10)
      summary.campaignCount += parseInt(record.campaignCount, 10)
    })

    return summary
  }

  const handleTemplateCreate = rule => (event) => {
    event.stopPropagation()
    dispatch(showTemplateEditor(rule.type))
  }

  const handleTemplateEdit = template => (event) => {
    event.preventDefault()
    dispatch(showTemplateEditor(template.type, template.id))
  }

  const handleDeleteFromTemplate = template => () => {
    setTemplateToRemoveFrom(template)
    setShowRemoveConfirm(true)
  }

  const handleConfirmRemoval = async (confirmed) => {
    setShowRemoveConfirm(false)

    if (!confirmed) {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(deleteFromTemplate(
      accessToken,
      templateToRemoveFrom.type,
      templateToRemoveFrom.id,
    ))
    if (response) {
      setRules(rules.map((rule) => {
        if (rule.type !== templateToRemoveFrom.type) {
          return rule
        }

        let campaignsDeleted = 0
        const children = rule.children.map((child) => {
          if (child.id !== templateToRemoveFrom.id) {
            return child
          }

          campaignsDeleted = child.templateCampaignCount
          return Object.assign({}, child, {
            campaigns: [],
            templateCampaignCount: 0,
          })
        })

        return Object.assign({}, rule, {
          campaignCount: rule.campaignCount - campaignsDeleted,
          children,
        })
      }))
    }
    setIsLoading(false)
  }

  const renderRule = record => (
    <>
      <div className="table-col col-rule">
        { record.name }
      </div>
      <div className="table-col">
        { record.templateCount }
      </div>
      <div className="table-col">
        { record.campaignCount }
      </div>
      <div className="table-col col-action">
        <CustomTooltip
          placement="bottom"
          icon={(
            <FiFilePlus
              className="create-template-link"
              size={24}
              title="Create Template"
              onClick={handleTemplateCreate(record)}
            />
          )}
        >
          <p>Create Template</p>
        </CustomTooltip>
      </div>
    </>
  )

  const renderTotal = summary => (
    <>
      <div className="table-col col-rule">Totals:</div>
      <div className="table-col">
        { formatValue(summary.templateCount, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(summary.campaignCount, 'removeZeroDecimal') }
      </div>
      <div className="table-col col-action" />
    </>
  )

  const renderTemplate = record => (
    <>
      <div className="table-col">
        <a href="#" onClick={handleTemplateEdit(record)}>
          { record.templateName }
        </a>
      </div>
      <div className="table-col col-campaigns">
        { record.templateCampaignCount }
        {
          record.templateCampaignCount > 0 && (
            <CustomTooltip placement="right" className="popup-campaign-names">
              <ul>
                {
                  record.campaigns.map(campaign => (
                    <li key={campaign.campaign_id}>
                      { campaign.name }
                    </li>
                  ))
                }
              </ul>
            </CustomTooltip>
          )
        }
      </div>
      <div className="table-col col-action">
        {
          record.templateCampaignCount > 0 && (
            <CustomTooltip
              placement="bottom"
              icon={(
                <FiFileMinus
                  className="remove-from-template-link"
                  size={24}
                  title="Remove Campaigns from Template"
                  onClick={handleDeleteFromTemplate(record)}
                />
              )}
            >
              <p>Remove Campaigns from Template</p>
            </CustomTooltip>
          )
        }
      </div>
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return record[column.key] || '';
    })
  )

  return (
    <>
      <SortableTable
        tableComponent={GroupTable}
        isLoading={isLoading}
        columns={columns}
        defaultSort={['hierarchy', 'asc']}
        sorter={tableSorter(['name'])}
        className="table-rules"
        records={rules}
        idField="type"
        searchFields={['name']}
        noCheckBox
        hasSticky
        showParentColumnsOnly
        aggregator={calcSummary}
        renderRecord={renderRule}
        renderTotal={renderTotal}
        getExportData={getExportData}
        exportFileName="Rules"
        sorterChild={tableSorter(['templateName'])}
        idFieldChild="id"
        renderChild={renderTemplate}
      />
      <ConfirmModal
        show={showRemoveConfirm}
        text='Are you sure you want to remove campaigns from the template?'
        onConfirm={handleConfirmRemoval}
      />
    </>
  )
}

export default RulesView
