import React, { useState } from 'react'
import { FiVideo } from 'react-icons/fi'

import VideoModal from '../VideoModal'

const VideoLink = ({ videoList, iconOnly = false, modalTitle = '', linkName = 'Watch Tutorial' }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        type="button"
        className="tutorial-link"
        title={linkName}
        onClick={() => { setShowModal(true) }}
      >
        <FiVideo color="#979797" size={16} />
        { !iconOnly && linkName }
      </button>
      <VideoModal
        videoList={videoList}
        showModal={showModal}
        title={modalTitle}
        onClose={() => { setShowModal(false) }}
      />
    </>
  )
}

export default VideoLink
