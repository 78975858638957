import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import YoutubeVideoCollection from './YoutubeVideoCollection'
import LoomVideoCollection from './LoomVideoCollection'

const attachments = [
  { name: 'Smart Pilot Flow.pdf', url: 'https://drive.google.com/file/d/1EPHIEjYYIcfNQfdx6BCXUreV0GiWyaq9/view?ts=62c86746' },
]

const videoList = [
  {
    name: 'Smart Pilot Flow. Let\'s Go!',
    videoId: 'uBPUzeKeyow',
  },
]

const loomVideoList = [
  { name: 'Smart Pilot Enhancements', videoId: '7514713d803e4d789f879115e8b35303' },
]

const SmartPilotFlow = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Smart Pilot Flow"
      coin={10}
      {...props}
    >
      <YoutubeVideoCollection videoList={videoList} onWatch={onWatch} />
      <LoomVideoCollection videoList={loomVideoList} />
      <div className="flow-timestamps">
        <div className="timestamp-section">
          <div className="title">
            Smart Pilot Navigation & Structure 00:43-4:07
          </div>
          <div className="content">
            <span className="timestamp">Smart Pilot Icon & Locations 00:43 – 1:11</span>
            <span className="timestamp">Smart Pilot differences based on Ad Type 1:41 – 4:07</span>
          </div>
        </div>
        <div className="timestamp-section">
          <div className="title">
            Optimization Section 4:08-23:35
          </div>
          <div className="content">
            <span className="timestamp">Intro to Setting up Smart Pilot 4:08-4:21</span>
            <span className="timestamp">Smart Pilot Optimization Basic Settings 6:15-10:46</span>
            <span className="timestamp">Smart Pilot Optimization Advanced Settings 10:47-17:22</span>
            <span className="timestamp">Negative Target Automation 17:24-20:35</span>
            <span className="timestamp">AB Split Test 22:37-23:35</span>
          </div>
        </div>
        <div className="timestamp-section">
          <div className="title">Expansion Section 23:37-32:21</div>
          <div className="content">
            <span className="timestamp">Target Bid Expansion 23:37-24:57</span>
            <span className="timestamp">Search Term Expansion 25:05-32:21</span>
          </div>
        </div>
        <div className="timestamp-section">
          <div className="title">Templates 32:33-37:38</div>
          <div className="content">
            <span className="timestamp">Creation of a Template 34:50-36:06</span>
            <span className="timestamp">Search Term Expansion 37:38-38:30</span>
          </div>
        </div>
      </div>
      <div className="flow-text">
        <p><strong>Entourage 2.0 is equipped with an all new Smart Pilot.</strong></p>
        <p>The Smart Pilot allows you to automate many aspects of advertising so that you can free up more of your time for other things.</p>
        <p>The Smart Pilot is broken down into two sections, Optimization and Expansion.</p>
        <p>The basic optimization settings allow you to get started in seconds by simply choosing a target ACoS.</p>
        <p>The more advanced optimization settings allow you a bit more control.</p>
        <p>We have added premade optimization templates to help you get some wins even faster!</p>
        <p>As for expansion settings, the Smart Pilot allows you to migrate search terms and ASINS from one campaigns to another.</p><br/>
        <p><strong>After completing this flow you'll learn</strong></p>
        <ol>
          <li>
            1) Where the Smart Pilot is located and how to apply a Smart Pilot Template.
          </li>
          <li>
            2) Basic Target Bid Optimization settings and what is the "Genius Bid"?
          </li>
          <li>
            3) Advanced Target Bid Optimization settings which includes eliminating zero click targets, high ACoS targets and more
          </li>
          <li>
            4) How to use frequency, lookback period and clone settings the right way
          </li>
          <li>
            5) How to reduce zero impression targets automatically
          </li>
          <li>
            6) How to harvest both winning search terms and ASINS from campaigns and add them into another
          </li>
          <li>
            7) How to save a template and apply to multiple campaigns.
          </li>
        </ol>
        <p>After completing this flow and applying your very first Smart Pilot, you'll earn an additional 25 Genius Coins for use in the marketplace.</p>
        <p>Let's get started!</p>
      </div>
      <AttachmentList attachments={attachments} />
    </FlowContainer>
  )
}

export default SmartPilotFlow
