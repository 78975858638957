/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { format } from 'date-fns'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'

import { getPaymentHistory, getInvoiceDownloadLink } from '../../redux/actions/auth'

const statusList = {
  paid: 'Paid',
  posted: 'Posted',
  payment_due: 'Payment Due',
  not_paid: 'Not Paid',
  voided: 'Voided',
  pending: 'Pending',
}

const BillingInvoice = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [invoiceList, setInvoiceList] = useState([])

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      dispatch(getPaymentHistory(accessToken)).then((response) => {
        setIsLoading(false)
        setInvoiceList(response)
      }).catch(() => {
        setIsLoading(false)
      })
    })()
  }, []) // eslint-disable-line

  const handleDownload = invoiceId => async (event) => {
    event.preventDefault()
    const accessToken = await getAccessTokenSilently()
    dispatch(getInvoiceDownloadLink(accessToken, invoiceId)).then((response) => {
      if (response.data.url) {
        window.open(response.data.url, '_blank')
      }
    }).catch(() => {
      //
    })
  }

  const renderInvoice = invoice => (
    <>
      <div className="table-col col-status">
        { statusList[invoice.status] || invoice.status }
      </div>
      <div className="table-col col-date">
        { format(invoice.date * 1000, 'dd-MMM-yyyy') }
      </div>
      <div className="table-col">
        { invoice.id }
      </div>
      <div className="table-col">
        { invoice.currency_code } { (invoice.total / 100).toFixed(2) }
        {
          invoice.paid_at && (
            <span className="invoice-paid-at">
              Paid on { format(invoice.paid_at * 1000, 'dd-MMM-yyyy') }
            </span>
          )
        }
      </div>
      <div className="table-col col-download">
        <a href="#" onClick={handleDownload(invoice.id)}>
          Download as PDF
        </a>
      </div>
    </>
  )

  return (
    <div>
      <CustomTable
        isLoading={isLoading}
        className="table-invoices"
        records={invoiceList}
        idField="id"
        noCheckBox
        noSearch
        renderRecord={renderInvoice}
      >
        <div className="table-col col-status">Status</div>
        <div className="table-col col-date">Date</div>
        <div className="table-col">Invoice Number</div>
        <div className="table-col">Amount</div>
        <div className="table-col col-download"></div>
      </CustomTable>
    </div>
  )
}

export default BillingInvoice
