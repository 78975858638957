import React from 'react'

import FlowContainer from './FlowContainer'
import LoomVideoCollection from './LoomVideoCollection'

const videoList = [
  { name: 'Bulk Actions Overview', videoId: 'f1004703ef94422e8bd84940722d4c03' },
  { name: 'Pause/Unpause, Portfolios, Target ACoS', videoId: 'e5ce9e44830142e9b08a792f6bf7a4ca' },
  { name: 'Campaign Naming', videoId: '8990493912aa4f178e04f7b52b6bca60' },
  { name: 'Bids and Placements', videoId: '436b965991be4c5a9edfe80b61b402f1' },
  { name: 'Budgets', videoId: '2b8313ac042c4aaf8a9ef179d19852f4' },
  { name: 'Products, Keywords, ASINs', videoId: '804c3911da16445ab53d927c36c80990' },
  { name: 'Tags', videoId: 'cfe89042856547148042e517d15b8975' },
  { name: 'Campaign Details/Creative', videoId: 'f9e735863bee4e52befbacd9ffb10530' },
  { name: 'Bulk Views', videoId: '8e163733aa1146979347e96fb6c88db8' },
  { name: 'Budgets Tab', videoId: '4b33e8ad9e26403e834e212a9ad3d7e7' },
]

const BulkMoveFlow = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Bulk Actions/Command Center Flow"
      {...props}
    >
      <div className="description">
        (Formerly Bulk Moves)
      </div>
      <LoomVideoCollection videoList={videoList} />
      <div className="flow-text">
        <p><strong>The Entourage Bulk Actions section will help save you more time than ever before!</strong></p>
        <p>Complete the Bulk Actions video series to learn how you can work more efficiently while making more money on Amazon.</p>
        <p>You’ll learn how to adjust campaign status, portfolios, target ACoS, bids, placement, budgets, and more, all in bulk.</p>
        <p>You can even rename campaigns in bulk, assign targets, or use our customizable tagging system.</p>
      </div>
    </FlowContainer>
  )
}

export default BulkMoveFlow
