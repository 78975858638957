import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'rsuite'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import ReportTileWidget from './ReportTileWidget'
import NewCustomMetricWidget from './NewCustomMetricWidget'
import CustomMetricWidget from './CustomMetricWidget'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'
import {
  calculateMetricsDifference,
  calculateCustomMetrics,
} from '../../services/helper'

const TileSection = ({
  sectionKey,
  isLoading,
  customMetricSection,
  viewWidgets,
  customMetricWidgets,
  results,
  pastResults,
  salesData = null,
  customizingSection,
  onRemove,
}) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const renderCustomWidgets = () => {
    const customWidgets = (customMetricWidgets || []).filter(widget =>
      widget.section === customMetricSection
    )
    return customWidgets.map((widget) => {
      const { value, isBetter, difference, diffPercent } = calculateCustomMetrics(
        results,
        pastResults,
        salesData,
        widget
      )

      return (
        <Col key={widget.sp + widget.xMetric + widget.operator + widget.yMetric} xs={6}>
          <CustomMetricWidget
            widget={widget}
            value={value}
            diff={{ value: difference, percent: diffPercent }}
            isBetter={isBetter}
            customizing={customizingSection}
          />
        </Col>
      )
    })
  }

  const renderWidgets = () => {
    const tileWidgets = (viewWidgets || []).filter(w =>
      !w.widgetType || w.widgetType === 'tile'
    )
    return tileWidgets.map((widget) => {
      if (!widget.view && !customizingSection) {
        return null
      }

      if (
        isNonEndemicAccount
        && [
          'revenue',
          'acos',
          'conversion',
          'roas',
          'ntb_orders',
          'ntb_sales',
          'ntb_units',
          'ntb_sales_percent',
          'cost_per_conversion',
          'click_order',
        ].includes(widget.key)
      ) {
        return null
      }

      const {
        isBetter = true,
        difference = 0.0,
        diffPercent = 0.00,
        value = 0.00
      } = calculateMetricsDifference(results, pastResults, salesData, widget.key)

      return (
        <Col key={widget.key} xs={6}>
          <ReportTileWidget
            widget={widget}
            value={value}
            diff={{ value: difference, percent: diffPercent }}
            isBetter={isBetter}
            customizing={customizingSection}
            section={sectionKey}
            onRemove={onRemove}
          />
        </Col>
      )
    })
  }

  return (
    <Row>
      {
        isLoading && (
          <Col xs={24}>
            <LoaderComponent />
          </Col>
        )
      }
      { renderCustomWidgets() }
      { renderWidgets() }
      {
        customizingSection && (
          <Col xs={6}>
            <NewCustomMetricWidget section={customMetricSection} />
          </Col>
        )
      }
    </Row>
  )
}

export default TileSection
