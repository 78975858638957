import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { selectAdTypeFilter } from '../../../redux/actions/campaign'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import { campaignOptions } from '../../../utils/filterDef'

const AdTypeMultiSelector = ({
  isShared = false,
  onChange = null,
  className = '',
  classNamePrefix = '',
  defaultValue = [],
}) => {
  const dispatch = useDispatch()
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const adTypeSelected = useSelector(state => state.campaign.adTypeSelected)

  const [value, setValue] = useState(!isShared && defaultValue.length ? defaultValue : [])

  useEffect(() => {
    if (isShared) {
      setValue(adTypeSelected)
    }
  }, [isShared]) // eslint-disable-line

  const handleChange = (newValue) => {
    setValue(newValue)
    if (onChange) {
      onChange(newValue)
    }

    if (isShared) {
      dispatch(selectAdTypeFilter(newValue))
    }
  }

  let optionsToRender
  if (!isNonEndemicAccount) {
    optionsToRender = campaignOptions
  } else {
    optionsToRender = campaignOptions.filter(option =>
      option.type !== 'ad_type'
    )
  }

  return (
    <Select
      className={className}
      classNamePrefix={classNamePrefix}
      isMulti
      options={optionsToRender}
      value={value}
      placeholder={isNonEndemicAccount ? 'Status' : 'Ad Type and Status'}
      onChange={handleChange}
    />
  )
}

export default AdTypeMultiSelector
