import React from 'react'

import { ReactComponent as CoachSvg } from '../../assets/svg/market_coach.svg'
import { ReactComponent as EmsSvg } from '../../assets/svg/market_ems.svg'

const PRODUCT_COACH = 'coach'
const PRODUCT_EMS = 'ems'

const productList = [
  {
    value: PRODUCT_COACH,
    icon: CoachSvg,
    name: 'Entourage Coaching',
    description: 'Personalized, one-on-one coaching with an Amazon ads expert '
      + 'from PPC Entourage. Whether you need strategic help, guidance on '
      + 'getting the most out of PPCE, or want to bounce ideas '
      + 'for your brand off of a seasoned pro, coaching can be tailored to your needs.',
    buttonLabel: 'Redeem Coins',
  },
  {
    value: PRODUCT_EMS,
    icon: EmsSvg,
    name: 'Entourage Management Services',
    description: 'Entourage\'s done-for-you PPC management option. '
      + 'Have an Amazon advertising expert from the Entourage team take expert care '
      + 'to scale your business profitably while staying current on all the latest strategies '
      + 'and updates so you don\'t have to. Best for sellers spending over $5k/mo in ad spend.',
    buttonLabel: 'Schedule Exploratory Call',
    link: 'https://go.oncehub.com/EntourageManagement',
  },
]

const ProductSelector = ({ selectedProduct, onSelect }) => {
  const renderAction = (product) => {
    if (product.value === PRODUCT_EMS) {
      return (
        <a
          href={product.link}
          className="btn btn-red"
          target="_blank"
          rel="noopener noreferrer"
        >
          { product.buttonLabel }
        </a>
      )
    }

    return (
      <button
        type="button"
        className="btn btn-red"
        onClick={(event) => { event.stopPropagation(); onSelect(product.value, true) }}
      >
        { product.buttonLabel }
      </button>
    )
  }

  return (
    <div className="product-selector">
      {
        productList.map(product => (
          <div
            key={product.value}
            className={`product-item${selectedProduct === product.value ? ' selected' : ''}`}
            onClick={() => { onSelect(product.value) }}
          >
            <div className="svg-wrapper">
              <product.icon />
            </div>
            <div className="product-name">
              { product.name }
            </div>
            <div className="product-description">
              { product.description }
            </div>
            <div className="action-wrapper">
              { renderAction(product) }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default ProductSelector
