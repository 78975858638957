import React from 'react'
import { FiAlertTriangle } from 'react-icons/fi'

import CustomTooltip from '../CommonComponents/CustomTooltip'
import { formatCurrency } from '../../services/helper'

const GeniusBidCell = ({ record, currencySign, currencyRate }) => {
  let contents = 'N/A'
  if (typeof record.maxCpc !== 'undefined') {
    contents = formatCurrency(record.maxCpc, currencySign, currencyRate)

    if (record.campaignType === 'sp'
      && typeof record.bidding === 'object'
      && typeof record.bidding.adjustments !== 'undefined'
      && record.bidding.adjustments.length) {
      contents = (
        <>
          { contents }
          <CustomTooltip placement="right" icon={<FiAlertTriangle size={16} />}>
            <p>This campaign has active placement modifiers.</p>
            <p>We suggest using Placement Optimization section of the web site.</p>
          </CustomTooltip>
        </>
      )
    }
  }

  return (
    <div className="table-col col-genius-bid">
      { contents }
    </div>
  )
}

export default GeniusBidCell
