export const campaignColumnSetting = [
  {
    value: 'default',
    label: 'Default',
    setting: [
      { key: 'campaign', fixed: true, label: 'Campaign' },
      { key: 'target_acos', label: 'Target ACoS %' },
      { key: 'daily_budget', label: 'Daily Budget' },
      { key: 'acos', label: 'ACoS %', direct: true },
      { key: 'impressions', label: 'Imp.' },
      { key: 'clicks', label: 'Clicks' },
      { key: 'ctr', label: 'CTR %' },
      { key: 'cost', label: 'Spend' },
      { key: 'cpc', label: 'Ave CPC', direct: true },
      { key: 'orders', label: 'Orders' },
      { key: 'revenue', label: 'Sales' },
      { key: 'conversion', label: 'Conversion Rate', name: 'Conv %' },
    ]
  }, {
    value: 'brand_metrics',
    label: 'Brand Metrics',
    setting: [
      { key: 'campaign', fixed: true, label: 'Campaign' },
      { key: 'impressions', label: 'Imp.' },
      { key: 'viewable_impressions', label: 'Viewable Imp.' },
      { key: 'orders', label: 'Orders' },
      { key: 'cost', label: 'Spend' },
      { key: 'ntb_orders', label: 'NTB Orders' },
      { key: 'ntb_orders_percent', label: 'NTB Orders %' },
      { key: 'ntb_sales', label: 'NTB Sales' },
      { key: 'ntb_sales_percent', label: 'NTB Sales %' },
      { key: 'acos', label: 'ACoS %', direct: true },
    ]
  }, {
    value: 'funnel_metrics',
    label: 'Funnel Metrics',
    setting: [
      { key: 'campaign', fixed: true, label: 'Campaign' },
      { key: 'impressions', label: 'Imp.' },
      { key: 'viewable_impressions', label: 'Viewable Imp.' },
      { key: 'clicks', label: 'Clicks' },
      { key: 'ctr', label: 'CTR %' },
      { key: 'orders', label: 'Orders' },
      { key: 'conversion', label: 'Conversion Rate', name: 'Conv %' },
      { key: 'acos', label: 'ACoS %', direct: true },
      { key: 'roas', label: 'Return on Ad Spend', name: 'ROAS' },
    ]
  }, {
    value: 'video_metrics',
    label: 'Video Metrics',
    setting: [
      { key: 'campaign', fixed: true, label: 'Campaign' },
      { key: 'impressions', label: 'Imp.' },
      { key: 'viewable_impressions', label: 'Viewable Imp.' },
      { key: 'vtr', label: 'View-Through Rate (VTR)', name: 'VTR'},
      { key: 'vctr', label: 'vCTR'},
      { key: 'video_views_25', label: 'First Quartile', name: '1st Quartile'},
      { key: 'video_views_50', label: 'Midpoint', name: 'Midpoint' },
      { key: 'video_views_75', label: 'Third Quartile', name: '3rd Quartile' },
      { key: 'video_views_100', label: 'Complete', name: 'Complete' },
      { key: 'video_5s_views', label: '5 Sec View', name: '5s View' },
      { key: 'view_5_seconds_rate', label: '5 Sec VR', name: '5s VR' },
    ]
  }, {
    value: 'budget_metrics',
    label: 'Budget Metrics',
    setting: [
      { key: 'campaign', fixed: true, label: 'Campaign' },
      { key: 'daily_budget', label: 'Daily Budget' },
      { key: 'cost', label: 'Spend' },
      { key: 'clicks', label: 'Clicks' },
      { key: 'cpc', label: 'Ave CPC', direct: true },
      { key: 'acos', label: 'ACoS %', direct: true },
      { key: 'roas', label: 'Return on Ad Spend', name: 'ROAS' },
    ]
  }
]

export const campaignColumnList = [
  { key: 'campaign', default: true, fixed: true, label: 'Campaign' },
  { key: 'start_date', label: 'Start Date'},
  { key: 'target_acos', default: true, label: 'Target ACoS %' },
  { key: 'daily_budget', default: true, label: 'Daily Budget' },
  { key: 'acos', default: true, label: 'ACoS %', direct: true },
  { key: 'impressions', default: true, label: 'Imp.' },
  { key: 'clicks', default: true, label: 'Clicks' },
  { key: 'ctr', default: true, label: 'CTR %' },
  { key: 'cost', default: true, label: 'Spend' },
  { key: 'cpc', default: true, label: 'Ave CPC', direct: true },
  { key: 'orders', default: true, label: 'Orders' },
  { key: 'revenue', default: true, label: 'Sales' },
  { key: 'roas', label: 'Return on Ad Spend', name: 'ROAS' },
  { key: 'conversion', default: true, label: 'Conversion Rate', name: 'Conv %' },
  { key: 'ntb_orders', label: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales' },
  { key: 'ntb_sales_percent', label: 'NTB Sales %' },
  { key: 'viewable_impressions', label: 'Viewable Imp.' },
  { key: 'vtr', label: 'View-Through Rate (VTR)', name: 'VTR'},
  { key: 'vctr', label: 'vCTR'},
  { key: 'vcpm', label: 'VCPM'},
  { key: 'video_views_25', label: 'First Quartile'},
  { key: 'video_views_50', label: 'Midpoint'},
  { key: 'video_views_75', label: 'Third Quartile'},
  { key: 'video_views_100', label: 'Complete'},
  { key: 'video_5s_views', label: '5 Sec View'},
  { key: 'view_5_seconds_rate', label: '5 Sec VR'},
]

export const productColumnList = [
  { key: 'product', default: true, fixed: true, label: 'Product', },
  // { key: 'cog', default: true, label: 'Cost of Goods' },
  // { key: 'profit_margin', default: true, label: 'Profit Margin' },
  // { key: 'break_even_cpa', default: true, label: 'Break Even CPA' },
  { key: 'clicks_order_ratio', default: true, label: 'Click/Order' },
  // { key: 'max_cpc', default: true, label: 'Max CPC' },
  // { key: 'true_acos', default: true, label: 'TACOS %'},
  { key: 'active_campaigns', default: true, label: 'Active Campaigns' },
  { key: 'revenue', default: true, label: 'Sales' },
  // { key: 'sales', default: true, label: 'Organic/PPC Sales' },
  // { key: 'sales_ratio', default: true, label: 'Organic/PPC ratio' },
  { key: 'ad_spend_margin', label: 'Ad Spend Margin Impact' },
  { key: 'clicks', default: true, label: 'Clicks' },
  { key: 'ctr', label: 'CTR %' },
  { key: 'cost', default: true, label: 'Spend' },
  { key: 'orders', default: true, label: 'Orders' },
  { key: 'acos', label: 'ACoS %' },
]

export const portfolioColumnList = [
  { key: 'portfolio', default: true, fixed: true, label: 'Portfolio' },
  { key: 'daily_budget', default: true, label: 'Daily Budget', name: 'Daily Budget' },
  { key: 'impressions', default: true, label: 'Imp.', name: 'Imp.' },
  { key: 'clicks', default: true, label: 'Clicks', name: 'Clicks'  },
  { key: 'ctr', default: true, label: 'CTR %', name: 'CTR %' },
  { key: 'cost', default: true, label: 'Spend', name: 'Spend' },
  { key: 'cpc', default: true, label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'orders', default: true, label: 'Orders', name: 'Orders' },
  { key: 'revenue', default: true, label: 'Sales', name: 'Sales' },
  { key: 'acos', default: true, label: 'ACoS %', name: 'ACoS %' },
  { key: 'roas', default: true, label: 'ROAS', name: 'ROAS' },
  { key: 'ntb_orders', label: 'NTB Orders', name: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %', name: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales', name: 'NTB Sales' },
  { key: 'ntb_sales_percent', label: 'NTB Sales %', name: 'NTB Sales %' },
]

export const productCampaignColumnList = [
  { key: 'campaign', default: true, fixed: true, label: 'Campaign', name: 'Campaign', className: 'col-campaign' },
  { key: 'impressions', default: true, label: 'Imp.' },
  { key: 'clicks', default: true, label: 'Clicks'  },
  { key: 'ctr', default: true, label: 'CTR %' },
  { key: 'cost', default: true, label: 'Spend' },
  { key: 'cpc', default: true, label: 'Ave CPC' },
  { key: 'orders', default: true, label: 'Orders',  },
  { key: 'revenue', default: true, label: 'Sales' },
  { key: 'acos', default: true, label: 'ACoS %' },
  { key: 'roas', label: 'Return on Ad Spend' },
  { key: 'conversion', label: 'Conversion Rate', name: 'Conv %' },
  { key: 'ntb_orders', label: 'NTB Orders',  },
  { key: 'ntb_orders_percent', label: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales' },
  { key: 'ntb_sales_percent', label: 'NTB Sales %' },
]

// Names are for tables, and labels are for column editor.
export const bulkNTBColumnList = [
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion Rate %', name: 'Conv %' },
  { key: 'ntb_orders', label: 'NTB Orders', name: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %', name: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales', name: 'NTB Sales', },
  { key: 'ntb_sales_percent', label: 'NTB Sales %', name: 'NTB Sales %', },
  { key: 'viewable_impressions', label: 'Viewable Imp.' },
]

export const bulkBidColumnList = [
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'viewable_impressions', label: 'Viewable Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'conversion', label: 'Conversion Rate %', name: 'Conv %' },
  { key: 'ntb_orders', label: 'NTB Orders', name: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %', name: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales', name: 'NTB Sales', },
  { key: 'ntb_sales_percent', label: 'NTB Sales %', name: 'NTB Sales %', },
]

export const bulkSKUColumnList = [
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion Rate %', name: 'Conv %' },
  { key: 'ntb_orders', label: 'NTB Orders', name: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %', name: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales', name: 'NTB Sales', },
  { key: 'ntb_sales_percent', label: 'NTB Sales %', name: 'NTB Sales %', },
]

export const bulkSTColumnList = [
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'conversion', label: 'Conversion Rate %', name: 'Conv %' },
  { key: 'st_impr_rank', label: 'Search Term Impression Rank', name: 'ST Imp. Rank' },
  { key: 'st_impr_share', label: 'Search Term Impression Share %', name: 'ST Imp. Share' },
  { key: 'viewable_impressions', label: 'Viewable Imp.' },
]

export const bulkPlacementColumnList = [
  { key: 'acos', label: 'ACoS %', name: 'ACoS %' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'impressions', label: 'Impressions', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks' },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'orders', label: 'Orders', name: 'Orders' },
]

export const currencyList = [
  {
    text: 'USD - US Dollar',
    sign: '$',
    flag: 'flag-us',
    code: 'us',
    currencyCode: 'USD',
  },
  {
    text: 'EUR - Euro',
    sign: '€',
    flag: 'flag-eu',
    code: 'eu',
    currencyCode: 'EUR',
  },
  {
    text: 'GBP - British Pound',
    sign: '£',
    flag: 'flag-gb',
    code: 'gb',
    currencyCode: 'GBP',
  },
  {
    text: 'INR - Indian Rupee',
    sign: '₹',
    flag: 'flag-in',
    code: 'in',
    currencyCode: 'INR',
  },
  {
    text: 'AUD - Australian Dollar',
    sign: '$',
    flag: 'flag-au',
    code: 'au',
    currencyCode: 'AUD',
  },
  {
    text: 'CAD - Canadian Dollar',
    sign: '$',
    flag: 'flag-ca',
    code: 'ca',
    currencyCode: 'CAD',
  },
  {
    text: 'JPY - Japanese Yen',
    sign: '¥',
    flag: 'flag-jp',
    code: 'jp',
    currencyCode: 'JPY',
  },
  {
    text: 'MXN - Mexican Peso',
    sign: '$',
    flag: 'flag-mx',
    code: 'mx',
    currencyCode: 'MXN',
  },
  {
    text: 'AED - Emirati Dirham',
    sign: 'د.إ',
    flag: 'flag-ae',
    code: 'ae',
    currencyCode: 'AED',
  },
  {
    text: 'SGD - Singapore Dollar',
    sign: '$',
    flag: 'flag-sg',
    code: 'sg',
    currencyCode: 'SGD',
  },
  {
    text: 'PLN - Polish Zloty',
    sign: 'zł',
    flag: 'flag-pl',
    code: 'pl',
    currencyCode: 'PLN',
  },
  {
    text: 'TRY - Turkish Lira',
    sign: '₺',
    flag: 'flag-tr',
    code: 'tr',
    currencyCode: 'TRY',
  },
  {
    text: 'BRL - Brazilian Real',
    sign: 'R$',
    flag: 'flag-br',
    code: 'br',
    currencyCode: 'BRL',
  },
  {
    text: 'SEK - Swedish Krona',
    sign: 'kr',
    flag: 'flag-se',
    code: 'se',
    currencyCode: 'SEK',
  },
  {
    text: 'EGP - Egyptian Pound',
    sign: 'E£',
    flag: 'flag-eg',
    code: 'eg',
    currencyCode: 'EGP',
  },
  {
    text: 'SAR - Saudi Riyal',
    sign: 'SR',
    flag: 'flag-sa',
    code: 'sa',
    currencyCode: 'SAR',
  },
]

export const countryList = [
  {
    value: 'all',
    label: 'All countries',
  },
  {
    value: 'au',
    label: 'Australia',
  },
  {
    value: 'be',
    label: 'Belgium',
  },
  {
    value: 'br',
    label: 'Brazil',
  },
  {
    value: 'ca',
    label: 'Canada',
  },
  {
    value: 'eg',
    label: 'Egypt',
  },
  {
    value: 'fr',
    label: 'France',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'in',
    label: 'India',
  },
  {
    value: 'it',
    label: 'Italy',
  },
  {
    value: 'jp',
    label: 'Japan',
  },
  {
    value: 'mx',
    label: 'Mexico',
  },
  {
    value: 'nl',
    label: 'Netherland',
  },
  {
    value: 'pl',
    label: 'Poland',
  },
  {
    value: 'sa',
    label: 'Saudi Arabia',
  },
  {
    value: 'sg',
    label: 'Singapore',
  },
  {
    value: 'es',
    label: 'Spain',
  },
  {
    value: 'se',
    label: 'Sweden',
  },
  {
    value: 'tr',
    label: 'Turkey',
  },
  {
    value: 'ae',
    label: 'United Arabic Emirates',
  },
  {
    value: 'gb',
    label: 'United Kingdom',
  },
  {
    value: 'us',
    label: 'United States',
  },
]

// List of regions supported by Amazon.
export const regionList = [
  { label: 'North America', value: 'na' },
  { label: 'Europe', value: 'eu' },
  { label: 'Far East', value: 'fe' },
]

// Key to sessionStorage to keep the app state.
export const STORAGE_KEY_STATE = 'sp_state'

// Key to sessionStorage to keep the selected region.
export const STORAGE_KEY_REGION = 'signup_region'

// Key to sessionStorage to keep the selected account type.
export const STORAGE_KEY_ACCOUNT_TYPE = 'signup_account_type'

// Key to sessionStorage to keep whether authorization flow
// is started for adding new accounts or re-authorizing.
export const STORAGE_KEY_ORIGIN = 'auth_origin'

export const campaignTypeMap = {
  sb: 'Sponsored Brand',
  sp: 'Sponsored Product',
  sd: 'Sponsored Display',
}

export const stateLabels = {
  enabled: 'Active',
  paused: 'Paused',
  archived: 'Archived',
}

export const ADJUST_BID_RAISE_ABSOLUTE = 'raise_by_absolute'
export const ADJUST_BID_LOWER_ABSOLUTE = 'lower_by_absolute'
export const ADJUST_BID_RAISE_PERCENT = 'raise_by_percent'
export const ADJUST_BID_LOWER_PERCENT = 'lower_by_percent'
export const ADJUST_BID_CPC = 'cpc'
export const ADJUST_BID_CPC_RAISE_ABSOLUTE = 'cpc_raise_by_absolute'
export const ADJUST_BID_CPC_LOWER_ABSOLUTE = 'cpc_lower_by_absolute'
export const ADJUST_BID_CPC_RAISE_PERCENT = 'cpc_raise_by_percent'
export const ADJUST_BID_CPC_LOWER_PERCENT = 'cpc_lower_by_percent'

export const adjustBidOptions = [
  { value: 'setBid', label: 'Set bid to ($)' },
  { value: ADJUST_BID_RAISE_ABSOLUTE, label: 'Raise bid by ($)' },
  { value: ADJUST_BID_LOWER_ABSOLUTE, label: 'Lower bid by ($)' },
  { value: ADJUST_BID_RAISE_PERCENT, label: 'Raise bid by (%)' },
  { value: ADJUST_BID_LOWER_PERCENT, label: 'Lower bid by (%)' },
  { value: ADJUST_BID_CPC, label: 'Change to avg CPC ($)' },
  { value: ADJUST_BID_CPC_RAISE_ABSOLUTE, label: 'Change to avg CPC + ($)' },
  { value: ADJUST_BID_CPC_LOWER_ABSOLUTE, label: 'Change to avg CPC - ($)' },
  { value: ADJUST_BID_CPC_RAISE_PERCENT, label: 'Change to avg CPC + (%)' },
  { value: ADJUST_BID_CPC_LOWER_PERCENT, label: 'Change to avg CPC - (%)' },
]

export const PLACEMENT_TOP = 'placementTop'
export const PLACEMENT_TOP_V3 = 'PLACEMENT_TOP'
export const PLACEMENT_PRODUCT_PAGE = 'placementProductPage'
export const PLACEMENT_PRODUCT_PAGE_V3 = 'PLACEMENT_PRODUCT_PAGE'
export const PLACEMENT_REST_OF_SEARCH = 'placementRestOfSearch'
export const PLACEMENT_REST_OF_SEARCH_V3 = 'PLACEMENT_REST_OF_SEARCH'

export const placementList = {
  [PLACEMENT_TOP]: 'Top of search (first page)',
  [PLACEMENT_PRODUCT_PAGE]: 'Product pages',
  [PLACEMENT_REST_OF_SEARCH]: 'Rest of search',
}

export const MAX_PLACEMENT_BID_CHANGE = 900

export const tagColumnList = [
  { key: 'tag', default: true, fixed: true, label: 'Tag' },
  { key: 'daily_budget', default: true, label: 'Daily Budget', name: 'Daily Budget' },
  { key: 'impressions', default: true, label: 'Imp.', name: 'Imp.' },
  { key: 'clicks', default: true, label: 'Clicks', name: 'Clicks'  },
  { key: 'ctr', default: true, label: 'CTR %', name: 'CTR %' },
  { key: 'cost', default: true, label: 'Spend', name: 'Spend' },
  { key: 'cpc', default: true, label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'orders', default: true, label: 'Orders', name: 'Orders' },
  { key: 'revenue', default: true, label: 'Sales', name: 'Sales' },
  { key: 'acos', default: true, label: 'ACoS %', name: 'ACoS %' },
  { key: 'roas', default: true, label: 'ROAS', name: 'ROAS' },
  { key: 'ntb_orders', label: 'NTB Orders', name: 'NTB Orders' },
  { key: 'ntb_orders_percent', label: 'NTB Orders %', name: 'NTB Orders %' },
  { key: 'ntb_sales', label: 'NTB Sales', name: 'NTB Sales' },
  { key: 'ntb_sales_percent', label: 'NTB Sales %', name: 'NTB Sales %' },
]

export const STREAM_VIEW_MODE_DAY = 'day'
export const STREAM_VIEW_MODE_DAY_HOUR = 'day_hour'
export const STREAM_VIEW_MODE_HOUR = 'hour'

export const dowList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const COMPARISON_VIEW_TYPE_WEEKLY = 'weekly'
export const COMPARISON_VIEW_TYPE_MONTHLY = 'monthly'

export const COMPARISON_VIEW_TYPE_LABELS = {
  [COMPARISON_VIEW_TYPE_WEEKLY]: 'Weekly View',
  [COMPARISON_VIEW_TYPE_MONTHLY]: 'Monthly View',
}

export const metricList = {
  organicRevenue: {
    name: 'Organic Revenue',
    tooltip: 'The amount of revenue generated from non-advertising sales. ' +
      'If you see negative organic revenue, Amazon may be experiencing ' +
      'delayed attribution (assigning revenue to certain orders). ' +
      'This is corrected once Amazon updates the data they send to us.'
  },
  revenue: {
    name: 'PPC Revenue',
    tooltip: 'The total value of products sold to shoppers within the specified timeframe due to clicks on your ads',
  },
  cost: {
    name: 'Ad Spend',
    tooltip: 'The total click charges for a product ad',
  },
  impressions: {
    name: 'Impressions',
    tooltip: 'The number of times ads were displayed',
  },
  clicks: {
    name: 'Clicks/CTR(%)',
  },
  orders: {
    name: 'Orders',
  },
  conversion: {
    name: 'Conversion Rate(%)',
  },
  acos: {
    name: 'ACoS(%)',
  },
  cpc: {
    name: 'Ave CPC',
  },
  roas: {
    name: 'ROAS',
    tooltip: 'Return on ad spend (ROAS)<br /><br />' +
      'The revenue you receive from your advertising investment. ' +
      'This is calculated by dividing sales attributed to your ads by your spend.',
  },
  ntb_orders: {
    name: 'NTB Orders',
    tooltip: 'The number of first-time orders for products within the brand over a 1-year period.',
  },
  ntb_sales: {
    name: 'NTB Sales',
    tooltip: 'The total sales (in local currency) of new-to-brand orders.',
  },
  ntb_units: {
    name: 'NTB Units',
  },
  ntb_sales_percent: {
    name: '% of NTB Sales',
    tooltip: 'The percentage of total sales (in local currency) that are new-to-brand sales.',
  },
  viewable_impressions: {
    name: 'Viewable Impressions',
  },
  vtr: {
    name: 'VTR',
  },
  vctr: {
    name: 'vCTR',
  },
  vcpm: {
    name: 'VCPM',
  },
}

export const accountReportPageSideNavItems = [
  {
    key: 'overview',
    text: 'Account Overview'
  },{
    key: 'all_sponsored_ads',
    text: 'All Sponsored Ads'
  },{
    key: 'sponsored_products',
    text: 'Sponsored Product'
  },{
    key: 'sponsored_brands',
    text: 'Sponsored Brand'
  },{
    key: 'sponsored_display',
    text: 'Sponsored Display'
  },{
    key: 'brand_growth',
    text: 'Brand Growth'
  },{
    key: 'budget_utilization',
    text: 'Budget Utilization'
  },{
    key: 'hourly_data',
    text: 'Hourly Data'
  },{
    key: 'portfolios',
    text: 'Your Portfolios'
  },{
    key: 'tags',
    text: 'Your Tags'
  },{
    key: 'campaigns',
    text: 'Your Campaigns'
  },{
    key: 'products',
    text: 'Your Products'
  },{
    key: 'keywords',
    text: 'Your Keywords'
  },{
    key: 'targets',
    text: 'Your Targets'
  },{
    key: 'performanceByAdType',
    text: 'Performance By Ad Type'
  },{
    key: 'performanceByPlacement',
    text: 'Performance By Placement'
  },{
    key: 'performanceByBidType',
    text: 'Performance By Bid Type'
  },{
    key: 'performanceByMatchType',
    text: 'Performance By Match Type'
  }
]

export const reportSections = {
  accountOverview: { show: true, note: true},
  allSponsoredAds: { show: true, note: true},
  sponsoredProductAds: { show: true, note: true},
  sponsoredBrandAds: { show: true, note: true},
  sponsoredDisplayAds: { show: true, note: true},
  brandGrowth: { show: true, note: true},
  budgetUtilization: { show: true, note: true},
  hourlyData: { show: true, note: true},
  tags: { show: true, note: true},
  portfolios: { show: true, note: true},
  campaigns: { show: true, note: true},
  products: { show: true, note: true},
  keywords: { show: true, note: true},
  targets: { show: true, note: true},
  performanceByAdType: { show: true, note: true},
  performanceByPlacement: { show: true, note: true},
  performanceByBidType: { show: true, note: true},
  performanceByMatchType: { show: true, note: true},
}

export const reportWidgets = {
  allSponsoredAds: [
    { key: 'cost', type: 'currency', default: true, title: 'Ad Spend' },
    { key: 'organic_revenue', type: 'currency', default: true, title: 'Organic Revenue' },
    { key: 'ppc_revenue_all', type: 'currency', default: true, title: 'PPC Revenue' },
    { key: 'acos', type: 'percent', default: true, title: 'ACoS' },
    { key: 'true_acos', type: 'percent', title: 'TACoS' },
    { key: 'impressions', type: 'number', title: 'Impressions', decimal: 0 },
    { key: 'clicks', type: 'number', title: 'Clicks', decimal: 0 },
    { key: 'ctr', type: 'percent', title: 'CTR%' },
    { key: 'cpc', type: 'currency', title: 'Average CPC' },
    { key: 'conversion', type: 'percent', title: 'Conversion Rate %' },
    { key: 'roas', type: 'number', default: true, title: 'ROAS' },
    { key: 'ntb_orders', type: 'number', title: 'NTB Orders', decimal: 0 },
    { key: 'ntb_sales', type: 'currency', title: 'NTB Sales' },
    { key: 'ntb_units', type: 'number', title: 'NTB Units', decimal: 0 },
    { key: 'ntb_sales_percent', type: 'percent', title: '% of NTB Sales' },
    { key: 'spend_vs_sales', type: 'currency', title: 'Spend vs. Sales', widgetType: 'chart' },
    { key: 'trending_acos', type: 'percent', default: true, title: 'Trending ACoS', widgetType: 'chart' },
    { key: 'trending_ctr', type: 'percent', title: 'Trending CTR', widgetType: 'chart' },
    { key: 'trending_conversion_rate', type: 'percent', title: 'Trending Conversion Rate', widgetType: 'chart' },
    { key: 'trending_cpc', type: 'currency', title: 'Trending CPC', widgetType: 'chart' },
    { key: 'unproductive_ad_spend_rate', type: 'percent', default: true, title: 'Trending Awareness Ad Spend %', widgetType: 'chart', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'unproductive_ad_spend', type: 'currency', default: true, title: 'Awareness Ad Spend', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'productive_ad_spend_rate', type: 'percent', default: true, title: 'Productive Ad Spend Rate', tooltip: 'Spend that led to a direct sale.'},
    { key: 'productive_ad_spend', type: 'currency', default: true, title: 'Productive Ad Spend', tooltip: 'Spend that led to a direct sale.'},
    { key: 'cost_per_1000_impressions', type: 'currency', default: true, title: 'Spend / 1,000 Impressions' },
    { key: 'cost_per_conversion', type: 'currency', default: true, title: 'Spend / Conversion' },
    { key: 'click_order', type: 'number', default: true, title: 'Click / Order Ratio' },
    { key: 'all_ads_table', title: 'All Sponsored Ads', widgetType: 'table'},
    { key: 'brand_vs_non_brand', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'chart' },
    { key: 'sales_by_ad_type', type: 'currency', title: 'Sales By Ad Type', widgetType: 'pieChart' },
    { key: 'organic_vs_ppc', type: 'currency', title: 'Organic vs PPC Sales', widgetType: 'pieChart' },
    { key: 'sales_by_match_type', type: 'currency', title: 'Sales By Match Type', widgetType: 'pieChart' },
    { key: 'brand_vs_non_brand_pie', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'pieChart' },
    { key: 'branded_sale', type: 'currency', default: true, title: 'Branded Sales' },
    { key: 'non_branded_sale', type: 'currency', default: true, title: 'Non Branded Sales' },
    { key: 'comparison_mom_table', type: 'number', default: true, title: 'Month over Month', widgetType: 'special' },
  ],
  sponsoredProductAds: [
    { key: 'cost', type: 'currency', default: true, title: 'Ad Spend' },
    { key: 'revenue', type: 'currency', default: true, title: 'PPC Revenue' },
    { key: 'acos', type: 'percent', default: true, title: 'ACoS' },
    { key: 'impressions', type: 'number', title: 'Impressions', decimal: 0 },
    { key: 'clicks', type: 'number', title: 'Clicks', decimal: 0 },
    { key: 'ctr', type: 'percent', title: 'CTR%' },
    { key: 'cpc', type: 'currency', title: 'Average CPC' },
    { key: 'conversion', type: 'percent', title: 'Conversion Rate %' },
    { key: 'roas', type: 'number', default: true, title: 'ROAS' },
    { key: 'spend_vs_sales', type: 'currency', title: 'Spend vs. Sales', widgetType: 'chart' },
    { key: 'trending_acos', type: 'percent', default: true, title: 'Trending ACoS', widgetType: 'chart' },
    { key: 'trending_ctr', type: 'percent', title: 'Trending CTR', widgetType: 'chart' },
    { key: 'trending_conversion_rate', type: 'percent', title: 'Trending Conversion Rate', widgetType: 'chart' },
    { key: 'trending_cpc', type: 'currency', title: 'Trending CPC', widgetType: 'chart' },
    { key: 'unproductive_ad_spend_rate', type: 'percent', default: true, title: 'Trending Awareness Ad Spend %', widgetType: 'chart', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'unproductive_ad_spend', type: 'currency', default: true, title: 'Awareness Ad Spend', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'productive_ad_spend_rate', type: 'percent', default: true, title: 'Productive Ad Spend Rate', tooltip: 'Spend that led to a direct sale.'},
    { key: 'productive_ad_spend', type: 'currency', default: true, title: 'Productive Ad Spend', tooltip: 'Spend that led to a direct sale.'},
    { key: 'cost_per_1000_impressions', type: 'currency', default: true, title: 'Spend / 1,000 Impressions' },
    { key: 'cost_per_conversion', type: 'currency', default: true, title: 'Spend / Conversion' },
    { key: 'click_order', type: 'number', default: true, title: 'Click / Order Ratio' },
    { key: 'sp_ads_table', title: 'Sponsored Products Ads', widgetType: 'table'},
    { key: 'brand_vs_non_brand', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'chart' },
    { key: 'sales_by_match_type', type: 'currency', title: 'Sales By Match Type', widgetType: 'pieChart' },
    { key: 'sales_by_placement', type: 'currency', title: 'Sales By Placement', widgetType: 'pieChart' },
    { key: 'sales_by_bid_type', type: 'currency', title: 'Sales By Bid Type', widgetType: 'pieChart' },
    { key: 'brand_vs_non_brand_pie', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'pieChart' },
    { key: 'branded_sale', type: 'currency', default: true, title: 'Branded Sales' },
    { key: 'non_branded_sale', type: 'currency', default: true, title: 'Non Branded Sales' },
  ],
  sponsoredBrandAds: [
    { key: 'cost', type: 'currency', default: true, title: 'Ad Spend' },
    { key: 'revenue', type: 'currency', default: true, title: 'PPC Revenue' },
    { key: 'acos', type: 'percent', default: true, title: 'ACoS' },
    { key: 'impressions', type: 'number', title: 'Impressions', decimal: 0 },
    { key: 'clicks', type: 'number', title: 'Clicks', decimal: 0 },
    { key: 'ctr', type: 'percent', title: 'CTR%' },
    { key: 'cpc', type: 'currency', title: 'Average CPC' },
    { key: 'conversion', type: 'percent', title: 'Conversion Rate %' },
    { key: 'roas', type: 'number', title: 'ROAS' },
    { key: 'ntb_orders', type: 'number', default: true, title: 'NTB Orders', decimal: 0 },
    { key: 'ntb_sales', type: 'currency', title: 'NTB Sales' },
    { key: 'ntb_units', type: 'number', title: 'NTB Units', decimal: 0 },
    { key: 'ntb_sales_percent', type: 'percent', title: '% of NTB Sales' },
    { key: 'spend_vs_sales', type: 'currency', title: 'Spend vs. Sales', widgetType: 'chart' },
    { key: 'trending_acos', type: 'percent', title: 'Trending ACoS', widgetType: 'chart' },
    { key: 'trending_ctr', type: 'percent', title: 'Trending CTR', widgetType: 'chart' },
    { key: 'trending_conversion_rate', type: 'percent', title: 'Trending Conversion Rate', widgetType: 'chart' },
    { key: 'trending_cpc', type: 'currency', title: 'Trending CPC', widgetType: 'chart' },
    { key: 'trending_ntb_revenue', type: 'currency', default: true, title: 'Trending New To Brand Revenue', widgetType: 'chart' },
    { key: 'trending_ntb_units', type: 'currency', title: 'Trending New To Brand Units', widgetType: 'chart' },
    { key: 'trending_ntb_order_rate', type: 'currency', title: 'Trending New To Brand Order Rate', widgetType: 'chart' },
    { key: 'unproductive_ad_spend_rate', type: 'percent', default: true, title: 'Trending Awareness Ad Spend %', widgetType: 'chart', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'unproductive_ad_spend', type: 'currency', default: true, title: 'Awareness Ad Spend', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'productive_ad_spend_rate', type: 'percent', default: true, title: 'Productive Ad Spend Rate', tooltip: 'Spend that led to a direct sale.'},
    { key: 'productive_ad_spend', type: 'currency', default: true, title: 'Productive Ad Spend', tooltip: 'Spend that led to a direct sale.'},
    { key: 'cost_per_1000_impressions', type: 'currency', default: true, title: 'Spend / 1,000 Impressions' },
    { key: 'cost_per_conversion', type: 'currency', default: true, title: 'Spend / Conversion' },
    { key: 'click_order', type: 'number', default: true, title: 'Click / Order Ratio' },
    { key: 'all_ads_table', title: 'Sponsored Brands Ads', widgetType: 'table'},
    { key: 'brand_vs_non_brand', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'chart' },
    { key: 'sales_by_match_type', type: 'currency', title: 'Sales By Match Type', widgetType: 'pieChart' },
    { key: 'brand_vs_non_brand_pie', type: 'currency', title: 'Branded vs Non-Branded Sales', widgetType: 'pieChart' },
    { key: 'branded_sale', type: 'currency', default: true, title: 'Branded Sales' },
    { key: 'non_branded_sale', type: 'currency', default: true, title: 'Non Branded Sales' },
    { key: 'viewable_impressions', type: 'number', default: true, title: 'Viewable Impressions', decimal: 0 },
    { key: 'vtr', type: 'percent', default: true, title: 'View-Through Rate (VTR)' },
    { key: 'vctr', type: 'percent', default: true, title: 'vCTR' },
    { key: 'video_25_views', type: 'number', default: true, title: 'Video First Quartile Views', decimal: 0 },
    { key: 'video_50_views', type: 'number', default: true, title: 'Video Midpoint Views', decimal: 0 },
    { key: 'video_75_views', type: 'number', default: true, title: 'Video Third Quartile Views', decimal: 0 },
    { key: 'video_100_views', type: 'number', default: true, title: 'Video Complete Views', decimal: 0 },
    { key: 'view_5_seconds', type: 'number', default: true, title: '5 Seconds Views', decimal: 0 },
    { key: 'view_5_seconds_rate', type: 'percent', default: true, title: '5 Seconds View Rate' },
  ],
  sponsoredDisplayAds: [
    { key: 'cost', type: 'currency', default: true, title: 'Ad Spend' },
    { key: 'revenue', type: 'currency', default: true, title: 'PPC Revenue' },
    { key: 'acos', type: 'percent', default: true, title: 'ACoS' },
    { key: 'impressions', type: 'number', title: 'Impressions', decimal: 0 },
    { key: 'clicks', type: 'number', title: 'Clicks', decimal: 0 },
    { key: 'ctr', type: 'percent', title: 'CTR%' },
    { key: 'cpc', type: 'currency', title: 'Average CPC' },
    { key: 'conversion', type: 'percent', title: 'Conversion Rate %' },
    { key: 'roas', type: 'number', default: true, title: 'ROAS' },
    { key: 'ntb_orders', type: 'number', title: 'NTB Orders', decimal: 0 },
    { key: 'ntb_sales', type: 'currency', title: 'NTB Sales' },
    { key: 'ntb_units', type: 'number', title: 'NTB Units', decimal: 0 },
    { key: 'ntb_sales_percent', type: 'percent', title: '% of NTB Sales' },
    { key: 'spend_vs_sales', type: 'currency', title: 'Spend vs. Sales', widgetType: 'chart' },
    { key: 'trending_acos', type: 'percent', default: true, title: 'Trending ACoS', widgetType: 'chart' },
    { key: 'trending_ctr', type: 'percent', title: 'Trending CTR', widgetType: 'chart' },
    { key: 'trending_conversion_rate', type: 'percent', title: 'Trending Conversion Rate', widgetType: 'chart' },
    { key: 'trending_cpc', type: 'currency', title: 'Trending CPC', widgetType: 'chart' },
    { key: 'unproductive_ad_spend_rate', type: 'percent', default: true, title: 'Trending Awareness Ad Spend %', widgetType: 'chart', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'unproductive_ad_spend', type: 'currency', default: true, title: 'Awareness Ad Spend', tooltip: 'Spend that did not directly lead to a sale but led to product awareness.'},
    { key: 'productive_ad_spend_rate', type: 'percent', default: true, title: 'Productive Ad Spend Rate', tooltip: 'Spend that led to a direct sale.'},
    { key: 'productive_ad_spend', type: 'currency', default: true, title: 'Productive Ad Spend', tooltip: 'Spend that led to a direct sale.'},
    { key: 'cost_per_1000_impressions', type: 'currency', default: true, title: 'Spend / 1,000 Impressions' },
    { key: 'cost_per_conversion', type: 'currency', default: true, title: 'Spend / Conversion' },
    { key: 'click_order', type: 'number', default: true, title: 'Click / Order Ratio' },
    { key: 'sp_ads_table', default: true, title: 'Sponsored Display Ads', widgetType: 'table'},
    { key: 'viewable_impressions', type: 'number', title: 'Viewable Impressions', decimal: 0 },
    { key: 'vtr', type: 'percent', title: 'VTR' },
    { key: 'vctr', type: 'percent', title: 'vCTR' },
    { key: 'vcpm', type: 'currency', default: true, title: 'VCPM' },
  ],
  brandGrowth: [
    { key: 'sp_impressions', type: 'number', default: true, title: 'SP', decimal: 0, metricType: 'impressions'},
    { key: 'sb_impressions', type: 'number', default: true, title: 'SB', decimal: 0, metricType: 'impressions'},
    { key: 'sd_impressions', type: 'number', default: true, title: 'SD', decimal: 0, metricType: 'impressions'},
    { key: 'sp_cost_per_1000_impressions', type: 'currency', default: true, title: 'SP', metricType: 'cost_per_1000_impressions'},
    { key: 'sb_cost_per_1000_impressions', type: 'currency', default: true, title: 'SB', metricType: 'cost_per_1000_impressions'},
    { key: 'sd_cost_per_1000_impressions', type: 'currency', default: true, title: 'SD', metricType: 'cost_per_1000_impressions'},
    { key: 'sb_ntb_orders', type: 'number', default: true, title: 'SB', decimal: 0, metricType: 'ntb_orders'},
    { key: 'sd_ntb_orders', type: 'number', default: true, title: 'SD', decimal: 0, metricType: 'ntb_orders'},
    { key: 'sb_ntb_sales', type: 'currency', default: true, title: 'SB', metricType: 'ntb_sales'},
    { key: 'sd_ntb_sales', type: 'currency', default: true, title: 'SD', metricType: 'ntb_sales'},
    { key: 'sb_ntb_units', type: 'number', default: true, title: 'SB', decimal: 0, metricType: 'ntb_units'},
    { key: 'sd_ntb_units', type: 'number', default: true, title: 'SD', decimal: 0, metricType: 'ntb_units'},
    { key: 'sb_percent_ntb_sales', type: 'percent', default: true, title: 'SB', metricType: 'ntb_sales_percent'},
    { key: 'sd_percent_ntb_sales', type: 'percent', default: true, title: 'SD', metricType: 'ntb_sales_percent'},
    { key: 'sp_brand_vs_non_brand', type: 'currency', default: true, title: 'SP', widgetType: 'chart', metricType: 'brand_vs_non_brand'},
    { key: 'sp_brand_vs_non_brand_pie', type: 'currency', default: true, title: 'SP', widgetType: 'pieChart', metricType: 'brand_vs_non_brand'},
    { key: 'sb_brand_vs_non_brand', type: 'currency', default: true, title: 'SB', widgetType: 'chart', metricType: 'brand_vs_non_brand'},
    { key: 'sb_brand_vs_non_brand_pie', type: 'currency', default: true, title: 'SB', widgetType: 'pieChart', metricType: 'brand_vs_non_brand'},
  ],
  budgetUtilization: [
    { key: 'cost_by_ad_type', default: true, title: 'Ad Spend By Ad Type', widgetType: 'pieChart'},
    { key: 'cost_by_match_type', default: true, title: 'Ad Spend By Match Type', widgetType: 'pieChart'},
    { key: 'cost_by_sku', default: true, title: 'Ad Spend By SKU', widgetType: 'table'},
    { key: 'cost_by_portfolio', default: true, title: 'Ad Spend By Portfolio', widgetType: 'table'},
    { key: 'cost_by_tag', default: true, title: 'Ad Spend By Tag', widgetType: 'table'},
    { key: 'cost_by_target', default: true, title: 'Ad Spend By Keywords vs ASIN Target', widgetType: 'pieChart'},
    { key: 'cost_by_keyword', default: true, title: 'Ad Spend By Search Term Length', widgetType: 'pieChart'},
    { key: 'cost_by_placement', default: true, title: 'Ad Spend By Placement', widgetType: 'pieChart'},
    { key: 'cost_by_targeting_type', default: true, title: 'Ad Spend By Targeting Type', widgetType: 'pieChart'},
    { key: 'cost_trend_by_ad_type', default: true, title: 'Ad Spend Trend By Ad Type', widgetType: 'chart'},
    { key: 'cost_trend_by_branded', default: true, title: 'Branded vs. Non-Branded Ad Spend Trend', widgetType: 'chart'},
  ],
  hourlyData: [
    { key: 'stream_data', type: 'number', default: true, title: 'Hourly Data', widgetType: 'special' },
  ],
  campaigns: [
    { key: 'highest_revenue', default: true, title: 'Highest Revenue', widgetType: 'table'},
    { key: 'expensive_campaign', default: true, title: 'Highest Spend', widgetType: 'table'}
  ],
  products: [
    { key: 'highest_revenue', default: true, title: 'Highest Revenue', widgetType: 'table'},
    { key: 'expensive_product', default: true, title: 'Highest Spend', widgetType: 'table'}
  ],
  keywords: [
    { key: 'keywords_track', default: true, title: 'Keyword Tracker', widgetType: 'table'},
    { key: 'highest_revenue', default: true, title: 'Highest Revenue', widgetType: 'table'},
    { key: 'profitable_keyword', default: true, title: 'Most Profitable', widgetType: 'table'},
    { key: 'expensive_keyword', default: true, title: 'Highest Spend', widgetType: 'table'}
  ],
  targets: [
    { key: 'highest_revenue', default: true, title: 'Highest Revenue', widgetType: 'table'},
    { key: 'profitable_target', default: true, title: 'Most Profitable', widgetType: 'table'},
    { key: 'expensive_target', default: true, title: 'Highest Spend', widgetType: 'table'}
  ],
  performanceByAdType: [
    { key: 'sponsoredProduct', type: 'number', default: true, title: 'Sponsored Product', widgetType: 'special' },
    { key: 'sponsoredBrand', type: 'number', default: true, title: 'Sponsored Brand', widgetType: 'special' },
    { key: 'sponsoredDisplay', type: 'number', default: true, title: 'Sponsored Display', widgetType: 'special' },
  ],
  performanceByPlacement: [
    { key: 'topOfSearch', type: 'number', default: true, title: 'Top of Search (First Page)', widgetType: 'special' },
    { key: 'productPage', type: 'number', default: true, title: 'Product Page', widgetType: 'special' },
    { key: 'restOfSearch', type: 'number', default: true, title: 'Rest of Search', widgetType: 'special' },
  ],
  performanceByBidType: [
    { key: 'legacy', type: 'number', default: true, title: 'Dynamic bids - down only', widgetType: 'special' },
    { key: 'auto', type: 'number', default: true, title: 'Dynamic bids - up and down', widgetType: 'special' },
    { key: 'manual', type: 'number', default: true, title: 'Fixed Bid', widgetType: 'special' },
  ],
  performanceByMatchType: [
    { key: 'exact', type: 'number', default: true, title: 'Exact', widgetType: 'special' },
    { key: 'broad', type: 'number', default: true, title: 'Broad', widgetType: 'special' },
    { key: 'phrase', type: 'number', default: true, title: 'Phrase', widgetType: 'special' },
  ]
}

export const reportWidgetSuffix = {
  allSponsoredAds: '',
  sponsoredProductAds: '(SP)',
  sponsoredBrandAds: '(SB)',
  sponsoredDisplayAds: '(SD)'
}

export const defaultMetricValue = {
  impressions: 0,
  clicks: 0,
  orders: 0,
  cost: 0,
  revenue: 0,
  cpc: 0,
  ctr: 0,
  conversion: 0,
  acos: 0,
}

export const MAX_REPORT_NOTE_CHARACTER_LENGTH = 500

export const mainMetricList = [
  { value: 'cost', label: 'Ad Spend', available: ['all', 'sp', 'sb', 'sd']},
  { value: 'organic_revenue', label: 'Organic Revenue', available: ['all']},
  { value: 'revenue', label: 'PPC Revenue', available: ['all', 'sp', 'sb', 'sd']},
  { value: 'impressions', label: 'Impressions', available: ['all', 'sp', 'sb', 'sd']},
  { value: 'clicks', label: 'Clicks', available: ['all', 'sp', 'sb', 'sd']},
  { value: 'orders', label: 'Orders', available: ['all', 'sp', 'sb', 'sd']},
  { value: 'ntb_orders', label: 'NTB Orders', available: ['all', 'sb', 'sd']},
  { value: 'ntb_sales', label: 'NTB Sales', available: ['all', 'sb', 'sd']},
  { value: 'ntb_units', label: 'NTB Units', available: ['all', 'sb', 'sd']},
  { value: 'viewable_impressions', label: 'Viewable Impressions', available: ['sb']},
  { value: 'videoViews25', label: 'Video First Quartile Views', available: ['sb']},
  { value: 'videoViews50', label: 'Video Midpoint Views', available: ['sb']},
  { value: 'videoViews75', label: 'Video Third Quartile Views', available: ['sb']},
  { value: 'videoViews100', label: 'Video Complete Views', available: ['sb']},
  { value: 'video5sViews', label: '5 Seconds Views', available: ['sb']}
]

export const accountTypeOptions = [
  { label: '- All Types -', value: '' },
  { label: 'Seller', value: 'seller' },
  { label: 'Vendor', value: 'vendor' },
]

export const accountTypeWithDspOptions = [
  { label: '- All Types -', value: '' },
  { label: 'Seller', value: 'seller' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'DSP', value: 'dsp' },
]

export const JOB_STATUS_PENDING = 1
export const JOB_STATUS_COMPLETED = 2
export const JOB_STATUS_FAILED = 3

export const jobStatusLabels = {
  [JOB_STATUS_PENDING]: 'Pending',
  [JOB_STATUS_COMPLETED]: 'Completed',
  [JOB_STATUS_FAILED]: 'Failed',
  4: 'Deleted',
}

export const JOB_TYPE_BULK_SKU_OP = 'bulk_sku_op'
export const JOB_TYPE_BULK_BID_OP = 'bulk_bid_op'
export const JOB_TYPE_BULK_ST_OP = 'bulk_st_op'
export const JOB_TYPE_BULK_NEGATIVE = 'bulk_negative'
export const JOB_TYPE_BULK_PLACEMENT_OP = 'bulk_placement_op'
export const JOB_TYPE_BULK_ADVANCED_NEGATIVE = 'bulk_advanced_negative'

export const JOB_TYPE_BULK_TARGET_EX = 'bulk_target_ex'
export const JOB_TYPE_BULK_ST_EX = 'bulk_st_ex'
export const JOB_TYPE_BULK_PRODUCT_EX = 'bulk_product_ex'

export const JOB_TYPE_BULK_TARGET_SEARCH = 'bulk_target_search'
export const JOB_TYPE_BULK_DUPLICATE_TARGETS = 'bulk_duplicate_targets'

export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_STATES = 'bulk_update_campaign_states'
export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_TARGET_ACOS = 'bulk_update_campaign_target_acos'
export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_BUDGETS = 'bulk_update_campaign_budgets'
export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_PORTFOLIOS = 'bulk_update_campaign_portfolios'
export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_NAMES = 'bulk_update_campaign_names'
export const JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS = 'bulk_update_campaign_biddings'
export const JOB_TYPE_BULK_UPDATE_PA_STATES = 'bulk_update_pa_states'
export const JOB_TYPE_BULK_UPDATE_KEYWORD_STATES = 'bulk_update_keyword_states'
export const JOB_TYPE_BULK_UPDATE_KEYWORD_BIDS = 'bulk_update_keyword_bids'
export const JOB_TYPE_BULK_UPDATE_TARGET_STATES = 'bulk_update_target_states'
export const JOB_TYPE_BULK_UPDATE_TARGET_BIDS = 'bulk_update_target_bids'
export const JOB_TYPE_BULK_ARCHIVE_NEGATIVE_KEYWORDS = 'bulk_archive_negative_keywords'
export const JOB_TYPE_BULK_ARCHIVE_NEGATIVE_TARGETS = 'bulk_archive_negative_targets'
export const JOB_TYPE_BULK_CREATE_PRODUCT_ADS = 'bulk_create_product_ads'
export const JOB_TYPE_BULK_CREATE_TARGETS = 'bulk_create_targets'
export const JOB_TYPE_BULK_CREATE_NEGATIVES = 'bulk_create_negatives'

export const JOB_BULK_REPORT_TYPES = [
  JOB_TYPE_BULK_SKU_OP,
  JOB_TYPE_BULK_BID_OP,
  JOB_TYPE_BULK_ST_OP,
  JOB_TYPE_BULK_NEGATIVE,
  JOB_TYPE_BULK_PLACEMENT_OP,
  JOB_TYPE_BULK_ADVANCED_NEGATIVE,
  JOB_TYPE_BULK_TARGET_EX,
  JOB_TYPE_BULK_ST_EX,
  JOB_TYPE_BULK_PRODUCT_EX,
  JOB_TYPE_BULK_TARGET_SEARCH,
  JOB_TYPE_BULK_DUPLICATE_TARGETS,
]

export const JOB_TYPE_ACTIVITY_LOG_DOWNLOAD = 'activity_log_download'
export const JOB_TYPE_AUDIT_REPORT = 'audit_report'

export const MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH = 512

export const dashboardChartMetricList = [
  { name: 'PPC Revenue', key: 'revenue', color: '#fbab34' },
  { name: 'Ad Spend', key: 'cost', color: '#cfd2f5' },
  { name: 'Orders', key: 'orders', color: '#3dade8' },
  { name: 'Clicks', key: 'clicks', color: '#25c835' },
  { name: 'Impressions', key: 'impressions', color: '#93df8d' },
  { name: 'ACoS %', key: 'acos', color: '#ffd156' },
  { name: 'Ave CPC', key: 'cpc', color: '#072257' },
  { name: 'ROAS', key: 'roas', color: '#292929' },
  { name: 'Conv %', key: 'conversion', color: '#ff7b56' },
  { name: 'Organic Revenue', key: 'organic', color: '#ffa7d9' },
  { name: 'TACOS', key: 'trueAcos', color: '#80a4ff' },
]

export const campaignChartMetricList = [
  { name: 'PPC Revenue', key: 'revenue', color: '#fbab34' },
  { name: 'Ad Spend', key: 'cost', color: '#cfd2f5' },
  { name: 'Orders', key: 'orders', color: '#3dade8' },
  { name: 'Clicks', key: 'clicks', color: '#25c835' },
  { name: 'Impressions', key: 'impressions', color: '#93df8d' },
  { name: 'ACoS %', key: 'acos', color: '#ffd156' },
  { name: 'Ave CPC', key: 'cpc', color: '#072257' },
  { name: 'ROAS', key: 'roas', color: '#292929' },
  { name: 'Conv %', key: 'conversion', color: '#ff7b56' },
]

export const prepList = [
  'above', 'across', 'against', 'along', 'among', 'around', 'at',
  'before', 'behind', 'below', 'beneath', 'beside', 'between', 'by',
  'down', 'for', 'from', 'in', 'into', 'near', 'of', 'off', 'on', 'to',
  'toward', 'under', 'upon', 'with', 'within'
]

export const RULE_TYPE_BID = 'bid'
export const RULE_TYPE_CAMPAIGN = 'campaign'
export const RULE_TYPE_TOS_MODIFIER = 'modifier'
export const RULE_TYPE_PRODUCT_MODIFIER = 'product_modifier'
export const RULE_TYPE_REST_MODIFIER = 'rest_modifier'
export const RULE_TYPE_EXCLUDE_DATA = 'excludeData'

export const RULE_BID_ACTION_DEC = 'dec'
export const RULE_BID_ACTION_PAUSE = 'pause'

export const ruleBidActionList = [
  { value: 'inc', label: 'Increase' },
  { value: RULE_BID_ACTION_DEC, label: 'Decrease' },
  { value: RULE_BID_ACTION_PAUSE, label: 'Pause target' },
]

export const RULE_CAMPAIGN_ACTION_DEC = 'dec'
export const RULE_CAMPAIGN_ACTION_PAUSE = 'pause'

export const ruleCampaignActionList = [
  { value: 'inc', label: 'Increase' },
  { value: RULE_CAMPAIGN_ACTION_DEC, label: 'Decrease' },
  { value: RULE_CAMPAIGN_ACTION_PAUSE, label: 'Pause campaign' },
]

export const RULE_MODIFIER_ACTION_DEC = 'dec'
export const RULE_MODIFIER_ACTION_SET = 'set'

export const ruleModifierActionList = [
  { value: 'inc', label: 'Increase' },
  { value: RULE_MODIFIER_ACTION_DEC, label: 'Decrease' },
  { value: RULE_MODIFIER_ACTION_SET, label: 'Set to' },
]

// Constants for template editors must be in the format of
// 'xx_template_editor' because showTemplateEditor() assumes so.
export const RULE_TYPE_SP = 'sp' // Smart pilot
export const RULE_TYPE_SP_TEMPLATE_EDITOR = 'sp_template_editor'
export const RULE_TYPE_DP = 'dp' // Dayparting
export const RULE_TYPE_DP_TEMPLATE_EDITOR = 'dp_template_editor'
export const RULE_TYPE_WP = 'wp' // Weekparting
export const RULE_TYPE_WP_TEMPLATE_EDITOR = 'wp_template_editor'
export const RULE_TYPE_CP = 'cp' // Campaign performance
export const RULE_TYPE_CP_TEMPLATE_EDITOR = 'cp_template_editor'
export const RULE_TYPE_RR = 'rr' // Rank rules
export const RULE_TYPE_EP = 'ep' // Event planning/seasonality
export const RULE_TYPE_EP_TEMPLATE_EDITOR = 'ep_template_editor'
export const RULE_TYPE_IR = 'ir' // Inventory rules
export const RULE_TYPE_IR_TEMPLATE_EDITOR = 'ir_template_editor'

export const ruleNames = {
  [RULE_TYPE_SP]: 'Smart Pilot',
  [RULE_TYPE_DP]: 'Dayparting',
  [RULE_TYPE_WP]: 'Weekparting',
  [RULE_TYPE_CP]: 'Campaign Performance',
  [RULE_TYPE_RR]: 'Rank',
  [RULE_TYPE_EP]: 'Event Planning/Seasonality',
  [RULE_TYPE_IR]: 'Inventory',
}

export const ruleStickerNames = {
  [RULE_TYPE_SP]: 'Smart Pilot',
  [RULE_TYPE_DP]: 'Dayparting',
  [RULE_TYPE_WP]: 'Weekparting',
  [RULE_TYPE_CP]: 'Performance',
  [RULE_TYPE_RR]: 'Rank',
  [RULE_TYPE_EP]: 'Events',
  [RULE_TYPE_IR]: 'Inventory',
}

export const budgetRecommendationColumnList = [
  { key: 'campaign', default: true, fixed: true, label: 'Campaign', className: 'col-campaign' },
  { key: 'bidding_strategy', default: true, label: 'Bidding Strategy', className: 'col-bidding-strategy' },
  { key: 'daily_budget', default: true, label: 'Daily Budget' },
  { key: 'suggested_budget', default: true, label: 'Recommended Budget' },
  { key: 'percent_time_in_budget', default: true, label: 'Avg. Time in Budget', className: 'col-percent-time' },
  { key: 'missed_impressions', default: true, label: 'Est. Missed Impressions', name: 'Est. Missed Imp.', className: 'missed-opportunity-col' },
  { key: 'missed_clicks', default: true, label: 'Est. Missed Clicks', className: 'missed-opportunity-col' },
  { key: 'missed_sales', default: true, label: 'Est. Missed Sales', name: 'Est. Missed Sales', className: 'missed-opportunity-col' },
  { key: 'impressions', label: 'Imp.', name: 'Imp.' },
  { key: 'clicks', label: 'Clicks', name: 'Clicks'  },
  { key: 'ctr', label: 'CTR %', name: 'CTR %' },
  { key: 'cost', label: 'Spend', name: 'Spend' },
  { key: 'cpc', label: 'Ave CPC', name: 'Ave CPC' },
  { key: 'orders', label: 'Orders', name: 'Orders' },
  { key: 'revenue', label: 'Sales', name: 'Sales' },
  { key: 'acos', label: 'ACoS %', name: 'ACoS %', default: true },
  { key: 'roas', label: 'ROAS', name: 'ROAS' },
  { key: 'conversion', label: 'Conversion Rate', name: 'Conv %' },
]

// A list of log types for filtering on the activity log page
// and campaign details/log table.
export const logTypeOptions = [
  { value: '', label: '- Select All -' },
  { value: 'ap_kw_basic', label: 'Target bid optimization' },
  { value: 'ap_kw_adv', label: 'Target bid optimization/Advanced settings' },
  { value: 'ap_nta', label: 'Negative target automation' },
  { value: 'ap_npt', label: 'Negative product targeting' },
  { value: 'ap_kw_ex', label: 'Target bid expansion' },
  { value: 'ap_st_ex', label: 'Search term expansion' },
  { value: 'ap_pt_ex', label: 'Product targeting expansion' },
  { value: 'ap_placement', label: 'Placement optimization (Smart Pilot)' },
  { value: 'rule_dayparting', label: ruleNames.dp },
  { value: 'rule_weekparting', label: ruleNames.wp },
  { value: 'rule_performance', label: ruleNames.cp },
  { value: 'rule_event_planning', label: ruleNames.ep },
]

export const REVERTABLE_LOG_TYPES = [
  'campaign_placement_apply',
  'campaign_placement_update',
  'ap_placement',
  'bulk_keyword_bid_change',
  'bulk_keyword_state_change',
  'bulk_target_bid_change',
  'bulk_target_state_change',
  'bulk_nk_state_change',
  'bulk_sku',
  'bulk_negatives',
  'budget_update',
  'acos_update',
  'name_update',
  'bulk_negatives_as_npt',
  'bulk_target_ex_add',
  'product_ad_add',
]

export const matchTypeOptions = [
  { value: 'broad', label: 'Broad' },
  { value: 'phrase', label: 'Phrase' },
  { value: 'exact', label: 'Exact' },
]

export const AD_FORMAT_COLLECTION = 'collection'
export const AD_FORMAT_SPOTLIGHT = 'spotlight'
export const AD_FORMAT_VIDEO = 'video'

export const adFormatList = [
  {
    value: AD_FORMAT_COLLECTION,
    name: 'Product collection',
    description: 'Promote multiple products from a landing page of your choice.',
  },
  {
    value: AD_FORMAT_SPOTLIGHT,
    name: 'Store spotlight',
    description: 'Drive traffic to a Store, including subpages.',
  },
  {
    value: AD_FORMAT_VIDEO,
    name: 'Video',
    description: 'Feature a single product with an autoplaying video.',
  },
]

export const LANDING_PAGE_TYPE_STORE = 'store'
export const LANDING_PAGE_TYPE_NEW_LANDING_PAGE = 'new_landing_page'
export const LANDING_PAGE_TYPE_PRODUCT_DETAIL_PAGE = 'product_detail_page'

export const GOAL_PAGE_VISIT = 'PAGE_VISIT'
export const GOAL_BRAND_IMPRESSION_SHARE = 'BRAND_IMPRESSION_SHARE'

// Bulk engine constants
export const MODULE_NAME_SKU_OP = 'SKU Optimization'
export const MODULE_NAME_TARGET_OP = 'Bid Optimization'
export const MODULE_NAME_ST_OP = 'Search Term/ASIN Optimization'
export const MODULE_NAME_NEGATIVE = 'Negative Word Finder'
export const MODULE_NAME_PLACEMENT_OP = 'Placement Optimization'
export const MODULE_NAME_ADVANCED = 'Advanced Optimization'

export const MODULE_NAME_TARGET_EX = 'Target Expansion'
export const MODULE_NAME_ST_EX = 'Search Term Expansion'
export const MODULE_NAME_PT_EX_ASIN = 'ASIN Expansion'
export const MODULE_NAME_PT_EX_CATEGORY = 'Category Expansion'

export const MODULE_NAME_TARGET_SEARCH = 'Target Search'
export const MODULE_NAME_DUPLICATE_TARGETS = 'Find Duplicate Targets'

export const BULK_ACTION_EXPENSIVE = 'expensive'
export const BULK_ACTION_NO_IMPRESSION = 'no_impression'
export const BULK_ACTION_LOW_CONVERSION = 'low_conversion'
export const BULK_ACTION_NO_SALES = 'no_sales'
export const BULK_ACTION_NEGATIVES = 'negatives'

export const BULK_ACTION_NAME_EXPENSIVE = 'Find High CPC Targets'
export const BULK_ACTION_NAME_NO_IMPRESSION = 'Find Zero Impression Targets'
export const BULK_ACTION_NAME_LOW_CONVERSION = 'Find Low Converting Targets'
export const BULK_ACTION_NAME_NO_SALES = 'Find Targets with Spend and No Sales'
export const BULK_ACTION_NAME_NEGATIVES = 'Review All Negatives'

export const CAMPAIGN_TABLE_FILTER = 'campaignTable'

export const NEGATIVE_MATCH_TYPE_EXACT = 'negativeExact'
export const NEGATIVE_MATCH_TYPE_PHRASE = 'negativePhrase'

export const negativeMatchTypeOptions = [
  { value: NEGATIVE_MATCH_TYPE_EXACT, label: 'Negative Exact' },
  { value: NEGATIVE_MATCH_TYPE_PHRASE, label: 'Negative Phrase' },
]
