import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'rsuite'

import SortableTable from '../CommonComponents/SortableTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'

import {
  copyToClipboard,
  tableSorter,
} from '../../services/helper'

const columns = [
  { key: 'search', name: 'Search Term' },
]

const StExSelection = ({ searchTerms, onChange, onAddToExisting }) => {
  const [selectedSts, setSelectedSts] = useState([])

  const handleBreakWords = () => {
    const stList = []
    const newSelection = []; // Semi-colon is mandatory here.
    searchTerms.forEach((record) => {
      const broken = record.search.match(/\S+\s*/g)
      broken.forEach((word) => {
        const trimmed = word.trim()
        if (stList.indexOf(trimmed) === -1) {
          newSelection.push({
            search: trimmed,
          })
          stList.push(trimmed)
        }
      })
    })
    onChange(newSelection)
  }

  const handleRemoveSpecial = () => {
    onChange(searchTerms.map(record => ({
      search: record.search.replace(/^-+|[!@#$%^&*()]/g, '')
    })))
  }

  const handleCopy = () => {
    const sts = searchTerms.filter(st => (
      selectedSts.indexOf(st.search) !== -1
    )).map(st => st.search.trim())

    copyToClipboard([...new Set(sts)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedSts.length} search term${selectedSts.length > 1 ? 's' : ''}.`
    })
  }

  const handleAddToExisting = () => {
    const sts = searchTerms.filter(st => (
      selectedSts.indexOf(st.search) !== -1
    )).map(st => st.search.trim())
    onAddToExisting([...new Set(sts)])
  }

  const renderAction = () => {
    const targets = searchTerms.filter(st => (
      selectedSts.indexOf(st.search) !== -1
    )).map(st => st.search.trim())

    return (
      <>
        <button type="button" className="btn btn-light-blue" onClick={handleBreakWords}>
          Individual Words
        </button>
        <button type="button" className="btn btn-light-blue" onClick={handleRemoveSpecial}>
          Remove Special Characters
        </button>
        <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
          Clear
        </button>
        {
          selectedSts.length > 0 && (
            <>
              <button type="button" className="btn btn-green" onClick={handleCopy}>
                Copy
              </button>
              <button type="button" className="btn btn-blue" onClick={handleAddToExisting}>
                Add to Existing Campaigns
              </button>
              <Dropdown
                title="Add to New Campaign"
                placement="bottomEnd"
                toggleClassName="btn-new"
              >
                <Dropdown.Item componentClass={Link} to={{
                  pathname: '/campaigns/new/sp',
                  state: {
                    targets,
                  },
                }}>
                  Sponsored Product Campaign
                </Dropdown.Item>
                <Dropdown.Item componentClass={Link} to={{
                  pathname: '/campaigns/new/sb',
                  state: {
                    targets,
                  },
                }}>
                  Sponsored Brand Campaign
                </Dropdown.Item>
              </Dropdown>
            </>
          )
        }
      </>
    )
  }

  const renderSt = record => (
    <>
      <div className="table-col" title={record.search}>
        { record.search }
      </div>
    </>
  )

  return (
    <SortableTable
      columns={columns}
      defaultSort={['search', 'asc']}
      sorter={tableSorter(['search'])}
      className="table-st-selection"
      records={searchTerms}
      idField="search"
      searchFields={['search']}
      selectedRecords={selectedSts}
      renderRecord={renderSt}
      renderTopRight={renderAction}
      onChange={setSelectedSts}
    />
  )
}

export default StExSelection
