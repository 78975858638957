import React, { useEffect, useState } from 'react'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

const BrandNameInput = ({ account, onSave }) => {
  const [brandName, setBrandName] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setBrandName(account.brand_name || '')
  }, [account])

  const handleChange = (event) => {
    setBrandName(event.target.value)
    setIsChanged(true)
  }

  const handleSave = () => {
    setIsSaving(true)
    onSave(account, brandName).then(() => {
      setIsSaving(false)
      toast.show({
        title: 'Success',
        description: 'Brand name successfully saved.',
      })
    }).catch((error) => {
      setIsSaving(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to save brand name.',
      })
    })
  }

  return (
    <>
      <input
        type="text"
        required
        value={brandName}
        onChange={handleChange}
      />
      {
        isChanged && (
          <button
            type="button"
            className="btn btn-red"
            disabled={isSaving}
            onClick={handleSave}
          >
            Save
          </button>
        )
      }
    </>
  )
}

export default BrandNameInput
