/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'rsuite'

import { toast } from '../CommonComponents/ToastComponent/toast'
import CustomTooltip from '../CommonComponents/CustomTooltip'

const TargetExInputModal = ({ existingTargets, show, onConfirm, onClose }) => {
  const fileEl = useRef(null)

  const [targets, setTargets] = useState('')

  useEffect(() => {
    setTargets(existingTargets.join('\n'))
  }, [existingTargets])

  const handleFileChange = (event) => {
    if (!event.target.files.length) {
      return
    }

    const reader = new FileReader()

    reader.onload = () => {
      const lines = reader.result.split(/\n/)
      if (!lines.length) {
        toast.show({
          title: 'Warning',
          description: 'There is no keyword to upload.',
        })
        return
      }

      // The first line contains column headings.
      const keywordIndex = lines[0].split(',')
        .findIndex(heading => heading.toLowerCase() === 'keyword')

      if (keywordIndex === -1) {
        toast.show({
          title: 'Warning',
          description: 'Unable to detect CSV headers. '
            + 'Please make sure you have the "keyword" header in your file.',
        })
      }

      const keywords = []
      for (let i = 1; i < lines.length; i += 1) {
        const fields = lines[i].split(',')
        if (typeof fields[keywordIndex] === 'undefined') {
          continue;
        }

        keywords.push(fields[keywordIndex].replace('\r', '').split('"').join(''))
      }

      if (targets) {
        setTargets(`${targets}\n${keywords.join('\n')}`)
      } else {
        setTargets(keywords.join('\n'))
      }
    }

    reader.readAsText(event.target.files[0])
  }

  const handleUploadClick = (event) => {
    event.preventDefault()

    fileEl.current.value = null
    fileEl.current.click()
  }

  const nonEmptyTargets = (targets || '').split('\n').filter(target => target !== '')

  return (
    <Modal className="target-ex-input-modal" backdrop="static" size="sm" show={show}>
      <Modal.Header onHide={() => { onClose() }}>
        <Modal.Title>
          Enter Targets
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="description-box">
          Enter one target per line, or&nbsp;
          <a
            href="#"
            onClick={handleUploadClick}
          >
            upload a CSV file.

            <CustomTooltip>
              Upload a CSV file with {'"'}keyword{'"'} header.
              Use a comma as a field separator and a new line for
              a new pair of values.
            </CustomTooltip>
          </a>
          <input
            type="file"
            ref={fileEl}
            accept=".csv"
            onChange={handleFileChange}
          />
        </p>
        <textarea
          value={targets}
          onChange={(event) => { setTargets(event.target.value) }}
        />
        <div>
          Total number: <strong>{ nonEmptyTargets.length }</strong>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          onClick={() => { onConfirm(targets) }}
        >
          Confirm
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TargetExInputModal
