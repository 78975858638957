/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import Select from 'react-select'
import { List, Icon } from 'rsuite'

import { hideColumnEditorAction } from '../../../redux/actions/pageGlobal'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'

const ColumnEditor = ({ columnList, currentSelection, onApply }) => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [selectedCols, setSelectedCols] = useState([...new Set(currentSelection)])

  const handleApply = () => {
    dispatch(onApply(selectedCols))
  }

  const handleSortColumns = ({ oldIndex, newIndex }) => {
    const moveColumn = selectedCols.splice(oldIndex, 1)
    const newColumns = [...selectedCols]
    newColumns.splice(newIndex, 0, moveColumn[0])
    setSelectedCols([...newColumns])
  }

  const handleSelectColumn = (column) => {
    setSelectedCols(prev => ([...prev, column]))
  }

  const handleRemoveColumn = (column) => {
    setSelectedCols(p => p.filter(s => s.key !== column.key))
  }

  const renderColumn = (column, index) => {
    if (
      isNonEndemicAccount &&
      [
        'revenue',
        'acos',
        'orders',
        'conversion',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
      ].includes(column.key)
    ) {
      return null
    }

    return (
      <List.Item
        key={column.key}
        className="column-item"
        index={index}
        disabled={
          column.key === 'campaign'
          || column.key === 'product'
          || column.key === 'portfolio'
          || column.key === 'tag'
        }
        collection={column.fixed ? 0 : 1}
      >
        <Icon className="handle-column" icon="ellipsis-v" size="lg" />
        <span className="column-label">{column.label}</span>
        <Icon
          className={`remove-column ${column.key === 'campaign' ? 'invisible' : ''}`}
          icon="close"
          onClick={() => { handleRemoveColumn(column) }}
        />
      </List.Item>
    )
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => { dispatch(hideColumnEditorAction()) }}
    >
      <div className="column-editor">
        <div className="pane-header">
          <div className="pane-title">Table Columns</div>
          <span className="close-icon" onClick={() => { dispatch(hideColumnEditorAction()) }}>
            &times;
          </span>
        </div>
        <div className="pane-body">
          <div className="column-sub-panel">
            <span className="sub-panel-title">Columns</span>
            <div className="setting-item">
              <span>Add Columns</span>
              <Select
                className="column-select"
                options={columnList.filter(item => !selectedCols.find(c => item.key === c.key))}
                value={null}
                placeholder="Select Your Column"
                onChange={handleSelectColumn}
              />
            </div>
            <div className="column-item-list">
              <List sortable pressDelay={300} onSort={handleSortColumns}>
                {
                  (selectedCols || []).map(renderColumn)
                }
              </List>
            </div>
          </div>
        </div>
        <div className="pane-footer">
          <button type="button" className="btn btn-blue" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default ColumnEditor
