import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  TOGGLE_DASHBOARD_TABLE,
  GET_DASHBOARD_CAMPAIGN_RULES,
} from '../actionTypes/dashboard'

export const toggleDashboardTable = () => {
  return {
    type: TOGGLE_DASHBOARD_TABLE
  }
}

export const getSalesStats = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callGet('/dashboard/getSalesStats', accessToken, {
    userId: currentUserId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get sales stats.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getStats = (accessToken, adType, signal) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callGet('/dashboard/getStats', accessToken, {
    userId: currentUserId,
    adType,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get stats.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getSummary = (accessToken, type, portfolioIds, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/dashboard/getSummary', accessToken, {
    userId: currentUserId,
    type,
    portfolioIds
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get summary.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getPortfolioStats = (accessToken, portfolioIds, signal) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callGet('/dashboard/getStatsForPortfolio', accessToken, {
    userId: currentUserId,
    portfolioIds,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to get the portfolio stats.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const initiateRefresh = (accessToken) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/dashboard/refresh', {
    userId: currentUserId,
  }, accessToken).then((response) => {
    if (response.data.error) {
      toast.show({
        title: 'Warning',
        description: response.data.error,
      })
    } else {
      toast.show({
        title: 'Success',
        description: 'We are re-fetching 24 hours of data. '
          + 'This process can take several minutes. '
          + 'Please wait ten minutes and then refresh the page in your browser.',
      })
    }
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to request a refresh.',
    })
  })
}

export const getRulesData = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/dashboard/getRulesData', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    return response.data
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve rules.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const getCampaignRulesData = (accessToken, signal) => (dispatch, getState) => {
  const {
    header: {
      currentUserId,
      currentStartDate,
      currentEndDate,
    },
  } = getState()

  return callGet('/dashboard/getCampaignRulesData', accessToken, {
    userId: currentUserId,
    startDate: getISODate(currentStartDate),
    endDate: getISODate(currentEndDate),
  }, signal).then((response) => {
    dispatch({
      type: GET_DASHBOARD_CAMPAIGN_RULES,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve campaign rules.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}
