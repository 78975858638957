import { callGet, callPost } from '../../services/axios'
import { getISODate } from '../../services/helper'

import { GET_ACTIVITY_LOG_SUCCEED } from '../actionTypes/activityLog'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'

export const getActivityLog = (accessToken, startDate, endDate, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/campaignlog/getLogs', accessToken, {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, signal).then((response) => {
    dispatch({
      type: GET_ACTIVITY_LOG_SUCCEED,
      data: response.data,
    })
  }).catch((error) => {
    if (error.code !== 'ERR_CANCELED') {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to load activity log.',
      })
    }
    return Promise.reject(error.code === 'ERR_CANCELED')
  })
}

export const generateLogs = (accessToken, startDate, endDate) => (dispatch, getState) => {
  const { header: {currentUserId} } = getState()

  toast.show({
    title: 'Info',
    description: 'Generating activity logs might take a few minutes.',
  })

  return callPost('/campaignlog/generateLogs', {
    userId: currentUserId,
    startDate: getISODate(startDate),
    endDate: getISODate(endDate),
  }, accessToken)
}
