import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import NoSaleTable from './NoSaleTable'
import LowCTRTable from './LowCTRTable'

import {
  getSTData,
  getNegativeKWData,
} from '../../../../../../redux/actions/campaignDetail'

const TimeSavingFilterTab = ({ campaignType, currentAdGroupId, hideKeywords, hideNegatedTerms }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)
  const currentAcos = useSelector(state => state.campaignDetail.currentAcos)

  const { id: campaignId } = useParams()

  // Find search terms.
  useEffect(() => {
    let abortCtrl
    if (currentStartDate && currentEndDate) {
      abortCtrl = new AbortController();
      (async () => {
        const accessToken = await getAccessTokenSilently()
        dispatch(getSTData(accessToken, {
          campaignId,
          campaignType,
          isProfitable: false,
          stOnly: hideKeywords,
          targetAcos: currentAcos || 0,
        }, abortCtrl.signal))
      })()
    }
    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentStartDate, currentEndDate, campaignId, currentAcos, hideKeywords]) // eslint-disable-line

  // Find negative keywords.
  useEffect(() => {
    if (!currentStartDate || !currentEndDate || !hideNegatedTerms) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      dispatch(getNegativeKWData(accessToken, {
        campaignId,
        campaignType,
      }))
    })()
  }, [currentStartDate, currentEndDate, campaignId, currentAdGroupId, hideNegatedTerms]) // eslint-disable-line

  return (
    <div className="time-saving-filter-contents">
      <NoSaleTable
        currentAdGroupId={currentAdGroupId}
        campaignType={campaignType}
        hideNegatedTerms={hideNegatedTerms}
      />
      <LowCTRTable
        currentAdGroupId={currentAdGroupId}
        campaignType={campaignType}
        hideNegatedTerms={hideNegatedTerms}
      />
    </div>
  )
}

export default TimeSavingFilterTab
