import React from 'react'
import { useSelector } from 'react-redux'

import withReportSection from './withReportSection'
import AdTypeSection from './AdTypeSection'

const Component = withReportSection(AdTypeSection, {
  sectionId: 'sponsored_display',
  reportSectionKey: 'sponsoredDisplayAds',
})

const SponsoredDisplayAds = ({
  settings,
  isLoadingSettings,
  onLoadStats,
  onSaveSettings,
}) => {
  const sdMetrics = useSelector(state => state.accountHealth.sdMetrics)
  const sdChart = useSelector(state => state.accountHealth.sdChart)
  const sdPastMetrics = useSelector(state => state.accountHealth.sdPastMetrics)

  return (
    <Component
      title="Sponsored Display"
      sectionKey="sponsoredDisplayAds"
      noteKey="notes_sd"
      customMetricSection="sd"
      settings={settings}
      isLoadingSettings={isLoadingSettings}
      onSaveSettings={onSaveSettings}
      results={sdMetrics}
      pastResults={sdPastMetrics}
      charts={sdChart}
      onLoadStats={onLoadStats}
    />
  )
}

export default SponsoredDisplayAds
