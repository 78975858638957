import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

import { impersonate } from '../../redux/actions/auth'

const Impersonate = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')

  const handleSignIn = () => {
    if (!email || !password) {
      return
    }

    dispatch(impersonate(email, password)).then((response) => {
      if (!response.t) {
        setMessage('Invalid email or password')
      } else {
        Cookies.set('56eac8d0', JSON.stringify(response), { expires: 1 })

        history.push('/login')
      }
    })
  }

  const handleSignOut = () => {
    Cookies.remove('56eac8d0')
    setMessage('Signed out')
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px' }}>
      <input
        type="text"
        placeholder="Email"
        style={{ marginBottom: '8px' }}
        value={email}
        onChange={(event) => { setEmail(event.target.value) }}
      />
      <input
        type="password"
        placeholder="Password"
        style={{ marginBottom: '8px' }}
        value={password}
        onChange={(event) => { setPassword(event.target.value) }}
      />
      <button
        type="button"
        className="btn btn-blue"
        style={{ marginBottom: '8px' }}
        onClick={handleSignIn}
      >
        Sign In
      </button>
      <button
        type="button"
        className="btn btn-red"
        style={{ marginBottom: '8px' }}
        onClick={handleSignOut}
      >
        Sign Out
      </button>
      {
        message !== '' && (
          <div>
            { message }
          </div>
        )
      }
    </div>
  )
}

export default Impersonate
