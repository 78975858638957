import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Redirect, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import ProtectedRoute from './ProtectedRoute'

import LoaderComponent from './components/CommonComponents/LoaderComponent'
import { AUTH0_LOGOUT_URL } from './config/api'

import Campaign from './pages/Campaign'
import BulkEditor from './pages/BulkEditor'
import AccountReport from './pages/AccountReport'
import Setting from './pages/Setting'
import Tutorial from './pages/Tutorial'
import Marketplace from './pages/Marketplace'
import ActivityLog from './pages/ActivityLog'
import Dashboard from './pages/Dashboard'
import CampaignDetailComponent from './components/CampaignDetailComponent'
import ProductDetailComponent from './components/ProductDetailComponent'
import AgencyView from './pages/AgencyView'
import AuditReport from './pages/AuditReport'
import Reactivation from './pages/Reactivation'

import { checkAuth } from './redux/actions/auth'

const MainRouter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0()
  const [isUserChecked, setIsUserChecked] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        setIsUserChecked(false)
        const accessToken = await getAccessTokenSilently()

        try {
          await dispatch(checkAuth(accessToken))
        } catch (statusCode) {
          if (statusCode !== 404) {
            logout({
              logoutParams: {
                returnTo: AUTH0_LOGOUT_URL,
              },
            })
          } else {
            // No PPCE account for a logged in C6 user,
            // so redirect to the signup flow.
            history.push('/signup')
          }
        }
        setIsUserChecked(true)
      })()
    } else {
      setIsUserChecked(true)
    }
  }, [isAuthenticated]) // eslint-disable-line

  if (!isUserChecked) {
    return <LoaderComponent />
  }

  return (
    <div className="main">
      <Switch>
        <Redirect
          from="/"
          to='/login'
          exact
        />
        <ProtectedRoute
          path='/dashboard'
          component={Dashboard}
        />
        <ProtectedRoute
          path='/campaigns'
          component={Campaign}
        />
        <ProtectedRoute
          path='/bulk-engine'
          component={BulkEditor}
        />
        <ProtectedRoute
          path='/account-report'
          component={AccountReport}
        />
        <ProtectedRoute
          path='/agency-view'
          component={AgencyView}
        />
         <ProtectedRoute
          path='/audit-report'
          component={AuditReport}
        />
        <ProtectedRoute
          path='/settings'
          component={Setting}
        />
        <ProtectedRoute
          path='/tutorial'
          component={Tutorial}
        />
        <ProtectedRoute
          path='/marketplace'
          component={Marketplace}
        />
        <ProtectedRoute
          path='/activity-log'
          component={ActivityLog}
        />
        <ProtectedRoute
          path='/reactivation'
          component={Reactivation}
        />
        {/* When you change this path, you need to update HeaderComponent as well
        because it uses this path pattern to check if you are on campaign details page. */}
        <ProtectedRoute
          path="/campaign/:id/:campaignType"
          component={CampaignDetailComponent}
          exact
        />
        <ProtectedRoute
          path="/product/:id"
          component={ProductDetailComponent}
          exact
        />
      </Switch>
    </div>
  )
}

export default MainRouter
