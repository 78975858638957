import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'rsuite'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableCell from '../CommonComponents/TableCell'

import {
  calcDerivedMetrics,
  formatCurrency,
  tableSorter,
} from '../../services/helper'

const legacyColumns = [
  { key: 'searchTerm', name: 'Search Term', className: 'col-search-term' },
  { key: 'campaign', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroup', name: 'Ad Group' },
]

const newColumns = [
  { key: 'searchTerm', name: 'Search Term', className: 'col-search-term' },
  { key: 'campaign', name: 'Campaign', className: 'col-campaign' },
  { key: 'adgroup', name: 'Ad Group' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Unprofitable Clicks' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'orders', name: 'Orders' },
  { key: 'conversion', name: 'Conv %' },
  { key: 'acos', name: 'Associated ACoS %' },
  { key: 'revenue', name: 'Associated Sales' },
  { key: 'cost', name: 'Wasted AD Spend' },
  { key: 'cpc', name: 'Ave CPC' },
  { key: 'yearlyCost', name: 'Approx Yearly Savings' },
]

const metricsToRender = [
  'impressions',
  'clicks',
  'ctr',
  'orders',
  'conversion',
  'acos',
  'revenue',
  'cost',
  'cpc',
]

const NegativeDetailsModal = ({
  negative,
  dateDiff,
  onAdd,
  onClose,
}) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const [isLegacy, setIsLegacy] = useState(true)
  const [searchTerms, setSearchTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])

  useEffect(() => {
    let records = []
    let _isLegacy = true
    if (!negative.children) {
      records = negative.searchTerms.map((st) => {
        if (typeof st !== 'string') {
          _isLegacy = false
        }
        let extendedRecord = {
          id: `${negative.campaign_id}-${negative.adgroup_id}-${_isLegacy ? st : st.searchTerm}`,
          campaign_id: negative.campaign_id,
          adgroup_id: negative.adgroup_id,
          campaignName: negative.campaignName,
          campaignType: negative.campaignType,
          targetingType: negative.targetingType,
          adgroupName: negative.adgroupName,
          search: _isLegacy ? st : st.searchTerm,
        }

        if (!_isLegacy) {
          extendedRecord.revenue = st.revenue
          extendedRecord.cost = st.cost
          extendedRecord.impressions = st.impressions
          extendedRecord.clicks = st.clicks
          extendedRecord.orders = st.orders

          extendedRecord = calcDerivedMetrics(extendedRecord)
          extendedRecord.yearlyCost = parseFloat(extendedRecord.cost) / dateDiff * 365
        }

        return extendedRecord
      })
    } else {
      negative.children.forEach((record) => {
        record.searchTerms.forEach((st) => {
          if (typeof st !== 'string') {
            _isLegacy = false
          }

          let extendedRecord = {
            id: `${record.campaign_id}-${record.adgroup_id}-${_isLegacy ? st : st.searchTerm}`,
            campaign_id: record.campaign_id,
            adgroup_id: record.adgroup_id,
            campaignName: record.campaignName,
            campaignType: record.campaignType,
            targetingType: record.targetingType,
            adgroupName: record.adgroupName,
            search: _isLegacy ? st : st.searchTerm,
          }

          if (!_isLegacy) {
            extendedRecord.revenue = st.revenue
            extendedRecord.cost = st.cost
            extendedRecord.impressions = st.impressions
            extendedRecord.clicks = st.clicks
            extendedRecord.orders = st.orders

            extendedRecord = calcDerivedMetrics(extendedRecord)
            extendedRecord.yearlyCost = parseFloat(extendedRecord.cost) / dateDiff * 365
          }

          records.push(extendedRecord)
        })
      })
    }
    setSearchTerms(records)
    setIsLegacy(_isLegacy)
  }, [negative, dateDiff])

  const handleAdd = () => {
    const records = searchTerms.filter(record => (
      selectedSearchTerms.includes(record.id)
    ))
    onAdd(records)
  }

  const renderAction = () => {
    if (!selectedSearchTerms.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={handleAdd}
        >
          Add as Negative
        </button>
      </>
    )
  }

  const renderRecord = record => (
    <>
      <div className="table-col col-search-term" title={record.search}>
        <span className="contents">
          { record.search }
        </span>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        !isLegacy && (
          <>
            {
              metricsToRender.map(metric => (
                <TableCell
                  key={metric}
                  record={record}
                  columnKey={metric}
                  currencySign={currencySign}
                  currencyRate={currencyRate}
                />
              ))
            }
            <div className="table-col">
              { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
            </div>
          </>
        )
      }
    </>
  )

  return (
    <Modal className="negative-details-modal" backdrop="static" size="lg" show>
      <Modal.Header onHide={onClose}>
        <Modal.Title>
          Word: { negative?.search }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SortableTable
          columns={isLegacy ? legacyColumns : newColumns}
          defaultSort={['search', 'asc']}
          sorter={tableSorter(['search'])}
          className="table-search-terms"
          records={searchTerms}
          idField="id"
          searchFields={['search']}
          selectedRecords={selectedSearchTerms}
          renderRecord={renderRecord}
          renderTopRight={renderAction}
          onChange={setSelectedSearchTerms}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={onClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NegativeDetailsModal
