import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import DspTable from './DspTable'

import {
  getAdvertisers,
} from '../../redux/actions/dsp'
import { calcDerivedMetrics } from '../../services/dspHelper'

const initialColumns = [
  { key: 'advertiser', name: 'Advertiser', fixed: true, width: 200 }
]

const DspAdvertiserTable = ({ selectedDspAccount, onAdvertiserChange }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const campaignColumns = useSelector(state => state.dsp.campaignColumns)

  const [isLoading, setIsLoading] = useState(false)
  const [advertiserList, setAdvertiserList] = useState([])

  useEffect(() => {
    if (selectedDspAccount) {
      loadData()
    }
  }, [selectedDspAccount, campaignColumns, currentStartDate, currentEndDate]) // eslint-disable-line

  const loadData = async () => {
    const accessToken = await getAccessTokenSilently()
    setIsLoading(true)
    const list = await dispatch(getAdvertisers(
      accessToken,
      selectedDspAccount,
      campaignColumns
    ))
    setAdvertiserList(list.map(record => ({
      advertiser: record.name,
      ...calcDerivedMetrics(record),
    })))
    setIsLoading(false)
  }

  const columns = useMemo(() => {
    return [
      ...initialColumns,
      ...campaignColumns,
    ]
  }, [campaignColumns])

  return (
    <div className="dsp-content">
      <DspTable
        columns={columns}
        data={advertiserList}
        loading={isLoading}
        autoHeight
        sortColumn="impressions"
        sortType="desc"
        searchField="advertiser"
        onAdvertiserChange={onAdvertiserChange}
      />
    </div>
  )
}

export default DspAdvertiserTable
