import React from 'react'

import OpKeywordAdvancedSetting from './OpKeywordAdvancedSetting'
import FieldRow from './FieldRow'
import FieldNumber from './FieldNumber'

// High ACoS Targets
const OpKeywordAcos = ({ settings, onChange, ...props }) => (
  <>
    <OpKeywordAdvancedSetting
      isOriginal
      tooltip="These are targets that have an ACoS that is high but still have sales. You may wish to pause or lower bids."
      settings={settings}
      onChange={onChange}
      {...props}
    >
      <FieldRow>
        <FieldNumber
          label="ACoS (%)"
          suffix="or higher"
          field="op_adv_high_acos_acos"
          settings={settings}
          onChange={onChange}
        />
        <FieldNumber
          label="With at least this many clicks"
          field="op_adv_high_acos_clicks"
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
    </OpKeywordAdvancedSetting>
    {
      settings[`copy_${props.prefix}active`] && (
        <OpKeywordAdvancedSetting
          settings={settings}
          onChange={onChange}
          {...props}
        >
          <FieldRow>
            <FieldNumber
              label="ACoS (%)"
              suffix="or higher"
              field="copy_op_adv_high_acos_acos"
              settings={settings}
              onChange={onChange}
            />
            <FieldNumber
              label="With at least this many clicks"
              field="copy_op_adv_high_acos_clicks"
              settings={settings}
              onChange={onChange}
            />
          </FieldRow>
        </OpKeywordAdvancedSetting>
      )
    }
  </>
)

export default OpKeywordAcos
