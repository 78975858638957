import React from 'react'
import { Toggle } from 'rsuite'

import TimezoneSelector from '../RuleManagerComponents/TimezoneSelector'
import LookbackSelector from '../RuleManagerComponents/LookbackSelector'
import CampaignInfo from './CampaignInfo'
import CustomTooltip from "../CommonComponents/CustomTooltip";

const Header = ({ campaigns, isSaved, isOn, timezone, lookback, currentTemplates,
  isCampaignSelectorVisible, isUpdatingStatus, isLoadingRules, isMultiCampaignSelection,
  onIsOnChange, onTimezoneChange, onLookbackChange,
  onCampaignRemove, onTemplateChange, onClose }) => {

  const toggleComponent = <Toggle
      checked={isOn}
      checkedChildren="On"
      unCheckedChildren="Off"
      disabled={isMultiCampaignSelection || isUpdatingStatus || isLoadingRules }
      onChange={onIsOnChange}
  />

  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Weekparting
          </span>

          {isMultiCampaignSelection ?
              <CustomTooltip
                  trigger={"hover"}
                  placement={"bottom"}
                  wrappedChildren={
                    toggleComponent
                  }
              >
                Multiple campaigns are selected. You may edit and apply rules in this window. To turn Weekparting On or Off for all campaigns, select campaigns in the main Dashboard and use the Rules menu.
              </CustomTooltip> :
              toggleComponent
          }

        </div>
        <div className="right-column">
          <TimezoneSelector
            timezone={timezone}
            onChange={onTimezoneChange}
          />
          <LookbackSelector
            lookback={lookback}
            onChange={onLookbackChange}
          />
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
      {
        !isCampaignSelectorVisible && (
          <>
            <CampaignInfo
              campaigns={campaigns}
              currentTemplates={currentTemplates}
              onTemplateChange={onTemplateChange}
              onRemove={onCampaignRemove}
            />
            {
              !isSaved && (
                <div className="save-instruction">
                  You need to click on "Save Rules" to save your changes.
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default Header
