import React from 'react'

import { formatValue } from '../../services/helper'

const MetricDifference = ({className, displayType = 'diff', difference, decimals=[0, 2], noTrending = false}) => {
  const valueType = displayType === 'diff' ? 'number' : 'percent'
  const decimal = displayType === 'diff' ? decimals[0] : decimals[1]
  const additionalClassName = noTrending ? 'neutral-trending' : difference.status > 0 ? 'positive-trending' : 'negative-trending'

  return (
    <span className={`${className} ${additionalClassName}`}>
      { (difference.diff > 0 ? '+' : '')
        + formatValue(difference[displayType], valueType, decimal)
      }
    </span>
  )
}

export default MetricDifference