import React from 'react'
import { InputGroup, Input, Icon, IconButton } from 'rsuite'
import { FiSearch } from 'react-icons/fi'

const SearchBox = ({
  placeholder = 'Type to search',
  keyword,
  exclusable = false,
  searchInclusive = true,
  onChange,
  onChangeSearchInclusive = () => {},
  onKeyPress = () => {},
  ...props
}) => (
  <div className="search-box-component">
    <InputGroup inside {...props}>
      <InputGroup.Addon>
        <FiSearch size={18} color="#979797" />
      </InputGroup.Addon>
      <Input
        placeholder={placeholder}
        value={keyword}
        onChange={(v, _) => onChange(v)}
        onKeyPress={onKeyPress}
      />
    </InputGroup>
    {
      exclusable && (
        <IconButton
          title={searchInclusive ? 'Inclusive' : 'Exclusive'}
          className="search-inclusive-btn"
          icon={
            <Icon icon={searchInclusive ? 'dot-circle-o' : 'circle-o'} />
          }
          onClick={() => { onChangeSearchInclusive(!searchInclusive) }}
        />
      )
    }
  </div>
)

export default SearchBox
