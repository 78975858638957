import React from 'react'

import Section from './Section'
import OpKeyword from './OpKeyword'
import OpNegative from './OpNegative'
import OpTesting from './OpTesting'

const SUBSECTION_KEYWORD = 'keyword'
const SUBSECTION_NEGATIVE = 'negative'
const SUBSECTION_TESTING = 'testing'

const OpSection = (props) => {
  const { campaign, settings } = props

  let isSD = false
  if (campaign) {
    // For Sponsored Displays, Negative Target Automation is disabled.
    isSD = campaign.basic[0].type === 'sd'
  }

  return (
    <Section>
      <OpKeyword id={SUBSECTION_KEYWORD} {...props} />
      {
        !isSD && (
          <OpNegative
            id={SUBSECTION_NEGATIVE}
            videoList={[{
              title: 'Negative Optimization Video',
              url: 'https://www.loom.com/embed/1a9120a64cfb480d8917207de820ae0b',
            }]}
            {...props}
          />
        )
      }
      {
        !settings.adgroup_id && (
          <OpTesting
            id={SUBSECTION_TESTING}
            videoList={[{
              title: 'Testing Optimization Video',
              url: 'https://www.loom.com/embed/51a79f2cc1cc42c48b7ee436576761ce',
            }]}
            {...props}
          />
        )
      }
    </Section>
  )
}

export default OpSection
