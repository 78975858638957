// Smart pilot template manager pane header.
import React from 'react'
import { FiEdit3 } from 'react-icons/fi'

import CustomTooltip from '../../CommonComponents/CustomTooltip'

const Header = ({ name, onChangeName, onClose }) => {
  return (
    <div className="top-container">
      <div className="pane-header">
        <div className="left-column">
          <span className="pane-title">
            Edit Smart Pilot Template
            <CustomTooltip placement="bottomEnd">
              <p>
                Here you can update the template name and settings.
                Please note the all changes will impact the campaigns
                that are currently using this template.
              </p>
              <p>
                You can also create new templates by making adjustments
                and clicking on “Save as New Template”.
                Once saved, your template will be available to apply
                to one or more campaigns.
              </p>
            </CustomTooltip>
          </span>
          <span className="input-wrapper">
            <input
              type="text"
              placeholder="Template name"
              value={name}
              onChange={(event) => { onChangeName(event.target.value) }}
            />
            <FiEdit3 />
          </span>
        </div>
        <div className="right-column">
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
      </div>
    </div>
  )
}

export default Header
