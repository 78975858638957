import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Toggle } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import BidAdjustComponent from '../CommonComponents/BidAdjustComponent'
import ThumbHistory from '../CampaignTableComponent/ThumbHistory'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'

import { ReactComponent as HistorySvg } from '../../assets/svg/history.svg'

import {
  updateTargetBids,
  updateTargetStates,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  formatCurrency,
  parseAsinTarget,
  tableSorter,
  calcDerivedMetrics,
  capitalizeFirstLetter,
  getExportValueForColumn,
  groupRecords,
  copyToClipboard,
  calcNewBid,
} from '../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
  JOB_TYPE_BULK_UPDATE_TARGET_STATES,
  JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
} from '../../utils/defaultValues'
import { campaignTypes } from '../../utils/filterDef'

const initialColumns = [
  { key: 'target_text', name: 'ASIN', className: 'col-target' },
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'bid', name: 'Current Bid' },
]

const initialColumnsGroup = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
  { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
  { key: 'target_text', name: 'ASIN', className: 'col-target' },
  { key: 'bid', name: 'Current Bid' },
]

const campaignTypesWithAll = [
  { value: '', label: 'All' },
  ...campaignTypes,
]

const TargetSearchTargetTable = ({ startDate, endDate, includeZeroSpend,
  isLoadingChart, chartData, onChangeDate, onLoadChart, onIncludeZeroSpend }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const campaignsWithHistory = useSelector(state => state.campaign.campaignsWithHistory)

  const findTargetsData = useSelector(state => state.bulkEngine.findTargetsData)

  const [groupMode, setGroupMode] = useState(false)
  const [targets, setTargets] = useState([])
  const [groupedTargets, setGroupedTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [selectedAdType, setSelectedAdType] = useState(campaignTypesWithAll[0])
  const [isShowHistory, setIsShowHistory] = useState(false)
  const [historyData, setHistoryData] = useState({})
  const [historyPayload, setHistoryPayload] = useState({})
  const [isLifetimeVisible, setIsLifetimeVisible] = useState(false)
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)

  useEffect(() => {
    const campaignsById = {}
    campaignsWithHistory.forEach((campaign) => {
      campaignsById[campaign.campaign_id] = {
        name: campaign.campaign,
        type: campaign.campaignType,
        targetingType: campaign.targeting_type,
        costType: campaign.cost_type,
      }
    })

    const extendedTargets = [];
    (findTargetsData.targets || []).forEach((record) => {
      const campaignDetail = campaignsById[record.campaign_id] || {}

      const campaignType = campaignDetail.type || ''

      if (selectedAdType.value !== '') {
        if (campaignType !== selectedAdType.value) {
          return
        }
      }

      extendedTargets.push({
        ...calcDerivedMetrics(record),
        campaignName: campaignDetail.name || '',
        campaignType: campaignType,
        targetingType: campaignDetail.targetingType || '',
        costType: campaignDetail.costType || '',
      })
    })

    setTargets(extendedTargets)
    setGroupedTargets(
      groupRecords(
        extendedTargets,
        'campaign_id',
        ['campaignName', 'campaignType', 'targetingType']
      )
    )
  }, [findTargetsData, campaignsWithHistory, selectedAdType])

  useEffect(() => {
    const dataByTarget = {};
    (chartData.targets || []).forEach((record) => {
      const key = `${record.campaign_id}-${record.target_id}`
      if (!dataByTarget[key]) {
        dataByTarget[key] = []
      }
      dataByTarget[key].push(calcDerivedMetrics(record))
    })
    setHistoryData(dataByTarget)
  }, [chartData])

  const [columns, groupColumns, columnSelection] = useMemo(() => {
    const availableColumns = campaignTableColumns.filter(c1 => bulkBidColumnList.find(c2 => c2.key === c1.key))
    return [
      [...initialColumns, ...availableColumns],
      [...initialColumnsGroup, ...availableColumns],
      availableColumns,
    ]
  }, [campaignTableColumns])

  const handleChangeState = async (state) => {
    let targetsChanged = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      targetsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        target_text: record.target_text,
        originalState: record.state,
      })
    })

    // Remove duplicate entries.
    targetsChanged =  [...new Map(targetsChanged.map(item => [item.targetId, item])).values()]

    if (!targetsChanged.length) {
      return
    }

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateTargetStates(
      accessToken,
      targetsChanged,
      state,
      false,
    ))
    setIsUpdatingStates(false)

    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_TARGET_STATES,
        state,
      ))
    }
  }

  const handleAdjustBid = async () => {
    let targetsChanged = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.campaignType,
        currentAccount?.country_id,
        record.costType,
      )

      targetsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroup_name,
        target_text: record.target_text,
        originalBid: record.bid,
      })
    })

    // Remove duplicate entries.
    targetsChanged =  [...new Map(targetsChanged.map(item => [item.targetId, item])).values()]

    if (!targetsChanged.length) {
      toast.show({
        title: 'Warning',
        description: 'The minimum bid allowed is $0.15. Please check your targets.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateTargetBids(
      accessToken,
      targetsChanged,
      false,
    ))
    setIsUpdatingBids(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
        targetsChanged,
      ))
    }
  }

  const handleShowHistory = () => {
    if (!isShowHistory) {
      onLoadChart('targets')
      setIsShowHistory(true)
    } else {
      setIsShowHistory(false)
    }
  }

  const handleHistoryClick = (record, column) => {
    if (!isShowHistory) {
      return
    }

    setHistoryPayload({
      metric: column.label,
      metricKey: column.key,
      asin: parseAsinTarget(record.target_text),
      data: (historyData[`${record.campaign_id}-${record.target_id}`] || []).map(item => ({
        date: item.report_date,
        value: item[column.key] || 0,
      })),
    })
  }

  const handleCopy = () => {
    const dataToCopy = targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).map(record => parseAsinTarget(record.target_text))

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} target${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const handleDateChange = (startDate, endDate) => {
    onChangeDate(startDate, endDate)
    setIsLifetimeVisible(startDate === null && endDate === null)
  }

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By ASINs"
          onChange={setGroupMode}
        />
        <div className="select-wrapper">
          <span>Ad Type</span>
          <Select
            classNamePrefix="ad-type-selector"
            options={campaignTypesWithAll}
            value={selectedAdType}
            onChange={setSelectedAdType}
          />
        </div>
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label="Include zero spend targets"
            checked={includeZeroSpend}
            onChange={onIncludeZeroSpend}
          />
        </div>
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedTargets.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => { handleCopy() }}
          >
            Copy
          </button>
          <button
            type="button"
            className="btn btn-green"
            disabled={isUpdatingStates || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button
            type="button"
            className="btn btn-light-blue"
            onClick={() => { setIsShowAdjustBid(true) }}
          >
            Adjust Bid
          </button>
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderSecondaryAction = () => {
    if (isLifetimeVisible) {
      return null
    }
    return (
      <HistorySvg title="History" onClick={handleShowHistory}/>
    )
  }

  const renderTarget = record => (
    <>
      <div className="table-col col-target" title={parseAsinTarget(record.target_text)}>
        <strong>
          { parseAsinTarget(record.target_text) }
        </strong>
        <div className="meta-data">
          { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <TableCampaignCell record={record} />
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.target_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-target">Totals:</div>
      <div className="table-col col-campaign" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'target_text') {
        return `${parseAsinTarget(record.target_text)} (${capitalizeFirstLetter(record.state)})`
      }
      if (column.key === 'bid') {
        return formatCurrency(record.bid, currencySign, currencyRate)
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-target">
        { record.children.length } targets
      </div>
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.target_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderChild = record => (
    <>
      <div className="table-col col-target" title={parseAsinTarget(record.target_text)}>
        <strong>
          { parseAsinTarget(record.target_text) }
        </strong>
        <div className="meta-data">
          { capitalizeFirstLetter(record.state) }
        </div>
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
            showHistory={isShowHistory && !isLoadingChart && !isLifetimeVisible}
            historyData={historyData[`${record.campaign_id}-${record.target_id}`] || []}
            startDate={startDate}
            endDate={endDate}
            onClick={() => { handleHistoryClick(record, column) }}
          />
        ))
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-target" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const isLoading = isUpdatingBids
    || isUpdatingStates
    || isLoadingChart

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            columns={groupColumns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-targets"
            records={groupedTargets}
            idField="campaign_id"
            searchFields={['target_text']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedTargets}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchTargetResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            isLoading={isLoading}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedTargets}
            onChangeDate={handleDateChange}
            sorterChild={tableSorter(['state', 'target_text'])}
            idFieldChild="target_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            columns={columns}
            defaultSort={['cost', 'desc']}
            sorter={tableSorter(['campaignName', 'state', 'target_text'])}
            className="table-targets"
            records={targets || []}
            idField="target_id"
            searchFields={['target_text']}
            exactSearch
            searchPlaceholder="Exact match search"
            selectedRecords={selectedTargets}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName="targetSearchResult"
            columnEditorId="targetSearchTargetResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            isLoading={isLoading}
            exportFileName="Target Search"
            getExportData={getExportData}
            renderRecord={renderTarget}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            renderTopRightSecondary={renderSecondaryAction}
            onChange={setSelectedTargets}
            onChangeDate={handleDateChange}
          />
        )
      }
      <ThumbHistory
        title={`Target: ${historyPayload.asin}`}
        areaData={historyPayload.data || []}
        metric={historyPayload.metric}
        metricKey={historyPayload.metricKey}
        startDate={startDate}
        endDate={endDate}
        onClose={() => { setHistoryPayload({}) }}
      />
    </>
  )
}

export default TargetSearchTargetTable
