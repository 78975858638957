import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Toggle } from 'rsuite'

import RulesView from './RulesView'
import CampaignsView from './CampaignsView'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

const RuleTableComponent = () => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const [showCampaignsView, setShowCampaignsView] = useState(true)

  return (
    <div className="rule-table-component">
      <div className="toggle-wrapper">
        <Toggle
          checked={showCampaignsView}
          checkedChildren="Campaigns view"
          unCheckedChildren="Templates view"
          onChange={setShowCampaignsView}
        />
        <div className="rule-hierarchy">
          <strong>Rule Hierarchy</strong>
          {
            !isNonEndemicAccount && (
              <>
                Inventory Rules <span className="release-note">(coming soon)</span> &gt;&nbsp;
              </>
            )
          }
          Event Rules <span className="release-note">(beta)</span> &gt;&nbsp;
          {
            !isNonEndemicAccount && (
              <>
                Rank Rules <span className="release-note">(coming soon)</span> &gt;&nbsp;
              </>
            )
          }
          Campaign Performance Rules &gt;
          Weekparting Rules &gt;
          Dayparting Rules &gt;
          Smart Pilot
        </div>
      </div>
      {
        showCampaignsView ? (
          <CampaignsView />
        ) : (
          <RulesView />
        )
      }
    </div>
  )
}

export default RuleTableComponent
