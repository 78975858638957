import React from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import LoaderComponent from './components/CommonComponents/LoaderComponent'

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <LoaderComponent />
      ),
    })}
    {...args}
  />
)

export default ProtectedRoute
