import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import { toast } from '../CommonComponents/ToastComponent/toast'
import PlacementModal from '../CommonComponents/PlacementModal'

import BulkResultContainer from '../BulkResultContainer'
import PlacementOpResultTable from './PlacementOpResultTable'

import { updateBiddings } from '../../redux/actions/campaign'
import { monitorJob } from '../../redux/actions/job'

import {
  tableSorter,
  calcDerivedMetrics,
  formatValue,
  getExportValueForColumn,
} from '../../services/helper'

import {
  bulkPlacementColumnList,
  placementList,
  MAX_PLACEMENT_BID_CHANGE,
  MODULE_NAME_PLACEMENT_OP,
  JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
} from '../../utils/defaultValues'

const columns = [
  { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
  { key: 'strategy', name: 'Bidding Strategy', className: 'col-strategy' },
  { key: 'baseBid', name: 'Recomm Base Bid', className: 'col-base-bid' },
  { key: 'targetAcos', name: 'Target ACoS', className: 'col-acos' },
  { key: 'placeholder', name: '', className: 'col-placeholder', exportable: false },
]

const columnsChild = [
  { key: 'placementText', name: 'Placement', className: 'col-placement' },
  { key: 'currentBidAdjustment', name: 'Current Modifier' },
  { key: 'newBidAdjustment', name: 'Recomm Modifier' },
]

const PlacementOpResult = ({ campaignsById, onChangeDate }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)
  const placementOpData = useSelector(state => state.bulkEngine.placementOpData)

  const [placements, setPlacements] = useState([])
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [open, setOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    const extendedCampaigns = [];
    (placementOpData || []).forEach((record) => {
      // Parse campaign bidding info.
      let strategy = 'Fixed bid'
      const adjustmentsByPlacement = {}
      if (record.bidding) {
        if (record.bidding.strategy) {
          if (record.bidding.strategy === 'legacyForSales') {
            strategy = 'Dynamic bids - down only'
          } else if (record.bidding.strategy === 'autoForSales') {
            strategy = 'Dynamic bids - up and down'
          } else {
            strategy = 'Fixed bid'
          }
        }

        if (record.bidding.adjustments) {
          record.bidding.adjustments.forEach((adjustment) => {
            adjustmentsByPlacement[adjustment.predicate] = adjustment.percentage
          })
        }
      }

      const campaignDetail = campaignsById[record.campaign_id] || {}

      record.cost = 0
      record.revenue = 0
      record.impressions = 0
      record.clicks = 0
      record.orders = 0

      let worstAcos = 0
      let hasInvalidPlacement = false
      const children = Object.keys(placementList).map((placement) => {
        const revenue = record.placementData[placement]
          ? parseFloat(record.placementData[placement].revenue || 0)
          : 0

        if (!revenue) {
          hasInvalidPlacement = true
        }

        // Calculate totals of campaign.
        if (record.placementData[placement]) {
          record.cost += parseFloat(record.placementData[placement].cost || 0)
          record.revenue += revenue
          record.impressions += parseInt(record.placementData[placement].impressions || 0, 10)
          record.clicks += parseInt(record.placementData[placement].clicks || 0, 10)
          record.orders += parseInt(record.placementData[placement].orders || 0, 10)
        }

        const currentBidAdjustment = adjustmentsByPlacement[placement] || 0

        const derived = calcDerivedMetrics({
          placement,
          placementText: placementList[placement],
          currentBidAdjustment,
          revenue,
          cost: record.placementData[placement] ? (record.placementData[placement].cost || 0) : 0,
          clicks: record.placementData[placement] ? (record.placementData[placement].clicks || 0) : 0,
          impressions: record.placementData[placement] ? (record.placementData[placement].impressions || 0) : 0,
          orders: record.placementData[placement] ? (record.placementData[placement].orders || 0) : 0,
        })

        if (derived.acos && (!worstAcos || worstAcos < derived.acos)) {
          worstAcos = derived.acos
        }

        return Object.assign(derived, {
          campaignName: campaignDetail.name || '',
          campaignType: campaignDetail.type || '',
          targetingType: campaignDetail.targetingType || '',
          strategy,
        })
      })

      let baseBid = 0
      if (worstAcos) {
        baseBid = Math.ceil(parseFloat(record.acos) / worstAcos * 100) - 100
      }
      if (hasInvalidPlacement) {
        baseBid = 'N/A'
      }

      extendedCampaigns.push({
        targetAcos: record.acos,
        ...calcDerivedMetrics(record),
        campaignName: campaignDetail.name || '',
        campaignType: campaignDetail.type || '',
        targetingType: campaignDetail.targetingType || '',
        strategy,
        baseBid,
        children: children.map((child) => {
          let newBidAdjustment = 0
          if (child.acos) {
            const acosAfterBaseBidChange = child.acos + (child.acos * baseBid / 100)
            if (acosAfterBaseBidChange) {
              const raisedBy = record.acos / acosAfterBaseBidChange * 100
              newBidAdjustment = Math.min(
                Math.ceil(
                  Math.max((100 + child.currentBidAdjustment) * raisedBy / 100 - 100, 0)
                ),
                MAX_PLACEMENT_BID_CHANGE
              )
            }
          }

          return Object.assign(child, {
            targetAcos: record.acos,
            baseBid,
            newBidAdjustment,
          })
        }),
      })
    })

    setPlacements(extendedCampaigns)
  }, [placementOpData, campaignsById])

  const columnSelection = useMemo(() => {
    return campaignTableColumns.filter(c1 => bulkPlacementColumnList.find(c2 => c2.key === c1.key))
  }, [campaignTableColumns])

  const doUpdate = async (campaigns, updateBaseBid) => {
    setIsUpdating(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateBiddings(
      accessToken,
      campaigns,
      updateBaseBid,
      false,
    ))
    setIsUpdating(false)

    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_CAMPAIGN_BIDDINGS,
      ))
    }
  }

  const handleApplyRecommendation = async (percent) => {
    const campaigns = []
    let hasInvalidCampaign = false
    placements.forEach((record) => {
      if (selectedCampaigns.indexOf(record.campaign_id) === -1) {
        return
      }

      if (record.baseBid === 'N/A') {
        hasInvalidCampaign = true
      }

      const bidding = Object.assign({}, record.bidding || {})

      const oldAdjustments = {};
      ((record.bidding || {}).adjustments || []).forEach((adjustment) => {
        oldAdjustments[adjustment.predicate] = adjustment.percentage
      })

      bidding.adjustments = []
      record.children.forEach((placement) => {
        const oldPercent = oldAdjustments[placement.placement] || 0

        bidding.adjustments.push({
          predicate: placement.placement,
          percentage: oldPercent
            + Math.round((placement.newBidAdjustment - oldPercent) / 100 * percent),
        })
      })

      campaigns.push({
        campaignId: record.campaign_id,
        bidding,
        oldBidding: record.bidding || {},
        baseBid: Math.round(record.baseBid / 100 * percent),
      })
    })

    if (hasInvalidCampaign) {
      toast.show({
        title: 'Warning',
        description: 'All placements should have at least one sale to apply recommendations.',
      })
      return
    }

    doUpdate(campaigns, true)
  }

  const handleAdjustBid = async (bidValue) => {
    const campaigns = []
    placements.forEach((record) => {
      if (selectedCampaigns.indexOf(record.campaign_id) === -1) {
        return
      }

      const bidding = Object.assign({}, record.bidding || {})
      const adjustments = [...bidding.adjustments || []]
      Object.keys(bidValue).forEach(placement => {
        const index = adjustments.findIndex(adjustment =>
          adjustment.predicate === placement
        )
        if (index !== -1) {
          adjustments[index] = Object.assign({}, adjustments[index], {
            percentage: bidValue[placement],
          })
        } else {
          adjustments.push({
            predicate: placement,
            percentage: bidValue[placement],
          })
        }
      })
      bidding.adjustments = adjustments

      campaigns.push({
        campaignId: record.campaign_id,
        bidding,
        oldBidding: record.bidding || {},
      })
    })

    doUpdate(campaigns, false)
  }

  const renderAction = () => (
    <>
      <button
        className="btn btn-blue optimize-placement-button"
        disabled={!selectedCampaigns.length || isUpdating}
        onClick={() => { setOpen(true) }}
      >
        Optimize Placement
      </button>
      <PlacementModal
        open={open}
        isLoading={isUpdating}
        onAdjustBid={handleAdjustBid}
        onApplyRecommend={handleApplyRecommendation}
        onClose={() => { setOpen(false) }}
      />
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map(column => (
      getExportValueForColumn(record, column.key, currencySign, currencyRate)
    ))
  )

  const renderCampaign = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-strategy">
        { record.strategy }
      </div>
      <div className="table-col col-base-bid">
        { formatValue(record.baseBid, 'percent', 0) }
      </div>
      <div className="table-col col-acos">
        { formatValue(record.targetAcos, 'percent', 0) }
      </div>
      <div className="table-col col-placeholder">
        <em>Click to view</em>
      </div>
    </>
  )

  const renderPlacement = record => (
    <>
      <div className="table-col col-placement">
        { record.placementText }
      </div>
      <div className="table-col">
        {
          formatValue(record.currentBidAdjustment, 'percent', 0)
        }
      </div>
      <div className="table-col">
        {
          record.baseBid === 'N/A' ? 'N/A' : formatValue(record.newBidAdjustment, 'percent', 0)
        }
      </div>
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={record}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  const renderTotalChild = summary => (
    <>
      <div className="table-col col-placement">Totals:</div>
      <div className="table-col" />
      <div className="table-col" />
      {
        columnSelection.map(column => (
          <TableCell
            key={column.key}
            record={summary}
            columnKey={column.key}
            columnSelection={columnSelection}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        ))
      }
    </>
  )

  return (
    <BulkResultContainer>
      <SortableTable
        tableComponent={PlacementOpResultTable}
        isLoading={isUpdating}
        columns={columns}
        columnsChild={[...columnsChild, ...columnSelection]}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['campaignName', 'strategy'])}
        className="table-placements"
        records={placements}
        idField="campaign_id"
        searchFields={['campaignName']}
        selectedRecords={selectedCampaigns}
        columnEditorId="placementOpResult"
        columnList={bulkPlacementColumnList}
        columnSelection={columnSelection}
        exportFileName={MODULE_NAME_PLACEMENT_OP}
        getExportData={getExportData}
        renderRecord={renderCampaign}
        renderTotalChild={renderTotalChild}
        renderTopRight={renderAction}
        onChange={setSelectedCampaigns}
        onChangeDate={onChangeDate}
        sorterChild={tableSorter(['placementText'])}
        idFieldChild="placement"
        renderChild={renderPlacement}
      />
    </BulkResultContainer>
  )
}

export default PlacementOpResult
