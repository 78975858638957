import React from 'react'

const ProductList = ({ products, onRemove }) => {
  if (!products.length) {
    return (
      <div className="no-product-desc">
        No product added.
      </div>
    )
  }

  return (
    <div className="product-container">
      {
        products.map((product) =>
          <div key={product.id} className="product-box">
            <span className="close-icon" title="Remove" onClick={() => { onRemove(product.id) }}>
              &times;
            </span>
            <img src={product.image} alt={product.name} />
            <div className="product-info">
              <div className="product-name">{product.name}</div>
              <div className="product-detail">
                <span>Price: {product.price}</span>
                <span>ASIN: {product.asin}</span>
                <span>SKU: {product.sku}</span>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default ProductList
