/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import { FiDownload, FiFilter, FiColumns } from 'react-icons/fi'

import SortableTable from '../../../../CommonComponents/SortableTableComponent'
import PaginationComponent from '../../../../CommonComponents/PaginationComponent'
import LoaderComponent from '../../../../CommonComponents/LoaderComponent'
import TableFilter from '../../../../CommonComponents/TableFilter'
import TableFilterShower from '../../../../CommonComponents/TableFilterShower'
import ColumnEditor from '../../../../CommonComponents/ColumnEditor'
import StreamChart from '../../../../CommonComponents/StreamChart'
import SearchBox from '../../../../CommonComponents/SearchBox'

import {
  showFilter,
  showColumnEditorAction,
  selectColumns,
} from '../../../../../redux/actions/pageGlobal'

import { matchFilter, calcDerivedMetrics } from '../../../../../services/helper'

import filterDef from '../../../../../utils/filterDef'

const DEFAULT_PAGE_SIZE = 10

const TargetStreamTable = (props) => {
  const {
    defaultSort,
    sorterChild,
    className = '',
    records,
    selectedRecords = [],
    idField,
    idFieldChild,
    searchFields,
    paginationNeighbours = 2,
    noCheckBox = false,
    hasSearchChild = false,
    noSearch = false,
    hasSticky = false,
    noRecordText = 'No records found.',
    filterName,
    // true to use a standalone filter modal, instead of filter pane.
    useFilterModal = false,
    // Unique ID to differentiate tables.
    columnEditorId,
    columnEditorNoReset = true,
    columns,
    columnList,
    columnSelection,
    isLoading = false,
    exportFileName = '',
    // Callback to get exportable data for a record.
    getExportData = null,
    searchPlaceholder = 'Type to search',
    renderRecord,
    renderChild,
    // Callback to render aggregation row.
    renderTotal = null,
    renderTopRight,
    onChange = () => {},
    onSearch = null,
    onFilterValidate = null,
    viewAsTable,
    children,
  } = props

  const dispatch = useDispatch()
  const filterValues = useSelector(state => state.pageGlobal.filterValues)
  const visibleFilterName = useSelector(state => state.pageGlobal.visibleFilterName)
  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleColumnEditorId = useSelector(state => state.pageGlobal.visibleColumnEditorId)

  const [keyword, setKeyword] = useState('')
  const [filteredRecords, setFilteredRecords] = useState(records)
  const [pageStart, setPageStart] = useState(0)
  const [pageEnd, setPageEnd] = useState(DEFAULT_PAGE_SIZE)
  const [stickyOffset, setStickyOffset] = useState(null)
  const [expansionState, setExpansionState] = useState({})
  const [currentSelection, setCurrentSelection] = useState({})

  const refBody = useRef(null)
  const refHeader = useRef(null)
  const refFooter = useRef(null)
  const refAction = useRef(null)

  useEffect(() => {
    doFilter()
  }, [records]) // eslint-disable-line

  useEffect(() => {
    if (filterName) {
      doFilter()
    }
  }, [filterValues]) // eslint-disable-line

  // Attach a scroll event listener.
  useEffect(() => {
    if (hasSticky) {
      const mainContent = document.querySelector('.main-content')
      mainContent.addEventListener('scroll', handleScroll)

      return () => {
        mainContent.removeEventListener('scroll', handleScroll)
      }
    }
  }, []) // eslint-disable-line

  // Adjust positions of sticky header/footer.
  useEffect(() => {
    if (stickyOffset === null) {
      return
    }
    let offset = 0
    if (hasAction()) {
      refAction.current.style.top = `${stickyOffset}px`
      offset = refAction.current.clientHeight
    }
    refHeader.current.style.top = `${stickyOffset + offset}px`
    if (renderTotal) {
      refFooter.current.style.top = `${refHeader.current.clientHeight + stickyOffset + offset}px`
    }
  }, [stickyOffset, renderTotal]) // eslint-disable-line

  // Listen for scroll event on main contents area.
  const handleScroll = () => {
    if (refBody.current) {
      const { top } = refBody.current.getBoundingClientRect()
      if (top < 0) {
        setStickyOffset(-top)
        return
      }
    }
    setStickyOffset(null)
  }

  // Filter records.
  const doFilter = () => {
    let recordsToHandle = []
    if (keyword === '') {
      recordsToHandle = records
    } else {
      const lowerCased = keyword.toLowerCase()
      records.forEach((record) => {
        record.cost = 0
        record.revenue = 0
        record.impressions = 0
        record.clicks = 0
        record.orders = 0
        record.yearlyCost = 0
        record.ntb_orders = 0
        record.ntb_sales = 0
        record.st_impr_rank = 0
        record.st_impr_share = 0
        record.viewable_impressions = 0
        record.daily_budget = 0

        const filteredChildren = record.children.filter((child) => {
          const matchingField = searchFields.find((field) => {
            return child[field].toLowerCase().indexOf(lowerCased) !== -1
          })

          if (typeof matchingField !== 'undefined'
            && typeof child.cost !== 'undefined') {
            record.cost += parseFloat(child.cost)
            record.revenue += parseFloat(child.revenue)
            record.impressions += parseInt(child.impressions, 10)
            record.clicks += parseInt(child.clicks, 10)
            record.orders += parseInt(child.orders, 10)
            record.yearlyCost += parseFloat(child.yearlyCost || 0)
            record.ntb_orders += parseInt(child.ntb_orders || 0, 10)
            record.ntb_sales += parseFloat(child.ntb_sales || 0)
            record.st_impr_rank += parseFloat(child.st_impr_rank || 0)
            record.st_impr_share += parseFloat(child.st_impr_share || 0)
            record.viewable_impressions += parseInt(child.viewable_impressions || 0, 10)
            record.daily_budget += parseFloat(child.daily_budget || 0)
          }

          return matchingField
        })

        if (filteredChildren.length) {
          record.st_impr_rank /= filteredChildren.length
          record.st_impr_share /= filteredChildren.length

          recordsToHandle.push({
            ...calcDerivedMetrics(record),
            children: filteredChildren,
          })
        }
      })
    }

    if (!filterName) {
      setFilteredRecords(recordsToHandle)
      return
    }

    const filtered = []
    recordsToHandle.forEach((record) => {
      record.cost = 0
      record.revenue = 0
      record.impressions = 0
      record.clicks = 0
      record.orders = 0
      record.yearlyCost = 0
      record.ntb_orders = 0
      record.ntb_sales = 0
      record.st_impr_rank = 0
      record.st_impr_share = 0
      record.viewable_impressions = 0
      record.daily_budget = 0

      const filteredChildren = record.children.filter((child) => {
        const matched = matchFilter(
          child,
          filterDef[filterName],
          (filterValues || {})[filterName] || {}
        )

        if (matched && typeof child.cost !== 'undefined') {
          record.cost += parseFloat(child.cost)
          record.revenue += parseFloat(child.revenue)
          record.impressions += parseInt(child.impressions, 10)
          record.clicks += parseInt(child.clicks, 10)
          record.orders += parseInt(child.orders, 10)
          record.yearlyCost += parseFloat(child.yearlyCost || 0)
          record.ntb_orders += parseInt(child.ntb_orders || 0, 10)
          record.ntb_sales += parseFloat(child.ntb_sales || 0)
          record.st_impr_rank += parseFloat(child.st_impr_rank || 0)
          record.st_impr_share += parseFloat(child.st_impr_share || 0)
          record.viewable_impressions += parseInt(child.viewable_impressions || 0, 10)
          record.daily_budget += parseFloat(child.daily_budget || 0)
        }

        return matched
      })

      if (filteredChildren.length) {
        record.st_impr_rank /= filteredChildren.length
        record.st_impr_share /= filteredChildren.length

        filtered.push({
          ...calcDerivedMetrics(record),
          children: filteredChildren,
        })
      }
    })
    setFilteredRecords(filtered)
  }

  const handleKeywordPress = (event) => {
    if (event.key === 'Enter') {
      if (onSearch) {
        onSearch(keyword)
        return
      }

      doFilter()

      // Un-check when searching.
      onChange([])
    }
  }

  const handleShowFilter = () => {
    dispatch(showFilter(filterName))
  }

  const handleShowColumnEditor = () => {
    dispatch(showColumnEditorAction(columnEditorId))
  }

  // Callback for ColumnEditor.
  const handleColumnChange = (selectedColumns) => {
    // Keep columns that are not part of column list.
    // For example, target ACoS is not part of bulk column list,
    // but its status needs to be kept.
    const columnsToKeep = []
    columnSelection.forEach((column) => {
      const found = columnList.find(c => c.key === column)
      if (!found) {
        columnsToKeep.push(column)
      }
    })
    return selectColumns([...new Set(columnsToKeep.concat(selectedColumns))], columnEditorId)
  }

  // Expand/collapse parent records.
  const handleExpand = (record) => {
    setExpansionState(Object.assign({}, expansionState, {
      [record[idField]]: expansionState[record[idField]] === true ? false : true,
    }))
  }

  // Handle selection changes in children.
  const handleChildSelectionChange = parentRecord => (selection) => {
    const newSelection = { ...currentSelection }
    const allChildIds = parentRecord.children.map(child => child[idFieldChild])
    // Because the `selectedRecords` of all parents are passed to each child,
    // and they operate on this list, we need to remove out child IDs
    // that belong to other parents.
    newSelection[parentRecord[idField]] = selection.filter(childId => (
      allChildIds.indexOf(childId) !== -1
    ))
    setCurrentSelection(newSelection)

    // Propagate changes to the parent component.
    let payload = []
    Object.keys(newSelection).forEach((parentId) => {
      payload = payload.concat(newSelection[parentId])
    })
    onChange(payload)
  }

  const loadData = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStart((pageNum - 1) * pageRows)
      setPageEnd(pageNum * pageRows)
    } else {
      setPageStart(0)
      setPageEnd(filteredRecords.length)
    }
  }

  const hasAction = () => (
    !(noSearch && !renderTopRight
      && (typeof filterName === 'undefined' || useFilterModal)
      && typeof columnList === 'undefined' && !getExportData)
  )

  const renderAction = () => {
    if (!hasAction()) {
      return null
    }

    let tableActionClass = 'table-header'
    if (stickyOffset !== null) {
      tableActionClass = `${tableActionClass} sticky`
    }

    return (
      <div className={tableActionClass} ref={refAction}>
        {
          !noSearch && (
            <div className="table-header-left">
              <SearchBox
                placeholder={searchPlaceholder}
                keyword={keyword}
                onChange={setKeyword}
                onKeyPress={handleKeywordPress}
              />
            </div>
          )
        }
        {
          (renderTopRight || (typeof filterName !== 'undefined' && !useFilterModal)
          || typeof columnList !== 'undefined' || getExportData !== null) && (
            <div className="table-header-right">
              { renderTopRight ? renderTopRight() : null }
              {
                getExportData !== null && (
                  <CSVLink
                    data={csvToExport}
                    filename={`${exportFileName}.csv`}
                    className="export-link"
                    title="Export to CSV"
                  >
                    <FiDownload size={16} />
                  </CSVLink>
                )
              }
              {
                (typeof filterName !== 'undefined' && !useFilterModal) && (
                  <FiFilter
                    title="Filter"
                    color="#979797"
                    onClick={handleShowFilter}
                  />
                )
              }
              {
                typeof columnList !== 'undefined' && (
                  <FiColumns
                    title="Column Customizer"
                    color="#979797"
                    onClick={handleShowColumnEditor}
                  />
                )
              }
            </div>
          )
        }
      </div>
    )
  }

  const renderRecords = () => {
    if (!filteredRecords.length) {
      return (
        <div className="table-row">
          <div className="table-col">
            { noRecordText }
          </div>
        </div>
      )
    }

    const columnsChild = columns.filter(column => column.parentOnly !== true)

    return filteredRecords.slice(pageStart, pageEnd).map((record, index) => {
      const isExpanded = expansionState[record[idField]] === true

      let childContents = null
      if (isExpanded) {
        if (viewAsTable) {
          childContents = (
            <div className="child-table-wrapper">
              <SortableTable
                defaultSort={defaultSort}
                sorter={sorterChild}
                records={record.children}
                selectedRecords={selectedRecords}
                idField={idFieldChild}
                noPagination
                noCheckBox={noCheckBox}
                searchFields={searchFields}
                noSearch={!hasSearchChild}
                columns={columnsChild}
                columnList={columnList}
                columnSelection={columnSelection}
                renderRecord={renderChild}
                onChange={handleChildSelectionChange(record)}
              />
            </div>
          )
        } else {
          childContents = (
            <StreamChart stats={record.children} />
          )
        }
      }

      return (
        <Fragment key={record[idField]}>
          <div
            className={`table-row${record.className ? ` ${record.className}` : ''}`}
            onClick={() => { handleExpand(record) }}
          >
            <div className="table-col col-expand">
              <span className="expand-icon" title={isExpanded ? 'Collapse' : 'Expand'}>
                { isExpanded ? '-' : '+' }
              </span>
            </div>
            { renderRecord(record, false, pageStart + index) }
          </div>
          { childContents }
        </Fragment>
      )
    })
  }

  // Render aggregation row.
  const renderAggregation = () => {
    if (!renderTotal) {
      return null
    }

    const summary = {
      revenue: 0,
      cost: 0,
      orders: 0,
      impressions: 0,
      clicks: 0,
      yearlyCost: 0,
      ntb_orders: 0,
      ntb_sales: 0,
      st_impr_rank: 0,
      st_impr_share: 0,
      viewable_impressions: 0,
      daily_budget: 0,
    }

    filteredRecords.forEach((record) => {
      summary.revenue += parseFloat(record.revenue)
      summary.cost += parseFloat(record.cost)
      summary.orders += parseInt(record.orders, 10)
      summary.impressions += parseInt(record.impressions, 10)
      summary.clicks += parseInt(record.clicks, 10)
      summary.yearlyCost += parseFloat(record.yearlyCost || 0)
      summary.ntb_orders += parseInt(record.ntb_orders || 0, 10)
      summary.ntb_sales += parseFloat(record.ntb_sales || 0)
      summary.st_impr_rank += parseFloat(record.st_impr_rank || 0)
      summary.st_impr_share += parseFloat(record.st_impr_share || 0)
      summary.viewable_impressions += parseInt(record.viewable_impressions || 0, 10)
      summary.daily_budget += parseFloat(record.daily_budget || 0)
    })

    if (filteredRecords.length) {
      summary.st_impr_rank /= filteredRecords.length
      summary.st_impr_share /= filteredRecords.length
    }

    const derived = calcDerivedMetrics(summary)

    return (
      <div className="table-row content-footer" ref={refFooter}>
        <div className="table-col col-expand" />
        { renderTotal(derived) }
      </div>
    )
  }

  // Prepare data to export.
  const csvToExport = useMemo(() => {
    if (!getExportData) {
      return ''
    }

    let dataToExport = []

    // Add row for headings.
    const exportableColumns = []
    columns.forEach((column) => {
      if (column.exportable === false) {
        return
      }

      if (typeof columnList !== 'undefined') {
        const found = columnList.find(c => c.key === column.key)
        if (found && !columnSelection.find(c => c.key === column.key)) {
          return
        }
      }

      exportableColumns.push(column)
    })
    dataToExport.push(exportableColumns.map(column => column.plainName || column.name || column.label))

    filteredRecords.forEach((record) => {
      dataToExport = dataToExport.concat(
        record.children.map(child =>
          getExportData(exportableColumns, child)
        )
      )
    })

    return dataToExport
  }, [columns, columnList, columnSelection, filteredRecords, getExportData])

  // Prepare CSS classes for table body.
  let tableBodyClass = 'table-body'
  if (renderTotal) {
    tableBodyClass = `${tableBodyClass} has-footer`
  }
  if (hasAction()) {
    tableBodyClass = `${tableBodyClass} has-action`
  }
  if (stickyOffset !== null) {
    tableBodyClass = `${tableBodyClass} sticky`
  }

  return (
    <div className={`custom-table-component ${className} ${isLoading ? 'loading' : ''}`}>
      {
        isLoading && <LoaderComponent />
      }
      {
        typeof filterName !== 'undefined' && (
          <TableFilterShower
            filterName={filterName}
            onValidate={onFilterValidate}
          />
        )
      }
      { renderAction() }
      <div className={tableBodyClass} ref={refBody}>
        <div className="table-row content-header" ref={refHeader}>
          <div className="table-col col-expand" />
          { children }
        </div>
        { renderAggregation() }
        { renderRecords() }
      </div>
      <PaginationComponent
        pageNeighbours={paginationNeighbours}
        total={filteredRecords.length}
        loadData={loadData}
      />
      {
        (typeof filterName !== 'undefined')
        && !useFilterModal
        && (visibleFilterName === filterName)
        && <TableFilter filterName={filterName} />
      }
      {
        typeof columnList !== 'undefined' && showColumnEditor && visibleColumnEditorId === columnEditorId &&
        <ColumnEditor
          columnList={columnList}
          currentSelection={columnSelection}
          noReset={columnEditorNoReset}
          onApply={handleColumnChange}
        />
      }
    </div>
  )
}

export default TargetStreamTable
