/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SearchBox from '../../CommonComponents/SearchBox'
import CustomTable from '../../CommonComponents/CustomTableComponent'

import { searchLocations } from '../../../redux/actions/campaignCreator'

import { capitalizeFirstLetter } from '../../../services/helper'

const SDLocationSection = ({ locations, onSelect }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const handleKeywordPress = async (event) => {
    if (event.key !== 'Enter' || keyword === '') {
      return
    }

    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(searchLocations(accessToken, keyword))
    setSearchResults(response)
    setIsLoading(false)
  }

  const doSelect = (newSelection) => {
    const locationsToSelect = []
    const selectedLocationIds = []
    newSelection.forEach((location) => {
      if (selectedLocationIds.includes(location.locationId)) {
        return
      }
      locationsToSelect.push(location)
      selectedLocationIds.push(location.locationId)
    })
    onSelect(locationsToSelect)
  }

  const handleAllAdd = (event) => {
    event.preventDefault()
    doSelect([
      ...locations,
      ...searchResults,
    ])
  }

  const handleAllRemove = (event) => {
    event.preventDefault()
    doSelect([])
  }

  const handleLocationAdd = location => (event) => {
    event.preventDefault()
    doSelect([
      ...locations,
      location,
    ])
  }

  const handleLocationRemove = location => () => {
    doSelect(locations.filter(selected =>
      selected.locationId !== location.locationId
    ))
  }

  const renderLocationResult = (location) => {
    let category = location.category
    if (category !== 'DMA') {
      category = capitalizeFirstLetter(category.toLowerCase())
    }

    const isAdded = locations.find(selected =>
      selected.locationId === location.locationId
    )

    return (
      <>
        <div className="table-col col-location">
          { location.name }
          <div className="location-category">
            { category }
          </div>
        </div>
        <div className="table-col">
          {
            !isAdded ? (
              <a
                href="#"
                onClick={handleLocationAdd(location)}
              >
                Add
              </a>
            ) : (
              <span className="location-add-status">Added</span>
            )
          }
        </div>
      </>
    )
  }

  const renderSearchResults = () => {
    return (
      <CustomTable
        isLoading={isLoading}
        className="table-locations"
        records={searchResults}
        noCheckBox
        noSearch
        idField="locationId"
        noRecordText="No locations found."
        renderRecord={renderLocationResult}
      >
        <div className="table-col col-location">Location</div>
        <div className="table-col">
          <a href="#" onClick={handleAllAdd}>
            Add All
          </a>
        </div>
      </CustomTable>
    )
  }

  const renderSearchArea = () => {
    return (
      <div className="location-pane">
        <SearchBox
          placeholder="Search locations by state, DMA, city, or postal code"
          keyword={keyword}
          onChange={setKeyword}
          onKeyPress={handleKeywordPress}
        />
        { renderSearchResults() }
      </div>
    )
  }

  const renderLocationSelection = (location) => {
    let category = location.category
    if (category !== 'DMA') {
      category = capitalizeFirstLetter(category.toLowerCase())
    }

    return (
      <>
        <div className="table-col col-location">
          { location.name }
          <div className="location-category">
            { category }
          </div>
        </div>
        <div className="table-col">
          <span
            className="close-icon"
            title="Remove"
            onClick={handleLocationRemove(location)}
          >
            &times;
          </span>
        </div>
      </>
    )
  }

  const renderSelection = () => {
    return (
      <div className="location-pane">
        <CustomTable
          className="table-locations"
          records={locations}
          noCheckBox
          noSearch
          idField="locationId"
          noRecordText="No locations added."
          renderRecord={renderLocationSelection}
        >
          <div className="table-col col-location">Location</div>
          <div className="table-col">
            <a href="#" onClick={handleAllRemove}>
              Remove All
            </a>
          </div>
        </CustomTable>
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-title">
        Locations (optional)
      </div>
      <div className="section-note">
        We'll show your ads to customers relevant to the locations you choose.<br />
        Choosing locations will narrow your campaign reach. If no locations are chosen,
        we will show your ads throughout the country.
      </div>
      <div className="location-area">
        { renderSearchArea() }
        { renderSelection() }
      </div>
    </div>
  )
}

export default SDLocationSection
