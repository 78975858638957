import React from 'react'

import FlowContainer from './FlowContainer'
import AttachmentList from './AttachmentList'
import LoomVideoCollection from './LoomVideoCollection'

const attachments = [
  { name: 'Quick Start Flow.pdf', url: 'https://drive.google.com/file/d/1AgUQOlwwGoTKrjaskraXmI8Hvpfzg7ZE/view?usp=sharing' },
]

const videoList = [
  {
    name: 'Quick Start Flow. Let\'s Go!',
    videoId: 'b54d9f4dd7bc4c828832d49d7d9247fa',
  },
]

// FIXME: `LoomVideoCollection` does not support `onWatch`,
// making customers unable to earn coins.
const QuickStartFlow = ({ onWatch, ...props }) => {
  return (
    <FlowContainer
      name="Quick Start Flow"
      coin={10}
      {...props}
    >
      <LoomVideoCollection videoList={videoList} />
      <div className="flow-text">
        <p><strong>Welcome to the Quick Start Flow.</strong></p>
        <p>If you're reading this, it's probably because you are new to Entourage 2.0 and
          are looking for the FASTEST way to learn the software and to start getting results.</p>
        <p>We have good news! The Quick Start Flow is a short tutorial that will teach you exactly how to get started with ease.</p><br/>
        <p><strong>After finishing this flow, you'll be able to</strong></p>
        <ol>
          <li>1) Learn how to customize dashboards to help you drill down into the data with ease.</li>
          <li>2) Learn How to set your target ACOS so that our system can help you optimize in seconds.</li>
          <li>3) Set up customized columns across multiple tables and use the filtering system.</li>
          <li>4) Locate the Smart Pilot, Activity log, Campaign Dashboard, Product Dashboard and more.</li>
        </ol>
        <p>You'll earn an additional 10 Genius Coins for finishing this flow. Let's get started!</p>
      </div>
      <AttachmentList attachments={attachments} />
    </FlowContainer>
  )
}

export default QuickStartFlow
