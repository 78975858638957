import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import DateRangeComponent from '../CommonComponents/DateRangeComponent'

import ComparisonView from '../ComparisonView'
import ProductChartComponent from './ProductChartComponent'
import ProductCampaignTable from './ProductCampaignTable'

import {
  formatCurrency,
  formatValue
} from '../../services/helper'

import {
  COMPARISON_VIEW_TYPE_WEEKLY,
  COMPARISON_VIEW_TYPE_MONTHLY,
  COMPARISON_VIEW_TYPE_LABELS,
} from '../../utils/defaultValues'

import { getSummary } from '../../redux/actions/product'
import { setDateRange } from '../../redux/actions/header'

const VIEW_TYPE_CHART = 'chart'

const kpiList = [
  // { value: 'organicRevenue', label: 'Organic Revenue' },
  { value: 'ppcRevenue', label: 'PPC Revenue' },
  { value: 'cost', label: 'Ad Spend' },
  { value: 'impressions', label: 'Impressions' },
  { value: 'clicks', label: 'Clicks/CTR(%)' },
  { value: 'orders', label: 'Orders' },
  { value: 'conversion', label: 'Conversion Rate(%)' },
  { value: 'acos', label: 'ACoS(%)' },
]

const ProductDashboardComponent = ({ productCog, setProductCog, onUpdateAcos }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  // const curProduct = useSelector(state => state.product.curProduct)
  const curProductKpi = useSelector(state => state.product.curProductKpi)
  const campaignsForProduct = useSelector(state => state.product.campaignsForProduct)

  const { id: productId } = useParams()

  const [currentViewType, setCurrentViewType] = useState(VIEW_TYPE_CHART)
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [summary, setSummary] = useState(null)

  useEffect(() => {
    if (currentViewType && currentViewType !== VIEW_TYPE_CHART) {
      (async () => {
        setIsSummaryLoading(true)
        const accessToken = await getAccessTokenSilently()
        const data = await dispatch(getSummary(
          accessToken,
          currentViewType,
          productId,
        ))
        setSummary(data)
        setIsSummaryLoading(false)
      })()
    }
  }, [productId, currentViewType]) // eslint-disable-line

  const handleChangeDateRange = (value) => {
    dispatch(setDateRange({
      startDate: value[0],
      endDate: value[1],
    }))
  }

  const renderMeta = () => (
    <div className="meta-container">
      {/* <div className="meta-card">
        <span className="meta-label">
          Cost of goods
        </span>
        <input
          type="number"
          min="0"
          value={productCog}
          onChange={e => setProductCog(e.target.value)}
        />
        {
          !isSame && (
            <button
              type="button"
              className="btn btn-red"
              onClick={onUpdateAcos}
            >
              Save
            </button>
          )
        }
      </div> */}
      <div className="meta-card">
        <span className="meta-label">
          Average selling price
        </span>
        <span className="meta-value">
          { formatCurrency((curProductKpi || {}).average_selling_price || 0, currencySign, currencyRate) }
        </span>
      </div>
      <div className="meta-card">
        <span className="meta-label">
          Total Active Campaigns
        </span>
        <span className="meta-value">
          { (campaignsForProduct || []).length }
        </span>
      </div>
    </div>
  )

  const calcMetricValue = (metric) => {
    const kpi = curProductKpi || {}
    // if (metric === 'organicRevenue') {
    //   let value = 0
    //   if (curProductKpi) {
    //     value = curProductKpi.total_sale - curProductKpi.revenue
    //   }
    //   return formatCurrency(value, currencySign, currencyRate)
    // }
    if (metric === 'ppcRevenue') {
      return formatCurrency(kpi.revenue || 0, currencySign, currencyRate)
    }
    if (metric === 'cost') {
      return formatCurrency(kpi.cost || 0, currencySign, currencyRate)
    }
    if (metric === 'impressions') {
      return formatValue(kpi.impressions || 0, 'number', 0)
    }
    if (metric === 'clicks') {
      return `${formatValue(kpi.clicks || 0, 'number', 0)} / ${formatValue(kpi.ctr || 0, 'percent', 2)}`
    }
    if (metric === 'orders') {
      return formatValue(kpi.orders || 0, 'number', 0)
    }
    if (metric === 'conversion') {
      let value = 0
      if (kpi.clicks || 0) {
        value = (kpi.orders || 0) / kpi.clicks * 100
      }
      return formatValue(value, 'percent', 2)
    }
    if (metric === 'acos') {
      let value = 0
      if (kpi.revenue || 0) {
        value = (kpi.cost || 0) / kpi.revenue * 100
      }
      return formatValue(value, 'percent', 1)
    }
  }

  const renderKpi = () => (
    <div className="kpi-container">
      {
        kpiList.map(kpi => (
          <div key={kpi.value} className="kpi-card">
            <div className="metric-name">{ kpi.label }</div>
            <div className="metric-value">{ calcMetricValue(kpi.value) }</div>
          </div>
        ))
      }
    </div>
  )

  const renderTypeSelector = () => {
    return (
      <div className="type-selector-container">
        <div>
          {
            [VIEW_TYPE_CHART, COMPARISON_VIEW_TYPE_WEEKLY, COMPARISON_VIEW_TYPE_MONTHLY].map(type => (
              <button
                key={type}
                type="button"
                className={`btn ${currentViewType === type ? 'btn-red' : 'btn-white'}`}
                onClick={() => { setCurrentViewType(type) }}
              >
                { COMPARISON_VIEW_TYPE_LABELS[type] || 'Chart View' }
              </button>
            ))
          }
        </div>
        <DateRangeComponent
          value={[currentStartDate, currentEndDate]}
          placement="bottomEnd"
          onChange={handleChangeDateRange}
        />
      </div>
    )
  }

  // const isSame = formatValue(curProduct.cog, 'number') === productCog

  return (
    <div className="product-detail-dashboard">
      <div className="section">
        { renderMeta() }
        { renderKpi() }
        { renderTypeSelector() }
        {
          currentViewType === VIEW_TYPE_CHART ? (
            <ProductChartComponent />
          ) : (
            <ComparisonView
              isLoading={isSummaryLoading}
              summary={summary}
            />
          )
        }
      </div>
      <div className="section">
        <ProductCampaignTable />
      </div>
    </div>
  )
}

export default ProductDashboardComponent
