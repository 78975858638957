import React from 'react'
import { Modal } from 'rsuite'
import YouTube from 'react-youtube'

const youTubeOptions = {
  height: '100%',
  width: '100%',
  allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
}

const YouTubeVideoModal = ({ title = '', showModal = false, onClose, video, onWatch }) => (
  <Modal
    backdrop={true}
    className="video-popup-modal"
    show={showModal}
    size="lg"
    onClick={onClose}
    onHide={onClose}
    overflow={false}
  >
    <Modal.Header>
      <Modal.Title>
        { title }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="flow-video-list">
        <div className="flow-video-item">
          <YouTube
            className="youtube-player"
            videoId={video?.videoId}
            id={video?.videoId}
            title={title}
            opts={youTubeOptions}
            onEnd={() => onWatch()}
          />
        </div>
      </div>
    </Modal.Body>
  </Modal>
)
export default YouTubeVideoModal