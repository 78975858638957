import React, { useState } from 'react'

import CustomTable from '../CustomTableComponent'
import TargetingModal from '../../CampaignCreator/Shared/TargetingModal'

const ProductInputSection = ({ targetings, onChange }) => {
  const [openTargetModal, setOpenTargetModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.25)

  const handleDefaultBidApply = () => {
    onChange(targetings.map(targeting => ({
      ...targeting,
      bid: defaultBid,
    })))
  }

  const handleBidChange = (event, target) => {
    onChange(targetings.map((item) => {
      if (item.id.toString() === target.id.toString()) {
        return {
          ...item,
          bid: event.target.value,
        }
      }
      return item
    }))
  }

  const handleRemove = (target) => {
    onChange(targetings.filter(item => {
      if (target.type !== item.type) {
        return true
      }
      return target.id.toString() !== item.id.toString()
    }))
  }

  const renderAction = () => (
    <>
      <label>Default Bid</label>
      <input
        type="text"
        value={defaultBid}
        onChange={(event) => { setDefaultBid(event.target.value) }}
      />
      <button
        type="button"
        className="btn btn-blue"
        onClick={handleDefaultBidApply}
      >
        Apply
      </button>
    </>
  )

  const renderTarget = record => (
    <>
      <div className="table-col col-target">
        {
          record.type !== 'product' && (
            <div className="category-path">
              { record.path }
            </div>
          )
        }
        <div className="targeting-name" title={record.name}>
          { record.type !== 'product' ? 'Category' : 'Product' }:&nbsp;
          { record.name }
        </div>
        {
          record.type === 'product' && (
            <div className="targeting-meta">
              ASIN: { record.asin }
            </div>
          )
        }
        {
          (record.type === 'refine' && record.brandName) && (
            <div className="targeting-meta">
              Brand: { record.brandName }
            </div>
          )
        }
      </div>
      <div className="table-col">
        <input
          type="number"
          value={record.bid}
          onChange={(event) => { handleBidChange(event, record) }}
        />
      </div>
      <div className="table-col col-action">
        <button
          type="button"
          className="btn btn-red"
          onClick={() => { handleRemove(record) }}
        >
          Remove
        </button>
      </div>
    </>
  )

  return (
    <div className="section-container">
      <div className="section-title">
        Products Targeting
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setOpenTargetModal(true) }}
        >
          Find Categories/ASINs
        </button>
      </div>
      <div className="section-note">
        When adding Products or Categories to multiple campaigns,
        only Product Targeting enabled ad groups will be available
        for selection on the next screen.
      </div>
      <CustomTable
        className="table-targets"
        records={targetings}
        idField="id"
        noCheckBox
        searchFields={['name']}
        noRecordText="No targeting added."
        renderTopRight={renderAction}
        renderRecord={renderTarget}
      >
        <div className="table-col">Target</div>
        <div className="table-col">Bid</div>
        <div className="table-col"></div>
      </CustomTable>
      <TargetingModal
        show={openTargetModal}
        defaultBid={defaultBid}
        targetings={targetings}
        suggestedCategories={[]}
        suggestedProducts={[]}
        onChange={onChange}
        onClose={() => { setOpenTargetModal(false) }}
      />
    </div>
  )
}

export default ProductInputSection
