import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import ModalAddNegatives from '../../modals/ModalAddNegatives'

import {
  formatValue,
  formatCurrency,
  tableSorter,
} from '../../../../services/helper'

const columns = [
  { key: 'term', name: 'Search Term', className: 'col-search-term' },
  { key: 'clicks', name: 'Clicks' },
  { key: 'cost', name: 'Spend' },
]

const SalesTable = ({ campaignType, currentAdGroupId }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const isNegativeKWLoading = useSelector(state => state.campaignDetail.isNegativeKWLoading)
  const isKeywordRelatedDataLoading = useSelector(state => state.campaignDetail.isKeywordRelatedDataLoading)
  const negativeKWData = useSelector(state => state.campaignDetail.negativeKWData)
  const keywordRelatedData = useSelector(state => state.campaignDetail.keywordRelatedData)

  const [terms, setTerms] = useState([])
  const [selectedSearchTerms, setSelectedSearchTerms] = useState([])
  const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false)

  useEffect(() => {
    if (!keywordRelatedData
        || !keywordRelatedData.termsWithoutSales
        || !keywordRelatedData.termsWithoutSales.length) {
      setTerms([])
      return
    }

    let filteredTerms = [...keywordRelatedData.termsWithoutSales]

    if (negativeKWData && negativeKWData.length) {
      filteredTerms = filteredTerms.filter(term => (
        !negativeKWData.find(keyword => keyword.keyword_text === term.term)
      ))
    }

    setTerms(filteredTerms)
  }, [keywordRelatedData, negativeKWData])

  const renderAction = () => {
    return (
      <>
        {
          selectedSearchTerms.length > 0 && (
            <button
              type="button"
              className="btn btn-green"
              onClick={() => { setShowAddToCampaignModal(true) }}
            >
              Add Negatives to Campaign
            </button>
          )
        }
      </>
    )
  }

  const renderSearchTerm = record => (
    <>
      <div className="table-col col-search-term" title={record.term}>
        { record.term }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
    </>
  )

  const selectedSTs = terms
    .filter(term => selectedSearchTerms.indexOf(term.id) !== -1)
    .map(st => ({
      ...st,
      search: st.term,
    }))

  const isLoading = isKeywordRelatedDataLoading || isNegativeKWLoading

  return (
    <div className="table-content">
      <h6>Search terms without sales</h6>
      <SortableTable
        isLoading={isLoading}
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['term'])}
        className="table-search-terms"
        records={terms}
        idField="id"
        searchFields={['term']}
        hasSticky
        selectedRecords={selectedSearchTerms}
        renderRecord={renderSearchTerm}
        renderTopRight={renderAction}
        onChange={setSelectedSearchTerms}
      />
      <ModalAddNegatives
        terms={selectedSTs}
        modalType="keyword-cleaner"
        campaignType={campaignType}
        currentAdGroupId={currentAdGroupId}
        showModal={showAddToCampaignModal}
        onClose={() => { setShowAddToCampaignModal(false) }}
      />
    </div>
  )
}

export default SalesTable
