import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import {
  getBrandRecommendations,
  searchProduct,
} from '../../../redux/actions/targeting'

import LoaderComponent from '../../CommonComponents/LoaderComponent'
import ExcludeBrandTable from './ExcludeBrandTable'
import ExcludeProductTable from './ExcludeProductTable'

const TAB_CATEGORY = 'category'
const TAB_PRODUCT = 'product'

const NegativeTargetingModal = ({ show, negativeTargetings, targetingType, onChange, onClose }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [currentTab, setCurrentTab] = useState(targetingType === 'auto' ? TAB_PRODUCT : TAB_CATEGORY)
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([])

  const handleBrandSearch = async (keyword) => {
    if (keyword === '') {
      return
    }
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getBrandRecommendations(accessToken, {
      keyword,
    }))
    setBrands(response.map(brand => ({
      ...brand,
      isTargeted: negativeTargetings.filter(item => item.type === 'brand')
        .filter(item => item.id.toString() === brand.id.toString()).length > 0,
    })))
    setIsLoading(false)
  }

  const handleProductSearch = async (keyword) => {
    if (keyword === '') {
      return
    }

    setIsSearching(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(searchProduct(accessToken, {
      search: keyword,
    }))
    setProducts(response.map(product => ({
      ...product,
      isTargeted: negativeTargetings.filter(item => item.type === 'product')
        .filter(item => item.asin === product.asin).length > 0
    })))
    setIsSearching(false)
  }

  const tabList = useMemo(() => {
    // Only negative keywords and negative product targets
    // are allowed in auto-targeting campaigns.
    if (targetingType === 'auto') {
      return [
        { value: TAB_PRODUCT, name: 'Exclude Products' },
      ]
    }
    return [
      { value: TAB_CATEGORY, name: 'Exclude Brands' },
      { value: TAB_PRODUCT, name: 'Exclude Products' },
    ]
  }, [targetingType])

  return (
    <Modal className={`negative-targeting-modal${isLoading || isSearching ? ' loading' : ''}`} backdrop="static" show={show} size="lg">
      <Modal.Body>
        { (isLoading || isSearching) && <LoaderComponent /> }
        <div className="tab-list">
          {
            tabList.map(tab => (
              <button
                key={tab.value}
                type="button"
                className={currentTab === tab.value ? 'selected' : ''}
                onClick={() => { setCurrentTab(tab.value) }}
              >
                { tab.name }
              </button>
            ))
          }
        </div>
        {
          currentTab === TAB_CATEGORY && (
            <ExcludeBrandTable
              brands={brands}
              negativeTargetings={negativeTargetings}
              onSearch={handleBrandSearch}
              onChange={onChange}
            />
          )
        }
        {
          currentTab === TAB_PRODUCT && (
            <ExcludeProductTable
              products={products}
              negativeTargetings={negativeTargetings}
              onSearch={handleProductSearch}
              onChange={onChange}
            />
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={() => onClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default NegativeTargetingModal
