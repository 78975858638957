import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from 'rsuite'

import CustomTable from '../../components/CommonComponents/CustomTableComponent'

import {
  changeViewWidgetState
} from '../../redux/actions/pageGlobal'

const ReportTableWidget = ({
  widget,
  columns,
  records,
  idField,
  customizing = false,
  renderRecord,
  isLoading = false,
  section = '',
  suffix = '',
  noPagination = true,
  onRemove,
}) => {
  const dispatch = useDispatch()

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          <span>
            {`${widget.title} ${suffix}`}
          </span>
        </div>
        <div className="widget-action">
          {customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={widget.view ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )}
        </div>
      </div>
      <CustomTable
        columns={columns}
        className="table-report"
        records={records}
        idField={idField}
        noRecordText={'No records found.'}
        noCheckBox
        noSearch
        noPagination={noPagination}
        renderRecord={renderRecord}
        isLoading={isLoading}
      >
        {(columns || []).map(c => (
          <div className="table-col" key={c.key}>
            {c.name}
          </div>
        ))}
      </CustomTable>
    </div>
  )
}

export default ReportTableWidget
