import React, { useState } from 'react'
import Select from 'react-select'
import CustomTooltip from '../../CommonComponents/CustomTooltip'

const bidTypeOptions = [
  { value: 'suggested', label: 'Suggested' },
  { value: 'min', label: 'Min' },
  { value: 'max', label: 'Max' },
]

const SuggestedBidTypeSelector = ({ targetings, forKeyword = false, onChange }) => {
  const [bidType, setBidType] = useState(bidTypeOptions[0])

  const handleApply = () => {
    onChange(targetings.map((target) => {
      if (target.suggestedBid) {
        if (bidType.value === 'max') {
          if (!forKeyword) {
            target.bid = target.suggestedBid.rangeUpper
          } else {
            target.keywordBid = target.suggestedBid.rangeEnd
          }
        } else if (bidType.value === 'min') {
          if (!forKeyword) {
            target.bid = target.suggestedBid.rangeLower
          } else {
            target.keywordBid = target.suggestedBid.rangeStart
          }
        } else {
          if (!forKeyword) {
            target.bid = target.suggestedBid.recommended
          } else {
            if (typeof target.suggestedBid.suggested !== 'undefined') {
              target.keywordBid = target.suggestedBid.suggested
            } else {
              target.keywordBid = target.suggestedBid.recommended
            }
          }
        }
      }
      return target
    }))
  }

  if (!targetings.length) {
    return null
  }

  return (
    <div className="suggested-bid-type-container">
      <label>
        Amazon Suggested Bid:
      </label>
      <CustomTooltip>
        <p>
          Suggested bids are calculated daily based on past
          bidding activity for ads similar to yours
          to predict bids that are more likely to win.
        </p>
      </CustomTooltip>
      <Select
        className="bid-type-selector"
        options={bidTypeOptions}
        value={bidType}
        onChange={setBidType}
      />
      <button
        type="button"
        className="btn btn-green"
        onClick={handleApply}
      >
        Apply To All
      </button>
    </div>
  )
}

export default SuggestedBidTypeSelector
