import React from 'react'

import CustomTooltip from '../../CommonComponents/CustomTooltip'
import CheckboxComponent from '../../CommonComponents/CheckboxComponent'

const CreativeHeadline = ({ basicInfo, required = false, onChange }) => {
  return (
    <div className="customizer-section">
      <div className="field-section-name">
        {
          required ? (
            <>Headline</>
          ) : (
            <CheckboxComponent
              label="Headline"
              checked={basicInfo.hasHeadline}
              onChange={(checked) => { onChange('hasHeadline', checked) }}
            />
          )
        }
        <CustomTooltip placement="right">
          <p>Tips to help get your headline approved</p>
          <p>- Check for typos, misspellings and grammar mistakes</p>
          <p>- Avoid excessive punctuation such as ‘!!!’ or ‘?!’</p>
          <p>- Avoid random punctuation such as ‘Your. headline’.</p>
          <p>- Avoid extra spacing such as ‘Y o u r h e a d l i n e’.</p>
          <p>- Sentence case is recommended (i.e. “This is a new headline”)</p>
        </CustomTooltip>
      </div>
      {
        (basicInfo.hasHeadline || required) && (
          <div className="field-section-contents">
            <input
              type="text"
              value={basicInfo.headline}
              onChange={(event) => { onChange('headline', event.target.value) }}
            />
            {
              basicInfo.headline.length > 50 && (
                <div className="warning-message">
                  Maximum length of headline is 50 characters.
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default CreativeHeadline
