import React, { useState } from 'react'
import { Modal } from 'rsuite'

const FilterSaveModal = ({ show, onSave, onClose }) => {
  const [name, setName] = useState('')

  return (
    <Modal className="filter-save-modal" backdrop="static" show={show} size="xs">
      <Modal.Body>
        <input
          type="text"
          value={name}
          placeholder="Enter a filter name"
          onChange={(event) => { setName(event.target.value) }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={name === ''}
          onClick={() => { onSave(name) }}
        >
          Save
        </button>
        <button type="button" className="rs-btn rs-btn-subtle" onClick={onClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FilterSaveModal
