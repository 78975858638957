import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { watch } from '../../redux/actions/tutorial'
import { selectIsNonEndemicAccount } from '../../redux/reducers/header'

import {
  COIN_TYPE_FLOW_QUICK_RESULTS,
  COIN_TYPE_FLOW_SMART_PILOT,
  COIN_TYPE_FLOW_OP_BASIC,
  // COIN_TYPE_FLOW_MORE_MARGINS,
} from '../../utils/coin'

import VideoLink from '../../components/CommonComponents/VideoLink'

import MainLayout from '../../layout/MainLayout'
import WelcomeDialog from './WelcomeDialog'
import QuickStartFlow from './QuickStartFlow'
import OpBasicFlow from './OpBasicFlow'
import SmartPilotFlow from './SmartPilotFlow'
import GeniusBid from './GeniusBid'
import Rules from './Rules'
import BulkOverviewFlow from './BulkOverviewFlow'
import BulkMoveFlow from './BulkMoveFlow'
// import MoreMarginFlow from './MoreMarginFlow'
import EntourPlaybookSeries from './EntourPlaybookSeries'
import SPAds from './SPAds'
import SBAds from './SBAds'
import SDAds from './SDAds'

import UniversityHeaderPng from '../../assets/img/university-header.png'

const flowList = [
  { key: COIN_TYPE_FLOW_QUICK_RESULTS, component: QuickStartFlow },
  { key: COIN_TYPE_FLOW_SMART_PILOT, component: SmartPilotFlow },
  { key: 'rules', component: Rules },
  { key: COIN_TYPE_FLOW_OP_BASIC, component: OpBasicFlow },
  { key: 'genius_bid', component: GeniusBid },
  { key: 'bulk_engine_overview', component: BulkOverviewFlow },
  { key: 'new_bulk_move_flow', component: BulkMoveFlow },
  // { key: COIN_TYPE_FLOW_MORE_MARGINS, component: MoreMarginFlow },
  { key: 'entourage_playbook_series', component: EntourPlaybookSeries },
  { key: 'sp_ads', component: SPAds },
  { key: 'sb_ads', component: SBAds },
  { key: 'sd_ads', component: SDAds },
]

const videoList = [
  { title: 'Genius Coin Marketplace', url: 'https://www.loom.com/embed/8c2f1bdb1de54db7bb9a091dc62b454f' }
]

const TutorialPage = () => {
  const dispatch = useDispatch()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const { getAccessTokenSilently } = useAuth0()

  const [activeFlow, setActiveFlow] = useState()

  const handleToggle = (key) => {
    setActiveFlow(activeFlow === key ? '' : key)
  }

  // After watched all videos belong to a specific flow, earn coins.
  const handleWatch = async (key) => {
    const accessToken = await getAccessTokenSilently()
    dispatch(watch(accessToken, key))
  }

  const renderFlow = (flow) => {
    if (isNonEndemicAccount && flow.key !== COIN_TYPE_FLOW_QUICK_RESULTS) {
      return null
    }

    return (
      <flow.component
        key={flow.key}
        active={activeFlow === flow.key}
        onToggle={() => { handleToggle(flow.key) }}
        onWatch={() => { handleWatch(flow.key) }}
      />
    )
  }

  return (
    <MainLayout>
      <div className="tutorial-page">
        <div className="page-header">
          <div className="page-title">
            <img src={UniversityHeaderPng} alt="university-header" />
          </div>
        </div>
        <VideoLink
          videoList={videoList}
          modalTitle='Genius Coin Marketplace'
        />
        <div className="page-content">
          <WelcomeDialog />
          { flowList.map(renderFlow) }
        </div>
      </div>
    </MainLayout>
  )
}

export default TutorialPage
