/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'

import DateRangeComponent from '../../components/CommonComponents/DateRangeComponent'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'
import { logTypeOptions } from '../../utils/defaultValues'

const Header = ({ tabList, activeTab, currentType, range,
  onTabChange, onTypeChange, onRangeChange, onDownload }) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const typesToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return logTypeOptions
    }
    return logTypeOptions.filter(type => (
      ![
        'ap_kw_adv',
        'ap_nta',
        'ap_npt',
        'ap_st_ex',
        'ap_pt_ex',
        'ap_placement',
      ].includes(type.value)
    ))
  }, [isNonEndemicAccount])

  return (
    <div className="header-container">
      <div className="tab-container">
        {
          tabList.map(tab => (
            <a
              key={tab}
              href="#"
              className={tab === activeTab ? 'active' : ''}
              onClick={onTabChange(tab)}
            >
              { tab }
            </a>
          ))
        }
      </div>
      <div className="filter-container">
        <Select
          className="type-selector"
          value={currentType}
          options={typesToRender}
          onChange={onTypeChange}
        />
        <DateRangeComponent
          placement="bottomEnd"
          value={range}
          onChange={onRangeChange}
        />
        <button
          type="button"
          className="btn btn-blue"
          onClick={onDownload}
        >
          Download
        </button>
      </div>
    </div>
  )
}

export default Header
