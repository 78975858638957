/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { ReactComponent as CloneSvg } from '../../assets/svg/clone.svg'

import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import Frequency from './Frequency'
import Lookback from './Lookback'
import FieldRow from './FieldRow'
import CheckInput from './CheckInput'

/**
 * @param {bool} isOriginal `true` if a setting is an original one,
 *                          `false` if it's a cloned one.
 */
const OpNTA = ({ isOriginal = false, campaign, settings, onChange }) => {
  let isSB = false
  if (campaign) {
    isSB = campaign.basic[0].type === 'sb'
  }

  // For a copied setting, prefix all field names with `copy_`.
  const getFieldName = name => (
    isOriginal ? name : `copy_${name}`
  )

  // Handle clicking on 'Clone'.
  const handleClone = (event) => {
    event.preventDefault()
    onChange('copy_nta_active', true)
  }

  const toggleField = settings[getFieldName('nta_active')]

  return (
    <div className="nta-section">
      <div className="setting-header">
        <div className="checkbox-info-wrapper">
          <CheckboxComponent
            label={`Automatically negate search terms if it meets the following criteria${!isOriginal ? ' (clone)' : ''}`}
            checked={toggleField}
            onChange={(checked) => { onChange(getFieldName('nta_active'), checked) }}
          />
          {
            isOriginal && (
              <CustomTooltip>
                <p>Negative exact match is used to negate search terms.</p>
              </CustomTooltip>
            )
          }
        </div>
        {
          isOriginal && !settings.copy_nta_active && (
            <a href="#" className="clone-button" onClick={handleClone}>
              <CloneSvg />
              Clone
            </a>
          )
        }
      </div>
      <FieldRow disabled={!toggleField}>
        {
          !isSB ? (
            <div className="field-wrapper checkbox-info-wrapper">
              <CheckboxComponent
                label="Add to campaign level"
                checked={settings[getFieldName('nta_add_to_campaigns')]}
                onChange={(checked) => { onChange(getFieldName('nta_add_to_campaigns'), checked) }}
              />
              <CustomTooltip>
                <p>Campaign level negative targeting means the negated search term
                or ASIN will be negated for the entire campaign, including all ad groups.</p>
              </CustomTooltip>
            </div>
          ) : (
            <div className="field-wrapper" />
          )
        }
        <div className="field-wrapper checkbox-info-wrapper">
          <CheckboxComponent
            label="Add to Ad Group level"
            checked={settings[getFieldName('nta_add_to_adgroups')]}
            onChange={(checked) => { onChange(getFieldName('nta_add_to_adgroups'), checked) }}
          />
          <CustomTooltip>
            <p>By default we’ll add negatives to the ad group from which they came from.</p>
            <p>Ad Group level negative targeting means the negated search term
            or ASIN will be negated for the ad group that it originated from.</p>
          </CustomTooltip>
        </div>
      </FieldRow>
      <FieldRow disabled={!toggleField}>
        <div className="field-wrapper checkbox-info-wrapper">
          <CheckboxComponent
            label="Add ASINS (Products) as negatives"
            checked={settings[getFieldName('nta_negate_asins_active')]}
            onChange={(checked) => { onChange(getFieldName('nta_negate_asins_active'), checked) }}
          />
          <CustomTooltip>
            <p>In Automatic campaigns, ASINs (products) will appear in the search term report.</p>
            <p>We'll automatically negate the ASINs that meet the criteria below.</p>
            <p>We’ll also add negate ASINs found in Category targeting campaigns that meet the criteria below.</p>
          </CustomTooltip>
        </div>
      </FieldRow>
      <FieldRow disabled={!toggleField}>
        <CheckInput
          label="Click(s) without sale"
          field={getFieldName('nta_clicks_active')}
          inputField={getFieldName('nta_clicks')}
          settings={settings}
          onChange={onChange}
        />
        <CheckInput
          label="Impressions without a click"
          field={getFieldName('nta_impressions_active')}
          inputField={getFieldName('nta_impressions')}
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <FieldRow disabled={!toggleField}>
        <CheckInput
          label="Ad spend without sale"
          field={getFieldName('nta_cost_active')}
          inputField={getFieldName('nta_cost')}
          settings={settings}
          onChange={onChange}
        />
        <div className="field-wrapper">
        </div>
      </FieldRow>
      <FieldRow disabled={!toggleField}>
        <CheckInput
          label="CTR (%) greater than"
          field={getFieldName('nta_ctr_greater_active')}
          inputField={getFieldName('nta_ctr_greater')}
          settings={settings}
          onChange={onChange}
        />
        <CheckInput
          label="CTR (%) lower than"
          field={getFieldName('nta_ctr_active')}
          inputField={getFieldName('nta_ctr')}
          settings={settings}
          onChange={onChange}
        />
      </FieldRow>
      <Frequency
        disabled={!toggleField}
        prefix={getFieldName('nta_')}
        settings={settings}
        onChange={onChange}
      />
      <Lookback
        disabled={!toggleField}
        field={getFieldName('nta_lookback')}
        settings={settings}
        onChange={onChange}
      />
    </div>
  )
}

export default OpNTA
