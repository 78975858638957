import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { Timeline, Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'
import { differenceInWeeks, format, formatDistanceToNow } from 'date-fns'

import LoaderComponent from '../LoaderComponent'
import { toast } from '../ToastComponent/toast'

import {
  addNotes,
  deleteNote,
} from '../../../redux/actions/campaign'

import { MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH } from '../../../utils/defaultValues'

import { ReactComponent as DefaultTaskSvg } from '../../../assets/svg/task-passive.svg'
import { parseDate } from '../../../services/helper'

const CampaignNotePane = ({ campaign, onClose, onChange = null }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [note, setNote] = useState('')
  const [noteList, setNoteList] = useState([])

  useEffect(() => {
    if (campaign.notes.length) {
      setNoteList(campaign.notes)
    }
  }, [campaign])

  const handleChangeNote = (newNote) => {
    if (newNote.length < MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH) {
      setNote(newNote)
    }
  }

  const handleSaveNote = async () => {
    setIsLoading(true)

    const accessToken = await getAccessTokenSilently()
    dispatch(addNotes(accessToken, [{
      note,
      campaignId: campaign.campaign_id,
    }])).then((response) => {
      setIsLoading(false)
      if (onChange) {
        onChange({
          ...campaign,
          notes: [response[0], ...noteList],
        })
      }
    }).catch(() => { setIsLoading(false) })
  }

  const handleRemoveNote = async (noteInfo) => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteNote(accessToken, noteInfo)).then((response) => {
      setIsLoading(false)
      if (onChange) {
        onChange({
          ...campaign,
          notes: noteList.filter(n => n.id !== response.id),
        })
      }
      toast.show({
        title: 'Success',
        description: 'Note has been successfully deleted.',
      })
    }).catch((error) => {
      setIsLoading(false)
      toast.show({
        title: 'Danger',
        description: error,
      })
    })
  }

  const renderNewNote = () => (
    <div className="new-note">
      <textarea
        className="campaign-note-textarea"
        rows={5}
        placeholder={`Maximum ${MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH} Characters`}
        value={note}
        onChange={(v) => handleChangeNote(v.target.value)}
      />
      <button
        type="button"
        className="btn btn-blue note-save-btn"
        disabled={isLoading || note === '' || note.length > MAX_CAMPAIGN_NOTE_CHARACTER_LENGTH}
        onClick={handleSaveNote}
      >
        Save
      </button>
    </div>
  )

  const renderTimeLineItem = (note) => (
    <Timeline.Item
      key={note.id}
      dot={
        <DefaultTaskSvg />
      }
    >
      <p>{ note.note }</p>
      <p className="time-helper-text">
        {
          differenceInWeeks(new Date(), parseDate(note.updated_at)) > 1
          ? format(parseDate(note.updated_at), 'MMM d, yyyy h:mm a')
          : formatDistanceToNow(parseDate(note.updated_at), { addSuffix: true })
        }
      </p>
      <IconButton
        className="note-remove-btn"
        appearance="primary"
        icon={<Icon icon="trash" />}
        size="sm"
        circle
        title="Remove"
        onClick={() => { handleRemoveNote(note) }}
      />
    </Timeline.Item>
  )

  return (
    <OutsideClickHandler
      onOutsideClick={onClose}
    >
      <div className="notes-panel">
        <div className="pane-header">
          <div className="pane-title">
            Notes: {campaign.campaign || campaign.name}
          </div>
          <span className="close-icon" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className={`pane-body ${isLoading ? 'loading' : ''}`}>
          { isLoading && <LoaderComponent /> }
          { renderNewNote() }
          <Timeline className="note-timeline">
            {
              (noteList || []).map(renderTimeLineItem)
            }
          </Timeline>
        </div>
        <div className="pane-footer">
          <button type="button" className="btn btn-blue" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default CampaignNotePane
