import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import SortableTable from '../../../CommonComponents/SortableTableComponent'
import { toast } from '../../../CommonComponents/ToastComponent/toast'

import ModalAddNegativeAsins from '../../modals/ModalAddNegativeAsins'

import {
  formatValue,
  formatCurrency,
  copyToClipboard,
  tableSorter,
  getExportValueForColumn,
} from '../../../../services/helper'

const columns = [
  { key: 'search', name: 'ASIN / Search Words', className: 'col-word' },
  { key: 'impressions', name: 'Imp.' },
  { key: 'clicks', name: 'Unprofitable Clicks' },
  { key: 'revenue', name: 'Sales' },
  { key: 'cost', name: 'Wasted AD Spend' },
  { key: 'yearlyCost', name: 'Approximate Yearly Savings' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'acos', name: 'ACoS' },
  { key: 'roas', name: 'ROAS' },
]

const TargetTable = ({ currentAdgroupId, campaignType, dateDiff }) => {
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const isNegativeWordDataLoading = useSelector(state => state.campaignDetail.isNegativeWordDataLoading)
  const negativeTargetData = useSelector(state => state.campaignDetail.negativeTargetData)

  const [asins, setAsins] = useState([])
  const [selectedAsins, setSelectedAsins] = useState([])
  const [showAddNegativesModal, setShowAddNegativesModal] = useState(false)

  useEffect(() => {
    const extendedAsins = (negativeTargetData || []).map((asin) => {
      let ctr = 0
      let acos = 0
      let roas = 0
      if (parseInt(asin.impressions, 10)) {
        ctr = parseInt(asin.clicks, 10) / parseInt(asin.impressions, 10) * 100
      }
      if (parseFloat(asin.revenue)) {
        acos = parseFloat(asin.cost) / parseFloat(asin.revenue) * 100
      }
      if (parseFloat(asin.cost)) {
        roas = parseFloat(asin.revenue) / parseFloat(asin.cost)
      }

      return {
        ...asin,
        asin: asin.search,
        ctr,
        acos,
        roas,
        yearlyCost: parseFloat(asin.cost) / dateDiff * 365,
      }
    })

    setAsins(extendedAsins)
  }, [negativeTargetData]) // eslint-disable-line

  const handleCopy = () => {
    copyToClipboard([...new Set(selectedAsins)].join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${selectedAsins.length} search word${selectedAsins.length > 1 ? 's' : ''}.`
    })
  }

  const renderAction = () => {
    if (!selectedAsins.length) {
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-blue"
          onClick={() => { setShowAddNegativesModal(true) }}
        >
          Add negative{selectedAsins.length > 1 ? 's' : ''} to campaign
        </button>
        <button type="button" className="btn btn-green" onClick={handleCopy}>
          Copy
        </button>
      </>
    )
  }

  const renderNegative = record => (
    <>
      <div className="table-col col-word">
        { record.search }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.yearlyCost, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.ctr, 'percent') }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent', 1) }
      </div>
      <div className="table-col">
        { formatValue(record.roas, 'number', 2) }
      </div>
    </>
  )

  const selectedWords = asins.filter(negative => (
    selectedAsins.indexOf(negative.search) !== -1
  ))

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  return (
    <>
      <SortableTable
        columns={columns}
        defaultSort={['cost', 'desc']}
        sorter={tableSorter(['search'])}
        className="table-search-words"
        records={asins}
        idField="search"
        searchFields={['search']}
        selectedRecords={selectedAsins}
        hasSticky
        hasDateRange
        filterName="campaignDetailNegative"
        isLoading={isNegativeWordDataLoading}
        getExportData={getExportData}
        exportFileName="Targets in Campaign"
        renderRecord={renderNegative}
        renderTopRight={renderAction}
        onChange={setSelectedAsins}
      />
      <ModalAddNegativeAsins
        showModal={showAddNegativesModal}
        terms={selectedWords}
        campaignType={campaignType}
        currentAdGroupId={currentAdgroupId}
        onClose={() => { setShowAddNegativesModal(false) }}
      />
    </>
  )
}

export default TargetTable
