import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'rsuite'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import ReportChartWidget from './ReportChartWidget'
import ReportPieChartWidget from './ReportPieChartWidget'

import { selectIsNonEndemicAccount } from '../../redux/reducers/header'
import { calculateChartMetric } from '../../services/helper'

const ChartSection = ({
  sectionKey,
  isLoading,
  viewWidgets,
  charts,
  spMetrics = undefined,
  sbMetrics = undefined,
  sdMetrics = undefined,
  salesData = undefined,
  matchTypeSummary = [],
  placementTotal = [],
  bidTotal = [],
  customizingSection,
  currencySign,
  onRemove,
}) => {
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)

  const renderCharts = () => {
    const chartWidgets = (viewWidgets || []).filter(w => w.widgetType === 'chart')
    return chartWidgets.map((widget) => {
      if (!widget.view && !customizingSection) {
        return null
      }

      if (isNonEndemicAccount && [
        'spend_vs_sales',
        'trending_acos',
        'trending_conversion_rate',
      ].includes(widget.key)) {
        return null
      }

      const {
        chartData = [],
        chartMetrics = []
      } = calculateChartMetric(charts, widget, currencySign)

      return (
        <Col key={widget.key} xs={12}>
          <ReportChartWidget
            widget={widget}
            data={chartData}
            metrics={chartMetrics}
            onRemove={onRemove}
            customizing={customizingSection}
            section={sectionKey}
          />
        </Col>
      )
    })
  }

  const renderPieCharts = () => {
    const chartWidgets = (viewWidgets || []).filter(w => w.widgetType === 'pieChart')
    return chartWidgets.map((widget) => {
      if (!widget.view && !customizingSection) {
        return null
      }

      let data = []
      switch (widget.key) {
        case 'sales_by_ad_type':
          data = [
            { name: `SP (${currencySign})`, value: spMetrics ? spMetrics.revenue : 0 , color: '#31cceb', fullName: 'SP Sales'},
            { name: `SB (${currencySign})`, value: sbMetrics ? sbMetrics.revenue : 0, color: '#cf1d23', fullName: 'SB Sales'},
            { name: `SD (${currencySign})`, value: sdMetrics ? sdMetrics.revenue : 0, color: '#97e80c', fullName: 'SD Sales'},
          ]
          break
        case 'organic_vs_ppc':
          data = [
            { name: `Organic Sales (${currencySign})`, value: salesData ? salesData.sales - salesData.ppcRevenue : 0, color: '#ed4c0c', fullName: 'Organic Sales'},
            { name: `PPC Sales (${currencySign})`, value: salesData ? salesData.ppcRevenue : 0, color: '#97e80c', fullName: 'PPC Sales'},
          ]
          break
        case 'sales_by_match_type':
          data = [
            { name: `Exact (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[0].revenue : 0, color: '#ed4c0c', fullName: 'EXACT'},
            { name: `Broad (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[1].revenue : 0, color: '#97e80c', fullName: 'BROAD'},
            { name: `Phrase (${currencySign}):`, value: matchTypeSummary?.length > 0 ? matchTypeSummary[2].revenue : 0, color: '#cf1d23', fullName: 'PHRASE'},
          ]
          break
        case 'brand_vs_non_brand_pie':
          let brandedSale = 0
          let nonBrandedSale = 0
          charts.forEach((record) => {
            brandedSale += record.branded_sale || 0
            nonBrandedSale += record.non_branded_sale || 0
          })
          data = [
            { name: `Branded (${currencySign}):`, value: brandedSale, color: '#ed4c0c', fullName: 'Branded'},
            { name: `Non-Branded (${currencySign}):`, value: nonBrandedSale, color: '#97e80c', fullName: 'Non-Branded'},
          ]
          break
        case 'sales_by_placement':
          data = [
            { name: `Top (${currencySign}):`, value: placementTotal['revenueTop'], color: '#0ce8dd', fullName: 'Top of Search'},
            { name: `Product (${currencySign}):`, value: placementTotal['revenueDetail'], color: '#97e80c', fullName: 'Product Page'},
            { name: `Rest (${currencySign}):`, value: placementTotal['revenueOther'], color: '#cf1d23', fullName: 'Rest of Search'},
          ]
          break
        case 'sales_by_bid_type':
          data = [
            { name: `Down only (${currencySign}):`, value: bidTotal['revenueLegacy'], color: '#0ce8dd', fullName: 'Down Only'},
            { name: `Up and Down (${currencySign}):`, value: bidTotal['revenueAuto'], color: '#97e80c', fullName: 'Up & Down'},
            { name: `Fixed Bid (${currencySign}):`, value: bidTotal['revenueManual'], color: '#cf1d23', fullName: 'Fixed'},
          ]
          break
        default:
          return null
      }

      return (
        <Col key={widget.key} xs={12}>
          <ReportPieChartWidget
            widget={widget}
            data={data}
            onRemove={onRemove}
            customizing={customizingSection}
            section={sectionKey}
          />
        </Col>
      )
    })
  }

  return (
    <Row>
      {
        isLoading && (
          <Col xs={24}>
            <LoaderComponent />
          </Col>
        )
      }
      { renderCharts() }
      { renderPieCharts() }
    </Row>
  )
}

export default ChartSection
