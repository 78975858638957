import React from 'react'
import { Tooltip, Whisper } from 'rsuite'
import { FiInfo } from 'react-icons/fi'

const CustomTooltip = ({ placement = 'left', trigger = 'hover', className = '', icon = null, wrappedChildren = null, children }) => {
  return (
    <Whisper placement={placement} trigger={trigger} speaker={(
      <Tooltip className={className}>
        { children }
      </Tooltip>
    )}>
        {wrappedChildren ? wrappedChildren :
            icon ? icon : <FiInfo size={16} color="#D9D9D9" /> }
      {/*{ icon ? icon : <FiInfo size={16} color="#D9D9D9" /> }*/}
    </Whisper>
  )
}

export default CustomTooltip
