import { callGet, callPost } from '../../services/axios.js'
import { toast } from '../../components/CommonComponents/ToastComponent/toast'

import {
  RULE_MANAGER_SHOW,
  UPDATE_CAMPAIGN_SELECTION,
  GET_TEMPLATE_TO_EDIT,
  TURN_RULES_SUCCESS,
  DELETE_FROM_TEMPLATE_SUCCESS,
} from '../actionTypes/ruleManager'

import {
  RULE_TYPE_SP,
  ruleNames,
} from '../../utils/defaultValues'

/**
 * Show rule manager.
 */
export const showRuleManager = (ruleType, campaigns, products = []) => {
  return {
    type: RULE_MANAGER_SHOW,
    ruleType,
    campaigns,
    products,
  }
}

// Hide rule managers.
export const hideRuleManager = () => {
  return {
    type: RULE_MANAGER_SHOW,
  }
}

/**
 * Show template editor.
 *
 * @param {Object|number|null} template The full template object,
 * or a template ID, or NULL to create a new template.
 */
export const showTemplateEditor = (ruleType, template = null) => {
  return {
    type: RULE_MANAGER_SHOW,
    ruleType: `${ruleType}_template_editor`,
    template,
  }
}

// Show smart pilot manager.
export const showSpManager = (campaignId, adgroupId) => {
  return {
    type: RULE_MANAGER_SHOW,
    ruleType: RULE_TYPE_SP,
    campaignId,
    adgroupId,
  }
}

export const updateCampaignSelection = (campaigns) => {
  return {
    type: UPDATE_CAMPAIGN_SELECTION,
    campaigns,
  }
}

export const turnRules = (accessToken, type, campaignIds, value = null) => (dispatch, getState) => {
  // Value: This manages whether to toggle, turn on or turn off the rule.
  // A null value means the rule is supposed to be toggled.
  // A true value means it is supposed to be turned on for all selected campaigns
  // A false value means it is supposed to be turned off for all selected campaigns

  const { header: { currentUserId } } = getState()

  return callPost('/ruleManager/turnRules', {
    userId: currentUserId,
    type,
    campaignIds,
    value
  }, accessToken).then((response) => {
    dispatch({
      type: TURN_RULES_SUCCESS,
      data: {
        type,
        campaignIds: response.data.successIds.map(campaignId => campaignId.toString()),
        value
      },
    })

    if (!response.data.failedCampaigns.length) {
      toast.show({
        title: 'Success',
        description: 'Toggled successfully.',
      })
      return true
    }

    let description = 'The following campaigns can\'t be toggled:<br />'
    description += response.data.failedCampaigns.map(({ name }) => `- ${name}`).join('<br />')
    description += '<br />You must first save settings for these campaigns.<br />'
    description += `Visit the ${ruleNames[type]} for each campaign or use a template and apply settings.`
    toast.show({
      title: 'Danger',
      description,
      duration: 5000,
    })

    return false
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to toggle.',
    })

    return false
  })
}

export const applyTemplates = (accessToken, type, campaignIds, templateIds) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/ruleManager/applyTemplates', {
    userId: currentUserId,
    type,
    campaignIds,
    templateIds,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Successfully applied templates.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to apply templates.',
    })
  })
}

export const loadTemplates = (accessToken, type) => () => {
  return callGet('/ruleManager/loadTemplates', accessToken, { type })
}

export const getTemplate = (accessToken, type, templateId) => (dispatch) => {
  return callGet(`/ruleManager/getTemplate/${templateId}`, accessToken, { type }).then((response) => {
    dispatch({
      type: GET_TEMPLATE_TO_EDIT,
      template: response.data,
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to get template.',
    })
  })
}

export const deleteTemplate = (accessToken, type, templateId) => () => {
  return callPost(`/ruleManager/deleteTemplate/${templateId}`, { type }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Successfully deleted template.',
    })
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to delete template.',
    })
  })
}

export const deleteFromTemplate = (accessToken, type, templateId, campaignId = null) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/ruleManager/deleteFromTemplate', {
    userId: currentUserId,
    type,
    templateId,
    campaignId,
  }, accessToken).then(() => {
    toast.show({
      title: 'Success',
      description: 'Removed successfully.',
    })
    dispatch({
      type: DELETE_FROM_TEMPLATE_SUCCESS,
      data: {
        type,
        templateId,
        campaignId,
      },
    })
    return true
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to remove.',
    })
    return false
  })
}

export const loadSkus = (accessToken, { page, countPerPage, search }) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/ruleManager/loadSkus', accessToken, {
    userId: currentUserId,
    page,
    countPerPage,
    search,
  }).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to load skus.',
    })
    return []
  })
}

// Retrieve campaigns matching SKUs selected.
export const loadCampaigns = (accessToken, includeSpSd, includeSb, skus = [], excludeSd = false) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callPost('/ruleManager/loadCampaigns', {
    userId: currentUserId,
    includeSpSd,
    includeSb,
    skus,
    excludeSd,
  }, accessToken).then((response) => {
    return response.data
  }).catch((error) => {
    toast.show({
      title: 'Danger',
      description: error?.response?.data?.message || 'Failed to retrieve campaigns.',
    })
    return []
  })
}
