export const GET_ALL_TAGS_START = 'GET_ALL_TAGS_START'
export const GET_ALL_TAGS_SUCCEED = 'GET_ALL_TAGS_SUCCEED'
export const GET_ALL_TAGS_FAIL = 'GET_ALL_TAGS_FAIL'
export const GET_ALL_TAGS_FOR_CAMPAIGN_START = 'GET_ALL_TAGS_FOR_CAMPAIGN_START'
export const GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED = 'GET_ALL_TAGS_FOR_CAMPAIGN_SUCCEED'
export const GET_ALL_TAGS_FOR_CAMPAIGN_FAIL = 'GET_ALL_TAGS_FOR_CAMPAIGN_FAIL'
export const REMOVE_TAGS_FROM_CAMPAIGN_START = 'REMOVE_TAGS_FROM_CAMPAIGN_START'
export const REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED = 'REMOVE_TAGS_FROM_CAMPAIGN_SUCCEED'
export const REMOVE_TAGS_FROM_CAMPAIGN_FAIL = 'REMOVE_TAGS_FROM_CAMPAIGN_FAIL'
export const GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED = 'GET_BULK_TAGS_FOR_CAMPAIGNS_SUCCEED'
export const DELETE_TAG_SUCCEED = 'DELETE_TAG_SUCCEED'
export const CREATE_TAGS_FOR_CAMPAIGNS = 'CREATE_TAGS_FOR_CAMPAIGNS'
export const SELECT_TAGS = 'SELECT_TAGS'
