import { callGet, callPost } from '../../services/axios'

export const getCampaignColumnSettings = (accessToken) => () => {
  return callGet('/columnSetting/getCampaignColumnSettings', accessToken).then(res => res.data)
}

export const createCampaignColumnSetting = (accessToken, name, setting) => () => {
  return callPost('/columnSetting/createCampaignColumnSetting', {
    name,
    setting,
  }, accessToken).then(res => res.data)
}

export const updateCampaignColumnSetting = (accessToken, settingId, setting) => () => {
  return callPost('/columnSetting/updateCampaignColumnSetting', {
    id: settingId,
    setting,
  }, accessToken)
}

export const deleteCampaignColumnSetting = (accessToken, id) => () => {
  return callPost(`/columnSetting/deleteCampaignColumnSetting/${id}`, {}, accessToken)
}
