import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeViewSectionState } from '../../redux/actions/pageGlobal'

const withReportSection = (Component, {
  sectionId,
  reportSectionKey,
}) => {
  return (props) => {
    const dispatch = useDispatch()

    const reportSectionSetting = useSelector(state => state.pageGlobal.reportSectionSetting)

    const [showSection, setShowSection] = useState(false)
    const [noteWidgetView, setNoteWidgetView] = useState(true)

    useEffect(() => {
      setShowSection(reportSectionSetting ? reportSectionSetting[reportSectionKey].show : true)
      setNoteWidgetView(reportSectionSetting ? reportSectionSetting[reportSectionKey].note : true)
    }, [reportSectionSetting])

    const onToggle = () => {
      dispatch(changeViewSectionState(reportSectionKey))
      setShowSection(p => !p)
    }

    return (
      <div
        id={sectionId}
        className="account-overview-section"
      >
        <Component
          {...props}
          showSection={showSection}
          noteWidgetView={noteWidgetView}
          setNoteWidgetView={setNoteWidgetView}
          onToggle={onToggle}
        />
      </div>
    )
  }
}

export default withReportSection
