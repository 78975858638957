import { callGet } from '../../services/axios'

export const getCategories = (accessToken, signal) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()
  return callGet('/targeting/getCategories', accessToken, {
    userId: currentUserId,
  }, signal).then((response) => {
    return response.data
  }).catch(() => {
    return []
  })
}

// Get products by search
export const searchProduct = (accessToken, params) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/targeting/searchProduct', accessToken, {
    ...params,
    userId: currentUserId,
  }).then((response) => {
    return response.data
  })
}

// Get Brand Recommandations
export const getBrandRecommendations = (accessToken, params) => (dispatch, getState) => {
  const { header: { currentUserId } } = getState()

  return callGet('/targeting/getBrandRecommendations', accessToken, {
    ...params,
    userId: currentUserId,
  }).then((response) => {
    return response.data
  })
}
