import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import { formatInTimeZone } from 'date-fns-tz'
import Select from 'react-select'
import { FiPlus, FiTrash2 } from 'react-icons/fi'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import ChartOverview from './ChartOverview'

import { selectCurrentAccount, selectIsNonEndemicAccount } from '../../redux/reducers/header'

import { formatCurrency, formatValue, getLogDescription } from '../../services/helper'

import { dashboardChartMetricList, campaignChartMetricList } from '../../utils/defaultValues'

const CHART_METRIC_SETTING = 'DASHBOARD_CHART_METRICS'
const METRIC_LIMIT = 6

const DashboardChartComponent = ({ isLoading, isOverview, isLogView = false,
  salesStats = null, stats }) => {
  const currentAccount = useSelector(selectCurrentAccount)
  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const [chartData, setChartData] = useState([])
  const [chartMetrics, setChartMetrics] = useState([])
  const [isClickedAdd, setIsClickedAdd] = useState(false)

  const visibility = {}
  const metricList = isOverview ? dashboardChartMetricList : campaignChartMetricList
  metricList.forEach((metric) => {
    // By default, turn off line chart for impressions.
    visibility[metric.key] = metric.key !== 'impressions'
  })

  const [ metricVisibility, setMetricVisibility ] = useState(visibility)

  useEffect(() => {
    loadSavedMetrics()
  }, []) // eslint-disable-line

  const loadSavedMetrics = () => {
    const savedMetrics = window.localStorage.getItem(CHART_METRIC_SETTING)
    let metrics = []
    if (savedMetrics) {
      try {
        metrics = JSON.parse(savedMetrics)
      } catch (error) {
        // keep silence
      }
    } else {
      metrics = metricList.slice(0, METRIC_LIMIT)
    }

    if (!isNonEndemicAccount) {
      setChartMetrics(metrics)
    } else {
      setChartMetrics(metrics.filter(metric =>
        ![
          'organic',
          'revenue',
          'acos',
          'orders',
          'conversion',
          'roas',
          'trueAcos',
        ].includes(metric.key)
      ))
    }
  }

  useEffect(() => {
    loadChartData()
  }, [stats, currencyRate]) //eslint-disable-line

  const loadChartData = () => {
    if (!stats) {
      return
    }

    setChartData(stats.charts.map(record => Object.assign({}, record, {
      revenue: record.revenue * currencyRate,
      cost: record.cost * currencyRate,
      acos: record.revenue ? record.cost / record.revenue * 100 : 0,
      roas: record.cost ? record.revenue / record.cost : 0,
      cpc: record.clicks ? record.cost / record.clicks : 0,
      conversion: record.clicks ? record.orders / record.clicks * 100 : 0,
      organic: record.sales ? (record.sales - record.revenue || 0) * currencyRate : 0,
      trueAcos: record.sales ? (record.cost / record.sales) * 100 : 0,
    })))
  }

  const handleMetricToggle = key => () => {
    const visibility = Object.assign({}, metricVisibility, {
      [key]: !metricVisibility[key]
    })
    setMetricVisibility(visibility)
  }

  const handleClickCreateMetric = () => {
    setIsClickedAdd(true)
  }

  const handleDeleteMetric = (index) => {
    let newChartMetrics = [...chartMetrics].filter((metric, ind) => ind !== index)
    setChartMetrics(newChartMetrics)
    window.localStorage.setItem(CHART_METRIC_SETTING, JSON.stringify(newChartMetrics))
  }

  const handleMetricAdd = (metric) => {
    const newChartMetrics = [...chartMetrics]
    const length = newChartMetrics.length
    const selectedMetric = metricList.find(item => item.key === metric.value)
    metricVisibility[selectedMetric.key] = false
    newChartMetrics[length] = selectedMetric
    setChartMetrics(newChartMetrics)
    setIsClickedAdd(false)
    window.localStorage.setItem(CHART_METRIC_SETTING, JSON.stringify(newChartMetrics))
  }

  const formatter = (value, name) => {
    if (name === 'PPC Revenue' || name === 'Ad Spend' || name === 'Organic Revenue') {
      return formatCurrency(value, currencySign, currencyRate)
    }
    if (name === 'ACoS %') {
      return formatValue(value, 'percent', 1)
    }
    if (name === 'Conv %') {
      return formatValue(value, 'percent', 2)
    }
    if (name === 'ROAS' || name === 'Ave CPC') {
      return formatValue(value, 'number')
    }
    return formatValue(value, 'number', 0)
  }

  const tickFormatter = (ts) => {
    if (!ts || isNaN(ts)) {
      return ''
    }
    return formatInTimeZone(parseInt(ts, 10), 'UTC', 'MM/dd')
  }

  const renderMetricSelector = () => {
    if (!isClickedAdd) {
      return null
    }

    let options = metricList.filter(metric =>
      chartMetrics.findIndex(item => item.key === metric.key) === -1
    ).map(metric => ({
      label: metric.name,
      value: metric.key,
    }))

    if (isNonEndemicAccount) {
      options = options.filter(option =>
        ![
          'organic',
          'revenue',
          'acos',
          'orders',
          'conversion',
          'roas',
          'trueAcos',
        ].includes(option.value)
      )
    }

    return (
      <li className="metric-selector-container">
        <Select
          className="metric-selector"
          classNamePrefix="metric-selector"
          options={options}
          onChange={handleMetricAdd}
        />
      </li>
    )
  }

  const renderLegend = ({ payload }) => (
    <ul className="legend-list">
      {
        payload.map((entry, index) => {
          const style = {
            borderColor: entry.payload.stroke,
          }

          if (metricVisibility[entry.dataKey]) {
            style.backgroundColor = entry.payload.stroke
          }

          return (
            <li key={entry.dataKey}>
              <button onClick={handleMetricToggle(entry.dataKey)}>
                <span className="bullet" style={style} />
                { entry.value }
              </button>
              <FiTrash2 onClick={() => { handleDeleteMetric(index) }}/>
            </li>
          )
        })
      }
      { renderMetricSelector() }
      <li className="add-action">
        <FiPlus
          title="Add Metric"
          onClick={handleClickCreateMetric}
        />
      </li>
    </ul>
  )

  const renderLabelFormatter = (ts) => {
    if (!ts || isNaN(ts)) {
      return ''
    }

    const d = formatInTimeZone(parseInt(ts, 10), 'UTC', 'yyyy/MM/dd')

    if (!isLogView || (stats?.logs || []).length === 0) {
      return d
    }

    const logs = stats.logs.filter(l =>
      formatInTimeZone(l.created_at, 'UTC', 'yyyy/MM/dd') === d
    )
    if (!logs.length) {
      return d
    }

    const logElements = logs.map(l => (
      <p key={l.id} className="log-item">
        { getLogDescription(l.log_type, l.description) }
      </p>
    ))
    return (
      <>
        <strong>
          { d }
        </strong>
        { logElements }
      </>
    )
  }

  const renderDot = (props, metric) => {
    const { cx, cy, payload } = props
    const dt = formatInTimeZone(parseInt(payload.date, 10), 'UTC', 'yyyy/MM/dd')

    let hasLog = false
    if ((stats?.logs || []).length > 0) {
      hasLog = stats.logs.findIndex(l =>
        formatInTimeZone(l.created_at, 'UTC', 'yyyy/MM/dd') === dt
      ) !== -1
    }

    return (
      <circle
        key={`${metric.key}-${dt}`}
        cx={cx}
        cy={cy}
        r={(isLogView && hasLog) ? 6 : 3}
        fill={metric.color}
      />
    )
  }

  const showOverview = isOverview && salesStats && !isNonEndemicAccount

  return (
    <div className={`dashboard-chart-component${isLoading ? ' loading' : ''}`}>
      { isLoading && <LoaderComponent/> }
      <div
        className="chart-area"
        style={{
          width: showOverview ? 'calc(100% - 250px)' : '100%',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={300}
            height={300}
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: -25,
              bottom: 20,
            }}
          >
            <XAxis
              dataKey="date"
              tickFormatter={tickFormatter}
            />
            <YAxis />
            <Tooltip
              labelFormatter={renderLabelFormatter}
              formatter={formatter}
            />
            {
              chartMetrics.map(metric => (
                metric && <Line
                  key={metric.key}
                  type="monotone"
                  name={metric.name}
                  dataKey={metric.key}
                  stroke={metric.color}
                  strokeWidth={2}
                  dot={(props) => renderDot(props, metric)}
                  activeDot={true}
                  hide={!metricVisibility[metric.key]}
                />
              ))
            }
            <Legend
              content={renderLegend}
              wrapperStyle={{
                bottom: -5,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      {
        showOverview && (
          <ChartOverview
            salesStats={salesStats}
            hasSpCode={currentAccount?.hasSpCode}
            currencySign={currencySign}
            currencyRate={currencyRate}
          />
        )
      }
    </div>
  )
}

export default DashboardChartComponent
