import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'rsuite'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth0 } from '@auth0/auth0-react'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

import { STRIPE_PUB_KEY } from '../../config/api'
import { updateCard, getStripeSecret } from '../../redux/actions/auth'

const CardModal = ({ show, onHide }) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const { getAccessTokenSilently } = useAuth0()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  const handleUpdate = async () => {
    if (!firstName) {
      toast.show({
        title: 'Danger',
        description: 'Enter your first name.',
      })
      return
    }

    if (!lastName) {
      toast.show({
        title: 'Danger',
        description: 'Enter your last name.',
      })
      return
    }

    setIsUpdating(true)

    const cardElement = elements.getElement(CardElement)

    const { token, error } = await stripe.createToken(cardElement, {
      name: `${firstName} ${lastName}`,
    })

    if (error) {
      setIsUpdating(false)
      toast.show({
        title: 'Danger',
        description: error.message,
      })
      return
    }

    const secret = await dispatch(getStripeSecret())
    const result = await stripe.confirmCardPayment(secret.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result && result.error) {
      setIsUpdating(false)
      toast.show({
        title: 'Danger',
        description: result.error.message,
      })
      return
    }

    const accessToken = await getAccessTokenSilently()
    dispatch(updateCard(accessToken, token.id)).then((response) => {
      setIsUpdating(false)
      toast.show({
        title: 'Success',
        description: 'A new card successfully added.',
      })

      onHide(response)
    }).catch((error) => {
      setIsUpdating(false)
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to add a new card.',
      })
    })
  }

  return (
    <Modal className="settings-card-modal" backdrop="static" show={show} size="xs">
      <Modal.Header onHide={() => { onHide() }}>
        <Modal.Title>
          Update Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { isUpdating && <LoaderComponent /> }
        <div className="field-wrapper">
          <label>First Name <span className="required">*</span></label>
          <input
            type="text"
            value={firstName}
            onChange={(event) => { setFirstName(event.target.value) }}
          />
        </div>
        <div className="field-wrapper">
          <label>Last Name <span className="required">*</span></label>
          <input
            type="text"
            value={lastName}
            onChange={(event) => { setLastName(event.target.value) }}
          />
        </div>
        <CardElement
          className="stripe-wrapper"
          options={{
            iconStyle: 'solid',
            style: {
              base: {
                fontSize: '14px',
              },
            },
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isUpdating}
          onClick={handleUpdate}
        >
          Update
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onHide() }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const WrapperWithStripe = (props) => {
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    setStripePromise(loadStripe(STRIPE_PUB_KEY))
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <CardModal {...props} />
    </Elements>
  )
}

export default WrapperWithStripe
