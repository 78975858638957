import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import PaginationComponent from '../CommonComponents/PaginationComponent'
import ColumnEditor from '../CommonComponents/ColumnEditor'
import LoaderComponent from '../CommonComponents/LoaderComponent'
import TableFilter from '../CommonComponents/TableFilter'
import TableFilterShower from '../CommonComponents/TableFilterShower'

import ActionBar from './ActionBar'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableFooter from './TableFooter'

import { applyProductColumnChanges } from '../../redux/actions/pageGlobal'

import {
  getProducts,
  updateProductCog,
  // getProductKeywords,
} from '../../redux/actions/product'

import {
  tableSorter,
  matchFilter,
} from '../../services/helper'

import { productColumnList } from '../../utils/defaultValues'

import filterDef from '../../utils/filterDef'
import { selectCurrentAccount } from '../../redux/reducers/header'

const columns = [
  // { key: 'cog', name: 'Cost of Goods' },
  // { key: 'profit_margin', name: 'Profit Margin' },
  // {
  //   key: 'break_even_cpa',
  //   name: (<span>Break Even<br/> CPA</span>),
  //   className: 'break-even',
  //   tooltip: (
  //     <p>
  //       Break Even Cost per Acquisition is the amount of money
  //       you can spend to get a sale while still breaking even.
  //     </p>
  //   )
  // },
  {
    key: 'clicks_order_ratio',
    name: (<span>Clicks /<br/> Orders</span>),
    tooltip: (
      <p>
        This measures how many clicks it takes on average to get an order.
      </p>
    )
  },
  // {
  //   key: 'max_cpc',
  //   name: (<span>Max<br/> CPC</span>),
  //   tooltip: (
  //     <>
  //       <p>The maximum one can spend per click to break even with PPC.</p>
  //       <p>
  //         Takes into account the products profit margins
  //         and the average amount of clicks to get an order.
  //       </p>
  //     </>
  //   )
  // },
  { key: 'active_campaigns', name: 'Active Campaigns' },
  // { key: 'true_acos', name: 'TACOS %'},
  { key: 'revenue', name: 'PPC Sales' },
  // {
  //   key: 'sales',
  //   name: (
  //     <>
  //       <span>Organic</span>
  //       <span>PPC Sales</span>
  //     </>
  //   ),
  //   className: 'organic-sales',
  // },
  // {
  //   key: 'sales_ratio',
  //   name: (
  //     <>
  //       <span>Sales Ratio %</span>
  //       <span>Organic/PPC</span>
  //     </>
  //   ),
  //   className: 'organic-sales-ratio',
  // },
  {
    key: 'ad_spend_margin',
    name: (<span>Ad Spend<br/> Margin Impact</span>),
    className: 'ad-spend-margin',
    tooltip: (
      <>
        <p>Also known as TACOS.</p>
        <p>
          The amount of margin impact that your ad dollars are having
          on this products profitability (does not include SB ad spend)
        </p>
      </>
    )
  },
  { key: 'clicks', name: 'Clicks' },
  { key: 'ctr', name: 'CTR %' },
  { key: 'cost', name: 'Spend' },
  { key: 'orders', name: 'Orders' },
  { key: 'acos', name: 'ACoS %' },
]

const columnsToExport = [
  'name', 'sku', 'asin', 'total_sale', 'sales', 'total_quantity',
  'total_tax', 'total_fee', 'fee', 'break_even_cpa', 'revenue', 'ppcRevenue',
  'cost', 'impressions', 'clicks', 'total_clicks', 'orders', 'clicks_order_ratio',
  'max_cpc', 'abtest_status', 'active_campaigns', 'active_sp_campaigns',
  'acos', 'sales_ratio', 'ad_spend_margin', 'ctr'
];

const ProductTableComponent = ({ fromAccountReport }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currentUserId = useSelector(state => state.header.currentUserId)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const currentStartDate = useSelector(state => state.header.currentStartDate)
  const currentEndDate = useSelector(state => state.header.currentEndDate)

  const showColumnEditor = useSelector(state => state.pageGlobal.showColumnEditor)
  const visibleColumnEditorId = useSelector(state => state.pageGlobal.visibleColumnEditorId)
  const visibleFilterName = useSelector(state => state.pageGlobal.visibleFilterName)
  const productTableColumns = useSelector(state => state.pageGlobal.productTableColumns)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)

  const productList = useSelector(state => state.product.productList)
  const isUpdateProductCog = useSelector(state => state.product.isUpdateProductCog)
  // const isLoadingProductKeywords = useSelector(state => state.product.isLoadingProductKeywords)
  // const productKeywords = useSelector(state => state.product.productKeywords)

  const [isLoading, setIsLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [searchInclusive, setSearchInclusive] = useState(true)
  const [pageStartNum, setPageStartNum] = useState(0)
  const [pageEndNum, setPageEndNum] = useState(10)
  const [sortColumnName, setSortColumnName] = useState('cost')
  const [sortDirection, setSortDirection] = useState('desc')

  // const [expandedId, setExpandedId] = useState()
  const [isSticky, setIsSticky] = useState(false)
  const [topPos, setTopPos] = useState(0)

  const refHeader = useRef(null)
  const refFooter = useRef(null)
  const refProductCol = useRef()
  const refTable = useRef(null)

  const [tableHeight, setTableHeight] = useState('auto')
  const [resizing, setResizing] = useState(false)

  const [selectedProducts, setSelectedProducts] = useState([])

  const productColIndex = 0
  const productColMinWidth = 240

  useEffect(() => {
    setTableHeight(refTable.current.offsetHeight)
  }, [])

  const handleMouseMove = useCallback(
    (e) => {
      const leftPos = refProductCol.current.getBoundingClientRect().left
      const width = e.clientX - leftPos >= productColMinWidth
        ? e.clientX - leftPos
        : refProductCol.current.offsetWidth
      refProductCol.current.style.minWidth = `${width}px`
      let rowElement = refProductCol.current.parentNode
      while (rowElement) {
        rowElement.childNodes[productColIndex].style.minWidth = `${width}px`
        rowElement = rowElement.nextSibling
      }
    },
    []
  )

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', removeListeners)
  }, [handleMouseMove])

  const handleMouseUp = useCallback(() => {
    setResizing(false)
    removeListeners()
  }, [setResizing, removeListeners])

  useEffect(() => {
    if (resizing) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      removeListeners()
    }
  }, [resizing, handleMouseMove, handleMouseUp, removeListeners])

  useEffect(() => {
    const mainContent = document.querySelector('.main-content')
    mainContent.addEventListener('scroll', handleScroll)

    return () => {
      mainContent.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!isSticky || !topPos) {
      return
    }
    refHeader.current.style.top = `${topPos}px`
    refFooter.current.style.top = `${topPos + refHeader.current.clientHeight}px`
  }, [isSticky, topPos])

  useEffect(() => {
    let abortCtrl
    if (currentUserId && !fromAccountReport) {
      abortCtrl = new AbortController();
      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        await dispatch(getProducts(
          accessToken,
          undefined,
          undefined,
          abortCtrl.signal,
        ))
        setIsLoading(false)
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [currentUserId, currentStartDate, currentEndDate]) // eslint-disable-line

  const setProductColWidth = (width = productColMinWidth) => {
    refProductCol.current.style.minWidth = `${width}px`
    let rowElement = refProductCol.current.parentNode
    while (rowElement) {
      rowElement.childNodes[productColIndex].style.minWidth = `${width}px`
      rowElement = rowElement.nextSibling
    }
  }

  const handleExpandProductName = () => {
    let width = productColMinWidth
    const rows = document.getElementsByClassName('table-row')
    for (let index = 0; index < rows.length; index += 1) {
      const productCol = rows[index].childNodes[productColIndex].getElementsByTagName('button')[0]
      if (productCol) {
        width = Math.max(width, productCol.scrollWidth)
      }
    }

    setProductColWidth(width)
  }

  const handleCollapseProductName = () => {
    setProductColWidth()
  }

  const handleScroll = () => {
    setIsSticky(false)
    if (refHeader.current) {
      const { top } = refHeader.current.getBoundingClientRect()
      if (top <= 0) {
        setIsSticky(true)
        setTopPos(-top)
      }
    }
  }

  const loadProducts = (pageNum, pageRows) => {
    if (pageRows !== 'all') {
      setPageStartNum((pageNum - 1) * pageRows)
      setPageEndNum(pageNum * pageRows - 1)
    } else {
      setPageStartNum(0)
      setPageEndNum(products.length)
    }
  }

  const sortColumn = (field) => {
    setSortDirection(
      sortColumnName === field && sortDirection === 'asc'
      ? 'desc'
      : 'asc'
    )
    setSortColumnName(field)
  }

  let products = useMemo(() => {
    // const euList = ['gb', 'fr', 'de', 'es', 'it', 'nl', 'in', 'ae', 'se', 'pl', 'tr', 'eg', 'sa', 'be']
    // const isInEU = euList.includes(currentAccount?.country_id || 'us')

    const lowerCasedKey = searchKey.toLowerCase()

    return productList.filter((record) => {
      record.acos = record.revenue
        ? record.cost / record.revenue * 100
        : 0
      record.sales_ratio = record.sales
        ? (record.sales - record.revenue) / record.sales * 100
        : 0
      record.ad_spend_margin = record.sales
        ? record.cost / record.sales * 100
        : 0
      record.ctr = parseInt(record.impressions, 10)
        ? parseInt(record.clicks, 10) / parseInt(record.impressions, 10) * 100
        : 0

      record.total_quantity = parseInt(record.total_quantity || 0, 10)
      // record.profit_margin = 0
      // if (record.total_quantity) {
      //   let sales = parseFloat(record.total_sale || 0)
      //   if (isInEU) {
      //     // In EU, sales do not contain VAT.
      //     sales += parseFloat(record.total_tax || 0)
      //   }
      //   record.profit_margin =
      //     (sales - parseFloat(record.total_fee || 0))
      //     / record.total_quantity - parseFloat(record.cog || 0)
      // }

      if (
        lowerCasedKey
        && (
          (
            searchInclusive
            && !(record.name || '').toLowerCase().includes(lowerCasedKey)
            && !(record.sku || '').toLowerCase().includes(lowerCasedKey)
            && !(record.asin || '').toLowerCase().includes(lowerCasedKey)
          )
          ||
          (
            !searchInclusive
            && (
              (record.name || '').toLowerCase().includes(lowerCasedKey)
              || (record.sku || '').toLowerCase().includes(lowerCasedKey)
              || (record.asin || '').toLowerCase().includes(lowerCasedKey)
            )
          )
        )
      ) {
        return false
      }

      if (!matchFilter(
        record,
        filterDef.productTable,
        (filterValues || {}).productTable || {}
      )) {
        return false
      }

      return true
    })
  }, [productList, searchKey, searchInclusive, filterValues])

  products = useMemo(() => (
    tableSorter(['campaign'])
    (products, [sortColumnName, sortDirection])
  ), [products, sortColumnName, sortDirection])

  const productsToExport = useMemo(() => {
    return products.map(p => {
      const extractedObj = {};
      columnsToExport.forEach(key => {
        if (p.hasOwnProperty(key)) {
          extractedObj[key] = p[key];
        }
      });
      return extractedObj;
    })
  }, [products]);

  const handleSelect = (checked, product) => {
    if (checked) {
      setSelectedProducts(prev => ([...prev, product]))
    } else {
      setSelectedProducts(selectedProducts.filter(item => item.id !== product.id))
    }
  }

  const handleSelectToggle = (checked) => {
    setSelectedProducts(checked ? products : [])
  }

  const handleCogSave = async (id, cog) => {
    const accessToken = await getAccessTokenSilently()
    dispatch(updateProductCog(accessToken, id, cog))
  }

  // const onExpandProduct = (id = undefined) => {
  //   setExpandedId(id)
  //   if (id) {
  //     @todo: Pass access token.
  //     dispatch(getProductKeywords(id))
  //   }
  // }
  const currentColumnSelection = useMemo(() => (
    productTableColumns.filter(col => col.key !== 'sales'
      && col.key !== 'sales_ratio'
      && col.key !== 'cog'
    )
  ), [productTableColumns])

  const productElements = products.slice(pageStartNum, pageEndNum + 1).map(product => (
    <TableRow
      key={product.id}
      product={product}
      // productKeywords={productKeywords}
      // isLoadingProductKeywords={isLoadingProductKeywords}
      selectedColumns={productTableColumns}
      currentAccount={currentAccount}
      selectedProducts={selectedProducts}
      currencySign={currencySign}
      currencyRate={currencyRate}
      // onExpand={onExpandProduct}
      onSelect={handleSelect}
      onSaveCog={handleCogSave}
    />
  ))

  const inProgress = isLoading || isUpdateProductCog

  return (
    <div className={`product-table-component${inProgress ? ' loading' : ''}`}>
      { inProgress && <LoaderComponent />}
      {
        showColumnEditor && visibleColumnEditorId === '' &&
        <ColumnEditor
          columnList={productColumnList}
          currentSelection={currentColumnSelection}
          onApply={applyProductColumnChanges}
        />
      }
      {
        visibleFilterName === 'productTable' &&
        <TableFilter filterName="productTable" />
      }
      <TableFilterShower filterName="productTable" />
      <ActionBar
        dataToExport={productsToExport}
        searchKey={searchKey}
        searchInclusive={searchInclusive}
        selectedProducts={selectedProducts}
        fromAccountReport={fromAccountReport}
        onSearchKeyChange={setSearchKey}
        onChangeSearchInclusive={setSearchInclusive}
      />
      <div className="table-body" ref={refTable}>
        <TableHeader
          columns={columns}
          tableColumns={productTableColumns}
          isSticky={isSticky}
          refHeader={refHeader}
          refProductCol={refProductCol}
          tableHeight={tableHeight}
          resizing={resizing}
          isSelected={products.length > 0 && products.length === selectedProducts.length}
          onSort={sortColumn}
          onExpandProductName={handleExpandProductName}
          onCollapseProductName={handleCollapseProductName}
          onResize={setResizing}
          onSelect={handleSelectToggle}
        />
        <TableFooter
          columns={columns}
          tableColumns={productTableColumns}
          products={products}
          isSticky={isSticky}
          refFooter={refFooter}
          currencyRate={currencyRate}
          currencySign={currencySign}
        />
        { productElements }
      </div>
      <PaginationComponent
        total={products.length}
        loadData={loadProducts}
      />
    </div>
  )
}

export default ProductTableComponent
