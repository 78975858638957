import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Toggle } from 'rsuite'
import * as Icon from 'react-icons/fi'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../CommonComponents/SortableTableComponent'
import GroupTable from '../CommonComponents/GroupTableComponent'
import { toast } from '../CommonComponents/ToastComponent/toast'
import TableCell from '../CommonComponents/TableCell'
import TableCampaignCell from '../CommonComponents/TableCampaignCell'
import TableFilterModal from '../CommonComponents/TableFilterModal'
import BidAdjustComponent from '../CommonComponents/BidAdjustComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import GeniusBidCell from '../GeniusBidCell'

import {
  FILTER_NAME_TARGET_OP,
  FILTER_NAME_ADVANCED_OP,
} from './TargetOpResult'

import {
  updateTargetStates,
  updateTargetBids,
  getAsinImages,
} from '../../redux/actions/bulkEngine'
import { monitorJob } from '../../redux/actions/job'
import { selectCurrentAccount } from '../../redux/reducers/header'

import {
  formatCurrency,
  formatValue,
  tableSorter,
  calcDerivedMetrics,
  calcMaxCpc,
  capitalizeFirstLetter,
  parseTargetExp,
  getExportValueForColumn,
  groupRecords,
  getAmazonCategoryLink,
  getAmazonAsinLink,
  copyToClipboard,
  calcNewBid,
} from '../../services/helper'

import {
  bulkBidColumnList,
  adjustBidOptions,
  MODULE_NAME_TARGET_OP,
  BULK_ACTION_NO_IMPRESSION,
  JOB_TYPE_BULK_UPDATE_TARGET_STATES,
  JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
} from '../../utils/defaultValues'

import { keywordStatuses } from '../../utils/filterDef'

const TargetOpTargetTable = ({ sortBy, keyMetric, activeModule, selectedStatus,
  campaignsById, adgroupNamesById, isNonEndemicAccount, onChangeDate, onSelectStatus }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentAccount = useSelector(selectCurrentAccount)
  const currencySign = useSelector(state => state.header.currencySign)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const campaignTableColumns = useSelector(state => state.pageGlobal.campaignTableColumns)

  const targetOpData = useSelector(state => state.bulkEngine.targetOpData)

  const [groupMode, setGroupMode] = useState(false)
  const [targets, setTargets] = useState([])
  const [groupedTargets, setGroupedTargets] = useState([])
  const [selectedTargets, setSelectedTargets] = useState([])
  const [removeZeroSpend, setRemoveZeroSpend] = useState(true)
  const [isShowAdjustBid, setIsShowAdjustBid] = useState(false)
  const [selectedAdjustBidOption, setSelectedAdjustBidOption] = useState(adjustBidOptions[0])
  const [bidValue, setBidValue] = useState(0)
  const [currentFilterName, setCurrentFilterName] = useState('')
  const [isUpdatingStates, setIsUpdatingStates] = useState(false)
  const [isUpdatingBids, setIsUpdatingBids] = useState(false)
  const [isGettingImages, setIsGettingImages] = useState(false)

  useEffect(() => {
    const extendedTargets = []; // semi-colon is required here.
    ((targetOpData || {}).targets || []).forEach((record) => {
      if (activeModule !== BULK_ACTION_NO_IMPRESSION
        && removeZeroSpend
        && parseFloat(record.cost || 0) === 0) {
        return
      }

      if (selectedStatus.value !== '') {
        if ((record.state || '').toLowerCase() !== selectedStatus.value) {
          return
        }
      }

      const derived = calcDerivedMetrics(record)

      const campaignDetail = campaignsById[record.campaign_id] || {}

      const campaignType = campaignDetail.type || ''

      let adType
      if (campaignType === 'sb') {
        adType = campaignDetail.videoAdgroupIds.includes(
          record.adgroup_id.toString()
        ) ? 'sbv' : campaignType
      } else {
        adType = campaignType
      }

      extendedTargets.push({
        ...derived,
        campaignName: campaignDetail.name || '',
        campaignType,
        adType,
        targetingType: campaignDetail.targetingType || '',
        costType: campaignDetail.costType || '',
        bidding: campaignDetail.bidding,
        adgroupName: adgroupNamesById[record.adgroup_id] || '',
        maxCpc: calcMaxCpc(
          derived,
          adType,
          currentAccount?.country_id,
          campaignDetail.costType || '',
        ),
      })
    })

    setTargets(extendedTargets)
  }, [targetOpData, removeZeroSpend, campaignsById,
    adgroupNamesById, activeModule, selectedStatus, currentAccount?.country_id])

  useEffect(() => {
    if (groupMode) {
      setGroupedTargets(
        groupRecords(
          targets,
          'campaign_id',
          ['campaignName', 'campaignType', 'targetingType']
        )
      )
    } else {
      setGroupedTargets([])
    }
  }, [targets, groupMode])

  const [columns, columnsGroup, columnSelection] = useMemo(() => {
    let columns = [
      { key: 'target_text', name: 'Target', className: 'col-target' },
      { key: 'campaignName', name: 'Campaign', className: 'col-campaign' },
      { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
    ]

    let columnsGroup = [
      { key: 'campaignName', name: 'Campaign', className: 'col-campaign', parentOnly: true },
      { key: 'checkPlaceholder', name: '', className: 'col-check', exportable: false, parentOnly: true },
      { key: 'target_text', name: 'Target', className: 'col-target' },
      { key: 'adgroupName', name: 'Ad Group', className: 'col-adgroup' },
    ]

    let columnSelection = campaignTableColumns.filter(c1 =>
      bulkBidColumnList.find(c2 => c2.key === c1.key)
    )

    if (!keyMetric) {
      const metricColumns = [
        { key: 'bid', name: 'Current Bid' },
        {
          key: 'maxCpc',
          name: (
            <span>
              Genius Bid
              <CustomTooltip placement="right">
                <p>A minimum of 3 clicks is required before we suggest a “Genius Bid”.</p>
              </CustomTooltip>
            </span>
          ),
          plainName: 'Genius Bid',
          className: 'col-genius-bid',
        },
        ...columnSelection,
      ]

      columns = columns.concat(metricColumns)
      columnsGroup = columnsGroup.concat(metricColumns)
    } else {
      const keyColumn = bulkBidColumnList.find(c => c.key === keyMetric)

      const metricColumns = [
        keyColumn,
        { key: 'bid', name: 'Current Bid' },
        {
          key: 'maxCpc',
          name: (
            <span>
              Genius Bid
              <CustomTooltip placement="right">
                <p>A minimum of 3 clicks is required before we suggest a “Genius Bid”.</p>
              </CustomTooltip>
            </span>
          ),
          plainName: 'Genius Bid',
          className: 'col-genius-bid',
        },
        ...columnSelection.filter(c => c.key !== keyMetric),
      ]

      columns = columns.concat(metricColumns)
      columnsGroup = columnsGroup.concat(metricColumns)
    }

    if (isNonEndemicAccount) {
      const columnsToExclude = [
        'revenue',
        'acos',
        'orders',
        'conversion',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
        'maxCpc',
      ]

      columns = columns.filter(column => (
        !columnsToExclude.includes(column.key)
      ))

      columnsGroup = columnsGroup.filter(column => (
        !columnsToExclude.includes(column.key)
      ))

      columnSelection = columnSelection.filter(column => (
        !columnsToExclude.includes(column.key)
      ))
    }

    return [columns, columnsGroup, columnSelection]
  }, [campaignTableColumns, keyMetric, isNonEndemicAccount])

  const handleChangeState = async (state) => {
    const targetsChanged = targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).map(record => ({
      campaignId: record.campaign_id,
      campaignType: record.campaignType,
      adGroupId: record.adgroup_id,
      targetId: record.target_id,
      // Below information are used for logging in backend.
      adgroupName: record.adgroupName,
      target_text: record.target_text,
      originalState: record.state,
    }))

    if (!targetsChanged.length) {
      return
    }

    setIsUpdatingStates(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateTargetStates(
      accessToken,
      targetsChanged,
      state,
      false,
    ))
    setIsUpdatingStates(false)

    if (response) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_TARGET_STATES,
        state,
      ))
    }
  }

  const handleChangeToMaxBid = async () => {
    let targetsToChange = []

    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1)
    ).forEach((record) => {
      if (!record.maxCpc) {
        return
      }
      targetsToChange.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        bid: parseFloat(record.maxCpc.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroupName,
        target_text: record.target_text,
        originalBid: record.bid,
      })
    })

    if (!targetsToChange.length) {
      toast.show({
        title: 'Warning',
        description: 'Selected target(s) has invalid genius bid. '
          + 'The minimum bid allowed is $0.15. Please check your targets.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateTargetBids(
      accessToken,
      targetsToChange,
      false,
    ))
    setIsUpdatingBids(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
        targetsToChange,
      ))
    }
  }

  const handleAdjustBid = async () => {
    let targetsChanged = []
    targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).forEach((record) => {
      const newBid = calcNewBid(
        record,
        selectedAdjustBidOption.value,
        bidValue,
        record.adType,
        currentAccount?.country_id,
        record.costType,
      )

      targetsChanged.push({
        campaignId: record.campaign_id,
        campaignType: record.campaignType,
        adGroupId: record.adgroup_id,
        targetId: record.target_id,
        bid: parseFloat(newBid.toFixed(2)),
        // Below information are used for logging in backend.
        adgroupName: record.adgroupName,
        target_text: record.target_text,
        originalBid: record.bid,
      })
    })

    if (!targetsChanged.length) {
      toast.show({
        title: 'Warning',
        description: 'There is no target to update.',
      })
      return
    }

    setIsUpdatingBids(true)
    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(updateTargetBids(
      accessToken,
      targetsChanged,
      false,
    ))
    setIsUpdatingBids(false)
    setIsShowAdjustBid(false)

    if (response.data) {
      dispatch(monitorJob(
        response.data.jobId,
        JOB_TYPE_BULK_UPDATE_TARGET_BIDS,
        targetsChanged,
      ))
    }
  }

  const handleRefineFilter = () => {
    setCurrentFilterName(activeModule !== '' ? FILTER_NAME_ADVANCED_OP : FILTER_NAME_TARGET_OP)
  }

  const handleCopy = () => {
    const dataToCopy = targets.filter(record => (
      selectedTargets.indexOf(record.target_id) !== -1
    )).map(record => parseTargetExp(record.target_text))

    copyToClipboard(dataToCopy.join('\n'))

    toast.show({
      title: 'Success',
      description: `Successfully copied ${dataToCopy.length} target${dataToCopy.length > 1 ? 's' : ''}.`
    })
  }

  const handleAsinImageFetch = async () => {
    const asins = [];
    (targets || []).forEach((record) => {
      const target = parseTargetExp(record.target_text)
      if (target.indexOf('asin=') === 0) {
        try {
          const parsed = JSON.parse(record.target_text)
          asins.push(parsed[0].value.toUpperCase())
        } catch (e) {
          //
        }
      }
    })

    if (asins.length) {
      setIsGettingImages(true)
      const accessToken = await getAccessTokenSilently()
      await dispatch(getAsinImages(accessToken, asins, 'targetOp'))
      setIsGettingImages(false)
    }
  }

  const renderFilter = () => {
    const hasAsin = typeof (targets || []).find((record) => {
      const target = parseTargetExp(record.target_text)
      return target.indexOf('asin=') === 0
    }) !== 'undefined'

    return (
      <div className="filter-container">
        <Toggle
          checked={groupMode}
          checkedChildren="Organize by campaigns"
          unCheckedChildren="By targets"
          onChange={setGroupMode}
        />
        {
          activeModule !== BULK_ACTION_NO_IMPRESSION && (
            <div className="checkbox-wrapper">
              <CheckboxComponent
                label="Exclude Targets with Zero Spend"
                checked={removeZeroSpend}
                onChange={setRemoveZeroSpend}
              />
            </div>
          )
        }
        <div className="select-wrapper">
          <span>Status</span>
          <Select
            classNamePrefix="status-selector"
            options={keywordStatuses}
            value={selectedStatus}
            onChange={onSelectStatus}
          />
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleRefineFilter}
          >
            Refine Filter
          </button>
        </div>
        {
          hasAsin && (
            <div className="button-wrapper">
              <button
                type="button"
                className="btn btn-blue"
                disabled={isGettingImages}
                onClick={handleAsinImageFetch}
              >
                Show ASIN Images
              </button>
            </div>
          )
        }
      </div>
    )
  }

  const renderAction = () => {
    if (!selectedTargets.length) {
      return null
    }

    if (!isShowAdjustBid) {
      const isEnableDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'enabled'
      )) === 'undefined'

      const isPauseDisabled = typeof targets.find(record => (
        selectedTargets.indexOf(record.target_id) !== -1
        && record.state.toLowerCase() !== 'paused'
      )) === 'undefined'

      return (
        <>
          <button
            type="button"
            className="btn btn-green"
            onClick={() => { handleCopy() }}
          >
            Copy
          </button>
          <button
            type="button"
            className="btn btn-green"
            disabled={isUpdatingStates || isEnableDisabled}
            onClick={() => { handleChangeState('enabled') }}
          >
            Enable
          </button>
          <button
            type="button"
            className="btn btn-red"
            disabled={isUpdatingStates || isPauseDisabled}
            onClick={() => { handleChangeState('paused') }}
          >
            Pause
          </button>
          <button
            type="button"
            className="btn btn-light-blue"
            onClick={() => { setIsShowAdjustBid(true) }}
          >
            Adjust Bid
          </button>
          {
            !isNonEndemicAccount && (
              <button
                type="button"
                className="btn btn-blue"
                disabled={isUpdatingBids}
                onClick={handleChangeToMaxBid}
              >
                Change to Genius Bid
              </button>
            )
          }
        </>
      )
    }

    return (
      <BidAdjustComponent
        adjustBidOption={selectedAdjustBidOption}
        bidValue={bidValue}
        isAdjusting={isUpdatingBids}
        onChangeAdjustBidOption={setSelectedAdjustBidOption}
        onChangeBidValue={setBidValue}
        onApply={handleAdjustBid}
        onCancel={() => { setIsShowAdjustBid(false) }}
      />
    )
  }

  const renderTargetCell = (record) => {
    const target = parseTargetExp(record.target_text)
    let link
    if (target.indexOf('asin=') === 0) {
      try {
        const parsed = JSON.parse(record.target_text)
        link = (
          <a
            href={getAmazonAsinLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon.FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        //
      }
    } else if (target.indexOf('category=') === 0) {
      try {
        const parsed = JSON.parse(record.target_exp)
        link = (
          <a
            href={getAmazonCategoryLink(currentAccount, parsed[0].value)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon.FiExternalLink size={16} />
          </a>
        )
      } catch (e) {
        //
      }
    }

    return (
      <div className="table-col col-target" title={target}>
        {
          typeof record.image !== 'undefined' && (
            <img
              src={record.image}
              alt={target}
            />
          )
        }
        <div className="target-info">
          <div className="target-text-wrapper">
            <strong>
              { target }
            </strong>
            { link }
          </div>
          <div className="meta-data">
            { capitalizeFirstLetter(record.state) }
          </div>
        </div>
      </div>
    )
  }

  const renderTarget = record => (
    <>
      { renderTargetCell(record) }
      <TableCampaignCell record={record} />
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <div className="table-col col-genius-bid">
        {
          typeof record.maxCpc !== 'undefined'
          ? formatCurrency(record.maxCpc, currencySign, currencyRate)
          : 'N/A'
        }
      </div>
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  // Render aggregation row.
  const renderTotal = summary => (
    <>
      <div className="table-col col-target">Totals:</div>
      <div className="table-col col-campaign" />
      <div className="table-col col-adgroup" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(summary.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col"></div>
      <div className="table-col col-genius-bid"></div>
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={summary}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const getExportData = (exportableColumns, record) => (
    exportableColumns.map((column) => {
      if (column.key === 'target_text') {
        return `${parseTargetExp(record.target_text)} (${capitalizeFirstLetter(record.state)})`
      }
      if (column.key === 'bid') {
        return formatCurrency(record.bid, currencySign, currencyRate)
      }
      if (column.key === 'maxCpc') {
        return typeof record.maxCpc !== 'undefined'
          ? formatCurrency(record.maxCpc, currencySign, currencyRate)
          : 'N/A'
      }
      return getExportValueForColumn(record, column.key, currencySign, currencyRate)
    })
  )

  // For grouped table.
  const renderParent = record => (
    <>
      <TableCampaignCell record={record} />
      <div className="table-col col-check" />
      <div className="table-col col-target">
        { record.children.length } targets
      </div>
      <div className="table-col col-adgroup" />
      <div className="table-col" />
      <div className="table-col col-genius-bid" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const renderChild = record => (
    <>
      { renderTargetCell(record) }
      <div className="table-col col-adgroup" title={record.adgroupName}>
        <span className="contents">
          { record.adgroupName }
        </span>
      </div>
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(record.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(record.cost, currencySign, currencyRate) }
          </div>
        )
      }
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <GeniusBidCell
        record={record}
        currencySign={currencySign}
        currencyRate={currencyRate}
      />
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={record}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const renderTotalGroup = summary => (
    <>
      <div className="table-col col-campaign">Totals:</div>
      <div className="table-col col-check" />
      <div className="table-col col-target" />
      <div className="table-col col-adgroup" />
      <div className="table-col" />
      <div className="table-col col-genius-bid" />
      {
        keyMetric === 'cpc' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cpc, currencySign, currencyRate) }
          </div>
        )
      }
      {
        keyMetric === 'conversion' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatValue(summary.conversion, 'percent') }
          </div>
        )
      }
      {
        keyMetric === 'cost' && campaignTableColumns.find(c => c.key === keyMetric) && (
          <div className="table-col">
            { formatCurrency(summary.cost, currencySign, currencyRate) }
          </div>
        )
      }
      {
        columnSelection.map((column) => {
          if (keyMetric && column.key === keyMetric) {
            return null
          }

          return (
            <TableCell
              key={column.key}
              record={summary}
              columnKey={column.key}
              columnSelection={campaignTableColumns}
              currencySign={currencySign}
              currencyRate={currencyRate}
            />
          )
        })
      }
    </>
  )

  const isLoading = isUpdatingStates || isUpdatingBids || isGettingImages

  return (
    <>
      { renderFilter() }
      {
        groupMode ? (
          <SortableTable
            tableComponent={GroupTable}
            isLoading={isLoading}
            columns={columnsGroup}
            defaultSort={sortBy}
            sorter={tableSorter(['campaignName'])}
            className="table-grouped-targets"
            records={groupedTargets}
            idField="campaign_id"
            searchFields={['target_text']}
            selectedRecords={selectedTargets}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={activeModule !== '' ? FILTER_NAME_ADVANCED_OP :  FILTER_NAME_TARGET_OP}
            useFilterModal
            columnEditorId="targetOpTargetResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_TARGET_OP}
            getExportData={getExportData}
            renderRecord={renderParent}
            renderTotal={renderTotalGroup}
            renderTopRight={renderAction}
            onChange={setSelectedTargets}
            onChangeDate={onChangeDate}
            sorterChild={tableSorter(['adgroupName', 'state', 'target_text'])}
            idFieldChild="target_id"
            renderChild={renderChild}
          />
        ) : (
          <SortableTable
            isLoading={isLoading}
            columns={columns}
            defaultSort={sortBy}
            sorter={tableSorter(['campaignName', 'adgroupName', 'state', 'target_text'])}
            className="table-targets"
            records={targets || []}
            idField="target_id"
            searchFields={['target_text']}
            selectedRecords={selectedTargets}
            hasSticky
            hasDateRange
            hasLifetimeRange
            filterName={activeModule !== '' ? FILTER_NAME_ADVANCED_OP : FILTER_NAME_TARGET_OP}
            useFilterModal
            columnEditorId="targetOpTargetResult"
            columnList={bulkBidColumnList}
            columnSelection={columnSelection}
            exportFileName={MODULE_NAME_TARGET_OP}
            getExportData={getExportData}
            renderRecord={renderTarget}
            renderTotal={renderTotal}
            renderTopRight={renderAction}
            onChange={setSelectedTargets}
            onChangeDate={onChangeDate}
          />
        )
      }
      {
        currentFilterName !== '' && (
          <TableFilterModal
            filterName={currentFilterName}
            currentModuleName={activeModule !== '' ? 'Advanced Optimization' : MODULE_NAME_TARGET_OP}
            onApply={() => { setCurrentFilterName('') }}
            onClose={() => { setCurrentFilterName('') }}
          />
        )
      }
    </>
  )
}

export default TargetOpTargetTable
