import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { getUnixTime, startOfDay, subDays } from 'date-fns'

import MainLayout from '../../layout/MainLayout'
import LoaderComponent from '../../components/CommonComponents/LoaderComponent'
import PageHeader from './PageHeader'
import Header from './Header'
import LogTable from './LogTable'

import { getActivityLog, generateLogs } from '../../redux/actions/activityLog'
import { monitorJob } from '../../redux/actions/job'

import { toast } from '../../components/CommonComponents/ToastComponent/toast'
import { filterLogs, parseDate } from '../../services/helper'

import { JOB_TYPE_ACTIVITY_LOG_DOWNLOAD } from '../../utils/defaultValues'

const TAB_ALL = 'All'
const TAB_AP = 'Smart Pilot/Rules'

const ActivityLog = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const logs = useSelector(state => state.activityLog.logs)
  const isRevertingLog = useSelector(state => state.campaignLog.isRevertingLog)
  const currentUserId = useSelector(state => state.header.currentUserId)

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(TAB_ALL)
  const [currentType, setCurrentType] = useState('')
  const [range, setRange] = useState([
    subDays(new Date(), 14),
    new Date(),
  ])

  useEffect(() => {
    const abortCtrl = new AbortController();
    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      try {
        await dispatch(getActivityLog(
          accessToken,
          range[0],
          range[1],
          abortCtrl.signal,
        ))
        setIsLoading(false)
      } catch (isCancelled) {
        if (!isCancelled) {
          setIsLoading(false)
        }
      }
    })()
    return () => {
      abortCtrl.abort()
    }
  }, [range, currentUserId]) // eslint-disable-line

  const handleTabChange = tab => (event) => {
    event.preventDefault()
    setActiveTab(tab)
  }

  const handleDownload = async () => {
    const accessToken = await getAccessTokenSilently()

    dispatch(generateLogs(
      accessToken,
      range[0],
      range[1]
    )).then((response) => {
      dispatch(monitorJob(response.data.jobId, JOB_TYPE_ACTIVITY_LOG_DOWNLOAD))
    }).catch((error) => {
      toast.show({
        title: 'Danger',
        description: error?.response?.data?.message || 'Failed to retrieve activity logs report.',
      })
    })
  }

  const filteredLogs = useMemo(() => {
    const filtered = filterLogs(logs || [], currentType)
    if (activeTab === TAB_AP) {
      return filtered.filter((log) => {
        const logType = log.log_type || ''
        return logType.indexOf('ap_') === 0
          || logType.indexOf('rule_') === 0
      })
    }
    return filtered
  }, [logs, currentType, activeTab])

  const groupedLogs = {}
  filteredLogs.forEach((log) => {
    const key = `${getUnixTime(startOfDay(parseDate(log.created_at)))}-${log.name}`
    groupedLogs[key] = (groupedLogs[key] || []).concat([log])
  })

  const flattenGroups = []
  Object.keys(groupedLogs).sort().reverse().forEach((key) => {
    flattenGroups.push({
      key,
      logs: groupedLogs[key],
    })
  })

  return (
    <MainLayout>
      <div className="activity-log">
        <PageHeader />
        <div className={`page-content ${(isLoading || isRevertingLog) ? 'loading' : ''}`}>
          { (isLoading || isRevertingLog) && <LoaderComponent /> }
          <Header
            tabList={[TAB_ALL, TAB_AP]}
            activeTab={activeTab}
            currentType={currentType}
            range={range}
            onTabChange={handleTabChange}
            onTypeChange={setCurrentType}
            onRangeChange={setRange}
            onDownload={handleDownload}
          />
          <LogTable
            logs={flattenGroups}
            isRevertingLog={isRevertingLog}
          />
        </div>
      </div>
    </MainLayout>
  )
}

export default ActivityLog
