import React, { useState, useEffect, useMemo }  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import Select from 'react-select'
import { Icon, IconButton } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import CheckboxComponent from '../CheckboxComponent'
import SkuSelector from '../SkuSelector'
import LoaderComponent from '../LoaderComponent'
import { toast } from '../ToastComponent/toast'
import CustomTooltip from '../CustomTooltip'

import { applyFilter, hideFilter } from '../../../redux/actions/pageGlobal'
import {
  loadFilters,
  saveFilter,
  updateFilter,
  deleteFilter,
} from '../../../redux/actions/filter'
import { selectIsNonEndemicAccount } from '../../../redux/reducers/header'
import filterDef from '../../../utils/filterDef'
import { CAMPAIGN_TABLE_FILTER } from '../../../utils/defaultValues'

const TableFilter = ({ title = 'Table Filters', filterName }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const isNonEndemicAccount = useSelector(selectIsNonEndemicAccount)
  const filterValues = useSelector(state => state.pageGlobal.filterValues)

  const [values, setValues] = useState({ ...((filterValues || {})[filterName] || {}) })
  const [filterOptions, setFilterOptions] = useState([])
  const [isLoadingFilterList, setIsLoadingFilterList] = useState(false)
  const [currentFilter, setCurrentFilter] = useState(null)
  const [isAdding, setIsAdding] = useState(false)
  const [newFilterName, setNewFilterName] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (currentFilter) {
      setValues({...currentFilter.data})
    }
  }, [currentFilter])

  const filtersToRender = useMemo(() => {
    if (!isNonEndemicAccount) {
      return filterDef[filterName]
    }
    return filterDef[filterName].filter(filter => (
      ![
        'revenue',
        'acos',
        'orders',
        'conversion',
        'roas',
        'ntb_orders',
        'ntb_orders_percent',
        'ntb_sales',
        'ntb_sales_percent',
        'video_views_25',
        'video_views_50',
        'video_views_75',
        'video_views_100',
        'video_5s_views',
        'view_5_seconds_rate',
      ].includes(filter.key)
    ))
  }, [isNonEndemicAccount, filterName])

  const handleApply = () => {
    dispatch(applyFilter(filterName, values))
  }

  const handleReset = () => {
    const newValues = { ...values }
    filtersToRender.forEach((filter) => {
      if (!filter.type || filter.type === 'range') {
        newValues[`${filter.key}Min`] = ''
        newValues[`${filter.key}Max`] = ''
      } else if (filter.type === 'target_acos') {
        newValues[filter.key] = false
      } else {
        newValues[filter.key] = ''
      }

      if ((newValues?.skus || []).length) {
        newValues.skus = []
      }
    })
    setValues(newValues)
    dispatch(applyFilter(filterName, newValues))
  }

  const setFilterValue = (key, value) => {
    setValues({
      ...values,
      [key]: value,
    })
  }

  const handleFocusFilter = async () => {
    if (!filterOptions.length && !isLoadingFilterList) {
      setIsLoadingFilterList(true)
      const accessToken = await getAccessTokenSilently()
      dispatch(loadFilters(accessToken, filterName)).then((response) => {
        setIsLoadingFilterList(false)
        setFilterOptions(response.map(f => ({
          value: f.id,
          label: f.name,
          data: f.value,
        })))
      }).catch(() => { setIsLoadingFilterList(false) })
    }
  }

  const handleUpdateFilter = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    try {
      await dispatch(updateFilter(
        accessToken,
        currentFilter.value,
        currentFilter.label,
        values,
      ))

      setIsSaving(false)
      setCurrentFilter(f => ({...f, data: values}))
      setFilterOptions(f =>
        f.map(f => f.value === currentFilter.value ?
          {...f, data: values}
          : f
        )
      )
      toast.show({
        title: 'Success',
        description: 'The filter has been updated successfully.',
      })
    } catch {
      setIsSaving(false)
    }
  }

  const handleSaveAsFilter = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(saveFilter(accessToken, filterName, newFilterName, values)).then((response) => {
      setIsSaving(false)
      setIsAdding(false)
      setFilterOptions(p => p.push(response))
      toast.show({
        title: 'Success',
        description: 'A new filter has been saved successfully.',
      })
    }).catch(() => setIsSaving(false))
  }

  const handleDeleteFilter = async () => {
    setIsSaving(true)
    const accessToken = await getAccessTokenSilently()
    dispatch(deleteFilter(accessToken, currentFilter.value)).then(() => {
      setIsSaving(false)
      setFilterOptions(p => p.filter(f => f.value !== currentFilter.value))
      setCurrentFilter(null)
    }).catch(() => { setIsSaving(false) })
  }

  const handleClose = () => {
    dispatch(hideFilter(filterName))
  }

  const renderFilter = (filter) => {
    let contents
    if (filter.type === 'select' || filter.type === 'multi-select') {
      let options
      if (!isNonEndemicAccount) {
        options = filter.options
      } else {
        options = filter.options.filter(option => (
          ![
            'product_targeting',
            'keyword_targeting',
            'exact',
            'phrase',
            'broad',
            'auto_campaign',
            'placement',
            'legacy',
            'manual',
            'auto',
          ].includes(option.value)
        ))
      }

      contents = (
        <Select
          options={options}
          value={values[filter.key]}
          isMulti={filter.type === 'multi-select'}
          onChange={(data) => setFilterValue(filter.key, data)}
        />
      )
    } else if (filter.type === 'target_acos') {
      contents = (
        <div className="checkbox-wrapper">
          <CheckboxComponent
            label={filter.label}
            checked={values[filter.key]}
            onChange={(checked) => { setFilterValue(filter.key, checked) }}
          />
          <CustomTooltip placement="right">
            <p>We’ll use your set Target ACoS for each campaign to find what needs to be optimized, fast!</p>
            <p>Results that are above your target ACoS will be revealed for each campaign.</p>
          </CustomTooltip>
        </div>
      )
    } else {
      let isDisabled = false
      if (filter.depends) {
        if (filter.dependValue !== false) {
          isDisabled = values[filter.depends] !== filter.dependValue
        } else {
          isDisabled = values[filter.depends] !== filter.dependValue
            && typeof values[filter.depends] !== 'undefined'
        }
      }
      contents = (
        <div className="filter-row-child">
          <input
            type="number"
            placeholder="Min"
            value={values[`${filter.key}Min`] || ''}
            disabled={isDisabled}
            onChange={(e) => setFilterValue(`${filter.key}Min`, e.target.value)}
          />
          <input
            type="number"
            placeholder="Max"
            value={values[`${filter.key}Max`] || ''}
            disabled={isDisabled}
            onChange={(e) => setFilterValue(`${filter.key}Max`, e.target.value)}
          />
        </div>
      )
    }

    return (
      <div key={filter.key} className="filter-row">
        {
          filter.type !== 'target_acos' && (
            <span>{ filter.label }</span>
          )
        }
        { contents }
      </div>
    )
  }

  const renderFilterSelectPanel = () => (
    <div className={`pane-filter-select ${isSaving ? 'loading' : ''}`}>
      { isSaving && <LoaderComponent/> }
      <div className="filter-select-option">
        <Select
          className="filter-select"
          options={filterOptions}
          value={currentFilter}
          placeholder="Select a Filter"
          isLoading={isLoadingFilterList}
          onChange={setCurrentFilter}
          onFocus={(handleFocusFilter)}
        />
      </div>
      {
        isAdding ? (
          <div className="new-filter">
            <input
              type="text"
              placeholder="New Filter Name"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
            />
            <IconButton
              icon={<Icon icon="check" />}
              appearance="primary"
              title="Save Filter"
              onClick={handleSaveAsFilter}
              disabled={newFilterName === ''}
            />
            <IconButton
              icon={<Icon icon="close" />}
              title="Cancel"
              onClick={() => { setIsAdding(false) }}
            />
          </div>
        ) : (
          <div className="filter-select-action">
            <button
              type="button"
              className="btn btn-white"
              onClick={() => { setIsAdding(true) }}
              disabled={isAdding}>
              Add
            </button>
            <button
              type="button"
              className="btn btn-white"
              onClick={handleUpdateFilter}
              disabled={isAdding || !currentFilter}>
              Update
            </button>
            <button
              type="button"
              className="btn btn-white"
              onClick={handleDeleteFilter}
              disabled={isAdding || !currentFilter}>
              Delete
            </button>
          </div>
        )
      }
    </div>
  )

  const renderSkuFilter = () => {
    if (filterName !== CAMPAIGN_TABLE_FILTER) {
      return null
    }

    return (
      <div className="filter-row">
        <span>SKU(s)</span>
        <SkuSelector
          showAllButton={false}
          selectedSkus={values?.skus || []}
          onChange={(skus) => { setFilterValue('skus', skus) }}
        />
      </div>
    )
  }

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <div className="table-filter-pane">
        <div className="pane-header">
          <div className="pane-title">
            { title }
          </div>
          <span className="close-icon" onClick={handleClose}>
            &times;
          </span>
        </div>
        { renderFilterSelectPanel() }
        <div className="pane-body">
          { renderSkuFilter() }
          { filtersToRender.map(renderFilter) }
        </div>
        <div className="pane-footer">
          <button type="button" className="btn btn-white" onClick={handleReset}>Reset</button>
          {
            isAdding && (
              <button
                type="button"
                className="btn btn-blue"
                onClick={handleSaveAsFilter}
                disabled={newFilterName === ''}
              >
                  Save
              </button>
            )
          }
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleApply}
          >
            Apply
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default TableFilter
