/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'rsuite'
import Select from 'react-select'

import LoaderComponent from '../../components/CommonComponents/LoaderComponent'

const Option = (props) => {
  const { innerRef, innerProps, getStyles, isSelected, data } = props
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={getStyles('option', props)}
      className={`template-option${isSelected ? ' selected' : ''}`}
    >
      <span>{ data.name }</span>
      <a href="#" onClick={(event) => { data.onDelete(event, data.id) }}>
        Delete
      </a>
    </div>
  )
}

const TemplatePicker = ({ show, onLoad, onSelect, onDelete, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  useEffect(() => {
    if (show && !isLoading) {
      setIsLoading(true)
      onLoad().then((response) => {
        setTemplates(response)
        setIsLoading(false)
      })
    }
  }, [show]) // eslint-disable-line

  const handleSelect = () => {
    onSelect(selectedTemplate.contents)
  }

  const handleDelete = (event, templateId) => {
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)
    onDelete(templateId).then(() => {
      setIsLoading(false)
      setTemplates(templates.filter(template => template.id !== templateId))
      if (selectedTemplate && selectedTemplate.id === templateId) {
        setSelectedTemplate(null)
      }
    })
  }

  const extendedTemplates = templates.map(template => ({
    ...template,
    onDelete: handleDelete,
  }))

  return (
    <Modal className="template-picker-modal" backdrop="static" show={show} size="xs">
      <Modal.Body>
        { isLoading && <LoaderComponent /> }
        <Select
          options={extendedTemplates}
          getOptionLabel={template => template.name}
          getOptionValue={template => template.id}
          value={selectedTemplate}
          components={{ Option }}
          isLoading={isLoading}
          placeholder="Select template..."
          onChange={setSelectedTemplate}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-primary"
          disabled={isLoading || selectedTemplate === null}
          onClick={() => { handleSelect() }}
        >
          Load
        </button>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => { onCancel() }}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default TemplatePicker
