import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Toggle, TagGroup, Tag } from 'rsuite'
import { useAuth0 } from '@auth0/auth0-react'

import StreamView from '../CommonComponents/StreamView'

import { getStream } from '../../redux/actions/campaign'

const HourlyDataModal = ({ campaigns, onClose, onCloseCampaign }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isStreamLoading, setIsStreamLoading] = useState(false)
  const [campaignStream, setCampaignStream] = useState([])
  const [isShowCampaigns, setIsShowCampaigns] = useState(true)

  useEffect(() => {
    if (!campaigns.length) {
      return
    }

    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsStreamLoading(true)
      dispatch(getStream(
        accessToken,
        campaigns.map(c => ({
          campaignId: c.campaign_id,
          campaignType: c.campaignType,
        }))
      )).then((res) => {
        setCampaignStream(res)
        setIsStreamLoading(false)
      }).catch(() => {
        setIsStreamLoading(false)
      })
    })()
  }, [campaigns]) // eslint-disable-line


  const hadOldSb = useMemo(() => {
    if (!campaigns || !campaigns.length) {
      return false
    }
    return typeof campaigns.find(campaign => (
      campaign.campaignType === 'sb'
      && !campaign.is_multi_adgroups_enabled
    )) !== 'undefined'
  }, [campaigns])

  return (
    <Modal className={`stream-data-modal ${isStreamLoading ? 'loading' : ''}`} backdrop="static" show size="lg" overflow={false}>
      <Modal.Header onHide={() => { onClose()}}>
        <Modal.Title>
          Hourly Data
          {
            (campaigns || []).length > 0 && (
              <Toggle
                checked={isShowCampaigns}
                checkedChildren="Show Campaigns"
                unCheckedChildren="Hide Campaigns"
                onChange={setIsShowCampaigns}
              />
            )
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          (campaigns.length > 0 && isShowCampaigns) && (
            <TagGroup>
              {
                campaigns.map(c => (
                  <Tag
                    key={c.campaign_id}
                    closable={campaigns.length > 1}
                    onClose={() => { onCloseCampaign(c.campaign_id) }}
                  >
                    {c.campaign}
                  </Tag>
                ))
              }
            </TagGroup>
          )
        }
        <div className="stream-content">
          {
            hadOldSb && (
              <div className="stream-sb-note">
                Amazon Marketing Stream only includes traffic deriving
                from Sponsored Brands version 4 (multi-ad group campaigns).<br />
                Data related to any campaigns created before May 22, 2023 (in the US)
                or before August 10, 2023 (rest of the world) will not be sent.
              </div>
            )
          }
          <StreamView
            isLoading={isStreamLoading}
            streams={campaignStream}
            noDatePicker
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="rs-btn rs-btn-subtle"
          onClick={() => onClose()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default HourlyDataModal
