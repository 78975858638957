import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  Row,
  Col,
  Icon,
  IconButton,
  Slider
} from 'rsuite'

import {
  changeViewWidgetState,
  changeHighlightWidgetState
} from '../../redux/actions/pageGlobal'
import { formatCurrency, formatValue } from '../../services/helper'

const ReportPerformanceWidget = ({widget, data, onRemove, customizing = false, section}) => {
  const dispatch = useDispatch()

  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)
  const reportWidgetSetting = useSelector(state => state.pageGlobal.reportWidgetSetting)

  const [highlighted, setHighlighted] = useState(widget.highlighted)

  useEffect(() => {
    if (section !== '') {
      const sectionWidget = reportWidgetSetting[section].find((w) => w.key === widget.key)
      setHighlighted(sectionWidget.highlighted)
    }
  }, [section, reportWidgetSetting, widget])

  const {
    ctr = 0.00,
    clicks = 0,
    impressions = 0,
    conversion = 0.00,
    orders = 0,
    acos = 0.00,
    revenue = 0.00,
    cpc = 0.00
  } = data

  const handleClickHideWidget = () => {
    dispatch(changeViewWidgetState(section, widget.key))
    onRemove(widget.key)
  }

  const handleClickHighlightWidget = () => {
    dispatch(changeHighlightWidgetState(section, widget.key))
  }

  const renderHeader = () => (
    <div className="report-widget-header">
      <div className="widget-title">
        <IconButton
          icon={<Icon icon="star" />}
          size="xs"
          className={`highlight-report-widget${highlighted ? ' active' : ''}`}
          circle
          onClick={handleClickHighlightWidget}
        />
        { widget.title }
      </div>
      <div className="widget-action">
        {
          customizing && (
            <IconButton
              icon={<Icon icon="check" />}
              color={widget.view ? 'green' : 'violet'}
              circle
              onClick={handleClickHideWidget}
            />
          )
        }
      </div>
    </div>
  )

  const renderContents = () => {
    const metricList = [
      {
        name: 'CTR',
        value: ctr,
        meta: [
          `Clicks: ${formatValue(clicks, 'number', 0)}`,
          `Impressions: ${formatValue(impressions, 'number', 0)}`,
        ],
      },
      {
        name: 'Conversion',
        value: parseFloat(conversion || 0),
        meta: [
          `Orders: ${formatValue(orders, 'number', 0)}`,
          `Clicks: ${formatValue(clicks, 'number', 0)}`,
        ],
      },
      {
        name: 'ACoS',
        value: parseFloat(acos || 0),
        meta: [
          `Sales: ${formatCurrency(revenue, currencySign, currencyRate)}`,
          `CPC: ${formatCurrency(cpc, currencySign, currencyRate)}`,
        ],
      },
    ]

    return (
      <div className="report-widget-content">
        <Grid fluid>
          <Row gutter={20}>
            {
              metricList.map(metric => (
                <Col key={metric.name} xs={8}>
                  <div className="performance-item">
                    <div className="performance-main-metric">{ metric.name }</div>
                    <div className="performance-main-metric-value">
                      { formatValue(metric.value, 'percent')}
                    </div>
                    <div className="performance-progress">
                      <div className="label">
                        <span>{ metric.meta[0] }</span>
                        <span>{ metric.meta[1] }</span>
                      </div>
                      <div className="content">
                        <Slider
                          barClassName="performance-conv"
                          progress
                          value={metric.value}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Grid>
      </div>
    )
  }

  return (
    <div className="report-widget">
      { renderHeader() }
      { renderContents() }
    </div>
  )
}

export default ReportPerformanceWidget
