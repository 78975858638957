import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import Select from 'react-select'

import LoaderComponent from '../LoaderComponent'

import { getSdCreativePreview } from '../../../redux/actions/campaign'

const typeOptions = [
  { value: 0, label: 'Amazon product page right', width: 245, height: 250 },
  { value: 1, label: 'Amazon product page middle', width: 650, height: 130 },
  { value: 2, label: 'Amazon mobile rectangle', width: 414, height: 125 },
  { value: 3, label: 'Medium rectangle', width: 300, height: 250 },
  { value: 4, label: 'Mobile leaderboard', width: 320, height: 50 },
  { value: 5, label: 'Billboard', width: 970, height: 250 },
  { value: 6, label: 'Twitch stream desktop', width: 970, height: 66 },
  { value: 7, label: 'Leaderboard', width: 728, height: 90 },
  { value: 8, label: 'Amazon top stripe', width: 980, height: 55 },
  { value: 9, label: 'Wide skyscraper', width: 160, height: 600 },
  { value: 10, label: 'Half page skyscraper', width: 300, height: 600 },
]

const NonEndemicPreview = ({ campaignId }) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [previewType, setPreviewType] = useState(typeOptions[0])
  const [previewHtmls, setPreviewHtmls] = useState([])

  useEffect(() => {
    const abortCtrl = new AbortController();

    (async () => {
      setIsLoading(true)
      const accessToken = await getAccessTokenSilently()
      const response = await dispatch(getSdCreativePreview(
        accessToken,
        campaignId,
        abortCtrl.signal,
      ))
      setPreviewHtmls(response || [])
      setIsLoading(false)
    })()

    return () => {
      abortCtrl.abort()
    }
  }, []) // eslint-disable-line

  const renderContents = () => {
    if (isLoading) {
      return <LoaderComponent />
    }

    if (!previewHtmls.length) {
      return (
        <p>
          No previews generated.
        </p>
      )
    }

    const html = previewHtmls.find(preview => (
      preview.includes(`${previewType.width}x${previewType.height}`)
    ))

    return (
      <div className="campaign-creative-panel desktop">
        <iframe
          className="preview-iframe"
          srcDoc={html}
          title="preview"
          width={previewType.width}
          height={previewType.height}
        />
      </div>
    )
  }

  return (
    <div className="campaign-creative-section">
      <div className="creative-action-bar">
        <Select
          options={typeOptions}
          value={previewType}
          onChange={setPreviewType}
        />
      </div>
      { renderContents() }
    </div>
  )
}

export default NonEndemicPreview
