import React from 'react'
import { Radio, RadioGroup } from 'rsuite'

import CustomTooltip from '../CommonComponents/CustomTooltip'

const bidOpList = [
  {
    value: 'clicks',
    label: 'Optimize for page visits',
    description: 'We\'ll optimize your bids for higher click-through rates. '
      + 'Drive product consideration by showing your ads to shoppers more likely to click on your ad.',
  },
  {
    value: 'conversions',
    label: 'Optimize for conversions',
    description: 'We\'ll optimize your bids for higher conversion rates. '
      + 'Drive sales by showing your ad to shoppers more likely to purchase your product.',
  },
  {
    value: 'reach',
    label: 'Optimize for reach',
    description: 'We\'ll optimize your bids for higher viewable impressions. '
      + 'Drive product awareness by showing your ad to relevant audiences on and off Amazon to maximize reach.',
  },
]

const SDBidSection = ({ isNonEndemicAccount, info, dailyBudget, onChange }) => (
  <>
    <div className="field-wrapper">
      <div className="field-name">
        Bid optimization
        <CustomTooltip>
          We'll optimize your bid for the metric you want to focus on.
        </CustomTooltip>
      </div>
      <RadioGroup
        value={info.bidOp}
        onChange={(value) => { onChange('bidOp', value) }}
      >
        {
          bidOpList.map((option) => {
            if (isNonEndemicAccount && option.value === 'conversions') {
              return null
            }
            return (
              <Radio key={option.value} value={option.value}>
                { option.label }
                <CustomTooltip>
                  { option.description }
                </CustomTooltip>
              </Radio>
            )
          })
        }
      </RadioGroup>
    </div>
    <div className="field-wrapper">
      <div className="field-name">
        Default bid
      </div>
      <input
        type="number"
        value={info.defaultBid}
        onChange={(event) => { onChange('defaultBid', event.target.value) }}
      />
      {
        parseFloat(info.defaultBid) >= parseFloat(dailyBudget) / 2 && (
          <div className="warning-message">
            Bid must be less than half the value of your budget.
          </div>
        )
      }
    </div>
  </>
)

export default SDBidSection
