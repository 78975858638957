/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import * as Icon from 'react-icons/fi'

import BillingCard from './BillingCard'
import BillingInfoModal from './BillingInfoModal'

import { getBillingInfo } from '../../redux/actions/auth'

import { countryList } from '../../utils/country'

const BillingInfo = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState(false)
  const [cardList, setCardList] = useState([])
  const [currentInfo, setCurrentInfo] = useState({})
  const [showInfoModal, setShowInfoModal] = useState(false)

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently()
      setIsLoading(true)
      dispatch(getBillingInfo(accessToken)).then((response) => {
        setIsLoading(false)
        setCardList(response.cards)
        setCurrentInfo(response.billingInfo || null)
      }).catch(() => {
        setIsLoading(false)
      })
    })()
  }, []) // eslint-disable-line

  const handleInfoModalClose = (payload) => {
    setShowInfoModal(false)

    if (payload) {
      setCurrentInfo(payload)
    }
  }

  const renderBilling = () => {
    if (!currentInfo) {
      return (
        <em>Unavailable</em>
      )
    }

    let country
    if (currentInfo.country) {
      const found = countryList.find(option => option.value === currentInfo.country)
      if (found) {
        country = found.label
      }
    }

    return (
      <>
        <p>{ currentInfo.first_name } { currentInfo.last_name }</p>
        { currentInfo.line1 && <p>{ currentInfo.line1 }</p> }
        { currentInfo.line2 && <p>{ currentInfo.line2 }</p> }
        {
          (currentInfo.city || currentInfo.zip) && (
            <p>
              { currentInfo.city && `${currentInfo.city} ` }
              { currentInfo.zip || '' }
            </p>
          )
        }
        { currentInfo.state && <p>{ currentInfo.state }</p> }
        { country && <p>{ country }</p> }
      </>
    )
  }

  const renderInfo = () => {
    return (
      <div className="billing-info-container">
        <BillingCard
          isLoading={isLoading}
          cardList={cardList}
          onCardChange={setCardList}
        />
        <div className="billing-info-details">
          <div className="billing-info-title">
            <span>Billing Address</span>
            <a
              href="#"
              onClick={(event) => { event.preventDefault(); setShowInfoModal(true) }}
            >
              <Icon.FiEdit3 /> Edit
            </a>
          </div>
          { renderBilling() }
        </div>
      </div>
    )
  }

  return (
    <div>
      {
        isLoading ? 'Loading...' : renderInfo()
      }
      <BillingInfoModal
        show={showInfoModal}
        onHide={handleInfoModalClose}
      />
    </div>
  )
}

export default BillingInfo
