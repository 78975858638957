import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import TargetingModal from './Shared/TargetingModal'
import TargetingList from './Shared/TargetingList'
import DefaultBidSection from './Shared/DefaultBidSection'

const SPTargetingSection = ({ targetings, isSuggestionsLoading, dailyBudget, onFind, onChange }) => {
  const suggestedSPCategories = useSelector(state => state.campaignCreator.suggestedSPCategories)
  const suggestedSPProducts = useSelector(state => state.campaignCreator.suggestedSPProducts)

  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const handleFind = () => {
    setOpenModal(true)
    onFind()
  }

  return (
    <div className="section-container">
      <div className="section-title">
        <span>Products Targeting</span>
        <div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFind}
          >
            Find Categories/ASINs
          </button>
          {
            targetings.length > 0 && (
              <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
                Remove All
              </button>
            )
          }
        </div>
      </div>
      <div className="section-note">
        Help shoppers find your product by choosing categories, products,
        brands, or features related to your product.
      </div>
      <DefaultBidSection
        targetings={targetings}
        defaultBid={defaultBid}
        onChange={onChange}
        onDefaultBidChange={setDefaultBid}
      />
      <TargetingList
        adType="sp"
        targetings={targetings}
        dailyBudget={dailyBudget}
        onChange={onChange}
      />
      <TargetingModal
        show={openModal}
        defaultBid={defaultBid}
        targetings={targetings}
        isLoading={isSuggestionsLoading}
        suggestedCategories={suggestedSPCategories || []}
        suggestedProducts={suggestedSPProducts}
        onChange={onChange}
        onClose={() => { setOpenModal(false) }}
      />
    </div>
  )
}

export default SPTargetingSection
