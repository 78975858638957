export const GET_DETAILS_START = 'GET_DETAILS_START'
export const GET_DETAILS_SUCCEED = 'GET_DETAILS_SUCCEED'
export const GET_DETAILS_FAIL = 'GET_DETAILS_FAIL'

export const GET_ADGROUPS_SUCCEED = 'GET_ADGROUPS_SUCCEED'
export const GET_LOGS_SUCCEED = 'GET_LOGS_SUCCEED'

export const UPDATE_PORTFOLIO_START = 'UPDATE_PORTFOLIO_START'
export const UPDATE_PORTFOLIO_SUCCEED = 'UPDATE_PORTFOLIO_SUCCEED'
export const UPDATE_PORTFOLIO_FAIL = 'UPDATE_PORTFOLIO_FAIL'

export const GET_ST_DATA_START = 'GET_ST_DATA_START'
export const GET_ST_DATA_SUCCEED = 'GET_ST_DATA_SUCCEED'
export const GET_ST_DATA_FAIL = 'GET_ST_DATA_FAIL'

export const GET_NEGATIVE_KW_DATA_START = 'GET_NEGATIVE_KW_DATA_START'
export const GET_NEGATIVE_KW_DATA_SUCCEED = 'GET_NEGATIVE_KW_DATA_SUCCEED'
export const GET_NEGATIVE_KW_DATA_FAIL = 'GET_NEGATIVE_KW_DATA_FAIL'

export const GET_BID_DATA_SUCCEED = 'GET_BID_DATA_SUCCEED'

export const GET_BID_TARGET_DATA_SUCCEED = 'GET_BID_TARGET_DATA_SUCCEED'

export const CHANGE_TARGET_SUCCEED = 'CHANGE_TARGET_SUCCEED'

export const GET_NEGATIVE_WORD_DATA_START = 'GET_NEGATIVE_WORD_DATA_START'
export const GET_NEGATIVE_WORD_DATA_SUCCEED = 'GET_NEGATIVE_WORD_DATA_SUCCEED'
export const GET_NEGATIVE_WORD_DATA_FAIL = 'GET_NEGATIVE_WORD_DATA_FAIL'

export const GET_NEGATIVE_TARGET_DATA_START = 'GET_NEGATIVE_TARGET_DATA_START'
export const GET_NEGATIVE_TARGET_DATA_SUCCEED = 'GET_NEGATIVE_TARGET_DATA_SUCCEED'
export const GET_NEGATIVE_TARGET_DATA_FAIL = 'GET_NEGATIVE_TARGET_DATA_FAIL'

export const APPLY_PLACEMENT_BIDDING_SUCCEED = 'APPLY_PLACEMENT_BIDDING_SUCCEED'

export const GET_KEYWORD_DATA_START = 'GET_KEYWORD_DATA_START'

export const GET_KEYWORD_RALATED_START = 'GET_KEYWORD_RALATED_START'
export const GET_KEYWORD_RALATED_SUCCEED = 'GET_KEYWORD_RALATED_SUCCEED'
export const GET_KEYWORD_RALATED_FAIL = 'GET_KEYWORD_RALATED_FAIL'

export const GET_NEGATIVE_TARGET_START = 'GET_NEGATIVE_TARGET_START'
export const GET_NEGATIVE_TARGET_SUCCEED = 'GET_NEGATIVE_TARGET_SUCCEED'
export const GET_NEGATIVE_TARGET_FAIL = 'GET_NEGATIVE_TARGET_FAIL'
