import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import TargetingModal from './Shared/TargetingModal'
import TargetingList from './Shared/TargetingList'
import SuggestedBidTypeSelector from './Shared/SuggestedBidTypeSelector'
import DefaultBidSection from './Shared/DefaultBidSection'

import { getSdBidSuggestions } from '../../redux/actions/campaignCreator'
import { parseTargeting } from '../../services/helper'

const SDTargetingSection = ({
  targetings,
  isSuggestionsLoading,
  dailyBudget,
  bidInfo,
  products,
  onFind,
  onChange,
}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const suggestedSDCategories = useSelector(state => state.campaignCreator.suggestedSDCategories)
  const suggestedSDProducts = useSelector(state => state.campaignCreator.suggestedSDProducts)

  const [openModal, setOpenModal] = useState(false)
  const [defaultBid, setDefaultBid] = useState(0.75)

  const parseTargetings = () => {
    const targets = []
    targetings.forEach((targeting) => {
      if (['category', 'refine', 'product'].indexOf(targeting.type) === -1) {
        return
      }

      const payload = parseTargeting(targeting)
      if (!payload) {
        return
      }

      payload.targeting = targeting
      targets.push(payload)
    })
    return targets
  }

  const handleFind = () => {
    setOpenModal(true)
    onFind()
  }

  const handleGetSuggestedBid = async () => {
    setOpenModal(false)

    if (!products.length || !targetings.length) {
      return
    }

    const asins = products.map((product) => ({
      asin: product.asin,
    }))

    const bidParams = parseTargetings(targetings).map((suggestedTarget) => ({
      targeting: suggestedTarget,
      param: {
        products: asins,
        bidOptimization: bidInfo.bidOp,
        costType: bidInfo.bidOp === 'reach' ? 'vcpm' : 'cpc',
        targetingClauses: [
          {
            targetingClause: {
              expressionType: 'manual',
              expression: suggestedTarget.expression,
            },
          },
        ],
      },
    }))

    const accessToken = await getAccessTokenSilently()
    const response = await dispatch(getSdBidSuggestions(accessToken, bidParams))
    if (!response || !response.length) {
      return
    }

    onChange(targetings.map((targeting) => {
      const suggestedBid = response.find(bid => (
        (
          bid.targeting.targeting.type === 'category'
          && targeting.type === 'category'
          && bid.targeting.targeting.id.toString() === targeting.id.toString()
        )
        ||
        (
          bid.targeting.targeting.type === 'product'
          && targeting.type === 'product'
          && bid.targeting.targeting.asin === targeting.asin
        )
        ||
        (
          bid.targeting.targeting.type === 'refine'
          && targeting.type === 'refine'
          && bid.targeting.targeting.brandId.toString() === targeting.brandId.toString()
          && bid.targeting.targeting.id.toString() === targeting.id.toString()
        )
      ))
      if (suggestedBid) {
        return {
          ...targeting,
          suggestedBid: suggestedBid.suggestedBid,
        }
      }
      return targeting
    }))
  }

  return (
    <div className="section-container">
      <div className="section-title">
        <span>Contextual Targeting</span>
        <div>
          <button
            type="button"
            className="btn btn-blue"
            onClick={handleFind}
          >
            Find Categories/ASINs
          </button>
          {
            targetings.length > 0 && (
              <button type="button" className="btn btn-red" onClick={() => { onChange([]) }}>
                Remove All
              </button>
            )
          }
        </div>
      </div>
      <div className="section-note">
        Help shoppers find your product by choosing categories, products,
        brands, or features related to your product.
      </div>
      <div className="field-row">
        <div className="field-wrapper">
          <DefaultBidSection
            targetings={targetings}
            defaultBid={defaultBid}
            onChange={onChange}
            onDefaultBidChange={setDefaultBid}
          />
        </div>
        <div className="field-wrapper">
          <SuggestedBidTypeSelector
            targetings={targetings}
            onChange={onChange}
          />
        </div>
      </div>
      <TargetingList
        adType="sd"
        targetings={targetings}
        dailyBudget={dailyBudget}
        defaultBid={defaultBid}
        onChange={onChange}
      />
      <TargetingModal
        show={openModal}
        defaultBid={defaultBid}
        targetings={targetings}
        isLoading={isSuggestionsLoading}
        suggestedCategories={suggestedSDCategories || []}
        suggestedProducts={suggestedSDProducts}
        onChange={onChange}
        onClose={handleGetSuggestedBid}
      />
    </div>
  )
}

export default SDTargetingSection
