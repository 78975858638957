import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { format, addDays, isAfter } from 'date-fns'

import { getApTestResults } from '../../redux/actions/ap'

import LoaderComponent from '../CommonComponents/LoaderComponent'
import CheckboxComponent from '../CommonComponents/CheckboxComponent'
import CustomTooltip from '../CommonComponents/CustomTooltip'
import SubSection from './SubSection'
import OpTestingMetric from './OpTestingMetric'
import { getISODate } from '../../services/helper'

const dayList = [
  { value: 14, label: '14 days' },
  { value: 21, label: '21 days' },
  { value: 28, label: '28 days' },
]

// FIXME: Show symbol of currently selected currency.
const OpTesting = ({ campaign, settings, isNonEndemicAccount, onChange, ...props}) => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const campaignId = useSelector(state => state.ap.campaignId)

  const [isLoading, setIsLoading] = useState(false)
  const [testResults, setTestResults] = useState(null)

  // Load testing results.
  useEffect(() => {
    let abortCtrl
    // FIXME: `test_start_date` should be always non-null,
    // if `test_active` is TRUE. Need to sanitize
    // DB records to remove `dirty` states.
    if (settings.test_active
      && settings.test_start_date
      && !testResults
      && !isLoading) {
      abortCtrl = new AbortController();

      (async () => {
        setIsLoading(true)
        const accessToken = await getAccessTokenSilently()
        try {
          const data = await dispatch(getApTestResults(accessToken, {
            campaignId,
            start: settings.test_start_date,
            duration: settings.test_lookback
          }, abortCtrl.signal))
          setTestResults(data)
          setIsLoading(false)
        } catch (isCancelled) {
          if (!isCancelled) {
            setIsLoading(false)
          }
        }
      })()
    }

    return () => {
      if (abortCtrl) {
        abortCtrl.abort()
      }
    }
  }, [ // eslint-disable-line
    settings.test_active,
    settings.test_start_date,
    settings.test_lookback,
  ])

  const daySelected = dayList.find(day => day.value === settings.test_lookback)

  let revenueA = 0
  let revenueB = 0
  let costA = 0
  let costB = 0
  let impressionA = 0
  let impressionB = 0
  let clickA = 0
  let clickB = 0
  let ctrA = 0
  let ctrB = 0
  let orderA = 0
  let orderB = 0
  let conversionA = 0
  let conversionB = 0
  let acosA = 0
  let acosB = 0
  // FIXME: Take currency and its rate into account
  // when calculating money amounts.
  if (testResults) {
    const resultA = testResults[0][0]
    const resultB = testResults[1][0]

    revenueA = parseFloat(resultA.revenue || 0)
    revenueB = parseFloat(resultB.revenue || 0)
    costA = parseFloat(resultA.cost || 0)
    costB = parseFloat(resultB.cost || 0)
    impressionA = parseInt(resultA.impressions || 0, 10)
    impressionB = parseInt(resultB.impressions || 0, 10)
    clickA = parseInt(resultA.clicks || 0, 10)
    clickB = parseInt(resultB.clicks || 0, 10)
    ctrA = impressionA ? clickA / impressionA * 100 : 0
    ctrB = impressionB ? clickB / impressionB * 100 : 0
    orderA = parseInt(resultA.orders || 0, 10)
    orderB = parseInt(resultB.orders || 0, 10)
    conversionA = clickA ? orderA / clickA * 100 : 0
    conversionB = clickB ? orderB / clickB * 100 : 0
    acosA = revenueA ? costA / revenueA * 100 : 0
    acosB = revenueB ? costB / revenueB * 100 : 0
  }

  const handleStart = () => {
    onChange('test_active', true)
    onChange('test_start_date', getISODate(new Date()))
  }

  const renderHeader = () => {
    if (!settings.test_active || !settings.test_start_date) {
      return (
        <div className="testing-header">
          <label className="testing-label">
            A/B test for
          </label>
          <div className="testing-header-wrapper">
            <Select
              className="testing-day-selector"
              options={dayList}
              value={daySelected}
              onChange={(selected) => { onChange('test_lookback', selected.value) }}
            />
            <div className="split-test-email-wrapper">
              <CheckboxComponent
                label="Send email when A/B test done"
                checked={settings.test_send_email}
                onChange={(checked) => { onChange('test_send_email', checked) }}
              />
              <CustomTooltip>
                <p>Email will be sent three days after test completion to ensure data accuracy.</p>
              </CustomTooltip>
            </div>
            <button
              type="button"
              className="btn btn-white"
              onClick={handleStart}
            >
              Start
            </button>
          </div>
        </div>
      )
    }

    const endDate = addDays(settings.test_start_date, settings.test_lookback)

    return (
      <div className="testing-header">
        <div className="testing-header-wrapper">
          <div className="testing-details">
            <div className="testing-details-field">
              <div className="field-name">Start date</div>
              <div className="field-value">
                { format(settings.test_start_date, 'MM/dd/yyyy') }
              </div>
            </div>
            <div className="testing-details-field">
              <div className="field-name">End date</div>
              <div className="field-value">
                { format(endDate, 'MM/dd/yyyy') }
              </div>
            </div>
            <div className="testing-details-field">
              <div className="field-name">Status</div>
              <div className="field-value">
                { isAfter(endDate, new Date()) ? 'Running' : 'Done' }
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-red"
            onClick={() => { onChange('test_active', false) }}
          >
            Stop
          </button>
        </div>
      </div>
    )
  }

  const renderMetrics = () => {
    if (!isNonEndemicAccount) {
      return (
        <>
          <div className="testing-metric-list">
            <OpTestingMetric
              name="Gross revenue"
              type="money"
              valueA={revenueA}
              valueB={revenueB}
            />
            <OpTestingMetric
              name="Ad spend"
              type="money"
              valueA={costA}
              valueB={costB}
            />
            <OpTestingMetric
              name="Impressions"
              type="int"
              valueA={impressionA}
              valueB={impressionB}
            />
            <OpTestingMetric
              name="Clicks"
              type="int"
              valueA={clickA}
              valueB={clickB}
            />
          </div>
          <div className="testing-metric-list">
            <OpTestingMetric
              name="CTR (%)"
              type="percent"
              valueA={ctrA}
              valueB={ctrB}
            />
            <OpTestingMetric
              name="Orders"
              type="int"
              valueA={orderA}
              valueB={orderB}
            />
            <OpTestingMetric
              name="Conversion (%)"
              type="percent"
              valueA={conversionA}
              valueB={conversionB}
            />
            <OpTestingMetric
              name="ACoS (%)"
              type="percent"
              valueA={acosA}
              valueB={acosB}
            />
          </div>
        </>
      )
    }

    return (
      <>
        <div className="testing-metric-list">
          <OpTestingMetric
            name="Ad spend"
            type="money"
            valueA={costA}
            valueB={costB}
          />
          <OpTestingMetric
            name="Impressions"
            type="int"
            valueA={impressionA}
            valueB={impressionB}
          />
          <OpTestingMetric
            name="Clicks"
            type="int"
            valueA={clickA}
            valueB={clickB}
          />
          <OpTestingMetric
            name="CTR (%)"
            type="percent"
            valueA={ctrA}
            valueB={ctrB}
          />
        </div>
      </>
    )
  }

  return (
    <SubSection
      name="A/B Testing"
      tooltip={'Looking to see how a smart pilot update impacted your ads performance? '
        + 'Set up an A/B test and compare the before and after results. '
        + 'Choose the amount of days and once the test is completed, '
        + 'we’ll compare results to the same amount of days prior to the test start.'}
      {...props}
    >
      { isLoading && <LoaderComponent /> }
      { renderHeader() }
      { renderMetrics() }
    </SubSection>
  )
}

export default OpTesting
