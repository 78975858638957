import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import SortableTable from '../../components/CommonComponents/SortableTableComponent'
import { getPerformanceSegment } from '../../redux/actions/accountHealth'
import { calcDerivedMetrics, formatCurrency, formatValue, tableSorter } from '../../services/helper'

const columns = [
  { key: 'label', name: 'Label', className: 'col-label' },
  { key: 'count', name: 'Target Count' },
  { key: 'bid', name: 'Average Bid' },
  { key: 'minBid', name: 'Min Bid' },
  { key: 'maxBid', name: 'Max Bid' },
  { key: 'impressions', name: 'Impression' },
  { key: 'clicks', name: 'Click' },
  { key: 'costPercent', name: '% Spend' },
  { key: 'cost', name: 'Spend' },
  { key: 'orders', name: 'Order' },
  { key: 'revenuePercent', name: '% Sales' },
  { key: 'revenue', name: 'Sales' },
  { key: 'ctr', name: 'CTR' },
  { key: 'cpc', name: 'CPC' },
  { key: 'acos', name: 'ACoS' },
]

const PerformanceSegment = () => {
  const dispatch = useDispatch()
  const { getAccessTokenSilently } = useAuth0()

  const currentUserId = useSelector(state => state.header.currentUserId)
  const startDate = useSelector(state => state.accountHealth.startDate)
  const endDate = useSelector(state => state.accountHealth.endDate)
  const currencyRate = useSelector(state => state.header.currencyRate)
  const currencySign = useSelector(state => state.header.currencySign)

  const [isLoading, setIsLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [summary, setSummary] = useState({})

  useEffect(() => {
    const abortCtrl = new AbortController();

    if (currentUserId && !isLoading) {
      (async () => {
        const accessToken = await getAccessTokenSilently()
        setIsLoading(true)
        const data = await dispatch(getPerformanceSegment(
          accessToken,
          abortCtrl.signal,
        ))
        parseData(data)
        setIsLoading(false)
      })()
    }

    return () => {
      abortCtrl.abort()
    }
  }, [currentUserId, startDate, endDate]) // eslint-disable-line

  const parseData = (data) => {
    if (!data) {
      setRecords([])
      return
    }

    const buckets = [
      { key: 'acos10', label: 'ACoS 0-10%' },
      { key: 'acos20', label: 'ACoS 10-20%' },
      { key: 'acos30', label: 'ACoS 20-30%' },
      { key: 'acos40', label: 'ACoS 30-40%' },
      { key: 'acos50', label: 'ACoS 40-50%' },
      { key: 'acos60', label: 'ACoS 50-60%' },
      { key: 'acos70', label: 'ACoS 60-70%' },
      { key: 'acos80', label: 'ACoS 70-80%' },
      { key: 'acos90', label: 'ACoS 80-90%' },
      { key: 'acos100', label: 'ACoS 90-100%' },
      { key: 'acos120', label: 'ACoS 100-120%' },
      { key: 'acos150', label: 'ACoS 120-150%' },
      { key: 'acos200', label: 'ACoS 150-200%' },
      { key: 'acos200+', label: '> 200% ACoS' },
      { key: 'clicks5', label: '< 5 Clicks, No Orders' },
      { key: 'clicks10', label: '5-9 Clicks, No Orders' },
      { key: 'clicks15', label: '10-14 Clicks, No Orders' },
      { key: 'clicks15+', label: '> 15 Clicks, No Orders' },
      { key: 'impressions50', label: '< 50 Impressions' },
      { key: 'no_spend', label: 'No Spends' },
    ]

    let totalCount = 0
    let totalRevenue = 0
    let totalCost = 0
    setRecords(buckets.map((bucket, index) => {
      const record = calcDerivedMetrics(data.buckets[bucket.key])

      totalCount += record.count
      totalRevenue += record.revenue
      totalCost += record.cost

      return {
        ...record,
        sort: index,
        key: bucket.key,
        label: bucket.label,
        revenuePercent: data.totalRevenue
          ? record.revenue / data.totalRevenue * 100
          : 0,
        costPercent: data.totalCost
          ? record.cost / data.totalCost * 100
          : 0,
      }
    }))

    setSummary({
      count: totalCount,
      revenuePercent: data.totalRevenue
        ? totalRevenue / data.totalRevenue * 100
        : 0,
      costPercent: data.totalCost
        ? totalCost / data.totalCost * 100
        : 0,
    })
  }

  const renderRecord = record => (
    <>
      <div className="table-col col-label">{ record.label }</div>
      <div className="table-col">
        { formatValue(record.count, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatCurrency(record.bid, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.minBid, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatCurrency(record.maxBid, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.costPercent, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate, 2, true) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.revenuePercent, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate, 2, true) }
      </div>
      <div className="table-col">
        { formatValue(record.ctr, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cpc, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent') }
      </div>
    </>
  )

  const renderTotal = record => (
    <>
      <div className="table-col col-label">Totals:</div>
      <div className="table-col">
        { formatValue(summary.count || 0, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
      </div>
      <div className="table-col">
      </div>
      <div className="table-col">
      </div>
      <div className="table-col">
        { formatValue(record.impressions, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(record.clicks, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(summary.costPercent || 0, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cost, currencySign, currencyRate, 2, true) }
      </div>
      <div className="table-col">
        { formatValue(record.orders, 'removeZeroDecimal') }
      </div>
      <div className="table-col">
        { formatValue(summary.revenuePercent || 0, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.revenue, currencySign, currencyRate, 2, true) }
      </div>
      <div className="table-col">
        { formatValue(record.ctr, 'percent') }
      </div>
      <div className="table-col">
        { formatCurrency(record.cpc, currencySign, currencyRate) }
      </div>
      <div className="table-col">
        { formatValue(record.acos, 'percent') }
      </div>
    </>
  )

  return (
    <div className="report-widget">
      <div className="report-widget-header">
        <div className="widget-title">
          <span>
            Performance Segments
          </span>
        </div>
      </div>
      <div className="report-widget-content">
        <SortableTable
          isLoading={isLoading}
          columns={columns}
          defaultSort={['sort', 'asc']}
          sorter={tableSorter(['label'])}
          className="table-performance-segment"
          records={records}
          idField="key"
          noSearch
          noCheckBox
          noPagination
          renderRecord={renderRecord}
          renderTotal={renderTotal}
        />
      </div>
    </div>
  )
}

export default PerformanceSegment
