import React from 'react'
import { Modal } from 'rsuite'

const TemplateSelectorFooter = ({
  selectedTemplates,
  currentTemplates,
  isApplying,
  onApply,
  onClose,
}) => {
  let showApply = false
  let buttonLabel
  const selected = selectedTemplates.map(t => t.id).sort()
  const current = currentTemplates.map(t => t.id).sort()
  if (JSON.stringify(selected) !== JSON.stringify(current)) {
    showApply = true

    if (!currentTemplates.length) {
      buttonLabel = `Apply Template${selectedTemplates.length > 1 ? 's' : ''}`
    } else if (!selectedTemplates.length) {
      buttonLabel = `Remove Template${currentTemplates.length > 1 ? 's' : ''}`
    } else {
      buttonLabel = 'Save Changes'
    }
  }

  return (
    <Modal.Footer>
      {
        showApply && (
          <button
            type="button"
            className="rs-btn rs-btn-primary"
            disabled={isApplying}
            onClick={onApply}
          >
            { buttonLabel }
          </button>
        )
      }
      <button
        type="button"
        className="rs-btn rs-btn-subtle"
        disabled={isApplying}
        onClick={onClose}
      >
        Cancel
      </button>
    </Modal.Footer>
  )
}

export default TemplateSelectorFooter
