import React, { useState } from 'react'
import Select from 'react-select'

const EmptyCard = ({ metricList, cards, isNonEndemicAccount, noOrganic, onChange }) => {
  const [isSelecting, setIsSelecting] = useState(false)

  const unusedMetrics = []
  Object.keys(metricList).forEach((metric) => {
    if (
      (cards.indexOf(metric) !== -1)
      ||
      (noOrganic && metric === 'organicRevenue')
      ||
      (
        isNonEndemicAccount
        && [
          'organicRevenue',
          'revenue',
          'acos',
          'orders',
          'conversion',
          'roas',
          'ntb_orders',
          'ntb_sales',
          'ntb_units',
          'ntb_sales_percent',
        ].includes(metric)
      )
    ) {
      return
    }

    unusedMetrics.push({
      label: metricList[metric].name,
      value: metric,
    })
  })

  return (
    <div
      className="kpi-card-inner kpi-empty"
      onClick={() => { !isSelecting && setIsSelecting(true) }}
    >
      {
        !isSelecting ? '+ Metric Card' : (
          <Select
            className="metric-selector"
            classNamePrefix="metric-selector"
            options={unusedMetrics}
            placeholder="Select Metric..."
            onChange={(selected) => { onChange(selected.value) }}
          />
        )
      }
    </div>
  )
}

export default EmptyCard
