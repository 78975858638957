import React from 'react'

import TrendingChart from '../TrendingChart'

import { formatValue, formatCurrency } from '../../../services/helper'

const TableCell = ({
  record,
  columnKey,
  columnSelection,
  currencySign,
  currencyRate,
  historyData = [],
  showHistory = false,
  startDate,
  endDate,
  onClick = () => {},
}) => {
  if (
    typeof columnSelection !== 'undefined'
    && !columnSelection.find(c => c.key === columnKey)
  ) {
    return null
  }

  let value = record[columnKey]
  if (['orders', 'impressions', 'clicks', 'ntb_orders',
    'viewable_impressions', 'video_views_25', 'video_views_50',
    'video_views_75', 'video_views_100', 'video_5s_views'].includes(columnKey)) {
    value = formatValue(record[columnKey], 'removeZeroDecimal')
  } else if (['acos', 'ctr', 'conversion', 'ntb_orders_percent',
    'ntb_sales_percent', 'vtr', 'vctr', 'view_5_seconds_rate'].includes(columnKey)) {
    value = formatValue(record[columnKey], 'percent')
  } else if (['revenue', 'cost', 'cpc', 'ntb_sales', 'daily_budget', 'vcpm'].includes(columnKey)) {
    value = formatCurrency(record[columnKey], currencySign, currencyRate)
  } else if (columnKey === 'roas') {
    value = formatValue(record[columnKey], 'number')
  } else if (columnKey === 'st_impr_rank') {
    if (parseFloat(record[columnKey])) {
      value = formatValue(Math.ceil(parseFloat(record[columnKey])), 'removeZeroDecimal')
    }
  } else if (columnKey === 'st_impr_share') {
    if (parseFloat(record[columnKey])) {
      value = formatValue(record[columnKey], 'percent')
    }
  }

  return (
    <div className="table-col col-history" onClick={onClick}>
      { value }
      {
        (columnKey !== 'start_date' && showHistory) && (
          <>
            <TrendingChart
              metricKey={columnKey}
              chartData={
                historyData.map(item => ({
                  date: item.report_date,
                  value: item[columnKey] || 0,
                }))
              }
              startDate={startDate}
              endDate={endDate}
            />
            <span className="tooltiptext">+</span>
          </>
        )
      }
    </div>
  )
}

export default TableCell
