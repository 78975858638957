import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import SectionAdd from './SectionAdd'
import AccountTable from './AccountTable'

import { STORAGE_KEY_REGION, STORAGE_KEY_ORIGIN } from '../../utils/defaultValues'

import { addAccountOpen } from '../../redux/actions/auth'

const BillingAmazon = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    const qs = new URLSearchParams(location.search)
    const callbackUrl = qs.get('amazon_callback_uri')
    const spCode = qs.get('spapi_oauth_code')
    const code = qs.get('code')
    const origin = window.sessionStorage.getItem(STORAGE_KEY_ORIGIN)

    if ((callbackUrl || spCode || code) && origin === 'add_account') {
      setExpanded(true)
    }
  }, [location])

  const handleExpand = () => {
    if (!expanded) {
      window.sessionStorage.removeItem(STORAGE_KEY_REGION)
      dispatch(addAccountOpen())
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }

  const handleModalClose = () => {
    setExpanded(false)
  }

  return (
    <>
      <AccountTable onAdd={handleExpand} />
      <SectionAdd
        expanded={expanded}
        onClose={handleModalClose}
      />
    </>
  )
}

export default BillingAmazon
