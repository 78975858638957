import React from 'react'

import C6Logo from '../../assets/svg/login/c6-logo.svg'

const AuthSideComponent = ({ children }) => {
  return (
    <div className="auth-side-component">
      <img src={C6Logo} alt="Carbon6" />
      <div className="c6-family-notice">
        Better than ever!<br />
        PPC Entourage is now part of Carbon6.
      </div>
      <div className="page-notice">
        { children }
      </div>
      <div className="footer-notice">
        &copy;Copyright 2023 - Carbon6
      </div>
    </div>
  )
}

export default AuthSideComponent
