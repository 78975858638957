import React, { useMemo, useState } from 'react'
import Select from 'react-select'
import { Modal } from 'rsuite'

import CustomTooltip from '../CommonComponents/CustomTooltip'

const AdgroupSelector = ({ campaign, settings, onChange }) => {
  const [selectCache, setSelectCache] = useState(0)
  const [showConfirm, setShowConfirm] = useState(false)

  const adgroupList = useMemo(() => {
    let adgroups = []
    if (campaign) {
      adgroups = campaign.adgroups.filter(adgroup => (
        adgroup.state.toLowerCase() === 'enabled'
      ))
    }

    const list = adgroups.map(adgroup => ({
      value: adgroup.id.toString(),
      label: adgroup.name,
    }))

    list.unshift({
      value: 0,
      label: '- Entire Campaign -',
    })

    return list
  }, [campaign])

  const value = useMemo(() => (
    adgroupList.find(option => (
      option.value === settings.adgroup_id.toString()
    ))
  ), [adgroupList, settings.adgroup_id])

  const handleChange = (selected) => {
    setSelectCache(selected.value)
    setShowConfirm(true)
  }

  const handleConfirm = toSave => () => {
    onChange(selectCache, toSave)
    setShowConfirm(false)
  }

  return (
    <div className="adgroup-selector">
      <label>
        Apply to Entire Campaign or Select Ad Group
        <CustomTooltip>
          <p>You can set automation rules on a campaign level or ad group level.
          If you have multiple ad groups but want our system
          to treat them all the same, use campaign level settings.</p>
          <p>You can also set both campaign level automations and ad group level automations
          for the same campaign. In this case, the ad group level automations you save
          will override campaign level automations for that ad group.</p>
          <p>Please note: Search Term Expansion and Product Targeting Expansion is saved
          on a campaign level but you can choose which ad groups you want to add targets to.</p>
          <p>Saving Templates and “Apply to multiple campaigns” options use campaign level settings.</p>
        </CustomTooltip>
      </label>
      <Select
        value={value}
        options={adgroupList}
        placeholder="Choose Ad Groups"
        onChange={handleChange}
      />
      <Modal backdrop="static" show={showConfirm} size="xs">
        <Modal.Body>
          Save changes before switching to { selectCache ? 'ad group' : 'campaign' }?
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="rs-btn rs-btn-primary" onClick={handleConfirm(true)}>
            Yes
          </button>
          <button type="button" className="rs-btn rs-btn-subtle" onClick={handleConfirm(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AdgroupSelector
